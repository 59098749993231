import _flatten from 'lodash/flatten';
export const extractPassedPrograms = (usersList) => usersList.map((user) => {
    var _a;
    const extractedProfiles = [];
    const currentProgramProfile = Object.assign({}, user);
    delete currentProgramProfile.passedProgramsList;
    extractedProfiles.push(currentProgramProfile);
    if (((_a = user.passedProgramsList) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        user.passedProgramsList.forEach((program) => {
            const passedProgramProfile = Object.assign(Object.assign({}, currentProgramProfile), { program });
            extractedProfiles.push(passedProgramProfile);
        });
    }
    return _flatten(extractedProfiles);
});
export const filterFinishedUsers = (usersList) => usersList
    .filter(({ program }) => {
    var _a, _b;
    return program && (((_a = program === null || program === void 0 ? void 0 : program.reportData) === null || _a === void 0 ? void 0 : _a.workSessionCount)
        >= ((_b = program === null || program === void 0 ? void 0 : program.progress) === null || _b === void 0 ? void 0 : _b.targetWorkSessionsCount));
});
export const filterSortUsersByRisk = (usersList) => usersList
    .filter(({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.improvements) === null || _a === void 0 ? void 0 : _a.currentHazardsPerHour; })
    .sort((a, b) => b.program.improvements.currentHazardsPerHour - a.program.improvements.currentHazardsPerHour);
export const filterSortUsersByImprovement = (usersList) => usersList
    .filter(({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.improvements) === null || _a === void 0 ? void 0 : _a.hazardsPerHourChange; })
    .sort((a, b) => a.program.improvements.hazardsPerHourChange - b.program.improvements.hazardsPerHourChange);
