import * as types from './actionTypes';
import { getMetaData, jobDictionaryRequest, jobDictionaryService } from '../../../init/proto_client';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

export function toggleIsLoading(isLoading) {
  return { type: types.JOB_PROFILES_IS_LOADING, isLoading };
}

export function getJobDictionary(companyId) {
  return async (dispatch) => {
    dispatch(toggleIsLoading(true));
    jobDictionaryRequest.setCompanyId(companyId);
    await jobDictionaryService.getJobDictionary(
      jobDictionaryRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch({
            type: types.JOB_PROFILES_ERROR,
            isLoading: false,
            error,
          });
        } else {
          dispatch({
            type: types.JOB_PROFILES_SET,
            isLoading: false,
            profileData: response.toObject(),
          });
        }
      },
    );
  };
}
