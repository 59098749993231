import React from 'react';
import { TaskVideoStatus } from 'init/proto_client';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { videoStatus } from '../../../../task/gallery/constants/videoStatus';

const getStatusColor = (status) => {
  if (status === TaskVideoStatus.WAITING_FOR_APPROVAL) {
    return COLORS.ORANGE[600];
  }
  if (status === TaskVideoStatus.APPROVED) {
    return COLORS.GREEN[600];
  }
  return COLORS.GRAY[950];
};

const VideoStatusCell = ({ rowData }) => {
  const { t } = useTranslation();
  return (
    <div style={{ color: getStatusColor(rowData.status), wordBreak: 'keep-all' }}>
      {t(videoStatus[rowData.status])}
    </div>
  );
};

export default VideoStatusCell;
