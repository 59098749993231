import { physicalDemandsColorList, weightUnitList } from '../constants';

export default class DemandsActivityUiConvertor {
  convertDataToUiValues(data, weightUnit) {
    const recalculatedData = { ...data };
    Object.keys(recalculatedData).forEach((activityKey) => {
      recalculatedData[activityKey] = recalculatedData[activityKey]
        .map(({ color, value }) => (
          {
            color: physicalDemandsColorList[color],
            value: value ? value / weightUnitList[weightUnit].coefficient : 0,
          }
        ));
    });
    return recalculatedData;
  }

  convertUiValuesToData(values, weightUnit) {
    const recalculatedData = { ...values };
    Object.keys(recalculatedData).forEach((activityKey) => {
      recalculatedData[activityKey] = recalculatedData[activityKey]
        .map(({ color, value }) => (
          {
            color: physicalDemandsColorList.indexOf(color),
            value: value ? value * weightUnitList[weightUnit].coefficient : 0,
          }
        ));
    });
    return recalculatedData;
  }
}
