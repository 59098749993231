import React from 'react';
import sliceLongString from 'lib/utils/sliceLongString';
import { name } from 'assets/jss/containers/pdfLayersStyle';
import { useTranslation } from 'react-i18next';

const UserReportSubheader = ({
  firstName, lastName, jobTitle, department,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={name}>{sliceLongString(`${firstName} ${lastName}`, 40)}</div>
      <div>{`${jobTitle || t('WEARABLES.USER_PAGE.NO_JOB_TITLE')}, ${department?.name || t('WEARABLES.USER_PAGE.NO_DEPARTMENT')}`}</div>
    </>
  );
};

export default UserReportSubheader;
