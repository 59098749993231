import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  flexColumn, flexRow, inputLabel, mb2, mt2,
} from '../../../assets/soter-flex-styles';
import {
  addButton,
  buttonWrapper,
  cancelButton,
  errors,
  timeSelect,
} from '../../../assets/jss/components/addDayTasksDialog';
import { errorTexts } from './constants';
import { getClickOnChartTime } from './utils';
import Button from '../../../lib/ui/Button';
import useFocus from '../../../lib/utils/useFocus';
import InputWithPreselect from './InputWithPreselect';
import CustomSelect from '../../../lib/ui/CustomSelect';

const AddNewTaskForm = ({
  values,
  taskTitles,
  isEditMode,
  timeOptions,
  shouldBeValidated,
  validationProps,
  isAddTaskBtnDisabled,
  onFieldChange,
  onAddNewTask,
  onSaveEditedTask,
  onCancelEditTask,
}) => {
  const [inputRef, setInputFocus] = useFocus();
  const { t } = useTranslation();

  const [startPreSet, setStartPreset] = useState(true);

  const clickOnChartTime = useSelector((state) => state.userTasks.startTime);

  useEffect(() => {
    setTimeout(() => setInputFocus(), 0);
  }, [isEditMode]);

  const handleStartSelectChange = useCallback(
    (name, option) => {
      if (name === 'timeStart' && clickOnChartTime) {
        setStartPreset(false);
      }
      onFieldChange(name, option.value);
    },
    [onFieldChange],
  );

  const handleSelectChange = useCallback(
    (name, option) => {
      onFieldChange(name, option.value);
    },
    [onFieldChange],
  );

  const timeStart = timeOptions.find(({ value }) => {
    if (clickOnChartTime && startPreSet) {
      const targetTime = getClickOnChartTime(timeOptions, clickOnChartTime);
      return value === targetTime?.value;
    }
    return value === values.timeStart;
  });

  const timeEnd = timeOptions.find(({ value }) => value === values.timeEnd);

  const { isValidTaskTime, isTaskNameFilled, isTimeEndBiggerThanTimeStart }
    = validationProps;

  return (
    <>
      <label
        style={{ marginBottom: '1rem' }}
        className={inputLabel}
        htmlFor="taskName"
      >
        {t('SOTER_TASK.VIDEOS_LIST.TASK_NAME')}
        <InputWithPreselect
          className={`${mt2} ${mb2}`}
          preselectionList={taskTitles}
          value={values.taskName}
          id="taskName"
          disabled={isEditMode}
          setValue={(v) => onFieldChange('taskName', v)}
        />
      </label>
      <div className={`${flexRow}`} style={{ alignItems: 'flex-end' }}>
        <div className={flexColumn} style={{ flex: '1 0 23%' }}>
          <label className={inputLabel}>
            {t('WEARABLES.USER_PAGE.START_TIME')}
          </label>
          <CustomSelect
            type="number"
            className={timeSelect}
            name="timeStart"
            value={timeStart || ''}
            options={timeOptions}
            isSearchable
            filterByLabel
            onChange={handleStartSelectChange}
          />
        </div>
        <div className={flexColumn} style={{ flex: '1 0 23%' }}>
          <label className={inputLabel}>
            {t('WEARABLES.USER_PAGE.END_TIME')}
          </label>
          {isEditMode ? (
            <CustomSelect
              type="number"
              className={timeSelect}
              name="timeEnd"
              options={timeOptions}
              isSearchable
              value={timeEnd}
              filterByLabel
              onChange={handleSelectChange}
            />
          ) : (
            <CustomSelect
              type="number"
              className={timeSelect}
              name="timeEnd"
              options={timeOptions}
              isSearchable
              filterByLabel
              value={timeEnd || ''}
              onChange={handleSelectChange}
            />
          )}
        </div>
      </div>
      <div className={errors}>
        {shouldBeValidated && (
          <>
            {!isTaskNameFilled && <p>{t(errorTexts.emptyTaskName)}</p>}
            {!isValidTaskTime && <p>{t(errorTexts.overlappingTime)}</p>}
            {!isTimeEndBiggerThanTimeStart && (
              <p>{t(errorTexts.timeEndBiggerThanTimeStart)}</p>
            )}
          </>
        )}
      </div>
      {!isEditMode ? (
        <Button
          style={{ width: '100%', display: 'block' }}
          type="button"
          className={addButton}
          disabled={isAddTaskBtnDisabled}
          handler={onAddNewTask}
        >
          {t('WEARABLES.USER_PAGE.ADD_NEW_TASK')}
        </Button>
      ) : (
        <div className={buttonWrapper}>
          <button
            type="button"
            className={cancelButton}
            onClick={onCancelEditTask}
          >
            {t('GENERAL.CANCEL')}
          </button>
          <button
            type="button"
            className={addButton}
            disabled={isAddTaskBtnDisabled}
            onClick={onSaveEditedTask}
          >
            {t('GENERAL.SAVE')}
          </button>
        </div>
      )}
    </>
  );
};

export default AddNewTaskForm;
