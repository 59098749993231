import React from 'react';
import { useFormik } from 'formik';
import { Link, Redirect } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { login } from './store/actions';
import LoginPageContainer from './LoginContainer/components/LoginPageContainer';
import LoginFormCard from './LoginContainer/components/LoginFormCard';
import LoginTextInput from './LoginContainer/components/LoginTextInput';
import { ValidateAsyncOnChange } from './ValidateAsyncOnChange';
import { useAppDispatch, useAppSelector } from '../lib/utils/useStore';
import emailValidator from './emailValidator';

function LoginPage({ lastVisitedPage }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const { id } = useAppSelector((state) => state.companies.selectedCompany);
  const validate = emailValidator(t);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      const { email, password } = values;
      dispatch(login({ email, password }));
    },
  });

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const debouncedValidation = () => sleep(500).then(() => validate(formik.values));
  const isButtonDisabled = !!validate(formik.values).email;

  return (
    <>
      {auth.profile && id
        ? <Redirect noThrow to={`/${id}/users/wearables/overview`} />
        : (
          <LoginPageContainer>
            <LoginFormCard
              buttonText={t('LOGIN.LOGIN', 'Login')}
              title={t('LOGIN.HEADER', 'Login to your Account')}
              onSubmit={formik.handleSubmit}
              buttonDisabled={isButtonDisabled}
            >
              <LoginTextInput
                name="email"
                label={t('LOGIN.EMAIL', 'Email Address')}
                placeholder={t('LOGIN.EMAIL_PLACEHOLDER', 'Enter email')}
                onChange={formik.handleChange}
                value={formik.values && formik.values.email}
                error={formik.errors?.email}
                touched={formik.touched.email}
                errorMessage={formik.errors?.email}
                checkTouched={false}
              />
              <Link
                to="/forgot-password"
                style={{
                  position: 'absolute',
                  zIndex: '100',
                  top: '10.4rem',
                  right: '1.5em',
                  color: COLORS.LIGHT_BLUE[0],
                }}
                tabIndex="-1"
              >
                {t('LOGIN.FORGOT_PASSWORD', 'I forgot password')}
              </Link>
              <LoginTextInput
                name="password"
                type="password"
                label={t('LOGIN.PASSWORD', 'Password')}
                placeholder={t('LOGIN.PASSWORD', 'Password')}
                onChange={formik.handleChange}
                value={formik.values && formik.values.password}
                error={false}
                checkTouched={false}
              />
            </LoginFormCard>
          </LoginPageContainer>
        )}
      <ValidateAsyncOnChange validate={debouncedValidation} formik={formik} />
    </>
  );
}

export default LoginPage;
