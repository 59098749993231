import {
  createAssessmentRequest,
  deleteAssessmentRequest,
  getAssessmentsRequest,
  getMetaData,
  taskAssessmentService,
  updateAssessmentRequest,
} from 'init/proto_client';
import * as types from './actionsTypes';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

const { AssessmentField } = require('../../../api/soter-task/common/v1/assessment_message_pb');

const prepareFields = (fields) => fields.map((value) => {
  const preparedField = new AssessmentField();
  if (value.value >= 0 && value.name) {
    preparedField.setValue(value.value);
    preparedField.setType(1);
    preparedField.setName(value.name);
  } else {
    preparedField.setValue(1);
    preparedField.setType(1);
    preparedField.setName(value);
  }
  return preparedField;
});

export const createAssessment = ({
  type, title, totalScore, timestamp, taskVideoId, fields, additionalTotalScore,
}) => async (dispatch) => {
  createAssessmentRequest.setType(type);
  createAssessmentRequest.setTitle(title);
  createAssessmentRequest.setTotalScore(totalScore);
  createAssessmentRequest.setAdditionalTotalScore(additionalTotalScore);
  createAssessmentRequest.setTimestamp(timestamp);
  createAssessmentRequest.setTaskVideoId(taskVideoId);
  createAssessmentRequest.setFieldsList(prepareFields(fields));
  await taskAssessmentService.createAssessment(
    createAssessmentRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.ASSESSMENTS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.SAVE_ASSESSMENT_FAILED_MSG,
        }));
      } else {
        dispatch({
          type: types.ASSESSMENTS_UPDATE,
          assessment: response.toObject(),
        });
        dispatch({
          type: types.ASSESSMENT_SET,
          currentAssessmentId: response.toObject().id,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.SAVE_ASSESSMENT_SUCCESS_MSG,
        }));
      }
    },
  );
};

export const updateAssessment = ({
  id, title, totalScore, timestamp, fields, additionalTotalScore,
}) => async (dispatch) => {
  updateAssessmentRequest.setId(id);
  updateAssessmentRequest.setTitle(title);
  updateAssessmentRequest.setTotalScore(totalScore);
  updateAssessmentRequest.setAdditionalTotalScore(additionalTotalScore);
  updateAssessmentRequest.setTimestamp(timestamp);
  updateAssessmentRequest.setFieldsList(prepareFields(fields));
  await taskAssessmentService.updateAssessment(
    updateAssessmentRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.ASSESSMENTS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.SAVE_ASSESSMENT_FAILED_MSG,
        }));
      } else {
        dispatch({
          type: types.ASSESSMENTS_UPDATE,
          assessment: response.toObject(),
        });
        dispatch({
          type: types.ASSESSMENT_SET,
          currentAssessmentId: response.toObject().id,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.SAVE_ASSESSMENT_SUCCESS_MSG,
        }));
      }
    },
  );
};

export const removeAssessment = (id) => async (dispatch) => {
  deleteAssessmentRequest.setAssessmentId(id);
  await taskAssessmentService.deleteAssessment(
    deleteAssessmentRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch({
          type: types.ASSESSMENTS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.REMOVE_ASSESSMENT_FAILED_MSG,
        }));
      } else {
        dispatch({
          type: types.ASSESSMENT_REMOVE,
          removedAssessmentId: id,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_ASSESSMENT_SUCCESS_MSG,
        }));
      }
    },
  );
};

export const getAssessments = (videoId) => async (dispatch) => {
  getAssessmentsRequest.setVideoId(videoId);
  await taskAssessmentService.getAssessmentsForVideo(
    getAssessmentsRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.ASSESSMENTS_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.ASSESSMENTS_SET,
          assessmentsList: response.toObject().assessmentsList,
        });
      }
    },
  );
};

export const setAssessmentStep = (assessmentStep) => (dispatch) => dispatch(
  { type: types.ASSESSMENT_STEP_SET, assessmentStep },
);

export const setCurrentAssessment = (currentAssessmentId) => (dispatch) => dispatch(
  { type: types.ASSESSMENT_SET, currentAssessmentId },
);

export const resetAssessments = () => (dispatch) => dispatch({ type: types.ASSESSMENTS_RESET });
