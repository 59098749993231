import React from 'react';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';

const bar = (width, color) => Emotion.css`
  width: ${width}px;
  max-width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: ${color};
`;

const ProgressBar = ({ width, currentProgress }) => (
  <div className={bar(width, COLORS.GRAY[400])}>
    <div className={bar((width / 100) * currentProgress, COLORS.LIGHT_BLUE[600])} />
  </div>
);

export default ProgressBar;
