// source: api/dashboard/v2/segments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_segment_message_pb = require('../../../api/dashboard/v2/segment_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_segment_message_pb);
goog.exportSymbol('proto.segments.AddTagToUserRequest', null, global);
goog.exportSymbol('proto.segments.AddTagToUsersRequest', null, global);
goog.exportSymbol('proto.segments.AddTagsToDashboardUserRequest', null, global);
goog.exportSymbol('proto.segments.AddTagsToDashboardUserResponse', null, global);
goog.exportSymbol('proto.segments.AddTagsToDeviceRequest', null, global);
goog.exportSymbol('proto.segments.AddTagsToDeviceResponse', null, global);
goog.exportSymbol('proto.segments.AddTagsToEventRequest', null, global);
goog.exportSymbol('proto.segments.AddTagsToEventResponse', null, global);
goog.exportSymbol('proto.segments.AddTagsToUserRequest', null, global);
goog.exportSymbol('proto.segments.AddTagsToUserResponse', null, global);
goog.exportSymbol('proto.segments.AddTagsToWorkSessionRequest', null, global);
goog.exportSymbol('proto.segments.AddTagsToWorkSessionResponse', null, global);
goog.exportSymbol('proto.segments.CreateSegmentRequest', null, global);
goog.exportSymbol('proto.segments.CreateTagRequest', null, global);
goog.exportSymbol('proto.segments.CreateTagResponse', null, global);
goog.exportSymbol('proto.segments.CreateTagsRequest', null, global);
goog.exportSymbol('proto.segments.CreateTagsResponse', null, global);
goog.exportSymbol('proto.segments.GetSegmentsRequest', null, global);
goog.exportSymbol('proto.segments.GetSegmentsResponse', null, global);
goog.exportSymbol('proto.segments.RemoveSegmentRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagToUserRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromDashboardUserRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromDashboardUserResponse', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromDashboardUserResponse.Status', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromDeviceRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromDeviceResponse', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromDeviceResponse.Status', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromEventRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromEventResponse', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromEventResponse.Status', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromUserRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromUserResponse', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromUserResponse.Status', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromWorkSessionRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromWorkSessionResponse', null, global);
goog.exportSymbol('proto.segments.RemoveTagsFromWorkSessionResponse.Status', null, global);
goog.exportSymbol('proto.segments.RemoveTagsRequest', null, global);
goog.exportSymbol('proto.segments.RemoveTagsResponse', null, global);
goog.exportSymbol('proto.segments.RemoveTagsResponse.Status', null, global);
goog.exportSymbol('proto.segments.SegmentResponse', null, global);
goog.exportSymbol('proto.segments.SegmentsResponse', null, global);
goog.exportSymbol('proto.segments.SegmentsResponse.Status', null, global);
goog.exportSymbol('proto.segments.UpdateSegmentRequest', null, global);
goog.exportSymbol('proto.segments.UpdateTagRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.SegmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.SegmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.SegmentResponse.displayName = 'proto.segments.SegmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.SegmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.SegmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.SegmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.SegmentsResponse.displayName = 'proto.segments.SegmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.SegmentsResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.SegmentsResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.SegmentsResponse.Status.displayName = 'proto.segments.SegmentsResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.CreateSegmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.CreateSegmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.CreateSegmentRequest.displayName = 'proto.segments.CreateSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.UpdateSegmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.UpdateSegmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.UpdateSegmentRequest.displayName = 'proto.segments.UpdateSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveSegmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveSegmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveSegmentRequest.displayName = 'proto.segments.RemoveSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.CreateTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.CreateTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.CreateTagRequest.displayName = 'proto.segments.CreateTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.UpdateTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.UpdateTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.UpdateTagRequest.displayName = 'proto.segments.UpdateTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.GetSegmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.GetSegmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.GetSegmentsRequest.displayName = 'proto.segments.GetSegmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.GetSegmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.GetSegmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.GetSegmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.GetSegmentsResponse.displayName = 'proto.segments.GetSegmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagRequest.displayName = 'proto.segments.RemoveTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.CreateTagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.CreateTagsRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.CreateTagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.CreateTagsRequest.displayName = 'proto.segments.CreateTagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.CreateTagsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.CreateTagsResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.CreateTagsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.CreateTagsResponse.displayName = 'proto.segments.CreateTagsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsRequest.displayName = 'proto.segments.RemoveTagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsResponse.displayName = 'proto.segments.RemoveTagsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagsResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsResponse.Status.displayName = 'proto.segments.RemoveTagsResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.CreateTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.CreateTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.CreateTagResponse.displayName = 'proto.segments.CreateTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToEventRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToEventRequest.displayName = 'proto.segments.AddTagsToEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToEventResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToEventResponse.displayName = 'proto.segments.AddTagsToEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromEventRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromEventRequest.displayName = 'proto.segments.RemoveTagsFromEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromEventResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromEventResponse.displayName = 'proto.segments.RemoveTagsFromEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromEventResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagsFromEventResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromEventResponse.Status.displayName = 'proto.segments.RemoveTagsFromEventResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToWorkSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToWorkSessionRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToWorkSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToWorkSessionRequest.displayName = 'proto.segments.AddTagsToWorkSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToWorkSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToWorkSessionResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToWorkSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToWorkSessionResponse.displayName = 'proto.segments.AddTagsToWorkSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromWorkSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromWorkSessionRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromWorkSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromWorkSessionRequest.displayName = 'proto.segments.RemoveTagsFromWorkSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromWorkSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromWorkSessionResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromWorkSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromWorkSessionResponse.displayName = 'proto.segments.RemoveTagsFromWorkSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagsFromWorkSessionResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromWorkSessionResponse.Status.displayName = 'proto.segments.RemoveTagsFromWorkSessionResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.AddTagToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagToUserRequest.displayName = 'proto.segments.AddTagToUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagToUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagToUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagToUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagToUsersRequest.displayName = 'proto.segments.AddTagToUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagToUserRequest.displayName = 'proto.segments.RemoveTagToUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToUserRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToUserRequest.displayName = 'proto.segments.AddTagsToUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToUserResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToUserResponse.displayName = 'proto.segments.AddTagsToUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromUserRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromUserRequest.displayName = 'proto.segments.RemoveTagsFromUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromUserResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromUserResponse.displayName = 'proto.segments.RemoveTagsFromUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromUserResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagsFromUserResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromUserResponse.Status.displayName = 'proto.segments.RemoveTagsFromUserResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToDashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToDashboardUserRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToDashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToDashboardUserRequest.displayName = 'proto.segments.AddTagsToDashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToDashboardUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToDashboardUserResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToDashboardUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToDashboardUserResponse.displayName = 'proto.segments.AddTagsToDashboardUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromDashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromDashboardUserRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromDashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromDashboardUserRequest.displayName = 'proto.segments.RemoveTagsFromDashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromDashboardUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromDashboardUserResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromDashboardUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromDashboardUserResponse.displayName = 'proto.segments.RemoveTagsFromDashboardUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagsFromDashboardUserResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromDashboardUserResponse.Status.displayName = 'proto.segments.RemoveTagsFromDashboardUserResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToDeviceRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToDeviceRequest.displayName = 'proto.segments.AddTagsToDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.AddTagsToDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.AddTagsToDeviceResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.AddTagsToDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.AddTagsToDeviceResponse.displayName = 'proto.segments.AddTagsToDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromDeviceRequest.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromDeviceRequest.displayName = 'proto.segments.RemoveTagsFromDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.segments.RemoveTagsFromDeviceResponse.repeatedFields_, null);
};
goog.inherits(proto.segments.RemoveTagsFromDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromDeviceResponse.displayName = 'proto.segments.RemoveTagsFromDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.segments.RemoveTagsFromDeviceResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.segments.RemoveTagsFromDeviceResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.segments.RemoveTagsFromDeviceResponse.Status.displayName = 'proto.segments.RemoveTagsFromDeviceResponse.Status';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.SegmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.SegmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.SegmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.SegmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.SegmentResponse}
 */
proto.segments.SegmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.SegmentResponse;
  return proto.segments.SegmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.SegmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.SegmentResponse}
 */
proto.segments.SegmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.SegmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.SegmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.SegmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.SegmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.segments.SegmentResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.SegmentResponse} returns this
 */
proto.segments.SegmentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.SegmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.SegmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.SegmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.SegmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.SegmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.SegmentsResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.SegmentsResponse}
 */
proto.segments.SegmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.SegmentsResponse;
  return proto.segments.SegmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.SegmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.SegmentsResponse}
 */
proto.segments.SegmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.SegmentsResponse.Status;
      reader.readMessage(value,proto.segments.SegmentsResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.SegmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.SegmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.SegmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.SegmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.SegmentsResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.SegmentsResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.SegmentsResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.SegmentsResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.SegmentsResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.SegmentsResponse.Status}
 */
proto.segments.SegmentsResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.SegmentsResponse.Status;
  return proto.segments.SegmentsResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.SegmentsResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.SegmentsResponse.Status}
 */
proto.segments.SegmentsResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.SegmentsResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.SegmentsResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.SegmentsResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.SegmentsResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.SegmentsResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.SegmentsResponse.Status} returns this
 */
proto.segments.SegmentsResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.SegmentsResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.SegmentsResponse.Status} returns this
 */
proto.segments.SegmentsResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.SegmentsResponse.Status>}
 */
proto.segments.SegmentsResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.SegmentsResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.SegmentsResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.SegmentsResponse.Status>} value
 * @return {!proto.segments.SegmentsResponse} returns this
*/
proto.segments.SegmentsResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.SegmentsResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.SegmentsResponse.Status}
 */
proto.segments.SegmentsResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.SegmentsResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.SegmentsResponse} returns this
 */
proto.segments.SegmentsResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.CreateSegmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.CreateSegmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.CreateSegmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateSegmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.CreateSegmentRequest}
 */
proto.segments.CreateSegmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.CreateSegmentRequest;
  return proto.segments.CreateSegmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.CreateSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.CreateSegmentRequest}
 */
proto.segments.CreateSegmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.CreateSegmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.CreateSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.CreateSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateSegmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.segments.CreateSegmentRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.CreateSegmentRequest} returns this
 */
proto.segments.CreateSegmentRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.segments.CreateSegmentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.CreateSegmentRequest} returns this
 */
proto.segments.CreateSegmentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.segments.CreateSegmentRequest.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.CreateSegmentRequest} returns this
 */
proto.segments.CreateSegmentRequest.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.UpdateSegmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.UpdateSegmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.UpdateSegmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.UpdateSegmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.UpdateSegmentRequest}
 */
proto.segments.UpdateSegmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.UpdateSegmentRequest;
  return proto.segments.UpdateSegmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.UpdateSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.UpdateSegmentRequest}
 */
proto.segments.UpdateSegmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.UpdateSegmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.UpdateSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.UpdateSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.UpdateSegmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.UpdateSegmentRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.UpdateSegmentRequest} returns this
 */
proto.segments.UpdateSegmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.segments.UpdateSegmentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.UpdateSegmentRequest} returns this
 */
proto.segments.UpdateSegmentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.segments.UpdateSegmentRequest.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.UpdateSegmentRequest} returns this
 */
proto.segments.UpdateSegmentRequest.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveSegmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveSegmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveSegmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveSegmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveSegmentRequest}
 */
proto.segments.RemoveSegmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveSegmentRequest;
  return proto.segments.RemoveSegmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveSegmentRequest}
 */
proto.segments.RemoveSegmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveSegmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveSegmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 segment_id = 1;
 * @return {number}
 */
proto.segments.RemoveSegmentRequest.prototype.getSegmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveSegmentRequest} returns this
 */
proto.segments.RemoveSegmentRequest.prototype.setSegmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.CreateTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.CreateTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.CreateTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companySegmentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.CreateTagRequest}
 */
proto.segments.CreateTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.CreateTagRequest;
  return proto.segments.CreateTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.CreateTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.CreateTagRequest}
 */
proto.segments.CreateTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanySegmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.CreateTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.CreateTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.CreateTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanySegmentId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.segments.CreateTagRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.CreateTagRequest} returns this
 */
proto.segments.CreateTagRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_segment_id = 2;
 * @return {number}
 */
proto.segments.CreateTagRequest.prototype.getCompanySegmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.CreateTagRequest} returns this
 */
proto.segments.CreateTagRequest.prototype.setCompanySegmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.segments.CreateTagRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.CreateTagRequest} returns this
 */
proto.segments.CreateTagRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.UpdateTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.UpdateTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.UpdateTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.UpdateTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companySegmentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.UpdateTagRequest}
 */
proto.segments.UpdateTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.UpdateTagRequest;
  return proto.segments.UpdateTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.UpdateTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.UpdateTagRequest}
 */
proto.segments.UpdateTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanySegmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.UpdateTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.UpdateTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.UpdateTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.UpdateTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanySegmentId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.UpdateTagRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.UpdateTagRequest} returns this
 */
proto.segments.UpdateTagRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_segment_id = 2;
 * @return {number}
 */
proto.segments.UpdateTagRequest.prototype.getCompanySegmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.UpdateTagRequest} returns this
 */
proto.segments.UpdateTagRequest.prototype.setCompanySegmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.segments.UpdateTagRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.UpdateTagRequest} returns this
 */
proto.segments.UpdateTagRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.GetSegmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.GetSegmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.GetSegmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.GetSegmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.GetSegmentsRequest}
 */
proto.segments.GetSegmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.GetSegmentsRequest;
  return proto.segments.GetSegmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.GetSegmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.GetSegmentsRequest}
 */
proto.segments.GetSegmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.GetSegmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.GetSegmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.GetSegmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.GetSegmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.segments.GetSegmentsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.GetSegmentsRequest} returns this
 */
proto.segments.GetSegmentsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.GetSegmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.GetSegmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.GetSegmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.GetSegmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.GetSegmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    api_dashboard_v2_segment_message_pb.Segment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.GetSegmentsResponse}
 */
proto.segments.GetSegmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.GetSegmentsResponse;
  return proto.segments.GetSegmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.GetSegmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.GetSegmentsResponse}
 */
proto.segments.GetSegmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.Segment;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.GetSegmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.GetSegmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.GetSegmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.GetSegmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.Segment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.Segment segments = 1;
 * @return {!Array<!proto.segment_message.Segment>}
 */
proto.segments.GetSegmentsResponse.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.segment_message.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.Segment, 1));
};


/**
 * @param {!Array<!proto.segment_message.Segment>} value
 * @return {!proto.segments.GetSegmentsResponse} returns this
*/
proto.segments.GetSegmentsResponse.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.Segment}
 */
proto.segments.GetSegmentsResponse.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.GetSegmentsResponse} returns this
 */
proto.segments.GetSegmentsResponse.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagRequest}
 */
proto.segments.RemoveTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagRequest;
  return proto.segments.RemoveTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagRequest}
 */
proto.segments.RemoveTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyTagId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_tag_id = 1;
 * @return {number}
 */
proto.segments.RemoveTagRequest.prototype.getCompanyTagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagRequest} returns this
 */
proto.segments.RemoveTagRequest.prototype.setCompanyTagId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.CreateTagsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.CreateTagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.CreateTagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.CreateTagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companySegmentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    titlesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.CreateTagsRequest}
 */
proto.segments.CreateTagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.CreateTagsRequest;
  return proto.segments.CreateTagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.CreateTagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.CreateTagsRequest}
 */
proto.segments.CreateTagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanySegmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTitles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.CreateTagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.CreateTagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.CreateTagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanySegmentId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitlesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.segments.CreateTagsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.CreateTagsRequest} returns this
 */
proto.segments.CreateTagsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_segment_id = 2;
 * @return {number}
 */
proto.segments.CreateTagsRequest.prototype.getCompanySegmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.CreateTagsRequest} returns this
 */
proto.segments.CreateTagsRequest.prototype.setCompanySegmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string titles = 3;
 * @return {!Array<string>}
 */
proto.segments.CreateTagsRequest.prototype.getTitlesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.segments.CreateTagsRequest} returns this
 */
proto.segments.CreateTagsRequest.prototype.setTitlesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.segments.CreateTagsRequest} returns this
 */
proto.segments.CreateTagsRequest.prototype.addTitles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.CreateTagsRequest} returns this
 */
proto.segments.CreateTagsRequest.prototype.clearTitlesList = function() {
  return this.setTitlesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.CreateTagsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.CreateTagsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.CreateTagsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.CreateTagsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagsList: jspb.Message.toObjectList(msg.getCompanyTagsList(),
    api_dashboard_v2_segment_message_pb.CompanyTag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.CreateTagsResponse}
 */
proto.segments.CreateTagsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.CreateTagsResponse;
  return proto.segments.CreateTagsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.CreateTagsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.CreateTagsResponse}
 */
proto.segments.CreateTagsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTag;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTag.deserializeBinaryFromReader);
      msg.addCompanyTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.CreateTagsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.CreateTagsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.CreateTagsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.CompanyTag company_tags = 1;
 * @return {!Array<!proto.segment_message.CompanyTag>}
 */
proto.segments.CreateTagsResponse.prototype.getCompanyTagsList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTag, 1));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTag>} value
 * @return {!proto.segments.CreateTagsResponse} returns this
*/
proto.segments.CreateTagsResponse.prototype.setCompanyTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.CompanyTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTag}
 */
proto.segments.CreateTagsResponse.prototype.addCompanyTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.CompanyTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.CreateTagsResponse} returns this
 */
proto.segments.CreateTagsResponse.prototype.clearCompanyTagsList = function() {
  return this.setCompanyTagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsRequest}
 */
proto.segments.RemoveTagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsRequest;
  return proto.segments.RemoveTagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsRequest}
 */
proto.segments.RemoveTagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.RemoveTagsRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.RemoveTagsRequest} returns this
 */
proto.segments.RemoveTagsRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsRequest} returns this
 */
proto.segments.RemoveTagsRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsRequest} returns this
 */
proto.segments.RemoveTagsRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.RemoveTagsResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsResponse}
 */
proto.segments.RemoveTagsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsResponse;
  return proto.segments.RemoveTagsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsResponse}
 */
proto.segments.RemoveTagsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.RemoveTagsResponse.Status;
      reader.readMessage(value,proto.segments.RemoveTagsResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.RemoveTagsResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsResponse.Status}
 */
proto.segments.RemoveTagsResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsResponse.Status;
  return proto.segments.RemoveTagsResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsResponse.Status}
 */
proto.segments.RemoveTagsResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.RemoveTagsResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsResponse.Status} returns this
 */
proto.segments.RemoveTagsResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.RemoveTagsResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.RemoveTagsResponse.Status} returns this
 */
proto.segments.RemoveTagsResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.RemoveTagsResponse.Status>}
 */
proto.segments.RemoveTagsResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.RemoveTagsResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.RemoveTagsResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.RemoveTagsResponse.Status>} value
 * @return {!proto.segments.RemoveTagsResponse} returns this
*/
proto.segments.RemoveTagsResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.RemoveTagsResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsResponse.Status}
 */
proto.segments.RemoveTagsResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.RemoveTagsResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsResponse} returns this
 */
proto.segments.RemoveTagsResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.CreateTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.CreateTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.CreateTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.CreateTagResponse}
 */
proto.segments.CreateTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.CreateTagResponse;
  return proto.segments.CreateTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.CreateTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.CreateTagResponse}
 */
proto.segments.CreateTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.CreateTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.CreateTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.CreateTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.CreateTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.segments.CreateTagResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.CreateTagResponse} returns this
 */
proto.segments.CreateTagResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToEventRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToEventRequest}
 */
proto.segments.AddTagsToEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToEventRequest;
  return proto.segments.AddTagsToEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToEventRequest}
 */
proto.segments.AddTagsToEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.AddTagsToEventRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.AddTagsToEventRequest} returns this
 */
proto.segments.AddTagsToEventRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.AddTagsToEventRequest} returns this
 */
proto.segments.AddTagsToEventRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToEventRequest} returns this
 */
proto.segments.AddTagsToEventRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 event_id = 2;
 * @return {number}
 */
proto.segments.AddTagsToEventRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagsToEventRequest} returns this
 */
proto.segments.AddTagsToEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagsEventsList: jspb.Message.toObjectList(msg.getCompanyTagsEventsList(),
    api_dashboard_v2_segment_message_pb.CompanyTagsEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToEventResponse}
 */
proto.segments.AddTagsToEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToEventResponse;
  return proto.segments.AddTagsToEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToEventResponse}
 */
proto.segments.AddTagsToEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTagsEvent;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTagsEvent.deserializeBinaryFromReader);
      msg.addCompanyTagsEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagsEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTagsEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.CompanyTagsEvent company_tags_events = 1;
 * @return {!Array<!proto.segment_message.CompanyTagsEvent>}
 */
proto.segments.AddTagsToEventResponse.prototype.getCompanyTagsEventsList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTagsEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTagsEvent, 1));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTagsEvent>} value
 * @return {!proto.segments.AddTagsToEventResponse} returns this
*/
proto.segments.AddTagsToEventResponse.prototype.setCompanyTagsEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.CompanyTagsEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTagsEvent}
 */
proto.segments.AddTagsToEventResponse.prototype.addCompanyTagsEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.CompanyTagsEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToEventResponse} returns this
 */
proto.segments.AddTagsToEventResponse.prototype.clearCompanyTagsEventsList = function() {
  return this.setCompanyTagsEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromEventRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromEventRequest}
 */
proto.segments.RemoveTagsFromEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromEventRequest;
  return proto.segments.RemoveTagsFromEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromEventRequest}
 */
proto.segments.RemoveTagsFromEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.RemoveTagsFromEventRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.RemoveTagsFromEventRequest} returns this
 */
proto.segments.RemoveTagsFromEventRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromEventRequest} returns this
 */
proto.segments.RemoveTagsFromEventRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromEventRequest} returns this
 */
proto.segments.RemoveTagsFromEventRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 event_id = 2;
 * @return {number}
 */
proto.segments.RemoveTagsFromEventRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromEventRequest} returns this
 */
proto.segments.RemoveTagsFromEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.RemoveTagsFromEventResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromEventResponse}
 */
proto.segments.RemoveTagsFromEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromEventResponse;
  return proto.segments.RemoveTagsFromEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromEventResponse}
 */
proto.segments.RemoveTagsFromEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.RemoveTagsFromEventResponse.Status;
      reader.readMessage(value,proto.segments.RemoveTagsFromEventResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.RemoveTagsFromEventResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromEventResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromEventResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromEventResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromEventResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromEventResponse.Status}
 */
proto.segments.RemoveTagsFromEventResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromEventResponse.Status;
  return proto.segments.RemoveTagsFromEventResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromEventResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromEventResponse.Status}
 */
proto.segments.RemoveTagsFromEventResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromEventResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromEventResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromEventResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromEventResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.RemoveTagsFromEventResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromEventResponse.Status} returns this
 */
proto.segments.RemoveTagsFromEventResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.RemoveTagsFromEventResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.RemoveTagsFromEventResponse.Status} returns this
 */
proto.segments.RemoveTagsFromEventResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.RemoveTagsFromEventResponse.Status>}
 */
proto.segments.RemoveTagsFromEventResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.RemoveTagsFromEventResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.RemoveTagsFromEventResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.RemoveTagsFromEventResponse.Status>} value
 * @return {!proto.segments.RemoveTagsFromEventResponse} returns this
*/
proto.segments.RemoveTagsFromEventResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.RemoveTagsFromEventResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromEventResponse.Status}
 */
proto.segments.RemoveTagsFromEventResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.RemoveTagsFromEventResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromEventResponse} returns this
 */
proto.segments.RemoveTagsFromEventResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToWorkSessionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToWorkSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToWorkSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToWorkSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    workSessionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToWorkSessionRequest}
 */
proto.segments.AddTagsToWorkSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToWorkSessionRequest;
  return proto.segments.AddTagsToWorkSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToWorkSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToWorkSessionRequest}
 */
proto.segments.AddTagsToWorkSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToWorkSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToWorkSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToWorkSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getWorkSessionId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.AddTagsToWorkSessionRequest} returns this
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.AddTagsToWorkSessionRequest} returns this
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToWorkSessionRequest} returns this
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 work_session_id = 2;
 * @return {number}
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.getWorkSessionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagsToWorkSessionRequest} returns this
 */
proto.segments.AddTagsToWorkSessionRequest.prototype.setWorkSessionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToWorkSessionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToWorkSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToWorkSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToWorkSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToWorkSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagsWorkSessionsList: jspb.Message.toObjectList(msg.getCompanyTagsWorkSessionsList(),
    api_dashboard_v2_segment_message_pb.CompanyTagsWorkSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToWorkSessionResponse}
 */
proto.segments.AddTagsToWorkSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToWorkSessionResponse;
  return proto.segments.AddTagsToWorkSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToWorkSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToWorkSessionResponse}
 */
proto.segments.AddTagsToWorkSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTagsWorkSession;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTagsWorkSession.deserializeBinaryFromReader);
      msg.addCompanyTagsWorkSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToWorkSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToWorkSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToWorkSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToWorkSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagsWorkSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTagsWorkSession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.CompanyTagsWorkSession company_tags_work_sessions = 1;
 * @return {!Array<!proto.segment_message.CompanyTagsWorkSession>}
 */
proto.segments.AddTagsToWorkSessionResponse.prototype.getCompanyTagsWorkSessionsList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTagsWorkSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTagsWorkSession, 1));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTagsWorkSession>} value
 * @return {!proto.segments.AddTagsToWorkSessionResponse} returns this
*/
proto.segments.AddTagsToWorkSessionResponse.prototype.setCompanyTagsWorkSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.CompanyTagsWorkSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTagsWorkSession}
 */
proto.segments.AddTagsToWorkSessionResponse.prototype.addCompanyTagsWorkSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.CompanyTagsWorkSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToWorkSessionResponse} returns this
 */
proto.segments.AddTagsToWorkSessionResponse.prototype.clearCompanyTagsWorkSessionsList = function() {
  return this.setCompanyTagsWorkSessionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromWorkSessionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromWorkSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromWorkSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromWorkSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    workSessionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromWorkSessionRequest}
 */
proto.segments.RemoveTagsFromWorkSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromWorkSessionRequest;
  return proto.segments.RemoveTagsFromWorkSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromWorkSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromWorkSessionRequest}
 */
proto.segments.RemoveTagsFromWorkSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromWorkSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromWorkSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromWorkSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getWorkSessionId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.RemoveTagsFromWorkSessionRequest} returns this
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromWorkSessionRequest} returns this
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromWorkSessionRequest} returns this
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 work_session_id = 2;
 * @return {number}
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.getWorkSessionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromWorkSessionRequest} returns this
 */
proto.segments.RemoveTagsFromWorkSessionRequest.prototype.setWorkSessionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromWorkSessionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromWorkSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromWorkSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.RemoveTagsFromWorkSessionResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromWorkSessionResponse;
  return proto.segments.RemoveTagsFromWorkSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.RemoveTagsFromWorkSessionResponse.Status;
      reader.readMessage(value,proto.segments.RemoveTagsFromWorkSessionResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromWorkSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromWorkSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.RemoveTagsFromWorkSessionResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromWorkSessionResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse.Status}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromWorkSessionResponse.Status;
  return proto.segments.RemoveTagsFromWorkSessionResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse.Status}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromWorkSessionResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse.Status} returns this
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse.Status} returns this
 */
proto.segments.RemoveTagsFromWorkSessionResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.RemoveTagsFromWorkSessionResponse.Status>}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.RemoveTagsFromWorkSessionResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.RemoveTagsFromWorkSessionResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.RemoveTagsFromWorkSessionResponse.Status>} value
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse} returns this
*/
proto.segments.RemoveTagsFromWorkSessionResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.RemoveTagsFromWorkSessionResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse.Status}
 */
proto.segments.RemoveTagsFromWorkSessionResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.RemoveTagsFromWorkSessionResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromWorkSessionResponse} returns this
 */
proto.segments.RemoveTagsFromWorkSessionResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagToUserRequest}
 */
proto.segments.AddTagToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagToUserRequest;
  return proto.segments.AddTagToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagToUserRequest}
 */
proto.segments.AddTagToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyTagId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_tag_id = 1;
 * @return {number}
 */
proto.segments.AddTagToUserRequest.prototype.getCompanyTagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagToUserRequest} returns this
 */
proto.segments.AddTagToUserRequest.prototype.setCompanyTagId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.segments.AddTagToUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagToUserRequest} returns this
 */
proto.segments.AddTagToUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagToUsersRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagToUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagToUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagToUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagToUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagToUsersRequest}
 */
proto.segments.AddTagToUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagToUsersRequest;
  return proto.segments.AddTagToUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagToUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagToUsersRequest}
 */
proto.segments.AddTagToUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyTagId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagToUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagToUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagToUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagToUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_tag_id = 1;
 * @return {number}
 */
proto.segments.AddTagToUsersRequest.prototype.getCompanyTagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagToUsersRequest} returns this
 */
proto.segments.AddTagToUsersRequest.prototype.setCompanyTagId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 user_ids = 2;
 * @return {!Array<number>}
 */
proto.segments.AddTagToUsersRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.AddTagToUsersRequest} returns this
 */
proto.segments.AddTagToUsersRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.AddTagToUsersRequest} returns this
 */
proto.segments.AddTagToUsersRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagToUsersRequest} returns this
 */
proto.segments.AddTagToUsersRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagToUserRequest}
 */
proto.segments.RemoveTagToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagToUserRequest;
  return proto.segments.RemoveTagToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagToUserRequest}
 */
proto.segments.RemoveTagToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyTagId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_tag_id = 1;
 * @return {number}
 */
proto.segments.RemoveTagToUserRequest.prototype.getCompanyTagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagToUserRequest} returns this
 */
proto.segments.RemoveTagToUserRequest.prototype.setCompanyTagId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.segments.RemoveTagToUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagToUserRequest} returns this
 */
proto.segments.RemoveTagToUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToUserRequest}
 */
proto.segments.AddTagsToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToUserRequest;
  return proto.segments.AddTagsToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToUserRequest}
 */
proto.segments.AddTagsToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.AddTagsToUserRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.AddTagsToUserRequest} returns this
 */
proto.segments.AddTagsToUserRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.AddTagsToUserRequest} returns this
 */
proto.segments.AddTagsToUserRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToUserRequest} returns this
 */
proto.segments.AddTagsToUserRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.segments.AddTagsToUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagsToUserRequest} returns this
 */
proto.segments.AddTagsToUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagsUsersList: jspb.Message.toObjectList(msg.getCompanyTagsUsersList(),
    api_dashboard_v2_segment_message_pb.CompanyTagsUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToUserResponse}
 */
proto.segments.AddTagsToUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToUserResponse;
  return proto.segments.AddTagsToUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToUserResponse}
 */
proto.segments.AddTagsToUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTagsUser;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTagsUser.deserializeBinaryFromReader);
      msg.addCompanyTagsUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagsUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTagsUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.CompanyTagsUser company_tags_users = 1;
 * @return {!Array<!proto.segment_message.CompanyTagsUser>}
 */
proto.segments.AddTagsToUserResponse.prototype.getCompanyTagsUsersList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTagsUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTagsUser, 1));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTagsUser>} value
 * @return {!proto.segments.AddTagsToUserResponse} returns this
*/
proto.segments.AddTagsToUserResponse.prototype.setCompanyTagsUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.CompanyTagsUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTagsUser}
 */
proto.segments.AddTagsToUserResponse.prototype.addCompanyTagsUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.CompanyTagsUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToUserResponse} returns this
 */
proto.segments.AddTagsToUserResponse.prototype.clearCompanyTagsUsersList = function() {
  return this.setCompanyTagsUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromUserRequest}
 */
proto.segments.RemoveTagsFromUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromUserRequest;
  return proto.segments.RemoveTagsFromUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromUserRequest}
 */
proto.segments.RemoveTagsFromUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.RemoveTagsFromUserRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.RemoveTagsFromUserRequest} returns this
 */
proto.segments.RemoveTagsFromUserRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromUserRequest} returns this
 */
proto.segments.RemoveTagsFromUserRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromUserRequest} returns this
 */
proto.segments.RemoveTagsFromUserRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.segments.RemoveTagsFromUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromUserRequest} returns this
 */
proto.segments.RemoveTagsFromUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.RemoveTagsFromUserResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromUserResponse}
 */
proto.segments.RemoveTagsFromUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromUserResponse;
  return proto.segments.RemoveTagsFromUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromUserResponse}
 */
proto.segments.RemoveTagsFromUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.RemoveTagsFromUserResponse.Status;
      reader.readMessage(value,proto.segments.RemoveTagsFromUserResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.RemoveTagsFromUserResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromUserResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromUserResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromUserResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromUserResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromUserResponse.Status}
 */
proto.segments.RemoveTagsFromUserResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromUserResponse.Status;
  return proto.segments.RemoveTagsFromUserResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromUserResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromUserResponse.Status}
 */
proto.segments.RemoveTagsFromUserResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromUserResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromUserResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromUserResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromUserResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.RemoveTagsFromUserResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromUserResponse.Status} returns this
 */
proto.segments.RemoveTagsFromUserResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.RemoveTagsFromUserResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.RemoveTagsFromUserResponse.Status} returns this
 */
proto.segments.RemoveTagsFromUserResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.RemoveTagsFromUserResponse.Status>}
 */
proto.segments.RemoveTagsFromUserResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.RemoveTagsFromUserResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.RemoveTagsFromUserResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.RemoveTagsFromUserResponse.Status>} value
 * @return {!proto.segments.RemoveTagsFromUserResponse} returns this
*/
proto.segments.RemoveTagsFromUserResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.RemoveTagsFromUserResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromUserResponse.Status}
 */
proto.segments.RemoveTagsFromUserResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.RemoveTagsFromUserResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromUserResponse} returns this
 */
proto.segments.RemoveTagsFromUserResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToDashboardUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToDashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToDashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToDashboardUserRequest}
 */
proto.segments.AddTagsToDashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToDashboardUserRequest;
  return proto.segments.AddTagsToDashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToDashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToDashboardUserRequest}
 */
proto.segments.AddTagsToDashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDashboardUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToDashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToDashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.AddTagsToDashboardUserRequest} returns this
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.AddTagsToDashboardUserRequest} returns this
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToDashboardUserRequest} returns this
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 dashboard_user_id = 2;
 * @return {number}
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagsToDashboardUserRequest} returns this
 */
proto.segments.AddTagsToDashboardUserRequest.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToDashboardUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToDashboardUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToDashboardUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToDashboardUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDashboardUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagsDashboardUsersList: jspb.Message.toObjectList(msg.getCompanyTagsDashboardUsersList(),
    api_dashboard_v2_segment_message_pb.CompanyTagsDashboardUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToDashboardUserResponse}
 */
proto.segments.AddTagsToDashboardUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToDashboardUserResponse;
  return proto.segments.AddTagsToDashboardUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToDashboardUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToDashboardUserResponse}
 */
proto.segments.AddTagsToDashboardUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTagsDashboardUser;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTagsDashboardUser.deserializeBinaryFromReader);
      msg.addCompanyTagsDashboardUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToDashboardUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToDashboardUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToDashboardUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDashboardUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagsDashboardUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTagsDashboardUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.CompanyTagsDashboardUser company_tags_dashboard_users = 1;
 * @return {!Array<!proto.segment_message.CompanyTagsDashboardUser>}
 */
proto.segments.AddTagsToDashboardUserResponse.prototype.getCompanyTagsDashboardUsersList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTagsDashboardUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTagsDashboardUser, 1));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTagsDashboardUser>} value
 * @return {!proto.segments.AddTagsToDashboardUserResponse} returns this
*/
proto.segments.AddTagsToDashboardUserResponse.prototype.setCompanyTagsDashboardUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.CompanyTagsDashboardUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTagsDashboardUser}
 */
proto.segments.AddTagsToDashboardUserResponse.prototype.addCompanyTagsDashboardUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.CompanyTagsDashboardUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToDashboardUserResponse} returns this
 */
proto.segments.AddTagsToDashboardUserResponse.prototype.clearCompanyTagsDashboardUsersList = function() {
  return this.setCompanyTagsDashboardUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromDashboardUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromDashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromDashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromDashboardUserRequest}
 */
proto.segments.RemoveTagsFromDashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromDashboardUserRequest;
  return proto.segments.RemoveTagsFromDashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromDashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromDashboardUserRequest}
 */
proto.segments.RemoveTagsFromDashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDashboardUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromDashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromDashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.RemoveTagsFromDashboardUserRequest} returns this
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromDashboardUserRequest} returns this
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromDashboardUserRequest} returns this
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 dashboard_user_id = 2;
 * @return {number}
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromDashboardUserRequest} returns this
 */
proto.segments.RemoveTagsFromDashboardUserRequest.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromDashboardUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromDashboardUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDashboardUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.RemoveTagsFromDashboardUserResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromDashboardUserResponse;
  return proto.segments.RemoveTagsFromDashboardUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.RemoveTagsFromDashboardUserResponse.Status;
      reader.readMessage(value,proto.segments.RemoveTagsFromDashboardUserResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromDashboardUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDashboardUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.RemoveTagsFromDashboardUserResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromDashboardUserResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse.Status}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromDashboardUserResponse.Status;
  return proto.segments.RemoveTagsFromDashboardUserResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse.Status}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromDashboardUserResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse.Status} returns this
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse.Status} returns this
 */
proto.segments.RemoveTagsFromDashboardUserResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.RemoveTagsFromDashboardUserResponse.Status>}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.RemoveTagsFromDashboardUserResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.RemoveTagsFromDashboardUserResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.RemoveTagsFromDashboardUserResponse.Status>} value
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse} returns this
*/
proto.segments.RemoveTagsFromDashboardUserResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.RemoveTagsFromDashboardUserResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse.Status}
 */
proto.segments.RemoveTagsFromDashboardUserResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.RemoveTagsFromDashboardUserResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromDashboardUserResponse} returns this
 */
proto.segments.RemoveTagsFromDashboardUserResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToDeviceRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToDeviceRequest}
 */
proto.segments.AddTagsToDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToDeviceRequest;
  return proto.segments.AddTagsToDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToDeviceRequest}
 */
proto.segments.AddTagsToDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.AddTagsToDeviceRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.AddTagsToDeviceRequest} returns this
 */
proto.segments.AddTagsToDeviceRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.AddTagsToDeviceRequest} returns this
 */
proto.segments.AddTagsToDeviceRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToDeviceRequest} returns this
 */
proto.segments.AddTagsToDeviceRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 device_id = 2;
 * @return {number}
 */
proto.segments.AddTagsToDeviceRequest.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.AddTagsToDeviceRequest} returns this
 */
proto.segments.AddTagsToDeviceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.AddTagsToDeviceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.AddTagsToDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.AddTagsToDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.AddTagsToDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagsDevicesList: jspb.Message.toObjectList(msg.getCompanyTagsDevicesList(),
    api_dashboard_v2_segment_message_pb.CompanyTagsDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.AddTagsToDeviceResponse}
 */
proto.segments.AddTagsToDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.AddTagsToDeviceResponse;
  return proto.segments.AddTagsToDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.AddTagsToDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.AddTagsToDeviceResponse}
 */
proto.segments.AddTagsToDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTagsDevice;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTagsDevice.deserializeBinaryFromReader);
      msg.addCompanyTagsDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.AddTagsToDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.AddTagsToDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.AddTagsToDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.AddTagsToDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagsDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTagsDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated segment_message.CompanyTagsDevice company_tags_devices = 1;
 * @return {!Array<!proto.segment_message.CompanyTagsDevice>}
 */
proto.segments.AddTagsToDeviceResponse.prototype.getCompanyTagsDevicesList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTagsDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTagsDevice, 1));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTagsDevice>} value
 * @return {!proto.segments.AddTagsToDeviceResponse} returns this
*/
proto.segments.AddTagsToDeviceResponse.prototype.setCompanyTagsDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segment_message.CompanyTagsDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTagsDevice}
 */
proto.segments.AddTagsToDeviceResponse.prototype.addCompanyTagsDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segment_message.CompanyTagsDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.AddTagsToDeviceResponse} returns this
 */
proto.segments.AddTagsToDeviceResponse.prototype.clearCompanyTagsDevicesList = function() {
  return this.setCompanyTagsDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromDeviceRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyTagIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromDeviceRequest}
 */
proto.segments.RemoveTagsFromDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromDeviceRequest;
  return proto.segments.RemoveTagsFromDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromDeviceRequest}
 */
proto.segments.RemoveTagsFromDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanyTagIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyTagIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 company_tag_ids = 1;
 * @return {!Array<number>}
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.getCompanyTagIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.segments.RemoveTagsFromDeviceRequest} returns this
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.setCompanyTagIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromDeviceRequest} returns this
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.addCompanyTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromDeviceRequest} returns this
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.clearCompanyTagIdsList = function() {
  return this.setCompanyTagIdsList([]);
};


/**
 * optional int32 device_id = 2;
 * @return {number}
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromDeviceRequest} returns this
 */
proto.segments.RemoveTagsFromDeviceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.segments.RemoveTagsFromDeviceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.segments.RemoveTagsFromDeviceResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromDeviceResponse}
 */
proto.segments.RemoveTagsFromDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromDeviceResponse;
  return proto.segments.RemoveTagsFromDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromDeviceResponse}
 */
proto.segments.RemoveTagsFromDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.segments.RemoveTagsFromDeviceResponse.Status;
      reader.readMessage(value,proto.segments.RemoveTagsFromDeviceResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.segments.RemoveTagsFromDeviceResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.segments.RemoveTagsFromDeviceResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.segments.RemoveTagsFromDeviceResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.segments.RemoveTagsFromDeviceResponse.Status}
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.segments.RemoveTagsFromDeviceResponse.Status;
  return proto.segments.RemoveTagsFromDeviceResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.segments.RemoveTagsFromDeviceResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.segments.RemoveTagsFromDeviceResponse.Status}
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.segments.RemoveTagsFromDeviceResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.segments.RemoveTagsFromDeviceResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.segments.RemoveTagsFromDeviceResponse.Status} returns this
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.segments.RemoveTagsFromDeviceResponse.Status} returns this
 */
proto.segments.RemoveTagsFromDeviceResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.segments.RemoveTagsFromDeviceResponse.Status>}
 */
proto.segments.RemoveTagsFromDeviceResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.segments.RemoveTagsFromDeviceResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.segments.RemoveTagsFromDeviceResponse.Status, 1));
};


/**
 * @param {!Array<!proto.segments.RemoveTagsFromDeviceResponse.Status>} value
 * @return {!proto.segments.RemoveTagsFromDeviceResponse} returns this
*/
proto.segments.RemoveTagsFromDeviceResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.segments.RemoveTagsFromDeviceResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segments.RemoveTagsFromDeviceResponse.Status}
 */
proto.segments.RemoveTagsFromDeviceResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.segments.RemoveTagsFromDeviceResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.segments.RemoveTagsFromDeviceResponse} returns this
 */
proto.segments.RemoveTagsFromDeviceResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


goog.object.extend(exports, proto.segments);
