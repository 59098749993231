import React, { useState } from 'react';
import { COLORS } from 'constants/colors';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import CustomInput from 'lib/ui/CustomInput';
import CustomCheckbox from 'lib/ui/CustomCheckbox/CustomCheckbox';
import { text } from 'assets/jss/style';
import { productTitle, productTitleTop, sectionWrapper, soterItem, } from './styles';
import { accordionHandler } from '../utils';
const SoterTaskSettings = ({ company, editMode, formik, t, }) => {
    const [isOpenAccordion, setIsOpenAccordion] = useState(true);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: sectionWrapper, onClick: () => accordionHandler(isOpenAccordion, setIsOpenAccordion) },
            React.createElement("h3", { className: text(COLORS.GRAY[950], 24, 800) }, t('ADMIN.SOTER_CONFIGURATION', 'Task assessment. Configuration')),
            React.createElement(ArrowDownLineIcon, { className: isOpenAccordion ? '' : 'hidden' })),
        React.createElement("div", { className: `${soterItem} ${isOpenAccordion ? '' : 'hidden'}` },
            React.createElement("h3", { className: productTitleTop, style: { width: '100%' } }, t('SOTER_TASK.RISKS.GENERAL')),
            editMode ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "left" },
                    React.createElement("h4", { className: productTitle }, "Video details"),
                    React.createElement("div", { style: { width: '260px', marginBottom: 12 } },
                        React.createElement("label", null, `${t('ADMIN.VIDEO_DURATION')} (${t('ADMIN.MIN')})`),
                        React.createElement(CustomInput, { type: "int", min: 0, max: 1000, name: "videoDuration", placeholder: `${t('SOTER_TASK.VIDEOS_LIST.DURATION')} (${t('TIME.SINCE.SECONDS')})`, formik: formik }))),
                React.createElement("div", { className: "right" },
                    React.createElement("h4", { className: productTitle }, t('ADMIN.OTHER')),
                    React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } },
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.BLUR_PEOPLE_FACES', 'Blur people faces'), name: "blurFaceEnable", formik: formik })),
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.BLUR_VIDEO_BACKGROUND', 'Blur video background'), name: "blurBackgroundEnable", formik: formik })),
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.ENABLE_OFFICE_ASSESSMENT', 'Enable office assessment'), name: "officeAssessment", formik: formik })))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "left" },
                    React.createElement("h4", { className: productTitle }, "Video details"),
                    React.createElement("div", { className: "subtitle" }, t('ADMIN.VIDEO_DURATION', 'Video duration')),
                    React.createElement("div", { className: "title" }, `${company.videoDuration} ${t('ADMIN.MIN')}`)),
                React.createElement("div", { className: "right" },
                    React.createElement("h4", { className: productTitle }, t('ADMIN.OTHER')),
                    React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } },
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.BLUR_PEOPLE_FACES', 'Blur people faces'), checked: company.blurFaceEnable, disabled: true })),
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.BLUR_VIDEO_BACKGROUND', 'Blur video background'), checked: company.blurBackgroundEnable, disabled: true })),
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.ENABLE_OFFICE_ASSESSMENT', 'Enable office assessment'), checked: company.officeAssessment, disabled: true })))))))));
};
export default SoterTaskSettings;
