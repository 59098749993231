import * as Emotion from 'emotion';
import _reverse from 'lodash/reverse';
import React from 'react';
import Tooltip from 'lib/ui/Tooltip';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { cardMediumWithHeight } from '../../assets/soter-flex-styles';
import infoIcon from '../../assets/img/icons/info.svg';
import UnitSelectionString from './UnitSelectionString';
import AngleLabel from './AngleLabel';
import PieChart from './PieChart';
import hands from '../../assets/img/newArmPerson.png';
import { backButton } from './SpineChart';

const ShoulderChart = ({
  setShouldersChartState,
  shouldersChartState,
  unitList,
  chartTypes,
  getShouldersData,
  goBack,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ width: '564px', height: '690px' }}
      className={`${cardMediumWithHeight(690)} `}
    >
      <div
        style={{
          fontSize: '20px',
          color: COLORS.GRAY[950],
          padding: '8px 0px 0px 23px',
        }}
      >
        {t('WEARABLES.SHOULDER')}
      </div>
      <div style={{ position: 'absolute', left: '520px', top: '11px' }}>
        <Tooltip
          text={t('JOB_DICTIONARY.JOB_PROFILE.ILLUSTRATED')}
          placement="bottomEnd"
          openOnClick
        >
          <img
            style={{ width: '24px', height: '24px' }}
            src={infoIcon}
            alt="i"
          />
        </Tooltip>
      </div>
      <div className={backButton} onClick={() => goBack()}>
        {t('JOB_DICTIONARY.JOB_PROFILE.GO_BACK').toUpperCase()}
      </div>
      <UnitSelectionString
        setTypeState={(type) => setShouldersChartState({ ...shouldersChartState, type })}
        setUnitState={(unit) => setShouldersChartState({ ...shouldersChartState, unit })}
        typeState={shouldersChartState.type}
        typeList={chartTypes}
        unitList={unitList}
      />
      <div
        className={Emotion.css`
           {
            margin-left: 42px;
            margin-top: 12px;
            position: relative;
          }
        `}
      >
        <AngleLabel position="top" angle={180} />
        <AngleLabel position="bottom" angle={0} />
        <AngleLabel position="middle" angle={90} />
        <AngleLabel position="middleTop" angle={150} />
        <AngleLabel position="highMiddle" angle={120} />
        <AngleLabel position="lowMiddle" angle={60} />
        <AngleLabel position="middleBottom" angle={30} />
        <PieChart
          chartId="pie_shoulder"
          containerHeight={480}
          data={_reverse([...getShouldersData()])}
          dataType={shouldersChartState.type}
          unit={shouldersChartState.unit === 'time' ? 'percent' : 'amount'}
        />
        <img
          src={hands}
          width={282}
          height={465}
          style={{
            position: 'absolute',
            zIndex: 0,
            top: '37px',
            left: '-7px',
          }}
          alt="pic"
        />
      </div>
    </div>
  );
};

export default ShoulderChart;
