export const SESSION_TIME = 60;
export const INTERVAL_TIME = 240;
export const TARGET_DAYS = 10;
export const VIDEO_DURATION_MIN = 2;
export const PRODUCT_INFO = {
    isAvailable: false,
    timeStart: null,
    timeEnd: null,
    typeOfAccess: '',
};
export const ERRORS = {
    wearableStart: '',
    wearableEnd: '',
    soterTaskStart: '',
    soterTaskEnd: '',
    jobDictionaryStart: '',
    jobDictionaryEnd: '',
    copilotStart: '',
    copilotEnd: '',
};
