export const hazardsEnum = {
  spine: {
    repetition: 'WEARABLES.HAZARDS.REPETITION',
    intensity: 'WEARABLES.HAZARDS.INTENSITY',
    pbStatic: 'WEARABLES.HAZARDS.STATIC',
    twisting: 'WEARABLES.HAZARDS.TWISTING',
    lifting: 'WEARABLES.HAZARDS.LIFTING',
  },
  shoulder: {
    overexertion: 'WEARABLES.HAZARDS.OVER',
    pushingPulling: 'WEARABLES.HAZARDS.PUSHING',
    staticArmElevation: 'WEARABLES.HAZARDS.STATIC_ELEVATION',
    repetitiveArmMovements: 'WEARABLES.HAZARDS.REPETITIVE',
    armElevation: 'WEARABLES.HAZARDS.ELEVATION',
  },
};
