import * as Emotion from 'emotion';

export const badge = Emotion.css`{
  display: inline-block;
  width: 12px;
  height: 12px;
  
  svg {
    width: 100%;
    height: 100%;
  }
}`;

export const medium = Emotion.css`{
  width: 20px;
  height: 20px;
}`;
