/**
 * @fileoverview gRPC-Web generated client stub for admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/admin_hub_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_admin_hub_message_pb = require('../../../api/dashboard/v2/admin_hub_message_pb.js')
const proto = {};
proto.admin = require('./admin_hub_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin.HubServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin.HubServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.GetHubsRequest,
 *   !proto.admin.GetHubsResponse>}
 */
const methodDescriptor_HubService_GetHubs = new grpc.web.MethodDescriptor(
  '/admin.HubService/GetHubs',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_hub_message_pb.GetHubsRequest,
  api_dashboard_v2_admin_hub_message_pb.GetHubsResponse,
  /**
   * @param {!proto.admin.GetHubsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_hub_message_pb.GetHubsResponse.deserializeBinary
);


/**
 * @param {!proto.admin.GetHubsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.GetHubsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.GetHubsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.HubServiceClient.prototype.getHubs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.HubService/GetHubs',
      request,
      metadata || {},
      methodDescriptor_HubService_GetHubs,
      callback);
};


/**
 * @param {!proto.admin.GetHubsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.GetHubsResponse>}
 *     Promise that resolves to the response
 */
proto.admin.HubServicePromiseClient.prototype.getHubs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.HubService/GetHubs',
      request,
      metadata || {},
      methodDescriptor_HubService_GetHubs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.UpdateHubRequest,
 *   !proto.admin.Hub>}
 */
const methodDescriptor_HubService_UpdateHub = new grpc.web.MethodDescriptor(
  '/admin.HubService/UpdateHub',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_hub_message_pb.UpdateHubRequest,
  api_dashboard_v2_admin_hub_message_pb.Hub,
  /**
   * @param {!proto.admin.UpdateHubRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_hub_message_pb.Hub.deserializeBinary
);


/**
 * @param {!proto.admin.UpdateHubRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.Hub)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.Hub>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.HubServiceClient.prototype.updateHub =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.HubService/UpdateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_UpdateHub,
      callback);
};


/**
 * @param {!proto.admin.UpdateHubRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.Hub>}
 *     Promise that resolves to the response
 */
proto.admin.HubServicePromiseClient.prototype.updateHub =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.HubService/UpdateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_UpdateHub);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.CreateHubRequest,
 *   !proto.admin.Hub>}
 */
const methodDescriptor_HubService_CreateHub = new grpc.web.MethodDescriptor(
  '/admin.HubService/CreateHub',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_hub_message_pb.CreateHubRequest,
  api_dashboard_v2_admin_hub_message_pb.Hub,
  /**
   * @param {!proto.admin.CreateHubRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_hub_message_pb.Hub.deserializeBinary
);


/**
 * @param {!proto.admin.CreateHubRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.Hub)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.Hub>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.HubServiceClient.prototype.createHub =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.HubService/CreateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_CreateHub,
      callback);
};


/**
 * @param {!proto.admin.CreateHubRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.Hub>}
 *     Promise that resolves to the response
 */
proto.admin.HubServicePromiseClient.prototype.createHub =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.HubService/CreateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_CreateHub);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.HubRequest,
 *   !proto.admin.HubResponse>}
 */
const methodDescriptor_HubService_DeleteHub = new grpc.web.MethodDescriptor(
  '/admin.HubService/DeleteHub',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_hub_message_pb.HubRequest,
  api_dashboard_v2_admin_hub_message_pb.HubResponse,
  /**
   * @param {!proto.admin.HubRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_hub_message_pb.HubResponse.deserializeBinary
);


/**
 * @param {!proto.admin.HubRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.HubResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.HubResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.HubServiceClient.prototype.deleteHub =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.HubService/DeleteHub',
      request,
      metadata || {},
      methodDescriptor_HubService_DeleteHub,
      callback);
};


/**
 * @param {!proto.admin.HubRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.HubResponse>}
 *     Promise that resolves to the response
 */
proto.admin.HubServicePromiseClient.prototype.deleteHub =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.HubService/DeleteHub',
      request,
      metadata || {},
      methodDescriptor_HubService_DeleteHub);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.HubRequest,
 *   !proto.admin.HubResponse>}
 */
const methodDescriptor_HubService_ActivateHub = new grpc.web.MethodDescriptor(
  '/admin.HubService/ActivateHub',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_hub_message_pb.HubRequest,
  api_dashboard_v2_admin_hub_message_pb.HubResponse,
  /**
   * @param {!proto.admin.HubRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_hub_message_pb.HubResponse.deserializeBinary
);


/**
 * @param {!proto.admin.HubRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.HubResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.HubResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.HubServiceClient.prototype.activateHub =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.HubService/ActivateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_ActivateHub,
      callback);
};


/**
 * @param {!proto.admin.HubRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.HubResponse>}
 *     Promise that resolves to the response
 */
proto.admin.HubServicePromiseClient.prototype.activateHub =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.HubService/ActivateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_ActivateHub);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.HubRequest,
 *   !proto.admin.HubResponse>}
 */
const methodDescriptor_HubService_DeactivateHub = new grpc.web.MethodDescriptor(
  '/admin.HubService/DeactivateHub',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_hub_message_pb.HubRequest,
  api_dashboard_v2_admin_hub_message_pb.HubResponse,
  /**
   * @param {!proto.admin.HubRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_hub_message_pb.HubResponse.deserializeBinary
);


/**
 * @param {!proto.admin.HubRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.HubResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.HubResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.HubServiceClient.prototype.deactivateHub =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.HubService/DeactivateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_DeactivateHub,
      callback);
};


/**
 * @param {!proto.admin.HubRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.HubResponse>}
 *     Promise that resolves to the response
 */
proto.admin.HubServicePromiseClient.prototype.deactivateHub =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.HubService/DeactivateHub',
      request,
      metadata || {},
      methodDescriptor_HubService_DeactivateHub);
};


module.exports = proto.admin;

