import { COLORS } from 'constants/colors';
import arrowDown from '../../../../assets/img/icons/arrows/arrow-down.svg';
import arrowUp from '../../../../assets/img/icons/arrows/arrow-up.svg';

// eslint-disable-next-line import/prefer-default-export
export const getRatingProps = (ratingChange, isClipGo, isRatio = false) => {
  if (!isClipGo && !ratingChange) {
    return {
      color: COLORS.GRAY[300],
      withPercent: true,
      rating: ratingChange,
    };
  }
  if (isClipGo) {
    return {
      color: COLORS.BLUE[600],
      rating: ratingChange,
      withPercent: !isRatio,
    };
  }
  if (!isClipGo && ratingChange < 0) {
    return {
      color: COLORS.GREEN[600],
      icon: arrowDown,
      iconName: 'arrow-down',
      rating: ratingChange,
      withPercent: true,
    };
  }
  return {
    color: COLORS.ORANGE[600],
    icon: arrowUp,
    iconName: 'arrow-up',
    rating: ratingChange,
    withPercent: true,
  };
};
