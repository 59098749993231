import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import Button from 'lib/ui/Button';
import { coloredButton, darkTitleText, mt5, smallMutedText, } from '../../assets/soter-flex-styles';

const ResetPasswordDialog = ({ name, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4 className={`${darkTitleText}`}>
        {`${t('MY_COMPANY.USERS.RESET_FOR')} ${name}?`}
      </h4>
      <span className={smallMutedText}>{t('MY_COMPANY.USERS.USER_WILL')}</span>
      <Button
        className={`${coloredButton(COLORS.LIGHT_BLUE[600], 'small')} ${mt5}`}
        type="button"
        handler={onSubmit}
        text={t('MY_COMPANY.USERS.RESET_THE')}
      />
    </>
  );
};

export default ResetPasswordDialog;
