import { ReactComponent as Briefcase } from '../../../assets/img/icons/navigation/briefcase.svg';
import { ReactComponent as Devices } from '../../../assets/img/icons/navigation/devices.svg';
import { ReactComponent as Home } from '../../../assets/img/icons/navigation/home.svg';
import { ReactComponent as Programs } from '../../../assets/img/icons/navigation/programs.svg';
import { ReactComponent as User } from '../../../assets/img/icons/navigation/user.svg';
import { ReactComponent as Task } from '../../../assets/img/icons/navigation/task.svg';
import { ReactComponent as Gallery } from '../../../assets/img/icons/navigation/gallery.svg';
import { ReactComponent as Compare } from '../../../assets/img/icons/navigation/compare.svg';
import { ReactComponent as Wearables } from '../../../assets/img/icons/navigation/wearables.svg';
import { ReactComponent as Help } from '../../../assets/img/icons/navigation/help.svg';
import { ReactComponent as Box } from '../../../assets/img/icons/navigation/box.svg';
import { ReactComponent as Chat } from '../../../assets/img/icons/navigation/chat.svg';
import { ReactComponent as Feedback } from '../../../assets/img/icons/navigation/feedback.svg';
import { ReactComponent as Categories } from '../../../assets/img/icons/my_company/categories.svg';
import { routes } from './routes';

export const navSettingsRegular = [
  {
    name: 'NAVBAR.WEARABLES',
    to: `/${routes.users}/${routes.wearables}/${routes.overview}`,
    icon: Wearables,
  },
  {
    name: 'NAVBAR.JOB_DICTIONARY',
    to: `/${routes.users}/${routes.jobs}`,
    icon: Briefcase,
  },
  {
    name: 'NAVBAR.SOTER_GENIUS',
    to: `/${routes.users}/${routes.genius}/${routes.chat}`,
    icon: Chat,
  }
];

export const wearableNavSettings = [
  {
    name: 'NAVBAR.OVERVIEW',
    to: `/${routes.users}/${routes.wearables}/${routes.overview}`,
    icon: Home,
  },
  {
    name: 'NAVBAR.ACTIVITY',
    to: `/${routes.users}/${routes.wearables}/${routes.activity}`,
    icon: Programs,
  },
  {
    name: 'NAVBAR.NEED_ACTION',
    to: `/${routes.users}/${routes.wearables}/${routes.help}`,
    activeQuantity: 'helpRequests',
    icon: Help,
  },
  {
    name: 'NAVBAR.DEVICES',
    to: `/${routes.users}/${routes.wearables}/${routes.devices}`,
    icon: Devices,
  },
];

export const soterGeniusNavSettings = [
  {
    // type: 'dropdown',
    // name: 'NAVBAR.CHAT',
    // links: [
      // {
        name: 'NAVBAR.CHAT',
        to: `/${routes.users}/${routes.genius}/${routes.chat}`,
      // },
      // {
      //   name: 'NAVBAR.PROFILE',
      //   to: `/${routes.users}/${routes.genius}/${routes.profile}`,
      //   icon: Gallery,
      // },
    // ]
  },
  {
    type: 'dropdown',
    name: 'NAVBAR.TASK_ASSESSMENT',
    links: [
      {
        name: 'NAVBAR.OVERVIEW',
        to: `/${routes.users}/${routes.genius}/${routes.overview}`,
      },
      {
        name: 'NAVBAR.GALLERY',
        to: `/${routes.users}/${routes.genius}/${routes.gallery}`,
      },
      {
        name: 'NAVBAR.COMPARISON',
        to: `/${routes.users}/${routes.genius}/${routes.comparison}`,
      },
    ]
  },
];

export const adminNavSettings = [
  {
    name: 'Companies',
    to: `/${routes.admin}/${routes.companies}`,
    icon: Briefcase,
  },
  {
    name: 'Dashboard Users',
    to: `/${routes.admin}/${routes.users}`,
    icon: User,
  },
  {
    name: 'Devices',
    to: `/${routes.admin}/${routes.devices}`,
    icon: Devices,
  },
  {
    name: 'Hubs',
    to: `/${routes.admin}/${routes.hubs}`,
    icon: Box,
  },
  {
    name: 'Feedbacks',
    to: `/${routes.admin}/${routes.feedbacks}`,
    icon: Feedback,
  },
];

export const myCompanyNav = [
  {
    name: 'NAVBAR.USERS',
    to: `/${routes.users}/${routes.company}/${routes.users}`,
    icon: User,
  },
  {
    name: 'NAVBAR.CATEGORIES',
    to: `/${routes.users}/${routes.company}/${routes.categories}`,
    icon: Categories,
  },
];
