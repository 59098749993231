import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import StaticNotification from '../../containers/NotificationContainer/components/StaticNotification';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
// @ts-ignore
import { ReactComponent as Lock } from '../../assets/img/icons/my_company/lock.svg';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import { getMultiSelectionChecked } from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { prepareSeparatedTitle } from '../utils/preparedTitle';
import { buttonTypeAndSize } from '../../assets/jss/components/button';
import { setCurrentCategory } from '../categories/store/categorySlice';
import { deleteCategory } from '../categories/store/actions';
import { getActionHandlerForModal } from '../../lib/utils/getActionHandlerForModal';
import Loader from '../../lib/ui/Loader';
import { container } from '../../assets/soter-flex-styles';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import TitleChangeDialog from './TitleChangeDialog';
const CategoriesTable = ({ categories }) => {
    var _a;
    const [editedCategory, setEditedCategory] = useState(0);
    const { t } = useTranslation();
    const { selectedCompany } = useSelector((state) => state.companies);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.category.isLoading);
    const [openedModal, setOpenedModal] = useState('');
    const sortedCategories = Object.assign([], categories).sort((a) => (a.main ? -1 : 1));
    const closeModals = () => {
        setOpenedModal('');
    };
    const categoryOnClick = (name) => `./tags/${name === null || name === void 0 ? void 0 : name.replaceAll('/', '_')}`;
    const currentTitle = ((_a = categories === null || categories === void 0 ? void 0 : categories.find(({ id }) => editedCategory === id)) === null || _a === void 0 ? void 0 : _a.title) || '';
    const columns = (getActions) => getColumns(getMultiSelectionChecked, null, categoryOnClick, getActions, TableType.categories, t);
    const actions = [
        {
            title: t('MY_COMPANY.CATEGORIES.RENAME'),
            handler: (cat) => {
                setEditedCategory(cat.rowData.id);
                setOpenedModal('renameCategory');
            },
        },
        {
            title: t('MY_COMPANY.CATEGORIES.DELETE'),
            handler: (cat) => {
                setEditedCategory(cat.rowData.id);
                setOpenedModal('deleteCategory');
            },
            attention: true,
        },
    ];
    const cells = {
        number: (rowData) => (React.createElement("div", null, rowData.items.indexOf(rowData.rowData) + 1)),
        title: (rowData) => (React.createElement("div", { style: { display: 'flex' } }, prepareSeparatedTitle(rowData.rowData.title, t))),
        entries: (rowData) => (React.createElement("div", null,
            `${rowData.rowData.tagsList.length} `,
            rowData.rowData.tagsList.length === 1
                ? t('MY_COMPANY.CATEGORIES.ENTRY')
                : t('MY_COMPANY.CATEGORIES.ENTRIES'))),
        edit: (rowData) => (React.createElement(Link, { className: buttonTypeAndSize('secondary'), style: { width: 'min-content', textDecoration: 'none', whiteSpace: 'nowrap' }, onClick: () => dispatch(setCurrentCategory({
                id: rowData.rowData.id,
                title: rowData.rowData.title,
            })), to: categoryOnClick(rowData.rowData.title) }, t('MY_COMPANY.CATEGORIES.ADD_EDIT'))),
        actions: (rowData) => (rowData.rowData.main ? (React.createElement(Lock, null)) : (React.createElement(ActionCell, { rowData: rowData, actions: actions, userType: false, isTheOnlyVisibleItem: false }))),
    };
    const filterButton = {
        text: t('MY_COMPANY.CATEGORIES.CREATE_NEW'),
        action: () => {
            setOpenedModal('createCategory');
        },
    };
    const handleCloseModal = () => {
        setOpenedModal('');
    };
    const categoryTitleGetById = () => { var _a; return (_a = categories.find((c) => Number(c.id) === Number(editedCategory))) === null || _a === void 0 ? void 0 : _a.title; };
    const switchModal = (modalType) => {
        switch (modalType) {
            case 'deleteCategory':
                return (React.createElement(DeleteEntityDialog, { confirmText: t('MY_COMPANY.CATEGORIES.ARE_YOU'), buttonText: t('MY_COMPANY.CATEGORIES.DELETE'), onSubmit: () => {
                        dispatch(deleteCategory(editedCategory));
                        closeModals();
                    }, additionalText: false },
                    React.createElement(StaticNotification, { notification: {
                            status: 'TYPE_WARNING',
                            message: `${t('MY_COMPANY.CATEGORIES.YOU_TRY')}
               ${prepareSeparatedTitle(categoryTitleGetById() || '', t)} ${t('MY_COMPANY.CATEGORIES.WILL_ALSO')}`,
                        } })));
            case 'createCategory':
                return (React.createElement(TitleChangeDialog, { companyId: selectedCompany.id, categoryId: false, entryId: false, handlerFn: (e, companyId, title) => {
                        getActionHandlerForModal('createCategory', TableType.categories)(closeModals, dispatch)(e, companyId, title);
                    }, type: t('MY_COMPANY.CATEGORIES.CATEGORY'), userIds: false, subtitle: false, user: false, currentTitle: "" }));
            case 'renameCategory':
                return (React.createElement(TitleChangeDialog, { companyId: selectedCompany.id, categoryId: editedCategory, entryId: false, handlerFn: (e, companyId, categoryId, title) => {
                        getActionHandlerForModal('updateCategory', TableType.categories)(closeModals, dispatch)(e, companyId, categoryId, title);
                    }, type: t('MY_COMPANY.CATEGORIES.CATEGORY'), subtitle: t('MY_COMPANY.CATEGORIES.UPDATE'), userIds: false, user: false, currentTitle: currentTitle }));
            default:
                return null;
        }
    };
    return (React.createElement("div", { style: { display: 'block' } }, !categories || categories.length < 1 ? (React.createElement(Loader, null)) : (React.createElement("div", null,
        React.createElement("div", { className: container },
            React.createElement(NavigateBackButton, { name: t('MY_COMPANY.USERS.BACK_TO'), navigateTo: false, goBackStepHistory: true, style: { paddingTop: '10px' }, additionalHandler: false })),
        React.createElement(ItemTableContainer, { items: sortedCategories, columns: columns, actions: actions, isLoading: isLoading, type: TableType.categories, cells: cells, filterButtonProps: filterButton, openedModal: openedModal, setOpenedModal: setOpenedModal, switchModal: switchModal, onCloseModal: handleCloseModal, tableClass: false, overlayClass: false, modalClass: false, keepModalOnClickOutside: true, stickyOnScrollContainerTopBlock: false, stickyOnScrollContainerHeight: false })))));
};
export default CategoriesTable;
