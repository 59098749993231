import {
  createCategory,
  createEntry,
  deleteEntry,
  getCategories,
  updateCategory,
  updateEntry,
} from '../../company/categories/store/actions';
import {
  createUser,
} from '../../wearables/activity/createUserStore/actions';
import { notificationTypes, notificationMessages } from '../../containers/NotificationContainer/notifications';
import {
  createEventNotification,
  setNotification,
} from '../../containers/NotificationContainer/notificationsStore/actions';
import {
  CREATE_NEW_CATEGORY, USER_DEPARTMENT_CHANGE, USER_JOB_TITLE_CHANGE, CREATE_NEW_ENTRY,
} from '../../constants/analyticsEvents';
import {
  createDepartment, createSite, editDepartment, editSite,
} from '../../company/companiesStore/actions';
import { setUserNamesCG, setJobTitle, setDepartment } from '../../wearables/user/userStore/actions';
import { setUsersSite, setCoachJobTitle, setCoachDepartment } from '../../wearables/activity/usersStore/actions';
import { setDevicesDepartment, setDevicesSite } from '../../admin/devices/devicesStore/actions';

const actionMapByActionType = {
  coach: {
    setJobTitle: (toggleModalFn, dispatch) => (e, userIDs, title) => {
      const IDs = Array.isArray(userIDs) ? userIDs : [userIDs];
      e?.preventDefault();
      dispatch(setCoachJobTitle(IDs, title));
      toggleModalFn();
    },
    setDepartment: (toggleModalFn, dispatch) => (e, userIDs, departmentID) => {
      const IDs = Array.isArray(userIDs) ? userIDs : [userIDs];
      e?.preventDefault();
      dispatch(setCoachDepartment(IDs, departmentID));
      toggleModalFn();
    },
    setSite: (toggleModalFn, dispatch) => (e, userIDs, siteID) => {
      const IDs = Array.isArray(userIDs) ? userIDs : [userIDs];
      e?.preventDefault();
      dispatch(setUsersSite(IDs, siteID));
      toggleModalFn();
    },
    createUser: (toggleModalFn, dispatch) => (e, userData, companyId) => {
      e?.preventDefault();
      dispatch(createUser(userData, companyId));
    },
  },
  devices: {
    setDepartment: (toggleModalFn, dispatch) => (e, userIDs, departmentID) => {
      const IDs = Array.isArray(userIDs) ? userIDs : [userIDs];
      e?.preventDefault();
      dispatch(setDevicesDepartment(IDs, departmentID));
      toggleModalFn();
    },
    setSite: (toggleModalFn, dispatch) => (e, userIDs, siteID) => {
      const IDs = Array.isArray(userIDs) ? userIDs : [userIDs];
      e?.preventDefault();
      dispatch(setDevicesSite(IDs, siteID));
      toggleModalFn();
    },
  },
  user: {
    setJobTitle: (toggleModalFn, dispatch) => (e, userID, title) => {
      window.logEvent(USER_JOB_TITLE_CHANGE);
      e?.preventDefault();
      dispatch(setJobTitle(userID, title));
      toggleModalFn();
    },
    setDepartment: (toggleModalFn, dispatch) => (e, userID, departmentID) => {
      window.logEvent(USER_DEPARTMENT_CHANGE);
      e?.preventDefault();
      dispatch(setDepartment(+userID, +departmentID));
      toggleModalFn();
    },
    setName: (toggleModalFn, dispatch) => (e, userID, names) => {
      e?.preventDefault();
      dispatch(setUserNamesCG(+userID, names.firstName, names.lastName));
      toggleModalFn();
    },
    // eslint-disable-next-line no-unused-vars
    setNewProgram: (toggleModalFn, dispatch) => (e, userID, programID) => {
      e?.preventDefault();
      // dispatch(setNewProgram(userID, programID));
      toggleModalFn();
    },
    generateQR: (toggleModalFn, dispatch) => (e, userID, names) => {
      e?.preventDefault();
      dispatch(setUserNamesCG(+userID, names.firstName, names.lastName));
    },
  },
  categories: {
    createCategory:
      (toggleModalFn, dispatch) => (e, companyId, categoryId) => {
        e?.preventDefault();
        window.logEvent(CREATE_NEW_CATEGORY);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(CREATE_NEW_CATEGORY));
        }
        dispatch(createCategory({ companyId, title: categoryId }));
        toggleModalFn();
      },
    createEntries:
      (toggleModalFn, dispatch) => (e, companyId, categoryId, title) => {
        e?.preventDefault();
        window.logEvent(CREATE_NEW_ENTRY);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(CREATE_NEW_ENTRY));
        }
        const separatedTitles = categoryId.split(',' || ', ');
        separatedTitles.map((t) => dispatch(createEntry({ companyId, categoryId: title, title: t })));
        toggleModalFn();
      },
    createEntry:
      (toggleModalFn, dispatch) => (e, companyId, categoryId, title) => {
        e?.preventDefault();
        window.logEvent(CREATE_NEW_ENTRY);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(CREATE_NEW_ENTRY));
        }
        dispatch(
          createEntry({ companyId, categoryId: title, title: categoryId }),
        );
        toggleModalFn();
      },
    deleteEntries: (toggleModalFn, dispatch) => (e, entryId) => {
      e?.preventDefault();
      window.logEvent(CREATE_NEW_ENTRY);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(CREATE_NEW_ENTRY));
      }
      dispatch(deleteEntry({ entryId }));
      toggleModalFn();
    },
    updateCategory:
      (toggleModalFn, dispatch) => (e, companyId, editedCategory, title) => {
        e?.preventDefault();
        dispatch(
          updateCategory({
            title: editedCategory,
            companyId,
            categoryId: title,
          }),
        );
        toggleModalFn();
      },
    updateEntry:
      (toggleModalFn, dispatch) => (e, companyId, editedEntry, title, editedCategory) => {
        e?.preventDefault();
        dispatch(
          updateEntry({
            companyId,
            editedEntry,
            title,
            editedCategory,
          }),
        );
        toggleModalFn();
      },
    createDepartment:
      (toggleModalFn, dispatch) => (e, companyId, categoryId, title) => {
        e?.preventDefault();
        dispatch(createDepartment(companyId, title));
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.ENTRY_CREATED,
        }));
        dispatch(getCategories(companyId));
        toggleModalFn();
      },
    updateDepartment:
      (toggleModalFn, dispatch) => (e, companyId, editedCategory, title, editedEntry) => {
        e?.preventDefault();
        dispatch(
          editDepartment(
            editedCategory,
            title,
            companyId,
            'updateDepartment',
            editedEntry,
          ),
        );
        toggleModalFn();
      },
    updateSite:
      (toggleModalFn, dispatch) => (e, companyId, editedCategory, title, editedEntry) => {
        e?.preventDefault();
        dispatch(
          editSite(editedCategory, title, companyId, 'updateSite', editedEntry),
        );
        toggleModalFn();
      },
    createDepartments:
      (toggleModalFn, dispatch) => (e, companyId, title, editedCategory, editedEntry) => {
        e?.preventDefault();
        const separatedTitles = title.split(',' || ', ');
        separatedTitles.map((t) => dispatch(createDepartment(companyId, t, true)));
        toggleModalFn();
      },
    createSites: (toggleModalFn, dispatch) => (e, companyId, title) => {
      e?.preventDefault();
      const separatedTitles = title.split(',' || ', ');
      separatedTitles.map((t) => dispatch(createSite(companyId, t, true)));
      toggleModalFn();
    },
  },
};

export const getActionHandlerForModal = (action, itemType) => actionMapByActionType[itemType][action];
