import { COLORS } from '../../constants/colors';
import hexToRgba from '../../lib/utils/hexToRgba';

export default function getActivityCellColor(value, risk, frequency) {
  if (!value) return 'transparent';
  let color;
  switch (risk) {
    case 'Very High':
      color = COLORS.RED[600];
      break;
    case 'High':
      color = COLORS.ORANGE[600];
      break;
    case 'Moderate':
      color = COLORS.YELLOW[600];
      break;
    case 'Low':
      color = COLORS.GREEN[600];
      break;
    case 'None':
      color = COLORS.WHITE;
      break;
    default:
      return 'transparent';
  }
  return hexToRgba(color, 0.2);
}
