import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';

export const link = Emotion.css`
   {
    color: ${COLORS.GRAY[950]};
    text-decoration: none;
    :hover {
      text-decoration: none;
      color: ${COLORS.GRAY[950]};
    }
    :disabled {
      background-color: ${COLORS.WHITE} !important;
    }
  }
`;

export const clickableArea = Emotion.css`
   {
    margin: 0 8px;
    height: 38px;
    line-height: 38px;
     // TODO switch this to max z-index. move max z-index to project constants 
    z-index: 99999;
  }
`;
