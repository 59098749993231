import React from 'react';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import vibroOnIcon from '../../../../assets/img/icons/device/vibration/VibrationOn.svg';
import vibroOffIcon from '../../../../assets/img/icons/device/vibration/VibrationOff.svg';
import soundOnIcon from '../../../../assets/img/icons/device/volumeOn.svg';
import soundOffIcon from '../../../../assets/img/icons/device/volumeOff.svg';
import lowBattery from '../../../../assets/img/icons/device/battery/0-10.svg';
import lowMidBattery from '../../../../assets/img/icons/device/battery/10-25.svg';
import midBattery from '../../../../assets/img/icons/device/battery/25-50.svg';
import highMidBattery from '../../../../assets/img/icons/device/battery/50-75.svg';
import highBattery from '../../../../assets/img/icons/device/battery/75-95.svg';
import highestBattery from '../../../../assets/img/icons/device/battery/95-100.svg';
import Tooltip from '../../../../lib/ui/Tooltip';

// eslint-disable-next-line consistent-return
const switchBatteryIcon = (value) => {
  if (value <= 0) return lowBattery;
  if (value <= 10) return lowMidBattery;
  if (value > 10 && value <= 40) return midBattery;
  if (value > 40 && value <= 75) return highMidBattery;
  if (value > 75 && value < 95) return highBattery;
  if (value >= 95) return highestBattery;
};

const DeviceCell = ({ rowData, isClip }) => {
  const icon = switchBatteryIcon(rowData.device?.lastBatteryCharge);
  const { t } = useTranslation();
  return (
    <>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {rowData.isPassedProgramCopy ? (
        ''
      ) : rowData.device ? (
        <>
          <p style={{ fontSize: '15px' }}>
            {rowData.device?.macAddress?.toUpperCase().slice(-5)}
          </p>
          <span>
            {rowData.device?.lastBatteryCharge !== -1 && (
              <Tooltip
                text={`${rowData.device?.lastBatteryCharge}% ${t(
                  'WEARABLES.HELP.CHARGE',
                ).toLowerCase()}`}
              >
                <img src={icon} alt="" style={{ marginRight: '3px' }} />
              </Tooltip>
            )}
            {!isClip && (
              <>
                {' '}
                <Tooltip
                  text={`${t('WEARABLES.HELP.VOLUME_IS')} ${
                    rowData.device?.soundOn
                      ? t('GENERAL.ON').toLowerCase()
                      : t('GENERAL.OFF').toLowerCase()
                  }`}
                >
                  {rowData.device?.soundOn ? (
                    <img src={soundOnIcon} alt="sound icon" />
                  ) : (
                    <img src={soundOffIcon} alt="sound icon" />
                  )}
                </Tooltip>
                {' '}
                <Tooltip
                  text={`${t('WEARABLES.HELP.VIBRATION_IS')} ${
                    rowData.device?.vibroOn
                      ? t('GENERAL.ON').toLowerCase()
                      : t('GENERAL.OFF').toLowerCase()
                  }`}
                >
                  {rowData.device?.vibroOn ? (
                    <img src={vibroOnIcon} alt="device vibro icon" />
                  ) : (
                    <img src={vibroOffIcon} alt="device vibro icon" />
                  )}
                </Tooltip>
              </>
            )}
          </span>
        </>
      ) : (
        <p style={{ color: COLORS.GRAY[400], fontSize: '12px' }}>
          {t('WEARABLES.HELP.NO_DEVICE')}
        </p>
      )}
    </>
  );
};

export default DeviceCell;
