export default function getActivityLegendSchema(t) {
    return ([
        {
            header: t('WEARABLES.ACTIVITY.REPORT.METRIC_NAME'),
            getValue: (item) => t(item[0]),
        },
        {
            header: t('GENERAL.DESCRIPTION'),
            getValue: (item) => t(item[1]),
        },
    ]);
}
