import React from 'react';
import _map from 'lodash/map';
import _pickBy from 'lodash/pickBy';
import { text } from 'assets/jss/style';
import { CHART_SIDE_GAP, PIXEL_COEFFICIENT, USER_HORIZONTAL_CHARTS_STAGE_HEIGHT_MM } from 'containers/PdfContainer/constants';
import RiskGroupBadge from 'lib/ui/RiskGroupBadge/RiskGroupBadge';
import { COLORS } from 'constants/colors';
import { legend } from 'assets/jss/containers/pdfLayersStyle';
import legendSquare from 'assets/jss/components/legendSquare';
import { useTranslation } from 'react-i18next';
import HorizontalBarChartWrapper from './HorizontalBarChartWrapper';
import { hazardNameColorMap } from '../../../overview/constants/hazardNameColorMap';
import RatingInfo from '../../ProgramContainer/components/RatingInfo';
import { getRatingProps } from '../../ProgramContainer/utils/getRatingProps';
import { legendSlot } from '../../ProgramContainer/components/OverallHazardsCharts';
import prepareDataForHazardsDonutChart
  from '../../ProgramContainer/utils/prepareDataForHazardsDonutChart';

const OverallData = ({ currentProgram, isGrayScale }) => {
  const { t } = useTranslation();
  const overallHazardsStart = { ...currentProgram.overallHazardsStart };
  delete overallHazardsStart.overallHazard;
  const preparedHazardsStart = _pickBy(overallHazardsStart, (n) => n !== 0);
  const overallHazardsCurrent = { ...currentProgram.overallHazardsCurrent };
  delete overallHazardsCurrent.overallHazard;
  const preparedHazardsCurrent = _pickBy(overallHazardsCurrent, (n) => n !== 0);
  const overallImprovement = getRatingProps(
    currentProgram?.improvements?.averageHazardousMovementFrequencyChange,
  );
  const splitNumberByFifth = (num) => {
    const fifth = num / 5;
    return [
      num,
      num - fifth,
      num - 2 * fifth,
      num - 3 * fifth,
      num - 4 * fifth,
      num - 5 * fifth,
    ];
  };
  const currentHazardMap
    = currentProgram.deviceType !== 'armband'
      ? hazardNameColorMap.spine
      : hazardNameColorMap.shoulder;
  const stageWidth = 200;
  const chartWidth = 170;
  const chartHeight = 150;
  const barHeight = 8;
  const chartHeightMargin = 24;
  const stageHeight = USER_HORIZONTAL_CHARTS_STAGE_HEIGHT_MM;
  const maxHazardValue = Math.max(
    ...Object.values(preparedHazardsStart),
    ...Object.values(preparedHazardsCurrent),
  );
  // const hazardMapForChart = [];
  // const compareCurrentHazardMap = currentHazardMap.map((obj) => {
  //   Object.keys(preparedHazardsStart).includes(obj.alias) ? hazardMapForChart.push(obj) : null;
  // });
  const compareCurrentHazardMap = (hazards) => {
    const hazardMapForChart = [];
    currentHazardMap.map((obj) => (Object.keys(hazards).includes(obj.alias)
      ? hazardMapForChart.push(obj)
      : null));
    return hazardMapForChart;
  };
  const prepareDataForOverallChart = (overallHazard) => compareCurrentHazardMap(overallHazard).map(({ alias, color }, index) => ({
    x: 0,
    y:
        compareCurrentHazardMap(overallHazard).length <= 2
          ? chartHeightMargin * 2
            + index
              * barHeight
              * (8 - compareCurrentHazardMap(overallHazard).length)
            + 5
          : chartHeightMargin
            + index
              * barHeight
              * (8 - compareCurrentHazardMap(overallHazard).length)
            + 5,
    width: (chartWidth / maxHazardValue) * overallHazard[alias],
    height: barHeight,
    fill: color,
    value: overallHazard[alias],
  }));
  const { deviceType } = currentProgram;
  const startChartData = prepareDataForHazardsDonutChart(
    currentProgram.overallHazardsStart,
    deviceType,
  );
  const ratioChartDomain = splitNumberByFifth(maxHazardValue).reverse();
  const axisData = (domainValues) => ({
    bottom: {
      x: CHART_SIDE_GAP * PIXEL_COEFFICIENT,
      y: chartHeight,
      width: chartWidth,
    },
    doted: {
      x: (CHART_SIDE_GAP + 10) * PIXEL_COEFFICIENT,
      width: stageWidth + 10,
      points: splitNumberByFifth(chartWidth),
      domainValues,
    },
  });
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '30vw', marginTop: '16px', position: 'relative' }}>
        <div style={{ position: 'absolute', top: '20px' }}>
          <RiskGroupBadge
            mediumSize
            showTooltip={false}
            riskGroup={currentProgram.riskGroup}
          />
        </div>
        <div style={{ borderBottom: '1px solid #E0E5EC' }}>
          <HorizontalBarChartWrapper
            header={t('WEARABLES.OVERVIEW.START_OVERALL_HAZARDS').toUpperCase()}
            width={stageWidth}
            height={stageHeight}
            axisData={axisData(ratioChartDomain)}
            data={prepareDataForOverallChart(preparedHazardsStart)}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            marginTop: '-100px',
            marginLeft: '252px',
          }}
        >
          <h2
            style={
              currentProgram.overallHazardsStart.overallHazard >= 10
                ? null
                : { textAlign: 'center' }
            }
          >
            {currentProgram.overallHazardsStart.overallHazard.toFixed(1)}
          </h2>
          <p className={text(COLORS.GRAY[400], 16)}>
            {t('WEARABLES.OVERVIEW.OVERALL').toUpperCase()}
          </p>
        </div>
      </div>
      <div style={{ width: '36vw' }}>
        <div
          style={{ display: 'flex', marginLeft: '20px', marginBottom: '-15px' }}
        >
          {overallImprovement.rating ? (
            <h2
              style={{ paddingRight: '14px' }}
              className={text(COLORS.GRAY[400], 20)}
            >
              {t('WEARABLES.OVERVIEW.OVERALL_IMPROVEMENT').toUpperCase()}
            </h2>
          ) : null}
          <div
            style={
              !overallImprovement.rating
                ? { marginLeft: '50px', paddingTop: '15px' }
                : null
            }
          >
            <RatingInfo ratingProps={overallImprovement} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={legend}
        >
          {_map(startChartData.overallHazardsForCurrentType, (val, key) => {
            const currentHazardPresentation = startChartData.hazardsColors.find(
              ({ alias }) => alias === key,
            );
            return (
              <div key={key} className={legendSlot}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '180px',
                    marginTop: '22px',
                  }}
                >
                  <div
                    className={legendSquare(currentHazardPresentation.color)}
                  />
                  {t(currentHazardPresentation.name)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ width: '30vw', marginTop: '16px', position: 'relative' }}>
        <div style={{ position: 'absolute', top: '20px' }}>
          <RiskGroupBadge
            mediumSize
            showTooltip={false}
            riskGroup={currentProgram.currentRiskGroup}
          />
        </div>
        <div style={{ borderBottom: '1px solid #E0E5EC' }}>
          <HorizontalBarChartWrapper
            header={t(
              'WEARABLES.OVERVIEW.CURRENT_OVERALL_HAZARDS',
            ).toUpperCase()}
            width={stageWidth}
            height={stageHeight}
            axisData={axisData(ratioChartDomain)}
            data={prepareDataForOverallChart(preparedHazardsCurrent)}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            marginTop: '-100px',
            marginLeft: '252px',
          }}
        >
          <h2
            style={
              currentProgram.overallHazardsCurrent.overallHazard >= 10
                ? null
                : { textAlign: 'center' }
            }
          >
            {currentProgram.overallHazardsCurrent.overallHazard.toFixed(1)}
          </h2>
          <p className={text(COLORS.GRAY[400], 16)}>
            {t('WEARABLES.OVERVIEW.OVERALL').toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OverallData;
