import React from 'react';
import { column, noDataText } from 'assets/soter-flex-styles';
import Text from 'lib/ui/Text';

const NoDataInfo = ({ noDataMessage }) => (
  <div className={column}>
    <Text text={noDataMessage} className={noDataText} />
  </div>
);

export default NoDataInfo;
