import React from 'react';
import {
  failUploadNotification,
  flexRow,
  ml3,
  mr3,
  notificationText,
  smallIcon,
  successUploadNotification,
  warningUploadNotification,
} from '../../../assets/soter-flex-styles';
import successNotificationIcon from '../../../assets/img/icons/notifications/success-notification-icon.svg';
import failNotificationIcon from '../../../assets/img/icons/notifications/fail-notification-icon.svg';
import warningNotificationIcon from '../../../assets/img/icons/notifications/warning-notification-icon.svg';

const notificationMap = {
  TYPE_SUCCESS: {
    iconClass: successUploadNotification,
    icon: successNotificationIcon,
  },
  TYPE_FAILED: {
    iconClass: failUploadNotification,
    icon: failNotificationIcon,
  },
  TYPE_WARNING: {
    iconClass: warningUploadNotification,
    // buttonClass: warningNotificationClose,
    icon: warningNotificationIcon,
  },
};

const StaticNotification = ({ notification }) => {
  const { message, status } = notification;
  return (
    <div className={notificationMap[status]?.iconClass}>
      <div className={flexRow}>
        <img className={`${smallIcon} ${ml3} ${mr3}`} src={notificationMap[status].icon} alt="battery charge" />
        <span className={notificationText}>
          {message}
        </span>
      </div>
    </div>
  );
};

export default StaticNotification;
