import { TableType } from './tableType';
export var ReportType;
(function (ReportType) {
    ReportType[ReportType["Csv"] = 0] = "Csv";
    ReportType[ReportType["Xls"] = 1] = "Xls";
})(ReportType || (ReportType = {}));
export var ReportOption;
(function (ReportOption) {
    ReportOption[ReportOption["default"] = 0] = "default";
    ReportOption[ReportOption["finishedProgram"] = 1] = "finishedProgram";
    ReportOption[ReportOption["byRisk"] = 2] = "byRisk";
    ReportOption[ReportOption["byImprovements"] = 3] = "byImprovements";
})(ReportOption || (ReportOption = {}));
export const activityPageReportList = [
    {
        id: 'all_csv',
        type: ReportType.Csv,
        title: 'WEARABLES.ACTIVITY.ACTIONS.ALL_CSV',
        option: ReportOption.default,
    },
    {
        id: 'all_xls',
        type: ReportType.Xls,
        title: 'WEARABLES.ACTIVITY.ACTIONS.ALL_XLS',
        option: ReportOption.default,
    },
    {
        id: 'finish_csv',
        type: ReportType.Csv,
        title: 'WEARABLES.ACTIVITY.ACTIONS.FINISH_CSV',
        option: ReportOption.finishedProgram,
    },
    {
        id: 'finish_xls',
        type: ReportType.Xls,
        title: 'WEARABLES.ACTIVITY.ACTIONS.FINISH_XLS',
        option: ReportOption.finishedProgram,
    },
    {
        id: 'ranked_csv',
        type: ReportType.Csv,
        title: 'WEARABLES.ACTIVITY.ACTIONS.RANKED_CSV',
        option: ReportOption.byRisk,
    },
    {
        id: 'ranked_xls',
        type: ReportType.Xls,
        title: 'WEARABLES.ACTIVITY.ACTIONS.RANKED_XLS',
        option: ReportOption.byRisk,
    },
    {
        id: 'ranked_i_csv',
        type: ReportType.Csv,
        title: 'WEARABLES.ACTIVITY.ACTIONS.RANKED_I_CSV',
        option: ReportOption.byImprovements,
    },
    {
        id: 'ranked_i_xls',
        type: ReportType.Xls,
        title: 'WEARABLES.ACTIVITY.ACTIONS.RANKED_I_XLS',
        option: ReportOption.byImprovements,
    },
];
export const galleryPageReportList = [
    {
        id: 'all_csv',
        type: ReportType.Csv,
        title: 'SOTER_TASK.VIDEOS_LIST.VIDEOS_CSV',
    },
    {
        id: 'all_xls',
        type: ReportType.Xls,
        title: 'SOTER_TASK.VIDEOS_LIST.VIDEOS_XLS',
    },
];
export const comparisonPageReportList = [
    {
        id: 'all_csv',
        type: ReportType.Csv,
        title: 'SOTER_TASK.COMPARISONS_LIST.COMPARISONS_CSV',
    },
    {
        id: 'all_xls',
        type: ReportType.Xls,
        title: 'SOTER_TASK.COMPARISONS_LIST.COMPARISONS_XLS',
    },
];
const emptyReportEntity = {
    id: '',
    type: ReportType.Csv,
    title: '',
};
export function getCurrentPageReportList(tableType) {
    switch (tableType) {
        case TableType.coach: return activityPageReportList;
        case TableType.soterTaskVideos: return galleryPageReportList;
        case TableType.soterTaskComparison: return comparisonPageReportList;
        default: return [emptyReportEntity];
    }
}
