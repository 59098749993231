import React from 'react';
import { Radio, RadioGroup } from 'rsuite';
import { subHeader } from '../../assets/jss/style';
import { ClipgoInputLoginMethod } from '../../init/proto_client';

const TeamMemberIdRadio = ({ onChange, loginMethod, splitHeader }) => (
  <div>
    <div className={subHeader}>
      Default method for entering
      {splitHeader ? <br /> : ' '}
      team member id
    </div>
    <RadioGroup value={loginMethod} onChange={onChange}>
      <Radio name="scanQr" value={ClipgoInputLoginMethod.BOTH_CAMERA}>QR (tablet keyboard optional)</Radio>
      <Radio name="keyboard" value={ClipgoInputLoginMethod.BOTH_KEYBOARD}>Tablet keyboard (QR optional)</Radio>
      <Radio name="onlyQr" value={ClipgoInputLoginMethod.ONLY_CAMERA}>Only QR</Radio>
      <Radio name="onlyKeyboard" value={ClipgoInputLoginMethod.ONLY_KEYBOARD}>Only tablet keyboard</Radio>
      <Radio name="scanGun" value={ClipgoInputLoginMethod.SCAN_GUN}>Scan gun (tablet keyboard optional)</Radio>
    </RadioGroup>
  </div>
);

export default TeamMemberIdRadio;
