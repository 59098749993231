import React from 'react';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { getTimeInUserTimeZone } from '../../../../lib/utils/getTimeInUserTimeZone';
import { hazardNameColorMap } from '../../../overview/constants/hazardNameColorMap';
import { CARD_TITLES } from '../../ProgramContainer/constants';

const UserReportProgram = ({
  currentProgram, user, timezone, isGrayScale,
}) => {
  const { t } = useTranslation();
  const mostRiskyHour = currentProgram.mostRiskyHourMilliseconds;
  const mostRiskyDay = currentProgram.mostRiskyDayMilliseconds;
  const mostlyHazardPresentation = [
    ...hazardNameColorMap.shoulder,
    ...hazardNameColorMap.spine,
  ].find(({ alias }) => alias === currentProgram.mostRiskyMovement);
  const FractionalElem = (data) => {
    const {
      dividend, divider, name, width,
    } = data;
    return (
      <div style={{ width: `${width}` }}>
        <div style={{ display: 'flex' }}>
          <h5>{dividend}</h5>
          <div style={{ marginTop: '4mm' }}>
            <p className={text(COLORS.GRAY[400], 12)}>
              {' '}
              /
              {divider}
            </p>
          </div>
        </div>
        <div className={text(COLORS.GRAY[400], 10)}>{name}</div>
      </div>
    );
  };
  return (
    <div
      style={{
        borderBottom: `1px solid ${COLORS.GRAY[100]}`,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '86mm' }}>
        <div className={text(COLORS.GRAY[400], 14)}>
          {t('WEARABLES.OVERVIEW.CURRENT_PROGRAM').toUpperCase()}
        </div>
        <h4 className={text('black', 18)}>{currentProgram.title}</h4>
      </div>
      <FractionalElem
        dividend={currentProgram.progress.acceptedWorkSessionsCount}
        divider={currentProgram.progress.targetWorkSessionsCount}
        name={t('WEARABLES.OVERVIEW.DAYS').toUpperCase()}
        width="16mm"
      />
      <FractionalElem
        dividend={currentProgram.tutorials.finishedTutorialsCount}
        divider={currentProgram.tutorials.tutorialsCount}
        name={t('WEARABLES.OVERVIEW.TUTORIALS').toUpperCase()}
        width="26mm"
      />
      <div style={{ width: '56mm' }}>
        <h5 className={text('black', 16)}>
          {mostlyHazardPresentation?.name
            ? t(mostlyHazardPresentation?.name)
            : '-'}
        </h5>
        <div className={text(COLORS.GRAY[400], 12)}>
          {t('WEARABLES.OVERVIEW.FREQUENT_HAZARD').toUpperCase()}
        </div>
      </div>
      <div style={{ width: '40mm' }}>
        {mostRiskyHour ? (
          <>
            <h5 className={text('black', 16)}>
              {getTimeInUserTimeZone(
                mostRiskyHour,
                user.timezone,
                'DD MMM YYYY',
              )}
            </h5>
            <h5 className={text('black', 16)}>
              {`${getTimeInUserTimeZone(mostRiskyHour, user.timezone, 'HH:mm')} 
                    - ${getTimeInUserTimeZone(
                mostRiskyHour + 3600000,
                user.timezone,
                'HH:mm',
              )}`}
            </h5>
          </>
        ) : (
          '-'
        )}
        <div className={text(COLORS.GRAY[400], 12)}>{t(CARD_TITLES.mostRiskHour)}</div>
      </div>
      <div style={{ width: '38mm' }}>
        <h5 className={text('black', 16)}>
          {mostRiskyDay
            ? getTimeInUserTimeZone(mostRiskyDay, user.timezone, 'DD MMM YYYY')
            : '-'}
        </h5>
        <div className={text(COLORS.GRAY[400], 12)}>{t(CARD_TITLES.mostRiskDay)}</div>
      </div>
      <div style={{ width: '36mm' }}>
        <h5 className={text('black', 16)}>
          {currentProgram.mostRiskyTask ? currentProgram.mostRiskyTask : ' - '}
        </h5>
        <div className={text(COLORS.GRAY[400], 12)}>{t(CARD_TITLES.mostRiskTask)}</div>
      </div>
    </div>
  );
};

export default UserReportProgram;
