import moment from 'moment';

const getMsFromMinutes = (minutes) => 1000 * 60 * minutes;

const roundDate = (date, minutes) => {
  const coeff = getMsFromMinutes(minutes);
  return Math.floor(new Date(date).getTime() / coeff) * coeff;
};

export const hideMinutesFn = (minute) => minute % 5 !== 0;

export const isInvalidtimeEnd = (timeStart, timeEnd) => timeEnd.value <= timeStart.value;

export const isTaskTimeValid = (
  existingTasks,
  newTasktimeStart,
  newTasktimeEnd,
) => {
  const existingTasksTimeRanges = existingTasks.map((task) => ([task.timeStart, task.timeEnd]));
  let isValid = true;
  existingTasksTimeRanges.forEach((range) => {
    if (!((newTasktimeStart < range[0] && newTasktimeEnd <= range[0])
      || (newTasktimeStart >= range[1] && newTasktimeEnd > range[1]))) {
      isValid = false;
    }
  });
  return isValid;
};

export const convertDateToString = (date) => moment(date).format('MMM DD YYYY');

export const getDisplayedDate = (timeStart, dateWithoutTimeZone, timeZone) => {
  const date = moment(dateWithoutTimeZone).tz(timeZone);
  const getDate = () => {
    if (date.date() !== moment(timeStart).tz(timeZone).date()) {
      return ` (${moment(date).format('MMM DD')})`;
    }

    return '';
  };
  if ((moment(date).format('HH:mm') + getDate()) < '12:00') {
    return `${moment(date).format('hh:mm') + getDate()} am`;
  }
  return `${moment(date).format('hh:mm') + getDate()} pm`;
};

export const getTimeOptions = (timeStart, timeEnd, timeZone) => {
  const res = [];

  for (let date = roundDate(timeStart, 5); date < timeEnd; date += getMsFromMinutes(5)) {
    res.push({ value: date, label: getDisplayedDate(timeStart, date, timeZone) });
  }
  return res;
};

export const getStartTime = (values, tasks) => {
  const fiveMin = 300000;
  const startTime = tasks[tasks.length - 1]?.timeEnd + fiveMin;
  return values.find((i) => i.value === startTime);
};

export const getTimeValues = (defaultTimeArr, excludeTimeArr) => {
  const excludeSet = new Set(excludeTimeArr.map((e) => JSON.stringify(e)));
  return defaultTimeArr.filter((e) => !excludeSet.has(JSON.stringify(e)));
};

export const getTimeInUserTimezone = (time, timeZone) => moment(time, 'x').tz(timeZone).valueOf();
let iterator = 0;
// eslint-disable-next-line consistent-return,
export function getClickOnChartTime(timeOptions, timeForSearch) {
  const timeScope = [...timeOptions];
  let clickTime = timeForSearch;
  if (timeScope.find((val) => val?.value === clickTime)) {
    const result = timeScope.find((val) => val?.value === clickTime);
    return { ...result };
  }
  if (timeScope[timeScope.length - 1]?.value > clickTime) {
    iterator += 1;
    if (iterator > 300) return getClickOnChartTime(timeScope, timeScope[timeScope.length - 1]?.value);
    clickTime += 300000;
    return getClickOnChartTime(timeScope, clickTime);
  }
}
