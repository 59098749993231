import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';

const buttonText = Emotion.css`
  font-family: 'Mulish', sans-serif;
`;

const button = Emotion.css`
  ${buttonText};

  cursor: pointer;
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

// Button sizes starts
const buttonDefault = Emotion.css`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
`;

const buttonLarge = Emotion.css`
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  font-size: 1.25rem;
  min-width: 95px;
`;

const buttonSmall = Emotion.css`
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  min-width: 65px;
`;

const buttonExtraSmall = Emotion.css`
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  min-width: 55px;
`;
// Button sizes ends

// Button types starts
const main = Emotion.css`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.LIGHT_BLUE[600]};
  transition: all 0.2 ease-in-out;

  &:hover {
    background-color: ${COLORS.LIGHT_BLUE[700]};
  }

  &:active {
    background-color: ${COLORS.LIGHT_BLUE[800]};
  }

  &:disabled {
    color: ${COLORS.GRAY[300]};
    background-color: ${COLORS.LIGHT_BLUE[100]};
    opacity: 1;
  }
`;

const secondary = Emotion.css`
  color: ${COLORS.LIGHT_BLUE[600]};
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHT_BLUE[600]};
  transition: all 0.2 ease-in-out;

  &:hover {
    color: ${COLORS.LIGHT_BLUE[700]};
    background-color: ${COLORS.LIGHT_BLUE[50]};
    border-color: ${COLORS.LIGHT_BLUE[700]};
  }

  &:active {
    color: ${COLORS.LIGHT_BLUE[800]};
    background-color: ${COLORS.LIGHT_BLUE[100]};
    border-color: ${COLORS.LIGHT_BLUE[800]};
  }

  &:disabled {
    color: ${COLORS.GRAY[300]};
    border-color: ${COLORS.LIGHT_BLUE[200]};
    opacity: 1;
  }
`;

const tertiary = Emotion.css`
  color: ${COLORS.GRAY[400]};
  background-color: ${COLORS.GRAY[50]};

  &:hover {
    background-color: ${COLORS.GRAY[100]};
  }
  &:active {
    background-color: ${COLORS.GRAY[200]};
  }
`;

const text = Emotion.css`
  color: ${COLORS.LIGHT_BLUE[600]};
  background: none;

  &:hover {
    background-color: ${COLORS.BLUE[50]};
  }
  &:active {
    background-color: ${COLORS.BLUE[100]};
  }
`;
// Button types ends

const buttonType = (type) => {
  switch (type) {
    case 'secondary':
      return secondary;
    case 'tertiary':
      return tertiary;
    case 'text':
      return text;
    default:
      return main;
  }
};

const sizedButton = (size) => {
  switch (size) {
    case 'lg':
      return buttonLarge;
    case 'sm':
      return buttonSmall;
    case 'xs':
      return buttonExtraSmall;
    default:
      return buttonDefault;
  }
};

export const buttonTypeAndSize = (type = 'default', size) => Emotion.css`
  ${button};
  ${buttonType(type)};
  ${sizedButton(size)};
`;

export const adminButton = Emotion.css`
  border: none !important;
  &:hover {
    background: #fff !important;
  }
  &:active {
    background: #fff !important;
  }
`;

export const userButton = Emotion.css`
  border: none !important;
  background: none !important;
  &:hover {
    background: none !important;
  }
  &:active {
    background: none !important;
  }
`;

export const backButton = Emotion.css`
  &:hover {
    background: none !important;
  }
`;
