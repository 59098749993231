import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import { TABLE_DATA_FILTER } from 'constants/analyticsEvents';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { pickerForTable } from '../../../assets/jss/components/Datepicker';
import { setDateRange } from '../../../wearables/activity/usersStore/actions';
import { createEventNotification } from '../../NotificationContainer/notificationsStore/actions';
import CustomDateRangePicker from './CustomDateRangePicker';
import { datePickerAllTimeValues, getDatePickerRanges } from '../../../wearables/overview/utils/getPeriods';
// import { removeParam } from '../utils/addURLQueryParams';
// import { calendar } from '../../../constants/URLParams';

const CustomDatePicker = ({fullWidth}) => {
  const { selectedCompany } = useSelector((state) => state.companies);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dateRangePickerValue } = useSelector((state) => state.coachUsers);
  const datePickerAllTimeValue = datePickerAllTimeValues(selectedCompany?.createdAtInMilliseconds);
  const datePickerRanges = getDatePickerRanges(datePickerAllTimeValue, t);
  useEffect(() => {
    if (!dateRangePickerValue[0]) {
      dispatch(setDateRange(datePickerAllTimeValue));
    }
  }, [dateRangePickerValue]);

  const changePeriod = (selectedPeriod) => {
    if (selectedPeriod && selectedPeriod.length === 2) {
      const [startDate, endDate] = selectedPeriod;
      const start = moment(startDate).startOf('day').toDate();
      const end = moment(endDate).endOf('day').toDate();
      dispatch(setDateRange([start, end]));

      const params = {
        filter_by: 'period',
      };
      window.logEvent(TABLE_DATA_FILTER, params);

      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(`${TABLE_DATA_FILTER}: ${JSON.stringify(params)}`));
      }
    }
  };

  return (
    <CustomDateRangePicker
      className={pickerForTable(fullWidth)}
      placeholder={t('WEARABLES.OVERVIEW.DATE_RANGE')}
      disableBefore={selectedCompany?.createdAtInMilliseconds * 1000}
      // renderValue={getRenderValueForRangePicker()} // @deprecated
      value={dateRangePickerValue.length ? dateRangePickerValue : undefined}
      ranges={datePickerRanges}
      cleanable={false}
      loading={!dateRangePickerValue.length}
      // appearance="subtle"
      onChange={changePeriod}
    />
  );
};

export default CustomDatePicker;
