import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userButton } from '../../../assets/jss/components/button';
import { columnSmall, pl0, pr0 } from '../../../assets/soter-flex-styles';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import { logout } from '../../../auth/store/actions';
import { routes, users } from '../../PrivatPageHOC/constants/routes';
import ActionToggleButton from '../../../lib/ui/ActionToggleButton';
import DropToggler from '../../ItemTableContainer/components/DropToggler';
import Text from '../../../lib/ui/Text';
import { COLORS } from '../../../constants/colors';
import DropdownActionList from '../../../lib/ui/DropdownActionList/DropdownActionList';
import { productStatus } from '../../../init/proto_client';

const AdminActionDropdown = ({
  user, isAdminArea, navigate, isAdmin, id,
}) => {
  const { products } = useAppSelector((state) => state.companies.selectedCompany);
  const elementId = 'admin-dropdown';
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const {
    firstName, lastName,
  } = user;
  const [adminActionState, setAdminActionState] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);

  useLayoutEffect(() => {
    if (document.getElementById(elementId).childNodes.length === 0) {
      setShowLanguages(false);
    }
  });


  const basicActions = {
    lang: {
      id: 'lang',
      name: t('DROPDOWN.LANGUAGE'),
      color: COLORS.GRAY[400],
      size: 'small',
      actionHandler: () => {
        setShowLanguages(true);
      },
      disabled: false,
    },
    logout: {
      id: 'logout',
      name: t('DROPDOWN.LOG_OUT'),
      color: COLORS.GRAY[400],
      size: 'small',
      actionHandler: () => {
        dispatch(logout());
        navigate(`/${routes.auth}`);
      },
      disabled: false,
    },
  };

  if (products?.geniusProduct?.status !== productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE) {
    delete basicActions.profile
  }
  
  const langActions = {
    en: {
      id: 'en',
      name: t('LANGUAGES.ENGLISH'),
      color: COLORS.GRAY[400],
      size: 'small',
      selected: i18n.language === 'en',
      actionHandler: () => {
        i18n.changeLanguage('en');
        localStorage.setItem('language', 'en');
        setAdminActionState(false);
        setShowLanguages(false);
      },
      disabled: false,
    },
    es: {
      id: 'es',
      name: t('LANGUAGES.SPAIN'),
      color: COLORS.GRAY[400],
      size: 'small',
      selected: i18n.language === 'es',
      actionHandler: () => {
        i18n.changeLanguage('es');
        localStorage.setItem('language', 'es');
        setAdminActionState(false);
        setShowLanguages(false);
      },
      disabled: false,
    },
    fr: {
      id: 'fr',
      name: t('LANGUAGES.FRENCH'),
      color: COLORS.GRAY[400],
      size: 'small',
      selected: i18n.language === 'fr',
      actionHandler: () => {
        i18n.changeLanguage('fr');
        localStorage.setItem('language', 'fr');
        setAdminActionState(false);
        setShowLanguages(false);
      },
      disabled: false,
    },
  };

  const adminActions = {
    myCompany: {
      id: 'myCompany',
      name: isAdminArea
        ? t('ADMIN.BACK_TO_USER_AREA', 'Back to user area')
        : t('ADMIN.ADMIN_AREA', 'Admin area'),
      color: COLORS.GRAY[400],
      size: 'small',
      actionHandler: () => {
        navigate(isAdminArea
          ? `/${id}/${users}/${routes.wearables}/${routes.overview}`
          : `/${id}/${routes.admin}/${routes.companies}`);
        setAdminActionState(false);
      },
      disabled: false,
    },
    ...basicActions,
  };

  const userDropdownActions
    = isAdmin ? adminActions : basicActions;

  const toggleDropdown = () => {
    setAdminActionState(!adminActionState);
  };

  const handleAction = (actionID) => {
    showLanguages
      ? langActions[actionID].actionHandler()
      : userDropdownActions[actionID].actionHandler();
  };

  const togglerButton = (
    <ActionToggleButton id={elementId} className={userButton} />
  );

  return (
    <div className={`${columnSmall} ${pl0} ${pr0}`}>
      <DropToggler
        content={(
          <div style={{ flexDirection: 'row' }}>
            <Text
              text={`${firstName} ${lastName}`}
              style={{
                fontFamily: 'Mulish',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 1.2,
                whiteSpace: 'nowrap',
                color: isAdminArea ? '#fff' : COLORS.GRAY[950],
              }}
              data-name={elementId}
            />
            <Text
              text={isAdmin ? t('ADMIN.ADMINISTRATOR') : ''}
              appearance="caption"
              style={{
                fontFamily: 'Mulish',
                fontSize: '14px',
                fontWeight: 400,
                margin: 0,
                color: isAdminArea ? '#fff' : '#9AA0AB',
                textAlign: 'left',
              }}
              data-name={elementId}
            />
          </div>
        )}
        id={elementId}
        togglerButton={togglerButton}
        direction="down"
        disabled={false}
        toggleDrop={toggleDropdown}
        state={adminActionState}
      >
        <DropdownActionList
          actionHandler={handleAction}
          actions={showLanguages ? langActions : userDropdownActions}
        />
      </DropToggler>
    </div>
  );
};

export default AdminActionDropdown;
