import _isNull from 'lodash/isNull';
import _uniq from 'lodash/uniq';
import _difference from 'lodash/difference';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { assessment, text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../../lib/utils/usePreviuos';
import ExpandedPanel from './ExpandedPanel';
import { PictureStepsWrapper, TextStepsWrapper } from './AssessmentSteps';
import { assessmentSteps } from '../constants';
import AssessmentTotalScore from './AssessmentTotalScore';
import calculateRebaRiskLevel from '../../utils/calculateRebaRiskLevel';

export const getColorForTotalScore = (summary, type) => {
  if (type.includes('rula')) {
    if (summary > 0 && summary <= 2) return COLORS.GREEN[600];
    if (summary > 2 && summary <= 6) return COLORS.ORANGE[600];
    if (summary > 6) return COLORS.RED[600];
  } else if (type === 'reba') {
    return calculateRebaRiskLevel(summary, () => null)[1];
  }
};
export const getTotalScoreDescription = (score, type, t) => {
  if (type.includes('rula')) {
    let string = '';
    if (score >= 7) string = t('SOTER_TASK.ASSESSMENT.RULA.INVESTIGATE');
    if (score < 7 && score >= 5) string = t('SOTER_TASK.ASSESSMENT.RULA.SOON');
    if (score < 5 && score >= 3) { string = t('SOTER_TASK.ASSESSMENT.RULA.MAY_BE'); }
    if (score < 3 && score >= 1) {
      string = t('SOTER_TASK.ASSESSMENT.RULA.ACCEPTABLE_POSTURE');
    }
    return string;
  }
  if (type === 'reba') {
    return calculateRebaRiskLevel(score, t)[0];
  }
};

const Assessment = ({
  structure,
  selectedFields,
  setSelectedFields,
  matrix,
  totalScore,
  setTotalScore,
  assessmentType,
  weightUnit,
}) => {
  const { t } = useTranslation();
  const { assessmentStep, currentAssessmentId } = useSelector(
    (state) => state.soterTaskAssessments,
  );
  const [stepsStructure, setStepStructure] = useState({});
  const [stepsKeys, setStepsKeys] = useState([]);
  const [scores, setScores] = useState(stepsStructure);
  useEffect(() => {
    const struc = {};
    if (structure) {
      structure.forEach(({ steps }) => {
        steps.forEach(({ id }) => (struc[id] = null));
      });
    }
    setStepsKeys(Object.keys(struc));
    setScores(struc);
    setStepStructure(struc);
  }, [structure]);
  const switchFields = (id, type, radioValues) => {
    if (selectedFields.includes(id) && type === 'checkbox') {
      const newFields = [...selectedFields];
      newFields.splice(selectedFields.indexOf(id), 1);
      setSelectedFields(_uniq(newFields));
    } else {
      const newFields = [...selectedFields];
      if (type === 'radio') {
        radioValues.forEach((val) => {
          if (val !== id && selectedFields.includes(val)) { newFields.splice(selectedFields.indexOf(val), 1); }
        });
      }
      newFields.push(id);
      setSelectedFields(_uniq(newFields));
    }
  };
  const previousSelectedFields = usePrevious(selectedFields);
  useEffect(() => {
    if (!previousSelectedFields) return;
    const newScores = { ...scores };
    structure.forEach(({ steps }) => {
      steps.forEach(({ id: stepId, values, symbol }) => {
        if (!symbol) newScores[stepId] = null;
        values.forEach(({ id, score }) => {
          const isSelected = selectedFields.find((el) => el === id);
          // if (isSelected && !currentAssessmentId) setScores({ ...scores, [stepId]: scores[stepId] ? scores[stepId] + score : score });
          if (isSelected) {
            newScores[stepId] = newScores[stepId]
              ? newScores[stepId] + score
              : score;
          }
        });
        if (previousSelectedFields.length > selectedFields.length) {
          const unselectedField = _difference(
            previousSelectedFields,
            selectedFields,
          )[0];
          const unselectedFieldScore = values.find(
            ({ id }) => id === unselectedField,
          )?.score;
          if (!unselectedFieldScore) return;
          // if (!currentAssessmentId) setScores({ ...scores, [stepId]: scores[stepId] - unselectedFieldScore });
          newScores[stepId] = scores[stepId] - unselectedFieldScore;
        }
      });
    });
    setScores(newScores);
  }, [selectedFields]);
  useEffect(() => {
    let newScores = { ...scores };
    if (assessmentType === 'reba') return;
    if (
      scores[stepsKeys[0]] >= 0 &&
      scores[stepsKeys[1]] &&
      scores[stepsKeys[2]] &&
      scores[stepsKeys[3]]
    ) {
      let rowIndex
        = (scores[stepsKeys[0]] - 1) * 3 + (scores[stepsKeys[1]] - 1);
      const columnIndex
        = (scores[stepsKeys[2]] - 1) * 2 + (scores[stepsKeys[3]] - 1);
      if (rowIndex < 0) rowIndex = scores[stepsKeys[1]] - 1;
      newScores = {
        ...newScores,
        [stepsKeys[4]]: matrix[0][rowIndex][columnIndex],
      };
    }
    if (
      scores[stepsKeys[4]] &&
      scores[stepsKeys[5]] >= 0 &&
      scores[stepsKeys[6]] >= 0
    ) {
      newScores = {
        ...newScores,
        [stepsKeys[7]]:
          scores[stepsKeys[4]] + scores[stepsKeys[5]] + scores[stepsKeys[6]],
      };
    }
    if (scores[stepsKeys[8]] && scores[stepsKeys[9]] && scores[stepsKeys[10]]) {
      const rowIndex = scores[stepsKeys[8]] - 1;
      const columnIndex
        = (scores[stepsKeys[9]] - 1) * 2 + (scores[stepsKeys[10]] - 1);
      newScores = {
        ...newScores,
        [stepsKeys[11]]: matrix[1][rowIndex][columnIndex],
      };
    }
    if (
      scores[stepsKeys[11]] &&
      scores[stepsKeys[12]] >= 0 &&
      scores[stepsKeys[13]] >= 0
    ) {
      newScores = {
        ...newScores,
        [stepsKeys[14]]:
          scores[stepsKeys[11]] + scores[stepsKeys[12]] + scores[stepsKeys[13]],
      };
    }
    setScores(newScores);
  }, [
    scores[stepsKeys[0]],
    scores[stepsKeys[1]],
    scores[stepsKeys[2]],
    scores[stepsKeys[3]],
    scores[stepsKeys[5]],
    scores[stepsKeys[6]],
    scores[stepsKeys[8]],
    scores[stepsKeys[9]],
    scores[stepsKeys[10]],
    scores[stepsKeys[12]],
    scores[stepsKeys[13]],
  ]);
  useEffect(() => {
    if (assessmentType === 'reba') return;
    let newScores = scores;
    if (
      scores[stepsKeys[4]] &&
      scores[stepsKeys[5]] >= 0 &&
      scores[stepsKeys[6]] >= 0
    ) {
      newScores = {
        ...newScores,
        [stepsKeys[7]]:
          scores[stepsKeys[4]] + scores[stepsKeys[5]] + scores[stepsKeys[6]],
      };
    }
    if (
      scores[stepsKeys[11]] &&
      scores[stepsKeys[12]] >= 0 &&
      scores[stepsKeys[13]] >= 0
    ) {
      newScores = {
        ...newScores,
        [stepsKeys[14]]:
          scores[stepsKeys[11]] + scores[stepsKeys[12]] + scores[stepsKeys[13]],
      };
    }
    setScores(newScores);
  }, [scores[stepsKeys[4]], scores[stepsKeys[11]]]);
  useEffect(() => {
    if (assessmentType === 'reba') return;
    if (scores[stepsKeys[14]] && scores[stepsKeys[7]]) {
      const rowIndex = scores[stepsKeys[7]] >= 8 ? 7 : scores[stepsKeys[7]] - 1;
      const columnIndex
        = scores[stepsKeys[14]] >= 7 ? 6 : scores[stepsKeys[14]] - 1;
      setTotalScore(matrix[2][rowIndex][columnIndex]);
    }
  }, [scores[stepsKeys[14]], scores[stepsKeys[7]]]);
  useEffect(() => {
    let newScores = { ...scores };
    if (assessmentType !== 'reba') return;
    if (scores[stepsKeys[0]] && scores[stepsKeys[1]] && scores[stepsKeys[2]]) {
      const rowIndex
        = newScores[stepsKeys[1]] - 1 <= 4 ? newScores[stepsKeys[1]] - 1 : 4;
      const columnIndex
        = (newScores[stepsKeys[0]] - 1) * 2 + (newScores[stepsKeys[2]] - 1);
      newScores = {
        ...newScores,
        [stepsKeys[3]]: matrix[0][rowIndex][columnIndex],
      };
    }
    if (scores[stepsKeys[3]] && scores[stepsKeys[4]] >= 0) {
      newScores = {
        ...newScores,
        [stepsKeys[5]]: newScores[stepsKeys[3]] + newScores[stepsKeys[4]],
      };
    }
    if (
      scores[stepsKeys[6]] >= 0 &&
      scores[stepsKeys[7]] &&
      scores[stepsKeys[8]]
    ) {
      const rowIndex
        = newScores[stepsKeys[6]] - 1 >= 0 ? newScores[stepsKeys[6]] - 1 : 0;
      const columnIndex
        = (newScores[stepsKeys[7]] - 1) * 3 + (newScores[stepsKeys[8]] - 1);
      newScores = {
        ...newScores,
        [stepsKeys[9]]: matrix[1][rowIndex][columnIndex],
      };
    }
    if (scores[stepsKeys[9]] && scores[stepsKeys[10]]) {
      newScores = {
        ...newScores,
        [stepsKeys[11]]: newScores[stepsKeys[9]] + newScores[stepsKeys[10]],
      };
    }
    setScores(newScores);
  }, [
    scores[stepsKeys[0]],
    scores[stepsKeys[1]],
    scores[stepsKeys[2]],
    scores[stepsKeys[4]],
    scores[stepsKeys[6]],
    scores[stepsKeys[7]],
    scores[stepsKeys[8]],
    scores[stepsKeys[10]],
  ]);
  useEffect(() => {
    if (assessmentType !== 'reba') return;
    let newScores = scores;
    if (scores[stepsKeys[3]] && scores[stepsKeys[4]]) {
      newScores = {
        ...newScores,
        [stepsKeys[5]]: newScores[stepsKeys[3]] + newScores[stepsKeys[4]],
      };
    }
    if (scores[stepsKeys[9]] && scores[stepsKeys[10]] >= 0) {
      newScores = {
        ...newScores,
        [stepsKeys[11]]: newScores[stepsKeys[9]] + newScores[stepsKeys[10]],
      };
    }
    setScores(newScores);
  }, [scores[stepsKeys[3]], scores[stepsKeys[9]]]);
  useEffect(() => {
    if (assessmentType !== 'reba') return;
    if (
      scores[stepsKeys[5]] &&
      scores[stepsKeys[11]] &&
      scores[stepsKeys[12]] >= 0
    ) {
      const rowIndex
        = scores[stepsKeys[5]] - 1 <= 11 ? scores[stepsKeys[5]] - 1 : 11;
      const columnIndex = scores[stepsKeys[11]] - 1;
      setTotalScore(matrix[2][rowIndex][columnIndex] + scores[stepsKeys[12]]);
    }
  }, [scores[stepsKeys[5]], scores[stepsKeys[11]], scores[stepsKeys[12]]]);
  return (
    <div className={assessment}>
      {structure.map(({ metaTitle, steps }) => (
        <div key={metaTitle}>
          <div className={text(COLORS.GRAY[400], 16)}>
            {t(metaTitle).toUpperCase()}
          </div>
          <div>
            {steps.map(({
              title, values, id, symbol, weight,
            }) => {
              const pictureSteps = values.filter(({ image }) => image);
              const textSteps = values.filter(({ text: t }) => t);
              const radioValues = values
                .filter(({ type: t }) => t === 'radio')
                .map(({ id: i }) => i);
              return (
                <ExpandedPanel
                  expanded={!currentAssessmentId}
                  key={title}
                  title={title}
                  score={_isNull(scores[id]) ? t('GENERAL.NA') : scores[id]}
                  scoreDisabled={_isNull(scores[id])}
                >
                  {pictureSteps && !symbol && (
                    <PictureStepsWrapper
                      steps={pictureSteps}
                      title={t(title)}
                      selectedFields={selectedFields}
                      clickHandler={(i, type) => switchFields(i, type, radioValues)}
                      isSelected={(i) => selectedFields.includes(i)}
                      disabled={assessmentStep === assessmentSteps.view}
                    />
                  )}
                  {textSteps && !symbol && (
                    <TextStepsWrapper
                      steps={textSteps}
                      title={t(title)}
                      selectedFields={selectedFields}
                      weight={weight}
                      weightUnit={weightUnit}
                      clickHandler={(i, type) => switchFields(i, type, radioValues)}
                      isSelected={(i) => selectedFields.includes(i)}
                      disabled={assessmentStep === assessmentSteps.view}

                    />
                  )}
                  {symbol && <div>{t(values[0]?.label)}</div>}
                </ExpandedPanel>
              );
            })}
          </div>
        </div>
      ))}
      {totalScore ? (
        <AssessmentTotalScore
          score={totalScore}
          description={getTotalScoreDescription(totalScore, assessmentType, t)}
          color={getColorForTotalScore(totalScore, assessmentType)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Assessment;
