import { COLORS } from '../../constants/colors';

export default function getColor(index, type, value, max) {
  const isDanger = value / max >= 0.5;
  if (type === 'shoulders') {
    if (index === 0) return COLORS.GRAY[100];
    if (index === 1) return COLORS.GRAY[100];
    if (index === 2) return isDanger ? COLORS.YELLOW[200] : COLORS.LIME[600];
    if (index === 3) return isDanger ? COLORS.ORANGE[300] : COLORS.YELLOW[200];
    if (index === 4) return COLORS.RED[400];
    if (index === 5) return isDanger ? COLORS.RED[800] : COLORS.RED[400];
  }
  if (type === 'spine') {
    if (index === 0) return COLORS.GRAY[100];
    if (index === 1) return isDanger ? COLORS.YELLOW[200] : COLORS.LIME[600];
    if (index === 2) return isDanger ? COLORS.ORANGE[300] : COLORS.YELLOW[200];
    if (index === 3) return COLORS.RED[400];
  }

  return null;
}
