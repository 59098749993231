// source: api/dashboard-admin/v1/wearable_settings_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.dashboard_admin.ClipgoInputLoginMethod', null, global);
goog.exportSymbol('proto.dashboard_admin.TypesWearingDevice', null, global);
goog.exportSymbol('proto.dashboard_admin.WearableSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.WearableSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.WearableSettings.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.WearableSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.WearableSettings.displayName = 'proto.dashboard_admin.WearableSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.TypesWearingDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.TypesWearingDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.TypesWearingDevice.displayName = 'proto.dashboard_admin.TypesWearingDevice';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.WearableSettings.repeatedFields_ = [2,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.WearableSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.WearableSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.WearableSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.WearableSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeWearing: (f = msg.getTypeWearing()) && proto.dashboard_admin.TypesWearingDevice.toObject(includeInstance, f),
    availableCoachingProgramIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    firmwareId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inputLoginMethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tierId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    employeeLoginPrompt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    workdayTimeMinutes: jspb.Message.getFieldWithDefault(msg, 7, 0),
    workdayIntervalMinutes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    programTargetDays: jspb.Message.getFieldWithDefault(msg, 9, 0),
    collectJobRoleOnRegister: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    reportPeopleNames: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    collectEmployeeName: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    availableLanguagesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    nicknameRegexpTemplate: jspb.Message.getFieldWithDefault(msg, 14, ""),
    disableNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    downloadDataNotOnlyFromDevicesOnCharge: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    showOnboardingPages: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    enableGamification: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.WearableSettings}
 */
proto.dashboard_admin.WearableSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.WearableSettings;
  return proto.dashboard_admin.WearableSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.WearableSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.WearableSettings}
 */
proto.dashboard_admin.WearableSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dashboard_admin.TypesWearingDevice;
      reader.readMessage(value,proto.dashboard_admin.TypesWearingDevice.deserializeBinaryFromReader);
      msg.setTypeWearing(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAvailableCoachingProgramIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirmwareId(value);
      break;
    case 4:
      var value = /** @type {!proto.dashboard_admin.ClipgoInputLoginMethod} */ (reader.readEnum());
      msg.setInputLoginMethod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTierId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeLoginPrompt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkdayTimeMinutes(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkdayIntervalMinutes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgramTargetDays(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectJobRoleOnRegister(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReportPeopleNames(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectEmployeeName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableLanguages(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNicknameRegexpTemplate(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableNotifications(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnCharge(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOnboardingPages(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableGamification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.WearableSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.WearableSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.WearableSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.WearableSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeWearing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dashboard_admin.TypesWearingDevice.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCoachingProgramIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getFirmwareId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getInputLoginMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTierId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEmployeeLoginPrompt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWorkdayTimeMinutes();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getWorkdayIntervalMinutes();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getProgramTargetDays();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCollectJobRoleOnRegister();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getReportPeopleNames();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCollectEmployeeName();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAvailableLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getNicknameRegexpTemplate();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDisableNotifications();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnCharge();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getShowOnboardingPages();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getEnableGamification();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional TypesWearingDevice type_wearing = 1;
 * @return {?proto.dashboard_admin.TypesWearingDevice}
 */
proto.dashboard_admin.WearableSettings.prototype.getTypeWearing = function() {
  return /** @type{?proto.dashboard_admin.TypesWearingDevice} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.TypesWearingDevice, 1));
};


/**
 * @param {?proto.dashboard_admin.TypesWearingDevice|undefined} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
*/
proto.dashboard_admin.WearableSettings.prototype.setTypeWearing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.clearTypeWearing = function() {
  return this.setTypeWearing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.hasTypeWearing = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int32 available_coaching_program_ids = 2;
 * @return {!Array<number>}
 */
proto.dashboard_admin.WearableSettings.prototype.getAvailableCoachingProgramIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setAvailableCoachingProgramIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.addAvailableCoachingProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.clearAvailableCoachingProgramIdsList = function() {
  return this.setAvailableCoachingProgramIdsList([]);
};


/**
 * optional int32 firmware_id = 3;
 * @return {number}
 */
proto.dashboard_admin.WearableSettings.prototype.getFirmwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setFirmwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ClipgoInputLoginMethod input_login_method = 4;
 * @return {!proto.dashboard_admin.ClipgoInputLoginMethod}
 */
proto.dashboard_admin.WearableSettings.prototype.getInputLoginMethod = function() {
  return /** @type {!proto.dashboard_admin.ClipgoInputLoginMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dashboard_admin.ClipgoInputLoginMethod} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setInputLoginMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 tier_id = 5;
 * @return {number}
 */
proto.dashboard_admin.WearableSettings.prototype.getTierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setTierId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string employee_login_prompt = 6;
 * @return {string}
 */
proto.dashboard_admin.WearableSettings.prototype.getEmployeeLoginPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setEmployeeLoginPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 workday_time_minutes = 7;
 * @return {number}
 */
proto.dashboard_admin.WearableSettings.prototype.getWorkdayTimeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setWorkdayTimeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 workday_interval_minutes = 8;
 * @return {number}
 */
proto.dashboard_admin.WearableSettings.prototype.getWorkdayIntervalMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setWorkdayIntervalMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 program_target_days = 9;
 * @return {number}
 */
proto.dashboard_admin.WearableSettings.prototype.getProgramTargetDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setProgramTargetDays = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool collect_job_role_on_register = 10;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getCollectJobRoleOnRegister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setCollectJobRoleOnRegister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool report_people_names = 11;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getReportPeopleNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setReportPeopleNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool collect_employee_name = 12;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getCollectEmployeeName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setCollectEmployeeName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated string available_languages = 13;
 * @return {!Array<string>}
 */
proto.dashboard_admin.WearableSettings.prototype.getAvailableLanguagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setAvailableLanguagesList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.addAvailableLanguages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.clearAvailableLanguagesList = function() {
  return this.setAvailableLanguagesList([]);
};


/**
 * optional string nickname_regexp_template = 14;
 * @return {string}
 */
proto.dashboard_admin.WearableSettings.prototype.getNicknameRegexpTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setNicknameRegexpTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool disable_notifications = 15;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getDisableNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setDisableNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge = 16;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getDownloadDataNotOnlyFromDevicesOnCharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setDownloadDataNotOnlyFromDevicesOnCharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool show_onboarding_pages = 17;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getShowOnboardingPages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setShowOnboardingPages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool enable_gamification = 18;
 * @return {boolean}
 */
proto.dashboard_admin.WearableSettings.prototype.getEnableGamification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.WearableSettings} returns this
 */
proto.dashboard_admin.WearableSettings.prototype.setEnableGamification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.TypesWearingDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.TypesWearingDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.TypesWearingDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.TypesWearingDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    spine: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    shoulder: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    headset: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    helmet: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.TypesWearingDevice}
 */
proto.dashboard_admin.TypesWearingDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.TypesWearingDevice;
  return proto.dashboard_admin.TypesWearingDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.TypesWearingDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.TypesWearingDevice}
 */
proto.dashboard_admin.TypesWearingDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpine(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShoulder(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeadset(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHelmet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.TypesWearingDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.TypesWearingDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.TypesWearingDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.TypesWearingDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpine();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getShoulder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHeadset();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHelmet();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool spine = 1;
 * @return {boolean}
 */
proto.dashboard_admin.TypesWearingDevice.prototype.getSpine = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.TypesWearingDevice} returns this
 */
proto.dashboard_admin.TypesWearingDevice.prototype.setSpine = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool shoulder = 2;
 * @return {boolean}
 */
proto.dashboard_admin.TypesWearingDevice.prototype.getShoulder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.TypesWearingDevice} returns this
 */
proto.dashboard_admin.TypesWearingDevice.prototype.setShoulder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool headset = 3;
 * @return {boolean}
 */
proto.dashboard_admin.TypesWearingDevice.prototype.getHeadset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.TypesWearingDevice} returns this
 */
proto.dashboard_admin.TypesWearingDevice.prototype.setHeadset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool helmet = 4;
 * @return {boolean}
 */
proto.dashboard_admin.TypesWearingDevice.prototype.getHelmet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.TypesWearingDevice} returns this
 */
proto.dashboard_admin.TypesWearingDevice.prototype.setHelmet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.dashboard_admin.ClipgoInputLoginMethod = {
  NONE: 0,
  ONLY_CAMERA: 1,
  BOTH_CAMERA: 2,
  ONLY_KEYBOARD: 3,
  BOTH_KEYBOARD: 4,
  SCAN_GUN: 5
};

goog.object.extend(exports, proto.dashboard_admin);
