/* eslint no-param-reassign: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-shadow: 0 */
/* eslint no-underscore-dangle:0 */
import * as d3 from 'd3';
import Chart from '../Chart';
import getTextForLegend from './utils/getTextForLegend';
import { COLORS } from '../../../../../constants/colors';
import { emptyBarChartData } from '../../../constants/emptyChartData/emptyBarChartData';

const barChartMargins = {
  top: 30,
  right: 0,
  bottom: 40,
  left: 0,
};

const defaultOutlierColor = COLORS.RED[600];

export default class BarChart extends Chart {
  constructor(id) {
    super(id);

    this._maxChartYValue = null;
    this._tooltipText = '';
    this._yAttrAdjuster = (d, y) => y(d.sum);
    this._heightAttrAdjuster = (d, y) => y(0) - y(d.sum);
    this._outlierColor = defaultOutlierColor;
    this._checkOutlier = (d, max) => d.sum > max;
    this._withExtraText = false;

    this.margin = barChartMargins;
  }

  setWithExtraText(bool) {
    this._withExtraText = bool;
  }

  get withExtraText() {
    return this._withExtraText;
  }

  // Sets max Y axis value
  setMaxChartYValue(maxValue) {
    this._maxChartYValue = maxValue;
  }

  get maxChartYValue() {
    return this._maxChartYValue;
  }

  setTooltipText(text) {
    this._tooltipText = text;
  }

  get tooltipText() {
    return this._tooltipText;
  }

  /* Used with maxYChartValue.
   * Sets y value for normal bars and outliers.
   * By default draws normal y.
   */
  setYAttrAdjuster(fn) {
    this._yAttrAdjuster = fn;
  }

  setTranslation(t) {
    this.t = t;
  }

  get yAttrAdjuster() {
    return this._yAttrAdjuster;
  }

  /* Used with maxYChartValue.
   * Sets height value for normal bars and outliers.
   * By default draws full height.
   */
  setHeightAttrAdjuster(fn) {
    this._heightAttrAdjuster = fn;
  }

  get heightAttrAdjuster() {
    return this._heightAttrAdjuster;
  }

  setOutlierColor(color) {
    this._outlierColor = color;
  }

  get outlierColor() {
    return this._outlierColor;
  }

  // Checks if bar represents an outlier
  setCheckOutlier(fn) {
    this._checkOutlier = fn;
  }

  get checkOutlier() {
    return this._checkOutlier;
  }

  // eslint-disable-next-line class-methods-use-this
  drawLegend(data = []) {
    let overallHazardsSum;
    const hasData = !!data.length;
    if (hasData) {
      overallHazardsSum = data.reduce((acc, session) => acc + session.sum, 0);
    } else {
      overallHazardsSum = -1;
    }

    return (g) => g.append('g').call((g) => g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('fill', COLORS.GRAY[400])
      .attr('style', 'font-size: 1.25em')
      .text(() => this.t(getTextForLegend(overallHazardsSum)).toUpperCase()));
  }

  getX() {
    return d3
      .scaleBand()
      .domain(d3.range(this.data.length))
      .range([this.margin.left + 20, this.width - this.margin.right])
      .padding(1);
  }

  getY() {
    return d3
      .scaleLinear()
      .domain([0, this.maxChartYValue || d3.max(this.data, (d) => d.sum || 10)])
      .nice()
      .range([this.height - this.margin.bottom, this.margin.top]);
  }

  drawXAxis(x, data) {
    return (g) => g
      .attr('transform', `translate(0,${this.height - this.margin.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .tickFormat((i) => this.xAxisTickFormatter(i, data))
          .tickSizeOuter(0),
      )
      .call((g) => g
        .selectAll('.tick')
        .attr('opacity', 0.5)
        .append('text')
        .attr('class', 'extra-text')
        .attr('transform', 'translate(0, 30)')
        .attr('fill', COLORS.GRAY[950])
        .text((d, i) => {
          if (!this.withExtraText) return '';
          return data[i]?.WorkersCount
            ? data[i]?.WorkersCount === 1
              ? `(${data[i]?.WorkersCount} ${this.t(
                'WEARABLES.OVERVIEW.WORKER',
              )})`
              : `(${data[i]?.WorkersCount} ${this.t(
                'WEARABLES.OVERVIEW.WORKERS',
              )})`
            : `(0 ${this.t('WEARABLES.OVERVIEW.WORKERS')})`;
        }))
      .call((g) => g.select('.domain').attr('opacity', 0))
      .call((g) => {
        if (!this.withRotatedXAxisTickText) return;
        this.rotateXAxisTickText(g);
      });
  }

  drawYAxis(y) {
    return (g) => g
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(
        d3
          .axisRight(y)
          .tickSize(this.width - this.margin.left - this.margin.right)
          .tickFormat(this.yAxisTickFormatter()),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) => g
        .selectAll('.tick:not(:first-of-type) line')
        .attr('stroke-opacity', 0.5)
        .attr('stroke-dasharray', '2'))
      .call((g) => g.selectAll('.tick:first-of-type line').attr('stroke-opacity', 0.5))
      .call((g) => g
        .selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', -4)
        .attr('fill', 'black')
        .attr('opacity', 0.5));
  }

  draw(color) {
    const currentTooltip = d3.select(`#${this.id}_tooltip`);
    if (currentTooltip) currentTooltip.remove();
    const x = this.getX();
    const y = this.getY();

    const xAxis = this.drawXAxis(x, this.data);
    const yAxis = this.drawYAxis(y);

    this.drawSvg([0, 0, this.width, this.height]);

    const svg = d3.select(`svg.${this.id}-chart`);

    const tooltip = d3
      .select('#page_layer')
      .append('div')
      .attr('id', `${this.id}_tooltip`)
      .attr('class', 'tooltip')
      .style('opacity', 0);

    svg.append('g').call(xAxis);

    svg
      .append('g')
      .call(yAxis)
      .call((g) => g.select('.domain').remove());

    svg
      .append('g')
      .selectAll('rect')
      .data(this.data)
      .join('rect')
      .attr('x', (d, i) => x(i))
      .attr('y', (d) => this.yAttrAdjuster(d, y))
      .attr('height', (d) => this.heightAttrAdjuster(d, y))
      .attr('width', 16)
      .attr('fill', (d) => (this.checkOutlier(d, this.maxChartYValue) ? color : this.outlierColor))
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(`${d.sum}${this.tooltipText} `)
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', (d) => {
        tooltip.transition().duration(500).style('opacity', 0);
      })
      .append('title');

    svg
      .append('g')
      .attr('class', `legend-${this.id}`)
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      .call(this.drawLegend(this.data));

    this.decimateYAxisTicks();
    this.setTickTextAttr('Mulish', '0.6rem');
  }

  drawEmpty(emptyData) {
    const x = d3
      .scaleBand()
      .domain(d3.range(emptyData.length))
      .range([this.margin.left + 20, this.width - this.margin.right])
      .padding(0.6);
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(emptyData, (d) => d.sum)])
      .nice()
      .range([this.height - this.margin.bottom, this.margin.top]);

    const xAxis = this.drawXAxis(x, emptyData);

    const yAxis = (g) => g
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(
        d3
          .axisRight(y)
          .tickSize(this.width - this.margin.left - this.margin.right),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) => g
        .selectAll('.tick:not(:first-of-type) line')
        .attr('stroke-opacity', 0.5)
        .attr('stroke-dasharray', '2'))
      .call((g) => g.selectAll('.tick:first-of-type line').attr('stroke-opacity', 0.5))
      .call((g) => g
        .selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', -4)
        .attr('fill', 'black')
        .attr('opacity', 0.5));

    d3.select(`#${this.id}`)
      .append('svg')
      .attr('class', `${this.id}-chart`)
      .attr('viewBox', [0, 0, this.width, this.height]);

    const svg = d3.select(`svg.${this.id}-chart`);

    svg.append('g').call(xAxis);

    svg
      .append('g')
      .call(yAxis)
      .call((g) => g.select('.domain').remove());

    svg
      .append('g')
      .attr('fill', 'transparent')
      .selectAll('rect')
      .data(emptyBarChartData)
      .join('rect')
      .attr('x', (d, i) => x(i))
      .attr('y', (d) => y(d.sum))
      .attr('height', (d) => y(0) - y(d.sum))
      .attr('width', x.bandwidth());

    svg
      .append('g')
      .attr('class', `legend-${this.id}`)
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      .call(this.drawLegend());

    this.decimateYAxisTicks();
  }
}
