import React, { useState } from 'react';
import { columnSmall, pl0, pr0 } from '../../../assets/soter-flex-styles';
import DropdownActionList from '../../../lib/ui/DropdownActionList/DropdownActionList';

const RebaWeightsDropdown = ({
  userActions,
  setUserActions,
  setRebaWeights,
  rebaWeights,
  videoWidth,
  children,
  setIsButtonDisabled
}) => {

  const [openDropdown, setOpenDropdown] = useState(false);
  const [order, setOrder] = useState(1)

  const barWidth = 100;
  const barShape = {
    height: 16,
    fill: '#2096F3',
    // cornerRadius: 2,
    width: barWidth
  };

  let startingPoint = 0;
  const yAxisForFirstRow = 48;
  const yAxisForSecondRow = 88;
  const distance = 40;

  const openDialog = (id) => {
    setIsButtonDisabled(false)
    let selected = userActions?.find((action) => action.id === +id);
    let barPositions = [];
    rebaWeights?.map((w) => {
      barPositions.push([w.x, w.x + w.width])
    });
    let barsCount = barPositions.length;
    barPositions.sort((a, b) => a[1] - b[1])
    for (let i = 0; i < barsCount; i++) {
      const [_, barEnd] = barPositions[i];
      let previousEnd = barEnd;
      let barEnds = [];
      let barBeginnings = [];
      let gaps = [];
      let hasGap = false;
      for (let j = 0; j < barsCount; j++) {
        const [barStart, barEnd] = barPositions[j];
        barEnds.push(barEnd);
        barBeginnings.push(barStart);
        if (i === j) continue;
        let nextStart = 0;
        if (j > i) {
          nextStart = barStart;
        } else {
          continue;
        }
        hasGap = previousEnd + barWidth <= nextStart ? true : false;
        gaps.push(hasGap)
      }
      let min = Math.min(...barBeginnings);
      let hasGaps = gaps.every(gap => gap === true);
      if (previousEnd + barWidth > videoWidth) {
        startingPoint = (min - barWidth) < 0 ? 0 : (min - barWidth);
      } else {
        startingPoint = previousEnd;
      }
      if (hasGaps) {
        break;
      }
    }

    setUserActions([...userActions]);
    selected.x = startingPoint;
    selected.order = order;
    setOrder(order + 1)


    if (!rebaWeights.length) {
      selected.y = yAxisForFirstRow;
    } else if (rebaWeights.length === 1) {
      selected.y = yAxisForSecondRow;
    } else {
      selected.y = yAxisForFirstRow + rebaWeights.length * distance;
    }

    setRebaWeights((rest) => [...rest, { ...barShape, ...selected }]);
  };

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const DropToggler = React.Children.map(children, (child) =>
    React.cloneElement(
      child,
      {
        toggleDrop: toggleDropdown,
        state: openDropdown,
      },
      <DropdownActionList
        actionHandler={openDialog}
        actions={userActions}
        style={{ fontSize: '14px' }}
      />
    )
  );

  return (
    <div>
      <div className={`${columnSmall} ${pl0} ${pr0}`}>{DropToggler}</div>
    </div>
  );
};

export default RebaWeightsDropdown;
