// source: api/dashboard-admin/v1/company_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard$admin_v1_task_settings_message_pb = require('../../../api/dashboard-admin/v1/task_settings_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_task_settings_message_pb);
var api_dashboard$admin_v1_wearable_settings_message_pb = require('../../../api/dashboard-admin/v1/wearable_settings_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_wearable_settings_message_pb);
var api_dashboard$admin_v1_product_message_pb = require('../../../api/dashboard-admin/v1/product_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_product_message_pb);
var api_dashboard$admin_v1_tier_message_pb = require('../../../api/dashboard-admin/v1/tier_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_tier_message_pb);
var api_dashboard$admin_v1_coaching_program_message_pb = require('../../../api/dashboard-admin/v1/coaching_program_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_coaching_program_message_pb);
var api_dashboard$admin_v1_firmware_message_pb = require('../../../api/dashboard-admin/v1/firmware_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_firmware_message_pb);
var api_dashboard$admin_v1_department_message_pb = require('../../../api/dashboard-admin/v1/department_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_department_message_pb);
var api_dashboard$admin_v1_site_message_pb = require('../../../api/dashboard-admin/v1/site_message_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_site_message_pb);
var api_dashboard$admin_v1_base_enum_pb = require('../../../api/dashboard-admin/v1/base_enum_pb.js');
goog.object.extend(proto, api_dashboard$admin_v1_base_enum_pb);
goog.exportSymbol('proto.dashboard_admin.Company', null, global);
goog.exportSymbol('proto.dashboard_admin.CompanyReseller', null, global);
goog.exportSymbol('proto.dashboard_admin.CreateCompanyRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.DeleteCompanyRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.DeleteCompanyResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.EditCompanyRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.Feedback', null, global);
goog.exportSymbol('proto.dashboard_admin.FeedbackV2', null, global);
goog.exportSymbol('proto.dashboard_admin.FeedbackV2.DashboardUser', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableCoachingProgramsRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableCoachingProgramsResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableFirmwaresRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableFirmwaresResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableLanguagesRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableLanguagesResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableTiersRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetAvailableTiersResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetCompaniesRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetCompaniesResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetCompaniesResponse.Company', null, global);
goog.exportSymbol('proto.dashboard_admin.GetCompanyInfoRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetCompanyInfoResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetDashboardFeedbackRequest', null, global);
goog.exportSymbol('proto.dashboard_admin.GetDashboardUsersFeedbackResponse', null, global);
goog.exportSymbol('proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.EditCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.EditCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.EditCompanyRequest.displayName = 'proto.dashboard_admin.EditCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.CreateCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.CreateCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.CreateCompanyRequest.displayName = 'proto.dashboard_admin.CreateCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.Company.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.Company.displayName = 'proto.dashboard_admin.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetCompaniesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetCompaniesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetCompaniesRequest.displayName = 'proto.dashboard_admin.GetCompaniesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetCompaniesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetCompaniesResponse.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetCompaniesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetCompaniesResponse.displayName = 'proto.dashboard_admin.GetCompaniesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetCompaniesResponse.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetCompaniesResponse.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetCompaniesResponse.Company.displayName = 'proto.dashboard_admin.GetCompaniesResponse.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetCompanyInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetCompanyInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetCompanyInfoRequest.displayName = 'proto.dashboard_admin.GetCompanyInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.CompanyReseller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.CompanyReseller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.CompanyReseller.displayName = 'proto.dashboard_admin.CompanyReseller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetCompanyInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetCompanyInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetCompanyInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetCompanyInfoResponse.displayName = 'proto.dashboard_admin.GetCompanyInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableFirmwaresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableFirmwaresRequest.displayName = 'proto.dashboard_admin.GetAvailableFirmwaresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetAvailableFirmwaresResponse.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableFirmwaresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableFirmwaresResponse.displayName = 'proto.dashboard_admin.GetAvailableFirmwaresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableTiersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableTiersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableTiersRequest.displayName = 'proto.dashboard_admin.GetAvailableTiersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableTiersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetAvailableTiersResponse.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableTiersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableTiersResponse.displayName = 'proto.dashboard_admin.GetAvailableTiersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableCoachingProgramsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableCoachingProgramsRequest.displayName = 'proto.dashboard_admin.GetAvailableCoachingProgramsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetAvailableCoachingProgramsResponse.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableCoachingProgramsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableCoachingProgramsResponse.displayName = 'proto.dashboard_admin.GetAvailableCoachingProgramsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableLanguagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableLanguagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableLanguagesRequest.displayName = 'proto.dashboard_admin.GetAvailableLanguagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetAvailableLanguagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetAvailableLanguagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetAvailableLanguagesResponse.displayName = 'proto.dashboard_admin.GetAvailableLanguagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.DeleteCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.DeleteCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.DeleteCompanyRequest.displayName = 'proto.dashboard_admin.DeleteCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.DeleteCompanyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.DeleteCompanyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.DeleteCompanyResponse.displayName = 'proto.dashboard_admin.DeleteCompanyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetDashboardFeedbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.GetDashboardFeedbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetDashboardFeedbackRequest.displayName = 'proto.dashboard_admin.GetDashboardFeedbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.Feedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.Feedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.Feedback.displayName = 'proto.dashboard_admin.Feedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetDashboardUsersFeedbackResponse.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetDashboardUsersFeedbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetDashboardUsersFeedbackResponse.displayName = 'proto.dashboard_admin.GetDashboardUsersFeedbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.FeedbackV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.FeedbackV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.FeedbackV2.displayName = 'proto.dashboard_admin.FeedbackV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.FeedbackV2.DashboardUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.FeedbackV2.DashboardUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.FeedbackV2.DashboardUser.displayName = 'proto.dashboard_admin.FeedbackV2.DashboardUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.repeatedFields_, null);
};
goog.inherits(proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.displayName = 'proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.EditCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.EditCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.EditCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    availableProducts: (f = msg.getAvailableProducts()) && api_dashboard$admin_v1_product_message_pb.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.EditCompanyRequest}
 */
proto.dashboard_admin.EditCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.EditCompanyRequest;
  return proto.dashboard_admin.EditCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.EditCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.EditCompanyRequest}
 */
proto.dashboard_admin.EditCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 5:
      var value = new api_dashboard$admin_v1_product_message_pb.AvailableProducts;
      reader.readMessage(value,api_dashboard$admin_v1_product_message_pb.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 6:
      var value = new api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings;
      reader.readMessage(value,api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 8:
      var value = new api_dashboard$admin_v1_task_settings_message_pb.TaskSettings;
      reader.readMessage(value,api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.EditCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.EditCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.EditCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_dashboard$admin_v1_product_message_pb.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 partner_id = 4;
 * @return {number}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AvailableProducts available_products = 5;
 * @return {?proto.dashboard_admin.AvailableProducts}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getAvailableProducts = function() {
  return /** @type{?proto.dashboard_admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_product_message_pb.AvailableProducts, 5));
};


/**
 * @param {?proto.dashboard_admin.AvailableProducts|undefined} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
*/
proto.dashboard_admin.EditCompanyRequest.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WearableSettings wearable_settings = 6;
 * @return {?proto.dashboard_admin.WearableSettings}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getWearableSettings = function() {
  return /** @type{?proto.dashboard_admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings, 6));
};


/**
 * @param {?proto.dashboard_admin.WearableSettings|undefined} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
*/
proto.dashboard_admin.EditCompanyRequest.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TaskSettings soter_task_settings = 8;
 * @return {?proto.dashboard_admin.TaskSettings}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.dashboard_admin.TaskSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_task_settings_message_pb.TaskSettings, 8));
};


/**
 * @param {?proto.dashboard_admin.TaskSettings|undefined} value
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
*/
proto.dashboard_admin.EditCompanyRequest.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.EditCompanyRequest} returns this
 */
proto.dashboard_admin.EditCompanyRequest.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.EditCompanyRequest.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.CreateCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.CreateCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.CreateCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    availableProducts: (f = msg.getAvailableProducts()) && api_dashboard$admin_v1_product_message_pb.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.CreateCompanyRequest}
 */
proto.dashboard_admin.CreateCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.CreateCompanyRequest;
  return proto.dashboard_admin.CreateCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.CreateCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.CreateCompanyRequest}
 */
proto.dashboard_admin.CreateCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 4:
      var value = new api_dashboard$admin_v1_product_message_pb.AvailableProducts;
      reader.readMessage(value,api_dashboard$admin_v1_product_message_pb.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 5:
      var value = new api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings;
      reader.readMessage(value,api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 6:
      var value = new api_dashboard$admin_v1_task_settings_message_pb.TaskSettings;
      reader.readMessage(value,api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.CreateCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.CreateCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.CreateCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      api_dashboard$admin_v1_product_message_pb.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region = 2;
 * @return {string}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 partner_id = 3;
 * @return {number}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AvailableProducts available_products = 4;
 * @return {?proto.dashboard_admin.AvailableProducts}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.getAvailableProducts = function() {
  return /** @type{?proto.dashboard_admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_product_message_pb.AvailableProducts, 4));
};


/**
 * @param {?proto.dashboard_admin.AvailableProducts|undefined} value
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
*/
proto.dashboard_admin.CreateCompanyRequest.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WearableSettings wearable_settings = 5;
 * @return {?proto.dashboard_admin.WearableSettings}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.getWearableSettings = function() {
  return /** @type{?proto.dashboard_admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings, 5));
};


/**
 * @param {?proto.dashboard_admin.WearableSettings|undefined} value
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
*/
proto.dashboard_admin.CreateCompanyRequest.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TaskSettings soter_task_settings = 6;
 * @return {?proto.dashboard_admin.TaskSettings}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.dashboard_admin.TaskSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_task_settings_message_pb.TaskSettings, 6));
};


/**
 * @param {?proto.dashboard_admin.TaskSettings|undefined} value
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
*/
proto.dashboard_admin.CreateCompanyRequest.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.CreateCompanyRequest} returns this
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.CreateCompanyRequest.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.Company.repeatedFields_ = [9,10,11,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    availableProducts: (f = msg.getAvailableProducts()) && api_dashboard$admin_v1_product_message_pb.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.toObject(includeInstance, f),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard$admin_v1_department_message_pb.Department.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    api_dashboard$admin_v1_site_message_pb.Site.toObject, includeInstance),
    availableCoachingProgramsList: jspb.Message.toObjectList(msg.getAvailableCoachingProgramsList(),
    api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.toObject, includeInstance),
    tier: (f = msg.getTier()) && api_dashboard$admin_v1_tier_message_pb.Tier.toObject(includeInstance, f),
    availableFirmwaresList: jspb.Message.toObjectList(msg.getAvailableFirmwaresList(),
    api_dashboard$admin_v1_firmware_message_pb.Firmware.toObject, includeInstance),
    currentFirmware: (f = msg.getCurrentFirmware()) && api_dashboard$admin_v1_firmware_message_pb.Firmware.toObject(includeInstance, f),
    deviceCount: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.Company}
 */
proto.dashboard_admin.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.Company;
  return proto.dashboard_admin.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.Company}
 */
proto.dashboard_admin.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new api_dashboard$admin_v1_product_message_pb.AvailableProducts;
      reader.readMessage(value,api_dashboard$admin_v1_product_message_pb.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 7:
      var value = new api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings;
      reader.readMessage(value,api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 8:
      var value = new api_dashboard$admin_v1_task_settings_message_pb.TaskSettings;
      reader.readMessage(value,api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    case 9:
      var value = new api_dashboard$admin_v1_department_message_pb.Department;
      reader.readMessage(value,api_dashboard$admin_v1_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 10:
      var value = new api_dashboard$admin_v1_site_message_pb.Site;
      reader.readMessage(value,api_dashboard$admin_v1_site_message_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 11:
      var value = new api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram;
      reader.readMessage(value,api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.deserializeBinaryFromReader);
      msg.addAvailableCoachingPrograms(value);
      break;
    case 12:
      var value = new api_dashboard$admin_v1_tier_message_pb.Tier;
      reader.readMessage(value,api_dashboard$admin_v1_tier_message_pb.Tier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 13:
      var value = new api_dashboard$admin_v1_firmware_message_pb.Firmware;
      reader.readMessage(value,api_dashboard$admin_v1_firmware_message_pb.Firmware.deserializeBinaryFromReader);
      msg.addAvailableFirmwares(value);
      break;
    case 14:
      var value = new api_dashboard$admin_v1_firmware_message_pb.Firmware;
      reader.readMessage(value,api_dashboard$admin_v1_firmware_message_pb.Firmware.deserializeBinaryFromReader);
      msg.setCurrentFirmware(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard$admin_v1_product_message_pb.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      api_dashboard$admin_v1_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      api_dashboard$admin_v1_site_message_pb.Site.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCoachingProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      api_dashboard$admin_v1_tier_message_pb.Tier.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFirmwaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      api_dashboard$admin_v1_firmware_message_pb.Firmware.serializeBinaryToWriter
    );
  }
  f = message.getCurrentFirmware();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      api_dashboard$admin_v1_firmware_message_pb.Firmware.serializeBinaryToWriter
    );
  }
  f = message.getDeviceCount();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.Company.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dashboard_admin.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.dashboard_admin.Company.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 partner_id = 4;
 * @return {number}
 */
proto.dashboard_admin.Company.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.dashboard_admin.Company.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional AvailableProducts available_products = 6;
 * @return {?proto.dashboard_admin.AvailableProducts}
 */
proto.dashboard_admin.Company.prototype.getAvailableProducts = function() {
  return /** @type{?proto.dashboard_admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_product_message_pb.AvailableProducts, 6));
};


/**
 * @param {?proto.dashboard_admin.AvailableProducts|undefined} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.Company.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional WearableSettings wearable_settings = 7;
 * @return {?proto.dashboard_admin.WearableSettings}
 */
proto.dashboard_admin.Company.prototype.getWearableSettings = function() {
  return /** @type{?proto.dashboard_admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings, 7));
};


/**
 * @param {?proto.dashboard_admin.WearableSettings|undefined} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.Company.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TaskSettings soter_task_settings = 8;
 * @return {?proto.dashboard_admin.TaskSettings}
 */
proto.dashboard_admin.Company.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.dashboard_admin.TaskSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_task_settings_message_pb.TaskSettings, 8));
};


/**
 * @param {?proto.dashboard_admin.TaskSettings|undefined} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.Company.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Department departments = 9;
 * @return {!Array<!proto.dashboard_admin.Department>}
 */
proto.dashboard_admin.Company.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_department_message_pb.Department, 9));
};


/**
 * @param {!Array<!proto.dashboard_admin.Department>} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.dashboard_admin.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Department}
 */
proto.dashboard_admin.Company.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.dashboard_admin.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * repeated Site sites = 10;
 * @return {!Array<!proto.dashboard_admin.Site>}
 */
proto.dashboard_admin.Company.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_site_message_pb.Site, 10));
};


/**
 * @param {!Array<!proto.dashboard_admin.Site>} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.dashboard_admin.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Site}
 */
proto.dashboard_admin.Company.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.dashboard_admin.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * repeated CoachingProgram available_coaching_programs = 11;
 * @return {!Array<!proto.dashboard_admin.CoachingProgram>}
 */
proto.dashboard_admin.Company.prototype.getAvailableCoachingProgramsList = function() {
  return /** @type{!Array<!proto.dashboard_admin.CoachingProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram, 11));
};


/**
 * @param {!Array<!proto.dashboard_admin.CoachingProgram>} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setAvailableCoachingProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.dashboard_admin.CoachingProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.CoachingProgram}
 */
proto.dashboard_admin.Company.prototype.addAvailableCoachingPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.dashboard_admin.CoachingProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearAvailableCoachingProgramsList = function() {
  return this.setAvailableCoachingProgramsList([]);
};


/**
 * optional Tier tier = 12;
 * @return {?proto.dashboard_admin.Tier}
 */
proto.dashboard_admin.Company.prototype.getTier = function() {
  return /** @type{?proto.dashboard_admin.Tier} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_tier_message_pb.Tier, 12));
};


/**
 * @param {?proto.dashboard_admin.Tier|undefined} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.Company.prototype.hasTier = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated Firmware available_firmwares = 13;
 * @return {!Array<!proto.dashboard_admin.Firmware>}
 */
proto.dashboard_admin.Company.prototype.getAvailableFirmwaresList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Firmware>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_firmware_message_pb.Firmware, 13));
};


/**
 * @param {!Array<!proto.dashboard_admin.Firmware>} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setAvailableFirmwaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.dashboard_admin.Firmware=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Firmware}
 */
proto.dashboard_admin.Company.prototype.addAvailableFirmwares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.dashboard_admin.Firmware, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearAvailableFirmwaresList = function() {
  return this.setAvailableFirmwaresList([]);
};


/**
 * optional Firmware current_firmware = 14;
 * @return {?proto.dashboard_admin.Firmware}
 */
proto.dashboard_admin.Company.prototype.getCurrentFirmware = function() {
  return /** @type{?proto.dashboard_admin.Firmware} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_firmware_message_pb.Firmware, 14));
};


/**
 * @param {?proto.dashboard_admin.Firmware|undefined} value
 * @return {!proto.dashboard_admin.Company} returns this
*/
proto.dashboard_admin.Company.prototype.setCurrentFirmware = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.clearCurrentFirmware = function() {
  return this.setCurrentFirmware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.Company.prototype.hasCurrentFirmware = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int32 device_count = 15;
 * @return {number}
 */
proto.dashboard_admin.Company.prototype.getDeviceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Company} returns this
 */
proto.dashboard_admin.Company.prototype.setDeviceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetCompaniesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetCompaniesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetCompaniesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompaniesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetCompaniesRequest}
 */
proto.dashboard_admin.GetCompaniesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetCompaniesRequest;
  return proto.dashboard_admin.GetCompaniesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetCompaniesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetCompaniesRequest}
 */
proto.dashboard_admin.GetCompaniesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetCompaniesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetCompaniesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetCompaniesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompaniesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.dashboard_admin.GetCompaniesRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.GetCompaniesRequest} returns this
 */
proto.dashboard_admin.GetCompaniesRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetCompaniesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetCompaniesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetCompaniesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetCompaniesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompaniesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companiesList: jspb.Message.toObjectList(msg.getCompaniesList(),
    proto.dashboard_admin.GetCompaniesResponse.Company.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetCompaniesResponse}
 */
proto.dashboard_admin.GetCompaniesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetCompaniesResponse;
  return proto.dashboard_admin.GetCompaniesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetCompaniesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetCompaniesResponse}
 */
proto.dashboard_admin.GetCompaniesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dashboard_admin.GetCompaniesResponse.Company;
      reader.readMessage(value,proto.dashboard_admin.GetCompaniesResponse.Company.deserializeBinaryFromReader);
      msg.addCompanies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetCompaniesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetCompaniesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetCompaniesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompaniesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dashboard_admin.GetCompaniesResponse.Company.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetCompaniesResponse.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetCompaniesResponse.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetCompaniesResponse.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompaniesResponse.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetCompaniesResponse.Company}
 */
proto.dashboard_admin.GetCompaniesResponse.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetCompaniesResponse.Company;
  return proto.dashboard_admin.GetCompaniesResponse.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetCompaniesResponse.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetCompaniesResponse.Company}
 */
proto.dashboard_admin.GetCompaniesResponse.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetCompaniesResponse.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetCompaniesResponse.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetCompaniesResponse.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompaniesResponse.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetCompaniesResponse.Company.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetCompaniesResponse.Company} returns this
 */
proto.dashboard_admin.GetCompaniesResponse.Company.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dashboard_admin.GetCompaniesResponse.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.GetCompaniesResponse.Company} returns this
 */
proto.dashboard_admin.GetCompaniesResponse.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Company companies = 1;
 * @return {!Array<!proto.dashboard_admin.GetCompaniesResponse.Company>}
 */
proto.dashboard_admin.GetCompaniesResponse.prototype.getCompaniesList = function() {
  return /** @type{!Array<!proto.dashboard_admin.GetCompaniesResponse.Company>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dashboard_admin.GetCompaniesResponse.Company, 1));
};


/**
 * @param {!Array<!proto.dashboard_admin.GetCompaniesResponse.Company>} value
 * @return {!proto.dashboard_admin.GetCompaniesResponse} returns this
*/
proto.dashboard_admin.GetCompaniesResponse.prototype.setCompaniesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dashboard_admin.GetCompaniesResponse.Company=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.GetCompaniesResponse.Company}
 */
proto.dashboard_admin.GetCompaniesResponse.prototype.addCompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dashboard_admin.GetCompaniesResponse.Company, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetCompaniesResponse} returns this
 */
proto.dashboard_admin.GetCompaniesResponse.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetCompanyInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetCompanyInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetCompanyInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompanyInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetCompanyInfoRequest}
 */
proto.dashboard_admin.GetCompanyInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetCompanyInfoRequest;
  return proto.dashboard_admin.GetCompanyInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetCompanyInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetCompanyInfoRequest}
 */
proto.dashboard_admin.GetCompanyInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetCompanyInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetCompanyInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetCompanyInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompanyInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetCompanyInfoRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetCompanyInfoRequest} returns this
 */
proto.dashboard_admin.GetCompanyInfoRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.CompanyReseller.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.CompanyReseller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.CompanyReseller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.CompanyReseller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasWearable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.CompanyReseller}
 */
proto.dashboard_admin.CompanyReseller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.CompanyReseller;
  return proto.dashboard_admin.CompanyReseller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.CompanyReseller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.CompanyReseller}
 */
proto.dashboard_admin.CompanyReseller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWearable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.CompanyReseller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.CompanyReseller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.CompanyReseller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.CompanyReseller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasWearable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.CompanyReseller.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.CompanyReseller} returns this
 */
proto.dashboard_admin.CompanyReseller.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dashboard_admin.CompanyReseller.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.CompanyReseller} returns this
 */
proto.dashboard_admin.CompanyReseller.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_wearable = 3;
 * @return {boolean}
 */
proto.dashboard_admin.CompanyReseller.prototype.getHasWearable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.CompanyReseller} returns this
 */
proto.dashboard_admin.CompanyReseller.prototype.setHasWearable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetCompanyInfoResponse.repeatedFields_ = [10,11,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetCompanyInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetCompanyInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompanyInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    availableProducts: (f = msg.getAvailableProducts()) && api_dashboard$admin_v1_product_message_pb.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.toObject(includeInstance, f),
    deviceCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    partner: (f = msg.getPartner()) && proto.dashboard_admin.CompanyReseller.toObject(includeInstance, f),
    availableCoachingProgramsList: jspb.Message.toObjectList(msg.getAvailableCoachingProgramsList(),
    api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.toObject, includeInstance),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard$admin_v1_department_message_pb.Department.toObject, includeInstance),
    tier: (f = msg.getTier()) && api_dashboard$admin_v1_tier_message_pb.Tier.toObject(includeInstance, f),
    availableFirmwaresList: jspb.Message.toObjectList(msg.getAvailableFirmwaresList(),
    api_dashboard$admin_v1_firmware_message_pb.Firmware.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    currentFirmware: (f = msg.getCurrentFirmware()) && api_dashboard$admin_v1_firmware_message_pb.Firmware.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse}
 */
proto.dashboard_admin.GetCompanyInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetCompanyInfoResponse;
  return proto.dashboard_admin.GetCompanyInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetCompanyInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse}
 */
proto.dashboard_admin.GetCompanyInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 5:
      var value = new api_dashboard$admin_v1_product_message_pb.AvailableProducts;
      reader.readMessage(value,api_dashboard$admin_v1_product_message_pb.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 6:
      var value = new api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings;
      reader.readMessage(value,api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 7:
      var value = new api_dashboard$admin_v1_task_settings_message_pb.TaskSettings;
      reader.readMessage(value,api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceCount(value);
      break;
    case 9:
      var value = new proto.dashboard_admin.CompanyReseller;
      reader.readMessage(value,proto.dashboard_admin.CompanyReseller.deserializeBinaryFromReader);
      msg.setPartner(value);
      break;
    case 10:
      var value = new api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram;
      reader.readMessage(value,api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.deserializeBinaryFromReader);
      msg.addAvailableCoachingPrograms(value);
      break;
    case 11:
      var value = new api_dashboard$admin_v1_department_message_pb.Department;
      reader.readMessage(value,api_dashboard$admin_v1_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 12:
      var value = new api_dashboard$admin_v1_tier_message_pb.Tier;
      reader.readMessage(value,api_dashboard$admin_v1_tier_message_pb.Tier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 13:
      var value = new api_dashboard$admin_v1_firmware_message_pb.Firmware;
      reader.readMessage(value,api_dashboard$admin_v1_firmware_message_pb.Firmware.deserializeBinaryFromReader);
      msg.addAvailableFirmwares(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = new api_dashboard$admin_v1_firmware_message_pb.Firmware;
      reader.readMessage(value,api_dashboard$admin_v1_firmware_message_pb.Firmware.deserializeBinaryFromReader);
      msg.setCurrentFirmware(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetCompanyInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetCompanyInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetCompanyInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_dashboard$admin_v1_product_message_pb.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      api_dashboard$admin_v1_task_settings_message_pb.TaskSettings.serializeBinaryToWriter
    );
  }
  f = message.getDeviceCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPartner();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.dashboard_admin.CompanyReseller.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCoachingProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      api_dashboard$admin_v1_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      api_dashboard$admin_v1_tier_message_pb.Tier.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFirmwaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      api_dashboard$admin_v1_firmware_message_pb.Firmware.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCurrentFirmware();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      api_dashboard$admin_v1_firmware_message_pb.Firmware.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 partner_id = 4;
 * @return {number}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AvailableProducts available_products = 5;
 * @return {?proto.dashboard_admin.AvailableProducts}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getAvailableProducts = function() {
  return /** @type{?proto.dashboard_admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_product_message_pb.AvailableProducts, 5));
};


/**
 * @param {?proto.dashboard_admin.AvailableProducts|undefined} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WearableSettings wearable_settings = 6;
 * @return {?proto.dashboard_admin.WearableSettings}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getWearableSettings = function() {
  return /** @type{?proto.dashboard_admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_wearable_settings_message_pb.WearableSettings, 6));
};


/**
 * @param {?proto.dashboard_admin.WearableSettings|undefined} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TaskSettings soter_task_settings = 7;
 * @return {?proto.dashboard_admin.TaskSettings}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.dashboard_admin.TaskSettings} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_task_settings_message_pb.TaskSettings, 7));
};


/**
 * @param {?proto.dashboard_admin.TaskSettings|undefined} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 device_count = 8;
 * @return {number}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getDeviceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setDeviceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional CompanyReseller partner = 9;
 * @return {?proto.dashboard_admin.CompanyReseller}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getPartner = function() {
  return /** @type{?proto.dashboard_admin.CompanyReseller} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.CompanyReseller, 9));
};


/**
 * @param {?proto.dashboard_admin.CompanyReseller|undefined} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setPartner = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearPartner = function() {
  return this.setPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.hasPartner = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated CoachingProgram available_coaching_programs = 10;
 * @return {!Array<!proto.dashboard_admin.CoachingProgram>}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getAvailableCoachingProgramsList = function() {
  return /** @type{!Array<!proto.dashboard_admin.CoachingProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram, 10));
};


/**
 * @param {!Array<!proto.dashboard_admin.CoachingProgram>} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setAvailableCoachingProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.dashboard_admin.CoachingProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.CoachingProgram}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.addAvailableCoachingPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.dashboard_admin.CoachingProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearAvailableCoachingProgramsList = function() {
  return this.setAvailableCoachingProgramsList([]);
};


/**
 * repeated Department departments = 11;
 * @return {!Array<!proto.dashboard_admin.Department>}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_department_message_pb.Department, 11));
};


/**
 * @param {!Array<!proto.dashboard_admin.Department>} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.dashboard_admin.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Department}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.dashboard_admin.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional Tier tier = 12;
 * @return {?proto.dashboard_admin.Tier}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getTier = function() {
  return /** @type{?proto.dashboard_admin.Tier} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_tier_message_pb.Tier, 12));
};


/**
 * @param {?proto.dashboard_admin.Tier|undefined} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.hasTier = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated Firmware available_firmwares = 13;
 * @return {!Array<!proto.dashboard_admin.Firmware>}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getAvailableFirmwaresList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Firmware>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_firmware_message_pb.Firmware, 13));
};


/**
 * @param {!Array<!proto.dashboard_admin.Firmware>} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setAvailableFirmwaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.dashboard_admin.Firmware=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Firmware}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.addAvailableFirmwares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.dashboard_admin.Firmware, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearAvailableFirmwaresList = function() {
  return this.setAvailableFirmwaresList([]);
};


/**
 * optional int64 created_at = 14;
 * @return {number}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Firmware current_firmware = 15;
 * @return {?proto.dashboard_admin.Firmware}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.getCurrentFirmware = function() {
  return /** @type{?proto.dashboard_admin.Firmware} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_firmware_message_pb.Firmware, 15));
};


/**
 * @param {?proto.dashboard_admin.Firmware|undefined} value
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
*/
proto.dashboard_admin.GetCompanyInfoResponse.prototype.setCurrentFirmware = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetCompanyInfoResponse} returns this
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.clearCurrentFirmware = function() {
  return this.setCurrentFirmware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetCompanyInfoResponse.prototype.hasCurrentFirmware = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableFirmwaresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresRequest}
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableFirmwaresRequest;
  return proto.dashboard_admin.GetAvailableFirmwaresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresRequest}
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableFirmwaresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresRequest} returns this
 */
proto.dashboard_admin.GetAvailableFirmwaresRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableFirmwaresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableFirmwaresList: jspb.Message.toObjectList(msg.getAvailableFirmwaresList(),
    api_dashboard$admin_v1_firmware_message_pb.Firmware.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresResponse}
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableFirmwaresResponse;
  return proto.dashboard_admin.GetAvailableFirmwaresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresResponse}
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard$admin_v1_firmware_message_pb.Firmware;
      reader.readMessage(value,api_dashboard$admin_v1_firmware_message_pb.Firmware.deserializeBinaryFromReader);
      msg.addAvailableFirmwares(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableFirmwaresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableFirmwaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard$admin_v1_firmware_message_pb.Firmware.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Firmware available_firmwares = 1;
 * @return {!Array<!proto.dashboard_admin.Firmware>}
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.prototype.getAvailableFirmwaresList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Firmware>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_firmware_message_pb.Firmware, 1));
};


/**
 * @param {!Array<!proto.dashboard_admin.Firmware>} value
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresResponse} returns this
*/
proto.dashboard_admin.GetAvailableFirmwaresResponse.prototype.setAvailableFirmwaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dashboard_admin.Firmware=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Firmware}
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.prototype.addAvailableFirmwares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dashboard_admin.Firmware, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetAvailableFirmwaresResponse} returns this
 */
proto.dashboard_admin.GetAvailableFirmwaresResponse.prototype.clearAvailableFirmwaresList = function() {
  return this.setAvailableFirmwaresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableTiersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableTiersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableTiersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableTiersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableTiersRequest}
 */
proto.dashboard_admin.GetAvailableTiersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableTiersRequest;
  return proto.dashboard_admin.GetAvailableTiersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableTiersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableTiersRequest}
 */
proto.dashboard_admin.GetAvailableTiersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableTiersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableTiersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableTiersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableTiersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetAvailableTiersRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetAvailableTiersRequest} returns this
 */
proto.dashboard_admin.GetAvailableTiersRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetAvailableTiersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableTiersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableTiersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableTiersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableTiersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableTiersList: jspb.Message.toObjectList(msg.getAvailableTiersList(),
    api_dashboard$admin_v1_tier_message_pb.Tier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableTiersResponse}
 */
proto.dashboard_admin.GetAvailableTiersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableTiersResponse;
  return proto.dashboard_admin.GetAvailableTiersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableTiersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableTiersResponse}
 */
proto.dashboard_admin.GetAvailableTiersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard$admin_v1_tier_message_pb.Tier;
      reader.readMessage(value,api_dashboard$admin_v1_tier_message_pb.Tier.deserializeBinaryFromReader);
      msg.addAvailableTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableTiersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableTiersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableTiersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableTiersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard$admin_v1_tier_message_pb.Tier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tier available_tiers = 1;
 * @return {!Array<!proto.dashboard_admin.Tier>}
 */
proto.dashboard_admin.GetAvailableTiersResponse.prototype.getAvailableTiersList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Tier>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_tier_message_pb.Tier, 1));
};


/**
 * @param {!Array<!proto.dashboard_admin.Tier>} value
 * @return {!proto.dashboard_admin.GetAvailableTiersResponse} returns this
*/
proto.dashboard_admin.GetAvailableTiersResponse.prototype.setAvailableTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dashboard_admin.Tier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Tier}
 */
proto.dashboard_admin.GetAvailableTiersResponse.prototype.addAvailableTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dashboard_admin.Tier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetAvailableTiersResponse} returns this
 */
proto.dashboard_admin.GetAvailableTiersResponse.prototype.clearAvailableTiersList = function() {
  return this.setAvailableTiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableCoachingProgramsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableCoachingProgramsRequest;
  return proto.dashboard_admin.GetAvailableCoachingProgramsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableCoachingProgramsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} returns this
 */
proto.dashboard_admin.GetAvailableCoachingProgramsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableCoachingProgramsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableCoachingProgramsList: jspb.Message.toObjectList(msg.getAvailableCoachingProgramsList(),
    api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableCoachingProgramsResponse;
  return proto.dashboard_admin.GetAvailableCoachingProgramsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram;
      reader.readMessage(value,api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.deserializeBinaryFromReader);
      msg.addAvailableCoachingPrograms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableCoachingProgramsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableCoachingProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CoachingProgram available_coaching_programs = 1;
 * @return {!Array<!proto.dashboard_admin.CoachingProgram>}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.prototype.getAvailableCoachingProgramsList = function() {
  return /** @type{!Array<!proto.dashboard_admin.CoachingProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard$admin_v1_coaching_program_message_pb.CoachingProgram, 1));
};


/**
 * @param {!Array<!proto.dashboard_admin.CoachingProgram>} value
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse} returns this
*/
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.prototype.setAvailableCoachingProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dashboard_admin.CoachingProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.CoachingProgram}
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.prototype.addAvailableCoachingPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dashboard_admin.CoachingProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetAvailableCoachingProgramsResponse} returns this
 */
proto.dashboard_admin.GetAvailableCoachingProgramsResponse.prototype.clearAvailableCoachingProgramsList = function() {
  return this.setAvailableCoachingProgramsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableLanguagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableLanguagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableLanguagesRequest}
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableLanguagesRequest;
  return proto.dashboard_admin.GetAvailableLanguagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableLanguagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableLanguagesRequest}
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableLanguagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableLanguagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetAvailableLanguagesRequest} returns this
 */
proto.dashboard_admin.GetAvailableLanguagesRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetAvailableLanguagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetAvailableLanguagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableLanguages: (f = msg.getAvailableLanguages()) && api_dashboard$admin_v1_base_enum_pb.AvailableLanguages.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetAvailableLanguagesResponse}
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetAvailableLanguagesResponse;
  return proto.dashboard_admin.GetAvailableLanguagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetAvailableLanguagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetAvailableLanguagesResponse}
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard$admin_v1_base_enum_pb.AvailableLanguages;
      reader.readMessage(value,api_dashboard$admin_v1_base_enum_pb.AvailableLanguages.deserializeBinaryFromReader);
      msg.setAvailableLanguages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetAvailableLanguagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetAvailableLanguagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableLanguages();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_dashboard$admin_v1_base_enum_pb.AvailableLanguages.serializeBinaryToWriter
    );
  }
};


/**
 * optional AvailableLanguages available_languages = 1;
 * @return {?proto.dashboard_admin.AvailableLanguages}
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.prototype.getAvailableLanguages = function() {
  return /** @type{?proto.dashboard_admin.AvailableLanguages} */ (
    jspb.Message.getWrapperField(this, api_dashboard$admin_v1_base_enum_pb.AvailableLanguages, 1));
};


/**
 * @param {?proto.dashboard_admin.AvailableLanguages|undefined} value
 * @return {!proto.dashboard_admin.GetAvailableLanguagesResponse} returns this
*/
proto.dashboard_admin.GetAvailableLanguagesResponse.prototype.setAvailableLanguages = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.GetAvailableLanguagesResponse} returns this
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.prototype.clearAvailableLanguages = function() {
  return this.setAvailableLanguages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.GetAvailableLanguagesResponse.prototype.hasAvailableLanguages = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.DeleteCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.DeleteCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.DeleteCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.DeleteCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.DeleteCompanyRequest}
 */
proto.dashboard_admin.DeleteCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.DeleteCompanyRequest;
  return proto.dashboard_admin.DeleteCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.DeleteCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.DeleteCompanyRequest}
 */
proto.dashboard_admin.DeleteCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.DeleteCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.DeleteCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.DeleteCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.DeleteCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.DeleteCompanyRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.DeleteCompanyRequest} returns this
 */
proto.dashboard_admin.DeleteCompanyRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.DeleteCompanyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.DeleteCompanyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.DeleteCompanyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.DeleteCompanyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.DeleteCompanyResponse}
 */
proto.dashboard_admin.DeleteCompanyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.DeleteCompanyResponse;
  return proto.dashboard_admin.DeleteCompanyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.DeleteCompanyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.DeleteCompanyResponse}
 */
proto.dashboard_admin.DeleteCompanyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.DeleteCompanyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.DeleteCompanyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.DeleteCompanyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.DeleteCompanyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.dashboard_admin.DeleteCompanyResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.DeleteCompanyResponse} returns this
 */
proto.dashboard_admin.DeleteCompanyResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetDashboardFeedbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetDashboardFeedbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sortByRate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetDashboardFeedbackRequest}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetDashboardFeedbackRequest;
  return proto.dashboard_admin.GetDashboardFeedbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetDashboardFeedbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetDashboardFeedbackRequest}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortByRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetDashboardFeedbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetDashboardFeedbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSortByRate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 company_id = 1;
 * @return {number}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetDashboardFeedbackRequest} returns this
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetDashboardFeedbackRequest} returns this
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 offset = 3;
 * @return {number}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetDashboardFeedbackRequest} returns this
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sort_by_rate = 4;
 * @return {string}
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.getSortByRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.GetDashboardFeedbackRequest} returns this
 */
proto.dashboard_admin.GetDashboardFeedbackRequest.prototype.setSortByRate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.Feedback.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.Feedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.Feedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.Feedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.Feedback}
 */
proto.dashboard_admin.Feedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.Feedback;
  return proto.dashboard_admin.Feedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.Feedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.Feedback}
 */
proto.dashboard_admin.Feedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDashboardUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.Feedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.Feedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.Feedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.Feedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.Feedback.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 dashboard_user_id = 2;
 * @return {number}
 */
proto.dashboard_admin.Feedback.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 company_id = 3;
 * @return {number}
 */
proto.dashboard_admin.Feedback.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string reason = 4;
 * @return {string}
 */
proto.dashboard_admin.Feedback.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.dashboard_admin.Feedback.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 rate = 6;
 * @return {number}
 */
proto.dashboard_admin.Feedback.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.dashboard_admin.Feedback.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.Feedback} returns this
 */
proto.dashboard_admin.Feedback.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetDashboardUsersFeedbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedbacksList: jspb.Message.toObjectList(msg.getFeedbacksList(),
    proto.dashboard_admin.Feedback.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetDashboardUsersFeedbackResponse;
  return proto.dashboard_admin.GetDashboardUsersFeedbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dashboard_admin.Feedback;
      reader.readMessage(value,proto.dashboard_admin.Feedback.deserializeBinaryFromReader);
      msg.addFeedbacks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetDashboardUsersFeedbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedbacksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dashboard_admin.Feedback.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated Feedback feedbacks = 1;
 * @return {!Array<!proto.dashboard_admin.Feedback>}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.getFeedbacksList = function() {
  return /** @type{!Array<!proto.dashboard_admin.Feedback>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dashboard_admin.Feedback, 1));
};


/**
 * @param {!Array<!proto.dashboard_admin.Feedback>} value
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse} returns this
*/
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.setFeedbacksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dashboard_admin.Feedback=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.Feedback}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.addFeedbacks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dashboard_admin.Feedback, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse} returns this
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.clearFeedbacksList = function() {
  return this.setFeedbacksList([]);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponse} returns this
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.FeedbackV2.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.FeedbackV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.FeedbackV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.FeedbackV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dashboardUser: (f = msg.getDashboardUser()) && proto.dashboard_admin.FeedbackV2.DashboardUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.FeedbackV2}
 */
proto.dashboard_admin.FeedbackV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.FeedbackV2;
  return proto.dashboard_admin.FeedbackV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.FeedbackV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.FeedbackV2}
 */
proto.dashboard_admin.FeedbackV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDashboardUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new proto.dashboard_admin.FeedbackV2.DashboardUser;
      reader.readMessage(value,proto.dashboard_admin.FeedbackV2.DashboardUser.deserializeBinaryFromReader);
      msg.setDashboardUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.FeedbackV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.FeedbackV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.FeedbackV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.FeedbackV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDashboardUser();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.dashboard_admin.FeedbackV2.DashboardUser.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.FeedbackV2.DashboardUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.FeedbackV2.DashboardUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.FeedbackV2.DashboardUser}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.FeedbackV2.DashboardUser;
  return proto.dashboard_admin.FeedbackV2.DashboardUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.FeedbackV2.DashboardUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.FeedbackV2.DashboardUser}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.FeedbackV2.DashboardUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.FeedbackV2.DashboardUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.FeedbackV2.DashboardUser} returns this
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.FeedbackV2.DashboardUser} returns this
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.FeedbackV2.DashboardUser} returns this
 */
proto.dashboard_admin.FeedbackV2.DashboardUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.dashboard_admin.FeedbackV2.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 dashboard_user_id = 2;
 * @return {number}
 */
proto.dashboard_admin.FeedbackV2.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 company_id = 3;
 * @return {number}
 */
proto.dashboard_admin.FeedbackV2.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string reason = 4;
 * @return {string}
 */
proto.dashboard_admin.FeedbackV2.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.dashboard_admin.FeedbackV2.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 rate = 6;
 * @return {number}
 */
proto.dashboard_admin.FeedbackV2.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.dashboard_admin.FeedbackV2.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DashboardUser dashboard_user = 8;
 * @return {?proto.dashboard_admin.FeedbackV2.DashboardUser}
 */
proto.dashboard_admin.FeedbackV2.prototype.getDashboardUser = function() {
  return /** @type{?proto.dashboard_admin.FeedbackV2.DashboardUser} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.FeedbackV2.DashboardUser, 8));
};


/**
 * @param {?proto.dashboard_admin.FeedbackV2.DashboardUser|undefined} value
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
*/
proto.dashboard_admin.FeedbackV2.prototype.setDashboardUser = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.FeedbackV2} returns this
 */
proto.dashboard_admin.FeedbackV2.prototype.clearDashboardUser = function() {
  return this.setDashboardUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.FeedbackV2.prototype.hasDashboardUser = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedbacksList: jspb.Message.toObjectList(msg.getFeedbacksList(),
    proto.dashboard_admin.FeedbackV2.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2;
  return proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dashboard_admin.FeedbackV2;
      reader.readMessage(value,proto.dashboard_admin.FeedbackV2.deserializeBinaryFromReader);
      msg.addFeedbacks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedbacksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dashboard_admin.FeedbackV2.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated FeedbackV2 feedbacks = 1;
 * @return {!Array<!proto.dashboard_admin.FeedbackV2>}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.getFeedbacksList = function() {
  return /** @type{!Array<!proto.dashboard_admin.FeedbackV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dashboard_admin.FeedbackV2, 1));
};


/**
 * @param {!Array<!proto.dashboard_admin.FeedbackV2>} value
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2} returns this
*/
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.setFeedbacksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dashboard_admin.FeedbackV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dashboard_admin.FeedbackV2}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.addFeedbacks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dashboard_admin.FeedbackV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2} returns this
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.clearFeedbacksList = function() {
  return this.setFeedbacksList([]);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2} returns this
 */
proto.dashboard_admin.GetDashboardUsersFeedbackResponseV2.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.dashboard_admin);
