/**
 * @fileoverview gRPC-Web generated client stub for videoDashboardTask
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/soter-task/dashboard/v1/video_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_soter$task_common_v1_video_message_pb = require('../../../../api/soter-task/common/v1/video_message_pb.js')
const proto = {};
proto.videoDashboardTask = require('./video_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.CreateTaskVideoRequest,
 *   !proto.videoTask.TaskVideoResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_CreateVideo = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/CreateVideo',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.CreateTaskVideoRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse,
  /**
   * @param {!proto.videoTask.CreateTaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.CreateTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.createVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/CreateVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_CreateVideo,
      callback);
};


/**
 * @param {!proto.videoTask.CreateTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.createVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/CreateVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_CreateVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.VideoUploadChunkRequest,
 *   !proto.videoTask.TaskVideoResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_UploadVideoByChunk = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/UploadVideoByChunk',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.VideoUploadChunkRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse,
  /**
   * @param {!proto.videoTask.VideoUploadChunkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.VideoUploadChunkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.uploadVideoByChunk =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/UploadVideoByChunk',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_UploadVideoByChunk,
      callback);
};


/**
 * @param {!proto.videoTask.VideoUploadChunkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.uploadVideoByChunk =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/UploadVideoByChunk',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_UploadVideoByChunk);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.TaskVideoRequest,
 *   !proto.videoTask.TaskVideoResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_GetVideo = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetVideo',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.TaskVideoRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse,
  /**
   * @param {!proto.videoTask.TaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetVideo,
      callback);
};


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.UpdateTaskVideoRequest,
 *   !proto.videoTask.TaskVideoResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_UpdateVideo = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/UpdateVideo',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.UpdateTaskVideoRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse,
  /**
   * @param {!proto.videoTask.UpdateTaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.UpdateTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.updateVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/UpdateVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_UpdateVideo,
      callback);
};


/**
 * @param {!proto.videoTask.UpdateTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.updateVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/UpdateVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_UpdateVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.TaskVideoRequest,
 *   !proto.videoTask.TaskVideoResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_DeleteVideo = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/DeleteVideo',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.TaskVideoRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse,
  /**
   * @param {!proto.videoTask.TaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.deleteVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteVideo,
      callback);
};


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.deleteVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteVideo',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.TaskVideoListRequest,
 *   !proto.videoTask.TaskVideosResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_GetVideosList = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetVideosList',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.TaskVideoListRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideosResponse,
  /**
   * @param {!proto.videoTask.TaskVideoListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideosResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.TaskVideoListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getVideosList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetVideosList',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetVideosList,
      callback);
};


/**
 * @param {!proto.videoTask.TaskVideoListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideosResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getVideosList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetVideosList',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetVideosList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.TaskVideoRequest,
 *   !proto.videoTask.TaskMovementData>}
 */
const methodDescriptor_TaskDashboardVideoService_GetMovementData = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetMovementData',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.TaskVideoRequest,
  api_soter$task_common_v1_video_message_pb.TaskMovementData,
  /**
   * @param {!proto.videoTask.TaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskMovementData.deserializeBinary
);


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskMovementData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskMovementData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getMovementData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetMovementData',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetMovementData,
      callback);
};


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskMovementData>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getMovementData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetMovementData',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetMovementData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.TaskVideoRequest,
 *   !proto.videoTask.TaskRebaData>}
 */
const methodDescriptor_TaskDashboardVideoService_GetRebaData = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetRebaData',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.TaskVideoRequest,
  api_soter$task_common_v1_video_message_pb.TaskRebaData,
  /**
   * @param {!proto.videoTask.TaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskRebaData.deserializeBinary
);


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskRebaData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskRebaData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getRebaData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetRebaData',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetRebaData,
      callback);
};


/**
 * @param {!proto.videoTask.TaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskRebaData>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getRebaData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetRebaData',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetRebaData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.EventsForVideoRequest,
 *   !proto.videoTask.StatusForEventsTaskVideoResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_GetEventsForVideos = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetEventsForVideos',
  grpc.web.MethodType.SERVER_STREAMING,
  api_soter$task_common_v1_video_message_pb.EventsForVideoRequest,
  api_soter$task_common_v1_video_message_pb.StatusForEventsTaskVideoResponse,
  /**
   * @param {!proto.videoTask.EventsForVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.StatusForEventsTaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.EventsForVideoRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.StatusForEventsTaskVideoResponse>}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getEventsForVideos =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetEventsForVideos',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetEventsForVideos);
};


/**
 * @param {!proto.videoTask.EventsForVideoRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.StatusForEventsTaskVideoResponse>}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getEventsForVideos =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetEventsForVideos',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetEventsForVideos);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.CreateTaskVideoComparisonRequest,
 *   !proto.videoTask.TaskVideoComparisonResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_CreateTaskVideoComparison = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/CreateTaskVideoComparison',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.CreateTaskVideoComparisonRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonResponse,
  /**
   * @param {!proto.videoTask.CreateTaskVideoComparisonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.CreateTaskVideoComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoComparisonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoComparisonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.createTaskVideoComparison =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/CreateTaskVideoComparison',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_CreateTaskVideoComparison,
      callback);
};


/**
 * @param {!proto.videoTask.CreateTaskVideoComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoComparisonResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.createTaskVideoComparison =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/CreateTaskVideoComparison',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_CreateTaskVideoComparison);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.UpdateTaskVideoComparisonRequest,
 *   !proto.videoTask.TaskVideoComparisonResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_UpdateTaskVideoComparison = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/UpdateTaskVideoComparison',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.UpdateTaskVideoComparisonRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonResponse,
  /**
   * @param {!proto.videoTask.UpdateTaskVideoComparisonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.UpdateTaskVideoComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoComparisonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoComparisonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.updateTaskVideoComparison =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/UpdateTaskVideoComparison',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_UpdateTaskVideoComparison,
      callback);
};


/**
 * @param {!proto.videoTask.UpdateTaskVideoComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoComparisonResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.updateTaskVideoComparison =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/UpdateTaskVideoComparison',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_UpdateTaskVideoComparison);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.DeleteTaskVideoComparisonsRequest,
 *   !proto.videoTask.DeleteTaskVideoComparisonsResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoComparisons = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoComparisons',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoComparisonsRequest,
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoComparisonsResponse,
  /**
   * @param {!proto.videoTask.DeleteTaskVideoComparisonsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoComparisonsResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.DeleteTaskVideoComparisonsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.DeleteTaskVideoComparisonsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.deleteTaskVideoComparisons =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoComparisons',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoComparisons,
      callback);
};


/**
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.DeleteTaskVideoComparisonsResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.deleteTaskVideoComparisons =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoComparisons',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoComparisons);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.TaskVideoComparisonListRequest,
 *   !proto.videoTask.TaskVideoComparisonListResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_GetTaskVideoComparisonList = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetTaskVideoComparisonList',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonListRequest,
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonListResponse,
  /**
   * @param {!proto.videoTask.TaskVideoComparisonListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.TaskVideoComparisonListResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.TaskVideoComparisonListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.TaskVideoComparisonListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.TaskVideoComparisonListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getTaskVideoComparisonList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetTaskVideoComparisonList',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetTaskVideoComparisonList,
      callback);
};


/**
 * @param {!proto.videoTask.TaskVideoComparisonListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.TaskVideoComparisonListResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getTaskVideoComparisonList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetTaskVideoComparisonList',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetTaskVideoComparisonList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.DeleteTaskVideoComparisonRequest,
 *   !proto.videoTask.DeleteTaskVideoComparisonResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoComparison = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoComparison',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoComparisonRequest,
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoComparisonResponse,
  /**
   * @param {!proto.videoTask.DeleteTaskVideoComparisonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoComparisonResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.DeleteTaskVideoComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.DeleteTaskVideoComparisonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.DeleteTaskVideoComparisonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.deleteTaskVideoComparison =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoComparison',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoComparison,
      callback);
};


/**
 * @param {!proto.videoTask.DeleteTaskVideoComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.DeleteTaskVideoComparisonResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.deleteTaskVideoComparison =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoComparison',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoComparison);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.CreateTaskVideosRebaWeightsRequest,
 *   !proto.videoTask.CreateTaskVideosRebaWeightsResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_CreateTaskVideosRebaWeights = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/CreateTaskVideosRebaWeights',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.CreateTaskVideosRebaWeightsRequest,
  api_soter$task_common_v1_video_message_pb.CreateTaskVideosRebaWeightsResponse,
  /**
   * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.CreateTaskVideosRebaWeightsResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.CreateTaskVideosRebaWeightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.CreateTaskVideosRebaWeightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.createTaskVideosRebaWeights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/CreateTaskVideosRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_CreateTaskVideosRebaWeights,
      callback);
};


/**
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.CreateTaskVideosRebaWeightsResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.createTaskVideosRebaWeights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/CreateTaskVideosRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_CreateTaskVideosRebaWeights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.GetTaskVideoRebaWeightsRequest,
 *   !proto.videoTask.GetTaskVideoRebaWeightsResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_GetTaskVideoRebaWeights = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetTaskVideoRebaWeights',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.GetTaskVideoRebaWeightsRequest,
  api_soter$task_common_v1_video_message_pb.GetTaskVideoRebaWeightsResponse,
  /**
   * @param {!proto.videoTask.GetTaskVideoRebaWeightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.GetTaskVideoRebaWeightsResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.GetTaskVideoRebaWeightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.GetTaskVideoRebaWeightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getTaskVideoRebaWeights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetTaskVideoRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetTaskVideoRebaWeights,
      callback);
};


/**
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.GetTaskVideoRebaWeightsResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getTaskVideoRebaWeights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetTaskVideoRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetTaskVideoRebaWeights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.DeleteTaskVideoRebaWeightsRequest,
 *   !proto.videoTask.DeleteTaskVideoRebaWeightsResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoRebaWeights = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoRebaWeights',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoRebaWeightsRequest,
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoRebaWeightsResponse,
  /**
   * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.DeleteTaskVideoRebaWeightsResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.videoTask.DeleteTaskVideoRebaWeightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.DeleteTaskVideoRebaWeightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.deleteTaskVideoRebaWeights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoRebaWeights,
      callback);
};


/**
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.videoTask.DeleteTaskVideoRebaWeightsResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.deleteTaskVideoRebaWeights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/DeleteTaskVideoRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_DeleteTaskVideoRebaWeights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.videoTask.EventsForRebaWeightRequest,
 *   !proto.videoTask.StatusForEventsRebaWeightResponse>}
 */
const methodDescriptor_TaskDashboardVideoService_GetEventsForRebaWeights = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskDashboardVideoService/GetEventsForRebaWeights',
  grpc.web.MethodType.SERVER_STREAMING,
  api_soter$task_common_v1_video_message_pb.EventsForRebaWeightRequest,
  api_soter$task_common_v1_video_message_pb.StatusForEventsRebaWeightResponse,
  /**
   * @param {!proto.videoTask.EventsForRebaWeightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_video_message_pb.StatusForEventsRebaWeightResponse.deserializeBinary
);


/**
 * @param {!proto.videoTask.EventsForRebaWeightRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.StatusForEventsRebaWeightResponse>}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServiceClient.prototype.getEventsForRebaWeights =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetEventsForRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetEventsForRebaWeights);
};


/**
 * @param {!proto.videoTask.EventsForRebaWeightRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.videoTask.StatusForEventsRebaWeightResponse>}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskDashboardVideoServicePromiseClient.prototype.getEventsForRebaWeights =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/videoDashboardTask.TaskDashboardVideoService/GetEventsForRebaWeights',
      request,
      metadata || {},
      methodDescriptor_TaskDashboardVideoService_GetEventsForRebaWeights);
};


module.exports = proto.videoDashboardTask;

