import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { modalOverflow } from '../../assets/jss/style';
import { outlineColoredButton } from '../../assets/soter-flex-styles';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import SimpleCompaniesTextCell
  from '../../containers/ItemTableContainer/components/CustomCells/SimpleCompaniesTextCell';
import DeviceProgramCell from '../../containers/ItemTableContainer/components/CustomCells/DeviceProgramsCell';
import CreatedAtCell from '../../containers/ItemTableContainer/components/CustomCells/CreatedAtCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import { createAdminCompany, deleteAdminCompany, getAdminCompanies, getCompanyData, } from '../store/actions';
import { setCurrentCompanyId } from '../store/adminCompaniesSlice';
import CreateCompany from '../components/CreateCompany/CreateCompany';
import { transformData } from './company/utils/utils';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import { getInfo } from '../components/utils';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import Loader from '../../lib/ui/Loader';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import ActionToggleButton from '../../lib/ui/ActionToggleButton';

const companiesTable = Emotion.css`
  font-size: 14px;
`;

const CompaniesContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    companyList,
    companyData,
    isLoading,
  } = useAppSelector((state) => state.adminCompanies);
  const { openedModal } = useAppSelector((state) => state.modals);
  const { companies: selectedCompanies } = useAppSelector((state) => state.selectedItems);
  const [editedCompany] = useState(null);

  const columns = (getActionForCompanies) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.companies),
    // eslint-disable-next-line no-undef
    _,
    getActionForCompanies,
    TableType.companies,
    t,
  );

  const cells = {
    id: SimpleCompaniesTextCell,
    companyName: SimpleCompaniesTextCell,
    region: SimpleCompaniesTextCell,
    reseller: SimpleCompaniesTextCell,
    companyDeviceCount: SimpleCompaniesTextCell,
    programs: DeviceProgramCell,
    namesInReport: SimpleCompaniesTextCell,
    companyCreatedAt: CreatedAtCell,
    actions: ActionCell,
  };

  const actions = [
    {
      title: t('ADMIN.DELETE', 'Delete'),
      handler: ({ id }) => {
        dispatch(setCurrentCompanyId(id));
        dispatch(setOpenedModal('deleteCompany'));
      },
      attention: true,
    },
  ];

  const companyActions = [
    {
      id: 'setCompany',
      name: 'Change company',
      color: COLORS.GRAY[400],
      size: 'small',
      child: <div />,
      disabled: true,
    },
  ];

  const togglerButton = (
    <ActionToggleButton
      name="multi"
      dataName="multi-user-dropdown"
      className={outlineColoredButton('#248BF2', 'small')}
    />
  );

  const switchModal = (modalType) => {
    if (isLoading) {
      return null;
    }
    switch (modalType) {
      case 'createCompany':
        return (
          <CreateCompany
            companyData={companyData}
            onSubmit={(data) => dispatch(createAdminCompany({ companyData: transformData(data) }))}
          />
        );
      case 'deleteCompany':
        return (
          <DeleteEntityDialog
            confirmText="Are you sure you want to delete the company?"
            buttonText="Delete company"
            onSubmit={() => dispatch(deleteAdminCompany())}
          />
        );
      default:
        return null;
    }
  };

  const filterButtonHandler = () => {
    dispatch(setOpenedModal('createCompany'));
  };

  const filterButtonProps = {
    text: t('ADMIN.CREATE_NEW_COMPANY', 'Create new company'),
    action: filterButtonHandler,
  };

  const handleSwitchModal = (type) => {
    dispatch(setOpenedModal(type));
  };

  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
  };

  useEffect(() => {
    dispatch(getAdminCompanies());
    getInfo(dispatch);
  }, []);

  if (isLoading) return <Loader />;
  if (companyList.length === 0) return <Loader />;

  return (
    <ItemTableContainer
      tableClass={companiesTable}
      items={companyList}
      selectedItems={selectedCompanies}
      columns={columns}
      cells={cells}
      type={TableType.companies}
      isLoading={isLoading}
      actions={actions}
      editedItem={editedCompany}
      openedModal={openedModal}
      setOpenedModal={handleSwitchModal}
      itemActions={companyActions}
      togglerButton={togglerButton}
      filterButtonProps={filterButtonProps}
      switchModal={switchModal}
      modalClass={modalOverflow}
      keepModalOnClickOutside
      onCloseModal={handleCloseModal}
      isCloseModal={false}
    />
  );
};

export default PrivatePageHOC(CompaniesContainer);
