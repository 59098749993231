import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { COLORS } from 'constants/colors';
import { bodyEdgesMap } from '../video/constants';

const getColor = (colors, color, thresholds, threshold) => {
  if (colors[color] > thresholds[threshold][1]) {
    return COLORS.RED[600];
  }

  if (colors[color] > thresholds[threshold][0]) {
    return COLORS.ORANGE[600];
  }

  return COLORS.GREEN[600];
};

const parseMovementData = (movementData, thresholds) => movementData.map((dot) => _compact(
  _map(bodyEdgesMap, ({ pointsIndex, threshold, color }) => {
    const firstDotX
          = (dot[pointsIndex[0]] && dot[pointsIndex[0]][0]) > 0 &&
          dot[pointsIndex[0]][2] > 0
            ? dot[pointsIndex[0]][0]
            : 0;
    const firstDotY
          = (dot[pointsIndex[0]] && dot[pointsIndex[0]][1]) > 0 &&
          dot[pointsIndex[0]][2] > 0
            ? dot[pointsIndex[0]][1]
            : 0;
    const secondDotX
          = (dot[pointsIndex[1]] && dot[pointsIndex[1]][0]) > 0 &&
          dot[pointsIndex[1]][2] > 0
            ? dot[pointsIndex[1]][0]
            : 0;
    const secondDotY
          = (dot[pointsIndex[1]] && dot[pointsIndex[1]][1]) > 0 &&
          dot[pointsIndex[1]][2] > 0
            ? dot[pointsIndex[1]][1]
            : 0;
    const colors = dot[19] || {};
    return {
      bodyPart: color,
      points: [firstDotX, firstDotY, secondDotX, secondDotY],
      color: getColor(colors, color, thresholds, threshold),
    };
  }),
));

export default parseMovementData;
