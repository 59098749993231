import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';
import ItemTableContainer from 'containers/ItemTableContainer/ItemTableContainer';
import { COLORS } from 'constants/colors';
import getColumns from 'containers/ItemTableContainer/utils/getColumns';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from 'containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import * as Emotion from 'emotion';
import { text } from '../../assets/jss/style';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import SimpleCompaniesTextCell
  from '../../containers/ItemTableContainer/components/CustomCells/SimpleCompaniesTextCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import CreateHubDialog from './CreateHubDialog';
import {
  changeHubStatus, createHub, editHub, getHubs, removeHub, resetHubs,
} from './hubsStore/actions';
import HubQrDialog from './HubQrDialog';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import { getAdminCompanies, getAvailableCoachingPrograms } from '../store/actions';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';

const HubsContainer = () => {
  const dispatch = useDispatch();
  const { companiesList } = useSelector((state) => state.companies);
  const { hubsList, isLoading } = useSelector((state) => state.hubs);
  const { openedModal } = useSelector((state) => state.modals);
  const [editedHub, setEditedHub] = useState(null);
  const columns = (getActionForCompanies) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.hubs),
    // eslint-disable-next-line no-undef
    _,
    getActionForCompanies,
    TableType.hubs,
  );
  const cells = {
    id: SimpleCompaniesTextCell,
    imei: SimpleCompaniesTextCell,
    alias: SimpleCompaniesTextCell,
    company: SimpleCompaniesTextCell,
    status: SimpleCompaniesTextCell,
    hubType: SimpleCompaniesTextCell,
    lastSeenMilliseconds: SimpleCompaniesTextCell,
    createdAtMilliseconds: SimpleCompaniesTextCell,
    actions: ActionCell,
  };
  const filterButtonProps = {
    text: 'Create new hub',
    action: () => dispatch(setOpenedModal('createHub')),
  };
  const actions = [
    {
      title: 'Edit hub',
      handler: (hub) => {
        setEditedHub(hub);
        dispatch(setOpenedModal('editHub'));
      },
    },
    {
      title: 'Hub status',
      handler: (hub) => {
        setEditedHub(hub);
        dispatch(setOpenedModal('hubStatus'));
      },
    },
    {
      title: 'Display QR',
      handler: (hub) => {
        setEditedHub(hub);
        dispatch(setOpenedModal('displayQr'));
      },
    },
    {
      title: 'Delete hub',
      handler: (hub) => {
        setEditedHub(hub);
        dispatch(setOpenedModal('deleteHub'));
      },
    },
  ];
  const switchModal = (modalType) => {
    switch (modalType) {
      case 'createHub':
        return (
          <CreateHubDialog
            companies={_sortBy(companiesList, 'name')}
            onSubmit={(data) => dispatch(createHub(data))}
          />
        );
      case 'displayQr':
        return <HubQrDialog code={editedHub.hubCode} />;
      case 'hubStatus':
        return (
          <DeleteEntityDialog
            additionalText={`You can ${
              editedHub?.isActive ? 'reactivate' : 'deactivate'
            } it later`}
            confirmText={`${
              editedHub?.isActive ? 'Deactivate' : 'Activate'
            } the hub?`}
            onSubmit={() => {
              dispatch(changeHubStatus(editedHub?.id, !editedHub?.isActive));
              setEditedHub(null);
            }}
            buttonText={editedHub?.isActive ? 'Deactivate' : 'Activate'}
          />
        );
      case 'deleteHub':
        return (
          <DeleteEntityDialog
            additionalText="This is an irreversible action"
            confirmText="Delete the Hub?"
            onSubmit={() => {
              dispatch(removeHub(editedHub.id));
              setEditedHub(null);
            }}
            buttonText="Delete"
          />
        );
      case 'editHub':
        return (
          <CreateHubDialog
            companies={_sortBy(companiesList, 'name')}
            onSubmit={(data) => {
              dispatch(editHub(editedHub?.id, data));
              setEditedHub(null);
            }}
            hub={editedHub}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    dispatch(getHubs());
    return () => dispatch(resetHubs());
  }, []);
  useEffect(() => {
    dispatch(getAdminCompanies());
    dispatch(getAvailableCoachingPrograms());
  }, []);

  return (
    <ItemTableContainer
      selectedItems={[]}
      tableClass={text(COLORS.GRAY[950], 14)}
      cells={cells}
      columns={columns}
      isLoading={isLoading}
      type={TableType.hubs}
      items={_sortBy(hubsList, 'id')}
      actions={actions}
      filterButtonProps={filterButtonProps}
      switchModal={switchModal}
      openedModal={openedModal}
      onCloseModal={() => dispatch(setOpenedModal(null))}
      modalClass={
        openedModal === 'displayQr'
          ? Emotion.css`
              width: 226px;
            `
          : Emotion.css`
              background-color: #F5F5F5;
            `
      }
    />
  );
};

export default PrivatePageHOC(HubsContainer);
