import { createAction, createSlice } from '@reduxjs/toolkit';
import { createTaskVideo, getRebaData, getRebaWeights, getRebaWeightsStream, getTaskVideo, getVideos, getVideoStatusStream, removeTaskVideo, updateVideo, updateVideoDescription, updateVideoStatus, uploadTaskVideo, } from './actions';
const initialState = {
    currentVideo: {},
    uploadedVideo: {},
    rebaWeightsList: [],
    movementData: [],
    rebaData: [],
    videosList: [],
    isEmailFree: false,
    updated: false,
    error: null,
    isLoading: true,
    isRebaReady: false,
};
const pending = createAction('videos/pending');
const rejected = createAction('videos/rejected');
const updateCurrentVideo = (state, action) => {
    const video = action.payload;
    const index = state.videosList.findIndex((el) => el.id === video.id);
    state.currentVideo = video;
    state.videosList[index] = video;
    state.isLoading = false;
};
const VideosSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {
        resetTaskVideos: () => initialState,
        resetTaskVideosError: (state) => (Object.assign(Object.assign({}, state), { error: null })),
        setVideosUpdated: (state, action) => {
            state.updated = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(getVideos.fulfilled, (state, action) => {
            if (action.payload) {
                state.videosList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(getVideoStatusStream.fulfilled, (state, action) => {
            if (action.payload)
                state.updated = action.payload;
        });
        builder.addCase(getRebaWeightsStream.fulfilled, (state, action) => {
            if (action.payload === true) {
                state.isRebaReady = action.payload;
            }
        });
        builder.addCase(createTaskVideo.fulfilled, (state, action) => {
            if (action.payload)
                state.uploadedVideo = action.payload;
        });
        builder.addCase(uploadTaskVideo.fulfilled, (state, action) => {
            if (action.payload)
                state.uploadedVideo = action.payload;
        });
        builder.addCase(getRebaWeights.fulfilled, (state, action) => {
            if (action.payload) {
                state.rebaWeightsList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(getTaskVideo.fulfilled, (state, action) => {
            if (action.payload) {
                if (action.payload[0].id) {
                    state.currentVideo = action.payload[0];
                }
                if (action.payload[1]) {
                    state.movementData = JSON.parse(action.payload[1].toObject().data);
                }
            }
        });
        builder.addCase(getRebaData.fulfilled, (state, action) => {
            if (action.payload) {
                if (action.payload.id) {
                    state.currentVideo = action.payload;
                }
                else if (state.rebaData) {
                    state.rebaData = action.payload;
                }
            }
        });
        builder.addCase(updateVideo.fulfilled, (state, action) => {
            if (action.payload)
                updateCurrentVideo(state, action);
        });
        builder.addCase(updateVideoDescription.fulfilled, (state, action) => {
            if (action.payload)
                updateCurrentVideo(state, action);
        });
        builder.addCase(updateVideoStatus.fulfilled, (state, action) => {
            if (action.payload)
                updateCurrentVideo(state, action);
        });
        builder.addCase(removeTaskVideo.fulfilled, (state, action) => {
            if (action.payload) {
                state.videosList = state.videosList.filter((video) => video.id !== action.payload);
                state.isLoading = false;
            }
        });
    },
});
export const { resetTaskVideos, setVideosUpdated, resetTaskVideosError } = VideosSlice.actions;
export default VideosSlice.reducer;
