import { hazardsEnum } from '../wearables/overview/constants/hazardsEnum';
import { AngleZones, MovementTypes } from '../wearables/overview/constants/constants';

const { spine, shoulder } = hazardsEnum;

const HMPresentationNameMap = {
  repetition: spine.repetition,
  intensity: spine.intensity,
  pbStatic: spine.pbStatic,
  twisting: spine.twisting,
  lifting: spine.lifting,
  overexertion: shoulder.overexertion,
  pushingPulling: shoulder.pushingPulling,
  staticArmElevation: shoulder.staticArmElevation,
  repetitiveArmMovements: shoulder.repetitiveArmMovements,
  armElevation: shoulder.armElevation,
  noHazardousMovements: 'Hazardous movements',
  [MovementTypes.NORMAL_MOVEMENT]: 'Safe movements',
  [MovementTypes.HAZARDOUS_MOVEMENT]: 'Hazardous movements',
  [AngleZones.LOW]: `0${String.fromCharCode(176)}-30${String.fromCharCode(176)}`,
  [AngleZones.MIDDLE]: `30${String.fromCharCode(176)}-60${String.fromCharCode(176)}`,
  [AngleZones.HIGH]: `60${String.fromCharCode(176)}-90${String.fromCharCode(176)}`,
  [AngleZones.HIGHEST]: `>90${String.fromCharCode(176)}`,
  negativeFeedbackCount: 'WEARABLES.OVERVIEW.NEGATIVE',
  neutralFeedbackCount: 'WEARABLES.OVERVIEW.NEUTRAL',
  positiveFeedbackCount: 'WEARABLES.OVERVIEW.POSITIVE',
};
// TODO: refactor this function to remove it and get the object parameter directly
const getHMPresentationName = (hazardMovement) => HMPresentationNameMap[hazardMovement];

export default getHMPresentationName;
