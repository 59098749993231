import { toCamel } from 'snake-camel';
import {
  overviewRequest, overviewService, period, getMetaData,
} from 'init/proto_client';
import * as types from './actionTypes';
import { setAllSelectedDepartments } from '../overviewState/actions';
import { DAY_IN_S } from './times';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';

export function setOverviewIsLoading() {
  return ({ type: types.SET_OVERVIEW_IS_LOADING });
}

export function resetOverviewIsLoading() {
  return ({ type: types.RESET_OVERVIEW_IS_LOADING });
}

export function setOverviewError(error) {
  return ({
    type: types.SET_OVERVIEW_ERROR,
    error,
  });
}

export function resetOverviewError() {
  return {
    type: types.RESET_OVERVIEW_ERROR,
  };
}

function setOverview(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_OVERVIEW,
      overview: data,
    });
  };
}

const memo = {};

const isTimeToUpdateData = (date1, date2, timeBeforeNextUpdate) => ((date1.getTime() - date2.getTime()) / 1000) >= timeBeforeNextUpdate;

const successGetOverviewResponse = (dispatch, response, state, type) => {
  {
    const overview = toCamel(response.toObject());
    dispatch(setOverview(overview));
    const wasCompanyChanged = overview.companyId !== state.companies.selectedCompany?.id;
    if (overview.dataByDepartmentList.length > 0 && (wasCompanyChanged || !state.overviewState.isAllDepartmentDataSet)) {
      const allDepartmentList = overview.dataByDepartmentList.map(({ departmentName }) => departmentName);
      dispatch(setAllSelectedDepartments(allDepartmentList));
    }
    if (type !== 'setDateFilter') {
      dispatch(resetOverviewIsLoading());
    }
  }
};

export function getOverview(companyID, selectedPeriod, departments = [], sites = [], type) {
  return async (dispatch) => {
    overviewRequest.setCompanyId(companyID);
    if (selectedPeriod) {
      period.setStartedAt(selectedPeriod.startedAt);
      period.setFinishedAt(selectedPeriod.finishedAt);
      overviewRequest.setPeriod(period);
    } else {
      overviewRequest.clearPeriod();
    }
    overviewRequest.setDepartmentIdsList(departments);
    overviewRequest.setSiteIdsList(sites);
    dispatch(resetOverviewError());
    if (type !== 'setDateFilter') {
      dispatch(setOverviewIsLoading());
    }
    const memoKey = JSON.stringify(overviewRequest.array) + localStorage.getItem('token');
    await overviewService.getNewOverviewData(overviewRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(resetOverviewIsLoading());
        error.code !== 5 &&
          dispatch(
            setNotification({
              type: notificationTypes.TYPE_FAILED,
              message: notificationMessages.GENERIC_ERROR,
            })
          );
        dispatch(setOverviewError(error));
      } else {
        dispatch(resetOverviewIsLoading());
        memo[memoKey] = { data: response, date: new Date() };
        dispatch(setOverview(toCamel(response.toObject())));
      }
    });
  };
}

export function resetOverview() {
  return async (dispatch) => {
    dispatch({
      type: types.RESET_OVERVIEW,
    });
  };
}
