// source: api/dashboard-admin/v1/product_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.dashboard_admin.AvailableProducts', null, global);
goog.exportSymbol('proto.dashboard_admin.ProductInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.AvailableProducts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.AvailableProducts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.AvailableProducts.displayName = 'proto.dashboard_admin.AvailableProducts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dashboard_admin.ProductInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dashboard_admin.ProductInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dashboard_admin.ProductInfo.displayName = 'proto.dashboard_admin.ProductInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.AvailableProducts.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.AvailableProducts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.AvailableProducts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.AvailableProducts.toObject = function(includeInstance, msg) {
  var f, obj = {
    wearable: (f = msg.getWearable()) && proto.dashboard_admin.ProductInfo.toObject(includeInstance, f),
    soterTask: (f = msg.getSoterTask()) && proto.dashboard_admin.ProductInfo.toObject(includeInstance, f),
    jobDictionary: (f = msg.getJobDictionary()) && proto.dashboard_admin.ProductInfo.toObject(includeInstance, f),
    ehsCopilot: (f = msg.getEhsCopilot()) && proto.dashboard_admin.ProductInfo.toObject(includeInstance, f),
    genius: (f = msg.getGenius()) && proto.dashboard_admin.ProductInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.AvailableProducts}
 */
proto.dashboard_admin.AvailableProducts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.AvailableProducts;
  return proto.dashboard_admin.AvailableProducts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.AvailableProducts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.AvailableProducts}
 */
proto.dashboard_admin.AvailableProducts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dashboard_admin.ProductInfo;
      reader.readMessage(value,proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader);
      msg.setWearable(value);
      break;
    case 2:
      var value = new proto.dashboard_admin.ProductInfo;
      reader.readMessage(value,proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader);
      msg.setSoterTask(value);
      break;
    case 3:
      var value = new proto.dashboard_admin.ProductInfo;
      reader.readMessage(value,proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader);
      msg.setJobDictionary(value);
      break;
    case 4:
      var value = new proto.dashboard_admin.ProductInfo;
      reader.readMessage(value,proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader);
      msg.setEhsCopilot(value);
      break;
    case 5:
      var value = new proto.dashboard_admin.ProductInfo;
      reader.readMessage(value,proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader);
      msg.setGenius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.AvailableProducts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.AvailableProducts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.AvailableProducts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.AvailableProducts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWearable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dashboard_admin.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getSoterTask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dashboard_admin.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getJobDictionary();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dashboard_admin.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getEhsCopilot();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dashboard_admin.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getGenius();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dashboard_admin.ProductInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProductInfo wearable = 1;
 * @return {?proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.AvailableProducts.prototype.getWearable = function() {
  return /** @type{?proto.dashboard_admin.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.ProductInfo, 1));
};


/**
 * @param {?proto.dashboard_admin.ProductInfo|undefined} value
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
*/
proto.dashboard_admin.AvailableProducts.prototype.setWearable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
 */
proto.dashboard_admin.AvailableProducts.prototype.clearWearable = function() {
  return this.setWearable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.AvailableProducts.prototype.hasWearable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductInfo soter_task = 2;
 * @return {?proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.AvailableProducts.prototype.getSoterTask = function() {
  return /** @type{?proto.dashboard_admin.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.ProductInfo, 2));
};


/**
 * @param {?proto.dashboard_admin.ProductInfo|undefined} value
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
*/
proto.dashboard_admin.AvailableProducts.prototype.setSoterTask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
 */
proto.dashboard_admin.AvailableProducts.prototype.clearSoterTask = function() {
  return this.setSoterTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.AvailableProducts.prototype.hasSoterTask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductInfo job_dictionary = 3;
 * @return {?proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.AvailableProducts.prototype.getJobDictionary = function() {
  return /** @type{?proto.dashboard_admin.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.ProductInfo, 3));
};


/**
 * @param {?proto.dashboard_admin.ProductInfo|undefined} value
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
*/
proto.dashboard_admin.AvailableProducts.prototype.setJobDictionary = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
 */
proto.dashboard_admin.AvailableProducts.prototype.clearJobDictionary = function() {
  return this.setJobDictionary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.AvailableProducts.prototype.hasJobDictionary = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProductInfo ehs_copilot = 4;
 * @return {?proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.AvailableProducts.prototype.getEhsCopilot = function() {
  return /** @type{?proto.dashboard_admin.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.ProductInfo, 4));
};


/**
 * @param {?proto.dashboard_admin.ProductInfo|undefined} value
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
*/
proto.dashboard_admin.AvailableProducts.prototype.setEhsCopilot = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
 */
proto.dashboard_admin.AvailableProducts.prototype.clearEhsCopilot = function() {
  return this.setEhsCopilot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.AvailableProducts.prototype.hasEhsCopilot = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProductInfo genius = 5;
 * @return {?proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.AvailableProducts.prototype.getGenius = function() {
  return /** @type{?proto.dashboard_admin.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.dashboard_admin.ProductInfo, 5));
};


/**
 * @param {?proto.dashboard_admin.ProductInfo|undefined} value
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
*/
proto.dashboard_admin.AvailableProducts.prototype.setGenius = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dashboard_admin.AvailableProducts} returns this
 */
proto.dashboard_admin.AvailableProducts.prototype.clearGenius = function() {
  return this.setGenius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dashboard_admin.AvailableProducts.prototype.hasGenius = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dashboard_admin.ProductInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.dashboard_admin.ProductInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dashboard_admin.ProductInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.ProductInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    timeStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeEnd: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.ProductInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dashboard_admin.ProductInfo;
  return proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dashboard_admin.ProductInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dashboard_admin.ProductInfo}
 */
proto.dashboard_admin.ProductInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAvailable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dashboard_admin.ProductInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dashboard_admin.ProductInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dashboard_admin.ProductInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dashboard_admin.ProductInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAvailable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTimeEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool is_available = 1;
 * @return {boolean}
 */
proto.dashboard_admin.ProductInfo.prototype.getIsAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dashboard_admin.ProductInfo} returns this
 */
proto.dashboard_admin.ProductInfo.prototype.setIsAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 time_start = 2;
 * @return {number}
 */
proto.dashboard_admin.ProductInfo.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.ProductInfo} returns this
 */
proto.dashboard_admin.ProductInfo.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 time_end = 3;
 * @return {number}
 */
proto.dashboard_admin.ProductInfo.prototype.getTimeEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dashboard_admin.ProductInfo} returns this
 */
proto.dashboard_admin.ProductInfo.prototype.setTimeEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.dashboard_admin);
