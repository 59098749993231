/**
 * @fileoverview gRPC-Web generated client stub for dashboard_admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard-admin/v1/company.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard$admin_v1_company_message_pb = require('../../../api/dashboard-admin/v1/company_message_pb.js')
const proto = {};
proto.dashboard_admin = require('./company_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dashboard_admin.AdminCompanyClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dashboard_admin.AdminCompanyPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.CreateCompanyRequest,
 *   !proto.dashboard_admin.Company>}
 */
const methodDescriptor_AdminCompany_CreateCompany = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/CreateCompany',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.CreateCompanyRequest,
  api_dashboard$admin_v1_company_message_pb.Company,
  /**
   * @param {!proto.dashboard_admin.CreateCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.Company.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.CreateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.createCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/CreateCompany',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_CreateCompany,
      callback);
};


/**
 * @param {!proto.dashboard_admin.CreateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.Company>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.createCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/CreateCompany',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_CreateCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.EditCompanyRequest,
 *   !proto.dashboard_admin.Company>}
 */
const methodDescriptor_AdminCompany_EditCompany = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/EditCompany',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.EditCompanyRequest,
  api_dashboard$admin_v1_company_message_pb.Company,
  /**
   * @param {!proto.dashboard_admin.EditCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.Company.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.EditCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.editCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/EditCompany',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_EditCompany,
      callback);
};


/**
 * @param {!proto.dashboard_admin.EditCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.Company>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.editCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/EditCompany',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_EditCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.DeleteCompanyRequest,
 *   !proto.dashboard_admin.DeleteCompanyResponse>}
 */
const methodDescriptor_AdminCompany_DeleteCompany = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/DeleteCompany',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.DeleteCompanyRequest,
  api_dashboard$admin_v1_company_message_pb.DeleteCompanyResponse,
  /**
   * @param {!proto.dashboard_admin.DeleteCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.DeleteCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.DeleteCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.DeleteCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.DeleteCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.deleteCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/DeleteCompany',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_DeleteCompany,
      callback);
};


/**
 * @param {!proto.dashboard_admin.DeleteCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.DeleteCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.deleteCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/DeleteCompany',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_DeleteCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetCompaniesRequest,
 *   !proto.dashboard_admin.GetCompaniesResponse>}
 */
const methodDescriptor_AdminCompany_GetCompanies = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetCompanies',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetCompaniesRequest,
  api_dashboard$admin_v1_company_message_pb.GetCompaniesResponse,
  /**
   * @param {!proto.dashboard_admin.GetCompaniesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetCompaniesResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetCompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetCompaniesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetCompaniesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getCompanies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetCompanies',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetCompanies,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetCompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetCompaniesResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getCompanies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetCompanies',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetCompanies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetCompanyInfoRequest,
 *   !proto.dashboard_admin.GetCompanyInfoResponse>}
 */
const methodDescriptor_AdminCompany_GetCompanyInfo = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetCompanyInfo',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetCompanyInfoRequest,
  api_dashboard$admin_v1_company_message_pb.GetCompanyInfoResponse,
  /**
   * @param {!proto.dashboard_admin.GetCompanyInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetCompanyInfoResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetCompanyInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetCompanyInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetCompanyInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getCompanyInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetCompanyInfo',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetCompanyInfo,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetCompanyInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetCompanyInfoResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getCompanyInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetCompanyInfo',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetCompanyInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetAvailableFirmwaresRequest,
 *   !proto.dashboard_admin.GetAvailableFirmwaresResponse>}
 */
const methodDescriptor_AdminCompany_GetAvailableFirmwares = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetAvailableFirmwares',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetAvailableFirmwaresRequest,
  api_dashboard$admin_v1_company_message_pb.GetAvailableFirmwaresResponse,
  /**
   * @param {!proto.dashboard_admin.GetAvailableFirmwaresRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetAvailableFirmwaresResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetAvailableFirmwaresResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetAvailableFirmwaresResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getAvailableFirmwares =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableFirmwares',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableFirmwares,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetAvailableFirmwaresRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetAvailableFirmwaresResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getAvailableFirmwares =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableFirmwares',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableFirmwares);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetAvailableTiersRequest,
 *   !proto.dashboard_admin.GetAvailableTiersResponse>}
 */
const methodDescriptor_AdminCompany_GetAvailableTiers = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetAvailableTiers',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetAvailableTiersRequest,
  api_dashboard$admin_v1_company_message_pb.GetAvailableTiersResponse,
  /**
   * @param {!proto.dashboard_admin.GetAvailableTiersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetAvailableTiersResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetAvailableTiersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetAvailableTiersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetAvailableTiersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getAvailableTiers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableTiers',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableTiers,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetAvailableTiersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetAvailableTiersResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getAvailableTiers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableTiers',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableTiers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetAvailableCoachingProgramsRequest,
 *   !proto.dashboard_admin.GetAvailableCoachingProgramsResponse>}
 */
const methodDescriptor_AdminCompany_GetAvailableCoachingPrograms = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetAvailableCoachingPrograms',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetAvailableCoachingProgramsRequest,
  api_dashboard$admin_v1_company_message_pb.GetAvailableCoachingProgramsResponse,
  /**
   * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetAvailableCoachingProgramsResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetAvailableCoachingProgramsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetAvailableCoachingProgramsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getAvailableCoachingPrograms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableCoachingPrograms',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableCoachingPrograms,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetAvailableCoachingProgramsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetAvailableCoachingProgramsResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getAvailableCoachingPrograms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableCoachingPrograms',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableCoachingPrograms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetAvailableLanguagesRequest,
 *   !proto.dashboard_admin.GetAvailableLanguagesResponse>}
 */
const methodDescriptor_AdminCompany_GetAvailableLanguages = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetAvailableLanguages',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetAvailableLanguagesRequest,
  api_dashboard$admin_v1_company_message_pb.GetAvailableLanguagesResponse,
  /**
   * @param {!proto.dashboard_admin.GetAvailableLanguagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetAvailableLanguagesResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetAvailableLanguagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetAvailableLanguagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetAvailableLanguagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getAvailableLanguages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableLanguages',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableLanguages,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetAvailableLanguagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetAvailableLanguagesResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getAvailableLanguages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetAvailableLanguages',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetAvailableLanguages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.GetDashboardFeedbackRequest,
 *   !proto.dashboard_admin.GetDashboardUsersFeedbackResponse>}
 */
const methodDescriptor_AdminCompany_GetDashboardUsersFeedback = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminCompany/GetDashboardUsersFeedback',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_company_message_pb.GetDashboardFeedbackRequest,
  api_dashboard$admin_v1_company_message_pb.GetDashboardUsersFeedbackResponse,
  /**
   * @param {!proto.dashboard_admin.GetDashboardFeedbackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_company_message_pb.GetDashboardUsersFeedbackResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.GetDashboardFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.GetDashboardUsersFeedbackResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.GetDashboardUsersFeedbackResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminCompanyClient.prototype.getDashboardUsersFeedback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetDashboardUsersFeedback',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetDashboardUsersFeedback,
      callback);
};


/**
 * @param {!proto.dashboard_admin.GetDashboardFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.GetDashboardUsersFeedbackResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminCompanyPromiseClient.prototype.getDashboardUsersFeedback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminCompany/GetDashboardUsersFeedback',
      request,
      metadata || {},
      methodDescriptor_AdminCompany_GetDashboardUsersFeedback);
};


module.exports = proto.dashboard_admin;

