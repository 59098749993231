import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';
export const switcherStyles = Emotion.css `
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: 700;
  }
  input[type='checkbox'] {
    background: red;
    position: relative;
    width: 44px;
    height: 24px;
    -webkit-appearance: none;
    background: #999;
    outline: none;
    border-radius: 20px;
    transition: 0.3s;
  }
  input:checked[type='checkbox'] {
    background: #2096F3;
  }
  input[type='checkbox']:before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    background: #fff;
    position: absolute;
    transition: 0.3s;
  }
  input:checked[type='checkbox']:before {
    left: 22px;
  }
`;
export const customCheckboxStyles = Emotion.css `
  display: flex;
  align-items: center;
  margin-left: 12px;

input[type='checkbox'] {
  flex-shrink: 0;
  background: transparent;
  border: 2px solid #999;
  border-radius: 2px;
  transition: 0.3s;
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-appearance: none;
  outline: none;
}
input:checked[type='checkbox'] {
  background: #2096F3;
  border-color: #2096F3;
}
input:checked[type='checkbox']:before {
  content: '';
  width: 16px;
  height: 13px;
  border-radius: 50%;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  background: url('/assets/img/icons/check-tick.svg') no-repeat;
  position: absolute;
  transition: 0.3s;
}
.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.GRAY[950]};
  margin-left: 15px;
}
`;
