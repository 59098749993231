import { ACTIVE_USERS_CHART } from 'constants/colors';

export const deviceTypesUsersMap = {
  spineUsers: 'WEARABLES.CHARTS.DEVICE_LEGEND.SPINE_USERS',
  shoulderUsers: 'WEARABLES.CHARTS.DEVICE_LEGEND.SHOULDER_USERS',
  helmetUsers: 'WEARABLES.CHARTS.DEVICE_LEGEND.HELMET_USERS',
  headsetUsers: 'WEARABLES.CHARTS.DEVICE_LEGEND.HEADSET_USERS',
};

const deviceTypesUsersKeys = Object.keys(deviceTypesUsersMap);

export const deviceTypesUsersColorMap = [
  {
    alias: deviceTypesUsersKeys[0],
    name: deviceTypesUsersMap.spineUsers,
    color: ACTIVE_USERS_CHART[0],
  },
  {
    alias: deviceTypesUsersKeys[1],
    name: deviceTypesUsersMap.shoulderUsers,
    color: ACTIVE_USERS_CHART[1],
  },
  {
    alias: deviceTypesUsersKeys[2],
    name: deviceTypesUsersMap.helmetUsers,
    color: ACTIVE_USERS_CHART[2],
  },
  {
    alias: deviceTypesUsersKeys[3],
    name: deviceTypesUsersMap.headsetUsers,
    color: ACTIVE_USERS_CHART[3],
  },
];
