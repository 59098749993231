export const CATEGORY = 'CATEGORY';
export const GET_CATEGORIES = '/GET_CATEGORIES';
export const CREATE_CATEGORY = '/CREATE_CATEGORY';
export const EDIT_CATEGORY = '/EDIT_CATEGORY';
export const CREATE_ENTRY = '/CREATE_TAG';
export const ADD_ENTRY = '/ADD_ENTRY';
export const EDIT_ENTRY = '/EDIT_ENTRY';
export const DELETE_ENTRY = '/DELETE_ENTRY';
export const REMOVE_ENTRY = '/REMOVE_ENTRY';
export const DELETE_CATEGORY = '/DELETE_CATEGORY';
export const FULFILLED = '/fulfilled';
export const REJECTED = '/rejected';
export const PENDING = '/pending';
