import { createAction, createSlice } from '@reduxjs/toolkit';
import { authMe, contactSales, getUserResource, login, resetPassword, resetPasswordByToken, requestSSOLink, } from './actions';
const initialState = {
    profile: null,
    anonymous: true,
    isPasswordReset: false,
    isPasswordSet: false,
    resources: {
        departmentsList: null,
        sitesList: null,
    },
    companiesList: [],
    error: null,
    errorAction: null,
    isLoading: false,
    isContactSales: false,
};
const pending = createAction('auth/pending');
const rejected = createAction('auth/rejected');
const adminCompaniesSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetProfile: () => initialState,
        clearError: (state) => {
            state.error = null;
        },
        setAnonymous: (state) => {
            state.anonymous = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            if (action.payload) {
                const { auth, anonymous } = action.payload;
                state.profile = auth.profile;
                state.anonymous = anonymous;
                state.error = null;
            }
        });
        builder.addCase(login.rejected, (state, action) => {
            const { error, errorAction } = action.payload;
            state.error = error;
            state.errorAction = errorAction;
        });
        builder.addCase(requestSSOLink.rejected, (state, action) => {
            const { error, errorAction } = action.payload;
            state.error = error;
            state.errorAction = errorAction;
        });
        builder.addCase(authMe.fulfilled, (state, action) => {
            if (action.payload) {
                const { auth, anonymous } = action.payload;
                state.profile = auth.profile;
                state.anonymous = anonymous;
                state.error = null;
            }
        });
        builder.addCase(authMe.rejected, (state, action) => {
            const { anonymous, error } = action.payload;
            state.anonymous = anonymous;
            state.error = error;
        });
        builder.addCase(getUserResource.fulfilled, (state, action) => {
            if (action.payload) {
                const { resources } = action.payload;
                state.resources = resources;
            }
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            if (action.payload) {
                const { isPasswordReset } = action.payload;
                state.isPasswordReset = isPasswordReset;
            }
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            const { anonymous, error } = action.payload;
            state.anonymous = anonymous;
            state.error = error;
        });
        builder.addCase(resetPasswordByToken.fulfilled, (state, action) => {
            if (action.payload) {
                const { isPasswordSet } = action.payload;
                state.isPasswordSet = isPasswordSet;
            }
        });
        builder.addCase(resetPasswordByToken.rejected, (state, action) => {
            const { anonymous, error } = action.payload;
            state.anonymous = anonymous;
            state.error = error;
        });
        builder.addCase(contactSales.fulfilled, (state, action) => {
            if (action.payload) {
                const { isContactSales } = action.payload;
                state.isContactSales = isContactSales;
            }
        });
    },
});
export const { resetProfile, clearError, setAnonymous } = adminCompaniesSlice.actions;
export default adminCompaniesSlice.reducer;
