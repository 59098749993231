import React from 'react';
import { COLORS } from 'constants/colors';

const NoUserFoundPage = ({ id }) => (
  <div
    style={{
      width: '100%',
      height: 'calc(100vh - 120px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: COLORS.GRAY[300],
    }}
  >
    <h3>{`User with ID ${id} doesn't exist`}</h3>
  </div>
);

export default NoUserFoundPage;
