import React from 'react';
import moment from 'moment';
import { mutedText } from '../../../../assets/soter-flex-styles';

const CreatedAtCell = ({ rowData, x, noFormat }) => {
  const format = x ? 'X' : noFormat ? '' : 'x';
  const time = moment(rowData.createdAt, format);
  const fromNow = time.fromNow();
  return (
    <>
      <div>
        <span>{time.format('DD MMM YYYY')}</span>
        <div style={{ fontSize: '12px' }} className={mutedText}>{fromNow?.indexOf('ago') > -1 && fromNow?.slice(0, fromNow.length - 3)}</div>
      </div>
    </>
  );
};

export default CreatedAtCell;
