// source: api/dashboard/v2/admin_companies_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_company_message_pb = require('../../../api/dashboard/v2/company_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_company_message_pb);
var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_base_enum_pb = require('../../../api/dashboard/v2/base_enum_pb.js');
goog.object.extend(proto, api_dashboard_v2_base_enum_pb);
var api_dashboard_v2_admin_message_pb = require('../../../api/dashboard/v2/admin_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_admin_message_pb);
goog.exportSymbol('proto.admin.AdminCoachingProgram', null, global);
goog.exportSymbol('proto.admin.AdminCompany', null, global);
goog.exportSymbol('proto.admin.AdminFirmware', null, global);
goog.exportSymbol('proto.admin.AdminTiers', null, global);
goog.exportSymbol('proto.admin.AvailableProducts', null, global);
goog.exportSymbol('proto.admin.CompaniesAdminRequest', null, global);
goog.exportSymbol('proto.admin.CreateCompanyRequest', null, global);
goog.exportSymbol('proto.admin.DeleteCompanyRequest', null, global);
goog.exportSymbol('proto.admin.Reseller', null, global);
goog.exportSymbol('proto.admin.ResponseAdminCompanies', null, global);
goog.exportSymbol('proto.admin.SoterTaskSettings', null, global);
goog.exportSymbol('proto.admin.UpdateCompanyRequest', null, global);
goog.exportSymbol('proto.admin.WearableSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.Reseller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.Reseller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.Reseller.displayName = 'proto.admin.Reseller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.AdminCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.AdminCompany.repeatedFields_, null);
};
goog.inherits(proto.admin.AdminCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.AdminCompany.displayName = 'proto.admin.AdminCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.ResponseAdminCompanies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.ResponseAdminCompanies.repeatedFields_, null);
};
goog.inherits(proto.admin.ResponseAdminCompanies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.ResponseAdminCompanies.displayName = 'proto.admin.ResponseAdminCompanies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.AdminFirmware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.AdminFirmware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.AdminFirmware.displayName = 'proto.admin.AdminFirmware';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.AdminTiers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.AdminTiers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.AdminTiers.displayName = 'proto.admin.AdminTiers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.AdminCoachingProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.AdminCoachingProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.AdminCoachingProgram.displayName = 'proto.admin.AdminCoachingProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.CompaniesAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.CompaniesAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.CompaniesAdminRequest.displayName = 'proto.admin.CompaniesAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.CreateCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.CreateCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.CreateCompanyRequest.displayName = 'proto.admin.CreateCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.UpdateCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.UpdateCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.UpdateCompanyRequest.displayName = 'proto.admin.UpdateCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DeleteCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.DeleteCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DeleteCompanyRequest.displayName = 'proto.admin.DeleteCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.AvailableProducts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.AvailableProducts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.AvailableProducts.displayName = 'proto.admin.AvailableProducts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.WearableSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.WearableSettings.repeatedFields_, null);
};
goog.inherits(proto.admin.WearableSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.WearableSettings.displayName = 'proto.admin.WearableSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.SoterTaskSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.SoterTaskSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.SoterTaskSettings.displayName = 'proto.admin.SoterTaskSettings';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.Reseller.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.Reseller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.Reseller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.Reseller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.Reseller}
 */
proto.admin.Reseller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.Reseller;
  return proto.admin.Reseller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.Reseller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.Reseller}
 */
proto.admin.Reseller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.Reseller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.Reseller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.Reseller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.Reseller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.Reseller.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.Reseller} returns this
 */
proto.admin.Reseller.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin.Reseller.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.Reseller} returns this
 */
proto.admin.Reseller.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.AdminCompany.repeatedFields_ = [12,13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.AdminCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.AdminCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.AdminCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    availableProducts: (f = msg.getAvailableProducts()) && proto.admin.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && proto.admin.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && proto.admin.SoterTaskSettings.toObject(includeInstance, f),
    deviceCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    partner: (f = msg.getPartner()) && api_dashboard_v2_company_message_pb.CompanyReseller.toObject(includeInstance, f),
    availableCoachingProgramsList: jspb.Message.toObjectList(msg.getAvailableCoachingProgramsList(),
    api_dashboard_v2_company_message_pb.CompanyCoachingProgram.toObject, includeInstance),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard_v2_department_message_pb.Department.toObject, includeInstance),
    tier: (f = msg.getTier()) && proto.admin.AdminTiers.toObject(includeInstance, f),
    availableFirmwaresList: jspb.Message.toObjectList(msg.getAvailableFirmwaresList(),
    proto.admin.AdminFirmware.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    currentFirmware: (f = msg.getCurrentFirmware()) && proto.admin.AdminFirmware.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.AdminCompany}
 */
proto.admin.AdminCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.AdminCompany;
  return proto.admin.AdminCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.AdminCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.AdminCompany}
 */
proto.admin.AdminCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 5:
      var value = new proto.admin.AvailableProducts;
      reader.readMessage(value,proto.admin.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 6:
      var value = new proto.admin.WearableSettings;
      reader.readMessage(value,proto.admin.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 8:
      var value = new proto.admin.SoterTaskSettings;
      reader.readMessage(value,proto.admin.SoterTaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceCount(value);
      break;
    case 10:
      var value = new api_dashboard_v2_company_message_pb.CompanyReseller;
      reader.readMessage(value,api_dashboard_v2_company_message_pb.CompanyReseller.deserializeBinaryFromReader);
      msg.setPartner(value);
      break;
    case 12:
      var value = new api_dashboard_v2_company_message_pb.CompanyCoachingProgram;
      reader.readMessage(value,api_dashboard_v2_company_message_pb.CompanyCoachingProgram.deserializeBinaryFromReader);
      msg.addAvailableCoachingPrograms(value);
      break;
    case 13:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 14:
      var value = new proto.admin.AdminTiers;
      reader.readMessage(value,proto.admin.AdminTiers.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 15:
      var value = new proto.admin.AdminFirmware;
      reader.readMessage(value,proto.admin.AdminFirmware.deserializeBinaryFromReader);
      msg.addAvailableFirmwares(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 17:
      var value = new proto.admin.AdminFirmware;
      reader.readMessage(value,proto.admin.AdminFirmware.deserializeBinaryFromReader);
      msg.setCurrentFirmware(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.AdminCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.AdminCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.AdminCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.admin.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.admin.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.admin.SoterTaskSettings.serializeBinaryToWriter
    );
  }
  f = message.getDeviceCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPartner();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      api_dashboard_v2_company_message_pb.CompanyReseller.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCoachingProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      api_dashboard_v2_company_message_pb.CompanyCoachingProgram.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.admin.AdminTiers.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFirmwaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.admin.AdminFirmware.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCurrentFirmware();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.admin.AdminFirmware.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.AdminCompany.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin.AdminCompany.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.admin.AdminCompany.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 partner_id = 4;
 * @return {number}
 */
proto.admin.AdminCompany.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AvailableProducts available_products = 5;
 * @return {?proto.admin.AvailableProducts}
 */
proto.admin.AdminCompany.prototype.getAvailableProducts = function() {
  return /** @type{?proto.admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, proto.admin.AvailableProducts, 5));
};


/**
 * @param {?proto.admin.AvailableProducts|undefined} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.AdminCompany.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WearableSettings wearable_settings = 6;
 * @return {?proto.admin.WearableSettings}
 */
proto.admin.AdminCompany.prototype.getWearableSettings = function() {
  return /** @type{?proto.admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, proto.admin.WearableSettings, 6));
};


/**
 * @param {?proto.admin.WearableSettings|undefined} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.AdminCompany.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SoterTaskSettings soter_task_settings = 8;
 * @return {?proto.admin.SoterTaskSettings}
 */
proto.admin.AdminCompany.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.admin.SoterTaskSettings} */ (
    jspb.Message.getWrapperField(this, proto.admin.SoterTaskSettings, 8));
};


/**
 * @param {?proto.admin.SoterTaskSettings|undefined} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.AdminCompany.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 device_count = 9;
 * @return {number}
 */
proto.admin.AdminCompany.prototype.getDeviceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.setDeviceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional company.CompanyReseller partner = 10;
 * @return {?proto.company.CompanyReseller}
 */
proto.admin.AdminCompany.prototype.getPartner = function() {
  return /** @type{?proto.company.CompanyReseller} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_company_message_pb.CompanyReseller, 10));
};


/**
 * @param {?proto.company.CompanyReseller|undefined} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setPartner = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearPartner = function() {
  return this.setPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.AdminCompany.prototype.hasPartner = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated company.CompanyCoachingProgram available_coaching_programs = 12;
 * @return {!Array<!proto.company.CompanyCoachingProgram>}
 */
proto.admin.AdminCompany.prototype.getAvailableCoachingProgramsList = function() {
  return /** @type{!Array<!proto.company.CompanyCoachingProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_company_message_pb.CompanyCoachingProgram, 12));
};


/**
 * @param {!Array<!proto.company.CompanyCoachingProgram>} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setAvailableCoachingProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.company.CompanyCoachingProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.company.CompanyCoachingProgram}
 */
proto.admin.AdminCompany.prototype.addAvailableCoachingPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.company.CompanyCoachingProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearAvailableCoachingProgramsList = function() {
  return this.setAvailableCoachingProgramsList([]);
};


/**
 * repeated department_message.Department departments = 13;
 * @return {!Array<!proto.department_message.Department>}
 */
proto.admin.AdminCompany.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.department_message.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_department_message_pb.Department, 13));
};


/**
 * @param {!Array<!proto.department_message.Department>} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.department_message.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.department_message.Department}
 */
proto.admin.AdminCompany.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.department_message.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional AdminTiers tier = 14;
 * @return {?proto.admin.AdminTiers}
 */
proto.admin.AdminCompany.prototype.getTier = function() {
  return /** @type{?proto.admin.AdminTiers} */ (
    jspb.Message.getWrapperField(this, proto.admin.AdminTiers, 14));
};


/**
 * @param {?proto.admin.AdminTiers|undefined} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.AdminCompany.prototype.hasTier = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated AdminFirmware available_firmwares = 15;
 * @return {!Array<!proto.admin.AdminFirmware>}
 */
proto.admin.AdminCompany.prototype.getAvailableFirmwaresList = function() {
  return /** @type{!Array<!proto.admin.AdminFirmware>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.AdminFirmware, 15));
};


/**
 * @param {!Array<!proto.admin.AdminFirmware>} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setAvailableFirmwaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.admin.AdminFirmware=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.AdminFirmware}
 */
proto.admin.AdminCompany.prototype.addAvailableFirmwares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.admin.AdminFirmware, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearAvailableFirmwaresList = function() {
  return this.setAvailableFirmwaresList([]);
};


/**
 * optional int64 created_at = 16;
 * @return {number}
 */
proto.admin.AdminCompany.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional AdminFirmware current_firmware = 17;
 * @return {?proto.admin.AdminFirmware}
 */
proto.admin.AdminCompany.prototype.getCurrentFirmware = function() {
  return /** @type{?proto.admin.AdminFirmware} */ (
    jspb.Message.getWrapperField(this, proto.admin.AdminFirmware, 17));
};


/**
 * @param {?proto.admin.AdminFirmware|undefined} value
 * @return {!proto.admin.AdminCompany} returns this
*/
proto.admin.AdminCompany.prototype.setCurrentFirmware = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.AdminCompany} returns this
 */
proto.admin.AdminCompany.prototype.clearCurrentFirmware = function() {
  return this.setCurrentFirmware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.AdminCompany.prototype.hasCurrentFirmware = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.ResponseAdminCompanies.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.ResponseAdminCompanies.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.ResponseAdminCompanies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.ResponseAdminCompanies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.ResponseAdminCompanies.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyList: jspb.Message.toObjectList(msg.getCompanyList(),
    proto.admin.AdminCompany.toObject, includeInstance),
    availableFirmwaresList: jspb.Message.toObjectList(msg.getAvailableFirmwaresList(),
    proto.admin.AdminFirmware.toObject, includeInstance),
    availableTiersList: jspb.Message.toObjectList(msg.getAvailableTiersList(),
    proto.admin.AdminTiers.toObject, includeInstance),
    availableCoachingProgramList: jspb.Message.toObjectList(msg.getAvailableCoachingProgramList(),
    proto.admin.AdminCoachingProgram.toObject, includeInstance),
    availableLanguages: (f = msg.getAvailableLanguages()) && api_dashboard_v2_base_enum_pb.AvailableLanguages.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.ResponseAdminCompanies}
 */
proto.admin.ResponseAdminCompanies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.ResponseAdminCompanies;
  return proto.admin.ResponseAdminCompanies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.ResponseAdminCompanies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.ResponseAdminCompanies}
 */
proto.admin.ResponseAdminCompanies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin.AdminCompany;
      reader.readMessage(value,proto.admin.AdminCompany.deserializeBinaryFromReader);
      msg.addCompany(value);
      break;
    case 2:
      var value = new proto.admin.AdminFirmware;
      reader.readMessage(value,proto.admin.AdminFirmware.deserializeBinaryFromReader);
      msg.addAvailableFirmwares(value);
      break;
    case 3:
      var value = new proto.admin.AdminTiers;
      reader.readMessage(value,proto.admin.AdminTiers.deserializeBinaryFromReader);
      msg.addAvailableTiers(value);
      break;
    case 4:
      var value = new proto.admin.AdminCoachingProgram;
      reader.readMessage(value,proto.admin.AdminCoachingProgram.deserializeBinaryFromReader);
      msg.addAvailableCoachingProgram(value);
      break;
    case 5:
      var value = new api_dashboard_v2_base_enum_pb.AvailableLanguages;
      reader.readMessage(value,api_dashboard_v2_base_enum_pb.AvailableLanguages.deserializeBinaryFromReader);
      msg.setAvailableLanguages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.ResponseAdminCompanies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.ResponseAdminCompanies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.ResponseAdminCompanies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.ResponseAdminCompanies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admin.AdminCompany.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFirmwaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.admin.AdminFirmware.serializeBinaryToWriter
    );
  }
  f = message.getAvailableTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.admin.AdminTiers.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCoachingProgramList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.admin.AdminCoachingProgram.serializeBinaryToWriter
    );
  }
  f = message.getAvailableLanguages();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_dashboard_v2_base_enum_pb.AvailableLanguages.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdminCompany company = 1;
 * @return {!Array<!proto.admin.AdminCompany>}
 */
proto.admin.ResponseAdminCompanies.prototype.getCompanyList = function() {
  return /** @type{!Array<!proto.admin.AdminCompany>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.AdminCompany, 1));
};


/**
 * @param {!Array<!proto.admin.AdminCompany>} value
 * @return {!proto.admin.ResponseAdminCompanies} returns this
*/
proto.admin.ResponseAdminCompanies.prototype.setCompanyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admin.AdminCompany=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.AdminCompany}
 */
proto.admin.ResponseAdminCompanies.prototype.addCompany = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admin.AdminCompany, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.ResponseAdminCompanies} returns this
 */
proto.admin.ResponseAdminCompanies.prototype.clearCompanyList = function() {
  return this.setCompanyList([]);
};


/**
 * repeated AdminFirmware available_firmwares = 2;
 * @return {!Array<!proto.admin.AdminFirmware>}
 */
proto.admin.ResponseAdminCompanies.prototype.getAvailableFirmwaresList = function() {
  return /** @type{!Array<!proto.admin.AdminFirmware>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.AdminFirmware, 2));
};


/**
 * @param {!Array<!proto.admin.AdminFirmware>} value
 * @return {!proto.admin.ResponseAdminCompanies} returns this
*/
proto.admin.ResponseAdminCompanies.prototype.setAvailableFirmwaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.admin.AdminFirmware=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.AdminFirmware}
 */
proto.admin.ResponseAdminCompanies.prototype.addAvailableFirmwares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.admin.AdminFirmware, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.ResponseAdminCompanies} returns this
 */
proto.admin.ResponseAdminCompanies.prototype.clearAvailableFirmwaresList = function() {
  return this.setAvailableFirmwaresList([]);
};


/**
 * repeated AdminTiers available_tiers = 3;
 * @return {!Array<!proto.admin.AdminTiers>}
 */
proto.admin.ResponseAdminCompanies.prototype.getAvailableTiersList = function() {
  return /** @type{!Array<!proto.admin.AdminTiers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.AdminTiers, 3));
};


/**
 * @param {!Array<!proto.admin.AdminTiers>} value
 * @return {!proto.admin.ResponseAdminCompanies} returns this
*/
proto.admin.ResponseAdminCompanies.prototype.setAvailableTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.admin.AdminTiers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.AdminTiers}
 */
proto.admin.ResponseAdminCompanies.prototype.addAvailableTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.admin.AdminTiers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.ResponseAdminCompanies} returns this
 */
proto.admin.ResponseAdminCompanies.prototype.clearAvailableTiersList = function() {
  return this.setAvailableTiersList([]);
};


/**
 * repeated AdminCoachingProgram available_coaching_program = 4;
 * @return {!Array<!proto.admin.AdminCoachingProgram>}
 */
proto.admin.ResponseAdminCompanies.prototype.getAvailableCoachingProgramList = function() {
  return /** @type{!Array<!proto.admin.AdminCoachingProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.AdminCoachingProgram, 4));
};


/**
 * @param {!Array<!proto.admin.AdminCoachingProgram>} value
 * @return {!proto.admin.ResponseAdminCompanies} returns this
*/
proto.admin.ResponseAdminCompanies.prototype.setAvailableCoachingProgramList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.admin.AdminCoachingProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.AdminCoachingProgram}
 */
proto.admin.ResponseAdminCompanies.prototype.addAvailableCoachingProgram = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.admin.AdminCoachingProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.ResponseAdminCompanies} returns this
 */
proto.admin.ResponseAdminCompanies.prototype.clearAvailableCoachingProgramList = function() {
  return this.setAvailableCoachingProgramList([]);
};


/**
 * optional base_enum.AvailableLanguages available_languages = 5;
 * @return {?proto.base_enum.AvailableLanguages}
 */
proto.admin.ResponseAdminCompanies.prototype.getAvailableLanguages = function() {
  return /** @type{?proto.base_enum.AvailableLanguages} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_base_enum_pb.AvailableLanguages, 5));
};


/**
 * @param {?proto.base_enum.AvailableLanguages|undefined} value
 * @return {!proto.admin.ResponseAdminCompanies} returns this
*/
proto.admin.ResponseAdminCompanies.prototype.setAvailableLanguages = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.ResponseAdminCompanies} returns this
 */
proto.admin.ResponseAdminCompanies.prototype.clearAvailableLanguages = function() {
  return this.setAvailableLanguages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.ResponseAdminCompanies.prototype.hasAvailableLanguages = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.AdminFirmware.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.AdminFirmware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.AdminFirmware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminFirmware.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    newDataFormat: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    critical: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.AdminFirmware}
 */
proto.admin.AdminFirmware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.AdminFirmware;
  return proto.admin.AdminFirmware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.AdminFirmware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.AdminFirmware}
 */
proto.admin.AdminFirmware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewDataFormat(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCritical(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.AdminFirmware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.AdminFirmware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.AdminFirmware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminFirmware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNewDataFormat();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCritical();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.AdminFirmware.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminFirmware} returns this
 */
proto.admin.AdminFirmware.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.admin.AdminFirmware.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminFirmware} returns this
 */
proto.admin.AdminFirmware.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 version = 3;
 * @return {number}
 */
proto.admin.AdminFirmware.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminFirmware} returns this
 */
proto.admin.AdminFirmware.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool new_data_format = 4;
 * @return {boolean}
 */
proto.admin.AdminFirmware.prototype.getNewDataFormat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminFirmware} returns this
 */
proto.admin.AdminFirmware.prototype.setNewDataFormat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool critical = 5;
 * @return {boolean}
 */
proto.admin.AdminFirmware.prototype.getCritical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminFirmware} returns this
 */
proto.admin.AdminFirmware.prototype.setCritical = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.AdminTiers.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.AdminTiers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.AdminTiers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminTiers.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maskRisk: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maskSound: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maskVibration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notificationTimeoutMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    showHazardLifting: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    showHazardTwisting: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    showHazardStatic: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    showHazardIntensity: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    showHazardRepetition: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    showHazardPushingpulling: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    showHazardStaticarmelevation: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    showHazardRepetitivearmmovements: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    showHazardOverexertion: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    showHazardArmElevation: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    firstDayNotificationsDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.AdminTiers}
 */
proto.admin.AdminTiers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.AdminTiers;
  return proto.admin.AdminTiers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.AdminTiers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.AdminTiers}
 */
proto.admin.AdminTiers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaskRisk(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaskSound(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaskVibration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotificationTimeoutMinutes(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardLifting(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardTwisting(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardStatic(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardIntensity(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardRepetition(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardPushingpulling(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardStaticarmelevation(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardRepetitivearmmovements(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardOverexertion(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardArmElevation(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFirstDayNotificationsDisabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.AdminTiers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.AdminTiers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.AdminTiers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminTiers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaskRisk();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaskSound();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMaskVibration();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNotificationTimeoutMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getShowHazardLifting();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getShowHazardTwisting();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getShowHazardStatic();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getShowHazardIntensity();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getShowHazardRepetition();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getShowHazardPushingpulling();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getShowHazardStaticarmelevation();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getShowHazardRepetitivearmmovements();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getShowHazardOverexertion();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getShowHazardArmElevation();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getFirstDayNotificationsDisabled();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.AdminTiers.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin.AdminTiers.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 mask_risk = 3;
 * @return {number}
 */
proto.admin.AdminTiers.prototype.getMaskRisk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setMaskRisk = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 mask_sound = 4;
 * @return {number}
 */
proto.admin.AdminTiers.prototype.getMaskSound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setMaskSound = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 mask_vibration = 5;
 * @return {number}
 */
proto.admin.AdminTiers.prototype.getMaskVibration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setMaskVibration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 notification_timeout_minutes = 6;
 * @return {number}
 */
proto.admin.AdminTiers.prototype.getNotificationTimeoutMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setNotificationTimeoutMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool show_hazard_lifting = 7;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardLifting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardLifting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool show_hazard_twisting = 8;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardTwisting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardTwisting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool show_hazard_static = 9;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardStatic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardStatic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool show_hazard_intensity = 10;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardIntensity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardIntensity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool show_hazard_repetition = 11;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardRepetition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardRepetition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool show_hazard_pushingPulling = 12;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardPushingpulling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardPushingpulling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool show_hazard_staticArmElevation = 13;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardStaticarmelevation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardStaticarmelevation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool show_hazard_repetitiveArmMovements = 14;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardRepetitivearmmovements = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardRepetitivearmmovements = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool show_hazard_overexertion = 15;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardOverexertion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardOverexertion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool show_hazard_arm_elevation = 16;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getShowHazardArmElevation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setShowHazardArmElevation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool first_day_notifications_disabled = 17;
 * @return {boolean}
 */
proto.admin.AdminTiers.prototype.getFirstDayNotificationsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminTiers} returns this
 */
proto.admin.AdminTiers.prototype.setFirstDayNotificationsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.AdminCoachingProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.AdminCoachingProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.AdminCoachingProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminCoachingProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    allTutorialsOpen: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.AdminCoachingProgram}
 */
proto.admin.AdminCoachingProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.AdminCoachingProgram;
  return proto.admin.AdminCoachingProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.AdminCoachingProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.AdminCoachingProgram}
 */
proto.admin.AdminCoachingProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllTutorialsOpen(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.AdminCoachingProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.AdminCoachingProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.AdminCoachingProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AdminCoachingProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAllTutorialsOpen();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.AdminCoachingProgram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin.AdminCoachingProgram.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_active = 3;
 * @return {boolean}
 */
proto.admin.AdminCoachingProgram.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool all_tutorials_open = 4;
 * @return {boolean}
 */
proto.admin.AdminCoachingProgram.prototype.getAllTutorialsOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setAllTutorialsOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_default = 5;
 * @return {boolean}
 */
proto.admin.AdminCoachingProgram.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.admin.AdminCoachingProgram.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string image_url = 7;
 * @return {string}
 */
proto.admin.AdminCoachingProgram.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string device_type = 8;
 * @return {string}
 */
proto.admin.AdminCoachingProgram.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.AdminCoachingProgram} returns this
 */
proto.admin.AdminCoachingProgram.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.CompaniesAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.CompaniesAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.CompaniesAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CompaniesAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.CompaniesAdminRequest}
 */
proto.admin.CompaniesAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.CompaniesAdminRequest;
  return proto.admin.CompaniesAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.CompaniesAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.CompaniesAdminRequest}
 */
proto.admin.CompaniesAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.CompaniesAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.CompaniesAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.CompaniesAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CompaniesAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.admin.CompaniesAdminRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CompaniesAdminRequest} returns this
 */
proto.admin.CompaniesAdminRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.CreateCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.CreateCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.CreateCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CreateCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    availableProducts: (f = msg.getAvailableProducts()) && proto.admin.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && proto.admin.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && proto.admin.SoterTaskSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.CreateCompanyRequest}
 */
proto.admin.CreateCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.CreateCompanyRequest;
  return proto.admin.CreateCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.CreateCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.CreateCompanyRequest}
 */
proto.admin.CreateCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 4:
      var value = new proto.admin.AvailableProducts;
      reader.readMessage(value,proto.admin.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 5:
      var value = new proto.admin.WearableSettings;
      reader.readMessage(value,proto.admin.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 7:
      var value = new proto.admin.SoterTaskSettings;
      reader.readMessage(value,proto.admin.SoterTaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.CreateCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.CreateCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.CreateCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CreateCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.admin.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.admin.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.admin.SoterTaskSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.admin.CreateCompanyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateCompanyRequest} returns this
 */
proto.admin.CreateCompanyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region = 2;
 * @return {string}
 */
proto.admin.CreateCompanyRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateCompanyRequest} returns this
 */
proto.admin.CreateCompanyRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 partner_id = 3;
 * @return {number}
 */
proto.admin.CreateCompanyRequest.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.CreateCompanyRequest} returns this
 */
proto.admin.CreateCompanyRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AvailableProducts available_products = 4;
 * @return {?proto.admin.AvailableProducts}
 */
proto.admin.CreateCompanyRequest.prototype.getAvailableProducts = function() {
  return /** @type{?proto.admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, proto.admin.AvailableProducts, 4));
};


/**
 * @param {?proto.admin.AvailableProducts|undefined} value
 * @return {!proto.admin.CreateCompanyRequest} returns this
*/
proto.admin.CreateCompanyRequest.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.CreateCompanyRequest} returns this
 */
proto.admin.CreateCompanyRequest.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.CreateCompanyRequest.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WearableSettings wearable_settings = 5;
 * @return {?proto.admin.WearableSettings}
 */
proto.admin.CreateCompanyRequest.prototype.getWearableSettings = function() {
  return /** @type{?proto.admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, proto.admin.WearableSettings, 5));
};


/**
 * @param {?proto.admin.WearableSettings|undefined} value
 * @return {!proto.admin.CreateCompanyRequest} returns this
*/
proto.admin.CreateCompanyRequest.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.CreateCompanyRequest} returns this
 */
proto.admin.CreateCompanyRequest.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.CreateCompanyRequest.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SoterTaskSettings soter_task_settings = 7;
 * @return {?proto.admin.SoterTaskSettings}
 */
proto.admin.CreateCompanyRequest.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.admin.SoterTaskSettings} */ (
    jspb.Message.getWrapperField(this, proto.admin.SoterTaskSettings, 7));
};


/**
 * @param {?proto.admin.SoterTaskSettings|undefined} value
 * @return {!proto.admin.CreateCompanyRequest} returns this
*/
proto.admin.CreateCompanyRequest.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.CreateCompanyRequest} returns this
 */
proto.admin.CreateCompanyRequest.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.CreateCompanyRequest.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.UpdateCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.UpdateCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.UpdateCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.UpdateCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    availableProducts: (f = msg.getAvailableProducts()) && proto.admin.AvailableProducts.toObject(includeInstance, f),
    wearableSettings: (f = msg.getWearableSettings()) && proto.admin.WearableSettings.toObject(includeInstance, f),
    soterTaskSettings: (f = msg.getSoterTaskSettings()) && proto.admin.SoterTaskSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.UpdateCompanyRequest}
 */
proto.admin.UpdateCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.UpdateCompanyRequest;
  return proto.admin.UpdateCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.UpdateCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.UpdateCompanyRequest}
 */
proto.admin.UpdateCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerId(value);
      break;
    case 5:
      var value = new proto.admin.AvailableProducts;
      reader.readMessage(value,proto.admin.AvailableProducts.deserializeBinaryFromReader);
      msg.setAvailableProducts(value);
      break;
    case 6:
      var value = new proto.admin.WearableSettings;
      reader.readMessage(value,proto.admin.WearableSettings.deserializeBinaryFromReader);
      msg.setWearableSettings(value);
      break;
    case 8:
      var value = new proto.admin.SoterTaskSettings;
      reader.readMessage(value,proto.admin.SoterTaskSettings.deserializeBinaryFromReader);
      msg.setSoterTaskSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.UpdateCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.UpdateCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.UpdateCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.UpdateCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvailableProducts();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.admin.AvailableProducts.serializeBinaryToWriter
    );
  }
  f = message.getWearableSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.admin.WearableSettings.serializeBinaryToWriter
    );
  }
  f = message.getSoterTaskSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.admin.SoterTaskSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.UpdateCompanyRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin.UpdateCompanyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.admin.UpdateCompanyRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 partner_id = 4;
 * @return {number}
 */
proto.admin.UpdateCompanyRequest.prototype.getPartnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AvailableProducts available_products = 5;
 * @return {?proto.admin.AvailableProducts}
 */
proto.admin.UpdateCompanyRequest.prototype.getAvailableProducts = function() {
  return /** @type{?proto.admin.AvailableProducts} */ (
    jspb.Message.getWrapperField(this, proto.admin.AvailableProducts, 5));
};


/**
 * @param {?proto.admin.AvailableProducts|undefined} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
*/
proto.admin.UpdateCompanyRequest.prototype.setAvailableProducts = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.clearAvailableProducts = function() {
  return this.setAvailableProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.UpdateCompanyRequest.prototype.hasAvailableProducts = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WearableSettings wearable_settings = 6;
 * @return {?proto.admin.WearableSettings}
 */
proto.admin.UpdateCompanyRequest.prototype.getWearableSettings = function() {
  return /** @type{?proto.admin.WearableSettings} */ (
    jspb.Message.getWrapperField(this, proto.admin.WearableSettings, 6));
};


/**
 * @param {?proto.admin.WearableSettings|undefined} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
*/
proto.admin.UpdateCompanyRequest.prototype.setWearableSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.clearWearableSettings = function() {
  return this.setWearableSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.UpdateCompanyRequest.prototype.hasWearableSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SoterTaskSettings soter_task_settings = 8;
 * @return {?proto.admin.SoterTaskSettings}
 */
proto.admin.UpdateCompanyRequest.prototype.getSoterTaskSettings = function() {
  return /** @type{?proto.admin.SoterTaskSettings} */ (
    jspb.Message.getWrapperField(this, proto.admin.SoterTaskSettings, 8));
};


/**
 * @param {?proto.admin.SoterTaskSettings|undefined} value
 * @return {!proto.admin.UpdateCompanyRequest} returns this
*/
proto.admin.UpdateCompanyRequest.prototype.setSoterTaskSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.UpdateCompanyRequest} returns this
 */
proto.admin.UpdateCompanyRequest.prototype.clearSoterTaskSettings = function() {
  return this.setSoterTaskSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.UpdateCompanyRequest.prototype.hasSoterTaskSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DeleteCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DeleteCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DeleteCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DeleteCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DeleteCompanyRequest}
 */
proto.admin.DeleteCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DeleteCompanyRequest;
  return proto.admin.DeleteCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DeleteCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DeleteCompanyRequest}
 */
proto.admin.DeleteCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DeleteCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DeleteCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DeleteCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DeleteCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.DeleteCompanyRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DeleteCompanyRequest} returns this
 */
proto.admin.DeleteCompanyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.AvailableProducts.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.AvailableProducts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.AvailableProducts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AvailableProducts.toObject = function(includeInstance, msg) {
  var f, obj = {
    wearable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dataSandpit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    jobDictionary: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    soterTask: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    ehsCopilot: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    genius: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.AvailableProducts}
 */
proto.admin.AvailableProducts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.AvailableProducts;
  return proto.admin.AvailableProducts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.AvailableProducts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.AvailableProducts}
 */
proto.admin.AvailableProducts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWearable(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDataSandpit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setJobDictionary(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSoterTask(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEhsCopilot(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGenius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.AvailableProducts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.AvailableProducts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.AvailableProducts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.AvailableProducts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWearable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDataSandpit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getJobDictionary();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSoterTask();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEhsCopilot();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getGenius();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool wearable = 1;
 * @return {boolean}
 */
proto.admin.AvailableProducts.prototype.getWearable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AvailableProducts} returns this
 */
proto.admin.AvailableProducts.prototype.setWearable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool data_sandpit = 2;
 * @return {boolean}
 */
proto.admin.AvailableProducts.prototype.getDataSandpit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AvailableProducts} returns this
 */
proto.admin.AvailableProducts.prototype.setDataSandpit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool job_dictionary = 3;
 * @return {boolean}
 */
proto.admin.AvailableProducts.prototype.getJobDictionary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AvailableProducts} returns this
 */
proto.admin.AvailableProducts.prototype.setJobDictionary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool soter_task = 4;
 * @return {boolean}
 */
proto.admin.AvailableProducts.prototype.getSoterTask = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AvailableProducts} returns this
 */
proto.admin.AvailableProducts.prototype.setSoterTask = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool ehs_copilot = 5;
 * @return {boolean}
 */
proto.admin.AvailableProducts.prototype.getEhsCopilot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AvailableProducts} returns this
 */
proto.admin.AvailableProducts.prototype.setEhsCopilot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool genius = 6;
 * @return {boolean}
 */
proto.admin.AvailableProducts.prototype.getGenius = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.AvailableProducts} returns this
 */
proto.admin.AvailableProducts.prototype.setGenius = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.WearableSettings.repeatedFields_ = [3,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.WearableSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.WearableSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.WearableSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.WearableSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeWearing: (f = msg.getTypeWearing()) && api_dashboard_v2_admin_message_pb.TypesWearingDevice.toObject(includeInstance, f),
    firmwareId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    availableCoachingProgramIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    tierId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    workdayTimeMinutes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    workdayIntervalMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    programTargetDays: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reportPeopleName: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    enableGamification: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    collectEmployeeName: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    showSurvey: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    inputLoginMethod: jspb.Message.getFieldWithDefault(msg, 12, 0),
    employeeLoginType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    nicknameRegexpTemplate: jspb.Message.getFieldWithDefault(msg, 14, ""),
    configureDeviceMethod: jspb.Message.getFieldWithDefault(msg, 15, 0),
    availableLanguagesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    disableNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    downloadDataNotOnlyFromDevicesOnCharge: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    collectJobRoleOnRegister: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    showOnboardingPages: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.WearableSettings}
 */
proto.admin.WearableSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.WearableSettings;
  return proto.admin.WearableSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.WearableSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.WearableSettings}
 */
proto.admin.WearableSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_admin_message_pb.TypesWearingDevice;
      reader.readMessage(value,api_dashboard_v2_admin_message_pb.TypesWearingDevice.deserializeBinaryFromReader);
      msg.setTypeWearing(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirmwareId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAvailableCoachingProgramIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTierId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkdayTimeMinutes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkdayIntervalMinutes(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgramTargetDays(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReportPeopleName(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableGamification(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectEmployeeName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowSurvey(value);
      break;
    case 12:
      var value = /** @type {!proto.admin.ClipgoInputLoginMethod} */ (reader.readEnum());
      msg.setInputLoginMethod(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeLoginType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNicknameRegexpTemplate(value);
      break;
    case 15:
      var value = /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (reader.readEnum());
      msg.setConfigureDeviceMethod(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableLanguages(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableNotifications(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnCharge(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectJobRoleOnRegister(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOnboardingPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.WearableSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.WearableSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.WearableSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.WearableSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeWearing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_dashboard_v2_admin_message_pb.TypesWearingDevice.serializeBinaryToWriter
    );
  }
  f = message.getFirmwareId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAvailableCoachingProgramIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getTierId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWorkdayTimeMinutes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWorkdayIntervalMinutes();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getProgramTargetDays();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getReportPeopleName();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEnableGamification();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCollectEmployeeName();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getShowSurvey();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getInputLoginMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getEmployeeLoginType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNicknameRegexpTemplate();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getConfigureDeviceMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getAvailableLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getDisableNotifications();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnCharge();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getCollectJobRoleOnRegister();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getShowOnboardingPages();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional TypesWearingDevice type_wearing = 1;
 * @return {?proto.admin.TypesWearingDevice}
 */
proto.admin.WearableSettings.prototype.getTypeWearing = function() {
  return /** @type{?proto.admin.TypesWearingDevice} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_admin_message_pb.TypesWearingDevice, 1));
};


/**
 * @param {?proto.admin.TypesWearingDevice|undefined} value
 * @return {!proto.admin.WearableSettings} returns this
*/
proto.admin.WearableSettings.prototype.setTypeWearing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.clearTypeWearing = function() {
  return this.setTypeWearing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.hasTypeWearing = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 firmware_id = 2;
 * @return {number}
 */
proto.admin.WearableSettings.prototype.getFirmwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setFirmwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int32 available_coaching_program_ids = 3;
 * @return {!Array<number>}
 */
proto.admin.WearableSettings.prototype.getAvailableCoachingProgramIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setAvailableCoachingProgramIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.addAvailableCoachingProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.clearAvailableCoachingProgramIdsList = function() {
  return this.setAvailableCoachingProgramIdsList([]);
};


/**
 * optional int32 tier_id = 4;
 * @return {number}
 */
proto.admin.WearableSettings.prototype.getTierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setTierId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 workday_time_minutes = 5;
 * @return {number}
 */
proto.admin.WearableSettings.prototype.getWorkdayTimeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setWorkdayTimeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 workday_interval_minutes = 6;
 * @return {number}
 */
proto.admin.WearableSettings.prototype.getWorkdayIntervalMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setWorkdayIntervalMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 program_target_days = 7;
 * @return {number}
 */
proto.admin.WearableSettings.prototype.getProgramTargetDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setProgramTargetDays = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool report_people_name = 8;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getReportPeopleName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setReportPeopleName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool enable_gamification = 9;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getEnableGamification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setEnableGamification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool collect_employee_name = 10;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getCollectEmployeeName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setCollectEmployeeName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool show_survey = 11;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getShowSurvey = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setShowSurvey = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional ClipgoInputLoginMethod input_login_method = 12;
 * @return {!proto.admin.ClipgoInputLoginMethod}
 */
proto.admin.WearableSettings.prototype.getInputLoginMethod = function() {
  return /** @type {!proto.admin.ClipgoInputLoginMethod} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.admin.ClipgoInputLoginMethod} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setInputLoginMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string employee_login_type = 13;
 * @return {string}
 */
proto.admin.WearableSettings.prototype.getEmployeeLoginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setEmployeeLoginType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string nickname_regexp_template = 14;
 * @return {string}
 */
proto.admin.WearableSettings.prototype.getNicknameRegexpTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setNicknameRegexpTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional ClipgoConfigureDeviceMethod configure_device_method = 15;
 * @return {!proto.admin.ClipgoConfigureDeviceMethod}
 */
proto.admin.WearableSettings.prototype.getConfigureDeviceMethod = function() {
  return /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.admin.ClipgoConfigureDeviceMethod} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setConfigureDeviceMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated string available_languages = 17;
 * @return {!Array<string>}
 */
proto.admin.WearableSettings.prototype.getAvailableLanguagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setAvailableLanguagesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.addAvailableLanguages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.clearAvailableLanguagesList = function() {
  return this.setAvailableLanguagesList([]);
};


/**
 * optional bool disable_notifications = 18;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getDisableNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setDisableNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge = 19;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getDownloadDataNotOnlyFromDevicesOnCharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setDownloadDataNotOnlyFromDevicesOnCharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool collect_job_role_on_register = 20;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getCollectJobRoleOnRegister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setCollectJobRoleOnRegister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool show_onboarding_pages = 21;
 * @return {boolean}
 */
proto.admin.WearableSettings.prototype.getShowOnboardingPages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.WearableSettings} returns this
 */
proto.admin.WearableSettings.prototype.setShowOnboardingPages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.SoterTaskSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.SoterTaskSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.SoterTaskSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.SoterTaskSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    blurFaceEnable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.SoterTaskSettings}
 */
proto.admin.SoterTaskSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.SoterTaskSettings;
  return proto.admin.SoterTaskSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.SoterTaskSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.SoterTaskSettings}
 */
proto.admin.SoterTaskSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlurFaceEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.SoterTaskSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.SoterTaskSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.SoterTaskSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.SoterTaskSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlurFaceEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool blur_face_enable = 1;
 * @return {boolean}
 */
proto.admin.SoterTaskSettings.prototype.getBlurFaceEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.SoterTaskSettings} returns this
 */
proto.admin.SoterTaskSettings.prototype.setBlurFaceEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.admin);
