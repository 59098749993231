export function customError(err, type, actionType) {
  const result = {};
  const { response } = err;
  if (response) {
    const { code } = err || {};
    switch (actionType) {
      case 'AUTH_ERROR': {
        // eslint-disable-next-line default-case
        switch (code) {
          case 100: {
            result[type] = 'Unable to log in with provided credentials.';
            return result;
          }
          case 201: {
            result[type] = 'User does not exists.';
            return result;
          }
          case 901: {
            result[type] = 'User already has password';
            return result;
          }
        }
        break;
      }
      default:
        result[type] = 'Server error';
        return result;
    }
  }
  result[type] = 'Server error';
  return result;
}
