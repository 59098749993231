import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import * as Emotion from 'emotion';
import styled from '@emotion/styled';
import { Dropdown } from 'rsuite';
import ReactSlider from 'react-slider';
import { COLORS } from 'constants/colors';
import { ReactComponent as ArrowRight } from '../../../assets/img/icons/chevron-right-white.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/img/icons/chevron-left-white.svg';
import { ReactComponent as Play } from '../../../assets/img/icons/play.svg';
import { ReactComponent as Pause } from '../../../assets/img/icons/pause.svg';
import { ReactComponent as Volume } from '../../../assets/img/icons/volume.svg';
import { ReactComponent as VolumeOff } from '../../../assets/img/icons/volume-off.svg';
import { CONTROLS_HEIGHT, videoPlaybackRateList } from '../constants';
import useOutsideClickHandler from '../../../lib/utils/useOutsideClickHandler';
import presentSeconds from '../../../lib/utils/presentSeconds';

const controls = (videoWidth, videoHeight) => Emotion.css`
  position: absolute;
  z-index: 6;
  display: flex;
  left: 0px;
  top: ${videoHeight - CONTROLS_HEIGHT}px;
  align-items: center;
  width: ${videoWidth}px;
  height: ${CONTROLS_HEIGHT}px;
  padding: 0 7px;
  background-color: rgba(0, 0, 0, 0.4);
`;

const controlWrapper = Emotion.css`
  margin: 0 14px;
  color: ${COLORS.WHITE};
  &:hover {
    cursor: pointer;
  }
`;

const dropdown = (width) => Emotion.css`
  // margin-left: ${width - 80}px;
  position: absolute;
  left: ${width - 55}px;
`;

const dropdownToggle = Emotion.css`
  color: ${COLORS.WHITE};
  font-size: 15px;
  background-color: transparent;
`;

const progressBar = (videoWidth) => Emotion.css`
  position: absolute;
  left: -7px;
  top: -24px;
  background-color: ${COLORS.GRAY[400]};
  width: ${videoWidth}px;
  height: 4px;
  cursor: pointer;
`;

const progressBarThumb = Emotion.css`
  background-color: ${COLORS.WHITE};
  width: 10px;
  height: 10px;
  border-radius: 10px;
  top: -3px;
  cursor: grab;
  &:focus {
    outline-width: 0;
  }
`;

const volumeBar = Emotion.css`
  background-color: ${COLORS.GRAY[400]};
  width: 90px;
  height: 4px;
  cursor: pointer;
  border-radius: 10px;
`;

const volumeBarThumb = Emotion.css`
  background-color: ${COLORS.WHITE};
  width: 4px;
  height: 4px;
  border-radius: 10px;

  cursor: grab;
  &:focus {
    outline-width: 0;
  }
`;

const StyledTrack = styled.div`
  height: 4px;
  top: 0;
  bottom: 0;
  background: ${(props) => (props.index === 1 ? COLORS.GRAY[400] : COLORS.WHITE)};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const Controls = ({
  video,
  currentTimeMs,
  totalFrames,
  isPlaying,
  setIsPlaying,
  isDataParsed,
  videoHeight,
  videoWidth,
}) => {
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState(1);
  const [isVolumeOpen, setIsVolumeOpen] = useState(false);
  const [currentVolume, setCurrentVolume] = useState(100);
  const [isVolumeOff, setIsVolumeOff] = useState(false);
  const ref = useRef();
  useOutsideClickHandler(ref, () => setIsVolumeOpen(false));
  useEffect(() => {
    if (!video) return;
    video.volume = currentVolume / 100;
    if (currentVolume === 0) setIsVolumeOff(true);
    if (currentVolume > 0) setIsVolumeOff(false);
  }, [currentVolume]);
  useEffect(() => {
    if (!video) return;
    if (isVolumeOff) {
      video.volume = 0;
    } else {
      video.volume = currentVolume / 100;
    }
  }, [isVolumeOff]);
  const fadeStyles = useSpring({
    config: {
      duration: 300,
      tension: 20,
      fiction: 7,
      mass: 5,
    },
    from: {
      opacity: 0,
    },
    to: {
      opacity: isDataParsed ? 1 : 0,
    },
  });
  useEffect(() => {
    if (video) video.playbackRate = currentPlaybackRate;
  }, [currentPlaybackRate]);
  const changePlay = (play) => {
    setIsPlaying(play);
    isPlaying ? video.pause() : video.play();
  };
  return (
    <animated.div
      className={controls(videoWidth, videoHeight)}
      style={fadeStyles}
    >
      <div style={{ position: 'relative', width: '0px' }}>
        <ReactSlider
          className={progressBar(videoWidth)}
          thumbClassName={progressBarThumb}
          max={(totalFrames / 30) * 1000}
          value={currentTimeMs}
          renderTrack={Track}
          onChange={(v) => (video.currentTime = v / 1000)}
        />
      </div>
      <span
        className={controlWrapper}
        onClick={() => (video.currentTime -= 0.1)}
      >
        <ArrowLeft />
      </span>
      <span
        style={{ width: '19px' }}
        className={controlWrapper}
        onClick={() => changePlay(!isPlaying)}
      >
        {isPlaying ? (
          <Pause style={{ marginTop: '2px' }} />
        ) : (
          <Play style={{ marginTop: '2px' }} />
        )}
      </span>
      <span
        className={controlWrapper}
        onClick={() => {
          video.currentTime += 0.1;
        }}
      >
        <ArrowRight />
      </span>
      <span
        ref={ref}
        className={controlWrapper}
        style={{ display: 'flex', alignItems: 'center' }}
        onMouseEnter={() => !isVolumeOff && setIsVolumeOpen(true)}
      >
        <span
          style={{ marginRight: '9px', display: 'flex', alignItems: 'center' }}
          onClick={() => {
            setIsVolumeOff(!isVolumeOff);
            setIsVolumeOpen(isVolumeOff);
          }}
        >
          {isVolumeOff ? <VolumeOff /> : <Volume />}
        </span>
        {isVolumeOpen && (
          <ReactSlider
            value={currentVolume}
            max={100}
            onChange={(v) => setCurrentVolume(v)}
            renderTrack={Track}
            className={volumeBar}
            thumbClassName={volumeBarThumb}
          />
        )}
      </span>
      <span
        style={{
          fontFamily: 'monospaced',
          color: COLORS.WHITE,
          width: '90px',
        }}
      >
        {`${presentSeconds(currentTimeMs / 1000)} / ${presentSeconds(
          totalFrames / 30,
        )}`}
      </span>
      {/* <span onClick={() => { */}
      {/*  canvas.requestFullscreen(); */}
      {/* }}>F</span> */}
      <Dropdown
        placement="topStart"
        className={dropdown(videoWidth)}
        toggleClassName={dropdownToggle}
        title={`${currentPlaybackRate.toFixed(1)}x`}
        noCaret
      >
        {videoPlaybackRateList.map((rate) => (
          <Dropdown.Item
            key={rate}
            onSelect={() => setCurrentPlaybackRate(rate)}
            active={rate === currentPlaybackRate}
          >
            {`${rate.toFixed(1)}x`}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </animated.div>
  );
};

export default Controls;
