import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chartContainerWithHeight } from 'assets/soter-flex-styles/chart-container';
import useWindowSize from '../../utils/useWindowSize';
import { ChartFactoryContext } from '../../utils/d3/chartFactoryContext';
import gradients from '../../constants/gradients';
import { escapeHTML } from '../../../../lib/ui/EditableTextCard/escapeHTML';

const SplitChart = ({
  className,
  hasDataForChart,
  config,
  chartId,
  data,
  containerHeight,
  setTooltip,
  withTextOnChart,
  tickTextSize,
  tickTextWeight,
  withLegend,
  totalMax,
}) => {
  useWindowSize();
  const { t } = useTranslation();
  const chartFactory = useContext(ChartFactoryContext);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const chartContainerRef = useRef(null);

  const setChartDimensions = (updatedWidth, updatedHeight) => {
    if (updatedWidth !== chartWidth) setChartWidth(updatedWidth);
    if (updatedHeight !== chartHeight) setChartHeight(updatedHeight);
  };

  useEffect(() => {
    const drawer = chartFactory.getChartDrawer('stacked', chartId);
    if (chartContainerRef.current && drawer) {
      setChartDimensions(
        chartContainerRef.current.offsetWidth,
        chartContainerRef.current.offsetHeight
      );

      // Clear the previous chart before drawing a new one
      drawer.clearChart();

      if (chartWidth && chartHeight) {
        // Set chart dimensions
        drawer.setWidth(chartWidth);
        drawer.setHeight(chartHeight);

        // Set translations and configurations
        drawer.setTranslation(t);
        drawer.setConfig(config);
        drawer.setFontSize(tickTextSize);
        drawer.setFontWeight(tickTextWeight);

        if (hasDataForChart && data && data.length > 0) {
          // Set the data for the chart, ensuring it is properly formatted
          const sanitizedData = data.map(d => ({
            ...d,
            title: escapeHTML(d.title)
          }));
          drawer.setData(sanitizedData);
          drawer.setMax(totalMax);

          // Draw the chart with data
          drawer.draw(gradients, setTooltip, withTextOnChart, withLegend);
        } else {
          // Draw an empty chart if no data is available
          drawer.drawEmpty(withTextOnChart);
        }
      }
    }
  }, [chartFactory, chartId, chartWidth, chartHeight, data, hasDataForChart, config, totalMax, t, setTooltip, withTextOnChart, tickTextSize, tickTextWeight, withLegend]);

  return (
    <div
      className={`${chartContainerWithHeight(containerHeight)} ${className || ''}`}
      ref={chartContainerRef}
      id={chartId}
    />
  );
};

export default SplitChart;
