import * as Emotion from 'emotion';

export const timelineChart = Emotion.css`{
  .task {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .timeAxis {
    path {
      opacity: 0.6;
    }
  
    .tick {
      opacity: 0.5;
  
      &:first-of-type,
      &:last-of-type {
        display: none;
      }
    }
  }
}`;

export const emptyData = Emotion.css`{
  position: absolute;
  top: -17px;
  width: 100%;
  text-align: center;
  color: #9aa0ac;
  text-transform: uppercase;
}`;
