import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
// eslint-disable-next-line import/named
import { useTranslation } from 'react-i18next';
import { chartContainerWithHeight } from 'assets/soter-flex-styles/chart-container';
import gradients from '../../constants/gradients';
import useWindowSize from '../../utils/useWindowSize';
import { ChartFactoryContext } from '../../utils/d3/chartFactoryContext';

const DonutChart = ({
  hasDataForChart,
  chartId,
  getData,
  sessionHazards,
  overallText,
  deviceType,
  containerHeight,
  overallNumber,
  withLegend,
  legendPosition,
  legendItems,
}) => {
  const { t } = useTranslation();
  useWindowSize();
  const chartFactory = useContext(ChartFactoryContext);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const chartContainerRef = useRef(null);

  const setChartDimensions = (updatedWidth, updatedHeight) => {
    if (updatedWidth !== chartWidth) setChartWidth(updatedWidth);
    if (updatedHeight !== chartHeight) setChartHeight(updatedHeight);
  };

  const configWithData = (drawer, data, overallData) => {
    drawer.setWidth(chartWidth);
    drawer.setHeight(chartHeight);
    drawer.setTranslation(t);
    drawer.setWithLegend(withLegend);
    drawer.setLegendPosition(legendPosition);
    drawer.setData(data);
    drawer.setOverallNumber(overallData);
    drawer.setOverallText(overallNumber ? overallText : 'No data');
  };

  const configEmpty = (drawer) => {
    drawer.setWidth(chartWidth);
    drawer.setHeight(chartHeight);
    drawer.setTranslation(t);
    drawer.setWithLegend(withLegend);
    drawer.setLegendPosition(legendPosition);
  };

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartDimensions(
        chartContainerRef.current.offsetWidth - 35,
        chartContainerRef.current.offsetHeight - 35,
      );
      const drawer = chartFactory.getChartDrawer('donut', chartId);
      drawer.clearChart();
      if (chartWidth && chartHeight && hasDataForChart) {
        const averageHazardOverall = sessionHazards.overallHazard?.toFixed(1);
        const overallData = overallNumber || averageHazardOverall;
        const data = getData();
        configWithData(drawer, data, overallData);
        drawer.draw(gradients);
      } else if (chartWidth && chartHeight) {
        configEmpty(drawer);
        drawer.drawEmpty(gradients, deviceType, legendItems);
      }
    }
  }, [sessionHazards]);

  return (
    <div
      className={chartContainerWithHeight(containerHeight)}
      ref={chartContainerRef}
      id={chartId}
    />
  );
};

export default DonutChart;
