import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { flexColumn, flexRow, pl3 } from '../../assets/soter-flex-styles';
import { dot, DOTClassificationsToColors } from '../constants';
import {
  ButtonContainer, DOTButton, editingHeader, editingHeaderSlot,
} from './EditableJobProfileHeader';
import CustomInput from '../../lib/ui/CustomInput';

const editingTaskHeader = Emotion.css`
  ${editingHeader};
  grid-template-rows: 1fr 1fr;
  height: 196px;
`;

const EditingTaskProfileHeader = ({
  currentTask,
  editMode,
  setCurrentTask,
}) => {
  const { title, frequency, dotClassification } = currentTask;
  const { t } = useTranslation();
  const [selectedDOT, setSelectedDOT] = useState(dotClassification);
  const changeFieldInState = (n, v) => setCurrentTask({ ...currentTask, [n]: v });
  useEffect(() => {
    setSelectedDOT(dotClassification);
  }, [dotClassification]);
  return editMode ? (
    <div className={editingTaskHeader}>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.TASK_PROFILE.TITLE')}</span>
        <CustomInput name="title" value={title} onChange={changeFieldInState} />
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.TASK_PROFILE.DOT_CLASSIFICATION')}</span>
        <div className={ButtonContainer}>
          {dot.map((v) => (
            <div
              className={DOTButton(
                DOTClassificationsToColors[v],
                selectedDOT === dot.indexOf(v),
              )}
              onClick={() => {
                setCurrentTask({
                  ...currentTask,
                  dotClassification: dot.indexOf(v),
                });
              }}
              key={v}
            >
              {t(v)}
            </div>
          ))}
        </div>
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.TASK_PROFILE.FREQUENCY')}</span>
        <CustomInput
          name="frequency"
          value={frequency}
          onChange={changeFieldInState}
        />
      </div>
    </div>
  ) : (
    <div
      className={flexRow}
      style={{ display: 'flex', alignItems: 'baseline', marginBottom: '12px' }}
    >
      <div className={`${flexColumn} ${pl3}`}>
        <div
          style={{
            fontSize: '32px',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>{title}</span>
          {/* <div */}
          {/*  className={DOTSmallButton( */}
          {/*    DOTClassificationsToColors[dot[selectedDOT]], */}
          {/*    true */}
          {/*  )} */}
          {/* > */}
          {/*  <div>{t(dot[selectedDOT])}</div> */}
          {/* </div> */}
        </div>
        {/* <div> */}
        {/*  <span style={{ color: GRAY[400] }}>{`${t( */}
        {/*    'JOB_DICTIONARY.TASK_PROFILE.FREQUENCY' */}
        {/*  )}: `}</span> */}
        {/*  <span>{frequency}</span> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default EditingTaskProfileHeader;
