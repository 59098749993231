import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'rsuite';
import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import CustomTooltip from 'lib/ui/Tooltip';
import Text from 'lib/ui/Text';
import _flatten from 'lodash/flatten';
import { tableIndent } from '../../assets/jss/style';
import StickyOnScrollContainer from './components/StickyOnScrollContainer';
import { TABLE_HEIGHT_CHANGE, TABLE_SORTING_CHANGE } from '../../constants/analyticsEvents';
import {
  container, flexColumn, flexRow, mb4, mt2,
} from '../../assets/soter-flex-styles';
import getFilteringParameters from './utils/getFilteringParameters';
import { printReport } from './utils/helpers';
import { setFilterFromParameters } from './utils/setFilterFromParameters';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { TableType } from './constants/tableType';
import { DEPARTMENT_NOT_ASSIGNED } from './constants/tableValuesAndFilterOptions';
import sliceLongString from '../../lib/utils/sliceLongString';
import { videoStatus } from '../../task/gallery/constants/videoStatus';
import { initFilters } from './filtersStore/filtersSlice';
import {
  setCurrentPage, setDisplayLength, setSortingColumn, setSortingType,
} from './tableStateStore/actions';
import { createEventNotification } from '../NotificationContainer/notificationsStore/actions';
import { filterItemsAccordingToFilterState } from './utils/filterForTable';
import sortUsersForTable from './utils/sortItemsForTable';
import useTableReport from './utils/useTableReport';
import { getCurrentPageReportList } from './constants/tablePageReportList';
import { setReportBuilder, setReportId } from './reportsStore/reportSlice';
import paginator from './utils/paginator';
import FiltersWithButton from './components/FiltersWithButton';
import Filters from './components/Filters';
import { useSearchParams } from './utils/useSearchParams';
import ItemsTable from './components/CustomizableTable';
import Cell from './components/CustomCells/Cell';
import ModalWrapper from '../ModalContainer/ModalWrapper';
import { program } from '../../assets/jss/containers/pdfLayersStyle';
import { COLORS } from 'constants/colors'

const { Column, HeaderCell } = Table;

const ItemTableContainer = ({
  actions,
  cells,
  columns,
  isLoading,
  items,
  modalClass,
  openedModal,
  secondaryButton = null,
  selectedItems = [],
  setOpenedModal,
  switchModal,
  type,
  filterButtonProps = {},
  keepModalOnClickOutside,
  overlayClass,
  tableClass,
  stickyOnScrollContainerTopBlock,
  stickyOnScrollContainerHeight,
  onCloseModal,
  withCloseButton = true,
  isCloseModal = true,
}) => {
  const { dateRangePickerValue } = useSelector((state) => state.coachUsers);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { reportId, reportBuilder } = useAppSelector((state) => state.reports);
  const { selectedCompany, companiesList: companies } = useAppSelector(
    (state) => state.companies,
  );
  const accessibleDepartments = selectedCompany.departmentsList || [];
  const departments
    = accessibleDepartments.map((dep) => ({ value: dep.id, label: dep.name }))
    || [];
  if (departments.length > 0) departments.push({ value: type === TableType.coach || type === TableType.devices ? -1 : 0, label: t(DEPARTMENT_NOT_ASSIGNED) });
  const sites
    = selectedCompany?.sitesList?.map((dep) => ({
      value: dep.id,
      label: dep.name,
    })) || [];
  const categories = useAppSelector((state) => state.category.categories) || [];
  const companySegmentList
    = categories
      .filter(
        (category) => category.id > 0 &&
          category?.tagsList.length > 0 &&
          !['users_job_role'].includes(category.title),
      )
      .map((item) => ({
        name: item.title,
        values: item?.tagsList.map((tag) => ({
          value: tag.id,
          label: tag.title,
        })),
      })) || [];
  if (sites.length > 0) sites.push({ value: type === TableType.coach ? -1 : 0, label: t(DEPARTMENT_NOT_ASSIGNED) });
  const jobRoles = useMemo(() => {
    const roles = _uniq(
      _compact(
        items?.map((item) => {
          const { jobRole } = type === TableType.help ? item.user : item;
          if (jobRole) return jobRole.title;
        }),
      ),
    );
    if (!roles) return [];
    const r = roles?.map((value) => ({ value, label: value }));
    r.unshift({ value: null, label: t('GENERAL.NOT_ASSIGNED') });
    return r;
  }, [items]);
  const showPassedPrograms = useSelector((state) => state?.filtersNew?.filters?.coach?.showPassedPrograms);
  const programs = useMemo(() => {
    if (type === TableType.companies && items?.length) {
      const progs = {};
      items.forEach(({ availableCoachingProgramsList }) => {
        if (!availableCoachingProgramsList) return;
        availableCoachingProgramsList.forEach(({ id, name }) => {
          progs[id] = { value: id, label: name };
        });
      });
      return Object.values(progs);
    }
    const progs = [];
    progs.push({ value: 0, label: t('WEARABLES.ACTIVITY.HAS_NO_PROGRAM') });
    const activePrograms = _uniqBy(items?.map((user) => ({ value: user.program?.id, label: user.program?.name })), 'value').filter((p) => p.value);
    if (showPassedPrograms) {
      const passedPrograms = _uniqBy(_flatten(items?.map((user) => user.passedProgramsList?.map((p) => (
        { value: p.id, label: p.name })))), 'value');
      progs.push(..._uniqBy([...activePrograms, ...passedPrograms], 'value'));
    } else {
      progs.push(...activePrograms);
    }
    return progs;
  }, [items, showPassedPrograms]);
  const getSelectOptionsForSimpleFilter = (
    currentUserType,
    { filterLabel, optionWithNoValue },
  ) => useMemo(() => {
    if (type === currentUserType && items?.length) {
      const optionsData = items?.map((item) => {
        const value = _get(item, filterLabel);
        return { value, label: value };
      });
      const options = _uniqBy(optionsData, 'value').filter(
        (item) => item.value !== undefined,
      );
      if (optionWithNoValue) {
        options.unshift({ value: null, label: optionWithNoValue });
      }
      return options;
    }
    return [];
  }, [items]);

  const regions = getSelectOptionsForSimpleFilter(TableType.companies, {
    filterLabel: 'region',
  });
  const partners = getSelectOptionsForSimpleFilter(TableType.companies, {
    filterLabel: 'partner.name',
    optionWithNoValue: t('WEARABLES.ACTIVITY.NO_PARTNER'),
  });
  const problems = getSelectOptionsForSimpleFilter(TableType.help, {
    filterLabel: 'comment',
  });
  const videosCreatedBy = getSelectOptionsForSimpleFilter(
    TableType.soterTaskVideos,
    { filterLabel: 'createdBy.name' },
  );
  const videosJobRole = getSelectOptionsForSimpleFilter(
    TableType.soterTaskVideos,
    {
      filterLabel: 'jobRole.title',
      optionWithNoValue: t('GENERAL.NOT_ASSIGNED'),
    },
  );
  const videosTaskName = getSelectOptionsForSimpleFilter(
    TableType.soterTaskVideos,
    {
      filterLabel: 'taskName.title',
      optionWithNoValue: t('GENERAL.NOT_ASSIGNED'),
    },
  );
  const comparisonsCreatedBy = getSelectOptionsForSimpleFilter(
    TableType.soterTaskComparison,
    { filterLabel: 'createdBy' },
  );

  const companiesForFilter = companies?.map(({ id, name }) => ({
    value: id,
    label: sliceLongString(name, 25),
  }));
  const userCreators = TableType.dashboardUsers
    ? _uniq(items.map((item) => item.createdBy)).map((id) => {
      const creator = items.find((item) => item.id === id);
      return {
        label: creator ? `${creator.firstName} ${creator.lastName}` : '-',
        value: id,
        firstName: creator?.firstName,
        lastName: creator?.lastName,
      };
    })
    : null;
  const nonEmptyCreators = userCreators?.filter((el) => el.label !== '-');

  const getVideoStatuses = () => useMemo(() => {
    if (type === TableType.soterTaskVideos && items?.length) {
      const statusCodes = _uniq(items?.map((item) => item.status));
      const result = statusCodes?.map((code) => ({
        value: code,
        label: t(videoStatus[code]) || '-',
      }));
      return result;
    }
    return [];
  }, [items]);

  const videoStatuses = getVideoStatuses();

  const filteringParameters = getFilteringParameters(
    departments,
    type,
    programs,
    null,
    regions,
    partners,
    companiesForFilter,
    nonEmptyCreators,
    videoStatuses,
    videosCreatedBy,
    videosTaskName,
    videosJobRole,
    comparisonsCreatedBy,
    jobRoles,
    sites,
    problems,
    t,
    companySegmentList,
  );
  const filtersNew = useAppSelector((state) => state.filtersNew);
  const filtersState = filtersNew?.filters[type];
  const { searchString } = filtersNew;
  useEffect(() => {
    const isChangedInitFilters
      = filtersNew?.filters?.coach &&
      Object.keys(filtersNew.filters.coach).length
        !== filteringParameters.length;

    if (!filtersState || isChangedInitFilters) {
      dispatch(
        initFilters({
          types: filteringParameters.map(({ name }) => name),
          pageType: type,
        }),
      );
    }

    // return () => dispatch(setSearchString({ searchString: '' }));
  }, [type, filteringParameters.length]);

  const { currentPage, displayLength } = useAppSelector(
    (state) => state?.tableState[type]?.pagination,
  );
  const setTableCurrentPage = (page) => dispatch(setCurrentPage(page, type));
  const setTableDisplayLength = (length) => {
    const params = {
      height_length: length,
      table_type: type,
    };
    window.logEvent(TABLE_HEIGHT_CHANGE, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${TABLE_HEIGHT_CHANGE}: ${JSON.stringify(params)}`));
    }
    dispatch(setDisplayLength(length, type));
  };

  const { sortColumn, sortType } = useAppSelector(
    (state) => state?.tableState[type]?.sorting,
  );

  const handleChangeSorting = (itemType) => (column, typeOfSort) => {
    const params = {
      table_type: itemType,
      column_name: column,
    };
    window.logEvent(TABLE_SORTING_CHANGE, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${TABLE_SORTING_CHANGE}: ${JSON.stringify(params)}`));
    }
    dispatch(setSortingColumn(column, itemType));
    dispatch(setSortingType(typeOfSort, itemType));
  };

  const filteredItemsForTable = useMemo(() => {
    const i = [...items];
    if (items[0]?.passedProgramsList && filtersState?.showPassedPrograms) {
      i.forEach((item) => {
        if (item?.passedProgramsList.length > 0) {
          item?.passedProgramsList?.forEach((program) => {
            i.push({
              ...item,
              program,
              improvements: program?.improvements,
              passedProgramsList: [],
              device: null,
              lastSync: null,
              isPassedProgramCopy: true,
            });
          });
        }
      });
    }
    return sortUsersForTable(
      filterItemsAccordingToFilterState(
        i,
        { ...filtersState, searchString },
        type,
      ),
      {
        sortColumn,
        sortType,
      },
      type,
    );
  }, [items, filtersState, searchString, sortColumn, sortType]);
  useTableReport(
    reportId,
    selectedItems.length > 0 ? selectedItems : filteredItemsForTable,
    type,
  );
  useEffect(() => {
    if (reportBuilder) {
      const reportEntity = getCurrentPageReportList(type).find(({ id }) => id === reportId);
      printReport(reportEntity, reportBuilder, t);
      dispatch(setReportId(null));
      dispatch(setReportBuilder(null));
    }
  }, [reportBuilder]);

  const filteredItemsForCurrentPage = useMemo(
    () => paginator(filteredItemsForTable, displayLength, currentPage),
    [currentPage, displayLength, filteredItemsForTable],
  );

  const withTooltip = (columnData) => (columnData.tooltip ? (
    <CustomTooltip
      placement="topEnd"
      text={
          columnData.title.length > 12
            ? `${columnData.title} (${columnData.tooltip})` || ''
            : columnData.tooltip || ''
        }
      noTranslation
    >
      <span>{sliceLongString(columnData.title.toUpperCase(), 12)}</span>
    </CustomTooltip>
  ) : (
    sliceLongString(columnData.title.toUpperCase(), 20)
  ));

  const isTypeWithFilterButton
    = (type === TableType.companies
      || type === TableType.dashboardUsers
      || type === TableType.soterTaskVideos
      || type === TableType.coach
      || type === TableType.soterTaskComparison
      || type === TableType.myCompanyUsers
      || type === TableType.usersUpload
      || type === TableType.hubs
      || type === TableType.categories
      || type === TableType.entries
      || type === TableType.devices) &&
    filterButtonProps !== -1;

  const FiltersComponent = isTypeWithFilterButton ? FiltersWithButton : Filters;

  const handleCloseModal = () => (onCloseModal ? onCloseModal() : setOpenedModal(null));

  // eslint-disable-next-line no-unused-expressions
  secondaryButton &&
    secondaryButton.length > 0 &&
    secondaryButton.forEach((button) => {
      if (button?.dropdown?.type === 'exportReport') {
        button.dropdown.userActions = button.dropdown.exportActions;
      }
    });
  const params = useSearchParams(type);
  useEffect(() => {
    setFilterFromParameters(params, type, dispatch, selectedCompany, dateRangePickerValue);
  },
  [params]);
  return (
    <div style={{ display: 'block' }}>
      {!isLoading && (
      <StickyOnScrollContainer
        topMargin={stickyOnScrollContainerTopBlock ? 0 : 7}
        topBlock={stickyOnScrollContainerTopBlock}
        height={stickyOnScrollContainerHeight}
      >
        <div className={flexColumn} style={{ paddingRight: 0 }}>
          <div className={flexRow}>
            <FiltersComponent
              filteringParameters={filteringParameters}
              type={type}
              modalClass={modalClass}
              secondaryButton={secondaryButton}
              onActionBtnClick={filterButtonProps.action}
              actionButtonText={filterButtonProps.text}
              disabled={filterButtonProps.disabled}
            />
          </div>
        </div>
      </StickyOnScrollContainer>
      )}
      <div className={`${container} ${tableIndent}`} id="overflowContainer">
        {(type === 'soterTaskComparison' || type === 'soterTaskVideos') && <Text
          text={type === 'soterTaskComparison' ? t('SOTER_GENIUS.TASK_COMPARISON') : t('SOTER_GENIUS.TASK_GALLERY')}
          weight='bold'
          appearance='headlineOne'
          style={{ margin: '20px 12px' }}
        />}
        <div>
          <div className={flexColumn} style={{ paddingRight: 0 }}>
            <div className={`${flexRow} ${mt2}`}>
              <div className={`${flexColumn} ${mb4}`}>
                {/* <Profiler id="Table" onRender={callback}> */}
                <ItemsTable
                  tableType={type}
                  tableClass={tableClass}
                  items={filteredItemsForCurrentPage}
                  totalItems={filteredItemsForTable?.length}
                  currentPage={currentPage}
                  setCurrentPage={setTableCurrentPage}
                  displayLength={displayLength}
                  setDisplayLength={setTableDisplayLength}
                  sortColumn={sortColumn}
                  sortType={sortType}
                  handleChangeSorting={handleChangeSorting(type)}
                  allItemsLength={items?.length}
                  isLoadingItems={isLoading}
                >
                  {columns(actions).map((column) => (
                    <Column
                      width={column.width || 140}
                      align={column.align || 'left'}
                      fixed={column.fixed}
                      flexGrow={column.flexGrow}
                      key={column.dataKey}
                      verticalAlign="middle"
                      minWidth={column.minWidth}
                      sortable={column.sortable}
                    >
                      <HeaderCell>
                        <Text
                          text={
                            typeof column.title === 'string'
                              ? withTooltip(column)
                              : column.title({
                                rowData: null,
                                checked: column.checked(
                                  selectedItems,
                                  filteredItemsForCurrentPage,
                                  'all',
                                ),
                                isHeaderCheckbox: true,
                                handler: (
                                  _,
                                  checked,
                                  // eslint-disable-next-line no-shadow
                                  dispatch,
                                ) => column.handler(
                                  filteredItemsForCurrentPage,
                                  checked,
                                  dispatch,
                                ),
                              })
                          }
                          style={{
                            fontSize: '10px',
                            color: COLORS.GRAY[400],
                          }}
                          inline
                        />
                      </HeaderCell>
                      <Cell dataKey={column.dataKey}>
                        {(rowData) => cells[column.type]({
                          rowData,
                          items,
                          type: column.type,
                          dataKey: column.dataKey,
                          handler: column.handler,
                          actions: column.actions,
                          checked:
                              column.checked &&
                              column.checked(
                                selectedItems,
                                filteredItemsForCurrentPage,
                                rowData.id,
                              ),
                          company: selectedCompany,
                          avg: column.avg,
                          isClip: column.isClip,
                          userType: type,
                          isTheOnlyVisibleItem:
                              filteredItemsForTable.length === 1,
                        })}
                      </Cell>
                    </Column>
                  ))}
                </ItemsTable>
              </div>
            </div>
          </div>
        </div>
        {openedModal && (
          <ModalWrapper
            modalClass={modalClass}
            overlayClass={overlayClass}
            keepModalOnClickOutside={keepModalOnClickOutside}
            closeModal={handleCloseModal}
            withCloseButton={withCloseButton && openedModal !== 'deletedUsersList'}
            closeWithoutConfirmation={
              openedModal === 'videoUploadingLimitExceeded'
              || openedModal === 'contactSales'
              || openedModal === 'showAppQrcode'
              || openedModal === 'contactUs'
              || openedModal === 'upgrade'
              || openedModal === 'createUser'
              || openedModal === 'fillingError'
              || openedModal === 'userActionDropdownModal'
              || openedModal === 'qrCode'
              || openedModal === 'subscriptionExpired'
            }
            isCloseModal={isCloseModal}
          >
            {switchModal(openedModal)}
          </ModalWrapper>
        )}
      </div>
    </div>
  );
};

export default ItemTableContainer;
