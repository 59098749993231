import * as Emotion from 'emotion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cardMediumWithHeight } from '../../assets/soter-flex-styles';
import infoIcon from '../../assets/img/icons/info.svg';
import UnitSelectionString from './UnitSelectionString';
import AngleLabel from './AngleLabel';
import bend from '../../assets/img/newBendPersonTripple.png';
import { COLORS } from '../../constants/colors';
import Tooltip from '../../lib/ui/Tooltip';
import PieChart from './PieChart';

export const backButton = Emotion.css`
  position: absolute;
  left: 420px;
  top: 11px;
  color: ${COLORS.LIGHT_BLUE[600]};
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

const SpineChart = ({
  setSpineChartState,
  spineChartState,
  unitList,
  chartTypes,
  getSpineData,
  goBack,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ width: '564px', height: '692px' }}
      className={`${cardMediumWithHeight(690)}`}
    >
      <div
        style={{
          fontSize: '20px',
          color: COLORS.GRAY[950],
          padding: '8px 0px 0px 23px',
        }}
      >
        {t('WEARABLES.SPINE')}
      </div>
      <div style={{ position: 'absolute', left: '520px', top: '11px' }}>
        <Tooltip
          text={t('JOB_DICTIONARY.JOB_PROFILE.ILLUSTRATED')}
          placement="bottomEnd"
          openOnClick
        >
          <img
            style={{ width: '24px', height: '24px' }}
            src={infoIcon}
            alt="i"
          />
        </Tooltip>
      </div>
      <div className={backButton} onClick={() => goBack()}>
        {t('JOB_DICTIONARY.JOB_PROFILE.GO_BACK').toUpperCase()}
      </div>
      <UnitSelectionString
        setUnitState={(unit) => setSpineChartState({ ...spineChartState, unit })}
        setTypeState={(type) => setSpineChartState({ ...spineChartState, type })}
        typeState={spineChartState.type}
        unitList={unitList}
        typeList={chartTypes}
      />
      <div
        className={Emotion.css`
           {
            margin-left: 42px;
            margin-top: 12px;
            position: relative;
          }
        `}
      >
        <AngleLabel position="top" angle={0} />
        <AngleLabel position="middleTop" angle={30} />
        <AngleLabel position="highMiddle" angle={60} />
        <AngleLabel position="bottom" angle={180} />
        <AngleLabel position="lowMiddle" angle={120} />
        <AngleLabel position="middleBottom" angle={150} />
        <AngleLabel position="middle" angle={90} />
        <PieChart
          chartId="pie_spine"
          containerHeight={480}
          data={getSpineData()}
          dataType={spineChartState.type}
          unit={spineChartState.unit === 'time' ? 'percent' : 'amount'}
        />
        <img
          src={bend}
          width={282}
          height={465}
          style={{ position: 'absolute', top: '39px', left: '-21px' }}
          alt="pic"
        />
      </div>
    </div>
  );
};

export default SpineChart;
