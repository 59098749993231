import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    filters: {},
    searchString: '',
};
const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        initFilters(state, action) {
            const { types, pageType } = action.payload;
            state.filters[pageType] = {};
            types.forEach((type) => {
                if (type === 'showPassedPrograms') {
                    state.filters[pageType][type] = false;
                }
                else {
                    state.filters[pageType][type] = [];
                }
            });
        },
        setFilterValues(state, action) {
            const { type, values, pageType } = action.payload;
            state.filters[pageType][type] = values;
        },
        addFilterValue(state, action) {
            const { type, value, pageType } = action.payload;
            state.filters[pageType][type].push(value);
        },
        removeFilterValue(state, action) {
            const { type, value, pageType } = action.payload;
            const index = state.filters[pageType][type].indexOf(value);
            state.filters[pageType][type].splice(index, 1);
        },
        clearFilter(state, action) {
            const { type, pageType } = action.payload;
            state.filters[pageType][type] = [];
        },
        clearFilters(state, action) {
            const { pageType } = action.payload;
            if (pageType) {
                Object.keys(state.filters[pageType]).forEach((type) => {
                    state.filters[pageType][type] = type === 'showPassedPrograms' ? false : [];
                });
            }
            else {
                state.filters = {};
            }
        },
        setSearchString(state, action) {
            const { searchString } = action.payload;
            state.searchString = searchString;
        },
        resetFilters(state) {
            state = initialState;
        },
    },
});
const { actions, reducer } = filtersSlice;
export const { initFilters, addFilterValue, clearFilter, setFilterValues, clearFilters, removeFilterValue, resetFilters, setSearchString, } = actions;
export default reducer;
