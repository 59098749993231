export const COACH_USER_SET_CURRENT_PAGE = 'COACH_USER_SET_CURRENT_PAGE';
export const COACH_USER_SET_DISPLAY_LENGTH = 'COACH_USER_SET_DISPLAY_LENGTH';
export const USERS_SET_SORTING_COLUMN = 'USERS_SET_SORTING_COLUMN';
export const USERS_SET_SORTING_TYPE = 'USERS_SET_SORTING_TYPE';
export const USERS_UPLOAD_SET_CURRENT_PAGE = 'USERS_UPLOAD_SET_CURRENT_PAGE';
export const USERS_UPLOAD_SET_DISPLAY_LENGTH = 'USERS_UPLOAD_SET_DISPLAY_LENGTH';
export const USERS_UPLOAD_SET_SORTING_COLUMN = 'USERS_UPLOAD_SET_SORTING_COLUMN';
export const USERS_UPLOAD_SET_SORTING_TYPE = 'USERS_UPLOAD_SET_SORTING_TYPE';

export const DEVICES_SET_CURRENT_PAGE = 'DEVICES_SET_CURRENT_PAGE';
export const DEVICES_SET_DISPLAY_LENGTH = 'DEVICES_SET_DISPLAY_LENGTH';
export const DEVICES_SET_SORTING_COLUMN = 'DEVICES_SET_SORTING_COLUMN';
export const DEVICES_SET_SORTING_TYPE = 'DEVICES_SET_SORTING_TYPE';

export const HELP_USER_SET_CURRENT_PAGE = 'HELP_USER_SET_CURRENT_PAGE';
export const HELP_USER_SET_DISPLAY_LENGTH = 'HELP_USER_SET_DISPLAY_LENGTH';
export const HELP_USERS_SET_SORTING_COLUMN = 'HELP_USER_SET_SORTING_COLUMN';
export const HELP_USERS_SET_SORTING_TYPE = 'HELP_USERS_SET_SORTING_TYPE';

export const COMPANIES_SET_CURRENT_PAGE = 'COMPANIES_SET_CURRENT_PAGE';
export const COMPANIES_SET_DISPLAY_LENGTH = 'COMPANIES_SET_DISPLAY_LENGTH';
export const COMPANIES_SET_SORTING_COLUMN = 'COMPANIES_SET_SORTING_COLUMN';
export const COMPANIES_SET_SORTING_TYPE = 'COMPANIES_SET_SORTING_TYPE';

export const DASHBOARD_USERS_SET_CURRENT_PAGE = 'DASHBOARD_USERS_SET_CURRENT_PAGE';
export const DASHBOARD_USERS_SET_DISPLAY_LENGTH = 'DASHBOARD_USERS_SET_DISPLAY_LENGTH';
export const DASHBOARD_USERS_SET_SORTING_COLUMN = 'DASHBOARD_USERS_SET_SORTING_COLUMN';
export const DASHBOARD_USERS_SET_SORTING_TYPE = 'DASHBOARD_USERS_SET_SORTING_TYPE';

export const ADMIN_DEVICES_SET_CURRENT_PAGE = 'ADMIN_DEVICES_SET_CURRENT_PAGE';
export const ADMIN_DEVICES_SET_DISPLAY_LENGTH = 'ADMIN_DEVICES_SET_DISPLAY_LENGTH';
export const ADMIN_DEVICES_SET_SORTING_COLUMN = 'ADMIN_DEVICES_SET_SORTING_COLUMN';
export const ADMIN_DEVICES_SET_SORTING_TYPE = 'ADMIN_DEVICES_SET_SORTING_TYPE';

export const HUBS_SET_CURRENT_PAGE = 'HUBS_SET_CURRENT_PAGE';
export const HUBS_SET_DISPLAY_LENGTH = 'HUBS_SET_DISPLAY_LENGTH';
export const HUBS_SET_SORTING_COLUMN = 'HUBS_SET_SORTING_COLUMN';
export const HUBS_SET_SORTING_TYPE = 'HUBS_SET_SORTING_TYPE';

export const FEEDBACKS_SET_CURRENT_PAGE = 'FEEDBACKS_SET_CURRENT_PAGE';
export const FEEDBACKS_SET_DISPLAY_LENGTH = 'FEEDBACKS_SET_DISPLAY_LENGTH';
export const FEEDBACKS_SET_SORTING_COLUMN = 'FEEDBACKS_SET_SORTING_COLUMN';
export const FEEDBACKS_SET_SORTING_TYPE = 'FEEDBACKS_SET_SORTING_TYPE';

export const MY_COMPANY_USERS_SET_CURRENT_PAGE = 'MY_COMPANY_USERS_SET_CURRENT_PAGE';
export const MY_COMPANY_USERS_SET_DISPLAY_LENGTH = 'MY_COMPANY_USERS_SET_DISPLAY_LENGTH';
export const MY_COMPANY_USERS_SET_SORTING_COLUMN = 'MY_COMPANY_USERS_SET_SORTING_COLUMN';
export const MY_COMPANY_USERS_SET_SORTING_TYPE = 'MY_COMPANY_USERS_SET_SORTING_TYPE';

export const MY_COMPANY_CATEGORIES_SET_CURRENT_PAGE = 'MY_COMPANY_CATEGORIES_SET_CURRENT_PAGE';
export const MY_COMPANY_CATEGORIES_SET_DISPLAY_LENGTH = 'MY_COMPANY_CATEGORIES_SET_DISPLAY_LENGTH';
export const MY_COMPANY_CATEGORIES_SET_SORTING_COLUMN = 'MY_COMPANY_CATEGORIES_SET_SORTING_COLUMN';
export const MY_COMPANY_CATEGORIES_SET_SORTING_TYPE = 'MY_COMPANY_CATEGORIES_SET_SORTING_TYPE';

export const MY_COMPANY_ENTRIES_SET_CURRENT_PAGE = 'MY_COMPANY_ENTRIES_SET_CURRENT_PAGE';
export const MY_COMPANY_ENTRIES_SET_DISPLAY_LENGTH = 'MY_COMPANY_ENTRIES_SET_DISPLAY_LENGTH';
export const MY_COMPANY_ENTRIES_SET_SORTING_COLUMN = 'MY_COMPANY_ENTRIES_SET_SORTING_COLUMN';
export const MY_COMPANY_ENTRIES_SET_SORTING_TYPE = 'MY_COMPANY_ENTRIES_SET_SORTING_TYPE';

export const SOTER_TASK_VIDEOS_SET_CURRENT_PAGE = 'SOTER_TASK_VIDEOS_SET_CURRENT_PAGE';
export const SOTER_TASK_VIDEOS_SET_DISPLAY_LENGTH = 'SOTER_TASK_VIDEOS_SET_DISPLAY_LENGTH';
export const SOTER_TASK_VIDEOS_SET_SORTING_COLUMN = 'SOTER_TASK_VIDEOS_SET_SORTING_COLUMN';
export const SOTER_TASK_VIDEOS_SET_SORTING_TYPE = 'SOTER_TASK_VIDEOS_SET_SORTING_TYPE';

export const SOTER_TASK_SET_COMPARISONS_SORTING_TYPE = 'SOTER_TASK_SET_COMPARISONS_SORTING_TYPE';
export const SOTER_TASK_COMPARISONS_SET_SORTING_COLUMN = 'SOTER_TASK_COMPARISONS_SET_SORTING_COLUMNS';
export const SOTER_TASK_COMPARISONS_SET_CURRENT_PAGE = 'SOTER_TASK_COMPARISONS_SET_CURRENT_PAGE';
export const SOTER_TASK_COMPARISONS_SET_DISPLAY_LENGTH = 'SOTER_TASK_COMPARISONS_SET_DISPLAY_LENGTH';

export const JOBS_SET_SORTING_TYPE = 'JOBS_SET_SORTING_TYPE';
export const JOBS_SET_SORTING_COLUMN = 'JOBS_SET_SORTING_COLUMN';
export const JOBS_SET_CURRENT_PAGE = 'JOBS_SET_CURRENT_PAGE';
export const JOBS_SET_DISPLAY_LENGTH = 'JOBS_SET_DISPLAY_LENGTH';

export const CLEAR_PAGINATION = 'CLEAR_PAGINATION';
