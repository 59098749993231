import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _isEqual from 'lodash/isEqual';
import { flexRow, pl3, pr3 } from 'assets/soter-flex-styles';
import {
  clearFiltersButton,
  disabledButton,
  exportButton,
  selectionFilter,
} from 'assets/jss/components/stringDataSelectionFilter';
import Text from 'lib/ui/Text';
import { OVERVIEW_DATA_FILTER, OVERVIEW_PDF_EXPORT } from 'constants/analyticsEvents';
import InlineMultiSelect from './InlineMultiSelect';
import { getDatePickerRanges } from '../utils/getPeriods';
import useNumberOfTrialDaysLeftBanner from '../../../task/overview/useNumberOfTrialDaysLeftBanner';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import useUpdate from '../utils/useUpdate';
import { setSelectedGrouping } from '../overviewState/actions';
import StickyOnScrollContainer from '../../../containers/ItemTableContainer/components/StickyOnScrollContainer';
import CustomDateRangePicker from '../../../containers/ItemTableContainer/components/CustomDateRangePicker';
import Button from '../../../lib/ui/Button';
import Tooltip from '../../../lib/ui/Tooltip';
import exportToPdf from '../../../containers/PdfContainer/utils/exportToPdf';
import { useAppSelector } from '../../../lib/utils/useStore';

const StringDataSelectionFilter = ({
  period,
  departments,
  selectedDepartments,
  sites,
  selectedSites,
  noDataForReport,
  onRenderedRangeChange,
  getOverviewData,
}) => {
  const { grouping } = useAppSelector((state) => state.overviewState);
  const isGroupedByDay = grouping.activeUsers.includes('BY_DAY') || grouping.registeredUsers.includes('BY_DAY');
  const isSelectedMoreThanYear = (period?.startedAt && period?.finishedAt) && Math.abs(period?.finishedAt - period?.startedAt) > 31557600;
  const disableReport = isGroupedByDay && isSelectedMoreThanYear;
  const dispatch = useDispatch();
  const timezoneOffsetSeconds = moment().utcOffset() * 60;
  const { selectedCompany } = useSelector((state) => state.companies);
  const [datePickerAllTimeValue, setDatePickerAllTimeValues] = useState([
    new Date(selectedCompany?.createdAtInMilliseconds * 1000),
    new Date(),
  ]);
  const [dateRangePickerValue, setDateRangePickerValue] = useState(
    datePickerAllTimeValue || [],
  );
  const [preSelectedDepartments, setPreselectedDepartments]
    = useState(selectedDepartments);
  const [preSelectedSites, setPreselectedSites] = useState(selectedSites);
  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('wearableProduct');
  const { t } = useTranslation();
  useEffect(() => {
    setPreselectedDepartments(selectedDepartments);
  }, [selectedDepartments]);
  useEffect(() => {
    setPreselectedSites(selectedSites);
  }, [selectedSites]);
  const exportHandler = () => {
    window.logEvent(OVERVIEW_PDF_EXPORT);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(OVERVIEW_PDF_EXPORT));
    }
    exportToPdf(
      t('WEARABLES.OVERVIEW.OVERVIEW'),
      selectedCompany.name,
      '#overview_report',
      'p',
    );
  };
  const mapDataForSelect = (data) => data?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const changeDepartment = (deps) => {
    const params = {
      filter_by: 'department',
    };
    window.logEvent(OVERVIEW_DATA_FILTER, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${OVERVIEW_DATA_FILTER}: ${params}`));
    }
    getOverviewData(period, deps, selectedSites);
  };
  const changeSite = (s) => {
    const params = {
      filter_by: 'site',
    };
    window.logEvent(OVERVIEW_DATA_FILTER, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${OVERVIEW_DATA_FILTER}: ${params}`));
    }
    getOverviewData(period, selectedDepartments, s);
  };
  const changePeriod = (selectedPeriod) => {
    setDateRangePickerValue(selectedPeriod);
    const newPeriod = {
      startedAt: Math.floor(+moment(selectedPeriod[0]).startOf('day').format('X')) + timezoneOffsetSeconds,
      finishedAt: Math.floor(+moment(selectedPeriod[1]).endOf('day').format('X')) + timezoneOffsetSeconds,
    };
    const params = {
      filter_by: 'period',
    };
    window.logEvent(OVERVIEW_DATA_FILTER, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${OVERVIEW_DATA_FILTER}: ${params}`));
    }
    getOverviewData(newPeriod, selectedDepartments, selectedSites);
  };
  useEffect(
    () => setDatePickerAllTimeValues([
      new Date(selectedCompany?.createdAtInMilliseconds * 1000),
      new Date(),
    ]),
    [selectedCompany],
  );
  useEffect(
    () => setDateRangePickerValue(datePickerAllTimeValue),
    [datePickerAllTimeValue],
  );
  const clearAllFilters = () => {
    setDateRangePickerValue(datePickerAllTimeValue);
    const newPeriod = {
      startedAt: Math.floor(datePickerAllTimeValue[0].valueOf() / 1000),
      finishedAt: Math.floor(datePickerAllTimeValue[1].valueOf() / 1000),
    };
    getOverviewData(
      newPeriod,
      departments.map(({ id }) => id),
      sites.map(({ id }) => id),
    );
  };

  useUpdate(() => {
    clearAllFilters();
    dispatch(setSelectedGrouping('by department', 'spineChart'));
    dispatch(setSelectedGrouping('by department', 'shoulderChart'));
  }, [selectedCompany]);

  const datePickerRanges = getDatePickerRanges(datePickerAllTimeValue, t);
  const lang = localStorage.getItem('language');
  const getRenderValueForRangePicker = () => {
    const item = datePickerRanges.find(({ value }) => _isEqual(value, dateRangePickerValue));
    if (item) {
      return () => item.label;
    }
    return null;
  };

  const isClearAllFiltersVisible = useMemo(
    () => (departments &&
        sites &&
        selectedDepartments.length !== departments?.length &&
        selectedDepartments.length > 0)
      || (selectedSites.length !== sites?.length && selectedSites.length > 0)
      || getRenderValueForRangePicker()?.()
        !== t('WEARABLES.OVERVIEW.PERIODS.ALL_TIME'),
    [selectedDepartments, selectedSites],
  );
  useEffect(() => {
    const renderedValue = getRenderValueForRangePicker()?.();
    // eslint-disable-next-line max-len
    onRenderedRangeChange(
      renderedValue
        || `${moment(dateRangePickerValue[0]).format('YYYY-MM-DD')} – ${moment(
          dateRangePickerValue[1],
        ).format('YYYY-MM-DD')}`,
    );
  }, [dateRangePickerValue]);

  return (
    <>
      {numberOfTrialDaysLeftBanner && (
      <StickyOnScrollContainer
        topMargin={numberOfTrialDaysLeftBanner ? 0 : 7}
        topBlock={numberOfTrialDaysLeftBanner}
        height={50}
      />
      )}
      <StickyOnScrollContainer topMargin={26}>
        <div className={`${flexRow} ${pl3} ${pr3} ${selectionFilter}`}>
          <Text
            text={t('WEARABLES.OVERVIEW.DATA_FROM')}
            appearance="body"
            inline
            style={{ fontSize: lang === 'en' ? '16px' : '14px' }}
          />
          <InlineMultiSelect
            option={preSelectedDepartments}
            data={mapDataForSelect(departments)}
            changeOption={setPreselectedDepartments}
          // cleanable={selectedDepartments.length > 1}
            size={lang === 'en' ? 'lg' : 'md'}
            unit={t('WEARABLES.OVERVIEW.DEPARTMENT').toLowerCase()}
            values={departments}
            onApply={changeDepartment}
          />
          <InlineMultiSelect
            option={preSelectedSites}
            data={mapDataForSelect(sites)}
            changeOption={setPreselectedSites}
            size={lang === 'en' ? 'lg' : 'md'}
            unit={t('WEARABLES.OVERVIEW.SITE').toLowerCase()}
            values={sites}
            onApply={changeSite}
          />
          <Text
            text={t('WEARABLES.OVERVIEW.FOR_RANGE')}
            appearance="body"
            inline
            style={{ fontSize: lang === 'en' ? '16px' : '14px' }}
          />
          <CustomDateRangePicker
            disableBefore={selectedCompany?.createdAtInMilliseconds * 1000}
            // renderValue={getRenderValueForRangePicker()} // @deprecated
            value={dateRangePickerValue}
            ranges={datePickerRanges}
            cleanable={false}
            appearance="subtle"
            onChange={changePeriod}
          />
          {isClearAllFiltersVisible && (
          <Button
            appearance="text"
            className={clearFiltersButton}
            handler={clearAllFilters}
            text={t('WEARABLES.OVERVIEW.CLEAR_FILTERS')}
            style={{ background: 'none' }}
          />
          )}
          <div style={{ marginLeft: 'auto' }}>
            <Tooltip
              placement="bottomStart"
              text={
                disableReport ? t('WEARABLES.OVERVIEW.TOOLTIPS.LIMIT_REPORT_CHARTS')
                  : noDataForReport
                    ? t('WEARABLES.OVERVIEW.NO_REPORT_DATA')
                    : t('WEARABLES.OVERVIEW.GENERATE_REPORT')
          }
            >
              <Button
                appearance="secondary"
                className={exportButton}
                disabled={noDataForReport || disableReport}
                handler={noDataForReport ? () => {} : exportHandler}
                text={t('WEARABLES.OVERVIEW.EXPORT_PDF')}
              />
            </Tooltip>
          </div>
        </div>
      </StickyOnScrollContainer>
    </>
  );
};

export default StringDataSelectionFilter;
