import React from 'react';
import { useTranslation } from 'react-i18next';
import { cardHeader, flexColumn, flexRow } from 'assets/soter-flex-styles';
import Text from '../../../../lib/ui/Text';

const TwoChartCard = ({
  title,
  cardClass,
  badge = null,
  control = null,
  leftChart,
  rightChart,
  headerAdditionalStyles = {},
}) => {
  const { t } = useTranslation();

  return (
    <div className={cardClass}>
      <div className={cardHeader} style={headerAdditionalStyles}>
        <Text text={title.toUpperCase()} />
        <Text
          text={t('WEARABLES.USER_PAGE.HAZARDS').toUpperCase()}
          style={{ marginLeft: '520px' }}
          inline
        />
        {control}
        {badge}
      </div>
      <div className={flexRow} style={{ alignItems: 'flex-start' }}>
        <div className={flexColumn} style={{ flex: '1 0 514px' }}>
          {leftChart}
        </div>
        <div
          className={flexColumn}
          style={{ flex: '1 0 514px', paddingTop: '11px' }}
        >
          {rightChart}
        </div>
      </div>
    </div>
  );
};

export default TwoChartCard;
