import React from 'react';
import * as Emotion from 'emotion';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash/function';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/x-circle.svg';
import { column, formControlInput } from '../../../assets/soter-flex-styles';
import { clearSearchButton, iconWrapper, label, searchInput, } from '../../../assets/jss/components/searchInput';
import searchIcon from '../../../assets/img/icons/search.svg';
import useFocus from '../../../lib/utils/useFocus';
import { setSearchString } from '../filtersStore/filtersSlice';
import Tooltip from '../../../lib/ui/Tooltip';

export const checkCircleIcon = Emotion.css`
  stroke: ${COLORS.GRAY[400]};
`;

const SearchInputForUserTable = ({ type, withLabel = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterState = useSelector((state) => state.filtersNew);
  const { searchString } = filterState;
  const tooltipTextByType = {
    coach: t('SEARCH_TOOLTIPS.ACTIVITY'),
    clipGoUsersUpload: t('SEARCH_TOOLTIPS.UPLOAD'),
    help: t('SEARCH_TOOLTIPS.HELP'),
    devices: t('SEARCH_TOOLTIPS.DEVICES'),
    jobs: t('SEARCH_TOOLTIPS.JOBS'),
    myCompanyUsers: t('SEARCH_TOOLTIPS.MY_USERS'),
    soterTaskVideos: t('SEARCH_TOOLTIPS.VIDEOS'),
    companies: t('SEARCH_TOOLTIPS.COMPANIES'),
    dashboardUsers: t('SEARCH_TOOLTIPS.DASH_USERS'),
    categories: t('SEARCH_TOOLTIPS.CATEGORIES'),
    entries: t('SEARCH_TOOLTIPS.ENTRIES'),
  };

  const isClearable = searchString.length > 0;

  // eslint-disable-next-line no-unused-vars
  const [inputRef, setInputFocus] = useFocus();

  const handleInputChange = debounce((event) => {
    // eslint-disable-next-line no-unused-expressions
    // event === '' ? removeParam(URLParams.search) : addURLQueryParams(URLParams.search, event);
    dispatch(setSearchString({ searchString: event }));
  }, 500, { leading: true, maxWait: 2000, trailing: false });

  const handleClearSearch = () => {
    // removeParam(URLParams.search);
    dispatch(setSearchString({ searchString: '' }));
    setInputFocus();
  };

  const input = (
    <input
      className={`${formControlInput} ${searchInput}`}
      type="text"
      id="user-search"
      placeholder={t('GENERAL.SEARCH')}
      ref={inputRef}
      value={searchString}
      onChange={(event) => handleInputChange(event.target.value)}
    />
  );

  return (
    <Tooltip
      noTranslation
      text={tooltipTextByType[type] || t('SEARCH_TOOLTIPS.ACTIVITY')}
      placement="bottomStart"
    >
      <form
        className={column}
        autoComplete="off"
        name="change"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {withLabel ? (
          <label
            className={label}
            style={{ marginBottom: '1.625rem' }}
            htmlFor="user-search"
          >
            {input}
          </label>
        ) : (
          input
        )}
        <div className={iconWrapper}>
          {isClearable ? (
            <button
              style={{ zIndex: 2 }}
              className={clearSearchButton}
              type="button"
              // appearance='text'
              onClick={handleClearSearch}
            >
              <CloseIcon className={checkCircleIcon} />
            </button>
          ) : (
            <img src={searchIcon} alt="search" />
          )}
        </div>
      </form>
    </Tooltip>
  );
};

export default SearchInputForUserTable;
