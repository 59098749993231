import React from 'react';
import * as Emotion from 'emotion';
import { COLORS } from '../../constants/colors';

const wrapper = Emotion.css`
   {
    position: absolute;
    left: 265px;
    top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 220px;
    height: 177px;
    border: 1px solid ${COLORS.GRAY[100]};
    padding: 15px;
    font-size: 14px;
    span:nth-child(2n) {
      text-align: right;
      font-weight: 700;
    }
  }
`;

const TwistingCard = ({
  lowAngles,
  mediumAngles,
  highAngles,
  type,
  isTask,
}) => {
  return (
    <div className={wrapper}>
      <span style={{ color: GRAY[400], fontSize: '12px' }}>
        {type === 'percent'
          ? '%\u00A0of\u00A0time with twisting'.toUpperCase()
          : type === 'time'
          ? 'Seconds in twisting'.toUpperCase()
          : isTask
          ? 'Movements\u00A0 with twisting'.toUpperCase()
          : 'Movements\u00A0/\u00A0h with twisting'.toUpperCase()}
      </span>
      <span style={{ fontSize: '34px' }}>
        {(lowAngles + mediumAngles + highAngles).toFixed(
          type === 'percent' || (type === 'amount' && isTask) ? 0 : 2
        )}
      </span>
      <span>30° – 60°</span>
      <span>{lowAngles.toFixed(type === 'amount' && isTask ? 0 : 2)}</span>
      <span>60° – 90°</span>
      <span>{mediumAngles.toFixed(type === 'amount' && isTask ? 0 : 2)}</span>
      <span>90° – 120°</span>
      <span>{highAngles.toFixed(type === 'amount' && isTask ? 0 : 2)}</span>
    </div>
  );
};

export default TwistingCard;
