import React, { useEffect } from 'react';
import { Redirect, useParams } from '@reach/router';
import { confirmEmail } from './store/actions';
import { useAppDispatch, useAppSelector } from '../lib/utils/useStore';

const ConfirmEmailPage = () => {
  const { profile, error } = useAppSelector((state) => state.auth);
  const { token } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (token) {
      window.localStorage.setItem('token', token);
      dispatch(confirmEmail());
    }
  }, [token]);
  return (
    <div style={{
      marginTop: '40vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
    }}
    >
      {error ? `Can't confirm email. Error message:  ${error.message}. Please check you link properly or contact our support.` : 'Your email is confirming...'}
      {profile?.id && profile.emailConfirmed ? <Redirect to="../../users/soter-genius/gallery" /> : null}
    </div>
  );
};

export default ConfirmEmailPage;
