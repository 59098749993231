import React from 'react';
import { useTranslation } from 'react-i18next';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import usePrevious from '../../../lib/utils/usePreviuos';
import CustomInput from '../../../lib/ui/CustomInput';

const AssessmentName = ({ name, setName }) => {
  const { t } = useTranslation();
  const previousName = usePrevious(name);
  return (
    <div style={{ height: 'inherit', padding: '10px' }}>
      <div className={text(COLORS.GRAY[400], 16)}>
        {t('SOTER_TASK.ASSESSMENT.TITLES.ENTER_NAME').toUpperCase()}
      </div>
      <CustomInput value={name} onChange={(_, value) => setName(value)} />
      {previousName?.length > 0 && name?.replace(/\s/g, '').length === 0 && (
        <span className={text(COLORS.RED[800])}>
          {t('SOTER_TASK.ASSESSMENT.ERRORS.INVALID_NAME')}
        </span>
      )}
    </div>
  );
};

export default AssessmentName;
