import React from 'react';
import {
  eventNotification,
  flexRow,
  ml3,
  mr3,
  notificationText,
  smallIcon,
  successNotificationClose,
} from '../../../assets/soter-flex-styles';
import successNotificationIcon from '../../../assets/img/icons/notifications/success-notification-icon.svg';
import { useAppDispatch } from '../../../lib/utils/useStore';
import { resetEventNotification } from '../notificationsStore/notificationsSlice';

const NotificationEvent = ({ notification }) => {
  const dispatch = useAppDispatch();
  const { message, id } = notification;

  return (
    <div className={eventNotification} role="alert">
      <div className={flexRow}>
        <img
          className={`${smallIcon} ${ml3} ${mr3}`}
          src={successNotificationIcon}
          alt="battery charge"
        />
        <div className={notificationText}>
          event sent successfully:
          {' '}
          <span style={{ color: '#EF6C00' }}>{message}</span>
        </div>
        <button
          className={`${successNotificationClose} ${mr3}`}
          type="button"
          onClick={() => dispatch(resetEventNotification(id))}
          aria-label="close notification"
        />
      </div>
    </div>
  );
};

export default NotificationEvent;
