import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import moment from 'moment';
import { Dropdown, Popover, Whisper } from 'rsuite';
import { COLORS } from 'constants/colors';
import moreIcon from 'assets/img/icons/more-vertical.svg';
import {
  actionButton, actionColorRed, actionDropdown, text,
} from 'assets/jss/style';
import hexToRgba from '../../../lib/utils/hexToRgba';
import { getColorForScore } from './AssessmentNiosh';
import { getColorForTotalScore } from './Assessment';

const assessmentList = Emotion.css`
  height: inherit;
  overflow: scroll;
`;

const assessmentSlot = Emotion.css`
  display: grid;
  padding: 10px 0;
  grid-template-areas:
    'triangle title'
    'triangle info';
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  position: relative;
  cursor: pointer;
`;

const info = Emotion.css`
  grid-area: info;
  display: flex;
  margin-top: 7px;
  span {
    margin-right: 15px;
  }
`;

const bubble = (color) => Emotion.css`
  width: 20px;
  height: 20px;
  color: ${color};
  font-size: 10px;
  margin-top: -5px;
  margin-left: 17px;
  background-color: ${hexToRgba(color, 0.2)};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const triangle = (isSelected) => Emotion.css`
  grid-area: triangle;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 14px solid ${isSelected ? COLORS.LIGHT_BLUE[600] : COLORS.GRAY[400]};
  border-bottom: 7px solid transparent;
  border-radius: 2px;
  position: relative;
  margin-top: 37%;
  margin-left: 25%;
  div {
    position: absolute;
    top: -5px;
    left: -13px;
    width: 0;
    height: 0;
    border-radius: 2px;
    border-top: 5px solid transparent;
    border-left: 10px solid ${isSelected ? COLORS.LIGHT_BLUE[600] : COLORS.WHITE};
    border-bottom: 5px solid transparent;
  }
`;

const AssessmentDropdown = ({ removeItemHandler, exportReport }) => {
  const { t } = useTranslation();
  const triggerRef = React.createRef();
  const onSelect = () => {
    triggerRef.current.hide();
  };
  return (
    <Whisper
      trigger="click"
      placement="bottomEnd"
      triggerRef={triggerRef}
      speaker={(
        <Popover className={actionDropdown}>
          <Dropdown.Menu className={actionDropdown} onSelect={onSelect}>
            {exportReport ? (
              <Dropdown.Item onClick={exportReport}>
                {t('SOTER_TASK.ASSESSMENT.BUTTONS.EXPORT')}
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item
              className={actionColorRed}
              onClick={removeItemHandler}
            >
              {t('SOTER_TASK.ASSESSMENT.BUTTONS.DELETE')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      )}
    >
      <img src={moreIcon} className={`${actionButton}`} alt=":" />
    </Whisper>
  );
};

const AssessmentSlot = ({
  createdBy,
  createdAt,
  totalScore,
  type,
  title,
  isSelected,
  selectAssessment,
  id,
  goToAssessment,
  removeAssessment,
  exportReport,
}) => (
  <div className={assessmentSlot}>
    <div className={triangle(isSelected)} onClick={() => goToAssessment()}>
      <div />
    </div>
    <div
      onClick={() => selectAssessment(id)}
      className={text(COLORS.GRAY[950], 13)}
      style={{ gridArea: 'title' }}
    >
      {title}
    </div>
    <div
      onClick={() => selectAssessment(id)}
      className={`${info} ${text(COLORS.GRAY[400], 12)}`}
    >
      <span>{moment(createdAt, 'X').format('DD MMM YYYY')}</span>
      <span>{createdBy}</span>
      <span>
        {type === 2 || type === 3 ? 'RULA' : type === 4 ? 'REBA' : 'NIOSH'}
      </span>
    </div>
    <div
      style={{
        position: 'absolute',
        left: '272px',
        top: '27px',
      }}
    >
      <span
        onClick={() => selectAssessment(id)}
        className={bubble(
          type === 5
            ? getColorForScore(totalScore)
            : getColorForTotalScore(
              totalScore,
              type === 2 || type === 3 ? 'rula' : 'reba',
            ),
        )}
      >
        {totalScore / Math.floor(totalScore) === 1
          ? totalScore
          : totalScore.toFixed(2)}
      </span>
    </div>
    <div
      style={{
        position: 'absolute',
        left: '310px',
        top: '20px',
      }}
    >
      <AssessmentDropdown
        exportReport={exportReport}
        removeItemHandler={() => {
          if (window.confirm('Are you sure?')) removeAssessment(id);
        }}
      />
    </div>
  </div>
);

const AssessmentsList = ({
  assessmentsList,
  setCurrentTime,
  currentTime,
  selectAssessment,
  removeAssessment,
  setAssessmentDownloading,
}) => (
  <div className={assessmentList}>
    {assessmentsList.map(
      ({
        createdBy, createdAt, totalScore, type, title, id, timestamp,
      }) => (
        <AssessmentSlot
          key={id}
          goToAssessment={() => setCurrentTime(timestamp / 1000)}
          id={id}
          selectAssessment={selectAssessment}
          title={title}
          type={type}
          createdAt={createdAt}
          createdBy={createdBy}
          totalScore={totalScore}
          isSelected={
            Math.floor(currentTime / 1000) === Math.floor(timestamp / 1000)
          }
          removeAssessment={removeAssessment}
          exportReport={totalScore ? () => setAssessmentDownloading(id) : null}
        />
      ),
    )}
  </div>
);

export default AssessmentsList;
