import React from 'react';
import { useTranslation } from 'react-i18next';
import { programInfoSubText } from '../../../../assets/soter-flex-styles';
import sliceLongString from '../../../../lib/utils/sliceLongString';
import CustomTooltip from '../../../../lib/ui/Tooltip';
import { DEPARTMENT_NOT_ASSIGNED } from '../../constants/tableValuesAndFilterOptions';

const DepartmentCell = ({
  rowData, dataKey, sites, departments,
}) => {
  const { t } = useTranslation();
  let departmentName = rowData.department?.name;
  if (
    departments &&
    (rowData.departmentId || rowData.firstTaskVideo?.departmentId)
  ) {
    departmentName = departments.find(
      ({ id }) => id === rowData.departmentId || rowData.firstTaskVideo?.departmentId,
    )?.name;
  }
  let siteName = rowData.site?.name;
  if (sites && (rowData.siteId || rowData.firstTaskVideo?.siteId)) {
    siteName = sites.find(
      ({ id }) => id === (rowData.siteId || rowData.firstTaskVideo?.siteId),
    )?.name;
  }
  let isTooltipNeeded = false;
  let isSiteTooltipNeeded = false;
  if (departmentName === departmentName?.toUpperCase()) {
    if (departmentName && departmentName.length > 15) isTooltipNeeded = true;
    departmentName = sliceLongString(departmentName, 15);
  } else {
    if (departmentName && departmentName.length > 17) isTooltipNeeded = true;
    departmentName = sliceLongString(departmentName, 15);
  }
  if (siteName?.length > 20) isSiteTooltipNeeded = true;
  return (
    <>
      {isTooltipNeeded ? (
        <CustomTooltip text={rowData.department?.name || departmentName}>
          <span style={{ fontSize: '14px' }}>
            {departmentName || t(DEPARTMENT_NOT_ASSIGNED)}
          </span>
        </CustomTooltip>
      ) : (
        <span style={{ fontSize: '14px' }}>
          {departmentName || t(DEPARTMENT_NOT_ASSIGNED)}
        </span>
      )}
      <br />
      {isSiteTooltipNeeded ? (
        <CustomTooltip text={siteName}>
          <div className={programInfoSubText}>
            {sliceLongString(siteName, 17) || '-'}
          </div>
        </CustomTooltip>
      ) : (
        <div className={programInfoSubText}>
          {sliceLongString(siteName, 17) || '-'}
        </div>
      )}
    </>
  );
};

export default DepartmentCell;
