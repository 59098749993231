import { toCamel } from 'snake-camel';
import * as types from './actionTypes';
import {
  adminService,
  createDashboardUserRequest,
  dashboardUsersRequest,
  deleteDashboardUserRequest,
  editDashboardUserRequest,
  getInviteLinkRequest,
  getMetaData,
  myCompanyRequest,
  myCompanyService,
  resetDashboardUserPasswordRequest,
  sendInviteRequest
} from '../../../init/proto_client';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';
import { openedModalSelector } from '../../../containers/ModalContainer/modalsStore/selectors';
import { currentDashboardUserIdSelector, currentDashboardUserIsOnlyItemSelector } from './selectors';
import { resetFiltersState } from '../../../containers/ItemTableContainer/filtersStoreOld/actions';

export function setCurrentDashboardUserId(id) {
  return ({ type: types.SET_CURRENT_DASHBOARD_USER_ID, id });
}

export function setCurrentDashbordUserIsOnlyItem(isOnlyItem) {
  return { type: types.SET_CURRENT_DASHBOARD_USER_IS_ONLY_ITEM, isOnlyItem };
}

export function setInviteLink(inviteLink) {
  return ({ type: types.SET_INVITE_LINK, inviteLink });
}

export function getAdminUsers() {
  return async (dispatch) => {
    dispatch({
      type: types.DASHBOARD_USERS_TOGGLE_LOADING,
      isLoading: true,
    });
    dashboardUsersRequest.setDummy(true);
    await adminService.getDashboardUsers(dashboardUsersRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const users = [...response.toObject().dashboardUsersList].map((user) => toCamel(user));
        dispatch({
          type: types.DASHBOARD_USERS_SET,
          users,
        });
        dispatch({
          type: types.DASHBOARD_USERS_TOGGLE_LOADING,
          isLoading: false,
        });
      }
    });
  };
}

export function getMyCompanyUsers(currentCompanyId) {
  return async (dispatch) => {
    dispatch({
      type: types.DASHBOARD_USERS_TOGGLE_LOADING,
      isLoading: true,
    });
    myCompanyRequest.setCompanyId(currentCompanyId);
    await myCompanyService.myCompany(myCompanyRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const users = [...response.toObject().dashboardUsersList].map((user) => toCamel(user));
        dispatch({
          type: types.DASHBOARD_USERS_SET,
          users,
        });
        dispatch({
          type: types.DASHBOARD_USERS_TOGGLE_LOADING,
          isLoading: false,
        });
      }
    });
  };
}

const modifyDashboardUserRequest = (request, userData) => {
  const {
    id,
    email,
    firstName,
    lastName,
    jobTitle,
    isAdmin,
    canDeactivateDevices,
    canDeactivateUsers,
    password,
    resellerId,
    companies,
    departments,
    getInvite,
    sendInvite,
    receiveNeedHelpRequests,
    canInviteUsers,
    isSubscriptionManager,
    sites,
  } = userData;
  if (request === editDashboardUserRequest) {
    request.setDashboardUserId(id);
  }
  request
    .setEmail(email)
    .setFirstName(firstName)
    .setLastName(lastName)
    .setJobTitle(jobTitle)
    .setAdmin(isAdmin)
    .setIsActive(true)
    .setCanDeactivateDevices(canDeactivateDevices)
    .setCanDeactivateUsers(canDeactivateUsers)
    .setPassword(password)
    .setResellerId(resellerId)
    .setCompaniesList(companies || [])
    .setDepartmentsList(departments || [])
    .setGetInviteLink(getInvite)
    .setSendInvite(sendInvite)
    .setReceiveNeedHelpRequests(receiveNeedHelpRequests)
    .setCanInviteUsers(canInviteUsers)
    .setIsSubscriptionManager(isSubscriptionManager)
    .setSitesList(sites || []);
};

const getService = (isMyCompanyUser) => (isMyCompanyUser ? myCompanyService : adminService);

export const getInviteLink = (userId, isMyCompanyUser) => async (dispatch) => {
  getInviteLinkRequest.setDashboardUserId(userId);
  await getService(isMyCompanyUser).getInviteLink(
    getInviteLinkRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
        dispatch(setOpenedModal(null));
      } else {
        const { inviteLink } = toCamel(response.toObject());
        dispatch(setInviteLink(inviteLink));
        dispatch(setOpenedModal('copyInviteLink'));
      }
    },
  );
};

export const sendInvite = (userId, isMyCompanyUser) => async (dispatch, getStore) => {
  const state = getStore();
  sendInviteRequest.setDashboardUserId(userId);
  await getService(isMyCompanyUser).sendInviteLink(
    sendInviteRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const openedModal = openedModalSelector(state);
        if (openedModal === 'resendInviteLink') {
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.SEND_INVITE_SUCCESS_MSG,
          }));
          dispatch(setOpenedModal(null));
        }
      }
    },
  );
};

export const resendInviteLink = (userId, isMyCompanyUser) => async (dispatch, getStore) => {
  const state = getStore();
  sendInviteRequest.setDashboardUserId(userId);
  await getService(isMyCompanyUser).resendInviteLink(
    sendInviteRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const openedModal = openedModalSelector(state);
        if (openedModal === 'resendInviteLink') {
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.SEND_INVITE_SUCCESS_MSG,
          }));
          // dispatch(setOpenedModal(null));
        }
      }
    },
  );
};

export const resetPassword = (userId, isMyCompanyUser) => async (dispatch, getStore) => {
  const state = getStore();
  resetDashboardUserPasswordRequest.setDashboardUserId(userId);
  await getService(isMyCompanyUser).resetDashboardUserPassword(
    resetDashboardUserPasswordRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const openedModal = openedModalSelector(state);
        if (openedModal === 'resetPassword') {
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.RESET_PASSWORD_SUCCESS_MSG,
          }));
          dispatch(setOpenedModal(null));
        }
      }
    },
  );
};

export function createDashboardUser(userData) {
  modifyDashboardUserRequest(createDashboardUserRequest, userData);
  return async (dispatch, getStore) => {
    const state = getStore();
    await adminService.createDashboardUser(createDashboardUserRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
      } else {
        const user = response.toObject();
        dispatch({
          type: types.DASHBOARD_USER_ADD,
          user,
        });
        const openedModal = openedModalSelector(state);
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: openedModal === 'addNewUser'
            ? notificationMessages.CREATE_DASHBOARD_USER_SUCCESS_MSG
            : notificationMessages.INVITE_DASHBOARD_USER_SUCCESS_MSG,
        }));
        const isAddOrInviteMode = openedModal === 'addNewUser' || openedModal === 'inviteNewUser';
        if (isAddOrInviteMode) {
          dispatch(setCurrentDashboardUserId(user.id));
          const isMyCompanyUser = openedModal === 'inviteNewUser';
          dispatch(sendInvite(user.id, isMyCompanyUser));
          dispatch(getInviteLink(user.id, isMyCompanyUser));
        } else {
          dispatch(setOpenedModal(null));
        }
      }
    });
  };
}

export function editDashboardUser(userData) {
  modifyDashboardUserRequest(editDashboardUserRequest, userData);
  return async (dispatch, getStore) => {
    const state = getStore();
    await adminService.editDashboardUser(editDashboardUserRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          isLoading: false,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.CREATE_DASHBOARD_USER_FAILED_MSG,
        }));
      } else {
        const user = response.toObject();
        dispatch({
          type: types.DASHBOARD_USER_UPDATE,
          user,
        });
        const openedModal = openedModalSelector(state);
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: openedModal === 'addNewUser'
            ? notificationMessages.CREATE_DASHBOARD_USER_SUCCESS_MSG
            : notificationMessages.INVITE_DASHBOARD_USER_SUCCESS_MSG,
        }));
        dispatch(setOpenedModal(null));
      }
    });
  };
}

export const deleteDashboardUser = () => async (dispatch, getStore) => {
  const state = getStore();
  const currentDashboardUserId = currentDashboardUserIdSelector(state);
  deleteDashboardUserRequest.setDashboardUserId(currentDashboardUserId);
  await adminService.deleteDashboardUsers(
    deleteDashboardUserRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.DASHBOARD_USERS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.DELETE_DASHBOARD_USER_FAILED_MSG,
        }));
      } else {
        const { id } = toCamel(response.toObject());
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.DELETE_DASHBOARD_USER_SUCCESS_MSG,
        }));
        dispatch({
          type: types.DASHBOARD_USER_DELETE,
          id,
        });
        dispatch(setOpenedModal(null));
        if (currentDashboardUserIsOnlyItemSelector(state)) {
          dispatch(resetFiltersState());
        }
      }
    },
  );
};

export function resetDashboardUsers() {
  return { type: types.DASHBOARD_USERS_RESET };
}
