import { useState } from 'react';
import _chunk from 'lodash/chunk';

const useChunkedList = (list, length) => {
  const [selectedListChunk, setSelectedListChunk] = useState(0);
  const chunkedVideoList = _chunk(list, length);
  const showListSwitcher = chunkedVideoList.length > 1;
  const switchListHandler = (direction) => () => {
    if (direction === 'left' && selectedListChunk > 0) setSelectedListChunk(selectedListChunk - 1);
    if (direction === 'right' && selectedListChunk < chunkedVideoList.length - 1) setSelectedListChunk(selectedListChunk + 1);
  };
  return [selectedListChunk, chunkedVideoList, showListSwitcher, switchListHandler];
};

export default useChunkedList;
