const activityLegendData = [
    [
        'WEARABLES.ACTIVITY.REPORT.PER_CHANGE',
        'WEARABLES.ACTIVITY.REPORT.RANKED_01',
    ],
    [
        'WEARABLES.ACTIVITY.REPORT.START_RISK',
        'WEARABLES.ACTIVITY.REPORT.RANKED_02',
    ],
    [
        'WEARABLES.ACTIVITY.REPORT.CURRENT_RISK',
        'WEARABLES.ACTIVITY.REPORT.RANKED_03',
    ],
    [
        'WEARABLES.ACTIVITY.REPORT.START_PER',
        'WEARABLES.ACTIVITY.REPORT.RANKED_04',
    ],
    [
        'WEARABLES.ACTIVITY.REPORT.CURRENT_PER',
        'WEARABLES.ACTIVITY.REPORT.RANKED_05',
    ],
    [
        'WEARABLES.ACTIVITY.REPORT.GOLD',
        'WEARABLES.ACTIVITY.REPORT.RANKED_06',
    ],
    [
        'WEARABLES.ACTIVITY.REPORT.AVG_PER',
        'WEARABLES.ACTIVITY.REPORT.RANKED_07',
    ],
];
export default activityLegendData;
