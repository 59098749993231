import withUserDataSources from './withUserDataSources';
import CoachUserPageContainer from './CoachUserPageContainer';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';

const GlobalUserPage = ({ id }) => withUserDataSources(
  CoachUserPageContainer,
  id,
);

export default PrivatePageHOC(GlobalUserPage);
