/**
 * @fileoverview gRPC-Web generated client stub for users
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/user_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_tier_message_pb = require('../../../api/dashboard/v2/tier_message_pb.js')

var api_dashboard_v2_improvement_message_pb = require('../../../api/dashboard/v2/improvement_message_pb.js')

var api_dashboard_v2_job_role_message_pb = require('../../../api/dashboard/v2/job_role_message_pb.js')

var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js')

var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js')

var api_dashboard_v2_user_note_message_pb = require('../../../api/dashboard/v2/user_note_message_pb.js')
const proto = {};
proto.users = require('./user_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.users.UserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.users.UserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_GetUser = new grpc.web.MethodDescriptor(
  '/users.User/GetUser',
  grpc.web.MethodType.UNARY,
  proto.users.UserRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/GetUser',
      request,
      metadata || {},
      methodDescriptor_User_GetUser,
      callback);
};


/**
 * @param {!proto.users.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/GetUser',
      request,
      metadata || {},
      methodDescriptor_User_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserDepartmentRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_SetDepartment = new grpc.web.MethodDescriptor(
  '/users.User/SetDepartment',
  grpc.web.MethodType.UNARY,
  proto.users.UserDepartmentRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.setDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/SetDepartment',
      request,
      metadata || {},
      methodDescriptor_User_SetDepartment,
      callback);
};


/**
 * @param {!proto.users.UserDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.setDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/SetDepartment',
      request,
      metadata || {},
      methodDescriptor_User_SetDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserSiteRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_SetSite = new grpc.web.MethodDescriptor(
  '/users.User/SetSite',
  grpc.web.MethodType.UNARY,
  proto.users.UserSiteRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserSiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.setSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/SetSite',
      request,
      metadata || {},
      methodDescriptor_User_SetSite,
      callback);
};


/**
 * @param {!proto.users.UserSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.setSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/SetSite',
      request,
      metadata || {},
      methodDescriptor_User_SetSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserJobTitleRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_SetJobTitle = new grpc.web.MethodDescriptor(
  '/users.User/SetJobTitle',
  grpc.web.MethodType.UNARY,
  proto.users.UserJobTitleRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserJobTitleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserJobTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.setJobTitle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/SetJobTitle',
      request,
      metadata || {},
      methodDescriptor_User_SetJobTitle,
      callback);
};


/**
 * @param {!proto.users.UserJobTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.setJobTitle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/SetJobTitle',
      request,
      metadata || {},
      methodDescriptor_User_SetJobTitle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_role.UserJobRoleRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_SetJobRole = new grpc.web.MethodDescriptor(
  '/users.User/SetJobRole',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_job_role_message_pb.UserJobRoleRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.job_role.UserJobRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.job_role.UserJobRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.setJobRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/SetJobRole',
      request,
      metadata || {},
      methodDescriptor_User_SetJobRole,
      callback);
};


/**
 * @param {!proto.job_role.UserJobRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.setJobRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/SetJobRole',
      request,
      metadata || {},
      methodDescriptor_User_SetJobRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_ContinuousWearing = new grpc.web.MethodDescriptor(
  '/users.User/ContinuousWearing',
  grpc.web.MethodType.UNARY,
  proto.users.UserRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.continuousWearing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/ContinuousWearing',
      request,
      metadata || {},
      methodDescriptor_User_ContinuousWearing,
      callback);
};


/**
 * @param {!proto.users.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.continuousWearing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/ContinuousWearing',
      request,
      metadata || {},
      methodDescriptor_User_ContinuousWearing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserUpdateUserPrivateDataRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_UpdateUserPrivateData = new grpc.web.MethodDescriptor(
  '/users.User/UpdateUserPrivateData',
  grpc.web.MethodType.UNARY,
  proto.users.UserUpdateUserPrivateDataRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserUpdateUserPrivateDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserUpdateUserPrivateDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.updateUserPrivateData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/UpdateUserPrivateData',
      request,
      metadata || {},
      methodDescriptor_User_UpdateUserPrivateData,
      callback);
};


/**
 * @param {!proto.users.UserUpdateUserPrivateDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.updateUserPrivateData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/UpdateUserPrivateData',
      request,
      metadata || {},
      methodDescriptor_User_UpdateUserPrivateData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.DeviceLinkHistoryRequest,
 *   !proto.users.DeviceLinkHistoryResponse>}
 */
const methodDescriptor_User_GetDeviceLinkHistory = new grpc.web.MethodDescriptor(
  '/users.User/GetDeviceLinkHistory',
  grpc.web.MethodType.UNARY,
  proto.users.DeviceLinkHistoryRequest,
  proto.users.DeviceLinkHistoryResponse,
  /**
   * @param {!proto.users.DeviceLinkHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.DeviceLinkHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.users.DeviceLinkHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.DeviceLinkHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.DeviceLinkHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.getDeviceLinkHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/GetDeviceLinkHistory',
      request,
      metadata || {},
      methodDescriptor_User_GetDeviceLinkHistory,
      callback);
};


/**
 * @param {!proto.users.DeviceLinkHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.DeviceLinkHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.getDeviceLinkHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/GetDeviceLinkHistory',
      request,
      metadata || {},
      methodDescriptor_User_GetDeviceLinkHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UserServiceCreateUserRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_CreateUser = new grpc.web.MethodDescriptor(
  '/users.User/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.users.UserServiceCreateUserRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.users.UserServiceCreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.users.UserServiceCreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/CreateUser',
      request,
      metadata || {},
      methodDescriptor_User_CreateUser,
      callback);
};


/**
 * @param {!proto.users.UserServiceCreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/CreateUser',
      request,
      metadata || {},
      methodDescriptor_User_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_note_message.SetNoteRequest,
 *   !proto.users.UserResponse>}
 */
const methodDescriptor_User_SetNote = new grpc.web.MethodDescriptor(
  '/users.User/SetNote',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_user_note_message_pb.SetNoteRequest,
  proto.users.UserResponse,
  /**
   * @param {!proto.user_note_message.SetNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UserResponse.deserializeBinary
);


/**
 * @param {!proto.user_note_message.SetNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.setNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/SetNote',
      request,
      metadata || {},
      methodDescriptor_User_SetNote,
      callback);
};


/**
 * @param {!proto.user_note_message.SetNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UserResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.setNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/SetNote',
      request,
      metadata || {},
      methodDescriptor_User_SetNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.CreateDashboardFeedbackRequest,
 *   !proto.users.CreateDashboardFeedbackResponse>}
 */
const methodDescriptor_User_CreateDashboardFeedback = new grpc.web.MethodDescriptor(
  '/users.User/CreateDashboardFeedback',
  grpc.web.MethodType.UNARY,
  proto.users.CreateDashboardFeedbackRequest,
  proto.users.CreateDashboardFeedbackResponse,
  /**
   * @param {!proto.users.CreateDashboardFeedbackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.CreateDashboardFeedbackResponse.deserializeBinary
);


/**
 * @param {!proto.users.CreateDashboardFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.CreateDashboardFeedbackResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.CreateDashboardFeedbackResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UserClient.prototype.createDashboardFeedback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.User/CreateDashboardFeedback',
      request,
      metadata || {},
      methodDescriptor_User_CreateDashboardFeedback,
      callback);
};


/**
 * @param {!proto.users.CreateDashboardFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.CreateDashboardFeedbackResponse>}
 *     Promise that resolves to the response
 */
proto.users.UserPromiseClient.prototype.createDashboardFeedback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.User/CreateDashboardFeedback',
      request,
      metadata || {},
      methodDescriptor_User_CreateDashboardFeedback);
};


module.exports = proto.users;

