import { COLORS } from 'constants/colors'
import * as Emotion from 'emotion';
import { buttonStylesReset, inputLabel, mb3 } from '../../soter-flex-styles';

export const searchInput = Emotion.css`
  border-radius: 6px;
  transition: border-color ease-in-out 0.3s;
  z-index: 330;

  &:hover {
    border-color: ${COLORS.LIGHT_BLUE[600]};
  }

  &:focus {
    border-color: ${COLORS.LIGHT_BLUE[600]};
    box-shadow: none;
  }
`;

export const label = Emotion.css`
  ${inputLabel};
  ${mb3};

  margin-bottom: 1.625rem;
`;

export const iconWrapper = Emotion.css`
  position: absolute;
  top: 0;
  right: 0.5rem;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const clearSearchButton = Emotion.css`
  ${buttonStylesReset};

  display: flex;
  width: 20px;
  height: 20px;
  opacity: 0.8;
  
  &:hover{
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
