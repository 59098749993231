import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { mutedText } from '../../../../assets/soter-flex-styles';

const HelpRequestDaysCell = ({ rowData }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {rowData.wearingNow ? (
        <div style={{ color: COLORS.GREEN[600], fontSize: '13px', wordBreak: 'keep-all' }}>
          {t('WEARABLES.DEVICES.FILTERS.WEARING')}
        </div>
      ) : rowData.lastSessionInMilliseconds ? (
        <div style={{ fontSize: '14px', wordBreak: 'keep-all' }}>
          {moment(rowData.lastSessionInMilliseconds)
            .fromNow()
            .slice(
              0,
              moment(rowData.lastSessionInMilliseconds)
                .fromNow()
                .length - 3,
            )}
        </div>
      ) : (
        <div className={mutedText} style={{ fontSize: '14px', wordBreak: 'keep-all' }}>
          {t('WEARABLES.HELP.NOT_SYNCED')}
        </div>
      )}
    </>
  );
};

export default HelpRequestDaysCell;
