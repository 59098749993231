import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { logo, logoLinkWrapper, logoText } from '../../../assets/jss/components/logo';
import { routes } from '../../PrivatPageHOC/constants/routes';

const Logo = ({ isAdminArea }) => {
  const { id } = useSelector((state) => state.companies.selectedCompany);
  const logoLink = isAdminArea
    ? `../../${id}/${routes.admin}/${routes.companies}`
    : `../../${id}/${routes.users}/${routes.wearables}/${routes.overview}`;

  return (
    <div className={logo}>
      <Link to={logoLink} className={logoLinkWrapper}>
        <img
          src={isAdminArea ? '/assets/img/soterLogoWhite.svg' : '/assets/img/soterLogo.svg'}
          alt="company logotype"
        />
      </Link>
    </div>
  );
};

export default Logo;
