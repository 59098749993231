import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _each from 'lodash/each';
import { COLORS } from 'constants/colors';
import { hazardNameColorMap } from '../../../overview/constants/hazardNameColorMap';
import { hazardsEnum } from '../../../overview/constants/hazardsEnum';

export default function (overallHazards, deviceType) {
  const hazardsColors
    = deviceType !== 'armband'
      ? hazardNameColorMap.spine
      : hazardNameColorMap.shoulder;
  const hazards
    = deviceType !== 'armband' ? hazardsEnum.spine : hazardsEnum.shoulder;
  const hazardKeys = Object.keys(hazards);
  const overallHazardsForCurrentType = {};
  _each(overallHazards, (val, key) => {
    if (hazardKeys.includes(key)) overallHazardsForCurrentType[key] = val;
  });
  const sum = _reduce(overallHazardsForCurrentType, (acc, val) => acc + val, 0);
  const overall = sum;
  const chartData
    = sum === 0
      ? [{ angle: 360, fill: COLORS.GRAY[400] }]
      : _map(overallHazardsForCurrentType, (val, key) => {
        const angle = val * (360 / sum);
        const fill = hazardsColors.find(({ alias }) => alias === key)?.color;
        return { angle, fill };
      });
  return {
    overall,
    chartData,
    overallHazardsForCurrentType,
    hazardsColors,
  };
}
