import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import Button from 'lib/ui/Button';
import Text from 'lib/ui/Text';
import { ReactComponent as Star } from 'assets/img/star.svg';
import { setFeedback } from '../userStore/actions';
import { toCamel } from 'snake-camel';

const reasonTitleStyle = Emotion.css`
  margin: 20px 0 15px;
`;

const reasonsStyle = Emotion.css`
  display: flex;
  justify-content: space-between;
`;

const reasonStyle = Emotion.css`
  display: flex;
  align-items: center;
`;

const labelStyle = Emotion.css`
  padding: 0 0 0 10px;
  margin: 0;
  cursor: pointer;
`;

const warningMessageStyle = (warning, description, reason) => Emotion.css`
  opacity: ${warning && reason !== 'Compliment' ? '1' : '0'} ;
  color: #B91924 !important;
  margin-top: ${description ? '0' : '5px'} ;
  margin-bottom: ${description ? '20px' : '0'} ;
`;

const descriptionTitleStyle = Emotion.css`
  margin: 25px 0 15px;
`;

const descriptionStyle = (warning, reason) => Emotion.css`
  height: 85px;
  border: 1px solid ${warning && reason !== 'Compliment' ? '#B91924' : '#E0E5EC'}; 
  border-radius: 3px;
  width: 100%;
  resize: none;
  &:focus {
    outline: 0;
  }
`;

const characterBarHolderStyle = Emotion.css`
  background: #D9D9D9;
  height: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
`;

const characterBarStyle = (description, maxCharacter) => Emotion.css`
  background: #484848;
  height: 100%;
  width: ${(description.length / maxCharacter) * 100}%;
  border-radius: 5px;
`;

const characterCountStyle = (description, maxCharacter) => Emotion.css`
  color: ${description.length === maxCharacter && '#B91924'};
`;

const ratingTitleStyle = Emotion.css`
  margin: 10px 0 10px;
`;

const starsStyle = Emotion.css`
  display: flex;
  justify-content: center;
`;

const starStyle = (star) => Emotion.css`
  margin-right: ${star !== 5 ? '20px' : '0'};
  cursor: pointer;
`;

const buttonStyle = Emotion.css`
  width: 100%;
  justify-content: center;
  margin-top: 26px;
`;

const FeedbackDialog = ({ id, closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [rating, setRating] = useState(0);
  const [showReasonMessage, setShowReasonMessage] = useState(false);
  const [showDescriptionMessage, setShowDescriptionMessage] = useState(false);
  const [showRatingMessage, setShowRatingMessage] = useState(false);

  const reasons = ['Suggestion', 'Other', 'Compliment'];
  const stars = [1, 2, 3, 4, 5];
  const maxCharacter = 500;

  const sendFeedbackAndCloseModal = () => {
    dispatch(setFeedback(id, reason, description, +rating));
    closeModal();
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (rating && reason === 'Compliment') {
      sendFeedbackAndCloseModal();
    } else if (rating && reason && description) {
      sendFeedbackAndCloseModal();
    } else {
      !rating && setShowRatingMessage(true);
      !reason && setShowReasonMessage(true);
      !description && setShowDescriptionMessage(true);
      reason === 'Compliment' && setShowDescriptionMessage(false);
    }
  };

  const reasonHandler = (e) => {
    setReason(e.currentTarget.value);
    setShowReasonMessage(false);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
    setShowDescriptionMessage(false);
  };

  const ratingHandler = (e) => {
    setRating(e.currentTarget.id);
    setShowRatingMessage(false);
  };

  return (
    <form onSubmit={(e) => formSubmitHandler(e)}>
      <Text appearance="headlineTwo" weight="bold" text={t('FEEDBACK.TITLE')} />
      <Text appearance="title" weight="bold" text={t('FEEDBACK.REASON')} className={reasonTitleStyle} />
      <div className={reasonsStyle}>
        {reasons.map((reason) => (
          <div key={reason} className={reasonStyle}>
            <input className={Emotion.css`cursor: pointer`} value={reason} type="radio" id={reason} name="reason" onChange={(e) => reasonHandler(e)} />
            <label className={labelStyle} htmlFor={reason}>{t(`FEEDBACK.${reason.toUpperCase()}`)}</label>
          </div>
        ))}
      </div>
      <Text appearance="caption" text={t('FEEDBACK.WARNING.REASON')} className={warningMessageStyle(showReasonMessage)} />
      <Text appearance="title" weight="bold" text={t('FEEDBACK.DESCRIPTION')} className={descriptionTitleStyle} />
      <textarea value={description} maxLength={maxCharacter} z onChange={(e) => descriptionHandler(e)} className={descriptionStyle(showDescriptionMessage, reason)} placeholder={t('FEEDBACK.PLACEHOLDER')} />
      {showDescriptionMessage && reason !== 'Compliment'
        ? <Text appearance="caption" text={t('FEEDBACK.WARNING.DESCRIPTION')} style={{ marginTop: 0 }} className={warningMessageStyle(showDescriptionMessage, 'description', reason)} />
        : (
          <>
            <div className={characterBarHolderStyle}>
              <div className={characterBarStyle(description, maxCharacter)} />
            </div>
            <Text appearance="caption" text={`${description.length}/500`} className={characterCountStyle(description, maxCharacter)} />
          </>
        )}
      <Text appearance="title" weight="bold" text={t('FEEDBACK.RATING')} className={ratingTitleStyle} />
      <div className={starsStyle}>
        {stars.map((star) => <Star id={star} key={star} className={starStyle(star)} onClick={(e) => ratingHandler(e)} fill={star > rating ? '#EFF0F6' : '#FFD029'} stroke={showRatingMessage && '#B91924'} />)}
      </div>
      <Text appearance="caption" text={t('FEEDBACK.WARNING.RATING')} className={warningMessageStyle(showRatingMessage)} />
      <Button type="submit" text={t('FEEDBACK.SEND')} className={buttonStyle} />
    </form>
  );
};

export default FeedbackDialog;
