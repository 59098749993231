import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'lib/ui/Button';
import { COLORS } from '../../constants/colors';
import { coloredButton, mb8, mt4 } from '../../assets/soter-flex-styles';

const VideoLimitDialog = ({ contactHandler }) => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center' }}>
      <h4 className={`${mb8} ${mt4}`}>
        {t('SOTER_TASK.VIDEO_LIMIT_DIALOG.HEADER')}
      </h4>
      <div>{t('SOTER_TASK.VIDEO_LIMIT_DIALOG.TEXT1')}</div>
      <div className={mb8}>{t('SOTER_TASK.OVERVIEW.UPGRADE')}</div>
      <Button
        style={{ width: '90%', margin: '18px auto', display: 'block' }}
        handler={contactHandler}
        className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
        text={t('SOTER_TASK.VIDEO_LIMIT_DIALOG.BUTTON')}
      />
    </div>
  );
};

export default VideoLimitDialog;
