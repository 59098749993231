import React from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import Text from 'lib/ui/Text';
import Button from 'lib/ui/Button';
import { COLORS } from 'constants/colors';

const modalStyle = (deleteOptionTopAndRight) => {
  const [optionTop, optionRight] = deleteOptionTopAndRight;
  return Emotion.css`
    width: 160px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06),
      0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 16px 20px;
    position: absolute;
    z-index: 2147483647;
    top: ${optionTop + 20}px;
    left: ${optionRight - 10}px;
  `;
};

const buttonHolderStyle = Emotion.css`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const buttonStyle = Emotion.css`
  background-color: ${COLORS.RED[600]} !important;
`;

const DeleteModal = ({
  deleteRowId,
  deleteOptionTopAndRight,
  setRebaWeights,
  setShowDeleteModal,
  rebaWeights,
  setIsButtonDisabled,
}) => {
  const { t } = useTranslation();

  const deleteRow = (y) => {
    const initialHeight = 48;
    const distance = 40;
    const updatedRebaWeights = rebaWeights.filter((weight) => weight.y !== y);
    updatedRebaWeights.forEach((weight, i) => {
      updatedRebaWeights[i].y = initialHeight + (i * distance);
    });

    setRebaWeights(updatedRebaWeights);
    setShowDeleteModal(false);
    setIsButtonDisabled(false);
  };

  return (
    <div
      onMouseLeave={() => setShowDeleteModal(false)}
      className={modalStyle(deleteOptionTopAndRight)}
    >
      <Text
        text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.DELETE_ROW')}
        weight="bold"
      />
      <div className={buttonHolderStyle}>
        <Button
          handler={() => deleteRow(deleteRowId)}
          text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.DELETE')}
          className={buttonStyle}
        />
      </div>
    </div>
  );
};

export default DeleteModal;
