import { COLORS, ACTIVE_USERS_CHART, HAZARDS_COLORS } from 'constants/colors';
const gradients = [
    {
        hazard: 'intensity',
        leftStopColor: HAZARDS_COLORS.SPINE.INTENSE_BENDING[600],
        rightStopColor: HAZARDS_COLORS.SPINE.INTENSE_BENDING[600],
    },
    {
        hazard: 'repetition',
        leftStopColor: HAZARDS_COLORS.SPINE.REPETITION[600],
        rightStopColor: HAZARDS_COLORS.SPINE.REPETITION[600],
    },
    {
        hazard: 'lifting',
        leftStopColor: HAZARDS_COLORS.SPINE.POOR_BENDING[600],
        rightStopColor: HAZARDS_COLORS.SPINE.POOR_BENDING[600],
    },
    {
        hazard: 'twisting',
        leftStopColor: HAZARDS_COLORS.SPINE.BACK_TWISTING[600],
        rightStopColor: HAZARDS_COLORS.SPINE.BACK_TWISTING[600],
    },
    {
        hazard: 'pbStatic',
        leftStopColor: HAZARDS_COLORS.SPINE.AWKWARD_STATIC_POSTURE[600],
        rightStopColor: HAZARDS_COLORS.SPINE.AWKWARD_STATIC_POSTURE[600],
    },
    {
        hazard: 'overexertion',
        leftStopColor: HAZARDS_COLORS.SHOULDER.OVEREXERTION[600],
        rightStopColor: HAZARDS_COLORS.SHOULDER.OVEREXERTION[600],
    },
    {
        hazard: 'repetitiveArmMovements',
        leftStopColor: HAZARDS_COLORS.SHOULDER.REPETITIVE_ARM_MOVEMENTS[600],
        rightStopColor: HAZARDS_COLORS.SHOULDER.REPETITIVE_ARM_MOVEMENTS[600],
    },
    {
        hazard: 'armElevation',
        leftStopColor: HAZARDS_COLORS.SHOULDER.ARM_ELEVATION[600],
        rightStopColor: HAZARDS_COLORS.SHOULDER.ARM_ELEVATION[600],
    },
    {
        hazard: 'empty',
        leftStopColor: '#e5e5ea',
        rightStopColor: '#e5e5ea',
    },
    {
        hazard: 'pushingPulling',
        leftStopColor: HAZARDS_COLORS.SHOULDER.PUSHING_AND_PULLING[600],
        rightStopColor: HAZARDS_COLORS.SHOULDER.PUSHING_AND_PULLING[600],
    },
    {
        hazard: 'staticArmElevation',
        leftStopColor: HAZARDS_COLORS.SHOULDER.STATIC_ARM_ELEVATION[600],
        rightStopColor: HAZARDS_COLORS.SHOULDER.STATIC_ARM_ELEVATION[600],
    },
    {
        hazard: 'noHazardousMovements',
        leftStopColor: '#E0E5EC',
        rightStopColor: '#E0E5EC',
    },
    { hazard: '0-30', leftStopColor: COLORS.GREEN[200], rightStopColor: COLORS.GREEN[200] },
    { hazard: '30-60', leftStopColor: '#46AFFB', rightStopColor: '#46AFFB' },
    { hazard: '60-90', leftStopColor: '#FFD786', rightStopColor: '#FFD786' },
    { hazard: '>90', leftStopColor: '#F94957', rightStopColor: '#F94957' },
    {
        hazard: 'spineUsers',
        leftStopColor: ACTIVE_USERS_CHART[0],
        rightStopColor: ACTIVE_USERS_CHART[0],
    },
    {
        hazard: 'shoulderUsers',
        leftStopColor: ACTIVE_USERS_CHART[1],
        rightStopColor: ACTIVE_USERS_CHART[1],
    },
    {
        hazard: 'helmetUsers',
        leftStopColor: ACTIVE_USERS_CHART[2],
        rightStopColor: ACTIVE_USERS_CHART[2],
    },
    {
        hazard: 'headsetUsers',
        leftStopColor: ACTIVE_USERS_CHART[3],
        rightStopColor: ACTIVE_USERS_CHART[3],
    },
    {
        hazard: 'usersCount',
        leftStopColor: '#a60e48',
        rightStopColor: '#a60e48',
    },
    {
        hazard: 'negativeFeedbackCount',
        leftStopColor: COLORS.RED[600],
        rightStopColor: COLORS.RED[600],
    },
    {
        hazard: 'neutralFeedbackCount',
        leftStopColor: COLORS.BLUE[900],
        rightStopColor: COLORS.BLUE[900],
    },
    {
        hazard: 'positiveFeedbackCount',
        leftStopColor: COLORS.GREEN[600],
        rightStopColor: COLORS.GREEN[600],
    },
];
export default gradients;
