const downloadCSV = (data, title) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    // @ts-ignore
    link.setAttribute('href', url);
    link.setAttribute('download', `${title}.csv`);
    link.click();
};
export default downloadCSV;
