export const NO_DEPARTMENTS = 'MY_COMPANY.USERS.NO_DEPARTMENTS';
export const NO_SITES = 'MY_COMPANY.USERS.NO_SITES';
export const ALL_DEPARTMENTS = 'MY_COMPANY.USERS.ALL_DEPARTMENTS';
export const ALL_SITES = 'MY_COMPANY.USERS.ALL_SITES';

export const initialUserValues = {
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  password: '',
  isAdmin: false,
  resellerId: [],
  isManager: false,
  sendInvite: false,
  getInvite: false,
  companies: [],
  receiveNeedHelpRequests: false,
  canInviteUsers: false,
};

export const texts = {
  addNewUser: {
    title: 'MY_COMPANY.USERS.DATA',
    buttonText: 'MY_COMPANY.USERS.CREATE_NEW',
  },
  inviteNewUser: {
    title: 'MY_COMPANY.USERS.INVITE',
    buttonText: 'MY_COMPANY.USERS.INVITE',
  },
  editUser: {
    title: 'MY_COMPANY.USERS.EDIT',
    buttonText: 'MY_COMPANY.USERS.UPDATE',
  },
  myCompanyEditUser: {
    title: 'MY_COMPANY.USERS.EDIT',
    buttonText: 'MY_COMPANY.USERS.UPDATE',
  },
};
