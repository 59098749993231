import React from 'react';

const coachSubHeader = (t) => (
  <>
    {t('WEARABLES.PREVIEW.SUB_HEADER_1')}
    {' '}
    {t('WEARABLES.PREVIEW.SUB_HEADER_2')}
  </>
);
const coachAdvantages = (t) => (
  <>
    <li style={{ marginBottom: '24px' }}>
      {t('WEARABLES.PREVIEW.ADVANTAGES_1')}
    </li>
    <li style={{ marginBottom: '24px' }}>
      {t('WEARABLES.PREVIEW.ADVANTAGES_2')}
    </li>
    <li>
      {t('WEARABLES.PREVIEW.ADVANTAGES_3')}
    </li>
  </>
);

const dictionarySubHeader = (t) => (
  <>
    {t('JOB_DICTIONARY.PREVIEW.SUB_HEADER_1')}
    {' '}
    {t('JOB_DICTIONARY.PREVIEW.SUB_HEADER_2')}
  </>
);
const dictionaryAdvantages = (t) => (
  <>
    <li style={{ marginBottom: '24px' }}>
      {t('JOB_DICTIONARY.PREVIEW.ADVANTAGES_1')}
    </li>
    <li style={{ marginBottom: '24px' }}>
      {t('JOB_DICTIONARY.PREVIEW.ADVANTAGES_2')}
    </li>
    <li>
      {t('JOB_DICTIONARY.PREVIEW.ADVANTAGES_3')}
    </li>
  </>
);

const geniusAdvantages = (t) => (
  <>
    <li style={{ marginBottom: '18px' }}>
      {t('SOTER_GENIUS.PREVIEW.ADVANTAGES_1')}
    </li>
    <li style={{ marginBottom: '18px' }}>
      {t('SOTER_GENIUS.PREVIEW.ADVANTAGES_2')}
    </li>
    <li style={{ marginBottom: '18px' }}>
      {t('SOTER_GENIUS.PREVIEW.ADVANTAGES_3')}
    </li>
    <li>
      {t('SOTER_GENIUS.PREVIEW.ADVANTAGES_4')}
    </li>
  </>
);

export const productPreview = {
  GeniusPreviewProduct: (t) => ({
    header: 'SoterGenius',
    subHeader: t('SOTER_GENIUS.PREVIEW.SUB_HEADER'),
    description: t('SOTER_GENIUS.PREVIEW.DESCRIPTION'),
    advantages: geniusAdvantages(t),
    requested: t('SOTER_GENIUS.PREVIEW.DEMO_REQUESTED'),
    c2a: t('SOTER_GENIUS.PREVIEW.REQUEST_A_DEMO'),
    video: 'https://www.youtube.com/watch?v=xl33-qL-XN8'
  }),
  CoachPreviewProduct: (t) => ({
    header: 'SoterCoach',
    subHeader: coachSubHeader(t),
    description: t('WEARABLES.PREVIEW.DESCRIPTION_NEW'),
    advantages: coachAdvantages(t),
    requested: t('SOTER_GENIUS.PREVIEW.DEMO_REQUESTED'),
    c2a: t('SOTER_GENIUS.PREVIEW.REQUEST_A_DEMO'),
    video: 'https://www.youtube.com/watch?v=BPqNFpyu9kE',
  }),
  JobDictionaryPreviewProduct: (t) => ({
    header: 'Job Dictionary',
    subHeader: dictionarySubHeader(t),
    description: t('JOB_DICTIONARY.PREVIEW.DESCRIPTION_1') + t('JOB_DICTIONARY.PREVIEW.DESCRIPTION_2'),
    advantages: dictionaryAdvantages(t),
    requested: t('SOTER_GENIUS.PREVIEW.DEMO_REQUESTED'),
    c2a: t('SOTER_GENIUS.PREVIEW.REQUEST_A_DEMO'),
    video: '',
  }),
};
