import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { assessmentTableTitle, rebaRiskText, riskText, text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { dataSplitting } from '../utils/dataSplittingChunk';
import prepareChartAnglesData from '../utils/prepareChartAnglesData';
import prepareChartAxisData from '../utils/prepareChartAxisData';
import KonvaChart from './components/KonvaChart';
import DonutChartWithLegendForReba from './components/DonutChartWithLegendForReba';
import DonutChartWithLegend from './components/DonutChartWithLegend';
import { getRiskText } from '../../constants/getRiskGroupText';
import SinglePage from '../../containers/PdfContainer/SinglePage';
import { SINGLE_PAGE_TYPE } from '../../containers/PdfContainer/constants';
import { TIME_DISTRIBUTION_BAR_CHART_HEIGHT, TIME_DISTRIBUTION_BAR_CHART_WIDTH } from './constants';
import BodyPartsRiskScore from '../components/BodyPartsRiskScore';
import BodyPartRiskDistribution from '../components/BodyPartsRiskDistribution';
import AssessmentsReportList from '../components/AssessmentsReportList';
import SecondaryHeader from '../../containers/PdfContainer/components/SecondaryHeader';
import calculateRebaRiskLevel from '../utils/calculateRebaRiskLevel';

const border = Emotion.css`
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  margin-bottom: 25px;
`;

const SoterTaskVideoPdfLayer = ({
  chartAnglesData,
  chartAxisData,
  currentThreshold,
  bodyParts,
  title,
  videoWidth,
  chartHeight,
  description,
  currentVideo,
  risksForHumanLabel,
  risksForBarChart,
  movementAndRebaData,
  thresholds,
}) => {
  const { t } = useTranslation();
  const VideoAssessmentsData = useSelector(
    (state) => state.soterTaskAssessments.assessmentsList,
  );
  const { maxRebaScore } = currentVideo;
  const rebaRisk = calculateRebaRiskLevel(maxRebaScore, t);
  const assessmentsData = dataSplitting(VideoAssessmentsData, 6);
  const Title = ({ pageTitle }) => (
    <h3 style={{ marginBottom: '7px' }} className={text(COLORS.GRAY[950], 32, 500)}>
      {pageTitle}
    </h3>
  );
  const SubTitle = ({ subtitle }) => (
    <h4
      style={{ marginBottom: '14px' }}
      className={text(COLORS.GRAY[950], 20, 500)}
    >
      {subtitle}
    </h4>
  );
  const ChartsWrapper = ({ currentBodyPart }) => {
    chartAnglesData = prepareChartAnglesData(
      movementAndRebaData,
      thresholds,
      videoWidth,
      chartHeight,
      currentBodyPart,
    );

    chartAxisData = prepareChartAxisData(
      movementAndRebaData,
      videoWidth,
      currentBodyPart,
    );

    return (
      <div style={{ display: 'block', marginBottom: '44px' }}>
        <KonvaChart
          progressPosition={-10}
          anglesData={chartAnglesData[currentBodyPart]}
          axisData={chartAxisData}
          scale={0.82}
          videoWidth={videoWidth}
          chartHeight={chartHeight}
        />

        {currentBodyPart === 'reba' ? (
          <DonutChartWithLegendForReba
            chartAnglesData={chartAnglesData}
            selectedBodyPart={currentBodyPart}
            currentThreshold={currentThreshold}
            scale={0.77}
          />
        ) : (
          <DonutChartWithLegend
            chartAnglesData={chartAnglesData}
            selectedBodyPart={currentBodyPart}
            currentThreshold={currentThreshold}
            scale={0.77}
          />
        )}
      </div>
    );
  };

  const CommonInfo = () => (
    <div>
      <div
        style={{
          fontSize: '14px',
          display: 'grid',
          gridTemplateColumns: '27% 38% 25%',
        }}
      >
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <div className={assessmentTableTitle}>
            {`${t('WEARABLES.USER_PAGE.RISK_GROUP')}:`}
          </div>
          <span className={riskText(currentVideo.risks.overallRisk)}>
            {t(getRiskText(currentVideo.risks.overallRisk))}
          </span>
        </div>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <div className={assessmentTableTitle}>
            {`${t('SOTER_TASK.MAX_REBA_SCORE')}:`}
          </div>
          <span className={rebaRiskText(rebaRisk[1])}>
            {`${maxRebaScore} - ${rebaRisk[0]}`}
          </span>
        </div>

        {currentVideo.duration > 0 && (
          <div
            style={{
              display: 'flex',
              marginBottom: '15px',
            }}
          >
            <div className={assessmentTableTitle}>
              {`${t(
                'SOTER_TASK.VIDEOS_LIST.DURATION',
              )}: `}
            </div>
            <div>
              {Number(Math.ceil(Math.abs(currentVideo.duration / 1000)))}
            </div>
          </div>
        )}

        {currentVideo.taskName?.title && (
          <div
            style={{
              display: 'flex',
              marginBottom: '15px',
            }}
          >
            <div className={assessmentTableTitle}>
              {`${t(
                'SOTER_TASK.VIDEOS_LIST.TASK_NAME',
              )}: `}
            </div>
            <div>{currentVideo.taskName?.title || t('GENERAL.NA')}</div>
          </div>
        )}

        <div>
          <div style={{ display: 'flex', marginBottom: '15px' }}>
            <div className={assessmentTableTitle}>
              {`${t(
                'SOTER_TASK.VIDEOS_LIST.DATE',
              )}: `}
            </div>
            <div>
              {moment(currentVideo.startUploadingTime).format('D MMM YYYY')}
            </div>
          </div>
        </div>

        {currentVideo.repetition > 0 && (
          <div
            style={{
              display: 'flex',
              marginBottom: '15px',
            }}
          >
            <div className={assessmentTableTitle}>
              {`${t(
                'WEARABLES.HAZARDS.REPETITION',
              )}: `}
            </div>
            <div>{currentVideo.repetition}</div>
          </div>
        )}

        {currentVideo.jobRole?.title && (
          <div
            style={{
              display: 'flex',
              marginBottom: '15px',
            }}
          >
            <div className={assessmentTableTitle}>
              {`${t(
                'SOTER_TASK.VIDEOS_LIST.JOB_ROLE',
              )}: `}
            </div>
            <div>{currentVideo.jobRole?.title || t('GENERAL.NA')}</div>
          </div>
        )}

        {currentVideo.createdBy?.name && (
          <div style={{ display: 'flex', marginBottom: '15px' }}>
            <div className={assessmentTableTitle}>
              {`${t(
                'MY_COMPANY.USERS.CREATED_BY',
              )}: `}
            </div>
            <div>{currentVideo.createdBy?.name || t('GENERAL.NA')}</div>
          </div>
        )}
      </div>
      <div style={{ width: '680px' }} className={border} />
    </div>
  );

  return (
    <div
      id="soter_task_report"
      style={{
        display: 'none',
      }}
    >
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <h1>SoterTask</h1>
        <div style={{ width: '680px' }} className={border} />
        <Title pageTitle={title} />
        <CommonInfo />
        <Title pageTitle={t('NAVBAR.OVERVIEW')} />
        <div style={{ marginLeft: '25%', marginTop: '36px' }}>
          <BodyPartsRiskScore risks={risksForHumanLabel} forReport />
        </div>
        <div style={{ marginLeft: '-46px' }}>
          <BodyPartRiskDistribution
            gridArea="risks"
            risks={risksForBarChart}
            width={700}
            barHeight={TIME_DISTRIBUTION_BAR_CHART_HEIGHT}
            barWidth={TIME_DISTRIBUTION_BAR_CHART_WIDTH}
          />
        </div>
      </SinglePage>
      <AssessmentsReportList
        assessmentsData={assessmentsData}
        title={title}
        withHeader={t('SOTER_TASK.ASSESSMENTS')}
      />
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={title} type="SoterTask" />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.BACK')} />
        <ChartsWrapper currentBodyPart={bodyParts[0]} />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.NECK')} />
        <ChartsWrapper currentBodyPart={bodyParts[1]} />
      </SinglePage>
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={title} type="SoterTask" />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.LEFT_ARM')} />
        <ChartsWrapper currentBodyPart={bodyParts[2]} />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.RIGHT_ARM')} />
        <ChartsWrapper currentBodyPart={bodyParts[3]} />
      </SinglePage>
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={title} type="SoterTask" />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.LEFT_LEG')} />
        <ChartsWrapper currentBodyPart={bodyParts[4]} />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.RIGHT_LEG')} />
        <ChartsWrapper currentBodyPart={bodyParts[5]} />
      </SinglePage>
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={title} type="SoterTask" />
        <SubTitle subtitle={t('SOTER_TASK.BODY_PARTS.REBA')} />
        <ChartsWrapper currentBodyPart={bodyParts[6]} />
      </SinglePage>
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={title} type="SoterTask" />
        <Title />
        <div
          style={{ marginBottom: '7px' }}
          className={text(COLORS.GRAY[950], 18, 600)}
        >
          {t('GENERAL.DESCRIPTION')}
        </div>
        <div className={text(COLORS.GRAY[950])}>{description}</div>
      </SinglePage>
    </div>
  );
};

export default SoterTaskVideoPdfLayer;
