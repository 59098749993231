import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  profile: {},
  soterTaskVideos: [],
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.TASK_PROFILE_SET:
      // eslint-disable-next-line no-case-declarations
      const { profile } = action;
      return state.merge({
        profile,
        error: null,
      });
    case types.TASK_PROFILE_VIDEOS_SET:
      // eslint-disable-next-line no-case-declarations
      const { soterTaskVideos } = action;
      return state.merge({
        soterTaskVideos,
        error: null,
      });
    case types.TASK_PROFILE_ERROR:
      // eslint-disable-next-line no-case-declarations
      const { error } = action;
      return state.merge({ error });
    case types.TASK_PROFILE_RESET:
      return initialState;
    default: return state;
  }
}
