import { navigate } from '@reach/router';
import { reportTypeNames, reportFormatNames } from '../constants';
import { setVideoMetadata } from '../../../profile/profileStore/actions';
// Here we convert the video data to format acceptable by the AI backend
export default function redirectToGeniusWithMetadata(metadata, dispatch) {
    const { processedVideoUrl, reportType, reportFormat, videoId, companyId, videoTitle, taskName, } = metadata;
    const language = localStorage.getItem('language') || 'en';
    // Preparing values for AI
    const reportTypeName = reportTypeNames[reportType] && reportTypeNames[reportType][0]
        .toLowerCase().replaceAll(' ', '_');
    const reportFormatName = reportFormatNames[reportFormat] && reportFormatNames[reportFormat][0]
        .toLowerCase().replaceAll(' ', '_').replaceAll('-', '_');
    dispatch(setVideoMetadata({
        processedVideoUrl,
        videoId: videoId.toString(),
        videoTitle,
        reportType: reportTypeName,
        reportFormat: reportFormatName,
        language,
        taskName: (taskName === null || taskName === void 0 ? void 0 : taskName.title) || '',
    }));
    navigate(`/${companyId}/users/soter-genius/chat`);
}
