import * as Emotion from 'emotion';

export const mt1 = Emotion.css`
  margin-top: 0.25rem;
`;
export const ml1 = Emotion.css`
  margin-left: 0.25rem;
`;
export const zmr1 = Emotion.css`
  margin-right: 0.25rem;
`;
export const mb1 = Emotion.css`
  margin-bottom: 0.25rem;
`;

export const mt2 = Emotion.css`
  margin-top: 0.5rem;
`;
export const ml2 = Emotion.css`
  margin-left: 0.5rem;
`;
export const mr2 = Emotion.css`
  margin-right: 0.5rem;
`;
export const mb2 = Emotion.css`
  margin-bottom: 0.5rem;
`;

export const mt3 = Emotion.css`
  margin-top: 0.75rem;
`;
export const ml3 = Emotion.css`
  margin-left: 0.75rem;
`;
export const mr3 = Emotion.css`
  margin-right: 0.75rem;
`;
export const mb3 = Emotion.css`
  margin-bottom: 0.75rem;
`;

export const mt4 = Emotion.css`
  margin-top: 1rem;
`;
export const ml4 = Emotion.css`
  margin-left: 1rem;
`;
export const mr4 = Emotion.css`
  margin-right: 1rem;
`;
export const mb4 = Emotion.css`
  margin-bottom: 1rem;
`;

export const mt5 = Emotion.css`
  margin-top: 1.5rem;
`;
export const ml5 = Emotion.css`
  margin-left: 1.5rem;
`;
export const mr5 = Emotion.css`
  margin-right: 1.5rem;
`;
export const mb5 = Emotion.css`
  margin-bottom: 1.5rem;
`;

export const mt6 = Emotion.css`
  margin-top: 2rem;
`;
export const mb6 = Emotion.css`
  margin-bottom: 2rem;
`;

export const mb7 = Emotion.css`
  margin-bottom: 2.5rem;
`;

export const mb8 = Emotion.css`
  margin-bottom: 3rem;
`;

export const mt8 = Emotion.css`
  margin-top: 3rem;
`;
