import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';

export const printableWrapper = Emotion.css`
  @media print {
    padding: 0 40px;
  }
`;

export const printableQR = Emotion.css`
  @media print {
    width: 3cm;
    height: 3cm;
  }
`;

export const printableQRText = Emotion.css`
  @media print {
    text-align: center;
    width: 6cm;
    height: 4cm;
    margin-top: 1.5cm;
  }
`;

export const printableQRArea = Emotion.css`
  @media print {
    width: 6cm;
    height: 7cm;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 1.45cm auto;
    padding-top: 0.8em;
    border: 1px solid ${COLORS.GRAY[950]};
  }
`;
