import { COLORS } from 'constants/colors'
import * as Emotion from 'emotion';
import { closeButton } from './button-elements';
import { container } from './page-container-element';

export const notificationContainer = Emotion.css`
 ${container};
 
 padding: 0 1.5rem;
 position: absolute;
 left: 50%;
 bottom: 1rem;
 transform: translate(-50.3%,0);
 z-index: 5000;
`;

const notification = Emotion.css`
  box-sizing: border-box;
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 3px;
  opacity: 1;
`;

const notificationAnimation = Emotion.css`
  @keyframes fade {
    0%{
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fade 3000ms ease-in-out;
`;

const eventNotificationAnimation = Emotion.css`
  @keyframes fade {
    0%{
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fade 10000ms ease-in-out;
`;

export const coloredNotification = (color) => Emotion.css`
  ${notification};

  color: ${COLORS.GRAY[950]};
  background-color: ${color};
  border-color: ${COLORS.GRAY[950]};
  ${notificationAnimation};
`;

export const successNotification = Emotion.css`
  ${notification};
  
  color: #316100;
  background-color: #dff1cc;
  border-color: #d2ecb8;
  ${notificationAnimation};
`;

export const eventNotification = Emotion.css`
  ${notification};
  
  color: #316100;
  background-color: #dff1cc;
  border-color: #d2ecb8;
  ${eventNotificationAnimation};
`;

export const successUploadNotification = Emotion.css`
  ${notification};
  width: 100%;
  color: #316100;
  background-color: #dff1cc;
  border-color: #d2ecb8;
`;

export const failNotification = Emotion.css`
  ${notification};
  
  color: #6b1110;
  background-color: #f5d2d2;
  border-color: #f1c1c0;
  ${notificationAnimation};
`;

export const failUploadNotification = Emotion.css`
  ${notification};
  width: 100%;
  color: #6b1110;
  background-color: #f5d2d2;
  border-color: #f1c1c0;
`;

export const warningNotification = Emotion.css`
  ${notification};
  
  color: #7d6608;
  background-color: #fcf3cf;
  border-color: #fbeebc;
  ${notificationAnimation};
`;

export const warningUploadNotification = Emotion.css`
  ${notification};
  width: 100%;
  color: #7d6608;
  background-color: #fcf3cf;
  border-color: #fbeebc;
`;

export const notificationText = Emotion.css`
  flex: 1 0 50%
`;

const closeNotificationButton = Emotion.css`
  ${closeButton};
  width: 1rem;
  height: 1rem;
`;

export const successNotificationClose = Emotion.css`
  ${closeNotificationButton};
  
  color: #316100;
  background-color: #dff1cc;
  background-image: url('/assets/img/icons/notifications/successClose.svg');
`;

export const failNotificationClose = Emotion.css`
  ${closeNotificationButton};
  
  color: #6b1110;
  background-color: #f5d2d2;
  background-image: url('/assets/img/icons/notifications/failClose.svg');
`;

export const warningNotificationClose = Emotion.css`
 ${closeNotificationButton};
 
  color: #7d6608;
  background-color: #fcf3cf;
  background-image: url('/assets/img/icons/notifications/warningClose.svg');
`;
