import { bodyPartNames, bodyPartNamesHackMap } from '../video/constants';

const bodyParts = Object.keys(bodyPartNames);

const prepareRisksForHumanLabels = (risksFromServer) => {
  if (!risksFromServer) return null;
  const risksMap = {};
  bodyParts.forEach((bodyPart) => {
    const bodyPartFormList = bodyPartNamesHackMap[bodyPart];
    const scoreKey = Object.keys(risksFromServer).find((val) => val.includes(bodyPartFormList) && val.includes('Risk'));
    risksMap[bodyPart] = risksFromServer[scoreKey];
  });
  return risksMap;
};

export default prepareRisksForHumanLabels;
