import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp, registerVersion } from 'firebase/app';
import 'rsuite/dist/rsuite.min.css';
import 'assets/css/style.css';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { TextDecoder } from 'text-encoding';
import { navigate, Redirect, Router } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { adjustRouteWithSelectedCompany } from './utils/adjustRouteWithSelectedCompany';
import { useAppDispatch, useAppSelector } from '../lib/utils/useStore';
import { getTextForMoment } from './utils/getTextForMoment';
import packageJson from '../../package.json';
import { getCategories } from '../company/categories/store/actions';
import { authMe, getUserResource } from '../auth/store/actions';
import { navigationLevels, noCompanyIDRoutes, routes } from '../containers/PrivatPageHOC/constants/routes';
import { setAnonymous } from '../auth/store/authSlice';
import { getCompanies } from '../company/companiesStore/actions';
import { getFirstAvailableProductRoute } from './utils/availableProduct';
import LoginPage from '../auth/LoginPage';
import SingleSignOn from '../auth/SSO';
import ForgotPasswordPage from '../auth/ForgotPasswordPage';
import SetPasswordPage from '../auth/SetPasswordPage';
import OAuthRedirectWithToken from '../auth/OAuthRedirectWithToken';
import ConfirmEmailPage from '../auth/ConfirmEmailPage';
import PrivateRoute from './components/PrivateRoute';
import Overview from '../wearables/overview/Overview';
import Devices from '../wearables/devices/Devices';
import ActivityPage from '../wearables/activity/Activity';
import UploadUsers from '../wearables/activity/UsersUpload/UploadUsers';
import HelpPage from '../wearables/action/Help';
import GlobalUserPage from '../wearables/user/GlobalUserPage';
import JobDictionary from '../dictionary/dictionaryList/JobDictionary';
import JobProfile from '../dictionary/jobProfile/JobProfile';
import TaskProfile from '../dictionary/taskProfile/TaskProfile';
import UserProfile from '../profile/UserProfile';
import SoterTaskContainer from '../task/gallery/SoterTaskContainer';
import SoterTaskVideo from '../task/video/SoterTaskVideo';
import AddRebaWeight from '../task/reba/AddRebaWeight';
import SoterTaskComparison from '../task/comparison/SoterTaskComparison';
import SoterTaskOverview from '../task/overview/SoterTaskOverview';
import CompanyUsersPage from '../company/users/CompanyUsersPage';
import CompanyCategoriesPage from '../company/categories/CompanyCategoriesPage';
import CompanyTagsPage from '../company/categories/entries/CompanyTagsPage';
import NotFoundPage from './components/NotFound';
import Companies from '../admin/companies/Companies';
import Company from '../admin/companies/company/Company';
import DashboardUsers from '../admin/users/DashboardUsers';
import AdminDevicesContainer from '../admin/devices/AdminDevicesContainer';
import Chat from '../chat/Chat';
import Hubs from '../admin/hubs/Hubs';
import Feedbacks from '../admin/feedbacks/Feedbacks';
import SoterTaskComparisonPage from '../task/comparison/SoterTaskComparisonPage';
import Loader from '../lib/ui/Loader';
import { SSO_LOGIN } from '../auth/store/auth';
import { ErrorCodes } from './proto_client';

if (!window.TextDecoder) {
  window.TextDecoder = TextDecoder;
}

const getTokenFromStorage = () => localStorage.getItem('token');

let appId;
let measurementId;

if (
  window.location.host.indexOf('localhost') !== -1
  || window.location.host.indexOf('dev') !== -1
) {
  appId = '1:13165099341:web:6d625604d886ed52de5f04';
  measurementId = 'G-JCN9ZKTKCC';
} else if (window.location.host.indexOf('stage') !== -1) {
  appId = '1:13165099341:web:5dc8137fada94c4fde5f04';
  measurementId = 'G-3MTV1C2459';
} else {
  appId = '1:13165099341:web:a022e5903a023c57de5f04';
  measurementId = 'G-N7TXBFMG9V';
}

const firebaseConfig = {
  apiKey: 'AIzaSyDV8jNu1g0iNtmXerQO4l8nfWuCoMzkZko',
  authDomain: 'dashboardtabler.firebaseapp.com',
  projectId: 'dashboardtabler',
  storageBucket: 'dashboardtabler.appspot.com',
  messagingSenderId: '13165099341',
  appId,
  measurementId,
};
const app = initializeApp(firebaseConfig);
registerVersion('soter-dashboard-tabler', packageJson.version);
const analytics = getAnalytics(app);
window.analytics = analytics;
window.logEvent = (name, parameters) => logEvent(analytics, name, parameters);
export let initialLoad = true;
const App = () => {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const lang = localStorage.getItem('language') || 'en';
  moment.updateLocale(lang, {
    relativeTime: getTextForMoment(t),
  });

  const auth = useAppSelector((state) => state.auth);
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const { companiesList } = useAppSelector((state) => state.companies);
  const { users } = useAppSelector((state) => state.coachUsers);
  const token = getTokenFromStorage();
  const widget = document.getElementById('launcher');

  const [isLoading, setIsLoading] = useState(true);
  const initLoad = window.onload = () => {
    if (window.location.search) {
      initialLoad = false;
    }
  };

  useEffect(() => {
    setUserId(analytics, auth?.profile?.id);
    if (selectedCompany.id) {
      initLoad();
      dispatch(getCategories(selectedCompany.id));
      dispatch(getUserResource({ companyId: selectedCompany.id }));
      // eslint-disable-next-line no-restricted-globals
      adjustRouteWithSelectedCompany(
        selectedCompany.id,
        location.pathname.split('/')[navigationLevels.companyID],
        dispatch,
        companiesList,
        setIsLoading,
      );
    }
  }, [selectedCompany.id]);
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      const l = navigator.language.slice(0, 2);
      localStorage.setItem('language', l);
      i18n.changeLanguage(l);
    }
    if (token) {
      dispatch(setAnonymous());
    }
    dispatch(authMe());
  }, []);

  useEffect(() => {
    if ((token && auth.profile) || (!selectedCompany.id && auth.profile)) {
      dispatch(getCompanies());
    }
  }, [auth.profile]);

  useEffect(() => {
    if (!auth.anonymous) {
      if (token && auth.profile) {
        Sentry.setUser({ id: auth?.profile?.id, email: auth?.profile?.email });
        if (typeof window.zE === 'function') {
          try {
            window.zE('webWidget', 'prefill', {
              name: {
                value: `${auth.profile.firstName} ${auth.profile.lastName}`,
              },
              email: {
                value: auth.profile.email,
              },
            });
          } catch (e) {
            console.error(e)
          }
        }

        if (widget !== null) {
          widget.style.marginRight = '7px';
          widget.style.marginBottom = '2px';
        }
      }
    }
    if (auth.error && auth.errorAction !== SSO_LOGIN) {
      if (location.pathname === '/login/sso-error') {
        dispatch({
          type: 'auth/requestSSOLink/rejected',
          payload: { error: { message: JSON.stringify({ code: ErrorCodes.USER_NOT_FOUND }) }, errorAction: SSO_LOGIN },
        });
        navigate('/single-sign-on');
      }
      if (!noCompanyIDRoutes.has(window.location.pathname.split('/')[navigationLevels.companyID])) {
        navigate('/auth');
      }
      setIsLoading(false);
    }
  }, [auth]);

  const page = getFirstAvailableProductRoute(selectedCompany);

  return ((auth.isLoading && !auth.error && companiesList.length === 0) || isLoading) ? (
    <Loader />
  ) : (
    <>
      <Router>
        <Redirect noThrow from="/" to={(!token || auth.error) ? 'auth' : `${selectedCompany.id}/users/wearables/overview/`} />
        <LoginPage path="auth" lastVisitedPage="/" />
        <SingleSignOn path="single-sign-on" />
        <ForgotPasswordPage path="forgot-password" />
        <SetPasswordPage path="set-password/:token" />
        <OAuthRedirectWithToken path="auth-with-token/:token" />
        <ConfirmEmailPage path="email-confirm/:token" />
        {selectedCompany.id && (
        <>
          <Redirect noThrow from={`/${routes.users}`} to={`/${selectedCompany.id}${page}`} />
          <Redirect noThrow from={`/${selectedCompany.id}`} to={`/${selectedCompany.id}${page}`} />
          <Redirect noThrow from={`/${selectedCompany.id}/users`} to={`/${selectedCompany.id}${page}`} />
          <PrivateRoute path={`${selectedCompany.id}/users`} analytics={analytics} token={token} setInitLoader={setIsLoading}>
            <Overview path="wearables/overview" />
            <Devices path="wearables/devices" />
            <ActivityPage path="wearables/activity" />
            <UploadUsers path="wearables/activity/upload-user-file" />
            <HelpPage path="wearables/help" />
            <GlobalUserPage path="wearables/user/:id" />
            <JobDictionary path="jobs" />
            <JobProfile path="jobs/profile" />
            <JobProfile path="jobs/profile/:id" />
            <TaskProfile path="jobs/profile/:id/task" />
            <TaskProfile path="jobs/profile/:id/task/:taskId" />
            <SoterTaskContainer path="soter-genius/gallery" />
            <SoterTaskVideo path="soter-genius/gallery/:id" />
            <AddRebaWeight path="soter-genius/gallery/weight/:id" />
            <SoterTaskComparison path="soter-genius/comparison" />
            <SoterTaskComparisonPage path="soter-genius/comparison/:id" />
            <SoterTaskOverview path="soter-genius/overview" />
            <Redirect noThrow from="my-company" to="users" />
            <CompanyUsersPage path="my-company/users" />
            <CompanyCategoriesPage path="my-company/categories" />
            <CompanyTagsPage path="my-company/categories/tags/:id" />
            <Chat path='soter-genius/chat'/>
            <UserProfile path="soter-genius/profile" />
            <NotFoundPage default />
          </PrivateRoute>
          <Redirect noThrow from="admin" to="companies" />
          <PrivateRoute path={`${selectedCompany.id}/admin`} analytics={analytics} isAdminArea setInitLoader={setIsLoading}>
            <Companies path="companies" />
            <Company path="companies/:id" />
            <DashboardUsers path="users" />
            <AdminDevicesContainer path="devices" />
            <Hubs path="hubs" />
            <Feedbacks path="feedbacks" />
          </PrivateRoute>
        </>
        )}
        <NotFoundPage default />
      </Router>
    </>
  );
};

export default App;
