import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@reach/router';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import legendSquare from 'assets/jss/components/legendSquare';
import { useTranslation } from 'react-i18next';
import {
  cardLarge,
  cardMediumWithHeight100,
  cardSmall132,
  columnLarge,
  columnMedium,
  columnMediumSmall,
  flexColumn,
  flexRow,
  mb5,
  mt5,
} from 'assets/soter-flex-styles';
import ProgramInfo from './components/ProgramInfo';
import ProgramProgressInfo from './components/ProgramProgressInfo';
import DeviceInfo from './components/DeviceInfo';
import { CARD_TITLES } from './constants';
import { hazardNameColorMap } from '../../overview/constants/hazardNameColorMap';
import { setEditedTask } from '../userTasksStore/actions';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';
import { getUser } from '../userStore/actions';
import InfoCard from '../../../lib/ui/InfoCard';
import statusMap from './constants/statusMapForCardBadge';
import { getTimeInUserTimeZone } from '../../../lib/utils/getTimeInUserTimeZone';
import OverallHazardsCharts from './components/OverallHazardsCharts';
import prepareDataForHazardsDonutChart from './utils/prepareDataForHazardsDonutChart';
import TwoChartCard from './components/TwoChartCard';
import ArrowBackForwardSwitch from '../../overview/components/ArrowBackForwardSwitch';
import HazardsOverallPerDaySplitChart from './components/HazardsOverallPerDaySplitChart';
import HazardsPerHourSplitChart from './components/HazardsPerHourSplitChart';
import ModalWrapper from '../../../containers/ModalContainer/ModalWrapper';
import AddDayTasksDialog from '../AddDayTasksDialog/AddDayTasksDialog';
import { hazardsDisplayTypeSelect } from './styles';
import Select from './components/Select';

const ProgramContainer = ({
  user,
  hazardsByDay,
  hazardsConfig,
  dates,
  timezone,
  program = {},
}) => {
  const [workSessionId, setWorkSessionId] = useState(dates[0]?.id);
  const { userType, lastActivityInMilliseconds } = user;
  const {
    currentDevice,
    progress,
    tutorials,
    status,
    improvements,
    lastSyncInMilliseconds,
    deviceType,
    riskGroup,
    currentRiskGroup,
    hazardsByDayList,
    overallHazardsCurrent,
    overallHazardsStart,
    mostRiskyMovement,
    mostRiskyTask,
    mostRiskyDayMilliseconds,
    mostRiskyHourMilliseconds,
    achievements,
  } = program || {};
  const daySelectOptions = dates.map((date) => ({
    label: date.date,
    value: date.id,
    fillDay: hazardsByDay.find((el) => el.workSessionId === date.id).fillDay,
  }));
  const mostlyHazardPresentation = [
    ...hazardNameColorMap.shoulder,
    ...hazardNameColorMap.spine,
  ].find(({ alias }) => alias === mostRiskyMovement);
  const modal = document.getElementById('modal').children.length;
  const dispatch = useDispatch();
  useEffect(() => {
    if (modal === 0) {
      dispatch(setEditedTask(false));
    }
  }, [modal]);
  const { t } = useTranslation();
  const { openedModal, modalID } = useSelector((state) => state.modals);
  const params = useParams();
  const handleCloseTasksModal = () => {
    dispatch(setOpenedModal(null));
    dispatch(getUser(params.id));
  };
  const currentUser = useSelector((state) => state.user);
  const programId = program?.coachingProgramPassId;
  const [OHPHPDPeriod, setOHPHPDPeriod] = useState({
    from: 0,
    to: 15,
  });
  const couldOHPHPDStepBack = hazardsByDay?.length - OHPHPDPeriod.to > 0;
  const couldOHPHPDStepForward = OHPHPDPeriod.from > 0;
  const onOHPHPDStepBack = () => {
    setOHPHPDPeriod({
      from: OHPHPDPeriod.from + 1,
      to: OHPHPDPeriod.to + 1,
    });
  };
  const onOHPHPDStepForward = () => {
    setOHPHPDPeriod({
      from: OHPHPDPeriod.from - 1,
      to: OHPHPDPeriod.to - 1,
    });
  };

  const riskGroupsObject = {
    lastTwoDays: riskGroup,
    current: currentRiskGroup,
  };
  const hazardsDisplayTypes = [
    { label: t('WEARABLES.USER_PAGE.PER_HOUR').toLowerCase(), value: 'hour' },
    {
      label: t('WEARABLES.USER_PAGE.PER_HOUR_TASK').toLowerCase(),
      value: 'task',
    },
  ];
  const [hazardsDisplayType, setHazardsDisplayType] = useState('hour');
  const riskGroups = riskGroupsObject;
  const mostCardText = text(COLORS.GRAY[950], 20, 600);
  return (
    <>
      <div className={flexColumn}>
        <div className={flexRow} style={{ flexFlow: 'unset' }}>
          <div className={`${columnMediumSmall} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.program)}
              cardClass={cardSmall132}
              badgeMap={statusMap}
              badge={status || null}
              style={{ height: '169px' }}
            >
              <ProgramInfo
                program={program}
                sync={program?.progress?.lastSession}
                userType={userType}
                timezone={timezone}
              />
            </InfoCard>
          </div>
          <div className={`${columnMedium} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.programProgress)}
              cardClass={`${cardSmall132}`}
              style={{ height: '169px' }}
            >
              <ProgramProgressInfo
                progress={progress}
                tutorials={tutorials}
                riskGroups={riskGroups}
                goldMedalsQuantity={achievements?.goldGoalsQuantity}
              />
            </InfoCard>
          </div>
          <div className={`${columnMediumSmall} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.device)}
              cardClass={cardSmall132}
              style={{ height: '169px' }}
            >
              <DeviceInfo device={currentDevice} userType={userType} />
            </InfoCard>
          </div>
        </div>
        <div className={flexRow}>
          <div className={`${columnMediumSmall} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.mostFrequentHazard)}
              cardClass={cardSmall132}
            >
              {mostlyHazardPresentation ? (
                <div className={mostCardText}>
                  <div
                    className={legendSquare(mostlyHazardPresentation.color)}
                  />
                  {t(mostlyHazardPresentation.name)}
                </div>
              ) : (
                '-'
              )}
            </InfoCard>
          </div>
          <div className={`${columnMediumSmall} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.mostRiskHour)}
              cardClass={`${cardSmall132}`}
            >
              {mostRiskyHourMilliseconds ? (
                <div className={mostCardText}>
                  <div>
                    {getTimeInUserTimeZone(
                      mostRiskyHourMilliseconds,
                      timezone,
                      'DD MMM YYYY',
                    )}
                  </div>
                  <div>
                    {`${getTimeInUserTimeZone(
                      mostRiskyHourMilliseconds,
                      timezone,
                      'HH:mm',
                    )} 
                    - ${getTimeInUserTimeZone(
                      mostRiskyHourMilliseconds + 3600000,
                      timezone,
                      'HH:mm',
                    )}`}
                  </div>
                </div>
              ) : (
                '-'
              )}
            </InfoCard>
          </div>
          <div className={`${columnMediumSmall} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.mostRiskDay)}
              cardClass={cardSmall132}
            >
              {mostRiskyDayMilliseconds ? (
                <div className={mostCardText}>
                  {getTimeInUserTimeZone(
                    mostRiskyDayMilliseconds,
                    timezone,
                    'DD MMM YYYY',
                  )}
                </div>
              ) : (
                '-'
              )}
            </InfoCard>
          </div>
          <div className={`${columnMediumSmall} ${mt5}`}>
            <InfoCard
              title={t(CARD_TITLES.mostRiskTask)}
              cardClass={cardSmall132}
            >
              {mostRiskyTask ? (
                <div className={mostCardText}>{mostRiskyTask}</div>
              ) : (
                '-'
              )}
            </InfoCard>
          </div>
        </div>
        <div className={`${flexRow}`}>
          <div className={`${columnLarge} ${mt5}`}>
            <OverallHazardsCharts
              improvements={improvements}
              startChartData={prepareDataForHazardsDonutChart(
                overallHazardsStart,
                deviceType,
              )}
              currentChartData={prepareDataForHazardsDonutChart(
                overallHazardsCurrent,
                deviceType,
              )}
              startRiskGroup={riskGroup}
              currentRiskGroup={currentRiskGroup}
            />
          </div>
        </div>
        <div className={`${flexRow} ${mb5}`}>
          <div className={`${columnLarge} ${mt5}`}>
            <TwoChartCard
              title={t(CARD_TITLES.chosenDay).toUpperCase()}
              cardClass={cardLarge}
              headerAdditionalStyles={{
                borderBottom: '1px solid rgba(0, 40, 100, 0.12)',
                height: '72px',
              }}
              control={(
                <Select
                  id="hazardsDisplayType"
                  className={hazardsDisplayTypeSelect}
                  data={hazardsDisplayTypes}
                  optionMapFunction={(element) => (
                    <option
                      key={element.value}
                      value={element.value}
                      defaultValue={hazardsDisplayType}
                    >
                      {element.label}
                    </option>
                  )}
                  setValue={setHazardsDisplayType}
                />
              )}
              leftChart={(
                <InfoCard
                  title={' '}
                  cardClass={`${cardMediumWithHeight100(370)} ${Emotion.css`
                    border: 1px solid transparent !important;
                  `}`}
                  bodyStyles={{ display: 'flex', alignItems: 'flex-end' }}
                  rightCornerControl={(
                    <ArrowBackForwardSwitch
                      couldStepBack={couldOHPHPDStepBack}
                      couldStepForward={couldOHPHPDStepForward}
                      onStepBack={onOHPHPDStepBack}
                      onStepForward={onOHPHPDStepForward}
                    />
                  )}
                >
                  <HazardsOverallPerDaySplitChart
                    programId={programId}
                    hazardsByDay={hazardsByDay}
                    hazardsConfig={hazardsConfig}
                    deviceType={deviceType}
                    containerHeight={296}
                    period={OHPHPDPeriod}
                    timezone={timezone}
                  />
                </InfoCard>
              )}
              rightChart={(
                <HazardsPerHourSplitChart
                  programId={programId}
                  hazardsByDay={hazardsByDay}
                  hazardsConfig={hazardsConfig}
                  workSessionId={workSessionId}
                  deviceType={deviceType}
                  containerHeight={296}
                  timezone={timezone}
                  isTaskListBtnDisabled={dates.length === 0}
                  setWorkSessionId={setWorkSessionId}
                  dates={dates}
                  hazardsDisplayType={hazardsDisplayType}
                  daySelectOptions={daySelectOptions}
                />
              )}
            />
          </div>
        </div>
      </div>
      {openedModal === 'addDayTasks' && modalID === programId && (
        <ModalWrapper closeModal={handleCloseTasksModal}>
          <AddDayTasksDialog
            hazardsByDay={hazardsByDay}
            userId={currentUser?.user.id}
            userTimezone={timezone}
            dates={dates}
            workSessionId={workSessionId || dates[0]?.id}
            setWorkSessionId={setWorkSessionId}
          />
        </ModalWrapper>
      )}
    </>
  );
};

export default ProgramContainer;
