import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  companiesList: [],
  companiesWithDepartments: [],
  selectedCompany: {},
  error: null,
  isLoading: false,
});

export default function (state = initialState, action = {}) {
  const {
    error, companiesList, isLoading, selectedCompany = {},
  } = action;
  switch (action.type) {
    case types.SET_IS_LOADING:
      return ({ ...state, isLoading });
    case types.SET_COMPANIES_ERROR:
      return ({ ...state, error });
    case types.SET_COMPANIES:
      return ({ ...state, companiesList });
    case types.RESET_SELECTED_COMPANY:
      return ({ ...state, selectedCompany: {} });
    case types.SET_COMPANIES_WITH_DEPARTMENTS:
      return ({ ...state, companiesWithDepartments: companiesList });
    case types.SET_SELECTED_COMPANY:
      return ({ ...state, selectedCompany });
    case types.REMOVE_DEPARTMENT_FROM_CURRENT_COMPANY:
      return ({
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          departmentsList: state.selectedCompany.departmentsList.filter(({ id }) => id !== action.id),
        },

      });
    case types.EDIT_DEPARTMENT_IN_CURRENT_COMPANY:
      return ({
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          departmentsList: state.selectedCompany.departmentsList.map(
            (item) => (item.id === action.id ? { ...item, name: action.name } : item),
          ),
        },

      });
    case types.REMOVE_SITE_FROM_CURRENT_COMPANY:
      return ({
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          sitesList: state.selectedCompany.sitesList.filter(({ id }) => id !== action.id),
        },

      });
    case types.EDIT_SITE_IN_CURRENT_COMPANY:
      return ({
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          sitesList: state.selectedCompany.sitesList.map(
            (item) => (item.id === action.id ? { ...item, name: action.name } : item),
          ),
        },

      });
    default: return state;
  }
}
