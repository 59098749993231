import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import moment from 'moment';
import { Text } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import sliceLongString from '../../lib/utils/sliceLongString';
import { DEPARTMENT_NOT_ASSIGNED } from '../../containers/ItemTableContainer/constants/tableValuesAndFilterOptions';
import * as companiesSelectors from '../../company/companiesStore/selectors';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import DeviceName from '../../containers/ItemTableContainer/components/CustomCells/DeviceName';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import DepartmentsChangeDialog from './DepartmentsChangeDialog/DepartmentsChangeDialog';
import { getActionHandlerForModal } from '../../lib/utils/getActionHandlerForModal';
import DeviceHistoryDialog from './DeviceHistoryDialog';
import Button from '../../lib/ui/Button';
import { getAdminDevices, resetDevices } from './devicesStore/actions';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import { outlineColoredButton } from '../../assets/soter-flex-styles';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';
import { COLORS } from 'constants/colors'

const UniversalSimpleDeviceCell = ({ type, rowData }) => {
  const {
    lastBatteryCharge,
    lastSynced,
    department,
    company,
    linkedAt,
    batch,
  } = rowData;
  const { t } = useTranslation();
  let muted;
  let customClass;
  let text;
  let l;

  switch (type) {
    case 'battery':
      customClass
        = lastBatteryCharge < 15 && lastBatteryCharge >= 0
          ? Emotion.css`
               {
                color: #f14b75;
                font-size: 16px;
              }
            `
          : Emotion.css`
               {
                font-size: 16px;
              }
            `;
      text = lastBatteryCharge < 0 ? 'No data' : `${lastBatteryCharge}%`;
      muted = lastBatteryCharge < 0;
      break;
    case 'department':
      muted = !department;
      text
        = sliceLongString(department?.name, 15) || t(DEPARTMENT_NOT_ASSIGNED);
      break;
    case 'company':
      muted = !company;
      text = sliceLongString(company?.name, 50) || t(DEPARTMENT_NOT_ASSIGNED);
      break;
    case 'batch':
      muted = !batch;
      text = batch || 'No batch';
      break;
    case 'synced':
      muted = !lastSynced && !linkedAt;
      l = lastSynced || linkedAt;
      text = l ? moment(l, lastSynced ? 'X' : 'x').fromNow().replace(/( ago)+/g, ' ago') : 'Never synced';
      customClass = Emotion.css`
         {
          font-size: 16px;
        }
      `;
      break;
    default:
      throw new Error('Unknown cell type - UniversalSimpleDeviceCell');
  }

  return (
    <Text className={customClass || ''} muted={muted}>
      {text}
    </Text>
  );
};

const DevicesContainer = () => {
  const dispatch = useDispatch();
  const { devices, isLoading, deviceHistory } = useSelector(
    (state) => state.devices,
  );
  const { devices: selectedDevices } = useSelector(
    (state) => state.selectedItems,
  );
  const { selectedCompany } = useSelector((state) => state.companies);
  const companyId = selectedCompany?.id;

  const accessibleDepartments = useSelector(
    companiesSelectors.selectedCompanyAccessibleDepartmentsIdsSelector,
  );
  const filteredDepartmentList = selectedCompany.departmentsList?.filter((el) => accessibleDepartments.includes(el.id));

  const [openedModal, setOpenedModal] = useState(null);
  const [editedDevice, setEditedDevice] = useState(null);
  useEffect(() => {
    if (deviceHistory.length) setOpenedModal('deviceHistory');
  }, [deviceHistory]);

  const columns = (getActionForDevices) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.adminDevices),
    // eslint-disable-next-line no-undef
    _,
    getActionForDevices,
    TableType.adminDevices,
  );

  const cells = {
    device: DeviceName,
    synced: UniversalSimpleDeviceCell,
    company: UniversalSimpleDeviceCell,
    batch: UniversalSimpleDeviceCell,
    // actions: ActionCell, // Need to figure out what this action do
  };

  const closeModals = () => setOpenedModal(null);

  const deviceActions = [
    {
      id: 'setDepartment',
      name: 'Change department',
      color: COLORS.GRAY[950],
      size: 'small',
      child: (
        <DepartmentsChangeDialog
          userIds={selectedDevices.map((user) => user.id)}
          user={null}
          departments={filteredDepartmentList || []}
          handlerFn={(e, userIDs, departmentID) => {
            getActionHandlerForModal('setDepartment', TableType.adminDevices)(
              closeModals,
              dispatch,
            )(e, userIDs, departmentID);
          }}
          itemType={TableType.adminDevices}
          company={selectedCompany}
        />
      ),
      disabled: false,
    },
  ];

  const switchModal = (modalType) => {
    switch (modalType) {
      case 'departments':
        return (
          <DepartmentsChangeDialog
            user={editedDevice}
            departments={filteredDepartmentList || []}
            handlerFn={(e, userIDs, departmentID) => {
              getActionHandlerForModal(
                'setDepartment',
                TableType.adminDevices,
              )(closeModals, dispatch)(e, userIDs, departmentID);
            }}
            closeModal={closeModals}
            company={selectedCompany}
            itemType={TableType.adminDevices}
          />
        );
      case 'deviceHistory':
        return <DeviceHistoryDialog deviceHistory={deviceHistory} />;
      default:
        return null;
    }
  };

  const DeviceActionTogglerButton = ({
    id,
    clickHandler,
    disabled,
    content,
    name,
    dataName,
    className,
  }) => (
    <Button
      className={className}
      style={{ width: '100%' }}
      type="button"
      name={id || name}
      data-name={id ? `user-dropdown-${id}` : dataName}
      onClick={() => clickHandler()}
      disabled={disabled}
      text={content}
    />
  );

  useEffect(() => {
    dispatch(getAdminDevices());
    return () => dispatch(resetDevices());
  }, [companyId]);

  return (
    <ItemTableContainer
      items={devices}
      selectedItems={selectedDevices}
      columns={columns}
      cells={cells}
      type={TableType.adminDevices}
      isLoading={isLoading}
      editedItem={editedDevice}
      openedModal={openedModal}
      setOpenedModal={setOpenedModal}
      itemActions={deviceActions}
      togglerButton={(
        <DeviceActionTogglerButton
          name="devices-multi"
          dataName="multi-device-dropdown"
          className={outlineColoredButton('#248BF2', 'small')}
        />
      )}
      switchModal={switchModal}
    />
  );
};

export default PrivatePageHOC(DevicesContainer);
