import React, { useRef, useState } from 'react';
import { Dropdown, Popover, Whisper } from 'rsuite';
import { actionButton, actionColorRed, actionDropdown } from '../../../../assets/jss/style';
import moreIcon from '../../../../assets/img/icons/more-vertical.svg';
import { useAppDispatch, useAppSelector } from '../../../../lib/utils/useStore';
import { authedUserSelector } from '../../../../auth/store/selectors';
import {
  DEVICES_USAGE_HISTORY_CHECKED,
  TABLE_ACTIONS_OPEN,
  TASK_VIDEO_STATUS_CHANGED,
} from '../../../../constants/analyticsEvents';
import { createEventNotification } from '../../../NotificationContainer/notificationsStore/actions';
import { TableType } from '../../constants/tableType';
import { TaskVideoStatus } from '../../../../init/proto_client';

const getChangeStatusType = (currentStatus) => (currentStatus === TaskVideoStatus.APPROVED
  ? 'Disapprove'
  : 'Approve');

const getHubStatusText = (status) => (status ? 'Deactivate' : 'Activate');

// eslint-disable-next-line no-unused-vars
const ActionCell = ({
  rowData, actions, userType, isTheOnlyVisibleItem,
}) => {
  const authedUser = useAppSelector(authedUserSelector);
  const [placement, setPlacement] = useState('bottomEnd');
  const dispatch = useAppDispatch();
  const triggerRef = React.createRef();
  const containerRef = useRef();
  const onSelect = () => {
    triggerRef.current.hide();
  };
  const onClose = () => {
    containerRef.current.close();
  };
  const checkDropdownPlacement = (event) => {
    window.logEvent(TABLE_ACTIONS_OPEN);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(TABLE_ACTIONS_OPEN));
    }
    if ((window.innerHeight - event.clientY) < ((actions.length * 40) + 10)) {
      setPlacement('topEnd');
    } else {
      setPlacement('bottomEnd');
    }
  };

  const userIsCreatedProgrammatically = rowData.createdBy === 0 && authedUser?.role !== 'admin';
  const userIsNotCreatedByCurrentUser = rowData.createdBy && rowData.createdBy !== authedUser?.id;

  if (userType !== TableType.dashboardUsers && (userType !== TableType.soterTaskComparison && userType !== TableType.soterTaskVideos) && (userIsNotCreatedByCurrentUser || userIsCreatedProgrammatically)) {
    return <span />;
  }

  const isPossibleToChangeVideoStatus = rowData.status &&
    (rowData.status === TaskVideoStatus.APPROVED || rowData.status === TaskVideoStatus.WAITING_FOR_APPROVAL || rowData.status === TaskVideoStatus.ARCHIVED);

  const isPossibleToArchiveVideo = rowData.status &&
    (rowData.status === TaskVideoStatus.APPROVED || rowData.status === TaskVideoStatus.WAITING_FOR_APPROVAL);

  return (
    <Whisper
      trigger="click"
      placement={placement}
      triggerRef={triggerRef}
      ref={containerRef}
      onClick={checkDropdownPlacement}
      speaker={(
        <Popover className={actionDropdown}>
          <Dropdown.Menu
            className={actionDropdown}
            onSelect={onSelect}
            onClick={onClose}
          >
            {actions?.map((menuItem) => {
              if (!isPossibleToChangeVideoStatus && menuItem.type === 'CHANGE_STATUS') {
                return null;
              }
              if (!isPossibleToArchiveVideo && menuItem.type === 'ARCHIVE') {
                return null;
              }
              return (
                <Dropdown.Item
                  key={Math.random()}
                  className={menuItem.attention && actionColorRed}
                  disabled={
                    (menuItem.title === 'Resend invite' && rowData.isPasswordSet)
                    || (menuItem.title === 'Device usage history' && (!rowData.lastUser && !rowData.currentUser))
                    || undefined
                    || menuItem.disabled
                  }
                  onClick={() => {
                    if (menuItem.type === 'CHANGE_STATUS') {
                      window.logEvent(TASK_VIDEO_STATUS_CHANGED);
                      if (localStorage.getItem('showEvents')) {
                        dispatch(createEventNotification(TASK_VIDEO_STATUS_CHANGED));
                      }
                    } else if (menuItem.title === 'Device usage history') {
                      window.logEvent(DEVICES_USAGE_HISTORY_CHECKED);
                      if (localStorage.getItem('showEvents')) {
                        dispatch(createEventNotification(DEVICES_USAGE_HISTORY_CHECKED));
                      }
                    }
                    menuItem.handler(rowData.data ? rowData.data : rowData, { isTheOnlyVisibleItem });
                  }}
                >
                  {menuItem.title === 'Change status' ? getChangeStatusType(rowData.status) : menuItem.title === 'Hub status' ? getHubStatusText(rowData.isActive) : menuItem.title}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Popover>
      )}
    >
      <img src={moreIcon} className={actionButton} alt="more actions icon" />
    </Whisper>
  );
};

export default ActionCell;
