import React from 'react';
import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { overviewHeader } from 'assets/jss/containers/pdfLayersStyle';
import { COLORS } from 'constants/colors';
import { RISKS_CHART_WIDTH_MM } from 'containers/PdfContainer/constants';

const OverviewReportHeader = ({
  period,
  selectedDepartmentNames,
  product,
  users,
  improvements,
  daysRecorded,
  deviceType,
  selectedSiteNames,
}) => {
  const { t } = useTranslation();
  const spineImprovement
    = improvements.spine !== 1 && `${-improvements.spine}%`;
  const shoulderImprovement
    = improvements.shoulder !== 1 && `${-improvements.shoulder}%`;
  const selectedDepartmentQuantity = selectedDepartmentNames.length;
  const selectedSiteQuantity = selectedSiteNames.length;
  const getCategoryString = (names, quantity, type) => {
    if (quantity > 6) {
      return `${quantity} ${t(
        type === 'department' ? 'WEARABLES.OVERVIEW.DEPARTMENTS_SELECTED' : 'WEARABLES.OVERVIEW.SITES_SELECTED',
      )}`;
    }
    return `${t(
      type === 'department' ? 'WEARABLES.OVERVIEW.DEPARTMENTS' : 'WEARABLES.OVERVIEW.SITES',
    )}: ${names.reduce((acc, item, index, arr) => {
      const string = index === arr.length - 1 ? item?.name : `${item?.name}, `;
      // eslint-disable-next-line no-return-assign,no-param-reassign
      return (acc += string);
    }, '')}`;
  };
  const usersTypeMap = {
    new: t('WEARABLES.OVERVIEW.REGISTERED'),
    active: t('WEARABLES.OVERVIEW.ACTIVE'),
    finished_program: t('WEARABLES.OVERVIEW.FINISHED'),
  };
  return (
    <div className={overviewHeader}>
      <div style={{ width: `${RISKS_CHART_WIDTH_MM}mm`, position: 'relative' }}>
        <div style={{ fontSize: '16pt', marginBottom: '10mm' }}>
          {`${t(
            'WEARABLES.OVERVIEW.OVERVIEW',
          )} ${deviceType}`}
        </div>
        <div
          style={{
            fontSize: product.length > 11 ? '20pt' : '28pt',
            fontWeight: 600,
            marginBottom: '3mm',
            position: 'absolute',
            top: '5mm',
            width: `${RISKS_CHART_WIDTH_MM * 2}mm`,
          }}
        >
          {product}
        </div>
        <div
          style={{
            fontSize: '12pt',
            color: COLORS.GRAY[400],
            wordWrap: 'break-word',
            marginBottom: '3mm',
          }}
        >
          {getCategoryString(selectedDepartmentNames, selectedDepartmentQuantity, 'department')}
        </div>
        <div
          style={{
            fontSize: '12pt',
            color: COLORS.GRAY[400],
            wordWrap: 'break-word',
            marginBottom: '3mm',
          }}
        >
          {getCategoryString(selectedSiteNames, selectedSiteQuantity, 'site')}
        </div>
        <div
          style={{ fontSize: '10pt', color: COLORS.GRAY[400], marginBottom: '3mm' }}
        >
          <b>{`${t('WEARABLES.OVERVIEW.DATE_RANGE')}: `}</b>
          {period}
        </div>
        {daysRecorded[deviceType] ? (
          <div
            style={{
              fontSize: '10pt',
              color: COLORS.GRAY[400],
            }}
          >
            <b>{`${t('WEARABLES.OVERVIEW.DAYS_RECORDED')}: `}</b>
            {daysRecorded[deviceType]}
          </div>
        ) : null}
      </div>
      <div style={{ width: `${RISKS_CHART_WIDTH_MM}mm` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10mm',
            marginBottom: '6mm',
          }}
        >
          {deviceType === 'Spine' ? (
            <div>
              <div
                style={{
                  fontSize: '25pt',
                  fontWeight: 600,
                  color: spineImprovement ? '#01a793' : COLORS.GRAY[400],
                }}
              >
                {spineImprovement || t('GENERAL.NA')}
              </div>
              <div
                style={{
                  fontSize: '10pt',
                  color: COLORS.GRAY[400],
                }}
              >
                {t('WEARABLES.OVERVIEW.SPINE_IMPROVEMENT').toUpperCase()}
              </div>
            </div>
          ) : deviceType === 'Shoulder' ? (
            <div>
              <div
                style={{
                  fontSize: '25pt',
                  fontWeight: 600,
                  color: shoulderImprovement ? '#01a793' : COLORS.GRAY[400],
                }}
              >
                {shoulderImprovement || t('GENERAL.NA')}
              </div>
              <div style={{ fontSize: '10pt', color: COLORS.GRAY[400] }}>
                {t('WEARABLES.OVERVIEW.SHOULDER_IMPROVEMENT').toUpperCase()}
              </div>
            </div>
          ) : null}
        </div>
        {users[deviceType] ? (
          <>
            <div
              style={{
                fontSize: '12pt',
                color: COLORS.GRAY[400],
              }}
            >
              {`${t('WEARABLES.USERS')}:`}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {_map(users[deviceType], (value, title) => (
                <div key={title}>
                  <div
                    style={{
                      fontSize: '21pt',
                      fontWeight: 600,
                    }}
                  >
                    {value}
                  </div>
                  <div
                    style={{
                      fontSize: '10pt',
                      color: COLORS.GRAY[400],
                    }}
                  >
                    {usersTypeMap[title]?.toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default OverviewReportHeader;
