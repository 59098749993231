import { navigate } from '@reach/router';
import {
  _activityDemandBreakdown,
  _cardsViewConfig,
  _criticalPhysicalDemands,
  createJobDictionaryProfile,
  createJobDictionaryTagRequest,
  createJobProfileRequest,
  editJobProfileRequest,
  editLimitedTaskProfile,
  editLimitedTaskProfileRequest,
  getMetaData,
  jobDictionaryService,
  jobProfile,
  jobProfileRequest,
  metaJobProfile,
} from 'init/proto_client';
import { notificationMessages, notificationTypes } from 'containers/NotificationContainer/notifications';
import { JOB_PROFILE_EDIT, JOB_PROFILE_REMOVE } from 'constants/analyticsEvents';
import {
  createEventNotification,
  setNotification,
} from 'containers/NotificationContainer/notificationsStore/actions';
import DemandsActivityByteConvertor from '../../utils/DemandsActivityByteConvertor';
import * as types from './actionTypes';

const setJobProfile = ({
  title,
  dotClassification,
  occupationCategory,
  businessUnit,
  rosterHoursShift,
  rosterDays,
  assessedBy,
  lastReviewInMilliseconds,
  nextReviewInMilliseconds,
  keyRisks,
  medicalSurveillance,
  environmentalDemand,
  psychosocialDemand,
  cognitiveDemand,
  summaryDescription,
  toolsList,
  preRequirementsList,
  departmentId,
  workGroupId,
  cardsViewConfig,
  activityDemandBreakdown,
  criticalPhysicalDemands,
  physicalDemandShoulders,
  physicalDemandSpine,
  physicalDemandKnees,
  shoulderChartData,
  spineChartData,
  jobRoleId,
}) => {
  const {
    showKeyRisks,
    showMedicalSurveillance,
    showEnvironmentalDemand,
    showPsychologicalDemand,
    showCognitiveDemand,
    showTools,
    showPreRequirements,
  } = cardsViewConfig;
  const {
    standingWalking,
    activeWork,
    liftingLowering,
    armElevation,
    pushingPulling,
    stoopingBending,
  } = activityDemandBreakdown;
  const {
    floorToWaistLift,
    waistToShoulderLift,
    overheadLift,
    carry,
    push,
    pull,
    sitting,
    standing,
    walking,
    kneeling,
    stairClimbing,
    ladderClimbing,
    bending,
    sustainedFlexion,
    forwardReach,
    overheadReach,
    neckMovement,
    trunkMovement,
    wristMovement,
    grip,
    armControl,
    legControl,
    criticalBalance,
    squatting,
  } = criticalPhysicalDemands;
  _cardsViewConfig.setShowKeyRisks(showKeyRisks);
  _cardsViewConfig.setShowMedicalSurveillance(showMedicalSurveillance);
  _cardsViewConfig.setShowEnvironmentalDemand(showEnvironmentalDemand);
  _cardsViewConfig.setShowPsychosocialDemand(showPsychologicalDemand);
  _cardsViewConfig.setShowCognitiveDemand(showCognitiveDemand);
  _cardsViewConfig.setShowTools(showTools);
  _cardsViewConfig.setShowPreRequirements(showPreRequirements);
  _activityDemandBreakdown.setSitting(activityDemandBreakdown.sitting);
  _activityDemandBreakdown.setStandingWalking(standingWalking);
  _activityDemandBreakdown.setActiveWork(activeWork);
  _activityDemandBreakdown.setLiftingLowering(liftingLowering);
  _activityDemandBreakdown.setArmElevation(armElevation);
  _activityDemandBreakdown.setPushingPulling(pushingPulling);
  _activityDemandBreakdown.setStoopingBending(stoopingBending);
  _criticalPhysicalDemands.setFloorToWaistLift(floorToWaistLift);
  _criticalPhysicalDemands.setWaistToShoulderLift(waistToShoulderLift);
  _criticalPhysicalDemands.setOverheadLift(overheadLift);
  _criticalPhysicalDemands.setCarry(carry);
  _criticalPhysicalDemands.setPush(push);
  _criticalPhysicalDemands.setPull(pull);
  _criticalPhysicalDemands.setSitting(sitting);
  _criticalPhysicalDemands.setStanding(standing);
  _criticalPhysicalDemands.setWalking(walking);
  _criticalPhysicalDemands.setKneeling(kneeling);
  _criticalPhysicalDemands.setStairClimbing(stairClimbing);
  _criticalPhysicalDemands.setLadderClimbing(ladderClimbing);
  _criticalPhysicalDemands.setBending(bending);
  _criticalPhysicalDemands.setSustainedFlexion(sustainedFlexion);
  _criticalPhysicalDemands.setForwardReach(forwardReach);
  _criticalPhysicalDemands.setOverheadReach(overheadReach);
  _criticalPhysicalDemands.setNeckMovement(neckMovement);
  _criticalPhysicalDemands.setTrunkMovement(trunkMovement);
  _criticalPhysicalDemands.setWristMovement(wristMovement);
  // _criticalPhysicalDemands.setFineHandUse(fineHandUse);
  _criticalPhysicalDemands.setSquatting(squatting);
  _criticalPhysicalDemands.setGrip(grip);
  _criticalPhysicalDemands.setArmControl(armControl);
  _criticalPhysicalDemands.setLegControl(legControl);
  _criticalPhysicalDemands.setCriticalBalance(criticalBalance);
  jobProfile.setTitle(title);
  jobProfile.setDotClassification(dotClassification);
  jobProfile.setOccupationCategory(occupationCategory);
  jobProfile.setOccupationCategory(occupationCategory);
  jobProfile.setBusinessUnit(businessUnit);
  jobProfile.setRosterHoursShift(rosterHoursShift);
  jobProfile.setRosterDays(rosterDays);
  jobProfile.setAssessedBy(assessedBy);
  jobProfile.setLastReviewInMilliseconds(lastReviewInMilliseconds);
  jobProfile.setNextReviewInMilliseconds(nextReviewInMilliseconds);
  jobProfile.setKeyRisks(keyRisks);
  jobProfile.setMedicalSurveillance(medicalSurveillance);
  jobProfile.setEnvironmentalDemand(environmentalDemand);
  jobProfile.setPsychosocialDemand(psychosocialDemand);
  jobProfile.setCognitiveDemand(cognitiveDemand);
  jobProfile.setSummaryDescription(summaryDescription);
  jobProfile.setToolsList(toolsList);
  jobProfile.setPreRequirementsList(preRequirementsList);
  jobProfile.setDepartmentId(departmentId);
  jobProfile.setWorkGroupId(workGroupId);
  jobProfile.setCardsViewConfig(_cardsViewConfig);
  jobProfile.setActivityDemandBreakdown(_activityDemandBreakdown);
  jobProfile.setCriticalPhysicalDemands(_criticalPhysicalDemands);
  jobProfile.setPhysicalDemandShoulders(physicalDemandShoulders);
  jobProfile.setPhysicalDemandSpine(physicalDemandSpine);
  jobProfile.setPhysicalDemandKnees(physicalDemandKnees);
  jobProfile.setShoulderChartData(shoulderChartData);
  jobProfile.setSpineChartData(spineChartData);
  jobProfile.setJobRoleId(jobRoleId);
  return jobProfile;
};

export function toggleIsLoading(isLoading) {
  return ({ type: types.JOB_PROFILE_IS_LOADING, isLoading });
}

export function resetProfileState() {
  return ({ type: types.JOB_PROFILE_RESET });
}

export function createJobProfile(companyId, profile) {
  return async (dispatch) => {
    const pr = setJobProfile(profile);
    createJobDictionaryProfile.setProfile(pr);
    createJobDictionaryProfile.setCompanyId(companyId);
    createJobProfileRequest.setJobProfile(createJobDictionaryProfile);
    await jobDictionaryService.createJobDictionaryProfile(createJobProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.JOB_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.CREATE_JOB_PROFILE_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.JOB_PROFILE_SET,
            profile: response.toObject().jobProfile,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.CREATE_JOB_PROFILE_SUCCESS_MSG,
          }));
          dispatch(toggleIsLoading(false));
        }
      });
  };
}

export function editJobProfile(id, profile, noNotification) {
  return async (dispatch) => {
    window.logEvent(JOB_PROFILE_EDIT);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(JOB_PROFILE_EDIT));
    }
    const pr = setJobProfile(profile);
    metaJobProfile.setProfile(pr);
    metaJobProfile.setId(id);
    editJobProfileRequest.setJobProfile(metaJobProfile);
    await jobDictionaryService.editJobDictionaryProfile(
      editJobProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.JOB_PROFILE_ERROR,
            error,
          });
          // eslint-disable-next-line no-unused-expressions
          !noNotification && dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.EDIT_JOB_PROFILE_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.JOB_PROFILE_SET,
            profile: response.toObject().jobProfile,
          });
          // eslint-disable-next-line no-unused-expressions
          !noNotification && dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.EDIT_JOB_PROFILE_SUCCESS_MSG,
          }));
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export function getJobProfile(id) {
  return async (dispatch) => {
    jobProfileRequest.setJobProfileId(id);
    await jobDictionaryService.getJobDictionaryProfile(
      jobProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.JOB_PROFILE_ERROR,
            error,
          });
        } else {
          const profile = response.toObject().jobProfile;
          const demands = profile.profile.criticalPhysicalDemands;
          const byteConvertor = new DemandsActivityByteConvertor();
          const zeroBase64String = byteConvertor.getZeroBase64String();
          let emptyDemands = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const demand in demands) {
            if (demands[demand] === '') {
              emptyDemands = true;
              demands[demand] = zeroBase64String;
            }
          }
          // eslint-disable-next-line no-unused-expressions
          emptyDemands && dispatch(editJobProfile(profile.id, profile.profile, true));
          dispatch({
            type: types.JOB_PROFILE_SET,
            profile,
          });
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export function createTag(companyId, type, title) {
  return async (dispatch) => {
    createJobDictionaryTagRequest.setCompanyId(companyId);
    createJobDictionaryTagRequest.setEntity(type);
    createJobDictionaryTagRequest.setTitle(title);
    await jobDictionaryService.createJobDictionaryTags(
      createJobDictionaryTagRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch({
            type: types.JOB_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.CREATE_JOB_PROFILE_TAG_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.JOB_PROFILE_TAG_SET,
            tag: response.toObject(),
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.CREATE_JOB_PROFILE_TAG_SUCCESS_MSG,
          }));
        }
      },
    );
  };
}

export function deleteJobProfile(id) {
  return async (dispatch) => {
    window.logEvent(JOB_PROFILE_REMOVE);
    jobProfileRequest.setJobProfileId(id);
    await jobDictionaryService.removeJobProfile(
      jobProfileRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.JOB_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.DELETE_JOB_PROFILE_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.JOB_PROFILE_DELETE,
          });
          dispatch(toggleIsLoading(false));
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.DELETE_JOB_PROFILE_SUCCESS_MSG,
          }));
          navigate('/users/jobs');
        }
      },
    );
  };
}

export function editTaskProfile({
  id,
  riskGroup,
  spineRiskGroup,
  shouldersRiskGroup,
  kneeRiskGroup,
  duration,
}) {
  return async (dispatch) => {
    editLimitedTaskProfile.setId(id);
    editLimitedTaskProfile.setRiskGroup(riskGroup);
    editLimitedTaskProfile.setShouldersRiskGroup(shouldersRiskGroup);
    editLimitedTaskProfile.setSpineRiskGroup(spineRiskGroup);
    editLimitedTaskProfile.setKneeRiskGroup(kneeRiskGroup);
    editLimitedTaskProfile.setDuration(duration);
    editLimitedTaskProfileRequest.setTaskProfile(editLimitedTaskProfile);
    await jobDictionaryService.editLimitedJobDictionaryTask(
      editLimitedTaskProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.JOB_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.EDIT_TASK_PROFILE_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.JOB_PROFILE_SET,
            profile: response.toObject().jobProfile,
          });
          dispatch(toggleIsLoading(false));
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.EDIT_TASK_PROFILE_SUCCESS_MSG,
          }));
        }
      },
    );
  };
}
