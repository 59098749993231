import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';
import { transparentButton } from '../../soter-flex-styles';

export const multiSelectFooter = Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 290px;
  height: 70px;
  margin-top: 1.5rem;
  padding: 0 12px;
  border-top: 1px solid ${COLORS.GRAY[100]};
`;

export const button = Emotion.css`
  ${transparentButton('small')};

  width: auto;
  margin-left: 0.5rem;
  padding: 11px 14px;
`;
