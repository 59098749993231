import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    reportBuilder: null,
    reportId: null,
};
const reportSlice = createSlice({
    name: 'REPORT',
    initialState,
    reducers: {
        setReportId(state, action) {
            state.reportId = action.payload;
        },
        setReportBuilder(state, action) {
            state.reportBuilder = action.payload;
        },
    },
});
export default reportSlice.reducer;
export const { setReportId, setReportBuilder, } = reportSlice.actions;
