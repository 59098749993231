import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  tasks: [],
  taskTitles: [],
  editedTask: null,
  isLoading: false,
  startTime: false,
});

export default function reduce(state = initialState, action = {}) {
  const {
    isLoading, tasks, task, taskTitles, taskTitle, taskId, startingTime,
  } = action;
  switch (action.type) {
    case types.SET_TASKS_ARE_LOADING:
      return state.merge({ isLoading });
    case types.GET_TASKS_BY_WORKSESSION_SUCCESS:
      return state.merge({
        isLoading: false,
        tasks,
      });
    case types.CREATE_TASK_SUCCESS:
      return state.merge({
        tasks: [
          ...state.tasks,
          task,
        ],
        editedTask: null,
      });
    case types.GET_TASK_TITLES_SUCCESS:
      return state.merge({
        taskTitles,
      });
    case types.ADD_TASK_TITLE_SUCCESS:
      return state.merge({
        taskTitles: [
          ...state.taskTitles,
          taskTitle,
        ],
      });
    case types.SET_EDITED_TASK:
      return state.merge({
        editedTask: task,
      });
    case types.UPDATE_TASK_SUCCESS:
      return state.merge({
        tasks: state.tasks.map((el) => (el.id === task.id ? task : el)),
        editedTask: null,
      });
    case types.REMOVE_TASK_SUCCESS:
      return state.merge({
        tasks: state.tasks.filter((el) => el.id !== taskId),
      });
    case types.SET_START_TIME:
      return state.merge({
        startTime: startingTime,
      });
    default: return state;
  }
}
