import { routes } from '../constants/routes';

export const getOverflowDivHeightToSubtract = (activePath, nestedActivePath, pathname, wearableAvailable) => {
  if (nestedActivePath === routes.user || pathname.includes(`/${routes.admin}`)) {
    return 65;
  }
  if (nestedActivePath && nestedActivePath === routes.help) {
    return 70;
  }

  if (nestedActivePath === routes.devices) return 70;

  if (!wearableAvailable || nestedActivePath === routes.activity) {
    return 65;
  }

  if (activePath === routes.jobs && pathname.includes(routes.taskPage)) {
    return 80;
  }

  switch (activePath) {
    case routes.overview:
      return 65;
    case routes.activity:
    case routes.devices:
    case routes.companies:
    case routes.users:
      return 145;
    case routes.task:
      return 65;
    default:
      return 65;
  }
};
