import React, { useEffect } from 'react';
import { assessment, text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { Radio, RadioGroup, Toggle } from 'rsuite';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import calculateNiosh from '../../utils/calculateNiosh';
import CustomInput from '../../../lib/ui/CustomInput';
import AssessmentTotalScore from './AssessmentTotalScore';

const header = Emotion.css`
  ${text(COLORS.GRAY[400], 16)};
  margin-top: 14px;
`;
const subHeader = Emotion.css`
  ${text(COLORS.GRAY[950], 14, 600)};
  margin-top: 10px;
`;
const regular = text(COLORS.GRAY[950], 14);

const radioGroup = Emotion.css`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const getColorForScore = (score) => {
  if (score < 1) return COLORS.GREEN[600];
  if (score < 3) return COLORS.ORANGE[600];
  return COLORS.RED[600];
};
export const getDescriptionForScore = (score, t) => {
  if (score < 1) return t('SOTER_TASK.ASSESSMENT.NIOSH.MAY_BE');
  if (score < 3) return t('SOTER_TASK.ASSESSMENT.NIOSH.MAY_INCREASE');
  return t('SOTER_TASK.ASSESSMENT.NIOSH.MAY_EXCEED');
};

const AssessmentNiosh = ({
  selectedFields = {},
  setSelectedFields,
  totalScore,
  setTotalScore,
  editingMode,
  weightUnit,
  additionalTotalScore,
  setAdditionalTotalScore,
}) => {
  const types = {
    coupling: ['coupling_good', 'coupling_fair', 'coupling_poor'],
    duration: ['duration_one', 'duration_two', 'duration_eight'],
  };
  const { t } = useTranslation();
  const handleFieldChange = (name, value) => {
    const newFields = [...selectedFields].filter(
      (field) => name !== field.name,
    );
    setSelectedFields([...newFields, { name, value }]);
  };
  const getFieldValue = (n) => selectedFields.find(({ name }) => n === name)?.value;
  const isControlRequired = !!getFieldValue('control_required');

  useEffect(() => {
    if (
      getFieldValue('horizontal') &&
      getFieldValue('vertical_origin') &&
      getFieldValue('vertical_destination') &&
      getFieldValue('asymmetry') &&
      getFieldValue('coupling') >= 0 &&
      getFieldValue('frequency') &&
      getFieldValue('avg_load') &&
      getFieldValue('duration') >= 0
    ) {
      if (
        getFieldValue('control_required') &&
        !getFieldValue('horizontal_destination') &&
        !getFieldValue('asymmetry_destination')
      ) {
        setTotalScore(null);
        return;
      }
      const scores = calculateNiosh(getFieldValue, weightUnit);
      setTotalScore(scores[0]);
      setAdditionalTotalScore(scores[1]);
    } else {
      setTotalScore(null);
      setAdditionalTotalScore(null);
    }
  }, [selectedFields]);
  const Input = ({ name, max }) => (
    <CustomInput
      max={max}
      disabled={!editingMode}
      type="number"
      name={name}
      onBlur={handleFieldChange}
      defaultValue={getFieldValue(name)}
    />
  );
  const RadioButton = ({ name }) => {
    const type = name.slice(0, name.indexOf('_'));
    return <Radio disabled={!editingMode} value={name} name={name} />;
  };

  return (
    <div className={assessment}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <Toggle
          disabled={!editingMode}
          name="control_required"
          checked={isControlRequired}
          onChange={(v) => handleFieldChange('control_required', v)}
        />
        <label htmlFor="control_required" className={regular}>
          {t('SOTER_TASK.ASSESSMENT.NIOSH.CONTROL_LIFT')}
        </label>
      </div>
      <div className={header}>
        {t('SOTER_TASK.ASSESSMENT.NIOSH.HOR_LOCATION').toUpperCase()}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: isControlRequired ? '48%' : '100%' }}>
          <label className={subHeader} htmlFor="horizontal">
            {t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}
          </label>
          <Input name="horizontal" max={25} />
        </div>
        {isControlRequired ? (
          <div style={{ marginLeft: '10px' }}>
            <label className={subHeader} htmlFor="horizontal_destination">
              {t('SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION')}
            </label>
            <Input name="horizontal_destination" max={25} />
          </div>
        ) : null}
      </div>
      <div className={header}>
        {t('SOTER_TASK.ASSESSMENT.NIOSH.VERT_LOCATION').toUpperCase()}
      </div>
      <div style={{ display: 'flex' }}>
        <div>
          <label className={subHeader} htmlFor="vertical_origin">
            {t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}
          </label>
          <Input name="vertical_origin" max={70} />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <label className={subHeader} htmlFor="vertical_destination">
            {t('SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION')}
          </label>
          <Input name="vertical_destination" max={70} />
        </div>
      </div>
      <div className={header}>
        {t('SOTER_TASK.ASSESSMENT.NIOSH.ASYMMETRY').toUpperCase()}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: isControlRequired ? '48%' : '100%' }}>
          <label className={subHeader} htmlFor="asymmetry">
            {t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}
          </label>
          <Input name="asymmetry" max={135} />
        </div>
        {isControlRequired ? (
          <div style={{ marginLeft: '10px' }}>
            <label className={subHeader} htmlFor="asymmetry_destination">
              {t('SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION')}
            </label>
            <Input name="asymmetry_destination" max={135} />
          </div>
        ) : null}
      </div>
      <div className={header}>
        {t('SOTER_TASK.ASSESSMENT.NIOSH.COUPLING').toUpperCase()}
      </div>
      <RadioGroup
        className={radioGroup}
        value={types.coupling[getFieldValue('coupling')]}
        onChange={(v) => handleFieldChange('coupling', types.coupling.indexOf(v))}
      >
        <RadioButton name="coupling_good" />
        <span htmlFor="coupling_good" className={regular}>
          {t('SOTER_TASK.ASSESSMENT.NIOSH.GOOD')}
        </span>
        <RadioButton name="coupling_fair" />
        <span htmlFor="coupling_fair" className={regular}>
          {t('SOTER_TASK.ASSESSMENT.NIOSH.FAIR')}
        </span>
        <RadioButton name="coupling_poor" />
        <span htmlFor="coupling_poor" className={regular}>
          {t('SOTER_TASK.ASSESSMENT.NIOSH.POOR')}
        </span>
      </RadioGroup>
      <label htmlFor="frequency" className={subHeader}>
        {t('SOTER_TASK.ASSESSMENT.NIOSH.FREQUENCY')}
      </label>
      <Input name="frequency" max={15} />
      <label htmlFor="avg_load" className={subHeader}>
        {`${t(
          'SOTER_TASK.ASSESSMENT.NIOSH.AVERAGE_LOAD',
        )} (${weightUnit})`}
      </label>
      <Input name="avg_load" />
      <div className={header}>
        {t('SOTER_TASK.VIDEOS_LIST.DURATION').toUpperCase()}
      </div>
      <RadioGroup
        className={radioGroup}
        value={types.duration[getFieldValue('duration')]}
        onChange={(v) => handleFieldChange('duration', types.duration.indexOf(v))}
      >
        <RadioButton name="duration_one" />
        <span htmlFor="duration_one" className={regular}>
          {`1 ${t(
            'GENERAL.HOUR',
          )}`}
        </span>
        <RadioButton name="duration_two" />
        <span htmlFor="duration_two" className={regular}>
          {`1 - 2 ${t(
            'GENERAL.HOURS',
          )}`}
        </span>
        <RadioButton name="duration_eight" />
        <span htmlFor="duration_eight" className={regular}>
          {`2 - 8 ${t(
            'GENERAL.HOURS',
          )}`}
        </span>
      </RadioGroup>
      {totalScore !== null && totalScore >= 0 ? (
        <AssessmentTotalScore
          score={totalScore}
          description={getDescriptionForScore(totalScore, t)}
          color={getColorForScore(totalScore)}
          scoreSubject={t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}
        />
      ) : null}
      {additionalTotalScore !== null &&
      additionalTotalScore >= 0 &&
      isControlRequired ? (
        <AssessmentTotalScore
          score={additionalTotalScore}
          description={getDescriptionForScore(additionalTotalScore, t)}
          color={getColorForScore(additionalTotalScore)}
          scoreSubject={t('SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION')}
        />
        ) : null}
    </div>
  );
};

export default AssessmentNiosh;
