import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { contactSalesProductType, Risks } from '../../../init/proto_client';
import { modalOverflow } from '../../../assets/jss/style';
import arrowRight from '../../../assets/img/icons/arrow-right.svg';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import { getComparisonList, removeComparison, resetComparisons } from '../comparisonsStore/actions';
import sliceLongString from '../../../lib/utils/sliceLongString';
import Tooltip from '../../../lib/ui/Tooltip';
import RiskGroupBadge from '../../../lib/ui/RiskGroupBadge/RiskGroupBadge';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import getColumns from '../../../containers/ItemTableContainer/utils/getColumns';
import { TableType } from '../../../containers/ItemTableContainer/constants/tableType';
import DepartmentCell from '../../../containers/ItemTableContainer/components/CustomCells/DepartmentCell';
import CreatedAtCell from '../../../containers/ItemTableContainer/components/CustomCells/CreatedAtCell';
import ActionCell from '../../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import { createExportActions } from '../../../containers/ItemTableContainer/components/TableReport';
import ActionToggleButton from '../../../lib/ui/ActionToggleButton';
import { getCurrentPageReportList } from '../../../containers/ItemTableContainer/constants/tablePageReportList';
import { routes } from '../../../containers/PrivatPageHOC/constants/routes';
import useNumberOfTrialDaysLeftBanner from '../../overview/useNumberOfTrialDaysLeftBanner';
import { openedModalSelector } from '../../../containers/ModalContainer/modalsStore/selectors';
import SoterTaskComparisonEmptyList from './components/SoterTaskComparisonEmptyList';
import ItemTableContainer from '../../../containers/ItemTableContainer/ItemTableContainer';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';
import StickyOnScrollContainer from '../../../containers/ItemTableContainer/components/StickyOnScrollContainer';
import Loader from '../../../lib/ui/Loader';
import ModalWrapper from '../../../containers/ModalContainer/ModalWrapper';
import UpgradeDialog from '../../components/UpgradeDialog';
import { contactSales } from '../../../auth/store/actions';
import ContactUsDialog from '../../../lib/ui/ContactUsDialog';

const SoterTaskContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [editedVideo] = useState(null);
  const { comparisonList, isLoading } = useAppSelector((state) => state.soterTaskComparisons);
  const { selectedCompany } = useAppSelector((state) => state.companies);

  useEffect(() => {
    if (selectedCompany.id) dispatch(getComparisonList(selectedCompany.id));
    return () => dispatch(resetComparisons());
  }, [selectedCompany]);

  const getRiskForBadge = (risk) => {
    switch (risk) {
      case Risks.LOW:
        return 'lower';
      case Risks.MEDIUM:
        return 'medium';
      case Risks.HIGH:
        return 'high';
      default:
        return '';
    }
  };

  const TextCell
    = (type) => ({ rowData }) => <div>{sliceLongString(rowData[type], 20)}</div>;

  const LinkCell
    = (type) => ({ rowData }) => (
      <div>
        <Tooltip text={rowData[type]}>
          <Link to={`${rowData.id}`} style={{ color: COLORS.GRAY[950] }}>
            <div
              style={{
                minHeight: '42px',
                minWidth: '370px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {sliceLongString(rowData[type], 30)}
            </div>
          </Link>
        </Tooltip>
      </div>
    );

  const RiskCell = ({ rowData }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <RiskGroupBadge
        noRiskGroup
        riskGroup={getRiskForBadge(rowData.firstTaskVideo?.risks?.overallRisk)}
      />
      <img
        width="11px"
        src={arrowRight}
        alt=">"
      />
      <RiskGroupBadge
        noRiskGroup
        riskGroup={getRiskForBadge(rowData.secondTaskVideo?.risks?.overallRisk)}
      />
    </div>
  );

  const ImprovementsCell = ({ rowData }) => {
    const ImprovementData = rowData.overallImprovement;
    const color = () => {
      if (ImprovementData > 0) {
        return COLORS.RED[600];
      }
      if (ImprovementData < 0) {
        return COLORS.GREEN[600];
      }
      return '#969A9D';
    };
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: color(ImprovementData),
        }}
      >
        {ImprovementData > 0 ? '+' : '-'}
        {Number(Math.ceil(Math.abs(ImprovementData)))}
        %
      </div>
    );
  };

  const columns = (getActionsForUser) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.soterTaskComparison),
    {},
    getActionsForUser,
    TableType.soterTaskComparison,
    t,
  );

  const cells = {
    title: LinkCell('title'),
    departmentAndSite: (props) => (
      <DepartmentCell
        {...props}
        sites={selectedCompany?.sitesList}
        departments={selectedCompany?.departmentsList}
      />
    ),
    createdAt: (props) => <CreatedAtCell {...props} x />,
    createdBy: TextCell('createdBy'),
    changesInRisk: RiskCell,
    improvements: ImprovementsCell,
    actions: ActionCell,
  };

  const actions = [
    {
      title: t('GENERAL.DELETE'),
      handler: ({ id }) => {
        dispatch(removeComparison(id));
      },
      attention: true,
    },
  ];

  const toglerButtonExport = (
    <ActionToggleButton
      name="exportReport"
      dataName="exportReport"
    />
  );

  const exportActions = createExportActions(getCurrentPageReportList(TableType.soterTaskComparison));
  const secondaryButton = [
    {
      text: t('WEARABLES.ACTIVITY.ACTIONS.EXPORT'),
      handler: () => {},
      dropdown: {
        type: 'exportReport',
        selectedItems: comparisonList,
        selectedCompany,
        exportActions,
        content: t('WEARABLES.ACTIVITY.ACTIONS.EXPORT'),
        togglerButton: toglerButtonExport,
      },
    },
  ];
  const filterButtonProps = {
    action: () => {
      navigate(`/${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.comparison}/${routes.anyNew}`);
    },
    text: t('SOTER_TASK.COMPARISONS_LIST.CREATE_NEW'),
  };

  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('geniusProduct');
  // func for upgrade button
  const { id: userId } = useAppSelector((state) => state.auth.profile);
  const openedModal = useAppSelector(openedModalSelector);
  const modalClass = modalOverflow;
  const overlayClass = Emotion.css`
    margin-top: 119px;
  `;
  const withCloseButton = true;

  if (isLoading) return <Loader />;

  return (
    <>
      {comparisonList.length === 0 ? (
        <>
          {numberOfTrialDaysLeftBanner && (
          <StickyOnScrollContainer
            topMargin={0}
            topBlock={numberOfTrialDaysLeftBanner}
            height={50}
            innerHeight={0}
          />
          )}
          <SoterTaskComparisonEmptyList />
        </>
      ) : (
        <ItemTableContainer
          items={comparisonList}
          selectedItems={[]}
          columns={columns}
          cells={cells}
          type={TableType.soterTaskComparison}
          isLoading={isLoading}
          actions={actions}
          editedItem={editedVideo}
          setOpenedModal={setOpenedModal}
          stickyOnScrollContainerTopBlock={numberOfTrialDaysLeftBanner}
          stickyOnScrollContainerHeight={
            numberOfTrialDaysLeftBanner ? 106 : undefined
          }
          secondaryButton={secondaryButton}
          filterButtonProps={filterButtonProps}
        />
      )}
      {openedModal === 'upgrade' || openedModal === 'contactSales' ? (
        <ModalWrapper
          closeModal={() => dispatch(setOpenedModal(null))}
          modalClass={modalClass}
          overlayClass={overlayClass}
          keepModalOnClickOutside
          withCloseButton={withCloseButton}
          closeWithoutConfirmation
        >
          {openedModal === 'upgrade' ? (
            <UpgradeDialog
              handleClick={() => {
                dispatch(
                  contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK }),
                );
                dispatch(setOpenedModal('contactSales'));
              }}
              days={selectedCompany.products?.geniusProduct?.currentTermEnd}
            />
          ) : (
            <ContactUsDialog
              handleClose={() => dispatch(setOpenedModal(null))}
            />
          )}
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default SoterTaskContainer;
