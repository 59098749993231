import React from 'react';
import { Link } from '@reach/router';
import { ReactComponent as ClockProcessing } from 'assets/img/icons/clockProcessing.svg';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { nameLink } from 'assets/soter-flex-styles';
import { TaskVideoStatus } from '../../../../init/proto_client';
import sliceLongString from '../../../../lib/utils/sliceLongString';
import Tooltip from '../../../../lib/ui/Tooltip';

const previewBox = Emotion.css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 52px;
  margin-right: 0.5rem;
`;

const preview = Emotion.css`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.WHITE};
  background-size: cover;
`;

const getPreviewComponent = (thumbnail, status) => {
  if (
    !thumbnail
    || status === TaskVideoStatus.WAITING_FOR_PROCESSING
    || status === TaskVideoStatus.PROCESSING
  ) {
    return <ClockProcessing />;
  }

  return (
    <div className={preview} style={{ backgroundImage: `url(${thumbnail})` }} />
  );
};

const VideoPreviewAndNameCell = ({ rowData, handler: goToVideo }) => (rowData.status === TaskVideoStatus.WAITING_FOR_APPROVAL
  || rowData.status === TaskVideoStatus.APPROVED
  || rowData.status === TaskVideoStatus.ARCHIVED ? (
    <Link className={nameLink} to={goToVideo(rowData.id)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={previewBox}>
          {getPreviewComponent(rowData.amazonThumbnailUrl, rowData.status)}
        </div>
        <Tooltip text={rowData.title} noTranslation>
          <div>{sliceLongString(rowData.title, 11) || '–'}</div>
        </Tooltip>
      </div>
    </Link>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className={previewBox}>
        {getPreviewComponent(rowData.amazonThumbnailUrl, rowData.status)}
      </div>
      <Tooltip text={rowData.title} noTranslation>
        <div>{sliceLongString(rowData.title, 11) || '–'}</div>
      </Tooltip>
    </div>
  ));

export default VideoPreviewAndNameCell;
