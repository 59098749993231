import { bodyPartNamesList } from '../video/constants';
const { Risks } = require('../../api/soter-task/common/v1/video_risks_enum_pb');
const prepareRisksForBarChart = (risksFromServer) => {
    if (!risksFromServer)
        return null;
    const risksMap = {};
    const hardcodedColor = (group) => {
        switch (group) {
            case Risks.LOW: return 'Green';
            case Risks.MEDIUM: return 'Yellow';
            case Risks.HIGH: return 'Red';
            default: return '';
        }
    };
    const selectTimeDistributionForBodyPart = (riskGroup, bodyPart, totalTime) => {
        const current = Object.keys(risksFromServer).find((val) => val.includes(bodyPart) && val.includes(hardcodedColor(riskGroup)));
        if (current)
            return Math.round((risksFromServer[current] / (totalTime / 100)) * 10) / 10;
    };
    Object.keys(bodyPartNamesList).forEach((part) => {
        const totalTime = Object.keys(risksFromServer).reduce((acc, risk) => ((risk.includes(part) && !risk.includes('Risk')) ? acc + risksFromServer[risk] : acc), 0);
        risksMap[part] = Object.values(Risks).map((riskGroup) => ({
            riskGroup,
            value: selectTimeDistributionForBodyPart(riskGroup, part, totalTime),
        }));
        risksMap[part].totalTime = Math.ceil(totalTime / 1000);
        risksMap[part].riskGroup = risksFromServer[`${part}Risk`];
    });
    return risksMap;
};
export default prepareRisksForBarChart;
