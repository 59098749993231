import { createAction, createSlice } from '@reduxjs/toolkit';
import { createAdminCompany, deleteAdminCompany, getAdminCompanies, getAvailableCoachingPrograms, getAvailableFirmwares, getAvailableLanguages, getAvailableTiers, getCompanyData, } from './actions';
import { initialCompanyData } from '../companies/company/constants';
import { formatDataForForm } from '../companies/company/utils/utils';
const initialState = {
    companyList: [],
    availableFirmwaresList: [],
    availableCoachingProgramList: [],
    availableTiersList: [],
    availableLanguagesList: [],
    companyData: initialCompanyData,
    selectedCompany: initialCompanyData,
    currentCompanyId: null,
    error: null,
    isLoading: false,
};
const pending = createAction('adminCompanies/pending');
const rejected = createAction('adminCompanies/rejected');
const adminCompaniesSlice = createSlice({
    name: 'adminCompanies',
    initialState,
    reducers: {
        resetCompanyForm: () => initialState,
        setCurrentCompanyId: (state, action) => {
            state.currentCompanyId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(getAdminCompanies.fulfilled, (state, action) => {
            if (action.payload) {
                state.companyList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(getAvailableFirmwares.fulfilled, (state, action) => {
            if (action.payload) {
                state.availableFirmwaresList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(getAvailableTiers.fulfilled, (state, action) => {
            if (action.payload) {
                state.availableTiersList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(getAvailableCoachingPrograms.fulfilled, (state, action) => {
            if (action.payload) {
                state.availableCoachingProgramList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(getAvailableLanguages.fulfilled, (state, action) => {
            if (action.payload) {
                state.availableLanguagesList = action.payload;
                state.isLoading = false;
            }
        });
        builder.addCase(createAdminCompany.fulfilled, (state, action) => {
            if (action.payload) {
                state.companyList = [...state.companyList, action.payload];
                state.isLoading = false;
            }
        });
        builder.addCase(getCompanyData.fulfilled, (state, action) => {
            if (action.payload) {
                state.selectedCompany = formatDataForForm(action.payload);
                state.isLoading = false;
            }
        });
        builder.addCase(deleteAdminCompany.fulfilled, (state, action) => {
            if (action.payload) {
                state.companyList = state.companyList.filter((company) => company.id !== action.payload);
            }
        });
    },
});
export const { resetCompanyForm, setCurrentCompanyId } = adminCompaniesSlice.actions;
export default adminCompaniesSlice.reducer;
