import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import moment from 'moment';
import { Text } from 'tabler-react';
import { COLORS } from 'constants/colors';
import { getActionHandlerForModal } from 'lib/utils/getActionHandlerForModal';
import Button from 'lib/ui/Button';
import { getDeviceHistory, getDevices, resetDevices } from '../../admin/devices/devicesStore/actions';
import sliceLongString from '../../lib/utils/sliceLongString';
import { DEPARTMENT_NOT_ASSIGNED } from '../../containers/ItemTableContainer/constants/tableValuesAndFilterOptions';
import CustomTooltip from '../../lib/ui/Tooltip';
import LinkToEntry from '../../lib/ui/LinkToEntry/LinkToEntry';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import DeviceHistoryDialog from '../../admin/devices/DeviceHistoryDialog';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import {
  getMultiSelectionChecked,
  selectionHandler
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import SelectionCell from '../../containers/ItemTableContainer/components/CustomCells/SelectionCell';
import DeviceName from '../../containers/ItemTableContainer/components/CustomCells/DeviceName';
import DeviceUserCell from '../../containers/ItemTableContainer/components/CustomCells/DeviceUserCell';
import DepartmentCell from '../../containers/ItemTableContainer/components/CustomCells/DepartmentCell';
import DeviceProgramCell from '../../containers/ItemTableContainer/components/CustomCells/DeviceProgramsCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';

const UniversalSimpleDeviceCell = ({ type, rowData }) => {
  const { lastBatteryCharge, lastSynced, department } = rowData;
  const { t } = useTranslation();
  let muted;
  let customClass;
  let text;

  switch (type) {
    case 'battery':
      customClass
        = lastBatteryCharge < 15 && lastBatteryCharge >= 0
          ? Emotion.css`
              {
                color: ${COLORS.RED[600]};
                font-size: 16px;
              }
        `
          : Emotion.css`
              {
                font-size: 16px;
              }
        `;
      text
        = lastBatteryCharge < 0 ? t('GENERAL.NO_DATA') : `${lastBatteryCharge}%`;
      muted = lastBatteryCharge < 0;
      break;
    case 'department':
      muted = !department;
      text
        = sliceLongString(department?.name, 15) || t(DEPARTMENT_NOT_ASSIGNED);
      break;
    case 'synced':
      muted = !lastSynced;
      text = lastSynced
        ? moment(lastSynced, 'X').fromNow()
        : t('WEARABLES.DEVICES.NEVER_SYNCED');
      customClass = Emotion.css`
      {
        font-size: 16px;
      }
      `;
      break;
    default:
      throw new Error('Unknown cell type - UniversalSimpleDeviceCell');
  }

  return (
    <Text className={customClass || ''} muted={muted}>
      <span style={{ wordBreak: 'keep-all' }}>{text.indexOf('ago') > -1 ? text.slice(0, text.length - 3) : text}</span>
    </Text>
  );
};

const DevicesContainer = () => {
  const modalClass = Emotion.css`
    min-width: 680px;
  `;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { devices, isLoading, deviceHistory } = useSelector(
    (state) => state.devices,
  );
  const { devices: selectedDevices } = useSelector(
    (state) => state.selectedItems,
  );
  const { selectedCompany } = useSelector((state) => state.companies);
  const companyId = selectedCompany?.id;

  const [openedModal, setOpenedModal] = useState(null);
  const [editedDevice, setEditedDevice] = useState(null);
  const [isClicked, setIsClicked] = useState(1);
  const { categories } = useSelector((state) => state.category);
  const departmentAndSiteCategories = categories?.filter(
    (category) => category.title === 'department' || category.title === 'site',
  );

  useEffect(() => {
    if (deviceHistory.length) setOpenedModal('deviceHistory');
  }, [deviceHistory]);

  useEffect(() => {
    if (companyId) {
      dispatch(getDevices(companyId));
    }
    return () => dispatch(resetDevices());
  }, [companyId]);

  const DeviceActionTogglerButton = ({
    id,
    clickHandler,
    disabled,
    content,
    name,
    dataName,
    hasTooltip,
  }) => {
    const dropdownBtn = (
      <Button
        text={content}
        appearance="secondary"
        type="button"
        name={id || name}
        dataName={id ? `user-dropdown-${id}` : dataName}
        handler={() => clickHandler()}
        disabled={disabled}
      />
    );

    return hasTooltip ? (
      <CustomTooltip
        placement="topEnd"
        text={t('WEARABLES.DEVICES.DEVICE_ACTIONS_TOOLTIP')}
      >
        {dropdownBtn}
      </CustomTooltip>
    ) : (
      dropdownBtn
    );
  };

  const togglerButton = (
    <DeviceActionTogglerButton
      name="devices-multi"
      dataName="multi-device-dropdown"
      hasTooltip="hasTooltip"
    />
  );

  const closeModals = () => setOpenedModal(null);

  const switchModal = (modalType) => {
    switch (modalType) {
      case 'entries':
        return (
          <LinkToEntry
            title={t('WEARABLES.DEVICES.ACTIONS.LINK')}
            categories={departmentAndSiteCategories}
            companyId={companyId}
            setOpenedModal={setOpenedModal}
            userIds={[editedDevice.id]}
            handlerFnForDevices={(e, userIDs, departmentID, setCategory) => {
              getActionHandlerForModal(setCategory, TableType.devices)(
                closeModals,
                dispatch,
              )(e, userIDs, departmentID);
            }}
          />
        );
      case 'deviceHistory':
        return <DeviceHistoryDialog deviceHistory={deviceHistory} companyID={companyId} />;
      default:
        return null;
    }
  };

  const columns = (getActionForDevices) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.devices),
    // eslint-disable-next-line no-undef
    _,
    getActionForDevices,
    TableType.devices,
    t,
  );

  const cells = {
    selection: SelectionCell,
    device: DeviceName,
    user: DeviceUserCell,
    battery: UniversalSimpleDeviceCell,
    synced: UniversalSimpleDeviceCell,
    department: DepartmentCell,
    programs: DeviceProgramCell,
    actions: ActionCell,
  };

  const actions = [
    {
      title: t('WEARABLES.DEVICES.ACTIONS.LINK'),
      handler: (device) => {
        setEditedDevice(device);
        setOpenedModal('entries');
      },
    },
    {
      title: t('WEARABLES.DEVICES.USAGE_HISTORY'),
      handler: (device) => {
        dispatch(getDeviceHistory(device.id));
      },
    },
  ];

  const deviceActions = [
    {
      id: 'linkToEntry',
      name: t('WEARABLES.DEVICES.ACTIONS.LINK'),
      color: COLORS.GRAY[950],
      size: 'small',
      child: (
        <LinkToEntry
          title={t('WEARABLES.DEVICES.ACTIONS.LINK')}
          categories={departmentAndSiteCategories}
          companyId={companyId}
          setOpenedModal={setOpenedModal}
          userIds={selectedDevices.map((device) => device.id)}
          handlerFnForDevices={(e, userIDs, departmentID, setCategory) => {
            getActionHandlerForModal(setCategory, TableType.devices)(
              closeModals,
              dispatch,
            )(e, userIDs, departmentID);
          }}
          setUserForTagShown
        />
      ),
      disabled: selectedDevices?.length === 0,
    },
  ];

  const buttonProps = [
    {
      bubble: selectedDevices.length,
      tooltip: t('WEARABLES.DEVICES.DEVICE_ACTIONS_TOOLTIP'),
      dropdown: {
        type: 'device',
        selectedItems: selectedDevices,
        selectedCompany,
        userActions: deviceActions,
        handler: () => setIsClicked(isClicked + 1),
        content: t('WEARABLES.DEVICES.DEVICE_ACTIONS'),
        togglerButton,
      },
      isClicked,
      disabled: selectedDevices.length < 1,
    },
  ];

  return (
    <ItemTableContainer
      actions={actions}
      cells={cells}
      columns={columns}
      isLoading={isLoading}
      items={devices}
      modalClass={modalClass}
      openedModal={openedModal}
      secondaryButton={buttonProps}
      selectedItems={selectedDevices}
      setOpenedModal={setOpenedModal}
      switchModal={switchModal}
      type={TableType.devices}
    />
  );
};

export default DevicesContainer;
