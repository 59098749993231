import { regions } from '../constants';
import { PRODUCT_INFO } from '../adminCompanyDefaultSettings';

export const getRegionOptions = () => regions.map((item) => ({ value: item, label: item }));

const getFirmwareName = (url) => url?.substring(url?.lastIndexOf('/') + 1);
const getFirmwareElement = (firmareItem) => ({
  value: firmareItem?.id,
  label: `version: ${firmareItem?.version}, name: ${getFirmwareName(firmareItem?.url)}`,
});

export const getFirmwaresOptions = (items) =>
  items?.map((item) => ({
    value: item?.id,
    label: `version: ${item?.version}, name: ${getFirmwareName(item?.url)}`,
  }));

const selectFields = new Set(['region', 'resellerId', 'firmwareId', 'tierId']);
const multiselectFields = new Set(['availableCoachingProgramIdsList']);

const getFormattedValue = (value, key) => {
  if (selectFields.has(key)) {
    return value?.value || null;
  }
  if (multiselectFields.has(key)) {
    return value?.map((item) => item.value);
  }
  return value;
};

export const transformData = (data) => {
  const res = {};
  const dataToArray = Object.entries(data);
  dataToArray.forEach(([key, value]) => {
    res[key] = getFormattedValue(value, key);
  });
  return res;
};

export const formatDataForForm = (data) => {
  const {
    id,
    name,
    region,
    availableProducts,
    availableCoachingProgramsList,
    currentFirmware,
    tier,
    wearableSettings,
    soterTaskSettings,
    departmentsList,
  } = data;
  const { wearable, jobDictionary, genius } = availableProducts;
  const getValueObject = (field) => (field ? { value: field?.id, label: field?.name } : undefined);
  const { blurFaceEnable, blurBackgroundEnable, officeAssessment } = soterTaskSettings;
  let { videoDuration } = soterTaskSettings;
  const typeOfWearing = {
    spine: false,
    headset: false,
    shoulder: false,
    helmet: false,
  };
  if (videoDuration) videoDuration /= 60000;
  availableCoachingProgramsList.map((program) => {
    // eslint-disable-next-line default-case
    // TODO: Change switch to program IDs and avoid using hardcoded strings in code
    switch (program.name) {
      case 'Back Manual Handling Training':
        typeOfWearing.spine = true;
        break;
      case 'Back Manual Handling Program Headset':
        typeOfWearing.headset = true;
        break;
      case 'Shoulder Manual Handling Training':
        typeOfWearing.shoulder = true;
        break;
      case 'Back Manual Handling Program Helmet':
        typeOfWearing.helmet = true;
        break;
    }
  });

  return {
    id,
    name,
    region: { value: region, label: region },
    wearable: wearable || PRODUCT_INFO,
    jobDictionary: jobDictionary || PRODUCT_INFO,
    genius: genius || PRODUCT_INFO,
    spine: typeOfWearing.spine || wearableSettings?.typeWearing?.spine,
    shoulder: typeOfWearing.shoulder || wearableSettings?.typeWearing?.shoulder,
    headset: typeOfWearing.headset || wearableSettings?.typeWearing?.headset,
    helmet: typeOfWearing.helmet || wearableSettings?.typeWearing?.helmet,
    availableCoachingProgramIdsList: availableCoachingProgramsList.map((p) => getValueObject(p)),
    firmwareId: currentFirmware ? getFirmwareElement(currentFirmware) : null,
    inputLoginMethod: wearableSettings?.inputLoginMethod,
    tierId: getValueObject(tier),
    employeeLoginPrompt: wearableSettings?.employeeLoginPrompt,
    workdayTimeMinutes: wearableSettings?.workdayTimeMinutes,
    workdayIntervalMinutes: wearableSettings?.workdayIntervalMinutes,
    programTargetDays: wearableSettings?.programTargetDays,
    collectJobRoleOnRegister: wearableSettings?.collectJobRoleOnRegister,
    reportPeopleName: wearableSettings?.reportPeopleNames,
    collectEmployeeName: wearableSettings?.collectEmployeeName,
    availableLanguagesList: wearableSettings?.availableLanguagesList,
    nicknameRegexpTemplate: wearableSettings?.nicknameRegexpTemplate,
    disableNotifications: wearableSettings?.disableNotifications,
    downloadDataNotOnlyFromDevicesOnCharge: wearableSettings?.downloadDataNotOnlyFromDevicesOnCharge,
    showOnboardingPages: wearableSettings?.showOnboardingPages,
    enableGamification: wearableSettings?.enableGamification,
    blurFaceEnable,
    blurBackgroundEnable,
    officeAssessment,
    videoDuration,
    departmentsList,
  };
};
