import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { assessmentTableTitle } from '../../assets/jss/style';
import { SINGLE_PAGE_TYPE } from '../../containers/PdfContainer/constants';
import SecondaryHeader from '../../containers/PdfContainer/components/SecondaryHeader';
import { border } from '../../containers/PdfContainer/styles';
import { getAssessmentType } from '../video/utils/getAssessmentType';
import SinglePage from '../../containers/PdfContainer/SinglePage';

const AssessmentsReportList = ({ title, assessmentsData, withHeader }) => {
  const { t } = useTranslation();
  return (
    <>
      {assessmentsData.map((page, pageindex) => (
        <SinglePage key={page[0].type || page[0].id} type={SINGLE_PAGE_TYPE.VERTICAL}>
          <SecondaryHeader title={title} type="SoterTask" />
          {withHeader && (
            <header style={{ fontSize: '16px', fontWeight: '600', margin: '20px 0 40px 0' }}>
              {withHeader}
            </header>
          )}
          { page.map((assessment, index) => (
            <div style={{ marginTop: '15px' }}>
              {
                assessment.type === 'videoTitle' ? (
                  <div>
                    <header style={{ fontSize: '16px', fontWeight: '600', margin: '20px 0 40px 0' }}>
                      Video
                      {'  '}
                      { `${index === 0 ? 1 : 2}: ${assessment.title}`}
                    </header>
                    {
                      (!assessmentsData[pageindex][index + 1]?.type
                        || !Number.isInteger(assessmentsData[pageindex][index + 1]?.type)) && (
                        <>
                          <div style={{ marginBottom: '15px' }}>
                            {t('SOTER_TASK.NO_ASSESSMENTS')}
                          </div>
                          <div style={{ width: '680px' }} className={border} />
                        </>
                      )
                    }
                  </div>
                ) : (
                  <div style={{ fontSize: '14px', display: 'grid', gridTemplateColumns: '30% 30% 30%' }}>
                    <div style={{ display: 'flex', marginBottom: '15px' }}>
                      <div className={assessmentTableTitle}>{`${t('GENERAL.NAME')}:`}</div>
                      <div>
                        {assessment.title}
                      </div>
                    </div>

                    <div style={{ display: 'flex', marginBottom: '15px' }}>
                      <div className={assessmentTableTitle}>{`${t('GENERAL.TYPE')}:`}</div>
                      <div>{getAssessmentType(assessment.type)}</div>
                    </div>

                    <div style={{ display: 'flex', marginBottom: '15px' }}>
                      <div className={assessmentTableTitle}>{`${t('SOTER_TASK.ASSESSMENT.SCORE')}:`}</div>
                      <div>{assessment.totalScore}</div>
                    </div>

                    <div>
                      <div style={{ display: 'flex', marginBottom: '15px' }}>
                        <div className={assessmentTableTitle}>{`${t('SOTER_TASK.CREATED_DATE')}:`}</div>
                        <div>
                          {moment(assessment.createdAt, 'X').format('MMM Do YYYY')}
                        </div>
                      </div>
                      <div style={{ width: '680px' }} className={border} />
                    </div>

                    <div style={{ display: 'flex', marginBottom: '15px' }}>
                      <div className={assessmentTableTitle}>{`${t('MY_COMPANY.USERS.CREATED_BY')}:`}</div>
                      <div>{assessment.createdBy}</div>
                    </div>
                  </div>
                )
              }
            </div>
          ))}
        </SinglePage>
      ))}
    </>
  );
};

export default AssessmentsReportList;
