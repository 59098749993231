import * as Emotion from 'emotion';
import { COLORS, HAZARDS_COLORS } from 'constants/colors';

export const heightAuto = Emotion.css`
  && {
    height: auto;
  }
`;

export const daySelect = Emotion.css`
  width: 190px;
  margin-left: 61.5%;
  font-size: 0.8rem;
  color: ${COLORS.GRAY[950]};

  .custom-select__control {
    min-height: 42px;
  }

  .custom-select__value-container {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const progressTopTextBlock = Emotion.css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 147px;
`;

export const progressWrapper = Emotion.css`
  position: relative;
  width: 147px;
  height: 4px;
  border-radius: 4px;
  background: ${COLORS.GRAY[100]};
  overflow: hidden;
`;

export const progressBar = Emotion.css`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  border-radius: 4px;
  background: ${HAZARDS_COLORS.SPINE.BACK_TWISTING[600]};
`;
export const hazardsByTaskChart = Emotion.css`{
  .tick {
    font-size: .75rem;
    font-weight: 600;
  }

  .y-axis .tick {
    font-weight: normal;
  }
}`;
export const hazardsDisplayTypeSelect = Emotion.css`{
  select {
    margin-left: 20px;
    text-transform: uppercase;
    width: 190px;
  }
}`;
