import React from 'react';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { secondaryHeader, secondaryHeaderTitle } from '../styles';

const SecondaryHeader = ({ title, type }) => (
  <div className={secondaryHeader}>
    <div className={secondaryHeaderTitle}>{`${title}.`}</div>
    <div className={text(COLORS.GRAY[950], 24, 700)}>{type}</div>
  </div>
);

export default SecondaryHeader;
