export default function (inputString, substringLength) {
    const substrings = [];
    let currentPosition = 0;
    const { length } = inputString;
    while (currentPosition < length) {
        let substring = inputString.substring(currentPosition, currentPosition + substringLength);
        if (currentPosition + substringLength < length) {
            const lastSpaceIndex = substring.lastIndexOf(' ');
            if (lastSpaceIndex !== -1) {
                // Truncate the last word if it exceeds the substring length
                substring = substring.substring(0, lastSpaceIndex);
            }
        }
        substrings.push(substring.trim());
        currentPosition += substring.length;
    }
    return substrings;
}
