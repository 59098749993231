export const COLORS = {
    WHITE: '#fff',
    BLACK: '000',
    RED: {
        50: '#fef3f2',
        100: '#fde9e6',
        200: '#fbd3d0',
        300: '#f7aeaa',
        400: '#f27e7a',
        500: '#e94b4a',
        600: '#d93b42',
        700: '#b31d27',
        800: '#961b27',
        900: '#811a28',
        950: '#480910',
    },
    YELLOW: {
        50: '#fdfbe9',
        100: '#fbf5c6',
        200: '#f7ea91',
        300: '#f2d752',
        400: '#edc222',
        500: '#ddaa15',
        600: '#c48810',
        700: '#985f10',
        800: '#7e4c15',
        900: '#6b3e18',
        950: '#3f1f09',
    },
    ORANGE: {
        50: '#fffbeb',
        100: '#fdf3c8',
        200: '#fce48b',
        300: '#fad14f',
        400: '#f8bd27',
        500: '#f29c0e',
        600: '#de7a09',
        700: '#b2520b',
        800: '#903f10',
        900: '#773410',
        950: '#441a04',
    },
    GREEN: {
        50: '#f0fdf5',
        100: '#dcfce8',
        200: '#bbf7d1',
        300: '#86efad',
        400: '#4ade81',
        500: '#22c55e',
        600: '#16a34a',
        700: '#15803c',
        800: '#166533',
        900: '#14532b',
        950: '#052e14',
    },
    LIME: {
        50: '#f7fee7',
        100: '#edfccb',
        200: '#dbf99d',
        300: '#c1f264',
        400: '#a7e635',
        500: '#88cc16',
        600: '#68a30d',
        700: '#4f7c0f',
        800: '#416212',
        900: '#375314',
        950: '#1b2e05',
    },
    LIGHT_BLUE: {
        50: '#eefaff',
        100: '#daf3ff',
        200: '#bdebff',
        300: '#8fe1ff',
        400: '#5acdff',
        500: '#34b1fd',
        600: '#2096f3',
        700: '#167bdf',
        800: '#1864b5',
        900: '#1a558e',
        950: '#153456',
    },
    BLUE: {
        50: '#f0f7ff',
        100: '#e0eefe',
        200: '#bbdefc',
        300: '#7ec4fb',
        400: '#3aa5f6',
        500: '#108ae7',
        600: '#046cc5',
        700: '#05569f',
        800: '#084a84',
        900: '#0d3f6d',
        950: '#092848',
    },
    INDIGO: {
        50: '#eef1ff',
        100: '#e1e6fe',
        200: '#c8d1fd',
        300: '#a7b1fa',
        400: '#8489f5',
        500: '#6966ee',
        600: '#584ae1',
        700: '#4c3bc7',
        800: '#3e33a0',
        900: '#36307f',
        950: '#29235c',
    },
    GRAY: {
        50: '#f6f6f9',
        100: '#ededf1',
        200: '#d6d6e1',
        300: '#b3b5c6',
        400: '#8a8da6',
        500: '#6b6e8c',
        600: '#565873',
        700: '#46475e',
        800: '#3d3e4f',
        900: '#363744',
        950: '#16161c',
    },
    NEUTRAL: {
        50: '#f4f3f2',
        100: '#e2e1df',
        200: '#c8c4c0',
        300: '#a8a29c',
        400: '#8e8881',
        500: '#807872',
        600: '#6d6561',
        700: '#59524f',
        800: '#4d4846',
        900: '#443f3f',
        950: '#262322',
    },
};
export const HAZARDS_COLORS = {
    SPINE: {
        INTENSE_BENDING: {
            50: '#fff1f3',
            100: '#ffe4e9',
            200: '#fecdd5',
            300: '#fda4b3',
            400: '#fb7189',
            500: '#f43f5e',
            600: '#e74a65',
            700: '#be122f',
            800: '#9f122a',
            900: '#881327',
            950: '#4c0511',
        },
        POOR_BENDING: {
            50: '#faf5ff',
            100: '#f4e8ff',
            200: '#ebd5ff',
            300: '#dab4fe',
            400: '#c184fc',
            500: '#a855f7',
            600: '#a75cee',
            700: '#7a22ce',
            800: '#6621a8',
            900: '#531c87',
            950: '#370764',
        },
        AWKWARD_STATIC_POSTURE: {
            50: '#eff5ff',
            100: '#dbe8fe',
            200: '#bfd7fe',
            300: '#93bbfd',
            400: '#609afa',
            500: '#3b82f6',
            600: '#518def',
            700: '#1d64d8',
            800: '#1e55af',
            900: '#1e478a',
            950: '#172e54',
        },
        BACK_TWISTING: {
            50: '#fff8eb',
            100: '#feeac7',
            200: '#fdd28a',
            300: '#fcbb4d',
            400: '#fbab24',
            500: '#f59e0b',
            600: '#e1a238',
            700: '#b47409',
            800: '#92610e',
            900: '#78510f',
            950: '#452c03',
        },
        REPETITION: {
            50: '#ecfdf7',
            100: '#d1faec',
            200: '#a7f3da',
            300: '#6ee7bf',
            400: '#34d39e',
            500: '#10b981',
            600: '#37ab85',
            700: '#047852',
            800: '#065f42',
            900: '#064e36',
            950: '#022c1e',
        },
    },
    SHOULDER: {
        STATIC_ARM_ELEVATION: {
            50: '#fdf2f7',
            100: '#fce7f1',
            200: '#fbcfe5',
            300: '#f9a8d0',
            400: '#f472b2',
            500: '#ec4899',
            600: '#e25299',
            700: '#be186a',
            800: '#9d1759',
            900: '#83184d',
            950: '#50072b',
        },
        OVEREXERTION: {
            50: '#fef9e8',
            100: '#fef0c3',
            200: '#fee28a',
            300: '#fdd147',
            400: '#fac215',
            500: '#eab308',
            600: '#d5ae36',
            700: '#a17c07',
            800: '#85680e',
            900: '#715a12',
            950: '#423306',
        },
        ARM_ELEVATION: {
            50: '#eeeeff',
            100: '#e0e1ff',
            200: '#c7c8fe',
            300: '#a5a7fc',
            400: '#8184f8',
            500: '#6366f1',
            600: '#6b6dea',
            700: '#383bca',
            800: '#3032a3',
            900: '#2e3081',
            950: '#1b1c4b',
        },
        PUSHING_AND_PULLING: {
            50: '#f0fdfc',
            100: '#ccfbf6',
            200: '#99f6ec',
            300: '#5eeadb',
            400: '#2dd4c2',
            500: '#14b8a6',
            600: '#3da99d',
            700: '#0f766e',
            800: '#115e56',
            900: '#134e48',
            950: '#042f2a',
        },
        REPETITIVE_ARM_MOVEMENTS: {
            50: '#f5fee7',
            100: '#e9fccb',
            200: '#d5f99d',
            300: '#baf264',
            400: '#a0e635',
            500: '#84cc16',
            600: '#86b53d',
            700: '#517c0f',
            800: '#426212',
            900: '#3a5314',
            950: '#1e2e05',
        },
    },
};
export const CHART_GREY = [
    COLORS.GRAY[100],
    COLORS.GRAY[200],
    COLORS.GRAY[400],
    COLORS.GRAY[600],
    COLORS.GRAY[950],
];
export const CHART_COLORS_SPINE = [
    HAZARDS_COLORS.SPINE.INTENSE_BENDING[600],
    HAZARDS_COLORS.SPINE.REPETITION[600],
    HAZARDS_COLORS.SPINE.BACK_TWISTING[600],
    HAZARDS_COLORS.SPINE.POOR_BENDING[600],
    HAZARDS_COLORS.SPINE.AWKWARD_STATIC_POSTURE[600],
];
export const CHART_COLORS_SHOULDER = [
    HAZARDS_COLORS.SHOULDER.OVEREXERTION[600],
    HAZARDS_COLORS.SHOULDER.REPETITIVE_ARM_MOVEMENTS[600],
    HAZARDS_COLORS.SHOULDER.PUSHING_AND_PULLING[600],
    HAZARDS_COLORS.SHOULDER.ARM_ELEVATION[600],
    HAZARDS_COLORS.SHOULDER.STATIC_ARM_ELEVATION[600],
];
export const ACTIVE_USERS_CHART = [
    '#029cc3',
    '#bd2811',
    '#cfc623',
    '#7d2187',
];
