var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toCamel } from 'snake-camel';
import { setNotification } from 'containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from 'containers/NotificationContainer/notifications';
import { createRebaWeightsRequest, createTaskVideoRequest, deleteRebaWeightsRequest, getMetaData, getRebaWeightsRequest, getTaskVideosRequest, rebaWeightsStreamRequest, taskDashboardVideoService, 
// @ts-ignore
TaskVideoPlatform, taskVideoRequest, taskVideosStreamRequest, 
// @ts-ignore
TaskVideoStatus, updateTaskVideoRequest, UploadTaskVideoRequest, } from 'init/proto_client';
import { openedModalSelector } from '../../../containers/ModalContainer/modalsStore/selectors';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';
const pending = (dispatch) => dispatch({
    type: 'videos/pending',
    payload: true,
});
const rejected = (dispatch, message) => dispatch({
    type: 'videos/rejected',
    payload: message,
});
export const getVideos = createAsyncThunk('videos/getVideos', (companyId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    getTaskVideosRequest.setCompanyId(companyId);
    yield taskDashboardVideoService.getVideosList(getTaskVideosRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            const { videosList } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'videos/getVideos/fulfilled',
                payload: videosList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, { text: error.message, code: error.code });
            error.code !== 5 && thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    });
}));
export const getVideoStatusStream = createAsyncThunk('videos/getVideoStatusStream', (companyId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    taskVideosStreamRequest.setCompanyId(companyId);
    const connectToStream = () => {
        const stream = taskDashboardVideoService.getEventsForVideos(taskVideosStreamRequest, 
        // @ts-ignore
        getMetaData());
        stream.on('data', (data) => {
            const parsedData = data.toObject().status;
            if (parsedData === 1) {
                thunkAPI.dispatch({
                    type: 'videos/getVideoStatusStream/fulfilled',
                    payload: true,
                });
            }
        });
        // stream.on('error', (data: any) => {
        //   console.log('error', data);
        //   stream.cancel();
        // });
    };
    connectToStream();
}));
export const getRebaWeightsStream = createAsyncThunk('videos/getRebaWeightsStream', (videoId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    rebaWeightsStreamRequest.setVideoId(videoId);
    const connectToStream = () => {
        const stream = taskDashboardVideoService.getEventsForRebaWeights(rebaWeightsStreamRequest, 
        // @ts-ignore
        getMetaData());
        stream.on('data', (data) => {
            if (data.toObject().status !== 2) {
                connectToStream();
            }
            else {
                thunkAPI.dispatch({
                    type: 'videos/getRebaWeightsStream/fulfilled',
                    payload: true,
                });
                stream.cancel();
            }
        });
        stream.on('error', (error) => {
            // err code === 2 - End of file, err code === 5 Not Found
            if (error.code !== 2 && error.code !== 5) {
                connectToStream();
            }
            else {
                thunkAPI.dispatch({
                    type: 'videos/getRebaWeightsStream/fulfilled',
                    payload: true,
                });
                stream.cancel();
            }
        });
    };
    connectToStream();
}));
export const createTaskVideo = createAsyncThunk('videos/createTaskVideo', ({ companyId, newVideo }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const { title, jobRole, taskName, videoType, repetition, fileSize, fileName, duration, videoCategory, department, site, } = newVideo;
    createTaskVideoRequest.setCompanyId(companyId);
    createTaskVideoRequest.setTitle(title);
    createTaskVideoRequest.setFileSize(fileSize);
    createTaskVideoRequest.setFileName(fileName);
    createTaskVideoRequest.setPlatform(TaskVideoPlatform.DASHBOARD_UPLOAD);
    createTaskVideoRequest.setJobRole(jobRole);
    createTaskVideoRequest.setTaskName(taskName);
    createTaskVideoRequest.setRepetition(Number(repetition));
    createTaskVideoRequest.setVideoType(videoType);
    createTaskVideoRequest.setTaskDuration(duration);
    createTaskVideoRequest.setCategory(videoCategory);
    createTaskVideoRequest.setDepartmentId(department);
    createTaskVideoRequest.setSiteId(site);
    yield taskDashboardVideoService.createVideo(createTaskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/createTaskVideo/fulfilled',
                payload: response.toObject(),
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.REMOVE_TASK_VIDEO_FAILED_MSG,
            }));
        }
    });
}));
const { RebaWeight, } = require('../../../api/soter-task/common/v1/video_message_pb');
const prepareWeights = (weights) => weights.map((w) => {
    const preparedWeight = new RebaWeight();
    preparedWeight
        .setId(w.id)
        .setTaskVideoId(w.id)
        .setWeightType(w.weightType)
        .setWeight(w.weight)
        .setTimeStart(w.timeStart)
        .setTimeEnd(w.timeEnd);
    return preparedWeight;
});
export const createRebaWeights = createAsyncThunk('videos/createRebaWeights', ({ weights }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    createRebaWeightsRequest.setTaskVideosRebaWeightsList(prepareWeights(weights));
    yield taskDashboardVideoService.createTaskVideosRebaWeights(createRebaWeightsRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/createRebaWeights/fulfilled',
                payload: response.toObject(),
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: weights.length === 1
                    ? notificationMessages.SAVE_REBAWEIGHT_SUCCESS_MSG
                    : notificationMessages.SAVE_REBAWEIGHTS_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
        }
    });
}));
export const getRebaWeights = createAsyncThunk('videos/getRebaWeights', ({ id, setIsLoading }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    getRebaWeightsRequest.setTaskVideoId(id);
    yield taskDashboardVideoService.getTaskVideoRebaWeights(getRebaWeightsRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            const weights = response.toObject().rebaWeightsList.filter((weight) => weight.weightType !== 'empty');
            thunkAPI.dispatch({
                type: 'videos/getRebaWeights/fulfilled',
                payload: weights,
            });
            setTimeout(() => setIsLoading(false), 1000);
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
        }
    });
}));
export const deleteRebaWeights = createAsyncThunk('videos/deleteRebaWeights', ({ ids, isNotification }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    deleteRebaWeightsRequest.setIdsList(ids);
    yield taskDashboardVideoService.deleteTaskVideoRebaWeights(deleteRebaWeightsRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/deleteRebaWeights/fulfilled',
                payload: response.toObject(),
            });
            if (isNotification) {
                thunkAPI.dispatch(setNotification({
                    type: notificationTypes.TYPE_SUCCESS,
                    message: notificationMessages.SAVE_REBAWEIGHTS_SUCCESS_MSG,
                }));
            }
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
        }
    });
}));
export const uploadTaskVideo = createAsyncThunk('videos/uploadTaskVideo', ({ id, chunkData }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const uploadTaskVideoRequest = new UploadTaskVideoRequest();
    uploadTaskVideoRequest.setVideoId(id);
    uploadTaskVideoRequest.setChunkData(chunkData);
    yield taskDashboardVideoService.uploadVideoByChunk(uploadTaskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/uploadTaskVideo/fulfilled',
                payload: response.toObject(),
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            // thunkAPI.dispatch(
            //   setNotification({
            //     type: notificationTypes.TYPE_FAILED,
            //     message: notificationMessages.REMOVE_TASK_VIDEO_FAILED_MSG,
            //   }),
            // );
        }
    });
}));
export const getTaskVideo = createAsyncThunk('videos/getTaskVideo', (id, thunkAPI) => new Promise((resolve, reject) => {
    taskVideoRequest.setVideoId(id);
    const videoPromise = new Promise((resolve, reject) => {
        taskDashboardVideoService.getVideo(taskVideoRequest, 
        // @ts-ignore
        getMetaData(), (error, response) => {
            if (response) {
                resolve(response.toObject());
            }
            else if (error) {
                reject(error.message);
            }
        });
    });
    const movementDataPromise = new Promise((resolve, reject) => {
        taskDashboardVideoService.getMovementData(taskVideoRequest, 
        // @ts-ignore
        getMetaData(), (error, response) => {
            if (response) {
                resolve(response);
            }
            else if (error) {
                reject(error.message);
            }
        });
    });
    // Execute both promises and handle their outcomes
    Promise.all([videoPromise, movementDataPromise])
        .then((response) => {
        resolve(response);
    })
        .catch((errorMessage) => {
        rejected(thunkAPI.dispatch, errorMessage);
        thunkAPI.dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.REMOVE_TASK_VIDEO_FAILED_MSG,
        }));
        reject(errorMessage);
    });
}));
export const getRebaData = createAsyncThunk('videos/getRebaData', (id, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    taskVideoRequest.setVideoId(id);
    yield taskDashboardVideoService.getRebaData(taskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
        }
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/getRebaData/fulfilled',
                payload: toCamel(JSON.parse(response.toObject().data)),
            });
        }
    });
}));
export const updateVideo = createAsyncThunk('videos/updateVideo', ({ id, videoInfo }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const { title, taskName, jobRole, repetition, duration, risk, description, department, site, } = videoInfo;
    updateTaskVideoRequest.setVideoId(id);
    updateTaskVideoRequest.setTitle(title);
    updateTaskVideoRequest.setStatus(null);
    updateTaskVideoRequest.setTaskName(taskName);
    updateTaskVideoRequest.setJobRole(jobRole);
    updateTaskVideoRequest.setTaskDuration(duration);
    updateTaskVideoRequest.setRepetition(repetition);
    updateTaskVideoRequest.setOverallRisk(risk);
    updateTaskVideoRequest.setDescription(description);
    updateTaskVideoRequest.setDepartmentId(department);
    updateTaskVideoRequest.setSiteId(site);
    yield taskDashboardVideoService.updateVideo(updateTaskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            const video = response.toObject();
            thunkAPI.dispatch({
                type: 'videos/updateVideo/fulfilled',
                payload: video,
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.EDIT_TASK_VIDEO_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.EDIT_TASK_VIDEO_FAILED_MSG,
            }));
        }
    });
}));
export const updateVideoDescription = createAsyncThunk('videos/updateVideoDescription', ({ videoInfo, description }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const { id, title, taskName, jobRole, duration, repetition, risk, } = videoInfo;
    updateTaskVideoRequest.setVideoId(id);
    updateTaskVideoRequest.setDescription(description);
    updateTaskVideoRequest.setTitle(title);
    updateTaskVideoRequest.setStatus(null);
    updateTaskVideoRequest.setTaskName(taskName === null || taskName === void 0 ? void 0 : taskName.title);
    updateTaskVideoRequest.setJobRole(jobRole === null || jobRole === void 0 ? void 0 : jobRole.title);
    updateTaskVideoRequest.setTaskDuration(duration);
    updateTaskVideoRequest.setRepetition(repetition);
    updateTaskVideoRequest.setOverallRisk(risk);
    yield taskDashboardVideoService.updateVideo(updateTaskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/updateVideoDescription/fulfilled',
                payload: response.toObject(),
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.DESCRIPTION_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.EDIT_TASK_VIDEO_FAILED_MSG,
            }));
        }
    });
}));
const showNotification = (videoStatus) => {
    if (videoStatus === TaskVideoStatus.APPROVED) {
        return notificationMessages.APPROVE_TASK_VIDEO_SUCCESS_MSG;
    }
    if (videoStatus === TaskVideoStatus.ARCHIVED) {
        return notificationMessages.ARCHIVE_TASK_VIDEO_SUCCESS_MSG;
    }
    return notificationMessages.DISAPPROVE_TASK_VIDEO_SUCCESS_MSG;
};
export const updateVideoStatus = createAsyncThunk('videos/updateVideoStatus', ({ currentVideo, getStatus, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const { id, title, description, videoType, taskName, jobRole, repetition, taskDuration, overallRisk, category, siteId, departmentId, } = currentVideo;
    updateTaskVideoRequest.setVideoId(id);
    updateTaskVideoRequest.setTitle(title);
    updateTaskVideoRequest.setStatus(getStatus);
    updateTaskVideoRequest.setDescription(description);
    updateTaskVideoRequest.setVideoType(videoType);
    updateTaskVideoRequest.setTaskName(taskName === null || taskName === void 0 ? void 0 : taskName.title);
    updateTaskVideoRequest.setJobRole(jobRole === null || jobRole === void 0 ? void 0 : jobRole.title);
    updateTaskVideoRequest.setRepetition(repetition);
    updateTaskVideoRequest.setTaskDuration(taskDuration);
    updateTaskVideoRequest.setOverallRisk(overallRisk);
    updateTaskVideoRequest.setCategory(category);
    updateTaskVideoRequest.setDepartmentId(departmentId);
    updateTaskVideoRequest.setSiteId(siteId);
    yield taskDashboardVideoService.updateVideo(updateTaskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: showNotification(getStatus),
            }));
            thunkAPI.dispatch({
                type: 'videos/updateVideoStatus/fulfilled',
                payload: response.toObject(),
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.CHANGE_TASK_VIDEO_STATUS_FAILED_MSG,
            }));
        }
    });
}));
export const removeTaskVideo = createAsyncThunk('videos/removeTaskVideo', (id, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    taskVideoRequest.setVideoId(id);
    yield taskDashboardVideoService.deleteVideo(taskVideoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (response) {
            thunkAPI.dispatch({
                type: 'videos/removeTaskVideo/fulfilled',
                payload: id,
            });
            const openedModal = openedModalSelector(thunkAPI.getState());
            if (openedModal === 'deleteVideo') {
                thunkAPI.dispatch(setOpenedModal(null));
            }
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.REMOVE_TASK_VIDEO_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.REMOVE_TASK_VIDEO_FAILED_MSG,
            }));
        }
    });
}));
