import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { COLORS } from 'constants/colors';
import { coloredButton, inputLabel } from '../../../assets/soter-flex-styles';
import { ReactComponent as ArrowDown } from '../../../assets/img/icons/arrows/admin-arrow-down.svg';
import { editBtn, titleWrapper } from './styles';
import { inputStyles, inputWrapper } from '../CreateCompany/styles';
import Wearables from './Wearables';
import SoterTaskSettings from './SoterTaskSettings';
import Products from './Products';
import CustomInput from '../../../lib/ui/CustomInput';
import CustomSelect from '../../../lib/ui/CustomSelect';
import { useAppDispatch } from '../../../lib/utils/useStore';
import { getRegionOptions, transformData } from '../../companies/company/utils/utils';
import { editAdminCompany } from '../../store/actions';
import { sortByValue } from '../../companies/company/utils/createCompanyFilters';
import validationSchema from '../../companies/company/utils/validationSchema';
const EditCompanyForm = ({ selectedCompany, editMode, setEditMode, firmwares, tierOptions, availableCoachingProgramList, languages, t, }) => {
    const dispatch = useAppDispatch();
    const [wearableInfo, setWearableInfo] = useState(selectedCompany.wearable);
    const [isCalendarTouched, setIsCalendarTouched] = useState(false);
    const [jobDictionaryInfo, setJobDictionaryInfo] = useState(selectedCompany.jobDictionary);
    const [geniusInfo, setGeniusInfo] = useState(selectedCompany.genius);
    const [selectedPrograms, setSelectedPrograms] = useState((selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.availableCoachingProgramIdsList.length) ? selectedCompany.availableCoachingProgramIdsList : []);
    const [showProgramsError, setShowProgramsError] = useState(false);
    const regions = getRegionOptions();
    const ref = useRef(null);
    const formik = useFormik({
        initialValues: selectedCompany,
        validationSchema,
        onSubmit: (data) => {
            var _a, _b;
            const newData = Object.assign({}, data);
            if (wearableInfo.isAvailable && !selectedPrograms.length) {
                setShowProgramsError(true);
                (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
                return;
            }
            const additionalInfo = {
                wearable: wearableInfo,
                jobDictionary: jobDictionaryInfo,
                genius: geniusInfo,
                availableCoachingProgramIdsList: selectedPrograms,
                firmwareId: null,
                additionalInfo: [],
            };
            if (wearableInfo.isAvailable) {
                if (!newData.firmwareId)
                    additionalInfo.firmwareId = firmwares[firmwares.length - 1];
            }
            if ((_b = newData.availableLanguagesList) === null || _b === void 0 ? void 0 : _b.includes('')) {
                newData.availableLanguagesList = data.availableLanguagesList.filter((language) => language !== '');
            }
            if (!newData.availableLanguagesList) {
                newData.availableLanguagesList = ['en'];
            }
            dispatch(editAdminCompany({ companyData: transformData(newData), additionalInfo: transformData(additionalInfo) }));
            setEditMode(false);
        },
    });
    return (React.createElement("form", { style: { minHeight: '100vh', paddingBottom: 125 } },
        React.createElement("div", { className: titleWrapper, style: { alignItems: 'baseline', marginBottom: 48 } },
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement("div", { style: { width: 270, marginTop: 15 } },
                    React.createElement("label", { className: inputLabel }, t('ADMIN.COMPANY_NAME', 'Company Name')),
                    React.createElement(CustomInput, { name: "name", placeholder: t('GENERAL.NAME', 'Name'), className: inputStyles, style: { outline: 'none', height: 38 }, maxLength: 40, formik: formik })),
                React.createElement("div", { style: { width: 270, marginTop: 15, marginLeft: 15 } },
                    React.createElement("label", { className: inputLabel }, t('ADMIN.REGION', 'Region')),
                    React.createElement("div", { className: inputWrapper },
                        React.createElement(CustomSelect, { name: "region", options: sortByValue(regions), placeholder: t('GENERAL.NAME', 'Name'), isSearchable: true, isClearable: true, formik: formik }),
                        React.createElement(ArrowDown, null)))),
            React.createElement("button", { className: `${coloredButton(COLORS.LIGHT_BLUE[600], 'small')} ${editBtn}`, onClick: () => (formik.dirty || isCalendarTouched ? formik.handleSubmit() : setEditMode(false)), type: "button" }, formik.dirty || isCalendarTouched
                ? t('SOTER_TASK.ASSESSMENT.BUTTONS.SAVE')
                : t('SOTER_TASK.ASSESSMENT.BUTTONS.EXIT'))),
        React.createElement(Products, { company: selectedCompany, editMode: editMode, wearableInfo: wearableInfo, setWearableInfo: setWearableInfo, jobDictionaryInfo: jobDictionaryInfo, setJobDictionaryInfo: setJobDictionaryInfo, copilotInfo: geniusInfo, setCopilotInfo: setGeniusInfo, setIsCalendarTouched: setIsCalendarTouched, t: t }),
        (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.wearable.timeStart) || (wearableInfo === null || wearableInfo === void 0 ? void 0 : wearableInfo.timeStart) ? (React.createElement("div", { ref: ref },
            React.createElement(Wearables, { company: selectedCompany, editMode: editMode, firmwares: firmwares, tierOptions: tierOptions, coachingPrograms: availableCoachingProgramList, languages: languages, showProgramsError: showProgramsError, setShowProgramsError: setShowProgramsError, selectedPrograms: selectedPrograms, setSelectedPrograms: setSelectedPrograms, formik: formik, t: t }))) : null,
        (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.genius.timeStart) || geniusInfo.timeStart ? (React.createElement(SoterTaskSettings, { company: selectedCompany, editMode: editMode, formik: formik, t: t })) : null));
};
export default EditCompanyForm;
