export default function (legendData, tier) {
  const tierHazards = Object.keys(tier).filter((key) => key.indexOf('show') !== -1);
  let newLegendData = legendData;
  tierHazards.forEach((hazard) => {
    if (!tier[hazard]) {
      newLegendData = newLegendData.filter(
        (data) => data.key.toLowerCase()
          .indexOf(hazard.toLowerCase()
            .slice(10)) === -1,
      );
    }
  });
  return newLegendData;
}
