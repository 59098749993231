import { capitalizeAndRemoveUnderscore } from '../../../lib/utils/capitalizeAndRemoveUnderscore';
export const adjustGallerySchemaForComparison = (reportSchema, prefix, videoFieldName) => reportSchema.map((schema) => (Object.assign(Object.assign({}, schema), { header: `${prefix}${schema.header}`, getValue: (comparison, index) => schema.getValue(comparison[videoFieldName], index) })));
export const createCategoriesReportSchema = (headers) => headers.map((header) => ({
    header: capitalizeAndRemoveUnderscore(header),
    getValue: ({ tagsList }) => tagsList === null || tagsList === void 0 ? void 0 : tagsList.filter((tag) => { var _a; return ((_a = tag === null || tag === void 0 ? void 0 : tag.segment) === null || _a === void 0 ? void 0 : _a.title) === header; }).map((tag) => tag === null || tag === void 0 ? void 0 : tag.title).join(', '),
}));
export const getRankedSchema = (t) => ({
    header: t('GENERAL.RANK'),
    getValue: (user, index) => index + 1,
});
