// source: api/soter-task/common/v1/video_risks_enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.taskVideoRisks.Risks', null, global);
/**
 * @enum {number}
 */
proto.taskVideoRisks.Risks = {
  NONE: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
};

goog.object.extend(exports, proto.taskVideoRisks);
