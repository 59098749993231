import { useEffect, useRef } from 'react';

const useUpdate = (func, dep) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      func();
    }
  }, dep);
};

export default useUpdate;
