// source: api/dashboard/v2/help_request_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_improvement_message_pb = require('../../../api/dashboard/v2/improvement_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_improvement_message_pb);
var api_dashboard_v2_users_message_pb = require('../../../api/dashboard/v2/users_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_users_message_pb);
var api_dashboard_v2_job_role_message_pb = require('../../../api/dashboard/v2/job_role_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_job_role_message_pb);
var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_user_note_message_pb = require('../../../api/dashboard/v2/user_note_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_user_note_message_pb);
goog.exportSymbol('proto.help_request.ChangeStatusRequest', null, global);
goog.exportSymbol('proto.help_request.HelpRequest', null, global);
goog.exportSymbol('proto.help_request.HelpRequestDevice', null, global);
goog.exportSymbol('proto.help_request.HelpRequestModel', null, global);
goog.exportSymbol('proto.help_request.HelpRequestUser', null, global);
goog.exportSymbol('proto.help_request.HelpResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.help_request.HelpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.help_request.HelpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.help_request.HelpRequest.displayName = 'proto.help_request.HelpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.help_request.HelpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.help_request.HelpResponse.repeatedFields_, null);
};
goog.inherits(proto.help_request.HelpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.help_request.HelpResponse.displayName = 'proto.help_request.HelpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.help_request.HelpRequestModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.help_request.HelpRequestModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.help_request.HelpRequestModel.displayName = 'proto.help_request.HelpRequestModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.help_request.HelpRequestUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.help_request.HelpRequestUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.help_request.HelpRequestUser.displayName = 'proto.help_request.HelpRequestUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.help_request.HelpRequestDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.help_request.HelpRequestDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.help_request.HelpRequestDevice.displayName = 'proto.help_request.HelpRequestDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.help_request.ChangeStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.help_request.ChangeStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.help_request.ChangeStatusRequest.displayName = 'proto.help_request.ChangeStatusRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.help_request.HelpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.help_request.HelpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.help_request.HelpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.help_request.HelpRequest}
 */
proto.help_request.HelpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.help_request.HelpRequest;
  return proto.help_request.HelpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.help_request.HelpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.help_request.HelpRequest}
 */
proto.help_request.HelpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.help_request.HelpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.help_request.HelpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.help_request.HelpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.help_request.HelpRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequest} returns this
 */
proto.help_request.HelpRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.help_request.HelpResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.help_request.HelpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.help_request.HelpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.help_request.HelpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: (f = msg.getCompany()) && api_dashboard_v2_users_message_pb.UsersCompany.toObject(includeInstance, f),
    helpRequestsList: jspb.Message.toObjectList(msg.getHelpRequestsList(),
    proto.help_request.HelpRequestModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.help_request.HelpResponse}
 */
proto.help_request.HelpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.help_request.HelpResponse;
  return proto.help_request.HelpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.help_request.HelpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.help_request.HelpResponse}
 */
proto.help_request.HelpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_dashboard_v2_users_message_pb.UsersCompany;
      reader.readMessage(value,api_dashboard_v2_users_message_pb.UsersCompany.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 2:
      var value = new proto.help_request.HelpRequestModel;
      reader.readMessage(value,proto.help_request.HelpRequestModel.deserializeBinaryFromReader);
      msg.addHelpRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.help_request.HelpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.help_request.HelpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.help_request.HelpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_dashboard_v2_users_message_pb.UsersCompany.serializeBinaryToWriter
    );
  }
  f = message.getHelpRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.help_request.HelpRequestModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional users_message.UsersCompany company = 1;
 * @return {?proto.users_message.UsersCompany}
 */
proto.help_request.HelpResponse.prototype.getCompany = function() {
  return /** @type{?proto.users_message.UsersCompany} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_users_message_pb.UsersCompany, 1));
};


/**
 * @param {?proto.users_message.UsersCompany|undefined} value
 * @return {!proto.help_request.HelpResponse} returns this
*/
proto.help_request.HelpResponse.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpResponse} returns this
 */
proto.help_request.HelpResponse.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated HelpRequestModel help_requests = 2;
 * @return {!Array<!proto.help_request.HelpRequestModel>}
 */
proto.help_request.HelpResponse.prototype.getHelpRequestsList = function() {
  return /** @type{!Array<!proto.help_request.HelpRequestModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.help_request.HelpRequestModel, 2));
};


/**
 * @param {!Array<!proto.help_request.HelpRequestModel>} value
 * @return {!proto.help_request.HelpResponse} returns this
*/
proto.help_request.HelpResponse.prototype.setHelpRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.help_request.HelpRequestModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.help_request.HelpRequestModel}
 */
proto.help_request.HelpResponse.prototype.addHelpRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.help_request.HelpRequestModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.help_request.HelpResponse} returns this
 */
proto.help_request.HelpResponse.prototype.clearHelpRequestsList = function() {
  return this.setHelpRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.help_request.HelpRequestModel.prototype.toObject = function(opt_includeInstance) {
  return proto.help_request.HelpRequestModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.help_request.HelpRequestModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequestModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    user: (f = msg.getUser()) && proto.help_request.HelpRequestUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.help_request.HelpRequestModel}
 */
proto.help_request.HelpRequestModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.help_request.HelpRequestModel;
  return proto.help_request.HelpRequestModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.help_request.HelpRequestModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.help_request.HelpRequestModel}
 */
proto.help_request.HelpRequestModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new proto.help_request.HelpRequestUser;
      reader.readMessage(value,proto.help_request.HelpRequestUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.help_request.HelpRequestModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.help_request.HelpRequestModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.help_request.HelpRequestModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequestModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.help_request.HelpRequestUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.help_request.HelpRequestModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestModel} returns this
 */
proto.help_request.HelpRequestModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.help_request.HelpRequestModel.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestModel} returns this
 */
proto.help_request.HelpRequestModel.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.help_request.HelpRequestModel.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestModel} returns this
 */
proto.help_request.HelpRequestModel.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 user_id = 4;
 * @return {number}
 */
proto.help_request.HelpRequestModel.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestModel} returns this
 */
proto.help_request.HelpRequestModel.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.help_request.HelpRequestModel.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestModel} returns this
 */
proto.help_request.HelpRequestModel.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional HelpRequestUser user = 6;
 * @return {?proto.help_request.HelpRequestUser}
 */
proto.help_request.HelpRequestModel.prototype.getUser = function() {
  return /** @type{?proto.help_request.HelpRequestUser} */ (
    jspb.Message.getWrapperField(this, proto.help_request.HelpRequestUser, 6));
};


/**
 * @param {?proto.help_request.HelpRequestUser|undefined} value
 * @return {!proto.help_request.HelpRequestModel} returns this
*/
proto.help_request.HelpRequestModel.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpRequestModel} returns this
 */
proto.help_request.HelpRequestModel.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpRequestModel.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.help_request.HelpRequestUser.prototype.toObject = function(opt_includeInstance) {
  return proto.help_request.HelpRequestUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.help_request.HelpRequestUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequestUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    department: (f = msg.getDepartment()) && api_dashboard_v2_department_message_pb.Department.toObject(includeInstance, f),
    improvements: (f = msg.getImprovements()) && api_dashboard_v2_improvement_message_pb.Improvements.toObject(includeInstance, f),
    lastActivityInMilliseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    daysPassed: jspb.Message.getFieldWithDefault(msg, 8, 0),
    device: (f = msg.getDevice()) && proto.help_request.HelpRequestDevice.toObject(includeInstance, f),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 10, ""),
    jobRole: (f = msg.getJobRole()) && api_dashboard_v2_job_role_message_pb.JobRole.toObject(includeInstance, f),
    note: (f = msg.getNote()) && api_dashboard_v2_user_note_message_pb.UserNote.toObject(includeInstance, f),
    lastSessionInMilliseconds: jspb.Message.getFieldWithDefault(msg, 13, 0),
    wearingNow: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.help_request.HelpRequestUser}
 */
proto.help_request.HelpRequestUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.help_request.HelpRequestUser;
  return proto.help_request.HelpRequestUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.help_request.HelpRequestUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.help_request.HelpRequestUser}
 */
proto.help_request.HelpRequestUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 5:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    case 6:
      var value = new api_dashboard_v2_improvement_message_pb.Improvements;
      reader.readMessage(value,api_dashboard_v2_improvement_message_pb.Improvements.deserializeBinaryFromReader);
      msg.setImprovements(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivityInMilliseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysPassed(value);
      break;
    case 9:
      var value = new proto.help_request.HelpRequestDevice;
      reader.readMessage(value,proto.help_request.HelpRequestDevice.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 11:
      var value = new api_dashboard_v2_job_role_message_pb.JobRole;
      reader.readMessage(value,api_dashboard_v2_job_role_message_pb.JobRole.deserializeBinaryFromReader);
      msg.setJobRole(value);
      break;
    case 12:
      var value = new api_dashboard_v2_user_note_message_pb.UserNote;
      reader.readMessage(value,api_dashboard_v2_user_note_message_pb.UserNote.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSessionInMilliseconds(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWearingNow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.help_request.HelpRequestUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.help_request.HelpRequestUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.help_request.HelpRequestUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequestUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getImprovements();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard_v2_improvement_message_pb.Improvements.serializeBinaryToWriter
    );
  }
  f = message.getLastActivityInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDaysPassed();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.help_request.HelpRequestDevice.serializeBinaryToWriter
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getJobRole();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      api_dashboard_v2_job_role_message_pb.JobRole.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      api_dashboard_v2_user_note_message_pb.UserNote.serializeBinaryToWriter
    );
  }
  f = message.getLastSessionInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getWearingNow();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.help_request.HelpRequestUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.help_request.HelpRequestUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.help_request.HelpRequestUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string nickname = 4;
 * @return {string}
 */
proto.help_request.HelpRequestUser.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional department_message.Department department = 5;
 * @return {?proto.department_message.Department}
 */
proto.help_request.HelpRequestUser.prototype.getDepartment = function() {
  return /** @type{?proto.department_message.Department} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_department_message_pb.Department, 5));
};


/**
 * @param {?proto.department_message.Department|undefined} value
 * @return {!proto.help_request.HelpRequestUser} returns this
*/
proto.help_request.HelpRequestUser.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpRequestUser.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional improvement_message.Improvements improvements = 6;
 * @return {?proto.improvement_message.Improvements}
 */
proto.help_request.HelpRequestUser.prototype.getImprovements = function() {
  return /** @type{?proto.improvement_message.Improvements} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_improvement_message_pb.Improvements, 6));
};


/**
 * @param {?proto.improvement_message.Improvements|undefined} value
 * @return {!proto.help_request.HelpRequestUser} returns this
*/
proto.help_request.HelpRequestUser.prototype.setImprovements = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.clearImprovements = function() {
  return this.setImprovements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpRequestUser.prototype.hasImprovements = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 last_activity_in_milliseconds = 7;
 * @return {number}
 */
proto.help_request.HelpRequestUser.prototype.getLastActivityInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setLastActivityInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 days_passed = 8;
 * @return {number}
 */
proto.help_request.HelpRequestUser.prototype.getDaysPassed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setDaysPassed = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional HelpRequestDevice device = 9;
 * @return {?proto.help_request.HelpRequestDevice}
 */
proto.help_request.HelpRequestUser.prototype.getDevice = function() {
  return /** @type{?proto.help_request.HelpRequestDevice} */ (
    jspb.Message.getWrapperField(this, proto.help_request.HelpRequestDevice, 9));
};


/**
 * @param {?proto.help_request.HelpRequestDevice|undefined} value
 * @return {!proto.help_request.HelpRequestUser} returns this
*/
proto.help_request.HelpRequestUser.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpRequestUser.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string job_title = 10;
 * @return {string}
 */
proto.help_request.HelpRequestUser.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional job_role.JobRole job_role = 11;
 * @return {?proto.job_role.JobRole}
 */
proto.help_request.HelpRequestUser.prototype.getJobRole = function() {
  return /** @type{?proto.job_role.JobRole} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_job_role_message_pb.JobRole, 11));
};


/**
 * @param {?proto.job_role.JobRole|undefined} value
 * @return {!proto.help_request.HelpRequestUser} returns this
*/
proto.help_request.HelpRequestUser.prototype.setJobRole = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.clearJobRole = function() {
  return this.setJobRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpRequestUser.prototype.hasJobRole = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional user_note_message.UserNote note = 12;
 * @return {?proto.user_note_message.UserNote}
 */
proto.help_request.HelpRequestUser.prototype.getNote = function() {
  return /** @type{?proto.user_note_message.UserNote} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_user_note_message_pb.UserNote, 12));
};


/**
 * @param {?proto.user_note_message.UserNote|undefined} value
 * @return {!proto.help_request.HelpRequestUser} returns this
*/
proto.help_request.HelpRequestUser.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.help_request.HelpRequestUser.prototype.hasNote = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 last_session_in_milliseconds = 13;
 * @return {number}
 */
proto.help_request.HelpRequestUser.prototype.getLastSessionInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setLastSessionInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool wearing_now = 14;
 * @return {boolean}
 */
proto.help_request.HelpRequestUser.prototype.getWearingNow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.help_request.HelpRequestUser} returns this
 */
proto.help_request.HelpRequestUser.prototype.setWearingNow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.help_request.HelpRequestDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.help_request.HelpRequestDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.help_request.HelpRequestDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequestDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    macAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastBatteryCharge: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.help_request.HelpRequestDevice}
 */
proto.help_request.HelpRequestDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.help_request.HelpRequestDevice;
  return proto.help_request.HelpRequestDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.help_request.HelpRequestDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.help_request.HelpRequestDevice}
 */
proto.help_request.HelpRequestDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastBatteryCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.help_request.HelpRequestDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.help_request.HelpRequestDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.help_request.HelpRequestDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.HelpRequestDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastBatteryCharge();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string mac_address = 1;
 * @return {string}
 */
proto.help_request.HelpRequestDevice.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.HelpRequestDevice} returns this
 */
proto.help_request.HelpRequestDevice.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 last_battery_charge = 2;
 * @return {number}
 */
proto.help_request.HelpRequestDevice.prototype.getLastBatteryCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.HelpRequestDevice} returns this
 */
proto.help_request.HelpRequestDevice.prototype.setLastBatteryCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.help_request.ChangeStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.help_request.ChangeStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.help_request.ChangeStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.ChangeStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    helpRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.help_request.ChangeStatusRequest}
 */
proto.help_request.ChangeStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.help_request.ChangeStatusRequest;
  return proto.help_request.ChangeStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.help_request.ChangeStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.help_request.ChangeStatusRequest}
 */
proto.help_request.ChangeStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHelpRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.help_request.ChangeStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.help_request.ChangeStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.help_request.ChangeStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.help_request.ChangeStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelpRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 help_request_id = 1;
 * @return {number}
 */
proto.help_request.ChangeStatusRequest.prototype.getHelpRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.help_request.ChangeStatusRequest} returns this
 */
proto.help_request.ChangeStatusRequest.prototype.setHelpRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.help_request.ChangeStatusRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.help_request.ChangeStatusRequest} returns this
 */
proto.help_request.ChangeStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.help_request);
