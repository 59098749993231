import React, { useEffect, useState } from 'react';
import {
  CustomRadioButton,
  customRadioContainer,
  CustomRadioLabel,
  mb3,
  mt3,
} from '../../../assets/soter-flex-styles';
import ActionButton from './ActionButton';
import CustomInput from '../../../lib/ui/CustomInput';

const useSelectionList = ({
  defaultState,
  options,
  isRadioShown = true,
  onRadioChange = () => {},
  onEdit = () => {},
  onRemove = () => {},
}) => {
  const [state, setState] = useState(defaultState);
  const [controlsVisibleId, setControlsVisibleId] = useState(null);
  const [editedId, setEditedId] = useState(null);
  const [editedDepartmentName, setEditedDepartmentName] = useState('');
  const checked = (id) => id === +state;
  useEffect(() => {
    setEditedId(null);
  }, [JSON.stringify(options)]);

  const handleEdit = (option) => {
    setEditedId(option.id);
    setEditedDepartmentName(option.name || option.title);
  };

  const renderActions = (option) => {
    if (editedId === option.id) {
      return (
        <>
          <div style={{ position: 'relative', top: -1 }}>
            {editedDepartmentName.replace(/\s/g, '').length > 1 && (
            <ActionButton
              type="saveChanges"
              onClick={() => onEdit(option.id, editedDepartmentName)}
            />
            )}
          </div>
          <ActionButton type="cancel" onClick={() => setEditedId(null)} />
        </>
      );
    }

    if (controlsVisibleId === option.id && !editedId) {
      return (
        <>
          <ActionButton type="edit" onClick={() => handleEdit(option)} />
          <ActionButton type="remove" onClick={() => onRemove(option.id)} />
        </>
      );
    }

    return null;
  };

  const renderSelectionList = () => (
    <>
      {options.map((option) => (
        <div
          key={option.id}
          style={{ display: 'flex', alignItems: 'center' }}
          onMouseEnter={() => {
            setControlsVisibleId(option.id);
          }}
          onMouseLeave={() => {
            setControlsVisibleId(null);
          }}
        >
          <label
            className={`${customRadioContainer} ${mb3} ${mt3}`}
            style={{ height: 30 }}
            htmlFor={option.id}
          >
            <CustomRadioButton
              id={option.id}
              name="department"
              type="radio"
              checked={checked(option.id)}
              value={option.id}
              disabled={editedId}
              onChange={(e) => {
                setState(e.target.value);
                onRadioChange(e.target.value);
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            {isRadioShown && <CustomRadioLabel />}
            <div
              style={{ marginLeft: '1.6rem' }}
            >
              {editedId === option.id ? (
                <CustomInput
                  defaultValue={option.content || option.name || option.title}
                  onChange={(_, value) => setEditedDepartmentName(value)}
                />
              ) : (
                option.content || option.name || option.title
              )}
            </div>
          </label>
          <div style={{ display: 'flex', position: 'relative', right: 12 }}>
            {renderActions(option)}
          </div>
        </div>
      ))}
    </>
  );
  return [state, setState, renderSelectionList, editedId];
};

export default useSelectionList;
