import moment from 'moment';
import replaceCsvRestrictedSymbols from '../utils/replaceCsvRestrictedSymbols';
import { videoStatus } from '../../../task/gallery/constants/videoStatus';
import { categoryNames } from '../../../task/gallery/constants/videoTypeAndStatusNames';
import { getTypeText } from '../../../task/video/components/EditableVideoHeader';
import { riskGroupNames } from '../../../task/video/constants';
import prepareRisksForBarChart from '../../../task/utils/prepareRisksForBarChart';
export default function getGalleryReportSchema(t, departments, sites, videos) {
    return [
        {
            header: t('GENERAL.ID'),
            getValue: ({ id }) => id,
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.VIDEO_NAME'),
            getValue: ({ title }) => replaceCsvRestrictedSymbols(title),
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.DATE'),
            getValue: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY'),
        },
        {
            header: t('WEARABLES.HELP.STATUS'),
            getValue: (status) => t(videoStatus[status]),
        },
        {
            header: t('GENERAL.TYPE'),
            getValue: (videoType) => t(getTypeText(videoType)),
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.FILTERS.CATEGORY'),
            getValue: ({ category }) => t(categoryNames[category]),
        },
        {
            header: t('MY_COMPANY.USERS.CREATED_BY'),
            getValue: ({ createdBy }) => replaceCsvRestrictedSymbols(createdBy === null || createdBy === void 0 ? void 0 : createdBy.name),
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.TASK_NAME'),
            getValue: ({ taskName }) => replaceCsvRestrictedSymbols(taskName === null || taskName === void 0 ? void 0 : taskName.title),
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
            getValue: ({ jobRole }) => replaceCsvRestrictedSymbols(jobRole === null || jobRole === void 0 ? void 0 : jobRole.title),
        },
        {
            header: t('WEARABLES.OVERVIEW.DEPARTMENT'),
            getValue: ({ departmentId }) => { var _a; return replaceCsvRestrictedSymbols((_a = departments.find((v) => v.id === departmentId)) === null || _a === void 0 ? void 0 : _a.name); },
        },
        {
            header: t('WEARABLES.OVERVIEW.SITE'),
            getValue: ({ siteId }) => { var _a; return replaceCsvRestrictedSymbols((_a = sites.find((v) => v.id === siteId)) === null || _a === void 0 ? void 0 : _a.name); },
        },
        {
            header: `${t('SOTER_TASK.RISKS.GENERAL')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[risks.overallRisk]),
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.BACK')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[prepareRisksForBarChart(risks).back.riskGroup]),
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.NECK')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[prepareRisksForBarChart(risks).neck.riskGroup]),
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.LEFT_ARM')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[prepareRisksForBarChart(risks).armLeft.riskGroup]),
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.RIGHT_ARM')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[prepareRisksForBarChart(risks).armRight.riskGroup]),
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.LEFT_LEG')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[prepareRisksForBarChart(risks).legLeft.riskGroup]),
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.RIGHT_LEG')} ${t('SOTER_TASK.ASSESSMENT.RISK').toLowerCase()}`,
            getValue: ({ risks }) => t(riskGroupNames[prepareRisksForBarChart(risks).legRight.riskGroup]),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.BACK').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.LOW_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).back[1].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.BACK').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).back[2].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.BACK').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.HIGH_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).back[3].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.NECK').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.LOW_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).neck[1].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.NECK').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).neck[2].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.NECK').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.HIGH_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).neck[3].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.LEFT_ARM').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.LOW_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).armLeft[1].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.LEFT_ARM').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).armLeft[2].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.LEFT_ARM').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.HIGH_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).armLeft[3].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.RIGHT_ARM').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.LOW_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).armRight[1].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.RIGHT_ARM').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).armRight[2].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.RIGHT_ARM').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.HIGH_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).armRight[3].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.LEFT_LEG').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.LOW_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).legLeft[1].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.LEFT_LEG').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).legLeft[2].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.LEFT_LEG').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.HIGH_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).legLeft[3].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.RIGHT_LEG').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.LOW_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).legRight[1].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.RIGHT_LEG').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).legRight[2].value || t('GENERAL.NA'),
        },
        {
            header: `% ${t('GENERAL.OF')} ${t('SOTER_TASK.BODY_PARTS.RIGHT_LEG').toLowerCase()} ${t('SOTER_TASK.ASSESSMENT.HIGH_RISK').toLowerCase()} ${t('GENERAL.TIME')}`,
            getValue: ({ risks }) => prepareRisksForBarChart(risks).legRight[3].value || t('GENERAL.NA'),
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.REBA'),
            getValue: ({ maxRebaScore }) => maxRebaScore
        },
    ];
}
