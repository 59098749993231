import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';

export default function exportToPdf(firstName, lastName, reportId, orientation) {
  const element = document.querySelector(reportId);
  if (!element) return;

  element.setAttribute('style', 'display: block; position: absolute; bottom: -9999px');
  const totalPages = element.childElementCount;
  const pdf = new jsPDF(orientation, 'pt', 'a4');
  const pdfPageHeight = pdf.internal.pageSize.height;
  const pdfPageWidth = pdf.internal.pageSize.width;
  html2canvas(element, { scale: 2, dpi: 300 }).then((canvas) => {
    const imgHeight = canvas.height / canvas.width * pdfPageWidth;
    const img = canvas.toDataURL('image/jpeg');
    pdf.addImage(img, 'JPEG', 0, 0, pdfPageWidth, imgHeight, '', 'MEDIUM');
    if (totalPages > 1) {
      let i = 1;
      for (i; i < totalPages; i++) {
        pdf.addPage();
        pdf.addImage(img, 'JPEG', 0, -(pdfPageHeight * i), pdfPageWidth, imgHeight, '', 'MEDIUM');
      }
    }
    pdf.save(`SoterAnalytics_Report_${firstName}_${lastName}_${moment().format('DD.MM.YYYY_HH.mm')}.pdf`);
    element.setAttribute('style', 'display: none');
  });
}
