// source: api/dashboard/v2/admin_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.admin.ClipgoConfigureDeviceMethod', null, global);
goog.exportSymbol('proto.admin.ClipgoHubSettings', null, global);
goog.exportSymbol('proto.admin.ClipgoInputLoginMethod', null, global);
goog.exportSymbol('proto.admin.HubType', null, global);
goog.exportSymbol('proto.admin.TypesWearingDevice', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.ClipgoHubSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.ClipgoHubSettings.repeatedFields_, null);
};
goog.inherits(proto.admin.ClipgoHubSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.ClipgoHubSettings.displayName = 'proto.admin.ClipgoHubSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.TypesWearingDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.TypesWearingDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.TypesWearingDevice.displayName = 'proto.admin.TypesWearingDevice';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.ClipgoHubSettings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.ClipgoHubSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.ClipgoHubSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.ClipgoHubSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.ClipgoHubSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    configureDeviceMethod: jspb.Message.getFieldWithDefault(msg, 1, 0),
    availableLanguagesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    disableNotifications: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    downloadDataNotOnlyFromDevicesOnCharge: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    collectJobRoleOnRegister: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    showOnboardingPages: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    collectEmployeeName: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.ClipgoHubSettings}
 */
proto.admin.ClipgoHubSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.ClipgoHubSettings;
  return proto.admin.ClipgoHubSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.ClipgoHubSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.ClipgoHubSettings}
 */
proto.admin.ClipgoHubSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (reader.readEnum());
      msg.setConfigureDeviceMethod(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableLanguages(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableNotifications(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnCharge(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectJobRoleOnRegister(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowOnboardingPages(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectEmployeeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.ClipgoHubSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.ClipgoHubSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.ClipgoHubSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.ClipgoHubSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigureDeviceMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAvailableLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDisableNotifications();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnCharge();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCollectJobRoleOnRegister();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getShowOnboardingPages();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCollectEmployeeName();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional ClipgoConfigureDeviceMethod configure_device_method = 1;
 * @return {!proto.admin.ClipgoConfigureDeviceMethod}
 */
proto.admin.ClipgoHubSettings.prototype.getConfigureDeviceMethod = function() {
  return /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.admin.ClipgoConfigureDeviceMethod} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setConfigureDeviceMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string available_languages = 3;
 * @return {!Array<string>}
 */
proto.admin.ClipgoHubSettings.prototype.getAvailableLanguagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setAvailableLanguagesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.addAvailableLanguages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.clearAvailableLanguagesList = function() {
  return this.setAvailableLanguagesList([]);
};


/**
 * optional bool disable_notifications = 4;
 * @return {boolean}
 */
proto.admin.ClipgoHubSettings.prototype.getDisableNotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setDisableNotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge = 5;
 * @return {boolean}
 */
proto.admin.ClipgoHubSettings.prototype.getDownloadDataNotOnlyFromDevicesOnCharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setDownloadDataNotOnlyFromDevicesOnCharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool collect_job_role_on_register = 6;
 * @return {boolean}
 */
proto.admin.ClipgoHubSettings.prototype.getCollectJobRoleOnRegister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setCollectJobRoleOnRegister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool show_onboarding_pages = 7;
 * @return {boolean}
 */
proto.admin.ClipgoHubSettings.prototype.getShowOnboardingPages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setShowOnboardingPages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool collect_employee_name = 8;
 * @return {boolean}
 */
proto.admin.ClipgoHubSettings.prototype.getCollectEmployeeName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.ClipgoHubSettings} returns this
 */
proto.admin.ClipgoHubSettings.prototype.setCollectEmployeeName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.TypesWearingDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.TypesWearingDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.TypesWearingDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.TypesWearingDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    spine: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    shoulder: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    headset: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    helmet: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.TypesWearingDevice}
 */
proto.admin.TypesWearingDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.TypesWearingDevice;
  return proto.admin.TypesWearingDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.TypesWearingDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.TypesWearingDevice}
 */
proto.admin.TypesWearingDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpine(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShoulder(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeadset(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHelmet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.TypesWearingDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.TypesWearingDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.TypesWearingDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.TypesWearingDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpine();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getShoulder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHeadset();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHelmet();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool spine = 1;
 * @return {boolean}
 */
proto.admin.TypesWearingDevice.prototype.getSpine = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.TypesWearingDevice} returns this
 */
proto.admin.TypesWearingDevice.prototype.setSpine = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool shoulder = 2;
 * @return {boolean}
 */
proto.admin.TypesWearingDevice.prototype.getShoulder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.TypesWearingDevice} returns this
 */
proto.admin.TypesWearingDevice.prototype.setShoulder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool headset = 3;
 * @return {boolean}
 */
proto.admin.TypesWearingDevice.prototype.getHeadset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.TypesWearingDevice} returns this
 */
proto.admin.TypesWearingDevice.prototype.setHeadset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool helmet = 4;
 * @return {boolean}
 */
proto.admin.TypesWearingDevice.prototype.getHelmet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.TypesWearingDevice} returns this
 */
proto.admin.TypesWearingDevice.prototype.setHelmet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.admin.ClipgoConfigureDeviceMethod = {
  CONFIGURE_DEVICE_METHOD_NONE: 0,
  ADVERTISING: 1,
  DIRECT_CONNECT: 2,
  ONLY_ADVERTISING: 3
};

/**
 * @enum {number}
 */
proto.admin.HubType = {
  HUB_TYPE_NONE: 0,
  HUB_TYPE_SOTER_COACH_HUB: 1,
  HUB_TYPE_DATA_COLLECTION_HUB: 2
};

/**
 * @enum {number}
 */
proto.admin.ClipgoInputLoginMethod = {
  NONE: 0,
  ONLY_CAMERA: 1,
  BOTH_CAMERA: 2,
  ONLY_KEYBOARD: 3,
  BOTH_KEYBOARD: 4,
  SCAN_GUN: 5
};

goog.object.extend(exports, proto.admin);
