import { TaskVideoCategory, TaskVideoType } from 'init/proto_client';

export const typeNames = {
  [TaskVideoType.TASK_ASSESSMENT]: 'SOTER_TASK.VIDEOS_LIST.FILTERS.ASSESSMENT',
  [TaskVideoType.COACHING]: 'SOTER_TASK.VIDEOS_LIST.FILTERS.COACHING',
  [TaskVideoType.OTHER]: 'SOTER_TASK.VIDEOS_LIST.FILTERS.OTHER',
  [TaskVideoType.NONE]: '-',
};

export const categoryNames = {
  [TaskVideoCategory.INITIAL]: 'SOTER_TASK.VIDEOS_LIST.FILTERS.INITIAL',
  [TaskVideoCategory.POST_CHANGE]: 'SOTER_TASK.VIDEOS_LIST.FILTERS.POST_CHANGE',
  [TaskVideoCategory.PROPOSED_CHANGE]: 'SOTER_TASK.VIDEOS_LIST.FILTERS.PROPOSED_CHANGE',
  [TaskVideoCategory.NONE]: '-',
};
