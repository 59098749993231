import { navigate } from '@reach/router';
import { uniq } from 'lodash';
import { calendar, filter, page, pageSize, sorting, sortingType, } from '../../../constants/URLParams';
import capitalizeFirstLetter from '../../../lib/utils/capitalizeFirstLetter';
// export const addURLQueryParams = (paramKey: string, paramValue: string, availableMultiple?: boolean) => {
//   const currentPath = window.location.pathname;
//   const currentSearch = window.location.search;
//
//   // Add URL parameters to the existing query string
//   const updatedSearch = new URLSearchParams(currentSearch);
//   if (availableMultiple && currentSearch.indexOf(paramKey) !== -1) {
//     updatedSearch.append(paramKey, paramValue);
//   } else updatedSearch.set(paramKey, paramValue);
//
//   // Build the new URL with the updated query string
//   const updatedUrl = `${currentPath}?${updatedSearch.toString()}`;
//
//   if (!history.state) {
//     // If no state has been set yet, push the current state to the history
//     window.history.pushState({ hasPushed: true }, '', window.location.href);
//   }
//
//   // Replace the current URL with the updated one
//   window.history.replaceState({ hasPushed: true }, '', updatedUrl);
// };
export const removeParam = (paramKey, paramValue, availableMultiple) => {
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;
    // Parse the current query string into an object
    const searchParams = new URLSearchParams(currentSearch);
    const params = uniq(searchParams.getAll(paramKey));
    // Remove the specified parameter from the object
    if (params.length === 1) {
        searchParams.delete(paramKey);
    }
    else {
        // delete only one paramKey in case of more then one param with same key
        searchParams.delete(paramKey);
        const slicedParams = params.slice(0, params.length - 1);
        slicedParams.map((param, index) => {
            const returnRestParamsToURL = (index === 0 ? searchParams.set.bind(searchParams) : searchParams.append.bind(searchParams));
            returnRestParamsToURL(paramKey, param);
        });
    }
    // Build the new URL with the updated query string
    const updatedUrl = `${currentPath}?${searchParams.toString()}`;
    // Navigate to the new URL
    navigate(updatedUrl);
};
export function parseUrlParams(url) {
    const params = {};
    const queryString = url.split('?')[1];
    if (queryString) {
        const paramPairs = queryString.split('&');
        paramPairs.forEach((pair) => {
            const [key, value] = pair.split('=');
            const decodedKey = decodeURIComponent(key);
            const decodedValue = decodeURIComponent(value);
            switch (decodedKey) {
                case calendar: {
                    const [from, to] = decodedValue.split('-');
                    params.calendar = { from, to };
                    break;
                }
                case sorting: {
                    params.sorting = { field: decodedValue, sortingType: '' };
                    break;
                }
                case sortingType: {
                    if (params.sorting) {
                        params.sorting.sortingType = decodedValue;
                    }
                    break;
                }
                case page: {
                    const pageNumber = decodedValue;
                    // @ts-ignore
                    params.page = Object.assign({ pageNumber }, params.page);
                    break;
                }
                case pageSize: {
                    // @ts-ignore
                    params.page = Object.assign(Object.assign({}, params.page), { pageSize: decodedValue });
                    break;
                }
                default: {
                    if (decodedKey.includes(capitalizeFirstLetter(filter))) {
                        const subKey = decodedKey.replace(capitalizeFirstLetter(filter), '');
                        if (!params.filter) {
                            params.filter = {};
                        }
                        // @ts-ignore
                        const filterValues = params.filter[subKey];
                        if (filterValues) {
                            filterValues.push(decodedValue);
                        }
                        else {
                            // @ts-ignore
                            params.filter[subKey] = [decodedValue];
                        }
                    }
                    else if (decodedValue === ('true' || 'false')) {
                        // @ts-ignore
                        params[decodedKey] = Boolean(decodedValue);
                    }
                    else {
                        params[decodedKey] = decodedValue;
                    }
                    break;
                }
            }
        });
    }
    return params;
}
