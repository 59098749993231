import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  openedModal: null,
  modalId: null,
  closeButtonAlternativeBehavior: false,
});

export default function (state = initialState, action = {}) {
  const { modalType, modalID, flag } = action;
  switch (action.type) {
    case types.SET_OPENED_MODAL:
      return ({ ...state, openedModal: modalType, modalID });
    case types.SET_CLOSE_BUTTON_ALTERNATIVE_BEHAVIOR:
      return ({ ...state, closeButtonAlternativeBehavior: flag });
    case types.RESET_OPEN_MODAL:
      return initialState;
    default: return state;
  }
}
