/* eslint  import/prefer-default-export:0 */
import * as types from './actionTypes';

export const resetFiltersState = () => ({
  type: types.RESET_FILTERS_STATE,
});
export const setDatesFilter = (dates, userTypes) => ({
  type: types.SET_DATES,
  dates,
  userTypes,
});

export const setResultsFilter = (results, userTypes) => ({
  type: types.SET_RESULTS,
  results,
  userTypes,
});

export const setTypesFilter = (activeTypes, userTypes) => ({
  type: types.SET_TYPES,
  activeTypes,
  userTypes,
});

export const setTypeFilter = (type, userTypes) => ({
  type: types.SET_TYPE,
  userType: type,
  userTypes,
});

export const setWearingStatus = (wearingStatus, userTypes) => ({
  type: types.SET_WEARING_STATUS,
  wearingStatus,
  userTypes,
});

export const setGroup = (group, userTypes) => ({
  type: types.SET_GROUP,
  group,
  userTypes,
});

export const setJobsDepartment = (departments, userTypes) => ({
  type: types.SET_JOBS_DEPARTMENTS,
  departments,
  userTypes,
});

export const setJobRoles = (jobRoles, userTypes) => ({
  type: types.SET_JOB_ROLES,
  jobRoles,
  userTypes,
});

export const setHelpStatusFilter = (statuses, userTypes) => ({
  type: types.SET_HELP_STATUS,
  statuses,
  userTypes,
});

export const setRegionsFilter = (regions, userTypes) => ({
  type: types.SET_REGIONS,
  regions,
  userTypes,
});

export const setResellersFilter = (resellers, userTypes) => ({
  type: types.SET_RESELLERS,
  resellers,
  userTypes,
});

export const setCreatedByFilter = (createdBy, userTypes) => ({
  type: types.SET_CREATED_BY,
  createdBy,
  userTypes,
});

export const setCompaniesFilter = (companies, userTypes) => ({
  type: types.SET_COMPANIES_FILTER,
  companies,
  userTypes,
});

export const setCanInviteUsersFilter = (canInviteUsers, userTypes) => ({
  type: types.SET_CAN_INVITE_USERS_FILTER,
  canInviteUsers,
  userTypes,
});

export const setCanReceiveNeedHelpRequestsFilter = (canReceiveNeedHelpRequests, userTypes) => ({
  type: types.SET_CAN_RECEIVE_NEEDHELP_REQUESTS_FILTER,
  canReceiveNeedHelpRequests,
  userTypes,
});

export const setIsAdminFilter = (isAdmin, userTypes) => ({
  type: types.SET_IS_ADMIN_FILTER,
  isAdmin,
  userTypes,
});

export const setIsPartnerFilter = (isPartner, userTypes) => ({
  type: types.SET_IS_PARTNER_FILTER,
  isPartner,
  userTypes,
});

export const setIsSubscriptionManagerFilter = (isSubscriptionManager, userTypes) => ({
  type: types.SET_IS_SUBSCRIPTION_MANAGER_FILTER,
  isSubscriptionManager,
  userTypes,
});

export const setVideoStatusFilter = (videoStatus, userTypes) => ({
  type: types.SET_VIDEO_STATUS_FILTER,
  videoStatus,
  userTypes,
});

export const setVideoDateOfUploadFilter = (dateOfUpload, userTypes) => ({
  type: types.SET_VIDEO_STATUS_FILTER,
  dateOfUpload,
  userTypes,
});

export const setVideoCreatedByFilter = (createdBy, userTypes) => ({
  type: types.SET_VIDEO_CREATED_BY_FILTER,
  createdBy,
  userTypes,
});

export const setVideoTaskNameFilter = (videoTaskName, userTypes) => ({
  type: types.SET_VIDEO_TASK_NAME_FILTER,
  videoTaskName,
  userTypes,
});

export const setVideoJobRoleFilter = (videoJobRole, userTypes) => ({
  type: types.SET_VIDEO_JOB_ROLE_FILTER,
  videoJobRole,
  userTypes,
});

export const setVideoRisksFilter = (videoRisk, userTypes) => ({
  type: types.SET_VIDEO_RISK_FILTER,
  videoRisk,
  userTypes,
});

export const setComparisonCreatedByFilter = (createdBy, userTypes) => ({
  type: types.SET_COMPARISON_CREATED_BY_FILTER,
  createdBy,
  userTypes,
});

export const setHubCompaniesFilter = (hubCompanies, userTypes) => ({
  type: types.SET_HUB_COMPANIES_FILTER,
  hubCompanies,
  userTypes,
});

export const setHubTypeFilter = (hubTypes, userTypes) => ({
  type: types.SET_HUB_TYPE_FILTER,
  hubTypes,
  userTypes,
});

export const setHubStatusFilter = (statuses, userTypes) => ({
  type: types.SET_HUB_STATUS_FILTER,
  statuses,
  userTypes,
});

export const setVideoType = (videoType, userTypes) => ({
  type: types.VIDEO_TYPE_FILTER,
  videoType,
  userTypes,
});

export const setVideoCategory = (videoCategory, userTypes) => ({
  type: types.VIDEO_CATEGORY_FILTER,
  videoCategory,
  userTypes,
});
