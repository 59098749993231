/**
 * @fileoverview gRPC-Web generated client stub for user_task
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/user_task_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_user_service_pb = require('../../../api/dashboard/v2/user_service_pb.js')
const proto = {};
proto.user_task = require('./user_task_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user_task.UserTaskClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user_task.UserTaskPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_task.GetUserTasksByWorkSessionIdRequest,
 *   !proto.user_task.GetUserTasksByWorkSessionIdResponse>}
 */
const methodDescriptor_UserTask_GetUserTaskByWorkSessionId = new grpc.web.MethodDescriptor(
  '/user_task.UserTask/GetUserTaskByWorkSessionId',
  grpc.web.MethodType.UNARY,
  proto.user_task.GetUserTasksByWorkSessionIdRequest,
  proto.user_task.GetUserTasksByWorkSessionIdResponse,
  /**
   * @param {!proto.user_task.GetUserTasksByWorkSessionIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_task.GetUserTasksByWorkSessionIdResponse.deserializeBinary
);


/**
 * @param {!proto.user_task.GetUserTasksByWorkSessionIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_task.GetUserTasksByWorkSessionIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_task.GetUserTasksByWorkSessionIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_task.UserTaskClient.prototype.getUserTaskByWorkSessionId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_task.UserTask/GetUserTaskByWorkSessionId',
      request,
      metadata || {},
      methodDescriptor_UserTask_GetUserTaskByWorkSessionId,
      callback);
};


/**
 * @param {!proto.user_task.GetUserTasksByWorkSessionIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_task.GetUserTasksByWorkSessionIdResponse>}
 *     Promise that resolves to the response
 */
proto.user_task.UserTaskPromiseClient.prototype.getUserTaskByWorkSessionId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_task.UserTask/GetUserTaskByWorkSessionId',
      request,
      metadata || {},
      methodDescriptor_UserTask_GetUserTaskByWorkSessionId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_task.CreateUserTaskRequest,
 *   !proto.user_task.CreateUserTaskResponse>}
 */
const methodDescriptor_UserTask_CreateUserTask = new grpc.web.MethodDescriptor(
  '/user_task.UserTask/CreateUserTask',
  grpc.web.MethodType.UNARY,
  proto.user_task.CreateUserTaskRequest,
  proto.user_task.CreateUserTaskResponse,
  /**
   * @param {!proto.user_task.CreateUserTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_task.CreateUserTaskResponse.deserializeBinary
);


/**
 * @param {!proto.user_task.CreateUserTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_task.CreateUserTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_task.CreateUserTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_task.UserTaskClient.prototype.createUserTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_task.UserTask/CreateUserTask',
      request,
      metadata || {},
      methodDescriptor_UserTask_CreateUserTask,
      callback);
};


/**
 * @param {!proto.user_task.CreateUserTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_task.CreateUserTaskResponse>}
 *     Promise that resolves to the response
 */
proto.user_task.UserTaskPromiseClient.prototype.createUserTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_task.UserTask/CreateUserTask',
      request,
      metadata || {},
      methodDescriptor_UserTask_CreateUserTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_task.UpdateUserTaskRequest,
 *   !proto.user_task.CreateUserTaskResponse>}
 */
const methodDescriptor_UserTask_UpdateUserTask = new grpc.web.MethodDescriptor(
  '/user_task.UserTask/UpdateUserTask',
  grpc.web.MethodType.UNARY,
  proto.user_task.UpdateUserTaskRequest,
  proto.user_task.CreateUserTaskResponse,
  /**
   * @param {!proto.user_task.UpdateUserTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_task.CreateUserTaskResponse.deserializeBinary
);


/**
 * @param {!proto.user_task.UpdateUserTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_task.CreateUserTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_task.CreateUserTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_task.UserTaskClient.prototype.updateUserTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_task.UserTask/UpdateUserTask',
      request,
      metadata || {},
      methodDescriptor_UserTask_UpdateUserTask,
      callback);
};


/**
 * @param {!proto.user_task.UpdateUserTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_task.CreateUserTaskResponse>}
 *     Promise that resolves to the response
 */
proto.user_task.UserTaskPromiseClient.prototype.updateUserTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_task.UserTask/UpdateUserTask',
      request,
      metadata || {},
      methodDescriptor_UserTask_UpdateUserTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_task.RemoveUserTaskRequest,
 *   !proto.user_task.RemoveUserTaskResponse>}
 */
const methodDescriptor_UserTask_RemoveUserTask = new grpc.web.MethodDescriptor(
  '/user_task.UserTask/RemoveUserTask',
  grpc.web.MethodType.UNARY,
  proto.user_task.RemoveUserTaskRequest,
  proto.user_task.RemoveUserTaskResponse,
  /**
   * @param {!proto.user_task.RemoveUserTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_task.RemoveUserTaskResponse.deserializeBinary
);


/**
 * @param {!proto.user_task.RemoveUserTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_task.RemoveUserTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_task.RemoveUserTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_task.UserTaskClient.prototype.removeUserTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_task.UserTask/RemoveUserTask',
      request,
      metadata || {},
      methodDescriptor_UserTask_RemoveUserTask,
      callback);
};


/**
 * @param {!proto.user_task.RemoveUserTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_task.RemoveUserTaskResponse>}
 *     Promise that resolves to the response
 */
proto.user_task.UserTaskPromiseClient.prototype.removeUserTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_task.UserTask/RemoveUserTask',
      request,
      metadata || {},
      methodDescriptor_UserTask_RemoveUserTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_task.CreateUserTaskJobTitleRequest,
 *   !proto.user_task.UserTaskJobTitle>}
 */
const methodDescriptor_UserTask_CreateUserTaskJobTitle = new grpc.web.MethodDescriptor(
  '/user_task.UserTask/CreateUserTaskJobTitle',
  grpc.web.MethodType.UNARY,
  proto.user_task.CreateUserTaskJobTitleRequest,
  proto.user_task.UserTaskJobTitle,
  /**
   * @param {!proto.user_task.CreateUserTaskJobTitleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_task.UserTaskJobTitle.deserializeBinary
);


/**
 * @param {!proto.user_task.CreateUserTaskJobTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_task.UserTaskJobTitle)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_task.UserTaskJobTitle>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_task.UserTaskClient.prototype.createUserTaskJobTitle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_task.UserTask/CreateUserTaskJobTitle',
      request,
      metadata || {},
      methodDescriptor_UserTask_CreateUserTaskJobTitle,
      callback);
};


/**
 * @param {!proto.user_task.CreateUserTaskJobTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_task.UserTaskJobTitle>}
 *     Promise that resolves to the response
 */
proto.user_task.UserTaskPromiseClient.prototype.createUserTaskJobTitle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_task.UserTask/CreateUserTaskJobTitle',
      request,
      metadata || {},
      methodDescriptor_UserTask_CreateUserTaskJobTitle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_task.GetUserTasksJobTitleRequest,
 *   !proto.user_task.UserTaskJobTitleResponse>}
 */
const methodDescriptor_UserTask_GetUserTasksJobTitle = new grpc.web.MethodDescriptor(
  '/user_task.UserTask/GetUserTasksJobTitle',
  grpc.web.MethodType.UNARY,
  proto.user_task.GetUserTasksJobTitleRequest,
  proto.user_task.UserTaskJobTitleResponse,
  /**
   * @param {!proto.user_task.GetUserTasksJobTitleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_task.UserTaskJobTitleResponse.deserializeBinary
);


/**
 * @param {!proto.user_task.GetUserTasksJobTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_task.UserTaskJobTitleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_task.UserTaskJobTitleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_task.UserTaskClient.prototype.getUserTasksJobTitle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_task.UserTask/GetUserTasksJobTitle',
      request,
      metadata || {},
      methodDescriptor_UserTask_GetUserTasksJobTitle,
      callback);
};


/**
 * @param {!proto.user_task.GetUserTasksJobTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_task.UserTaskJobTitleResponse>}
 *     Promise that resolves to the response
 */
proto.user_task.UserTaskPromiseClient.prototype.getUserTasksJobTitle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_task.UserTask/GetUserTasksJobTitle',
      request,
      metadata || {},
      methodDescriptor_UserTask_GetUserTasksJobTitle);
};


module.exports = proto.user_task;

