import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import {
  CLEAR_PAGINATION,
  JOBS_SET_CURRENT_PAGE,
  JOBS_SET_DISPLAY_LENGTH,
  JOBS_SET_SORTING_COLUMN,
  JOBS_SET_SORTING_TYPE,
  MY_COMPANY_CATEGORIES_SET_CURRENT_PAGE,
  MY_COMPANY_CATEGORIES_SET_DISPLAY_LENGTH,
  MY_COMPANY_CATEGORIES_SET_SORTING_COLUMN,
  MY_COMPANY_CATEGORIES_SET_SORTING_TYPE,
  MY_COMPANY_ENTRIES_SET_CURRENT_PAGE,
  MY_COMPANY_ENTRIES_SET_DISPLAY_LENGTH,
  MY_COMPANY_ENTRIES_SET_SORTING_COLUMN,
  MY_COMPANY_ENTRIES_SET_SORTING_TYPE
} from './actionTypes';
import { TableType } from '../constants/tableType';

const initialState = Immutable({
  [TableType.coach]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'coachSync',
      sortType: 'desc',
    },
  },
  [TableType.devices]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'lastSynced',
      sortType: 'desc',
    },
  },
  [TableType.help]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'createdAt',
      sortType: 'desc',
    },
  },
  [TableType.companies]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'companyId',
      sortType: 'asc',
    },
  },
  [TableType.dashboardUsers]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'name',
      sortType: 'desc',
    },
  },
  [TableType.myCompanyUsers]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'name',
      sortType: 'desc',
    },
  },
  [TableType.soterTaskVideos]: {
    pagination: {
      currentPage: 1,
      displayLength: 20,
    },
    sorting: {
      sortColumn: 'dateOfUpload',
      sortType: 'desc',
    },
  },
  [TableType.soterTaskComparison]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'createdAt',
      sortType: 'desc',
    },
  },
  [TableType.adminDevices]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'id',
      sortType: 'desc',
    },
  },
  [TableType.hubs]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'id',
      sortType: 'desc',
    },
  },
  [TableType.feedbacks]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'id',
      sortType: 'desc',
    },
  },
  [TableType.categories]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'companyCategories',
      sortType: 'asc',
    },
  },
  [TableType.entries]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'title',
      sortType: 'asc',
    },
  },
  [TableType.jobs]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'title',
      sortType: 'asc',
    },
  },
  [TableType.usersUpload]: {
    pagination: {
      currentPage: 1,
      displayLength: 10,
    },
    sorting: {
      sortColumn: 'title',
      sortType: 'asc',
    },
  },
});

export default function reduce(state = initialState, action = {}) {
  const {
    displayLength, currentPage, sortColumn, sortType,
  } = action;
  switch (action.type) {
    case types.COACH_USER_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.coach]: {
          ...state[TableType.coach],
          pagination: {
            ...state[TableType.coach].pagination,
            displayLength,
          },
        },
      });
    case types.COACH_USER_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.coach]: {
          ...state[TableType.coach],
          pagination: {
            ...state[TableType.coach].pagination,
            currentPage,
          },
        },
      });
    case types.USERS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.coach]: {
          ...state[TableType.coach],
          sorting: {
            ...state[TableType.coach].sorting,
            sortColumn,
          },
        },
      });
    case types.USERS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.coach]: {
          ...state[TableType.coach],
          sorting: {
            ...state[TableType.coach].sorting,
            sortType,
          },
        },
      });

    case types.USERS_UPLOAD_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.usersUpload]: {
          ...state[TableType.usersUpload],
          pagination: {
            ...state[TableType.usersUpload].pagination,
            displayLength,
          },
        },
      });
    case types.USERS_UPLOAD_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.usersUpload]: {
          ...state[TableType.usersUpload],
          pagination: {
            ...state[TableType.usersUpload].pagination,
            currentPage,
          },
        },
      });
    case types.USERS_UPLOAD_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.usersUpload]: {
          ...state[TableType.usersUpload],
          sorting: {
            ...state[TableType.usersUpload].sorting,
            sortColumn,
          },
        },
      });
    case types.USERS_UPLOAD_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.usersUpload]: {
          ...state[TableType.usersUpload],
          sorting: {
            ...state[TableType.usersUpload].sorting,
            sortType,
          },
        },
      });

    case types.DEVICES_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.devices]: {
          ...state[TableType.devices],
          pagination: {
            ...state[TableType.devices].pagination,
            displayLength,
          },
        },
      });
    case types.DEVICES_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.devices]: {
          ...state[TableType.devices],
          pagination: {
            ...state[TableType.devices].pagination,
            currentPage,
          },
        },
      });
    case types.DEVICES_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.devices]: {
          ...state[TableType.devices],
          sorting: {
            ...state[TableType.devices].sorting,
            sortColumn,
          },
        },
      });
    case types.DEVICES_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.devices]: {
          ...state[TableType.devices],
          sorting: {
            ...state[TableType.devices].sorting,
            sortType,
          },
        },
      });
    case types.ADMIN_DEVICES_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.adminDevices]: {
          ...state[TableType.adminDevices],
          pagination: {
            ...state[TableType.adminDevices].pagination,
            displayLength,
          },
        },
      });
    case types.ADMIN_DEVICES_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.adminDevices]: {
          ...state[TableType.adminDevices],
          pagination: {
            ...state[TableType.adminDevices].pagination,
            currentPage,
          },
        },
      });
    case types.ADMIN_DEVICES_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.adminDevices]: {
          ...state[TableType.adminDevices],
          sorting: {
            ...state[TableType.adminDevices].sorting,
            sortColumn,
          },
        },
      });
    case types.ADMIN_DEVICES_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.adminDevices]: {
          ...state[TableType.adminDevices],
          sorting: {
            ...state[TableType.adminDevices].sorting,
            sortType,
          },
        },
      });
    case types.HUBS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.hubs]: {
          ...state[TableType.hubs],
          pagination: {
            ...state[TableType.hubs].pagination,
            displayLength,
          },
        },
      });
    case types.HUBS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.hubs]: {
          ...state[TableType.hubs],
          pagination: {
            ...state[TableType.hubs].pagination,
            currentPage,
          },
        },
      });
    case types.HUBS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.hubs]: {
          ...state[TableType.hubs],
          sorting: {
            ...state[TableType.hubs].sorting,
            sortColumn,
          },
        },
      });
    case types.HUBS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.hubs]: {
          ...state[TableType.hubs],
          sorting: {
            ...state[TableType.hubs].sorting,
            sortType,
          },
        },
      });

    case types.FEEDBACKS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.feedbacks]: {
          ...state[TableType.feedbacks],
          pagination: {
            ...state[TableType.feedbacks].pagination,
            displayLength,
          },
        },
      });
    case types.FEEDBACKS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.feedbacks]: {
          ...state[TableType.feedbacks],
          pagination: {
            ...state[TableType.feedbacks].pagination,
            currentPage,
          },
        },
      });
    case types.FEEDBACKS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.feedbacks]: {
          ...state[TableType.feedbacks],
          sorting: {
            ...state[TableType.feedbacks].sorting,
            sortColumn,
          },
        },
      });
    case types.FEEDBACKS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.feedbacks]: {
          ...state[TableType.feedbacks],
          sorting: {
            ...state[TableType.feedbacks].sorting,
            sortType,
          },
        },
      });

    case types.HELP_USER_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.help]: {
          ...state[TableType.help],
          pagination: {
            ...state[TableType.help].pagination,
            displayLength,
          },
        },
      });
    case types.HELP_USER_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.help]: {
          ...state[TableType.help],
          pagination: {
            ...state[TableType.help].pagination,
            currentPage,
          },
        },
      });
    case types.HELP_USERS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.help]: {
          ...state[TableType.help],
          sorting: {
            ...state[TableType.help].sorting,
            sortColumn,
          },
        },
      });
    case types.HELP_USERS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.help]: {
          ...state[TableType.help],
          sorting: {
            ...state[TableType.help].sorting,
            sortType,
          },
        },
      });

    case types.COMPANIES_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.companies]: {
          ...state[TableType.companies],
          pagination: {
            ...state[TableType.companies].pagination,
            displayLength,
          },
        },
      });
    case types.COMPANIES_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.companies]: {
          ...state[TableType.companies],
          pagination: {
            ...state[TableType.companies].pagination,
            currentPage,
          },
        },
      });
    case types.COMPANIES_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.companies]: {
          ...state[TableType.companies],
          sorting: {
            ...state[TableType.companies].sorting,
            sortColumn,
          },
        },
      });
    case types.COMPANIES_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.companies]: {
          ...state[TableType.companies],
          sorting: {
            ...state[TableType.companies].sorting,
            sortType,
          },
        },
      });
    case types.DASHBOARD_USERS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.dashboardUsers]: {
          ...state[TableType.dashboardUsers],
          pagination: {
            ...state[TableType.dashboardUsers].pagination,
            displayLength,
          },
        },
      });
    case types.DASHBOARD_USERS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.dashboardUsers]: {
          ...state[TableType.dashboardUsers],
          pagination: {
            ...state[TableType.dashboardUsers].pagination,
            currentPage,
          },
        },
      });
    case types.DASHBOARD_USERS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.dashboardUsers]: {
          ...state[TableType.dashboardUsers],
          sorting: {
            ...state[TableType.dashboardUsers].sorting,
            sortColumn,
          },
        },
      });
    case types.DASHBOARD_USERS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.dashboardUsers]: {
          ...state[TableType.dashboardUsers],
          sorting: {
            ...state[TableType.dashboardUsers].sorting,
            sortType,
          },
        },
      });
    case types.MY_COMPANY_USERS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.myCompanyUsers]: {
          ...state[TableType.myCompanyUsers],
          pagination: {
            ...state[TableType.myCompanyUsers].pagination,
            displayLength,
          },
        },
      });
    case types.MY_COMPANY_USERS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.myCompanyUsers]: {
          ...state[TableType.myCompanyUsers],
          pagination: {
            ...state[TableType.myCompanyUsers].pagination,
            currentPage,
          },
        },
      });
    case types.MY_COMPANY_USERS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.myCompanyUsers]: {
          ...state[TableType.myCompanyUsers],
          sorting: {
            ...state[TableType.myCompanyUsers].sorting,
            sortColumn,
          },
        },
      });
    case types.MY_COMPANY_USERS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.myCompanyUsers]: {
          ...state[TableType.myCompanyUsers],
          sorting: {
            ...state[TableType.myCompanyUsers].sorting,
            sortType,
          },
        },
      });
    case types.SOTER_TASK_VIDEOS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.soterTaskVideos]: {
          ...state[TableType.soterTaskVideos],
          pagination: {
            ...state[TableType.soterTaskVideos].pagination,
            displayLength,
          },
        },
      });
    case types.SOTER_TASK_VIDEOS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.soterTaskVideos]: {
          ...state[TableType.soterTaskVideos],
          pagination: {
            ...state[TableType.soterTaskVideos].pagination,
            currentPage,
          },
        },
      });
    case types.SOTER_TASK_VIDEOS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.soterTaskVideos]: {
          ...state[TableType.soterTaskVideos],
          sorting: {
            ...state[TableType.soterTaskVideos].sorting,
            sortColumn,
          },
        },
      });
    case types.SOTER_TASK_VIDEOS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.soterTaskVideos]: {
          ...state[TableType.soterTaskVideos],
          sorting: {
            ...state[TableType.soterTaskVideos].sorting,
            sortType,
          },
        },
      });
    case types.SOTER_TASK_SET_COMPARISONS_SORTING_TYPE:
      return ({
        ...state,
        [TableType.soterTaskComparison]: {
          ...state[TableType.soterTaskComparison],
          sorting: {
            ...state[TableType.soterTaskComparison].sorting,
            sortType,
          },
        },
      });
    case types.SOTER_TASK_COMPARISONS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.soterTaskComparison]: {
          ...state[TableType.soterTaskComparison],
          sorting: {
            ...state[TableType.soterTaskComparison].sorting,
            sortColumn,
          },
        },
      });
    case types.SOTER_TASK_COMPARISONS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.soterTaskComparison]: {
          ...state[TableType.soterTaskComparison],
          pagination: {
            ...state[TableType.soterTaskComparison].pagination,
            currentPage,
          },
        },
      });
    case types.SOTER_TASK_COMPARISONS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.soterTaskComparison]: {
          ...state[TableType.soterTaskComparison],
          pagination: {
            ...state[TableType.soterTaskComparison].pagination,
            displayLength,
          },
        },
      });
    case MY_COMPANY_CATEGORIES_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.categories]: {
          ...state[TableType.categories],
          pagination: {
            ...state[TableType.categories].pagination,
            currentPage,
          },
        },
      });
    case MY_COMPANY_CATEGORIES_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.categories]: {
          ...state[TableType.categories],
          pagination: {
            ...state[TableType.categories].pagination,
            displayLength,
          },
        },
      });
    case MY_COMPANY_CATEGORIES_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.categories]: {
          ...state[TableType.categories],
          sorting: {
            ...state[TableType.categories].sorting,
            sortColumn,
          },
        },
      });
    case MY_COMPANY_CATEGORIES_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.categories]: {
          ...state[TableType.categories],
          sorting: {
            ...state[TableType.categories].sorting,
            sortType,
          },
        },
      });
    case MY_COMPANY_ENTRIES_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.entries]: {
          ...state[TableType.entries],
          pagination: {
            ...state[TableType.entries].pagination,
            currentPage,
          },
        },
      });
    case MY_COMPANY_ENTRIES_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.entries]: {
          ...state[TableType.entries],
          pagination: {
            ...state[TableType.entries].pagination,
            displayLength,
          },
        },
      });
    case MY_COMPANY_ENTRIES_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.entries]: {
          ...state[TableType.entries],
          sorting: {
            ...state[TableType.entries].sorting,
            sortColumn,
          },
        },
      });
    case MY_COMPANY_ENTRIES_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.entries]: {
          ...state[TableType.entries],
          sorting: {
            ...state[TableType.entries].sorting,
            sortType,
          },
        },
      });
    case JOBS_SET_CURRENT_PAGE:
      return ({
        ...state,
        [TableType.jobs]: {
          ...state[TableType.jobs],
          pagination: {
            ...state[TableType.jobs].pagination,
            currentPage,
          },
        },
      });
    case JOBS_SET_DISPLAY_LENGTH:
      return ({
        ...state,
        [TableType.jobs]: {
          ...state[TableType.jobs],
          pagination: {
            ...state[TableType.jobs].pagination,
            displayLength,
          },
        },
      });
    case JOBS_SET_SORTING_COLUMN:
      return ({
        ...state,
        [TableType.jobs]: {
          ...state[TableType.jobs],
          sorting: {
            ...state[TableType.jobs].sorting,
            sortColumn,
          },
        },
      });
    case JOBS_SET_SORTING_TYPE:
      return ({
        ...state,
        [TableType.jobs]: {
          ...state[TableType.jobs],
          sorting: {
            ...state[TableType.jobs].sorting,
            sortType,
          },
        },
      });
    case CLEAR_PAGINATION:
      return initialState;
    default: return state;
  }
}
