import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { container } from 'assets/jss/_style';
import { nameLink } from '../../assets/soter-flex-styles';
import useNumberOfTrialDaysLeftBanner from '../../task/overview/useNumberOfTrialDaysLeftBanner';
import { routes } from '../../containers/PrivatPageHOC/constants/routes';
import getFilteringParameters from '../../containers/ItemTableContainer/utils/getFilteringParameters';
import { getJobDictionary } from './jobDictionaryStore/actions';
import { initFilters, resetFilters } from '../../containers/ItemTableContainer/filtersStore/filtersSlice';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import { filterItemsAccordingToFilterState } from '../../containers/ItemTableContainer/utils/filterForTable';
import Loader from '../../lib/ui/Loader';
import StickyOnScrollContainer from '../../containers/ItemTableContainer/components/StickyOnScrollContainer';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';

const JobDictionary = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roles = useSelector((state) => state.jobDictionary.jobProfiles);
  const isLoading = useSelector(
    (state) => state.jobDictionary.isLoading,
  );
  const { workGroupsList: workGroups = [], departmentsList: departments }
    = useSelector((state) => state.companies.selectedCompany);
  const companyId = useSelector((state) => state.companies.selectedCompany.id);
  const { filtersNew } = useSelector((state) => state);
  const filteringState = filtersNew.filters;
  const { searchString } = filtersNew;
  const [sortColumn, setSortColumn] = useState('title');
  const [sortType, setSortType] = useState('asc');
  const [filteredItems, setFilteredItems] = useState(roles);
  const [sortedItems, setSortedItems] = useState(filteredItems);
  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('jobDictionaryProduct');
  const JobCell = ({ rowData }) => (
    <Link className={nameLink} to={`/${companyId}/${routes.users}/${routes.jobs}/${routes.profile}/${rowData.id}`}>
      {rowData.title || <span className={text(COLORS.GRAY[400])}>—</span>}
    </Link>
  );
  const filteringParameters = getFilteringParameters(
    departments,
    'jobs',
    null,
    workGroups.map(({ title }) => ({ label: title, value: title })),
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    t,
  );

  useEffect(() => {
    if (companyId) dispatch(getJobDictionary(companyId));
  }, [companyId]);

  useEffect(() => {
    let newSortedItems;
    if (sortColumn === 'title') newSortedItems = _sortBy(filteredItems, (role) => role.title);
    // if (sortColumn === 'department') newSortedItems = _sortBy(filteredItems, (role) => role.departmentTitle);
    if (sortColumn === 'group') newSortedItems = _sortBy(filteredItems, (role) => role.workgroupTitle);
    if (sortColumn === 'dotClassification') newSortedItems = _sortBy(filteredItems, (role) => role.dotClassification);
    if (sortType === 'desc') newSortedItems = _reverse(newSortedItems);
    setSortedItems(newSortedItems);
  }, [sortColumn, sortType, filteredItems]);
  useEffect(() => {
    dispatch(
      initFilters({ types: filteringParameters.map(({ name }) => name) }),
    );
    return () => {
      dispatch(resetFilters());
    };
  }, []);
  useEffect(() => {
    setFilteredItems(
      filterItemsAccordingToFilterState(roles, {
        ...filteringState,
        searchString,
      }),
    );
  }, [filteringState, searchString, roles]);
  const columns = (getActions) => getColumns(
    null,
    null,
    null,
    getActions,
    TableType.jobs,
    t,
  );
  const cells = {
    title: JobCell,
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className={container}>
      {numberOfTrialDaysLeftBanner && (
      <StickyOnScrollContainer
        topMargin={0}
        topBlock={numberOfTrialDaysLeftBanner}
        height={50}
      />
      )}
      <ItemTableContainer
        items={roles}
        selectedItems={[]}
        columns={columns}
        cells={cells}
        type={TableType.jobs}
        isLoading={isLoading}
      />
    </div>
  );
};

export default JobDictionary;
