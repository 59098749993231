import { toCamel } from 'snake-camel';
import {
  comparisonListRequest,
  createComparisonRequest,
  deleteComparisonRequest,
  getAssessmentsForVideoRequest,
  getMetaData,
  taskAssessmentService,
  taskDashboardVideoService,
  taskVideoRequest,
  updateComparisonRequest,
} from 'init/proto_client';
import { flatten } from 'lodash/array';
import { isEqual, uniqWith } from 'lodash';
import { navigate } from '@reach/router';
import { TASK_COMPARISON_DELETED } from 'constants/analyticsEvents';
import * as types from './actionTypes';
import {
  createEventNotification,
  setNotification
} from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';

export const getComparisonList = (companyId) => async (dispatch) => {
  comparisonListRequest.setCompanyId(companyId);
  await taskDashboardVideoService.getTaskVideoComparisonList(
    comparisonListRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        const { listList } = toCamel(response.toObject());
        dispatch({
          type: types.COMPARISON_LIST_SET,
          comparisonList: listList,
        });
      }
    },
  );
};

export const createComparison = ({
  title, firstVideoId, secondVideoId, description, companyID,
}) => async (dispatch, getState) => {
  createComparisonRequest.setTitle(title);
  createComparisonRequest.setFirstTaskVideoId(firstVideoId);
  createComparisonRequest.setSecondTaskVideoId(secondVideoId);
  createComparisonRequest.setDescription(description);
  createComparisonRequest.setCompanyId(companyID);
  await taskDashboardVideoService.createTaskVideoComparison(
    createComparisonRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.CREATE_COMPARISON_FAILED_MSG,
        }));
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        const currentComparison = response.toObject();

        dispatch({
          type: types.COMPARISON_LIST_SET,
          comparisonList: [...getState().soterTaskComparisons.comparisonList, currentComparison],
        });
        dispatch({
          type: types.CURRENT_COMPARISON_SET,
          currentComparison,
        });
        dispatch({
          type: types.NEW_COMPARISON_IDS_SET,
          newComparisonIds: [null, null],
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.CREATE_COMPARISON_SUCCESS_MSG,
        }));
        navigate(`/${companyID}/users/soter-genius/comparison/${currentComparison.id}`);
      }
    },
  );
};

export const getComparisonMovementData = (firstVideoId, secondVideoId) => async (dispatch) => {
  taskVideoRequest.setVideoId(firstVideoId);
  await taskDashboardVideoService.getMovementData(
    taskVideoRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.COMPARISON_FIRST_VIDEO_MOVEMENT_DATA,
          firstVideoMovementData: toCamel(JSON.parse(response.toObject().data)),
        });
      }
    },
  );
  taskVideoRequest.setVideoId(secondVideoId);
  await taskDashboardVideoService.getMovementData(
    taskVideoRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.COMPARISON_SECOND_VIDEO_MOVEMENT_DATA,
          secondVideoMovementData: toCamel(JSON.parse(response.toObject().data)),
        });
      }
    },
  );
};

export const updateComparison = ({ id, title, description }) => (dispatch, getState) => {
  updateComparisonRequest.setId(id);
  updateComparisonRequest.setDescription(description);
  updateComparisonRequest.setTitle(title);
  taskDashboardVideoService.updateTaskVideoComparison(
    updateComparisonRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.UPDATE_COMPARISON_FAILED_MSG,
        }));
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        const currentComparison = response.toObject();
        dispatch({
          type: types.COMPARISON_LIST_SET,
          comparisonList: [...getState().soterTaskComparisons.comparisonList.filter(({ id }) => id !== currentComparison.id), currentComparison],
        });
        dispatch({
          type: types.CURRENT_COMPARISON_SET,
          currentComparison,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.UPDATE_COMPARISON_SUCCESS_MSG,
        }));
      }
    },
  );
};

export const removeComparison = (id) => async (dispatch, getState) => {
  deleteComparisonRequest.setTaskVideoComparisonId(id);
  taskDashboardVideoService.deleteTaskVideoComparison(
    deleteComparisonRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.REMOVE_COMPARISON_FAILED_MSG,
        }));
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.COMPARISON_LIST_SET,
          comparisonList: getState().soterTaskComparisons.comparisonList.filter((c) => c.id !== id),
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_COMPARISON_SUCCESS_MSG,
        }));
        window.logEvent(TASK_COMPARISON_DELETED);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(TASK_COMPARISON_DELETED));
        }
      }
    },
  );
};

export const getAssessmentForVideo = (firstVideoId, secondVideoId) => async (dispatch, getState) => {
  await taskAssessmentService.getAssessmentsForVideo(
    getAssessmentsForVideoRequest.setVideoId(firstVideoId),
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        const assessments = flatten([response.toObject().assessmentsList]);
        dispatch({
          type: types.COMPARISON_ASSESSMENTS,
          assessmentsList: {
            firstVideoAssessments: uniqWith(assessments, isEqual),
            secondVideoAssessments: getState().soterTaskComparisons.assessmentsList.secondVideoAssessments,
          },
        });
      }
    },
  );
  await taskAssessmentService.getAssessmentsForVideo(
    getAssessmentsForVideoRequest.setVideoId(secondVideoId),
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.COMPARISON_ERROR,
          error,
        });
      } else {
        const assessments = flatten([response.toObject().assessmentsList]);
        dispatch({
          type: types.COMPARISON_ASSESSMENTS,
          assessmentsList: {
            firstVideoAssessments: getState().soterTaskComparisons.assessmentsList.firstVideoAssessments,
            secondVideoAssessments: uniqWith(assessments, isEqual),
          },
        });
      }
    },
  );
};

export const setCurrentComparison = (currentComparison) => (dispatch) => {
  dispatch({
    type: types.CURRENT_COMPARISON_SET,
    currentComparison,
  });
};

export const setNewComparisonIds = (newComparisonIds) => ({
  type: types.NEW_COMPARISON_IDS_SET,
  newComparisonIds,
});

export const resetComparisons = () => ({ type: types.COMPARISON_RESET });
