import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import { useTimer } from 'react-timer-hook';
import Button from 'lib/ui/Button';
import { COLORS } from 'constants/colors';
import { coloredButton, mb8, mt4 } from 'assets/soter-flex-styles';

const disabled = Emotion.css`
   {
    background-color: rgWba(255, 255, 255, 0.5) !important;
    color: #ffffff !important;
    box-shadow: none !important;
    outline: none !important;
    opacity: 1 !important;
    border: 0 !important;
  }
`;

const TrialFinishedDialog = ({ ...content }) => {
  const { t } = useTranslation();
  const handler = content.onClickHandler;
  const time = new Date();
  const expiryTimestamp = time.setSeconds(time.getSeconds() + 600);
  const { seconds, minutes } = useTimer(expiryTimestamp);
  const { trialSent } = content;
  return (
    <div
      style={{
        color: COLORS.GRAY[950],
        textAlign: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <h4 className={`${mb8} ${mt4}`}>
        {t('SOTER_TASK.TRIAL_EXPIRED_DIALOG.HEADER')}
      </h4>
      <div>{t('SOTER_TASK.TRIAL_EXPIRED_DIALOG.TEXT1')}</div>
      <div className={mb8}>{t('SOTER_TASK.OVERVIEW.UPGRADE')}</div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {trialSent ? (
        minutes > 0 ? (
          // eslint-disable-next-line react/button-has-type
          <Button
            disabled
            style={{
              display: 'block', textAlign: 'center', margin: '0 auto', width: '60%',
            }}
            className={`${coloredButton(COLORS.LIGHT_BLUE[600], 'small')} ${disabled}`}
            text={`${t(
              'SOTER_TASK.TRIAL_EXPIRED_DIALOG.SENT_BUTTON_IN',
            )} ${minutes}:${seconds}`}
          />
        ) : (
          // eslint-disable-next-line react/button-has-type
          <Button
            handler={handler}
            style={{
              display: 'block', textAlign: 'center', margin: '0 auto', width: '60%',
            }}
            className={coloredButton(COLORS.LIGHT_BLUE[600], 'smvall')}
            text={t('SOTER_TASK.TRIAL_EXPIRED_DIALOG.SENT_BUTTON')}
          />
        )
      ) : (
        // eslint-disable-next-line react/button-has-type
        <Button
          handler={handler}
          style={{
            display: 'block', textAlign: 'center', margin: '0 auto', width: '60%',
          }}
          className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
          text={t('SOTER_TASK.TRIAL_EXPIRED_DIALOG.BUTTON')}
        />
      )}
    </div>
  );
};

export default TrialFinishedDialog;
