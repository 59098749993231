import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import CustomInput from 'lib/ui/CustomInput';
import { mb4, mt4 } from 'assets/soter-flex-styles';
import Tooltip from 'lib/ui/Tooltip';
import Button from 'lib/ui/Button';
import SwapIcon from '@rsuite/icons/Conversion';
import * as Emotion from 'emotion';
import { useAppDispatch, useAppSelector } from 'lib/utils/useStore';
import { setOpenedModal as closeModal } from 'containers/ModalContainer/modalsStore/actions';
import { COLORS } from 'constants/colors';
import { setNotification } from 'containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from 'containers/NotificationContainer/notifications';
import { editUsers, editUsersStatuses } from '../usersStore/actions';
import QRPrintDialog from './QRPrintDialog';
import validationSchema from './utils/validationSchema';
import * as usersStoreTypes from '../usersStore/actionTypes';
import * as userStoreTypes from '../../user/userStore/actionTypes';
import { Tooltip as TooltipRS } from 'rsuite';
const EditUserDialog = ({ users, title, setOpenedModal }) => {
    const dispatch = useAppDispatch();
    const { errorsOnEdit: activityPageError } = useAppSelector((state) => state.coachUsers);
    const { errorOnEdit: userPageError } = useAppSelector((state) => state.user);
    const location = useLocation();
    const isActivityPage = location.pathname.includes('/activity');
    const [userData, setUserData] = useState(null);
    const [maxLengthTooltip, setMaxLengthTooltip] = useState({
        firstName: false,
        lastName: false,
        nickname: false,
    });
    const { t } = useTranslation();
    const clearErrors = () => {
        const clearErrorActionType = isActivityPage
            ? usersStoreTypes.USERS_CLEAR_EDIT_STATUSES
            : userStoreTypes.USER_CLEAR_EDIT_STATUS;
        dispatch({ type: clearErrorActionType });
    };
    const hideTooltip = (fieldName) => {
        setTimeout(() => {
            setMaxLengthTooltip((prev) => (Object.assign(Object.assign({}, prev), { [fieldName]: false })));
        }, 3000);
    };
    const handleInputChange = (name, value) => {
        let newValue = value;
        const maxLength = name === 'nickname' ? 30 : 64;
        const hasError = isActivityPage ? activityPageError.length > 0 : Boolean(userPageError);
        if (hasError)
            clearErrors();
        if (newValue.length >= maxLength) {
            setMaxLengthTooltip((prev) => (Object.assign(Object.assign({}, prev), { [name]: true })));
            hideTooltip(name);
        }
        if (name === 'nickname')
            newValue = newValue.toLowerCase().replace(/\s+/g, '');
        newValue = newValue.replace(/[<>{}=+\\/|()*?]/g, '');
        formik.setFieldValue(name, newValue);
    };
    const handleCloseModal = () => {
        clearErrors();
        if (setOpenedModal) {
            setOpenedModal(null);
        }
        else {
            dispatch(closeModal());
        }
    };
    const handleNotification = (type, message) => {
        dispatch(setNotification({ type, message }));
    };
    const handleResponse = (res, data) => {
        if (res.type === 'serverError') {
            handleNotification(notificationTypes.TYPE_FAILED, notificationMessages.GENERIC_ERROR);
            return handleCloseModal();
        }
        if (res.type === 'usersEdit' && res.error)
            return;
        if (users[0].nickname === data.nickname) {
            handleNotification(notificationTypes.TYPE_SUCCESS, notificationMessages.USER_DATA_UPDATED);
            handleCloseModal();
        }
        else {
            handleNotification(notificationTypes.TYPE_SUCCESS, notificationMessages.USER_LOGIN_UPDATED);
            setUserData(data);
        }
    };
    const formik = useFormik({
        initialValues: users[0],
        validationSchema,
        onSubmit: (data) => {
            const cleanedData = Object.assign(Object.assign({}, data), { firstName: data.firstName.trim(), lastName: data.lastName.trim() });
            formik.setFieldValue('firstName', cleanedData.firstName);
            formik.setFieldValue('lastName', cleanedData.lastName);
            if (!cleanedData.firstName || !cleanedData.lastName)
                return;
            dispatch(editUsers([cleanedData], isActivityPage))
                .then((res) => handleResponse(res, data))
                .catch((err) => console.error(err));
        },
    });
    const handleSwapNames = () => {
        const { firstName, lastName } = formik.values;
        formik.setFieldValue('firstName', lastName);
        formik.setFieldValue('lastName', firstName);
    };
    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case editUsersStatuses.FIRST_NAME_VALIDATION_ERROR:
                return t('WEARABLES.ACTIVITY.DIALOGS.NAME_ERROR');
            case editUsersStatuses.LAST_NAME_VALIDATION_ERROR:
                return t('WEARABLES.ACTIVITY.DIALOGS.SECOND_NAME_ERROR');
            case editUsersStatuses.NICKNAME_VALIDATION_ERROR:
                return t('WEARABLES.ACTIVITY.DIALOGS.LOGIN_LENGTH_ERROR');
            case editUsersStatuses.NICKNAME_VALIDATION_REGEXP_ERROR:
                return t('WEARABLES.ACTIVITY.DIALOGS.LOGIN_REGEXP_ERROR');
            case editUsersStatuses.NICKNAME_DUPLICATION_ERROR:
                return t('WEARABLES.ACTIVITY.DIALOGS.LOGIN_ERROR');
            default:
                return '';
        }
    };
    const errorHandling = () => {
        const errors = {
            firstName: '',
            lastName: '',
            nickname: '',
        };
        const updateErrors = (errorCode) => {
            const errorMessage = getErrorMessage(errorCode);
            switch (errorCode) {
                case editUsersStatuses.FIRST_NAME_VALIDATION_ERROR:
                    errors.firstName = errorMessage;
                    break;
                case editUsersStatuses.LAST_NAME_VALIDATION_ERROR:
                    errors.lastName = errorMessage;
                    break;
                case editUsersStatuses.NICKNAME_VALIDATION_ERROR:
                case editUsersStatuses.NICKNAME_VALIDATION_REGEXP_ERROR:
                case editUsersStatuses.NICKNAME_DUPLICATION_ERROR:
                    errors.nickname = errorMessage;
                    break;
                default:
                    break;
            }
        };
        if (isActivityPage) {
            activityPageError.forEach((errorObj) => {
                updateErrors(errorObj.error);
            });
        }
        else {
            updateErrors(userPageError);
        }
        return errors;
    };
    const { firstName, lastName, nickname } = errorHandling();
    const renderMaxLengthTooltip = () => (React.createElement(TooltipRS, { visible: true, style: { top: 0, right: 0 } }, t('WEARABLES.ACTIVITY.DIALOGS.MAX_LENGTH_REACHED')));
    useEffect(() => {
        return () => {
            clearErrors();
        };
    }, []);
    const formStyles = Emotion.css `
    .infoWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .item {
      position: relative;
      label {
        margin-bottom: 5px;
        display: block;
      }
      &.login {
        margin-top: 16px;
      }
      .error {
        border-color: ${COLORS.RED[600]};
      }
      .errorMessage {
        font-size: 12px;
        color: ${COLORS.RED[600]};
        bottom: -19px;
        left: 0;
        position: absolute;
      }
    }
    .icon {
      margin-top: 25px;
      cursor: pointer;
    }
    button {
      text-align: center;
      width: 100%;
      margin-top: 25px;
      display: block;
    }
  `;
    return (React.createElement(React.Fragment, null, userData ? (React.createElement(QRPrintDialog, { user: userData })) : (React.createElement(React.Fragment, null,
        React.createElement("h4", { style: { width: '95%' }, className: mb4 }, title),
        React.createElement("form", { onSubmit: formik.handleSubmit, className: formStyles },
            React.createElement("div", { className: 'infoWrapper' },
                React.createElement("div", { className: 'item', style: { width: '46%' } },
                    React.createElement("label", null, t('GENERAL.FIRST_NAME')),
                    React.createElement(CustomInput, { className: firstName ? 'error' : '', name: 'firstName', placeholder: t('GENERAL.FIRST_NAME'), maxLength: 64, onInputChange: handleInputChange, formik: formik }),
                    firstName && React.createElement("span", { className: 'errorMessage' }, firstName),
                    maxLengthTooltip.firstName && renderMaxLengthTooltip()),
                React.createElement(Tooltip, { text: t('WEARABLES.ACTIVITY.DIALOGS.SWAP'), placement: 'topEnd' },
                    React.createElement(SwapIcon, { className: 'icon', onClick: handleSwapNames })),
                React.createElement("div", { className: 'item', style: { width: '46%' } },
                    React.createElement("label", null, t('GENERAL.LAST_NAME')),
                    React.createElement(CustomInput, { className: lastName ? 'error' : '', name: 'lastName', placeholder: t('GENERAL.LAST_NAME'), maxLength: 64, onInputChange: handleInputChange, formik: formik }),
                    lastName && React.createElement("span", { className: 'errorMessage' }, lastName),
                    maxLengthTooltip.lastName && renderMaxLengthTooltip())),
            React.createElement("div", { className: 'item login' },
                React.createElement("label", null, t('LOGIN.LOGIN')),
                React.createElement(CustomInput, { className: nickname ? 'error' : '', name: 'nickname', placeholder: t('LOGIN.LOGIN'), maxLength: 30, onInputChange: handleInputChange, formik: formik }),
                nickname && React.createElement("span", { className: 'errorMessage' }, nickname),
                maxLengthTooltip.nickname && renderMaxLengthTooltip()),
            React.createElement(Button, { type: 'submit', className: mt4, text: t('ADMIN.SAVE'), disabled: !formik.dirty }))))));
};
export default EditUserDialog;
