import * as Emotion from 'emotion';
import './colors';
import { COLORS } from 'constants/colors';
import hexToRgba from '../../lib/utils/hexToRgba';

const buttonText = Emotion.css`
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`;

const button = Emotion.css`
  ${buttonText};

  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 0.8125rem;
  min-width: 2.375rem;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    opacity: 0.65;
  }
`;

const buttonTiny = Emotion.css`
  ${button};

  padding: 0;
  font-size: 0.5rem;
  line-height: 1;
  height: 24px;
  min-width: 24px;
`;

const buttonSmall = Emotion.css`
  ${button};

  padding: 0.25rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.33333333;
  height: 38px;
`;

const buttonLarge = Emotion.css`
  ${button};

  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.625;
`;

const sizedButton = (size) => {
  switch (size) {
    case 'tiny':
      return buttonTiny;
    case 'small':
      return buttonSmall;
    case 'large':
      return buttonLarge;
    default:
      return buttonSmall;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const outlineColoredButton = (color, size) => Emotion.css`
  ${sizedButton(size)};

  color: ${color};
  background-color: ${COLORS.WHITE};
  background-image: none;
  border: 1px solid ${color};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  &:focus {
    outline: 3px auto ${color};
  }
  &:hover {
    color: #fff;
    background-color: ${color};
    border-color: ${color};
  }
  &:disabled {
    color: ${color};
    background-color: transparent;
  }
  &:active {
    color: #fff;
    background-color: ${color};
    border-color: ${color};
  }
`;

export const coloredButton = (color, size = 'small') => Emotion.css`
  ${sizedButton(size)};

  color: #fff;
  background-color: ${color};
  background-image: none;
  border: 1px solid ${color};
  opacity: 0.9;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  &:focus {
    opacity: 1;
    outline: 3px auto ${color};
  }
  &:hover {
    color: #fff;
    background-color: ${color};
    border-color: ${color};
    opacity: 1;
  }
  &:disabled {
    color: #fff;
    background-color: ${color};
    border-color: ${color};
    opacity: 0.5;
  }
  &:active {
    color: #fff;
    background-color: ${color};
    border-color: ${color};
    opacity: 1;
  }
`;

export const primaryOutlineButton = (color, textColor, size) => Emotion.css`
  ${coloredButton(color, size)};

  color: ${textColor};
  opacity: 1;
  &:focus,
  &:hover,
  &:active {
    opacity: 0.9;
    color: ${textColor};
  }
  &:disabled {
    color: ${textColor};
    opacity: 0.8;
  }
`;

export const transparentButton = (size) => Emotion.css`
  ${sizedButton(size)};

  color: ${COLORS.GRAY[950]};
  background-color: transparent;
  background-image: none;
  border: 1px solid #e0e5ec;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  &:focus {
    outline: 3px auto #e0e5ec;
  }
  &:hover {
    color: ${COLORS.GRAY[950]};
    background-color: #f6f6f6;
    border-color: rgba(0, 20, 49, 0.12);
  }
  &:disabled {
    color: #e0e5ec;
    background-color: transparent;
    opacity: 0.85;
  }
  &:active {
    color: ${COLORS.GRAY[950]};
    background-color: #f6f6f6;
    border-color: rgba(0, 20, 49, 0.12);
  }
`;

export const invisibleButton = (size, color = COLORS.GRAY[950]) => Emotion.css`
  ${sizedButton(size)};

  font-weight: 400;
  font-size: 0.9375rem;
  color: ${color};
  background-color: transparent;
  background-image: none;
  border: none;
  text-align: start;
  &:focus {
    outline: none;
  }
  &:hover(:not):disabled {
  }
  &:disabled {
    color: #868e96;
  }
  &:active {
    outline: none;
  }
`;

export const invisibleButtonMenuItem = (
  size,
  color = COLORS.GRAY[950],
  selected = false,
) => Emotion.css`
  ${invisibleButton(size, color)};
  background-color: ${selected
    ? hexToRgba(COLORS.LIGHT_BLUE[600], 0.3)
    : 'transparent'};
  padding: 0 15px;
  white-space: nowrap;
  &:focus {
    background-color: #f6f6f6;
    outline: #f6f6f6;
  }
  &:hover {
    background-color: #f6f6f6;
  }
  &:disabled {
    color: #868e96;
    background-color: transparent;
  }
  &:active {
    color: ${color};
    background-color: #f6f6f6;
    outline: #f6f6f6;
  }
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

export const closeButton = Emotion.css`
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 0.8125rem;

  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 50%;
  background-size: cover;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

export const closeModalButton = Emotion.css`
  display: flex;
  justify: content: right;
  height: 0;
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 5000;

  button {
    ${closeButton};

    width: 1.25rem;
    height: 1.25rem;
    background-color: #ffffff;
    background-image: url('/assets/img/icons/closeModal.svg');
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
`;

export const adminButton = Emotion.css`
  ${invisibleButton('small')}
`;

export const iconButton = Emotion.css`
  ${invisibleButton('tiny')}
`;

export const buttonIcon = Emotion.css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
`;

export const buttonStylesReset = Emotion.css`
  padding: 0;
  border: none;
  background: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

export const simpleTextButton = Emotion.css`
  ${buttonStylesReset}

  font-weight: 500;
  color: ${COLORS.LIGHT_BLUE[600]};
  opacity: 0.9;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    color: ${COLORS.GRAY[950]};
  }
`;

export const centeredIconButton = Emotion.css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;

  svg {
    opacity: 0.8;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
