import * as Yup from 'yup';
import { errorMessages } from '../../../../constants/validationMessages';

export default Yup.object().shape({
  name: Yup.string().required(errorMessages.requiredField),
  region: Yup.object().shape().nullable().required(errorMessages.requiredField),
  programTargetDays: Yup.string().required(errorMessages.requiredField),
  workdayIntervalMinutes: Yup.number().required(errorMessages.requiredField),
  workdayTimeMinutes: Yup.number().required(errorMessages.requiredField),
}, []);
