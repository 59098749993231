import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { card } from '../../assets/soter-flex-styles';
import { COLORS } from '../../constants/colors';
import CardFooterControl from './CardFooterControl';
import { JOB_PROFILE_VIDEO_PLAYED, TASK_PROFILE_VIDEO_PLAYED, } from '../../constants/analyticsEvents';
import hexToRgba from '../../lib/utils/hexToRgba';
import { useAppDispatch } from '../../lib/utils/useStore';
import useChunkedList from '../utils/useChunkedList';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';

const mainCard = Emotion.css`
  ${card};
  flex-direction: row;
  width: 1153px;
  margin-left: 11px;
`;
const videoListContainer = Emotion.css`
  flex-grow: 1;
`;
const videoListEmpty = Emotion.css`
  display: flex;
  flex-wrap: wrap;
`;
const itemWrapper = Emotion.css`
  height: 260px;
`;
const emptyItemWrapper = Emotion.css`
  width: 100%;
  order: 1;
  padding: 15px 0 25px;
`;
const videoListItem = (isSelected) => Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${isSelected ? COLORS.GRAY[100] : 'transparent'};
  height: 56px;
  width: 534px;
  &:hover {
    cursor: pointer;
    background-color: ${!isSelected && hexToRgba(COLORS.GRAY[100], 0.2)};
  }
`;
const header = Emotion.css`
  color: ${COLORS.GRAY[500]};
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  text-transform: uppercase;
  padding: 12px 38px 12px 20px;
`;
const line = Emotion.css`
  margin: 0 0 -1px 18px;
`;

const LIST_LENGTH = 4;

const VideoPlayer = ({
  videoList,
  addButtonHandler,
  taskAddHandler,
  isTaskProfile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPlaying, setCurrentPlaying] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [
    selectedListChunk,
    chunkedVideoList,
    showListSwitcher,
    switchListHandler,
  ] = useChunkedList(videoList, LIST_LENGTH);
  useEffect(() => {
    setIsPlaying(false);
    setCurrentPlaying(
      chunkedVideoList.length ? chunkedVideoList[selectedListChunk][0] : null,
    );
  }, [selectedListChunk]);
  useEffect(() => {
    if (!currentPlaying && videoList?.length) setCurrentPlaying(videoList[0]);
  }, [videoList]);
  
  return (
    <div className={mainCard}>
      {chunkedVideoList.length ? (
       <ReactPlayer
          url={currentPlaying?.url}
          onStart={() => {
            if (isTaskProfile) {
              window.logEvent(TASK_PROFILE_VIDEO_PLAYED);
              if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(TASK_PROFILE_VIDEO_PLAYED));
              }
            } else {
              window.logEvent(JOB_PROFILE_VIDEO_PLAYED);
              if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(JOB_PROFILE_VIDEO_PLAYED));
              }
            }
          }}
          playing={isPlaying}
          controls
          onProgress={({ playedSeconds }) => {}}
        />
      ) : null}
      <div
        className={`${videoListContainer} ${
          chunkedVideoList.length ? '' : videoListEmpty
        }`}
      >
        <div className={header}>
          <span>{t('JOB_DICTIONARY.JOB_PROFILE.VIDEO_LIST')}</span>
        </div>
        <div
          className={`${
            chunkedVideoList.length ? itemWrapper : emptyItemWrapper
          }`}
        >
          {chunkedVideoList.length ? (
            chunkedVideoList[selectedListChunk]?.map((video) => (
              <div key={video.id}>
                <div
                  className={videoListItem(video.id === currentPlaying?.id)}
                  onClick={() => setCurrentPlaying(video)}
                >
                  <span style={{ marginLeft: '24px' }}>{video.title}</span>
                  <div style={{ paddingRight: '12px' }}>
                    <span style={{ marginRight: '16px' }}>
                      {video.duration}
                    </span>
                  </div>
                </div>
                {video.id !== currentPlaying?.id && (
                  <hr color={COLORS.GRAY[100]} className={line} />
                )}
              </div>
            ))
          ) : (
            <div style={{ marginLeft: '20px', maxWidth: '100%' }}>
              {t('JOB_DICTIONARY.JOB_PROFILE.EMPTY_VIDEO_LIST')}
            </div>
          )}
        </div>
        <CardFooterControl
          showSwitcher={showListSwitcher}
          switchHandler={switchListHandler}
          // addHandler={addButtonHandler}
          // taskAddHandler={taskAddHandler}
          moreItemsCount={
            videoList?.length - (selectedListChunk + 1) * LIST_LENGTH
          }
          isFirstPage={selectedListChunk === 0}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
