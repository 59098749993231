import * as types from './actionTypes';
import {
  createUserTaskJobTitleRequest,
  createUserTaskRequest,
  getMetaData,
  getUserTaskByWorksessionIdRequest,
  getUserTasksJobTitleRequest,
  removeUserTaskRequest,
  updateUserTaskRequest,
  userTaskService,
} from 'init/proto_client';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';

export const createTaskSuccess = (task) => ({ type: types.CREATE_TASK_SUCCESS, task });

export const addTaskTitleSuccess = (taskTitle) => ({ type: types.ADD_TASK_TITLE_SUCCESS, taskTitle });

function createUserTask(
  userID,
  titleId,
  timeStart,
  timeEnd,
  workSessionId,
) {
  return async (dispatch) => {
    createUserTaskRequest.setUserId(userID);
    createUserTaskRequest.setTitleId(titleId);
    createUserTaskRequest.setTimeStart(timeStart);
    createUserTaskRequest.setTimeEnd(timeEnd);
    createUserTaskRequest.setWorkSessionId(workSessionId);
    await userTaskService.createUserTask(
      createUserTaskRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch({
            type: types.SET_TASKS_ERROR,
            isLoading: false,
            error,
          });
        } else {
          const task = response.toObject();
          dispatch({
            type: types.CREATE_TASK_SUCCESS,
            task,
          });
        }
      },
    );
  };
}

export const createUserTaskJobTitleCreateTask = (
  userId,
  workSessionId,
  taskName,
  timeStart,
  timeEnd,
) => async (dispatch) => {
  createUserTaskJobTitleRequest.setUserId(userId);
  createUserTaskJobTitleRequest.setTitle(taskName);
  await userTaskService.createUserTaskJobTitle(
    createUserTaskJobTitleRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_TASKS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const taskTitle = response.toObject();
        dispatch(createUserTask(
          userId,
          taskTitle.id,
          timeStart,
          timeEnd,
          workSessionId,
        ));
        dispatch(addTaskTitleSuccess(taskTitle));
      }
    },
  );
};

export const getUserTasksTitles = (userId) => async (dispatch) => {
  getUserTasksJobTitleRequest.setUserId(userId);
  await userTaskService.getUserTasksJobTitle(
    getUserTasksJobTitleRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_TASKS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const taskTitles = response.toObject().jobTitlesList;
        dispatch({
          type: types.GET_TASK_TITLES_SUCCESS,
          isLoading: false,
          taskTitles,
        });
      }
    },
  );
};

export const getUserTaskByWorksessionId = (worksessionId) => async (dispatch) => {
  dispatch({
    type: types.SET_TASKS_ARE_LOADING,
    isLoading: true,
  });
  getUserTaskByWorksessionIdRequest.setWorkSessionId(worksessionId);
  await userTaskService.getUserTaskByWorkSessionId(
    getUserTaskByWorksessionIdRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_TASKS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        // TODO uncomment when back fix the request
        // const tasks = response.toObject().hazardsByTaskList;
        // dispatch({
        // type: types.GET_TASKS_BY_WORKSESSION_SUCCESS,
        // isLoading: false,
        // tasks,
        // });
        dispatch({
          type: types.SET_TASKS_ARE_LOADING,
          isLoading: false,
        });
      }
    },
  );
};

export const updateUserTask = (
  taskId,
  userId,
  taskName,
  timeStart,
  timeEnd,
  workSessionId,
) => async (dispatch) => {
  updateUserTaskRequest.setId(taskId);
  updateUserTaskRequest.setUserId(userId);
  updateUserTaskRequest.setTitleId(taskName);
  updateUserTaskRequest.setTimeStart(timeStart);
  updateUserTaskRequest.setTimeEnd(timeEnd);
  updateUserTaskRequest.setWorkSessionId(workSessionId);
  await userTaskService.updateUserTask(
    updateUserTaskRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_TASKS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const task = response.toObject();
        dispatch({
          type: types.UPDATE_TASK_SUCCESS,
          task: { ...task, taskName: task.title },
        });
      }
    },
  );
};

export const setEditedTask = (task) => ({ type: types.SET_EDITED_TASK, task });

export const setStartTime = (startingTime) => ({ type: types.SET_START_TIME, startingTime });

export const createUserTaskJobTitleEditTask = ({
  taskId,
  userId,
  timeStart,
  timeEnd,
  workSessionId,
  taskName,
}) => async (dispatch) => {
  createUserTaskJobTitleRequest.setUserId(userId);
  createUserTaskJobTitleRequest.setTitle(taskName);
  await userTaskService.createUserTaskJobTitle(
    createUserTaskJobTitleRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_TASKS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const taskTitle = response.toObject();
        dispatch(updateUserTask(
          taskId,
          userId,
          taskTitle.id,
          timeStart,
          timeEnd,
          workSessionId,
        ));
        dispatch(addTaskTitleSuccess(taskTitle));
      }
    },
  );
};

export const removeUserTask = (taskId) => async (dispatch) => {
  removeUserTaskRequest.setUserTaskId(taskId);
  await userTaskService.removeUserTask(
    removeUserTaskRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_TASKS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        dispatch({
          type: types.REMOVE_TASK_SUCCESS,
          taskId,
        });
      }
    },
  );
};
