import React from 'react';
import * as Emotion from 'emotion';
import { ReactComponent as Star } from 'assets/img/star-small.svg';

const starStyle = (star) => Emotion.css`
  margin-right: ${star !== 5 ? '15x' : '0'};
`

const RatingCell = ({ rowData }) => {
  const stars = [1, 2, 3, 4, 5];
  return (
    <>
      {stars.map((star) => <Star id={star} key={star} className={starStyle(star)} fill={star > rowData.rate ? "#EFF0F6" : '#FFD029'}/>)}
    </>
  )
};

export default RatingCell;
