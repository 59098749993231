import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  overviewData: {},
  error: null,
  isLoading: true,
});

export default function reducer(state = initialState, action) {
  const { overviewData, error, isLoading } = action;
  switch (action.type) {
    case types.SET_TASK_OVERVIEW: return state.merge({ overviewData, error: null });
    case types.TASK_OVERVIEW_ERROR: return state.merge({ error });
    case types.TASK_OVERVIEW_IS_LOADING: return state.merge({ isLoading });
    case types.RESET_TASK_OVERVIEW: return state.merge(initialState);
    default: return state;
  }
}
