import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'lib/utils/useStore';
import ProductPreviewContainer from 'containers/ProductPreviewContainer/ProductPreviewContainer';
import SubscriptionEnded from '../../wearables/overview/components/SubscriptionEnded';
import { contactSalesProductType, productStatus } from '../../init/proto_client';
import JobDictionaryContainer from './JobDictionaryContainer';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';
import { productPreview } from '../../containers/ProductPreviewContainer/productPreview';
const JobDictionary = () => {
    var _a;
    const { products } = useAppSelector((state) => state.companies.selectedCompany);
    const { t } = useTranslation();
    const showCurrentSubscription = (status) => {
        switch (status) {
            case productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE:
                return React.createElement(JobDictionaryContainer, null);
            case productStatus.PRODUCTSTATUSSUBSCRIPTIONFINISHED:
                return React.createElement(SubscriptionEnded, { name: "Job Dictionary", productInfo: products === null || products === void 0 ? void 0 : products.jobDictionaryProduct });
            default:
                return (React.createElement(ProductPreviewContainer, { productInfo: products.jobDictionaryProduct, productType: contactSalesProductType.PRODUCTTYPEJOBDICTIONARY, layout: productPreview.JobDictionaryPreviewProduct(t) }));
        }
    };
    return showCurrentSubscription((_a = products === null || products === void 0 ? void 0 : products.jobDictionaryProduct) === null || _a === void 0 ? void 0 : _a.status);
};
export default PrivatePageHOC(JobDictionary);
