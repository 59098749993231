// eslint-disable-next-line import/prefer-default-export
import { ExtraDepartmentOptions } from '../constants/constants';

export const filterDataByDepartment = (data, selectedDepartments) => data?.filter((report) => {
  if (!report) return false;
  if (selectedDepartments.includes(ExtraDepartmentOptions.ALL_DEPARTMENTS) &&
    report.departmentID !== -1) {
    return true;
  }
  if (report.departmentID === 0 &&
    selectedDepartments.includes(ExtraDepartmentOptions.WITH_NO_DEPARTMENT)) {
    return true;
  }
  if (report.departmentID === -1) {
    return false;
  }
  if (report.departmentName) {
    return selectedDepartments.includes(report.departmentName);
  }
  return false;
}) || [];
