import filtersNew from '../containers/ItemTableContainer/filtersStore/filtersSlice';
import auth from '../auth/store/authSlice';
import devices from '../admin/devices/devicesStore/reducer';
import clipgoUsers from '../wearables/activity/createUserStore/reducer';
import user from '../wearables/user/userStore/reducer';
import notifications from '../containers/NotificationContainer/notificationsStore/notificationsSlice';
import coachUsers from '../wearables/activity/usersStore/reducer';
import filters from '../containers/ItemTableContainer/filtersStoreOld/reducer';
import selectedItems from '../containers/ItemTableContainer/selectedItemsStore/reducer';
import tableState from '../containers/ItemTableContainer/tableStateStore/reducer';
import jobDictionary from '../dictionary/dictionaryList/jobDictionaryStore/reducer';
import companies from '../company/companiesStore/reducer';
import overview from '../wearables/overview/overviewStore/reducer';
import overviewState from '../wearables/overview/overviewState/reducer';
import helpRequests from '../wearables/action/helpRequestsStore/reducer';
import dashboardUsers from '../company/users/dashboardUsersStore/reducer';
import modals from '../containers/ModalContainer/modalsStore/reducer';
import jobProfile from '../dictionary/jobProfile/jobProfileStore/reducer';
import userTasks from '../wearables/user/userTasksStore/reducer';
import taskProfile from '../dictionary/taskProfile/taskProfileStore/reducer';
import soterTaskAssessments from '../task/video/assessmentsStore/reducer';
import soterTaskComparisons from '../task/comparison/comparisonsStore/reducer';
import soterTaskOverview from '../task/overview/overviewStore/reducer';
import hubs from '../admin/hubs/hubsStore/reducer';
import feedbacks from '../admin/feedbacks/feedbacksStore/reducer';
import category from '../company/categories/store/categorySlice';
import videos from '../task/gallery/videosStore/videoSlice';
import adminCompanies from '../admin/store/adminCompaniesSlice';
import reports from '../containers/ItemTableContainer/reportsStore/reportSlice';
import userProfile from '../profile/profileStore/reducer'

export {
  auth,
  companies,
  coachUsers,
  clipgoUsers,
  user,
  notifications,
  devices,
  filters,
  selectedItems,
  tableState,
  jobDictionary,
  overview,
  overviewState,
  helpRequests,
  dashboardUsers,
  modals,
  jobProfile,
  userTasks,
  taskProfile,
  soterTaskAssessments,
  soterTaskComparisons,
  soterTaskOverview,
  hubs,
  feedbacks,
  category,
  filtersNew,
  videos,
  adminCompanies,
  reports,
  userProfile
};
