import React from 'react';
import moment from 'moment';
import { horizontalPageLayout, verticalPageLayout } from 'assets/jss/containers/pdfLayersStyle';
import { REPORT_GENERATED_DATE_FORMAT, SINGLE_PAGE_TYPE } from './constants';
import BottomLabel from './components/BottomLabel';

const SinglePage = ({
  children, isGrayScale, type,
}) => (
  <div className={type === SINGLE_PAGE_TYPE.HORIZONTAL ? horizontalPageLayout : verticalPageLayout}>
    {children}
    <BottomLabel
      pageType={type}
      isGrayScale={isGrayScale}
      reportDate={moment().format(REPORT_GENERATED_DATE_FORMAT)}
    />
  </div>
);

export default SinglePage;
