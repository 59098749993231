import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import backArrow from '../../../assets/img/icons/arrows/arrow-left.svg';
import { infoMainText, mb5, mb6, mt4, mt5, } from '../../../assets/soter-flex-styles';
import { loginFormBtn, loginFormBtnBack, loginFormText, loginFormTitle, } from './styles';
import { useAppSelector, useAppDispatch } from '../../../lib/utils/useStore';
import { LOGIN, SSO_LOGIN } from '../../store/auth';
import Button from '../../../lib/ui/Button';
import ssoErrorMessages from '../../store/ssoErrorMessages';
import { clearError } from '../../store/authSlice';
const LoginFormCard = ({ buttonText, buttonDisabled, title, onSubmit, showBackButton, handleBackButton, children, }) => {
    var _a;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { error, errorAction } = useAppSelector((state) => state.auth);
    // For SSO Login method we have a deal with Danil that error message will be json and contain error codes that are described in errors.proto
    // For now it isn't on global API level, so I leave keep logic here
    const ssoErrorCode = (error === null || error === void 0 ? void 0 : error.message[0]) === '{' && Number((_a = JSON.parse((error === null || error === void 0 ? void 0 : error.message) || '')) === null || _a === void 0 ? void 0 : _a.code);
    const path = window.location.pathname;
    return (React.createElement("form", { onSubmit: (e) => e.preventDefault() },
        showBackButton && (React.createElement("button", { className: loginFormBtnBack, type: "button", onClick: handleBackButton },
            React.createElement("img", { src: backArrow, alt: "button back" }))),
        React.createElement("p", { className: `${infoMainText} ${loginFormTitle} ${mb6} ${mt4}` }, title),
        children,
        React.createElement(Button, { type: "submit", disabled: buttonDisabled, handler: onSubmit, text: buttonText, className: `${loginFormBtn} ${mb5} ${mt5}` }),
        (((error === null || error === void 0 ? void 0 : error.code) === 5 && errorAction !== SSO_LOGIN) || (errorAction === LOGIN && (error === null || error === void 0 ? void 0 : error.code) === 2)) && (React.createElement("span", { className: loginFormText },
            t('LOGIN.INCORRECT_PASSWORD', 'Your password or email was incorrect.'),
            React.createElement("br", null),
            t('LOGIN.TRY_AGAIN', 'Please try again'))),
        (ssoErrorCode && errorAction === SSO_LOGIN) && (React.createElement("span", { className: loginFormText }, t(ssoErrorMessages[ssoErrorCode] || 'ERRORS.UNKNOWN'))),
        React.createElement(Link, { to: path === '/auth' ? '/single-sign-on' : '/auth', onClick: () => dispatch(clearError()), style: {
                position: 'absolute',
                zIndex: 100,
                bottom: '-40px',
                left: '50%',
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap',
            } }, path === '/auth' ? t('LOGIN.WITH_SSO', 'Login with SSO') : t('LOGIN.WITH_PASSWORD', 'Login with password'))));
};
export default LoginFormCard;
