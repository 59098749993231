import { COLORS } from 'constants/colors';

const statusMap = {
  program_in_progress: {
    title: 'In Progress',
    color: COLORS.GREEN[600],
  },
  program_finished: {
    title: 'Finished',
    color: COLORS.GRAY[300],
  },
  program_finished_incomplete: {
    title: 'Finished incomplete',
    color: COLORS.ORANGE[600],
  },
  continuous_wearing: {
    title: 'Continuous wearing',
    color: COLORS.BLUE[600],
  },
  waiting_for_initial_survey: {
    title: 'Waiting for survey',
    color: '',
  },
  waiting_for_device: {
    title: 'Waiting for device',
    color: '',
  },
};

export default statusMap;
