import React from 'react';
import { errorInputStyle, formControlInput, inputLabel, mt2, } from '../../../assets/soter-flex-styles';
import { loginTextLabel, loginTextWrapper } from './styles';
const LoginTextInput = ({ name, label, type, placeholder, onChange, onBlur, value, error, checkTouched = true, touched, errorMessage, }) => {
    const validateAsBeenTouched = checkTouched ? touched : true;
    const errorMessageText = validateAsBeenTouched ? errorMessage : '';
    return (React.createElement("label", { className: `${inputLabel} ${loginTextLabel}`, htmlFor: name },
        label,
        ' ',
        React.createElement("div", { className: loginTextWrapper },
            React.createElement("input", { className: `${mt2} ${error && validateAsBeenTouched ? errorInputStyle : formControlInput}`, type: type, id: name, name: name, placeholder: placeholder, value: value, onChange: (e) => onChange && onChange(e), onBlur: (e) => onBlur && onBlur(e) }),
            React.createElement("span", null, errorMessageText))));
};
export default LoginTextInput;
