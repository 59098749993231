import React, { useRef } from 'react';
import { CategoryEntry } from './CategoryEntry';
import useOutsideClickHandler from '../../../lib/utils/useOutsideClickHandler';
import { COLORS } from '../../../constants/colors';
export const EntryTooltip = ({ tags, coords, setCoords, setUserForTagShown, setOpenedModal, rowData, }) => {
    const ref = useRef(null);
    if (coords) {
        useOutsideClickHandler(ref, (event) => {
            if (event.target.id === 'lessBtn')
                return;
            setCoords(null);
        });
    }
    return (React.createElement("div", { ref: ref, id: "EntryTooltip", style: {
            display: 'grid',
            gridTemplateColumns: '2fr 2fr 2fr',
            backgroundColor: COLORS.WHITE,
            paddingTop: '6px',
            paddingRight: '4px',
            borderRadius: '10px',
            boxShadow: '0 0 5px rgba(0,0,0,0.5)',
        } }, tags.map(({ title, id }) => (React.createElement("div", { key: id, style: { marginBottom: '4px' } },
        React.createElement(CategoryEntry, { key: id, id: id, title: title, withoutTooltip: true, rowData: rowData, setUserForTagShown: setUserForTagShown, setOpenedModal: setOpenedModal }))))));
};
