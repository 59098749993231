import { useRef } from 'react';

const useFocus = () => {
  const htmlElRef = useRef(null);
  // eslint-disable-next-line no-unused-expressions
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };

  return [htmlElRef, setFocus];
};
export default useFocus;
