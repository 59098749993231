import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';
export const loginPageWrapper = Emotion.css `
  display: flex;
  height: 100vh;
  color: ${COLORS.GRAY[950]};
`;
export const loginPageWrapperV2 = Emotion.css `
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
`;
export const loginPageImg = Emotion.css `
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
export const loginPageCard = Emotion.css `
  width: 100%;
  height: auto;
`;
export const loginFormTitle = Emotion.css `
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
  lineHeight: 1.2;
`;
export const loginFormBtnBack = Emotion.css `
  width: 24px;
  height: 25px;
  border: none;
  background: none;
  margin: -1px;
  padding: 0;
  position: absolute;

  img {
    cursor: pointer;
  }
`;
export const loginFormBtn = Emotion.css `
  width: 100%;
  text-align: center;
  display: block !important;
`;
export const loginFormText = Emotion.css `
  color: #F14B75;
`;
export const loginTextLabel = Emotion.css `
  position: relative;
`;
export const loginTextWrapper = Emotion.css `
  position: relative;

  input {
    margin-bottom: 45px;
  }

  input[type="password"] {
    margin-bottom: 16px;
  }
  
  span {
    font-size: 0.69rem;
    font-weight: 400;
    color: ${COLORS.RED[800]};
    top: 45px;
    position: absolute;
  }
`;
