import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
export const companyTitle = Emotion.css `
  font-size: 32px;
  font-weight: 900;
  color: ${COLORS.GRAY[950]};
  margin: 0 10px 0 0;
`;
export const companySubtitle = Emotion.css `
  font-size: 20px;
  font-weight: 500;
  color: ${COLORS.GRAY[400]};
  margin: 2px 0 15px;
`;
export const subscriptionWrapper = Emotion.css `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;

  &.hidden {
    display: none;
  }

  .item {
    width: 382px;
    flex-basis: 30%;
    background: ${COLORS.WHITE};
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 11px;
    padding: 6px 10px 20px 16px;

    &:last-child {
      margin-right: 0;
    }

    .title {
      font-size: 16px;
    }

    .titleGreen {
      color: ${COLORS.GREEN[600]};
    }

    .titleRed {
      color: ${COLORS.RED[600]};
    }
  }

  .item__wrapper {
    display: flex;
    align-items: center;

    svg {
      margin-right: 14px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: ${COLORS.GRAY[950]};
      margin-top: -5px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      color: ${COLORS.GRAY[400]};

      &__edit {
        font-size: 14px;
        font-weight: 400;
        color: inherit;
        margin-top: -6px;
      }
    }
  }

  .custom-border {
    width: 270px;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
    margin: 8px 0 14px;
    left: 47px;
    position: relative;

    &__edit {
      width: 296px;
      left: 0;
    }
  }
`;
export const productTitleTop = Emotion.css `
  font-size: 20px;
  font-weight: 700;
  color: ${COLORS.GRAY[950]};
  margin-top: 10px;
`;
export const productTitle = Emotion.css `
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.GRAY[950]};
  margin-top: 16px;
  margin-bottom: 10px;
`;
export const subscriptionDates = Emotion.css `
  width: 300px;
  margin-top: 0.375rem;

  svg {
    right: 0 !important;
  }

  span {
    color: ${COLORS.GRAY[950]} !important;
  }

  .rs-picker-toggle-clean {
    right: 0 !important;
  }
`;
export const wearablesWrapper = Emotion.css `
  margin-top: 12px;

  &.hidden {
    display: none;
  }

  .item__large {
    display: flex;
    flex-wrap: wrap;
    background: ${COLORS.WHITE};
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 6px 10px 15px 16px;
    width: 100%;

    .left {
      width: 50%;
    }

    .right {
      width: 44%;
      margin-left: 6%;
    }

    .info__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .item {
        width: 260px;
        margin-bottom: 18px;
        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background: rgba(0, 0, 0, 0.08);
          left: 0;
          bottom: -5px;
          position: absolute;
        }

        &:last-child:after {
          content: none;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          color: ${COLORS.GRAY[950]};
        }

        .subtitle {
          font-size: 14px;
          font-weight: 500;
          color: ${COLORS.GRAY[400]};
        }
      }

      .item__edit {
      
        &:after {
          content: none;
        }
      }
    }
  }
`;
export const wearablesWrapperEdit = Emotion.css `
  display: flex;
  background: ${COLORS.WHITE};
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 400px;
  border-radius: 12px;
  padding: 6px 10px 5px 16px;
`;
export const soterItem = Emotion.css `
  display: flex;
  flex-wrap: wrap;
  background: ${COLORS.WHITE};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 6px 10px 15px 16px;
  width: 100%;

  &.hidden {
    display: none;
  }

  .left {
    width: 50%;
  }

  .right {
    width: 44%;
    margin-left: 6%;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: ${COLORS.GRAY[950]};
    margin-top: -5px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.GRAY[400]};
  }

  .border__bottom {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
    margin: 5px 0 14px;
    position: relative;
  }
`;
export const titleWrapper = Emotion.css `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const sectionWrapper = Emotion.css `
  display: flex;
  align-items: center;
  background: transparent;
  padding-left: 0;
  cursor: pointer;
  outline: none !important;
  
  h3 {
    font-size: 24px;
    font-weight: 800;
    color: ${COLORS.GRAY[950]};
  }

  svg {
    font-size: 24px;
    color: #248BF2;
    margin-top: 5px;
    margin-left: 10px;
    transform: rotate(180deg);
    transition: 0.3s;

    &.hidden {
      transform: rotate(0);
    }
  }
`;
export const editBtn = Emotion.css `
  font-size: 18px;
  height: 36px;
`;
export const programsWrapper = Emotion.css `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;

.left {
  display: flex;
  min-height: 39px;
}

.list__img {
  margin-left: 12px;
  margin-right: 18px;
}

.info {
  max-width: 310px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  list-style: none;
  margin-top: 7px;
  padding: 0;

  li:not(:last-child) {
    margin-right: 5px;
  }
}

.programsError {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  background: rgb(255, 242, 240);
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding-left: 14px;
}

.change__btn {
  margin-left: 20px;
}

.info__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.info__text {
  font-size: 14px;
  font-weight: 500;
}

.right {
  position: relative;

  button {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.56);
    background: #F5F5F7;
    padding: 6px 10px;
    border-radius: 4px;

    &:disabled {
      opacity: 0.3;
    }
  }

  .programs__modal {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06), 0px 4px 4px rgba(0, 0, 0, 0.12);
    width: 300px;
    max-height: 320px;
    top: 40px;
    left: -235px;
    position: absolute;
    z-index: 1;
    overflow: auto;
  }
}

.border__bottom {
  width: calc(100% + 48px);
  height: 8px;
  background: rgba(0, 0, 0, 0.04);
  bottom: 8px;
  left: -24px;
  position: absolute;
}
`;
export const bodyParts = Emotion.css `
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
`;
export const otherWrapper = Emotion.css `
  .rs-checkbox {
    display: block;
  }

  .rs-checkbox-inner::before {
    border-radius: 1px;
    width: 24px;
    height: 24px;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
    width: 9px;
    height: 12px;
    margin-top: 4px;
    margin-left: 7px;
  }

  .rs-checkbox-checker {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .rs-checkbox-checker label {
    font-size: 16px;
    margin-top: 3px;
    margin-left: 10px;
  }
`;
export const inputLabel = Emotion.css `
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`;
