import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import {
  cardExtraSmall119,
  columnSmall,
  container,
  flexRow,
  mb2,
  ml1,
  ml2,
  ml3,
  mr3,
  mt3,
  mt4,
  outlineColoredButton,
} from 'assets/soter-flex-styles';
import { ReactComponent as Upload } from 'assets/img/icons/csv_download/upload.svg';
import { ReactComponent as Download } from 'assets/img/icons/csv_download/download.svg';
import { activity, users, wearables } from 'containers/PrivatPageHOC/constants/routes';
import ItemTableContainer from 'containers/ItemTableContainer/ItemTableContainer';
import UserUploadNotification from 'containers/NotificationContainer/components/StaticNotification';
import Tooltip from '../../../lib/ui/Tooltip';
import { openedModalSelector } from '../../../containers/ModalContainer/modalsStore/selectors';
import getColumns from '../../../containers/ItemTableContainer/utils/getColumns';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { TableType } from '../../../containers/ItemTableContainer/constants/tableType';
import StatusCell from '../../../containers/ItemTableContainer/components/CustomCells/StatusCell';
import { setCurrentPage } from '../../../containers/ItemTableContainer/tableStateStore/actions';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';
import { createUsers } from '../usersStore/actions';
import {
  createEventNotification,
  setNotification,
} from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { ACTIVITY_USERS_LIST_UPLOADED } from '../../../constants/analyticsEvents';
import UploadUsersFillFailure from './UploadUsersFillFailure';
import NavigateBackButton from '../../../lib/ui/NavigateBackButton';
import downloadCSV from '../../../containers/ItemTableContainer/utils/csvLoader';
import Loader from '../../../lib/ui/Loader';
import * as types from '../usersStore/actionTypes';

const HACKED_ERROR_MSG_FOR_EXISTING_USERS = 'User with same credentials already exists';

const card = Emotion.css`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  width: '100%';
  padding: 0 !important;
`;

export const icon = Emotion.css`
  stroke: #2196f3;
`;

export const button = Emotion.css`
  width: 108px;
  margin-top: -8px;
  &:hover svg {
    stroke: #ffffff;
  }
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
  &:visited,
  &:focus {
    color: #2196f3;
    text-decoration: none !important;
  }
`;

const buttonWidth = Emotion.css`
  min-width: 108px !important;
`;

const userCell
  = (key) => ({ rowData }) => <div>{rowData[key] || '-'}</div>;

const numberCell
  = (key) => ({ rowData }) => (
    <Tooltip text="Line number in CSV file" placement="bottom">
      <div>{rowData[key] || '-'}</div>
    </Tooltip>
  );

const csvHeaders = 'Login; First Name; Last Name; Department; Site; Job Role;';
const csvTitle = 'Soter Analytics create users';

const UploadUsersContainer = () => {
  const { selectedCompany } = useSelector((state) => state.companies);
  const { createdStatuses } = useSelector((state) => state.coachUsers);
  const companyId = selectedCompany?.id;
  const { t } = useTranslation();
  const [unUniqLogins, setUniqLogins] = useState([]);
  const [userCreatedCounter, setUserCreatedCounter] = useState([]);
  const [existUsersCount, setExistUsersCount] = useState([]);
  const [errorUsersCount, setErrorUsersCount] = useState([]);
  const [isUsersCreated, setIsUsersCreated] = useState(false);
  const [isInvalidLogin, setIsInvalidLogin] = useState(false);
  const [notificationErrors, setNotificationsErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState([]);
  const dispatch = useDispatch();
  const openedModal = useSelector(openedModalSelector);
  const userData = [
    'id',
    'number',
    'login',
    'firstName',
    'lastName',
    'departmentName',
    'site',
    'jobRole',
  ];
  const columns = (getActionsForUser) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.usersUpload),
    '№',
    getActionsForUser,
    TableType.usersUpload,
    t,
  );

  const columnsAfterUpload = (getActionsForUser) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.usersUpload),
    'upload status',
    getActionsForUser,
    TableType.usersUpload,
    t,
  );

  const cells = {
    number: numberCell('number'),
    login: userCell('login'),
    firstName: userCell('firstName'),
    lastName: userCell('lastName'),
    department: userCell('departmentName'),
    site: userCell('site'),
    jobRole: userCell('jobRole'),
  };

  const getStatusText = (rowData) => {
    const { status, error } = userCreatedCounter.find(({ login }) => rowData.rowData.login === login);
    if (status === 1) {
      return {
        text: 'Created',
        type: 'success',
      };
    }
    if (error === HACKED_ERROR_MSG_FOR_EXISTING_USERS) {
      return {
        text: 'Already exist',
        type: 'warning',
      };
    }
    return {
      text: error,
      type: 'error',
    };
  };

  const afterUploadCells = {
    number: (rowData) => <StatusCell content={getStatusText(rowData)} />,
    login: userCell('login'),
    firstName: userCell('firstName'),
    lastName: userCell('lastName'),
    department: userCell('departmentName'),
    site: userCell('site'),
    jobRole: userCell('jobRole'),
  };

  const handleFileUpload = (e) => {
    dispatch(setCurrentPage(1, TableType.usersUpload));
    setIsLoading(true);
    setUploadResults(true);
    setNotificationsErrors(null);
    setUniqLogins([]);
    const { files } = e.target;
    const errors = [];
    const not = [];
    if (files) {
      Papa.parse(files[0], {
        skipEmptyLines: 'greedy',
        complete(results) {
          const data = _uniqWith(results.data, _isEqual);
          const logins = [];
          for (let line = 0; line < data.length; line++) {
            const number = line;
            const id = number + 1;
            const lineElems = data[line];
            if (line === 0 && lineElems[line].toLowerCase() === 'login') {
              not.push(lineElems);
            }
            lineElems.unshift(number);
            lineElems.unshift(id);
            logins.push({ line, login: lineElems[2] });
            if (lineElems.length > userData.length) {
              dispatch(setOpenedModal('fillingError'));
            }
            for (let elem = 0; elem < userData.length - 2; elem++) {
              const element = lineElems[elem];
              if (elem < 3 && element === '') {
                errors.push(lineElems);
                not.push(lineElems);
                break;
              }
            }
          }
          logins.map((login) => {
            if (
              logins.filter((l) => {
                if (l.login !== 'Login' && l.login === login.login) {
                  if (/[A-Z]/.test(l.login) || /\s/.test(l.login)) {
                    if (!isInvalidLogin) setIsInvalidLogin(true);
                  }
                  return login.login;
                }
              }).length > 1
            ) {
              setUniqLogins([...unUniqLogins, login.login]);
            }
          });
          errors.length > 0 ? setNotificationsErrors(errors) : null;
          for (const n of not) {
            data.splice(
              data.findIndex((v) => v === n),
              1,
            );
          }
          data.unshift(...errors);
          data.unshift(userData);
          const [keys, ...rows] = data;
          const result = rows.map((r) => keys.reduce((o, k, i) => ((o[k] = r[i]), o), {}));
          const formattedResult = result.map((user) => {
            user.login = user.login.replace(/ /g, '').toLowerCase();
            return user;
          });
          setUploadResults(formattedResult);
          setIsLoading(false);
          if (
            results.data.length <= 1 &&
            results.data[0].includes('Site' || 'site')
          ) {
            dispatch(setNotification({
              type: notificationTypes.TYPE_FAILED,
              message: notificationMessages.UPLOAD_EMPTY_CSV_ERROR,
            }));
          }
        },
      });
    }
  };
  const prepareUserData = (user) => ({
    login: user?.login,
    firstName: user?.firstName,
    lastName: user?.lastName,
    jobTitle: user?.jobRole,
    department: user?.departmentName,
    site: user?.site,
    id: user?.id,
  });
  const confirmButtonHandler = () => {
    window.logEvent(ACTIVITY_USERS_LIST_UPLOADED);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(ACTIVITY_USERS_LIST_UPLOADED));
    }
    dispatch(createUsers(uploadResults, companyId));
    setIsUsersCreated(true);
  };
  const confirmButton = notificationErrors?.length > 0
    ? {
      text: t('WEARABLES.ACTIVITY.UPLOAD_NEW'),
      action: handleFileUpload,
    }
    : {
      text: t('WEARABLES.ACTIVITY.CREATE'),
      action: confirmButtonHandler,
    };
  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
  };
  const fillingErrorHandler = () => {
    setUploadResults([]);
    dispatch(setOpenedModal(null));
  };
  const switchModal = (modalType) => {
    switch (modalType) {
      case 'fillingError':
        return <UploadUsersFillFailure handler={fillingErrorHandler} />;
      default:
        return null;
    }
  };
  let notification = {};
  // eslint-disable-next-line no-unused-expressions
  if (unUniqLogins.length > 0) {
    notification = {
      status: 'TYPE_WARNING',
      message: `${unUniqLogins?.length} ${
        uploadResults > 1 ? t('GENERAL.USERS') : t('GENERAL.USER')
      } ${t('WEARABLES.ACTIVITY.CAN_NOT_BE')} ${
        unUniqLogins.length === 1 ? t('GENERAL.LOGIN') : t('GENERAL.LOGINS')
      }:
      ${unUniqLogins.map((loginName) => ` "${loginName}"`)}.`,
    };
  } else if (isInvalidLogin) {
    notification = {
      status: 'TYPE_WARNING',
      message: t('WEARABLES.ACTIVITY.DEL_SPACES'),
    };
  } else if (!notificationErrors?.length) {
    notification = {
      status: 'TYPE_SUCCESS',
      message: `${uploadResults?.length} ${
        uploadResults > 1 ? t('GENERAL.USERS') : t('GENERAL.USER')
      } ${t('WEARABLES.ACTIVITY.LOADED')}. 
      ${t('WEARABLES.ACTIVITY.CLICK_TO')}.`,
    };
  } else {
    notification = {
      status: 'TYPE_FAILED',
      message: `${notificationErrors?.length} ${t(
        'WEARABLES.ACTIVITY.WERE_NOT',
      )}. ${
        notificationErrors?.length < 10
          ? `${t('WEARABLES.ACTIVITY.NEW_LINES')} №:${notificationErrors?.map(
            (item) => ` ${item[1]}`,
          )}`
          : t('WEARABLES.ACTIVITY.YOU_NEED')
      }`,
    };
  }
  useEffect(() => {
    setUserCreatedCounter(createdStatuses);
  }, [createdStatuses]);
  useEffect(() => () => {
    dispatch({
      type: types.USERS_SET_CREATED_STATUSES,
      createdStatuses: [],
    });
  }, []);
  const createdUsersLength = createdStatuses.filter(({ status }) => status === 1).length;
  const failedUsersLength = createdStatuses.length - createdUsersLength;
  const alreadyExistUsersCount = createdStatuses.filter(({ error }) => error === HACKED_ERROR_MSG_FOR_EXISTING_USERS).length;
  const otherErrorsUsersCount = failedUsersLength - alreadyExistUsersCount;
  const resetCurrentPage = () => {
    dispatch(setCurrentPage(1, TableType.usersUpload));
  };
  const NavigateBack = () => (
    <div onClick={resetCurrentPage}>
      <NavigateBackButton
        name={t('WEARABLES.ACTIVITY.BACK_TO')}
        navigateTo={`/${selectedCompany.id}/${users}/${wearables}/${activity}`}
        additionalHandler={() => {
          setExistUsersCount([]);
          setUserCreatedCounter([]);
          setErrorUsersCount([]);
          setUniqLogins([]);
        }}
      />
    </div>
  );
  return (
    <>
      {uploadResults.length === 0 ? (
        <div className={container}>
          <div className={`${flexRow} ${mt3}`}>
            <div
              className={columnSmall}
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <NavigateBack />
            </div>
          </div>
          <div className={`${flexRow} ${container} ${mt4}`}>
            <div className={`${flexRow} ${container} ${mt4}`}>
              <div
                className={`${cardExtraSmall119} ${mr3}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '50px 25px',
                }}
              >
                <div className={card}>
                  <div style={{ marginRight: '15px' }}>
                    {t('WEARABLES.ACTIVITY.DOWNLOAD_CSV')}
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      width: '108px',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                    className={`${button} ${outlineColoredButton(
                      '#2196F3',
                      'small',
                    )}`}
                    onClick={() => downloadCSV(csvHeaders, csvTitle)}
                  >
                    <Download className={icon} />
                    <p style={{ marginTop: '2px' }}>
                      {t('WEARABLES.ACTIVITY.DOWNLOAD')}
                    </p>
                  </button>
                </div>
              </div>
              <div
                className={`${cardExtraSmall119} ${ml3}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '50px 25px',
                }}
              >
                <div className={card}>
                  <div style={{ marginRight: '15px' }}>
                    {t('WEARABLES.ACTIVITY.POPULATED')}
                  </div>
                  <div>
                    <input
                      name="file"
                      type="file"
                      accept=".csv"
                      id="csv_input"
                      style={{ display: 'none' }}
                      onClick={() => setIsLoading(true)}
                      onChange={handleFileUpload}
                    />
                    <label
                      htmlFor="csv_input"
                      style={{ cursor: 'pointer', marginBottom: '0' }}
                      value=""
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                        className={`${button} ${buttonWidth} ${outlineColoredButton(
                          '#2196F3',
                          'small',
                        )}`}
                      >
                        <Upload className={icon} />
                        <p style={{ marginTop: '2px' }}>
                          {t('WEARABLES.ACTIVITY.FILE')}
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <Loader />
      ) : isUsersCreated ? (
        uploadResults.length
        === userCreatedCounter.length
          + existUsersCount.length
          + errorUsersCount.length ? (
            <div>
              <div className={container}>
                <div className={`${flexRow} ${ml2} ${mt3}`}>
                  <div
                    className={`${ml1} ${mr3} ${mb2}`}
                    style={{ display: 'block', width: '100%' }}
                  >
                    {createdUsersLength > 0 && (
                    <div
                      style={{
                        marginBottom:
                          existUsersCount.length > 0 ? '15px' : '0px',
                      }}
                    >
                      <UserUploadNotification
                        notification={{
                          status: 'TYPE_SUCCESS',
                          message: `${createdUsersLength} ${
                            createdUsersLength > 1
                              ? t('GENERAL.USERS')
                              : t('GENERAL.USER')
                          }
                       ${t('WEARABLES.ACTIVITY.CREATED')}.`,
                        }}
                      />
                    </div>
                    )}
                    {alreadyExistUsersCount > 0 && (
                    <div
                      style={{
                        marginBottom:
                          errorUsersCount.length > 0 ? '15px' : '0px',
                      }}
                    >
                      <UserUploadNotification
                        notification={{
                          status: 'TYPE_WARNING',
                          message: `${alreadyExistUsersCount} ${
                            alreadyExistUsersCount > 1
                              ? t('GENERAL.USERS')
                              : t('GENERAL.USER')
                          }
                        ${t('WEARABLES.ACTIVITY.EXIST')}.`,
                        }}
                      />
                    </div>
                    )}
                    {otherErrorsUsersCount > 0 && (
                    <div style={{ marginBottom: '15px' }}>
                      <UserUploadNotification
                        notification={{
                          status: 'TYPE_FAILED',
                          message: `${otherErrorsUsersCount} ${
                            otherErrorsUsersCount > 1 ? 'users' : 'user'
                          }
                       ${t('WEARABLES.ACTIVITY.WERE_NOT_CREATED')}.`,
                        }}
                      />
                    </div>
                    )}
                  </div>
                  <NavigateBack />
                </div>
              </div>
              <ItemTableContainer
                items={uploadResults}
                columns={columnsAfterUpload}
                cells={afterUploadCells}
                type={TableType.usersUpload}
                isLoading={isLoading}
                openedModal={openedModal}
                withCloseButton={openedModal !== 'fillingError'}
                setOpenedModal={setOpenedModal}
                switchModal={switchModal}
                onCloseModal={
                openedModal === 'fillingError' ? false : handleCloseModal
              }
              />
            </div>
          ) : (
            <Loader />
          )
      ) : (
        <>
          <div className={container}>
            <div className={`${flexRow} ${ml2} ${mt3}`}>
              <div
                className={`${ml1} ${mr3} ${mb2}`}
                style={{ display: 'flex', width: '100%' }}
              >
                <UserUploadNotification notification={notification} />
              </div>
              <NavigateBack />
            </div>
          </div>
          <ItemTableContainer
            items={uploadResults}
            columns={columns}
            cells={cells}
            type={TableType.usersUpload}
            isLoading={isLoading}
            openedModal={openedModal}
            withCloseButton={openedModal !== 'fillingError'}
            setOpenedModal={setOpenedModal}
            switchModal={switchModal}
            onCloseModal={
              openedModal === 'fillingError' ? false : handleCloseModal
            }
            filterButtonProps={confirmButton}
          />
        </>
      )}
    </>
  );
};

export default UploadUsersContainer;
