import React from 'react';
import { Checkbox, Radio, RadioGroup } from 'rsuite';
import { useTranslation } from 'react-i18next';
export const PictureStepsWrapper = ({ steps, title, selectedFields, isSelected, clickHandler, disabled, }) => {
    const filteredSteps = steps.filter((step) => step.type === 'radio' && selectedFields.includes(step.id));
    return (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-around' } },
        React.createElement(RadioGroup, { inline: true, name: title, defaultValue: filteredSteps.length > 0 ? filteredSteps[0].id : undefined, onChange: (value) => clickHandler(value, 'radio') }, steps.map(({ image, score, id, type, }) => (React.createElement("div", { key: id },
            React.createElement("img", { src: image, alt: "-" }),
            type === 'radio'
                ? React.createElement(Radio, { value: id, disabled: disabled }, `+${score}`)
                : (React.createElement(Checkbox, { onChange: (value) => clickHandler(value, 'checkbox'), value: id, checked: isSelected(id), disabled: disabled }, `+${score}`))))))));
};
export const TextStepsWrapper = ({ steps, title, selectedFields, weight, weightUnit, isSelected, clickHandler, disabled, }) => {
    const filteredSteps = steps.filter((step) => step.type === 'radio' && selectedFields.includes(step.id));
    const { t } = useTranslation();
    return (React.createElement(RadioGroup, { name: title, defaultValue: filteredSteps.length > 0 ? filteredSteps[0].id : undefined, onChange: (value) => clickHandler(value, 'radio') }, steps.map(({ text, score, id, type, }) => (React.createElement("div", { key: id }, type === 'radio' ? (React.createElement(Radio, { value: id, inline: true, disabled: disabled },
        React.createElement("span", null, `${score < 0 ? '' : '+'}${score} `),
        React.createElement("span", null, weight ? t(text[weightUnit]) : t(text)))) : (React.createElement(Checkbox, { onChange: (value) => clickHandler(value, 'checkbox'), value: id, checked: isSelected(id), disabled: disabled },
        React.createElement("span", null, t(text)),
        React.createElement("span", null, ` ${score < 0 ? '' : '+'}${score}`))))))));
};
