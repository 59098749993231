import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'lib/utils/useStore';
import CategoriesTable from '../components/CategoriesTable';
import { setColorCounter } from './store/categorySlice';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';
import { getCategories } from './store/actions';
const CompanyCategoriesContainer = () => {
    const { categories, colors } = useSelector((state) => state.category);
    const dispatch = useDispatch();
    const { selectedCompany } = useAppSelector((state) => state.companies);
    // useColorSetter(categories, colors, loader, setLoader);
    useEffect(() => {
        colors.forEach((color, index) => {
            let colorCounter = 0;
            categories === null || categories === void 0 ? void 0 : categories.forEach((category) => {
                if (color.main === category.color) {
                    // eslint-disable-next-line no-plusplus
                    colorCounter++;
                }
            });
            if (colorCounter !== 0) {
                dispatch(setColorCounter({ colorIndex: index, colorCounter }));
            }
            colorCounter = 0;
        });
    }, [categories]);
    useEffect(() => {
        dispatch(getCategories(selectedCompany.id));
    }, []);
    return React.createElement(CategoriesTable, { categories: categories });
};
export default PrivatePageHOC(CompanyCategoriesContainer);
