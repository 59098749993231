import React from 'react';
import { useTranslation } from 'react-i18next';
import _map from 'lodash/map';
import * as Emotion from 'emotion';
import { TableType } from 'containers/ItemTableContainer/constants/tableType';
import { showFilterPanel } from 'containers/ItemTableContainer/utils/shownFilters';
import { button, icon } from '../../../wearables/activity/UsersUpload/UploadUsersContainer';
import SearchInputForUserTable from './SearchInpuForTable';
import TableFilterPanel from './TableFilterPanel';
import UserActionDropdown from '../../../wearables/user/UserContainer/UserActionDropdown';
import { bubbleForButton, flexColumn, outlineColoredButton } from '../../../assets/soter-flex-styles';
import { ReactComponent as Upload } from '../../../assets/img/icons/csv_download/upload.svg';
import Button from '../../../lib/ui/Button';
import PassedProgramSwitcher from './PassedProgramsSwitcher';
import DropToggler from './DropToggler';
import { COLORS } from '../../../constants/colors';

export const buttonWithSvg = Emotion.css`
  &:hover svg {
    stroke: #ffffff;
  }
  &:active svg {
    stroke: #ffffff;
  }
`;

const FiltersWithButton = ({
  filteringParameters,
  type,
  modalClass,
  secondaryButton = null,
  onActionBtnClick,
  actionButtonText,
  disabled,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('language');
  return (
    <div>
      <div
        className={flexColumn}
        style={{
          flex: '1 1 60%',
          display: 'flex',
          alignItems: 'center',
          width: '1188px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ maxWidth: '1081px', paddingRight: '12px' }}>
            <TableFilterPanel
              filteringParameters={filteringParameters}
              type={type}
            />
          </div>
          {type === TableType.coach ? <PassedProgramSwitcher /> : null}

          <div
            style={{
              width: '248px',
              minWidth: '95px',
              marginLeft: showFilterPanel(type) ? '0px' : '-15px',
            }}
          >
            <SearchInputForUserTable type={type} />
          </div>
        </div>
        {secondaryButton ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {_map(secondaryButton, (button) => (
              <div key={Math.random()}>
                {button.dropdown && (
                  <UserActionDropdown
                    user={button.dropdown.selectedItems}
                    company={button.dropdown.selectedCompany}
                    userActions={button.dropdown.userActions}
                    itemType={button.dropdown.type}
                    secondaryButton
                    bubble={button.bubble}
                    modalClass={modalClass}
                  >
                    <DropToggler
                      id={button.dropdown.type}
                      content={button.dropdown.content}
                      togglerButton={button.dropdown.togglerButton}
                      direction="down"
                      disabled={button.disabled}
                    />
                  </UserActionDropdown>
                )}
                {!button.dropdown && (
                  <Button
                    appearance="secondary"
                    handler={button?.handler}
                    style={{
                      marginRight: '5px',
                      height: '38px',
                      fontSize:
                        lang === 'es'
                          ? '10px'
                          : lang === 'fr'
                            ? '8px'
                            : '0.875rem',
                    }}
                    type="button"
                    disabled={button.disabled}
                  >
                    <div
                      style={{ margin: '1px -6px -1px -6px' }}
                      onClick={button?.dropdown}
                    >
                      {button?.link ? (
                        <a
                          href={button.link}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            width: '100%',
                            height: '100%',
                            textDecoration: 'none',
                            color: COLORS.LIGHT_BLUE[600],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {button.text}
                        </a>
                      ) : (
                        button.text
                      )}
                      {button?.bubble ? (
                        <div className={bubbleForButton}>{button.bubble}</div>
                      ) : null}
                    </div>
                  </Button>
                )}
              </div>
            ))}
            {actionButtonText && (
              <Button
                appearance="secondary"
                text={actionButtonText}
                handler={onActionBtnClick}
                style={{
                  marginRight: '11px',
                  height: '38px',
                  fontSize:
                    lang === 'es' &&
                    actionButtonText
                      === t('WEARABLES.ACTIVITY.DIALOGS.CREATE_USER')
                      ? '10px'
                      : lang === 'fr' &&
                        actionButtonText
                          === t('WEARABLES.ACTIVITY.DIALOGS.CREATE_USER')
                        ? '8px'
                        : '0.875rem',
                }}
                type="button"
              />
            )}
          </div>
        ) : null}
      </div>
      {!secondaryButton ? (
        <div
          className={flexColumn}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '977px',
            marginTop: '-38px',
            marginLeft: '200px',
          }}
        >
          {actionButtonText
          === t('WEARABLES.ACTIVITY.ACTIONS.UPLOAD_NEW_FILE') ? (
            <div>
              <input
                name="file"
                type="file"
                accept=".csv"
                id="csv_input"
                style={{ display: 'none' }}
                onChange={onActionBtnClick}
              />
              <label htmlFor="csv_input" style={{ cursor: 'pointer' }} value="">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    paddingTop: '10px',
                    justifyContent: 'space-around',
                  }}
                  className={`${button} ${outlineColoredButton(
                    '#2196F3',
                    'small',
                  )}`}
                >
                  <Upload className={icon} />
                  <p style={{ marginTop: '2px' }}>{actionButtonText}</p>
                </div>
              </label>
            </div>
            ) : (
              actionButtonText && (
              <Button
                appearance="secondary"
                text={actionButtonText}
                handler={onActionBtnClick}
                disabled={disabled}
                style={{
                  width: 'minWidth',
                  height: '38px',
                }}
                type="button"
              />
              )
            )}
        </div>
      ) : null}
    </div>
  );
};

export default FiltersWithButton;
