import * as Yup from 'yup';
import { errorMessages } from 'constants/validationMessages';

const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default Yup.object({
  firstName: Yup.string().trim().required(errorMessages.requiredField),
  lastName: Yup.string().trim().required(errorMessages.requiredField),
  email: Yup.string().required(errorMessages.requiredField).matches(emailRegexp, errorMessages.email),
  resellerId: Yup.object().shape().nullable().when('isPartner',
    (isPartnerValue, resellerIdSchema) => {
      if (isPartnerValue) {
        return resellerIdSchema.required(errorMessages.requiredField);
      }
      return resellerIdSchema;
    }),
  companies: Yup.array().when('isAdmin',
    (isAdminValue, companiesSchema) => {
      if (!isAdminValue) {
        return companiesSchema.min(1, errorMessages.requiredField).required(errorMessages.requiredField);
      }
      return companiesSchema;
    }),
});
