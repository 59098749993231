import * as Emotion from 'emotion';

export const flexRow = Emotion.css`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
`;

export const column = Emotion.css`
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  min-height: 1px;
  max-width: 100%;
`;

export const flexColumnFlowColumnAligned = Emotion.css`
  ${column};

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
`;

export const flexColumn = Emotion.css`
  ${column};
  padding-right: 0.75rem;
  padding-left: 0.75rem;
`;

export const smallLoader = Emotion.css`
  width: 10px;
  height: 10px;
`;

export const columnExtraSmall = Emotion.css`
  ${flexColumn};

  flex: 1 1 100%;
  @media (min-width: 576px) {
    margin-bottom: 0;
    flex: 0 1 33.33333333%;
  }
  @media (min-width: 1280px) {
    margin-bottom: 0;
    flex: 0 1 16.66666667%;
  }
`;

export const columnMediumSmall = Emotion.css`
  ${flexColumn};

  flex: 1 1 100%;
  @media (min-width: 576px) {
    margin-bottom: 0;
    flex: 0 1 50%;
  }
  @media (min-width: 992px) {
    margin-bottom: 0;
    flex: 0 1 25%;
  }
`;

export const columnSmall = Emotion.css`
  ${flexColumn};

  flex: 1 1 100%;
  @media (min-width: 768px) {
    margin-bottom: 0;
    flex: 1 1 50%;
  }
  @media (min-width: 992px) {
    margin-bottom: 0;
    flex: 0 1 33.33333333%;
  }
`;

export const columnMedium = Emotion.css`
  ${flexColumn};

  flex: 1 1 100%;
  @media (min-width: 992px) {
    margin-bottom: 0;
    flex: 0 1 50%;
  }
`;

export const columnLarge = Emotion.css`
  ${flexColumn};

  margin-bottom: 0;
  flex: 1 1 100%;
`;
