import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  overview: {},
  isLoading: true,
  error: null,
});

export default function reducer(state = initialState, action) {
  const { overview, error } = action;
  switch (action.type) {
    case types.SET_OVERVIEW_IS_LOADING:
      return ({ ...state, isLoading: true });
    case types.RESET_OVERVIEW_IS_LOADING:
      return ({ ...state, isLoading: false });
    case types.SET_OVERVIEW_ERROR:
      return ({ ...state, error });
    case types.RESET_OVERVIEW_ERROR:
      return ({ ...state, error: null });
    case types.SET_OVERVIEW:
      return ({ ...state, overview });
    case types.RESET_OVERVIEW:
      return (initialState);
    default: return state;
  }
}
