import { hazardsEnum } from '../constants/hazardsEnum';

const { spine, shoulder } = hazardsEnum;

const getCurrentHazardsList = (deviceType) => {
  if (deviceType !== 'armband') {
    return [
      {
        key: 'lifting',
        title: spine.lifting,
        colorIndex: 3,
      },
      {
        key: 'twisting',
        title: spine.twisting,
        colorIndex: 2,
      },
      {
        key: 'repetition',
        title: spine.repetition,
        colorIndex: 1,
      },
      {
        key: 'intensity',
        title: spine.intensity,
        colorIndex: 0,
      },
      {
        key: 'pbStatic',
        title: spine.pbStatic,
        colorIndex: 4,
      },
    ];
  }
  return [
    {
      key: 'armElevation',
      title: shoulder.armElevation,
      colorIndex: 3,
    },
    {
      key: 'pushingPulling',
      title: shoulder.pushingPulling,
      colorIndex: 2,
    },
    {
      key: 'staticArmElevation',
      title: shoulder.staticArmElevation,
      colorIndex: 4,
    },
    {
      key: 'overexertion',
      title: shoulder.overexertion,
      colorIndex: 0,
    },
    {
      key: 'repetitiveArmMovements',
      title: shoulder.repetitiveArmMovements,
      colorIndex: 1,
    },
  ];
};

export default getCurrentHazardsList;
