import React, { useEffect, useRef, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import hexToRgba from '../../../lib/utils/hexToRgba';
import useOutsideClickHandler from '../../../lib/utils/useOutsideClickHandler';
import CustomInput from '../../../lib/ui/CustomInput';
import { tagsMenu } from '../../../dictionary/components/EditableDictionary';

const tagsMenuItem = Emotion.css`
  padding: 6px 10px;
  border-bottom: 1px solid #e0e5ec;
  &:hover {
    cursor: pointer;
    background-color: ${hexToRgba(COLORS.LIGHT_BLUE[600], 0.4)};
  }
`;

const menu = Emotion.css`
  ${tagsMenu};

  width: 100%;
  z-index: 12;
  max-height: 100px;
  overflow-y: scroll;
`;

const InputWithPreselect = ({
  value,
  setValue,
  preselectionList = [],
  id,
  className,
  disabled
}) => {
  const MENU_LENGTH = 6;
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredList, setFilteredList] = useState(null);
  useEffect(() => {
    if (!preselectionList || preselectionList?.length === 0) return;
    const newList = _sortBy(preselectionList);
    if (value) {
      setFilteredList(
        newList
          .filter((v) => v.toLowerCase().indexOf(value.toLowerCase()) !== -1)
          .slice(0, MENU_LENGTH),
      );
    } else {
      setFilteredList(newList);
    }
  }, [preselectionList, value]);
  useOutsideClickHandler(ref, () => setIsMenuOpen(false));
  return (
    <div style={{ position: 'relative' }}>
      <CustomInput
        disabled={disabled}
        autoComplete="off"
        className={className}
        id={id}
        name={id}
        type="text"
        value={value}
        onChange={(_, v) => {
          setIsMenuOpen(true);
          setValue(v);
        }}
        onClear={() => setValue('')}
        onFocus={() => !value && setIsMenuOpen(true)}
        onFocusOut={() => setIsMenuOpen(false)}
      />
      {isMenuOpen && filteredList?.length > 0 && (
      <div ref={ref} className={menu}>
        {filteredList.map((i) => (
          <div
            className={tagsMenuItem}
            key={i}
            onClick={() => {
              setValue(i);
              setIsMenuOpen(false);
            }}
          >
            {i}
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default InputWithPreselect;
