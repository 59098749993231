import React, { useMemo, useState } from 'react';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { card, ml2, mt2 } from '../../assets/soter-flex-styles';
import { text } from '../../assets/jss/style';
import legendSquare from '../../assets/jss/components/legendSquare';
import down from '../../assets/img/icons/arrows/arrow-down.svg';
import downWhite from '../../assets/img/icons/arrows/arrow-down-white.svg';
import up from '../../assets/img/icons/arrows/arrow-up.svg';
import upWhite from '../../assets/img/icons/arrows/arrow-up-white.svg';
import {
  bodyPartNames, bodyPartNamesHackMap, riskGroupColors, riskGroupNames,
} from '../video/constants';
import prepareRisksForHumanLabels from '../utils/prepareRisksForHumanLabels';
import BodyPartsRiskScore from './BodyPartsRiskScore';

const riskLabelsContainer = Emotion.css`
  display: grid;
  grid-template-rows: 2fr repeat(1fr);
  grid-gap: 8px;
`;

const overallImprovementSlot = (firstColor, secondColor) => Emotion.css`
  //grid-area: overall;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${text(COLORS.WHITE, 20)};
  width: 473px;
  height: 40px;
  margin-bottom: 5px;
  background: linear-gradient(to right, ${firstColor}, ${secondColor});

  &:before {
    content: '';
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid ${secondColor};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
`;

const improvementLabel = (firstColor, secondColor) => Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${text(COLORS.WHITE, 20)};
  margin-bottom: 5px;

  div {
    background-color: ${firstColor};
    width: 132px;
    text-align: center;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    background-color: ${secondColor};
    width: 132px;
    height: 40px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const bodyPartImprovementSlot = Emotion.css`
  display: grid;
  grid-template-columns: 2fr 1fr 5fr 2fr;
`;

const riskLabel = Emotion.css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const bodyPartNumber = Emotion.css`
  border-radius: 20px;
  height: 42px;
  width: 42px;
  color: ${COLORS.LIGHT_BLUE[600]};
  font-size: 16px;
  border: 1px solid ${COLORS.LIGHT_BLUE[600]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const bodyPartImprovementsLabel = (firstColor, secondColor) => Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, ${firstColor}, ${secondColor});
  position: relative;
  margin-right: 18px;

  &:before {
    content: '';
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid ${secondColor};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
`;

const whiteLabel = (isSelected) => Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${isSelected ? 'transparent' : COLORS.WHITE};
  position: relative;
  height: 40px;
  width: 99%;
  margin-left: 1px;
  color: ${isSelected ? COLORS.WHITE : COLORS.GRAY[400]};
  padding-left: 5px;

  &:before {
    content: '';
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid ${isSelected ? 'transparent' : COLORS.WHITE};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
`;

export const RiskLabelsContainer = ({
  firstRisks,
  secondRisks,
  risksImprovements,
  selectBodyPart = () => {},
  selectedBodyPart,
  hideNumbers,
}) => {
  const { t } = useTranslation();
  const firstColor = riskGroupColors[firstRisks?.overallRisk];
  const secondColor = riskGroupColors[secondRisks?.overallRisk];
  return (
    <div className={riskLabelsContainer}>
      <div className={overallImprovementSlot(firstColor, secondColor)}>
        {`${t('WEARABLES.OVERVIEW.OVERALL_IMPROVEMENT').toUpperCase()}: 
        ${
          risksImprovements.overallImprovement
            ? Math.ceil(Math.abs(risksImprovements.overallImprovement))
            : 0
        }% 
        ${
          risksImprovements.overallImprovement > 0
            ? t('SOTER_TASK.COMPARISON.MORE')
            : ''
        }
        ${
          risksImprovements.overallImprovement < 0
            ? t('SOTER_TASK.COMPARISON.LESS')
            : ''
        }`}
      </div>
      <div className={improvementLabel(firstColor, secondColor)}>
        <div>{t('SOTER_TASK.OVERVIEW.BEFORE')}</div>
        <span>{t('SOTER_TASK.OVERVIEW.AFTER')}</span>
      </div>
      {risksImprovements?.bodyPartsImprovements?.map(({ id, value, index }) => (
        <div
          key={id}
          className={bodyPartImprovementSlot}
          onClick={() => selectBodyPart(id)}
        >
          <div className={riskLabel}>
            <div
              className={legendSquare(
                riskGroupColors[firstRisks[`${bodyPartNamesHackMap[id]}Risk`]],
              )}
            />
            <span>
              {t(riskGroupNames[firstRisks[`${bodyPartNamesHackMap[id]}Risk`]])}
            </span>
          </div>
          <div
            style={{
              color: hideNumbers && 'transparent',
              borderColor: hideNumbers && 'transparent',
            }}
            className={bodyPartNumber}
          >
            {`0${index + 1}`}
          </div>
          <div
            className={bodyPartImprovementsLabel(
              riskGroupColors[firstRisks[`${bodyPartNamesHackMap[id]}Risk`]],
              riskGroupColors[secondRisks[`${bodyPartNamesHackMap[id]}Risk`]],
            )}
          >
            <div className={whiteLabel(selectedBodyPart === id)}>
              <div>{t(bodyPartNames[id]).toUpperCase()}</div>
              <div
                style={{
                  fontSize: '17px',
                  color:
                    selectedBodyPart === id
                      ? COLORS.WHITE
                      : value < 0
                        ? COLORS.GREEN[400]
                        : value > 0
                          ? COLORS.ORANGE[300]
                          : COLORS.GRAY[400],
                }}
              >
                {`${value ? Math.ceil(Math.abs(value)) : 0}%`}
                {value < 0 ? (
                  <img
                    src={selectedBodyPart === id ? downWhite : down}
                    height="20px"
                  />
                ) : (
                  ''
                )}
                {value > 0 ? (
                  <img
                    src={selectedBodyPart === id ? upWhite : up}
                    height="20px"
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div style={{ marginLeft: '18px' }} className={riskLabel}>
            <div
              className={legendSquare(
                riskGroupColors[secondRisks[`${bodyPartNamesHackMap[id]}Risk`]],
              )}
            />
            <span>
              {t(
                riskGroupNames[secondRisks[`${bodyPartNamesHackMap[id]}Risk`]],
              )}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const BodyPartsRiskComparison = ({
  firstVideoRisks = {},
  secondVideoRisks = {},
  gridArea,
  improvements,
}) => {
  const { t } = useTranslation();
  const bodyParts = Object.keys(bodyPartNames);
  const [selectedBodyPart, setSelectedBodyPart] = useState(bodyParts[0]);
  const preparedFirstVideoRisks = useMemo(
    () => prepareRisksForHumanLabels(firstVideoRisks),
    [firstVideoRisks],
  );
  const preparedSecondVideoRisks = useMemo(
    () => prepareRisksForHumanLabels(secondVideoRisks),
    [secondVideoRisks],
  );
  return (
    <div className={card} style={{ gridArea, height: '480px' }}>
      <div className={`${text(COLORS.GRAY[400], 20)} ${mt2} ${ml2}`}>
        {t('SOTER_TASK.COMPARISON.BODY_PART').toUpperCase()}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <BodyPartsRiskScore
          selectedBodyPart={selectedBodyPart}
          selectBodyPart={setSelectedBodyPart}
          risks={preparedFirstVideoRisks}
          isComparison
        />
        <RiskLabelsContainer
          firstRisks={firstVideoRisks}
          secondRisks={secondVideoRisks}
          risksImprovements={improvements}
          selectBodyPart={setSelectedBodyPart}
          selectedBodyPart={selectedBodyPart}
        />
        <BodyPartsRiskScore
          selectedBodyPart={selectedBodyPart}
          selectBodyPart={setSelectedBodyPart}
          risks={preparedSecondVideoRisks}
          isComparison
        />
      </div>
    </div>
  );
};

export default BodyPartsRiskComparison;
