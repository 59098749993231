import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  users: [],
  currentDashboardUserId: null,
  inviteLink: null,
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.DASHBOARD_USERS_SET: {
      const { users } = action;
      return state.merge({
        users,
        error: null,
      });
    }
    case types.DASHBOARD_USER_ADD: {
      const { user } = action;
      return state.update('users', (users) => users.concat([user]));
    }
    case types.DASHBOARD_USER_UPDATE: {
      const { user } = action;
      const index = state.users.findIndex((el) => el.id === user.id);
      return state.setIn(['users', index], user);
    }
    case types.SET_INVITE_LINK: {
      const { inviteLink } = action;
      return state.merge({ inviteLink });
    }
    case types.DASHBOARD_USERS_TOGGLE_LOADING: {
      const { isLoading } = action;
      return state.merge({ isLoading });
    }
    case types.DASHBOARD_USERS_ERROR: {
      const { error } = action;
      return state.merge({ error });
    }
    case types.DASHBOARD_USERS_RESET:
      return state.merge({ ...initialState, pagination: state.pagination });
    case types.DASHBOARD_USER_DELETE: {
      const { id } = action;
      return state.set('users', state.users.filter((user) => user.id !== id));
    }
    case types.SET_CURRENT_DASHBOARD_USER_ID: {
      const { id } = action;
      return state.set('currentDashboardUserId', id);
    }
    case types.SET_CURRENT_DASHBOARD_USER_IS_ONLY_ITEM: {
      const { isOnlyItem } = action;
      return state.set('currentDashboardUserIsOnlyItem', isOnlyItem);
    }
    default: return state;
  }
}
