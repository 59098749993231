/* eslint no-param-reassign: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-shadow: 0 */
/* eslint class-methods-use-this: 0 */
/* eslint  no-underscore-dangle:0 */
import * as d3 from 'd3';
import _ from 'lodash';
import Chart from '../Chart';
import getTextForLegend from './utils/getTextForLegend';
import { emptyStackedChartData } from '../../../constants/emptyChartData/emptyStackedChartData';
import { COLORS } from 'constants/colors'

const stackedChartMargins = {
  top: 20,
  right: 0,
  bottom: 40,
  left: 0,
};

export default class StackedChart extends Chart {
  constructor(id) {
    super(id);
    this.margin = stackedChartMargins;
  }

  setTranslation(t) {
    this.t = t;
  }

  drawOverallLegend(data = []) {
    let overallHazardsPerSessionSum;
    const hasData = !!data.length;
    if (hasData) {
      overallHazardsPerSessionSum = data
        .reduce((acc, session) => acc
          + _.reduce(
            session,
            (acc, value, key) => {
              if (key === 'time' || key === 'timeInMilliseconds') {
                return acc;
              }
              return acc + session[key];
            },
            0,
          ), 0);
    } else {
      overallHazardsPerSessionSum = -1;
    }

    return (g) => g.append('g')
      .call((g) => g.append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', COLORS.GRAY[400])
        .attr('style', 'font-size: 1.25em')
        .text(() => this.t(getTextForLegend(overallHazardsPerSessionSum)).toUpperCase()));
  }

  drawXAxisClassic(g, x) {
    g.attr('transform', `translate(0,${this.height - this.margin.bottom})`)
      .call(d3.axisBottom(x)
        .tickFormat((n) => {
          const currentData = this.data.find((d) => d.id === n);
          return currentData?.time || n;
        })
        .tickSizeOuter(0))
      .call((g) => g.selectAll('.tick')
        .attr('opacity', 0.5))
      .call((g) => g.select('.domain')
        .attr('opacity', 0.5))
      .call((g) => g.selectAll('.tick text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)'));
  }

  drawXAxisOnChart(g, x) {
    g.attr('transform', `translate(0,${-this.margin.bottom})`)
      .call(d3.axisBottom(x)
        .tickFormat((n) => n || '')
        .tickSizeOuter(0.5))
      .call((g) => g.selectAll('.tick')
        .attr('opacity', 0.8))
      .call((g) => g.select('.domain')
        .attr('transform', `translate(0, ${this.height})`)
        .attr('opacity', 0.5))
      .call((g) => g.selectAll('.tick text')
        .style('text-anchor', 'start')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('font-size', '.75em !important')
        .attr('font-weight', '500')
        .attr('transform', `rotate(-90) translate(${-this.height + 20}, -20)`));
  }

  draw(
    gradients,
    setTooltipText,
    withTextOnChart = false,
  ) {
    const currentTooltip = d3.select(`#${this.id}_tooltip`);
    const tooltipID = this.id;
    if (currentTooltip) currentTooltip.remove();
    const series = d3.stack()
      .keys(this.config)(this.data)
      // eslint-disable-next-line no-sequences
      .map((d) => (d.forEach((v) => v.key = d.key), d));

    const x = d3.scaleBand()
      .domain(this.data.map((d) => (d.id ? d.id : d.time)))
      .range([this.margin.left, this.width - this.margin.right])
      .padding(1);
    const y = d3.scaleLinear()
      .domain([0, this.max || d3.max(series, (d) => d3.max(d, (d) => d[1])) || 10])
      .range([this.height - this.margin.bottom, this.margin.top]);

    const xAxis = (g) => (withTextOnChart
      ? this.drawXAxisOnChart(g, x)
      : this.drawXAxisClassic(g, x));
    const yAxis = (g) => g
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(d3.axisRight(y)
        .tickSize(this.width - this.margin.left - this.margin.right))
      .call((g) => g.select('.domain')
        .remove())
      .call((g) => g.selectAll('.tick:not(:first-of-type) line')
        .attr('stroke-opacity', 0.3)
        .attr('stroke-dasharray', '2,2'))
      .call((g) => g.selectAll('.tick:first-of-type line')
        .attr('stroke-opacity', 0))
      .call((g) => g.selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', -4)
        .attr('fill', 'black')
        .attr('opacity', 0.5));

    const tooltip = d3.select('#page_layer').append('div')
      .attr('id', `${this.id}_tooltip`)
      .attr('class', 'tooltip')
      .style('opacity', 0);

    const mouseover = function (event, d) {
      const onRightEdge = window.innerWidth < event.pageX + window.innerWidth / 6 + 150;
      const onBottomEdge = window.innerHeight < event.pageY + 250;
      const subgroupName = d3.select(this.parentNode).datum().key;
      const tooltipText = setTooltipText(d, d.data.overall);

      let tooltipHtml = '';
      if (typeof tooltipText === 'string') {
        tooltipHtml = tooltipText;
      } else {
        tooltipText.forEach((t) => tooltipHtml += `<div style="text-align: left">${t}</div>`);
      }

      d3.selectAll('.myRect').style('opacity', 0.2);
      d3.selectAll(`.${subgroupName}`)
        .style('opacity', 1);
      tooltip.transition()
        .duration(200)
        .style('opacity', 0.9)
        .style('display', (tooltipID.indexOf('hazards-per-session-bar-chart') && tooltipID.indexOf('hazards-per-hour-stacked')) < 0 ? 'grid' : 'block')
        .style('grid-template-columns', '1fr 1fr')
        .style('grid-gap', '10px');

      tooltip.html(tooltipHtml)
        .style('left', `${event.pageX}px`)
        .style('top', `${event.pageY - 28}px`)
        .style('transform', `translateX(${onRightEdge ? '-100%' : '0'})` + ' ' + `translateY(${onBottomEdge ? '-100%' : '0'})`)
        .style('z-index', 2147483647);
    };

    const mouseleave = function () {
      d3.selectAll('.myRect')
        .style('opacity', 1);
      tooltip.transition()
        .duration(500)
        .style('opacity', 0);
    };

    this.drawSvg([0, 0, this.width, this.height]);

    const svg = d3.select(`svg.${this.id}-chart`);

    this.createGradients(svg, gradients);

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    svg.append('g')
      .selectAll('g')
      .data(series)
      .enter()
      .append('g')
      .style('fill', (d) => `url("#${d.key ? d.key : 'default'}")`)
      .attr('class', (d) => `myRect ${d.key}`)
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => (d.data.id ? x(d.data.id) : x(d.data.time)))
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .attr('width', 16)
      .attr('name', (d) => d.key)
      .on('mouseover', mouseover)
      .on('mouseleave', mouseleave)
      .append('title');

    svg.append('g')
      .attr('class', `legend-${this.id}`)
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      .call(this.drawOverallLegend(this.data));

    this.decimateYAxisTicks();
    this.setTickTextAttr();
  }

  drawEmpty(withTextOnChart) {
    const x = d3.scaleBand()
      .domain(d3.range(emptyStackedChartData.length))
      .range([this.margin.left + 20, this.width - this.margin.right])
      .padding(0.6);
    const y = d3.scaleLinear()
      .domain([0, d3.max(emptyStackedChartData, (d) => d.value)]).nice()
      .range([this.height - this.margin.bottom, this.margin.top]);

    const xAxis = (g) => (withTextOnChart
      ? this.drawXAxisOnChart(g, x)
      : this.drawXAxisClassic(g, x));
    const yAxis = (g) => g
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(d3.axisRight(y)
        .tickSize(this.width - this.margin.left - this.margin.right))
      .call((g) => g.select('.domain')
        .remove())
      .call((g) => g.selectAll('.tick:not(:first-of-type) line')
        .attr('stroke-opacity', 0.5)
        .attr('stroke-dasharray', '2'))
      .call((g) => g.selectAll('.tick:first-of-type line')
        .attr('stroke-opacity', 0.5))
      .call((g) => g.selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', -4)
        .attr('fill', 'black')
        .attr('opacity', 0.5));

    d3.select(`#${this.id}`)
      .append('svg')
      .attr('class', `${this.id}-chart`)
      .attr('viewBox', [0, 0, this.width, this.height]);

    const svg = d3.select(`svg.${this.id}-chart`);

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis)
      .call((g) => g.select('.domain')
        .remove());

    svg.append('g')
      .attr('fill', 'transparent')
      .selectAll('rect')
      .data(emptyStackedChartData)
      .join('rect')
      .attr('x', (d) => x(d.time))
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => y(0) - y(d.value))
      .attr('width', x.bandwidth());

    svg.append('g')
      .attr('class', `legend-${this.id}`)
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      .call(this.drawOverallLegend());

    this.decimateYAxisTicks();
  }
}
