import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Session, Inbox, useSession } from '@talkjs/react';
import { useTranslation } from 'react-i18next';
import Talk from 'talkjs';
import gravatarUrl from 'gravatar-url';
import { navigate, Redirect } from '@reach/router';
import { useAppSelector, useAppDispatch } from '../lib/utils/useStore';
import Loader from '../lib/ui/Loader';
import { container } from '../assets/soter-flex-styles';
import { routes } from '../containers/PrivatPageHOC/constants/routes';
// @ts-ignore
import { getDashboardUserChatProfile, resetUserProfile, getChatSignature, getLatestChatId, resetVideoMetadata, } from '../profile/profileStore/actions';
// @ts-ignore
import { DashboardUserChatProfileFileType } from '../init/proto_client';
import { setNotification } from '../containers/NotificationContainer/notificationsStore/actions';
import { notificationTypes, notificationMessages } from '../containers/NotificationContainer/notifications';
import Text from '../lib/ui/Text';
import Button from '../lib/ui/Button';
import { productStatus } from 'init/proto_client';
import NotFound from "../init/components/NotFound";
import useNumberOfTrialDaysLeftBanner from '../task/overview/useNumberOfTrialDaysLeftBanner';
import StickyOnScrollContainer from 'containers/ItemTableContainer/components/StickyOnScrollContainer';
// Create conversation id from user id and current timestamp
const createConversationId = (id) => `${id}_${new Date().valueOf()}`;
const welcomeMessages = [
    `*How can I help you get OSHA-compliant today?*
*Safety Q&A Chatbot:* Instantly answer any OSHA/safety question. 
<actionbutton:question| Answer my question 💬>
*Health & Safety Policy Audit:* Upload and check any safety documents for OSHA compliance. 
<actionbutton:review| Audit Safety Document 📄>
*Health & Safety Image Analysis:* Upload facility images/videos for a detailed safety review. 
<actionbutton:image| Analyze this image 🏙️>
*Industry Smart Search:* Discover recent violations, errors, and inspections in your industry. 
<actionbutton:search| Search My Industry 🔍>
*Documentation Generator:* Create industry-specific safety documents, like ToolBox Talks and training materials. 
<actionbutton:documentation| Generate Safety Document ⛑️>`,
];
const TALKJS_APP_ID = window.location.host.includes('dashboard') ? 'qpNEGjlm' : 't1p6q14H';
const customActionPrompts = {
    question: 'I have an OSHA/safety related question that I would like you to help me get an answer to. Can you help me with that?',
    review: 'I have a safety document that I can upload and I would like you to review it and make sure it\'s OSHA compliant.',
    image: 'I have an image/video from within my facility, that I would like you to analyze for safety and compliance.',
    search: 'I would like to find common violations, inspections, and standards related to my industry, so that I can learn how to best keep my facility safe. Can you help me with that?',
    documentation: 'I would like to generate some training documents for my workers, can you help me with that?',
};
const SessionProvider = ({ id, conversationId, setConversationId, syncConversation, }) => {
    const session = useSession();
    const dispatch = useAppDispatch();
    const inboxRef = useRef();
    return ((React.createElement(React.Fragment, null,
        React.createElement(Inbox, { inboxRef: inboxRef, onLeaveConversation: () => {
                setConversationId(createConversationId(id));
            }, onSendMessage: () => {
                if (!navigator.onLine) {
                    dispatch(setNotification({
                        type: notificationTypes.TYPE_FAILED,
                        message: notificationMessages.GENERIC_ERROR,
                    }));
                }
            }, onCustomMessageAction: (e) => {
                var _a, _b;
                const { action } = e;
                const conversation = session === null || session === void 0 ? void 0 : session.getOrCreateConversation((_b = (_a = session === null || session === void 0 ? void 0 : session.getInboxes()[0]) === null || _a === void 0 ? void 0 : _a.currentConversation) === null || _b === void 0 ? void 0 : _b.id);
                conversation === null || conversation === void 0 ? void 0 : conversation.sendMessage(customActionPrompts[action] || '');
            }, onCustomConversationAction: (event) => {
                if (event.action === 'addNewChat') {
                    if (!session)
                        return;
                    dispatch(resetVideoMetadata());
                    setConversationId(createConversationId(id));
                }
            }, theme: "default", style: { width: 'inherit', height: '85vh' }, syncConversation: syncConversation, messageField: {
                placeholder: 'Message SoterGenius ...',
            } }))));
};
export default function ChatContainer() {
    var _a, _b;
    const { t } = useTranslation();
    const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('geniusProduct');
    const { profile } = useAppSelector((store) => store.auth);
    const { userProfile, error, signature, latestChatId, videoMetadata, } = useAppSelector((store) => store.userProfile);
    if (!profile || !userProfile)
        return React.createElement(Loader, null);
    const dispatch = useAppDispatch();
    const [warningShown, setWarningShown] = useState(false);
    const { id, firstName, lastName } = profile;
    useEffect(() => {
        dispatch(getDashboardUserChatProfile());
        dispatch(getChatSignature());
        const destructor = () => {
            dispatch(resetUserProfile());
        };
        return destructor;
    }, []);
    const { id: userProfileId, additionalInformation, companyIndustry, companyName, location, naicsCode, filesList, } = userProfile;
    const userFiles = {};
    Object.keys(DashboardUserChatProfileFileType).forEach((typeName) => {
        const files = filesList ? [...filesList.filter((file) => file.fileType === DashboardUserChatProfileFileType[typeName])].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) : [];
        const file = (files === null || files === void 0 ? void 0 : files.length) ? files[0] : {};
        userFiles[typeName] = file.fileUrl || '';
    });
    const { selectedCompany } = useAppSelector((store) => store.companies);
    // Get/create/update a user
    const syncUser = useCallback(() => new Talk.User({
        id,
        name: `${firstName} ${lastName}`,
        role: 'default',
    }), []);
    const [conversationId, setConversationId] = useState('');
    // Get all conversation and open the last one
    useEffect(() => {
        dispatch(getLatestChatId());
    }, []);
    useEffect(() => {
        if (latestChatId && !videoMetadata) {
            setConversationId(latestChatId);
        }
        else if (conversationId && videoMetadata) {
            return;
        }
        else {
            setConversationId(createConversationId(id));
        }
    }, [latestChatId]);
    // Set up a conversation
    const syncConversation = useCallback((session) => {
        if (!conversationId || !userProfileId)
            return;
        const aiUser = new Talk.User({
            id: 'ai_bot',
            name: 'SoterGenius',
            role: 'default',
        });
        let custom = Object.assign(Object.assign({ additionalInformation, companyIndustry, companyName, location, naicsCode }, userFiles), { isTyping: 'true' });
        const conversation = session.getOrCreateConversation(conversationId);
        conversation.setParticipant(session.me);
        conversation.setParticipant(aiUser);
        // Here is the logic flow of ST video analysis in the chat is handled
        if (videoMetadata) {
            conversation.sendMessage(`Please take the following video and generate a report for me:
      
⭐ *Video name*: ${videoMetadata.videoTitle}
      
🎥 *Link*: ${window.location.origin}/${selectedCompany.id}/users/soter-genius/gallery/${videoMetadata.videoId}
      
✅ *Report format*: ${videoMetadata.reportFormat.replaceAll('_', ' ').replaceAll('.', ' ')}
      `);
            custom = Object.assign(Object.assign({}, custom), videoMetadata);
        }
        // Here we set user profile default data for AI
        conversation.setAttributes({
            custom,
            photoUrl: gravatarUrl(conversationId, { default: 'monsterid' }),
            welcomeMessages,
        });
        return conversation;
    }, [conversationId, userProfileId]);
    if ((error === null || error === void 0 ? void 0 : error.code) === 5) {
        if (!warningShown) {
            dispatch(setNotification({
                type: notificationTypes.TYPE_WARNING,
                message: t('NOTIFICATIONS.FILL_INFO'),
            }));
            setWarningShown(true);
        }
        // @ts-ignore
        return React.createElement(Redirect, { noThrow: true, to: `/${selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id}/users/${routes.genius}/${routes.profile}` });
    }
    if (!signature)
        return React.createElement(Loader, null);
    if (((_b = (_a = selectedCompany.products) === null || _a === void 0 ? void 0 : _a.geniusProduct) === null || _b === void 0 ? void 0 : _b.status) !== productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE)
        return React.createElement(NotFound, null);
    return (React.createElement(React.Fragment, null,
        numberOfTrialDaysLeftBanner && (React.createElement(StickyOnScrollContainer, { topMargin: 0, topBlock: numberOfTrialDaysLeftBanner, height: 50, innerHeight: 0 })),
        React.createElement("div", { className: container, style: { position: 'relative' } },
            React.createElement("div", { style: {
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 20px 10px',
                } },
                React.createElement(Text, { weight: "bold", appearance: "headlineOne", text: t('SOTER_GENIUS.CHAT') }),
                React.createElement(Button, { appearance: "text", text: t('SOTER_GENIUS.EDIT_PROFILE'), handler: () => navigate(`/${selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id}/users/${routes.genius}/${routes.profile}`) })),
            React.createElement("div", { style: { marginLeft: '1rem' } },
                React.createElement(Session, { appId: TALKJS_APP_ID, syncUser: syncUser, signature: signature },
                    React.createElement(SessionProvider, { syncConversation: syncConversation, conversationId: conversationId, id: id, setConversationId: setConversationId }))))));
}
