import { RATIO_CHART_CEIL } from 'containers/PdfContainer/constants';

export default function getUserChartMaximums(hazardsByDayList, hazardsList) {
  let hazardsRatioMax = 0;
  hazardsByDayList.forEach((hazard) => (hazard.hazardousMovementRatioValue > hazardsRatioMax) &&
    (hazardsRatioMax = hazard.hazardousMovementRatioValue));
  let hazardsSplitMax = 0;
  hazardsByDayList.forEach((hazard) => {
    hazardsList.forEach((h) => (hazard[h.key] > hazardsSplitMax) &&
      (hazardsSplitMax = hazard[h.key]));
  });
  let ratioChartMax = 0;
  if (hazardsRatioMax <= RATIO_CHART_CEIL[0]) {
    [ratioChartMax] = RATIO_CHART_CEIL;
  } else if (hazardsRatioMax <= RATIO_CHART_CEIL[1]) {
    [, ratioChartMax] = RATIO_CHART_CEIL;
  } else {
    [, , ratioChartMax] = RATIO_CHART_CEIL;
  }
  // Trick for pretty values in chart domain
  const splitChartMax = Math.round(hazardsSplitMax / 5) * 5;
  return [ratioChartMax, splitChartMax];
}
