import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import {
  flexColumn,
  flexRow,
  infoMainText,
  mb2,
  mb5,
  mt5,
  printableQR,
  printableQRArea,
  printableQRText,
  subText,
} from 'assets/soter-flex-styles';
import Button from 'lib/ui/Button';

const QRCode = require('qrcode.react');

const QRPrintDialog = ({ user, closeModals = () => {} }) => {
  const qrRef = useRef(null);
  const { t } = useTranslation();
  const printQR = useReactToPrint({
    content: () => qrRef.current,
  });
  const printHandler = () => {
    printQR();
    setTimeout(() => {
      closeModals();
    }, 777);
  };
  return (
    <div className={`${flexColumn} ${mb2}`}>
      <div ref={qrRef} className={printableQRArea}>
        <div
          className={`${printableQR} ${flexRow} ${mt5} ${mb5}`}
          style={{ justifyContent: 'center' }}
        >
          <QRCode value={`${user?.nickname}`} size={192} />
        </div>
        <div className={printableQRText}>
          <p
            className={`${flexRow} ${infoMainText}`}
            style={{ justifyContent: 'center' }}
          >
            {`${user?.firstName} ${user?.lastName}`.substring(0, 18)}
          </p>
          <p
            className={`${flexRow} ${subText} ${mb5}`}
            style={{
              justifyContent: 'center',
              whitespace: 'nowrap',
            }}
          >
            {`(${user?.nickname.substring(0, 40)})`}
          </p>
        </div>
      </div>
      <Button
        text={t('WEARABLES.ACTIVITY.DIALOGS.PRINT_QR')}
        type="submit"
        style={{ display: 'block', width: '100%' }}
        handler={printHandler}
      />
    </div>
  );
};

export default QRPrintDialog;
