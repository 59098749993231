import React from 'react';
import moment from 'moment';
import * as Emotion from 'emotion';
import { Link } from '@reach/router';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { column, smallMutedText, subText } from '../../assets/soter-flex-styles';
import sliceLongString from '../../lib/utils/sliceLongString';

const modalWrapper = Emotion.css`
  max-height: 606px;
  overflow: scroll;
  margin-top: 10px;
`;
const deviceHistoryRaw = Emotion.css`
  display: flex;
  justify-content: space-between;
  margin: 11px 13px 11px 0;
`;

const link = Emotion.css`
  color: ${COLORS.GRAY[950]};
  text-decoration: none;
`;

const DeviceHistoryDialog = ({ deviceHistory, companyID }) => {
  const { t } = useTranslation();

  return (
    <div className={`${column}`}>
      <span className={subText}>
        {t('WEARABLES.DEVICES.USAGE_HISTORY').toUpperCase()}
      </span>
      <div
        className={deviceHistoryRaw}
        style={{ fontWeight: 600, marginTop: '15px', marginRight: '2px' }}
      >
        <div>{t('GENERAL.USER').toUpperCase()}</div>
        <div style={{ marginRight: '24px' }}>
          {t('WEARABLES.DEVICES.USED_SINCE').toUpperCase()}
        </div>
      </div>
      <div className={modalWrapper}>
        {deviceHistory.map((item) => (
          <div key={item.createdAt} className={deviceHistoryRaw}>
            <div>
              <Link
                className={link}
                to={`/${companyID}/users/wearables/user/${item.userId}`}
              >
                {sliceLongString(`${item.firstName} ${item.lastName}`, 40)}
              </Link>
              <div className={smallMutedText}>
                {sliceLongString(item.jobTitle, 40)}
              </div>
            </div>
            <div style={{ minWidth: '96px', textAlign: 'left' }}>
              <div>{moment(item.createdAt, 'x').format('DD MMM, HH:mm')}</div>
              <div className={smallMutedText}>
                {moment(item.createdAt, 'x').fromNow().replace(/( ago)+/g, ' ago')}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeviceHistoryDialog;
