import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'constants/colors';
import hexToRgba from 'lib/utils/hexToRgba';
import sliceLongString from 'lib/utils/sliceLongString';
import { useTranslation } from 'react-i18next';
import { bubbleForButton } from '../../../assets/soter-flex-styles';
import { ReactComponent as Search } from '../../../assets/img/icons/search.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/closeCircle.svg';
import CustomTooltip from '../../../lib/ui/Tooltip';
import CustomInput from '../../../lib/ui/CustomInput';
import CustomCheckbox from '../../../lib/ui/CustomCheckbox/CustomCheckbox';
import {
  addFilterValue, clearFilter, clearFilters, removeFilterValue,
} from '../filtersStore/filtersSlice';
import { clearPagination } from '../tableStateStore/actions';
import Button from '../../../lib/ui/Button';
// import { addURLQueryParams, removeParam } from '../utils/addURLQueryParams';

const container = Emotion.css`
    position: absolute;
    top: 52px;
    background-color: ${COLORS.WHITE};
    width: 1152px;
    z-index: 1130;
    border: 1px solid ${COLORS.GRAY[100]};
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    max-height: 450px; 
`;

const filtersArea = Emotion.css`
  display: flex;
`;

const checkboxesArea = Emotion.css`
  display: flex;
  width: inherit;
  overflow: scroll;
  height: auto;
`;
const scrollableContent = Emotion.css`
  overflow: auto;
  flex-grow: 1;
`;
const searchCheckboxesArea = Emotion.css`
  padding: 10px 10px 10px 15px;
  width: inherit;
  overflow-y: auto;
`;

const checkboxesColumn = Emotion.css`
  width: 195px;
`;

const filtersList = Emotion.css`
  padding: 10px 0 10px 10px;
`;

const footer = Emotion.css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${COLORS.GRAY[100]};
    padding: 15px;
`;

const filterParametersMenuItem = (selected) => Emotion.css`
  width: 240px;
  height: 35px;
  border-radius: 2px;
  background-color: ${selected && hexToRgba(COLORS.GRAY[500], 0.2)};
  padding-left: 10px;
  padding-top: 6px;
  position: relative;
  &:hover {
    background-color: ${hexToRgba(COLORS.GRAY[500], 0.1)};
    cursor: pointer;
  }
`;

const bubble = Emotion.css`
  ${bubbleForButton};

  top: 8px !important;
  right: 0;
`;

const searchInput = Emotion.css`
  width: 273px;
  margin: 3px 0 4px 10px;
`;

const close = Emotion.css`
  stroke: ${COLORS.GRAY[950]};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 14px;
  svg {
    margin-right: 3px;
  }
  &:hover {
    stroke: ${COLORS.RED[600]};
  }
`;

const OverlaidFiltersContainer = ({
  filteringParameters = [],
  innerRef,
  closeHandler,
  pageType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filtersNew = useSelector((state) => state.filtersNew.filters[pageType]);
  const filters = { ...filtersNew };
  delete filters.showPassedPrograms;
  const [selectedFiltersName, setSelectedFiltersName] = useState(
    filteringParameters[0].name,
  );
  const [preselectedFiltersName, setPreselectedFiltersName] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const selectedFilterValues = filteringParameters.find(
    ({ name }) => name === (preselectedFiltersName || selectedFiltersName),
  )?.values;
  const [filteredSelectedFilterValues, setFilteredSelectedFilterValues]
    = useState(selectedFilterValues);
  const filterParameterNames = {};
  filteringParameters.forEach(
    ({ name, label }) => (filterParameterNames[name] = label),
  );
  let stack = [];
  const preselectedNotSelected
    = preselectedFiltersName && preselectedFiltersName !== selectedFiltersName;
  const checkboxAreaHeight
    = selectedFilterValues?.length > 10 && !preselectedNotSelected ? 12 : 13;
  const mappedFilterValues = preselectedNotSelected
    ? selectedFilterValues
    : filteredSelectedFilterValues;
  useEffect(() => {
    setFilteredSelectedFilterValues(
      selectedFilterValues.filter(({ label }) => label.toLowerCase().includes(searchValue.toLowerCase())),
    );
  }, [searchValue, selectedFilterValues]);

  const sliceAndAddTooltip = (label, length) => (label.length > length ? (
    <CustomTooltip noTranslation text={label}>
      <div>{sliceLongString(label, length)}</div>
    </CustomTooltip>
  ) : (
    label
  ));

  return (
    <div className={container} ref={innerRef}>
      <div className={scrollableContent}>
      <div className={filtersArea}>
        <div className={filtersList}>
          {filteringParameters.map(({ name, label }) => (
            <div
              key={name}
              className={filterParametersMenuItem(name === selectedFiltersName)}
              onClick={() => {
                setSelectedFiltersName(name);
                setSearchValue('');
              }}
            >
              {sliceAndAddTooltip(label, 25)}
              {filters[name]?.length > 0 && (
                <div className={bubble}>{filters[name].length}</div>
              )}
            </div>
          ))}
        </div>
        <div id="checkbox_area" className={searchCheckboxesArea}>
          {selectedFilterValues?.length > 10 && !preselectedNotSelected ? (
            <div style={{ width: '273px' }}>
              <CustomInput
                className={searchInput}
                value={searchValue}
                placeholder={`${t('SEARCH_TOOLTIPS.SEARCH_BY')} ${
                  filterParameterNames[selectedFiltersName]
                }`}
                onChange={(_, value) => setSearchValue(value)}
                addon={<Search />}
                addonStyle={{ height: '38px' }}
              />
            </div>
          ) : null}
          <div className={checkboxesArea}>
            {mappedFilterValues.map(({ value, label }, index, array) => {
              stack.push(
                <div className={checkboxesColumn} key={`${label}_${value}`}>
                  <CustomCheckbox
                    label={sliceAndAddTooltip(label, 18)}
                    checked={filters[
                      preselectedFiltersName || selectedFiltersName
                    ]?.includes(value)}
                    onChange={(_, checked) => {
                      const changeFilter = checked
                        ? addFilterValue
                        : removeFilterValue;
                      // const changeURLParam = checked ? addURLQueryParams : removeParam;
                      dispatch(
                        changeFilter({
                          type: selectedFiltersName,
                          value,
                          pageType,
                        }),
                      );
                      // changeURLParam(`${selectedFiltersName}${capitalizeFirstLetter(filter)}`, value, true);
                      dispatch(clearPagination());
                    }}
                  />
                </div>,
              );
              if (
                (index + 1) % checkboxAreaHeight === 0
                || index === array.length - 1
              ) {
                const column = (
                  <div className={checkboxesColumn} key={value}>
                    {stack.map((el) => el)}
                  </div>
                );
                stack = [];
                return column;
              }
            })}
          </div>
        </div>
      </div>
      </div>
      <div className={footer}>
        <div style={{ display: 'flex' }}>
          {Object.keys(filters).map((parameter) => {
            if (filters[parameter].length) {
              return (
                <div
                  key={parameter}
                  className={close}
                  onClick={() => dispatch(clearFilter({ type: parameter, pageType }))}
                >
                  <Close />
                  {filters[parameter].length === 1
                    ? filteringParameters
                      .find(({ name }) => name === parameter)
                      .values.find(
                        ({ value }) => value === filters[parameter][0],
                      ).label
                    : `${filterParameterNames[parameter]} (${filters[parameter].length})`}
                </div>
              );
            }
          })}
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginRight: '10px' }}
            handler={() => dispatch(clearFilters({ pageType }))}
            text={t('GENERAL.CLEAR_ALL')}
          />
          <Button handler={closeHandler} text={t('GENERAL.CLOSE')} />
        </div>
      </div>
    </div>
  );
};

export default OverlaidFiltersContainer;
