import { getAvailableCoachingPrograms, getAvailableFirmwares, getAvailableLanguages, getAvailableTiers, } from '../store/actions';
// @ts-ignore
import { ClipgoInputLoginMethod } from '../../init/proto_client';
import { getSimpleSelectOptions } from '../../lib/utils/getSimpleSelectOptions';
function scrollTop(ref, top) {
    var _a;
    (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top, behavior: 'smooth' });
}
export const isCalendarError = (wearableInfo, soterTaskInfo, jobDictionaryInfo, copilotInfo, errors, setErrors, formRef) => {
    if (wearableInfo.isAvailable) {
        if (!wearableInfo.timeStart) {
            setErrors(Object.assign(Object.assign({}, errors), { wearableStart: 'required' }));
            scrollTop(formRef, 0);
            return true;
        }
        if (!wearableInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { wearableEnd: 'required' }));
            scrollTop(formRef, 0);
            return true;
        }
        if (wearableInfo.timeStart >= wearableInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { wearableStart: 'incorrect', wearableEnd: 'incorrect' }));
            scrollTop(formRef, 0);
            return true;
        }
    }
    if (soterTaskInfo.isAvailable) {
        if (!soterTaskInfo.timeStart) {
            setErrors(Object.assign(Object.assign({}, errors), { soterTaskStart: 'required' }));
            scrollTop(formRef, 60);
            return true;
        }
        if (!soterTaskInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { soterTaskEnd: 'required' }));
            scrollTop(formRef, 60);
            return true;
        }
        if (soterTaskInfo.timeStart >= soterTaskInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { soterTaskStart: 'incorrect', soterTaskEnd: 'incorrect' }));
            scrollTop(formRef, 60);
            return true;
        }
    }
    if (jobDictionaryInfo.isAvailable) {
        if (!jobDictionaryInfo.timeStart) {
            setErrors(Object.assign(Object.assign({}, errors), { jobDictionaryStart: 'required' }));
            scrollTop(formRef, 260);
            return true;
        }
        if (!jobDictionaryInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { jobDictionaryEnd: 'required' }));
            scrollTop(formRef, 260);
            return true;
        }
        if (jobDictionaryInfo.timeStart >= jobDictionaryInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { jobDictionaryStart: 'incorrect', jobDictionaryEnd: 'incorrect' }));
            return true;
        }
    }
    if (copilotInfo.isAvailable) {
        if (!copilotInfo.timeStart) {
            setErrors(Object.assign(Object.assign({}, errors), { copilotStart: 'required' }));
            scrollTop(formRef, 260);
            return true;
        }
        if (!copilotInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { copilotEnd: 'required' }));
            scrollTop(formRef, 260);
            return true;
        }
        if (copilotInfo.timeStart >= copilotInfo.timeEnd) {
            setErrors(Object.assign(Object.assign({}, errors), { copilotStart: 'incorrect', copilotEnd: 'incorrect' }));
            return true;
        }
    }
    return false;
};
export const loginMethods = Object.keys(ClipgoInputLoginMethod).map((key) => {
    switch (key) {
        case 'BOTH_CAMERA':
            return { value: ClipgoInputLoginMethod[key], label: 'QR (tablet keyboard optional)' };
        case 'BOTH_KEYBOARD':
            return { value: ClipgoInputLoginMethod[key], label: 'Tablet keyboard (QR optional)' };
        case 'ONLY_CAMERA':
            return { value: ClipgoInputLoginMethod[key], label: 'Only QR' };
        case 'ONLY_KEYBOARD':
            return { value: ClipgoInputLoginMethod[key], label: 'Only tablet keyboard' };
        case 'SCAN_GUN':
            return { value: ClipgoInputLoginMethod[key], label: 'Scan gun (tablet keyboard optional)' };
        default:
            return { value: ClipgoInputLoginMethod[key], label: 'None' };
    }
}).filter((item) => [1, 3].includes(item.value));
export const loginMethod = (id) => {
    const method = loginMethods.filter((item) => item.value === id);
    return method[0];
};
export const getInfo = (dispatch) => {
    dispatch(getAvailableFirmwares());
    dispatch(getAvailableTiers());
    dispatch(getAvailableCoachingPrograms());
    dispatch(getAvailableLanguages());
};
const findProgram = (name, coachingPrograms) => getSimpleSelectOptions(coachingPrograms)
    .find((program) => program.label === name);
const removeProgram = (selectedPrograms, name) => selectedPrograms.filter((program) => program.label !== name);
export const selectedDefaultBodyPart = (name, isChecked, setShowProgramsError, coachingPrograms, selectedPrograms, setSelectedPrograms) => {
    if (isChecked) {
        setShowProgramsError(false);
        switch (name) {
            case 'spine':
                setSelectedPrograms([...selectedPrograms, findProgram('Back Manual Handling Training', coachingPrograms)]);
                break;
            case 'headset':
                setSelectedPrograms([...selectedPrograms, findProgram('Back Manual Handling Program Headset', coachingPrograms)]);
                break;
            case 'shoulder':
                setSelectedPrograms([...selectedPrograms, findProgram('Shoulder Manual Handling Training', coachingPrograms)]);
                break;
            case 'helmet':
                setSelectedPrograms([...selectedPrograms, findProgram('Back Manual Handling Program Helmet', coachingPrograms)]);
                break;
            default:
                return null;
        }
    }
    else {
        switch (name) {
            case 'spine':
                setSelectedPrograms(removeProgram(selectedPrograms, 'Back Manual Handling Training'));
                break;
            case 'headset':
                setSelectedPrograms(removeProgram(selectedPrograms, 'Back Manual Handling Program Headset'));
                break;
            case 'shoulder':
                setSelectedPrograms(removeProgram(selectedPrograms, 'Shoulder Manual Handling Training'));
                break;
            case 'helmet':
                setSelectedPrograms(removeProgram(selectedPrograms, 'Back Manual Handling Program Helmet'));
                break;
            default:
                return null;
        }
    }
};
export const accordionHandler = (isOpenAccordion, setIsOpenAccordion) => setIsOpenAccordion(!isOpenAccordion);
export const calendarLocale = (t) => ({
    sunday: t('TIME.CALENDAR.SUNDAY'),
    monday: t('TIME.CALENDAR.MONDAY'),
    tuesday: t('TIME.CALENDAR.TUESDAY'),
    wednesday: t('TIME.CALENDAR.WEDNESDAY'),
    thursday: t('TIME.CALENDAR.THURSDAY'),
    friday: t('TIME.CALENDAR.FRIDAY'),
    saturday: t('TIME.CALENDAR.SATURDAY'),
    ok: t('TIME.CALENDAR.OK'),
    today: t('TIME.CALENDAR.TODAY'),
    yesterday: t('TIME.CALENDAR.YESTERDAY'),
    hours: t('TIME.CALENDAR.HOURS'),
    minutes: t('TIME.CALENDAR.MINUTES'),
    seconds: t('TIME.CALENDAR.SECONDS'),
    formattedMonthPattern: 'MMM yyyy',
    formattedDayPattern: 'MMM dd,  yyyy',
    last7Days: t('TIME.CALENDAR.LAST_7_DAYS'),
});
