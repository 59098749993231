import upperOne from '../../assets/img/rulaPictures/01.1.svg';
import upperTwo from '../../assets/img/rulaPictures/01.2.svg';
import upperThree from '../../assets/img/rulaPictures/01.3.svg';
import upperFour from '../../assets/img/rulaPictures/01.4.svg';
import upperFive from '../../assets/img/rulaPictures/01.5.svg';
import lowerOne from '../../assets/img/rulaPictures/02.1.svg';
import lowerTwo from '../../assets/img/rulaPictures/02.2.svg';
import lowerThree from '../../assets/img/rulaPictures/02.3.svg';
import wristOne from '../../assets/img/rulaPictures/03.1.svg';
import wristTwo from '../../assets/img/rulaPictures/03.2.svg';
import wristThree from '../../assets/img/rulaPictures/03.3.svg';
import wristFour from '../../assets/img/rulaPictures/03.4.svg';
import neckOne from '../../assets/img/rulaPictures/04.1.svg';
import neckTwo from '../../assets/img/rulaPictures/04.2.svg';
import neckThree from '../../assets/img/rulaPictures/04.3.svg';
import neckFour from '../../assets/img/rulaPictures/04.4.svg';
import trunkOne from '../../assets/img/rulaPictures/05.1.svg';
import trunkTwo from '../../assets/img/rulaPictures/05.2.svg';
import trunkThree from '../../assets/img/rulaPictures/05.3.svg';
import trunkFour from '../../assets/img/rulaPictures/05.4.svg';

export const rulaStructure = [
  {
    metaTitle: 'SOTER_TASK.ASSESSMENT.RULA.ARM_WRIST',
    steps: [
      {
        id: 'upper_arm',
        title: 'SOTER_TASK.ASSESSMENT.RULA.UPPER_SCORE',
        position: 1,
        values: [
          {
            type: 'radio',
            id: 'upper_first',
            score: 1,
            image: upperOne,
          },
          {
            type: 'radio',
            id: 'upper_second',
            score: 2,
            image: upperTwo,
          },
          {
            type: 'radio',
            id: 'upper_third',
            score: 2,
            image: upperThree,
          },
          {
            type: 'radio',
            id: 'upper_fourth',
            score: 3,
            image: upperFour,
          },
          {
            type: 'radio',
            id: 'upper_fifth',
            score: 4,
            image: upperFive,
          },
          {
            type: 'checkbox',
            id: 'upper_shoulder_raised',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.RAISED',
          },
          {
            type: 'checkbox',
            id: 'upper_arm_abducted',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.ABDUCTED',
          },
          {
            type: 'checkbox',
            id: 'upper_supported',
            score: -1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.SUPPORTED',
          },
          // {
          //   type: 'checkbox',
          //   id: 'upper_none',
          //   score: 0,
          //   text: 'None of the above',
          // },
        ],
      },
      {
        id: 'lower_arm',
        title: 'SOTER_TASK.ASSESSMENT.RULA.LOWER_SCORE',
        position: 2,
        values: [
          {
            type: 'radio',
            id: 'lower_first',
            score: 1,
            image: lowerOne,
          },
          {
            type: 'radio',
            id: 'lower_second',
            score: 2,
            image: lowerTwo,
          },
          {
            type: 'checkbox',
            id: 'lower_third',
            score: 1,
            image: lowerThree,
          },
        ],
      },
      {
        id: 'wrist',
        title: 'SOTER_TASK.ASSESSMENT.RULA.WRIST_SCORE',
        position: 3,
        values: [
          {
            type: 'radio',
            id: 'wrist_first',
            score: 1,
            image: wristOne,
          },
          {
            type: 'radio',
            id: 'wrist_second',
            score: 2,
            image: wristTwo,
          },
          {
            type: 'radio',
            id: 'wrist_third',
            score: 3,
            image: wristThree,
          },
          {
            type: 'checkbox',
            score: 1,
            id: 'wrist_fourth',
            image: wristFour,
          },
        ],
      },
      {
        id: 'wrist_twist',
        title: 'SOTER_TASK.ASSESSMENT.RULA.TWIST_SCORE',
        position: 4,
        values: [
          {
            type: 'radio',
            id: 'wrist_twisted',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.TWIST_MID',
          },
          {
            type: 'radio',
            id: 'wrist_end_range',
            score: 2,
            text: 'SOTER_TASK.ASSESSMENT.RULA.TWIST_END',
          },
        ],
      },
      {
        id: 'posture_score_a',
        title: 'SOTER_TASK.ASSESSMENT.RULA.A_SCORE',
        symbol: true,
        values: [
          {
            id: 'first_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.RULA.A_LABEL',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.RULA.A_HEADER',
              subHeader: 'SOTER_TASK.ASSESSMENT.RULA.A_SUB',
            },
          },
        ],
      },
      {
        id: 'arm_muscle_score',
        title: 'SOTER_TASK.ASSESSMENT.RULA.MUSCLE_SCORE',
        position: 6,
        values: [
          {
            type: 'radio',
            id: 'muscle_one_repeated',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.MAINLY',
          },
          {
            type: 'radio',
            id: 'muscle_one_none',
            score: 0,
            text: 'SOTER_TASK.ASSESSMENT.RULA.NONE',
          },
        ],
      },
      {
        id: 'arm_load_score',
        title: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_SCORE',
        position: 7,
        weight: true,
        values: [
          {
            type: 'radio',
            id: 'first_load_min',
            score: 0,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MIN_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MIN',
            },
          },
          {
            type: 'radio',
            id: 'first_load_mid',
            score: 1,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MID_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MID',
            },
          },
          {
            type: 'radio',
            id: 'first_load_max',
            score: 2,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MAX_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MAX',
            },
          },
          {
            type: 'radio',
            id: 'first_load_more',
            score: 3,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MORE_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.FORCE_MORE',
            },
          },
        ],
      },
      {
        id: 'row_c',
        title: 'SOTER_TASK.ASSESSMENT.RULA.ROW_C',
        symbol: true,
        values: [
          {
            id: 'second_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.RULA.LABEL_C',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.RULA.HEADER_C',
              subHeader: 'SOTER_TASK.ASSESSMENT.RULA.SUB_C',
            },
          },
        ],
      },
    ],
  },
  {
    metaTitle: 'SOTER_TASK.ASSESSMENT.RULA.NECK_TRUNK',
    steps: [
      {
        id: 'neck',
        title: 'SOTER_TASK.ASSESSMENT.RULA.NECK_SCORE',
        position: 9,
        values: [
          {
            type: 'radio',
            id: 'neck_first',
            score: 1,
            image: neckOne,
          },
          {
            type: 'radio',
            id: 'neck_second',
            score: 2,
            image: neckTwo,
          },
          {
            type: 'radio',
            id: 'neck_third',
            score: 3,
            image: neckThree,
          },
          {
            type: 'radio',
            id: 'neck_fourth',
            score: 4,
            image: neckFour,
          },
          {
            type: 'checkbox',
            id: 'neck_twisted',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.NECK_TWISTED',
          },
          {
            type: 'checkbox',
            id: 'neck_side',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.NECK_BEND',
          },
        ],
      },
      {
        id: 'trunk',
        title: 'SOTER_TASK.ASSESSMENT.RULA.TRUNK_SCORE',
        position: 10,
        values: [
          {
            type: 'radio',
            id: 'trunk_first',
            score: 1,
            image: trunkOne,
          },
          {
            type: 'radio',
            id: 'trunk_second',
            score: 2,
            image: trunkTwo,
          },
          {
            type: 'radio',
            id: 'trunk_third',
            score: 3,
            image: trunkThree,
          },
          {
            type: 'radio',
            id: 'trunk_fourth',
            score: 4,
            image: trunkFour,
          },
          {
            type: 'checkbox',
            id: 'trunk_twisted',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.TRUNK_TWISTED',
            label: 'SOTER_TASK.ASSESSMENT.RULA.TRUNK_TWISTED_ON',
          },
          {
            type: 'checkbox',
            id: 'trunk_side',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.TRUNK_BEND',
          },
        ],
      },
      {
        id: 'legs',
        title: 'SOTER_TASK.ASSESSMENT.RULA.LEG_SCORE',
        position: 11,
        values: [
          {
            type: 'radio',
            id: 'legs_supported',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.LEG_SUPPORTED',
          },
          {
            type: 'radio',
            id: 'legs_not_supported',
            score: 2,
            text: 'SOTER_TASK.ASSESSMENT.RULA.LEG_NOT',
          },
        ],
      },
      {
        id: 'posture_score_b',
        title: 'SOTER_TASK.ASSESSMENT.RULA.SCORE_B',
        symbol: true,
        values: [
          {
            id: 'third_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.RULA.LABEL_B',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.RULA.HEADER_B',
              subHeader: 'SOTER_TASK.ASSESSMENT.RULA.SUB_B',
            },
          },
        ],
      },
      {
        id: 'neck_muscle_use',
        title: 'SOTER_TASK.ASSESSMENT.RULA.MUSCLE_SCORE_USE',
        position: 13,
        values: [
          {
            type: 'radio',
            id: 'muscle_two_repeated',
            score: 1,
            text: 'SOTER_TASK.ASSESSMENT.RULA.MAINLY',
          },
          {
            type: 'radio',
            id: 'muscle_two_none',
            score: 0,
            text: 'SOTER_TASK.ASSESSMENT.RULA.NONE',
          },
        ],
      },
      {
        id: 'neck_load',
        title: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_SCORE',
        position: 14,
        weight: true,
        values: [
          {
            type: 'radio',
            id: 'second_load_min',
            score: 0,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MIN_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MIN_KG',
            },
          },
          {
            type: 'radio',
            id: 'second_load_mid',
            score: 1,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MID_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MID_KG',
            },
          },
          {
            type: 'radio',
            id: 'second_load_max',
            score: 2,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MAX_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MAX_KG',
            },
          },
          {
            type: 'radio',
            id: 'second_load_more',
            score: 3,
            text: {
              lb: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MORE_LB',
              kg: 'SOTER_TASK.ASSESSMENT.RULA.LOAD_MORE_KG',
            },
          },
        ],
      },
      {
        id: 'column_c',
        title: 'SOTER_TASK.ASSESSMENT.RULA.C_SCORE',
        symbol: true,
        values: [
          {
            id: 'fourth_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.RULA.C_LABEL',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.RULA.C_HEADER',
              subHeader: 'SOTER_TASK.ASSESSMENT.RULA.C_SUB',
            },
          },
        ],
      },
    ],
  },
];

export const rulaTableAMatrix = [
  [1, 2, 2, 2, 2, 3, 3, 3],
  [2, 2, 2, 2, 3, 3, 3, 3],
  [3, 3, 3, 3, 3, 3, 4, 4],
  [2, 3, 3, 3, 3, 4, 4, 4],
  [3, 3, 3, 3, 3, 4, 4, 4],
  [3, 4, 4, 4, 4, 4, 5, 5],
  [3, 3, 4, 4, 4, 4, 5, 5],
  [3, 4, 4, 4, 4, 4, 5, 5],
  [4, 4, 4, 4, 4, 5, 5, 5],
  [4, 4, 4, 4, 4, 5, 5, 5],
  [4, 4, 4, 4, 4, 5, 5, 5],
  [4, 4, 4, 5, 5, 5, 6, 6],
  [5, 5, 5, 5, 5, 6, 6, 7],
  [5, 6, 6, 6, 6, 7, 7, 7],
  [6, 6, 6, 7, 7, 7, 7, 8],
  [7, 7, 7, 7, 7, 8, 8, 9],
  [8, 8, 8, 8, 8, 9, 9, 9],
  [9, 9, 9, 9, 9, 9, 9, 9],
];

export const rulaTableBMatrix = [
  [1, 3, 2, 3, 3, 3, 5, 5, 6, 6, 7, 7],
  [2, 3, 2, 3, 4, 5, 5, 5, 6, 7, 7, 7],
  [3, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 7],
  [5, 5, 5, 6, 6, 7, 7, 7, 7, 7, 8, 8],
  [7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 8, 8],
  [8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9],
];

export const rulaTableCMatrix = [
  [1, 2, 3, 3, 4, 5, 5],
  [2, 2, 3, 4, 4, 5, 5],
  [3, 3, 3, 4, 4, 5, 6],
  [3, 3, 3, 4, 5, 6, 5],
  [4, 4, 4, 5, 6, 7, 7],
  [4, 4, 5, 6, 6, 7, 7],
  [5, 5, 6, 6, 7, 7, 7],
  [5, 5, 6, 7, 7, 7, 7],
];
