import React from 'react';
import { useDispatch } from 'react-redux';
import * as Emotion from 'emotion';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { programInfoSubText, simpleTextButton } from '../../../../assets/soter-flex-styles';
import Button from '../../../../lib/ui/Button';
import { categoryNames, typeNames } from '../../../../task/gallery/constants/videoTypeAndStatusNames';
import sliceLongString from '../../../../lib/utils/sliceLongString';
import CustomTooltip from '../../../../lib/ui/Tooltip';
import { setOpenedModal } from '../../../ModalContainer/modalsStore/actions';

export const access = Emotion.css`
  font-size: 13px;
`;

const DoubleLineCell = ({ rowData, dataKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let firstLine;
  let secondLine;
  let slicedFirstLine;
  let className;
  let accessModalButton;
  const companiesQuantity = rowData.companiesList?.length || 0;
  switch (dataKey) {
    case 'name':
      firstLine = `${rowData.firstName} ${rowData.lastName}`;
      secondLine = rowData.email;
      break;
    case 'access':
      className = access;
      accessModalButton = true;
      const departmentsQuantity = rowData.companiesList.reduce(
        (acc, val) => (acc += val.departmentsList?.length || 0),
        0,
      );
      firstLine
        = companiesQuantity > 0
          ? `${rowData.companiesList.length} ${
            companiesQuantity === 1
              ? t('MY_COMPANY.USERS.COMPANY')
              : t('MY_COMPANY.USERS.COMPANIES')
          }`
          : t('MY_COMPANY.USERS.ALL_COMPANIES');
      secondLine
        = departmentsQuantity > 0
          ? `${departmentsQuantity} ${
            departmentsQuantity === 1
              ? t('MY_COMPANY.USERS.DEPARTMENT')
              : t('MY_COMPANY.USERS.DEPARTMENTS')
          }`
          : t('MY_COMPANY.USERS.ALL_DEPARTMENTS');
      break;
    case 'siteAccess':
      className = access;
      accessModalButton = true;
      const sitesQuantity = rowData.companiesList.reduce(
        (acc, val) => (acc += val.sitesList?.length || 0),
        0,
      );
      firstLine
        = companiesQuantity > 0
          ? `${rowData.companiesList.length} ${
            companiesQuantity === 1
              ? t('MY_COMPANY.USERS.COMPANY')
              : t('MY_COMPANY.USERS.COMPANIES')
          }`
          : t('MY_COMPANY.USERS.ALL_COMPANIES');
      secondLine
        = sitesQuantity > 0
          ? `${sitesQuantity} ${
            sitesQuantity === 1
              ? t('MY_COMPANY.USERS.SITE')
              : t('MY_COMPANY.USERS.SITES')
          }`
          : t('MY_COMPANY.USERS.ALL_SITES');
      break;
    case 'lastSeen':
      firstLine
        = rowData.activity === 0
          ? '–'
          : moment(rowData.lastSeenInMilliseconds).fromNow();
      secondLine = `${rowData.activity} ${
        rowData.activity === 1
          ? t('MY_COMPANY.USERS.LOGIN')
          : t('MY_COMPANY.USERS.LOGINS')
      }`;
      break;
    case 'videoType':
      firstLine = t(typeNames[rowData.videoType]) || '';
      secondLine = t(categoryNames[rowData.category]) || '';
      break;
    default:
      return;
  }
  let isTooltipNeeded = false;
  let isJobTooltipNeeded = false;
  if (firstLine === firstLine?.toUpperCase()) {
    if (firstLine && firstLine.length > 11) isTooltipNeeded = true;
    slicedFirstLine = sliceLongString(firstLine, 11);
  } else {
    if (firstLine && firstLine.length > 11) isTooltipNeeded = true;
    slicedFirstLine = sliceLongString(firstLine, 11);
  }
  if (secondLine.length > 26) isJobTooltipNeeded = true;

  const ViewButton = () => (
    <Button
      type="button"
      appearance="text"
      style={{ marginLeft: 8, fontSize: 13 }}
      className={simpleTextButton}
      handler={() => dispatch(
        setOpenedModal(
          dataKey === 'siteAccess'
            ? 'showCompaniesWithSiteAccess'
            : 'showCompaniesWithAccess',
          { modalID: rowData.id },
        ),
      )}
      text={t('MY_COMPANY.USERS.VIEW')}
    />
  );

  const firstLineMarkup = isTooltipNeeded ? (
    <>
      <CustomTooltip text={firstLine}>
        <span>{slicedFirstLine}</span>
      </CustomTooltip>
      {accessModalButton && companiesQuantity > 0 && <ViewButton />}
    </>
  ) : (
    <>
      <span className={className}>{firstLine}</span>
      {accessModalButton && companiesQuantity > 0 && <ViewButton />}
    </>
  );

  const secondLineMarkup = isJobTooltipNeeded ? (
    <CustomTooltip text={secondLine}>
      <div className={programInfoSubText}>
        {sliceLongString(secondLine, 17) || '–'}
      </div>
    </CustomTooltip>
  ) : (
    <div className={className === access ? className : programInfoSubText}>
      {sliceLongString(secondLine, 26) || '–'}
    </div>
  );

  return (
    <>
      {firstLineMarkup}
      <br />
      {secondLineMarkup}
    </>
  );
};

export default DoubleLineCell;
