import React from 'react';
import * as Emotion from 'emotion';
import { text } from 'assets/jss/style';
import { useTranslation } from 'react-i18next';
import hexToRgba from '../../../lib/utils/hexToRgba';

const mainBar = (color) => Emotion.css`
  width: inherit;
  background-color: ${hexToRgba(color, 0.1)};
  border-radius: 3px;
  text-align: center;
  padding: 10px;
  margin-top: 15px;
`;
const AssessmentTotalScore = ({ score, description, color, scoreSubject = 'SOTER_TASK.ASSESSMENT.TOTAL' }) => {
  const { t } = useTranslation();
  return (
    <div className={mainBar(color)}>
      <div style={{ fontSize: '16px' }}>
        {`${t(scoreSubject)} ${t('SOTER_TASK.ASSESSMENT.SCORE')}: `}
        <span className={text(color, 16)}>{score / Math.floor(score) === 1 ? score : score.toFixed(2)}</span>
      </div>
      <div className={text(color)}>
        {t(description)}
      </div>
    </div>
  );
};

export default AssessmentTotalScore;
