import React from 'react';
import { ml5, select } from '../../../../assets/soter-flex-styles';

const Select = ({
  id,
  className,
  labelName,
  data,
  optionMapFunction,
  emptyOptionsText,
  defaultValue,
  setValue,
  noMargin,
}) => {
  const hasData = data && data.length;

  return (
    <label htmlFor={id} className={className}>
      {labelName}
      <select
        className={`${select} ${!noMargin && ml5}`}
        name={id}
        id={id}
        defaultValue={defaultValue || undefined}
        onChange={(e) => setValue(e.target.value)}
        disabled={!hasData}
      >
        {hasData ? data.map(optionMapFunction)
          : (
            <option>
              {emptyOptionsText}
            </option>
          )}
      </select>
    </label>
  );
};

Select.defaultProps = {
  optionMapFunction: (element) => (
    <option key={element.id} value={element.id}>
      {element.date}
    </option>
  ),
  emptyOptionsText: 'No synced days',
  labelName: null,
  noMargin: false,
};

export default Select;
