import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';

export const selectionFilter = Emotion.css`
  display: flex;
  justify-content: flex-start;
`;

export const clearFiltersButton = Emotion.css`
  margin-left: -14px;
  font-size: 16px;
  color: ${COLORS.ORANGE[500]};

  &:hover,
  &:focus,
  &:active {
    color: ${'#D4282D'};
  }
`;

export const exportButton = Emotion.css`
  margin-left: auto;
  font-size: 12px;
  color: ${COLORS.LIGHT_BLUE[600]};
  border: 1px solid ${COLORS.LIGHT_BLUE[600]};
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.LIGHT_BLUE[700]};
    border: 1px solid ${COLORS.LIGHT_BLUE[700]};
    background-color: ${COLORS.WHITE};
  }
`;

export const disabledButton = Emotion.css`
  color: ${COLORS.GRAY[400]};
  border-color: ${COLORS.GRAY[400]};

  &:focus,
  &:active,
  &:hover {
    cursor: not-allowed;
    color: ${COLORS.GRAY[400]};
    border-color: ${COLORS.GRAY[400]};
    background-color: transparent;
  }
`;
