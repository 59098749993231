var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toCamel } from 'snake-camel';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNotification } from 'containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from 'containers/NotificationContainer/notifications';
import { setOpenedModal } from 'containers/ModalContainer/modalsStore/actions';
import { addEntryToUsersRequest, categoriesService, createCategoryRequest, createEntryRequest, getCategoriesRequest, getMetaData, removeCategoryRequest, removeEntryRequest, removeEntryToUserRequest, updateCategoryRequest, updateEntryRequest, } from 'init/proto_client';
import { changeEntryName } from './categorySlice';
import { addEntryToUsersState, removeEntryFromUsersState } from '../../../wearables/activity/usersStore/actions';
import { ADD_ENTRY, CATEGORY, CREATE_CATEGORY, CREATE_ENTRY, DELETE_CATEGORY, DELETE_ENTRY, EDIT_CATEGORY, EDIT_ENTRY, FULFILLED, GET_CATEGORIES, PENDING, REJECTED, REMOVE_ENTRY, } from './categoryTypes';
const pending = (dispatch) => dispatch({
    type: CATEGORY + PENDING,
    payload: true,
});
const rejected = (dispatch, message) => dispatch({
    type: CATEGORY + REJECTED,
    payload: message,
});
export const getCategories = createAsyncThunk(CATEGORY + GET_CATEGORIES, (companyId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    getCategoriesRequest.setCompanyId(companyId);
    yield categoriesService.getSegments(getCategoriesRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (response) {
            const { segmentsList } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: CATEGORY + GET_CATEGORIES + FULFILLED,
                payload: segmentsList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
        }
    });
}));
export const createCategory = createAsyncThunk(CATEGORY + CREATE_CATEGORY, ({ companyId, title }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    createCategoryRequest.setCompanyId(companyId);
    createCategoryRequest.setTitle(title);
    yield categoriesService.createSegment(createCategoryRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + CREATE_CATEGORY + FULFILLED,
                payload: 'categoryCreated',
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.CATEGORY_CREATED,
            }));
            thunkAPI.dispatch(getCategories(companyId));
        }
        if (error) {
            if (error.code === 6) {
                thunkAPI.dispatch(setNotification({
                    type: notificationTypes.TYPE_FAILED,
                    message: notificationMessages.CATEGORY_DUPLICATE,
                }));
            }
            else {
                thunkAPI.dispatch(setNotification({
                    type: notificationTypes.TYPE_FAILED,
                    message: notificationMessages.GENERIC_ERROR,
                }));
            }
        }
    }));
}));
export const updateCategory = createAsyncThunk(CATEGORY + EDIT_CATEGORY, ({ categoryId, companyId, title, color, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    updateCategoryRequest.setId(categoryId);
    updateCategoryRequest.setTitle(title);
    updateCategoryRequest.setColor(color);
    yield categoriesService.updateSegment(updateCategoryRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + EDIT_CATEGORY + FULFILLED,
                payload: response,
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.CATEGORY_CREATED,
            }));
            thunkAPI.dispatch(getCategories(companyId));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    });
}));
export const deleteCategory = createAsyncThunk(CATEGORY + DELETE_CATEGORY, (segmentId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    removeCategoryRequest.setSegmentId(segmentId);
    yield categoriesService.removeSegment(removeCategoryRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + DELETE_CATEGORY + FULFILLED,
                payload: segmentId,
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.CATEGORY_DELETED,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const createEntry = createAsyncThunk(CATEGORY + CREATE_ENTRY, ({ companyId, categoryId, title }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    createEntryRequest.setCompanyId(companyId);
    createEntryRequest.setCompanySegmentId(categoryId);
    createEntryRequest.setTitle(title);
    yield categoriesService.createTag(createEntryRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + CREATE_ENTRY + FULFILLED,
                payload: 'categoryEntry',
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.ENTRY_CREATED,
            }));
            thunkAPI.dispatch(getCategories(companyId));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const updateEntry = createAsyncThunk(CATEGORY + EDIT_ENTRY, ({ companyId, editedEntry, title, editedCategory, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    updateEntryRequest.setId(editedEntry);
    updateEntryRequest.setCompanySegmentId(editedCategory);
    updateEntryRequest.setTitle(title);
    yield categoriesService.updateTag(updateEntryRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + EDIT_ENTRY + FULFILLED,
                payload: 'categoryEntry',
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.ENTRY_UPDATE,
            }));
            thunkAPI.dispatch((changeEntryName({ id: editedEntry, title })));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const addEntry = createAsyncThunk(CATEGORY + ADD_ENTRY, ({ entryId, userIds, companyId, categories, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    addEntryToUsersRequest.setCompanyTagId(entryId);
    addEntryToUsersRequest.setUserIdsList(userIds);
    yield categoriesService.addTagToUsers(addEntryToUsersRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + ADD_ENTRY + FULFILLED,
                payload: 'categoryEntry',
            });
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.ENTRY_ADDED,
            }));
            thunkAPI.dispatch(getCategories(companyId));
            thunkAPI.dispatch(addEntryToUsersState(userIds, entryId, categories));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const removeEntryFromUser = createAsyncThunk(CATEGORY + REMOVE_ENTRY, ({ entryId, userId, closeModal }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    removeEntryToUserRequest.setCompanyTagId(entryId);
    removeEntryToUserRequest.setUserId(userId);
    yield categoriesService.removeTagToUser(removeEntryToUserRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (error) {
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.REMOVE_ENTRY_FAILED_MSG,
            }));
            closeModal ? closeModal(null) : thunkAPI.dispatch(setOpenedModal(null));
        }
        else {
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.REMOVE_ENTRY_SUCCESS_MSG,
            }));
            thunkAPI.dispatch(removeEntryFromUsersState(userId, entryId));
            closeModal ? closeModal(null) : thunkAPI.dispatch(setOpenedModal(null));
        }
    }));
}));
export const deleteEntry = createAsyncThunk(CATEGORY + DELETE_ENTRY, ({ editedEntry, reqLength, index, companyId, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    removeEntryRequest.setCompanyTagId(editedEntry);
    yield categoriesService.removeTag(removeEntryRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        pending(thunkAPI.dispatch);
        if (response) {
            thunkAPI.dispatch({
                type: CATEGORY + DELETE_ENTRY + FULFILLED,
                payload: editedEntry,
            });
            // eslint-disable-next-line no-unused-expressions
            (reqLength && index) ? (reqLength === index + 1 &&
                thunkAPI.dispatch(setNotification({
                    type: notificationTypes.TYPE_SUCCESS,
                    message: notificationMessages.ENTRY_DELETED,
                }))) : thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.ENTRY_DELETED,
            }));
            thunkAPI.dispatch(getCategories(companyId));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
