import React from 'react';
import { Line } from 'react-konva';

const RebaChartVideoTracker = ({ progressPosition, stageHeight }) => (
  <Line
    points={[
      Math.round(progressPosition || 0),
      0,
      Math.round(progressPosition || 0),
      stageHeight,
    ]}
    stroke='#0D77D6'
    strokeWidth={2}
  />
);

export default RebaChartVideoTracker;
