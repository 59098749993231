import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getDatesOfSessions = (hazardsByDay, timezone) => hazardsByDay?.reduce(
  (acc, session) => [...acc, {
    id: session.workSessionId,
    date: moment(session.timeStart, 'x').format('MMM DD YYYY'),
    timeStart: moment(session.timeStart, 'x').valueOf(),
    timeEnd: moment(session.timeEnd, 'x').valueOf(),
  }],
  [],
);
