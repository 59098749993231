import React from 'react';
import { CHART_HEIGHT, VIDEO_WIDTH } from '../constants';
import { COLORS } from '../../../constants/colors';
import DonutChart from './DonutChart';
import legendSquare from '../../../assets/jss/components/legendSquare';
import presentSeconds from '../../../lib/utils/presentSeconds';

const getMargin = (scale) => (scale ? '20px' : '35px');

const DonutChartWithLegend = ({
  currentThreshold,
  chartAnglesData,
  selectedBodyPart,
  scale,
}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: '18px 0',
      color: COLORS.GRAY[400],
      fontSize: '12px',
      width: '333px',
    }}
  >
    <div
      style={{
        marginLeft: scale ? 0 : '10px',
        color: COLORS.GRAY[950],
        marginRight: '15px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
          marginTop: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            chartAnglesData[selectedBodyPart]?.arcs[0].fill,
            !!scale,
          )}
        />
        <div>
          <span>{'High: > '}</span>
          <span>
            {currentThreshold[1]}
            ˚
          </span>
          <span style={{ fontWeight: 600, marginLeft: scale ? '2px' : '5px' }}>
            {' '}
            {(
              (100 / 360) * chartAnglesData[selectedBodyPart]?.arcs[0].angle
              || 0
            ).toFixed(0)}
            %
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            chartAnglesData[selectedBodyPart]?.arcs[1].fill,
            !!scale,
          )}
        />
        <div>
          <span>Medium: </span>
          <span>
            {currentThreshold[0]}
            {'˚ - '}
            {currentThreshold[1]}
            ˚
          </span>
          <span style={{ fontWeight: 600, marginLeft: scale ? '2px' : '5px' }}>
            {' '}
            {(
              (100 / 360) * chartAnglesData[selectedBodyPart]?.arcs[1].angle
              || 0
            ).toFixed(0)}
            %
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            chartAnglesData[selectedBodyPart]?.arcs[2].fill,
            !!scale,
          )}
        />
        <div>
          <span>{'Low: < '}</span>
          <span>
            {currentThreshold[0]}
            ˚
          </span>
          <span style={{ fontWeight: 600, marginLeft: scale ? '2px' : '5px' }}>
            {' '}
            {(
              (100 / 360) * chartAnglesData[selectedBodyPart]?.arcs[2].angle
              || 0
            ).toFixed(0)}
            %
          </span>
        </div>
      </div>
    </div>
    <div style={{ marginTop: scale > 0.5 ? '0px' : '4px' }}>
      <DonutChart
        height={CHART_HEIGHT - 20}
        width={VIDEO_WIDTH / 4 - 30}
        data={chartAnglesData[selectedBodyPart]?.arcs}
        scale={scale}
        totalTime={presentSeconds(
          chartAnglesData[selectedBodyPart]?.totalFramesWithAngles / 30,
        )}
      />
    </div>
  </div>
);

export default DonutChartWithLegend;
