import React, { useState } from 'react';
import * as Emotion from 'emotion';
import { useDispatch } from 'react-redux';
import { COLORS } from 'constants/colors';
import { requestEmailConfirm } from '../../../auth/store/actions';
import { warningCardHeader as emailConfirmData } from '../../../assets/jss/_style';
import Button from '../../../lib/ui/Button';

const wrapper = Emotion.css`
  padding-top: 25vh;
  color: ${COLORS.WHITE};
  align-content: center;
  align-items: center;
  height: 100vh;
`;
const content = Emotion.css`
  max-width: 900px;
  margin: 0 auto;
`;

const header = Emotion.css`
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const desc = Emotion.css`
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 48px;
`;

const cta = Emotion.css`
  text-align: center;
`;

const button = Emotion.css`
  padding: 10px 224px 10px 224px;
  height: 38px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  background-color: #248bf2;
  border: 0;
  border-radius: 0;
  &:disabled {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: #ffffff !important;
    box-shadow: none !important;
    outline: none !important;
  }
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  &:active,
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: #248bf2;
    box-shadow: none !important;
    outline: none !important;
  }
`;

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const [trialButtonStatus, setTrialButtonDisable] = useState(false);
  const requestTrialHandler = () => {
    dispatch(requestEmailConfirm());
    setTrialButtonDisable(true);
  };
  return (
    <div style={{ width: '100vw' }}>
      <div
        className={wrapper}
        style={{
          backgroundImage: `url(${emailConfirmData?.background})`,
          backgroundPosition: 'top center',
          backgroundSize: '100%',
        }}
      >
        <div className={content}>
          <div className={header}>
            <h4>{emailConfirmData?.header}</h4>
          </div>
          <div className={cta}>
            {!trialButtonStatus ? (
              <Button
                disabled={false}
                type="button"
                className={button}
                handler={requestTrialHandler}
                text={emailConfirmData?.buttonText}
              />
            ) : (
              <Button
                disabled
                type="button"
                className={button}
                handler={requestTrialHandler}
                text={emailConfirmData?.buttonDisabledText}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
