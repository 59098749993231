/* eslint react/jsx-filename-extension: 0 */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Integrations } from '@sentry/tracing';
import 'assets/css/c3jscustom.css';
import 'assets/css/custom-theme.less';
import App from 'init/App';
import Root from 'init/Root';
import packageJson from '../package.json';
import 'init/i18n';

let sentryHost;
if (window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('dev') !== -1) {
  sentryHost = 'https://9f67cf44ed704fedbe470d5ccb38f85b@sentry2.soteranalytics.com/8';
} else if (window.location.host.indexOf('stage') !== -1 || window.location.host.indexOf('testing') !== -1) {
  sentryHost = 'https://1308acdbdd1c4969903a7efd2ac1a7de@sentry2.soteranalytics.com/9';
} else {
  sentryHost = 'https://45060659fdcf446e8efbbb7f07504c76@sentry2.soteranalytics.com/7';
}
window.t = (i) => i;

// Accept updates for all modules
if (module.hot) {
  module.hot.accept();
}

// this fix an error when user uses some special translation extensions and sort some of our tables all crashes
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      // uncomment this to turn on bug tracking
      // if (console) {
      //   console.warn('Cannot remove a child from a different parent', child, this);
      // }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // uncomment this to turn on bug tracking
      // if (console) {
      //   console.warn(
      //     'Cannot insert before a reference node from a different parent',
      //     referenceNode,
      //     this
      //   );
      // }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

Sentry.init({
  dsn: sentryHost,
  release: packageJson.version,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
console.log(`%c Version: ${packageJson.version}`, 'color: #fff; background-color: rgb(32, 150, 243);');

ReactDOM.render(
  <Sentry.ErrorBoundary fallback="An error has occurred">
    <Root>
      <App />
    </Root>
  </Sentry.ErrorBoundary>,
  document.getElementById('app'),
);
