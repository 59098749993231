function getXlsHeaders(schema) {
    return schema.map(({ header }) => ({
        value: header,
        fontWeight: 'bold',
        wrap: true,
    }));
}
function getXlsRows(data, schema) {
    return data.map((item, index) => schema === null || schema === void 0 ? void 0 : schema.map(({ getValue, type, getBackgroundColor, width, wrap, }) => ({
        value: getValue(item, index),
        type,
        backgroundColor: getBackgroundColor && getBackgroundColor(item),
        width: 30,
        wrap,
    })));
}
export class TableReportBuilder {
    constructor(data) {
        this.data = data;
        // schema is a mandatory property but it sets after class instance creation
        this.schema = [{ header: '', getValue: () => '' }];
    }
    modifyData(modifier) {
        this.data = modifier(this.data);
    }
    setSchema(schema) {
        this.schema = schema;
    }
    expandSchema(schema, insertIndex) {
        this.schema = [...this.schema.slice(0, insertIndex), ...schema, ...this.schema.slice(insertIndex)];
    }
    setLegend(legend) {
        this.legend = legend;
    }
    hasLegend() {
        return !!this.legend;
    }
    getCsvHeaders() {
        return this.schema.map(({ header }) => header).join(';').concat('\n');
    }
    getCsvRows() {
        const rows = this.data.map((item, index) => this.schema
            .map(({ getValue }) => getValue(item, index)).join(';')).join('\n').concat('\n');
        // Add legend data to csv because we can't put it to a separate sheet, so we put it in the bottom of a file
        if (this.legend) {
            return rows.concat(this.legend.data
                .map((item, index) => {
                var _a;
                return (_a = this.legend) === null || _a === void 0 ? void 0 : _a.schema.map(({ getValue }) => getValue(item, index)).join(';');
            }).join('\n'));
        }
        return rows;
    }
    getScvData() {
        return this.getCsvHeaders().concat(this.getCsvRows());
    }
    getXlsData() {
        return [getXlsHeaders(this.schema), ...getXlsRows(this.data, this.schema)];
    }
    getXlsLegend() {
        if (!this.legend)
            throw new Error('To get a legend add it to the builder first');
        return [getXlsHeaders(this.legend.schema), ...getXlsRows(this.legend.data, this.legend.schema)];
    }
}
