import { getMetaData, taskOverviewRequest, taskOverviewService } from 'init/proto_client';
import * as types from './actionTypes';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

export function toggleIsLoading(isLoading) {
  return ({ type: types.TASK_OVERVIEW_IS_LOADING, isLoading });
}

export function getTaskOverviewData(companyId) {
  return async (dispatch) => {
    taskOverviewRequest.setCompanyId(companyId);
    await taskOverviewService.getTaskOverviewData(
      taskOverviewRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.TASK_OVERVIEW_ERROR,
            error,
          });
        } else {
          dispatch({
            type: types.SET_TASK_OVERVIEW,
            overviewData: response.toObject(),
          });
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export const resetTaskOverview = () => (dispatch) => dispatch({ type: types.RESET_TASK_OVERVIEW });
