import moment from 'moment';
import { getRiskGroupText } from '../../../constants/getRiskGroupText';
import getRiskGroupsColor from './getRiskGroupsColor';
import { filterStatusMap } from './filterStatusMap';
import replaceCsvRestrictedSymbols from '../utils/replaceCsvRestrictedSymbols';
export default function getActivityReportSchema(t) {
    return [
        {
            header: t('GENERAL.ID'),
            getValue: ({ id }) => id,
        },
        {
            header: t('WEARABLES.ACTIVITY.PROFILE_LINK'),
            getValue: ({ id }) => `${window.location.origin}/users/wearables/user/${id}`,
            width: 21,
            wrap: true,
        },
        {
            header: t('GENERAL.FIRST_NAME'),
            getValue: ({ firstName }) => replaceCsvRestrictedSymbols(firstName),
        },
        {
            header: t('GENERAL.LAST_NAME'),
            getValue: ({ lastName }) => replaceCsvRestrictedSymbols(lastName),
        },
        {
            header: t('LOGIN.LOGIN'),
            getValue: ({ nickname }) => replaceCsvRestrictedSymbols(nickname),
        },
        {
            header: t('WEARABLES.OVERVIEW.DEPARTMENT'),
            getValue: ({ department }) => replaceCsvRestrictedSymbols(department === null || department === void 0 ? void 0 : department.name),
        },
        {
            header: t('WEARABLES.OVERVIEW.SITE'),
            getValue: ({ site }) => replaceCsvRestrictedSymbols(site === null || site === void 0 ? void 0 : site.name),
        },
        {
            header: t('GENERAL.JOB_TITLE'),
            getValue: ({ jobRole }) => replaceCsvRestrictedSymbols(jobRole === null || jobRole === void 0 ? void 0 : jobRole.title),
        },
        {
            header: t('WEARABLES.HELP.STATUS'),
            getValue: ({ program }) => filterStatusMap[program === null || program === void 0 ? void 0 : program.filterStatus],
        },
        // This column confuses users (identifying is a program active or not)
        // {
        //   header: t('GENERAL.PROGRAM'),
        //   getValue: ({ program }) => {
        //     if (!program) return '';
        //     const { startedAt, finishedAt } = program;
        //     if (startedAt && !finishedAt) return t('WEARABLES.ACTIVITY.REPORT.ACTIVE');
        //     return t('WEARABLES.ACTIVITY.REPORT.INACTIVE');
        //   },
        // },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.PROGRAM_NAME'),
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.name,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.STARTED_AT'),
            getValue: ({ program }) => ((program === null || program === void 0 ? void 0 : program.startedAt)
                ? moment(program.startedAt, 'X').format('DD/MM/YYYY')
                : '-'),
            width: 20,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.FINISHED_AT'),
            getValue: ({ program }) => ((program === null || program === void 0 ? void 0 : program.finishedAt)
                ? moment(program.finishedAt, 'X').format('DD/MM/YYYY')
                : '-'),
            width: 20,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.RECORDED'),
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.workSessionCount,
            type: Number,
        },
        {
            header: `${t('WEARABLES.ACTIVITY.REPORT.PER_CHANGE')} %`,
            getValue: ({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.improvements) === null || _a === void 0 ? void 0 : _a.hazardsPerHourChange; },
            type: Number,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.START_RISK'),
            getValue: ({ program }) => t(getRiskGroupText(program === null || program === void 0 ? void 0 : program.initialRiskGroup)),
            getBackgroundColor: ({ program }) => getRiskGroupsColor(program === null || program === void 0 ? void 0 : program.initialRiskGroup),
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.CURRENT_RISK'),
            getValue: ({ program }) => t(getRiskGroupText(program === null || program === void 0 ? void 0 : program.currentRiskGroup)),
            getBackgroundColor: ({ program }) => getRiskGroupsColor(program === null || program === void 0 ? void 0 : program.currentRiskGroup),
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.START_PER'),
            getValue: ({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.improvements) === null || _a === void 0 ? void 0 : _a.initialHazardsPerHour; },
            type: Number,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.CURRENT_PER'),
            getValue: ({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.improvements) === null || _a === void 0 ? void 0 : _a.currentHazardsPerHour; },
            type: Number,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.GOLD'),
            getValue: ({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.achievements) === null || _a === void 0 ? void 0 : _a.goldGoalsQuantity; },
            type: Number,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.AVG_PER'),
            getValue: ({ program }) => { var _a; return (_a = program === null || program === void 0 ? void 0 : program.improvements) === null || _a === void 0 ? void 0 : _a.hazardsPerHourImprovement; },
            type: Number,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.SPINE_MOVEMENTS'),
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.movementsCountSpine,
            type: Number,
        },
        {
            header: `${t('WEARABLES.ACTIVITY.REPORT.SPINE_HAZARDS')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.hazardsCountSpine,
            type: Number,
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.SHOULDER_MOVEMENTS'),
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.movementsCountShoulder,
            type: Number,
        },
        {
            header: `${t('WEARABLES.ACTIVITY.REPORT.SHOULDER_HAZARDS')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.hazardsCountShoulder,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.LIFTING')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.poorLifting,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.INTENSITY')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.intenseLifting,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.TWISTING')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.backTwisting,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.STATIC')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.staticPosition,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.REPETITION')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.repetition,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.ELEVATION')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.armElevation,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.STATIC_ELEVATION')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.staticArmElevation,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.REPETITIVE')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.repetitiveArmMovements,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.PUSHING')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.pushingPulling,
            type: Number,
        },
        {
            header: `${t('WEARABLES.HAZARDS.OVER')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ program }) => program === null || program === void 0 ? void 0 : program.reportData.overexertion,
            type: Number,
        },
        {
            header: `${t('WEARABLES.ACTIVITY.REPORT.TASKS')} (${t('WEARABLES.ACTIVITY.REPORT.TOTAL')})`,
            getValue: ({ taskNumber }) => taskNumber,
            type: Number,
        },
        {
            header: `${t('WEARABLES.ACTIVITY.REPORT.TOTAL_DURATION')} (${t('GENERAL.HOURS')})`,
            getValue: ({ program }) => { var _a, _b; return (_b = (_a = program === null || program === void 0 ? void 0 : program.reportData) === null || _a === void 0 ? void 0 : _a.hoursRecorded) === null || _b === void 0 ? void 0 : _b.toFixed(1); },
        },
        {
            header: t('WEARABLES.ACTIVITY.REPORT.WEARING_NOW'),
            getValue: ({ wearingNow }) => wearingNow,
            type: Boolean,
        },
    ];
}
