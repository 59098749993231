/**
 * @fileoverview gRPC-Web generated client stub for segments
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/segments.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_segment_message_pb = require('../../../api/dashboard/v2/segment_message_pb.js')
const proto = {};
proto.segments = require('./segments_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.segments.SegmentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.segments.SegmentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.GetSegmentsRequest,
 *   !proto.segments.GetSegmentsResponse>}
 */
const methodDescriptor_Segments_GetSegments = new grpc.web.MethodDescriptor(
  '/segments.Segments/GetSegments',
  grpc.web.MethodType.UNARY,
  proto.segments.GetSegmentsRequest,
  proto.segments.GetSegmentsResponse,
  /**
   * @param {!proto.segments.GetSegmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.GetSegmentsResponse.deserializeBinary
);


/**
 * @param {!proto.segments.GetSegmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.GetSegmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.GetSegmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.getSegments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/GetSegments',
      request,
      metadata || {},
      methodDescriptor_Segments_GetSegments,
      callback);
};


/**
 * @param {!proto.segments.GetSegmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.GetSegmentsResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.getSegments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/GetSegments',
      request,
      metadata || {},
      methodDescriptor_Segments_GetSegments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.CreateSegmentRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_CreateSegment = new grpc.web.MethodDescriptor(
  '/segments.Segments/CreateSegment',
  grpc.web.MethodType.UNARY,
  proto.segments.CreateSegmentRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.CreateSegmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.CreateSegmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.createSegment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/CreateSegment',
      request,
      metadata || {},
      methodDescriptor_Segments_CreateSegment,
      callback);
};


/**
 * @param {!proto.segments.CreateSegmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.createSegment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/CreateSegment',
      request,
      metadata || {},
      methodDescriptor_Segments_CreateSegment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.UpdateSegmentRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_UpdateSegment = new grpc.web.MethodDescriptor(
  '/segments.Segments/UpdateSegment',
  grpc.web.MethodType.UNARY,
  proto.segments.UpdateSegmentRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.UpdateSegmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.UpdateSegmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.updateSegment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/UpdateSegment',
      request,
      metadata || {},
      methodDescriptor_Segments_UpdateSegment,
      callback);
};


/**
 * @param {!proto.segments.UpdateSegmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.updateSegment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/UpdateSegment',
      request,
      metadata || {},
      methodDescriptor_Segments_UpdateSegment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveSegmentRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_RemoveSegment = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveSegment',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveSegmentRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.RemoveSegmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveSegmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeSegment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveSegment',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveSegment,
      callback);
};


/**
 * @param {!proto.segments.RemoveSegmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeSegment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveSegment',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveSegment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.CreateTagRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_CreateTag = new grpc.web.MethodDescriptor(
  '/segments.Segments/CreateTag',
  grpc.web.MethodType.UNARY,
  proto.segments.CreateTagRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.CreateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.createTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/CreateTag',
      request,
      metadata || {},
      methodDescriptor_Segments_CreateTag,
      callback);
};


/**
 * @param {!proto.segments.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.createTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/CreateTag',
      request,
      metadata || {},
      methodDescriptor_Segments_CreateTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.UpdateTagRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_UpdateTag = new grpc.web.MethodDescriptor(
  '/segments.Segments/UpdateTag',
  grpc.web.MethodType.UNARY,
  proto.segments.UpdateTagRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.UpdateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.updateTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/UpdateTag',
      request,
      metadata || {},
      methodDescriptor_Segments_UpdateTag,
      callback);
};


/**
 * @param {!proto.segments.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.updateTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/UpdateTag',
      request,
      metadata || {},
      methodDescriptor_Segments_UpdateTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_RemoveTag = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTag',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.RemoveTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTag',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTag,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTag',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.CreateTagsRequest,
 *   !proto.segments.CreateTagsResponse>}
 */
const methodDescriptor_Segments_CreateTags = new grpc.web.MethodDescriptor(
  '/segments.Segments/CreateTags',
  grpc.web.MethodType.UNARY,
  proto.segments.CreateTagsRequest,
  proto.segments.CreateTagsResponse,
  /**
   * @param {!proto.segments.CreateTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.CreateTagsResponse.deserializeBinary
);


/**
 * @param {!proto.segments.CreateTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.CreateTagsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.CreateTagsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.createTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/CreateTags',
      request,
      metadata || {},
      methodDescriptor_Segments_CreateTags,
      callback);
};


/**
 * @param {!proto.segments.CreateTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.CreateTagsResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.createTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/CreateTags',
      request,
      metadata || {},
      methodDescriptor_Segments_CreateTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagsRequest,
 *   !proto.segments.RemoveTagsResponse>}
 */
const methodDescriptor_Segments_RemoveTags = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTags',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagsRequest,
  proto.segments.RemoveTagsResponse,
  /**
   * @param {!proto.segments.RemoveTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.RemoveTagsResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.RemoveTagsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.RemoveTagsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTags',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTags,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.RemoveTagsResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTags',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagsToEventRequest,
 *   !proto.segments.AddTagsToEventResponse>}
 */
const methodDescriptor_Segments_AddTagsToEvent = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagsToEvent',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagsToEventRequest,
  proto.segments.AddTagsToEventResponse,
  /**
   * @param {!proto.segments.AddTagsToEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.AddTagsToEventResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagsToEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.AddTagsToEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.AddTagsToEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagsToEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagsToEvent',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToEvent,
      callback);
};


/**
 * @param {!proto.segments.AddTagsToEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.AddTagsToEventResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagsToEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagsToEvent',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagsFromEventRequest,
 *   !proto.segments.RemoveTagsFromEventResponse>}
 */
const methodDescriptor_Segments_RemoveTagsFromEvent = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTagsFromEvent',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagsFromEventRequest,
  proto.segments.RemoveTagsFromEventResponse,
  /**
   * @param {!proto.segments.RemoveTagsFromEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.RemoveTagsFromEventResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagsFromEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.RemoveTagsFromEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.RemoveTagsFromEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTagsFromEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromEvent',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromEvent,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagsFromEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.RemoveTagsFromEventResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTagsFromEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromEvent',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagsToWorkSessionRequest,
 *   !proto.segments.AddTagsToWorkSessionResponse>}
 */
const methodDescriptor_Segments_AddTagsToWorkSession = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagsToWorkSession',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagsToWorkSessionRequest,
  proto.segments.AddTagsToWorkSessionResponse,
  /**
   * @param {!proto.segments.AddTagsToWorkSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.AddTagsToWorkSessionResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagsToWorkSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.AddTagsToWorkSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.AddTagsToWorkSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagsToWorkSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagsToWorkSession',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToWorkSession,
      callback);
};


/**
 * @param {!proto.segments.AddTagsToWorkSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.AddTagsToWorkSessionResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagsToWorkSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagsToWorkSession',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToWorkSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagsFromWorkSessionRequest,
 *   !proto.segments.RemoveTagsFromWorkSessionResponse>}
 */
const methodDescriptor_Segments_RemoveTagsFromWorkSession = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTagsFromWorkSession',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagsFromWorkSessionRequest,
  proto.segments.RemoveTagsFromWorkSessionResponse,
  /**
   * @param {!proto.segments.RemoveTagsFromWorkSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.RemoveTagsFromWorkSessionResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagsFromWorkSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.RemoveTagsFromWorkSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.RemoveTagsFromWorkSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTagsFromWorkSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromWorkSession',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromWorkSession,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagsFromWorkSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.RemoveTagsFromWorkSessionResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTagsFromWorkSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromWorkSession',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromWorkSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagToUserRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_AddTagToUser = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagToUser',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagToUserRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.AddTagToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagToUser',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagToUser,
      callback);
};


/**
 * @param {!proto.segments.AddTagToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagToUser',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagToUsersRequest,
 *   !proto.segments.SegmentsResponse>}
 */
const methodDescriptor_Segments_AddTagToUsers = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagToUsers',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagToUsersRequest,
  proto.segments.SegmentsResponse,
  /**
   * @param {!proto.segments.AddTagToUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentsResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagToUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagToUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagToUsers',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagToUsers,
      callback);
};


/**
 * @param {!proto.segments.AddTagToUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentsResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagToUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagToUsers',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagToUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagToUserRequest,
 *   !proto.segments.SegmentResponse>}
 */
const methodDescriptor_Segments_RemoveTagToUser = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTagToUser',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagToUserRequest,
  proto.segments.SegmentResponse,
  /**
   * @param {!proto.segments.RemoveTagToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.SegmentResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.SegmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.SegmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTagToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTagToUser',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagToUser,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.SegmentResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTagToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTagToUser',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagsToUserRequest,
 *   !proto.segments.AddTagsToUserResponse>}
 */
const methodDescriptor_Segments_AddTagsToUser = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagsToUser',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagsToUserRequest,
  proto.segments.AddTagsToUserResponse,
  /**
   * @param {!proto.segments.AddTagsToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.AddTagsToUserResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagsToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.AddTagsToUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.AddTagsToUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagsToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagsToUser',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToUser,
      callback);
};


/**
 * @param {!proto.segments.AddTagsToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.AddTagsToUserResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagsToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagsToUser',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagsFromUserRequest,
 *   !proto.segments.RemoveTagsFromUserResponse>}
 */
const methodDescriptor_Segments_RemoveTagsFromUser = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTagsFromUser',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagsFromUserRequest,
  proto.segments.RemoveTagsFromUserResponse,
  /**
   * @param {!proto.segments.RemoveTagsFromUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.RemoveTagsFromUserResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagsFromUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.RemoveTagsFromUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.RemoveTagsFromUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTagsFromUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromUser',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromUser,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagsFromUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.RemoveTagsFromUserResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTagsFromUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromUser',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagsToDashboardUserRequest,
 *   !proto.segments.AddTagsToDashboardUserResponse>}
 */
const methodDescriptor_Segments_AddTagsToDashboardUser = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagsToDashboardUser',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagsToDashboardUserRequest,
  proto.segments.AddTagsToDashboardUserResponse,
  /**
   * @param {!proto.segments.AddTagsToDashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.AddTagsToDashboardUserResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagsToDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.AddTagsToDashboardUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.AddTagsToDashboardUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagsToDashboardUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagsToDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToDashboardUser,
      callback);
};


/**
 * @param {!proto.segments.AddTagsToDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.AddTagsToDashboardUserResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagsToDashboardUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagsToDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToDashboardUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagsFromDashboardUserRequest,
 *   !proto.segments.RemoveTagsFromDashboardUserResponse>}
 */
const methodDescriptor_Segments_RemoveTagsFromDashboardUser = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTagsFromDashboardUser',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagsFromDashboardUserRequest,
  proto.segments.RemoveTagsFromDashboardUserResponse,
  /**
   * @param {!proto.segments.RemoveTagsFromDashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.RemoveTagsFromDashboardUserResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagsFromDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.RemoveTagsFromDashboardUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.RemoveTagsFromDashboardUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTagsFromDashboardUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromDashboardUser,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagsFromDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.RemoveTagsFromDashboardUserResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTagsFromDashboardUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromDashboardUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.AddTagsToDeviceRequest,
 *   !proto.segments.AddTagsToDeviceResponse>}
 */
const methodDescriptor_Segments_AddTagsToDevice = new grpc.web.MethodDescriptor(
  '/segments.Segments/AddTagsToDevice',
  grpc.web.MethodType.UNARY,
  proto.segments.AddTagsToDeviceRequest,
  proto.segments.AddTagsToDeviceResponse,
  /**
   * @param {!proto.segments.AddTagsToDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.AddTagsToDeviceResponse.deserializeBinary
);


/**
 * @param {!proto.segments.AddTagsToDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.AddTagsToDeviceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.AddTagsToDeviceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.addTagsToDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/AddTagsToDevice',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToDevice,
      callback);
};


/**
 * @param {!proto.segments.AddTagsToDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.AddTagsToDeviceResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.addTagsToDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/AddTagsToDevice',
      request,
      metadata || {},
      methodDescriptor_Segments_AddTagsToDevice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.segments.RemoveTagsFromDeviceRequest,
 *   !proto.segments.RemoveTagsFromDeviceResponse>}
 */
const methodDescriptor_Segments_RemoveTagsFromDevice = new grpc.web.MethodDescriptor(
  '/segments.Segments/RemoveTagsFromDevice',
  grpc.web.MethodType.UNARY,
  proto.segments.RemoveTagsFromDeviceRequest,
  proto.segments.RemoveTagsFromDeviceResponse,
  /**
   * @param {!proto.segments.RemoveTagsFromDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.segments.RemoveTagsFromDeviceResponse.deserializeBinary
);


/**
 * @param {!proto.segments.RemoveTagsFromDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.segments.RemoveTagsFromDeviceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.segments.RemoveTagsFromDeviceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.segments.SegmentsClient.prototype.removeTagsFromDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromDevice',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromDevice,
      callback);
};


/**
 * @param {!proto.segments.RemoveTagsFromDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.segments.RemoveTagsFromDeviceResponse>}
 *     Promise that resolves to the response
 */
proto.segments.SegmentsPromiseClient.prototype.removeTagsFromDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/segments.Segments/RemoveTagsFromDevice',
      request,
      metadata || {},
      methodDescriptor_Segments_RemoveTagsFromDevice);
};


module.exports = proto.segments;

