import React from 'react';
import Notification from './components/Notification';
import { notificationContainer } from '../../assets/soter-flex-styles';
import NotificationEvent from './components/NotificationEvent';
import { useAppSelector } from '../../lib/utils/useStore';

const NotificationContainer = () => {
  const { notifications, events } = useAppSelector((state) => state.notifications);

  return (
    <>
      <div className={notificationContainer}>
        {events?.map((event) => (
          <NotificationEvent
            key={event.id}
            notification={event}
          />
        ))}
        {notifications?.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
          />
        ))}
      </div>
    </>
  );
};

export default NotificationContainer;
