import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import _chunk from 'lodash/chunk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  flexColumn,
  flexRow,
  infoMainText,
  mb2,
  mb5,
  mt5,
  printableWrapper,
  printableQR,
  printableQRArea,
  printableQRText,
  subText,
} from 'assets/soter-flex-styles';
import Button from 'lib/ui/Button';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';

const QRCode = require('qrcode.react');

const QRPrintDialog = ({ users, closeModals = () => {} }) => {
  const [showQrCodes, setShowQrCodes] = useState(false);
  const qrRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const printQR = useReactToPrint({
    content: () => qrRef.current,
  });
  const printHandler = () => {
    setShowQrCodes(true);
    // printQR();
    // setTimeout(() => {
    //   closeModals();
    // }, 777);
  };
  useEffect(() => {
    if (showQrCodes) {
      printQR();
      // setTimeout(() => {
      dispatch(setOpenedModal(null));
      // }, 1000);
    }
  }, [showQrCodes]);
  const chunkedUsers = _chunk(users, 3);
  return (
    <div style={{ maxHeight: '400px' }} className={`${flexColumn} ${mb2}`}>
      <h4 style={{ marginBottom: '30px' }}>
        {`You have selected ${users?.length} users`}
      </h4>
      <div style={{ overflow: 'scroll', height: '300px' }}>
        <div ref={qrRef}>
          {chunkedUsers.map((users) => (
            <div className={printableWrapper} style={{ display: 'flex' }}>
              {users.map((user) => (
                <div
                  style={{
                    marginRight: !showQrCodes ? '25px' : '',
                    marginLeft: !showQrCodes ? '25px' : '',
                  }}
                  className={`${printableQRArea}`}
                >
                  <div
                    className={`${printableQR} ${mt5} ${mb5} ${flexRow}`}
                    style={{ justifyContent: 'center' }}
                  >
                    <QRCode value={`${user?.nickname}`} size={170} />
                  </div>
                  <div className={printableQRText}>
                    <p
                      className={`${flexRow} ${infoMainText}`}
                      style={{ justifyContent: 'center' }}
                    >
                      {`${user?.firstName} ${user?.lastName}`.substring(0, 16)}
                    </p>
                    <p
                      className={`${flexRow} ${subText} ${mb5}`}
                      style={{
                        justifyContent: 'center',
                        whitespace: 'nowrap',
                      }}
                    >
                      {`(${user?.nickname.substring(0, 16)})`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Button
        handler={printHandler}
        text={t('WEARABLES.ACTIVITY.DIALOGS.PRINT_QR')}
        style={{ display: 'block', width: '100%' }}
        type="button"
      />
    </div>
  );
};

export default QRPrintDialog;
