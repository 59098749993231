import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Lower } from '../../../assets/img/icons/Lower.svg';
import { ReactComponent as Higher } from '../../../assets/img/icons/Higher.svg';
import { ReactComponent as Medium } from '../../../assets/img/icons/Medium.svg';
import { ReactComponent as NA } from '../../../assets/img/icons/NA.svg';
import { badge, medium } from './styles';
import Tooltip from '../Tooltip';
import { getRiskGroupText } from '../../../constants/getRiskGroupText';

const getRiskIcon = (risk) => {
  switch (risk) {
    case 'high': return <Higher />;
    case 'medium': return <Medium />;
    case 'lower': return <Lower />;
    default: return <NA />;
  }
};

const RiskGroupBadge = ({
  riskGroup, mediumSize, isCurrentGroup, showTooltip = true, noRiskGroup,
}) => {
  const { t } = useTranslation();
  return (
    showTooltip ? (
      <Tooltip
        noTranslation
        text={`${noRiskGroup ? '' : isCurrentGroup ? `${t('GENERAL.CURRENT')} ` : `${t('GENERAL.INITIAL')} `}${t(getRiskGroupText(riskGroup))
          .toLowerCase()}`}
      >
        <div className={`${badge} ${mediumSize ? medium : ''}`}>
          {getRiskIcon(riskGroup)}
        </div>
      </Tooltip>
    ) : (
      <div className={`${badge} ${mediumSize ? medium : ''}`}>
        {getRiskIcon(riskGroup)}
      </div>
    )
  );
};

export default RiskGroupBadge;
