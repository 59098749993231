import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

export default function sortItemsForTable(items, sortState, type) {
  const { sortColumn, sortType } = sortState;
  const asc = sortType === 'asc';
  const invertedSortType = asc ? 'desc' : 'asc'; // needed because of initially inverted sorting arrows
  const isHelp = type === 'help';
  const isDashboardUsers = type === 'dashboardUsers' || type === 'myCompanyUsers';
  const isSoterTaskVideos = type === 'soterTaskVideos';

  if (!items) return [];
  let sortedItems = [...items];

  const doSimpleSorting = (keyToSortBy, caseInsensitive) => {
    const hasNoValue = isSoterTaskVideos ? '' : undefined;
    const itemsWithUndefinedValues = items.filter((item) => _get(item, keyToSortBy) === hasNoValue);
    const itemsWithDefinedValues = items.filter((item) => _get(item, keyToSortBy) !== hasNoValue);
    const insensitiveSortFunc = (item) => _get(item, keyToSortBy).toLowerCase();
    const sortBy = caseInsensitive ? insensitiveSortFunc : keyToSortBy;
    return sortType === 'desc' ? _orderBy(itemsWithDefinedValues, [sortBy], [sortType]).concat(itemsWithUndefinedValues)
      : itemsWithUndefinedValues.concat(_orderBy(itemsWithDefinedValues, [sortBy], [sortType]));
  };

  const getLastname = (lastName) => {
    if (lastName === '_') {
      return ' ';
    }
    if (lastName === 'Clip&Go') {
      return null;
    }
    return lastName?.toLowerCase();
  };

  const getSortByFirstName = ({ firstName, lastName }) => (getLastname(lastName) ? firstName?.toLowerCase() : null);

  switch (sortColumn) {
    case 'name':
      if (isHelp) {
        sortedItems = _sortBy(items, (item) => [item.user.lastName, item.user.firstName, item.id]);
        return asc ? _reverse(sortedItems) : sortedItems;
      }
      if (isDashboardUsers) {
        return _orderBy(items,
          [(item) => getLastname(item.lastName), getSortByFirstName, (item) => item.email],
          [invertedSortType, invertedSortType, invertedSortType]);
      }
      return _orderBy(items,
        [(item) => getLastname(item.lastName), getSortByFirstName, (item) => item.nickname?.toLowerCase()],
        [invertedSortType, invertedSortType, invertedSortType]);
    case 'progress':
      sortedItems = _sortBy(
        items.filter((item) => item.program?.progress),
        (item) => item.program?.progress?.acceptedWorkSessionsCount,
      );
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      return sortedItems.concat(items.filter((item) => !item.program?.progress));
    case 'achievement':
      sortedItems = _sortBy(
        items.filter((item) => item.program?.achievements?.goldGoalsQuantity),
        (item) => item.program?.achievements?.goldGoalsQuantity,
      );
      sortedItems = items.filter((item) => !item.program?.achievements?.goldGoalsQuantity).concat(sortedItems);
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      return sortedItems;
    case 'device':
      sortedItems = isHelp ? _sortBy(
        items.filter((item) => item.user.device),
        (item) => [item.user.device?.lastBatteryCharge, item.id],
      ) : _sortBy(
        items.filter((item) => item.device),
        (item) => item.device?.lastBatteryCharge,
      );
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      return isHelp ? sortedItems.concat(items.filter((item) => !item.user.device)) : sortedItems.concat(items.filter((item) => !item.device));
    case 'daysPassed':
      sortedItems = isHelp ? _sortBy(items, (item) => item.user.daysPassed) : _sortBy(items.filter((i) => i.program), (item) => item.program?.daysPassed);
      return asc ? sortedItems : _reverse(sortedItems);
    case 'coachSync':
      sortedItems = _sortBy(
        items.filter((item) => item.program?.progress).filter((item) => !(item.wearingNow)),
        (item) => item.program?.progress?.lastSession,
      );
      sortedItems = sortedItems.concat(items.filter((item) => (item.wearingNow)));
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      return sortedItems.concat(items.filter((item) => !item.program));
    case 'startedAt':
      sortedItems = _sortBy(
        items.filter((item) => item.program),
        (item) => item.program?.startedAt,
      );
      sortedItems = asc ? sortedItems.concat(items.filter((item) => (item.wearingNow))) : _reverse(sortedItems);
      return sortedItems.concat(items.filter((item) => !item.program));
    case 'lastSync':
      sortedItems = isHelp ? _sortBy(items.filter((item) => !item.user.device && item.user.lastSessionInMilliseconds),
        (item) => [item.user.lastSessionInMilliseconds, item.id]) : _sortBy(items.filter((item) => !(item.wearingNow)),
        (item) => item.lastSessionInMilliseconds);
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      if (asc) {
        return isHelp ? sortedItems.concat(_sortBy(items.filter((item) => item.user.device), (item) => [item.user.lastSessionInMilliseconds, item.id])) : sortedItems.concat(items.filter((item) => (item.wearingNow)));
      }
      return isHelp ? _sortBy(items.filter((item) => item.user.device), (item) => [item.user.lastSessionInMilliseconds, item.id]).concat(sortedItems) : items.filter((item) => (item.wearingNow)).concat(sortedItems);

    case 'lastBatteryCharge':
      sortedItems = _sortBy(
        items.filter((item) => item.lastBatteryCharge !== -1), (item) => item.lastBatteryCharge,
      );
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      return sortedItems.concat(items.filter((item) => item.lastBatteryCharge === -1));
    case 'lastSynced':
      sortedItems = _sortBy(items, 'lastSynced');
      return asc ? sortedItems : _reverse(sortedItems);
    case 'lastSeen':
      return _orderBy(items, [(item) => item.lastSeenInMilliseconds, (item) => item.activity],
        [invertedSortType, invertedSortType]);
    case 'lastUser':
      sortedItems = _sortBy(items.filter((item) => item.currentUser || item.lastUser), (item) => item.currentUser?.lastName || item.lastUser?.lastName);
      sortedItems = asc ? _reverse(sortedItems) : sortedItems;
      return sortedItems.concat(items.filter((item) => !item.currentUser && !item.lastUser));
    case 'average':
      sortedItems = isHelp ? _sortBy(
        items.filter((item) => item.user.improvements),
        (item) => item.user.improvements.currentHazardsPerHour,
      ) : _sortBy(
        items.filter((item) => item.program?.improvements),
        (item) => item.program?.improvements.hazardsPerHourImprovement,
      );
      sortedItems = asc ? _reverse(sortedItems) : sortedItems;
      return isHelp ? sortedItems.concat(items.filter((item) => !item.user.program?.improvements)) : sortedItems.concat(items.filter((item) => !item.program?.improvements));
    case 'hazards':
      sortedItems = isHelp ? _sortBy(
        items.filter((item) => item.user.improvements),
        (item) => [item.user.improvements.hazardousMovementRatioValue, item.id],
      ) : _sortBy(
        items.filter((item) => item.program?.improvements),
        (item) => item.program?.improvements.hazardousMovementRatioValue,
      );
      sortedItems = asc ? _reverse(sortedItems) : sortedItems;
      return isHelp ? sortedItems.concat(items.filter((item) => !item.user.improvements)) : sortedItems.concat(items.filter((item) => !item.program?.improvements));
    case 'department':
      sortedItems = (isHelp) ? _sortBy(items, (item) => [item.user.department?.name, item.id]) : _sortBy(items.filter((i) => i.department && i.department?.name), (item) => item.department?.name.toLowerCase());
      sortedItems = asc ? sortedItems : _reverse(sortedItems);
      return sortedItems.concat(items.filter((i) => !i.department || (i.department && !i.department?.name)));
    case 'dashboardUserId':
      sortedItems = _sortBy(items, (item) => [item.dashboardUserId, item.id]);
      return asc ? sortedItems : _reverse(sortedItems);
    case 'reason':
      sortedItems = _sortBy(items, (item) => [item.reason, item.id]);
      return asc ? sortedItems : _reverse(sortedItems);
    case 'rate':
      sortedItems = _sortBy(items, (item) => [item.rate, item.id]);
      return asc ? sortedItems : _reverse(sortedItems);
    case 'reba':
      sortedItems = _orderBy(
        items,
        (item) => item.maxRebaScore,
      );
      return asc ? sortedItems : _reverse(sortedItems);
    case 'improvements':
      sortedItems = _sortBy(
        items,
        (item) => item.overallImprovement
      );
      return asc ? sortedItems : _reverse(sortedItems);
    case 'createdAt':
    case 'dateOfUpload':
    case 'companyCreatedAt':
      sortedItems = _sortBy(items, (item) => [item.createdAt, item.id]);
      return asc ? sortedItems : _reverse(sortedItems);
    case 'status':
      if (isSoterTaskVideos) {
        return doSimpleSorting('status', false);
      }
      const newItems = _sortBy(items.filter((i) => i.status === 'new'), (item) => item.createdAt);
      const reopenItems = _sortBy(items.filter((i) => i.status === 'reopen'), (item) => item.createdAt);
      const inProgressItems = _sortBy(items.filter((i) => i.status === 'in_progress'), (item) => item.createdAt);
      const closedItems = _sortBy(items.filter((i) => i.status === 'closed'), (item) => item.createdAt);
      sortedItems = closedItems.concat(inProgressItems).concat(reopenItems).concat(newItems);
      return asc ? sortedItems : _reverse(sortedItems);
    case 'videoType':
      return doSimpleSorting('category', false);
    case 'createdBy':
      if (items[0]?.createdBy) return doSimpleSorting('createdBy', false);
      sortedItems = _orderBy(
        items.filter((item) => item.createdByLastName && item.createdByFirstName),
        [(item) => item?.createdByLastName?.toLowerCase(), (item) => item?.createdByFirstName?.toLowerCase()],
        [invertedSortType, invertedSortType],
      );
      return sortedItems.concat(items.filter((item) => !item.createdByLastName || !item.createdByFirstName));
    case 'videoCreatedBy':
      sortedItems = _orderBy(
        items,
        [(item) => [item.createdBy?.name?.toLowerCase()]],
      );
      return asc ? sortedItems : _reverse(sortedItems);

    case 'videoTaskName':
      sortedItems = _orderBy(
        items.filter((item) => item.taskName),
        [(item) => [item.taskName.title?.toLowerCase()]],
      );
      return asc ? sortedItems.concat(items.filter((item) => !item.taskName)) : _reverse(sortedItems).concat(items.filter((item) => !item.taskName));
    case 'videoJobRole':
      sortedItems = _orderBy(
        items.filter((item) => item.jobRole),
        [(item) => [item.jobRole.title?.toLowerCase()]],
      );
      return asc ? sortedItems.concat(items.filter((item) => !item.jobRole)) : _reverse(sortedItems).concat(items.filter((item) => !item.jobRole));
    case 'videoRisk':
      sortedItems = _orderBy(
        items.filter((item) => item.risks),
        [(item) => [item.risks.overallRisk]],
      );
      return asc ? sortedItems : _reverse(sortedItems);
    case 'companyId':
    case 'userId':
      return doSimpleSorting('id');
    case 'companyName':
      return doSimpleSorting('name', true);
    case 'region':
      return doSimpleSorting('region');
    case 'reseller':
      return doSimpleSorting('partner.name');
    case 'companyDeviceCount':
      return doSimpleSorting('deviceCount');
    case 'namesInReport':
      sortedItems = _orderBy(
        items.filter((item) => item.wearableSettings),
        [(item) => [item.wearableSettings.reportPeopleName]],
      );
      return asc ? sortedItems : _reverse(sortedItems);
    case 'canInviteUsers':
      return doSimpleSorting('canInviteUsers');
    case 'receiveNeedHelpRequests':
      return doSimpleSorting('receiveNeedHelpRequests');
    case 'isAdmin':
      return doSimpleSorting('admin');
    case 'isPartner':
      return doSimpleSorting('resellerId');
    case 'isSubscriptionManager':
      return doSimpleSorting('isSubscriptionManager');
    case 'previewAndName':
      return doSimpleSorting('title', true);
    case 'title':
      return doSimpleSorting('title', true);
    case 'number':
      return doSimpleSorting('number');
    case 'lastSeenMilliseconds':
      return doSimpleSorting('lastSeenMilliseconds');
    case 'id':
      return doSimpleSorting('id');
    case 'createdAtMilliseconds':
      return doSimpleSorting('createdAtMilliseconds');
    default: return sortedItems;
  }
}
