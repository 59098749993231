import * as Emotion from 'emotion';
import { flexColumn, ml3 } from '../../soter-flex-styles';

export const logo = Emotion.css`
  ${flexColumn};

  flex: 0 1 233px;
`;

export const logoLinkWrapper = Emotion.css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const logoText = Emotion.css`
  ${ml3};

  position: relative;
  top: 3px;

  &.isAdminArea {
    top: 0;
  }
`;
