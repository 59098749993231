import React, { useState } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import _isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';
const CustomTooltip = ({ placement, text, children, openOnClick, noTranslation, disabled, childrenStyle, }) => {
    // text or element of text array should be an i18 string or noTranslation prop should be added!
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    return (React.createElement(Whisper, { open: disabled && open, trigger: openOnClick ? 'click' : 'hover', placement: placement || 'topStart', onEnter: () => setOpen(true), onExit: () => setOpen(false), speaker: (React.createElement(Tooltip, { style: { zIndex: 9999 } }, _isArray(text)
            ? text.map((te) => React.createElement("div", { style: { textAlign: 'left' }, key: te.slice(0, 5) + Math.random() }, noTranslation ? te : t(te)))
            : noTranslation ? text : t(text))) },
        React.createElement("span", { onMouseEnter: () => setOpen(true), onMouseLeave: () => setOpen(false), style: childrenStyle }, children)));
};
export default CustomTooltip;
