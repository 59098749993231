export const TABLE_DATA_FILTER = 'table_data_filter';
export const TABLE_DATA_SEARCH = 'table_data_search';
export const TABLE_HEIGHT_CHANGE = 'table_height_change';
export const TABLE_PAGINATION_CHANGE = 'table_pagination_change';
export const TABLE_SORTING_CHANGE = 'table_sorting_change';
export const TABLE_ITEM_SELECTED = 'table_item_selected';
export const TABLE_ACTIONS_OPEN = 'table_actions_open';
export const TABLE_XLS_REPORT = 'table_xls_report';
export const TABLE_CSV_REPORT = 'table_csv_report';
export const NEED_HELP_STATUS_CHANGE = 'need_help_status_change';
export const OVERVIEW_DATA_FILTER = 'overview_data_filter';
export const INFORMATION_TOOLTIP_APPEAR = 'overview_tooltip_appears';
export const OVERVIEW_HAZARDS_PER_HOUR_GROUPING_BY_CHANGED = 'overview_hazards_per_hour_grouping_by_changed';
export const OVERVIEW_ACTIVE_USERS_GROUPING_CHANGED = 'overview_active_users_grouping_changed';
export const OVERVIEW_REGISTERED_USERS_GROUPING_CHANGED = 'overview_registered_users_grouping_changed';
export const OVERVIEW_PDF_EXPORT = 'overview_pdf_export';
export const JOB_PROFILE_ADD = 'job_profile_added';
export const JOB_PROFILE_EDIT = 'job_profile_edited';
export const JOB_PROFILE_REMOVE = 'job_profile_remove';
export const CPD_WEIGHT_UNIT_CHANGE = 'cpd_weight_unit_change';
export const TASK_PROFILE_ADD = 'task_profile_added';
export const TASK_PROFILE_EDIT = 'task_profile_edited';
export const TASK_PROFILE_REMOVE = 'task_profile_remove';
export const TASK_PROFILE_VIDEO_ADD = 'task_profile_video_add';
export const TASK_PROFILE_SOTER_VIDEO_ADD = 'task_profile_soter_video_add';
export const USER_PASSED_PROGRAMS_OPEN = 'user_passed_program_open';
export const USER_DEPARTMENT_CHANGE = 'user_department_change';
export const USER_JOB_TITLE_CHANGE = 'user_job_title_change';
export const USER_CONTINUOUS_WEARING = 'user_continuous_wearing';
export const USER_EXPORT_PDF = 'user_export_pdf';
export const USER_PDF_DOWNLOADED = 'user_pdf_downloaded';
export const USER_NOTE_CHANGED = 'user_note_changed';
export const USER_TASK_ADDED = 'user_task_added';
export const SEND_FEEDBACK = 'send_feedback';
export const CHANGE_COMPANY = 'change_company';
export const SOTER_TASK_PDF_EXPORT = 'soter_task_pdf_export';
export const SOTER_TASK_COMPARISON_PDF_EXPORT = 'soter_task_comparison_pdf_export';
export const PAGE_VIEW = 'page_view';
export const USER_INFO = 'user_info';
export const CREATE_NEW_CATEGORY = 'create_new_category';
export const CREATE_NEW_ENTRY = 'create_new_entry';
export const TASK_VIDEO_PLAYED = 'soter_task_video_played';
export const TASK_VIDEO_COMPARISON_PLAYED = 'soter_task_comparison_video_played';
export const JOB_PROFILE_VIDEO_PLAYED = 'job_profile_video_played';
export const TASK_PROFILE_VIDEO_PLAYED = 'task_profile_video_played';
export const TASK_VIDEO_PAUSED = 'soter_task_video_paused';
export const TASK_VIDEO_COMPARISON_PAUSED = 'soter_task_comparison_video_paused';
export const TASK_VIDEO_MUTED_UNMUTED = 'soter_task_video_muted_unmuted';
export const TASK_VIDEO_COMPARISON_MUTED_UNMUTED = 'soter_task_comparison_video_muted_unmuted';
export const TASK_VIDEO_SPEED_CHANGED = 'soter_task_video_speed_changed';
export const TASK_VIDEO_COMPARISON_SPEED_CHANGED = 'soter_task_comparison_video_speed_changed';
export const TASK_VIDEO_SCROLLED = 'soter_task_video_scrolled';
export const TASK_VIDEO_COMPARISON_SCROLLED = 'soter_task_comparison_video_scrolled';
export const TASK_COMPARISON_VIDEO_UPLOADED = 'soter_task_comparison_video_uploaded';
export const TASK_VIDEO_EDITED = 'soter_task_video_edited';
export const TASK_DESCRIPTION_CHANGED = 'soter_task_description_changed';
export const TASK_COMPARISON_DESCRIPTION_CHANGED = 'soter_task_comparison_description_changed';
export const TASK_VIDEO_UPLOAD = 'soter_task_video_uploaded';
export const TASK_VIDEO_DELETED = 'soter_task_video_deleted';
export const TASK_VIDEO_STATUS_CHANGED = 'soter_task_video_status_changed';
export const TASK_BODY_PARTS_SELECTOR_CHANGED = 'soter_task_bodyparts_selector_changed';
export const TASK_BODY_COMPARISON_PARTS_SELECTOR_CHANGED = 'soter_task_comparison_bodyparts_selector_changed';
export const TASK_COMPARISON_CREATED = 'soter_task_comparison_created';
export const TASK_COMPARISON_EDITED = 'soter_task_comparison_edited';
export const TASK_COMPARISON_DELETED = 'soter_task_comparison_deleted';
export const TASK_COMPARISON_GO_TO_VIDEO_BY_LINK = 'soter_task_comparison_go_to_video_by_link';
export const TASK_ASSESSMENT_CREATED = 'soter_task_assessment_created';
export const TASK_ASSESSMENT_EDITED = 'soter_task_assessment_edited';
export const ACTIVITY_USER_QR_GENERATED = 'activity_user_qr_generated';
export const ACTIVITY_USER_CREATED = 'activity_user_created';
export const ACTIVITY_SHOW_PASSED_PROGRAMS_SWITCHER_CHANGED = 'activity_show_passed_programs_switcher_changed';
export const ACTIVITY_USERS_LIST_UPLOADED = 'activity_users_list_uploaded';
export const LOGIN_ERROR = 'login_error';
export const DEVICES_USAGE_HISTORY_CHECKED = 'devices_usage_history_checked';
export const DEVICES_ENTRY_CHANGED = 'devices_entry_changed';
export const ACTIVITY_USER_ENTRY_CHANGED = 'activity_user_entry_changed';
export const USER_ENTRY_CHANGED = 'user_entry_changed';
