import React, { useState } from 'react';
import { button, deleteButton, text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { ReactComponent as Pencil } from 'assets/img/icons/pencil.svg';
import arrowRight from 'assets/img/icons/arrow-right.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TASK_COMPARISON_EDITED } from '../../constants/analyticsEvents';
import { useAppDispatch } from '../../lib/utils/useStore';
import CustomInput from '../../lib/ui/CustomInput';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import sliceLongString from '../../lib/utils/sliceLongString';
import { editButton, titleWrapper } from '../video/components/EditableVideoHeader';
import Button from '../../lib/ui/Button';
import { riskGroupColors, riskGroupNames } from '../video/constants';

const EditableComparisonHeader = ({
  saveHandler,
  title,
  setTitle,
  createdAt,
  firstRisk,
  secondRisk,
  exportDisabled,
  removeHandler,
  exportComparison,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);
  const saveButtonClickHandler = () => {
    const trimmedTitle = title.trim();
    if (!trimmedTitle) return setIsError(true);

    saveHandler(trimmedTitle);
    setIsEditing(false);
  };
  return (
    <div style={{ gridArea: 'header' }}>
      {isEditing ? (
        <>
          <div style={{ display: 'flex' }}>
            <CustomInput value={title} onChange={(_, v) => {
              if (isError) setIsError(false);
              setTitle(v);
            }} />
            <button
              type="button"
              style={{
                minWidth: '104px',
                whiteSpace: 'nowrap',
                marginLeft: '0.75rem',
              }}
              className={button(COLORS.LIGHT_BLUE[600], true)}
              onClick={() => {
                const params = {
                  edited_fields: 'title',
                };
                saveButtonClickHandler();
                window.logEvent(TASK_COMPARISON_EDITED, params);
                if (localStorage.getItem('showEvents')) {
                  dispatch(createEventNotification(`${TASK_COMPARISON_EDITED}: ${JSON.stringify(params)}`));
                }
              }}
            >
              {t('SOTER_TASK.VIDEO.SAVE_CHANGES')}
            </button>
          </div>
          {isError && <span style={{color: COLORS.RED[600]}}>{t('FORM_VALIDATION.FORM_ERROR_REQUIRED')}</span>}
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={titleWrapper}>
            {sliceLongString(title, 35)}
            <Pencil className={editButton()} onClick={() => setIsEditing(true)} />
          </div>
          {exportDisabled ? null : (
            <div style={{ display: 'flex' }}>
              <Button
                text={t('WEARABLES.ACTIVITY.ACTIONS.EXPORT')}
                handler={exportDisabled ? () => {} : exportComparison}
                style={{
                  marginRight: '12px',
                }}
              />
              <Button
                text={t('GENERAL.DELETE')}
                handler={() => removeHandler()}
                className={deleteButton}
              />
            </div>
          )}
        </div>
      )}
      <div style={{ display: 'flex' }}>
        {createdAt ? (
          <div style={{ marginRight: '7px' }} className={text(COLORS.GRAY[400], 15)}>
            {moment(createdAt, 'X').format('MMM Do YYYY')}
          </div>
        ) : null}
        {firstRisk || secondRisk ? (
          <div>
            <span className={text(riskGroupColors[firstRisk], 15)}>
              {`${t(riskGroupNames[firstRisk])}`}
            </span>
            <img width="15px" src={arrowRight} alt=">" />
            <span className={text(riskGroupColors[secondRisk], 15)}>
              {`${t(riskGroupNames[secondRisk])}`}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default EditableComparisonHeader;
