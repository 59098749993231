import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import closeIcon from 'assets/img/icons/closeModal.svg';
import { coloredButton, modalContent } from 'assets/soter-flex-styles';
import { text } from 'assets/jss/style';
import { COLORS } from '../../constants/colors';
import CustomCheckbox from '../../lib/ui/CustomCheckbox/CustomCheckbox';
import sliceLongString from '../../lib/utils/sliceLongString';
import { fuzzySearch } from '../../lib/utils/fuzzySearch';
import Loader from '../../lib/ui/Loader';
import Button from '../../lib/ui/Button';

const root = Emotion.css`
  width: 374px;
  height: 549px;
`;

const inputWrapper = Emotion.css`
  display: flex;
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  input {
    border-width: 0;
    width: 93%;
    caret-color: ${COLORS.LIGHT_BLUE[600]};
    font-size: 14px;
    padding: 17px;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
  img {
    cursor: pointer;
  }
`;

const bottomWrapper = Emotion.css`
  padding: 13px;
  border-top: 1px solid ${COLORS.GRAY[100]};
  display: flex;
  justify-content: end;
`;

const selectionWrapper = Emotion.css`
  height: 440px;
  overflow: scroll;
`;

const selectItem = Emotion.css`
  height: 49px;
  display: flex;
  padding: 10px;
`;

const checkbox = Emotion.css`
  margin-top: -5px;
`;

const SelectItem = ({
  selectionHandler, item, isSelected, type,
}) => (
  <div className={selectItem}>
    <CustomCheckbox
      checked={isSelected}
      className={checkbox}
      value={isSelected}
      onChange={() => selectionHandler(!isSelected)}
    />
    {item.thumbnail ? (
      <img
        style={{ marginRight: '9px', objectFit: 'cover' }}
        width="59px"
        src={item.thumbnail}
        alt=""
      />
    ) : (
      ''
    )}
    <div>
      <div className={text('inherit', 14)}>
        {sliceLongString(item.title, item.thumbnail ? 25 : 40)}
      </div>
      <div className={text(COLORS.GRAY[400], 10)}>{item.subTitle}</div>
    </div>
  </div>
);

const SearchableSelectableDialog = ({
  items,
  placeholder,
  buttonText,
  buttonHandler,
  closeHandler,
  isRadio,
  videoList,
  isLoading,
}) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItems, setSelectedItems] = useState([]);
  const [alreadyAddedVideos, setAlreadyAddedVideos] = useState([]);
  const [videosNeedRemove, setVideosNeedRemove] = useState([]);
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    if (videoList) {
      videoList.map((video) => setAlreadyAddedVideos((oldArray) => [...oldArray, video.id]));
    }
  }, [videoList]);
  useEffect(() => {
    setFilteredItems(
      items.filter(({ title }) => fuzzySearch(title, searchString)),
    );
  }, [searchString, items]);
  const selectionHandler = (id) => (isSelected) => {
    if (isRadio) {
      setSelectedItems([id]);
      return;
    }
    if (isSelected) {
      if (videosNeedRemove.includes(id)) {
        setVideosNeedRemove(videosNeedRemove.filter((item) => item !== id));
        setAlreadyAddedVideos((oldArray) => [...oldArray, id]);
      } else {
        const tempItems = [...selectedItems];
        tempItems.push(id);
        setSelectedItems(tempItems);
      }
    } else if (alreadyAddedVideos.includes(id)) {
      setAlreadyAddedVideos(
        alreadyAddedVideos.filter((videoId) => (videoId !== id
          ? videoId
          : setVideosNeedRemove((oldArray) => [...oldArray, videoId]))),
      );
    } else {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className={`${modalContent} ${root}`}>
      <div style={{ height: '56px' }} className={inputWrapper}>
        <input
          type="text"
          placeholder={placeholder}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <img
          style={{ width: '14px', height: '14px', marginTop: '20px' }}
          src={closeIcon}
          alt="X"
          onClick={closeHandler}
        />
      </div>
      <div className={selectionWrapper}>
        {filteredItems.map((item) => (
          <SelectItem
            key={item.value}
            item={item}
            selectionHandler={selectionHandler(item.value)}
            isSelected={
              selectedItems.includes(item.value)
              || alreadyAddedVideos.includes(item.value)
            }
          />
        ))}
      </div>
      <div className={bottomWrapper}>
        <Button
          disabled={selectedItems.length === 0 && videosNeedRemove.length === 0}
          style={{ width: 'min-content' }}
          className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
          handler={() => {
            buttonHandler(selectedItems, videosNeedRemove);
            closeHandler();
          }}
          text={buttonText}
        />
      </div>
    </div>
  );
};

export default SearchableSelectableDialog;
