import { navigate } from '@reach/router';
import { noCompanyIDRoutes, routes } from '../../containers/PrivatPageHOC/constants/routes';
import { selectCompany } from '../../company/companiesStore/actions';
export const adjustRouteWithSelectedCompany = (companyID, companyRoute, dispatch, companiesList, setIsLoading) => {
    if (companyRoute && (+companyID !== +companyRoute)) {
        setIsLoading(true);
        // eslint-disable-next-line eqeqeq
        if (companiesList.find((company) => company.id == companyRoute)) {
            dispatch(selectCompany(+companyRoute));
        }
        else if (companyRoute === routes.users || companyRoute === routes.admin) {
            navigate(window.location.pathname.replace(companyRoute, `${companyID}/${companyRoute}`));
            setIsLoading(false);
        }
        else if (typeof companyRoute === 'string' && noCompanyIDRoutes.has(companyRoute)) {
            setIsLoading(false);
        }
        else {
            navigate(window.location.pathname.replace(companyRoute.toString(), companyID.toString()));
            setIsLoading(false);
        }
    }
    else {
        setIsLoading(false);
    }
};
