export var GeniusReportType;
(function (GeniusReportType) {
    GeniusReportType[GeniusReportType["ASSESSMENT"] = 0] = "ASSESSMENT";
    GeniusReportType[GeniusReportType["RECOMMENDATIONS"] = 1] = "RECOMMENDATIONS";
    GeniusReportType[GeniusReportType["REVIEW"] = 2] = "REVIEW";
    GeniusReportType[GeniusReportType["SCREENING"] = 3] = "SCREENING";
    GeniusReportType[GeniusReportType["PRODUCTIVITY"] = 4] = "PRODUCTIVITY";
})(GeniusReportType || (GeniusReportType = {}));
export var GeniusReportFormat;
(function (GeniusReportFormat) {
    GeniusReportFormat[GeniusReportFormat["INCHAT"] = 0] = "INCHAT";
    GeniusReportFormat[GeniusReportFormat["PDF"] = 1] = "PDF";
    GeniusReportFormat[GeniusReportFormat["XLS"] = 2] = "XLS";
    GeniusReportFormat[GeniusReportFormat["XLSX"] = 3] = "XLSX";
    GeniusReportFormat[GeniusReportFormat["CSV"] = 4] = "CSV";
})(GeniusReportFormat || (GeniusReportFormat = {}));
