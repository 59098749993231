import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { TableType } from '../constants/tableType';

const initialState = Immutable({
  [TableType.coach]: {
    searchString: '',
    risks: [],
    departments: [],
    programs: [],
    statuses: [],
    dates: [],
    results: [],
    jobRoles: [],
    sites: [],
  },
  [TableType.usersUpload]: {
    searchString: '',
  },
  [TableType.devices]: {
    searchString: '',
    departments: [],
    type: [],
    wearingStatus: [],
  },
  [TableType.jobs]: {
    searchString: '',
    jobDepartments: [],
    group: [],
  },
  [TableType.help]: {
    searchString: '',
    departments: [],
    status: [],
  },
  [TableType.companies]: {
    searchString: '',
  },
  [TableType.dashboardUsers]: {
    searchString: '',
    company: [],
    createdBy: [],
    isAdmin: [],
    isPartner: [],
    isSubscriptionManager: [],
  },
  [TableType.myCompanyUsers]: {
    searchString: '',
    createdBy: [],
    canInviteUsers: [],
    canReceiveNeedHelpRequests: [],
  },
  [TableType.soterTaskVideos]: {
    searchString: '',
    status: [],
    dateOfUpload: [],
    videoCreatedBy: [],
    videoRisk: [],
  },
  [TableType.soterTaskComparison]: {
    searchString: '',
    createdBy: [],
  },
  [TableType.adminDevices]: { searchString: '' },
  [TableType.hubs]: {
    searchString: '',
    companies: [],
    hubTypes: [],
    statuses: [],
  },
  [TableType.categories]: { searchString: '' },
});

export default function reducer(state = initialState, action) {
  const typeOfFilterState = action.userTypes;
  switch (action.type) {
    case types.RESET_FILTERS_STATE: return initialState;
    case types.SET_SEARCH_STRING:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          searchString: action.string,
        },
      };
    case types.SET_RISKS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          risks: action.risks,
        },
      };
    case types.SET_INITIAL_RISKS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          initialRisks: action.initialRisks,
        },
      };
    case types.SET_DEPARTMENTS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          departments: action.departments,
        },
      };
    case types.SET_SITES:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          sites: action.sites,
        },
      };
    case types.SET_PROGRAMS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          programs: action.programs,
        },
      };
    case types.SET_STATUSES:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          statuses: action.statuses,
        },
      };
    case types.SET_DATES:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          dates: action.dates,
        },
      };
    case types.SET_RESULTS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          results: action.results,
        },
      };
    case types.SET_GROUP:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          group: action.group,
        },
      };
    case types.SET_JOBS_DEPARTMENTS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          jobDepartments: action.departments,
        },
      };
    case types.SET_JOB_ROLES:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          jobRoles: action.jobRoles,
        },
      };
    case types.SET_TYPES:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          types: action.activeTypes,
        },
      };
    case types.SET_TYPE:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          type: action.userType,
        },
      };
    case types.SET_WEARING_STATUS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          wearingStatus: action.wearingStatus,
        },
      };
    case types.SET_HELP_STATUS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          status: action.statuses,
        },
      };
    case types.SET_CLIP_PROGRAMS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          clipPrograms: action.programs,
        },
      };
    case types.SET_REGIONS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          regions: action.regions,
        },
      };
    case types.SET_RESELLERS:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          resellers: action.resellers,
        },
      };
    case types.SET_CREATED_BY:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          createdBy: action.createdBy,
        },
      };
    case types.SET_COMPANIES_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          companies: action.companies,
        },
      };
    case types.SET_CAN_INVITE_USERS_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          canInviteUsers: action.canInviteUsers,
        },
      };
    case types.SET_CAN_RECEIVE_NEEDHELP_REQUESTS_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          canReceiveNeedHelpRequests: action.canReceiveNeedHelpRequests,
        },
      };
    case types.SET_IS_ADMIN_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          isAdmin: action.isAdmin,
        },
      };
    case types.SET_IS_PARTNER_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          isPartner: action.isPartner,
        },
      };
    case types.SET_IS_SUBSCRIPTION_MANAGER_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          isSubscriptionManager: action.isSubscriptionManager,
        },
      };
    case types.SET_VIDEO_STATUS_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          status: action.videoStatus,
        },
      };
    case types.SET_VIDEO_CREATED_BY_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          videoCreatedBy: action.createdBy,
        },
      };
    case types.SET_VIDEO_JOB_ROLE_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          videoJobRole: action.videoJobRole,
        },
      };
    case types.SET_VIDEO_TASK_NAME_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          videoTaskName: action.videoTaskName,
        },
      };
    case types.SET_VIDEO_RISK_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          videoRisk: action.videoRisk,
        },
      };
    case types.SET_COMPARISON_CREATED_BY_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          createdBy: action.createdBy,
        },
      };
    case types.SET_HUB_STATUS_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          hubStatus: action.statuses,
        },
      };
    case types.SET_HUB_TYPE_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          hubTypes: action.hubTypes,
        },
      };
    case types.SET_HUB_COMPANIES_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          hubCompanies: action.hubCompanies,
        },
      };
    case types.VIDEO_TYPE_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          videoType: action.videoType,
        },
      };
    case types.VIDEO_CATEGORY_FILTER:
      return {
        ...state,
        [typeOfFilterState]: {
          ...state[typeOfFilterState],
          videoCategory: action.videoCategory,
        },
      };
    case types.SET_PAGE:
      const newState = JSON.parse(JSON.stringify(state));
      newState.pagination[typeOfFilterState].currentPage = action.page;
      return newState;
    default: return state;
  }
}
