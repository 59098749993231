const FMTable = [
  [1, 1, 0.95, 0.95, 0.85, 0.85],
  [0.97, 0.97, 0.92, 0.92, 0.81, 0.81],
  [0.94, 0.94, 0.88, 0.88, 0.75, 0.75],
  [0.91, 0.91, 0.84, 0.84, 0.65, 0.65],
  [0.88, 0.88, 0.79, 0.79, 0.55, 0.55],
  [0.84, 0.84, 0.72, 0.6, 0.45, 0.45],
  [0.8, 0.8, 0.6, 0.5, 0.35, 0.35],
  [0.75, 0.75, 0.5, 0.42, 0.27, 0.27],
  [0.7, 0.7, 0.42, 0.35, 0.22, 0.22],
  [0.6, 0.6, 0.35, 0.3, 0.18, 0.18],
  [0.52, 0.52, 0.3, 0.3, 0, 0.15],
  [0.45, 0.45, 0.26, 0.26, 0, 0.13],
  [0.41, 0.41, 0, 0.23, 0, 0],
  [0.37, 0.37, 0, 0.21, 0, 0],
  [0, 0.34, 0, 0, 0, 0],
  [0, 0.31, 0, 0, 0, 0],
  [0, 0.28, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
];

const CMTable = [
  [1, 1],
  [0.95, 1],
  [0.9, 0.9],
];

const getFMRowIndex = (F) => {
  if (F <= 0.2) return 0;
  if (F <= 0.5) return 1;
  if (F <= 1) return 2;
  if (F > 15) return 17;
  return Math.ceil(F) + 1;
};

const getFMColumnIndex = (time, V, weightUnit) => {
  const indexes = [[0, 1], [2, 3], [4, 5]];
  const vPosition = weightUnit === 'lb' ? (V <= 30 ? 0 : 1) : (V <= 75 ? 0 : 1);
  return indexes[time][vPosition];
};

const calculateNiosh = (getFieldValue, weightUnit) => {
  const isControlRequired = !!getFieldValue('control_required');
  const horizontalOrigin = getFieldValue('horizontal');
  const horizontalDestination = getFieldValue('horizontal_destination');
  const verticalOrigin = getFieldValue('vertical_origin');
  const verticalDestination = getFieldValue('vertical_destination');
  const asymmetryOrigin = getFieldValue('asymmetry');
  const asymmetryDestination = getFieldValue('asymmetry_destination');
  const frequency = getFieldValue('frequency');
  const duration = getFieldValue('duration');
  const coupling = getFieldValue('coupling');
  const averageLoad = getFieldValue('avg_load');
  const LC = weightUnit === 'lb' ? 51 : 23;
  const HMO = weightUnit === 'lb' ? 10 / horizontalOrigin : 25 / horizontalOrigin;
  const HMD = weightUnit === 'lb' ? 10 / horizontalDestination : 25 / horizontalDestination;
  const VMO = weightUnit === 'lb' ? 1 - (0.0075 * Math.abs(verticalOrigin - 30)) : 1 - (0.003 * Math.abs(verticalOrigin - 75));
  const VMD = weightUnit === 'lb' ? 1 - (0.0075 * Math.abs(verticalDestination - 30)) : 1 - (0.003 * Math.abs(verticalDestination - 75));
  const forDm = weightUnit === 'lb' ? (1.8 / Math.abs(verticalDestination - verticalOrigin)) : (4.5 / Math.abs(verticalDestination - verticalOrigin));
  const DM = 0.82 + (forDm !== Infinity ? forDm : 0);
  const AMO = 1 - (0.0032 * asymmetryOrigin);
  const AMD = 1 - (0.0032 * asymmetryDestination);
  const FMO = FMTable[getFMRowIndex(frequency)][getFMColumnIndex(duration, verticalOrigin, weightUnit)];
  const FMD = FMTable[getFMRowIndex(frequency)][getFMColumnIndex(duration, verticalOrigin, weightUnit)];
  const CMO = CMTable[coupling][weightUnit === 'lb' ? (verticalOrigin <= 30 ? 0 : 1) : (verticalOrigin <= 75 ? 0 : 1)];
  const CMD = CMTable[coupling][weightUnit === 'lb' ? (verticalDestination <= 30 ? 0 : 1) : (verticalDestination <= 75 ? 0 : 1)];
  const RWLO = LC * HMO * VMO * DM * AMO * FMO * CMO;
  const RWLD = LC * HMD * VMD * DM * AMD * FMD * CMD;
  const LIO = averageLoad / RWLO;
  const LID = averageLoad / RWLD;
  return [LIO, isControlRequired ? LID : 0];
};

export default calculateNiosh;
