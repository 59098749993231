var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toCamel } from 'snake-camel';
import { getCompanies } from '../../company/companiesStore/actions';
import { adminServiceV2, availableProducts, createCompanyRequest, deleteAdminCompanyRequest, editCompanyRequest, getAvailableCoachingProgramsRequest, getAvailableFirmwaresRequest, getAvailableLanguagesRequest, getAvailableTiersRequest, getCompaniesRequest, getCompanyInfoRequest, getMetaData, productInfoWearablesSettings, productInfoGeniusSettings, productInfoJobDictionarySettings, taskSettings, typesWearingDevice, wearableSettings, } from '../../init/proto_client';
import { setNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import { currentCompanyIdSelector } from './selectors';
import { notificationMessages, notificationTypes } from '../../containers/NotificationContainer/notifications';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
const pending = (dispatch) => dispatch({
    type: 'adminCompanies/pending',
    payload: true,
});
const rejected = (dispatch, message) => dispatch({
    type: 'adminCompanies/rejected',
    payload: message,
});
export const getAdminCompanies = createAsyncThunk('adminCompanies/getAdminCompanies', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    getCompaniesRequest.setDummy(true);
    yield adminServiceV2.getCompanies(getCompaniesRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const { companiesList } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/getAdminCompanies/fulfilled',
                payload: companiesList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const getAvailableFirmwares = createAsyncThunk('adminCompanies/getAvailableFirmwares', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    yield adminServiceV2.getAvailableFirmwares(getAvailableFirmwaresRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const { availableFirmwaresList } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/getAvailableFirmwares/fulfilled',
                payload: availableFirmwaresList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const getAvailableTiers = createAsyncThunk('adminCompanies/getAvailableTiers', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    yield adminServiceV2.getAvailableTiers(getAvailableTiersRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            // const { availableFirmwaresList }: any = toCamel(response.toObject());
            const { availableTiersList } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/getAvailableTiers/fulfilled',
                payload: availableTiersList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const getAvailableCoachingPrograms = createAsyncThunk('adminCompanies/getAvailableCoachingPrograms', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    yield adminServiceV2.getAvailableCoachingPrograms(getAvailableCoachingProgramsRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const { availableCoachingProgramsList } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/getAvailableCoachingPrograms/fulfilled',
                payload: availableCoachingProgramsList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const getAvailableLanguages = createAsyncThunk('adminCompanies/getAvailableLanguages', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    yield adminServiceV2.getAvailableLanguages(getAvailableLanguagesRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const { availableLanguages } = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/getAvailableLanguages/fulfilled',
                payload: availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.languagesList,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
const modifyAdminCompanyRequest = (request, companyData, additionalInfo) => {
    // --> availableProducts <--
    if (additionalInfo) {
        productInfoWearablesSettings.setIsAvailable(additionalInfo.wearable.isAvailable);
        productInfoWearablesSettings.setTimeStart(additionalInfo.wearable.timeStart);
        productInfoWearablesSettings.setTimeEnd(additionalInfo.wearable.timeEnd);
        productInfoJobDictionarySettings.setIsAvailable(additionalInfo.jobDictionary.isAvailable);
        productInfoJobDictionarySettings.setTimeStart(additionalInfo.jobDictionary.timeStart);
        productInfoJobDictionarySettings.setTimeEnd(additionalInfo.jobDictionary.timeEnd);
        productInfoGeniusSettings.setIsAvailable(additionalInfo.genius.isAvailable);
        productInfoGeniusSettings.setTimeStart(additionalInfo.genius.timeStart);
        productInfoGeniusSettings.setTimeEnd(additionalInfo.genius.timeEnd);
    }
    else {
        productInfoWearablesSettings.setIsAvailable(companyData.wearable.isAvailable);
        productInfoWearablesSettings.setTimeStart(companyData.wearable.timeStart);
        productInfoWearablesSettings.setTimeEnd(companyData.wearable.timeEnd);
        productInfoJobDictionarySettings.setIsAvailable(companyData.jobDictionary.isAvailable);
        productInfoJobDictionarySettings.setTimeStart(companyData.jobDictionary.timeStart);
        productInfoJobDictionarySettings.setTimeEnd(companyData.jobDictionary.timeEnd);
        productInfoGeniusSettings.setIsAvailable(companyData.genius.isAvailable);
        productInfoGeniusSettings.setTimeStart(companyData.genius.timeStart);
        productInfoGeniusSettings.setTimeEnd(companyData.genius.timeEnd);
    }
    availableProducts.setWearable(productInfoWearablesSettings);
    availableProducts.setJobDictionary(productInfoJobDictionarySettings);
    availableProducts.setGenius(productInfoGeniusSettings);
    // --> wearableSettings <--
    typesWearingDevice.setSpine(companyData.spine);
    typesWearingDevice.setShoulder(companyData.shoulder);
    typesWearingDevice.setHeadset(companyData.headset);
    typesWearingDevice.setHelmet(companyData.helmet);
    wearableSettings.setTypeWearing(typesWearingDevice);
    wearableSettings.setAvailableCoachingProgramIdsList((additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.availableCoachingProgramIdsList)
        ? additionalInfo.availableCoachingProgramIdsList
        : companyData.availableCoachingProgramIdsList);
    wearableSettings.setFirmwareId(companyData.firmwareId ? companyData.firmwareId : additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.firmwareId);
    wearableSettings.setInputLoginMethod(companyData.inputLoginMethod);
    wearableSettings.setTierId(companyData.tierId);
    wearableSettings.setEmployeeLoginPrompt(companyData.employeeLoginPrompt);
    wearableSettings.setWorkdayTimeMinutes(companyData.workdayTimeMinutes);
    wearableSettings.setWorkdayIntervalMinutes(companyData.workdayIntervalMinutes);
    wearableSettings.setProgramTargetDays(Number(companyData.programTargetDays));
    wearableSettings.setCollectJobRoleOnRegister(companyData.collectJobRoleOnRegister);
    wearableSettings.setReportPeopleNames(companyData.reportPeopleName);
    wearableSettings.setCollectEmployeeName(companyData.collectEmployeeName);
    wearableSettings.setAvailableLanguagesList(companyData.availableLanguagesList.length
        ? companyData.availableLanguagesList
        : additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.availableLanguagesList);
    wearableSettings.setNicknameRegexpTemplate(companyData.nicknameRegexpTemplate);
    wearableSettings.setDisableNotifications(companyData.disableNotifications);
    wearableSettings.setDownloadDataNotOnlyFromDevicesOnCharge(companyData.downloadDataNotOnlyFromDevicesOnCharge);
    wearableSettings.setShowOnboardingPages(companyData.showOnboardingPages);
    wearableSettings.setEnableGamification(companyData.enableGamification);
    // --> taskSettings <--
    taskSettings.setVideoDuration(companyData.videoDuration * 60000);
    taskSettings.setBlurFaceEnable(companyData.blurFaceEnable);
    taskSettings.setBlurBackgroundEnable(companyData.blurBackgroundEnable);
    taskSettings.setOfficeAssessment(companyData.officeAssessment);
    // --> request <--
    if (companyData.id)
        request.setId(companyData.id);
    request.setName(companyData.name);
    request.setRegion(companyData.region);
    request.setAvailableProducts(availableProducts);
    request.setWearableSettings(wearableSettings);
    request.setSoterTaskSettings(taskSettings);
};
export const createAdminCompany = createAsyncThunk('adminCompanies/createAdminCompany', ({ companyData }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    modifyAdminCompanyRequest(createCompanyRequest, companyData);
    yield adminServiceV2.createCompany(createCompanyRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const company = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/createAdminCompany/fulfilled',
                payload: company,
            });
            thunkAPI.dispatch(getCompanies());
            thunkAPI.dispatch(setOpenedModal(null));
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.CREATE_ADMIN_COMPANY_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.CREATE_ADMIN_COMPANY_FAILED_MSG,
            }));
        }
    }));
}));
export const getCompanyData = createAsyncThunk('adminCompanies/getCompanyData', (companyId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    getCompanyInfoRequest.setCompanyId(companyId);
    yield adminServiceV2.getCompanyInfo(getCompanyInfoRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const companyInfo = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'adminCompanies/getCompanyData/fulfilled',
                payload: companyInfo,
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.GENERIC_ERROR,
            }));
        }
    }));
}));
export const editAdminCompany = createAsyncThunk('adminCompanies/editAdminCompany', ({ companyData, additionalInfo }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    pending(thunkAPI.dispatch);
    modifyAdminCompanyRequest(editCompanyRequest, companyData, additionalInfo);
    yield adminServiceV2.editCompany(editCompanyRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const company = toCamel(response.toObject());
            thunkAPI.dispatch(getCompanyData(company.id));
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.UPDATE_ADMIN_COMPANY_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.UPDATE_ADMIN_COMPANY_FAILED_MSG,
            }));
        }
    }));
}));
export const deleteAdminCompany = createAsyncThunk('adminCompanies/deleteAdminCompany', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = thunkAPI.getState();
    const currentCompanyId = currentCompanyIdSelector(state);
    deleteAdminCompanyRequest.setCompanyId(currentCompanyId);
    yield adminServiceV2.deleteCompany(deleteAdminCompanyRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch({
                type: 'adminCompanies/deleteAdminCompany/fulfilled',
                payload: currentCompanyId,
            });
            thunkAPI.dispatch(setOpenedModal(null));
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.DELETE_ADMIN_COMPANY_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error.message);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.DELETE_ADMIN_COMPANY_FAILED_MSG,
            }));
        }
    }));
}));
