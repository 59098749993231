import React from 'react';
import { textAppearanceAndWeight } from '../../assets/jss/components/text';
const Text = ({ text, appearance, weight, className, style, underline, inline, iconBeforeText = false, iconAfterText = false, key, dataName, children, }) => {
    const customizedStyle = underline && style
        ? Object.assign(Object.assign({}, style), { textDecoration: 'underline' }) : underline
        ? { textDecoration: 'underline' }
        : style;
    return !inline ? (React.createElement("p", { className: `${textAppearanceAndWeight(appearance, weight)} ${className}`, style: customizedStyle, key: key && key, "data-name": dataName && dataName },
        React.createElement(React.Fragment, null,
            children && iconBeforeText && children,
            text,
            children && iconAfterText && children))) : (React.createElement("span", { className: `${textAppearanceAndWeight(appearance, weight)} ${className}`, style: customizedStyle, key: key && key, "data-name": dataName && dataName },
        React.createElement(React.Fragment, null,
            children && iconBeforeText && children,
            text,
            children && iconAfterText && children)));
};
export default Text;
