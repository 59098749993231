import React from 'react';
import { centeredIconButton, invisibleButton } from '../../../assets/soter-flex-styles';
import { ReactComponent as TrashIcon } from '../../../assets/img/icons/red-trash.svg';
import { ReactComponent as PencilIcon } from '../../../assets/img/icons/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/x-circle-default-width.svg';
import { ReactComponent as SaveIcon } from '../../../assets/img/icons/save.svg';

const getIcon = (type) => {
  switch (type) {
    case "remove":
      return <TrashIcon />;
    case "edit":
      return <PencilIcon />;
    case "saveChanges":
      return <SaveIcon />;
    case "cancel":
      return <CloseIcon />;
    default:
      return <TrashIcon />;
  }
};

const ActionButton = ({ type, onClick }) => (
  <button
    className={`${centeredIconButton} ${invisibleButton("small")}`}
    type='button'
    onClick={onClick}
  >
    {getIcon(type)}
  </button>
);

export default ActionButton;
