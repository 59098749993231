import React, { useRef, useState } from 'react';
import { COLORS } from 'constants/colors';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import { bodyParts, productTitle, productTitleTop, programsWrapper, sectionWrapper, wearablesWrapper, } from './styles';
import { accordionHandler, loginMethods, selectedDefaultBodyPart } from '../utils';
import CustomCheckbox from '../../../lib/ui/CustomCheckbox/CustomCheckbox';
import CustomSelect from '../../../lib/ui/CustomSelect';
import CustomInput from '../../../lib/ui/CustomInput';
import useOutsideClickHandler from '../../../lib/utils/useOutsideClickHandler';
import { sortByLabel } from '../../companies/company/utils/createCompanyFilters';
import { text } from '../../../assets/jss/style';
import wearingTypeEnum from '../../../constants/wearingTypesEnum';
import { getSimpleSelectOptions } from '../../../lib/utils/getSimpleSelectOptions';
const Wearables = ({ company, editMode, firmwares, tierOptions, coachingPrograms, languages, showProgramsError, setShowProgramsError, selectedPrograms, setSelectedPrograms, formik, t, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [openModal, setOpenModal] = useState(false);
    const [isOpenAccordion, setIsOpenAccordion] = useState(true);
    const ref = useRef(null);
    const valuePrograms = selectedPrograms === null || selectedPrograms === void 0 ? void 0 : selectedPrograms.map((program) => program.value);
    const availableCoachingPrograms = coachingPrograms && getSimpleSelectOptions(coachingPrograms === null || coachingPrograms === void 0 ? void 0 : coachingPrograms.filter(({ deviceType }) => (deviceType !== 'armband' && formik.values.spine)
        || (deviceType === 'armband' && formik.values.shoulder)
        || formik.values[deviceType]));
    useOutsideClickHandler(ref, () => {
        if (openModal)
            setOpenModal(false);
    });
    const onProgramSelected = (value, selected, label) => {
        if (showProgramsError && setShowProgramsError)
            setShowProgramsError(false);
        if (selectedPrograms && setSelectedPrograms) {
            if (selected) {
                setSelectedPrograms([...selectedPrograms, { value, label }]);
            }
            else {
                setSelectedPrograms(selectedPrograms.filter((program) => program.value !== value));
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: sectionWrapper, onClick: () => accordionHandler(isOpenAccordion, setIsOpenAccordion) },
            React.createElement("h3", { className: text(COLORS.GRAY[950], 24, 800) }, t('ADMIN.WEARABLES_CONFIGURATION')),
            React.createElement(ArrowDownLineIcon, { className: isOpenAccordion ? '' : 'hidden' })),
        editMode ? (React.createElement("div", { className: `${wearablesWrapper} ${isOpenAccordion ? '' : 'hidden'}` },
            React.createElement("div", { className: "item__large" },
                React.createElement("h3", { className: productTitleTop, style: { width: '100%' } }, t('SOTER_TASK.RISKS.GENERAL')),
                React.createElement("div", { className: "left" },
                    React.createElement("h4", { className: productTitle }, "Wearing types"),
                    React.createElement("div", { className: bodyParts }, Object.entries(wearingTypeEnum).map(([key, value], idx, arr) => (React.createElement(React.Fragment, { key: key },
                        React.createElement(CustomCheckbox, { label: value[0].toUpperCase() + value.substring(1), name: value, onChange: (name, isChecked) => {
                                if (setShowProgramsError && coachingPrograms && selectedPrograms && setSelectedPrograms) {
                                    selectedDefaultBodyPart(name, isChecked, setShowProgramsError, coachingPrograms, selectedPrograms, setSelectedPrograms);
                                }
                            }, formik: formik }),
                        idx !== arr.length - 1 && React.createElement("div", { className: "border__bottom" }))))),
                    React.createElement("h4", { className: productTitle }, "Wearing details"),
                    React.createElement("div", { className: "info__wrapper" },
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, t('ADMIN.FIRMWARE', 'Firmware')),
                            React.createElement(CustomSelect, { name: "firmwareId", options: firmwares, defaultValue: firmwares && firmwares[firmwares.length - 1], isSearchable: true, isClearable: true, formik: formik })),
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, t('ADMIN.TIER', 'Tier')),
                            React.createElement(CustomSelect, { name: "tierId", options: tierOptions, isSearchable: true, isClearable: true, formik: formik })),
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, t('ADMIN.LOGIN_PROMPT')),
                            React.createElement(CustomInput, { name: "employeeLoginPrompt", placeholder: "Enter your Team Member ID", formik: formik })),
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, `${t('ADMIN.WORKDAY_LENGTH')} (${t('ADMIN.MIN')})`),
                            React.createElement(CustomInput, { type: "int", min: 0, max: 1000, name: "workdayTimeMinutes", placeholder: "Enter the number of minutes", formik: formik })),
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, `${t('ADMIN.INTERVAL_LENGTH')} (${t('ADMIN.MIN')})`),
                            React.createElement(CustomInput, { type: "int", min: 0, max: 1000, name: "workdayIntervalMinutes", placeholder: "Enter the number of minutes", formik: formik })),
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, `${t('ADMIN.PROGRAM_LENGTH')} (${t('ADMIN.WORKDAYS')})`),
                            React.createElement(CustomInput, { type: "int", min: 0, max: 1000, name: "programTargetDays", placeholder: "Target days", formik: formik })),
                        React.createElement("div", { className: "item item__edit" },
                            React.createElement("label", null, t('ADMIN.LOGIN_TEMPLATE', 'Login template (advanced)')),
                            React.createElement(CustomInput, { name: "nicknameRegexpTemplate", placeholder: "Enter login RegExp", formik: formik })))),
                React.createElement("div", { className: "right" },
                    React.createElement("h4", { className: productTitle }, t('WEARABLES.ACTIVITY.PROGRAMS')),
                    React.createElement("div", { className: `${programsWrapper} edit` },
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "info__wrapper info__wrapper--gap" },
                                React.createElement("h4", { className: "info__title" }, `${valuePrograms === null || valuePrograms === void 0 ? void 0 : valuePrograms.length} ${t('ADMIN.PROGRAMS_SELECTED', 'Programs selected')}`),
                                React.createElement("button", { type: "button", className: "change__btn", onClick: () => setOpenModal(!openModal), disabled: !availableCoachingPrograms.length }, t('NOTIFICATIONS.CHANGE', 'Change')))),
                        React.createElement("div", { className: "right" }, openModal && (React.createElement("div", { className: "programs__modal", ref: ref }, (_a = sortByLabel(availableCoachingPrograms)) === null || _a === void 0 ? void 0 : _a.map((program) => (React.createElement(CustomCheckbox, { key: program.value, label: program.label, name: program.value, checked: valuePrograms === null || valuePrograms === void 0 ? void 0 : valuePrograms.includes(program.value), onChange: onProgramSelected })))))),
                        React.createElement("ul", { className: "list", style: { width: '100%' } }, (selectedPrograms === null || selectedPrograms === void 0 ? void 0 : selectedPrograms.length) ? selectedPrograms.map((program, idx, array) => React.createElement("li", { key: program.value }, array.length - 1 === idx ? program.label : `${program.label},`))
                            : React.createElement("li", { className: "info__text" }, t('ADMIN.WEARING_TYPES'))),
                        showProgramsError && React.createElement("div", { className: "programsError" }, t('ADMIN.CHOOSE_PROGRAM'))),
                    React.createElement("h4", { className: productTitle }, t('ADMIN.LOGIN_METHOD', 'Login Method')), loginMethods === null || loginMethods === void 0 ? void 0 :
                    loginMethods.map((method) => (React.createElement("label", { style: { marginRight: 15 }, key: method.value },
                        React.createElement("input", { type: "radio", name: "inputLoginMethod", value: method.value, checked: Number(formik.values.inputLoginMethod) === method.value, onChange: formik.handleChange }),
                        React.createElement("span", { style: { marginLeft: 8 } }, method.label.replace(/Only\s+/i, ''))))),
                    React.createElement("h4", { className: productTitle }, t('ADMIN.OTHER')),
                    React.createElement("div", { style: { marginLeft: -7 } },
                        React.createElement(CustomCheckbox, { label: t('ADMIN.COLLECT_JOB_ROLE'), name: "collectJobRoleOnRegister", formik: formik })),
                    React.createElement("div", { style: { marginLeft: -7 } },
                        React.createElement(CustomCheckbox, { label: t('ADMIN.REPORT_PEOPLE_NAME', 'Report people name'), name: "reportPeopleName", formik: formik })),
                    React.createElement("div", { style: { marginLeft: -7 } },
                        React.createElement(CustomCheckbox, { label: t('ADMIN.COLLECT_FIRST_NAME'), name: "collectEmployeeName", formik: formik })))),
            React.createElement("div", { className: "item__large" },
                React.createElement("h3", { className: productTitleTop, style: { width: '100%' } }, "Hub settings"),
                React.createElement("div", { className: "left" },
                    React.createElement("h4", { className: productTitle }, t('ADMIN.LANGUAGES')),
                    React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', alignItems: 'center' } }, languages && ((_b = [...languages]) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.value.localeCompare(b.value)).map((language) => {
                        var _a, _b;
                        return (React.createElement("label", { style: { marginRight: 15, marginBottom: 10 }, key: language.key },
                            React.createElement("input", { type: "checkbox", name: "availableLanguagesList", value: language.key, checked: (_a = formik.values.availableLanguagesList) === null || _a === void 0 ? void 0 : _a.includes(language.key), onChange: formik.handleChange, disabled: language.key === 'en' && ((_b = formik.values.availableLanguagesList) === null || _b === void 0 ? void 0 : _b.includes('en')) }),
                            React.createElement("span", { style: { marginLeft: 8 } }, language.value)));
                    })))),
                React.createElement("div", { className: "right" },
                    React.createElement("h4", { className: productTitle }, t('ADMIN.OTHER')),
                    React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } },
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.SHOW_ONBOARDING', 'Show onboarding'), name: "showOnboardingPages", formik: formik })),
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.DISABLE_NOTIFICATIONS', 'Disable notifications on the devices'), name: "disableNotifications", formik: formik })),
                        React.createElement(CustomCheckbox, { label: t('ADMIN.ENABLE_DOWNLOAD_DATA', 'Enable downloading of data when devices are not on charge'), name: "downloadDataNotOnlyFromDevicesOnCharge", formik: formik })))))) : (React.createElement("div", { className: `${wearablesWrapper} ${isOpenAccordion ? '' : 'hidden'}` },
            React.createElement("div", { className: "item__large" },
                React.createElement("h3", { className: productTitleTop, style: { width: '100%' } }, t('SOTER_TASK.RISKS.GENERAL')),
                React.createElement("div", { className: "left" },
                    React.createElement("h4", { className: productTitle }, "Wearing types"),
                    React.createElement("div", { className: bodyParts }, Object.entries(wearingTypeEnum).map(([key, value], idx, arr) => (React.createElement(React.Fragment, { key: key },
                        React.createElement(CustomCheckbox, { label: value[0].toUpperCase() + value.substring(1), checked: company[value], disabled: true }),
                        idx !== arr.length - 1 && React.createElement("div", { className: "border__bottom" }))))),
                    React.createElement("h4", { className: productTitle }, "Wearing details"),
                    React.createElement("div", { className: "info__wrapper" },
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.FIRMWARE', 'Firmware')),
                            React.createElement("div", { className: "title" }, company.firmwareId && /^(.*?),/.exec((_c = company.firmwareId) === null || _c === void 0 ? void 0 : _c.label)[1])),
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.TIER', 'Tier')),
                            React.createElement("div", { className: "title" }, (_d = company.tierId) === null || _d === void 0 ? void 0 : _d.label)),
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.LOGIN_PROMPT', 'Employee login prompt')),
                            React.createElement("div", { className: "title" }, company.employeeLoginPrompt ? company.employeeLoginPrompt : t('ADMIN.NOT_SPECIFIED'))),
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.WORKDAY_LENGTH')),
                            React.createElement("div", { className: "title" }, `${company.workdayTimeMinutes} ${t('ADMIN.MIN')}`)),
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.INTERVAL_LENGTH', 'Interval length')),
                            React.createElement("div", { className: "title" }, `${company.workdayIntervalMinutes} ${t('ADMIN.MIN')}`)),
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.PROGRAM_LENGTH', 'Program target length')),
                            React.createElement("div", { className: "title" }, `${company.programTargetDays} ${t('ADMIN.WORKDAYS')}`)),
                        React.createElement("div", { className: "item" },
                            React.createElement("div", { className: "subtitle" }, t('ADMIN.LOGIN_TEMPLATE', 'Login template')),
                            React.createElement("div", { className: "title" }, company.nicknameRegexpTemplate ? company.nicknameRegexpTemplate : t('ADMIN.NOT_SPECIFIED'))))),
                React.createElement("div", { className: "right" },
                    React.createElement("h4", { className: productTitle }, t('WEARABLES.ACTIVITY.PROGRAMS')),
                    React.createElement("div", { className: `${programsWrapper} edit` },
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "info__wrapper info__wrapper--gap" },
                                React.createElement("h4", { className: "info__title" }, `${(_e = company.availableCoachingProgramIdsList) === null || _e === void 0 ? void 0 : _e.length} ${t('ADMIN.PROGRAMS_SELECTED', 'Programs selected')}`))),
                        React.createElement("ul", { className: "list", style: { width: '100%' } }, (_f = company.availableCoachingProgramIdsList) === null || _f === void 0 ? void 0 : _f.map((program, idx, array) => (React.createElement("li", { key: program.value }, array.length - 1 === idx ? program.label : `${program.label},`))))),
                    React.createElement("h4", { className: productTitle }, t('ADMIN.LOGIN_METHOD', 'Login Method')), loginMethods === null || loginMethods === void 0 ? void 0 :
                    loginMethods.map((method) => (React.createElement("label", { style: { marginRight: 15 }, key: method.value },
                        React.createElement("input", { type: "radio", value: method.value, checked: company.inputLoginMethod === method.value, disabled: true }),
                        React.createElement("span", { style: { marginLeft: 8 } }, method.label.replace(/Only\s+/i, ''))))),
                    React.createElement("h4", { className: productTitle }, t('ADMIN.OTHER')),
                    React.createElement("div", { style: { marginLeft: -7 } },
                        React.createElement(CustomCheckbox, { label: t('ADMIN.COLLECT_JOB_ROLE'), checked: company.collectJobRoleOnRegister, disabled: true })),
                    React.createElement("div", { style: { marginLeft: -7 } },
                        React.createElement(CustomCheckbox, { label: t('ADMIN.REPORT_PEOPLE_NAME', 'Report people name'), checked: company.reportPeopleName, disabled: true })),
                    React.createElement("div", { style: { marginLeft: -7 } },
                        React.createElement(CustomCheckbox, { label: t('ADMIN.COLLECT_FIRST_NAME'), checked: company.collectEmployeeName, disabled: true })))),
            React.createElement("div", { className: "item__large" },
                React.createElement("h3", { className: productTitleTop, style: { width: '100%' } }, "Hub settings"),
                React.createElement("div", { className: "left" },
                    React.createElement("h4", { className: productTitle }, t('ADMIN.LANGUAGES')),
                    React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', alignItems: 'center' } }, languages && ((_g = [...languages]) === null || _g === void 0 ? void 0 : _g.sort((a, b) => a.value.localeCompare(b.value)).map((language) => {
                        var _a;
                        return (React.createElement("label", { style: { marginRight: 15, marginBottom: 10 }, key: language.key },
                            React.createElement("input", { type: "checkbox", checked: ((_a = company.availableLanguagesList) === null || _a === void 0 ? void 0 : _a.includes(language.key)) || language.key === 'en', disabled: true }),
                            React.createElement("span", { style: { marginLeft: 8 } }, language.value)));
                    })))),
                React.createElement("div", { className: "right" },
                    React.createElement("h4", { className: productTitle }, t('ADMIN.OTHER')),
                    React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } },
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.SHOW_ONBOARDING', 'Show onboarding'), checked: company.showOnboardingPages, disabled: true })),
                        React.createElement("div", null,
                            React.createElement(CustomCheckbox, { label: t('ADMIN.DISABLE_NOTIFICATIONS', 'Disable notifications on the devices'), checked: company.disableNotifications, disabled: true })),
                        React.createElement(CustomCheckbox, { label: t('ADMIN.ENABLE_DOWNLOAD_DATA', 'Enable downloading of data when devices are not on charge'), checked: company.downloadDataNotOnlyFromDevicesOnCharge, disabled: true }))))))));
};
export default Wearables;
