import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/icons/close.svg';
import {
  deleteButton,
  edited,
  flexSpaceBetween,
  task,
  taskTitle,
} from '../../../../assets/jss/components/addDayTasksDialog';
import Tooltip from '../../../../lib/ui/Tooltip';
import sliceLongString from '../../../../lib/utils/sliceLongString';

const Task = ({
  id,
  taskName,
  timeStart,
  timeEnd,
  isEdited,
  onEdit,
  onRemove,
}) => {
  const handleEdit = () => {
    onEdit(id);
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove(id);
  };

  const renderTaskName = () => (taskName.length >= 20
    ? <Tooltip text={taskName}><span>{sliceLongString(taskName, 20)}</span></Tooltip>
    : taskName);

  return (
    <div className={`${task} ${flexSpaceBetween} ${isEdited ? edited : ''}`} onClick={handleEdit}>
      <div className={taskTitle}>
        {renderTaskName()}
      </div>
      <div className={flexSpaceBetween}>
        <div className={taskTitle}>{`${timeStart} - ${timeEnd}`}</div>
        <button
          className={deleteButton}
          type="button"
          onClick={handleRemove}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default Task;
