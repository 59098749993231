import { Circle, Group, Text } from 'react-konva';
import React from 'react';
import { COLORS } from '../../../../constants/colors';

const DotedLine = ({
  width, y, x, domainValue, vertical = false,
}) => {
  const dotes = [];
  for (let i = x; i <= width; i += 5) {
    domainValue !== '0' &&
      dotes.push(
        <Circle
          key={`dot${i}${Math.random()}`}
          radius={0.5}
          fill={COLORS.GRAY[400]}
          y={y - 0.5}
          x={i - 0.5 - 24}
        />,
      );
  }
  return (
    <Group
      rotation={vertical ? 90 : 0}
      y={vertical ? -34 : 0}
      x={vertical ? x + 80 : x}
    >
      <Text
        rotation={vertical ? -90 : 0}
        text={domainValue}
        x={vertical ? x + 110 : x + 5}
        y={vertical ? y - 1 : y - 15}
        fontSize={10}
      />
      {dotes}
    </Group>
  );
};

export default DotedLine;
