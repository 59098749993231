/**
 * @fileoverview gRPC-Web generated client stub for companies
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/companies_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_company_message_pb = require('../../../api/dashboard/v2/company_message_pb.js')

var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js')

var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js')
const proto = {};
proto.companies = require('./companies_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.companies.CompaniesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.companies.CompaniesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.CompaniesRequest,
 *   !proto.companies.CompaniesWithSitesAndDepartmentsResponse>}
 */
const methodDescriptor_Companies_GetCompaniesWithSitesAndDepartments = new grpc.web.MethodDescriptor(
  '/companies.Companies/GetCompaniesWithSitesAndDepartments',
  grpc.web.MethodType.UNARY,
  proto.companies.CompaniesRequest,
  proto.companies.CompaniesWithSitesAndDepartmentsResponse,
  /**
   * @param {!proto.companies.CompaniesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.companies.CompaniesWithSitesAndDepartmentsResponse.deserializeBinary
);


/**
 * @param {!proto.companies.CompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.companies.CompaniesWithSitesAndDepartmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.companies.CompaniesWithSitesAndDepartmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.getCompaniesWithSitesAndDepartments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/GetCompaniesWithSitesAndDepartments',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompaniesWithSitesAndDepartments,
      callback);
};


/**
 * @param {!proto.companies.CompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.companies.CompaniesWithSitesAndDepartmentsResponse>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.getCompaniesWithSitesAndDepartments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/GetCompaniesWithSitesAndDepartments',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompaniesWithSitesAndDepartments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.CompanyRequest,
 *   !proto.company.CompaniesCompany>}
 */
const methodDescriptor_Companies_GetCompany = new grpc.web.MethodDescriptor(
  '/companies.Companies/GetCompany',
  grpc.web.MethodType.UNARY,
  proto.companies.CompanyRequest,
  api_dashboard_v2_company_message_pb.CompaniesCompany,
  /**
   * @param {!proto.companies.CompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_company_message_pb.CompaniesCompany.deserializeBinary
);


/**
 * @param {!proto.companies.CompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.company.CompaniesCompany)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.company.CompaniesCompany>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.getCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/GetCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompany,
      callback);
};


/**
 * @param {!proto.companies.CompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.company.CompaniesCompany>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.getCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/GetCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.CompaniesRequest,
 *   !proto.companies.CompanyListResponse>}
 */
const methodDescriptor_Companies_GetCompanyList = new grpc.web.MethodDescriptor(
  '/companies.Companies/GetCompanyList',
  grpc.web.MethodType.UNARY,
  proto.companies.CompaniesRequest,
  proto.companies.CompanyListResponse,
  /**
   * @param {!proto.companies.CompaniesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.companies.CompanyListResponse.deserializeBinary
);


/**
 * @param {!proto.companies.CompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.companies.CompanyListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.companies.CompanyListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.getCompanyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/GetCompanyList',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompanyList,
      callback);
};


/**
 * @param {!proto.companies.CompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.companies.CompanyListResponse>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.getCompanyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/GetCompanyList',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompanyList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.CreateSiteRequest,
 *   !proto.companies.SitesResponse>}
 */
const methodDescriptor_Companies_CreateSite = new grpc.web.MethodDescriptor(
  '/companies.Companies/CreateSite',
  grpc.web.MethodType.UNARY,
  proto.companies.CreateSiteRequest,
  proto.companies.SitesResponse,
  /**
   * @param {!proto.companies.CreateSiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.companies.SitesResponse.deserializeBinary
);


/**
 * @param {!proto.companies.CreateSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.companies.SitesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.companies.SitesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.createSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/CreateSite',
      request,
      metadata || {},
      methodDescriptor_Companies_CreateSite,
      callback);
};


/**
 * @param {!proto.companies.CreateSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.companies.SitesResponse>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.createSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/CreateSite',
      request,
      metadata || {},
      methodDescriptor_Companies_CreateSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.EditSiteRequest,
 *   !proto.site_message.Site>}
 */
const methodDescriptor_Companies_EditSite = new grpc.web.MethodDescriptor(
  '/companies.Companies/EditSite',
  grpc.web.MethodType.UNARY,
  proto.companies.EditSiteRequest,
  api_dashboard_v2_site_message_pb.Site,
  /**
   * @param {!proto.companies.EditSiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_site_message_pb.Site.deserializeBinary
);


/**
 * @param {!proto.companies.EditSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.site_message.Site)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.site_message.Site>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.editSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/EditSite',
      request,
      metadata || {},
      methodDescriptor_Companies_EditSite,
      callback);
};


/**
 * @param {!proto.companies.EditSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.site_message.Site>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.editSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/EditSite',
      request,
      metadata || {},
      methodDescriptor_Companies_EditSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.RemoveSiteRequest,
 *   !proto.companies.SitesResponse>}
 */
const methodDescriptor_Companies_RemoveSite = new grpc.web.MethodDescriptor(
  '/companies.Companies/RemoveSite',
  grpc.web.MethodType.UNARY,
  proto.companies.RemoveSiteRequest,
  proto.companies.SitesResponse,
  /**
   * @param {!proto.companies.RemoveSiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.companies.SitesResponse.deserializeBinary
);


/**
 * @param {!proto.companies.RemoveSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.companies.SitesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.companies.SitesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.removeSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/RemoveSite',
      request,
      metadata || {},
      methodDescriptor_Companies_RemoveSite,
      callback);
};


/**
 * @param {!proto.companies.RemoveSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.companies.SitesResponse>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.removeSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/RemoveSite',
      request,
      metadata || {},
      methodDescriptor_Companies_RemoveSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.EditDepartmentRequest,
 *   !proto.department_message.Department>}
 */
const methodDescriptor_Companies_EditDepartment = new grpc.web.MethodDescriptor(
  '/companies.Companies/EditDepartment',
  grpc.web.MethodType.UNARY,
  proto.companies.EditDepartmentRequest,
  api_dashboard_v2_department_message_pb.Department,
  /**
   * @param {!proto.companies.EditDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_department_message_pb.Department.deserializeBinary
);


/**
 * @param {!proto.companies.EditDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.department_message.Department)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.department_message.Department>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.editDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/EditDepartment',
      request,
      metadata || {},
      methodDescriptor_Companies_EditDepartment,
      callback);
};


/**
 * @param {!proto.companies.EditDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.department_message.Department>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.editDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/EditDepartment',
      request,
      metadata || {},
      methodDescriptor_Companies_EditDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.CreateDepartmentRequest,
 *   !proto.companies.DepartmentsResponse>}
 */
const methodDescriptor_Companies_CreateDepartment = new grpc.web.MethodDescriptor(
  '/companies.Companies/CreateDepartment',
  grpc.web.MethodType.UNARY,
  proto.companies.CreateDepartmentRequest,
  proto.companies.DepartmentsResponse,
  /**
   * @param {!proto.companies.CreateDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.companies.DepartmentsResponse.deserializeBinary
);


/**
 * @param {!proto.companies.CreateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.companies.DepartmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.companies.DepartmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.createDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/CreateDepartment',
      request,
      metadata || {},
      methodDescriptor_Companies_CreateDepartment,
      callback);
};


/**
 * @param {!proto.companies.CreateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.companies.DepartmentsResponse>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.createDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/CreateDepartment',
      request,
      metadata || {},
      methodDescriptor_Companies_CreateDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.companies.RemoveDepartmentRequest,
 *   !proto.companies.DepartmentsResponse>}
 */
const methodDescriptor_Companies_RemoveDepartment = new grpc.web.MethodDescriptor(
  '/companies.Companies/RemoveDepartment',
  grpc.web.MethodType.UNARY,
  proto.companies.RemoveDepartmentRequest,
  proto.companies.DepartmentsResponse,
  /**
   * @param {!proto.companies.RemoveDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.companies.DepartmentsResponse.deserializeBinary
);


/**
 * @param {!proto.companies.RemoveDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.companies.DepartmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.companies.DepartmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.companies.CompaniesClient.prototype.removeDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/companies.Companies/RemoveDepartment',
      request,
      metadata || {},
      methodDescriptor_Companies_RemoveDepartment,
      callback);
};


/**
 * @param {!proto.companies.RemoveDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.companies.DepartmentsResponse>}
 *     Promise that resolves to the response
 */
proto.companies.CompaniesPromiseClient.prototype.removeDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/companies.Companies/RemoveDepartment',
      request,
      metadata || {},
      methodDescriptor_Companies_RemoveDepartment);
};


module.exports = proto.companies;

