export const DASHBOARD_USERS_SET = 'DASHBOARD_USERS_SET';
export const DASHBOARD_USER_ADD = 'DASHBOARD_USER_ADD';
export const DASHBOARD_USER_UPDATE = 'DASHBOARD_USER_UPDATE';
export const DASHBOARD_USERS_ERROR = 'DASHBOARD_USERS_ERROR';
export const DASHBOARD_USERS_TOGGLE_LOADING = 'DASHBOARD_USERS_TOGGLE_LOADING';
export const DASHBOARD_USERS_RESET = 'DASHBOARD_USERS_RESET';
export const SET_NEW_USER_DATA_FIELD = 'SET_NEW_USER_DATA_FIELD';
export const SET_CURRENT_DASHBOARD_USER_ID = 'SET_CURRENT_DASHBOARD_USER_ID';
export const SET_CURRENT_DASHBOARD_USER_IS_ONLY_ITEM = 'SET_CURRENT_DASHBOARD_USER_IS_ONLY_ITEM';
export const DASHBOARD_USER_DELETE = 'DASHBOARD_USER_DELETE';
export const SET_INVITE_LINK = 'SET_INVITE_LINK';
