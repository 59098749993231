import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _compact from 'lodash/compact';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import getGalleryReportSchema from '../constants/galleryReportSchema';
import { TableType } from '../constants/tableType';
import getComparisonReportSchema from '../constants/comparisonReportSchema';
import { setReportBuilder } from '../reportsStore/reportSlice';
import { TableReportBuilder } from './TableReportBuilder';
import { getCurrentPageReportList, ReportOption } from '../constants/tablePageReportList';
import getActivityReportSchema from '../constants/activityReportSchema';
import { createCategoriesReportSchema, getRankedSchema } from './schemaTools';
import getActivityLegendSchema from '../constants/activityLegendSchema';
import activityLegendData from '../constants/activityLegendData';
import { extractPassedPrograms, filterFinishedUsers, filterSortUsersByImprovement, filterSortUsersByRisk, } from './activityReportDataModifiers';
export default function useTableReport(reportId, reportData, tableType) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    // @ts-ignore
    const { videosList } = useAppSelector((state) => state.videos);
    const { comparisonList, } = useAppSelector((state) => state.soterTaskComparisons);
    // @ts-ignore
    const { sitesList, departmentsList } = useAppSelector((state) => state.companies.selectedCompany);
    // @ts-ignore
    const { filters } = useAppSelector((state) => state.filtersNew);
    // @ts-ignore
    const categories = useAppSelector((state) => state.category.categories);
    useEffect(() => {
        if (!reportId)
            return;
        const reportBuilder = new TableReportBuilder(reportData);
        const reportEntity = getCurrentPageReportList(tableType).find(({ id }) => id === reportId);
        // @ts-ignore
        const customCategoriesTitles = categories === null || categories === void 0 ? void 0 : categories.map(({ main, title }) => !main && title).filter((item) => item);
        // TODO: Remove task_title hack when logic on the backend will be adjusted
        const customCategoriesTitlesWithTask = [..._compact(customCategoriesTitles), t('GENERAL.TASK_TITLE')];
        switch (tableType) {
            case TableType.coach:
                reportBuilder.setSchema(getActivityReportSchema(t));
                // @ts-ignore
                reportBuilder.expandSchema(createCategoriesReportSchema(customCategoriesTitlesWithTask), 
                // Define the column after what additional columns with custom categories will appear
                getActivityReportSchema(t).findIndex(({ header }) => header === t('GENERAL.JOB_TITLE')));
                reportBuilder.setLegend({
                    schema: getActivityLegendSchema(t),
                    data: activityLegendData,
                });
                if (filters.showPassedPrograms)
                    reportBuilder.modifyData(extractPassedPrograms);
                switch (reportEntity === null || reportEntity === void 0 ? void 0 : reportEntity.option) {
                    case ReportOption.finishedProgram:
                        reportBuilder.modifyData(filterFinishedUsers);
                        break;
                    case ReportOption.byImprovements:
                        reportBuilder.modifyData(filterSortUsersByImprovement);
                        reportBuilder.expandSchema([getRankedSchema(t)], 0);
                        break;
                    case ReportOption.byRisk:
                        reportBuilder.modifyData(filterSortUsersByRisk);
                        reportBuilder.expandSchema([getRankedSchema(t)], 0);
                        break;
                    default: break;
                }
                break;
            case TableType.soterTaskVideos:
                reportBuilder.setSchema(getGalleryReportSchema(t, departmentsList, sitesList, videosList));
                break;
            case TableType.soterTaskComparison:
                reportBuilder.setSchema(getComparisonReportSchema(t, departmentsList, sitesList, comparisonList));
                break;
            default: return;
        }
        dispatch(setReportBuilder(reportBuilder));
    }, [reportId]);
}
