import React from 'react';
import { cardBody, cardLarge, container, loginContainer, mb4, page, pageMain, } from 'assets/soter-flex-styles';
import logo from 'assets/img/soter-analytics.svg';
import taskLogo from 'assets/img/soterTaskLogo.svg';
import { loginPageCard, loginPageImg, loginPageWrapper, loginPageWrapperV2, } from './styles';
const LoginPageContainer = ({ isSignUp, children }) => (React.createElement("div", { className: page },
    React.createElement("div", { className: pageMain },
        React.createElement("div", { className: `${container} ${loginPageWrapper}` },
            React.createElement("div", { className: loginPageWrapperV2 },
                React.createElement("div", { className: loginContainer },
                    React.createElement("img", { src: isSignUp ? taskLogo : logo, className: `${loginPageImg} ${mb4}`, alt: "logo" }),
                    React.createElement("div", { className: `${cardLarge} ${loginPageCard}` },
                        React.createElement("div", { className: cardBody }, children))))))));
export default LoginPageContainer;
