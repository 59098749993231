import React from 'react';
import { useTranslation } from 'react-i18next';
import { mb2, mt6 } from '../../../assets/soter-flex-styles';
import StaticNotification from '../../../containers/NotificationContainer/components/StaticNotification';
import { notificationTypes } from '../../../containers/NotificationContainer/notifications';
import Loader from '../../../lib/ui/Loader';
import Button from '../../../lib/ui/Button';

const DeletedEntriesList = ({ deleted, failed, closeModal }) => {
  const isLoading = deleted.length < 1 && failed.length < 1;
  const { t } = useTranslation();
  const failedMessage = (
    <div>
      <h4>{t('WEARABLES.ACTIVITY.CANT_DELETE')}</h4>
      {failed.map(({
        firstName, lastName, id, error,
      }) => (<div key={firstName + lastName}>{`${firstName} ${lastName} (${t('GENERAL.ID')}: ${id}) - ${error}`}</div>))}
    </div>
  );
  const successMessage = (
    <div>
      <h4>{t('WEARABLES.ACTIVITY.DELETED')}</h4>
      {deleted.map(({
        firstName, lastName,
      }) => (<div key={firstName + lastName}>{`${firstName} ${lastName}`}</div>))}
    </div>
  );
  const failedBlock = failed.length > 0
    ? (
      <div className={mt6}>
        <StaticNotification notification={{
          status: notificationTypes.TYPE_FAILED,
          message: failedMessage,
        }}
        />
      </div>
    ) : null;
  const successBlock = deleted.length > 0
    ? (
      <div className={mt6}>
        <StaticNotification notification={{
          status: notificationTypes.TYPE_SUCCESS,
          message: successMessage,
        }}
        />
      </div>
    ) : null;
  if (isLoading) {
    return (
      <div>
        <h5>{`${t('GENERAL.DELETING')}... ${t('GENERAL.PLEASE_WAIT')}`}</h5>
        <Loader />
      </div>
    );
  }
  return (
    <div className={mb2}>
      {failedBlock}
      {successBlock}
      <Button
        style={{ textAlign: 'center', display: 'block', width: '100%' }}
        className={mt6}
        text="OK"
        handler={() => closeModal()}
      />
    </div>
  );
};

export default DeletedEntriesList;
