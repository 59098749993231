import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';
import { outlineColoredButton, primaryOutlineButton } from '../../soter-flex-styles/button-elements';

export const headerContainer = Emotion.css`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  min-height: 66px;
`;

export const admin = Emotion.css`
  background-color: ${COLORS.LIGHT_BLUE[600]};
  border-bottom-color: ${COLORS.LIGHT_BLUE[600]};
`;

export const goToAdminButton = Emotion.css`
  ${outlineColoredButton(COLORS.LIGHT_BLUE[600], 'small')};

  width: 92px;
  margin-right: 4px;
`;

export const leaveAdminButton = Emotion.css`
  ${primaryOutlineButton('#fff', COLORS.LIGHT_BLUE[600], 'small')};

  width: 124px;
`;
