import { toCamel } from 'snake-camel';
import { changeHelpStatusRequest, getMetaData, helpRequest, helpService, } from 'init/proto_client';
import * as types from './actionTypes';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';

export function toggleIsLoading(isLoading) {
  return ({ type: types.HELP_TOGGLE_LOADING, isLoading });
}

export function resetHelpRequests() {
  return { type: types.HELP_RESET };
}

export function getHelpRequests(companyId) {
  return async (dispatch) => {
    dispatch(toggleIsLoading(true));
    helpRequest.setCompanyId(companyId);
    await helpService.getUser(helpRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.HELP_ERROR,
            error,
          });
        } else {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.HELP_SET,
            requests: [...response.toObject().helpRequestsList].map((user) => toCamel(user)),
          });
        }
      });
  };
}

export function setHelpRequestStatus(helpRequestId, status) {
  return async (dispatch) => {
    dispatch(toggleIsLoading(true));
    changeHelpStatusRequest.setHelpRequestId(helpRequestId);
    changeHelpStatusRequest.setStatus(status);
    await helpService.changeStatusHelpRequest(changeHelpStatusRequest, getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.CHANGE_HELP_REQUEST_STATUS_FAILED_MSG,
          }));
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.HELP_ERROR,
            error,
          });
        } else {
          dispatch(toggleIsLoading(false));
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.CHANGE_HELP_REQUEST_STATUS_SUCCESS_MSG,
          }));
          dispatch({
            type: types.HELP_SET,
            requests: [...response.toObject().helpRequestsList].map((user) => toCamel(user)),
          });
        }
      });
  };
}
