/**
 * @fileoverview gRPC-Web generated client stub for my_company
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/my_company_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_admin_dashboard_users_message_pb = require('../../../api/dashboard/v2/admin_dashboard_users_message_pb.js')
const proto = {};
proto.my_company = require('./my_company_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.my_company.MyCompanyClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.my_company.MyCompanyPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.my_company.MyCompanyRequest,
 *   !proto.admin.DashboardUsersResponse>}
 */
const methodDescriptor_MyCompany_MyCompany = new grpc.web.MethodDescriptor(
  '/my_company.MyCompany/MyCompany',
  grpc.web.MethodType.UNARY,
  proto.my_company.MyCompanyRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUsersResponse,
  /**
   * @param {!proto.my_company.MyCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUsersResponse.deserializeBinary
);


/**
 * @param {!proto.my_company.MyCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.DashboardUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.DashboardUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.my_company.MyCompanyClient.prototype.myCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/my_company.MyCompany/MyCompany',
      request,
      metadata || {},
      methodDescriptor_MyCompany_MyCompany,
      callback);
};


/**
 * @param {!proto.my_company.MyCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.DashboardUsersResponse>}
 *     Promise that resolves to the response
 */
proto.my_company.MyCompanyPromiseClient.prototype.myCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/my_company.MyCompany/MyCompany',
      request,
      metadata || {},
      methodDescriptor_MyCompany_MyCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.ResetDashboardUserPasswordResponse>}
 */
const methodDescriptor_MyCompany_ResetDashboardUserPassword = new grpc.web.MethodDescriptor(
  '/my_company.MyCompany/ResetDashboardUserPassword',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.ResetDashboardUserPasswordResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.ResetDashboardUserPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.ResetDashboardUserPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.ResetDashboardUserPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.my_company.MyCompanyClient.prototype.resetDashboardUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/my_company.MyCompany/ResetDashboardUserPassword',
      request,
      metadata || {},
      methodDescriptor_MyCompany_ResetDashboardUserPassword,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.ResetDashboardUserPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.my_company.MyCompanyPromiseClient.prototype.resetDashboardUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/my_company.MyCompany/ResetDashboardUserPassword',
      request,
      metadata || {},
      methodDescriptor_MyCompany_ResetDashboardUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.SendInviteLinkResponse>}
 */
const methodDescriptor_MyCompany_ResendInviteLink = new grpc.web.MethodDescriptor(
  '/my_company.MyCompany/ResendInviteLink',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.SendInviteLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.SendInviteLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.my_company.MyCompanyClient.prototype.resendInviteLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/my_company.MyCompany/ResendInviteLink',
      request,
      metadata || {},
      methodDescriptor_MyCompany_ResendInviteLink,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.SendInviteLinkResponse>}
 *     Promise that resolves to the response
 */
proto.my_company.MyCompanyPromiseClient.prototype.resendInviteLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/my_company.MyCompany/ResendInviteLink',
      request,
      metadata || {},
      methodDescriptor_MyCompany_ResendInviteLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.SendInviteLinkResponse>}
 */
const methodDescriptor_MyCompany_SendInviteLink = new grpc.web.MethodDescriptor(
  '/my_company.MyCompany/SendInviteLink',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.SendInviteLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.SendInviteLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.my_company.MyCompanyClient.prototype.sendInviteLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/my_company.MyCompany/SendInviteLink',
      request,
      metadata || {},
      methodDescriptor_MyCompany_SendInviteLink,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.SendInviteLinkResponse>}
 *     Promise that resolves to the response
 */
proto.my_company.MyCompanyPromiseClient.prototype.sendInviteLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/my_company.MyCompany/SendInviteLink',
      request,
      metadata || {},
      methodDescriptor_MyCompany_SendInviteLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.GetInviteLinkResponse>}
 */
const methodDescriptor_MyCompany_GetInviteLink = new grpc.web.MethodDescriptor(
  '/my_company.MyCompany/GetInviteLink',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.GetInviteLinkResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.GetInviteLinkResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.GetInviteLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.GetInviteLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.my_company.MyCompanyClient.prototype.getInviteLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/my_company.MyCompany/GetInviteLink',
      request,
      metadata || {},
      methodDescriptor_MyCompany_GetInviteLink,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.GetInviteLinkResponse>}
 *     Promise that resolves to the response
 */
proto.my_company.MyCompanyPromiseClient.prototype.getInviteLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/my_company.MyCompany/GetInviteLink',
      request,
      metadata || {},
      methodDescriptor_MyCompany_GetInviteLink);
};


module.exports = proto.my_company;

