import * as Yup from 'yup';
export function createValidationSchema(t) {
    return Yup.object().shape({
        companyName: Yup.string()
            .required(t('SOTER_GENIUS.REQUIRED_FIELD'))
            .max(255, t('SOTER_GENIUS.MAX_LENGTH', { count: 255 })),
        companyIndustry: Yup.string()
            .required(t('SOTER_GENIUS.REQUIRED_FIELD'))
            .max(255, t('SOTER_GENIUS.MAX_LENGTH', { count: 255 })),
        naicsCode: Yup.string()
            .required(t('SOTER_GENIUS.REQUIRED_FIELD'))
            .matches(/^[0-9]+$/, t('SOTER_GENIUS.ONLY_DIGITS'))
            .min(6, t('SOTER_GENIUS.EXACTLY_DIGITS', { count: 6 }))
            .max(6, t('SOTER_GENIUS.EXACTLY_DIGITS', { count: 6 })),
        location: Yup.string()
            .required(t('SOTER_GENIUS.REQUIRED_FIELD'))
            .max(255, t('SOTER_GENIUS.MAX_LENGTH', { count: 255 }))
    });
}
