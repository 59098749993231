import { COLORS } from 'constants/colors';

export const PIXEL_COEFFICIENT = 3.8;
export const CHART_HEIGHT_MM = 40;
export const DONUT_CHART_HEIGHT_MM = 50;
export const CHART_CANVAS_HEIGHT = 38;
export const CHART_HEIGHT_GAP = 10;
export const CHART_SIDE_GAP = 7;
export const USER_CHARTS_STAGE_HEIGHT_MM = 42;
export const USER_CHARTS_STAGE_WIDTH_MM = 267;
export const USER_CHARTS_WORK_WIDTH_MM = 260;
export const RATIO_CHART_CEIL = [40, 60, 80];
export const SPLIT_CHART_SLOT_WIDTH = 98;
export const SPLIT_CHART_BAR_WIDTH = 10;
export const REPORT_GENERATED_DATE_FORMAT = 'Do MMM hh:mm A';
export const DATE_SHORT_FORMAT = 'MM.DD';
export const DATE_LONG_FORMAT = 'DD MMMM';
export const SINGLE_PAGE_TYPE = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};
export const RISKS_CHART_WIDTH_MM = 92;
export const RISKS_CHART_SIDE_GAP_MM = 5;
export const RISKS_CHART_SLOT_WIDTH_MM = 29;
export const RISKS_CHART_BAR_WIDTH_MM = 9;
export const RISKS_GROUP_ENUM = {
  lower: ['WEARABLES.OVERVIEW.LOW_RISK', 'lowerWorkersCount'],
  middle: ['WEARABLES.OVERVIEW.MEDIUM_RISK', 'middleWorkersCount'],
  higher: ['WEARABLES.OVERVIEW.HIGH_RISK', 'higherWorkersCount'],
};
export const RISKS_CHART_LEGEND_TITLES = [
  {
    text: 'WEARABLES.OVERVIEW.PROGRAM_START',
    color: COLORS.INDIGO[800],
  },
  {
    text: 'WEARABLES.OVERVIEW.PROGRAM_END',
    color: COLORS.INDIGO[500],
  },
];

export const STACKED_CHART_WIDTH_MM = 184;
export const STACKED_CHART_INNER_GAP_MM = 10;
export const STACKED_CHART_SLOT_WIDTH_MM = 29;
export const STACKED_CHART_BAR_WIDTH_MM = 4;

export const DONUT_CHART_OUTER_RADIUS = 77;
export const DONUT_CHART_INNER_RADIUS = 61;
export const DONUT_CHART_ARC_GAP_DEGREE = 2;

export const USER_HORIZONTAL_CHARTS_STAGE_HEIGHT_MM = 150;
