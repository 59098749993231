import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { outlineColoredButton } from '../../../assets/soter-flex-styles';
import { ReactComponent as FilterIcon } from '../../../assets/img/icons/filters.svg';
import CustomDatePicker from './CustomDatepicker';
import { createEventNotification } from '../../NotificationContainer/notificationsStore/actions';
import { setFilterValues } from '../filtersStore/filtersSlice';
import { TABLE_DATA_FILTER } from '../../../constants/analyticsEvents';
import { setCurrentPage } from '../tableStateStore/actions';
import { TableType } from '../constants/tableType';
import { setDatesFilter } from '../filtersStoreOld/actions';
import useOutsideClickHandler from '../../../lib/utils/useOutsideClickHandler';
import { showFilterPanel } from '../utils/shownFilters';
import OverlaidFiltersContainer from './OverlaidFiltersContainer';

const TableFilterPanel = ({ filteringParameters, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterState = useSelector((state) => state.filtersNew.filters[type]);
  const isFiltersSet
    = filterState &&
    !!Object.keys(filterState).find(
      (key) => key !== 'showPassedPrograms' && filterState[key].length > 0,
    );
  const [showFilters, setShowFilters] = useState(false);
  const handleSelectChange = (parameter) => (value) => {
    dispatch(setFilterValues({ type: parameter, values: value }));
    const params = {
      filter_parameter: parameter,
      filter_value: value,
      table_type: type,
    };
    window.logEvent(TABLE_DATA_FILTER, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${TABLE_DATA_FILTER}: ${JSON.stringify(params)}`));
    }
    dispatch(setCurrentPage(1, TableType.coach));
    dispatch(setCurrentPage(1, TableType.devices));
    dispatch(setCurrentPage(1, TableType.companies));
    dispatch(setCurrentPage(1, TableType.dashboardUsers));
    dispatch(setCurrentPage(1, TableType.myCompanyUsers));
    dispatch(setCurrentPage(1, TableType.soterTaskVideos));
    dispatch(setCurrentPage(1, TableType.clipGoUsersUpload));
    switch (parameter) {
      case 'dates':
        dispatch(setDatesFilter(value, type));
        break;
      default:
        dispatch(resetFiltersState());
    }
  };
  const ref = useRef(null);
  useOutsideClickHandler(ref, () => setShowFilters(false));
  const filerIcon = (color) => Emotion.css`
    stroke: ${color};
  `;

  return (
    <div>
      {showFilterPanel(type) ? (
        <div style={{ display: 'flex' }}>
          {showFilters ? (
            <OverlaidFiltersContainer
              innerRef={ref}
              filteringParameters={filteringParameters.filter(
                ({ name }) => name !== 'dates' && name !== 'showPassedPrograms',
              )}
              closeHandler={() => setShowFilters(false)}
              pageType={type}
            />
          ) : null}
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '38px',
              borderRadius: '5px',
              fontWeight: 400,
            }}
            onClick={() => setShowFilters(!showFilters)}
            className={outlineColoredButton(
              showFilters ? COLORS.LIGHT_BLUE[600] : COLORS.GRAY[100],
              'small',
            )}
          >
            <FilterIcon
              className={filerIcon(
                isFiltersSet ? COLORS.LIGHT_BLUE[600] : COLORS.GRAY[400],
              )}
            />
            <div style={{ color: COLORS.GRAY[400], marginLeft: '5px' }}>
              {t('GENERAL.FILTERS')}
            </div>
          </button>
          {/* eslint-disable-next-line consistent-return,array-callback-return */}
          {filteringParameters.map((parameter) => {
            if (parameter.name === 'dates') {
              return (
                <CustomDatePicker
                  key={parameter.name}
                  onChange={handleSelectChange}
                />
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

export default TableFilterPanel;
