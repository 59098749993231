import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  profile: {},
  id: null,
  taskProfilesListList: [],
  videosList: [],
  tag: null,
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.JOB_PROFILE_SET:
      // eslint-disable-next-line no-case-declarations
      const {
        profile, id, taskProfilesListList, videosListList = [], videosTaskListList = [],
      } = action.profile;
      return state.merge({
        profile,
        id,
        taskProfilesListList,
        videosList: [...videosListList, ...videosTaskListList],
        error: null,
      });
    case types.JOB_PROFILE_TAG_SET:
      // eslint-disable-next-line no-case-declaration
      const { tag } = action;
      return state.merge({ tag });
    case types.JOB_PROFILE_ERROR:
      // eslint-disable-next-line no-case-declarations
      const { error } = action;
      return state.merge({ error });
    case types.JOB_PROFILE_RESET:
      return initialState;
    default: return state;
  }
}
