import { useEffect, useState } from 'react';
import { passedPrograms } from '../../../constants/URLParams';
import { parseUrlParams } from './addURLQueryParams';
// Custom hook to parse URL search params and apply sorting, filtering, page number, page length
export const useSearchParams = () => {
    const [params, setParams] = useState({});
    useEffect(() => {
        const urlSearchParams = window.location.search;
        // eslint-disable-next-line no-new
        new Promise((resolve) => {
            resolve(parseUrlParams(urlSearchParams));
        }).then((r) => {
            setParams(r);
        });
        // Get the values from the URL search params
    }, []);
    return params;
};
