export const CARD_TITLES = {
  program: 'GENERAL.PROGRAM',
  programHistory: 'WEARABLES.USER_PAGE.HISTORY',
  device: 'GENERAL.DEVICE',
  achievements: 'WEARABLES.USER_PAGE.ACHIEVEMENTS',
  programProgress: 'WEARABLES.USER_PAGE.PROGRESS',
  chosenDay: 'WEARABLES.USER_PAGE.CHOSEN_DAY',
  mostFrequentHazard: 'WEARABLES.USER_PAGE.MOST_HAZARD',
  mostRiskHour: 'WEARABLES.USER_PAGE.MOST_HOUR',
  mostRiskDay: 'WEARABLES.USER_PAGE.MOST_DAY',
  mostRiskTask: 'WEARABLES.USER_PAGE.MOST_TASK',
};
