import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../ModalContainer/ModalWrapper';
import { productStatus } from '../../../init/proto_client';
import Button from '../../../lib/ui/Button';
import CustomCheckbox from '../../../lib/ui/CustomCheckbox/CustomCheckbox';
import Text from '../../../lib/ui/Text';
import { mt3 } from '../../../assets/soter-flex-styles';
import { ReactComponent as ArrowRightDis } from '../../../assets/img/icons/chevron-right-disabled.svg';
import { ReactComponent as ArrowRight } from '../../../assets/img/icons/chevron-right-enabled.svg';
import { COLORS } from '../../../constants/colors';

export default function SoterTaskIsMovedModal() {
  const DONOTSHOW = 'dontShowSoterTaskPopup';
  const { t } = useTranslation();
  const { products, id } = useSelector((state) => state.companies.selectedCompany);
  const { taskProduct } = products;
  const dontShow = localStorage.getItem(DONOTSHOW);
  const [checked, setChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const buttonClickHandler = () => {
    setModalOpen(false);
    if (checked) {
      localStorage.setItem(DONOTSHOW, 'true');
    }
  };
  const linkClickHandler = () => {
    setModalOpen(false);
    navigate(`/${id}/users/soter-genius/gallery`);
  };
  if (!modalOpen || taskProduct?.status !== productStatus?.PRODUCTSTATUSSUBSCRIPTIONACTIVE || dontShow) return null;
  return (
    <ModalWrapper keepModalOnClickOutside={false} closeModal={() => setModalOpen(false)}>
      <Text className={mt3} style={{ width: '90%' }} appearance="title" weight="semi-bold" text={t('SOTER_TASK.MIGRATION.HEADER')} />
      <div style={{
        display: 'flex', alignItems: 'center', marginTop: '6px', marginBottom: '14px',
      }}
      >
        <div onMouseLeave={() => setIsArrowHovered(false)} onMouseEnter={() => setIsArrowHovered(true)} style={{ display: 'flex' }} onClick={linkClickHandler}>
          <Text className={Emotion.css`cursor: pointer; color: #9AA0AB; &:hover { color: ${COLORS.BLUE[600]}`} text={t('SOTER_TASK.MIGRATION.LINK')} />
          <div className={Emotion.css`display: flex; align-items: center; margin-left: 15px; margin-right: 30px`}>
            { isArrowHovered ? <ArrowRight /> : <ArrowRightDis />}
          </div>
        </div>
        <CustomCheckbox value={checked} onChange={() => setChecked(!checked)} label={t('SOTER_TASK.MIGRATION.CHECKBOX')} />
      </div>
      <Button style={{ width: '100%', display: 'flex', justifyContent: 'center' }} type="submit" disabled={!checked} handler={buttonClickHandler}>{t('SOTER_TASK.MIGRATION.BUTTON')}</Button>
    </ModalWrapper>
  );
}
