import React, { useState } from 'react';
import { Form, Radio, RadioGroup } from 'rsuite';
import { useTranslation } from 'react-i18next';
import Loader from 'lib/ui/Loader';
import { flexColumn, formControlInput, inputLabel, invisibleButton, mt3, } from '../../../assets/soter-flex-styles';
import { ReactComponent as Plus } from '../../../assets/img/icons/plus.svg';
import Button from '../Button';
import CustomSelect from '../CustomSelect';
import { useAppDispatch, useAppSelector } from '../../utils/useStore';
import { createEntry } from '../../../company/categories/store/actions';
import { COLORS } from '../../../constants/colors';
import { setOpenedModal as closeModal } from '../../../containers/ModalContainer/modalsStore/actions';
import { createDepartment, createSite } from '../../../company/companiesStore/actions';
import { prepareSeparatedTitle } from '../../../company/utils/preparedTitle';
import { ACTIVITY_USER_ENTRY_CHANGED, DEVICES_ENTRY_CHANGED } from '../../../constants/analyticsEvents';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { entryBtn, entryItem } from './styles';
import { getActionToDispatch } from './helpers';
const LinkToEntry = ({ title, categories, companyId, setOpenedModal, userIds, setUserForTagShown, handlerFnForDevices, }) => {
    var _a;
    const { t } = useTranslation();
    const notAssigned = t('GENERAL.NOT_ASSIGNED');
    // here we add NOT ASSIGNED option to site/dep/job role categories list
    const updatedCategories = categories.map((c) => {
        // @ts-ignore
        if ((c === null || c === void 0 ? void 0 : c.id) < 0 || (c === null || c === void 0 ? void 0 : c.title.includes('job_role'))) {
            return Object.assign(Object.assign({}, c), { tagsList: [...c.tagsList, {
                        companySegmentId: c.id || 0,
                        id: 0,
                        title: notAssigned,
                        type: 0,
                    }] });
        }
        return c;
    });
    const dispatch = useAppDispatch();
    const { openedModal } = useAppSelector((state) => state.modals);
    const [categoryPicked, setCategoryPicked] = useState(null);
    const [selectedEntry, setSelectedEntry] = useState('');
    const [isFormSent, setIsFormSent] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const listedTags = () => {
        const tags = [];
        updatedCategories === null || updatedCategories === void 0 ? void 0 : updatedCategories.map(({ tagsList }) => tags.push(...tagsList));
        return tags;
    };
    const selectedCategoryEntries = categoryPicked && ((_a = updatedCategories === null || updatedCategories === void 0 ? void 0 : updatedCategories.find((category) => category.id === categoryPicked.value)) === null || _a === void 0 ? void 0 : _a.tagsList);
    const tags = searchValue !== ''
        ? (selectedCategoryEntries || listedTags()).filter((tag) => tag.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
        : selectedCategoryEntries || listedTags();
    const categoriesSelectOptions = () => {
        const result = [];
        updatedCategories === null || updatedCategories === void 0 ? void 0 : updatedCategories.map((category) => result.push({
            label: category.title && prepareSeparatedTitle(category.title, t),
            value: category.id,
        }));
        return result;
    };
    const dispatchUserAction = (action) => {
        dispatch(action).then(() => {
            if (openedModal === '' || !openedModal) {
                setOpenedModal(null);
            }
            else {
                dispatch(closeModal());
            }
        });
    };
    const handleSubmit = () => {
        var _a;
        const idsArr = selectedEntry.split(' ');
        const selectedTagCategoryTitle = updatedCategories.filter((category) => category === null || category === void 0 ? void 0 : category.tagsList.map((tag) => tag.id).includes(Number(idsArr[0])))[0].title;
        const categoryTitle = (categoryPicked === null || categoryPicked === void 0 ? void 0 : categoryPicked.label) === 'Department'
            || selectedTagCategoryTitle === 'department'
            ? 'setDepartment'
            : (categoryPicked === null || categoryPicked === void 0 ? void 0 : categoryPicked.label) === 'Site'
                || selectedTagCategoryTitle === 'site'
                ? 'setSite'
                : '';
        const userCategoryTitle = (_a = updatedCategories.find(({ id }) => Number(id) === Number(idsArr[1]))) === null || _a === void 0 ? void 0 : _a.title;
        const params = { entry_type: userCategoryTitle };
        if (handlerFnForDevices) {
            // @ts-ignore
            window.logEvent(DEVICES_ENTRY_CHANGED, {
                entry_type: categoryTitle,
            });
            if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(DEVICES_ENTRY_CHANGED));
            }
            setIsFormSent(true);
            return handlerFnForDevices(null, userIds, Number(idsArr[0]), categoryTitle);
        }
        setUserForTagShown(false);
        setIsFormSent(true);
        // @ts-ignore
        window.logEvent(ACTIVITY_USER_ENTRY_CHANGED, params);
        const actionToDispatch = getActionToDispatch({
            userCategoryTitle,
            userIds,
            idsArr,
            companyId,
            categories,
        });
        dispatchUserAction(actionToDispatch);
    };
    return (React.createElement("div", null,
        React.createElement("label", { className: `${inputLabel}`, style: { marginBottom: '1.625rem' }, htmlFor: "entry" }, title),
        React.createElement(Form, { onSubmit: handleSubmit },
            isFormSent ? (React.createElement("div", { style: { height: '300px' } },
                React.createElement(Loader, null))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { id: "my-radio-group" }),
                React.createElement("div", { style: {
                        borderTop: '.5px solid #dee2e6',
                        marginLeft: '-1.5rem',
                        marginRight: '-1.5rem',
                        marginBottom: '15px',
                        display: 'block',
                    }, role: "group", "aria-labelledby": "my-radio-group" },
                    React.createElement("div", { style: {
                            marginLeft: '1.5rem',
                            marginRight: '1.5rem',
                        } },
                        React.createElement("label", { className: `${inputLabel}`, style: { marginTop: '0.75rem', marginBottom: '0.75rem' }, htmlFor: "site" }, t('WEARABLES.ACTIVITY.DIALOGS.SELECT_CATEGORY')),
                        React.createElement(CustomSelect, { name: undefined, value: categoryPicked, options: categoriesSelectOptions(), filterByLabel: undefined, dropdownIndicator: true, isSearchable: false, clearIndicator: true, onChange: (_, category) => {
                                setSelectedEntry('');
                                setCategoryPicked(category);
                                setSearchValue('');
                            }, components: undefined, formik: undefined, customFormikCallback: undefined }),
                        React.createElement("label", { className: `${inputLabel}`, htmlFor: "site", style: { marginBottom: '0.375rem', marginTop: '0.75rem' } }, t('WEARABLES.ACTIVITY.DIALOGS.SEARCH_ENTRY')),
                        React.createElement("input", { className: `${formControlInput} ${mt3}`, type: "text", id: "site", placeholder: t('WEARABLES.ACTIVITY.DIALOGS.SEARCH'), ref: undefined, disabled: undefined, value: searchValue, onChange: (e) => {
                                setSelectedEntry('');
                                setSearchValue(e.target.value.replaceAll(',', ''));
                            }, style: { marginBottom: '0.75rem', top: '10px' } }),
                        React.createElement(RadioGroup, { value: selectedEntry, name: "entryPicker", onChange: (value) => {
                                // @ts-ignore
                                setSelectedEntry(value);
                            }, style: {
                                height: '18rem',
                                overflow: 'auto',
                            } }, tags.length > 0 ? (tags.map((tag) => {
                            var _a;
                            const categoryName = (_a = categories.find((category) => category.id === tag.companySegmentId)) === null || _a === void 0 ? void 0 : _a.title;
                            return (React.createElement(Radio, { name: "picked", key: tag.id + Math.random(), value: `${tag.id} ${tag.companySegmentId}`, className: entryItem, style: {
                                    marginLeft: '-0.5rem',
                                    marginBottom: '0.375rem',
                                } },
                                React.createElement("span", null, `${tag.title} `),
                                React.createElement("span", { style: {
                                        color: COLORS.GRAY[400],
                                        fontSize: '12px',
                                        display: 'block',
                                    } }, categoryName &&
                                    prepareSeparatedTitle(categoryName, t))));
                        })) : (React.createElement("div", { className: flexColumn },
                            React.createElement("button", { className: invisibleButton('small', COLORS.LIGHT_BLUE[600]), type: "button", style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    stroke: categoryPicked && searchValue !== ''
                                        ? COLORS.LIGHT_BLUE[600]
                                        : COLORS.GRAY[400],
                                }, onClick: () => {
                                    const categoryId = categoryPicked && categoryPicked.value;
                                    // eslint-disable-next-line no-unused-expressions,no-nested-ternary
                                    categoryId !== null && categoryId > 0
                                        ? dispatch(createEntry({
                                            companyId,
                                            categoryId,
                                            title: searchValue,
                                        }))
                                        : categoryId === -1
                                            ? dispatch(createDepartment(companyId, searchValue, true))
                                            : dispatch(createSite(companyId, searchValue, true));
                                    setSearchValue('');
                                }, disabled: !categoryPicked || searchValue === '' },
                                React.createElement(Plus, null),
                                React.createElement("span", { style: { lineHeight: '1.5rem', paddingLeft: '.5rem' } },
                                    t('GENERAL.CREATE'),
                                    ' ',
                                    "\"",
                                    searchValue,
                                    "\"",
                                    t('WEARABLES.ACTIVITY.DIALOGS.ENTRY_TO_USER'))),
                            searchValue === '' && !categoryPicked && (React.createElement("div", { style: { color: COLORS.RED[600], paddingLeft: '10px' } }, t('WEARABLES.ACTIVITY.DIALOGS.PLEASE_FILL'))),
                            !categoryPicked && (React.createElement("div", { style: { color: COLORS.RED[600], paddingLeft: '10px' } }, t('WEARABLES.ACTIVITY.DIALOGS.PLEASE_SELECT')))))))))),
            !isFormSent && (React.createElement("div", { className: entryBtn },
                React.createElement(Button, { disabled: !selectedEntry, text: t('WEARABLES.ACTIVITY.DIALOGS.LINK_ENTRY'), type: "submit", style: { display: 'block' } }))))));
};
export default LinkToEntry;
