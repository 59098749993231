import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ProductPreviewContainer from 'containers/ProductPreviewContainer/ProductPreviewContainer';
import SubscriptionEnded from './components/SubscriptionEnded';
import { getHazardsConfig } from '../user/ProgramContainer/utils/getHazardsConfig';
import usePrevious from '../../lib/utils/usePreviuos';
import { setSelectedGrouping } from './overviewState/actions';
import { contactSalesProductType, productStatus } from '../../init/proto_client';
import { getOverview } from './overviewStore/actions';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';
import { isLoadingSelector } from './overviewStore/selectors';
import Loader from '../../lib/ui/Loader';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { productPreview } from '../../containers/ProductPreviewContainer/productPreview';
import StringDataSelectionFilter from './components/StringDataSelectionFilter';
import OverviewContainer from './OverviewContainer';
import OverviewPdfContainer from './PdfReportContainer/OverviewPdfContainer';
const Overview = () => {
    var _a;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { isContactSales } = useAppSelector((state) => state.auth);
    const { selectedCompany } = useAppSelector((state) => state.companies);
    const { departmentsList, sitesList, products } = selectedCompany;
    const departments = [{ id: 0, name: t('WEARABLES.OVERVIEW.NO_DEPARTMENT') }, ...departmentsList];
    const sites = [{ id: 0, name: t('WEARABLES.OVERVIEW.NO_SITE') }, ...sitesList];
    const overviewIsLoading = useAppSelector(isLoadingSelector);
    const { period, siteIdsList, departmentIdsList, fullCompanyData, companyId, } = useAppSelector((state) => state.overview.overview);
    const [showOverview, setShowOverview] = useState(false);
    const [renderedRange, setRenderedRange] = useState(t('WEARABLES.OVERVIEW.ALL_TIME'));
    const companyConfig = (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.tier) ? getHazardsConfig(selectedCompany.tier) : null;
    const oldCompanyId = usePrevious(selectedCompany.id);
    const setGrouping = (groupRule, chart) => {
        dispatch(setSelectedGrouping(groupRule, chart));
    };
    const showCurrentSubscription = (status) => {
        var _a, _b;
        switch (status) {
            case productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE:
                return (React.createElement(React.Fragment, null, overviewIsLoading ? React.createElement(Loader, null) : (React.createElement(React.Fragment, null,
                    period && (React.createElement(StringDataSelectionFilter, { period: period, departments: departments, selectedDepartments: departmentIdsList, sites: sites, selectedSites: siteIdsList, noDataForReport: !((_a = fullCompanyData === null || fullCompanyData === void 0 ? void 0 : fullCompanyData.completedSession) === null || _a === void 0 ? void 0 : _a.value), onRenderedRangeChange: setRenderedRange, getOverviewData: (newPeriod, departmentsData, sitesData) => {
                            dispatch(getOverview(selectedCompany.id, newPeriod, departmentsData, sitesData, 'setDateFilter'));
                        } })),
                    ((_b = fullCompanyData === null || fullCompanyData === void 0 ? void 0 : fullCompanyData.activeUsers) === null || _b === void 0 ? void 0 : _b.value) > 0 &&
                        (React.createElement(OverviewPdfContainer, { globalData: fullCompanyData, isGrayScale: false, timePeriod: renderedRange, selectedDepartmentNames: departmentIdsList.map((id) => departments.find((d) => d.id === id)), selectedSiteNames: siteIdsList.map((id) => sites.find((d) => d.id === id)) })),
                    fullCompanyData || showOverview ? (React.createElement(OverviewContainer, { data: fullCompanyData, setGrouping: setGrouping, companyConfig: companyConfig })) : React.createElement(Loader, null)))));
            case productStatus.PRODUCTSTATUSSUBSCRIPTIONFINISHED:
                return React.createElement(SubscriptionEnded, { name: "Wearables", productInfo: products === null || products === void 0 ? void 0 : products.wearableProduct });
            default:
                return (React.createElement(ProductPreviewContainer, { productInfo: products.wearableProduct, productType: contactSalesProductType.PRODUCTTYPEWEARABLE, layout: productPreview.CoachPreviewProduct(t) }));
        }
    };
    useEffect(() => {
        if (selectedCompany.id) {
            dispatch(getOverview(selectedCompany.id, {
                startedAt: selectedCompany.createdAtInMilliseconds,
                finishedAt: moment().format('X'),
            }, departments.map(({ id }) => id), sites.map(({ id }) => id)));
        }
    }, []);
    useEffect(() => {
        if (selectedCompany.id && oldCompanyId && (oldCompanyId !== selectedCompany.id || isContactSales)) {
            dispatch(getOverview(selectedCompany.id, {
                startedAt: selectedCompany.createdAtInMilliseconds,
                finishedAt: moment().format('X'),
            }, departments.map(({ id }) => id), sites.map(({ id }) => id)));
        }
    }, [selectedCompany]);
    useEffect(() => {
        var _a;
        if (!((_a = fullCompanyData === null || fullCompanyData === void 0 ? void 0 : fullCompanyData.newUsers) === null || _a === void 0 ? void 0 : _a.value) && companyId) {
            setShowOverview(true);
        }
    }, [fullCompanyData]);
    return showCurrentSubscription((_a = products === null || products === void 0 ? void 0 : products.wearableProduct) === null || _a === void 0 ? void 0 : _a.status);
};
export default PrivatePageHOC(Overview);
