import React from 'react';
import Tooltip from './Tooltip';
import infoIcon from '../../assets/img/icons/info.svg';
import { INFORMATION_TOOLTIP_APPEAR } from '../../constants/analyticsEvents';
import { useAppDispatch } from '../utils/useStore';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';

const styleForAbsolute = (grouped, isWide) => ({
  position: 'absolute',
  zIndex: 6,
  right: grouped ? '140px' : isWide ? '21px' : '-13px',
  top: grouped ? '-37px' : isWide ? '11px' : '-15px',
});

const InfoTooltip = ({
  // text for tooltip should be i18 string
  text, isAbs, forGrouped, isWide,
}) => {
  const dispatch = useAppDispatch();

  return (
    <div style={isAbs ? styleForAbsolute(forGrouped, isWide) : {}}>
      <Tooltip
        text={text}
        placement="topEnd"
      >
        <img
          onMouseEnter={() => {
            window.logEvent(INFORMATION_TOOLTIP_APPEAR);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(INFORMATION_TOOLTIP_APPEAR));
            }
          }}
          style={{ width: '24px', height: '24px' }}
          src={infoIcon}
          alt="i"
        />
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
