import React, { useState } from 'react';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import { COLORS } from 'constants/colors';
import { text } from '../../../assets/jss/style';
import { sectionWrapper, subscriptionWrapper } from './styles';
import SubscriptionItem from './SubscriptionItem';
import { accordionHandler } from '../utils';
const Products = ({ company, editMode, wearableInfo, setWearableInfo, jobDictionaryInfo, setJobDictionaryInfo, copilotInfo, setCopilotInfo, setIsCalendarTouched, t, }) => {
    const [isOpenAccordion, setIsOpenAccordion] = useState(true);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: sectionWrapper, onClick: () => accordionHandler(isOpenAccordion, setIsOpenAccordion) },
            React.createElement("h3", { className: text(COLORS.GRAY[950], 24, 800) }, t('ADMIN.PRODUCT_CONFIGURATION')),
            React.createElement(ArrowDownLineIcon, { className: isOpenAccordion ? '' : 'hidden' })),
        editMode ? (React.createElement("div", { className: `${subscriptionWrapper} ${isOpenAccordion ? '' : 'hidden'}` },
            React.createElement(SubscriptionItem, { title: "wearable", info: company.wearable, editMode: editMode, itemInfo: wearableInfo, setItemInfo: setWearableInfo, setIsCalendarTouched: setIsCalendarTouched, t: t }),
            React.createElement(SubscriptionItem, { title: "jobDictionary", info: company.jobDictionary, editMode: editMode, itemInfo: jobDictionaryInfo, setItemInfo: setJobDictionaryInfo, calendarPosition: "bottomEnd", setIsCalendarTouched: setIsCalendarTouched, t: t }),
            React.createElement(SubscriptionItem, { title: "soterGenius", info: company.genius, editMode: editMode, itemInfo: copilotInfo, setItemInfo: setCopilotInfo, calendarPosition: "topStart", setIsCalendarTouched: setIsCalendarTouched, t: t }))) : (React.createElement("div", { className: `${subscriptionWrapper} ${isOpenAccordion ? '' : 'hidden'}` },
            React.createElement(SubscriptionItem, { title: "wearable", info: company.wearable, t: t }),
            React.createElement(SubscriptionItem, { title: "jobDictionary", info: company.jobDictionary, t: t }),
            React.createElement(SubscriptionItem, { title: "soterGenius", info: company.genius, t: t })))));
};
export default Products;
