import React from 'react';
import moment from 'moment';
import { Text } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';

const LastSyncCell = ({ rowData, started }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {rowData.isPassedProgramCopy && !started ? (
        ''
      ) : rowData.wearingNow && !started ? (
        <div style={{ color: COLORS.GREEN[600], fontSize: '13px' }}>
          {t('WEARABLES.DEVICES.FILTERS.WEARING')}
        </div>
      ) : (
          started
            ? rowData.program?.startedAt
            : rowData.program?.progress?.lastSession
        ) ? (
        <Text>
          {moment(
            started
              ? rowData.program?.startedAt * 1000
              : rowData.program?.progress.lastSession
          ).format('DD MMM YYYY')}
        </Text>
      ) : (
        <Text style={{ fontSize: '12px' }} muted>
          -
        </Text>
      )}
    </>
  );
};

export default LastSyncCell;
