import * as Emotion from 'emotion';

/* eslint import/prefer-default-export:0 */
export const container = Emotion.css`{
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1240px) {
    max-width: 1200px;
  }

  @media print {
    max-width: none;
    min-width: 992px;
  }
}`;

export const overflow = (heightToExtract) => Emotion.css`{
  overflow-y: auto;
  height: calc(100vh - ${heightToExtract}px);
  margin: 0 !important;
  @media (min-width: 992px) {
    height: calc(100vh - ${heightToExtract}px);
  }
  `;
