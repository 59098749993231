import React from 'react';
import { badge } from '../../assets/soter-flex-styles';

const Badge = ({ badgeId, badgeMap }) => {
  const existedBadge = badgeMap[badgeId];
  if (!badgeId || !existedBadge) return null;

  const { color, title } = existedBadge;

  return (
    <div className={badge(color)}>
      {title}
    </div>
  );
};

export default Badge;
