import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { DatePicker } from 'rsuite';
import { useTranslation } from 'react-i18next';
import {
  flexColumn, flexRow, mb5, pl3,
} from '../../assets/soter-flex-styles';
import { COLORS } from '../../constants/colors';
import calendar from '../../assets/img/icons/calendar.svg';
import clock from '../../assets/img/icons/clock.svg';
import { dot, DOTClassificationsToColors } from '../constants';
import CustomInput from '../../lib/ui/CustomInput';

export const editingHeader = Emotion.css`
  ${mb5};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 31px 1fr 1fr;
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 3px;
  background-color: ${COLORS.WHITE};
  width: 1151px;
  height: 410px;
  padding-top: 19px;
`;

export const editingHeaderSlot = Emotion.css`
  min-height: 35px;
  padding: 0 14px;
  span {
    font-weight: 600;
    margin-bottom: 9px;
  }
`;

const editingHeaderDateSlot = Emotion.css`
  ${editingHeaderSlot};

  span {
    margin-bottom: 0;
    font-weight: 500;
  }
  div {
    font-weight: 600;
  }
`;

export const subHeader = Emotion.css`
  font-size: 16px;
  padding-left: 14px;
  font-weight: 600;
`;

export const DOTLabel = (color) => Emotion.css`
  background-color: ${color};
  color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 3px;
  font-size: 13px;
  margin-right: 7px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DOTButton = (color, isSelected) => Emotion.css`
  ${DOTLabel(color)};
  background-color: ${isSelected ? color : 'transparent'};
  color: ${isSelected ? COLORS.WHITE : color};
`;

export const DOTSmallButton = (color, isSelected) => Emotion.css`
  ${DOTButton(color, isSelected)};
  padding: 3px;
  height: 28px;
  margin-left: 15px;
  display: inline;
  white-space: nowrap;
  &:hover {
    cursor: default;
    border: 1px solid ${COLORS.GRAY[100]};
  }
`;

export const ButtonContainer = Emotion.css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const EditableJobProfileHeader = ({
  currentRole,
  editMode,
  setCurrentRole,
}) => {
  const {
    title,
    businessUnit,
    rosterDays,
    rosterHoursShift,
    dotClassification,
    nextReviewInMilliseconds,
    assessedBy,
    occupationCategory,
  } = currentRole;
  const { t } = useTranslation();
  const [selectedDOT, setSelectedDOT] = useState(dotClassification);
  const changeFieldInState = (n, v) => setCurrentRole({ ...currentRole, [n]: v });
  useEffect(() => {
    setSelectedDOT(dotClassification);
  }, [dotClassification]);
  return editMode ? (
    <div className={editingHeader}>
      <div className={editingHeaderSlot}>
        <span>{`${t('GENERAL.JOB_TITLE')} *`}</span>
        <CustomInput name="title" value={title} onChange={changeFieldInState} />
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.DOT_CLASSIFICATION')}</span>
        <div className={ButtonContainer}>
          {dot.map((v) => (
            <div
              className={DOTButton(
                DOTClassificationsToColors[v],
                selectedDOT === dot.indexOf(v),
              )}
              onClick={() => {
                setCurrentRole({
                  ...currentRole,
                  dotClassification: dot.indexOf(v),
                });
              }}
              key={v}
            >
              {t(v).toUpperCase()}
            </div>
          ))}
        </div>
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.JOB_PROFILE.UNIT')}</span>
        <CustomInput
          name="businessUnit"
          value={businessUnit}
          onChange={changeFieldInState}
        />
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.JOB_PROFILE.CATEGORY')}</span>
        <CustomInput
          name="occupationCategory"
          value={occupationCategory}
          onChange={changeFieldInState}
        />
      </div>
      <div className={subHeader}>{t('JOB_DICTIONARY.JOB_PROFILE.ROSTER')}</div>
      <div />
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.JOB_PROFILE.SHIFT')}</span>
        <CustomInput
          name="rosterHoursShift"
          value={rosterHoursShift}
          onChange={changeFieldInState}
        />
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.JOB_PROFILE.DAYS')}</span>
        <CustomInput
          name="rosterDays"
          value={rosterDays}
          onChange={changeFieldInState}
        />
      </div>
      <div className={editingHeaderSlot}>
        <span>{t('JOB_DICTIONARY.JOB_PROFILE.ASSESSED')}</span>
        <CustomInput
          name="assessedBy"
          value={assessedBy}
          onChange={changeFieldInState}
        />
      </div>
      <div className={editingHeaderDateSlot}>
        <div>{t('JOB_DICTIONARY.JOB_PROFILE.NEXT_REVIEW')}</div>
        <DatePicker
          block
          format="MMM dd, yyyy"
          ranges={[]}
          value={
            nextReviewInMilliseconds
              ? new Date(nextReviewInMilliseconds)
              : new Date()
          }
          onSelect={(v) => {
            setCurrentRole({
              ...currentRole,
              nextReviewInMilliseconds: v.getTime(),
            });
          }}
          onClean={() => setCurrentRole({
            ...currentRole,
            nextReviewInMilliseconds: new Date().getTime(),
          })}
        />
      </div>
    </div>
  ) : (
    <div
      className={flexRow}
      style={{ display: 'flex', alignItems: 'baseline', marginBottom: '12px' }}
    >
      <div className={`${flexColumn} ${pl3}`}>
        <div style={{ fontSize: '14px', color: COLORS.GRAY[400], marginTop: '20px' }}>
          {businessUnit?.toUpperCase()}
        </div>
        <div
          style={{
            fontSize: '32px',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>{title}</span>
          {/* <div
            className={DOTSmallButton(
              DOTClassificationsToColors[dot[selectedDOT]],
              true
            )}
          >
            <div>
              {t(dot[selectedDOT])[0]
                .toUpperCase()
                .concat(t(dot[selectedDOT]).slice(1))}
            </div>
          </div> */}
        </div>
        {occupationCategory && (
          <div>
            <span style={{ color: COLORS.GRAY[400] }}>
              {`${t(
                'JOB_DICTIONARY.JOB_PROFILE.CATEGORY',
              )}: `}
            </span>
            <span>{occupationCategory}</span>
          </div>
        )}
        {(rosterDays || rosterHoursShift) && (
          <div
            style={{
              fontSize: '14px',
              color: COLORS.GRAY[400],
              marginBottom: '14px',
              marginTop: '12px',
            }}
          >
            <span style={{ marginRight: '14px' }}>
              <img style={{ marginRight: '7px' }} src={clock} />
              {rosterHoursShift}
            </span>
            <span>
              <img style={{ marginRight: '7px' }} src={calendar} />
              {rosterDays}
            </span>
          </div>
        )}
        {assessedBy && (
          <div>
            <span style={{ color: COLORS.GRAY[400] }}>
              {`${t(
                'JOB_DICTIONARY.JOB_PROFILE.ASSESSED',
              )}: `}
            </span>
            <span>{assessedBy}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableJobProfileHeader;
