export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANIES_WITH_DEPARTMENTS = 'SET_COMPANIES_WITH_DEPARTMENTS';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_COMPANIES_ERROR = 'SET_ERROR_COMPANIES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const RESET_COMPANIES = 'RESET_COMPANIES';
export const RESET_SELECTED_COMPANY = 'RESET_SELECTED_COMPANY';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';
export const REMOVE_DEPARTMENT_FROM_CURRENT_COMPANY = 'REMOVE_DEPARTMENT_FROM_CURRENT_COMPANY';
export const EDIT_DEPARTMENT_IN_CURRENT_COMPANY = 'EDIT_DEPARTMENT_IN_CURRENT_COMPANY';
export const REMOVE_SITE_FROM_CURRENT_COMPANY = 'REMOVE_SITE_FROM_CURRENT_COMPANY';
export const EDIT_SITE_IN_CURRENT_COMPANY = 'EDIT_SITE_IN_CURRENT_COMPANY';
