// source: api/dashboard/v2/devices_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_site_message_pb);
goog.exportSymbol('proto.devices.DeviceSetCoachingProgramRequest', null, global);
goog.exportSymbol('proto.devices.DeviceSetStatusRequest', null, global);
goog.exportSymbol('proto.devices.DevicesCompany', null, global);
goog.exportSymbol('proto.devices.DevicesDevice', null, global);
goog.exportSymbol('proto.devices.DevicesProgram', null, global);
goog.exportSymbol('proto.devices.DevicesReportRequest', null, global);
goog.exportSymbol('proto.devices.DevicesReportResponse', null, global);
goog.exportSymbol('proto.devices.DevicesRequest', null, global);
goog.exportSymbol('proto.devices.DevicesResponse', null, global);
goog.exportSymbol('proto.devices.DevicesSetDepartmentRequest', null, global);
goog.exportSymbol('proto.devices.DevicesSetSiteRequest', null, global);
goog.exportSymbol('proto.devices.DevicesUser', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devices.DevicesUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesUser.displayName = 'proto.devices.DevicesUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devices.DevicesProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesProgram.displayName = 'proto.devices.DevicesProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devices.DevicesCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesCompany.displayName = 'proto.devices.DevicesCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devices.DevicesDevice.repeatedFields_, null);
};
goog.inherits(proto.devices.DevicesDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesDevice.displayName = 'proto.devices.DevicesDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devices.DevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.devices.DevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesResponse.displayName = 'proto.devices.DevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devices.DevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesRequest.displayName = 'proto.devices.DevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesSetDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devices.DevicesSetDepartmentRequest.repeatedFields_, null);
};
goog.inherits(proto.devices.DevicesSetDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesSetDepartmentRequest.displayName = 'proto.devices.DevicesSetDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesSetSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devices.DevicesSetSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.devices.DevicesSetSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesSetSiteRequest.displayName = 'proto.devices.DevicesSetSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DeviceSetStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devices.DeviceSetStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.devices.DeviceSetStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DeviceSetStatusRequest.displayName = 'proto.devices.DeviceSetStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DeviceSetCoachingProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devices.DeviceSetCoachingProgramRequest.repeatedFields_, null);
};
goog.inherits(proto.devices.DeviceSetCoachingProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DeviceSetCoachingProgramRequest.displayName = 'proto.devices.DeviceSetCoachingProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devices.DevicesReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesReportRequest.displayName = 'proto.devices.DevicesReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devices.DevicesReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devices.DevicesReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devices.DevicesReportResponse.displayName = 'proto.devices.DevicesReportResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesUser.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastActivity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    department: (f = msg.getDepartment()) && api_dashboard_v2_department_message_pb.Department.toObject(includeInstance, f),
    userType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesUser}
 */
proto.devices.DevicesUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesUser;
  return proto.devices.DevicesUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesUser}
 */
proto.devices.DevicesUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 6:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastActivity();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.devices.DevicesUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.devices.DevicesUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.devices.DevicesUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 last_activity = 4;
 * @return {number}
 */
proto.devices.DevicesUser.prototype.getLastActivity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setLastActivity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string device_type = 5;
 * @return {string}
 */
proto.devices.DevicesUser.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional department_message.Department department = 6;
 * @return {?proto.department_message.Department}
 */
proto.devices.DevicesUser.prototype.getDepartment = function() {
  return /** @type{?proto.department_message.Department} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_department_message_pb.Department, 6));
};


/**
 * @param {?proto.department_message.Department|undefined} value
 * @return {!proto.devices.DevicesUser} returns this
*/
proto.devices.DevicesUser.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devices.DevicesUser.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string user_type = 7;
 * @return {string}
 */
proto.devices.DevicesUser.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string nickname = 8;
 * @return {string}
 */
proto.devices.DevicesUser.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesUser} returns this
 */
proto.devices.DevicesUser.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesProgram}
 */
proto.devices.DevicesProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesProgram;
  return proto.devices.DevicesProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesProgram}
 */
proto.devices.DevicesProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.devices.DevicesProgram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesProgram} returns this
 */
proto.devices.DevicesProgram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.devices.DevicesProgram.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesProgram} returns this
 */
proto.devices.DevicesProgram.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesCompany}
 */
proto.devices.DevicesCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesCompany;
  return proto.devices.DevicesCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesCompany}
 */
proto.devices.DevicesCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.devices.DevicesCompany.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesCompany} returns this
 */
proto.devices.DevicesCompany.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.devices.DevicesCompany.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesCompany} returns this
 */
proto.devices.DevicesCompany.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devices.DevicesDevice.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    macAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    lastBatteryCharge: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastSynced: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentUser: (f = msg.getCurrentUser()) && proto.devices.DevicesUser.toObject(includeInstance, f),
    lastUser: (f = msg.getLastUser()) && proto.devices.DevicesUser.toObject(includeInstance, f),
    availableProgramsList: jspb.Message.toObjectList(msg.getAvailableProgramsList(),
    proto.devices.DevicesProgram.toObject, includeInstance),
    department: (f = msg.getDepartment()) && api_dashboard_v2_department_message_pb.Department.toObject(includeInstance, f),
    site: (f = msg.getSite()) && api_dashboard_v2_site_message_pb.Site.toObject(includeInstance, f),
    batch: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesDevice}
 */
proto.devices.DevicesDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesDevice;
  return proto.devices.DevicesDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesDevice}
 */
proto.devices.DevicesDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastBatteryCharge(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSynced(value);
      break;
    case 6:
      var value = new proto.devices.DevicesUser;
      reader.readMessage(value,proto.devices.DevicesUser.deserializeBinaryFromReader);
      msg.setCurrentUser(value);
      break;
    case 7:
      var value = new proto.devices.DevicesUser;
      reader.readMessage(value,proto.devices.DevicesUser.deserializeBinaryFromReader);
      msg.setLastUser(value);
      break;
    case 8:
      var value = new proto.devices.DevicesProgram;
      reader.readMessage(value,proto.devices.DevicesProgram.deserializeBinaryFromReader);
      msg.addAvailablePrograms(value);
      break;
    case 9:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    case 10:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLastBatteryCharge();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLastSynced();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCurrentUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.devices.DevicesUser.serializeBinaryToWriter
    );
  }
  f = message.getLastUser();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.devices.DevicesUser.serializeBinaryToWriter
    );
  }
  f = message.getAvailableProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.devices.DevicesProgram.serializeBinaryToWriter
    );
  }
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
  f = message.getBatch();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.devices.DevicesDevice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string mac_address = 2;
 * @return {string}
 */
proto.devices.DevicesDevice.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.devices.DevicesDevice.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 last_battery_charge = 4;
 * @return {number}
 */
proto.devices.DevicesDevice.prototype.getLastBatteryCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.setLastBatteryCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 last_synced = 5;
 * @return {number}
 */
proto.devices.DevicesDevice.prototype.getLastSynced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.setLastSynced = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional DevicesUser current_user = 6;
 * @return {?proto.devices.DevicesUser}
 */
proto.devices.DevicesDevice.prototype.getCurrentUser = function() {
  return /** @type{?proto.devices.DevicesUser} */ (
    jspb.Message.getWrapperField(this, proto.devices.DevicesUser, 6));
};


/**
 * @param {?proto.devices.DevicesUser|undefined} value
 * @return {!proto.devices.DevicesDevice} returns this
*/
proto.devices.DevicesDevice.prototype.setCurrentUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.clearCurrentUser = function() {
  return this.setCurrentUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devices.DevicesDevice.prototype.hasCurrentUser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DevicesUser last_user = 7;
 * @return {?proto.devices.DevicesUser}
 */
proto.devices.DevicesDevice.prototype.getLastUser = function() {
  return /** @type{?proto.devices.DevicesUser} */ (
    jspb.Message.getWrapperField(this, proto.devices.DevicesUser, 7));
};


/**
 * @param {?proto.devices.DevicesUser|undefined} value
 * @return {!proto.devices.DevicesDevice} returns this
*/
proto.devices.DevicesDevice.prototype.setLastUser = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.clearLastUser = function() {
  return this.setLastUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devices.DevicesDevice.prototype.hasLastUser = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated DevicesProgram available_programs = 8;
 * @return {!Array<!proto.devices.DevicesProgram>}
 */
proto.devices.DevicesDevice.prototype.getAvailableProgramsList = function() {
  return /** @type{!Array<!proto.devices.DevicesProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devices.DevicesProgram, 8));
};


/**
 * @param {!Array<!proto.devices.DevicesProgram>} value
 * @return {!proto.devices.DevicesDevice} returns this
*/
proto.devices.DevicesDevice.prototype.setAvailableProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.devices.DevicesProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devices.DevicesProgram}
 */
proto.devices.DevicesDevice.prototype.addAvailablePrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.devices.DevicesProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.clearAvailableProgramsList = function() {
  return this.setAvailableProgramsList([]);
};


/**
 * optional department_message.Department department = 9;
 * @return {?proto.department_message.Department}
 */
proto.devices.DevicesDevice.prototype.getDepartment = function() {
  return /** @type{?proto.department_message.Department} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_department_message_pb.Department, 9));
};


/**
 * @param {?proto.department_message.Department|undefined} value
 * @return {!proto.devices.DevicesDevice} returns this
*/
proto.devices.DevicesDevice.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devices.DevicesDevice.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional site_message.Site site = 10;
 * @return {?proto.site_message.Site}
 */
proto.devices.DevicesDevice.prototype.getSite = function() {
  return /** @type{?proto.site_message.Site} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_site_message_pb.Site, 10));
};


/**
 * @param {?proto.site_message.Site|undefined} value
 * @return {!proto.devices.DevicesDevice} returns this
*/
proto.devices.DevicesDevice.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devices.DevicesDevice.prototype.hasSite = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string batch = 11;
 * @return {string}
 */
proto.devices.DevicesDevice.prototype.getBatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.devices.DevicesDevice} returns this
 */
proto.devices.DevicesDevice.prototype.setBatch = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devices.DevicesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    company: (f = msg.getCompany()) && proto.devices.DevicesCompany.toObject(includeInstance, f),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.devices.DevicesDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesResponse}
 */
proto.devices.DevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesResponse;
  return proto.devices.DevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesResponse}
 */
proto.devices.DevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.devices.DevicesCompany;
      reader.readMessage(value,proto.devices.DevicesCompany.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 2:
      var value = new proto.devices.DevicesDevice;
      reader.readMessage(value,proto.devices.DevicesDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.devices.DevicesCompany.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.devices.DevicesDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional DevicesCompany company = 1;
 * @return {?proto.devices.DevicesCompany}
 */
proto.devices.DevicesResponse.prototype.getCompany = function() {
  return /** @type{?proto.devices.DevicesCompany} */ (
    jspb.Message.getWrapperField(this, proto.devices.DevicesCompany, 1));
};


/**
 * @param {?proto.devices.DevicesCompany|undefined} value
 * @return {!proto.devices.DevicesResponse} returns this
*/
proto.devices.DevicesResponse.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devices.DevicesResponse} returns this
 */
proto.devices.DevicesResponse.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devices.DevicesResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DevicesDevice devices = 2;
 * @return {!Array<!proto.devices.DevicesDevice>}
 */
proto.devices.DevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.devices.DevicesDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devices.DevicesDevice, 2));
};


/**
 * @param {!Array<!proto.devices.DevicesDevice>} value
 * @return {!proto.devices.DevicesResponse} returns this
*/
proto.devices.DevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.devices.DevicesDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devices.DevicesDevice}
 */
proto.devices.DevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.devices.DevicesDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DevicesResponse} returns this
 */
proto.devices.DevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesRequest}
 */
proto.devices.DevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesRequest;
  return proto.devices.DevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesRequest}
 */
proto.devices.DevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.devices.DevicesRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesRequest} returns this
 */
proto.devices.DevicesRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devices.DevicesSetDepartmentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesSetDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesSetDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesSetDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesSetDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    departmentId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesSetDepartmentRequest}
 */
proto.devices.DevicesSetDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesSetDepartmentRequest;
  return proto.devices.DevicesSetDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesSetDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesSetDepartmentRequest}
 */
proto.devices.DevicesSetDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesSetDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesSetDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesSetDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesSetDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 device_ids = 1;
 * @return {!Array<number>}
 */
proto.devices.DevicesSetDepartmentRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.devices.DevicesSetDepartmentRequest} returns this
 */
proto.devices.DevicesSetDepartmentRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.devices.DevicesSetDepartmentRequest} returns this
 */
proto.devices.DevicesSetDepartmentRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DevicesSetDepartmentRequest} returns this
 */
proto.devices.DevicesSetDepartmentRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional int32 department_id = 2;
 * @return {number}
 */
proto.devices.DevicesSetDepartmentRequest.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesSetDepartmentRequest} returns this
 */
proto.devices.DevicesSetDepartmentRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devices.DevicesSetSiteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesSetSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesSetSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesSetSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesSetSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    siteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesSetSiteRequest}
 */
proto.devices.DevicesSetSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesSetSiteRequest;
  return proto.devices.DevicesSetSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesSetSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesSetSiteRequest}
 */
proto.devices.DevicesSetSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesSetSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesSetSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesSetSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesSetSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 device_ids = 1;
 * @return {!Array<number>}
 */
proto.devices.DevicesSetSiteRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.devices.DevicesSetSiteRequest} returns this
 */
proto.devices.DevicesSetSiteRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.devices.DevicesSetSiteRequest} returns this
 */
proto.devices.DevicesSetSiteRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DevicesSetSiteRequest} returns this
 */
proto.devices.DevicesSetSiteRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional int32 site_id = 2;
 * @return {number}
 */
proto.devices.DevicesSetSiteRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesSetSiteRequest} returns this
 */
proto.devices.DevicesSetSiteRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devices.DeviceSetStatusRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DeviceSetStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DeviceSetStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DeviceSetStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DeviceSetStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DeviceSetStatusRequest}
 */
proto.devices.DeviceSetStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DeviceSetStatusRequest;
  return proto.devices.DeviceSetStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DeviceSetStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DeviceSetStatusRequest}
 */
proto.devices.DeviceSetStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DeviceSetStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DeviceSetStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DeviceSetStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DeviceSetStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated int32 device_ids = 1;
 * @return {!Array<number>}
 */
proto.devices.DeviceSetStatusRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.devices.DeviceSetStatusRequest} returns this
 */
proto.devices.DeviceSetStatusRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.devices.DeviceSetStatusRequest} returns this
 */
proto.devices.DeviceSetStatusRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DeviceSetStatusRequest} returns this
 */
proto.devices.DeviceSetStatusRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.devices.DeviceSetStatusRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devices.DeviceSetStatusRequest} returns this
 */
proto.devices.DeviceSetStatusRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devices.DeviceSetCoachingProgramRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DeviceSetCoachingProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DeviceSetCoachingProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DeviceSetCoachingProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    coachingProgramIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DeviceSetCoachingProgramRequest}
 */
proto.devices.DeviceSetCoachingProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DeviceSetCoachingProgramRequest;
  return proto.devices.DeviceSetCoachingProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DeviceSetCoachingProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DeviceSetCoachingProgramRequest}
 */
proto.devices.DeviceSetCoachingProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceIds(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCoachingProgramIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DeviceSetCoachingProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DeviceSetCoachingProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DeviceSetCoachingProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getCoachingProgramIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 device_ids = 1;
 * @return {!Array<number>}
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.devices.DeviceSetCoachingProgramRequest} returns this
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.devices.DeviceSetCoachingProgramRequest} returns this
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DeviceSetCoachingProgramRequest} returns this
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * repeated int32 coaching_program_ids = 2;
 * @return {!Array<number>}
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.getCoachingProgramIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.devices.DeviceSetCoachingProgramRequest} returns this
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.setCoachingProgramIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.devices.DeviceSetCoachingProgramRequest} returns this
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.addCoachingProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devices.DeviceSetCoachingProgramRequest} returns this
 */
proto.devices.DeviceSetCoachingProgramRequest.prototype.clearCoachingProgramIdsList = function() {
  return this.setCoachingProgramIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesReportRequest}
 */
proto.devices.DevicesReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesReportRequest;
  return proto.devices.DevicesReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesReportRequest}
 */
proto.devices.DevicesReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 device_id = 2;
 * @return {number}
 */
proto.devices.DevicesReportRequest.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesReportRequest} returns this
 */
proto.devices.DevicesReportRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devices.DevicesReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devices.DevicesReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devices.DevicesReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devices.DevicesReportResponse}
 */
proto.devices.DevicesReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devices.DevicesReportResponse;
  return proto.devices.DevicesReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devices.DevicesReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devices.DevicesReportResponse}
 */
proto.devices.DevicesReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devices.DevicesReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devices.DevicesReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devices.DevicesReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devices.DevicesReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 data = 1;
 * @return {number}
 */
proto.devices.DevicesReportResponse.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devices.DevicesReportResponse} returns this
 */
proto.devices.DevicesReportResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.devices);
