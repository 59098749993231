import React from 'react';
import _sortBy from 'lodash/sortBy';
import SplitChart from './SplitChart';

const HazardByDepartmentByJobRoleChart = ({
  chartId,
  data,
  config,
  setTooltip,
  period,
}) => {
  const hasDataForChart = !!(data && data.length);
  const containerHeight = 256;
  const tickTextSize = '.75rem';
  const tempArr = data ? _sortBy([...data], ['overall']).reverse() : null;
  const dataForRequestedPeriod = tempArr?.slice(period.from, period.to);
  let maxFromAllData = 0;
  data?.forEach((d) => { if (d.overall > maxFromAllData) maxFromAllData = d.overall; });
  return (
    <SplitChart
      chartId={chartId}
      hasDataForChart={hasDataForChart}
      config={config}
      data={dataForRequestedPeriod}
      containerHeight={containerHeight}
      setTooltip={setTooltip}
      withTextOnChart
      tickTextSize={tickTextSize}
      tickTextWeight={600}
      withLegend
      totalMax={maxFromAllData}
    />
  );
};

export default HazardByDepartmentByJobRoleChart;
