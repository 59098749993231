import React from 'react';
import { Table } from 'rsuite';
import { tableCell } from '../../../../assets/jss/style';

const RsuiteCell = Table.Cell;

const Cell = (props) => {
  const {
    children, rowData, column, dataKey,
  } = props;

  return (
    <RsuiteCell dataKey={dataKey} className={tableCell} {...props}>
      {children(rowData, column)}
    </RsuiteCell>
  );
};

export default Cell;
