import * as Emotion from 'emotion';

/* eslint import/prefer-default-export:0 */
export const page = Emotion.css`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
`;

export const pageMain = Emotion.css`
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
`;

export const pageFooter = Emotion.css`
 position: absolute;
 width: 100vw;
 bottom: 0;
 left: 0;
`;
