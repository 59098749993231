import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { filterDataByDepartment } from './utils/filterDataByDepartment';
import { getHazardsConfig, getHiddenFields } from '../user/ProgramContainer/utils/getHazardsConfig';
import { Periods } from './constants/constants';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import { setSelectedDepartmentNames, setSelectedGrouping, setSelectedPeriod } from './overviewState/actions';
import { getOverview } from './overviewStore/actions';

export const ChartType = {
  SPINE_CHART: 'spineChart',
  SHOULDER_CHART: 'shoulderChart',
  ACTIVE_USERS_CHART: 'activeUsers',
  REGISTERED_USERS_CHART: 'registeredUsers',
};

const useOverviewDatasource = (type) => {
  const dispatch = useDispatch();
  const { overview } = useSelector((state) => state.overview);
  const { period, dataByDepartmentList } = overview;
  const {
    selectedDepartmentNames,
    selectedPeriod,
    grouping,
  } = useSelector((state) => state.overviewState);

  const { selectedCompany } = useSelector((state) => state.companies);
  const companyConfig = selectedCompany?.tier
    ? getHazardsConfig(selectedCompany.tier)
    : null;

  const hiddenFields = selectedCompany?.tier ? getHiddenFields(selectedCompany.tier) : {};

  const departments = dataByDepartmentList?.map(({ departmentName }) => departmentName);

  const periods = Object.values(Periods);

  const dataByTypeByDepartment = dataByDepartmentList?.map(
    (data) => {
      switch (type) {
        case TableType.coach:
          return {
            departmentID: data.departmentId,
            departmentName: data.departmentName,
            metric: data.soterCoachData,
          };
        case TableType.clipgo:
          return {
            departmentID: data.departmentId,
            departmentName: data.departmentName,
            metric: data.clipgoData,
          };
        case TableType.all:
          return {
            departmentID: data.departmentId,
            departmentName: data.departmentName,
            metric: data.usersData,
          };
        default: return null;
      }
    },
  );

  const dataByTypeByDepartmentByPeriod = filterDataByDepartment(
    dataByTypeByDepartment,
    selectedDepartmentNames,
  );

  const setDepartmentNames = (depArray) => {
    dispatch(setSelectedDepartmentNames(depArray));
  };

  const getDataForSelectedPeriod = (per) => {
    const periodStart = moment(per[0]).format('X');
    const periodEnd = moment(per[1]).format('X');
    const selectedPeriodDuration = ({ startedAt: periodStart, finishedAt: periodEnd });
    dispatch(getOverview(selectedCompany.id, selectedPeriodDuration, 'setDateFilter'));
  };
  const setPeriod = (per) => {
    dispatch(setSelectedPeriod(per));
    getDataForSelectedPeriod(per);
  };

  const setGrouping = (groupRule, chart) => {
    dispatch(setSelectedGrouping(groupRule, chart));
  };

  return {
    departments,
    periods,
    companyConfig,
    hiddenFields,
    period,
    selectedDepartmentNames,
    setDepartmentNames,
    selectedPeriod,
    setPeriod,
    dataByDepartmentByPeriod: dataByTypeByDepartmentByPeriod,
    selectedGrouping: grouping,
    setGrouping,
  };
};

export default useOverviewDatasource;
