import React from 'react';
import { Toggle } from 'rsuite';
import { card } from '../../assets/soter-flex-styles';
import * as Emotion from 'emotion';

const container = (height) => Emotion.css`
  ${card};
  position: relative;
  padding: 14px 11px 11px 11px;
  height: ${height};
`;

const toggleWrapper = Emotion.css`
  position: absolute;
  right: 7px;
  top: 7px;
`;

const SwitchableCard = ({
  isActive, setIsActive, children, height = 'auto',
}) => (
  <div className={container(height)}>
    <div className={toggleWrapper}>
      <Toggle onChange={setIsActive} checked={isActive} />
    </div>
    {children}
  </div>
);

export default SwitchableCard;
