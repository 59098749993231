// source: api/dashboard/v2/admin_dashboard_users_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_site_message_pb);
var api_dashboard_v2_job_role_message_pb = require('../../../api/dashboard/v2/job_role_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_job_role_message_pb);
goog.exportSymbol('proto.admin.CreateDashboardUserRequest', null, global);
goog.exportSymbol('proto.admin.DashboardUser', null, global);
goog.exportSymbol('proto.admin.DashboardUserRequest', null, global);
goog.exportSymbol('proto.admin.DashboardUserResponse', null, global);
goog.exportSymbol('proto.admin.DashboardUsersCompany', null, global);
goog.exportSymbol('proto.admin.DashboardUsersRequest', null, global);
goog.exportSymbol('proto.admin.DashboardUsersResponse', null, global);
goog.exportSymbol('proto.admin.DeleteUserRequest', null, global);
goog.exportSymbol('proto.admin.DeleteUserResponse', null, global);
goog.exportSymbol('proto.admin.GetInviteLinkResponse', null, global);
goog.exportSymbol('proto.admin.ResetDashboardUserPasswordResponse', null, global);
goog.exportSymbol('proto.admin.SendInviteLinkResponse', null, global);
goog.exportSymbol('proto.admin.UpdateDashboardUserRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DashboardUsersCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.DashboardUsersCompany.repeatedFields_, null);
};
goog.inherits(proto.admin.DashboardUsersCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DashboardUsersCompany.displayName = 'proto.admin.DashboardUsersCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DashboardUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.DashboardUser.repeatedFields_, null);
};
goog.inherits(proto.admin.DashboardUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DashboardUser.displayName = 'proto.admin.DashboardUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DashboardUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.DashboardUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.admin.DashboardUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DashboardUsersResponse.displayName = 'proto.admin.DashboardUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DashboardUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.DashboardUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DashboardUsersRequest.displayName = 'proto.admin.DashboardUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.DashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DashboardUserRequest.displayName = 'proto.admin.DashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.UpdateDashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.UpdateDashboardUserRequest.repeatedFields_, null);
};
goog.inherits(proto.admin.UpdateDashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.UpdateDashboardUserRequest.displayName = 'proto.admin.UpdateDashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DashboardUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.DashboardUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DashboardUserResponse.displayName = 'proto.admin.DashboardUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.CreateDashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.CreateDashboardUserRequest.repeatedFields_, null);
};
goog.inherits(proto.admin.CreateDashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.CreateDashboardUserRequest.displayName = 'proto.admin.CreateDashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.GetInviteLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.GetInviteLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.GetInviteLinkResponse.displayName = 'proto.admin.GetInviteLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.SendInviteLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.SendInviteLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.SendInviteLinkResponse.displayName = 'proto.admin.SendInviteLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.ResetDashboardUserPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.ResetDashboardUserPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.ResetDashboardUserPasswordResponse.displayName = 'proto.admin.ResetDashboardUserPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DeleteUserRequest.displayName = 'proto.admin.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.DeleteUserResponse.displayName = 'proto.admin.DeleteUserResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.DashboardUsersCompany.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DashboardUsersCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DashboardUsersCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DashboardUsersCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUsersCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard_v2_department_message_pb.Department.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    api_dashboard_v2_site_message_pb.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DashboardUsersCompany}
 */
proto.admin.DashboardUsersCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DashboardUsersCompany;
  return proto.admin.DashboardUsersCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DashboardUsersCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DashboardUsersCompany}
 */
proto.admin.DashboardUsersCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 4:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DashboardUsersCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DashboardUsersCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DashboardUsersCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUsersCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.DashboardUsersCompany.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUsersCompany} returns this
 */
proto.admin.DashboardUsersCompany.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin.DashboardUsersCompany.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.DashboardUsersCompany} returns this
 */
proto.admin.DashboardUsersCompany.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated department_message.Department departments = 3;
 * @return {!Array<!proto.department_message.Department>}
 */
proto.admin.DashboardUsersCompany.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.department_message.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_department_message_pb.Department, 3));
};


/**
 * @param {!Array<!proto.department_message.Department>} value
 * @return {!proto.admin.DashboardUsersCompany} returns this
*/
proto.admin.DashboardUsersCompany.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.department_message.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.department_message.Department}
 */
proto.admin.DashboardUsersCompany.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.department_message.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.DashboardUsersCompany} returns this
 */
proto.admin.DashboardUsersCompany.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * repeated site_message.Site sites = 4;
 * @return {!Array<!proto.site_message.Site>}
 */
proto.admin.DashboardUsersCompany.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.site_message.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_site_message_pb.Site, 4));
};


/**
 * @param {!Array<!proto.site_message.Site>} value
 * @return {!proto.admin.DashboardUsersCompany} returns this
*/
proto.admin.DashboardUsersCompany.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.site_message.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.site_message.Site}
 */
proto.admin.DashboardUsersCompany.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.site_message.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.DashboardUsersCompany} returns this
 */
proto.admin.DashboardUsersCompany.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.DashboardUser.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DashboardUser.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DashboardUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DashboardUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    resellerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastSeenInMilliseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    email: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 9, 0),
    companiesList: jspb.Message.toObjectList(msg.getCompaniesList(),
    proto.admin.DashboardUsersCompany.toObject, includeInstance),
    canInviteUsers: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    receiveNeedHelpRequests: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isPasswordSet: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isSubscriptionManager: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    jobRole: (f = msg.getJobRole()) && api_dashboard_v2_job_role_message_pb.JobRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DashboardUser}
 */
proto.admin.DashboardUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DashboardUser;
  return proto.admin.DashboardUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DashboardUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DashboardUser}
 */
proto.admin.DashboardUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResellerId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeenInMilliseconds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActivity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedBy(value);
      break;
    case 10:
      var value = new proto.admin.DashboardUsersCompany;
      reader.readMessage(value,proto.admin.DashboardUsersCompany.deserializeBinaryFromReader);
      msg.addCompanies(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanInviteUsers(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReceiveNeedHelpRequests(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPasswordSet(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionManager(value);
      break;
    case 16:
      var value = new api_dashboard_v2_job_role_message_pb.JobRole;
      reader.readMessage(value,api_dashboard_v2_job_role_message_pb.JobRole.deserializeBinaryFromReader);
      msg.setJobRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DashboardUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DashboardUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DashboardUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getResellerId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLastSeenInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedBy();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.admin.DashboardUsersCompany.serializeBinaryToWriter
    );
  }
  f = message.getCanInviteUsers();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getReceiveNeedHelpRequests();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsPasswordSet();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsSubscriptionManager();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getJobRole();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      api_dashboard_v2_job_role_message_pb.JobRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.DashboardUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.admin.DashboardUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.admin.DashboardUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool admin = 4;
 * @return {boolean}
 */
proto.admin.DashboardUser.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 reseller_id = 5;
 * @return {number}
 */
proto.admin.DashboardUser.prototype.getResellerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setResellerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_seen_in_milliseconds = 6;
 * @return {number}
 */
proto.admin.DashboardUser.prototype.getLastSeenInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setLastSeenInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 activity = 7;
 * @return {number}
 */
proto.admin.DashboardUser.prototype.getActivity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setActivity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string email = 8;
 * @return {string}
 */
proto.admin.DashboardUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 created_by = 9;
 * @return {number}
 */
proto.admin.DashboardUser.prototype.getCreatedBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated DashboardUsersCompany companies = 10;
 * @return {!Array<!proto.admin.DashboardUsersCompany>}
 */
proto.admin.DashboardUser.prototype.getCompaniesList = function() {
  return /** @type{!Array<!proto.admin.DashboardUsersCompany>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.DashboardUsersCompany, 10));
};


/**
 * @param {!Array<!proto.admin.DashboardUsersCompany>} value
 * @return {!proto.admin.DashboardUser} returns this
*/
proto.admin.DashboardUser.prototype.setCompaniesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.admin.DashboardUsersCompany=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.DashboardUsersCompany}
 */
proto.admin.DashboardUser.prototype.addCompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.admin.DashboardUsersCompany, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};


/**
 * optional bool can_invite_users = 11;
 * @return {boolean}
 */
proto.admin.DashboardUser.prototype.getCanInviteUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setCanInviteUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool receive_need_help_requests = 12;
 * @return {boolean}
 */
proto.admin.DashboardUser.prototype.getReceiveNeedHelpRequests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setReceiveNeedHelpRequests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string job_title = 13;
 * @return {string}
 */
proto.admin.DashboardUser.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_password_set = 14;
 * @return {boolean}
 */
proto.admin.DashboardUser.prototype.getIsPasswordSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setIsPasswordSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_subscription_manager = 15;
 * @return {boolean}
 */
proto.admin.DashboardUser.prototype.getIsSubscriptionManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.setIsSubscriptionManager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional job_role.JobRole job_role = 16;
 * @return {?proto.job_role.JobRole}
 */
proto.admin.DashboardUser.prototype.getJobRole = function() {
  return /** @type{?proto.job_role.JobRole} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_job_role_message_pb.JobRole, 16));
};


/**
 * @param {?proto.job_role.JobRole|undefined} value
 * @return {!proto.admin.DashboardUser} returns this
*/
proto.admin.DashboardUser.prototype.setJobRole = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.DashboardUser} returns this
 */
proto.admin.DashboardUser.prototype.clearJobRole = function() {
  return this.setJobRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.DashboardUser.prototype.hasJobRole = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.DashboardUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DashboardUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DashboardUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DashboardUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardUsersList: jspb.Message.toObjectList(msg.getDashboardUsersList(),
    proto.admin.DashboardUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DashboardUsersResponse}
 */
proto.admin.DashboardUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DashboardUsersResponse;
  return proto.admin.DashboardUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DashboardUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DashboardUsersResponse}
 */
proto.admin.DashboardUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin.DashboardUser;
      reader.readMessage(value,proto.admin.DashboardUser.deserializeBinaryFromReader);
      msg.addDashboardUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DashboardUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DashboardUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DashboardUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admin.DashboardUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardUser dashboard_users = 1;
 * @return {!Array<!proto.admin.DashboardUser>}
 */
proto.admin.DashboardUsersResponse.prototype.getDashboardUsersList = function() {
  return /** @type{!Array<!proto.admin.DashboardUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.DashboardUser, 1));
};


/**
 * @param {!Array<!proto.admin.DashboardUser>} value
 * @return {!proto.admin.DashboardUsersResponse} returns this
*/
proto.admin.DashboardUsersResponse.prototype.setDashboardUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admin.DashboardUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.DashboardUser}
 */
proto.admin.DashboardUsersResponse.prototype.addDashboardUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admin.DashboardUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.DashboardUsersResponse} returns this
 */
proto.admin.DashboardUsersResponse.prototype.clearDashboardUsersList = function() {
  return this.setDashboardUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DashboardUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DashboardUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DashboardUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DashboardUsersRequest}
 */
proto.admin.DashboardUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DashboardUsersRequest;
  return proto.admin.DashboardUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DashboardUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DashboardUsersRequest}
 */
proto.admin.DashboardUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DashboardUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DashboardUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DashboardUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.admin.DashboardUsersRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.DashboardUsersRequest} returns this
 */
proto.admin.DashboardUsersRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DashboardUserRequest}
 */
proto.admin.DashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DashboardUserRequest;
  return proto.admin.DashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DashboardUserRequest}
 */
proto.admin.DashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDashboardUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 dashboard_user_id = 1;
 * @return {number}
 */
proto.admin.DashboardUserRequest.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUserRequest} returns this
 */
proto.admin.DashboardUserRequest.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.UpdateDashboardUserRequest.repeatedFields_ = [5,6,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.UpdateDashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.UpdateDashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.UpdateDashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.UpdateDashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    companiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    departmentsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    password: jspb.Message.getFieldWithDefault(msg, 7, ""),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    canDeactivateDevices: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    canDeactivateUsers: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    resellerId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    getInviteLink: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    sendInvite: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    canInviteUsers: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    receiveNeedHelpRequests: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isSubscriptionManager: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    sitesList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.UpdateDashboardUserRequest}
 */
proto.admin.UpdateDashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.UpdateDashboardUserRequest;
  return proto.admin.UpdateDashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.UpdateDashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.UpdateDashboardUserRequest}
 */
proto.admin.UpdateDashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDashboardUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanies(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDepartments(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeactivateDevices(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeactivateUsers(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResellerId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetInviteLink(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendInvite(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanInviteUsers(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReceiveNeedHelpRequests(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionManager(value);
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSites(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.UpdateDashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.UpdateDashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.UpdateDashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.UpdateDashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCanDeactivateDevices();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCanDeactivateUsers();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getResellerId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getGetInviteLink();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSendInvite();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCanInviteUsers();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getReceiveNeedHelpRequests();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsSubscriptionManager();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      19,
      f
    );
  }
};


/**
 * optional int32 dashboard_user_id = 1;
 * @return {number}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated int64 companies = 5;
 * @return {!Array<number>}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getCompaniesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setCompaniesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.addCompanies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};


/**
 * repeated int64 departments = 6;
 * @return {!Array<number>}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getDepartmentsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.addDepartments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional string password = 7;
 * @return {string}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool admin = 8;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_active = 9;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool can_deactivate_devices = 10;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getCanDeactivateDevices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setCanDeactivateDevices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool can_deactivate_users = 11;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getCanDeactivateUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setCanDeactivateUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 reseller_id = 12;
 * @return {number}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getResellerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setResellerId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool get_invite_link = 13;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getGetInviteLink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setGetInviteLink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool send_invite = 14;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getSendInvite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setSendInvite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool can_invite_users = 15;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getCanInviteUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setCanInviteUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool receive_need_help_requests = 16;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getReceiveNeedHelpRequests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setReceiveNeedHelpRequests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string job_title = 17;
 * @return {string}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_subscription_manager = 18;
 * @return {boolean}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getIsSubscriptionManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setIsSubscriptionManager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * repeated int64 sites = 19;
 * @return {!Array<number>}
 */
proto.admin.UpdateDashboardUserRequest.prototype.getSitesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.setSitesList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.addSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.UpdateDashboardUserRequest} returns this
 */
proto.admin.UpdateDashboardUserRequest.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DashboardUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DashboardUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DashboardUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DashboardUserResponse}
 */
proto.admin.DashboardUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DashboardUserResponse;
  return proto.admin.DashboardUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DashboardUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DashboardUserResponse}
 */
proto.admin.DashboardUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DashboardUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DashboardUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DashboardUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DashboardUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.DashboardUserResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DashboardUserResponse} returns this
 */
proto.admin.DashboardUserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.CreateDashboardUserRequest.repeatedFields_ = [5,7,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.CreateDashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.CreateDashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.CreateDashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CreateDashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    departmentsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    password: jspb.Message.getFieldWithDefault(msg, 8, ""),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    canDeactivateDevices: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    canDeactivateUsers: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    resellerId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    getInviteLink: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    sendInvite: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    canInviteUsers: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    receiveNeedHelpRequests: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 19, ""),
    isSubscriptionManager: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    sitesList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.CreateDashboardUserRequest}
 */
proto.admin.CreateDashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.CreateDashboardUserRequest;
  return proto.admin.CreateDashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.CreateDashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.CreateDashboardUserRequest}
 */
proto.admin.CreateDashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompanies(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDepartments(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeactivateDevices(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeactivateUsers(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResellerId(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetInviteLink(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendInvite(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanInviteUsers(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReceiveNeedHelpRequests(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionManager(value);
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSites(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.CreateDashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.CreateDashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.CreateDashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CreateDashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCanDeactivateDevices();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCanDeactivateUsers();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getResellerId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getGetInviteLink();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getSendInvite();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCanInviteUsers();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getReceiveNeedHelpRequests();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getIsSubscriptionManager();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      21,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.admin.CreateDashboardUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.admin.CreateDashboardUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.admin.CreateDashboardUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 companies = 5;
 * @return {!Array<number>}
 */
proto.admin.CreateDashboardUserRequest.prototype.getCompaniesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setCompaniesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.addCompanies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};


/**
 * repeated int64 departments = 7;
 * @return {!Array<number>}
 */
proto.admin.CreateDashboardUserRequest.prototype.getDepartmentsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.addDepartments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.admin.CreateDashboardUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool admin = 9;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_active = 10;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool can_deactivate_devices = 11;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getCanDeactivateDevices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setCanDeactivateDevices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool can_deactivate_users = 12;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getCanDeactivateUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setCanDeactivateUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 reseller_id = 14;
 * @return {number}
 */
proto.admin.CreateDashboardUserRequest.prototype.getResellerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setResellerId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool get_invite_link = 15;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getGetInviteLink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setGetInviteLink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool send_invite = 16;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getSendInvite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setSendInvite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool can_invite_users = 17;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getCanInviteUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setCanInviteUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool receive_need_help_requests = 18;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getReceiveNeedHelpRequests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setReceiveNeedHelpRequests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string job_title = 19;
 * @return {string}
 */
proto.admin.CreateDashboardUserRequest.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool is_subscription_manager = 20;
 * @return {boolean}
 */
proto.admin.CreateDashboardUserRequest.prototype.getIsSubscriptionManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setIsSubscriptionManager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated int64 sites = 21;
 * @return {!Array<number>}
 */
proto.admin.CreateDashboardUserRequest.prototype.getSitesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.setSitesList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.addSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.CreateDashboardUserRequest} returns this
 */
proto.admin.CreateDashboardUserRequest.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.GetInviteLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.GetInviteLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.GetInviteLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.GetInviteLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inviteLink: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.GetInviteLinkResponse}
 */
proto.admin.GetInviteLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.GetInviteLinkResponse;
  return proto.admin.GetInviteLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.GetInviteLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.GetInviteLinkResponse}
 */
proto.admin.GetInviteLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDashboardUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.GetInviteLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.GetInviteLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.GetInviteLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.GetInviteLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getInviteLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 dashboard_user_id = 1;
 * @return {number}
 */
proto.admin.GetInviteLinkResponse.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.GetInviteLinkResponse} returns this
 */
proto.admin.GetInviteLinkResponse.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string invite_link = 2;
 * @return {string}
 */
proto.admin.GetInviteLinkResponse.prototype.getInviteLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.GetInviteLinkResponse} returns this
 */
proto.admin.GetInviteLinkResponse.prototype.setInviteLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.SendInviteLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.SendInviteLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.SendInviteLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.SendInviteLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.SendInviteLinkResponse}
 */
proto.admin.SendInviteLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.SendInviteLinkResponse;
  return proto.admin.SendInviteLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.SendInviteLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.SendInviteLinkResponse}
 */
proto.admin.SendInviteLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.SendInviteLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.SendInviteLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.SendInviteLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.SendInviteLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.admin.SendInviteLinkResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.SendInviteLinkResponse} returns this
 */
proto.admin.SendInviteLinkResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.ResetDashboardUserPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.ResetDashboardUserPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.ResetDashboardUserPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.ResetDashboardUserPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.ResetDashboardUserPasswordResponse}
 */
proto.admin.ResetDashboardUserPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.ResetDashboardUserPasswordResponse;
  return proto.admin.ResetDashboardUserPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.ResetDashboardUserPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.ResetDashboardUserPasswordResponse}
 */
proto.admin.ResetDashboardUserPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.ResetDashboardUserPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.ResetDashboardUserPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.ResetDashboardUserPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.ResetDashboardUserPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.admin.ResetDashboardUserPasswordResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.ResetDashboardUserPasswordResponse} returns this
 */
proto.admin.ResetDashboardUserPasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DeleteUserRequest}
 */
proto.admin.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DeleteUserRequest;
  return proto.admin.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DeleteUserRequest}
 */
proto.admin.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.admin.DeleteUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.DeleteUserRequest} returns this
 */
proto.admin.DeleteUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.DeleteUserResponse}
 */
proto.admin.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.DeleteUserResponse;
  return proto.admin.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.DeleteUserResponse}
 */
proto.admin.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.admin.DeleteUserResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.DeleteUserResponse} returns this
 */
proto.admin.DeleteUserResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.admin);
