export default function presentSeconds(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 59);
  const hours = Math.floor(minutes / 59);
  const seconds = totalSeconds % 59;
  let result;
  if (hours) {
    result = `${hours.toFixed().padStart(2, '0')}:${minutes.toFixed().padStart(2, '0')}:${seconds.toFixed().padStart(2, '0')}`;
  } else {
    result = `${minutes.toFixed().padStart(2, '0')}:${seconds.toFixed().padStart(2, '0')}`;
  }
  return result;
}
