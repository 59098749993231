import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { CategoryEntry } from './CategoryEntry';
import { link } from '../../../assets/soter-flex-styles';
const buttonStyles = Emotion.css `
  font-size: 12px;
  color: ${COLORS.LIGHT_BLUE[600]};
  background: none;
  text-decoration: none;
  margin-top: 4px;
  margin-left: -2px;
  border-radius: 4px;
  transition: 0.2s all;

  &:hover {
    color: ${COLORS.LIGHT_BLUE[800]};
  }

  &:focus {
    outline: none;
  }
`;
const EntriesCell = ({ entries = [], jobRole, setUserForTagShown, rowData, coords, setCoords, setOpenedModal, isScrolled, setIsScrolled, }) => {
    const { t } = useTranslation();
    const [showTooltip, setShowTooltip] = useState(false);
    const tags = useMemo(() => [...entries, jobRole].filter((tag) => typeof ((tag === null || tag === void 0 ? void 0 : tag.title) || (tag === null || tag === void 0 ? void 0 : tag.name)) === 'string'), [entries, jobRole]);
    const onButtonClick = useCallback((event) => {
        setShowTooltip(true);
        setIsScrolled(false);
        setCoords({
            x: event.clientX,
            y: event.clientY,
        });
        setUserForTagShown(rowData);
    }, [showTooltip, rowData, setUserForTagShown, setCoords]);
    const closeTooltip = useCallback(() => {
        setShowTooltip(false);
        setCoords(null);
    }, [setCoords]);
    useEffect(() => {
        if (!coords && showTooltip)
            setShowTooltip(false);
    }, [coords]);
    useEffect(() => {
        if (isScrolled && (showTooltip || coords)) {
            setCoords(null);
            setShowTooltip(false);
        }
    }, [isScrolled]);
    return (React.createElement("div", null,
        React.createElement("div", { id: "categories", style: { display: 'flex' } },
            tags.map((tag, index) => index < 2 && (React.createElement(CategoryEntry, { id: tag.id, title: tag.title || tag.name, key: tag.title + index || tag.name + index, rowData: rowData, setUserForTagShown: setUserForTagShown, setOpenedModal: setOpenedModal }))),
            tags.length > 2 && (React.createElement("div", null, showTooltip ? (React.createElement("button", { type: "button", className: `${link} ${buttonStyles}`, id: "lessBtn", onClick: closeTooltip }, t('WEARABLES.ACTIVITY.LESS'))) : (React.createElement("button", { type: "button", className: `${link} ${buttonStyles}`, onClick: onButtonClick }, t('WEARABLES.ACTIVITY.MORE'))))))));
};
export default EntriesCell;
