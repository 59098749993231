import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/img/icons/arrow-right.svg';
import { subText } from '../../../../assets/soter-flex-styles';
import RiskGroupBadge from '../../../../lib/ui/RiskGroupBadge/RiskGroupBadge';

const RiskGroupBlock = ({ riskGroups }) => {
  const { t } = useTranslation();
  return (
    <div className={subText} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontSize: '15px', marginRight: '0.4rem' }}>
        {`${t(
          'WEARABLES.USER_PAGE.RISK_GROUP',
        )}:`}
      </div>
      {riskGroups ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '350px',
            marginTop: '0.1rem',
          }}
        >
          <RiskGroupBadge riskGroup={riskGroups.lastTwoDays} mediumSize />
          <div style={{ marginLeft: '4px', whiteSpace: 'nowrap' }}>
            {`/${t(
              'GENERAL.START',
            ).toLowerCase()}`}
          </div>
          {riskGroups.current && (
            <>
              <ArrowRightIcon />
              <RiskGroupBadge
                isCurrentGroup
                riskGroup={riskGroups.current}
                mediumSize
              />
              <div style={{ marginLeft: '4px', whiteSpace: 'nowrap' }}>
                {`/${t('GENERAL.CURRENT').toLowerCase()}`}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

export default RiskGroupBlock;
