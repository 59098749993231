/* eslint import/prefer-default-export:0 */
import { COLORS } from 'constants/colors'
import * as Emotion from 'emotion';

const drop = Emotion.css`
  position: absolute;
  left: 0;
  z-index: 100000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: ${COLORS.GRAY[950]};
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
`;

export const dropDown = Emotion.css`
  ${drop};
  top: 114%;
  
  &:before {
    position: absolute;
    top: -10px;
    left: 12px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid rgba(0, 40, 100, 0.12);
    border-left: 9px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: "";
  }
  &:after {
    position: absolute;
    top: -9px;
    left: 12px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    content: "";
  }
`;

export const dropUp = Emotion.css`
  ${drop};
  bottom: 114%;
  
  &:before {
    position: absolute;
    bottom: -10px;
    left: 12px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-top: 9px solid rgba(0, 40, 100, 0.12);
    border-left: 9px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.2);
    content: "";
  }
  &:after {
    position: absolute;
    bottom: -9px;
    left: 12px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-top: 9px solid #fff;
    border-left: 9px solid transparent;
    content: "";
  }
`;
