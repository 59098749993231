import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { cardMediumWithHeight } from '../../assets/soter-flex-styles';
import { COLORS } from '../../constants/colors';
import stooping from '../../assets/img/hazard-dudes/BadLifting_Blue.png';
import standing from '../../assets/img/hazard-dudes/standing.png';
import activeWorking from '../../assets/img/hazard-dudes/activeWorking.png';
import lifting from '../../assets/img/hazard-dudes/lifting.png';
import elevation from '../../assets/img/hazard-dudes/elevation.png';
import pulling from '../../assets/img/hazard-dudes/pulling.png';
import sitting from '../../assets/img/hazard-dudes/sitting.png';
import walking from '../../assets/img/hazard-dudes/walking.png';
import CustomInput from '../../lib/ui/CustomInput';
import InfoCard from '../../lib/ui/InfoCard';
import CardEditingControl from '../../lib/ui/CardEditingControl';

const hazardsBar = Emotion.css`
  border-radius: 5px;
`;
const hazardsLegend = Emotion.css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 33px;
`;
const hazardsSlot = Emotion.css`
  padding: 3px 0;
  margin-bottom: 12px;
  height: 38px;
  position: relative;
  min-width: 257px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;
const colorMarker = (color) => Emotion.css`
  background-color: ${color};
  width: 12px;
  height: 12px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 10px;
`;
const dudeImage = (wide) => Emotion.css`
  width: 25px;
  height: 25px;
`;

const input = Emotion.css`
  width: 45px;
  height: 38px;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  padding: 7px 0;
`;

// const infoString = 'Represents a ratio of seated, active work and “in between” category called Walking /Standing among job role.';

const getActivityPicture = (activity) => {
  if (activity === 'Standing') return standing;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.ACTIVE_WORK') return activeWorking;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.LIFTING_LOWERING') return lifting;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.STOOPING_BENDING') return stooping;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.PUSHING_PULLING') return pulling;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.ARM_ELEVATION') return elevation;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.STANDING_WALKING') return walking;
  if (activity === 'JOB_DICTIONARY.ACTIVITIES.SITTING') return sitting;
  return null;
};

const HazardSlot = ({
  name, value, color, image, editMode, setValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className={hazardsSlot}>
      <img
        src={image}
        className={dudeImage(
          name === 'JOB_DICTIONARY.ACTIVITIES.PUSHING_PULLING',
        )}
        alt="."
      />
      <div className={colorMarker(color)} />
      <span style={{ whiteSpace: 'nowrap' }}>
        {`${t(name)} — `}
        {editMode ? (
          <>
            <CustomInput
              type="number"
              max={100}
              min={0}
              value={value}
              className={input}
              onChange={setValue}
              name={name}
            />
            {' '}
            %
            {' '}
          </>
        ) : (
          <strong>{`${value}%`}</strong>
        )}
      </span>
    </div>
  );
};

const ActivityBreakdown = ({
  hazardsList,
  setActivityBreakdown,
  cancelHandler,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [hazardsLocalState, setHazardsLocalState] = useState(hazardsList);
  const [activities, setActivities] = useState();
  const [hazards, setHazards] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    setHazardsLocalState(hazardsList);
  }, [hazardsList]);
  useEffect(() => {
    if (Object.keys(hazardsLocalState).length === 0) return;
    setActivities(
      _filter(
        hazardsLocalState,
        (hazard) => hazard.name === 'JOB_DICTIONARY.ACTIVITIES.SITTING'
          || hazard.name === 'JOB_DICTIONARY.ACTIVITIES.STANDING_WALKING'
          || hazard.name === 'JOB_DICTIONARY.ACTIVITIES.ACTIVE_WORK',
      ),
    );
    setHazards(
      _filter(
        hazardsLocalState,
        (hazard) => hazard.name === 'JOB_DICTIONARY.ACTIVITIES.LIFTING_LOWERING'
          || hazard.name === 'JOB_DICTIONARY.ACTIVITIES.ARM_ELEVATION'
          || hazard.name === 'JOB_DICTIONARY.ACTIVITIES.PUSHING_PULLING'
          || hazard.name === 'JOB_DICTIONARY.ACTIVITIES.STOOPING_BENDING',
      ),
    );
  }, [hazardsLocalState]);
  const editButtonHandler = () => setEditMode(!editMode);
  const saveButtonHandler = async () => {
    await setActivityBreakdown(hazardsLocalState);
    setEditMode(false);
  };
  const cancelButtonHandler = () => {
    cancelHandler();
    setEditMode(false);
  };
  const inputChangeHandler = (hazardName, value) => {
    const newHazards = { ...hazardsLocalState };
    let hazardIndex;
    _find(newHazards, (hazard, index) => {
      if (hazard.name === hazardName) hazardIndex = index;
      return hazard.name === hazardName;
    });
    newHazards[hazardIndex].value = value;
    setHazardsLocalState(newHazards);
  };
  return (
    <InfoCard
      title={t('JOB_DICTIONARY.JOB_PROFILE.BREAKDOWN').toUpperCase()}
      cardClass={cardMediumWithHeight(402)}
      rightCornerControl={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CardEditingControl
            editMode={editMode}
            editButtonHandler={editButtonHandler}
            cancelButtonHandler={cancelButtonHandler}
            saveButtonHandler={saveButtonHandler}
          />
        </div>
      )}
    >
      <div className={hazardsBar}>
        {activities?.map((hazard) => (
          <div
            key={hazard.name}
            style={{
              width: `${hazard.proportion}%`,
              height: '8px',
              display: 'inline-block',
              backgroundColor: hazard.color,
              marginBottom: '8px',
            }}
          />
        ))}
      </div>
      <div className={hazardsLegend}>
        {activities?.map((hazard) => (
          <HazardSlot
            key={hazard.color}
            name={hazard.name}
            value={hazard.value}
            character={hazard.character}
            color={hazard.color}
            image={getActivityPicture(hazard.name)}
            editMode={editMode}
            setValue={inputChangeHandler}
          />
        ))}
      </div>
      <div className={Emotion.css`{color: ${COLORS.GRAY[400]}; margin: 12px 0px 9px 0px`}>
        {t('JOB_DICTIONARY.JOB_PROFILE.HAZARDS_ACTIVITIES')}
      </div>
      <div className={hazardsBar}>
        {hazards?.map((hazard) => (
          <div
            key={hazard.name}
            style={{
              width: `${hazard.hazardsProportion}%`,
              height: '8px',
              display: 'inline-block',
              backgroundColor: hazard.color,
              marginBottom: '8px',
            }}
          />
        ))}
      </div>
      <div className={hazardsLegend}>
        {hazards?.map((hazard) => (
          <HazardSlot
            key={hazard.color}
            name={hazard.name}
            value={hazard.value}
            character={hazard.character}
            color={hazard.color}
            image={getActivityPicture(hazard.name)}
            editMode={editMode}
            setValue={inputChangeHandler}
          />
        ))}
      </div>
    </InfoCard>
  );
};

export default ActivityBreakdown;
