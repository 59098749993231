import { COLORS } from 'constants/colors'
import * as Emotion from 'emotion';

export const heading = Emotion.css`{
  font-size: 14px;
  text-transform: uppercase;
  color: ${COLORS.GRAY[400]};
}`;

export const companyTitle = Emotion.css`{
  font-size: 14px;
  font-weight: 600;
}`;
