import React from 'react';
import _map from 'lodash/map';
import { flexColumn, invisibleButtonMenuItem } from '../../../assets/soter-flex-styles';
import DropdownActionButton from './DropdownActionButton';

const DropdownActionList = ({ actionHandler, actions, style = null }) => {
  const actionButtons = () => _map(actions, (actionSettings, action) => (
    <DropdownActionButton
      className={invisibleButtonMenuItem(
        'small',
        actionSettings.color,
        actionSettings.selected,
      )}
      key={action}
      type="button"
      style={style}
      id={actionSettings.id}
      clickHandler={(e) => actionHandler(e.target.id)}
      disabled={actionSettings.disabled}
    >
      {actionSettings.name}
    </DropdownActionButton>
  ));

  return <div className={flexColumn} style={{ padding: 0 }}>{actionButtons()}</div>;
};

export default DropdownActionList;
