import { INTERVAL_TIME, PRODUCT_INFO, SESSION_TIME, TARGET_DAYS, VIDEO_DURATION_MIN, } from './adminCompanyDefaultSettings';
export const initialCompanyData = {
    id: null,
    name: '',
    region: null,
    wearable: PRODUCT_INFO,
    jobDictionary: PRODUCT_INFO,
    genius: PRODUCT_INFO,
    spine: false,
    shoulder: false,
    headset: false,
    helmet: false,
    availableCoachingProgramIdsList: [],
    firmwareId: null,
    inputLoginMethod: 3,
    tierId: { value: 1, label: 'Default' },
    employeeLoginPrompt: '',
    workdayTimeMinutes: SESSION_TIME,
    workdayIntervalMinutes: INTERVAL_TIME,
    programTargetDays: TARGET_DAYS,
    collectJobRoleOnRegister: false,
    reportPeopleName: true,
    collectEmployeeName: true,
    availableLanguagesList: ['en'],
    departmentsList: [],
    nicknameRegexpTemplate: '',
    disableNotifications: false,
    downloadDataNotOnlyFromDevicesOnCharge: false,
    showOnboardingPages: false,
    enableGamification: false,
    videoDuration: VIDEO_DURATION_MIN,
    blurFaceEnable: false,
    blurBackgroundEnable: false,
    officeAssessment: false,
};
export const regions = ['eu', 'au', 'us'];
