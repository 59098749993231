import React from 'react';
import { useTranslation } from 'react-i18next';
import { Progress, Text } from 'tabler-react';
import shoulderColorLogo from '../../../../assets/img/icons/LogoShoulder.svg';
import spineColorLogo from '../../../../assets/img/icons/LogoSpine.svg';
import shoulderGrayLogo from '../../../../assets/img/icons/LogoShoulderGray.svg';
import spineGrayLogo from '../../../../assets/img/icons/LogoSpineGray.svg';
import { programCell, smallText } from '../../../../assets/jss/style';
import Tooltip from '../../../../lib/ui/Tooltip';
import { COLORS } from '../../../../constants/colors';

const ProgramAndProgressCell = ({ rowData, isClipgo }) => {
  const { t } = useTranslation();
  const { program, deviceType, isPassedProgramCopy } = rowData;
  const progress = program?.progress;
  const progressWidth
    = (progress?.acceptedWorkSessionsCount / progress?.targetWorkSessionsCount)
      * 100 || 0;
  const spineLogo = isPassedProgramCopy ? spineGrayLogo : spineColorLogo;
  const shoulderLogo = isPassedProgramCopy
    ? shoulderGrayLogo
    : shoulderColorLogo;
  const isSpine
    = program?.deviceType !== 'armband' || (isClipgo && deviceType !== 'armband');

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {program ? (
        <span className={programCell} style={{ marginRight: '8px' }}>
          <Tooltip
            noTranslation
            text={`${
              isSpine
                ? t('SOTER_TASK.BODY_PARTS.BACK')
                : t('SOTER_TASK.BODY_PARTS.SHOULDER')
            } ${t('GENERAL.PROGRAM').toLowerCase()}`}
          >
            <img
              width={24}
              height={24}
              src={isSpine ? spineLogo : shoulderLogo}
              alt="program image"
            />
          </Tooltip>
        </span>
      ) : (
        <Text style={{ fontSize: '12px', marginRight: '8px' }} muted>
          {t('WEARABLES.ACTIVITY.HAS_NO_PROGRAM')}
        </Text>
      )}
      {progress && progress.acceptedWorkSessionsCount ? (
        <div>
          <div className="clearfix">
            <div className="float-left">
              <span className={smallText(COLORS.GRAY[400])}>
                {`${progress.acceptedWorkSessionsCount} ${t('GENERAL.OF')} ${
                  progress.targetWorkSessionsCount
                } ${t('GENERAL.DAYS')}`}
              </span>
            </div>
          </div>
          <Progress size="xs">
            <Progress.Bar
              color={progressWidth >= 100 ? 'green' : 'primary'}
              width={progressWidth}
            />
          </Progress>
        </div>
      ) : (
        <Text style={{ fontSize: '12px' }} muted>
          -
        </Text>
      )}
    </div>
  );
};

export default ProgramAndProgressCell;
