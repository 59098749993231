import React from 'react';
import { Link } from '@reach/router';
import arrowRight from 'assets/img/icons/arrow-right.svg';
import { ReactComponent as Note } from 'assets/img/icons/note.svg';
import { uniqBy } from 'lodash';
import { COLORS } from 'constants/colors';
import { flexRow, ml2, nameLink } from 'assets/soter-flex-styles';
import sliceLongString from '../../../../lib/utils/sliceLongString';
import Tooltip from '../../../../lib/ui/Tooltip';
import RiskGroupBadge from '../../../../lib/ui/RiskGroupBadge/RiskGroupBadge';
import EntriesCell from '../../../../wearables/activity/Entries/EntryCell';

const NameCell = ({
  rowData,
  handler: goToProfile,
  isClipgo,
  setUserForTagShown,
  coords,
  setCoords,
  setOpenedModal,
  isScrolled,
  setIsScrolled,
}) => {
  const {
    lastName,
    firstName,
    nickname,
    program,
    jobRole,
    tagsList,
  } = rowData;
  let name;
  let nick;
  const STRING_LENGTH = 15;
  if (lastName === 'Clip&Go') {
    name = [sliceLongString(nickname, STRING_LENGTH)];
  } else if (`${firstName} ${lastName}`.length > STRING_LENGTH) {
    const isTooltipNeeded
      = firstName?.length > STRING_LENGTH || lastName?.length > STRING_LENGTH;
    name = isTooltipNeeded ? (
      <>
        <Tooltip noTranslation text={`${firstName} ${lastName}`}>
          <div>
            <p>{`${sliceLongString(firstName, STRING_LENGTH)} `}</p>
            <strong>{sliceLongString(lastName, STRING_LENGTH)}</strong>
          </div>
        </Tooltip>
      </>
    ) : (
      <>
        <p>{`${sliceLongString(firstName, STRING_LENGTH)} `}</p>
        <strong>{sliceLongString(lastName, STRING_LENGTH)}</strong>
      </>
    );
    nick = [sliceLongString(nickname, STRING_LENGTH)];
  } else {
    name = (
      <>
        <span>{`${firstName} `}</span>
        <strong>{lastName}</strong>
      </>
    );
    nick = sliceLongString(nickname, STRING_LENGTH);
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {program?.currentRiskGroup ? (
          <div style={{ margin: '2px 5px 0 2px', minWidth: '35px', display: 'flex' }}>
            <RiskGroupBadge riskGroup={program?.initialRiskGroup} />
            <img
              width="11px"
              style={{ marginBottom: '4px' }}
              src={arrowRight}
              alt=">"
            />
            <RiskGroupBadge
              riskGroup={program?.currentRiskGroup}
              isCurrentGroup
            />
          </div>
        ) : (
          <div>
            <RiskGroupBadge showTooltip={false} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
          }}
        >
          <div className={`${flexRow} ${ml2}`}>
            <Link
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              className={nameLink}
              // style={{ flexDirection: 'column' }}
              to={goToProfile(rowData.id)}
              // state={{ userType: isClipgo ? 'clipgo' : 'coach' }}
            >
              <span style={{ fontSize: '14px' }}>
                {lastName && firstName ? name : nick}
              </span>
              {rowData.note?.text ? (
                <Tooltip
                  noTranslation
                  text={rowData?.note?.text?.replaceAll(/<[^>]*>/g, '')}
                >
                  <div><Note style={{ marginLeft: '3px' }} /></div>
                </Tooltip>
              ) : null}
              <span
                style={{
                  color: COLORS.GRAY[400],
                  fontSize: '12px',
                  marginLeft: '3px',
                }}
              >
                {(jobRole?.title && jobRole?.title.length > 15)
                || (jobRole?.name && jobRole?.name.length > 15) ? (
                  <Tooltip noTranslation text={jobRole?.title?.replaceAll('_', ' ') || jobRole?.name?.replaceAll('_', ' ')}>
                    <span>
                      {(jobRole?.title &&
                        sliceLongString(jobRole?.title, 15).replaceAll('_', ' '))
                        || (jobRole?.name && sliceLongString(jobRole?.name, 15)?.replaceAll('_', ' '))}
                    </span>
                  </Tooltip>
                  ) : (
                    <span>{jobRole?.title?.replaceAll('_', ' ') || jobRole?.name?.replaceAll('_', ' ')}</span>
                  )}
              </span>
            </Link>
          </div>
          {!isClipgo && (
            <EntriesCell
              setUserForTagShown={setUserForTagShown}
              entries={uniqBy(tagsList, 'id')}
              jobeRole={jobRole}
              rowData={rowData}
              setCoords={setCoords}
              coords={coords}
              setOpenedModal={setOpenedModal}
              isScrolled={isScrolled}
              setIsScrolled={setIsScrolled}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NameCell;
