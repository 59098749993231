// source: api/errors/errors.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.errors.ErrorCodes', null, global);
/**
 * @enum {number}
 */
proto.errors.ErrorCodes = {
  UNKNOWN: 0,
  INVALID_EMAIL: 100,
  PASSWORD_REQUIRED: 101,
  EMAIL_REQUIRED: 102,
  FIRST_NAME_REQUIRED: 103,
  LAST_NAME_REQUIRED: 104,
  INVALID_PASSWORD: 105,
  USER_ALREADY_EXISTS: 106,
  USER_NOT_FOUND: 107,
  EMPTY_TOKEN: 108,
  EMAIL_ALREADY_EXISTS: 109,
  INVALID_ARGUMENTS: 110,
  USER_ALREADY_HAS_PASSWORD: 111,
  SSO_UNAVAILABLE: 112,
  COMPANY_NOT_FOUND: 200,
  PERMISSION_DENIED_ERROR: 400,
  INTERNAL_SERVER_ERROR: 555
};

goog.object.extend(exports, proto.errors);
