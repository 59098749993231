import React from 'react';
const TermsAndConditionsDialog = () => (React.createElement(React.Fragment, null,
    React.createElement("h6", null, "SOTER ANALYTICS SERVICE AGREEMENT TERMS & CONDITIONS"),
    React.createElement("h6", null, "INTERPRETATION"),
    React.createElement("h6", null, "1.1 DEFINITIONS:"),
    React.createElement("p", null, "Agreement: this contract between the Supplier and the Customer for the supply of Deliverables as which consists of:"),
    React.createElement("ol", null,
        React.createElement("li", null, "The Proposal;"),
        React.createElement("li", null, "These terms and conditions; and"),
        React.createElement("li", null, "Any schedules and annexures.")),
    React.createElement("p", null, "Business Day: a day (other than a Saturday, Sunday or public holiday) when banks in London are open for business."),
    React.createElement("p", null, "Commencement Date: the date the Agreement commences, as set out in the Contract Details."),
    React.createElement("p", null, "Contract Details: The section titled \u2018Contract Details\u2019 in the Proposal."),
    React.createElement("p", null, "Data Protection Legislation: the legislation identified in the applicable Privacy Policy."),
    React.createElement("p", null, "Deliverables: The Goods, Services and Software described in the Proposal."),
    React.createElement("p", null, "Delivery Date: the date specified for delivery of the Deliverables in accordance with the Proposal."),
    React.createElement("h6", null, "Delivery Location:"),
    React.createElement("ol", null,
        React.createElement("li", null, "In relation to Services, the location at which the Services must be provided to the Customer as set out in the Proposal; and"),
        React.createElement("li", null, "In relation to Goods, the location for delivery of the Goods, as set out in the Proposal.")),
    React.createElement("p", null, "Force Majeure Event: events, circumstances or causes beyond a party's reasonable control."),
    React.createElement("p", null, "Goods: the goods (or any part of them), as set out in the Proposal."),
    React.createElement("p", null, "Intellectual Property Rights: any patent rights, copyrights, trademarks, trade secrets, moral rights, and other proprietary or intellectual property rights worldwide."),
    React.createElement("p", null, "Price: the price for the Deliverables, as set out in the Proposal."),
    React.createElement("p", null, "Privacy Policy: the privacy policy as set out in the Contract Details."),
    React.createElement("p", null, "Proposal: The document executed by the parties that references these terms and conditions."),
    React.createElement("p", null, "Services: The services (or any part of them), as set out in the Proposal."),
    React.createElement("p", null, "Software: The software (or any part of them) as set out in the Proposal."),
    React.createElement("p", null, "Term: the period starting on the Commencement Date and ending on the last day of the Term."),
    React.createElement("h6", null, "1.2 INTERPRETATION:"),
    React.createElement("p", null, "(a) a reference to a statute or statutory provision is a reference to such statute or provision as amended or re-enacted. A reference to a statute or statutory provision includes any subordinate legislation made under that statute or statutory provision, as amended or reenacted;"),
    React.createElement("p", null, "(b) any phrase introduced by the terms including, include, in particular or any similar expression shall be construed as illustrative and shall not limit the sense of the words preceding those terms;"),
    React.createElement("p", null, "(c) clause headings are for reference purposes only;"),
    React.createElement("p", null, "(d) no rule of construction will apply to a clause to the disadvantage of a party merely because that party put forward the clause or would otherwise benefit from it;"),
    React.createElement("p", null, "(e) a reference to a party means a party to this Agreement and includes that party's heirs, executors, successors and permitted assigns;"),
    React.createElement("p", null, "(f) words in the singular include the plural and vice versa;"),
    React.createElement("p", null, "(g) if a word or phrase is defined its other grammatical forms have corresponding meanings; and"),
    React.createElement("p", null, "(h) a reference to writing or written includes emails."),
    React.createElement("h6", null, "2. COMMENCEMENT AND TERM"),
    React.createElement("p", null, "This Agreement shall commence on the Commencement Date and shall continue for the Term."),
    React.createElement("h6", null, "3. PURCHASE OF DELIVERABLES"),
    React.createElement("p", null, "3.1 The Customer hereby orders the number of Deliverables set out in the Proposal."),
    React.createElement("h6", null, "4. DELIVERY"),
    React.createElement("p", null, "4.1 The Supplier shall ensure that each delivery of Goods is accompanied by a delivery note which shows the contract number and the type and quantity of Goods."),
    React.createElement("p", null, "4.2 The Supplier shall deliver Goods to the Delivery Location by the relevant Delivery Date."),
    React.createElement("p", null, "4.3 Delivery of Goods is completed on the completion of unloading of the Goods at the Delivery Location."),
    React.createElement("p", null, "4.4 The Supplier shall deliver the Services to the Delivery Location."),
    React.createElement("p", null, "4.5 The Supplier shall not be liable for any delay in delivery of any Deliverables that is caused by:"),
    React.createElement("ol", null,
        React.createElement("li", null, "a Force Majeure Event; or"),
        React.createElement("li", null, "in respect of the Goods, the Customer's failure to provide the Supplier with adequate delivery instructions or any other instructions that are relevant to the supply of the Goods and in respect of the Services, with access to the Delivery Locations to the extent necessary and reasonably required by Supplier to perform its obligations.")),
    React.createElement("h6", null, "5. SOFTWARE"),
    React.createElement("p", null, "5.1 Subject to the Customer complying with clause 5.2, the Supplier grants a non-exclusive, nontransferable, revocable licence to the Customer to use the Software during the Term for the internal business purposes of the Customer. The Supplier may permit its contractors to use the Software while performing services to the Supplier."),
    React.createElement("p", null, "5.2 The Customer must not:"),
    React.createElement("ol", null,
        React.createElement("li", null, "act fraudulently or maliciously, including by hacking into or inserting malicious code, including viruses, or harmful data, into our Software or any operating system;"),
        React.createElement("li", null, "Use the Software in a way that could damage, disable, overburden, impair or compromise Supplier\u2019s systems or security or interfere with other users;"),
        React.createElement("li", null, "Collect or harvest any information or data from the Software or Supplier\u2019s systems or attempt to decipher any transmissions to or from the servers running the Software;"),
        React.createElement("li", null, "Transfer, resell, sublicense, repackage, monetize or make the functionality of the Software available to any third party or any leads or data accessed through the Software;"),
        React.createElement("li", null, "Remove, modify, or conceal any product identification, copyright, proprietary, intellectual property notices or other marks on the Software;"),
        React.createElement("li", null, "Reverse engineer, decompile, decrypt, disassemble, modify, adapt, copy or make derivative works of our Software or any part of it or extract the source code of any part of our Software.")),
    React.createElement("p", null, "5.3 Supplier shall meet or exceed the service level of 99.1% availability of the Software during each month of the Agreement."),
    React.createElement("p", null, "5.4 Subject to clause 5.5, Customer may claim a credit of 2% of the monthly Price of the applicable Software for each 1% below the service level in clause 5.3. The credit will not exceed 100% of the Price paid by the Customer for the relevant month for the applicable Software. Customer may apply the credit to future invoices for the applicable Software."),
    React.createElement("h6", null, "INTELLECTUAL PROPERTY"),
    React.createElement("p", null, "6.1 The parties agree that other than as provided in this Agreement, nothing in this Agreement transfers ownership in, or otherwise grants any rights in any Intellectual Property Rights of a party."),
    React.createElement("p", null, "6.2 All Intellectual Property Rights in the Software and any update, upgrade, modification or enhancement of the Software remain vested in Supplier or its licensors."),
    React.createElement("p", null, "6.3 Supplier represents and warrants that:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) use (and, where applicable, update, upgrade, modification and enhancement) of any Software in accordance with this Agreement; and"),
        React.createElement("li", null, "(b) use of any Goods and Services in accordance with this Agreement, will not infringe the Intellectual Property Rights of any person.")),
    React.createElement("h6", null, "CONFIDENTIALITY"),
    React.createElement("p", null, "7.1 Each party (receiving party) must keep confidential, and not disclose, any Confidential Information of the other party except:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) as permitted under this Agreement;"),
        React.createElement("li", null, "(b) where the receiving party has obtained the prior written permission of the disclosing party;"),
        React.createElement("li", null, "(c) to the party\u2019s officers, employees, professional advisers, insurers, agents, service providers, referrers and subcontractors who have a need to access the relevant Confidential Information;"),
        React.createElement("li", null, "(d) to the receiving party\u2019s auditors; or"),
        React.createElement("li", null, "(e) where the receiving party is compelled to do so by law, provided that it gives the disclosing party written notice prior to disclosure.")),
    React.createElement("p", null, "7.2 Each party must only use the other party\u2019s Confidential Information for the purpose for which it was disclosed in connection with this Agreement."),
    React.createElement("p", null, "7.3 The Supplier must comply with the Privacy Policy in relation to any personal information disclosed to or collected by the Supplier in the course of performing this Agreement."),
    React.createElement("h6", null, "QUALITY AND WARRANTIES"),
    React.createElement("p", null, "8.1 The Supplier warrants that, for a period of 12 months from the date of delivery (the \u201Cwarranty period\u201D), the Deliverables shall:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) conform in all material respects with their description;"),
        React.createElement("li", null, "(b) be free from material defects in design, material and workmanship; and"),
        React.createElement("li", null, "(c) be fit for any purpose held out by the Supplier.")),
    React.createElement("p", null, "8.2 Subject to clause 8.3, if:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) the Customer gives notice in writing to the Supplier during the warranty period, to the effect that some or all of the Deliverables do not comply with the warranties set out in clause 8.1;"),
        React.createElement("li", null, "(b) the Supplier is given a reasonable opportunity of examining such Deliverables; and"),
        React.createElement("li", null, "(c) the Customer (if asked to do so by the Supplier) returns such Goods to the Supplier's place of business at the Supplier's cost,")),
    React.createElement("p", null, "the Supplier shall, at its option, reperform, repair or replace any Deliverables that are found to be defective or refund the price of such defective Deliverables in full."),
    React.createElement("p", null, "8.3 The Supplier shall not be liable for Deliverables failure to comply with the warranties set out in clause 8.1 if:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) the Customer makes any further use of such Deliverables after giving notice of defects in accordance with clause 8.2;"),
        React.createElement("li", null, "(b) the defect arises because the Customer failed to follow the Supplier's oral or written instructions as to the storage, commissioning, installation, use and maintenance of the Goods and/or Software or (if there are none) good trade practice regarding the same;"),
        React.createElement("li", null, "(c) the Customer alters or repairs such Deliverables without the written consent of the Supplier;"),
        React.createElement("li", null, "(d) the defect arises as a result of fair wear and tear, wilful damage, negligence, or abnormal storage or working conditions; or"),
        React.createElement("li", null, "(e) the Deliverables differ from their description as a result of changes made to ensure they comply with applicable statutory or regulatory requirements.")),
    React.createElement("p", null, "8.4 The Supplier's only liability to the Customer if the Deliverables fail to comply with the warranties set out in clause 8.1 is as set out in clause 8.2."),
    React.createElement("p", null, "8.5 The terms implied by sections 13 to 15 of the Sale of Goods Act 1979 are, to the fullest extent permitted by law, excluded from the Agreement."),
    React.createElement("p", null, "8.6 The terms of the Agreement shall apply to any repaired or replacement Goods supplied by the Supplier."),
    React.createElement("p", null, "8.7 The Supplier warrants that it will provide the Services (a) exercising care, skill and diligence, to the standard expected of a like contractor in the same industry providing similar products and services."),
    React.createElement("p", null, "8.8 The Supplier reserves the right to amend the specification of the Goods if required by any applicable statutory or regulatory requirements."),
    React.createElement("h6", null, "TITLE AND RISK"),
    React.createElement("p", null, "9.1 Risk in Goods shall pass to the Customer on completion of unloading the Goods at the Delivery Location."),
    React.createElement("p", null, "9.2 Title to Goods shall only pass to the Customer once the Supplier receives payment in full (in cash or cleared funds) for them."),
    React.createElement("h6", null, "PRICE AND PAYMENT"),
    React.createElement("p", null, "10.1 Customer must pay to Supplier the applicable Price for any Deliverables supplied by Supplier."),
    React.createElement("p", null, "10.2 The Price excludes amounts in respect of value added tax or other applicable sales taxes (\u201CVAT\u201D), which the Customer shall additionally be liable to pay to the Supplier at the prevailing rate (if applicable), subject to the receipt of a valid VAT invoice."),
    React.createElement("p", null, "10.3 The Supplier may invoice the Customer for the Price of Deliverables plus VAT at the prevailing rate (if applicable):"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) in respect of Goods, on delivery of the Goods to the Delivery Location;"),
        React.createElement("li", null, "(b) in respect of Services, monthly in arrears; and"),
        React.createElement("li", null, "(c) in respect of Software, annually in advance.")),
    React.createElement("p", null, "10.4 The Supplier shall ensure that the invoice includes the invoice number and any supporting documentation that the Customer may reasonably require."),
    React.createElement("p", null, "10.5 The Customer shall pay invoices within thirty (30) days from the invoice date. Payment shall be made to the bank account nominated in writing by the Supplier."),
    React.createElement("p", null, "10.6 The Customer shall pay all amounts due under the Agreement in full without set-off, counterclaim, deduction or withholding (except for any deduction or withholding required by law)."),
    React.createElement("h6", null, "INSURANCE"),
    React.createElement("p", null, "11.1 The Supplier will during the Term, at its sole cost and expense, maintain the following insurances with a reputable insurer:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) Public liability insurance with a limit of indemnity of 5,000,000 GBP for any one occurrence;"),
        React.createElement("li", null, "(b) Products liability insurance with a limit of indemnity of 5,000,000 GBP in the aggregate;"),
        React.createElement("li", null, "(c) Professional indemnity insurance with a limit of indemnity of 1,000,000 GBP for any one occurrence; and"),
        React.createElement("li", null, "(d) Worker\u2019s compensation insurance as required by law.")),
    React.createElement("h6", null, "LIMITATION OF LIABILITY"),
    React.createElement("p", null, "12.1 Nothing in this Agreement shall limit or exclude either party\u2019s liability for:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) death or personal injury caused by its negligence, or the negligence of its employees, agents or subcontractors (as applicable);"),
        React.createElement("li", null, "(b) fraud or fraudulent misrepresentation;"),
        React.createElement("li", null, "(c) any matter in respect of which it would be unlawful for the Supplier to exclude or restrict liability.")),
    React.createElement("p", null, "12.2 Subject to clause 12.1:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) each party shall not be liable to the other party, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with the Agreement; and"),
        React.createElement("li", null, "(b) each party\u2019s aggregate liability to the other party for all other losses arising under or in connection with the Agreement, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall be limited to the Price.")),
    React.createElement("p", null, "12.3 Subject to clause 12.1, neither Soter Analytics Limited nor the Supplier shall in any circumstances be liable for any injury caused to or sustained by a Customer as a result of their use of the Goods and/or Software."),
    React.createElement("h6", null, "TERMINATION"),
    React.createElement("p", null, "13.1 Without limiting its other rights or remedies, either party may terminate this Agreement with immediate effect by giving written notice to the other party if:"),
    React.createElement("ol", null,
        React.createElement("li", null, "(a) the other party commits a material breach of any term of the Agreement and (if such a breach is remediable) fails to remedy that breach within 20 Business Days of that party being notified in writing to do so;"),
        React.createElement("li", null, "(b) the other party takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction; or"),
        React.createElement("li", null, "(c) the other party suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business.")),
    React.createElement("p", null, "13.2 Without limiting its other rights or remedies, the Supplier may terminate the Agreement with immediate effect by giving written notice to the Customer if the Customer fails to pay any amount due under the Agreement on the due date for payment and remains in default not less than 20 Business Days after being notified in writing to make such payment."),
    React.createElement("p", null, "13.3 Termination of the Agreement shall not affect any of the parties' rights and remedies that have accrued as at termination, including the right to claim damages in respect of any breach of this Agreement which existed at or before the date of termination."),
    React.createElement("p", null, "13.4 Clauses 6, 7, 11, 13.3, 13.4, 15.8, 15.11 and 15.12 and any other obligations which are expressed to or, by their nature, survive expiry or termination of this Agreement, will survive expiry or termination of this Agreement and are enforceable at any time at law or in equity."),
    React.createElement("h6", null, "DATA PROTECTION"),
    React.createElement("p", null, "14.1 The Supplier will comply with all applicable requirements of the Data Protection Legislation. This clause 14 is in addition to, and does not relieve, remove or replace, a party's obligations under the Data Protection Legislation."),
    React.createElement("p", null, "14.2 The Customer acknowledges that the Supplier shall disclose personal data on the Customer to the provider of the Software, Soter Analytics Limited, to enable it to provide the Customer with access to the Software. Soter Analytics Limited\u2019s use of the Customer\u2019s personal data shall be governed by its Privacy Policy."),
    React.createElement("h6", null, "GENERAL"),
    React.createElement("p", null,
        React.createElement("strong", null, "15.1 ASSIGNMENT AND OTHER DEALINGS."),
        " Neither party shall assign, transfer, charge, subcontract, declare a trust over or deal in any other manner with any or all of its rights or obligations under the Agreement without the prior written consent of the other party."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.2 ENTIRE AGREEMENT."),
        React.createElement("br", null),
        "(a) This Agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.",
        React.createElement("br", null),
        "(b) Each party agrees that it shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this agreement. Each party agrees that it shall have no claim for innocent or negligent misrepresentation based on any statement in this agreement."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.3 VARIATION."),
        " No variation of this Agreement shall be effective unless it is in writing and signed by the parties (or their authorized representatives)."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.4 COMPLIANCE WITH LAWS."),
        " Both parties must comply with all laws applicable to the performance of their respective obligations under this Agreement."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.5 WAIVER."),
        " No failure or delay by a party to exercise any right or remedy provided under the Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.6 SEVERANCE."),
        " If any provision or part-provision of the Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest of the Agreement."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.7 FURTHER STEPS."),
        " Each party must promptly do whatever any other party reasonably requires of it to give effect to this Agreement and to perform its obligations under this Agreement."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.8 DISPUTE RESOLUTION."),
        " The parties agree to work together in good faith to resolve any dispute regarding this Agreement internally and by escalating it to higher levels of management prior to resorting to litigation. Notwithstanding the existence of a dispute, each party will continue to perform its obligations under this Agreement. Nothing in this clause 15.8 will prevent a party from instituting proceedings to seek urgent injunctive, interlocutory, or declaratory relief in respect of a dispute."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.9 NOTICES."),
        React.createElement("br", null),
        "(a) Any notice or other communication given to a party under or in connection with the Agreement shall be in writing, addressed to that party at its address nominated in the Contract Details or such other address as that party may have specified to the other party in writing in accordance with this clause, and shall be delivered personally, or sent by pre-paid first-class post or other next working day delivery service, commercial courier.",
        React.createElement("br", null),
        "(b) A notice or other communication shall be deemed to have been received: if delivered personally, when left at the address referred to in clause 15.9(a); if sent by pre-paid first-class post or other next working day delivery service, at [9.00 am] on the [second] Business Day after posting; or, if delivered by commercial courier, on the date and at the time that the courier's delivery receipt is signed.",
        React.createElement("br", null),
        "(c) The provisions of this clause shall not apply to the service of any proceedings or other documents in any legal action."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.10 THIRD PARTY RIGHTS."),
        " No one other than a party to this Agreement and their permitted assignees shall have any right to enforce any of its terms."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.11 GOVERNING LAW."),
        " This Agreement, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation, shall be governed by, and construed in accordance with the law of England and Wales."),
    React.createElement("p", null,
        React.createElement("strong", null, "15.12 JURISDICTION."),
        " Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter or formation.")));
export default TermsAndConditionsDialog;
