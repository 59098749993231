import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import * as Emotion from 'emotion';
import { useDispatch, useSelector } from 'react-redux';
import _mergeWith from 'lodash/mergeWith';
import _isNumber from 'lodash/isNumber';
import _orderBy from 'lodash/orderBy';
import _omit from 'lodash/omit';
import _maxBy from 'lodash/maxBy';
import useDevice from 'usedevice';
import { useTranslation } from 'react-i18next';
import {
  daySelect, hazardsByTaskChart, progressBar, progressTopTextBlock, progressWrapper,
} from '../styles';
import { setStartTime } from '../../userTasksStore/actions';
import sliceLongString from '../../../../lib/utils/sliceLongString';
import { setOpenedModal } from '../../../../containers/ModalContainer/modalsStore/actions';
import CustomSelect from '../../../../lib/ui/CustomSelect';
import AddTaskButton from './AddTaskButton';
import { cardBody } from '../../../../assets/soter-flex-styles';
import { COLORS } from '../../../../constants/colors';
import Text from '../../../../lib/ui/Text';
import { mapConfigToProgramType } from '../../../overview/utils/mapConfigToProgramType';
import { getTimeInUserTimeZone } from '../../../../lib/utils/getTimeInUserTimeZone';
import getDataWithoutBlankHours from '../../../overview/utils/getDataWithoutBlankHours';
import SplitChart from '../../../overview/components/charts/SplitChart';
import { getTooltipText, getTooltipTextConstructor } from '../../../overview/utils/getTooltipText';
import TimelineChart from '../../../overview/components/charts/TimelineChart';

const oneHour = 3600000;

const optionWithTaskFillPercent = ({ label, fillDay }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className={progressTopTextBlock}>
      <div>{label}</div>
      <div>{`${Math.floor(fillDay * 100)}%`}</div>
    </div>
    <div className={progressWrapper}>
      <div
        className={progressBar}
        style={{
          width: `${fillDay * 100}%`,
          maxWidth: `${fillDay * 100}%`,
        }}
      />
    </div>
  </div>
);

const HazardsPerHourSplitChart = ({
  programId,
  hazardsConfig,
  timezone,
  workSessionId,
  deviceType,
  containerHeight,
  hazardsByDay,
  isTaskListBtnDisabled,
  setWorkSessionId,
  hazardsDisplayType,
  daySelectOptions,
}) => {
  const { language } = localStorage;
  const [chartCoords, setChartCoord] = useState('');
  const isAddTaskDialogOpen = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (isAddTaskDialogOpen.openedModal !== 'addDayTasks') {
      dispatch(setStartTime(false));
    }
  }, [isAddTaskDialogOpen.openedModal]);
  const ref = useRef();
  const width = useDevice();
  useEffect(() => {
    if (ref.current) {
      const coords = ref.current.getBoundingClientRect();
      setChartCoord({
        bottom: coords.bottom,
        height: coords.height,
        left: coords.left,
        right: coords.right,
        top: coords.top,
        width: coords.width,
        x: coords.x,
      });
    }
  }, [width]);

  const daySelectValue = daySelectOptions.find(
    ({ value }) => value === workSessionId,
  ) || {
    label: t('WEARABLES.USER_PAGE.NO_DATES'),
    value: '',
    fillDay: '',
  };
  useEffect(() => {
    setWorkSessionId(daySelectOptions[0]?.value);
  }, [programId]);
  const hasDataForChart = !!(hazardsByDay && hazardsByDay.length);
  const config = hazardsConfig
    ? mapConfigToProgramType(Object.keys(hazardsConfig), deviceType)
    : [];
  const chartId = `hazards-per-hour-stacked-chart-${programId}`;

  const hazardsPerHourChartdata = hasDataForChart
    ? hazardsByDay
      .find((dailyReport) => dailyReport.workSessionId === +workSessionId)
      ?.hazardsByHourList.filter(
        (item) => moment(item?.timeInMilliseconds).minutes() === 0,
      ) // temporary filter out all values with minutes
      .map((report) => {
        const hazards = Object.keys(report);
        return hazards.reduce(
          (acc, hazard) => {
            if (config?.includes(hazard)) {
              acc[hazard] = report[hazard];
              acc.overall += report[hazard];
              return acc;
            }
            return acc;
          },
          {
            timeInMilliseconds: report?.timeInMilliseconds,
            overall: 0,
          },
        );
      })
      .map((report) => ({
        ...report,
        time: getTimeInUserTimeZone(
          report?.timeInMilliseconds,
          timezone,
          'HH:mm',
        ),
      }))
    : null;
  const hazardsPerHourChartdataWithoutBlanks = getDataWithoutBlankHours(
    hazardsPerHourChartdata,
    timezone,
    oneHour,
  );
  const getTotalDurationByTask = (tasks) => {
    const totalDurationByTask = {};

    tasks?.forEach(({ timeStart, timeEnd, taskName }) => {
      const taskDuration = timeEnd - timeStart;

      if (!totalDurationByTask[taskName]) {
        totalDurationByTask[taskName] = taskDuration;
      } else {
        totalDurationByTask[taskName] += taskDuration;
      }
    });

    return totalDurationByTask;
  };

  const getCorrectedTask = (task, totalDurationByTask) => {
    const res = { ...task };
    const totalDuration = totalDurationByTask[task.taskName];
    const taskDuration = task.timeEnd - task.timeStart;
    const durationCoeff = taskDuration / totalDuration;

    Object.entries(task).forEach(([key, value]) => {
      if (_isNumber(value)) {
        res[key] = value * durationCoeff;
      }
    });

    return res;
  };

  const mergeTasksWithSameName = (tasks, totalDurationByTask) => {
    const customizer = (objValue, srcValue) => (_isNumber(objValue) ? objValue + srcValue : srcValue);

    const res = tasks?.reduce((acc, item) => {
      const correctedItem = getCorrectedTask(item, totalDurationByTask);

      if (!acc[item.taskName]) {
        acc[item.taskName] = correctedItem;
      } else {
        _mergeWith(acc[item.taskName], correctedItem, customizer);
      }
      return acc;
    }, {});

    return res ? Object.values(res) : [];
  };

  const getHazardsPerTaskData = () => {
    if (!hasDataForChart) return null;
    const hazardsByTask = hazardsByDay.find(
      (dailyReport) => dailyReport.workSessionId === +workSessionId,
    )?.hazardsByTaskList;
    const totalDurationByTask = getTotalDurationByTask(hazardsByTask);
    const tasks = mergeTasksWithSameName(hazardsByTask, totalDurationByTask);
    const hazardsMergedByTaskName = tasks.map((item) => _omit(item, ['id']));
    const result = hazardsMergedByTaskName.map((report) => {
      const hazards = Object.keys(report);
      return hazards.reduce(
        (acc, hazard) => {
          if (config?.includes(hazard)) {
            acc[hazard] = report[hazard];
            acc.overall += acc[hazard];
            return acc;
          }
          return acc;
        },
        {
          id: sliceLongString(report.taskName, 35),
          overall: 0,
        },
      );
    });

    const maxOverall = _maxBy(result, 'overall')?.overall;

    const resultWithEmpty = result.map((task) => ({
      ...task,
      empty: task.overall === 0 ? maxOverall / 10 : 0,
    }));

    return _orderBy(resultWithEmpty, ['overall'], ['desc']);
  };

  const hazardsPerTaskData = getHazardsPerTaskData();

  const getTaskTimelineData = () => {
    if (!hasDataForChart) return null;

    const hazardsByTask = hazardsByDay?.find(
      (dailyReport) => dailyReport.workSessionId === +workSessionId,
    )?.hazardsByTaskList;
    const mapped = _orderBy(hazardsByTask, ['timeStart'], ['asc']).map(
      (item) => [
        { name: item.taskName, date: item.timeStart, value: 1 },
        { name: item.taskName, date: item.timeEnd, value: 0 },
      ],
    );
    return mapped;
  };

  const taskTimelineData = getTaskTimelineData();

  const timelineStart
    = hazardsPerHourChartdata && hazardsPerHourChartdata.length > 0
      ? getTimeInUserTimeZone(
        hazardsPerHourChartdata[0]?.timeInMilliseconds - oneHour,
        timezone,
      )
      : 0;
  const timelineEnd
    = hazardsPerHourChartdata && hazardsPerHourChartdata.length > 0
      ? getTimeInUserTimeZone(
        hazardsPerHourChartdata[hazardsPerHourChartdata.length - 1]
          ?.timeInMilliseconds + oneHour,
        timezone,
      )
      : 0;

  const isHazardPerHourPerTaskView = hazardsDisplayType === 'task';

  const handleOpenAddTaskModal = () => {
    dispatch(setOpenedModal('addDayTasks', { modalID: programId }));
  };

  let hourStart = 0;
  const mouseMove = (e) => {
    let xStart = +chartCoords.x.toFixed();
    const chartWidth = +chartCoords.width.toFixed();
    const xEnd = +xStart + chartWidth;
    const hoursInChart = hazardsPerHourChartdataWithoutBlanks.length + 1;
    const chartSegmentWidth = chartWidth / hoursInChart;
    let counter = 0;
    for (xStart; xStart <= xEnd; xStart += chartSegmentWidth) {
      if (e.clientX > xStart && e.clientX < xStart + chartSegmentWidth) {
        if (counter === hazardsPerHourChartdataWithoutBlanks.length) {
          hourStart
            = hazardsPerHourChartdataWithoutBlanks[counter - 1]
              ?.timeInMilliseconds;
        } else {
          hourStart
            = hazardsPerHourChartdataWithoutBlanks[counter]?.timeInMilliseconds
            - 3600000;
        }
        break;
      }
      counter++;
    }
    dispatch(setStartTime(hourStart));
    handleOpenAddTaskModal();
  };

  return (
    <>
      <div>
        <CustomSelect
          className={daySelect}
          value={daySelectValue}
          options={[...daySelectOptions]}
          dropdownIndicator
          isSearchable={false}
          formatOptionLabel={optionWithTaskFillPercent}
          onChange={(_, value) => setWorkSessionId(value.value)}
        />
        {isHazardPerHourPerTaskView && (
          <div
            className={Emotion.css`
              margin-left: ${language === 'es' ? '75%' : '80%'};
              margin-top: 10px;
            `}
          >
            <AddTaskButton
              disabled={isTaskListBtnDisabled}
              onClick={handleOpenAddTaskModal}
            />
          </div>
        )}
      </div>
      <div className={cardBody}>
        {isHazardPerHourPerTaskView ? (
          <SplitChart
            className={hazardsByTaskChart}
            chartId={chartId}
            hasDataForChart={hasDataForChart}
            config={[...config, 'empty']}
            data={hazardsPerTaskData}
            containerHeight={containerHeight}
            withTextOnChart
            setTooltip={getTooltipTextConstructor({ roundingOverall: 1 })(t)}
          />
        ) : (
          <>
            <div
              style={{ cursor: 'pointer' }}
              ref={ref}
              onClick={mouseMove}
              key="clickOnChart"
            >
              <SplitChart
                chartId={chartId}
                hasDataForChart={hasDataForChart}
                config={config}
                data={hazardsPerHourChartdataWithoutBlanks}
                containerHeight={containerHeight}
                setTooltip={getTooltipText(t)}
              />
            </div>
            <div style={{ display: 'flex', marginTop: 10, marginBottom: 30 }}>
              <Text
                text={t('WEARABLES.USER_PAGE.TASKS').toUpperCase()}
                style={{ color: COLORS.GRAY[400] }}
              />
              <AddTaskButton
                disabled={isTaskListBtnDisabled}
                onClick={handleOpenAddTaskModal}
              />
            </div>
            <TimelineChart
              data={taskTimelineData}
              timeStart={timelineStart}
              timeEnd={timelineEnd}
              timezone={timezone}
            />
          </>
        )}
      </div>
    </>
  );
};

export default HazardsPerHourSplitChart;
