import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { smallMutedText } from '../../soter-flex-styles';

export const grayBottom = Emotion.css`
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  padding-bottom: 25px;
`;

export const makeGrayBottomWithWidth = (widthPercent) => Emotion.css`
  ${grayBottom};
  position: absolute;
  left: ${94 - widthPercent}%;
  width: ${widthPercent}%;
`;

export const companyWrapper = Emotion.css`
  &:last-child {
    & > div > div {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`;

export const companyBar = Emotion.css`
  display: flex;
  margin-top: 25px;
`;

export const makeCompanyWrapperWithBorder = (withBorderBottom) => Emotion.css`
  ${withBorderBottom && grayBottom};

  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
`;

export const plusButton = Emotion.css`
  cursor: pointer;
`;

export const disabledButton = Emotion.css`
  cursor: default;

  path {
    stroke: ${COLORS.GRAY[200]};
  }
`;

export const subText = Emotion.css`
  ${smallMutedText};

  font-size: 12px;
  margin-top: 5px;
`;
