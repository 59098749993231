import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { text } from 'assets/jss/style';

export const border = Emotion.css`
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  margin-bottom: 25px;
`;
export const header = Emotion.css`
  ${border};
  padding-top: 15px;
  div {
    margin-bottom: 15px;
  }
`;
export const secondaryHeader = Emotion.css`
  ${border};

  display: flex;
  justify-content: space-between;
`;
export const secondaryHeaderTitle = text(COLORS.GRAY[400], 18, 800);

export const titleWithBadge = Emotion.css`
  margin-bottom: 25px;
`;
