export default class DemandsActivityByteConvertor {
  // eslint-disable-next-line class-methods-use-this
  convertIntToActivityValues(int) {
    // eslint-disable-next-line no-bitwise
    const color = int >> 8;
    // eslint-disable-next-line no-bitwise
    const value = int & 255;
    return { color, value };
  }

  // eslint-disable-next-line class-methods-use-this
  convertActivityValuesToInt(value, color) {
    // eslint-disable-next-line no-bitwise
    return (color << 8) + value;
  }

  // eslint-disable-next-line class-methods-use-this
  convertBase64ToUintArray(base64string) {
    const rawString = window.atob(base64string);
    const uint8Array = new Uint8Array(rawString.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rawString.length; i++) {
      uint8Array[i] = rawString.charCodeAt(i);
    }
    const uint16Array = new Uint16Array(uint8Array.buffer);
    return uint16Array;
  }

  // eslint-disable-next-line class-methods-use-this
  convertUintArrayToBase64(array) {
    const decoder = new TextDecoder('utf8');
    return btoa(decoder.decode(array));
  }

  getZeroBase64String() {
    return this.convertUintArrayToBase64(new Uint16Array([0, 0, 0, 0]));
  }
}
