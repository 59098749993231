export const sorting = 'sorting';
export const sortingType = 'sorting-type';
export const search = 'search';
export const page = 'page';
export const pageSize = 'page-size';
export const filter = 'filter';
export const companyID = 'companyID';
export const tableLength = 'table-length';
export const passedPrograms = 'passed-programs';
export const calendar = 'calendar';
export const urlParams = {
    sorting,
    search,
    page,
    pageSize,
    filter,
    companyID,
    tableLength,
    sortingType,
    passedPrograms,
    calendar,
};
