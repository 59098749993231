import { removeDepartment, removeSite } from '../companiesStore/actions';
import { deleteEntry } from '../categories/store/actions';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
export const getDeleteEntriesAction = (id, dispatch, editedEntry, reqLength, index, 
// @ts-ignore
companyId) => {
    switch (id) {
        case -1:
            dispatch(removeDepartment(editedEntry, false, TableType.entries, false, reqLength, index));
            break;
        case -2:
            dispatch(removeSite(editedEntry, false, TableType.entries, false, reqLength, index));
            break;
        default:
            dispatch(deleteEntry({
                editedEntry, reqLength, index, companyId,
            }));
            break;
    }
};
export const getActionTypeById = (id) => {
    switch (id) {
        case -1:
            return 'updateDepartment';
        case -2:
            return 'updateSite';
        default:
            return 'updateEntry';
    }
};
export const getCreateEntryActionType = (id) => {
    switch (id) {
        case -1:
            return 'createDepartments';
        case -2:
            return 'createSites';
        default:
            return 'createEntries';
    }
};
export const getFilteredByTitleArray = (items) => items.sort((a, b) => {
    const firstTitle = a.title.toLowerCase();
    const secondTitle = b.title.toLowerCase();
    if (firstTitle > secondTitle) {
        return 1;
    }
    if (firstTitle < secondTitle) {
        return -1;
    }
    return 0;
});
