/**
 * @fileoverview gRPC-Web generated client stub for auth
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/auth_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_company_message_pb = require('../../../api/dashboard/v2/company_message_pb.js')

var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js')

var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js')
const proto = {};
proto.auth = require('./auth_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/auth.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.AuthMeRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_AuthMe = new grpc.web.MethodDescriptor(
  '/auth.AuthService/AuthMe',
  grpc.web.MethodType.UNARY,
  proto.auth.AuthMeRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.AuthMeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.AuthMeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.authMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/AuthMe',
      request,
      metadata || {},
      methodDescriptor_AuthService_AuthMe,
      callback);
};


/**
 * @param {!proto.auth.AuthMeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.authMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/AuthMe',
      request,
      metadata || {},
      methodDescriptor_AuthService_AuthMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.UserResourceRequest,
 *   !proto.auth.UserResourceResponse>}
 */
const methodDescriptor_AuthService_GetUserResource = new grpc.web.MethodDescriptor(
  '/auth.AuthService/GetUserResource',
  grpc.web.MethodType.UNARY,
  proto.auth.UserResourceRequest,
  proto.auth.UserResourceResponse,
  /**
   * @param {!proto.auth.UserResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.UserResourceResponse.deserializeBinary
);


/**
 * @param {!proto.auth.UserResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.UserResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.UserResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.getUserResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/GetUserResource',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetUserResource,
      callback);
};


/**
 * @param {!proto.auth.UserResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.UserResourceResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.getUserResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/GetUserResource',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetUserResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ResetPasswordRequest,
 *   !proto.auth.ResetPasswordResponse>}
 */
const methodDescriptor_AuthService_ResetPasswordSendLink = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ResetPasswordSendLink',
  grpc.web.MethodType.UNARY,
  proto.auth.ResetPasswordRequest,
  proto.auth.ResetPasswordResponse,
  /**
   * @param {!proto.auth.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.ResetPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.ResetPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.ResetPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.resetPasswordSendLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ResetPasswordSendLink',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPasswordSendLink,
      callback);
};


/**
 * @param {!proto.auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.ResetPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.resetPasswordSendLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ResetPasswordSendLink',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPasswordSendLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ResetPasswordByTokenRequest,
 *   !proto.auth.ResetPasswordByTokenResponse>}
 */
const methodDescriptor_AuthService_ResetPasswordByToken = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ResetPasswordByToken',
  grpc.web.MethodType.UNARY,
  proto.auth.ResetPasswordByTokenRequest,
  proto.auth.ResetPasswordByTokenResponse,
  /**
   * @param {!proto.auth.ResetPasswordByTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.ResetPasswordByTokenResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ResetPasswordByTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.ResetPasswordByTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.ResetPasswordByTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.resetPasswordByToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ResetPasswordByToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPasswordByToken,
      callback);
};


/**
 * @param {!proto.auth.ResetPasswordByTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.ResetPasswordByTokenResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.resetPasswordByToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ResetPasswordByToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPasswordByToken);
};


module.exports = proto.auth;

