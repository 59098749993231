/**
 * @fileoverview gRPC-Web generated client stub for admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/admin.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_admin_dashboard_users_message_pb = require('../../../api/dashboard/v2/admin_dashboard_users_message_pb.js')
const proto = {};
proto.admin = require('./admin_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin.AdminClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin.AdminPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUsersRequest,
 *   !proto.admin.DashboardUsersResponse>}
 */
const methodDescriptor_Admin_GetDashboardUsers = new grpc.web.MethodDescriptor(
  '/admin.Admin/GetDashboardUsers',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUsersRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUsersResponse,
  /**
   * @param {!proto.admin.DashboardUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUsersResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.DashboardUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.DashboardUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.getDashboardUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/GetDashboardUsers',
      request,
      metadata || {},
      methodDescriptor_Admin_GetDashboardUsers,
      callback);
};


/**
 * @param {!proto.admin.DashboardUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.DashboardUsersResponse>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.getDashboardUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/GetDashboardUsers',
      request,
      metadata || {},
      methodDescriptor_Admin_GetDashboardUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.CreateDashboardUserRequest,
 *   !proto.admin.DashboardUser>}
 */
const methodDescriptor_Admin_CreateDashboardUser = new grpc.web.MethodDescriptor(
  '/admin.Admin/CreateDashboardUser',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.CreateDashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUser,
  /**
   * @param {!proto.admin.CreateDashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUser.deserializeBinary
);


/**
 * @param {!proto.admin.CreateDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.DashboardUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.DashboardUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.createDashboardUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/CreateDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Admin_CreateDashboardUser,
      callback);
};


/**
 * @param {!proto.admin.CreateDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.DashboardUser>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.createDashboardUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/CreateDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Admin_CreateDashboardUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.UpdateDashboardUserRequest,
 *   !proto.admin.DashboardUser>}
 */
const methodDescriptor_Admin_EditDashboardUser = new grpc.web.MethodDescriptor(
  '/admin.Admin/EditDashboardUser',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.UpdateDashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUser,
  /**
   * @param {!proto.admin.UpdateDashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUser.deserializeBinary
);


/**
 * @param {!proto.admin.UpdateDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.DashboardUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.DashboardUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.editDashboardUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/EditDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Admin_EditDashboardUser,
      callback);
};


/**
 * @param {!proto.admin.UpdateDashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.DashboardUser>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.editDashboardUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/EditDashboardUser',
      request,
      metadata || {},
      methodDescriptor_Admin_EditDashboardUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.DashboardUser>}
 */
const methodDescriptor_Admin_DeleteDashboardUsers = new grpc.web.MethodDescriptor(
  '/admin.Admin/DeleteDashboardUsers',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUser,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUser.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.DashboardUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.DashboardUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.deleteDashboardUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/DeleteDashboardUsers',
      request,
      metadata || {},
      methodDescriptor_Admin_DeleteDashboardUsers,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.DashboardUser>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.deleteDashboardUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/DeleteDashboardUsers',
      request,
      metadata || {},
      methodDescriptor_Admin_DeleteDashboardUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.GetInviteLinkResponse>}
 */
const methodDescriptor_Admin_GetInviteLink = new grpc.web.MethodDescriptor(
  '/admin.Admin/GetInviteLink',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.GetInviteLinkResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.GetInviteLinkResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.GetInviteLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.GetInviteLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.getInviteLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/GetInviteLink',
      request,
      metadata || {},
      methodDescriptor_Admin_GetInviteLink,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.GetInviteLinkResponse>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.getInviteLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/GetInviteLink',
      request,
      metadata || {},
      methodDescriptor_Admin_GetInviteLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.SendInviteLinkResponse>}
 */
const methodDescriptor_Admin_SendInviteLink = new grpc.web.MethodDescriptor(
  '/admin.Admin/SendInviteLink',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.SendInviteLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.SendInviteLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.sendInviteLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/SendInviteLink',
      request,
      metadata || {},
      methodDescriptor_Admin_SendInviteLink,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.SendInviteLinkResponse>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.sendInviteLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/SendInviteLink',
      request,
      metadata || {},
      methodDescriptor_Admin_SendInviteLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.SendInviteLinkResponse>}
 */
const methodDescriptor_Admin_ResendInviteLink = new grpc.web.MethodDescriptor(
  '/admin.Admin/ResendInviteLink',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.SendInviteLinkResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.SendInviteLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.SendInviteLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.resendInviteLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/ResendInviteLink',
      request,
      metadata || {},
      methodDescriptor_Admin_ResendInviteLink,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.SendInviteLinkResponse>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.resendInviteLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/ResendInviteLink',
      request,
      metadata || {},
      methodDescriptor_Admin_ResendInviteLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin.DashboardUserRequest,
 *   !proto.admin.ResetDashboardUserPasswordResponse>}
 */
const methodDescriptor_Admin_ResetDashboardUserPassword = new grpc.web.MethodDescriptor(
  '/admin.Admin/ResetDashboardUserPassword',
  grpc.web.MethodType.UNARY,
  api_dashboard_v2_admin_dashboard_users_message_pb.DashboardUserRequest,
  api_dashboard_v2_admin_dashboard_users_message_pb.ResetDashboardUserPasswordResponse,
  /**
   * @param {!proto.admin.DashboardUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard_v2_admin_dashboard_users_message_pb.ResetDashboardUserPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin.ResetDashboardUserPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin.ResetDashboardUserPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin.AdminClient.prototype.resetDashboardUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin.Admin/ResetDashboardUserPassword',
      request,
      metadata || {},
      methodDescriptor_Admin_ResetDashboardUserPassword,
      callback);
};


/**
 * @param {!proto.admin.DashboardUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin.ResetDashboardUserPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.admin.AdminPromiseClient.prototype.resetDashboardUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin.Admin/ResetDashboardUserPassword',
      request,
      metadata || {},
      methodDescriptor_Admin_ResetDashboardUserPassword);
};


module.exports = proto.admin;

