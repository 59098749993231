// eslint-disable-next-line import/prefer-default-export
export const fuzzySearch = (initString, searchString, ratio = 1) => {
  const string = initString.toLowerCase();
  const compare = searchString.toLowerCase();
  let matches = 0;
  if (string.indexOf(compare) > -1) return true; // covers basic partial matches
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < compare.length; i++) {
    // eslint-disable-next-line no-unused-expressions
    string.indexOf(compare[i]) > -1 ? matches += 1 : matches -= 1;
  }
  return (matches / initString.length >= ratio || searchString === '');
};
