var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { createFilter } from 'react-select';
import * as Emotion from 'emotion';
import { ArrowDown } from '@rsuite/icons';
import { COLORS } from '../../constants/colors';
import { errorMessage } from '../../assets/soter-flex-styles';
const clearIndicatorStyle = Emotion.css `
  padding: 0 10px;
  color: #a6a6a6;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    color: #de350b;
  }
`;
const customFilterOption = createFilter({
    stringify: (option) => option.label,
});
const dropdownIndicatorStyle = Emotion.css `
  width: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const getCustomStyles = (formikError) => ({
    control: (provided) => (Object.assign(Object.assign({}, provided), { boxShadow: 'none', transition: 'border-color ease-in-out 0.3s', borderColor: formikError ? COLORS.RED[600] : COLORS.GRAY[300] })),
    menu: (styles) => (Object.assign(Object.assign({}, styles), { zIndex: 1000, color: COLORS.GRAY[950] })),
    input: (styles) => (Object.assign(Object.assign({}, styles), { fontSize: '14px' })),
    singleValue: (styles) => (Object.assign(Object.assign({}, styles), { fontSize: '14px', color: COLORS.GRAY[950] })),
    placeholder: (styles) => (Object.assign(Object.assign({}, styles), { fontSize: '14px', color: 'rgba(0, 0, 0, 0.24)' })),
});
const ClearIndicator = ({ innerRef, innerProps }) => (React.createElement("span", Object.assign({ className: clearIndicatorStyle, ref: innerRef }, innerProps), "\u2715"));
const DropdownIndicator = ({ innerRef, innerProps }) => (React.createElement("span", Object.assign({ className: dropdownIndicatorStyle, ref: innerRef }, innerProps),
    React.createElement(ArrowDown, null)));
const CustomSelect = (_a) => {
    var { name, value, options = [], filterByLabel, dropdownIndicator, clearIndicator, onChange, components, formik, customFormikCallback } = _a, rest = __rest(_a, ["name", "value", "options", "filterByLabel", "dropdownIndicator", "clearIndicator", "onChange", "components", "formik", "customFormikCallback"]);
    const { t } = useTranslation();
    const defaultComponents = {
        DropdownIndicator: dropdownIndicator ? DropdownIndicator : null,
        IndicatorSeparator: () => null,
        ClearIndicator: clearIndicator ? ClearIndicator : null,
    };
    const handleChange = (val, onChangeObj) => {
        if (formik && !customFormikCallback) {
            formik.setFieldTouched(name);
            formik.setFieldValue(name, val || (rest.isMulti ? [] : null));
        }
        else {
            onChange(name, val, onChangeObj);
        }
    };
    const formikError = formik && name && formik.touched[name] && formik.errors[name];
    const renderSelect = () => (
    // @ts-ignore
    React.createElement(Select, Object.assign({ styles: getCustomStyles(formikError), value: formik && name && formik.values[name] ? formik.values[name] : value, options: options, placeholder: t('WEARABLES.ACTIVITY.DIALOGS.SEARCH'), components: Object.assign(Object.assign({}, defaultComponents), components), filterOption: filterByLabel ? customFilterOption : undefined, theme: (theme) => (Object.assign(Object.assign({}, theme), { borderRadius: 3, colors: Object.assign(Object.assign({}, theme.colors), { neutral20: COLORS.GRAY[300], neutral30: formikError ? COLORS.RED[600] : COLORS.LIGHT_BLUE[600], primary: formikError ? COLORS.RED[600] : COLORS.LIGHT_BLUE[600] }) })), classNamePrefix: "custom-select", onChange: handleChange }, rest)));
    if (formik) {
        return (React.createElement("div", { style: { position: 'relative' } },
            renderSelect(),
            formikError && React.createElement("div", { className: errorMessage }, t(formikError))));
    }
    return renderSelect();
};
CustomSelect.defaultProps = {
    dropdownIndicator: false,
    clearIndicator: false,
};
export default CustomSelect;
