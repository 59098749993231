import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { navigate, useParams } from '@reach/router';
import * as Emotion from 'emotion';
import { Risks } from 'init/proto_client';
import { ReactComponent as Save } from '../../assets/img/save.svg';
import { TASK_VIDEO_MUTED_UNMUTED } from '../../constants/analyticsEvents';
import { container } from '../../assets/soter-flex-styles';
import { useAppSelector } from '../../lib/utils/useStore';
import getThresholds from '../utils/getThresholds';
import {
  createRebaWeights, deleteRebaWeights,
  getRebaData, getRebaWeights, getRebaWeightsStream, getTaskVideo,
} from '../gallery/videosStore/actions';
import parseMovementData from '../utils/parseMovementData';
import useVideoTimeUpdateHandler from '../video/utils/useVideoTimeUpdateHandler';
import { resetAssessments } from '../video/assessmentsStore/actions';
import { resetTaskVideos } from '../gallery/videosStore/videoSlice';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import prepareChartAxisData from '../utils/prepareChartAxisData';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
import { routes } from '../../containers/PrivatPageHOC/constants/routes';
import Text from '../../lib/ui/Text';
import Button from '../../lib/ui/Button';
import VideoPlayerWithStickman from '../components/VideoPlayerWIthStickman';
import Loader from '../../lib/ui/Loader';
import RebaChart from './components/RebaChart';

const chartHolderStyle = (width) => Emotion.css`
  display: grid;
  grid-template-columns: 1fr ${width}px 1fr;
  position: relative;
  padding-bottom: 100px;
`;

const containerStyle = Emotion.css`
  ${container};
  padding-bottom: 100px;
`;
const headerStyle = Emotion.css`
  display: flex;
  align-items: center;
`;
const saveIconHolderStyle = Emotion.css`
  margin-right: 6.5px;
  display: flex;
  align-items: center;
`;
const videoHolderStyle = Emotion.css`
  display: grid;
  justify-content: center;
  align-items: end;
  margin: 20px 0 30px;
`;

const RebaWeightContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const videoRef = useRef();
  const canvasRef = useRef();

  const [rebaWeights, setRebaWeights] = useState([]);
  const [storedRebaWeights, setStoredRebaWeights] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isRewoundVideo, setIsRewoundVideo] = useState(false);
  const [isDataParsed, setIsDataParsed] = useState(false);
  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  const [userActions, setUserActions] = useState([
    {
      id: Risks.LOW,
      name: t('SOTER_TASK.ASSESSMENT.REBA.CHART.WEIGHT_OPTIONS.LOW'),
    },
    {
      id: Risks.MEDIUM,
      name: t('SOTER_TASK.ASSESSMENT.REBA.CHART.WEIGHT_OPTIONS.MEDIUM'),
    },
    {
      id: Risks.HIGH,
      name: t('SOTER_TASK.ASSESSMENT.REBA.CHART.WEIGHT_OPTIONS.HIGH'),
    },
  ]);

  const {
    movementData,
    rebaData,
    currentVideo,
    rebaWeightsList,
    isRebaReady,
  } = useAppSelector((state) => state.videos);
  const { notifications } = useAppSelector((state) => state.notifications);
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    notifications[0]?.status === 'TYPE_SUCCESS' &&
      setTimeout(() => navigate(`/${selectedCompany.id}/users/soter-genius/gallery/${videoId}`), 2500) && dispatch(getRebaData(currentVideo.id));
  }, [notifications[0]?.status])

  const { anglesConfig, videoHeightPx, id: videoId } = currentVideo;
  const thresholds = getThresholds(anglesConfig);
  const parsedData = useMemo(
    () => parseMovementData(movementData, thresholds),
    [movementData],
  );
  const VIDEO_WIDTH = 784;
  const VIDEO_HEIGHT = 439;

  const bodyPartNames = {
    back_angle: 'SOTER_TASK.BODY_PARTS.BACK',
  };
  const headersForChart = bodyPartNames;
  headersForChart.reba = 'REBA';
  const bodyParts = Object.keys(headersForChart);
  const selectedBodyPart = bodyParts[0];
  const totalSeconds = Math.round(movementData?.length / 30);
  const chartAngleMax = 180;
  const videoLengthPixelRatio
    = ((movementData.length / 30) * 1000) / VIDEO_WIDTH;
  const canvasScale = VIDEO_HEIGHT / videoHeightPx;
  const frameCallbackIsAvailable
    = 'requestVideoFrameCallback' in HTMLVideoElement.prototype;
  const frameChangeCallback = (n, m) => {
    setCurrentTimeMs(m.mediaTime * 1000);
    videoRef.current?.requestVideoFrameCallback(frameChangeCallback);
  };
  const timeUpdateHandler = useVideoTimeUpdateHandler(setCurrentTimeMs);

  useEffect(() => {
    const storedRebaWeights = rebaWeightsList;
    const existingWeights = [];
    let initalX = 48;
    const barShape = {
      height: 16,
      fill: '#2096F3',
      cornerRadius: 2,
    };

    if (totalSeconds && storedRebaWeights) {
      storedRebaWeights.forEach((storedWeight) => {
        const weight = { ...barShape };
        const selected = userActions.find(
          (action) => action.id === storedWeight.weight,
        );
        const barStartXAxis
          = VIDEO_WIDTH / (Math.ceil(totalSeconds) / (storedWeight.timeStart / 1000));
        const barEndXAxis = VIDEO_WIDTH / (Math.ceil(totalSeconds) / (storedWeight.timeEnd / 1000));
        weight.name = selected?.name;
        weight.id = selected?.id;
        weight.x = barStartXAxis;
        weight.y = initalX;
        weight.width = barEndXAxis - barStartXAxis;
        existingWeights.push(weight);
        initalX += 40;
      });
    }

    setRebaWeights(existingWeights);
  }, [rebaWeightsList?.length, totalSeconds]);

  useEffect(() => {
    dispatch(getTaskVideo(+params?.id));
    return () => {
      dispatch(resetAssessments());
      dispatch(resetTaskVideos());
    };
  }, [params.id]);

  useEffect(() => {
    const widthsOfBars = [];
    rebaWeights?.map((w) => {
      widthsOfBars.push(w.width);
    });
    const totalBarsWidth = widthsOfBars.reduce((total, width) => total + width, 0);
    totalBarsWidth > VIDEO_WIDTH - 100 ? userActions.forEach((el) => el.disabled = true) : userActions.forEach((el) => el.disabled = false);
    // console.log(widthsOfBars)
  }, [rebaWeights.length]);

  useEffect(() => {
    if (frameCallbackIsAvailable && !navigator.vendor.match(/apple/i)) {
      videoRef.current?.requestVideoFrameCallback(frameChangeCallback);
    } else {
      videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
    }
    return () => {
      videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
    };
  }, [videoRef]);

  useEffect(() => {
    if (parsedData?.length > 0) {
      setIsDataParsed(true);
    } else {
      setIsDataParsed(false);
    }
  }, [parsedData]);

  useEffect(() => {
    dispatch(getRebaWeights({ id: +params?.id, setIsLoading }));
  }, []);

  useEffect(() => {
    if (currentVideo.id) {
      dispatch(getRebaWeightsStream(currentVideo.id));
      if (isRebaReady) dispatch(getRebaData(currentVideo.id));
    }
  }, [isRebaReady, currentVideo]);

  const sendMuteAnalyticsEvent = () => {
    setIsMuted((prev) => {
      const analyticsParams = {
        is_muted: !prev,
      };
      window.logEvent(TASK_VIDEO_MUTED_UNMUTED, analyticsParams);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(`${TASK_VIDEO_MUTED_UNMUTED}: ${JSON.stringify(analyticsParams)}`));
      }
      return !prev;
    });
  };

  const toggleMuteBtn = () => {
    if (videoRef.current.volume > 0) {
      if (!isMuted) return;

      sendMuteAnalyticsEvent();
    } else {
      if (isMuted) return;

      sendMuteAnalyticsEvent();
    }
  };

  const chartAxisData = useMemo(
    () => prepareChartAxisData(
      movementData,
      VIDEO_WIDTH,
      bodyPartNames.reba,
    ),
    [movementData],
  );

  const weightIds = storedRebaWeights?.map((w) => w.weight);

  const deletedWeightsIds = rebaWeightsList
    ?.filter((w) => !weightIds.includes(w.weight))
    .map((el) => el.id);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  return (
    <div className={containerStyle}>
      <NavigateBackButton name={t('ADMIN.GO_BACK')} navigateTo={`/${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.gallery}/${videoId}`} />
      <div className={headerStyle}>
        <Text
          text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.ADDING_WEIGHT')}
          appearance="headlineOne"
          weight="bold"
        />
        <Button
          text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.SAVE')}
          style={{
            marginLeft: '24px',
            fontSize: '20px',
            fontWeight: 500,
          }}
          disabled={isButtonDisabled
            || !(rebaWeights?.length
            || deletedWeightsIds?.length
            || rebaWeightsList?.length)}
          handler={() => {
            setIsButtonDisabled(true);
            const weightIds = storedRebaWeights?.map((w) => w.weight);
            const updatedWeightsIds = rebaWeightsList
              .filter((w) => weightIds.includes(w.weight))
              .map((el) => el.id);

            const deletedWeightsIds = rebaWeightsList
              ?.filter((w) => !weightIds.includes(w.weight))
              .map((el) => el.id);
            if (rebaWeights.length) {
              dispatch(
                createRebaWeights({
                  weights: storedRebaWeights,
                }),
              );
            }
            if (deletedWeightsIds.length || updatedWeightsIds.length) {
              dispatch(deleteRebaWeights({ ids: [...updatedWeightsIds, ...deletedWeightsIds], isNotification: rebaWeights.length === 0 })) && localStorage.setItem('deleteRebaWeights', [...updatedWeightsIds, ...deletedWeightsIds]);
            }
          }}
        >
          <span className={saveIconHolderStyle}>
            <Save />
          </span>
        </Button>
      </div>
      <div className={videoHolderStyle}>
        <div>
          <VideoPlayerWithStickman
            currentVideo={currentVideo}
            videoHeight={VIDEO_HEIGHT}
            videoWidth={VIDEO_WIDTH}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            toggleMuteBtn={toggleMuteBtn}
            isRewoundVideo={isRewoundVideo}
            setIsRewoundVideo={setIsRewoundVideo}
            videoRef={videoRef}
            canvasRef={canvasRef}
            currentTimeMs={currentTimeMs}
            canvasScale={canvasScale}
            totalFrames={movementData.length}
            isDataParsed={isDataParsed}
            selectedBodyPart={selectedBodyPart}
            parsedData={parsedData}
          />
        </div>
      </div>
      {!rebaData.length
        ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader />
          </div>
        )
        : (
          <div className={chartHolderStyle(VIDEO_WIDTH)}>
            <Text
              text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.WEIGHT')}
              appearance="footnote"
              weight="bold"
              iconAfterText
              style={{
                display: 'flex',
                fontWeight: ' bold',
              }}
            />
            <Text
              text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.TIMELINE')}
              appearance="footnote"
              weight="bold"
              style={{
                fontWeight: ' bold',
                marginTop: '0',
              }}
            />
            <div />
            {movementData && (
            <RebaChart
              videoWidth={VIDEO_WIDTH}
              progressPosition={Math.round(currentTimeMs / videoLengthPixelRatio)}
              points={rebaData}
              rebaWeights={rebaWeights}
              setRebaWeights={setRebaWeights}
              setStoredRebaWeights={setStoredRebaWeights}
              videoId={videoId}
              userActions={userActions}
              setUserActions={setUserActions}
              axisData={chartAxisData}
              totalSeconds={totalSeconds}
              setIsButtonDisabled={setIsButtonDisabled}
            />
            )}
          </div>
        )}
    </div>
  );
};

export default RebaWeightContainer;
