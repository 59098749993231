import _map from 'lodash/map';
import _reduce from 'lodash/reduce';

const activities = {
  sitting: 'JOB_DICTIONARY.ACTIVITIES.SITTING',
  standingWalking: 'JOB_DICTIONARY.ACTIVITIES.STANDING_WALKING',
  activeWork: 'JOB_DICTIONARY.ACTIVITIES.ACTIVE_WORK',
  liftingLowering: 'JOB_DICTIONARY.ACTIVITIES.LIFTING_LOWERING',
  armElevation: 'JOB_DICTIONARY.ACTIVITIES.ARM_ELEVATION',
  pushingPulling: 'JOB_DICTIONARY.ACTIVITIES.PUSHING_PULLING',
  stoopingBending: 'JOB_DICTIONARY.ACTIVITIES.STOOPING_BENDING',
};

const getActivityColor = (activity) => {
  if (activity === activities.sitting) return '#61B821';
  if (activity === activities.standingWalking) return '#34D59E';
  if (activity === activities.armElevation) return '#A25ABD';
  if (activity === activities.pushingPulling) return '#F94957';
  if (activity === activities.liftingLowering) return '#1045CC';
  if (activity === activities.stoopingBending) return '#FFD786';
  if (activity === activities.activeWork) return '#FEA232';
};

export default function (currentRole) {
  const activitiesProportionCoefficient = _reduce(currentRole,
    (acc, value, key) => ((key === 'sitting' || key === 'standingWalking' || key === 'activeWork') ? acc + value : acc + 0), 0) / 100;
  const hazardsProportionCoefficient = _reduce(currentRole,
    (acc, value, key) => ((key === 'stoopingBending' || key === 'pushingPulling' || key === 'liftingLowering' || key === 'armElevation') ? acc + value : acc + 0), 0) / 100;
  return _map(activities, (activity, key) => {
    const value = currentRole[key];
    const result = {
      activityKey: key,
      name: activity,
      value: value?.toFixed(1),
      proportion: value / activitiesProportionCoefficient,
      hazardsProportion: value / hazardsProportionCoefficient,
      color: getActivityColor(activity),
    };
    return result;
  });
}
