import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Text from 'lib/ui/Text';
import {
  flexColumn,
  flexRow,
  programIcon,
  programInfoMainText,
  programInfoSubText,
} from 'assets/soter-flex-styles';
import spine from 'assets/img/icons/LogoSpine.svg';
import shoulder from 'assets/img/icons/LogoShoulder.svg';
import NoDataInfo from '../../NoDataInfo';

const ProgramInfo = ({ program, sync, timezone }) => {
  const { t } = useTranslation();
  const noDataMessage = t('GENERAL.NO_DATA');
  const { id: programId, title: programName } = program;

  return (
    <>
      {programId || programId === 0 ? (
        <div
          className={flexRow}
          style={{
            height: '58px',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <div className={programIcon}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              src={program.deviceType !== 'armband' ? spine : shoulder}
              alt={programName?.substring(0, 10)}
            />
          </div>
          <div className={flexColumn}>
            <Text
              text={programName}
              className={programInfoMainText}
              style={{ fontSize: programName?.length < 25 ? '24px' : '16px' }}
            />
            <Text
              text={
                sync
                  ? `${t('WEARABLES.ACTIVITY.SESSION')}: ${moment(sync)
                    .tz(timezone)
                    .format('MMM DD, YYYY')}`
                  : ''
              }
              className={programInfoSubText}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <NoDataInfo noDataMessage={noDataMessage} />
          <Text
            text={
              sync
                ? `${t('WEARABLES.ACTIVITY.SESSION')}: ${moment(sync)
                  .tz(timezone)
                  .format('MMM DD, YYYY')}`
                : ''
            }
            className={programInfoSubText}
          />
        </div>
      )}
    </>
  );
};

export default ProgramInfo;
