import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useNavigate, useParams } from '@reach/router';
import * as Emotion from 'emotion';
import { container } from 'assets/soter-flex-styles';
import moment from 'moment';
import { modalOverflow, text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { ReactComponent as Lower } from 'assets/img/icons/Lower.svg';
import { ReactComponent as Higher } from 'assets/img/icons/Higher.svg';
import { ReactComponent as Medium } from 'assets/img/icons/Medium.svg';
import { ReactComponent as NA } from 'assets/img/icons/NA.svg';
import { link } from 'assets/jss/containers/itemTableStyle';
import { setOpenedModal } from 'containers/ModalContainer/modalsStore/actions';
import { useTranslation } from 'react-i18next';
import Button from 'lib/ui/Button';
import { Risks } from 'init/proto_client';
import { useAppDispatch, useAppSelector } from 'lib/utils/useStore';
import Loader from 'lib/ui/Loader';
import { bodyPartNames, bodyPartNamesHackMap } from '../../video/constants';
import {
  CHART_HEIGHT,
  TIME_DISTRIBUTION_BAR_CHART_HEIGHT,
  TIME_DISTRIBUTION_BAR_CHART_WIDTH,
  VIDEO_HEIGHT,
  VIDEO_WIDTH,
} from './constants';
import getThresholds from '../../utils/getThresholds';
import parseMovementData from '../../utils/parseMovementData';
import prepareChartAnglesData from '../../utils/prepareChartAnglesData';
import prepareChartAxisData from '../../utils/prepareChartAxisData';
import prepareRisksForBarChart from '../../utils/prepareRisksForBarChart';
import useVideoTimeUpdateHandler from '../../video/utils/useVideoTimeUpdateHandler';
import {
  createComparison,
  getComparisonList,
  getComparisonMovementData,
  removeComparison,
  resetComparisons,
  setCurrentComparison,
  setNewComparisonIds,
  updateComparison,
} from '../comparisonsStore/actions';
import { routes } from '../../../containers/PrivatPageHOC/constants/routes';
import { getVideos } from '../../gallery/videosStore/actions';
import { resetTaskVideos } from '../../gallery/videosStore/videoSlice';
import {
  SOTER_TASK_COMPARISON_PDF_EXPORT,
  TASK_COMPARISON_CREATED,
  TASK_COMPARISON_GO_TO_VIDEO_BY_LINK,
  TASK_COMPARISON_VIDEO_UPLOADED,
  TASK_VIDEO_COMPARISON_MUTED_UNMUTED,
} from '../../../constants/analyticsEvents';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import exportToPdf from '../../../containers/PdfContainer/utils/exportToPdf';
import { openedModalSelector } from '../../../containers/ModalContainer/modalsStore/selectors';
import ModalWrapper from '../../../containers/ModalContainer/ModalWrapper';
import UpgradeDialog from '../../components/UpgradeDialog';
import { contactSales } from '../../../auth/store/actions';
import ContactUsDialog from '../../../lib/ui/ContactUsDialog';
import NavigateBackButton from '../../../lib/ui/NavigateBackButton';
import EditableComparisonHeader from '../../components/EditableComparisonHeader';
import SlotForVideoSelection from './components/SlotForVideoSelection';
import VideoPlayerWithStickman from '../../components/VideoPlayerWIthStickman';
import MovementDataDynamicLineChart from '../../components/MovementDataDynamicLineChart';
import presentSeconds from '../../../lib/utils/presentSeconds';
import BodyPartRiskDistribution from '../../components/BodyPartsRiskDistribution';
import BodyPartsRiskComparison from '../../components/BodyPartsRiskComparison';
import EditableTextCard from '../../../lib/ui/EditableTextCard/EditableTextCard';
import SearchableSelectableDialog from '../../components/SearchableSelectableDialog';
import ComparisonPdfContainer from '../ComparisonPdfContainer';

const comparisonGrid = Emotion.css`
  display: grid;
  grid-gap: 15px 25px;
  grid-template-areas:
    'header header'
    'video1 video2'
    'videoHeader1 videoHeader2'
    'lineChart1 lineChart2'
    'barChart1 barChart2'
    'risks risks'
    'description description';
`;

const getRiskIcon = (risk) => {
  if (risk === Risks.LOW) {
    return <Lower style={{ width: '14px', height: '14px' }} />;
  }
  if (risk === Risks.MEDIUM) {
    return <Medium style={{ width: '14px', height: '14px' }} />;
  }
  if (risk === Risks.HIGH) {
    return <Higher style={{ width: '14px', height: '14px' }} />;
  }
  return <NA style={{ width: '14px', height: '14px' }} />;
};

const paddingForContainer = Emotion.css`
  padding: 14px;
`;
const ComparisonContainer = () => {
  const frameCallbackIsAvailable
    = 'requestVideoFrameCallback' in HTMLVideoElement.prototype;
  const bodyParts = Object.keys(bodyPartNames).filter((part) => part !== 'reba');
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const firstCanvasRef = useRef();
  const secondCanvasRef = useRef();
  const firstVideoRef = useRef();
  const secondVideoRef = useRef();
  const [isPlayingFirst, setIsPlayingFirst] = useState(false);
  const [isPlayingSecond, setIsPlayingSecond] = useState(false);
  const [isRewoundVideo, setIsRewoundVideo] = useState(false);
  const [isMutedVideo1, setIsMutedVideo1] = useState(false);
  const [isMutedVideo2, setIsMutedVideo2] = useState(false);
  const [firstCurrentTimeMs, setFirstCurrentTimeMs] = useState(0);
  const [secondCurrentTimeMs, setSecondCurrentTimeMs] = useState(0);
  const [firstSelectedBodyParts, setFirstSelectedBodyParts] = useState(
    bodyParts[0],
  );
  const [secondSelectedBodyParts, setSecondSelectedBodyParts] = useState(
    bodyParts[0],
  );
  const [firstCurrentThreshold, setFirstCurrentThreshold] = useState(null);
  const [secondCurrentThreshold, setSecondCurrentThreshold] = useState(null);
  const [comparisonDownloading, setComparisonDownloading] = useState(null);
  const [firstSelectedVideo, setFirstSelectedVideo] = useState(null);
  const {
    currentComparison,
    newComparisonIds,
    comparisonList,
    firstVideoMovementData,
    secondVideoMovementData,
  } = useAppSelector((state) => state.soterTaskComparisons);
  const { firstTaskVideo, secondTaskVideo } = currentComparison;
  const { videosList, isLoading } = useAppSelector((state) => state.videos);
  const { id: companyID } = useAppSelector(
    (state) => state.companies.selectedCompany,
  );
  const [openedDialog, setOpenedDialog] = useState(null);
  const [title, setTitle] = useState(
    currentComparison?.title || moment().format('YYYY-MM-DD HH:mm:ss'),
  );
  const [description, setDescription] = useState(
    currentComparison?.description || '',
  );
  const firstCanvasScale
    = VIDEO_HEIGHT / currentComparison?.firstTaskVideo?.videoHeightPx;
  const secondCanvasScale
    = VIDEO_HEIGHT / currentComparison?.secondTaskVideo?.videoHeightPx;
  const [firstThresholds, setFirstThresholds] = useState(
    getThresholds(currentComparison?.firstTaskVideo?.anglesConfig),
  );
  const [secondThresholds, setSecondThresholds] = useState(
    getThresholds(currentComparison?.secondTaskVideo?.anglesConfig),
  );
  const firstVideoLengthPixelRatio
    = ((firstVideoMovementData.length / 30) * 1000) / VIDEO_WIDTH;
  const secondVideoLengthPixelRatio
    = ((secondVideoMovementData.length / 30) * 1000) / VIDEO_WIDTH;
  const firstParsedData = useMemo(
    () => parseMovementData(firstVideoMovementData, firstThresholds),
    [firstVideoMovementData, firstThresholds],
  );
  const secondParsedData = useMemo(
    () => parseMovementData(secondVideoMovementData, secondThresholds),
    [secondVideoMovementData, secondThresholds],
  );
  const firstChartAnglesData = useMemo(
    () => prepareChartAnglesData(
      firstVideoMovementData,
      firstThresholds,
      VIDEO_WIDTH,
      CHART_HEIGHT,
      firstSelectedBodyParts,
    ),
    [firstVideoMovementData, firstThresholds],
  );
  const secondChartAnglesData = useMemo(
    () => prepareChartAnglesData(
      secondVideoMovementData,
      secondThresholds,
      VIDEO_WIDTH,
      CHART_HEIGHT,
      secondSelectedBodyParts,
    ),
    [secondVideoMovementData, secondThresholds],
  );
  const firstChartAxisData = useMemo(
    () => prepareChartAxisData(
      firstVideoMovementData,
      VIDEO_WIDTH,
      firstSelectedBodyParts,
    ),
    [firstVideoMovementData],
  );
  const secondChartAxisData = useMemo(
    () => prepareChartAxisData(
      secondVideoMovementData,
      VIDEO_WIDTH,
      secondSelectedBodyParts,
    ),
    [secondVideoMovementData],
  );
  const firstRisksForBarChart = useMemo(
    () => prepareRisksForBarChart(currentComparison?.firstTaskVideo?.risks),
    [currentComparison?.firstTaskVideo],
  );
  const secondRisksForBarChart = useMemo(
    () => prepareRisksForBarChart(currentComparison?.secondTaskVideo?.risks),
    [currentComparison?.secondTaskVideo],
  );
  const firstFrameChangeCallback = (n, m) => {
    setFirstCurrentTimeMs(m.mediaTime * 1000);
    firstVideoRef.current?.requestVideoFrameCallback(firstFrameChangeCallback);
  };
  const firstTimeUpdateHandler = useVideoTimeUpdateHandler(
    setFirstCurrentTimeMs,
  );
  useEffect(() => {
    if (frameCallbackIsAvailable && !navigator.vendor.match(/apple/i)) {
      firstVideoRef.current?.requestVideoFrameCallback(firstFrameChangeCallback);
    } else {
      firstVideoRef.current?.addEventListener('timeupdate', firstTimeUpdateHandler);
    }
    return () => {
      firstVideoRef.current?.removeEventListener('timeupdate', firstTimeUpdateHandler);
    };
  }, [firstVideoRef, firstTaskVideo]);
  const secondFrameChangeCallback = (n, m) => {
    setSecondCurrentTimeMs(m.mediaTime * 1000);
    secondVideoRef.current?.requestVideoFrameCallback(
      secondFrameChangeCallback,
    );
  };
  const secondTimeUpdateHandler = useVideoTimeUpdateHandler(
    setSecondCurrentTimeMs,
  );
  useEffect(() => {
    if (frameCallbackIsAvailable && !navigator.vendor.match(/apple/i)) {
      secondVideoRef.current?.requestVideoFrameCallback(secondFrameChangeCallback);
    } else {
      secondVideoRef.current?.addEventListener('timeupdate', secondTimeUpdateHandler);
    }
    return () => {
      secondVideoRef.current?.removeEventListener('timeupdate', secondTimeUpdateHandler);
    };
  }, [secondVideoRef, secondTaskVideo]);
  useEffect(() => {
    if (
      comparisonList?.length > 0 &&
      params?.id !== 'new' &&
      currentComparison?.id !== params.id
    ) {
      const comparison = comparisonList.find(({ id }) => +params.id === id);
      if (comparison) {
        dispatch(setCurrentComparison(comparison));
      } else {
        navigate(`${companyID}/${routes.users}/${routes.genius}/${routes.comparison}`);
      }
    }
  }, [params, comparisonList]);
  useEffect(() => {
    if (currentComparison?.title && currentComparison.title !== title) { setTitle(currentComparison?.title); }
    if (
      currentComparison?.description &&
      currentComparison.description !== description
    ) { setDescription(currentComparison?.description); }
  }, [currentComparison]);
  useEffect(() => {
    if (companyID) {
      dispatch(getVideos(companyID));
      dispatch(getComparisonList(companyID));
    }
    return () => {
      dispatch(resetTaskVideos());
      dispatch(resetComparisons());
    };
  }, [companyID]);
  useEffect(() => {
    // CODE BELOW REPRODUCES THE ERROR: you need to press the back button 3 times to go back
    // if (currentComparison?.id && currentComparison.id !== params.id) {
    //   navigate(`/users/soter-task/comparison/${currentComparison.id}`);
    // }
    if (currentComparison?.id) {
      dispatch(
        getComparisonMovementData(
          currentComparison?.firstTaskVideo?.id,
          currentComparison?.secondTaskVideo?.id,
        ),
      );
    }
    if (currentComparison?.firstTaskVideo?.anglesConfig) {
      setFirstThresholds(
        getThresholds(currentComparison?.firstTaskVideo?.anglesConfig),
      );
    }
    if (currentComparison?.secondTaskVideo?.anglesConfig) {
      setSecondThresholds(
        getThresholds(currentComparison?.secondTaskVideo?.anglesConfig),
      );
    }
  }, [currentComparison]);
  useEffect(() => {
    Object.keys(firstThresholds).forEach(
      (t) => firstSelectedBodyParts.includes(t) &&
        setFirstCurrentThreshold(firstThresholds[t]),
    );
  }, [firstSelectedBodyParts, currentComparison?.firstTaskVideo]);
  useEffect(() => {
    Object.keys(secondThresholds).forEach(
      (t) => secondSelectedBodyParts.includes(t) &&
        setSecondCurrentThreshold(secondThresholds[t]),
    );
  }, [secondSelectedBodyParts, currentComparison?.secondTaskVideo]);
  useEffect(() => {
    if (comparisonDownloading) {
      window.logEvent(SOTER_TASK_COMPARISON_PDF_EXPORT);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(SOTER_TASK_COMPARISON_PDF_EXPORT));
      }
      exportToPdf(
        'SoterTask',
        'comparison',
        '#soter_task_comparison_report',
        'p',
      );
      setComparisonDownloading(false);
    }
  }, [comparisonDownloading]);
  const addVideoHandler = (dialogNumber) => (items) => {
    window.logEvent(TASK_COMPARISON_VIDEO_UPLOADED);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(TASK_COMPARISON_VIDEO_UPLOADED));
    }
    setFirstSelectedVideo(videosList.find(
      (v) => v.id === items[0],
    ));
    if (dialogNumber === 1) {
      dispatch(setNewComparisonIds([items[0], newComparisonIds[1]]));
    } else {
      dispatch(setNewComparisonIds([newComparisonIds[0], items[0]]));
    }
  };
  const createComparisonHandler = (ids) => {
    const analyticsParams = {
      video_ids: [ids[0], ids[1]],
    };
    window.logEvent(TASK_COMPARISON_CREATED, analyticsParams);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${TASK_COMPARISON_CREATED}: ${JSON.stringify(analyticsParams)}`));
    }
    dispatch(
      createComparison({
        title,
        description,
        firstVideoId: ids[0],
        secondVideoId: ids[1],
        companyID,
      }),
    );
  };
  // func for upgrade button
  const { t } = useTranslation();
  const { id: userId } = useAppSelector((state) => state.auth.profile);
  const openedModal = useAppSelector(openedModalSelector);
  const modalClass = modalOverflow;
  const overlayClass = Emotion.css`
    margin-top: 119px;
  `;
  const withCloseButton = true;
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const comparisonKeys = Object.keys(currentComparison || {});
  const improvements = currentComparison
    ? {
      bodyPartsImprovements: bodyParts.map((id, index) => ({
        id,
        index,
        value:
          currentComparison[
            comparisonKeys.find((key) => key.includes(bodyPartNamesHackMap[id]))
          ],
      })),
      overallImprovement: currentComparison.overallImprovement,
    }
    : {};
  const sendMuteAnalyticsEvent = (currentVideo) => {
    if (currentVideo === 'video1') {
      setIsMutedVideo1((prev) => {
        const analyticsParams = {
          is_muted: !prev,
        };
        window.logEvent(TASK_VIDEO_COMPARISON_MUTED_UNMUTED, analyticsParams);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(`${TASK_VIDEO_COMPARISON_MUTED_UNMUTED}: ${JSON.stringify(analyticsParams)}`));
        }

        return !prev;
      });
    }

    if (currentVideo === 'video2') {
      setIsMutedVideo2((prev) => {
        const analyticsParams = {
          is_muted: !prev,
        };
        window.logEvent(TASK_VIDEO_COMPARISON_MUTED_UNMUTED, analyticsParams);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(`${TASK_VIDEO_COMPARISON_MUTED_UNMUTED}: ${JSON.stringify(analyticsParams)}`));
        }

        return !prev;
      });
    }
  };
  const toggleMuteBtn = (currentVideo) => {
    if (currentVideo === 'video1') {
      if (firstVideoRef.current.volume > 0) {
        if (!isMutedVideo1) return;

        sendMuteAnalyticsEvent(currentVideo);
      } else {
        if (isMutedVideo1) return;

        sendMuteAnalyticsEvent(currentVideo);
      }
    }

    if (currentVideo === 'video2') {
      if (secondVideoRef.current.volume > 0) {
        if (!isMutedVideo2) return;

        sendMuteAnalyticsEvent(currentVideo);
      } else {
        if (isMutedVideo2) return;

        sendMuteAnalyticsEvent(currentVideo);
      }
    }
  };

  const [videosForDialog, setVideosForDialog] = useState([]);
  useEffect(() => {
    let videos = [...videosList]
      .sort((a, b) => b.startUploadingTime - a.startUploadingTime);
    if (firstSelectedVideo) {
      videos = videos
        .filter(({ departmentId, siteId }) => departmentId === firstSelectedVideo.departmentId &&
          siteId === firstSelectedVideo.siteId);
    }
    setVideosForDialog(videos.map(({
      id, title, createdAt, amazonThumbnailUrl,
    }) => ({
      value: id,
      title,
      subTitle: moment(createdAt).format('DD MMM YYYY'),
      thumbnail: amazonThumbnailUrl,
    })));
  }, [videosList, firstSelectedVideo]);

  const VideoLink = ({ video }) => (
    <Link
      style={{ display: 'block' }}
      className={link}
      to={`/${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.gallery}/${video.id}`}
      onClick={() => {
        window.logEvent(TASK_COMPARISON_GO_TO_VIDEO_BY_LINK);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(TASK_COMPARISON_GO_TO_VIDEO_BY_LINK));
        }
        window.logEvent(TASK_COMPARISON_GO_TO_VIDEO_BY_LINK);
      }}
    >
      <div>
        <span style={{ marginRight: '6px' }}>{video.title}</span>
        <span>{getRiskIcon(video?.risks?.overallRisk)}</span>
      </div>
      <div className={text(COLORS.GRAY[400])}>
        {moment(video.startUploadingTime).format('D MMM YYYY')}
      </div>
    </Link>
  );

  if (params.id !== 'new' && !currentComparison?.id) return <Loader />;
  return (
    <>
      {openedModal === 'upgrade' || openedModal === 'contactSales' ? (
        <ModalWrapper
          closeModal={() => dispatch(setOpenedModal(null))}
          modalClass={modalClass}
          overlayClass={overlayClass}
          keepModalOnClickOutside
          withCloseButton={withCloseButton}
          closeWithoutConfirmation
        >
          {openedModal === 'upgrade' ? (
            <UpgradeDialog
              handleClick={() => {
                dispatch(
                  contactSales({ companyId: companyID, productType: contactSalesProductType.PRODUCTTYPETASK }),
                );
                dispatch(setOpenedModal('contactSales'));
              }}
              days={selectedCompany.products?.geniusProduct?.currentTermEnd}
            />
          ) : (
            <ContactUsDialog
              handleClose={() => dispatch(setOpenedModal(null))}
            />
          )}
        </ModalWrapper>
      ) : null}
      <div className={`${container} ${paddingForContainer}`}>
        <NavigateBackButton
          name={t('SOTER_TASK.COMPARISON.BACK_LIST')}
          navigateTo={`/${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.comparison}`}
          style={{ marginLeft: '-13px' }}
        />
        <EditableComparisonHeader
          setTitle={setTitle}
          title={title}
          createdAt={currentComparison?.createdAt}
          saveHandler={
            currentComparison?.id
              ? (title) => dispatch(
                updateComparison({
                  id: currentComparison?.id,
                  title,
                  description: currentComparison?.description,
                }),
              )
              : () => {}
          }
          exportComparison={() => setComparisonDownloading(true)}
          exportDisabled={!currentComparison.id}
          firstRisk={currentComparison?.firstTaskVideo?.risks?.overallRisk}
          secondRisk={currentComparison?.secondTaskVideo?.risks?.overallRisk}
          removeHandler={() => {
            if (window.confirm(t('SOTER_TASK.VIDEO.SURE'))) {
              dispatch(removeComparison(currentComparison?.id));
              setTimeout(() => navigate(`/${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.comparison}`), 300);
            }
          }}
        />
        {params?.id === 'new' ? (
          <>
            <div style={{ display: 'flex' }}>
              <SlotForVideoSelection
                clickHandler={() => setOpenedDialog(1)}
                stepNumber={1}
                selectedVideo={videosList.find(
                  (v) => v.id === newComparisonIds[0],
                )}
                videoNumber="first"
              />
              <SlotForVideoSelection
                clickHandler={() => setOpenedDialog(2)}
                stepNumber={2}
                selectedVideo={videosList.find(
                  (v) => v.id === newComparisonIds[1],
                )}
                videoNumber="second"
              />
            </div>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                disabled={!newComparisonIds[0] || !newComparisonIds[1]}
                style={{ width: '400px', display: 'block' }}
                handler={() => {
                  setFirstSelectedVideo(null);
                  createComparisonHandler(newComparisonIds);
                }}
                text={t('SOTER_TASK.COMPARISON.COMPARE')}
              />
            </div>
          </>
        ) : (
          <div className={comparisonGrid}>
            <div>
              <VideoPlayerWithStickman
                gridArea="video1"
                currentVideo={currentComparison?.firstTaskVideo}
                canvasRef={firstCanvasRef}
                videoRef={firstVideoRef}
                videoHeight={VIDEO_HEIGHT}
                videoWidth={VIDEO_WIDTH}
                isPlaying={isPlayingFirst}
                setIsPlaying={setIsPlayingFirst}
                canvasScale={firstCanvasScale}
                parsedData={firstParsedData}
                currentTimeMs={firstCurrentTimeMs}
                totalFrames={firstVideoMovementData.length}
                isDataParsed={firstParsedData?.length > 0}
                selectedBodyPart={firstSelectedBodyParts}
                toggleMuteBtn={toggleMuteBtn}
                isRewoundVideo={isRewoundVideo}
                setIsRewoundVideo={setIsRewoundVideo}
              />
              <VideoLink video={firstTaskVideo} />
            </div>
            <div>
              <VideoPlayerWithStickman
                gridArea="video2"
                currentVideo={currentComparison?.secondTaskVideo}
                canvasRef={secondCanvasRef}
                videoRef={secondVideoRef}
                videoHeight={VIDEO_HEIGHT}
                videoWidth={VIDEO_WIDTH}
                isPlaying={isPlayingSecond}
                setIsPlaying={setIsPlayingSecond}
                canvasScale={secondCanvasScale}
                parsedData={secondParsedData}
                currentTimeMs={secondCurrentTimeMs}
                totalFrames={secondVideoMovementData.length}
                isDataParsed={secondParsedData?.length > 0}
                selectedBodyPart={secondSelectedBodyParts}
                toggleMuteBtn={toggleMuteBtn}
                isRewoundVideo={isRewoundVideo}
                setIsRewoundVideo={setIsRewoundVideo}
              />
              <VideoLink video={secondTaskVideo} />
            </div>
            <MovementDataDynamicLineChart
              gridArea="lineChart1"
              videoWidth={VIDEO_WIDTH}
              bodyParts={bodyParts}
              setSelectedBodyPart={setFirstSelectedBodyParts}
              selectedBodyPart={firstSelectedBodyParts}
              totalTime={
                firstRisksForBarChart &&
                presentSeconds(
                  firstRisksForBarChart[
                    bodyPartNamesHackMap[firstSelectedBodyParts]]?.totalTime,
                )
              }
              currentThreshold={firstCurrentThreshold}
              currentTimeMs={firstCurrentTimeMs}
              videoLengthPixelRatio={firstVideoLengthPixelRatio}
              chartAnglesData={firstChartAnglesData}
              chartAxisData={firstChartAxisData}
              chartHeight={CHART_HEIGHT}
            />
            <MovementDataDynamicLineChart
              gridArea="lineChart2"
              videoWidth={VIDEO_WIDTH}
              bodyParts={bodyParts}
              setSelectedBodyPart={setSecondSelectedBodyParts}
              selectedBodyPart={secondSelectedBodyParts}
              totalTime={
                secondRisksForBarChart &&
                presentSeconds(
                  secondRisksForBarChart[
                    bodyPartNamesHackMap[secondSelectedBodyParts]]?.totalTime,
                )
              }
              currentThreshold={secondCurrentThreshold}
              currentTimeMs={secondCurrentTimeMs}
              videoLengthPixelRatio={secondVideoLengthPixelRatio}
              chartAnglesData={secondChartAnglesData}
              chartAxisData={secondChartAxisData}
              chartHeight={CHART_HEIGHT}
            />
            <BodyPartRiskDistribution
              gridArea="barChart1"
              risks={firstRisksForBarChart}
              width={VIDEO_WIDTH}
              barWidth={TIME_DISTRIBUTION_BAR_CHART_WIDTH}
              barHeight={TIME_DISTRIBUTION_BAR_CHART_HEIGHT}
              isCard
            />
            <BodyPartRiskDistribution
              gridArea="barChart2"
              risks={secondRisksForBarChart}
              width={VIDEO_WIDTH}
              barWidth={TIME_DISTRIBUTION_BAR_CHART_WIDTH}
              barHeight={TIME_DISTRIBUTION_BAR_CHART_HEIGHT}
              isCard
            />
            <BodyPartsRiskComparison
              gridArea="risks"
              firstVideoRisks={currentComparison?.firstTaskVideo?.risks}
              secondVideoRisks={currentComparison?.secondTaskVideo?.risks}
              improvements={improvements}
            />
            <EditableTextCard
              className={Emotion.css`
                grid-area: description;
              `}
              text={description}
              title={t('GENERAL.DESCRIPTION')}
              id="comparisonDescription"
              saveValue={(v) => dispatch(
                updateComparison({
                  id: currentComparison?.id,
                  description: v,
                }),
              )}
              height={269}
              isComparisonPage
            />
          </div>
        )}
        {openedDialog ? (
          <SearchableSelectableDialog
            isRadio
            placeholder={t('SOTER_TASK.COMPARISON.ENTER_NAME')}
            buttonText={t('SOTER_TASK.COMPARISON.ADD_TO')}
            buttonHandler={addVideoHandler(openedDialog)}
            items={videosForDialog}
            closeHandler={() => {
              setOpenedDialog(null);
            }}
          />
        ) : (
          ''
        )}
      </div>
      {comparisonDownloading && (
        <ComparisonPdfContainer
          currentComparison={currentComparison}
          secondRisksForBarChart={secondRisksForBarChart}
          firstRisksForBarChart={firstRisksForBarChart}
          improvements={improvements}
          firstThresholds={firstThresholds}
          secondThresholds={secondThresholds}
          firstVideoMovementData={firstVideoMovementData}
          secondVideoMovementData={secondVideoMovementData}
        />
      )}
    </>
  );
};

export default ComparisonContainer;
