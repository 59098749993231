import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import * as Emotion from 'emotion';
import { chartContainerWithHeight } from 'assets/soter-flex-styles/chart-container';
import infoIcon from 'assets/img/icons/info.svg';
import Tooltip from 'lib/ui/Tooltip';
import TwistingCard from './TwisingCard';

const maximum = (top) => Emotion.css`{
  position: absolute;
  top: ${top}px;
  left: -21px;
  background-color: rgba(255,255,255,0.7);
  border-radius: 3px;
}`;

const infoStyle = Emotion.css`{&:hover {cursor: pointer}}`;

const PieChart = ({
  chartId, data, containerHeight, dataType, unit, isTask,
}) => {
  const chartContainerRef = useRef(null);
  useEffect(() => {
    const width = 252;
    const height = 510;
    const chartRadius = 250;
    const currentChart = d3.select(`#${chartId}`);
    const currentTooltip = d3.select(`#${chartId}_tooltip`);
    if (currentChart) {
      currentChart.selectAll('svg').remove();
    }
    if (currentTooltip) {
      currentTooltip.remove();
    }

    const r = d3.scaleLinear()
      .domain([0, 150])
      .range([0, chartRadius]);

    const svg = d3.select(`#${chartId}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2
      },${height / 2})`)
      .attr('transform', `translate(0, ${chartRadius})`);
    const gr = svg.append('g')
      .attr('class', 'r axis')
      .selectAll('g')
      .data(r.ticks(4).slice(1))
      .enter()
      .append('g');

    gr.append('circle')
      .attr('r', r);

    const ga = svg.append('g')
      .attr('class', 'a axis')
      .selectAll('g')
      .data(d3.range(0, 360, 30))
      .attr('transform', 'rotate(180)')
      .enter()
      .append('g')
      .attr('transform', (d) => `rotate(${-d})`);

    ga.append('line')
      .attr('x2', chartRadius);

    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius((d) => d.data.score);

    const pie = d3.pie()
      .startAngle((Math.PI / 180))
      .endAngle(180 * (Math.PI / 180))
      .value((d) => d.count)
      .sort(null);

    const div = d3.select('#page_layer').append('div')
      .attr('class', 'tooltip')
      .attr('id', `${chartId}_tooltip`)
      .style('opacity', 0);

    const path = svg.selectAll('path')
      .data(pie(data))
      .enter();
    path.append('path')
      .attr('d', arc)
      .attr('fill', (d) => d.data.color)
      .attr('opacity', 0.8)
      .attr('stroke', (d) => d.data.color)
      .on('mouseover', (event, d) => {
        div.transition()
          .duration(200)
          .style('opacity', 0.9);
        div.html(`${dataType.toUpperCase()}: ${d.data.value.toFixed(unit === 'amount' ? 0 : 2)}${unit === 'percent' ? '%' : unit === 'time' ? ' minutes' : ' movements'}`)
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', (d) => {
        div.transition()
          .duration(500)
          .style('opacity', 0);
      });
  }, [data]);
  return (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        position: 'absolute',
        zIndex: 7,
        fontSize: '10px',
      }}
      className={chartContainerWithHeight(containerHeight)}
      ref={chartContainerRef}
      id={chartId}
    >
      <span className={maximum(0)}>{`${(data[0]?.maximum)}${unit === 'percent' ? '%' : ''}`}</span>
      <span className={maximum(82)}>{`${((data[0]?.maximum / 100) * 66).toFixed(1)}${unit === 'percent' ? '%' : ''}`}</span>
      <span className={maximum(165)}>{`${((data[0]?.maximum / 100) * 33).toFixed(1)}${unit === 'percent' ? '%' : ''}`}</span>
      {chartId === 'pie_spine' &&
     (
       <>
         <span
           className={infoStyle}
           style={{
             position: 'absolute', zIndex: 10, left: '463px', top: '16px', width: '15px', height: '15px', '&:hover': { cursor: 'pointer' },
           }}
         >
           <Tooltip
             text={unit === 'percent' ? 'JOB_DICTIONARY.JOB_PROFILE.TABLE_SHOWS' : 'JOB_DICTIONARY.JOB_PROFILE.WE_DEFINE'}
             placement="bottomEnd"
             openOnClick
           >
             <img style={{ width: '24px', height: '24px' }} src={infoIcon} alt="i" />
           </Tooltip>
         </span>
         <TwistingCard
           type={unit}
           highAngles={data.find((d) => d.name === 'Angles 90 - 120')?.twist_value || 0}
           lowAngles={data.find((d) => d.name === 'Angles 30 - 60')?.twist_value || 0}
           mediumAngles={data.find((d) => d.name === 'Angles 60 - 90')?.twist_value || 0}
           isTask={isTask}
         />
       </>
     )}
    </div>
  );
};

export default PieChart;
