import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  failNotification,
  failNotificationClose,
  flexRow,
  ml3,
  mr3,
  notificationText,
  smallIcon,
  successNotification,
  successNotificationClose,
  warningNotification,
  warningNotificationClose,
} from '../../../assets/soter-flex-styles';
import successNotificationIcon from '../../../assets/img/icons/notifications/success-notification-icon.svg';
import failNotificationIcon from '../../../assets/img/icons/notifications/fail-notification-icon.svg';
import { resetNotification } from '../notificationsStore/notificationsSlice';

const notificationMap = {
  TYPE_SUCCESS: {
    iconClass: successNotification,
    buttonClass: successNotificationClose,
    icon: successNotificationIcon,
  },
  TYPE_FAILED: {
    iconClass: failNotification,
    buttonClass: failNotificationClose,
    icon: failNotificationIcon,
  },
  TYPE_WARNING: {
    iconClass: warningNotification,
    buttonClass: warningNotificationClose,
    icon: '/assets/img/icons/notifications/warning-notification-icon.svg',
  },
};

const Notification = ({ notification }) => {
  const { message, status, id } = notification;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dismissNotification = () => dispatch(resetNotification(id));
  const [finalMessage, setFinalMessage] = useState('');
  useEffect(() => {
    Array.isArray(message)
      ? message.map((m) => setFinalMessage((prev) => `${prev}${t(m)} `))
      : setFinalMessage(message); // old is m
  }, [message]);
  return (
    <div className={notificationMap[status]?.iconClass} role="alert">
      <div className={flexRow}>
        <img
          className={`${smallIcon} ${ml3} ${mr3}`}
          src={notificationMap[status]?.icon}
          alt="battery charge"
        />
        <span className={notificationText}>{t(finalMessage)}</span>
        <button
          className={`${notificationMap[status]?.buttonClass} ${mr3}`}
          type="button"
          onClick={() => dismissNotification()}
          aria-label="close notification"
        />
      </div>
    </div>
  );
};

export default Notification;
