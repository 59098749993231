import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyToClipboardIcon } from '../../assets/img/icons/copy.svg';
import {
  buttonStylesReset,
  coloredButton,
  darkTitleText,
  flexColumn,
  flexRow,
  inputLabel,
  mt5,
  smallMutedText,
} from '../../assets/soter-flex-styles';
import Button from '../../lib/ui/Button';
import { openedModalSelector } from '../../containers/ModalContainer/modalsStore/selectors';
import { notificationMessages, notificationTypes } from '../../containers/NotificationContainer/notifications';
import { setNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import CustomInput from '../../lib/ui/CustomInput';

const InviteLinkDialog = ({
  isResendLink, email, link, onSendInvite,
}) => {
  const dispatch = useDispatch();
  const openedModal = useSelector(openedModalSelector);
  const { t } = useTranslation();
  const handleCopy = () => {
    dispatch(
      setNotification({
        type: notificationTypes.TYPE_SUCCESS,
        message: notificationMessages.LINK_COPIED_SUCCESS_MSG,
      }),
    );
    dispatch(setOpenedModal(null));
    setOpenedModal(null);
  };

  return (
    <>
      <h4 className={`${darkTitleText}`}>
        {isResendLink
          ? `${t('MY_COMPANY.USERS.SEND_TO')} ${email}?`
          : `${t('MY_COMPANY.USERS.SENT_TO')} ${email}`}
      </h4>
      {!isResendLink && (
        <span className={smallMutedText}>{t('MY_COMPANY.USERS.SEND_TO')}</span>
      )}
      <div className={`${flexRow} ${mt5}`} style={{ alignItems: 'flex-end' }}>
        <div className={flexColumn} style={{ flexBasis: '70%' }}>
          <label className={inputLabel}>{t('MY_COMPANY.USERS.LINK')}</label>
          <CustomInput
            type="text"
            value={link}
            addon={
              isResendLink && (
                <CopyToClipboard text={link} onCopy={handleCopy}>
                  <Button
                    type="button"
                    className={buttonStylesReset}
                    style={{ backgroundColor: '#f7f7fa' }}
                  >
                    <CopyToClipboardIcon />
                  </Button>
                </CopyToClipboard>
              )
            }
          />
        </div>
        <div className={flexColumn} style={{ flexBasis: '30%' }}>
          <CopyToClipboard text={link} onCopy={handleCopy}>
            <div
              className={`${coloredButton('#248BF2', 'small')}`}
              type="button"
            >
              <span style={{
                position: 'relative', top: '15%', fontSize: '14px',
              }}
              >
                {t('MY_COMPANY.USERS.COPY_LINK')}
              </span>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
};

export default InviteLinkDialog;
