import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import SelectionCell from '../../containers/ItemTableContainer/components/CustomCells/SelectionCell';
import NameCell from '../../containers/ItemTableContainer/components/CustomCells/NameCell';
import DepartmentCell from '../../containers/ItemTableContainer/components/CustomCells/DepartmentCell';
import ImprovementCell from '../../containers/ItemTableContainer/components/CustomCells/ImprovementCell';
import DaysPassedCell from '../../containers/ItemTableContainer/components/CustomCells/DaysPassedCell';
import HelpRequestDaysCell from '../../containers/ItemTableContainer/components/CustomCells/HelpRequestDaysCell';
import DeviceCell from '../../containers/ItemTableContainer/components/CustomCells/DeviceCell';
import CommentCell from '../../containers/ItemTableContainer/components/CustomCells/CommentCell';
import HelpRequestCreatedCell from '../../containers/ItemTableContainer/components/CustomCells/HelpRequestCreatedCell';
import ChangeStatusCell from '../../containers/ItemTableContainer/components/CustomCells/ChangeStatusCell';
import {
  getHelpRequests,
  resetHelpRequests,
  setHelpRequestStatus,
} from './helpRequestsStore/actions';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';

const NeedActionContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { requests, isLoading, error } = useSelector((state) => state.helpRequests);
  const { selectedCompany } = useSelector((state) => state.companies);
  const companyId = selectedCompany?.id;

  const userOnClickGoTo = (id) => `../user/${id}`;

  const columns = (getActionsForUser) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.help),
    userOnClickGoTo,
    getActionsForUser,
    TableType.help,
    t,
  );
  const cells = {
    selection: SelectionCell,
    name: (props) => <NameCell {...props} rowData={props.rowData.user} isClipgo />,
    department: (props) => <DepartmentCell {...props} rowData={props.rowData.user} />,
    average: (props) => <ImprovementCell {...props} rowData={props.rowData.user} />,
    daysPassed: (props) => <DaysPassedCell {...props} rowData={props.rowData.user} />,
    lastSync: (props) => <HelpRequestDaysCell {...props} rowData={props.rowData.user} />,
    device: (props) => <DeviceCell {...props} rowData={props.rowData.user} />,
    comment: CommentCell,
    createdAt: HelpRequestCreatedCell,
    status: (props) => <ChangeStatusCell {...props} error={error} setStatus={(id, status) => dispatch(setHelpRequestStatus(id, status))} />,
  };
  useEffect(() => {
    dispatch(getHelpRequests(companyId));
    return () => dispatch(resetHelpRequests());
  }, [companyId]);

  return (
    <ItemTableContainer
      items={requests}
      selectedItems={[]}
      columns={columns}
      cells={cells}
      type={TableType.help}
      isLoading={isLoading}
    />
  );
};

export default NeedActionContainer;
