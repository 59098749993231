import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { ReactComponent as Good } from '../assets/img/shape.svg';
import { ReactComponent as Bad } from '../assets/img/cross.svg';
import { text } from '../assets/jss/style';
import { resetPasswordByToken } from './store/actions';
import { clearError } from './store/authSlice';
import ForgotPasswordPageContainer from './PasswordContainer/ForgotPasswordPageContainer';
import NavigateBackButton from '../lib/ui/NavigateBackButton';
import RecoverActionSuccess from './PasswordContainer/components/RecoverActionSuccess';
import RecoverActionFailed from './PasswordContainer/components/RecoverActionFailed';
import LoginFormCard from './LoginContainer/components/LoginFormCard';
import LoginTextInput from './LoginContainer/components/LoginTextInput';
import { ValidateAsyncOnChange } from './ValidateAsyncOnChange';
import { useAppDispatch, useAppSelector } from '../lib/utils/useStore';

const pageElementsNames = {
  title: 'Set new password',
  buttonText: 'Set new password',
  passwordLabel: 'Password',
  passwordPlaceholder: 'Enter password',
  repeatedPasswordLabel: 'Repeat password',
  repeatedPasswordPlaceholder: 'Repeat password',
};

const errorTypeMessage = {
  mismatched: 'Password and repeated password don\'t match',
  short: 'Password must be 8 characters length or longer',
  required: 'Please, repeat password',
};

const SetPasswordPage = ({ token }) => {
  const dispatch = useAppDispatch();
  const { isPasswordSet, error } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const validate = (values) => {
    const errors = {};
    if (values?.password.length < 8) {
      errors.password = errorTypeMessage.short;
    } else if (
      values?.repeatedPassword &&
        (values?.password.length !== values?.repeatedPassword.length)
    ) {
      errors.repeatedPassword = errorTypeMessage.mismatched;
    } else if (!values?.repeatedPassword) {
      errors.repeatedPassword = errorTypeMessage.required;
    }
    return errors;
  };

  const PasswordValidationRules = ({ password }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '14px' }}>
      <div>
        <p>
          {password.length >= 8 ? <Good />
            : <Bad />}
          <span
            style={{ marginLeft: '6px' }}
            className={text(COLORS.GRAY[500], 12)}
          >
            {t('ERRORS.PASSWORD_LENGTH_REQUIREMENT')}
          </span>
        </p>
        <p>
          {password.search(/\d+/g) >= 0 ? <Good />
            : <Bad />}
          <span
            style={{ marginLeft: '6px' }}
            className={text(COLORS.GRAY[500], 12)}
          >
            {t('ERRORS.PASSWORD_DIGIT_REQUIREMENT')}
          </span>
        </p>
      </div>
      <div>
        <p>
          {password.search(/([!-\/:-@\[-^`{-~]+)|\s+/g) >= 0 ? <Good />
            : <Bad />}
          <span
            style={{ marginLeft: '6px' }}
            className={text(COLORS.GRAY[500], 12)}
          >
            {t('ERRORS.PASSWORD_SYMBOL_REQUIREMENT')}
          </span>
        </p>
        <p>
          {password.search(new RegExp('[A-Z]')) >= 0 ? <Good />
            : <Bad />}
          <span
            style={{ marginLeft: '6px' }}
            className={text(COLORS.GRAY[500], 12)}
          >
            {t('ERRORS.PASSWORD_CASE_LETTER_REQUIREMENT')}
          </span>
        </p>
      </div>
    </div>
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatedPassword: '',
    },
    onSubmit: (values) => {
      const { password, repeatedPassword } = values;
      dispatch(resetPasswordByToken({ token, password, repeatedPassword }));
    },
  });

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const debouncedValidation = () => sleep(1000).then(() => validate(formik.values));

  const successMessage = 'Your password was successfully changed';
  const failedMessage = 'Sorry, we were not able to process your password change. Please, try again later.';

  useEffect(() => {
    dispatch(clearError());
  }, []);

  return (
    <>
      <ForgotPasswordPageContainer>
        <div style={{
          marginLeft: '-.75em',
        }}
        >
          <NavigateBackButton name="Back to login page" navigateTo="/auth" />
        </div>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isPasswordSet && !(error?.code === 3)
          ? <RecoverActionSuccess message={successMessage} />
          : error?.code === 3
            ? <RecoverActionFailed navigate={navigate} message={failedMessage} />
            : (
              <LoginFormCard
                buttonText={pageElementsNames.buttonText}
                title={pageElementsNames.title}
                onSubmit={formik.handleSubmit}
                buttonDisabled={
                  (formik.values.password.length < 8
                    ? true : (formik.values.password !== formik.values.repeatedPassword ? true
                      : formik.values.password.search(/([!-\/:-@\[-^`{-~]+)|\s+/g) === -1 ? true
                        : formik.values.password.search(/\d+/g) === -1 ? true
                          : formik.values.password.search(new RegExp('[A-Z]')) === -1))
              }
              >
                <LoginTextInput
                  name="password"
                  type="password"
                  label={pageElementsNames.passwordLabel}
                  placeholder={pageElementsNames.passwordPlaceholder}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values && formik.values.password}
                  error={formik.errors && formik.errors.password}
                  touched={formik.touched.password}
                  checkTouched={false}
                  errorMessage={formik.errors?.password}
                />
                <PasswordValidationRules password={formik.values.password} />
                <div style={{ marginBottom: '1.2rem' }} />
                <LoginTextInput
                  name="repeatedPassword"
                  type="password"
                  label={pageElementsNames.repeatedPasswordLabel}
                  placeholder={pageElementsNames.repeatedPasswordPlaceholder}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values && formik.values.repeatedPassword}
                  error={formik.errors && formik.errors.repeatedPassword}
                  touched={formik.touched.password}
                  checkTouched={false}
                  errorMessage={formik.errors?.repeatedPassword}
                />
                <p style={{ marginTop: '14px' }}>
                  {(formik.values.repeatedPassword === formik.values.password) && formik.values.password.length >= 8
                    ? (
                      <>
                        <Good />
                        <span
                          style={{ marginLeft: '6px' }}
                          className={text(COLORS.GRAY[500], 12)}
                        >
                          {t('ERRORS.PASSWORD_MATCH')}
                        </span>
                      </>
                    )
                    : (
                      <>
                        <Bad />
                        <span
                          style={{ marginLeft: '6px' }}
                          className={text(COLORS.GRAY[500], 12)}
                        >
                          {t('ERRORS.PASSWORD_DOESNT_MATCH')}
                        </span>
                      </>
                    )}
                </p>
              </LoginFormCard>
            )}
      </ForgotPasswordPageContainer>
      <ValidateAsyncOnChange validate={debouncedValidation} formik={formik} />
    </>
  );
};

export default SetPasswordPage;
