import React from 'react';
import { mb5, mt2 } from 'assets/soter-flex-styles';

const LayoutColumn = ({ columnClass, children }) => (
  <div className={`${columnClass} ${mt2} ${mb5}`}>
    {children}
  </div>
);

export default LayoutColumn;
