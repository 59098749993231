import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, useLocation } from '@reach/router';
import * as Emotion from 'emotion';
import {
  container, flexRow, pb1, pl3, pr3, pt1,
} from 'assets/soter-flex-styles';
import { admin, headerContainer } from 'assets/jss/components/headerContainer';
import { adminButton } from 'assets/jss/components/button';
import Button from 'lib/ui/Button';
import { useAppSelector } from 'lib/utils/useStore';
import HeaderControls from './components/HeaderControls';
import AdminActionDropdown from './components/AdminActionDropdown';
import Logo from './components/Logo';
import CompanySelect from './components/CompanySelect';
import { company, routes, users } from '../PrivatPageHOC/constants/routes';
import ModalWrapper from 'containers/ModalContainer/ModalWrapper';
import FeedbackDialog from '../../wearables/user/FeedbackDialog/FeedbackDialog';

const renderAdminButton = (isAdmin, isAdminArea, id, t, isMyCompany) => {
  if (isAdmin) {
    if (isAdminArea || isMyCompany) {
      return (
        <Button
          appearance="secondary"
          type="button"
          text={t('ADMIN.BACK_TO_USER_AREA', 'Back to user area')}
          handler={() => navigate(`/${id}/${users}/${routes.wearables}/${routes.overview}`)}
          className={isAdminArea ? adminButton : ''}
        />
      );
    }
    return (
      <Button
        appearance="secondary"
        type="button"
        text={t('DROPDOWN.MY_COMPANY', 'My company')}
        handler={() => navigate(`/${id}/${users}/${company}/${users}`)}
      />
    );
  }
  return null;
};
const renderFeedbackButton = (isAdminArea, t, setOpenedModal) => 
      <Button
        appearance="secondary"
        type="button"
        text={t('FEEDBACK.FEEDBACK', 'Feedback')}
        handler={() => setOpenedModal('feedback')}
        className={isAdminArea ? adminButton : ''}
      />

// eslint-disable-next-line no-shadow
const HeaderContainer = ({ navigate, isAdminArea, setInitLoader }) => {
  const { t } = useTranslation();
  const { id } = useAppSelector((state) => state.companies.selectedCompany);
  const auth = useAppSelector((state) => state.auth);
  const user = auth?.profile;
  const isAdmin = auth?.profile.role === 'admin';
  const location = useLocation();
  const [openedModal, setOpenedModal] = useState(null);

  const modalClass = Emotion.css`
  min-width: 574px;
`;

const handleCloseModal = () => setOpenedModal(null);
  
const switchModal = (modalType) => {
  switch (modalType) {
    case 'feedback':
      return (
        <FeedbackDialog
          id={id}
          closeModal={handleCloseModal}
        />
      );
    default:
      return null;
  }
};
  


  return (
    <div className={`${headerContainer} ${isAdminArea ? admin : ''}`}>
      <div className={container}>
        <div className={`${flexRow} ${pt1} ${pb1} ${pl3} ${pr3}`}>
          <Logo isAdminArea={isAdminArea} />
          <HeaderControls>
            {renderFeedbackButton(isAdminArea, t, setOpenedModal)}
            {renderAdminButton(user.canInviteUsers, isAdminArea, id, t, location.pathname.includes(company))}
            {openedModal && (
          <ModalWrapper
            modalClass={modalClass}
            closeModal={handleCloseModal}
          >
            {switchModal(openedModal)}
          </ModalWrapper>
        )}
            {!isAdminArea && (
              <div style={{ display: 'flex' }}>
                <CompanySelect setInitLoader={setInitLoader} />
              </div>
            )}
            <AdminActionDropdown
              user={user}
              navigate={navigate}
              isAdminArea={isAdminArea}
              isAdmin={isAdmin}
              id={id}
            />
          </HeaderControls>
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer;
