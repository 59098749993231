import React from 'react';
import { mb5, ml5, mt6 } from '../../assets/soter-flex-styles';

const QRCode = require('qrcode.react');

const HubQrDialog = ({ code }) => (
  <div className={`${mt6} ${ml5} ${mb5}`}>
    <QRCode value={code} />
  </div>
);

export default HubQrDialog;
