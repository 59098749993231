import React, { useEffect, useState } from 'react';
import { darkTitleText, flexColumn, flexRow, inputLabel, mb3, mb5 } from 'assets/soter-flex-styles';
import CustomInput from 'lib/ui/CustomInput';
import useFocus from 'lib/utils/useFocus';
import { useTranslation } from 'react-i18next';
import Button from 'lib/ui/Button';
import QRPrintDialog from './QRPrintDialog';
import { useAppDispatch } from 'lib/utils/useStore';
import { resetWearableUser } from '../createUserStore/actions';
import { Tooltip } from 'rsuite';

const CreateCoachUserDialog = ({ handlerFn, user, reportPeopleNames, closeModals }) => {
  const dispatch = useAppDispatch();
  const [inputRef, setInputFocus] = useFocus();
  useEffect(() => {
    setTimeout(() => setInputFocus(), 0);

    return () => {
      dispatch(resetWearableUser());
    };
  }, []);
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    companyCode: '',
    login: '',
    firstName: '',
    lastName: '',
  });
  const [maxLengthTooltip, setMaxLengthTooltip] = useState({
    firstName: false,
    lastName: false,
    login: false,
  });
  const [prefixError, setPrefixError] = useState(false);
  const prefixChecker = (login) => {
    if (!login.includes('_') || login.charAt(0) === '_' || login.charAt(login.length - 1) === '_') {
      setPrefixError(true);
    } else {
      setPrefixError(false);
    }
  };
  const hideTooltip = (fieldName) => {
    setTimeout(() => {
      setMaxLengthTooltip((prev) => ({ ...prev, [fieldName]: false }));
    }, 3000);
  };
  const handleFieldChange = (name, value) => {
    let newValue = value;
    const maxLength = name === 'login' ? 30 : 64;
    if (name === 'login' || name === 'companyCode') newValue = newValue.toLowerCase().replace(/\s+/g, '');
    newValue = newValue.replace(/[<>{}=+\\/|()*?]/g, '');
    setUserData({ ...userData, [name]: newValue });
    if (prefixError && name === 'login') prefixChecker(value);
    if (newValue.length >= maxLength) {
      setMaxLengthTooltip((prev) => ({ ...prev, [name]: true }));
      hideTooltip(name);
    }
  };
  const renderMaxLengthTooltip = () => (
    <Tooltip visible={true} style={{ top: 0, right: 0 }}>
      {t('WEARABLES.ACTIVITY.DIALOGS.MAX_LENGTH_REACHED')}
    </Tooltip>
  );

  return (
    <>
      {user && reportPeopleNames ? (
        <QRPrintDialog user={user} closeModals={closeModals} />
      ) : (
        <>
          <h3 className={`${darkTitleText} ${mb5}`} style={{ fontWeight: 600 }}>
            {t('WEARABLES.ACTIVITY.DIALOGS.CREATE_USER')}
          </h3>
          <div className={`${flexRow} ${mb3}`}>
            <div className={flexColumn}>
              <label className={inputLabel}>{t('GENERAL.FIRST_NAME')}</label>
              <CustomInput
                type='text'
                id='clipgoFirstName'
                name='firstName'
                maxLength={64}
                value={userData.firstName}
                onChange={handleFieldChange}
              />
              {maxLengthTooltip.firstName && renderMaxLengthTooltip()}
            </div>
            <div className={flexColumn}>
              <label className={inputLabel}>{t('GENERAL.LAST_NAME')}</label>
              <CustomInput
                type='text'
                id='clipgoLastName'
                name='lastName'
                maxLength={64}
                value={userData.lastName}
                onChange={handleFieldChange}
              />
              {maxLengthTooltip.lastName && renderMaxLengthTooltip()}
            </div>
          </div>
          <div className={`${flexRow} ${mb5}`}>
            <div className={flexColumn}>
              <label className={inputLabel}>{t('LOGIN.LOGIN')}</label>
              <CustomInput
                type='text'
                id='clipgoLogin'
                name='login'
                maxLength={30}
                value={userData.login}
                onChange={handleFieldChange}
              />
              {maxLengthTooltip.login && renderMaxLengthTooltip()}
            </div>
          </div>
          <Button
            id='submit'
            text={t('GENERAL.CREATE')}
            type='button'
            disabled={!userData.firstName || !userData.lastName || !userData.login}
            handler={(e) => handlerFn(e, userData)}
            style={{ width: '100%', display: 'block' }}
          />
        </>
      )}
    </>
  );
};

export default CreateCoachUserDialog;
