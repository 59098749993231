import { findMaxY } from '../video/utils/findMaxY';
import { bodyPartNames, CHART_HEIGHT } from '../video/constants';
import presentSeconds from '../../lib/utils/presentSeconds';

const prepareChartAxisData = (
  movementData,
  videoWidth,
  selectedBodyPart,
) => {
  const chartAngleMax = findMaxY(movementData, selectedBodyPart);
  const anglePixelRatio = chartAngleMax / CHART_HEIGHT;
  const totalSeconds = Math.round(movementData.length / 30);
  let xAxisIterationStep = 1;
  if (totalSeconds >= 20) xAxisIterationStep = 2;
  if (totalSeconds >= 30) xAxisIterationStep = 3;
  if (totalSeconds >= 50) xAxisIterationStep = 5;
  if (totalSeconds >= 120) xAxisIterationStep = 10;
  if (totalSeconds >= 300) xAxisIterationStep = 20;
  if (totalSeconds >= 500) xAxisIterationStep = 50;
  if (totalSeconds >= 1000) xAxisIterationStep = 100;
  const xAxis = [];
  const yAxis = [];
  selectedBodyPart === bodyPartNames.reba.toLowerCase() && xAxis.unshift({ value: '00:00', point: 0 });

  const interval = selectedBodyPart === bodyPartNames.reba.toLowerCase() ? 2 : 30;
  for (let i = 0; i <= chartAngleMax; i += interval) {
    if (i) {
      yAxis.push({
        value: i,
        point: (chartAngleMax - i) / anglePixelRatio || 0,
      });
    }
  }
  for (let i = 0; i < totalSeconds; i += xAxisIterationStep) {
    if (i) {
      xAxis.push({
        value: presentSeconds(i),
        point: (videoWidth / totalSeconds) * i || 0,
      });
    }
  }
  if (xAxis.length > (selectedBodyPart === bodyPartNames.reba ? 11 : 10)) xAxis.pop();
  return {
    xAxis,
    yAxis,
  };
};

export default prepareChartAxisData;
