import Immutable from 'seamless-immutable';
import * as types from './actionsTypes';
import { assessmentSteps } from '../constants';

const initialState = Immutable({
  assessmentsList: [],
  currentAssessmentId: null,
  assessmentStep: assessmentSteps.list,
  error: null,
  isLoading: true,
});

export default function reduce(state = initialState, action = {}) {
  const {
    assessmentsList, currentAssessmentId, assessment, assessmentStep, error, removedAssessmentId,
  } = action;
  switch (action.type) {
    case types.ASSESSMENTS_SET: return state.merge({
      assessmentsList: [...state.assessmentsList, ...assessmentsList],
      error: null,
    });
    case types.ASSESSMENTS_UPDATE: return state.merge(
      { assessmentsList: [...state.assessmentsList.filter(({ id }) => id !== assessment.id), assessment] },
    );
    case types.ASSESSMENT_SET: return state.merge({ currentAssessmentId });
    case types.ASSESSMENT_STEP_SET: return state.merge({ assessmentStep });
    case types.ASSESSMENT_REMOVE: return state.merge(
      { assessmentsList: state.assessmentsList.filter(({ id }) => id !== removedAssessmentId) },
    );
    case types.ASSESSMENTS_ERROR: return state.merge({ error });
    case types.ASSESSMENTS_RESET: return state.merge(initialState);
    default: return state;
  }
}
