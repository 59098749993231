/**
 * @fileoverview gRPC-Web generated client stub for dashboard_admin
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard-admin/v1/device.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard$admin_v1_device_message_pb = require('../../../api/dashboard-admin/v1/device_message_pb.js')
const proto = {};
proto.dashboard_admin = require('./device_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dashboard_admin.AdminDevicesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dashboard_admin.AdminDevicesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dashboard_admin.AdminDevicesRequest,
 *   !proto.dashboard_admin.AdminDevicesResponse>}
 */
const methodDescriptor_AdminDevices_GetDevices = new grpc.web.MethodDescriptor(
  '/dashboard_admin.AdminDevices/GetDevices',
  grpc.web.MethodType.UNARY,
  api_dashboard$admin_v1_device_message_pb.AdminDevicesRequest,
  api_dashboard$admin_v1_device_message_pb.AdminDevicesResponse,
  /**
   * @param {!proto.dashboard_admin.AdminDevicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_dashboard$admin_v1_device_message_pb.AdminDevicesResponse.deserializeBinary
);


/**
 * @param {!proto.dashboard_admin.AdminDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dashboard_admin.AdminDevicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dashboard_admin.AdminDevicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dashboard_admin.AdminDevicesClient.prototype.getDevices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dashboard_admin.AdminDevices/GetDevices',
      request,
      metadata || {},
      methodDescriptor_AdminDevices_GetDevices,
      callback);
};


/**
 * @param {!proto.dashboard_admin.AdminDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dashboard_admin.AdminDevicesResponse>}
 *     Promise that resolves to the response
 */
proto.dashboard_admin.AdminDevicesPromiseClient.prototype.getDevices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dashboard_admin.AdminDevices/GetDevices',
      request,
      metadata || {},
      methodDescriptor_AdminDevices_GetDevices);
};


module.exports = proto.dashboard_admin;

