import React from 'react';

const MedalWithQuantity = ({ image, quantity }) => (
  <div>
    <img src={image} alt="medal icon" />
    <span>
      <div style={{ marginTop: quantity < 99 && '-1px', fontSize: quantity > 99 ? '9px' : '11px' }}>
        {quantity}
      </div>
    </span>
  </div>
);

export default MedalWithQuantity;
