import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as Emotion from 'emotion';
import { card } from 'assets/soter-flex-styles';
import { button, disabledButton, text } from 'assets/jss/style';
import { useTranslation } from 'react-i18next';
import stepOneImg from 'assets/img/asessmentPictures/assessment_step_one.svg';
import stepTwoImg from 'assets/img/asessmentPictures/assessment_step_two.svg';
import stepThreeImg from 'assets/img/asessmentPictures/assessment_step_three.svg';
import stepFourImg from 'assets/img/asessmentPictures/assessment_step_four.svg';
import { TASK_ASSESSMENT_CREATED, TASK_ASSESSMENT_EDITED } from '../../../constants/analyticsEvents';
import {
  ASSESSMENT_LONG_HEIGHT,
  ASSESSMENT_SHORT_HEIGHT,
  ASSESSMENT_WIDTH,
  assessmentSteps,
  assessmentStepsNames, assessmentTypesList, weightUnitsList,
} from '../constants';
import { COLORS } from '../../../constants/colors';
import {
  createAssessment, getAssessments,
  removeAssessment, resetAssessments, setAssessmentStep,
  setCurrentAssessment,
  updateAssessment,
} from '../assessmentsStore/actions';
import AssessmentsList from './AssessmentsList';
import AssessmentTime from './AssessmentTime';
import AssessmentName from './AssessmentName';
import AssessmentSettings from './AssessmentSettings';
import {
  rebaAMatrix, rebaBMatrix, rebaCMatrix, rebaStructure,
} from '../rebaStructure';
import {
  rulaStructure, rulaTableAMatrix, rulaTableBMatrix, rulaTableCMatrix,
} from '../rulaStructure';
import Assessment from './Assessment';
import AssessmentNiosh from './AssessmentNiosh';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

const stepImages = [stepOneImg, stepTwoImg, stepThreeImg, stepFourImg];

const wrapper = (isShort) => Emotion.css`
  height: ${isShort ? ASSESSMENT_SHORT_HEIGHT : ASSESSMENT_LONG_HEIGHT}px;
  width: ${ASSESSMENT_WIDTH + 5}px;
  grid-area: assessment;
`;

const header = (isDoubled) => Emotion.css`
  height: 58px;
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  padding: 10px;
  display: flex;
  justify-content: ${isDoubled ? 'space-between' : 'flex-end'};
`;

const bottom = Emotion.css`
  height: 58px;
  border-top: 1px solid ${COLORS.GRAY[100]};
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

const AssessmentWrapper = ({
  currentTime,
  taskVideoId,
  setCurrentTime,
  setAssessmentDownloading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assessmentStepsKeys = Object.keys(assessmentSteps);
  const {
    assessmentsList,
    assessmentStep: step,
    currentAssessmentId,
  } = useSelector((state) => state.soterTaskAssessments);
  const setStep = (s) => dispatch(setAssessmentStep(s));
  const [assessmentType, setAssessmentType] = useState(
    assessmentTypesList[0].value,
  );
  const [weightUnit, setWeightUnit] = useState(weightUnitsList[0].value);
  const [assessmentName, setAssessmentName] = useState(
    moment().format('YYYY-MM-DD HH:mm:ss'),
  );
  const [totalScore, setTotalScore] = useState(null);
  const [additionalTotalScore, setAdditionalTotalScore] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  useEffect(() => {
    if (taskVideoId) dispatch(getAssessments(taskVideoId));
    return () => dispatch(resetAssessments());
  }, [taskVideoId]);
  useEffect(() => {
    if (currentAssessmentId) {
      const currentAssessment = assessmentsList.find(
        ({ id }) => id === currentAssessmentId,
      );
      const currentAssessmentType
        = assessmentTypesList[currentAssessment.type - 2].value;
      setAssessmentType(currentAssessmentType);
      if (currentAssessmentType === 'niosh') {
        setSelectedFields(currentAssessment.fieldsList);
      } else {
        setSelectedFields(currentAssessment.fieldsList.map(({ name }) => name));
      }
      setAssessmentName(currentAssessment.title);
      setTotalScore(currentAssessment.totalScore);
      setAdditionalTotalScore(currentAssessment.additionalTotalScore);
    }
  }, [currentAssessmentId]);
  const isHeaderButtonDoubled
    = step !== assessmentSteps.list &&
    step !== assessmentSteps.settings &&
    step !== assessmentSteps.view;
  const switchStep = (currentStep, goNext = true) => {
    const currentIndex = assessmentStepsKeys.indexOf(currentStep);
    const newIndex = currentIndex + (goNext ? 1 : -1);
    setStep(assessmentStepsKeys[newIndex]);
    if (assessmentStepsKeys[newIndex] === 'view') {
      window.logEvent(TASK_ASSESSMENT_CREATED, {
        assessment_type: setStep(assessmentStepsKeys[newIndex]).type,
      });
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(`${TASK_ASSESSMENT_CREATED}, assessment_type: ${setStep(assessmentStepsKeys[newIndex]).type}`));
      }
    }
  };
  const getRightButtonHandler = (currentStep) => {
    switch (currentStep) {
      case assessmentSteps.view:
        return () => {
          window.logEvent(TASK_ASSESSMENT_EDITED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_ASSESSMENT_EDITED));
          }
          setStep(assessmentSteps.edit);
        };
      case assessmentSteps.edit:
        if (!currentAssessmentId) {
          return () => {
            switchStep(currentStep);
            dispatch(
              createAssessment({
                type: assessmentType.includes('rula')
                  ? 2
                  : assessmentType.includes('niosh')
                    ? 5
                    : 4,
                title: assessmentName,
                totalScore,
                additionalTotalScore,
                timestamp: currentTime,
                taskVideoId,
                fields: selectedFields,
              }),
            );
          };
        }
        return () => {
          switchStep(currentStep);
          dispatch(
            updateAssessment({
              id: currentAssessmentId,
              title: assessmentName,
              totalScore,
              additionalTotalScore,
              timestamp: currentTime,
              fields: selectedFields,
            }),
          );
        };
      default:
        return () => switchStep(currentStep);
    }
  };
  const getRightButtonText = (currentStep) => {
    switch (currentStep) {
      case assessmentSteps.edit:
        return t('SOTER_TASK.ASSESSMENT.BUTTONS.SAVE');
      case assessmentSteps.view:
        return t('SOTER_TASK.ASSESSMENT.BUTTONS.EDIT');
      case assessmentSteps.list:
        return t('SOTER_TASK.ASSESSMENT.BUTTONS.CREATE');
      default:
        return t('SOTER_TASK.ASSESSMENT.BUTTONS.NEXT');
    }
  };
  const getStepComponent = (currentStep) => {
    switch (currentStep) {
      case assessmentSteps.edit:
      case assessmentSteps.view:
        if (assessmentType === 'custom') {
          return (
            <div>{t('SOTER_TASK.ASSESSMENT.SET_SCORE').toUpperCase()}</div>
          );
        }
        if (assessmentType === 'niosh') {
          return (
            <AssessmentNiosh
              setTotalScore={setTotalScore}
              totalScore={totalScore}
              setSelectedFields={setSelectedFields}
              additionalTotalScore={additionalTotalScore}
              setAdditionalTotalScore={setAdditionalTotalScore}
              selectedFields={selectedFields}
              editingMode={currentStep === assessmentSteps.edit}
              weightUnit={weightUnit}
            />
          );
        }
        return (
          <Assessment
            structure={
              assessmentType === 'reba' ? rebaStructure : rulaStructure
            }
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            matrix={
              assessmentType === 'reba'
                ? [rebaAMatrix, rebaBMatrix, rebaCMatrix]
                : [rulaTableAMatrix, rulaTableBMatrix, rulaTableCMatrix]
            }
            totalScore={totalScore}
            setTotalScore={setTotalScore}
            assessmentType={assessmentType}
            weightUnit={weightUnit}
          />
        );
      case assessmentSteps.settings:
        return (
          <AssessmentSettings
            assessmentType={assessmentType}
            setAssessmentType={setAssessmentType}
            weightUnit={weightUnit}
            setWeightUnit={setWeightUnit}
          />
        );
      case assessmentSteps.name:
        return (
          <AssessmentName name={assessmentName} setName={setAssessmentName} />
        );
      case assessmentSteps.time:
        return <AssessmentTime time={currentTime} />;
      case assessmentSteps.list:
        return (
          <AssessmentsList
            assessmentsList={assessmentsList}
            selectAssessment={(id) => {
              setStep(assessmentSteps.view);
              dispatch(setCurrentAssessment(id));
            }}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            removeAssessment={(id) => dispatch(removeAssessment(id))}
            setAssessmentDownloading={setAssessmentDownloading}
          />
        );
      default:
        return <div>{step}</div>;
    }
  };
  return (
    <div className={`${card} ${wrapper(true)}`}>
      <div className={header(isHeaderButtonDoubled)}>
        {isHeaderButtonDoubled && (
          <div
            className={button(COLORS.LIGHT_BLUE[600])}
            onClick={() => switchStep(step, false)}
          >
            {t('SOTER_TASK.ASSESSMENT.BUTTONS.PREVIOUS')}
          </div>
        )}
        {step === assessmentSteps.list ? (
          <div
            style={{ whiteSpace: 'nowrap' }}
            className={
              step === assessmentSteps.name &&
              assessmentName.replace(/\s/g, '')?.length === 0
                ? disabledButton
                : button(COLORS.LIGHT_BLUE[600])
            }
            onClick={
              step === assessmentSteps.name &&
              assessmentName.replace(/\s/g, '')?.length === 0
                ? () => {}
                : getRightButtonHandler(step)
            }
          >
            {getRightButtonText(step)}
          </div>
        ) : (
          <div
            className={button(COLORS.LIGHT_BLUE[600])}
            onClick={() => {
              setSelectedFields([]);
              setStep(assessmentSteps.list);
              setTotalScore(null);
              setAdditionalTotalScore(null);
              setAssessmentName(moment().format('YYYY-MM-DD HH:mm:ss'));
              setAssessmentType(assessmentTypesList[0].value);
              setWeightUnit(weightUnitsList[0].value);
              dispatch(setCurrentAssessment(null));
            }}
          >
            {t('SOTER_TASK.ASSESSMENT.BUTTONS.EXIT')}
          </div>
        )}
      </div>
      {getStepComponent(step)}
      {step !== assessmentSteps.list && (
        <div className={bottom}>
          {assessmentStepsNames[step] && (
            <div
              className={text(COLORS.GRAY[400], 16)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ marginRight: '17px', marginTop: '-3px' }}>
                {t(assessmentStepsNames[step])}
              </span>
              <img
                src={
                  stepImages[Object.keys(assessmentStepsNames).indexOf(step)]
                }
              />
            </div>
          )}
          <div
            style={{ whiteSpace: 'nowrap' }}
            className={
              step === assessmentSteps.name &&
              assessmentName.replace(/\s/g, '')?.length === 0
                ? disabledButton
                : button(COLORS.LIGHT_BLUE[600])
            }
            onClick={
              step === assessmentSteps.name &&
              assessmentName.replace(/\s/g, '')?.length === 0
                ? () => {}
                : getRightButtonHandler(step)
            }
          >
            {getRightButtonText(step)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentWrapper;
