import React from 'react';
import {
  column,
  flexColumn,
  flexRow,
  horizontalDivider,
  infoMainText,
  mb3,
  medalIconLarge,
  medalQuantity,
  ml1,
  mt2,
  progressBar,
  subTextResizing,
} from '../../../../assets/soter-flex-styles';
import gold from '../../../../assets/img/icons/medals/Gold.svg';
import { COLORS } from 'constants/colors';
import noMedal from '../../../../assets/img/icons/medals/NoMedal.svg';
import RiskGroupBlock from './RiskGroupBlock';
import { useTranslation } from 'react-i18next';

const medalTextStyle = (quantity) => {
  if (!quantity) {
    return {
      fontSize: '2.25rem',
      lineHeight: '3rem',
    };
  }
  return {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: COLORS.GRAY[950],
  };
};

const ProgramProgressInfo = ({
  progress,
  tutorials,
  riskGroups,
  goldMedalsQuantity,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={flexRow}>
        <div className={`${flexColumn}`} style={{ flex: '0 0 33.3333%' }}>
          <div className={flexColumn}>
            <div
              className={flexRow}
              style={{ justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <p className={infoMainText} style={{ textAlign: 'start' }}>
                {`${progress?.acceptedWorkSessionsCount || 0}/${
                  progress?.targetWorkSessionsCount || 0
                }`}
              </p>
              <p
                className={`${subTextResizing} ${ml1}`}
                style={{ fontSize: '.75em' }}
              >
                {t('WEARABLES.OVERVIEW.DAYS')}
              </p>
            </div>
          </div>

          <progress
            className={progressBar}
            max={progress?.targetWorkSessionsCount || 1}
            value={progress?.acceptedWorkSessionsCount || 0}
          />
        </div>

        <div className={`${flexColumn}`} style={{ flex: '0 0 33.3333%' }}>
          <div className={flexColumn}>
            <div
              className={flexRow}
              style={{ justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <p className={infoMainText} style={{ textAlign: 'start' }}>
                {`${tutorials?.finishedTutorialsCount || 0}/${
                  tutorials?.tutorialsCount || 0
                }`}
              </p>
              <p className={`${subTextResizing} ${ml1}`}>
                {t('WEARABLES.OVERVIEW.TUTORIALS')}
              </p>
            </div>
          </div>

          <progress
            className={progressBar}
            max={tutorials?.tutorialsCount || 1}
            value={tutorials?.finishedTutorialsCount || 0}
          />
        </div>

        <div className={`${flexColumn}`} style={{ flex: '0 0 33.3333%' }}>
          {goldMedalsQuantity ? (
            <div
              className={column}
              style={{
                flex: '0 1 33.33333%',
                fontWeight: '600',
                color: COLORS.GRAY[400],
              }}
            >
              <img className={medalIconLarge} src={gold} alt='medal icon' />
              <span
                className={medalQuantity}
                style={medalTextStyle(goldMedalsQuantity)}
              >
                {`x ${goldMedalsQuantity}`}
              </span>
            </div>
          ) : (
            <img
              src={noMedal}
              alt='no medal'
              style={{
                width: '3.4rem',
                height: '3.4rem',
              }}
            />
          )}
        </div>
      </div>
      <div className={`${horizontalDivider} ${mt2} ${mb3}`} />
      <RiskGroupBlock riskGroups={riskGroups} />
    </>
  );
};

export default ProgramProgressInfo;
