import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import ModalWrapper from '../../../containers/ModalContainer/ModalWrapper';
import Button from '../../../lib/ui/Button';
import { GeniusReportFormat, GeniusReportType } from '../types';
import { defaultFormatSet, msdScreeningFormatSet, reportFormatNames, reportTypeNames, } from '../constants';
import redirectToGeniusWithMetadata from '../utils/redirectToGeniusWithMetadata';
import RadioButton from '../../../lib/ui/RadioButton';
import Text from '../../../lib/ui/Text';
import { mb2 } from "../../../assets/soter-flex-styles";
const flexContainer = Emotion.css `
    display: flex;
    flex-wrap: wrap;
`;
const GeniusReportModal = ({ isOpen, processedVideoData, closeModal }) => {
    const { t } = useTranslation();
    const { videoId, processedVideoUrl, videoTitle, taskName } = processedVideoData;
    const dispatch = useAppDispatch();
    const { selectedCompany } = useAppSelector((state) => state.companies);
    const [reportType, setReportType] = useState(GeniusReportType.ASSESSMENT);
    const [formatSet, setFormatSet] = useState(defaultFormatSet);
    const [reportFormat, setReportFormat] = useState(GeniusReportFormat.INCHAT);
    // Here is the logic of choosing proper format set for the report type
    useEffect(() => {
        switch (reportType) {
            case GeniusReportType.SCREENING:
                setFormatSet(msdScreeningFormatSet);
                setReportFormat(GeniusReportFormat.XLS);
                break;
            default:
                // Check if the format set stays the same and we don't need to update a report format
                if (formatSet[0] === defaultFormatSet[0])
                    return;
                setFormatSet(defaultFormatSet);
                setReportFormat(GeniusReportFormat.INCHAT);
        }
    }, [reportType]);
    const generateButtonHandler = () => {
        redirectToGeniusWithMetadata({
            processedVideoUrl,
            reportType,
            reportFormat,
            videoId,
            videoTitle,
            companyId: selectedCompany.id,
            taskName,
        }, dispatch);
    };
    if (!isOpen)
        return null;
    const reportTypesForRadioButtons = Object.keys(GeniusReportType).filter((key) => isNaN(Number(key)));
    return (React.createElement(ModalWrapper, { keepModalOnClickOutside: false, closeModal: closeModal },
        React.createElement(Text, { appearance: 'headlineTwo', text: t("SOTER_GENIUS.INSTANT_REPORT") }),
        React.createElement(Text, { className: mb2, appearance: 'title', text: t("SOTER_GENIUS.REPORT_TYPE") }),
        React.createElement("div", { className: `${mb2} ${flexContainer}` }, reportTypesForRadioButtons.map((key) => {
            const repType = reportTypeNames[GeniusReportType[key]];
            return (React.createElement(RadioButton, { key: `reptype_${key}`, label: t(repType[1] || repType[0]), name: "reportType", id: `reptype_${key}`, value: GeniusReportType[key].toString(), selectedValue: reportType.toString(), onChange: () => setReportType(GeniusReportType[key]) }));
        })),
        React.createElement(Text, { className: mb2, appearance: 'title', text: t("SOTER_GENIUS.REPORT_FORMAT") }),
        React.createElement("div", { className: `${mb2} ${flexContainer}` }, formatSet.map((key) => {
            const format = reportFormatNames[key] && (reportFormatNames[key][1] || reportFormatNames[key][0]);
            return (React.createElement(RadioButton, { key: `format_${key}`, id: `format_${key}`, label: t(format), name: "reportFormat", value: key.toString(), selectedValue: reportFormat.toString(), onChange: () => setReportFormat(key) }));
        })),
        React.createElement(Button, { style: { width: '100%', justifyContent: 'center' }, text: t("SOTER_GENIUS.GENERATE_BUTTON"), handler: generateButtonHandler })));
};
export default GeniusReportModal;
