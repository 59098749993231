export const USERS_SET = 'USERS_SET';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_TOGGLE_LOADING = 'USERS_TOGGLE_LOADING';
export const USERS_TOGGLE_FILTERS = 'USERS_TOGGLE_FILTERS';
export const USERS_RESET = 'USERS_RESET';
export const USERS_REMOVE_TAG = 'USERS_REMOVE_TAG';
export const USERS_ADD_TAG = 'USERS_ADD_TAG';
export const USERS_SET_JOB_ROLE = 'USERS_SET_JOB_ROLE';
export const USERS_SET_DEPARTMENT = 'USERS_SET_DEPARTMENT';
export const USERS_SET_SITE = 'USERS_SET_SITE';
export const USERS_SET_PICKER_VALUE = 'USERS_SET_PICKER_VALUE';
export const USERS_DELETE = 'USERS_DELETE';
export const USERS_RESET_DELETED = 'USERS_RESET_DELETED';
export const USERS_SET_CREATED_STATUSES = 'USERS_SET_CREATED_STATUSES';

export const USERS_EDIT_STATUSES = 'USERS_EDIT_STATUSES';
export const USERS_CLEAR_EDIT_STATUSES = 'USERS_CLEAR_EDIT_STATUSES';

export const USERS_EDIT_SET = 'USERS_EDIT_SET';
