import React from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { ml2 } from '../../assets/soter-flex-styles';
import LeftArrow from '../../assets/img/icons/chevron-left-disabled.svg';
import RightArrow from '../../assets/img/icons/chevron-right-disabled.svg';
import { COLORS } from '../../constants/colors';

const flex = Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const container = Emotion.css`
  ${flex};

  padding: 5px 14px;
  color: ${COLORS.LIGHT_BLUE[600]};
`;

const button = (disabled) => Emotion.css`
  height: 40px;
  width: 40px;
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  }
`;

const addButton = Emotion.css`
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

const CardFooterControl = ({
  showSwitcher,
  addHandler,
  taskAddHandler,
  switchHandler,
  moreItemsCount,
  isFirstPage,
}) => {
  const { t } = useTranslation();
  return (
    <div className={container}>
      {taskAddHandler && (
        <div className={addButton} onClick={taskAddHandler}>
          {`+ ${t('JOB_DICTIONARY.JOB_PROFILE.ATTACH').toUpperCase()}`}
        </div>
      )}
      {addHandler && (
        <div className={`${addButton} ${ml2}`} onClick={addHandler}>
          {`+ ${t('JOB_DICTIONARY.JOB_PROFILE.ADD').toUpperCase()}`}
        </div>
      )}
      {showSwitcher && (
        <div className={flex}>
          <div>
            {moreItemsCount > 0 &&
              `${moreItemsCount} ${t(
                'JOB_DICTIONARY.JOB_PROFILE.MORE'
              ).toLowerCase()}`}
          </div>
          <div
            className={`${button(isFirstPage)} ${ml2}`}
            onClick={switchHandler('left')}
          >
            <img src={LeftArrow} />
          </div>
          <div
            className={`${button(moreItemsCount < 1)} ${ml2}`}
            onClick={switchHandler('right')}
          >
            <img src={RightArrow} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardFooterControl;
