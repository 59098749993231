import React from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../constants/colors';
import Button from './Button';

const editButton = Emotion.css`
  background-color: transparent;
  color: ${COLORS.LIGHT_BLUE[600]};
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  margin-left: 9px;
  &:hover {
    cursor: pointer;
  }
`;

const editButtonRed = Emotion.css`
  ${editButton};

  color: ${COLORS.RED[600]};
  //margin-right: 15px;
`;

const CardEditingControl = ({
  editButtonHandler,
  saveButtonHandler,
  cancelButtonHandler,
  editMode,
}) => {
  const { language } = localStorage;
  const { t } = useTranslation();
  return (
    <div style={{ zIndex: 10, display: 'flex' }}>
      {editMode ? (
        <>
          <Button
            className={editButtonRed}
            handler={cancelButtonHandler}
            style={{ fontSize: language === 'es' ? '14px' : '', padding: '0' }}
            text={t('GENERAL.CANCEL').toUpperCase()}
            appearance="text"
          />
          <Button
            className={editButton}
            style={{ fontSize: language === 'es' ? '14px' : '', padding: '0' }}
            handler={saveButtonHandler}
            text={t('GENERAL.SAVE').toUpperCase()}
            appearance="text"
          />
        </>
      ) : (
        <Button
          className={editButton}
          handler={editButtonHandler}
          text={t('GENERAL.EDIT').toUpperCase()}
          appearance="text"
          style={{ padding: '0' }}
        />
      )}
    </div>
  );
};

export default CardEditingControl;
