import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import useFocus from 'lib/utils/useFocus';
import Button from 'lib/ui/Button';
import {
  column, formControlInput, inputLabel, mb2, mb3, mt3,
} from '../../assets/soter-flex-styles';

const TitleChangeDialog = ({
  user,
  handlerFn,
  userIds,
  type,
  entryId,
  companyId,
  categoryId,
  subtitle,
  currentTitle,
}) => {
  const { t } = useTranslation();
  const [inputRef, setInputFocus] = useFocus();
  const [title, setTitle] = useState(currentTitle);
  useEffect(() => {
    setTimeout(() => setInputFocus(), 0);
  }, []);
  const taskName = localStorage.getItem('taskName');

  return (
    <form
      className={`${column} ${mb2}`}
      onSubmit={(e) => {
        handlerFn(e, userIds || companyId || user.id, title, categoryId);
      }}
      autoComplete="off"
    >
      <label className={inputLabel} htmlFor={type || 'job-title'}>
        {type
          ? `${subtitle || t('GENERAL.CREATE_NEW')} ${type}`
          : t('GENERAL.JOB_TITLE')}
        <input
          className={`${formControlInput} ${mt3} ${mb3}`}
          ref={inputRef}
          type="text"
          defaultValue={taskName || currentTitle}
          id={type || 'job-title'}
          placeholder={`${t('WEARABLES.ACTIVITY.DIALOGS.INPUT_NEW')} ${
            type || t('GENERAL.JOB_TITLE').toLowerCase()
          } ${t('GENERAL.NAME').toLowerCase()}`}
   
          onKeyDown={(event) => {
            const keyValue = event.key;
            const targetValue = event.target.value;
            const regex = /^[a-zA-Z0-9,]+$/;
            const lastChar = targetValue[targetValue.length - 1];
            const oneBeforeLastChar = targetValue[targetValue.length - 2];
          
            if (targetValue !== "" && keyValue === ' ' && lastChar !== " ") {
              return;
            } else if (keyValue === ',' && ((lastChar === " " && oneBeforeLastChar === ",") || lastChar === ",") || (!regex.test(keyValue) && (lastChar === " " || targetValue === '' || lastChar === ",")) || (targetValue === "" && keyValue === ",")) {
              event.preventDefault();
            }
          }}
          
          onChange={(event) => {
            setTitle(
              event.target.value
                .trim()
                .replaceAll('/', '_')
                // Replace sequence of 2+ whitespaces to one whitespace
                .replaceAll(/\b\s{2,}\b/g, ' '),
            );
            localStorage.removeItem('taskName');
          }}
        />
      </label>
      {type === 'entries' ? (
        <div
          style={{
            fontSize: '14px',
            color: COLORS.GRAY[400],
            marginBottom: '10px',
            marginTop: '-6px',
          }}
        >
          {t('WEARABLES.ACTIVITY.DIALOGS.ENTER_AND_CREATED')}
        </div>
      ) : null}
      <Button
        type="button"
        handler={(e) => {
          let titleWithoutDuplication = title;

          if (title.length > 1) {
            const entries = title.split(',');
            const entriesWithoutSpace = entries.map(entry => entry.trim());
            const removeDuplicated = [...new Set(entriesWithoutSpace)];
            titleWithoutDuplication = removeDuplicated.join();

            if (!titleWithoutDuplication[titleWithoutDuplication.lastIndexOf(',') + 2]) {
              titleWithoutDuplication = titleWithoutDuplication.slice(0, titleWithoutDuplication.lastIndexOf(','));
            }
          }
          setTitle(titleWithoutDuplication);
          localStorage.removeItem('taskName');
          handlerFn(
            e,
            userIds || companyId || user.id,
            titleWithoutDuplication || taskName,
            categoryId,
            entryId,
          );
        }}
        disabled={!title && !taskName && taskName?.charAt(0) !== ','}
        text={`${subtitle ? t('GENERAL.SET') : t('GENERAL.CREATE')} ${
          subtitle
            ? t('GENERAL.EDITED').toLowerCase()
            : t('GENERAL.NEW').toLowerCase()
        } ${type || t('GENERAL.JOB_TITLE').toLowerCase()}`}
        style={{ display: 'block', width: '100%' }}
      />
    </form>
  );
};

export default TitleChangeDialog;
