import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { dropDown, dropUp } from '../../../assets/soter-flex-styles';
import { useTranslation } from 'react-i18next';
import Button from '../../../lib/ui/Button';

const directionClassMap = {
  down: dropDown,
  up: dropUp,
  left: 'dropLeft',
  right: 'dropRight',
};

const DropWrapper = ({
  id,
  name,
  toggleDrop,
  children,
  direction,
  withCancelButton,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const dropRef = useRef(null);
  const modalRoot = document.getElementById(id);
  if (!dropRef.current) {
    dropRef.current = document.createElement('div');
  }
  const dropClass = directionClassMap[direction];
  const handleClickOutside = (event) => {
    /*
    For proper work of toggler button it must get data-name attribute
    with the same value as id prop that is provided to DropToggler
    */
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      event.target.getAttribute('data-name') !== id
    ) {
      toggleDrop(name);
    }
  };

  useEffect(() => {
    modalRoot?.appendChild(dropRef.current);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      modalRoot?.removeChild(dropRef.current);
    };
  }, []);

  const modal = (
    <div className={dropClass} ref={wrapperRef}>
      {children}
      {withCancelButton ? (
        <Button
          text={t('GENERAL.CANCEL')}
          type='button'
          onClick={() => {
            toggleDrop(name);
          }}
        />
      ) : null}
    </div>
  );

  return createPortal(modal, dropRef.current);
};

export default DropWrapper;
