import * as types from './actionTypes';
import { setNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../containers/NotificationContainer/notifications';
import {
  getMetaData,
  dashboardUserChatProfile,
  dashboardUserChatProfileService,
  createDashboardUserChatProfileRequest,
  getDashboardUserChatProfileRequest,
  updateDashboardUserChatProfileRequest,
  getPreSignedUrlRequest,
  setUserProfileFileRequest,
  deleteUserProfileFileRequest,
  getChatSignatureRequest, getLatestChatIdRequest,
} from '../../init/proto_client';

export function createDashboardUserChatProfile(profileInfo) {
  return async (dispatch) => {
    dashboardUserChatProfile.setCompanyName(profileInfo.companyName);
    dashboardUserChatProfile.setCompanyIndustry(profileInfo.companyIndustry);
    dashboardUserChatProfile.setNaicsCode(profileInfo.naicsCode);
    dashboardUserChatProfile.setLocation(profileInfo.location);
    dashboardUserChatProfile.setAdditionalInformation(profileInfo.additionalInformation);
    createDashboardUserChatProfileRequest.setChatDashboardUserProfile(dashboardUserChatProfile);

    await dashboardUserChatProfileService.createDashboardUserChatProfile(
      createDashboardUserChatProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch({
            type: types.USER_PROFILE_ERROR,
            error,
          });
        } else {
          const userProfile = response.toObject().chatDashboardUserProfile;
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.CREATE_USERPROFILE_SUCCESS_MSG,
          }));
          dispatch({
            type: types.USER_PROFILE_CREATE,
            userProfile,
          });
        }
      },
    );
  };
}

const setProfile = (dispatch) => (error, response) => {
  if (error) {
    dispatch({
      type: types.USER_PROFILE_ERROR,
      error,
    });
    dispatch({
      type: types.USER_PROFILE_LOADING,
      isLoading: false,
    });
  } else {
    const userProfile = response.toObject().chatDashboardUserProfile;
    dispatch({
      type: types.USER_PROFILE_SET,
      userProfile,
    });
  }
};

export function getDashboardUserChatProfile(dashboardUserId) {
  return async (dispatch) => {
    getDashboardUserChatProfileRequest.setDashboardUserId(dashboardUserId);
    await dashboardUserChatProfileService.getDashboardUserChatProfile(
      getDashboardUserChatProfileRequest,
      getMetaData(),
      setProfile(dispatch),
    );
  };
}

export function updateDashboardUserChatProfile(profileInfo) {
  return async (dispatch) => {
    dispatch({
      type: types.USER_PROFILE_LOADING,
      isLoading: true,
    });
    dashboardUserChatProfile.setCompanyName(profileInfo.companyName);
    dashboardUserChatProfile.setCompanyIndustry(profileInfo.companyIndustry);
    dashboardUserChatProfile.setNaicsCode(profileInfo.naicsCode);
    dashboardUserChatProfile.setLocation(profileInfo.location);
    dashboardUserChatProfile.setAdditionalInformation(profileInfo.additionalInformation);
    updateDashboardUserChatProfileRequest.setChatDashboardUserProfile(dashboardUserChatProfile);

    await dashboardUserChatProfileService.updateDashboardUserChatProfile(
      updateDashboardUserChatProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch({
            type: types.USER_PROFILE_ERROR,
            error,
          });
        } else {
          const userProfile = response.toObject().chatDashboardUserProfile;
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.UPDATE_USERPROFILE_SUCCESS_MSG,
          }));
          dispatch({
            type: types.USER_PROFILE_SET,
            userProfile,
          });
        }
      },
    );
  };
}

export function uploadUserProfileFile(inputFile, fileType, t) {
  const { path, file } = inputFile;
  const fileName = path.slice(path.lastIndexOf('\\') + 1);
  return async (dispatch) => {
    getPreSignedUrlRequest.setOriginalFileName(fileName);
    await dashboardUserChatProfileService.getPreSignedUrlDashboardUserChatProfile(
      getPreSignedUrlRequest,
      getMetaData(),
      async (error, response) => {
        if (error) {
          dispatch({
            type: types.USER_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: `${t('NOTIFICATIONS.UPLOAD_FAILED')} ${error.message}`,
          }));
        } else {
          const url = response.toObject().preSignetUrl;
          try {
            const res = await fetch(url, {
              method: 'PUT',
              body: file,
            });
            if (res.ok) {
              setUserProfileFileRequest.setFileUrl(res.url);
              setUserProfileFileRequest.setFileType(fileType);
              setUserProfileFileRequest.setOriginalFileName(fileName);
              dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: t('NOTIFICATIONS.FILE_UPLOADED'),
              }));
              await dashboardUserChatProfileService.setDashboardUserChatProfileFile(
                setUserProfileFileRequest,
                getMetaData(),
                setProfile(dispatch),
              );
            } else {
              dispatch({
                type: types.USER_PROFILE_ERROR,
                error: res.statusText,
              });
              dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: `${t('NOTIFICATIONS.UPLOAD_FAILED')} ${res.statusText}`,
              }));
            }
          } catch (e) {
            console.error('Error occurred while uploading to S3:', e);
            dispatch({
              type: types.USER_PROFILE_ERROR,
              error: e,
            });
            dispatch(setNotification({
              type: notificationTypes.TYPE_FAILED,
              message: t('NOTIFICATIONS.S3_ERROR'),
            }));
          }
        }
      },
    );
  };
}

export function deleteUserProfileFile(fileId, t) {
  return async (dispatch, getState) => {
    deleteUserProfileFileRequest.setId(fileId);
    await dashboardUserChatProfileService.deleteDashboardUserChatProfileFile(
      deleteUserProfileFileRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch({
            type: types.USER_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: error.message,
          }));
        } else {
          dispatch(getDashboardUserChatProfile(getState().auth.profile.id));
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: t('NOTIFICATIONS.FILE_REMOVED'),
          }));
        }
      },
    );
  };
}

export function getChatSignature() {
  return async (dispatch) => {
    getChatSignatureRequest.setDummy(true);
    await dashboardUserChatProfileService.getDashboardUserSignature(
      getChatSignatureRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch({
            type: types.USER_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          // dispatch(setNotification({
          //   type: notificationTypes.TYPE_FAILED,
          //   message: error.message,
          // }));
        } else {
          const { signature } = response.toObject();
          dispatch({
            type: types.USER_PROFILE_SIGNATURE_SET,
            signature,
          });
        }
      },
    );
  };
}

export function getLatestChatId() {
  return async (dispatch) => {
    getLatestChatIdRequest.setDummy(true);
    await dashboardUserChatProfileService.getDashboardUserLatestChatId(
      getLatestChatIdRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch({
            type: types.USER_PROFILE_ERROR,
            error,
          });
        } else {
          const { latestChatId } = response.toObject();
          dispatch({
            type: types.USER_PROFILE_CHAT_ID_SET,
            latestChatId,
          });
        }
      },
    );
  };
}

export function setVideoMetadata(videoMetadata) {
  return (dispatch) => {
    dispatch({
      type: types.USER_PROFILE_VIDEO_METADATA_SET,
      videoMetadata,
    });
  };
}

export function resetVideoMetadata() {
  return (dispatch) => {
    dispatch({
      type: types.USER_PROFILE_VIDEO_METADATA_RESET,
    });
  };
}

export function resetUserProfile() {
  return { type: types.USER_PROFILE_RESET };
}

export function setIsLoading(isLoading) {
  return ({
    type: types.USER_PROFILE_LOADING,
    isLoading,
  });
}
