import React from 'react';
import { useTranslation } from 'react-i18next';
import DonutChart from './DonutChart';
import { LegendPosition } from '../../utils/d3/Chart';
import { deviceTypes } from '../../constants/deviceTypes';

const HMofSpineDonutChart = ({
  HMofSpineByDepartmentByPeriod,
  containerHeight,
  hasDataForChart,
}) => {
  const { t } = useTranslation();
  const chartId = 'hm-of-spine-donut-chart';
  const hazards = Object.keys(HMofSpineByDepartmentByPeriod);
  const getData = () => hazards.reduce((acc, hazard) => {
    if (hazard !== 'total') {
      return [...acc,
        {
          name: hazard,
          value: HMofSpineByDepartmentByPeriod[hazard],
        },
      ];
    }
    return acc;
  }, []).concat([{
    columns: ['name', 'value'],
  }]);
  const overallNumber = HMofSpineByDepartmentByPeriod.total &&
    !Number.isNaN(HMofSpineByDepartmentByPeriod.total) &&
    (+HMofSpineByDepartmentByPeriod.total).toFixed(1);

  return (
    <DonutChart
      chartId={chartId}
      hasDataForChart={hasDataForChart}
      getData={getData}
      sessionHazards={hazards}
      containerHeight={containerHeight}
      overallNumber={overallNumber}
      withLegend
      legendPosition={LegendPosition.RIGHT}
      overallText={t('WEARABLES.OVERVIEW.HAZARDS_PER_HOUR')}
      deviceType={deviceTypes.bend}
    />
  );
};

export default HMofSpineDonutChart;
