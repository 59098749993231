import { createSelector } from 'reselect';
import { userCompaniesSelector } from '../../auth/store/selectors';

const rootSelector = (state) => state.companies;

export const selectedCompanySelector = createSelector(
  rootSelector,
  ({ selectedCompany }) => selectedCompany,
);

export const companiesListSelector = createSelector(
  rootSelector,
  ({ companiesWithDepartments }) => companiesWithDepartments,
);

export const selectedCompanyIdSelector = createSelector(
  selectedCompanySelector,
  ({ id }) => id,
);

const currentCompanyDepartmentsListSelector = createSelector(
  selectedCompanySelector,
  ({ departmentsList }) => departmentsList || [],
);

const currentCompanyDepartmentsEntitiesSelector = createSelector(
  currentCompanyDepartmentsListSelector,
  (list) => {
    const res = {};

    list.forEach((department) => {
      res[department.id] = department;
    });

    return res;
  },
);

export const accessibleDepartmentsSelector = createSelector(
  companiesListSelector,
  (companies) => companies.map((company) => company.departmentsList).flat().map((dep) => dep.name) || [],
);

export const selectedCompanyAccessibleDepartmentsSelector = createSelector(
  [userCompaniesSelector, selectedCompanyIdSelector, currentCompanyDepartmentsEntitiesSelector],
  (companies, id, departmentEntities) => companies
    .find((company) => company.id === id)?.departmentsList?.asMutable({ deep: true })
    .map((department) => departmentEntities[department.id] || department) || [],
);

export const selectedCompanyAccessibleDepartmentsIdsSelector = createSelector(
  selectedCompanyAccessibleDepartmentsSelector,
  (departments) => departments.map((el) => el.id),
);
