import React from 'react';
import moment from 'moment';
import { container } from 'assets/soter-flex-styles';
import { useTranslation } from 'react-i18next';
import Button from 'lib/ui/Button';
import { contactSales } from '../../../auth/store/actions';
import { contactSalesProductType, productStatus } from '../../../init/proto_client';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import { banner, inner, success, warning, ended } from './styles';
export const currentProductType = (productName) => {
    switch (productName) {
        case 'geniusProduct':
            return contactSalesProductType.PRODUCTTYPEGENIUS;
        case 'Wearables':
        case 'wearableProduct':
            return contactSalesProductType.PRODUCTTYPEWEARABLE;
        case 'Job Dictionary':
        case 'jobDictionaryProduct':
            return contactSalesProductType.PRODUCTTYPEJOBDICTIONARY;
        default:
            return contactSalesProductType.PRODUCTTYPETASK;
    }
};
const SoterTaskInfoBanner = ({ productInfo, name }) => {
    const { selectedCompany } = useAppSelector((state) => state.companies);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const showBannerColor = () => {
        if (productInfo === null || productInfo === void 0 ? void 0 : productInfo.isSubscriptionRequested)
            return success;
        if ((productInfo === null || productInfo === void 0 ? void 0 : productInfo.status) === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE)
            return warning;
        return ended;
    };
    const getDuration = (termEnd) => {
        const now = moment();
        const end = moment.unix(termEnd);
        const duration = moment.duration(end.diff(now));
        return {
            days: duration.days(),
            hours: duration.hours(),
            minutes: duration.minutes(),
        };
    };
    const formatDuration = (duration, t) => {
        const { days, hours, minutes } = duration;
        if (days > 0) {
            return t('TIME.BANNER_DURATION.DAY', { count: days });
        }
        else if (hours > 0) {
            return t('TIME.BANNER_DURATION.HOUR', { count: hours });
        }
        else {
            return t('TIME.BANNER_DURATION.MINUTE', { count: minutes });
        }
    };
    const duration = getDuration(productInfo === null || productInfo === void 0 ? void 0 : productInfo.currentTermEnd);
    const daysLeft = formatDuration(duration, t);
    const showBannerMessage = () => {
        if (productInfo === null || productInfo === void 0 ? void 0 : productInfo.isSubscriptionRequested) {
            return (React.createElement("p", null, `${(productInfo === null || productInfo === void 0 ? void 0 : productInfo.status) === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE ? t('NOTIFICATIONS.SUBSCRIPTION_EXPIRES', { daysLeft }) : ''} 
          ${t('NOTIFICATIONS.REQUEST_TO_PROLONG')}`));
        }
        if ((productInfo === null || productInfo === void 0 ? void 0 : productInfo.status) === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE) {
            return (React.createElement("p", null, `${t('NOTIFICATIONS.SUBSCRIPTION_EXPIRES', { daysLeft })} ${t('NOTIFICATIONS.AFTER_THE_END')}`));
        }
        return (React.createElement("p", null, t('NOTIFICATIONS.SUBSCRIPTION_ENDED', { name })));
    };
    return (React.createElement("div", { className: `${banner} ${showBannerColor()}` },
        React.createElement("div", { className: container },
            React.createElement("div", { className: inner },
                showBannerMessage(),
                !(productInfo === null || productInfo === void 0 ? void 0 : productInfo.isSubscriptionRequested) && (React.createElement(Button, { type: "button", handler: () => {
                        if (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id) {
                            dispatch(contactSales({ companyId: selectedCompany.id, productType: currentProductType(name) }));
                        }
                    }, text: t('SOTER_TASK.SUBSCRIPTION_EXPIRED.BUTTON_REQUEST') }))))));
};
export default SoterTaskInfoBanner;
