// eslint-disable-next-line import/prefer-default-export
export const emptyStackedChartData = [
  {
    time: '00:00',
    value: 10,
  },
  {
    time: '01:00',
    value: 10,
  },
  {
    time: '02:00',
    value: 10,
  },
  {
    time: '03:00',
    value: 10,
  },
  {
    time: '04:00',
    value: 10,
  },
  {
    time: '05:00',
    value: 10,
  },
  {
    time: '06:00',
    value: 10,
  },
  {
    time: '07:00',
    value: 10,
  },
  {
    time: '08:00',
    value: 10,
  },
  {
    time: '09:00',
    value: 10,
  },
];
