const EMAIL_LENGTH = 320;
export default function emailValidator(t) {
    return (values) => {
        const errors = {};
        if (!(values === null || values === void 0 ? void 0 : values.email)) {
            errors.email = t('LOGIN.REQUIRED', 'Required');
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values === null || values === void 0 ? void 0 : values.email)) {
            errors.email = t('LOGIN.INVALID_LOGIN', 'Invalid email address.');
        }
        else if (values.email.length > EMAIL_LENGTH) {
            errors.email = t('LOGIN.EMAIL_TOO_LONG', 'The email address you\'ve entered is too long.');
        }
        return errors;
    };
}
