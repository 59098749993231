import React from 'react';
import { Rect, Group } from 'react-konva';
import Text from './Text';

const Bar = ({ rectParameters, textParameters }) => {
  const {
    text, size, rotation, fill,
  } = textParameters;
  return (
    <Group>
      {rectParameters.map(({
        x, y, width, height, radius, color,
      }) => (
        <Rect
          key={`rect${x}${y}`}
          x={x}
          y={y}
          cornerRadius={radius}
          width={width}
          height={height}
          fill={color}
        />
      ))}
      {text
        ? (
          <Text
            x={textParameters.x}
            y={textParameters.y}
            text={text}
            fontSize={size}
            rotation={rotation}
            fill={fill}
          />
        ) : null}
    </Group>
  );
};

export default Bar;
