import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import _each from 'lodash/each';

import en from 'constants/i18n/en.json';
import es from 'constants/i18n/es.json';
import fr from 'constants/i18n/fr.json';

const hu = {};

const huifayer = (o, h) => _each(o, (a, b) => {
  if (typeof a === 'string') {
    h[b] = '-ХУЇ-';
  } else {
    h[b] = {};
    huifayer(a, h[b]);
  }
});

// huifayer(en, hu);

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
    },
    returnObjects: true,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,

    keySeparator: '.', // we use content as keys

    interpolation: {
      formatSeparator: ',',
      prefix: '{{',
      suffix: '}}',
    },

    ns: ['translation'],
    defaultNS: 'translation',

    react: {
      useSuspense: true,
    },
  });

export default i18n;
