import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import {
  Group, Layer, Line, Stage, Text as KonvaText,
} from 'react-konva';
import Text from 'lib/ui/Text';
import { COLORS } from 'constants/colors';
import { ReactComponent as Cancel } from 'assets/img/cancel.svg';
import { ReactComponent as Add } from 'assets/img/add.svg';
import { ReactComponent as DownArrow } from 'assets/img/downArrow.svg';
import GradientRect from './GradientRect';
import DeleteModal from './DeleteModal';
import RebaChartRisks from './RebaChartRisks';
import Bar from './Bar';
import RebaChartVideoTracker from './RebaChartVideoTracker';
import RebaWeightsDropdown from './RebaWeightsDropdown';
import DropToggler from '../../../containers/ItemTableContainer/components/DropToggler';
import ActionToggleButton from '../../../lib/ui/ActionToggleButton';

const optionsHolderStyle = (optionsLength) => Emotion.css`
    margin-top: ${optionsLength && '57px'};
  `;

const weightOptionStyle = Emotion.css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RebaChart = ({
  videoId,
  videoWidth,
  points,
  progressPosition,
  rebaWeights,
  setRebaWeights,
  setStoredRebaWeights,
  userActions,
  setUserActions,
  axisData,
  totalSeconds,
  setIsButtonDisabled,
}) => {
  const { t } = useTranslation();
  const [selectedId, selectShape] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOptionTopAndRight, setDeleteOptionTopAndRight] = useState(0);

  const savedRebaWeights = [];
  const { xAxis } = axisData;

  const rebaWeightsLength = rebaWeights?.length;

  const initialStageHeight = 80;
  const timeIndicatorHeight = 40;
  const distance = 40;

  const stageHeight = !rebaWeightsLength
    ? initialStageHeight
    : initialStageHeight + timeIndicatorHeight + rebaWeightsLength * distance;

  useEffect(() => {
    rebaWeights?.forEach((w) => {
      savedRebaWeights.push({
        id: videoId,
        weight: w.id || 0,
        weightType: 'default',
        timeStart: +((totalSeconds / (videoWidth / w.x)) * 1000).toFixed(),
        timeEnd: +(
          (totalSeconds / (videoWidth / (w.x + w.width)))
          * 1000
        ).toFixed(),
      });
    });
    setStoredRebaWeights(savedRebaWeights);
  }, [rebaWeights]);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  return (
    <>
      <div className={optionsHolderStyle(rebaWeightsLength)}>
        {rebaWeights?.map((weight) => (
          <div key={Math.random()} className={weightOptionStyle}>
            <Text text={weight?.name} />
            <div
              id={weight?.id}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteRowId(weight?.y);
                setShowDeleteModal(true);
                setDeleteOptionTopAndRight([
                  e.currentTarget.offsetTop,
                  e.currentTarget.offsetLeft + e.currentTarget.offsetWidth,
                ]);
              }}
              style={{ marginLeft: '6px' }}
            >
              <Cancel />
            </div>
          </div>
        ))}
        {showDeleteModal && (
          <DeleteModal
            actions={userActions}
            deleteRowId={deleteRowId}
            deleteOptionTopAndRight={deleteOptionTopAndRight}
            setUserActions={setUserActions}
            rebaWeights={rebaWeights}
            setRebaWeights={setRebaWeights}
            setShowDeleteModal={setShowDeleteModal}
            setIsButtonDisabled={setIsButtonDisabled}
          />
        )}
        <div style={{ margin: '23px 0 16px' }}>
          <RebaWeightsDropdown
            rebaWeights={rebaWeights}
            setRebaWeights={setRebaWeights}
            videoWidth={videoWidth}
            userActions={userActions}
            setUserActions={setUserActions}
            setIsButtonDisabled={setIsButtonDisabled}
          >
            <DropToggler
              id="reba"
              content={t('SOTER_TASK.ASSESSMENT.REBA.CHART.ADD')}
              togglerButton={(
                <ActionToggleButton
                  content={t('SOTER_TASK.ASSESSMENT.REBA.CHART.ADD')}
                  appearance="main"
                  size="xs"
                  iconBeforeText={<Add />}
                  iconAfterText={<DownArrow />}
                  style={{ borderRadius: '2px' }}
                />
              )}
              direction="down"
            />
          </RebaWeightsDropdown>
        </div>
        <Text
          text={t('SOTER_TASK.ASSESSMENT.REBA.CHART.VIDEO_PARTS_RISK')}
          style={{ color: COLORS.GRAY[300] }}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <Stage
          width={videoWidth}
          height={stageHeight}
          scale={{ x: 1, y: 1 }}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>
            {!!rebaWeightsLength &&
              xAxis.map(({ point, value }) => (
                <Group key={Math.random()}>
                  <Line
                    points={[Math.round(point), 15, Math.round(point), 21.5]}
                    stroke={COLORS.GRAY[300]}
                    strokeWidth={1}
                  />
                  <KonvaText
                    text={value}
                    fontSize={12}
                    x={point + 3}
                    y={12}
                    fill={COLORS.GRAY[300]}
                    strokeWidth={0.5}
                  />
                </Group>
              ))}
            <Bar
              width={videoWidth}
              rebaWeights={rebaWeights}
              setRebaWeights={setRebaWeights}
              userActions={userActions}
              setUserActions={setUserActions}
              selectedId={selectedId}
              selectShape={selectShape}
              checkDeselect={checkDeselect}
              setIsButtonDisabled={setIsButtonDisabled}
            />
            <GradientRect width={videoWidth} rebaWeights={rebaWeights} />
            <RebaChartRisks
              points={points}
              width={videoWidth}
              rebaWeights={rebaWeights}
            />
            <RebaChartVideoTracker progressPosition={progressPosition} stageHeight={stageHeight} />
          </Layer>
        </Stage>
      </div>
      <div />
    </>
  );
};

export default RebaChart;
