import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';
import { SINGLE_PAGE_TYPE } from '../../../containers/PdfContainer/constants';

export const horizontalPageLayout = Emotion.css`
   {
    width: 297mm;
    height: 210mm;
    padding: 15mm;
    position: relative;
  }
`;

export const verticalPageLayout = Emotion.css`
   {
    height: 297mm;
    width: 210mm;
    padding: 15mm;
    position: relative;
  }
`;

export const overviewHeader = Emotion.css`
   {
    display: flex;
    height: 70mm;
    border-bottom: 1px solid ${COLORS.GRAY[100]};
  }
`;

export const header = Emotion.css`
   {
    display: grid;
    grid-template-areas: 'name improvement-1 improvement-2' \n'title improvement-1 improvement-2' \n'program achievements achievements';
    grid-template-columns: 179mm 49mm 49mm;
    grid-template-rows: 17mm 14mm 18mm;
    border-bottom: 1px solid ${COLORS.GRAY[100]};
  }
`;

export const name = Emotion.css`
   {
    color: ${COLORS.GRAY[950]};
    font-size: 22pt;
    grid-area: name;
  }
`;

export const title = Emotion.css`
   {
    color: ${COLORS.GRAY[400]};
    font-size: 14pt;
    grid-area: title;
    margin-bottom: 8mm;
    span {
      font-size: 10pt;
    }
  }
`;

export const program = Emotion.css`
   {
    display: flex;
    color: ${COLORS.GRAY[400]};
    font-size: 12pt;
    grid-area: program;
    margin-bottom: 6mm;
    span {
      font-size: 18pt;
      color: ${COLORS.GRAY[950]};
    }
  }
`;

export const riskGroupArea = Emotion.css`
  font-size: 12pt !important;
  margin-left: 7mm;
`;

export const riskLetter = (color) => Emotion.css`
  color: ${color};
  border: 1px solid ${color};
  border-radius: 1mm;
  width: 6mm;
  height: 6mm;
  text-align: center;
`;

export const achievementsStyle = Emotion.css`
   {
    display: flex;
    grid-area: achievements;
    width: 98mm;
  }
`;

export const coachAchievementSlot = Emotion.css`
   {
    margin-right: 14mm;
    color: ${COLORS.GRAY[400]};
    font-size: 10pt;
    position: relative;
  }
`;

export const achievementsBigLetter = Emotion.css`
   {
    color: ${COLORS.GRAY[950]} !important;
    font-size: 24pt !important;
    display: inline;
  }
`;

export const chartHeader = Emotion.css`
   {
    color: ${COLORS.GRAY[400]};
    font-size: 10pt;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const legend = Emotion.css`
   {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 7pt;
    margin-top: 4mm;
  }
`;

export const legendRect = (color) => Emotion.css`
   {
    width: 4mm;
    height: 4mm;
    background-color: ${color};
    display: inline-block;
    margin-right: 2mm;
  }
`;

export const dateLabel = (type) => Emotion.css`
   {
    position: absolute;
    top: ${type === SINGLE_PAGE_TYPE.HORIZONTAL ? '193mm' : '284mm'};
    font-size: 10pt;
  }
`;
