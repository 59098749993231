import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { outlineColoredButton } from '../../assets/soter-flex-styles';
import { modalOverflow } from '../../assets/jss/style';
import {
  currentDashboardUserIdSelector,
  dashboardUsersSelector,
  inviteLinkSelector,
  isLoadingSelector,
} from '../users/dashboardUsersStore/selectors';
import { selectedCompanyIdSelector } from '../companiesStore/selectors';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import DoubleLineCell from '../../containers/ItemTableContainer/components/CustomCells/DoubleLineCell';
import BooleanCell from '../../containers/ItemTableContainer/components/CustomCells/BooleanCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import {
  createDashboardUser,
  deleteDashboardUser,
  editDashboardUser,
  getAdminUsers,
  getInviteLink,
  getMyCompanyUsers,
  resendInviteLink,
  resetDashboardUsers,
  resetPassword,
  sendInvite,
  setCurrentDashboardUserId,
  setCurrentDashbordUserIsOnlyItem,
  setInviteLink,
} from '../users/dashboardUsersStore/actions';
import { getCompaniesWithDepartments } from '../companiesStore/actions';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import ActionToggleButton from '../../lib/ui/ActionToggleButton';
import AddNewDashboardUserDialog from '../../admin/users/AddNewDashboardUserDialog/AddNewDashboardUserDialog';
import InviteLinkDialog from '../../admin/users/InviteLinkDialog';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import CompaniesWithAccessDialog from '../../admin/users/CompaniesWithAccessDialog/CompaniesWithAccessDialog';
import ResetPasswordDialog from '../../admin/users/ResetPasswordDialog';

const addNewUserModal = Emotion.css`
  ${modalOverflow};
`;

const getActionButtonText = (type, t) => {
  switch (type) {
    case 'adminUserList':
      return t('DASHBOARD_USER.CREATE_NEW_USER', 'Create new user');
    case 'myCompany':
      return t('DASHBOARD_USER.INVITE_USER', 'Invite user');
    default:
      return t('DASHBOARD_USER.INVITE_USER', 'Invite user');
  }
};

const DashboardUsersTable = ({ type }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { dashboardUsers: selectedDashboardUsers } = useAppSelector(
    (state) => state.selectedItems,
  );
  const { companiesWithDepartments: companies } = useAppSelector(
    (state) => state.companies,
  );
  const { email: userEmail } = useAppSelector(
    (state) => state.auth.profile,
  );
  const isLoading = useAppSelector(isLoadingSelector);
  const users = useAppSelector(dashboardUsersSelector);
  const currentCompanyId = useAppSelector(selectedCompanyIdSelector);

  const { openedModal, modalID } = useAppSelector((state) => state.modals);

  const inviteLink = useAppSelector(inviteLinkSelector);

  const [editedUser, setEditedUser] = useState(null);
  const [showEvents, setShowEvents] = useState(!!localStorage.getItem('showEvents'));

  const currentUserId = useAppSelector(currentDashboardUserIdSelector);
  const currentUserObject = users.find((user) => user.id === currentUserId);

  const userType
    = type === 'adminUserList'
      ? TableType.dashboardUsers
      : TableType.myCompanyUsers;
  const isMycompanyUser = userType === TableType.myCompanyUsers;

  const columns = (getActionsForUsers) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.dashboardUsers),
    null,
    getActionsForUsers,
    userType,
    t,
  );

  const getCreatorNameString = (rowData) => {
    if (!rowData.createdByFirstName || !rowData.createdByLastName) {
      return undefined;
    }
    return `${rowData.createdByFirstName} ${rowData.createdByLastName}`;
  };

  const cellsByUserType = {
    [TableType.dashboardUsers]: {
      id: ({ rowData }) => <div>{rowData.id}</div>,
      name: DoubleLineCell,
      access: DoubleLineCell,
      siteAccess: DoubleLineCell,
      isAdmin: BooleanCell,
      isPartner: BooleanCell,
      isSubscriptionManager: BooleanCell,
      createdBy: ({ rowData }) => (
        <div>{getCreatorNameString(rowData) || '–'}</div>
      ),
      lastSeen: DoubleLineCell,
      actions: ActionCell,
    },
    [TableType.myCompanyUsers]: {
      id: ({ rowData }) => <div>{rowData.id}</div>,
      name: DoubleLineCell,
      access: DoubleLineCell,
      siteAccess: DoubleLineCell,
      canInviteUsers: BooleanCell,
      receiveNeedHelpRequests: BooleanCell,
      createdBy: ({ rowData }) => (
        <div>{getCreatorNameString(rowData) || '–'}</div>
      ),
      // lastSeen: DoubleLineCell,
      actions: ActionCell,
    },
  };

  const togglerButton = (
    <ActionToggleButton
      name="multi"
      dataName="multi-user-dropdown"
      className={outlineColoredButton('#248BF2', 'small')}
    />
  );

  useEffect(() => {
    if (currentCompanyId && userType !== TableType.dashboardUsers) {
      dispatch(getMyCompanyUsers(currentCompanyId));
    }
    if (userType === TableType.dashboardUsers) {
      dispatch(getAdminUsers());
    }
    dispatch(getCompaniesWithDepartments());
    return () => dispatch(resetDashboardUsers());
  }, [currentCompanyId]);

  const actions = [
    {
      title: t('DASHBOARD_USER.EDIT', 'Edit'),
      handler: (user) => {
        setEditedUser(user);
        if (type === 'adminUserList') {
          dispatch(setOpenedModal('editUser'));
        } else {
          dispatch(setOpenedModal('myCompanyEditUser'));
        }
      },
    },
    {
      title: t('DASHBOARD_USER.RESET_PASSWORD', 'Reset password'),
      handler: ({ id }) => {
        dispatch(setCurrentDashboardUserId(id));
        dispatch(setOpenedModal('resetPassword'));
      },
    },
    {
      title: t('DASHBOARD_USER.RESEND_INVITE', 'Resend invite'),
      handler: ({ id }) => {
        dispatch(setCurrentDashboardUserId(id));
        dispatch(getInviteLink(id, isMycompanyUser));
        dispatch(sendInvite(id, isMycompanyUser));
        dispatch(setOpenedModal('resendInviteLink'));
      },
    },
    {
      title: t('DASHBOARD_USER.DELETE_USER', 'Delete user'),
      handler: ({ id }, { isTheOnlyVisibleItem }) => {
        dispatch(setCurrentDashboardUserId(id));
        dispatch(setCurrentDashbordUserIsOnlyItem(isTheOnlyVisibleItem));
        dispatch(setOpenedModal('deleteUser'));
      },
      // attention: true,
    },
  ];

  const userActions = [
    {
      id: 'setCompany',
      name: t('MY_COMPANY.USERS.CHANGE_USER'),
      color: COLORS.GRAY[400],
      size: 'small',
      child: <div />,
      disabled: true,
    },
  ];

  const filterButtonHandler = () => {
    if (type === 'adminUserList') {
      dispatch(setOpenedModal('addNewUser'));
    } else {
      dispatch(setOpenedModal('inviteNewUser'));
    }
  };

  const filterButtonProps = {
    text: getActionButtonText(type, t),
    action: filterButtonHandler,
  };

  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
    dispatch(setInviteLink(null));
  };

  const handleResendInviteLink = () => {
    dispatch(resendInviteLink(currentUserId, isMycompanyUser));
  };

  const handleResetPassword = () => {
    dispatch(resetPassword(currentUserId, isMycompanyUser));
  };

  const switchModal = (modalType) => {
    switch (modalType) {
      case 'addNewUser':
        return (
          <AddNewDashboardUserDialog
            type="addNewUser"
            companies={companies}
            onSubmit={(data) => dispatch(createDashboardUser(data))}
          />
        );
      case 'inviteNewUser':
        return (
          <AddNewDashboardUserDialog
            type="inviteNewUser"
            companies={companies}
            onSubmit={(data) => dispatch(createDashboardUser(data))}
          />
        );
      case 'copyInviteLink':
        return (
          <InviteLinkDialog
            link={inviteLink || ''}
            email={currentUserObject?.email}
          />
        );
      case 'resendInviteLink':
        return (
          <InviteLinkDialog
            isResendLink
            link={inviteLink || ''}
            email={currentUserObject?.email}
            onSendInvite={handleResendInviteLink}
          />
        );
      case 'resetPassword':
        return (
          <ResetPasswordDialog
            name={`${currentUserObject?.firstName} ${currentUserObject?.lastName}`}
            onSubmit={handleResetPassword}
          />
        );
      case 'editUser':
        return (
          <AddNewDashboardUserDialog
            type="editUser"
            editedUser={editedUser}
            companies={companies}
            userEmail={userEmail}
            showEvents={showEvents}
            setShowEvents={setShowEvents}
            onSubmit={(data, events) => {
              if (events) {
                localStorage.setItem('showEvents', events);
              } else {
                localStorage.removeItem('showEvents');
              }
              dispatch(editDashboardUser(data));
            }}
          />
        );
      case 'myCompanyEditUser':
        return (
          <AddNewDashboardUserDialog
            type="myCompanyEditUser"
            editedUser={editedUser}
            companies={companies}
            onSubmit={(data) => dispatch(editDashboardUser(data))}
          />
        );
      case 'deleteUser':
        return (
          <DeleteEntityDialog
            confirmText={t('MY_COMPANY.USERS.ARE_SURE')}
            buttonText={t('MY_COMPANY.USERS.DELETE_USER')}
            onSubmit={() => dispatch(deleteDashboardUser())}
          />
        );
      case 'showCompaniesWithAccess':
        return (
          <CompaniesWithAccessDialog
            companies={users.find((user) => user.id === modalID).companiesList}
          />
        );
      case 'showCompaniesWithSiteAccess':
        return (
          <CompaniesWithAccessDialog
            companies={users.find((user) => user.id === modalID).companiesList}
            forSite
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <ItemTableContainer
        items={users}
        selectedItems={selectedDashboardUsers}
        columns={columns}
        cells={cellsByUserType[userType]}
        type={userType}
        isLoading={isLoading}
        actions={actions}
        editedItem={editedUser}
        openedModal={openedModal}
        setOpenedModal={setOpenedModal}
        itemActions={userActions}
        togglerButton={togglerButton}
        filterButtonProps={filterButtonProps}
        switchModal={switchModal}
        modalClass={addNewUserModal}
        keepModalOnClickOutside
        onCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default DashboardUsersTable;
