import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import wearingTypeEnum from 'constants/wearingTypesEnum';
import { card, coloredButton, flexColumn, flexRow, inputLabel, mb3, mt2, } from '../../assets/soter-flex-styles';
import Button from '../../lib/ui/Button';
import { ClipgoConfigureDeviceMethod, ClipgoInputLoginMethod, HubType } from '../../init/proto_client';
import useFocus from '../../lib/utils/useFocus';
import { useAppSelector } from '../../lib/utils/useStore';
import { getSimpleSelectOptions } from '../../lib/utils/getSimpleSelectOptions';
import CustomInput from '../../lib/ui/CustomInput';
import CustomSelect from '../../lib/ui/CustomSelect';
import SwitchableCard from './SwitchableCard';
import CustomCheckbox from '../../lib/ui/CustomCheckbox/CustomCheckbox';
import { sortByLabel } from '../companies/company/utils/createCompanyFilters';
import DeviceTypeRadio from '../components/DeviceTypeRadio';
import TeamMemberIdRadio from '../components/TeamMemeberIdRadio';

const CreateHubDialog = ({ onSubmit, companies = [], hub }) => {
  const { t } = useTranslation();
  const [inputRef, setInputFocus] = useFocus();
  const { availableCoachingProgramList } = useAppSelector((state) => state.adminCompanies);
  const [hubData, setHubData] = useState({
    alias: '',
    companyId: null,
    hubType: HubType.HUB_TYPE_SOTER_COACH_HUB,
    employeeLoginTypeEnable: false,
    employeeLoginType: '',
    inputLoginMethodEnable: false,
    inputLoginMethod: ClipgoInputLoginMethod.NONE,
    downloadDataNotOnlyFromDevicesOnChargeEnable: false,
    downloadDataNotOnlyFromDevicesOnCharge: false,
    configureDeviceMethod: ClipgoConfigureDeviceMethod.NONE,
    configureDeviceMethodEnable: false,
    hubsCoachingProgramsEnable: false,
    hubsCoachingProgramsList: [],
    typeWearingEnable: false,
  });
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState({
    [wearingTypeEnum.SPINE]: false,
    [wearingTypeEnum.SHOULDER]: false,
    [wearingTypeEnum.HELMET]: false,
    [wearingTypeEnum.HEADSET]: false,
  });
  const availableCoachingPrograms = getSimpleSelectOptions(
    availableCoachingProgramList.filter(
      ({ deviceType }) => (deviceType !== 'armband' && selectedDeviceTypes.spine)
        || (deviceType === 'armband' && selectedDeviceTypes.shoulder)
        || selectedDeviceTypes[deviceType],
    ),
  );
  useEffect(() => {
    setTimeout(() => setInputFocus(), 0);
  }, []);
  useEffect(() => {
    if (hub) {
      const {
        alias,
        companyId,
        hubType,
        employeeLoginTypeEnable,
        employeeLoginType,
        inputLoginMethodEnable,
        inputLoginMethod,
        downloadDataNotOnlyFromDevicesOnChargeEnable,
        downloadDataNotOnlyFromDevicesOnCharge,
        configureDeviceMethod,
        configureDeviceMethodEnable,
        hubsCoachingProgramsEnable,
        hubsCoachingProgramsList,
        typeWearingEnable,
        typeWearing,
      } = hub;
      setHubData({
        alias,
        companyId,
        hubType,
        employeeLoginTypeEnable,
        employeeLoginType,
        inputLoginMethodEnable,
        inputLoginMethod,
        downloadDataNotOnlyFromDevicesOnChargeEnable,
        downloadDataNotOnlyFromDevicesOnCharge,
        configureDeviceMethodEnable,
        configureDeviceMethod,
        hubsCoachingProgramsList,
        hubsCoachingProgramsEnable,
        typeWearingEnable,
      });
      if (typeWearing) setSelectedDeviceTypes(typeWearing);
    }
  }, [hub]);
  const companiesForSelect = companies.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const hubTypesForSelect = [
    {
      value: HubType.HUB_TYPE_SOTER_COACH_HUB,
      label: 'SoterCoach Hub',
    },
    {
      value: HubType.HUB_TYPE_DATA_COLLECTION_HUB,
      label: 'DCH',
    },
  ];
  const inputChangeHandler = (name, value) => setHubData({ ...hubData, [name]: value.value ?? value });
  const changeHandlerWithName = (name) => (value) => {
    inputChangeHandler(name, value);
  };
  const changeDeviceTypesHandler = (name, value) => setSelectedDeviceTypes({ ...selectedDeviceTypes, [name]: value });
  if (availableCoachingProgramList?.length === 0) return <Loader />;
  return (
    <div>
      <h5 style={{ marginBottom: '7px' }}>
        {`${
          hub ? 'Edit' : 'Create'
        } hub`}

      </h5>
      <div
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          maxHeight: `${window.innerHeight - 149}px`,
          marginRight: '4px',
        }}
      >
        <div className={card} style={{ padding: '12px', marginBottom: '26px' }}>
          <label className={inputLabel} htmlFor="alias">
            Alias
            <CustomInput
              className={`${mt2}`}
              inputRef={inputRef}
              value={hubData.alias}
              onChange={inputChangeHandler}
              type="text"
              id="alias"
              name="alias"
            />
          </label>
          <div className={flexRow}>
            <div className={flexColumn}>
              <label className={inputLabel} htmlFor="companyId">
                Company
                <CustomSelect
                  value={companiesForSelect.find(
                    ({ value }) => value === hubData.companyId,
                  )}
                  className={`${mt2}`}
                  options={companiesForSelect}
                  onChange={inputChangeHandler}
                  id="companyId"
                  name="companyId"
                />
              </label>
            </div>
            <div className={flexColumn}>
              <label className={inputLabel} htmlFor="hubType">
                Hub type
                <CustomSelect
                  className={`${mt2} ${mb3}`}
                  id="hubType"
                  name="hubType"
                  onChange={inputChangeHandler}
                  options={hubTypesForSelect}
                  value={hubTypesForSelect.find(
                    ({ value }) => value === hubData.hubType,
                  )}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={flexRow} style={{ marginBottom: '26px' }}>
          <div className={flexColumn}>
            <SwitchableCard
              isActive={
                hubData.hubsCoachingProgramsEnable && hubData.typeWearingEnable
              }
              setIsActive={(value) => {
                setHubData({
                  ...hubData,
                  hubsCoachingProgramsEnable: value,
                  typeWearingEnable: value,
                });
              }}
            >
              <label style={{ marginBottom: '.5rem' }} className={inputLabel}>
                Device wearing type
              </label>
              <div className={flexRow}>
                <div className={flexColumn}>
                  <CustomCheckbox
                    label={
                      wearingTypeEnum.SPINE[0].toUpperCase()
                      + wearingTypeEnum.SPINE.substring(1)
                    }
                    name={wearingTypeEnum.SPINE}
                    onChange={changeDeviceTypesHandler}
                    checked={selectedDeviceTypes[wearingTypeEnum.SPINE]}
                  />
                  <CustomCheckbox
                    label={
                      wearingTypeEnum.SHOULDER[0].toUpperCase()
                      + wearingTypeEnum.SHOULDER.substring(1)
                    }
                    name={wearingTypeEnum.SHOULDER}
                    onChange={changeDeviceTypesHandler}
                    checked={selectedDeviceTypes[wearingTypeEnum.SHOULDER]}
                  />
                </div>
                <div className={flexColumn}>
                  <CustomCheckbox
                    label={
                      wearingTypeEnum.HEADSET[0].toUpperCase()
                      + wearingTypeEnum.HEADSET.substring(1)
                    }
                    name={wearingTypeEnum.HEADSET}
                    onChange={changeDeviceTypesHandler}
                    checked={selectedDeviceTypes[wearingTypeEnum.HEADSET]}
                  />
                  <CustomCheckbox
                    label={
                      wearingTypeEnum.HELMET[0].toUpperCase()
                      + wearingTypeEnum.HELMET.substring(1)
                    }
                    name={wearingTypeEnum.HELMET}
                    onChange={changeDeviceTypesHandler}
                    checked={selectedDeviceTypes[wearingTypeEnum.HELMET]}
                  />
                </div>
              </div>
              <label
                style={{ marginBottom: '.5rem' }}
                className={inputLabel}
                htmlFor="hubsCoachingProgramsList"
              >
                Selected coaching programs
              </label>
              <CustomSelect
                name="hubsCoachingProgramsList"
                options={sortByLabel(availableCoachingPrograms)}
                isMulti
                isSearchable
                isClearable
                onChange={inputChangeHandler}
                value={hubData.hubsCoachingProgramsList}
              />
            </SwitchableCard>
          </div>
          <div className={flexColumn}>
            <SwitchableCard
              isActive={hubData.configureDeviceMethodEnable}
              setIsActive={changeHandlerWithName('configureDeviceMethodEnable')}
              height="197px"
            >
              <DeviceTypeRadio
                configureMethod={hubData.configureDeviceMethod}
                onChange={changeHandlerWithName('configureDeviceMethod')}
                splitHeader
              />
            </SwitchableCard>
          </div>
        </div>
        <div className={flexRow}>
          <div className={flexColumn}>
            <div style={{ marginBottom: '26px' }}>
              <SwitchableCard
                isActive={hubData.employeeLoginTypeEnable}
                setIsActive={changeHandlerWithName('employeeLoginTypeEnable')}
                height="132px"
              >
                <label
                  style={{ marginBottom: '.7rem' }}
                  className={inputLabel}
                  htmlFor="employeeLoginType"
                >
                  Employee login prompt
                </label>
                <CustomInput
                  onChange={inputChangeHandler}
                  value={hubData.employeeLoginType}
                  id="employeeLoginType"
                  name="employeeLoginType"
                  placeholder="Enter your Team Member ID"
                />
              </SwitchableCard>
            </div>
            <SwitchableCard
              isActive={hubData.downloadDataNotOnlyFromDevicesOnChargeEnable}
              setIsActive={changeHandlerWithName(
                'downloadDataNotOnlyFromDevicesOnChargeEnable',
              )}
              height="131px"
            >
              <div className={mt2}>
                <CustomCheckbox
                  label="Tick to download data from ALL devices - not only from devices on charge"
                  name="downloadDataNotOnlyFromDevicesOnCharge"
                  onChange={inputChangeHandler}
                  value={hubData.downloadDataNotOnlyFromDevicesOnCharge}
                />
              </div>
            </SwitchableCard>
          </div>
          <div className={flexColumn}>
            <SwitchableCard
              isActive={hubData.inputLoginMethodEnable}
              setIsActive={changeHandlerWithName('inputLoginMethodEnable')}
            >
              <TeamMemberIdRadio
                loginMethod={hubData.inputLoginMethod}
                onChange={changeHandlerWithName('inputLoginMethod')}
                splitHeader
              />
            </SwitchableCard>
          </div>
        </div>
      </div>
      <Button
        text={t('ADMIN.SAVE')}
        style={{ marginTop: '26px', display: 'block', width: '100%' }}
        className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
        disabled={!hubData.alias}
        handler={() => onSubmit({ ...hubData, typeWearing: selectedDeviceTypes })}
      />
    </div>
  );
};

export default CreateHubDialog;
