import { createAction, createSlice } from '@reduxjs/toolkit';
import { CATEGORY, PENDING, REJECTED, } from './categoryTypes';
import { createCategory, createEntry, deleteCategory, deleteEntry, getCategories, } from './actions';
const initialState = {
    categories: [],
    currentCategory: {
        id: null,
        title: null,
        main: false,
        tagsList: [],
        color: '',
    },
    isLoading: false,
    error: null,
    message: null,
    colors: [
        {
            main: '#248BF2',
            background: '#248BF220',
            counter: 0,
        },
        {
            main: '#FF3399',
            background: '#FF339920',
            counter: 0,
        },
        {
            main: '#1045CC',
            background: '#1045CC20',
            counter: 0,
        },
        {
            main: '#F14B75',
            background: '#F14B7520',
            counter: 0,
        },
        {
            main: '#61B821',
            background: '#61B82120',
            counter: 0,
        },
        {
            main: '#A25ABD',
            background: '#A25ABD20',
            counter: 0,
        },
        {
            main: '#FFD786',
            background: '#FFD78620',
            counter: 0,
        },
        {
            main: '#F6650A',
            background: '#F6650A20',
            counter: 0,
        },
    ],
};
const pending = createAction(CATEGORY + PENDING);
const rejected = createAction(CATEGORY + REJECTED);
const CategorySlice = createSlice({
    name: CATEGORY,
    initialState,
    reducers: {
        setCurrentCategory(state, action) {
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            state.currentCategory = state.categories.filter((({ id, title }) => (id === action.payload.id && title === action.payload.title)))[0];
        },
        changeEntryName(state, action) {
            var _a, _b;
            const editedEntry = (_a = state.currentCategory) === null || _a === void 0 ? void 0 : _a.tagsList.find((tag) => tag.id === action.payload.id);
            const index = (_b = state.currentCategory) === null || _b === void 0 ? void 0 : _b.tagsList.indexOf(editedEntry);
            state.currentCategory.tagsList[index].title = action.payload.title;
        },
        removeEntry(state, action) {
            var _a;
            state.currentCategory.tagsList = (_a = state.currentCategory) === null || _a === void 0 ? void 0 : _a.tagsList.filter(({ id }) => id !== action.payload);
        },
        setColorCounter(state, action) {
            const { colorCounter, colorIndex } = action.payload;
            state.colors[colorIndex].counter = colorCounter;
            state.colors = state.colors.sort((a, b) => a.counter - b.counter);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            if (action.payload) {
                state.isLoading = false;
                state.categories = action.payload;
            }
        });
        builder.addCase(createCategory.fulfilled, (state, action) => {
            if (action.payload) {
                state.isLoading = false;
            }
        });
        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            if (action.payload) {
                state.isLoading = false;
                state.categories = state.categories.filter(({ id }) => id !== action.payload);
            }
        });
        builder.addCase(createEntry.fulfilled, (state, action) => {
            if (action.payload) {
                state.isLoading = false;
            }
        });
        builder.addCase(deleteEntry.fulfilled, (state, action) => {
            if (action.payload) {
                state.isLoading = false;
                state.currentCategory.tagsList = state.currentCategory.tagsList.filter(({ id }) => id !== action.payload);
            }
        });
    },
});
export default CategorySlice.reducer;
export const { setCurrentCategory, changeEntryName, removeEntry, setColorCounter, } = CategorySlice.actions;
