export const GET_TASKS_BY_WORKSESSION_SUCCESS = 'GET_TASKS_BY_WORKSESSION_SUCCESS';
export const SET_TASKS_ERROR = 'SET_TASKS_ERROR';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const GET_TASK_TITLES_SUCCESS = 'GET_TASK_TITLES_SUCCESS';
export const ADD_TASK_TITLE_SUCCESS = 'ADD_TASK_TITLE_SUCCESS';
export const SET_EDITED_TASK = 'SET_EDITED_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const REMOVE_TASK_SUCCESS = 'REMOVE_TASK_SUCCESS';
export const SET_TASKS_ARE_LOADING = 'SET_TASKS_ARE_LOADING';
export const SET_START_TIME = 'SET_START_TIME';
