import React, { useEffect } from 'react';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { contactSalesProductType } from 'init/proto_client';
import legendSquare from 'assets/jss/components/legendSquare';
import { modalOverflow, text } from 'assets/jss/style';
import { card, container } from 'assets/soter-flex-styles';
import { setOpenedModal } from 'containers/ModalContainer/modalsStore/actions';
import CustomTooltip from '../../lib/ui/Tooltip';
import sliceLongString from '../../lib/utils/sliceLongString';
import Text from '../../lib/ui/Text';
import useNumberOfTrialDaysLeftBanner from './useNumberOfTrialDaysLeftBanner';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { openedModalSelector } from '../../containers/ModalContainer/modalsStore/selectors';
import { getTaskOverviewData, resetTaskOverview } from './overviewStore/actions';
import Loader from '../../lib/ui/Loader';
import StickyOnScrollContainer from '../../containers/ItemTableContainer/components/StickyOnScrollContainer';
import ModalWrapper from '../../containers/ModalContainer/ModalWrapper';
import UpgradeDialog from '../components/UpgradeDialog';
import ContactUsDialog from '../../lib/ui/ContactUsDialog';
import { contactSales } from '../../auth/store/actions';
import DonutChart from '../video/components/DonutChart';

const overviewGrid = Emotion.css`
  display: grid;
  grid-template-areas:
    'videoRisk cards cards'
    'videoRisk comparisonRisk comparisonRisk'
    'videoRisk comparisonRisk comparisonRisk';
  grid-gap: 25px;
`;

const dataCard = (dataColor) => Emotion.css`
  ${card};
  width: 174px;
  height: 129px;
  text-align: center;
  padding: 10px;

  div {
    ${text(dataColor, 32, 600)};
  }
  span {
    ${text(COLORS.GRAY[400], 15)};
  }
`;

const videoChartCard = Emotion.css`
  ${card};
  width: 374px;
  min-height: 450px;
  padding: 20px;
  grid-area: videoRisk;
`;

const comparisonChartCard = Emotion.css`
  ${card};

  grid-area: comparisonRisk;
  width: 779px;
  height: 304px;
  padding: 20px;
`;

const sliceAndAddTooltip = (title) => (title.length > 40 ? (
  <CustomTooltip placement="bottomLeft" text={title} noTranslation>
    <span style={{ fontSize: '14px' }}>{sliceLongString(title, 40)}</span>
  </CustomTooltip>
) : (
  <span style={{ fontSize: '14px' }}>{title}</span>
));

const DataCard = ({ data, title, dataColor = COLORS.GRAY[950] }) => (
  <div className={dataCard(data ? dataColor : COLORS.GRAY[400])}>
    <div>{data || 0}</div>
    {sliceAndAddTooltip(title)}
  </div>
);

const RiskLegend = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div style={{ minWidth: '150px' }} className={text(COLORS.GRAY[950], 14)}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '9px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={legendSquare(data ? COLORS.GREEN[600] : COLORS.GRAY[100])} />
          <Text
            text={t('WEARABLES.ACTIVITY.FILTERS.LOWER')}
            appearance="footnote"
            style={{ color: 'inherit' }}
          />
        </div>
        <Text
          text={`${Math.round(data?.low) || 0}%`}
          appearance="footnote"
          style={{ color: 'inherit', marginLeft: '8px' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '9px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={legendSquare(data ? COLORS.ORANGE[600] : COLORS.GRAY[100])} />
          <Text
            text={t('WEARABLES.ACTIVITY.FILTERS.MEDIUM')}
            appearance="footnote"
            style={{ color: 'inherit' }}
          />
        </div>
        <Text
          text={`${Math.round(data?.medium) || 0}%`}
          appearance="footnote"
          style={{ color: 'inherit', marginLeft: '8px' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '9px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={legendSquare(data ? COLORS.RED[600] : COLORS.GRAY[100])} />
          <Text
            text={t('WEARABLES.ACTIVITY.FILTERS.HIGHER')}
            appearance="footnote"
            style={{ color: 'inherit' }}
          />
        </div>
        <Text
          text={`${Math.round(data?.high) || 0}%`}
          appearance="footnote"
          style={{ color: 'inherit', marginLeft: '8px' }}
        />
      </div>
    </div>
  );
};

const SoterTaskOverviewContainer = () => {
  // func for upgrade button
  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('geniusProduct');
  const { id: userId } = useAppSelector((state) => state.auth.profile);
  const openedModal = useAppSelector(openedModalSelector);
  const modalClass = modalOverflow;
  const overlayClass = Emotion.css`
    margin-top: 119px;
  `;
  const withCloseButton = true;
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useAppSelector((state) => state.companies.selectedCompany);
  const { overviewData, isLoading } = useAppSelector(
    (state) => state.soterTaskOverview,
  );
  const {
    videoCount,
    assessmentCount,
    comparisonCount,
    averageComparisonImprovement,
    mediumRiskVideoCount,
    lowRiskVideoCount,
    highRiskVideoCount,
    comparisonLowRiskBeforeCount,
    comparisonMediumRiskBeforeCount,
    comparisonHighRiskBeforeCount,
    comparisonLowRiskAfterCount,
    comparisonMediumRiskAfterCount,
    comparisonHighRiskAfterCount,
  } = overviewData;
  const prepareRiskDataForChart = (low, medium, high) => {
    const oneDegree = 360 / (low + medium + high);
    if (!low && !medium && !high) return [{ fill: COLORS.GRAY[100], angle: 360 }];
    return [
      { fill: COLORS.GREEN[600], angle: low * oneDegree || 0 },
      { fill: COLORS.ORANGE[600], angle: medium * oneDegree || 0 },
      { fill: COLORS.RED[600], angle: high * oneDegree || 0 },
    ];
  };
  const prepareDataForLegend = (low, medium, high) => {
    const onePercent = 100 / (low + medium + high);
    if (!low && !medium && !high) return null;
    return {
      low: low * onePercent,
      medium: medium * onePercent,
      high: high * onePercent,
    };
  };
  const videoChartData = prepareRiskDataForChart(
    lowRiskVideoCount,
    mediumRiskVideoCount,
    highRiskVideoCount,
  );
  const comparisonBeforeChartData = prepareRiskDataForChart(
    comparisonLowRiskBeforeCount,
    comparisonMediumRiskBeforeCount,
    comparisonHighRiskBeforeCount,
  );
  const comparisonAfterChartData = prepareRiskDataForChart(
    comparisonLowRiskAfterCount,
    comparisonMediumRiskAfterCount,
    comparisonHighRiskAfterCount,
  );
  useEffect(() => {
    if (id) dispatch(getTaskOverviewData(id));
    return () => dispatch(resetTaskOverview());
  }, [id]);
  if (isLoading) return <Loader />;

  return (
    <>
      <StickyOnScrollContainer
        topMargin={numberOfTrialDaysLeftBanner ? 0 : 7}
        topBlock={numberOfTrialDaysLeftBanner}
        height={50}
      />
      {isLoading && <Loader />}
      {openedModal === 'upgrade' || openedModal === 'contactSales' ? (
        <ModalWrapper
          closeModal={() => dispatch(setOpenedModal(null))}
          modalClass={modalClass}
          overlayClass={overlayClass}
          keepModalOnClickOutside
          withCloseButton={withCloseButton}
          closeWithoutConfirmation
        >
          {openedModal === 'upgrade' ? (
            <UpgradeDialog
              handleClick={() => {
                dispatch(
                  contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK }),
                );
                dispatch(setOpenedModal('contactSales'));
              }}
              days={selectedCompany.products?.geniusProduct?.currentTermEnd}
            />
          ) : (
            <ContactUsDialog
              handleClose={() => dispatch(setOpenedModal(null))}
            />
          )}
        </ModalWrapper>
      ) : null}
      <div className={container}>
        <Text
          text={t('SOTER_GENIUS.TASK_OVERVIEW')}
          weight='bold'
          appearance='headlineOne'
          style={{margin: '0 0 20px'}}
        />
        <div className={overviewGrid}>
          <div className={videoChartCard}>
            <Text
              text={t('SOTER_TASK.OVERVIEW.RISK_DISTRIBUTION').toUpperCase()}
              className={text(COLORS.GRAY[950], 20)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '30px',
              }}
            >
              <DonutChart
                data={videoChartData}
                width={244}
                height={244}
                innerRadius={102}
                outerRadius={120}
                noData={videoChartData.length === 1}
              />
            </div>
            <RiskLegend
              data={prepareDataForLegend(
                lowRiskVideoCount,
                mediumRiskVideoCount,
                highRiskVideoCount,
              )}
            />
          </div>
          <div
            style={{
              gridArea: 'cards',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DataCard
              title={t('SOTER_TASK.OVERVIEW.VIDEOS_RECORDED')}
              data={videoCount}
            />
            <DataCard
              title={t('SOTER_TASK.OVERVIEW.ASSESSMENT_CREATED')}
              data={assessmentCount}
            />
            <DataCard
              title={t('SOTER_TASK.OVERVIEW.COMPARISONS_CREATED')}
              data={comparisonCount}
            />
            <DataCard
              title={t('SOTER_TASK.OVERVIEW.IMPROVEMENT')}
              dataColor={
                averageComparisonImprovement < 0 ? '#01a793' : COLORS.GRAY[400]
              }
              data={`${Math.abs(averageComparisonImprovement)}%`}
            />
          </div>
          <div className={comparisonChartCard}>
            <Text
              text={t('SOTER_TASK.OVERVIEW.DISTRIBUTION').toUpperCase()}
              className={text(COLORS.GRAY[950], 20)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Text
                  text={t('SOTER_TASK.OVERVIEW.BEFORE').toUpperCase()}
                  className={text(COLORS.GRAY[400], 14)}
                  style={{ marginTop: '40px', marginBottom: '15px' }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '300px',
                    alignItems: 'center',
                  }}
                >
                  <DonutChart
                    width={120}
                    height={120}
                    innerRadius={48}
                    outerRadius={60}
                    data={comparisonBeforeChartData}
                    noData={comparisonBeforeChartData.length === 1}
                  />
                  <RiskLegend
                    data={prepareDataForLegend(
                      comparisonLowRiskBeforeCount,
                      comparisonMediumRiskBeforeCount,
                      comparisonHighRiskBeforeCount,
                    )}
                  />
                </div>
              </div>
              <div style={{ marginRight: '30px' }}>
                <Text
                  text={t('SOTER_TASK.OVERVIEW.AFTER').toUpperCase()}
                  className={text(COLORS.GRAY[400], 14)}
                  style={{ marginTop: '40px', marginBottom: '15px' }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '300px',
                    alignItems: 'center',
                  }}
                >
                  <DonutChart
                    width={120}
                    height={120}
                    innerRadius={48}
                    outerRadius={60}
                    data={comparisonAfterChartData}
                    noData={comparisonAfterChartData.length === 1}
                  />
                  <RiskLegend
                    data={prepareDataForLegend(
                      comparisonLowRiskAfterCount,
                      comparisonMediumRiskAfterCount,
                      comparisonHighRiskAfterCount,
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoterTaskOverviewContainer;
