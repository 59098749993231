import React, { useState } from 'react';
import moment from 'moment';
import * as Emotion from 'emotion';
import ReactPlayer from 'react-player';
import { card } from '../../../../assets/soter-flex-styles';
import { button } from '../../../../assets/jss/style';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';

const absoluteButton = (left) => Emotion.css`
  ${button(COLORS.LIGHT_BLUE[600])};

  position: absolute;
  top: 10px;
  left: ${left}px;
`;

const selectionSlot = Emotion.css`
  width: 574px;
  height: 322px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spanText = Emotion.css`
  color: ${COLORS.GRAY[400]};
  font-size: 16px;
  text-align: center;
`;

const headingText = Emotion.css`
  text-align: center;
  line-height: 2;
`;

const videoContainer = Emotion.css`
  border: 1px dashed #dadada;
`;
const clickArea = Emotion.css`
  cursor: pointer;
`;

const SlotForVideoSelection = ({
  selectedVideo,
  stepNumber,
  clickHandler,
  videoNumber,
}) => {
  const videoWidth = 574;
  const videoHeight = 322;
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 20px 0 0',
      }}
    >
      <div className={`${card} ${selectionSlot} ${videoContainer}`}>
        {selectedVideo ? (
          <div style={{ position: 'relative' }}>
            <ReactPlayer
              height={videoHeight}
              width={videoWidth}
              url={selectedVideo?.amazonStreamUrl}
              playing={isPlaying}
              controls
            />
            <div className={absoluteButton(490)} onClick={clickHandler}>
              {t('SOTER_TASK.COMPARISON.REPLACE')}
            </div>
          </div>
        ) : (
          <div className={clickArea} onClick={clickHandler}>
            <h4 className={headingText}>
              {`${t(
                'SOTER_TASK.COMPARISON.STEP',
              )} ${stepNumber}`}
            </h4>
            <span className={spanText}>
              {`${t(
                'SOTER_TASK.COMPARISON.CLICK_HERE',
              )} ${videoNumber} ${t(
                'SOTER_TASK.COMPARISON.VIDEO_COMPARISON',
              )}`}
            </span>
          </div>
        )}
      </div>
      <div>
        {selectedVideo ? <div>{selectedVideo.title}</div> : ''}
        {selectedVideo ? (
          <div>
            {moment(selectedVideo.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SlotForVideoSelection;
