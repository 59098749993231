import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import * as d3 from 'd3';
import { emptyData, timelineChart } from 'assets/jss/components/timelineChart';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/useWindowSize';
import { escapeHTML } from '../../../../lib/ui/EditableTextCard/escapeHTML';

export function drawChart(el, tasks, timeStart, timeEnd, timezone) {
  const convertDateToStringHhMm = (date) => moment(date).tz(timezone).format('HH:mm');
  if (el.children[0] !== undefined) {
    const child = el.firstChild;
    el.removeChild(child);
  }

  const xAccessor = (d) => d.date;
  const yAccessor = (d) => d.value;

  const dimensions = {
    width: el.offsetWidth,
    height: el.offsetHeight,
    margin: {
      top: 0, right: 0, bottom: 20, left: 0,
    },
  };

  const wrapper = d3.select(el)
    .append('svg')
    .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height + 30}`);
  const bounds = wrapper.append('g');

  // set scales
  const chartStartsAt = timeStart;
  const chartEndsAt = timeEnd;
  const xScale = d3.scaleTime()
    .domain([chartStartsAt, chartEndsAt])
    .range([dimensions.margin.left, dimensions.width - dimensions.margin.right]);
  const yScale = d3.scaleLinear()
    .domain([0, 1])
    .range([dimensions.height - dimensions.margin.bottom, 0]);

  // prepare data
  const productionAreaGenerator = d3.area()
    .x((d) => xScale(xAccessor(d)))
    .y0(dimensions.height - dimensions.margin.bottom)
    .y1((d) => yScale(yAccessor(d)))
    .curve(d3.curveStepAfter);

  const timeScale = d3.scaleTime()
    .domain([new Date(chartStartsAt), new Date(chartEndsAt)])
    .range([dimensions.margin.left, dimensions.width - dimensions.margin.right]);
  bounds.append('g')
    .attr('transform', `translate(0,${dimensions.height - dimensions.margin.bottom})`)
    .call(d3.axisBottom(timeScale).tickFormat((d) => convertDateToStringHhMm(d)))
    .attr('class', 'timeAxis');

  const tooltip = d3.select('#page_layer').append('div')
    .attr('id', 'timelineTooltip')
    .attr('class', 'tooltip')
    .style('opacity', 0);

  const showTooltip = (name, start, end, event) => {
    const tooltipHtml = `
      <div>${escapeHTML(name)}</div><div>${convertDateToStringHhMm(start)} - ${convertDateToStringHhMm(end)}</div>
    `;
    tooltip.transition()
      .duration(200)
      .style('opacity', 0.9);
    tooltip.html(tooltipHtml)
      .style('left', `${event.pageX}px`)
      .style('top', `${event.pageY}px`);
  };

  tasks?.forEach((data) => {
    bounds.append('path')
      .attr('d', productionAreaGenerator(data))
      .attr('fill', '#1045CC')
      .attr('stroke', '#fff')
      .attr('class', 'task')
      .on('mouseover', (event) => {
        showTooltip(data[0].name, data[0].date, data[1].date, event);
      })
      .on('mouseleave', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });
  });
}

const TimelineChart = ({
  data, timeStart, timeEnd, timezone,
}) => {
  const windowSize = useWindowSize();
  const timelineChartRef = useRef(null);

  useEffect(() => {
    drawChart(timelineChartRef.current, data, timeStart, timeEnd, timezone);
  }, [data, windowSize]);
  const { t } = useTranslation();
  return (
    <div style={{ position: 'relative' }}>
      {(!data || data?.length === 0) && <div className={emptyData}>{t(t('WEARABLES.USER_PAGE.NO_DATA_ADD_SOME'))}</div>}
      <div id="timeline" className={timelineChart} ref={timelineChartRef} style={{ marginTop: 20, height: 35 }} />
    </div>
  );
};

export default TimelineChart;
