import moment from 'moment';
import { adjustGallerySchemaForComparison } from '../utils/schemaTools';
import replaceCsvRestrictedSymbols from '../utils/replaceCsvRestrictedSymbols';
import getGalleryReportSchema from './galleryReportSchema';
export default function getComparisonReportSchema(t, departments, sites, videos) {
    return [
        {
            header: t('GENERAL.ID').toUpperCase(),
            getValue: ({ id }) => id,
        },
        {
            header: t('SOTER_TASK.COMPARISONS_LIST.COMPARISON_NAME'),
            getValue: ({ title }) => replaceCsvRestrictedSymbols(title),
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.DATE'),
            getValue: ({ createdAt }) => moment(createdAt * 1000).format('DD/MM/YYYY'),
        },
        {
            header: t('MY_COMPANY.USERS.CREATED_BY'),
            getValue: ({ createdBy }) => replaceCsvRestrictedSymbols(createdBy),
        },
        {
            header: t('WEARABLES.OVERVIEW.DEPARTMENT'),
            getValue: ({ departmentId }) => { var _a; return replaceCsvRestrictedSymbols((_a = departments.find((v) => v.id === departmentId)) === null || _a === void 0 ? void 0 : _a.name); },
        },
        {
            header: t('WEARABLES.OVERVIEW.SITE'),
            getValue: ({ siteId }) => { var _a; return replaceCsvRestrictedSymbols((_a = sites.find((v) => v.id === siteId)) === null || _a === void 0 ? void 0 : _a.name); },
        },
        {
            header: t('WEARABLES.OVERVIEW.OVERALL_IMPROVEMENT'),
            getValue: ({ overallImprovement }) => overallImprovement,
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.BACK')} ${t('GENERAL.IMPROVEMENT')}`,
            getValue: ({ backImprovement }) => backImprovement,
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.NECK')} ${t('GENERAL.IMPROVEMENT')}`,
            getValue: ({ neckImprovement }) => neckImprovement,
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.LEFT_LEG')} ${t('GENERAL.IMPROVEMENT')}`,
            getValue: ({ legLeftImprovement }) => legLeftImprovement,
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.RIGHT_LEG')} ${t('GENERAL.IMPROVEMENT')}`,
            getValue: ({ legRightImprovement }) => legRightImprovement,
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.LEFT_ARM')} ${t('GENERAL.IMPROVEMENT')}`,
            getValue: ({ armLeftImprovement }) => armLeftImprovement,
        },
        {
            header: `${t('SOTER_TASK.BODY_PARTS.RIGHT_ARM')} ${t('GENERAL.IMPROVEMENT')}`,
            getValue: ({ armRightImprovement }) => armRightImprovement,
        },
        {
            header: t('SOTER_TASK.VIDEOS_LIST.REBA'),
            getValue: ({ maxRebaScore }) => maxRebaScore
        },
        ...adjustGallerySchemaForComparison(getGalleryReportSchema(t, departments, sites, videos), '#1 ', 'firstTaskVideo'),
        ...adjustGallerySchemaForComparison(getGalleryReportSchema(t, departments, sites, videos), '#2 ', 'secondTaskVideo'),
    ];
}
