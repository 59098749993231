import { toCamel } from 'snake-camel';
import {
  activityPageRequest,
  deleteUsersRequest,
  getMetaData,
  usersDepartmentsRequest,
  usersJobRoleRequest,
  usersSiteRequest,
  usersService,
  period, createUsersRequest, CreateUserMessageConstructor, editUsersInfoRequest, EditUserConstructor,
} from 'init/proto_client';
import * as types from './actionTypes';
import * as userTypes from '../../user/userStore/actionTypes';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { getUser } from '../../user/userStore/actions';
import { removeItemsFromSelection } from '../../../containers/ItemTableContainer/selectedItemsStore/actions';
import { TableType } from '../../../containers/ItemTableContainer/constants/tableType';
// import { setOpenedModal } from 'store/modals/actions';

export function toggleIsLoading(isLoading) {
  return ({ type: types.USERS_TOGGLE_LOADING, isLoading });
}

export function resetCoachUsers() {
  return { type: types.USERS_RESET };
}

export function getUsers(companyID, selectedPeriod, siteIDs, departmentIDs) {
  return async (dispatch) => {
    dispatch(toggleIsLoading(true));
    activityPageRequest.setCompanyId(companyID);
    if (selectedPeriod) {
      period.setStartedAt(+selectedPeriod.startedAt);
      period.setFinishedAt(+selectedPeriod.finishedAt);
      activityPageRequest.setPeriod(period);
    }
    activityPageRequest.setDepartmentIdsList(departmentIDs);
    activityPageRequest.setSiteIdsList(siteIDs);
    await usersService.getActivityPageDateRange(
      activityPageRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.USERS_ERROR,
            error,
          });
        } else {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.USERS_SET,
            users: response.toObject().usersList,
          });
          dispatch({
            type: types.USERS_TOGGLE_LOADING,
            isLoading: false,
          });
        }
      },
    );
  };
}

export function addEntryToUsersState(userIds, entryId, categories) {
  return async (dispatch) => {
    dispatch({
      type: types.USERS_ADD_TAG,
      addAction: {
        userIds,
        entryId,
        categories,
      },
    });
  };
}

export function removeEntryFromUsersState(userId, entryId) {
  return async (dispatch) => {
    dispatch({
      type: types.USERS_REMOVE_TAG,
      removeAction: {
        userId,
        entryId,
      },
    });
  };
}

export function setCoachDepartment(userIds, departmentId) {
  return async (dispatch, getState) => {
    usersDepartmentsRequest.setUserIdsList(userIds);
    usersDepartmentsRequest.setDepartmentId(+departmentId);
    await usersService.setDepartment(usersDepartmentsRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.DEPARTMENT_FAILED_MSG,
        }));
        dispatch({
          type: types.USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const { statusesList } = response.toObject();
        const usedOnUserPage = getState().coachUsers.users.length === 0;
        dispatch(getUser(userIds[0]));
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.DEPARTMENT_SUCCESS_MSG,
        }));
        !usedOnUserPage ? dispatch({
          type: types.USERS_SET_DEPARTMENT,
          isLoading: false,
          statusesList,
          departmentId,
          departments: getState().category.categories.find((c) => c.id === -1)?.tagsList,
        })
          : dispatch(getUser(userIds[0]));
      }
    });
  };
}

export function setUsersSite(userIds, siteId) {
  return async (dispatch, getState) => {
    usersSiteRequest.setUserIdsList(userIds);
    usersSiteRequest.setSiteId(+siteId);
    await usersService.setSite(usersSiteRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.SITE_FAILED_MSG,
        }));
        dispatch({
          type: types.USERS_ERROR,
          isLoading: false,
          error,
        });
      } else {
        const { statusesList } = response.toObject();
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.SITE_SUCCESS_MSG,
        }));
        dispatch({
          type: types.USERS_SET_SITE,
          isLoading: false,
          siteChangeList: statusesList,
          siteId,
          sites: getState().category.categories.find((c) => c.id === -2)?.tagsList,
        });
        dispatch(getUser(userIds[0]));
      }
    });
  };
}

export function setCoachJobTitle(userIds, jobRoleId) {
  return async (dispatch, getState) => {
    usersJobRoleRequest.setUserIdsList(userIds);
    usersJobRoleRequest.setJobRoleId(jobRoleId);
    usersJobRoleRequest.setCompanyId(getState().companies.selectedCompany.id);
    await usersService.setJobRole(
      usersJobRoleRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.JOB_TITLE_FAILED_MSG,
          }));
          dispatch({
            type: types.USERS_ERROR,
            isLoading: false,
            error,
          });
        } else {
          const { statusesList } = response.toObject();
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.JOB_TITLE_SUCCESS_MSG,
          }));
          dispatch({
            type: types.USERS_SET_JOB_ROLE,
            isLoading: false,
            jobRolesChangeList: statusesList,
            jobRoleId,
            jobRoles: getState()
              .category
              .categories
              .find((c) => c.title === 'user_job_role')?.tagsList,
          });
          dispatch(getUser(userIds[0]));
        }
      },
    );
  };
}

export function createUsers(usersData, companyId) {
  return async (dispatch) => {
    createUsersRequest.setCompanyId(companyId);
    const users = [];
    usersData.forEach((userData) => {
      const user = new CreateUserMessageConstructor();
      const {
        firstName,
        lastName,
        login,
        site,
        departmentName,
        jobRole,
      } = userData;
      user.setFirstName(firstName);
      user.setLastName(lastName);
      user.setLogin(login);
      user.setDepartment(departmentName);
      user.setJobTitle(jobRole);
      user.setSite(site);
      users.push(user);
    });
    createUsersRequest.setUsersList(users);
    await usersService.createUsers(
      createUsersRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          if (error.code === 6) {
            dispatch(setNotification({
              type: notificationTypes.TYPE_FAILED,
              message: notificationMessages.CREATE_DASHBOARD_USER_FAILED_ALREADY_EXIST_MSG,
            }));
          } else {
            dispatch(setNotification({
              type: notificationTypes.TYPE_FAILED,
              message: notificationMessages.CREATE_DASHBOARD_USER_FAILED_MSG,
            }));
          }
          dispatch({
            type: types.USERS_ERROR,
            error,
          });
        } else {
          const { usersStatusList } = response.toObject();
          // dispatch(setNotification({
          //   type: notificationTypes.TYPE_SUCCESS,
          //   message: notificationMessages.CREATE_DASHBOARD_USER_SUCCESS_MSG,
          // }));
          dispatch({
            type: types.USERS_SET_CREATED_STATUSES,
            createdStatuses: usersStatusList,
          });
        }
      },
    );
  };
}

export const editUsersStatuses = {
  NO_ERROR: 0,
  PERMISSION_DENIED_ERROR: 1,
  INTERNAL_SERVER_ERROR: 2,
  USER_ID_VALIDATION_ERROR: 3,
  FIRST_NAME_VALIDATION_ERROR: 4,
  LAST_NAME_VALIDATION_ERROR: 5,
  NICKNAME_VALIDATION_ERROR: 6,
  NICKNAME_VALIDATION_REGEXP_ERROR: 7,
  NICKNAME_DUPLICATION_ERROR: 8,
};

const serverErrors = new Set([
  editUsersStatuses.PERMISSION_DENIED_ERROR,
  editUsersStatuses.INTERNAL_SERVER_ERROR,
  editUsersStatuses.USER_ID_VALIDATION_ERROR,
]);

const validationErrors = new Set([
  editUsersStatuses.FIRST_NAME_VALIDATION_ERROR,
  editUsersStatuses.LAST_NAME_VALIDATION_ERROR,
  editUsersStatuses.NICKNAME_VALIDATION_ERROR,
  editUsersStatuses.NICKNAME_DUPLICATION_ERROR,
  editUsersStatuses.NICKNAME_VALIDATION_REGEXP_ERROR]);

export const editUsers = (usersData, isActivityPage) => async (dispatch) => {
  const editUsersInfo = new EditUserConstructor();
  editUsersInfo.setUserId(usersData[0].id);
  editUsersInfo.setFirstName(usersData[0].firstName);
  editUsersInfo.setLastName(usersData[0].lastName);
  editUsersInfo.setNickname(usersData[0].nickname);
  editUsersInfoRequest.setUsersList([editUsersInfo]);
  return new Promise((resolve) => {
    usersService.editUsersInfo(
      editUsersInfoRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          return resolve({ type: 'serverError', error });
        }
        const usersStatuses = response.toObject().usersStatusList;
        const usersWithServerError = usersStatuses.filter((status) => serverErrors.has(status.error));
        const usersWithValidationError = usersStatuses.filter((status) => validationErrors.has(status.error));
        const usersToModify = usersStatuses.filter((status) => status.error === editUsersStatuses.NO_ERROR).map((user) => (
          user?.userId === usersData[0].id && {
            id: usersData[0].id,
            firstName: usersData[0].firstName,
            lastName: usersData[0].lastName,
            nickname: usersData[0].nickname,
          }));
        if (usersWithServerError.length > 0) {
          return resolve({ type: 'serverError', users: usersWithServerError });
        }
        if (isActivityPage) {
          dispatch({
            type: types.USERS_EDIT_STATUSES,
            errorsOnEdit: usersWithValidationError,
          });
          dispatch({
            type: types.USERS_EDIT_SET,
            users: usersToModify,
          });
        } else {
          dispatch({
            type: userTypes.USER_EDIT_STATUS,
            errorOnEdit: usersWithValidationError[0]?.error || null,
          });
          dispatch({
            type: userTypes.USER_EDIT_SET,
            user: usersToModify[0] || null,
          });
        }
        resolve({
          type: 'usersEdit',
          error: usersWithValidationError.length > 0 ? usersWithValidationError[0]?.error : null,
        });
      },
    );
  });
};

export function deleteUsers(userIds, action) {
  return async (dispatch) => {
    deleteUsersRequest.setUserIdsList(userIds);
    await usersService.deleteUsers(
      deleteUsersRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: error?.message,
          }));
          dispatch({
            type: types.USERS_ERROR,
            error,
          });
        } else {
          const { usersStatusList } = response.toObject();
          const deletedUsers = usersStatusList.filter(({ status }) => status === 1);
          const failToDeleteUsers = usersStatusList.filter(({ status }) => status === 2);
          dispatch(removeItemsFromSelection(deletedUsers, TableType.coach));
          dispatch({
            type: types.USERS_DELETE,
            deletedUsers,
            failToDeleteUsers,
          });
        }
      },
    );
  };
}

export function resetDeleted() {
  return ({
    type: types.USERS_RESET_DELETED,
  });
}

export const setDateRange = (dateRangePickerValue) => ({
  type: types.USERS_SET_PICKER_VALUE,
  dateRangePickerValue,
});
