import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from '@reach/router';
import { getElementByTitle } from '../../utils/getElementByTitle';
import EntriesTable from '../../components/EntriesTable';
import { setCurrentCategory } from '../store/categorySlice';
import PrivatePageHOC from '../../../containers/PrivatPageHOC/PrivatePageHOC';
import Loader from '../../../lib/ui/Loader';
const CompanyEntriesContainer = () => {
    const currentCategory = useSelector((state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.category) === null || _a === void 0 ? void 0 : _a.currentCategory; });
    const currentRoute = useLocation();
    const [isNotFound, setIsNotFound] = useState(false);
    const { categories } = useSelector((state) => state === null || state === void 0 ? void 0 : state.category);
    const dispatch = useDispatch();
    useEffect(() => {
        if ((categories === null || categories === void 0 ? void 0 : categories.length) > 0) {
            const category = getElementByTitle(categories, currentRoute);
            if (!category) {
                setIsNotFound(true);
            }
            dispatch(setCurrentCategory({ id: category === null || category === void 0 ? void 0 : category.id, title: category === null || category === void 0 ? void 0 : category.title }));
        }
    }, [categories]);
    return (React.createElement("div", null, (currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.tagsList)
        ? React.createElement(EntriesTable, { currentCategory: currentCategory })
        : (React.createElement("div", null,
            React.createElement(Loader, null),
            isNotFound &&
                // @ts-ignore
                React.createElement(Redirect, { noThrow: true, from: "users", to: "/not-found" })))));
};
export default PrivatePageHOC(CompanyEntriesContainer);
