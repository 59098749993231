/**
 * @fileoverview gRPC-Web generated client stub for chat_dashboard_user_profile
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/dashboard_user_chat_profile_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.chat_dashboard_user_profile = require('./dashboard_user_chat_profile_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest,
 *   !proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_CreateDashboardUserChatProfile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/CreateDashboardUserChatProfile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest,
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.createDashboardUserChatProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/CreateDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_CreateDashboardUserChatProfile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.createDashboardUserChatProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/CreateDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_CreateDashboardUserChatProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest,
 *   !proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_GetDashboardUserChatProfile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserChatProfile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest,
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.getDashboardUserChatProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetDashboardUserChatProfile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.getDashboardUserChatProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetDashboardUserChatProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest,
 *   !proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_UpdateDashboardUserChatProfile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/UpdateDashboardUserChatProfile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest,
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.updateDashboardUserChatProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/UpdateDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_UpdateDashboardUserChatProfile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.updateDashboardUserChatProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/UpdateDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_UpdateDashboardUserChatProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest,
 *   !proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_DeleteDashboardUserChatProfile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/DeleteDashboardUserChatProfile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest,
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.deleteDashboardUserChatProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/DeleteDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_DeleteDashboardUserChatProfile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.deleteDashboardUserChatProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/DeleteDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_DeleteDashboardUserChatProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest,
 *   !proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_GetPreSignedUrlDashboardUserChatProfile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetPreSignedUrlDashboardUserChatProfile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest,
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.getPreSignedUrlDashboardUserChatProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetPreSignedUrlDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetPreSignedUrlDashboardUserChatProfile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.getPreSignedUrlDashboardUserChatProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetPreSignedUrlDashboardUserChatProfile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetPreSignedUrlDashboardUserChatProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest,
 *   !proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_SetDashboardUserChatProfileFile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/SetDashboardUserChatProfileFile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest,
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.setDashboardUserChatProfileFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/SetDashboardUserChatProfileFile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_SetDashboardUserChatProfileFile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.setDashboardUserChatProfileFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/SetDashboardUserChatProfileFile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_SetDashboardUserChatProfileFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest,
 *   !proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_DeleteDashboardUserChatProfileFile = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/DeleteDashboardUserChatProfileFile',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest,
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.deleteDashboardUserChatProfileFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/DeleteDashboardUserChatProfileFile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_DeleteDashboardUserChatProfileFile,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.deleteDashboardUserChatProfileFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/DeleteDashboardUserChatProfileFile',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_DeleteDashboardUserChatProfileFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest,
 *   !proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_GetDashboardUserSignature = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserSignature',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest,
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.getDashboardUserSignature =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserSignature',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetDashboardUserSignature,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.getDashboardUserSignature =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserSignature',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetDashboardUserSignature);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest,
 *   !proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse>}
 */
const methodDescriptor_DashboardUserChatProfileService_GetDashboardUserLatestChatId = new grpc.web.MethodDescriptor(
  '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserLatestChatId',
  grpc.web.MethodType.UNARY,
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest,
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse,
  /**
   * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.deserializeBinary
);


/**
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServiceClient.prototype.getDashboardUserLatestChatId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserLatestChatId',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetDashboardUserLatestChatId,
      callback);
};


/**
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse>}
 *     Promise that resolves to the response
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileServicePromiseClient.prototype.getDashboardUserLatestChatId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chat_dashboard_user_profile.DashboardUserChatProfileService/GetDashboardUserLatestChatId',
      request,
      metadata || {},
      methodDescriptor_DashboardUserChatProfileService_GetDashboardUserLatestChatId);
};


module.exports = proto.chat_dashboard_user_profile;

