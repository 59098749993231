import React from 'react';
import {
  Arc, Layer, Stage, Text,
} from 'react-konva';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';

const ARC_GAP_DEGREE = 2;
const INNER_RADIUS = 77;
const OUTER_RADIUS = 88;

const DonutChart = ({
  height,
  width,
  data,
  totalTime,
  scale = 1,
  outerRadius = OUTER_RADIUS,
  innerRadius = INNER_RADIUS,
  noData,
  text = 'TOTAL TIME',
}) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('language');
  const fontSizeForNoData = language === 'fr' ? '7' : '18';
  let rotation = 270;
  return (
    <Stage
      width={width * scale}
      height={height * scale}
      scale={{ x: scale, y: scale }}
    >
      <Layer>
        {data.map(({ fill, angle }) => {
          rotation += angle;
          return (
            <Arc
              key={(angle || 0) + Math.random()}
              x={outerRadius}
              y={outerRadius}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              /* eslint-disable-next-line no-nested-ternary */
              angle={
                angle
                  ? angle > ARC_GAP_DEGREE
                    ? angle - (data.length === 1 ? 0 : ARC_GAP_DEGREE)
                    : angle
                  : 0
              }
              /* eslint-disable-next-line no-nested-ternary */
              rotation={
                angle
                  ? angle > ARC_GAP_DEGREE
                    ? rotation - angle
                    : rotation - angle * 1.5
                  : 0
              }
              fill={fill}
            />
          );
        })}
        {totalTime ? (
          <>
            <Text
              text={t(text)}
              fontSize={16 * scale}
              fill={COLORS.GRAY[400]}
              x={(innerRadius - 15) * scale}
              y={innerRadius + 35 * scale}
            />
            <Text
              text={totalTime}
              fontSize={52 * scale}
              fill={COLORS.GRAY[950]}
              x={
                (totalTime.length > 5
                  ? innerRadius - 40
                  : totalTime.length > 3
                    ? innerRadius - 30
                    : innerRadius - 14) * scale
              }
              y={innerRadius - 10}
            />
          </>
        ) : null}
        {noData ? (
          <Text
            text={t('GENERAL.NO_DATA').toUpperCase()}
            fontSize={fontSizeForNoData}
            fill={COLORS.GRAY[400]}
            x={outerRadius - 40}
            y={outerRadius - 9}
          />
        ) : null}
      </Layer>
    </Stage>
  );
};

export default DonutChart;
