export const findMaxY = (dotsData, selectedBodyPart) => {
    const maxYValue = Math.round(Math.max(...dotsData
        // @ts-ignore
        .map((frame) => frame[frame.length - 1][selectedBodyPart])
        .filter((bodyPart) => bodyPart)));
    selectedBodyPart.toLowerCase();
    let maxYForChart;
    switch (selectedBodyPart) {
        case 'reba':
            maxYForChart = Math.round(((18 - maxYValue) / 2) + maxYValue) >= 18 ? 18 : Math.round(((18 - maxYValue) / 2) + maxYValue);
            break;
        default:
            maxYForChart = Math.round(((180 - maxYValue) / 2) + maxYValue) >= 180 ? 180 : Math.round(((180 - maxYValue) / 2) + maxYValue);
    }
    return maxYForChart;
};
