export const TableType = {
    user: 'user',
    coach: 'coach',
    usersUpload: 'usersUpload',
    devices: 'devices',
    jobs: 'jobs',
    help: 'help',
    companies: 'companies',
    dashboardUsers: 'dashboardUsers',
    myCompanyUsers: 'myCompanyUsers',
    soterTaskVideos: 'soterTaskVideos',
    soterTaskComparison: 'soterTaskComparison',
    adminDevices: 'adminDevices',
    exportReport: 'exportReport',
    hubs: 'hubs',
    feedbacks: 'feedbacks',
    categories: 'categories',
    entries: 'entries',
    reba: 'reba',
};
