import React from 'react';
import _each from 'lodash/each';
import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import noMedal from '../../../../assets/img/icons/medals/NoMedal.svg';
import gold from '../../../../assets/img/icons/medals/Gold.svg';
import silver from '../../../../assets/img/icons/medals/Silver.svg';
import bronze from '../../../../assets/img/icons/medals/Bronze.svg';
import { achievementsWrapper } from '../../../../assets/jss/style';
import Tooltip from '../../../../lib/ui/Tooltip';
import MedalWithQuantity from './MedalWithQuantity';

/* eslint react/destructuring-assignment:0 */

const AchievementsCell = ({ rowData }) => {
  const { program, isPassedProgramCopy } = rowData;
  const { t } = useTranslation();
  const achievements = program?.achievements;
  let isNoAchievement = true;
  _each(achievements, (achievement, key) => {
    if (achievement > 0 && key === 'goldGoalsQuantity') isNoAchievement = false;
  });

  const setAchievementImage = (achievement) => {
    switch (achievement) {
      case 'goldGoalsQuantity': return gold;
      case 'silverGoalsQuantity': return silver;
      case 'bronzeGoalsQuantity': return bronze;
      default: return noMedal;
    }
  };
  return (
    <>
      {isPassedProgramCopy ? '' : (isNoAchievement
        ? (
          <Tooltip text="WEARABLES.ACTIVITY.NO_ACHIEVEMENTS">
            <img src={noMedal} alt="no medals" />
          </Tooltip>
        )
        : (
          <div className={achievementsWrapper}>
            { _map(
              achievements,
              (achievement, key) => (achievement > 0 && key === 'goldGoalsQuantity') &&
                    (
                      <MedalWithQuantity
                        key={key}
                        type={key}
                        image={setAchievementImage(key)}
                        quantity={achievement}
                      />
                    ),
            )}
          </div>
        ))}
    </>
  );
};

export default AchievementsCell;
