import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenedModal } from 'containers/ModalContainer/modalsStore/actions';
import { openedModalSelector } from 'containers/ModalContainer/modalsStore/selectors';
import * as Emotion from 'emotion';
import {
  bubbleForButton, columnSmall, flexRow, pl0, pr0,
} from 'assets/soter-flex-styles';
import ModalWrapper from 'containers/ModalContainer/ModalWrapper';
import DropdownActionList from 'lib/ui/DropdownActionList/DropdownActionList';
import { getActionHandlerForModal } from 'lib/utils/getActionHandlerForModal';

const buttonForFilters = Emotion.css`
  margin-right: 5px;
`;

const UserActionDropdown = ({
  userActions = [],
  itemType,
  children,
  exportToPdf,
  bubble,
  secondaryButton = null,
  modalClass,
  flexStart,
}) => {
  const dispatch = useDispatch();
  const openModal
    = useSelector(openedModalSelector) === 'userActionDropdownModal';
  const setOpenModal = (flag) => dispatch(setOpenedModal(flag ? 'userActionDropdownModal' : null));

  const [openDropdown, setOpenDropdown] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [openedDialogId, setOpenedDialogId] = useState(null);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  const openModalDialog = () => {
    setOpenModal(true);
  };
  const closeModalDialog = () => {
    setOpenModal(false);
  };

  /*
   * Every component used as a modal content opened with this dropdown
   * must have a {handlerFn} prop that will get a proper action dispatcher
   * via getActionHandlerForModal function
   */
  const actions = userActions.map((action) => {
    if (!action.component) return action;
    const component = React.cloneElement(action.child, {
      handlerFn: getActionHandlerForModal(action.id, itemType)(
        closeModalDialog,
        dispatch,
      ),
      closeModal: closeModalDialog,
    });
    return { ...action, child: component };
  });
  const getOpenDialog = (id) => {
    setModalContent(
      <ModalWrapper
        closeModal={() => {
          closeModalDialog();
          setModalContent(null);
        }}
        keepModalOnClickOutside={id === 'edit'}
        withCloseButton
        modalClass={modalClass}
      >
        {actions.find((action) => action.id === id).child}
      </ModalWrapper>,
    );
  };

  const openDialog = (id) => {
    if (id === 'pdfExport') {
      exportToPdf();
    } else if (id) {
      setOpenDropdown(false);
      openModalDialog();
      getOpenDialog(id);
      setOpenedDialogId(id);
    } else {
      setOpenDropdown(false);
    }
  };

  const DropToggler = React.Children.map(children, (child) => React.cloneElement(
    child,
    {
      toggleDrop: toggleDropdown,
      state: openDropdown,
    },
    <DropdownActionList actionHandler={openDialog} actions={actions} />,
  ));

  useEffect(() => {
    if (openedDialogId) {
      setModalContent(
        <ModalWrapper
          closeModal={() => {
            closeModalDialog();
            setModalContent(null);
          }}
          withCloseButton
          modalClass={modalClass}
        >
          {actions.find((action) => action.id === openedDialogId).child}
        </ModalWrapper>,
      );
    }
  }, [userActions]);

  return (
    <div
      className={`${flexRow}`}
      style={{ justifyContent: !flexStart && 'flex-end', margin: 0 }}
    >
      {bubble ? <div className={bubbleForButton}>{bubble}</div> : null}
      <div
        className={`${columnSmall} ${pl0} ${pr0} ${
          secondaryButton ? buttonForFilters : null
        }`}
      >
        {DropToggler}
        {openModal && modalContent}
      </div>
    </div>
  );
};

export default UserActionDropdown;
