import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  user: null,
  error: null,
  isLoading: false,
  errorOnEdit: null,
});

export default function reduce(state = initialState, action = {}) {
  const { isLoading, user, error } = action;
  switch (action.type) {
    case types.USER_SET:
      return state.merge({
        user,
        error: null,
      });
    case types.USER_TOGGLE_LOADING:
      return state.merge({ isLoading });
    case types.USER_ERROR:
      return state.merge({ error });
    case types.USER_RESET:
      return state.merge(initialState);
    case types.USER_EDIT_STATUS:
      return state.merge({ ...state, errorOnEdit: action.errorOnEdit });
    case types.USER_CLEAR_EDIT_STATUS:
      return state.merge({...state, errorOnEdit: null});
    case types.USER_EDIT_SET:
      if (!action.user) return state;

      return state.merge({
        ...initialState,
        user: {
          ...state.user,
          id: action.user.id,
          firstName: action.user.firstName,
          lastName: action.user.lastName,
          nickname: action.user.nickname,
        },
      });
    default: return state;
  }
}
