// eslint-disable-next-line import/prefer-default-export
export const emptyGroupedChartData = [
  {
    Risk: 'Lower',
    sum: 10,
  },
  {
    Risk: 'Medium',
    sum: 10,
  },
  {
    Risk: 'Higher',
    sum: 10,
  },
];
