import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { ExtraDepartmentOptions, GroupingCriteria, GroupingCriteriaByPeriod, Periods } from '../constants/constants';

const initialState = Immutable(({
  selectedDepartmentNames: [],
  selectedPeriod: Periods.ALL_TIME,
  grouping: {
    spineChart: GroupingCriteria.BY_DEPARTMENT,
    shoulderChart: GroupingCriteria.BY_DEPARTMENT,
    activeUsers: GroupingCriteriaByPeriod.BY_MONTH,
    registeredUsers: GroupingCriteriaByPeriod.BY_MONTH,
  },
  isAllDepartmentDataSet: false,
}));

export default function reducer(state = initialState, action) {
  const {
    names, period, groupBy, chartType, type,
  } = action;
  switch (type) {
    case types.RESET_OVERVIEW_STATE:
      return initialState;
    case types.SET_ALL_SELECTED_DEPARTMENTS: {
      return {
        ...state,
        selectedDepartmentNames: names,
        isAllDepartmentDataSet: true,
      };
    }
    case types.SET_SELECTED_DEPARTMENT_NAMES:
      return {
        ...state,
        selectedDepartmentNames: names,
      };
    case types.SET_SELECTED_PERIOD:
      return {
        ...state,
        selectedPeriod: period,
      };
    case types.SET_SELECTED_GROUPING:
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [chartType]: groupBy,
        },
      };
    case types.RESET_SELECTED_DEPARTMENT_NAMES:
      return {
        ...state,
        selectedDepartmentNames: [ExtraDepartmentOptions.ALL_DEPARTMENTS],
      };
    case types.RESET_SELECTED_PERIOD:
      return {
        ...state,
        selectedPeriod: Periods.ALL_TIME,
      };
    case types.RESET_SELECTED_GROUPING:
      return {
        ...state,
        grouping: GroupingCriteria.BY_DEPARTMENT,
      };
    default: return state;
  }
}
