import React from 'react';
import circleGreen from '../../../../assets/img/icons/circle_green.svg';
import circleRed from '../../../../assets/img/icons/circle_red.svg';

const DeviceName = ({ rowData }) => (
  <>
    <img style={{ marginRight: '5px' }} src={rowData.currentUser || rowData.active ? circleGreen : circleRed} alt="" />
    {rowData.macAddress.toUpperCase()}
  </>
);

export default DeviceName;
