import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { Loader } from 'tabler-react';
import * as Emotion from 'emotion';
import { card } from 'assets/soter-flex-styles';
import { loader } from 'assets/jss/style';
import { ReactComponent as Weight } from 'assets/img/weight.svg';
import Button from 'lib/ui/Button';
import Text from 'lib/ui/Text';
import calculateRebaRiskLevel from '../utils/calculateRebaRiskLevel';
import InfoTooltip from '../../lib/ui/InfoTooltip';

const header = Emotion.css`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px;
`;

const weightSection = Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;

  .weightInfo {
    display: flex;
    align-items: center;
    color: initial;

    p {
      margin-left: 20px;
    }
  }
`;

const getScoreStyle = (score, t) => Emotion.css`
  font-size: 32px;
  font-weight: 900;
  color: ${calculateRebaRiskLevel(score, t)[1]};
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  p span {
    font-size: 16px;
    font-weight: 700;
  }

  .description {
    background-color: #f5f5f5;
    padding: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: initial;
  }
`;

const AverageRebaScore = ({
  rebaData, score, isLoading, companyId, videoId,
}) => {
  const { t } = useTranslation();
  const [isDelay, setIsDelay] = useState(false);

  setTimeout(() => setIsDelay(true), 5000);

  return (
    <div className={card} style={{ padding: '12px 16px' }}>
      <div className={header}>
        <p
          style={{
            marginRight: '9px',
          }}
        >
          {t(
            'SOTER_TASK.ASSESSMENT.REBA.MAX_SCORE_BANNER.TITLE',
          )}
        </p>
        <InfoTooltip text={t(
          'SOTER_TASK.ASSESSMENT.REBA.MAX_SCORE_BANNER.TOOLTIP',
        )}
        />
      </div>
      {isLoading || !isFinite(score) ? (
        <div className={loader} style={{ width: '100%', textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader />
          </div>
          <p
            style={{
              fontWeight: '600',
              fontSize: '14px',
              marginTop: '9.5px',
            }}
          >
            {isDelay
              ? t(
                'SOTER_TASK.ASSESSMENT.REBA.CHART.LOADING.TEXT',
              )
              : t(
                'SOTER_TASK.ASSESSMENT.REBA.CHART.LOADING.TITLE',
              )}
          </p>
        </div>
      ) : (
        <>
          <div className={getScoreStyle(score && score, t)}>
            <p>
              {score}
              {' '}
              <span>
                –
                {' '}
                {calculateRebaRiskLevel(score, t)[0]}
              </span>
            </p>
            <div className="description">
              {t(`SOTER_TASK.ASSESSMENT.REBA.${calculateRebaRiskLevel(score, t)[2]}`)}
            </div>
          </div>
          <div className={weightSection}>
            <div className="weightInfo">
              <Weight />
              <Text
                text={t(
                  'SOTER_TASK.ASSESSMENT.REBA.MAX_SCORE_BANNER.WEIGHT_INFO',
                )}
                appearance="footnote"
              />
            </div>
            <Button
              text={rebaData.length
                ? t('SOTER_TASK.ASSESSMENT.BUTTONS.EDIT')
                : t('JOB_DICTIONARY.JOB_PROFILE.ADD')}
              appearance="tertiary"
              handler={() => navigate(`/${companyId}/users/soter-genius/gallery/weight/${videoId}`)}
            />
          </div>
          <Text
            text={t(
              'SOTER_TASK.ASSESSMENT.REBA.MAX_SCORE_BANNER.CALCULATION_INFO',
            )}
            appearance="caption"
            style={{ color: '#4A5056', marginTop: '40px' }}
          />
        </>
      )}
    </div>
  );
};

export default AverageRebaScore;
