import _orderBy from 'lodash/orderBy';
import { ALL_DEPARTMENTS, ALL_SITES, initialUserValues, NO_DEPARTMENTS, NO_SITES, } from './constants';
import { isCorrectFieldLength } from './validation';

export const getCompaniesSelectOptions = (companies, companiesField) => {
  const companiesObjects = companies?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const filteredCompanies = companiesObjects.filter(({ value }) => !companiesField?.includes(value));

  return _orderBy(filteredCompanies, [(company) => company.label.toLowerCase()], ['asc']);
};

export const getCompaniesAccordionOptions = (companies, companiesField) => {
  const filteredCompanies = companies.filter(({ id }) => companiesField?.map(({ value }) => value).includes(id));

  return _orderBy(filteredCompanies, [(company) => company.name.toLowerCase()], ['asc']);
};

export const getSelectedDepartmentString = (selectedDepartments, openedCompanyDepartments, forSites) => {
  const currentCompanyDepartmentIds = openedCompanyDepartments.map((dept) => dept.id);
  const currentCompanyselectedDepartments = selectedDepartments?.filter(
    ({ value }) => currentCompanyDepartmentIds.includes(value),
  );

  if (openedCompanyDepartments.length === 0) {
    return forSites ? NO_SITES : NO_DEPARTMENTS;
  } if (currentCompanyselectedDepartments.length === 0 || currentCompanyselectedDepartments === null) {
    return forSites ? ALL_SITES : ALL_DEPARTMENTS;
  }
  return `${currentCompanyselectedDepartments?.length}/${openedCompanyDepartments.length} ${forSites ? 'sites' : 'departments'}`;
};

const getCompanies = (value, isAdmin) => (value === null || isAdmin ? [] : value.map((company) => company.value));

const getDepartments = (value) => Object.values(value).flat().filter((dept) => dept).map((dept) => dept.value);

export const transformDataForServer = (data) => ({
  ...data,
  firstName: data.firstName.trim(),
  lastName: data.lastName.trim(),
  jobTitle: data.jobTitle.trim(),
  companies: getCompanies(data.companies, data.isAdmin),
  departments: getDepartments(data.departments),
  sites: getDepartments(data.sites),
  resellerId: data.isAdmin ? undefined : data.resellerId.value,
});

export const getDepartmentSelectOptions = (departmentList) => (
  departmentList.map(({ id, name }) => ({
    value: id,
    label: name,
  }))
);

export const isCreateUserFormValid = (formFields, formType, isPartner) => {
  const hasName = isCorrectFieldLength(formFields.firstName, 2) && isCorrectFieldLength(formFields.lastName, 2);
  const hasEmail = isValidEmailField(formFields.email, 5);
  const hasCompanies = formFields.companies.length > 0;
  const hasResellerId = formFields.resellerId?.value;

  const baseAdminUserValidation = hasName && hasEmail && (formFields.isAdmin || hasCompanies);
  const myCompanyUserValidation = hasName && hasEmail && hasCompanies;

  if (formType === 'addNewUser' || formType === 'editUser') {
    if (isPartner) {
      return hasResellerId && baseAdminUserValidation;
    }
    return baseAdminUserValidation;
  }

  return myCompanyUserValidation;
};

const getDataFormatForSelect = (el) => ({ value: el.id, label: el.name });

export const getInitialFields = (editedUser) => {
  if (!editedUser) {
    return initialUserValues;
  }

  const formatDataForForm = (data) => ({
    ...data,
    companies: data.companiesList.map((el) => getDataFormatForSelect(el)),
    isAdmin: data.admin,
  });

  return { ...initialUserValues, ...formatDataForForm(editedUser) };
};

export const getInitialDepartments = (editedUser) => {
  if (!editedUser) {
    return {};
  }

  const getDepartmentsInCorrectFormat = (companiesList) => {
    const res = {};

    const getFormattedDepartments = (company) => company.departmentsList.map((el) => getDataFormatForSelect(el));

    companiesList.forEach((company) => {
      res[company.id] = getFormattedDepartments(company);
    });
    return res;
  };

  return getDepartmentsInCorrectFormat(editedUser.companiesList);
};

export const getInitialSites = (editedUser) => {
  if (!editedUser) {
    return {};
  }

  const getSitesInCorrectFormat = (companiesList) => {
    const res = {};

    const getFormattedSites = (company) => company.sitesList?.map((el) => getDataFormatForSelect(el));

    companiesList.forEach((company) => {
      res[company.id] = getFormattedSites(company);
    });
    return res;
  };

  return getSitesInCorrectFormat(editedUser.companiesList);
};
