import React, { useState, useEffect } from 'react';
import * as Emotion from 'emotion';
import { Risks, TaskVideoType } from 'init/proto_client';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants/colors';
import { VIDEO_WIDTH } from '../constants';
import {
  button, date, deleteButton, headerControl, riskText,
} from '../../../assets/jss/style';
import { TASK_VIDEO_EDITED } from '../../../constants/analyticsEvents';
import { buttonTypeAndSize } from '../../../assets/jss/components/button';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import sliceLongString from '../../../lib/utils/sliceLongString';
import Button from '../../../lib/ui/Button';
import { categoryNames } from '../../gallery/constants/videoTypeAndStatusNames';
import { getRiskText } from '../../../constants/getRiskGroupText';
import CustomTooltip from '../../../lib/ui/Tooltip';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { updateVideo } from '../../gallery/videosStore/actions';
import CustomInput from '../../../lib/ui/CustomInput';
import { DOTButton } from '../../../dictionary/components/EditableJobProfileHeader';
import CustomSelect from '../../../lib/ui/CustomSelect';
import { validateToPositiveIntegrals } from '../utils/filterNumberInput';
import capitalizeFirstLetter from '../../../lib/utils/capitalizeFirstLetter';
import {
  getDashboardUserChatProfile,
  resetUserProfile
} from '../../../profile/profileStore/actions';
import Tooltip from '../../../lib/ui/Tooltip';

const container = Emotion.css`
  // width: ${VIDEO_WIDTH}px;
  position: relative;
`;

const wrapper = Emotion.css`
  display: flex;
  flex-grow: 1;
`;

export const titleWrapper = Emotion.css`
  ${wrapper};

  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
`;

const editingHeader = (videoType) => Emotion.css`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${videoType === TaskVideoType.TASK_ASSESSMENT
    ? '1fr 1fr 1fr'
    : videoType === TaskVideoType.COACHING
      ? '1fr 1fr'
      : '1fr'};
  grid-gap: 11px;
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 3px;
  background-color: ${COLORS.WHITE};
  padding: 15px;
`;

const buttonsWrapper = Emotion.css`
  ${wrapper};
  position: absolute;
  top: -25px;
  right: 1px;
  justify-content: flex-end;
  div {
    margin-left: 18px;
  }
`;

const disableBtn = Emotion.css`
  color: ${COLORS.GRAY[400]} !important;
  pointer-events: none;
`;

export const editButton = (disabled) => Emotion.css`
  font-size: 15px;
  margin-left: 10px;
  color: ${disabled ? COLORS.GRAY[400] : COLORS.LIGHT_BLUE[600]};
  &:hover {
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  }
`;

const editingButtonsWrapper = Emotion.css`
  position: absolute;
  display: flex;
  top: 15px;
  white-space: nowrap;
  right: 0;
`;

export const getTypeText = (type) => {
  if (type === TaskVideoType.OTHER) return 'SOTER_TASK.VIDEOS_LIST.OTHER';
  if (type === TaskVideoType.COACHING) return 'SOTER_TASK.VIDEOS_LIST.COACHING';
  if (type === TaskVideoType.TASK_ASSESSMENT) return 'SOTER_TASK.VIDEOS_LIST.ASSESSMENT';
  return '';
};

const EditableVideoHeader = ({
  id,
  title,
  uploadedAt,
  description,
  setTitle,
  saveTitle,
  disabled,
  removeVideo,
  isAccepted,
  setIsAccepted,
  exportReport,
  videoLink,
  createdBy,
  jobRole,
  setJobRole,
  risk,
  setRisk,
  taskName,
  setTaskName,
  duration,
  setDuration,
  repetition,
  setRepetition,
  jobRoles,
  taskNames,
  cancelHandler,
  videoType,
  category,
  departments,
  department,
  setDepartment,
  sites,
  site,
  setSite,
  geniusHandler,
}) => {
  const { t } = useTranslation();
  const { disableVideoDownload } = useAppSelector(
    (state) => state.companies.selectedCompany.products.geniusProduct ?? false,
  );
  const { error: userProfileError } = useAppSelector((state) => state.userProfile)
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);
  const editingDisable = isAccepted;
  const [isAcceptButtonHovered, setIsAcceptButtonHovered] = useState(false);
  const dispatch = useAppDispatch();
  const getAcceptButtonText = () => {
    if (isAccepted && isAcceptButtonHovered) { return t('SOTER_TASK.VIDEO.DISAPPROVE'); }
    if (isAccepted) return t('SOTER_TASK.VIDEO_STATUS.APPROVED');
    return t('SOTER_TASK.VIDEO.APPROVE');
  };

  useEffect(() => {
    dispatch(getDashboardUserChatProfile());
  }, []);

  useEffect(() => {
    if (repetition === 0) {
      setRepetition('');
    }
  }, [repetition, setRepetition]);

  useEffect(() => {
    if (duration === 0) {
      setDuration('');
    }
  }, [duration, setDuration]);

  return (
    <div className={container}>
      <div>
        {!isEditing ? (
          <>
            <div style={{ display: 'flex' }}>
              <div className={titleWrapper}>
                {sliceLongString(title, 35)}
              </div>
              <div className={buttonsWrapper}>
                <Button
                  disabled={disabled}
                  style={{ minWidth: '89px',  }}
                  handler={exportReport}
                  text={t('WEARABLES.ACTIVITY.ACTIONS.EXPORT')}
                />
                {
                  disabled || userProfileError?.code === 5 ?
                    <Tooltip text={t('SOTER_TASK.VIDEO.FILL_PROFILE')}>
                      <Button
                  disabled={disabled || userProfileError?.code === 5}
                  style={{ minWidth: '89px', marginLeft: '18px', }}
                  handler={geniusHandler}
                  text={t('WEARABLES.ACTIVITY.ACTIONS.GENIUS_REPORT')}
                />
                    </Tooltip> : <Button
                      disabled={disabled || userProfileError?.code === 5}
                      style={{ minWidth: '89px', marginLeft: '18px', }}
                      handler={geniusHandler}
                      text={t('WEARABLES.ACTIVITY.ACTIONS.GENIUS_REPORT')}
                    /> }
                {videoLink && !disableVideoDownload && (
                  <div className={buttonTypeAndSize('default', 'default')}>
                    <a
                      href={videoLink}
                      download
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      {t('SOTER_TASK.VIDEO.DOWNLOAD')}
                    </a>
                  </div>
                )}
                <Button
                  text={getAcceptButtonText()}
                  style={{ margin: '0 18px', width: '92px' }}
                  className={button(COLORS.GREEN[600], isAccepted)}
                  handler={() => setIsAccepted(!isAccepted)}
                  onMouseEnter={() => setIsAcceptButtonHovered(true)}
                  onMouseLeave={() => setIsAcceptButtonHovered(false)}
                />
                <Button
                  text={t('GENERAL.DELETE')}
                  handler={removeVideo}
                  className={deleteButton}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <span className={date}>{uploadedAt}</span>
              <span className={date}>{t(getTypeText(videoType))}</span>
              <span className={date}>{t(categoryNames[category])}</span>
              <span className={date}>{createdBy}</span>
              {department?.value ? (
                <span className={date}>{department.label}</span>
              ) : null}
              {site?.value ? <span className={date}>{site.label}</span> : null}
              {taskName.label && videoType !== TaskVideoType.OTHER ? (
                <span className={date}>{taskName.label}</span>
              ) : null}
              {jobRole.label && videoType !== TaskVideoType.OTHER ? (
                <span className={date}>{jobRole.label}</span>
              ) : null}
              {repetition && videoType === TaskVideoType.TASK_ASSESSMENT ? (
                <span className={date}>
                  {`${t(
                    'SOTER_TASK.VIDEOS_LIST.TASK_REPETITION',
                  )}: ${repetition}`}
                </span>
              ) : null}
              {duration && videoType === TaskVideoType.TASK_ASSESSMENT ? (
                <span className={date}>
                  {`${t('SOTER_TASK.VIDEOS_LIST.DURATION')}: ${duration}m`}
                </span>
              ) : null}
              <span className={riskText(risk)}>{t(getRiskText(risk))}</span>
              <CustomTooltip
                text={t('SOTER_TASK.VIDEO.DISABLED_EDIT_HINT')}
                disabled={editingDisable}
                childrenStyle={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 2 }}
                placement="topEnd"
              >
                <div
                  className={editButton(editingDisable)}
                  onClick={() => !editingDisable && setIsEditing(true)}
                >
                  {t('GENERAL.EDIT')}
                </div>
              </CustomTooltip>
            </div>
          </>
        ) : (
          <div className={editingHeader(videoType)}>
            <div className={editingButtonsWrapper}>
              <button
                type="button"
                onClick={() => {
                  cancelHandler();
                  setIsEditing(false);
                }}
                style={{ background: 'none' }}
                className={headerControl(COLORS.RED[600])}
              >
                {t('GENERAL.CANCEL').toUpperCase()}
              </button>
              <button
                type="button"
                onClick={() => {
                  const trimmedTitle = title.trim();
                  if (!trimmedTitle) return setIsError(true);

                  const videoInfo = {
                    title: trimmedTitle,
                    taskName: taskName?.label,
                    repetition,
                    duration,
                    risk,
                    jobRole: jobRole.label,
                    description,
                    department: department?.value,
                    site: site?.value,
                  };
                  window.logEvent(TASK_VIDEO_EDITED, {
                    current_fields: videoInfo,
                  });
                  if (localStorage.getItem('showEvents')) {
                    dispatch(createEventNotification(`${TASK_VIDEO_EDITED}, current_fields: ${JSON.stringify(videoInfo)}`));
                  }
                  dispatch(updateVideo({ id, videoInfo }));
                  setIsEditing(false);
                }}
                style={{ background: 'none' }}
                className={`${headerControl(COLORS.LIGHT_BLUE[600])} ${
                  !title && disableBtn
                }`}
              >
                {t('SOTER_TASK.VIDEO.SAVE_CHANGES').toUpperCase()}
              </button>
            </div>
            <div>
              <label>{t('GENERAL.TITLE')}</label>
              <CustomInput value={title} onChange={(_, v) => {
                if (isError) setIsError(false);
                setTitle(v);
              }} />
              {isError && <span style={{color: COLORS.RED[600]}}>{t('FORM_VALIDATION.FORM_ERROR_REQUIRED')}</span>}
            </div>
            <div>
              <label>{t('SOTER_TASK.VIDEO.EVALUATED')}</label>
              <div style={{ display: 'flex' }}>
                {risk === Risks.LOW && (
                <div
                  className={DOTButton(COLORS.GREEN[600], risk === Risks.LOW)}
                  // onClick={() => setRisk(Risks.LOW)}
                >
                  {t('SOTER_TASK.RISKS.LOWER')}
                </div>
                )}
                {risk === Risks.MEDIUM && (
                <div
                  className={DOTButton(COLORS.ORANGE[600], risk === Risks.MEDIUM)}
                  // onClick={() => setRisk(Risks.MEDIUM)}
                >
                  {t('SOTER_TASK.RISKS.MEDIUM')}
                </div>
                )}
                {risk === Risks.HIGH && (
                <div
                  className={DOTButton(COLORS.RED[600], risk === Risks.HIGH)}
                  // onClick={() => setRisk(Risks.HIGH)}
                >
                  {t('SOTER_TASK.RISKS.HIGHER')}
                </div>
                )}
              </div>
            </div>
            <div>
              <label>{t('WEARABLES.OVERVIEW.DEPARTMENT')}</label>
              <CustomSelect
                id="department"
                value={department}
                options={[
                  ...departments.map(({ name, id }) => ({
                    label: capitalizeFirstLetter(name),
                    value: id,
                  })),
                ]}
                isSearchable
                onChange={(_, v) => setDepartment(v)}
              />
            </div>
            <div>
              <label>{t('WEARABLES.OVERVIEW.SITE')}</label>
              <CustomSelect
                id="site"
                value={site}
                options={[
                  ...sites.map(({ name, id }) => ({ label: capitalizeFirstLetter(name), value: id })),
                ]}
                isSearchable
                onChange={(_, v) => setSite(v)}
              />
            </div>
            {videoType !== TaskVideoType.OTHER ? (
              <>
                <div>
                  <label>{t('SOTER_TASK.VIDEOS_LIST.TASK_NAME')}</label>
                  <CustomSelect
                    id="taskName"
                    value={taskName}
                    options={taskNames}
                    isSearchable
                    onChange={(_, v) => setTaskName(v)}
                  />
                </div>
                <div>
                  <label>{t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE')}</label>
                  <CustomSelect
                    id="jobRole"
                    value={jobRole}
                    options={jobRoles}
                    isSearchable
                    onChange={(_, v) => setJobRole(v)}
                  />
                </div>
              </>
            ) : null}
            {videoType === TaskVideoType.TASK_ASSESSMENT ? (
              <>
                <div>
                  <label>{t('SOTER_TASK.VIDEOS_LIST.TASK_REPETITION')}</label>
                  <CustomInput
                    type="text"
                    value={repetition}
                    onChange={(_, v) => {
                      +v <= 10000
                        ? setRepetition(validateToPositiveIntegrals(v))
                        : '';
                    }}
                    onClear={() => setRepetition('')}
                    placeholder={t('SOTER_TASK.VIDEOS_LIST.HOW_MANY_TIMES')}
                  />
                </div>
                <div>
                  <label>{t('SOTER_TASK.VIDEOS_LIST.DURATION')}</label>
                  <CustomInput
                    type="text"
                    value={duration}
                    onChange={(_, v) => {
                      +v <= 1440
                        ? setDuration(validateToPositiveIntegrals(v))
                        : '';
                    }}
                    onClear={() => setDuration('')}
                    placeholder={t(
                      'SOTER_TASK.VIDEOS_LIST.AVG_TIME_TO_COMPLETE',
                    )}
                  />
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableVideoHeader;
