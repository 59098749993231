// source: api/job_dictionary/v1/job_task_profile_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_job_dictionary_v1_job_profile_message_pb = require('../../../api/job_dictionary/v1/job_profile_message_pb.js');
goog.object.extend(proto, api_job_dictionary_v1_job_profile_message_pb);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryTask', null, global);
goog.exportSymbol('proto.job_dictionary.EditLimitedJobDictionaryTask', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryTask', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.EditLimitedJobDictionaryTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.EditLimitedJobDictionaryTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.EditLimitedJobDictionaryTask.displayName = 'proto.job_dictionary.EditLimitedJobDictionaryTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryTask.displayName = 'proto.job_dictionary.CreateJobDictionaryTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.JobDictionaryTask.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryTask.displayName = 'proto.job_dictionary.JobDictionaryTask';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.EditLimitedJobDictionaryTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    riskGroup: jspb.Message.getFieldWithDefault(msg, 2, 0),
    spineRiskGroup: jspb.Message.getFieldWithDefault(msg, 3, 0),
    shouldersRiskGroup: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kneeRiskGroup: jspb.Message.getFieldWithDefault(msg, 5, 0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.EditLimitedJobDictionaryTask;
  return proto.job_dictionary.EditLimitedJobDictionaryTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRiskGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpineRiskGroup(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShouldersRiskGroup(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKneeRiskGroup(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.EditLimitedJobDictionaryTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSpineRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getShouldersRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKneeRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 risk_group = 2;
 * @return {number}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.getRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.setRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 spine_risk_group = 3;
 * @return {number}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.getSpineRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.setSpineRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 shoulders_risk_group = 4;
 * @return {number}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.getShouldersRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.setShouldersRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 knee_risk_group = 5;
 * @return {number}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.getKneeRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.setKneeRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double duration = 6;
 * @return {number}
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTask} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTask.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobDictionaryProfileId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dotClassification: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    safetyCritical: jspb.Message.getFieldWithDefault(msg, 5, 0),
    taskOverview: jspb.Message.getFieldWithDefault(msg, 6, ""),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    riskGroup: jspb.Message.getFieldWithDefault(msg, 8, 0),
    spineRiskGroup: jspb.Message.getFieldWithDefault(msg, 9, 0),
    shouldersRiskGroup: jspb.Message.getFieldWithDefault(msg, 10, 0),
    kneeRiskGroup: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cpdFloorToWaistLift: msg.getCpdFloorToWaistLift_asB64(),
    cpdWaistToShoulderLift: msg.getCpdWaistToShoulderLift_asB64(),
    cpdOverheadLift: msg.getCpdOverheadLift_asB64(),
    cpdCarry: msg.getCpdCarry_asB64(),
    cpdPush: msg.getCpdPush_asB64(),
    cpdPull: msg.getCpdPull_asB64(),
    shoulderChartData: jspb.Message.getFieldWithDefault(msg, 18, ""),
    spineChartData: jspb.Message.getFieldWithDefault(msg, 19, ""),
    taskTitleId: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryTask}
 */
proto.job_dictionary.CreateJobDictionaryTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryTask;
  return proto.job_dictionary.CreateJobDictionaryTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryTask}
 */
proto.job_dictionary.CreateJobDictionaryTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobDictionaryProfileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDotClassification(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSafetyCritical(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskOverview(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRiskGroup(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpineRiskGroup(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShouldersRiskGroup(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKneeRiskGroup(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdFloorToWaistLift(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdWaistToShoulderLift(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdOverheadLift(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdCarry(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdPush(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdPull(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setShoulderChartData(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpineChartData(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskTitleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobDictionaryProfileId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDotClassification();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSafetyCritical();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTaskOverview();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSpineRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getShouldersRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getKneeRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCpdFloorToWaistLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getCpdWaistToShoulderLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getCpdOverheadLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getCpdCarry_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getCpdPush_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      16,
      f
    );
  }
  f = message.getCpdPull_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getShoulderChartData();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSpineChartData();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTaskTitleId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
};


/**
 * optional int32 job_dictionary_profile_id = 1;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getJobDictionaryProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setJobDictionaryProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 dot_classification = 3;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getDotClassification = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setDotClassification = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 safety_critical = 5;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getSafetyCritical = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setSafetyCritical = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string task_overview = 6;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getTaskOverview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setTaskOverview = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double duration = 7;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 risk_group = 8;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 spine_risk_group = 9;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getSpineRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setSpineRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 shoulders_risk_group = 10;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getShouldersRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setShouldersRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 knee_risk_group = 11;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getKneeRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setKneeRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bytes cpd_floor_to_waist_lift = 12;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdFloorToWaistLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes cpd_floor_to_waist_lift = 12;
 * This is a type-conversion wrapper around `getCpdFloorToWaistLift()`
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdFloorToWaistLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdFloorToWaistLift()));
};


/**
 * optional bytes cpd_floor_to_waist_lift = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdFloorToWaistLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdFloorToWaistLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdFloorToWaistLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setCpdFloorToWaistLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};


/**
 * optional bytes cpd_waist_to_shoulder_lift = 13;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdWaistToShoulderLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes cpd_waist_to_shoulder_lift = 13;
 * This is a type-conversion wrapper around `getCpdWaistToShoulderLift()`
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdWaistToShoulderLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdWaistToShoulderLift()));
};


/**
 * optional bytes cpd_waist_to_shoulder_lift = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdWaistToShoulderLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdWaistToShoulderLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdWaistToShoulderLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setCpdWaistToShoulderLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bytes cpd_overhead_lift = 14;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdOverheadLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes cpd_overhead_lift = 14;
 * This is a type-conversion wrapper around `getCpdOverheadLift()`
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdOverheadLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdOverheadLift()));
};


/**
 * optional bytes cpd_overhead_lift = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdOverheadLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdOverheadLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdOverheadLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setCpdOverheadLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional bytes cpd_carry = 15;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdCarry = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes cpd_carry = 15;
 * This is a type-conversion wrapper around `getCpdCarry()`
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdCarry_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdCarry()));
};


/**
 * optional bytes cpd_carry = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdCarry()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdCarry_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdCarry()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setCpdCarry = function(value) {
  return jspb.Message.setProto3BytesField(this, 15, value);
};


/**
 * optional bytes cpd_push = 16;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdPush = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes cpd_push = 16;
 * This is a type-conversion wrapper around `getCpdPush()`
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdPush_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdPush()));
};


/**
 * optional bytes cpd_push = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdPush()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdPush_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdPush()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setCpdPush = function(value) {
  return jspb.Message.setProto3BytesField(this, 16, value);
};


/**
 * optional bytes cpd_pull = 17;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdPull = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes cpd_pull = 17;
 * This is a type-conversion wrapper around `getCpdPull()`
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdPull_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdPull()));
};


/**
 * optional bytes cpd_pull = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdPull()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getCpdPull_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdPull()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setCpdPull = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};


/**
 * optional string shoulder_chart_data = 18;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getShoulderChartData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setShoulderChartData = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string spine_chart_data = 19;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getSpineChartData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setSpineChartData = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 task_title_id = 20;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.getTaskTitleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTask} returns this
 */
proto.job_dictionary.CreateJobDictionaryTask.prototype.setTaskTitleId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.JobDictionaryTask.repeatedFields_ = [7,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryTask.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dotClassification: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    safetyCritical: jspb.Message.getFieldWithDefault(msg, 5, 0),
    taskOverview: jspb.Message.getFieldWithDefault(msg, 6, ""),
    videosListList: jspb.Message.toObjectList(msg.getVideosListList(),
    api_job_dictionary_v1_job_profile_message_pb.VideoItem.toObject, includeInstance),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    riskGroup: jspb.Message.getFieldWithDefault(msg, 9, 0),
    spineRiskGroup: jspb.Message.getFieldWithDefault(msg, 10, 0),
    shouldersRiskGroup: jspb.Message.getFieldWithDefault(msg, 11, 0),
    kneeRiskGroup: jspb.Message.getFieldWithDefault(msg, 12, 0),
    cpdFloorToWaistLift: msg.getCpdFloorToWaistLift_asB64(),
    cpdWaistToShoulderLift: msg.getCpdWaistToShoulderLift_asB64(),
    cpdOverheadLift: msg.getCpdOverheadLift_asB64(),
    cpdCarry: msg.getCpdCarry_asB64(),
    cpdPush: msg.getCpdPush_asB64(),
    cpdPull: msg.getCpdPull_asB64(),
    shoulderChartData: jspb.Message.getFieldWithDefault(msg, 19, ""),
    spineChartData: jspb.Message.getFieldWithDefault(msg, 20, ""),
    videosTaskListList: jspb.Message.toObjectList(msg.getVideosTaskListList(),
    api_job_dictionary_v1_job_profile_message_pb.VideoItem.toObject, includeInstance),
    neckRiskGroup: jspb.Message.getFieldWithDefault(msg, 22, 0),
    repetition: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryTask}
 */
proto.job_dictionary.JobDictionaryTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryTask;
  return proto.job_dictionary.JobDictionaryTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryTask}
 */
proto.job_dictionary.JobDictionaryTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDotClassification(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSafetyCritical(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskOverview(value);
      break;
    case 7:
      var value = new api_job_dictionary_v1_job_profile_message_pb.VideoItem;
      reader.readMessage(value,api_job_dictionary_v1_job_profile_message_pb.VideoItem.deserializeBinaryFromReader);
      msg.addVideosList(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRiskGroup(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpineRiskGroup(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShouldersRiskGroup(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKneeRiskGroup(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdFloorToWaistLift(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdWaistToShoulderLift(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdOverheadLift(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdCarry(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdPush(value);
      break;
    case 18:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCpdPull(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setShoulderChartData(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpineChartData(value);
      break;
    case 21:
      var value = new api_job_dictionary_v1_job_profile_message_pb.VideoItem;
      reader.readMessage(value,api_job_dictionary_v1_job_profile_message_pb.VideoItem.deserializeBinaryFromReader);
      msg.addVideosTaskList(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckRiskGroup(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDotClassification();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSafetyCritical();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTaskOverview();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVideosListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      api_job_dictionary_v1_job_profile_message_pb.VideoItem.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSpineRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getShouldersRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getKneeRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCpdFloorToWaistLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getCpdWaistToShoulderLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getCpdOverheadLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getCpdCarry_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      16,
      f
    );
  }
  f = message.getCpdPush_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getCpdPull_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      18,
      f
    );
  }
  f = message.getShoulderChartData();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSpineChartData();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getVideosTaskListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      api_job_dictionary_v1_job_profile_message_pb.VideoItem.serializeBinaryToWriter
    );
  }
  f = message.getNeckRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 dot_classification = 3;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getDotClassification = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setDotClassification = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 safety_critical = 5;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getSafetyCritical = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setSafetyCritical = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string task_overview = 6;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getTaskOverview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setTaskOverview = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated VideoItem videos_list = 7;
 * @return {!Array<!proto.job_dictionary.VideoItem>}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getVideosListList = function() {
  return /** @type{!Array<!proto.job_dictionary.VideoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_job_dictionary_v1_job_profile_message_pb.VideoItem, 7));
};


/**
 * @param {!Array<!proto.job_dictionary.VideoItem>} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
*/
proto.job_dictionary.JobDictionaryTask.prototype.setVideosListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.job_dictionary.VideoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.VideoItem}
 */
proto.job_dictionary.JobDictionaryTask.prototype.addVideosList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.job_dictionary.VideoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.clearVideosListList = function() {
  return this.setVideosListList([]);
};


/**
 * optional double duration = 8;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 risk_group = 9;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 spine_risk_group = 10;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getSpineRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setSpineRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 shoulders_risk_group = 11;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getShouldersRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setShouldersRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 knee_risk_group = 12;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getKneeRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setKneeRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bytes cpd_floor_to_waist_lift = 13;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdFloorToWaistLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes cpd_floor_to_waist_lift = 13;
 * This is a type-conversion wrapper around `getCpdFloorToWaistLift()`
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdFloorToWaistLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdFloorToWaistLift()));
};


/**
 * optional bytes cpd_floor_to_waist_lift = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdFloorToWaistLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdFloorToWaistLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdFloorToWaistLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setCpdFloorToWaistLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bytes cpd_waist_to_shoulder_lift = 14;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdWaistToShoulderLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes cpd_waist_to_shoulder_lift = 14;
 * This is a type-conversion wrapper around `getCpdWaistToShoulderLift()`
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdWaistToShoulderLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdWaistToShoulderLift()));
};


/**
 * optional bytes cpd_waist_to_shoulder_lift = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdWaistToShoulderLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdWaistToShoulderLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdWaistToShoulderLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setCpdWaistToShoulderLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional bytes cpd_overhead_lift = 15;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdOverheadLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes cpd_overhead_lift = 15;
 * This is a type-conversion wrapper around `getCpdOverheadLift()`
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdOverheadLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdOverheadLift()));
};


/**
 * optional bytes cpd_overhead_lift = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdOverheadLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdOverheadLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdOverheadLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setCpdOverheadLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 15, value);
};


/**
 * optional bytes cpd_carry = 16;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdCarry = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes cpd_carry = 16;
 * This is a type-conversion wrapper around `getCpdCarry()`
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdCarry_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdCarry()));
};


/**
 * optional bytes cpd_carry = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdCarry()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdCarry_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdCarry()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setCpdCarry = function(value) {
  return jspb.Message.setProto3BytesField(this, 16, value);
};


/**
 * optional bytes cpd_push = 17;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdPush = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes cpd_push = 17;
 * This is a type-conversion wrapper around `getCpdPush()`
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdPush_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdPush()));
};


/**
 * optional bytes cpd_push = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdPush()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdPush_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdPush()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setCpdPush = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};


/**
 * optional bytes cpd_pull = 18;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdPull = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * optional bytes cpd_pull = 18;
 * This is a type-conversion wrapper around `getCpdPull()`
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdPull_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCpdPull()));
};


/**
 * optional bytes cpd_pull = 18;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCpdPull()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getCpdPull_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCpdPull()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setCpdPull = function(value) {
  return jspb.Message.setProto3BytesField(this, 18, value);
};


/**
 * optional string shoulder_chart_data = 19;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getShoulderChartData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setShoulderChartData = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string spine_chart_data = 20;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getSpineChartData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setSpineChartData = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated VideoItem videos_task_list = 21;
 * @return {!Array<!proto.job_dictionary.VideoItem>}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getVideosTaskListList = function() {
  return /** @type{!Array<!proto.job_dictionary.VideoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_job_dictionary_v1_job_profile_message_pb.VideoItem, 21));
};


/**
 * @param {!Array<!proto.job_dictionary.VideoItem>} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
*/
proto.job_dictionary.JobDictionaryTask.prototype.setVideosTaskListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.job_dictionary.VideoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.VideoItem}
 */
proto.job_dictionary.JobDictionaryTask.prototype.addVideosTaskList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.job_dictionary.VideoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.clearVideosTaskListList = function() {
  return this.setVideosTaskListList([]);
};


/**
 * optional int32 neck_risk_group = 22;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getNeckRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setNeckRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 repetition = 23;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTask.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTask} returns this
 */
proto.job_dictionary.JobDictionaryTask.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


goog.object.extend(exports, proto.job_dictionary);
