import * as Emotion from 'emotion';

export const pt0 = Emotion.css`
  padding-top: 0;
`;
export const pl0 = Emotion.css`
  padding-left: 0;
`;
export const pr0 = Emotion.css`
  padding-right: 0;
`;
export const pb0 = Emotion.css`
  padding-bottom: 0;
`;

export const pt1 = Emotion.css`
  padding-top: 0.25rem;
`;
export const pl1 = Emotion.css`
  padding-left: 0.25rem;
`;
export const pr1 = Emotion.css`
  padding-right: 0.25rem;
`;
export const pb1 = Emotion.css`
  padding-bottom: 0.25rem;
`;

export const pt2 = Emotion.css`
  padding-top: 0.5rem;
`;
export const pl2 = Emotion.css`
  padding-left: 0.5rem;
`;
export const pr2 = Emotion.css`
  padding-right: 0.5rem;
`;
export const pb2 = Emotion.css`
  padding-bottom: 0.5rem;
`;

export const pt3 = Emotion.css`
  padding-top: 0.75rem;
`;
export const pl3 = Emotion.css`
  padding-left: 0.75rem;
`;
export const pr3 = Emotion.css`
  padding-right: 0.75rem;
`;
export const pb3 = Emotion.css`
  padding-bottom: 0.75rem;
`;

export const pt4 = Emotion.css`
  padding-top: 1rem;
`;
export const pl4 = Emotion.css`
  padding-left: 1rem;
`;
export const pr4 = Emotion.css`
  padding-right: 1rem;
`;
export const pb4 = Emotion.css`
  padding-bottom: 1rem;
`;

export const pt5 = Emotion.css`
  padding-top: 1.5rem;
`;
export const pl5 = Emotion.css`
  padding-left: 1.5rem;
`;
export const pr5 = Emotion.css`
  padding-right: 1.5rem;
`;
export const pb5 = Emotion.css`
  padding-bottom: 1.5rem;
`;
