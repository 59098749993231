import { toCamel } from 'snake-camel';
import * as types from './actionTypes';
import {
  deviceHistoryRequest,
  deviceSetDepartmentRequest,
  deviceSetSiteRequest,
  devicesRequest,
  devicesService,
  getMetaData,
  userService,
  adminDevicesRequest,
  adminServiceDevices,
} from '../../../init/proto_client';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

// eslint-disable-next-line import/prefer-default-export
export function getDevices(companyId) {
  return async (dispatch) => {
    dispatch({
      type: types.DEVICES_TOGGLE_LOADING,
      isLoading: true,
    });
    devicesRequest.setCompanyId(companyId);
    await devicesService.getDevices(
      devicesRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch({
            type: types.DEVICES_ERROR,
            error,
          });
        } else {
          const devices = response.toObject().devicesList.map((device) => toCamel(device));
          dispatch({
            type: types.DEVICES_SET,
            devices,
          });
          dispatch({
            type: types.DEVICES_TOGGLE_LOADING,
            isLoading: false,
          });
        }
      },
    );
  };
}

export function setDevicesDepartment(devicesIds, departmentId) {
  return async (dispatch) => {
    deviceSetDepartmentRequest.setDeviceIdsList(devicesIds);
    deviceSetDepartmentRequest.setDepartmentId(departmentId);
    await devicesService.setDepartmentDevice(
      deviceSetDepartmentRequest,
      getMetaData(), (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.DEPARTMENT_FAILED_MSG,
          }));
          dispatch({
            type: types.DEVICES_ERROR,
            error,
          });
        }
        const devices = response.toObject().devicesList.map((device) => toCamel(device));
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.DEPARTMENT_SUCCESS_MSG,
        }));
        dispatch({
          type: types.DEVICES_SET,
          devices,
        });
      },
    );
  };
}

export function setDevicesSite(devicesIds, siteId) {
  return async (dispatch) => {
    deviceSetSiteRequest.setDeviceIdsList(devicesIds);
    deviceSetSiteRequest.setSiteId(siteId);
    await devicesService.setSiteDevice(
      deviceSetSiteRequest,
      getMetaData(), (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.DEPARTMENT_FAILED_MSG,
          }));
          dispatch({
            type: types.DEVICES_ERROR,
            error,
          });
        }
        const devices = response.toObject().devicesList.map((device) => toCamel(device));
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.SITE_SUCCESS_MSG,
        }));
        dispatch({
          type: types.DEVICES_SET,
          devices,
        });
      },
    );
  };
}

export function getDeviceHistory(deviceId) {
  return async (dispatch) => {
    deviceHistoryRequest.setDeviceId(deviceId);
    await userService.getDeviceLinkHistory(
      deviceHistoryRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GET_DEVICE_HISTORY_FAILED_MSG,
          }));
          dispatch({
            type: types.DEVICES_ERROR,
            error,
          });
        } else {
          dispatch({
            type: types.DEVICES_HISTORY_SET,
            deviceHistory: response.toObject().deviceLinkHistoryList,
          });
        }
      },
    );
  };
}

export const getAdminDevices = () => async (dispatch) => {
  // adminDevicesRequest.setDummy(true);
  dispatch({
    type: types.DEVICES_TOGGLE_LOADING,
    isLoading: true,
  });

  adminServiceDevices.getDevices(
    adminDevicesRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.SERVER_ERROR_MSG,
        }));
        dispatch({
          type: types.DEVICES_ERROR,
          error,
        });
      } else {
        const devices = response.toObject().devicesList.map((device) => toCamel(device));
        dispatch({
          type: types.DEVICES_SET,
          devices,
        });
        dispatch({
          type: types.DEVICES_TOGGLE_LOADING,
          isLoading: false,
        });
      }
    },
  );
};

export function resetDevices() {
  return { type: types.DEVICES_RESET };
}
