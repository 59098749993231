import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants/colors';
import { dateLabel } from '../../../assets/jss/containers/pdfLayersStyle';

const BottomLabel = ({ isGrayScale, reportDate, pageType }) => {
  const { t } = useTranslation();
  return (
    <div className={dateLabel(pageType)}>
      <div>
        Soter
        <span style={{ color: isGrayScale ? COLORS.GRAY[400] : COLORS.LIGHT_BLUE[600] }}>
          Analytics
        </span>
      </div>
      <div style={{ color: COLORS.GRAY[400] }}>
        {`${t(
          'WEARABLES.USER_PAGE.REPORT_GENERATED_AT',
        )} ${reportDate}. ${moment.tz.guess()} ${t(
          'GENERAL.TIMEZONE',
        ).toLowerCase()}`}
      </div>
    </div>
  );
};

export default BottomLabel;
