// @ts-ignore
import { DashboardUserChatProfileFileType } from '../../init/proto_client';
const userProfileFilesConfig = [
    {
        type: DashboardUserChatProfileFileType.FILE_HS_POLICY,
        title: 'SOTER_GENIUS.POLICY_MANUAL',
        required: true,
    },
    {
        type: DashboardUserChatProfileFileType.FILE_HS_PROCEDURES,
        title: 'SOTER_GENIUS.PROCEDURE_MANUAL',
        required: false,
    },
    {
        type: DashboardUserChatProfileFileType.FILE_CHEM_LIST,
        title: 'SOTER_GENIUS.CHEMICAL_LIST',
        required: false,
    },
    {
        type: DashboardUserChatProfileFileType.FILE_EQUIPMENT_LIST,
        title: 'SOTER_GENIUS.MACHINE_LIST',
        required: false,
    },
    {
        type: DashboardUserChatProfileFileType.FILE_INJURY,
        title: 'SOTER_GENIUS.INJURY_DATA',
        required: false,
    },
];
export default userProfileFilesConfig;
