export const SET_SELECTED_COACH = 'SET_SELECTED_COACH';
export const SET_SELECTED_ENTRIES = 'SET_SELECTED_ENTRIES';
export const REMOVE_SELECTED_ENTRIES = 'REMOVE_SELECTED_ENTRIES';
export const SET_SELECTED_CLIPGO = 'SET_SELECTED_CLIPGO';
export const REMOVE_SELECTED_COACH = 'REMOVE_SELECTED_COACH';
export const REMOVE_SELECTED_CLIPGO = 'REMOVE_SELECTED_CLIPGO';
export const SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES';
export const REMOVE_SELECTED_DEVICES = 'REMOVE_SELECTED_DEVICES';
export const SET_SELECTED_COMPANIES = 'SET_SELECTED_COMPANIES';
export const REMOVE_SELECTED_COMPANIES = 'REMOVE_SELECTED_COMPANIES';
export const SET_SELECTED_DASHBOARD_USERS = 'SET_SELECTED_DASHBOARD_USERS';
export const REMOVE_SELECTED_DASHBOARD_USERS = 'REMOVE_SELECTED_DASHBOARD_USERS';
export const SET_SELECTED_SOTERTASK_VIDEOS = 'SET_SELECTED_SOTERTASK_VIDEOS';
export const REMOVE_SELECTED_SOTERTASK_VIDEOS = 'REMOVE_SELECTED_SOTERTASK_VIDEOS';
export const RESET_ALL_SELECTED = 'RESET_ALL_SELECTED';
