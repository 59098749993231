import React from 'react';
import _map from 'lodash/map';
import * as Emotion from 'emotion';
import { Dropdown, Popover, Whisper } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../constants/colors';
import { actionDropdown } from '../../assets/jss/style';
import { CPD_MAX_KG_VALUE, weightUnitList } from '../constants';
import getActivityCellColor from '../utils/getActivityCellColor';
import CustomInput from '../../lib/ui/CustomInput';
import hexToRgba from '../../lib/utils/hexToRgba';

const table = Emotion.css`
   {
    border: 1px solid ${COLORS.GRAY[100]};
    border-radius: 3px;
    width: 517px;
    padding: 0 !important;
    margin: 5px 0 7px 0;
  }
`;
const cell = Emotion.css`
   {
    border-bottom: 1px solid ${COLORS.GRAY[100]};
    height: 43px;
    padding-left: 11px;
  }
`;
const headerCell = Emotion.css`
   {
    ${cell};
    font-size: 12px;
    text-transform: uppercase;
  }
`;
const cellWithBg = (color) => Emotion.css`
   {
    ${cell};
    background-color: ${color};
  }
`;
const coloredDropdownItem = (color) => Emotion.css`
  background-color: ${color};
  &:hover {
    cursor: pointer;
  }
`;
const dropdownMenu = Emotion.css`
  width: 200px;
  padding: 4px 4px 5px 4px;
`;

const divForCell = Emotion.css`
  width: 85px;
  height: 22px;
`;

const numberInput = Emotion.css`
  width: 65px;
  height: 22px;
`;

const cpdMap = {
  floorToWaistLift: 'JOB_DICTIONARY.CPD.FLOOR_TO_WAIST_LIFT',
  waistToShoulderLift: 'JOB_DICTIONARY.CPD.WAIST_TO_SHOULDER_LIFT',
  overheadLift: 'JOB_DICTIONARY.CPD.OVERHEAD_LIFT',
  carry: 'JOB_DICTIONARY.CPD.CARRY',
  push: 'JOB_DICTIONARY.CPD.PUSH',
  pull: 'JOB_DICTIONARY.CPD.PULL',
  sitting: 'JOB_DICTIONARY.CPD.SITTING',
  standing: 'JOB_DICTIONARY.CPD.STANDING',
  walking: 'JOB_DICTIONARY.CPD.WALKING',
  squatting: 'JOB_DICTIONARY.CPD.SQUATTING',
  kneeling: 'JOB_DICTIONARY.CPD.KNEELING',
  stairClimbing: 'JOB_DICTIONARY.CPD.STAIR_CLIMBING',
  ladderClimbing: 'JOB_DICTIONARY.CPD.LADDER_CLIMBING',
  bending: 'JOB_DICTIONARY.CPD.BENDING',
  sustainedFlexion: 'JOB_DICTIONARY.CPD.SUSTAINING_FLEXION',
  forwardReach: 'JOB_DICTIONARY.CPD.FORWARD_REACH',
  overheadReach: 'JOB_DICTIONARY.CPD.OVERHEAD_REACH',
  neckMovement: 'JOB_DICTIONARY.CPD.NECK_MOVEMENT',
  trunkMovement: 'JOB_DICTIONARY.CPD.TRUNK_MOVEMENT',
  wristMovement: 'JOB_DICTIONARY.CPD.WRIST_MOVEMENT',
  grip: 'JOB_DICTIONARY.CPD.GRIP',
  armControl: 'JOB_DICTIONARY.CPD.ARM_CONTROL',
  legControl: 'JOB_DICTIONARY.CPD.LEG_CONTROL',
  criticalBalance: 'JOB_DICTIONARY.CPD.CRITICAL_BALANCE',
};

const frequencyList = [
  'JOB_DICTIONARY.JOB_PROFILE.INFREQUENT',
  'JOB_DICTIONARY.JOB_PROFILE.OCCASIONAL',
  'JOB_DICTIONARY.JOB_PROFILE.FREQUENT',
  'JOB_DICTIONARY.JOB_PROFILE.CONSTANT',
];

const ActivityBreakdownTable = ({
  data,
  units,
  haveValue,
  isEditing,
  setData,
}) => {
  const { t } = useTranslation();
  const risks = [
    {
      title: t('JOB_DICTIONARY.JOB_PROFILE.VERY_HIGH'),
      color: getActivityCellColor(1, 'Very High'),
      value: COLORS.RED[600],
    },
    {
      title: t('JOB_DICTIONARY.JOB_PROFILE.HIGH'),
      color: getActivityCellColor(1, 'High'),
      value: COLORS.ORANGE[600],
    },
    {
      title: t('JOB_DICTIONARY.JOB_PROFILE.MODERATE'),
      color: getActivityCellColor(1, 'Moderate'),
      value: COLORS.YELLOW[600],
    },
    {
      title: t('JOB_DICTIONARY.JOB_PROFILE.LOW'),
      color: getActivityCellColor(1, 'Low'),
      value: COLORS.GREEN[200],
    },
    {
      title: t('JOB_DICTIONARY.JOB_PROFILE.NONE'),
      color: getActivityCellColor(1, 'None'),
      value: COLORS.WHITE,
    },
  ];
  const EditableDivForCell = ({ value }) => (
    <div className={divForCell} contentEditable={isEditing && haveValue}>
      {haveValue &&
        (value ? `${value.toFixed(units === 'st' ? 1 : 0)} ${units}` : '-')}
    </div>
  );
  const changeValueHandler = (index, key, d) => (type) => (val) => {
    const newArray = [...d];
    newArray[index][type] = val;
    setData({ ...data, [key]: newArray });
  };
  const RiskLevelDropdown = ({ value, setValue }) => {
    const triggerRef = React.createRef();
    const onSelect = () => {
      triggerRef.current.hide();
    };
    return (
      <Whisper
        trigger="click"
        placement="bottomEnd"
        triggerRef={triggerRef}
        speaker={(
          <Popover className={actionDropdown}>
            <Dropdown.Menu className={dropdownMenu} onSelect={onSelect}>
              {risks.map((riskItem) => (
                <Dropdown.Item
                  key={Math.random().toString()}
                  className={coloredDropdownItem(riskItem.color)}
                  onClick={() => {
                    setValue('color')(riskItem.value);
                  }}
                >
                  {riskItem.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Popover>
        )}
      >
        <div className={divForCell}>
          {haveValue && (
            <CustomInput
              className={numberInput}
              type="number"
              max={CPD_MAX_KG_VALUE / weightUnitList[units].coefficient}
              min={0}
              value={value}
              onChange={(_, val) => setValue('value')(
                val > CPD_MAX_KG_VALUE / weightUnitList[units].coefficient
                  ? CPD_MAX_KG_VALUE / weightUnitList[units].coefficient
                  : parseFloat(val),
              )}
            />
          )}
        </div>
      </Whisper>
    );
  };
  const ColorCell = ({ content, setValue }) => (
    <td className={cellWithBg(hexToRgba(content.color, 0.2))}>
      {isEditing ? (
        <RiskLevelDropdown setValue={setValue} value={content.value} />
      ) : (
        <EditableDivForCell value={content.value} />
      )}
    </td>
  );
  return (
    <table className={table}>
      <thead>
        <tr>
          <td className={cell} style={{ color: COLORS.GRAY[400] }}>
            {t('JOB_DICTIONARY.JOB_PROFILE.ACTIVITY')}
          </td>
          {_map(frequencyList, (f) => (
            <th key={`${f}${Math.random()}`} className={headerCell}>
              {t(f)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {_map(data, (d, key) => {
          const title = key.indexOf('cpd') === 0 ? key.slice(3)[0].toLowerCase() + key.slice(4, key.length) : key;
          return (
            <tr key={`${key}${Math.random()}`}>
              <td
                className={cell}
                style={{ fontWeight: 600 }}
              >
                {t(cpdMap[title])}
              </td>
              {_map(d, (value, index) => (
                <ColorCell
                  key={Math.random() + key}
                  content={value}
                  haveValue={haveValue}
                  setValue={changeValueHandler(index, key, d)}
                />
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ActivityBreakdownTable;
