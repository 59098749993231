import React from 'react';
import { flexRow, mt5 } from '../../../assets/soter-flex-styles';

const LayoutRow = ({ children }) => (
  <div style={{ marginTop: '6px' }} className={`${flexRow} ${mt5}`}>
    {children}
  </div>
);

export default LayoutRow;
