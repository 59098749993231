import * as Emotion from 'emotion';
import { COLORS } from '../../constants/colors';

export const tableMain = Emotion.css`
  padding: 0 !important;
  position: relative;
`;

export const tableCell = Emotion.css`
  padding: 0 !important;
`;

export const actionButton = Emotion.css`
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;

export const selectionCheckbox = Emotion.css`
{

  }
`;

export const headerCheckbox = Emotion.css`
  margin: -12px 0;
`;

export const tableHeaderCheckbox = Emotion.css`
  position: absolute;
  left: 10px;
  top: 2px;
  z-index: 50;
`;

export const programCell = Emotion.css`
  position: relative;
  max-height: 56px;
`;

export const actionDropdown = Emotion.css`
  padding: 0 !important;
  width: auto;
`;

export const actionColorRed = Emotion.css`
  span {
    color: ${COLORS.RED[600]} !important;
  }
`;

export const loader = Emotion.css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2147483647;
`;

export const divider = Emotion.css`
  margin: 0 !important;
`;

export const profileButton = Emotion.css`
  display: inline-block;
`;

export const selectPanel = Emotion.css`
  display: flex;
  margin-bottom: 16px;
`;

export const multiSelect = Emotion.css`
  border-radius: 0;
  margin-left: 10px;
`;

export const companySelect = Emotion.css`
  width: 162px;
  left: ${(window.innerWidth - 1174) / 2 + 1174 - 345}px;

  .rs-picker-toggle {
    border-radius: 3px;
  }

  span {
    color: ${COLORS.GRAY[950]};
    margin-top: 1px;
    margin-left: 7px;
  }

  i {
    position: relative;
    top: 1px;
    color: ${COLORS.GRAY[400]};
  }

  a[role='combobox'] {
    height: 38px;
    border-radius: 3px;
  }
`;

export const companySelectMenu = Emotion.css`
  z-index: 55;
`;

export const overflow = Emotion.css`
  overflow-y: scroll;
  height: ${window.innerHeight - 216}px;
  margin: 0 !important;
`;

export const achievementsWrapper = Emotion.css`
  display: flex;
  div {
    position: relative;
    span {
      position: absolute;
      right: -1px;
      top: -1px;
      width: 16px;
      height: 16px;
      background-color: white;
      border-radius: 30px;
      border: 1px solid #248bf2;
      color: #248bf2;
      font-size: 11px;
      text-align: center;
      font-weight: 500;
    }
  }
`;

export const smallText = (color) => Emotion.css`
  font-size: 12px;
  color: ${color};
`;

export const customSelectComponent = Emotion.css`
  width: 100%;

  .rs-picker-toggle-value {
    color: ${COLORS.GRAY[950]} !important;
  }
`;

export const customDateRangePicker = Emotion.css`
  .rs-picker-toggle-value,
  .rs-picker-toggle-textbox {
    color: ${COLORS.LIGHT_BLUE[600]} !important;
  }

  .rs-picker-toggle-value {
    font-size: 1rem;
  }

  .rs-picker-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rs-picker-toggle-caret {
    padding-top: 3px;
  }
`;

export const customSelectMenu = Emotion.css`
  z-index: 110;
`;

export const autocompleteMenu = Emotion.css`
  ${customSelectMenu};

  max-height: 200px;
  overflow: auto;
`;

export const customDropdownSelect = Emotion.css`
  && {
    border-radius: 0;
    border-color: ${COLORS.GRAY[300]};
    transition: border-color ease-in-out 0.3s;

    &:focus,
    &:focus-within {
      box-shadow: none;
    }

    input {
      font-size: 14px;
    }

    .react-dropdown-select-clear {
      margin-right: 0;
      transition: 0.2s color linear;
    }
  }
`;

export const absoluteCentered = Emotion.css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const modalOverflow = Emotion.css`
  max-height: 85vh;
  overflow: auto;
`;

export const text = (color = COLORS.WHITE, size = 12, weight = 400) => Emotion.css`
  color: ${color};
  font-size: ${size}px;
  font-weight: ${weight};
`;

export const subHeader = text(COLORS.GRAY[950], 14, 600);

export const button = (color, isPressed = false) => Emotion.css`
  border: 1px solid ${color} !important;
  background-color: ${isPressed ? color : COLORS.WHITE} !important;
  color: ${isPressed ? COLORS.WHITE : color} !important;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-width: 49px;
  &:hover {
    cursor: pointer;
    background-color: ${isPressed ? COLORS.WHITE : color} !important;
    color: ${isPressed ? color : COLORS.WHITE} !important;
  }
`;

export const deleteButton = Emotion.css`
  min-width: 71px;
  background-color: ${COLORS.RED[600]} !important;
  &:hover {
    background-color: ${COLORS.RED[700]} !important;
  }
`;

export const disabledButton = Emotion.css`
  ${button(COLORS.GRAY[400])};

  &:hover {
    cursor: not-allowed;
  }
`;

export const absoluteHorizontalLine = (top, left, goRight) => Emotion.css`
  top: ${top}px;
  left: ${goRight ? left + 200 : left}px;
  width: 136px;
  border-color: ${COLORS.GRAY[400]};
  position: absolute;
  z-index: 15;
`;

export const absoluteColoredLabel = (
  top,
  left,
  color,
  goRight,
  isEditing,
  isSelected,
) => Emotion.css`
  position: absolute;
  top: ${top}px;
  left: ${goRight ? left + 310 : left}px;
  padding: 6px;
  background-color: ${isSelected ? color : 'transparent'};
  color: ${isSelected ? COLORS.WHITE : color};
  border: 1px solid ${color};
  font-size: 16px;
  border-radius: 5px;
  min-width: ${isEditing ? '45px' : '113px'};
  white-space: nowrap;
  &:hover {
    cursor: ${isEditing ? 'pointer' : 'default'};
  }
`;

export const assessment = Emotion.css`
  height: inherit;
  overflow: scroll;
  padding: 0 9px;
`;

export const headerControl = (color) => Emotion.css`
  margin-right: 14px;
  margin-bottom: 14px;
  padding-right: 14px;
  font-weight: 600;
  color: ${color};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const assessmentTableTitle = Emotion.css`
  color: ${COLORS.GRAY[400]};
  margin-right: 4px;
  margin-bottom: 10px;
`;

export const date = Emotion.css`
  color: ${COLORS.GRAY[400]};
  font-size: 15px;
  margin-right: 21px;
`;

export const riskText = (risk) => Emotion.css`
  ${date};

  color: ${risk === 1 ? COLORS.GREEN[600] : risk === 2 ? COLORS.ORANGE[600] : COLORS.RED[600]};
`;

export const rebaRiskText = (color) => Emotion.css`
  ${date};
  color: ${color};
`;

export const pagination = Emotion.css`
  font-family: 'Mulish', sans-serif;
  font-size: 18px;
  padding: 15px;
  
  .rs-stack {
    font-size: 15px;
  }

  .rs-picker-toggle.rs-btn {
    width: 90px;
    border-radius: 3px;
  }

  .rs-picker-toggle-wrapper {
    margin: 0 8px;
  }

  .rs-picker-toggle-value {
    color: ${COLORS.GRAY[950]} !important;
  }

  .rs-pagination-btn {
    border: 1px solid #E0E4EB !important;
    border-radius: 3px;
    padding: 9px 14px;
  }

  .rs-pagination-btn {
    color: ${COLORS.GRAY[950]};

    &:hover {
      background: ${COLORS.LIGHT_BLUE[50]};
    }
  }

  .rs-pagination-btn-active,
  .rs-pagination-btn:focus {
    color: ${COLORS.WHITE};
    background: ${COLORS.LIGHT_BLUE[600]};
    box-shadow: none;
  }

  .rs-pagination.rs-pagination-xs {
    display: flex;

    button {
      border: none;
    }
  }
`;

export const tableIndent = Emotion.css`
  margin-bottom: 25px
`;
