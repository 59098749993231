import React from 'react';
import Button from './Button';

const ActionToggleButton = ({
  id,
  appearance = 'secondary',
  size,
  clickHandler,
  disabled,
  content,
  name,
  dataName,
  className,
  style = null,
  children,
  iconBeforeText,
  iconAfterText,
}) => (
  <Button
    text={content}
    appearance={appearance}
    size={content === 'Add' && size}
    type="button"
    handler={clickHandler}
    disabled={disabled}
    name={id || name}
    dataName={id ? `user-dropdown-${id}` : dataName}
    style={style && style}
    className={className && className}
    iconBeforeText={iconBeforeText}
    iconAfterText={iconAfterText}
  >
    {children}
  </Button>
);

export default ActionToggleButton;
