import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    events: [],
    notifications: [],
};
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setEventNotification: (state, action) => {
            state.events.push(action.payload);
        },
        resetEventNotification: (state, action) => {
            state.events = state.events.filter((event) => event.id !== action.payload);
        },
        showNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        resetNotification: (state, action) => {
            state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
        },
    },
});
export const { setEventNotification, resetEventNotification, showNotification, resetNotification, } = notificationsSlice.actions;
export default notificationsSlice.reducer;
