import * as Emotion from 'emotion';
import styled from '@emotion/styled';
import { COLORS } from 'constants/colors';

export const customRadioContainer = Emotion.css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  max-width: 92%;
  margin-left: 1.4rem;
  margin-right: 1.4rem;
`;

export const CustomRadioLabel = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${COLORS.WHITE};
  border: 1px solid #bebebe;
  transform: translate(0, -50%);
`;

export const CustomRadioButton = styled.input`
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translate(0, -50%);
  line-height: 16px;

  &:focus ~ ${CustomRadioLabel} {
    background: #ffffff;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 2px rgba(70, 127, 207, 0.25);
      transform: translate(-4%, -8%);
    }
  }
  &:focus:checked ~ ${CustomRadioLabel} {
    background: #248bf2;
    line-height: 16px;

    &::before {
      content: '';
      background-color: transparent;
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 2px rgba(70, 127, 207, 0.25);
      transform: translate(-4%, -8%);
    }
    &::after {
      content: '';
      display: inline-block;
      line-height: 16px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      width: 4px;
      height: 4px;
      background: ${COLORS.WHITE};
      transform: translate(130%, -470%);
    }
  }
  ${(props) => props.checked &&
    `
    &:checked + ${CustomRadioLabel} {
      background: #248BF2;
      &::after {
        content: "";
        display: inline-block;
        line-height: 16px;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: ${COLORS.WHITE};
        transform: translate(70%, -100%);
      }
    }
  `}
`;

export const inputLabel = Emotion.css`
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
`;

const formControl = Emotion.css`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: ${COLORS.GRAY[950]};
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: ${COLORS.GRAY[950]};
    background-color: #fff;
    border-color: #1991eb;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
  }
  &::placeholder {
    font-weight: 400;
    color: #adb5bd;
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    font-weight: 400;
    color: #adb5bd;
    opacity: 1;
  }
  &::-moz-placeholder {
    font-weight: 400;
    color: #adb5bd;
    opacity: 1;
  }
  &:disabled {
    background-color: #f8f9fa;
    opacity: 1;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const select = Emotion.css`
  ${formControl};

  display: inline-block;
  width: 100%;
  height: 2.375rem;
  padding: 0.4rem 1.75rem 0.4rem 0.75rem;
  line-height: 1.375rem;
  color: ${COLORS.GRAY[950]};
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='%23999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const formWrapper = Emotion.css`
  position: relative;

  .clearInput {
    padding: 0 7px;
    color: #a6a6a6;
    background: none;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    outline none;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    position: absolute;
  
    &:hover {
      color: ${COLORS.GRAY[950]};
    }
  }
`;

export const formControlInput = Emotion.css`
  ${formControl};

  width: 100%;
  &:focus {
    border-color: #1991eb;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 5px rgba(25, 145, 235, 0.5);
  }
  &:disabled {
    color: #868e96;
    background-color: #e9ecef;
  }
`;

export const errorInputStyle = Emotion.css`
  ${formControl};

  width: 100%;
  border-color: ${COLORS.RED[800]};

  &:focus {
    border-color: ${COLORS.RED[800]};
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
  }
`;

export const checkboxGroup = Emotion.css`
  margin-left: 12px;
  display: flex;
  justify-content: flex-end;
`;

export const customInput = Emotion.css`
  font-size: 14px;
  font-weight: normal;
  border-radius: 3px;
  &:hover,
  &:focus {
    border-color: ${COLORS.BLUE[1]};
  }
`;

export const errorInput = Emotion.css`
  border-color: ${COLORS.RED[600]};

  &:hover,
  &:focus {
    border-color: ${COLORS.RED[600]};
  }
`;

export const errorMessage = Emotion.css`
  position: absolute;
  bottom: -19px;
  font-size: 12px;
  font-weight: normal;
  color: ${COLORS.RED[600]};
`;
