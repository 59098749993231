import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  flexColumn,
  flexRow,
  infoMainText,
  infoSpan,
  mediumIcon,
  mt2,
  pl3,
  pr3,
} from '../../../../assets/soter-flex-styles';
import charge from '../../../../assets/img/icons/device/battery/battery.svg';
import volume from '../../../../assets/img/icons/device/volume.svg';
import vibro from '../../../../assets/img/icons/device/vibration/vibration.svg';
import active from '../../../../assets/img/icons/device/active.svg';
import inactive from '../../../../assets/img/icons/device/inactive.svg';
import NoDataInfo from '../../NoDataInfo';
import { TableType } from '../../../../containers/ItemTableContainer/constants/tableType';

const deviceIconMap = {
  last_battery_charge: charge,
  volume_status: volume,
  vibration_status: vibro,
  volume_on: active,
  volume_off: inactive,
};

const DeviceInfo = ({ device, userType }) => {
  const { t } = useTranslation();
  const noDataMessage = t('WEARABLES.USER_PAGE.NO_DEVICE_LINKED');
  if (!device) {
    return (
      <div style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      }}
      >
        <NoDataInfo noDataMessage={noDataMessage} />
      </div>
    );
  }

  const {
    macAddress,
    lastBatteryCharge,
    soundOn,
    vibroOn,
  } = device;

  const deviceInfo = userType === TableType.clipgo
    ? [{
      value: lastBatteryCharge === -1 ? t('GENERAL.NA') : `${lastBatteryCharge}%`,
      icon: deviceIconMap.last_battery_charge,
    }]
    : [{
      value: lastBatteryCharge === -1 ? t('GENERAL.NA') : `${lastBatteryCharge}%`,
      icon: deviceIconMap.last_battery_charge,
    },
    {
      value: soundOn ? deviceIconMap.volume_on : deviceIconMap.volume_off,
      icon: deviceIconMap.volume_status,
    },
    {
      value: vibroOn ? deviceIconMap.volume_on : deviceIconMap.volume_off,
      icon: deviceIconMap.vibration_status,
    }];

  return (
    <>
      <p className={infoMainText}>
        {macAddress.toUpperCase()}
      </p>
      <div className={`${flexRow} ${mt2} ${pr3} ${pl3}`}>
        {deviceInfo.map((info) => (
          <div className={flexColumn} style={{ display: 'flex', justifyContent: 'center' }} key={info.icon}>
            <div className={flexRow} style={{ justifyContent: 'flex-start' }}>
              <img className={mediumIcon} src={info.icon} alt="battery charge" />
              {info.value.includes('image/svg') ? (
                <img className={mediumIcon} src={info.value} alt="value" />
              ) : (
                <span className={infoSpan}>
                  {info.value}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DeviceInfo;
