import React from 'react';
import LoginPageContainer from '../LoginContainer/components/LoginPageContainer';

const ForgotPasswordPageContainer = ({ children }) => (
  <LoginPageContainer>
    {children}
  </LoginPageContainer>
);

export default ForgotPasswordPageContainer;
