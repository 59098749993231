import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../lib/ui/Button';

const AddTaskButton = ({ disabled, onClick }) => {
  const handleClick = () => {
    onClick();
  };
  const { t } = useTranslation();
  return (
    <Button
      type='button'
      appearance='text'
      text={t('WEARABLES.USER_PAGE.ADD_TASKS')}
      disabled={disabled}
      handler={handleClick}
      style={{ marginLeft: 'auto', textTransform: 'uppercase' }}
    />
  );
};

export default AddTaskButton;
