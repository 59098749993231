// source: api/soter-task/common/v1/video_status_enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.videoTaskStatus.TaskVideoStatus', null, global);
/**
 * @enum {number}
 */
proto.videoTaskStatus.TaskVideoStatus = {
  NONE: 0,
  NEW: 1,
  UPLOADING: 2,
  UPLOAD_FINISHED: 3,
  WAITING_FOR_FILTER: 4,
  WAITING_FOR_PROCESSING: 5,
  PROCESSING: 6,
  WAITING_FOR_APPROVAL: 7,
  APPROVED: 8,
  ARCHIVED: 9,
  PROCESSING_ERROR: 10
};

goog.object.extend(exports, proto.videoTaskStatus);
