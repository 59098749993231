import React from 'react';
import Button from 'lib/ui/Button';
import {
  coloredButton, mb2, mb4, mt4, subText,
} from 'assets/soter-flex-styles';
import { COLORS } from 'constants/colors';

const UploadUsersFillFailure = ({ handler }) => (
  <div style={{ textAlign: 'center' }} className={mb2}>
    <h4>Populate the file according to the template</h4>
    <p style={{ textAlign: 'center' }} className={`${subText} ${mb4}`}>
      Please download template, populate required fields and upload the file
      again
    </p>
    <Button
      style={{ textAlign: 'center', display: 'block', width: '100%', mt4 }}
      className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
      type="button"
      handler={handler}
      text="Back to previous screen"
    />
  </div>
);

export default UploadUsersFillFailure;
