import moment from 'moment';
import { add } from 'date-fns';
import { AdminTerms, Periods } from '../constants/constants';

export const datePickerAllTimeValues = (createdAtInMilliseconds) => [
  new Date(createdAtInMilliseconds * 1000),
  moment(Number(new Date().getTime())).endOf('day').toDate()
];
// eslint-disable-next-line import/prefer-default-export
export const getPeriods = (duration) => {
  const timeNow = moment().format('x');
  const PeriodDurationMs = {
    WEEK: 604800000,
    MONTH: 2592000000,
    YEAR: 31556952000,
  };

  switch (duration) {
    case Periods.YESTERDAY:
      return ([
        new Date(moment().subtract(1, 'days').startOf('day')),
        new Date(moment().subtract(1, 'days').endOf('day')),
      ]);
    case Periods.LAST_7_DAYS:
      return ([
        moment().subtract(6, 'days').startOf('day').toDate(),
        new Date(Math.round(+timeNow)),
      ]);
    case Periods.LAST_30_DAYS:
      return ([
        moment().subtract(29, 'days').startOf('day').toDate(),
        new Date(Math.round(+timeNow)),
      ]);
    default: return null;
  }
};

export const getDatePickerRanges = (allTimeValue, t) => ([
  {
    label: t(Periods.YESTERDAY),
    value: getPeriods(Periods.YESTERDAY),
  },
  {
    label: t(Periods.LAST_7_DAYS),
    value: getPeriods(Periods.LAST_7_DAYS),
  },
  {
    label: t(Periods.LAST_30_DAYS),
    value: getPeriods(Periods.LAST_30_DAYS),
  },
  {
    label: t(Periods.ALL_TIME),
    value: allTimeValue,
  },
]);

export const getAdminTerms = (duration, timeStart) => {
  const timeNow = timeStart || +moment().format('x');

  switch (duration) {
    case AdminTerms.TWO_WEEKS:
      return ([
        new Date(Math.round(timeNow)),
        add(timeNow, { weeks: 2 }),
      ]);
    case AdminTerms.ONE_MONTH:
      return ([
        new Date(Math.round(timeNow)),
        add(timeNow, { months: 1 }),
      ]);
    case AdminTerms.THREE_MONTHS:
      return ([
        new Date(Math.round(timeNow)),
        add(timeNow, { months: 3 }),
      ]);
    case AdminTerms.SIX_MONTHS:
      return ([
        new Date(Math.round(timeNow)),
        add(timeNow, { months: 6 }),
      ]);
    case AdminTerms.ONE_YEAR:
      return ([
        new Date(Math.round(timeNow)),
        add(timeNow, { years: 1 }),
      ]);
    default: return null;
  }
};

export const getAdminDatePickerRanges = (t, timeStart) => ([
  {
    label: t(AdminTerms.TWO_WEEKS),
    value: getAdminTerms(AdminTerms.TWO_WEEKS, timeStart),
  },
  {
    label: t(AdminTerms.ONE_MONTH),
    value: getAdminTerms(AdminTerms.ONE_MONTH, timeStart),
  },
  {
    label: t(AdminTerms.THREE_MONTHS),
    value: getAdminTerms(AdminTerms.THREE_MONTHS, timeStart),
  },
  {
    label: t(AdminTerms.SIX_MONTHS),
    value: getAdminTerms(AdminTerms.SIX_MONTHS, timeStart),
  },
  {
    label: t(AdminTerms.ONE_YEAR),
    value: getAdminTerms(AdminTerms.ONE_YEAR, timeStart),
  },
]);
