import getColor from './getColor';
import timeToPixels from './timeToPixels';

const mapDataForAngleChart = (data, dataKey, type, max, twistDataKey, isRaw, isTwistRaw) => {
  let maximum = max;
  const maxArcSize = 250;
  const isPercent = dataKey.indexOf('percentage') > -1;
  const isTime = dataKey.indexOf('minutes') > -1;
  if (!data) return [];
  data.forEach((d) => {
    const val = isPercent
      ? d[Object.keys(d)[0]].time_in_seconds_percentage[isRaw ? 'raw_value' : 'value']
      : isTime ? d[Object.keys(d)[0]].time_in_minutes[isRaw ? 'raw_value' : 'value']
        : d[Object.keys(d)[0]].overall_number[isRaw ? 'raw_value' : 'value'];
    if (val > maximum) maximum = val.toFixed(1);
  });
  return data.map((zone, index) => {
    if (index === 0) {
      return {
        count: 1,
        score: maxArcSize,
        value: 0,
        twist_score: 0,
        twist_value: 0,
        name: 'Angles 0-30',
        color: getColor(0, type),
        maximum,
      };
    } if (index === 1 && type === 'shoulders') {
      return {
        count: 1,
        score: maxArcSize,
        value: 0,
        twist_score: 0,
        twist_value: 0,
        name: 'Angles 30-60',
        color: getColor(1, type),
        maximum,
      };
    }
    const value = isRaw
      ? zone[Object.keys(zone)[0]][dataKey].raw_value
      : zone[Object.keys(zone)[0]][dataKey].value;
    const twistValue = isRaw || isTwistRaw
      ? zone[Object.keys(zone)[0]][twistDataKey]?.raw_value
      : zone[Object.keys(zone)[0]][twistDataKey]?.value;
    return {
      count: 1,
      score: timeToPixels(value, maximum, maxArcSize),
      twist_score: timeToPixels(twistValue, maximum, maxArcSize) || 0,
      value,
      twist_value: twistValue || 0,
      name: Object.keys(zone)[0],
      color: getColor(index, type, timeToPixels(value, maximum, maxArcSize), maxArcSize),
      maximum,
    };
  });
};

export default mapDataForAngleChart;
