import * as types from './actionTypes';

export function setOpenedModal(modalType, { modalID } = {}) {
  return ({ type: types.SET_OPENED_MODAL, modalType, modalID });
}

export function setCloseButtonAlternativeBehavior(flag) {
  return ({ type: types.SET_CLOSE_BUTTON_ALTERNATIVE_BEHAVIOR, flag });
}

export function resetOpenModal() {
  return ({ type: types.RESET_OPEN_MODAL });
}
