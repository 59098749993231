import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { text } from './text-elements';

export const card = Emotion.css`
  ${text};

  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

export const cardExtraSmall119 = Emotion.css`
  ${card};

  height: 129px;
  flex: 1 1 33.3333%;
  @media (min-width: 1280px) {
    flex: 1 1 16.6666%;
  }
`;

export const cardSmall132 = Emotion.css`
  ${card};

  height: 132px;
  @media (min-width: 992px) {
    flex: 1 1 50%;
  }
`;

export const cardMedium270 = Emotion.css`
  ${card};

  height: auto;
  flex: 1 1 100%;
  @media (min-width: 992px) {
    height: 270px;
    flex: 1 1 50%;
  }
`;

export const cardMediumWithHeight = (height) => Emotion.css`
  ${card};

  height: auto;
  flex: 1 1 100%;
  @media (min-width: 992px) {
    height: ${height}px;
    flex: 1 1 50%;
  }
`;

export const cardMediumWithHeight100 = (height) => Emotion.css`
  ${cardMediumWithHeight(height)};

  height: 100% !important;
`;

export const cardLarge = Emotion.css`
  ${card};

  flex: 0 1 100%;
  height: auto;

  @media (min-width: 1280px) {
    flex: 1 1 100%;
    width: 1153px;
  }
`;

export const cardHeader = Emotion.css`
  color: ${COLORS.GRAY[400]};
  font-size: 16px;
  background: none;
  width: 100%;
  padding: 1rem 1.5rem 0.5rem;
  display: flex;
  min-height: 3rem;
  align-items: center;
  margin-bottom: 0;
`;

export const cardBody = Emotion.css`
  flex: 1 1 auto;
  margin: 0;
  padding: 0.5rem 1.5rem 1rem;
  position: relative;
  border-radius: 8px;
`;
