/**
 * @fileoverview gRPC-Web generated client stub for job_dictionary
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/job_dictionary/v1/job_dictionary_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_job_dictionary_v1_job_profile_message_pb = require('../../../api/job_dictionary/v1/job_profile_message_pb.js')

var api_job_dictionary_v1_job_task_profile_message_pb = require('../../../api/job_dictionary/v1/job_task_profile_message_pb.js')
const proto = {};
proto.job_dictionary = require('./job_dictionary_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.job_dictionary.JobDictionaryClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.job_dictionary.JobDictionaryPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.JobDictionaryRequest,
 *   !proto.job_dictionary.JobDictionaryResponse>}
 */
const methodDescriptor_JobDictionary_GetJobDictionary = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/GetJobDictionary',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.JobDictionaryRequest,
  proto.job_dictionary.JobDictionaryResponse,
  /**
   * @param {!proto.job_dictionary.JobDictionaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.JobDictionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.getJobDictionary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetJobDictionary',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetJobDictionary,
      callback);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.getJobDictionary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetJobDictionary',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetJobDictionary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.JobDictionaryProfileRequest,
 *   !proto.job_dictionary.JobDictionaryProfileResponse>}
 */
const methodDescriptor_JobDictionary_GetJobDictionaryProfile = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/GetJobDictionaryProfile',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.JobDictionaryProfileRequest,
  proto.job_dictionary.JobDictionaryProfileResponse,
  /**
   * @param {!proto.job_dictionary.JobDictionaryProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.JobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.getJobDictionaryProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetJobDictionaryProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetJobDictionaryProfile,
      callback);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryProfileResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.getJobDictionaryProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetJobDictionaryProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetJobDictionaryProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.CreateJobDictionaryProfileRequest,
 *   !proto.job_dictionary.JobDictionaryProfileResponse>}
 */
const methodDescriptor_JobDictionary_CreateJobDictionaryProfile = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/CreateJobDictionaryProfile',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.CreateJobDictionaryProfileRequest,
  proto.job_dictionary.JobDictionaryProfileResponse,
  /**
   * @param {!proto.job_dictionary.CreateJobDictionaryProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.createJobDictionaryProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryProfile,
      callback);
};


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryProfileResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.createJobDictionaryProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.EditJobDictionaryProfileRequest,
 *   !proto.job_dictionary.JobDictionaryProfileResponse>}
 */
const methodDescriptor_JobDictionary_EditJobDictionaryProfile = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/EditJobDictionaryProfile',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.EditJobDictionaryProfileRequest,
  proto.job_dictionary.JobDictionaryProfileResponse,
  /**
   * @param {!proto.job_dictionary.EditJobDictionaryProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.EditJobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.editJobDictionaryProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/EditJobDictionaryProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_EditJobDictionaryProfile,
      callback);
};


/**
 * @param {!proto.job_dictionary.EditJobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryProfileResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.editJobDictionaryProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/EditJobDictionaryProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_EditJobDictionaryProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.RemoveJobDictionaryProfileRequest,
 *   !proto.job_dictionary.JobDictionaryResponse>}
 */
const methodDescriptor_JobDictionary_RemoveJobProfile = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/RemoveJobProfile',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.RemoveJobDictionaryProfileRequest,
  proto.job_dictionary.JobDictionaryResponse,
  /**
   * @param {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.removeJobProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/RemoveJobProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_RemoveJobProfile,
      callback);
};


/**
 * @param {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.removeJobProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/RemoveJobProfile',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_RemoveJobProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.JobDictionaryTaskRequest,
 *   !proto.job_dictionary.JobDictionaryTaskResponse>}
 */
const methodDescriptor_JobDictionary_GetJobDictionaryTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/GetJobDictionaryTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.JobDictionaryTaskRequest,
  proto.job_dictionary.JobDictionaryTaskResponse,
  /**
   * @param {!proto.job_dictionary.JobDictionaryTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.JobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.getJobDictionaryTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetJobDictionaryTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryTaskResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.getJobDictionaryTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetJobDictionaryTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.CreateJobDictionaryTaskRequest,
 *   !proto.job_dictionary.JobDictionaryTaskResponse>}
 */
const methodDescriptor_JobDictionary_CreateJobDictionaryTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/CreateJobDictionaryTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.CreateJobDictionaryTaskRequest,
  proto.job_dictionary.JobDictionaryTaskResponse,
  /**
   * @param {!proto.job_dictionary.CreateJobDictionaryTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.createJobDictionaryTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryTaskResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.createJobDictionaryTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.EditJobDictionaryTaskRequest,
 *   !proto.job_dictionary.JobDictionaryTaskResponse>}
 */
const methodDescriptor_JobDictionary_EditJobDictionaryTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/EditJobDictionaryTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.EditJobDictionaryTaskRequest,
  proto.job_dictionary.JobDictionaryTaskResponse,
  /**
   * @param {!proto.job_dictionary.EditJobDictionaryTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.EditJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.editJobDictionaryTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/EditJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_EditJobDictionaryTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.EditJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryTaskResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.editJobDictionaryTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/EditJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_EditJobDictionaryTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.RemoveJobDictionaryTaskRequest,
 *   !proto.job_dictionary.JobDictionaryProfileResponse>}
 */
const methodDescriptor_JobDictionary_RemoveJobDictionaryTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/RemoveJobDictionaryTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.RemoveJobDictionaryTaskRequest,
  proto.job_dictionary.JobDictionaryProfileResponse,
  /**
   * @param {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.removeJobDictionaryTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/RemoveJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_RemoveJobDictionaryTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryProfileResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.removeJobDictionaryTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/RemoveJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_RemoveJobDictionaryTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.EditLimitedJobDictionaryTaskRequest,
 *   !proto.job_dictionary.JobDictionaryProfileResponse>}
 */
const methodDescriptor_JobDictionary_EditLimitedJobDictionaryTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/EditLimitedJobDictionaryTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.EditLimitedJobDictionaryTaskRequest,
  proto.job_dictionary.JobDictionaryProfileResponse,
  /**
   * @param {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.editLimitedJobDictionaryTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/EditLimitedJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_EditLimitedJobDictionaryTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryProfileResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.editLimitedJobDictionaryTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/EditLimitedJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_EditLimitedJobDictionaryTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.CreateJobDictionaryTagRequest,
 *   !proto.job_dictionary.JobDictionaryTag>}
 */
const methodDescriptor_JobDictionary_CreateJobDictionaryTags = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/CreateJobDictionaryTags',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.CreateJobDictionaryTagRequest,
  proto.job_dictionary.JobDictionaryTag,
  /**
   * @param {!proto.job_dictionary.CreateJobDictionaryTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryTag.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryTag)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryTag>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.createJobDictionaryTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryTags',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryTags,
      callback);
};


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryTag>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.createJobDictionaryTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryTags',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest,
 *   !proto.job_dictionary.CreateJobDictionaryVideoResponse>}
 */
const methodDescriptor_JobDictionary_CreateJobDictionaryYoutubeVideo = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/CreateJobDictionaryYoutubeVideo',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest,
  proto.job_dictionary.CreateJobDictionaryVideoResponse,
  /**
   * @param {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.CreateJobDictionaryVideoResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.CreateJobDictionaryVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.CreateJobDictionaryVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.createJobDictionaryYoutubeVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryYoutubeVideo',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryYoutubeVideo,
      callback);
};


/**
 * @param {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.CreateJobDictionaryVideoResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.createJobDictionaryYoutubeVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/CreateJobDictionaryYoutubeVideo',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_CreateJobDictionaryYoutubeVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.RemoveJobDictionaryVideoRequest,
 *   !proto.job_dictionary.RemoveJobDictionaryVideoResponse>}
 */
const methodDescriptor_JobDictionary_RemoveJobDictionaryYoutubeVideo = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/RemoveJobDictionaryYoutubeVideo',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.RemoveJobDictionaryVideoRequest,
  proto.job_dictionary.RemoveJobDictionaryVideoResponse,
  /**
   * @param {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.RemoveJobDictionaryVideoResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.RemoveJobDictionaryVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.RemoveJobDictionaryVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.removeJobDictionaryYoutubeVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/RemoveJobDictionaryYoutubeVideo',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_RemoveJobDictionaryYoutubeVideo,
      callback);
};


/**
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.RemoveJobDictionaryVideoResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.removeJobDictionaryYoutubeVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/RemoveJobDictionaryYoutubeVideo',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_RemoveJobDictionaryYoutubeVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.DetachJobDictionaryTaskVideoRequest,
 *   !proto.job_dictionary.DetachJobDictionaryTaskVideoResponse>}
 */
const methodDescriptor_JobDictionary_DetachJobDictionaryTaskVideo = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/DetachJobDictionaryTaskVideo',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.DetachJobDictionaryTaskVideoRequest,
  proto.job_dictionary.DetachJobDictionaryTaskVideoResponse,
  /**
   * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.DetachJobDictionaryTaskVideoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.detachJobDictionaryTaskVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/DetachJobDictionaryTaskVideo',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_DetachJobDictionaryTaskVideo,
      callback);
};


/**
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.detachJobDictionaryTaskVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/DetachJobDictionaryTaskVideo',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_DetachJobDictionaryTaskVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.JobDictionaryRequest,
 *   !proto.job_dictionary.SoterTaskVideosResponse>}
 */
const methodDescriptor_JobDictionary_GetVideosForSoterTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/GetVideosForSoterTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.JobDictionaryRequest,
  proto.job_dictionary.SoterTaskVideosResponse,
  /**
   * @param {!proto.job_dictionary.JobDictionaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.SoterTaskVideosResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.JobDictionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.SoterTaskVideosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.SoterTaskVideosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.getVideosForSoterTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetVideosForSoterTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetVideosForSoterTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.SoterTaskVideosResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.getVideosForSoterTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/GetVideosForSoterTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_GetVideosForSoterTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.job_dictionary.SetTaskVideoRequest,
 *   !proto.job_dictionary.JobDictionaryTaskResponse>}
 */
const methodDescriptor_JobDictionary_SetTaskVideoForJobDictionaryTask = new grpc.web.MethodDescriptor(
  '/job_dictionary.JobDictionary/SetTaskVideoForJobDictionaryTask',
  grpc.web.MethodType.UNARY,
  proto.job_dictionary.SetTaskVideoRequest,
  proto.job_dictionary.JobDictionaryTaskResponse,
  /**
   * @param {!proto.job_dictionary.SetTaskVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinary
);


/**
 * @param {!proto.job_dictionary.SetTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.job_dictionary.JobDictionaryTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.job_dictionary.JobDictionaryTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.job_dictionary.JobDictionaryClient.prototype.setTaskVideoForJobDictionaryTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/job_dictionary.JobDictionary/SetTaskVideoForJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_SetTaskVideoForJobDictionaryTask,
      callback);
};


/**
 * @param {!proto.job_dictionary.SetTaskVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.job_dictionary.JobDictionaryTaskResponse>}
 *     Promise that resolves to the response
 */
proto.job_dictionary.JobDictionaryPromiseClient.prototype.setTaskVideoForJobDictionaryTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/job_dictionary.JobDictionary/SetTaskVideoForJobDictionaryTask',
      request,
      metadata || {},
      methodDescriptor_JobDictionary_SetTaskVideoForJobDictionaryTask);
};


module.exports = proto.job_dictionary;

