import React from 'react';
import { COLORS } from 'constants/colors';
import { mb2, mb6, mt6 } from '../../assets/soter-flex-styles';
import { text } from '../../assets/jss/style';
import Button from './Button';

const DeleteEntityDialog = ({
  confirmText,
  buttonText,
  onSubmit,
  additionalText,
  children,
}) => (
  <div className={mb2}>
    <h4 style={{ width: '95%' }} className={additionalText ? mb2 : mb6}>
      {confirmText}
    </h4>
    {additionalText ? (
      <div className={`${text(COLORS.GRAY[400], 16)} ${mb6}`}>{additionalText}</div>
    ) : null}
    {children}
    <Button
      style={{ textAlign: 'center', display: 'block', width: '100%' }}
      className={`${children && mt6}`}
      text={buttonText}
      handler={onSubmit}
    />
  </div>
);

export default DeleteEntityDialog;
