import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'lib/ui/Button';
import { COLORS } from '../../constants/colors';
import { coloredButton, mb8, mt4 } from '../../assets/soter-flex-styles';

const SubscriptionExpiredDialog = ({ contactHandler }) => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center' }}>
      <h4 className={`${mb8} ${mt4}`}>
        {t('SOTER_TASK.SUBSCRIPTION_DUE_DIALOG.HEADER')}
      </h4>
      <div>{t('SOTER_TASK.SUBSCRIPTION_DUE_DIALOG.TEXT1')}</div>
      <div className={mb8}>{t('SOTER_TASK.OVERVIEW.UPGRADE')}</div>
      <Button
        style={{
          display: 'block', textAlign: 'center', margin: '0 auto', width: '60%',
        }}
        handler={contactHandler}
        className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
        text={t('SOTER_TASK.SUBSCRIPTION_DUE_DIALOG.BUTTON')}
      />
    </div>
  );
};

export default SubscriptionExpiredDialog;
