// source: api/soter-task/common/v1/assessment_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.assessmentTask.AssessmentCategory', null, global);
goog.exportSymbol('proto.assessmentTask.AssessmentField', null, global);
goog.exportSymbol('proto.assessmentTask.AssessmentFieldType', null, global);
goog.exportSymbol('proto.assessmentTask.AssessmentResponse', null, global);
goog.exportSymbol('proto.assessmentTask.AssessmentStatus', null, global);
goog.exportSymbol('proto.assessmentTask.AssessmentType', null, global);
goog.exportSymbol('proto.assessmentTask.AssessmentsResponse', null, global);
goog.exportSymbol('proto.assessmentTask.CreateAssessmentRequest', null, global);
goog.exportSymbol('proto.assessmentTask.DeleteAssessmentRequest', null, global);
goog.exportSymbol('proto.assessmentTask.GetAssessmentListRequest', null, global);
goog.exportSymbol('proto.assessmentTask.GetAssessmentsForVideoRequest', null, global);
goog.exportSymbol('proto.assessmentTask.UpdateAssessmentRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.GetAssessmentsForVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assessmentTask.GetAssessmentsForVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.GetAssessmentsForVideoRequest.displayName = 'proto.assessmentTask.GetAssessmentsForVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.AssessmentField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assessmentTask.AssessmentField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.AssessmentField.displayName = 'proto.assessmentTask.AssessmentField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.AssessmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assessmentTask.AssessmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.assessmentTask.AssessmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.AssessmentsResponse.displayName = 'proto.assessmentTask.AssessmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.AssessmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assessmentTask.AssessmentResponse.repeatedFields_, null);
};
goog.inherits(proto.assessmentTask.AssessmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.AssessmentResponse.displayName = 'proto.assessmentTask.AssessmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.CreateAssessmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assessmentTask.CreateAssessmentRequest.repeatedFields_, null);
};
goog.inherits(proto.assessmentTask.CreateAssessmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.CreateAssessmentRequest.displayName = 'proto.assessmentTask.CreateAssessmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.UpdateAssessmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assessmentTask.UpdateAssessmentRequest.repeatedFields_, null);
};
goog.inherits(proto.assessmentTask.UpdateAssessmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.UpdateAssessmentRequest.displayName = 'proto.assessmentTask.UpdateAssessmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.DeleteAssessmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assessmentTask.DeleteAssessmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.DeleteAssessmentRequest.displayName = 'proto.assessmentTask.DeleteAssessmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.AssessmentStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assessmentTask.AssessmentStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.AssessmentStatus.displayName = 'proto.assessmentTask.AssessmentStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assessmentTask.GetAssessmentListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assessmentTask.GetAssessmentListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assessmentTask.GetAssessmentListRequest.displayName = 'proto.assessmentTask.GetAssessmentListRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.GetAssessmentsForVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.GetAssessmentsForVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.GetAssessmentsForVideoRequest}
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.GetAssessmentsForVideoRequest;
  return proto.assessmentTask.GetAssessmentsForVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.GetAssessmentsForVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.GetAssessmentsForVideoRequest}
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.GetAssessmentsForVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.GetAssessmentsForVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 video_id = 1;
 * @return {number}
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.GetAssessmentsForVideoRequest} returns this
 */
proto.assessmentTask.GetAssessmentsForVideoRequest.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.AssessmentField.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.AssessmentField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.AssessmentField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.AssessmentField}
 */
proto.assessmentTask.AssessmentField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.AssessmentField;
  return proto.assessmentTask.AssessmentField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.AssessmentField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.AssessmentField}
 */
proto.assessmentTask.AssessmentField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.assessmentTask.AssessmentFieldType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.AssessmentField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.AssessmentField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.AssessmentField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.assessmentTask.AssessmentField.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentField} returns this
 */
proto.assessmentTask.AssessmentField.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.assessmentTask.AssessmentField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assessmentTask.AssessmentField} returns this
 */
proto.assessmentTask.AssessmentField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float value = 3;
 * @return {number}
 */
proto.assessmentTask.AssessmentField.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentField} returns this
 */
proto.assessmentTask.AssessmentField.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional AssessmentFieldType type = 4;
 * @return {!proto.assessmentTask.AssessmentFieldType}
 */
proto.assessmentTask.AssessmentField.prototype.getType = function() {
  return /** @type {!proto.assessmentTask.AssessmentFieldType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.assessmentTask.AssessmentFieldType} value
 * @return {!proto.assessmentTask.AssessmentField} returns this
 */
proto.assessmentTask.AssessmentField.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assessmentTask.AssessmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.AssessmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.AssessmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.AssessmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentsList: jspb.Message.toObjectList(msg.getAssessmentsList(),
    proto.assessmentTask.AssessmentResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.AssessmentsResponse}
 */
proto.assessmentTask.AssessmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.AssessmentsResponse;
  return proto.assessmentTask.AssessmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.AssessmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.AssessmentsResponse}
 */
proto.assessmentTask.AssessmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assessmentTask.AssessmentResponse;
      reader.readMessage(value,proto.assessmentTask.AssessmentResponse.deserializeBinaryFromReader);
      msg.addAssessments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.AssessmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.AssessmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.AssessmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.assessmentTask.AssessmentResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssessmentResponse assessments = 1;
 * @return {!Array<!proto.assessmentTask.AssessmentResponse>}
 */
proto.assessmentTask.AssessmentsResponse.prototype.getAssessmentsList = function() {
  return /** @type{!Array<!proto.assessmentTask.AssessmentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assessmentTask.AssessmentResponse, 1));
};


/**
 * @param {!Array<!proto.assessmentTask.AssessmentResponse>} value
 * @return {!proto.assessmentTask.AssessmentsResponse} returns this
*/
proto.assessmentTask.AssessmentsResponse.prototype.setAssessmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.assessmentTask.AssessmentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assessmentTask.AssessmentResponse}
 */
proto.assessmentTask.AssessmentsResponse.prototype.addAssessments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.assessmentTask.AssessmentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assessmentTask.AssessmentsResponse} returns this
 */
proto.assessmentTask.AssessmentsResponse.prototype.clearAssessmentsList = function() {
  return this.setAssessmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assessmentTask.AssessmentResponse.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.AssessmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.AssessmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.AssessmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.assessmentTask.AssessmentField.toObject, includeInstance),
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    additionalTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    assessmentCategory: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.AssessmentResponse}
 */
proto.assessmentTask.AssessmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.AssessmentResponse;
  return proto.assessmentTask.AssessmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.AssessmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.AssessmentResponse}
 */
proto.assessmentTask.AssessmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.assessmentTask.AssessmentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 8:
      var value = new proto.assessmentTask.AssessmentField;
      reader.readMessage(value,proto.assessmentTask.AssessmentField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdditionalTotalScore(value);
      break;
    case 11:
      var value = /** @type {!proto.assessmentTask.AssessmentCategory} */ (reader.readEnum());
      msg.setAssessmentCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.AssessmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.AssessmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.AssessmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.assessmentTask.AssessmentField.serializeBinaryToWriter
    );
  }
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAdditionalTotalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getAssessmentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.assessmentTask.AssessmentResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AssessmentType type = 2;
 * @return {!proto.assessmentTask.AssessmentType}
 */
proto.assessmentTask.AssessmentResponse.prototype.getType = function() {
  return /** @type {!proto.assessmentTask.AssessmentType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.assessmentTask.AssessmentType} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.assessmentTask.AssessmentResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float total_score = 4;
 * @return {number}
 */
proto.assessmentTask.AssessmentResponse.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.assessmentTask.AssessmentResponse.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.assessmentTask.AssessmentResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string created_by = 7;
 * @return {string}
 */
proto.assessmentTask.AssessmentResponse.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated AssessmentField fields = 8;
 * @return {!Array<!proto.assessmentTask.AssessmentField>}
 */
proto.assessmentTask.AssessmentResponse.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.assessmentTask.AssessmentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assessmentTask.AssessmentField, 8));
};


/**
 * @param {!Array<!proto.assessmentTask.AssessmentField>} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
*/
proto.assessmentTask.AssessmentResponse.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.assessmentTask.AssessmentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assessmentTask.AssessmentField}
 */
proto.assessmentTask.AssessmentResponse.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.assessmentTask.AssessmentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional int32 task_video_id = 9;
 * @return {number}
 */
proto.assessmentTask.AssessmentResponse.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float additional_total_score = 10;
 * @return {number}
 */
proto.assessmentTask.AssessmentResponse.prototype.getAdditionalTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setAdditionalTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional AssessmentCategory assessment_category = 11;
 * @return {!proto.assessmentTask.AssessmentCategory}
 */
proto.assessmentTask.AssessmentResponse.prototype.getAssessmentCategory = function() {
  return /** @type {!proto.assessmentTask.AssessmentCategory} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.assessmentTask.AssessmentCategory} value
 * @return {!proto.assessmentTask.AssessmentResponse} returns this
 */
proto.assessmentTask.AssessmentResponse.prototype.setAssessmentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assessmentTask.CreateAssessmentRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.CreateAssessmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.CreateAssessmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.CreateAssessmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.assessmentTask.AssessmentField.toObject, includeInstance),
    additionalTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    assessmentCategory: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.CreateAssessmentRequest}
 */
proto.assessmentTask.CreateAssessmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.CreateAssessmentRequest;
  return proto.assessmentTask.CreateAssessmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.CreateAssessmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.CreateAssessmentRequest}
 */
proto.assessmentTask.CreateAssessmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.assessmentTask.AssessmentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    case 6:
      var value = new proto.assessmentTask.AssessmentField;
      reader.readMessage(value,proto.assessmentTask.AssessmentField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdditionalTotalScore(value);
      break;
    case 8:
      var value = /** @type {!proto.assessmentTask.AssessmentCategory} */ (reader.readEnum());
      msg.setAssessmentCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.CreateAssessmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.CreateAssessmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.CreateAssessmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.assessmentTask.AssessmentField.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalTotalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getAssessmentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional AssessmentType type = 1;
 * @return {!proto.assessmentTask.AssessmentType}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getType = function() {
  return /** @type {!proto.assessmentTask.AssessmentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.assessmentTask.AssessmentType} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float total_score = 3;
 * @return {number}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {number}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 task_video_id = 5;
 * @return {number}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated AssessmentField fields = 6;
 * @return {!Array<!proto.assessmentTask.AssessmentField>}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.assessmentTask.AssessmentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assessmentTask.AssessmentField, 6));
};


/**
 * @param {!Array<!proto.assessmentTask.AssessmentField>} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
*/
proto.assessmentTask.CreateAssessmentRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.assessmentTask.AssessmentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assessmentTask.AssessmentField}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.assessmentTask.AssessmentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional float additional_total_score = 7;
 * @return {number}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getAdditionalTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setAdditionalTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional AssessmentCategory assessment_category = 8;
 * @return {!proto.assessmentTask.AssessmentCategory}
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.getAssessmentCategory = function() {
  return /** @type {!proto.assessmentTask.AssessmentCategory} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.assessmentTask.AssessmentCategory} value
 * @return {!proto.assessmentTask.CreateAssessmentRequest} returns this
 */
proto.assessmentTask.CreateAssessmentRequest.prototype.setAssessmentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assessmentTask.UpdateAssessmentRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.UpdateAssessmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.UpdateAssessmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.UpdateAssessmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.assessmentTask.AssessmentField.toObject, includeInstance),
    additionalTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    assessmentCategory: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.UpdateAssessmentRequest}
 */
proto.assessmentTask.UpdateAssessmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.UpdateAssessmentRequest;
  return proto.assessmentTask.UpdateAssessmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.UpdateAssessmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.UpdateAssessmentRequest}
 */
proto.assessmentTask.UpdateAssessmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = new proto.assessmentTask.AssessmentField;
      reader.readMessage(value,proto.assessmentTask.AssessmentField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdditionalTotalScore(value);
      break;
    case 11:
      var value = /** @type {!proto.assessmentTask.AssessmentCategory} */ (reader.readEnum());
      msg.setAssessmentCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.UpdateAssessmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.UpdateAssessmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.UpdateAssessmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.assessmentTask.AssessmentField.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalTotalScore();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAssessmentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float total_score = 3;
 * @return {number}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {number}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated AssessmentField fields = 5;
 * @return {!Array<!proto.assessmentTask.AssessmentField>}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.assessmentTask.AssessmentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assessmentTask.AssessmentField, 5));
};


/**
 * @param {!Array<!proto.assessmentTask.AssessmentField>} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
*/
proto.assessmentTask.UpdateAssessmentRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.assessmentTask.AssessmentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assessmentTask.AssessmentField}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.assessmentTask.AssessmentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional float additional_total_score = 6;
 * @return {number}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getAdditionalTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.setAdditionalTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional AssessmentCategory assessment_category = 11;
 * @return {!proto.assessmentTask.AssessmentCategory}
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.getAssessmentCategory = function() {
  return /** @type {!proto.assessmentTask.AssessmentCategory} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.assessmentTask.AssessmentCategory} value
 * @return {!proto.assessmentTask.UpdateAssessmentRequest} returns this
 */
proto.assessmentTask.UpdateAssessmentRequest.prototype.setAssessmentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.DeleteAssessmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.DeleteAssessmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.DeleteAssessmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.DeleteAssessmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.DeleteAssessmentRequest}
 */
proto.assessmentTask.DeleteAssessmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.DeleteAssessmentRequest;
  return proto.assessmentTask.DeleteAssessmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.DeleteAssessmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.DeleteAssessmentRequest}
 */
proto.assessmentTask.DeleteAssessmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssessmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.DeleteAssessmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.DeleteAssessmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.DeleteAssessmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.DeleteAssessmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 assessment_id = 1;
 * @return {number}
 */
proto.assessmentTask.DeleteAssessmentRequest.prototype.getAssessmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.DeleteAssessmentRequest} returns this
 */
proto.assessmentTask.DeleteAssessmentRequest.prototype.setAssessmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.AssessmentStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.AssessmentStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.AssessmentStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.AssessmentStatus}
 */
proto.assessmentTask.AssessmentStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.AssessmentStatus;
  return proto.assessmentTask.AssessmentStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.AssessmentStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.AssessmentStatus}
 */
proto.assessmentTask.AssessmentStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.AssessmentStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.AssessmentStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.AssessmentStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.AssessmentStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.assessmentTask.AssessmentStatus.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assessmentTask.AssessmentStatus} returns this
 */
proto.assessmentTask.AssessmentStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assessmentTask.GetAssessmentListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assessmentTask.GetAssessmentListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assessmentTask.GetAssessmentListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.GetAssessmentListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assessmentTask.GetAssessmentListRequest}
 */
proto.assessmentTask.GetAssessmentListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assessmentTask.GetAssessmentListRequest;
  return proto.assessmentTask.GetAssessmentListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assessmentTask.GetAssessmentListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assessmentTask.GetAssessmentListRequest}
 */
proto.assessmentTask.GetAssessmentListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assessmentTask.GetAssessmentListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assessmentTask.GetAssessmentListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assessmentTask.GetAssessmentListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assessmentTask.GetAssessmentListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.assessmentTask.GetAssessmentListRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assessmentTask.GetAssessmentListRequest} returns this
 */
proto.assessmentTask.GetAssessmentListRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.assessmentTask.AssessmentType = {
  ASSESSMENTTYPENONE: 0,
  ASSESSMENTTYPECUSTOM: 1,
  ASSESSMENTTYPERULALEFT: 2,
  ASSESSMENTTYPERULARIGHT: 3,
  ASSESSMENTTYPEREBA: 4,
  ASSESSMENTTYPENIOSH: 5
};

/**
 * @enum {number}
 */
proto.assessmentTask.AssessmentFieldType = {
  ASSESSMENTFIELDTYPENONE: 0,
  ASSESSMENTFIELDTYPERADIO: 1,
  ASSESSMENTFIELDTYPECHECKBOX: 2
};

/**
 * @enum {number}
 */
proto.assessmentTask.AssessmentCategory = {
  ASSESSMENTCATEGORYNONE: 0,
  ASSESSMENTCATEGORYACCEPTABLERISK: 1,
  ASSESSMENTCATEGORYFURTHERINVESTIGATION: 3,
  ASSESSMENTCATEGORYIMPLEMENTCHANGE: 4
};

goog.object.extend(exports, proto.assessmentTask);
