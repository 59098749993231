import React from 'react';
import TableFilterPanel from './TableFilterPanel';
import { flexColumn } from '../../../assets/soter-flex-styles';
import SearchInputForUserTable from './SearchInpuForTable';
import { showFilterPanel } from '../utils/shownFilters';
import CustomDatePicker from './CustomDatepicker';

const Filters = ({ filteringParameters, type }) => (
  <div className={flexColumn}>
    <div style={{ display: 'flex' }}>
      {type !== 'adminDevices' && (
        <div style={{ maxWidth: '1081px', paddingRight: '12px' }}>
          <TableFilterPanel
            filteringParameters={filteringParameters}
            type={type}
          />
        </div>
      )}
      <div style={{
        maxWidth: '248px',
        minWidth: '95px',
        marginLeft: !showFilterPanel(type) && '-12px',
      }}
      >
        {type === 'feedbacks' ? <CustomDatePicker fullWidth /> :
          <SearchInputForUserTable
            type={type}
        />}
      </div>
    </div>
  </div>
);

export default Filters;
