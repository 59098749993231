import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MailIcon } from '../../assets/img/contactUs.svg';
import { COLORS } from '../../constants/colors';
import { coloredButton, mb2 } from '../../assets/soter-flex-styles';
import Button from './Button';

const ContactUsDialog = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <MailIcon />
      <h4 style={{ fontSize: '24px' }} className={`${mb2}`}>
        {t('SOTER_TASK.CONTACT_US_DIALOG.HEADER')}
      </h4>
      <div className={mb2} style={{ fontSize: '16px', textAlign: 'center' }}>
        {t('SOTER_TASK.CONTACT_US_DIALOG.SUB_HEADER')}
      </div>
      <Button
        handler={handleClose}
        style={{
          display: 'block', textAlign: 'center', margin: '0 auto', width: '60%',
        }}
        className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
        text={t('SOTER_TASK.CONTACT_US_DIALOG.BUTTON')}
      />
    </div>
  );
};

export default ContactUsDialog;
