/* eslint max-len:0 */
import _uniq from 'lodash/uniq';
import { getAvgHCByRisk } from './getAvgHCByRisk';

export const getCompletedSessionsCount = (data, key = 'completedSession') => data
  .reduce((acc, report) => (acc + (report.metric?.[key]?.value || 0)), 0);
export const getImprovementAvg = (data, isShoulder) => {
  const avgHCByRisk = isShoulder ? getAvgHCByRisk(data, 'shoulderHazardsChangeByRisk') : getAvgHCByRisk(data);
  const {
    higherStart, higherEnd, middleStart, middleEnd, lowerStart, lowerEnd,
  } = avgHCByRisk;
  const start = higherStart || middleStart || lowerStart;
  const end = higherEnd || middleEnd || lowerEnd;
  if (!end || !start) return 1;
  if (start < end) return 0;
  return (((end / start) - 1) * 100).toFixed(1);
};
// Gert improvements from new data source with date range
export const getAverageImprovement = (data = {}) => {
  const {
    higherStart, higherEnd, middleStart, middleEnd, lowerStart, lowerEnd,
  } = data;
  const start = higherStart || middleStart || lowerStart;
  const end = higherEnd || middleEnd || lowerEnd;
  if (!end || !start) return 1;
  if (start < end) return 0;
  return (((end / start) - 1) * 100).toFixed(1);
};
export const getActiveUsersCount = (data, key = 'activeUsers') => data
  .reduce((acc, report) => (acc + (report.metric?.[key]?.value || 0)), 0);
export const getNewUsersCount = (data, key = 'newUsers') => data
  .reduce((acc, report) => (acc + (report.metric?.[key]?.value || 0)), 0);
export const getFinishedUsersCount = (data, key = 'finishedUsers') => data
  .reduce((acc, report) => (acc + (report.metric?.[key]?.value || 0)), 0);
export const getActiveDevicesCount = (data) => _uniq(data
  .reduce((acc, report) => (acc.concat(report.metric?.devices?.deviceIdsList || [])), [])).length;
