import * as Emotion from 'emotion';

const baseBadge = Emotion.css`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
`;

// eslint-disable-next-line import/prefer-default-export
export const badge = (color) => Emotion.css`
  ${baseBadge};

  color: #fff;
  background-color: ${color};
  @media (min-width: 992px) and (max-width: 1280px) {
    display: none;
  }
`;
