export const wearables = 'wearables';
export const overview = 'overview';
export const activity = 'activity';
export const help = 'help';
export const task = 'soter-task';
export const gallery = 'gallery';
export const comparison = 'comparison';
export const jobs = 'jobs';
export const company = 'my-company';
export const categories = 'categories';
export const tags = 'tags';
export const genius = 'soter-genius';
export const chat = 'chat';
export const profile = 'profile';
export const entries = 'entries';
export const weight = 'weight';
export const users = 'users';
export const user = 'user';
export const taskPage = 'task';
export const admin = 'admin';
export const companies = 'companies';
export const devices = 'devices';
export const hubs = 'hubs';
export const feedbacks = 'feedbacks';
export const notFound = 'not-found';
export const anyNew = 'new';
export const auth = 'auth';
export const forgotPassword = 'forgot-password';
export const threeDimensional = 'three-dimensional';
export const setPassword = 'set-password';
export const authWithToken = 'auth-with-token';
export const emailConfirm = 'email-confirm';
export const singleSignOn = 'single-sign-on';
export const authRoutes = {
    forgotPassword,
    setPassword,
    authWithToken,
    emailConfirm,
    auth,
    singleSignOn,
    threeDimensional,
};
export const noCompanyIDRoutes = new Set(Object.values(authRoutes));
export const routes = {
    notFound,
    auth,
    wearables,
    overview,
    activity,
    help,
    task,
    gallery,
    comparison,
    jobs,
    company,
    categories,
    tags,
    weight,
    users,
    admin,
    companies,
    devices,
    hubs,
    feedbacks,
    taskPage,
    user,
    profile,
    anyNew,
    genius,
    chat,
};
export const navigationLevels = {
    domainName: 0,
    companyID: 1,
    area: 2,
    product: 3,
    page: 4,
};
