import React from 'react';
import Tooltip from 'lib/ui/Tooltip';
import sliceLongString from 'lib/utils/sliceLongString';
const StatusCell = ({ content }) => {
    const { text, type } = content;
    let color;
    switch (type) {
        case 'warning':
            color = '#FEA232';
            break;
        case 'error':
            color = '#D61C1C';
            break;
        case 'success':
            color = '#61B821';
            break;
        default: color = '#FEA232';
    }
    return (React.createElement("div", { style: { color } }, text.length < 15 ? text
        : (React.createElement(Tooltip, { noTranslation: true, text: text }, sliceLongString(text, 11)))));
};
export default StatusCell;
