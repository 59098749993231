import { Radio, RadioGroup } from 'rsuite';
import React from 'react';
import { mb3 } from '../../assets/soter-flex-styles';
import { subHeader } from '../../assets/jss/style';
import { ClipgoConfigureDeviceMethod } from '../../init/proto_client';

const DeviceTypeRadio = ({ onChange, configureMethod, splitHeader }) => (
  <>
    <div className={subHeader}>
      How would you like to change
      {splitHeader ? <br /> : ' '}
      device type?
    </div>
    <RadioGroup className={mb3} value={configureMethod} onChange={onChange}>
      <Radio name="advertising" value={ClipgoConfigureDeviceMethod.ADVERTISING}>Advertising</Radio>
      <Radio name="directConnect" value={ClipgoConfigureDeviceMethod.DIRECT_CONNECT}>
        Direct connect
      </Radio>
    </RadioGroup>
  </>
);

export default DeviceTypeRadio;
