import React from 'react';
import { headerControlsContainer, ml3 } from '../../../assets/soter-flex-styles';

const HeaderControls = ({ children }) => {
  const controlsBlock = React.Children.map(
    children,
    (child, index) => (
      index === 0 ? child : (
        <div className={ml3}>
          {child}
        </div>
      )
    ),
  );
  return (
    <div
      className={headerControlsContainer}
    >
      {controlsBlock}
    </div>
  );
};

export default HeaderControls;
