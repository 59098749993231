import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, useParams } from '@reach/router';
import { useSelector } from 'react-redux';
import { companySubtitle, companyTitle, editBtn, titleWrapper } from './styles';
import SoterTaskSettings from './SoterTaskSettings';
import Wearables from './Wearables';
import { getInfo } from '../utils';
import EditCompanyForm from './EditCompanyForm';
import Products from './Products';
import NavigateBackButton from '../../../lib/ui/NavigateBackButton';
import { getCompanyData } from '../../store/actions';
import { coloredButton, container, flexRow, mb8, mt4 } from '../../../assets/soter-flex-styles';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import PrivatePageHOC from '../../../containers/PrivatPageHOC/PrivatePageHOC';
import { getFirmwaresOptions } from '../../companies/company/utils/utils';
import { resetCompanyForm } from '../../store/adminCompaniesSlice';
import { sortByValue, sortByVersion } from '../../companies/company/utils/createCompanyFilters';
import { COLORS } from '../../../constants/colors';
import { admin, companies } from '../../../containers/PrivatPageHOC/constants/routes';
import Loader from '../../../lib/ui/Loader';
import { getSimpleSelectOptions } from '../../../lib/utils/getSimpleSelectOptions';
const EditCompany = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const { availableFirmwaresList, availableCoachingProgramList, availableTiersList, availableLanguagesList, selectedCompany, isLoading, } = useAppSelector((state) => state.adminCompanies);
    const { id } = useParams();
    const { t } = useTranslation();
    // @ts-ignore
    const { id: companyID } = useSelector((state) => state.companies.selectedCompany);
    const [editMode, setEditMode] = useState(false);
    const sortedFirmwares = getFirmwaresOptions(sortByVersion([...availableFirmwaresList]));
    const tierOptions = availableTiersList && sortByValue(getSimpleSelectOptions(availableTiersList));
    const renderData = () => {
        var _a;
        if (editMode) {
            return (React.createElement(EditCompanyForm, { selectedCompany: selectedCompany, editMode: editMode, setEditMode: setEditMode, firmwares: sortedFirmwares, tierOptions: tierOptions, availableCoachingProgramList: availableCoachingProgramList, languages: availableLanguagesList, t: t }));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: titleWrapper, style: { alignItems: 'flex-start', marginTop: 6 } },
                React.createElement("h2", { className: companyTitle }, selectedCompany.name),
                React.createElement("button", { className: `${coloredButton(COLORS.LIGHT_BLUE[600], 'small')} ${editBtn}`, onClick: () => setEditMode(true), type: "button" }, t('SOTER_TASK.ASSESSMENT.BUTTONS.EDIT'))),
            React.createElement("div", { className: companySubtitle, style: { marginBottom: 40 } }, `${t('ADMIN.REGION', 'Region')}: ${(_a = selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.region) === null || _a === void 0 ? void 0 : _a.label}`),
            React.createElement(Products, { company: selectedCompany, t: t }),
            (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.wearable.isAvailable) && selectedCompany.wearable.timeStart !== 0 && (React.createElement(Wearables, { company: selectedCompany, languages: availableLanguagesList, t: t })),
            (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.genius.isAvailable) && selectedCompany.genius.timeStart !== 0 && (React.createElement(SoterTaskSettings, { company: selectedCompany, t: t }))));
    };
    useEffect(() => {
        if (!selectedCompany.id) {
            if (id) {
                dispatch(getCompanyData(Number(id)));
                getInfo(dispatch);
            }
            else {
                navigate(`/${companyID}/${admin}/${companies}`);
            }
        }
        return () => {
            dispatch(resetCompanyForm());
        };
    }, []);
    return (React.createElement(React.Fragment, null, isLoading || !(selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.name) ? (React.createElement(Loader, null)) : (React.createElement("div", { className: `${container} ${mt4} ${mb8}` },
        React.createElement("div", { className: flexRow },
            React.createElement(NavigateBackButton, { name: t('ADMIN.GO_BACK', 'Navigate back'), navigateTo: `/${companyID}/${admin}/${companies}`, additionalHandler: () => false })),
        React.createElement("div", { style: { marginLeft: 8 } }, renderData())))));
};
export default PrivatePageHOC(EditCompany);
