import * as Emotion from 'emotion';

// eslint-disable-next-line import/prefer-default-export
export const progressBar = Emotion.css`
  width: 100%;
  height: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
   border: none;
   border-radius: 3px;
  &::-webkit-progress-bar{
    background: #F5F7FB;
    border-radius: 3px;
  }
  &::-webkit-progress-value {
    background: #248BF2;
    border-radius: 3px;
  }
  &::-moz-progress-bar {
    background: #248BF2;
    border-radius: 3px;
  }
`;
