import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import * as Emotion from 'emotion';
import _flatten from 'lodash/flatten';
import { navigate, useParams } from '@reach/router';
import moment from 'moment';
import { contactSalesProductType, TaskVideoStatus } from 'init/proto_client';
import { modalOverflow, text } from 'assets/jss/style';
import { useTranslation } from 'react-i18next';
import {
  card, container, flexRow, mb5,
} from 'assets/soter-flex-styles';
import {
  bodyPartNames,
  bodyPartNamesHackMap,
  CHART_HEIGHT,
  TIME_DISTRIBUTION_BAR_CHART_HEIGHT,
  TIME_DISTRIBUTION_BAR_CHART_WIDTH,
  VIDEO_HEIGHT,
  VIDEO_WIDTH,
} from './constants';
import EditableVideoHeader from './components/EditableVideoHeader';
import { COLORS } from '../../constants/colors';
import { SOTER_TASK_PDF_EXPORT, TASK_VIDEO_DELETED, TASK_VIDEO_MUTED_UNMUTED } from '../../constants/analyticsEvents';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import getThresholds from '../utils/getThresholds';
import useNumberOfTrialDaysLeftBanner from '../overview/useNumberOfTrialDaysLeftBanner';
import parseMovementData from '../utils/parseMovementData';
import prepareChartAnglesData from '../utils/prepareChartAnglesData';
import prepareChartAxisData from '../utils/prepareChartAxisData';
import prepareRisksForBarChart from '../utils/prepareRisksForBarChart';
import prepareRisksForHumanLabels from '../utils/prepareRisksForHumanLabels';
import useVideoTimeUpdateHandler from './utils/useVideoTimeUpdateHandler';
import {
  getRebaData,
  getRebaWeightsStream,
  getTaskVideo,
  getVideos,
  removeTaskVideo, updateVideoDescription, updateVideoStatus,
} from '../gallery/videosStore/actions';
import { resetAssessments } from './assessmentsStore/actions';
import { resetTaskVideos } from '../gallery/videosStore/videoSlice';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import exportToPdf from '../../containers/PdfContainer/utils/exportToPdf';
import { openedModalSelector } from '../../containers/ModalContainer/modalsStore/selectors';
import StickyOnScrollContainer from '../../containers/ItemTableContainer/components/StickyOnScrollContainer';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
import VideoPlayerWithStickman from '../components/VideoPlayerWIthStickman';
import AssessmentWrapper from './components/AssessmentWrapper';
import ModalWrapper from '../../containers/ModalContainer/ModalWrapper';
import UpgradeDialog from '../components/UpgradeDialog';
import { contactSales } from '../../auth/store/actions';
import ContactUsDialog from '../../lib/ui/ContactUsDialog';
import MovementDataDynamicLineChart from '../components/MovementDataDynamicLineChart';
import presentSeconds from '../../lib/utils/presentSeconds';
import AverageRebaScore from '../components/AverageRebaScore';
import BodyPartRiskDistribution from '../components/BodyPartsRiskDistribution';
import BodyPartsRiskScore from '../components/BodyPartsRiskScore';
import EditableTextCard from '../../lib/ui/EditableTextCard/EditableTextCard';
import sliceLongString from '../../lib/utils/sliceLongString';
import stripHtmlFromText from '../../lib/utils/stripHtmlFromText';
import Loader from '../../lib/ui/Loader';
import { routes } from '../../containers/PrivatPageHOC/constants/routes';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import VideoPdfContainer from './VideoPdfContainer';
import AssessmentPdfContainer from './AssessmentPdfContainer';
import capitalizeFirstLetter from '../../lib/utils/capitalizeFirstLetter';
import GeniusReportModal from './components/GeniusReportModal';

const changeableGrid = (isReba) => Emotion.css`
  display: grid;
  grid-template-areas:
    'header header'
    'video assessment'
    'chart ${isReba ? 'reba' : 'assessment'}'
    'risks risks'
    'description description';
  grid-template-columns: ${VIDEO_WIDTH}px 1fr;
  grid-gap: 12px;
`;

const VideoContainer = () => {
  const frameCallbackIsAvailable
    = 'requestVideoFrameCallback' in HTMLVideoElement.prototype;
  const videoRef = useRef();
  const canvasRef = useRef();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [isGeniusModalOpen, setIsGeniusModalOpen] = useState(false)
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const { assessmentsList } = useAppSelector(
    (state) => state.soterTaskAssessments,
  );
  const {
    movementData, rebaData, currentVideo, isLoading, isRebaReady,
  } = useAppSelector(
    (state) => state.videos,
  );
  const movementAndRebaData
    = (rebaData?.length &&
      movementData?.map((el, i) => el.map((el) => (!Array.isArray(el) && typeof el === 'object'
        ? { ...el, reba: rebaData?.length && rebaData[i][0] }
        : el))))
    || movementData;

  const arr = _flatten(rebaData);
  const maxRebaScore = arr.reduce((a, b) => Math.max(a, b), -Infinity);
  const { anglesConfig, risks } = currentVideo;
  const jobRoles = selectedCompany.companySegmentList
    .find(({ title }) => title === 'user_job_role')
    ?.tagsList.map(({ title, id }) => ({ label: capitalizeFirstLetter(title), value: id }));
  const taskNames = selectedCompany.companySegmentList
    .find(({ title }) => title === 'task_title')
    ?.tagsList.map(({ title, id }) => ({ label: capitalizeFirstLetter(title), value: id }));
  const { resources } = useAppSelector((state) => state.auth);
  const { departmentsList, sitesList } = selectedCompany;
  const thresholds = getThresholds(anglesConfig);
  const uploadedAt = moment(currentVideo.createdAt).format('D MMM YYYY');
  const headersForChart = bodyPartNames;
  headersForChart.reba = 'REBA';
  const bodyParts = Object.keys(headersForChart);
  const [selectedBodyPart, setSelectedBodyPart] = useState(bodyParts[0]);
  const videoLengthPixelRatio
    = ((movementAndRebaData?.length / 30) * 1000) / VIDEO_WIDTH;
  const canvasScale = VIDEO_HEIGHT / currentVideo?.videoHeightPx;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isRewoundVideo, setIsRewoundVideo] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [assessmentDownloading, setAssessmentDownloading] = useState(null);
  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  const [title, setTitle] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [department, setDepartment] = useState('');
  const [site, setSite] = useState('');
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState(0);
  const [repetition, setRepetition] = useState(0);
  const [risk, setRisk] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDataParsed, setIsDataParsed] = useState(false);
  const [currentThreshold, setCurrentThreshold] = useState(null);
  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('geniusProduct');
  const parsedData = useMemo(
    () => parseMovementData(movementAndRebaData, thresholds),
    [movementAndRebaData],
  );
  const chartAnglesData = useMemo(
    () => prepareChartAnglesData(
      movementAndRebaData,
      thresholds,
      VIDEO_WIDTH,
      CHART_HEIGHT,
      selectedBodyPart,
    ),
    [movementAndRebaData],
  );
  const chartAxisData = useMemo(
    () => prepareChartAxisData(
      movementAndRebaData,
      VIDEO_WIDTH,
      selectedBodyPart,
    ),
    [movementAndRebaData],
  );
  const risksForBarChart = useMemo(
    () => prepareRisksForBarChart(risks),
    [risks],
  );
  const risksForHumanLabel = useMemo(
    () => prepareRisksForHumanLabels(risks),
    [risks],
  );
  // for video and canvas synchronisation
  const frameChangeCallback = (n, m) => {
    setCurrentTimeMs(m.mediaTime * 1000);
    videoRef.current?.requestVideoFrameCallback(frameChangeCallback);
  };
  const timeUpdateHandler = useVideoTimeUpdateHandler(setCurrentTimeMs);
  const setVideoDataInitialValues = () => {
    const selectedSite = sitesList.find(({ id }) => id === currentVideo.siteId);
    const selectedDepartment = departmentsList.find(
      ({ id }) => id === currentVideo.departmentId,
    );
    setTitle(currentVideo?.title);
    setJobRole({
      value: currentVideo?.jobRole?.id,
      label: currentVideo?.jobRole?.title,
    });
    setTaskName({
      value: currentVideo?.taskName?.id,
      label: currentVideo?.taskName?.title,
    });
    setDuration(currentVideo?.taskDuration);
    setRepetition(currentVideo?.repetition);
    setRisk(currentVideo.risks?.overallRisk);
    setDescription(currentVideo?.description);
    setSite({ label: selectedSite?.name || '', value: selectedSite?.id });
    setDepartment({
      label: selectedDepartment?.name || '',
      value: selectedDepartment?.id,
    });
  };
  useEffect(() => {
    if (frameCallbackIsAvailable && !navigator.vendor.match(/apple/i)) {
      videoRef.current?.requestVideoFrameCallback(frameChangeCallback);
    } else {
      videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
    }
    return () => {
      videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
    };
  }, [videoRef]);
  // other effects
  useEffect(() => {
    if (parsedData?.length > 0) {
      setIsDataParsed(true);
    } else {
      setIsDataParsed(false);
    }
  }, [parsedData]);
  useEffect(() => {
    dispatch(getTaskVideo(Number(params?.id)));
    return () => {
      dispatch(resetAssessments());
      dispatch(resetTaskVideos());
    };
  }, [params.id]);
  useEffect(() => {
    if (currentVideo) setVideoDataInitialValues();
  }, [currentVideo]);
  useEffect(() => {
    if (currentVideo.id) {
      dispatch(getRebaWeightsStream(currentVideo.id));
      if (isRebaReady) dispatch(getRebaData(currentVideo.id));
    }
  }, [isRebaReady, currentVideo]);
  useEffect(() => {
    Object.keys(thresholds).forEach(
      (t) => selectedBodyPart.includes(t) && setCurrentThreshold(thresholds[t]),
    );
  }, [selectedBodyPart, anglesConfig]);
  useEffect(() => {
    if (isDownloading) {
      window.logEvent(SOTER_TASK_PDF_EXPORT);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(SOTER_TASK_PDF_EXPORT));
      }
      exportToPdf(
        'SoterTask',
        t('SOTER_TASK.VIDEO.VIDEO_DATA'),
        '#soter_task_report',
        'p',
      );
      setIsDownloading(false);
    }
  }, [isDownloading]);
  useEffect(() => {
    if (assessmentDownloading) {
      exportToPdf(
        'SoterTask',
        t('SOTER_TASK.VIDEO.ASSESSMENT'),
        `#assessment_report_${assessmentDownloading}`,
        'p',
      );
      setAssessmentDownloading(null);
    }
  }, [assessmentDownloading]);
  const { id: userId } = useAppSelector((state) => state.auth.profile);
  const openedModal = useAppSelector(openedModalSelector);
  const modalClass = modalOverflow;
  const overlayClass = Emotion.css`
    margin-top: 119px;
  `;
  const withCloseButton = true;
  const sendMuteAnalyticsEvent = () => {
    setIsMuted((prev) => {
      const analyticsParams = {
        is_muted: !prev,
      };
      window.logEvent(TASK_VIDEO_MUTED_UNMUTED, analyticsParams);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(`${TASK_VIDEO_MUTED_UNMUTED}: ${JSON.stringify(analyticsParams)}`));
      }
      return !prev;
    });
  };
  const toggleMuteBtn = () => {
    if (videoRef.current.volume > 0) {
      if (!isMuted) return;

      sendMuteAnalyticsEvent();
    } else {
      if (isMuted) return;

      sendMuteAnalyticsEvent();
    }
  };
  return (
    <>
      {numberOfTrialDaysLeftBanner && (
        <StickyOnScrollContainer
          topMargin={0}
          topBlock={numberOfTrialDaysLeftBanner}
          height={50}
        />
      )}
      {currentVideo && movementAndRebaData ? (
        <div
          className={`${container} ${changeableGrid(true)}`}
          style={{ marginTop: '8px' }}
        >
          <div
            style={{
              gridArea: 'header',
            }}
          >
            <div className={flexRow} style={{ marginLeft: '-12px' }}>
              <NavigateBackButton
                name={t('SOTER_TASK.VIDEO.BACK_TO_LIST')}
                navigateTo={`/${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.gallery}`}
              />
            </div>
            <EditableVideoHeader
              id={params.id}
              title={title}
              setTitle={setTitle}
              videoLink={currentVideo?.amazonDownloadUrl}
              removeVideo={() => {
                if (window.confirm(t('SOTER_TASK.VIDEO.SURE'))) {
                  dispatch(removeTaskVideo(params.id));
                  dispatch(getVideos(selectedCompany.id));
                  dispatch(resetAssessments());
                  window.logEvent(TASK_VIDEO_DELETED);
                  if (localStorage.getItem('showEvents')) {
                    dispatch(createEventNotification(TASK_VIDEO_DELETED));
                  }
                  navigate(
                    `../../../../${selectedCompany.id}/${routes.users}/${routes.genius}/${routes.gallery}`,
                  );
                }
              }}
              disabled={!isDataParsed}
              exportReport={() => setIsDownloading(true)}
              isAccepted={currentVideo?.status === TaskVideoStatus.APPROVED}
              setIsAccepted={(isAccepted) => dispatch(
                updateVideoStatus({
                  currentVideo,
                  getStatus: isAccepted ? TaskVideoStatus.APPROVED
                    : TaskVideoStatus.WAITING_FOR_APPROVAL,
                }),
              )}
              uploadedAt={uploadedAt}
              createdBy={currentVideo.createdBy?.name}
              taskName={taskName}
              setTaskName={setTaskName}
              setJobRole={setJobRole}
              duration={duration}
              description={description}
              setDuration={setDuration}
              repetition={repetition}
              setRepetition={setRepetition}
              jobRole={jobRole}
              jobRoles={jobRoles}
              taskNames={taskNames}
              risk={risk}
              setRisk={setRisk}
              cancelHandler={setVideoDataInitialValues}
              videoType={currentVideo?.videoType}
              category={currentVideo?.category}
              departments={
                resources.departmentsList ? [...resources.departmentsList] : []
              }
              sites={resources.sitesList ? [...resources.sitesList] : []}
              department={department}
              setDepartment={setDepartment}
              site={site}
              setSite={setSite}
              geniusHandler={() => setIsGeniusModalOpen(true)}
            />
          </div>
          <VideoPlayerWithStickman
            gridArea="video"
            currentVideo={currentVideo}
            canvasRef={canvasRef}
            videoRef={videoRef}
            videoHeight={VIDEO_HEIGHT}
            videoWidth={VIDEO_WIDTH}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            canvasScale={canvasScale}
            parsedData={parsedData}
            currentTimeMs={currentTimeMs}
            totalFrames={movementAndRebaData?.length}
            isDataParsed={isDataParsed}
            selectedBodyPart={selectedBodyPart}
            toggleMuteBtn={toggleMuteBtn}
            isRewoundVideo={isRewoundVideo}
            setIsRewoundVideo={setIsRewoundVideo}
          />
          <AssessmentWrapper
            currentTime={Math.floor(currentTimeMs)}
            taskVideoId={currentVideo.id}
            setCurrentTime={(time) => {
              videoRef.current.currentTime = time;
            }}
            setAssessmentDownloading={setAssessmentDownloading}
          />
          {openedModal === 'upgrade' || openedModal === 'contactSales' ? (
            <ModalWrapper
              closeModal={() => dispatch(setOpenedModal(null))}
              modalClass={modalClass}
              overlayClass={overlayClass}
              keepModalOnClickOutside
              withCloseButton={withCloseButton}
            >
              {openedModal === 'upgrade' ? (
                <UpgradeDialog
                  handleClick={() => {
                    dispatch(
                      contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK }),
                    );
                    dispatch(setOpenedModal('contactSales'));
                  }}
                  days={selectedCompany.products?.geniusProduct?.currentTermEnd}
                />
              ) : (
                <ContactUsDialog
                  handleClose={() => dispatch(setOpenedModal(null))}
                />
              )}
            </ModalWrapper>
          ) : null}
          {isDataParsed ? (
            <>
              <MovementDataDynamicLineChart
                gridArea="chart"
                videoWidth={VIDEO_WIDTH}
                bodyParts={bodyParts}
                selectedBodyPart={selectedBodyPart}
                setSelectedBodyPart={setSelectedBodyPart}
                totalTime={risks && presentSeconds(
                  risksForBarChart[bodyPartNamesHackMap[selectedBodyPart]]
                    ?.totalTime,
                )}
                totalFrames={presentSeconds(movementAndRebaData?.length / 30)}
                currentThreshold={currentThreshold}
                currentTimeMs={currentTimeMs}
                videoLengthPixelRatio={videoLengthPixelRatio}
                chartAnglesData={chartAnglesData}
                chartAxisData={chartAxisData}
                chartHeight={CHART_HEIGHT}
                videoId={currentVideo?.id}
              />
              <AverageRebaScore rebaData={rebaData} score={maxRebaScore || 1} isLoading={!isRebaReady && !rebaData.length} companyId={selectedCompany.id} videoId={currentVideo?.id} />
              {risks?.overallRisk ? (
                <div style={{ gridArea: 'risks' }} className={`${mb5}`}>
                  <div
                    style={{ marginBottom: '11px' }}
                    className={text(COLORS.GRAY[950], 26, 600)}
                  >
                    {t('SOTER_TASK.ASSESSMENT.TIME_SPLIT')}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <BodyPartRiskDistribution
                      isCard
                      gridArea="risks"
                      risks={risksForBarChart}
                      width={VIDEO_WIDTH}
                      barHeight={TIME_DISTRIBUTION_BAR_CHART_HEIGHT}
                      barWidth={TIME_DISTRIBUTION_BAR_CHART_WIDTH}
                    />
                    <div
                      style={{
                        marginLeft: '12px',
                        width: '461px',
                        paddingLeft: '60px',
                      }}
                      className={card}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '-60px',
                          left: '0px',
                          whiteSpace: 'nowrap',
                          fontSize: '26px',
                        }}
                        className={text(COLORS.GRAY[950], 32, 600)}
                      >
                        {t('SOTER_TASK.ASSESSMENT.BODY_GROUP')}
                      </div>
                      <BodyPartsRiskScore
                        risks={risksForHumanLabel}
                        selectedBodyPart={selectedBodyPart}
                        selectBodyPart={setSelectedBodyPart}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <EditableTextCard
                className={Emotion.css`
                  grid-area: description;
                  margin-bottom: 24px;
                `}
                text={currentVideo.description}
                title={t('GENERAL.DESCRIPTION')}
                id="soterTaskVideoDescription"
                saveValue={(v) => dispatch(updateVideoDescription({ videoInfo: currentVideo, description: v }))}
                height={269}
              />
              {isDownloading && (
                <VideoPdfContainer
                  chartAnglesData={chartAnglesData}
                  currentVideo={currentVideo}
                  risksForHumanLabel={risksForHumanLabel}
                  risksForBarChart={risksForBarChart}
                  chartAxisData={chartAxisData}
                  currentThreshold={currentThreshold}
                  bodyParts={bodyParts}
                  title={title}
                  uploadedAt={uploadedAt}
                  videoWidth={VIDEO_WIDTH}
                  chartHeight={CHART_HEIGHT}
                  description={sliceLongString(
                    stripHtmlFromText(currentVideo.description),
                    5800,
                  )}
                  movementAndRebaData={movementAndRebaData}
                  thresholds={thresholds}
                />
              )}
              {assessmentDownloading && (
                <AssessmentPdfContainer
                  assessment={assessmentsList.find(
                    ({ id }) => id === assessmentDownloading,
                  )}
                />
              )}
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </div>
      )}
      <GeniusReportModal isOpen={isGeniusModalOpen} closeModal={() => setIsGeniusModalOpen(false)} processedVideoData={{ processedVideoUrl: currentVideo.amazonProcessedUrl, videoTitle: currentVideo.title, videoId: currentVideo.id}}/>
    </>
  );
};

export default VideoContainer;
