import React from 'react';
import CustomTooltip from '../../../../lib/ui/Tooltip';
import sliceLongString from '../../../../lib/utils/sliceLongString';

const CommentCell = ({ rowData }) => {
  const comment = [];
  let i = 0;
  let currentLength = 0;
  const STRING_LENGTH = 100;
  rowData.comment.split(' ').forEach((word) => {
    if (currentLength + word.length > STRING_LENGTH) i += 1;
    if (comment[i]) {
      comment[i] += ` ${word}`;
      currentLength += word.length;
    } else {
      comment[i] = word;
      currentLength = word.length;
    }
  });
  return (
    <>
      {comment.map((c) => (
        <div key={c}>
          <CustomTooltip placement='topEnd' text={c}>
            <p key={c} style={{ margin: 0, fontSize: '14px' }}>
              {sliceLongString(c, 15)}
            </p>
          </CustomTooltip>
        </div>
      ))}
    </>
  );
};

export default CommentCell;
