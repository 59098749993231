import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { authMe } from './store/actions';
import Loader from '../lib/ui/Loader';

const OAuthRedirectWithToken = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const { profile } = useSelector((state) => state.auth);
  !profile && localStorage.setItem('token', token);
  useEffect(() => {
    setTimeout(() => setRedirect(true), 1000);
    dispatch(authMe());
  }, []);
  return redirect ? <Redirect to="/" /> : <Loader />;
};

export default OAuthRedirectWithToken;
