import { toCamel } from 'snake-camel';
import {
  companiesService,
  companiesRequest,
  createDepartmentRequest,
  removeDepartmentRequest,
  editDepartmentRequest,
  companyRequest, getMetaData, createSiteRequest, removeSiteRequest, editSiteRequest,
} from 'init/proto_client';
import * as types from './actionTypes';
import { resetOverview } from '../../wearables/overview/overviewStore/actions';
import * as oldCompanyUtils from './oldCompany';
import { setNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../containers/NotificationContainer/notifications';
import { getCategories } from '../categories/store/actions';
import { SET_SITES } from '../../containers/ItemTableContainer/filtersStoreOld/actionTypes';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import { getUser, setDepartment } from '../../wearables/user/userStore/actions';
import { getDevices, setDevicesDepartment } from '../../admin/devices/devicesStore/actions';
import { getUsers, setCoachDepartment, setUsersSite } from '../../wearables/activity/usersStore/actions';
import { changeEntryName, removeEntry } from '../categories/store/categorySlice';

export function toggleIsLoading(isLoading) {
  return ({ type: types.SET_IS_LOADING, isLoading });
}

let callsIterator = 0;

export const selectCompany = (companyId) => async (dispatch, getState) => {
  const { companiesList } = getState().companies;
  const companyIdIterator = companiesList?.length;
  dispatch(resetOverview());
  oldCompanyUtils.setOldCompanyId(companyId);
  companyRequest.setCompanyId(companyId);
  companiesService.getCompany(
    companyRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
        dispatch({
          type: types.SET_IS_LOADING,
          isLoading: false,
        });
        callsIterator += 1;
        // console.log('Math.floor(Math.random() * (companyIdIterator.length - 1)', Math.floor(Math.random() * (companyIdIterator.length - 1)))
        if (callsIterator < 15) dispatch(selectCompany(companiesList[Math.floor(Math.random() * (companyIdIterator))].id));
      } else {
        dispatch({ type: types.SET_SELECTED_COMPANY, selectedCompany: response.toObject() });
      }
    },
  );
};

export const getCompaniesWithDepartments = () => async (dispatch) => {
  companiesRequest.setDummy();
  companiesService.getCompaniesWithSitesAndDepartments(
    companyRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      } else {
        const { companiesList } = toCamel(response.toObject());
        dispatch({
          type: types.SET_COMPANIES_WITH_DEPARTMENTS,
          companiesList,
        });
      }
    },
  );
};

export const getCompanies = () => async (dispatch) => {
  dispatch(toggleIsLoading(true));
  // eslint-disable-next-line no-restricted-globals
  const oldCompanyId = oldCompanyUtils.getOldCompany();
  companiesRequest.setDummy(true);
  await companiesService.getCompanyList(
    companiesRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
        dispatch({
          type: types.SET_IS_LOADING,
          isLoading: false,
        });
      } else {
        const { companiesList } = toCamel(response.toObject());
        dispatch({
          type: types.SET_COMPANIES,
          companiesList,
        });
        dispatch(selectCompany(oldCompanyId || companiesList[0].id));
        dispatch({
          type: types.SET_IS_LOADING,
          isLoading: false,
        });
      }
    },
  );
};

export const createDepartment = (companyID, name, forEntries) => async (dispatch) => {
  createDepartmentRequest.setCompanyId(companyID);
  createDepartmentRequest.setDepartmentName(name);
  await companiesService.createDepartment(
    createDepartmentRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      }
      dispatch(getCategories(companyID));
      dispatch(forEntries && setNotification({
        type: notificationTypes.TYPE_SUCCESS,
        message: notificationMessages.ENTRY_CREATED,
      }));
    },
  );
};

export const createSite = (companyID, name, forEntries) => async (dispatch) => {
  createSiteRequest.setCompanyId(companyID);
  createSiteRequest.setSiteName(name);
  await companiesService.createSite(
    createSiteRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      }
      const { sitesList } = toCamel(response.toObject());
      dispatch({
        type: SET_SITES,
        sitesList,
      });
      dispatch(getCategories(companyID));
      dispatch(forEntries && setNotification({
        type: notificationTypes.TYPE_SUCCESS,
        message: notificationMessages.ENTRY_CREATED,
      }));
    },
  );
};

export const createAndAssign = (
  userID,
  companyID,
  departmentName,
  callerType,
) => async (dispatch) => {
  createDepartmentRequest.setCompanyId(companyID);
  createDepartmentRequest.setDepartmentName(departmentName);
  await companiesService.createDepartment(
    createDepartmentRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      }
      const { companiesList } = toCamel(response.toObject());
      dispatch({
        type: types.SET_COMPANIES,
        companiesList,
      });
      const renewedSelectedCompany = companiesList
        .find((company) => company.id === companyID);
      dispatch({
        type: types.SET_SELECTED_COMPANY,
        selectedCompany: renewedSelectedCompany,
      });
      const newDepartmentID = renewedSelectedCompany.departmentsList
        .find((department) => department.name === departmentName).id;

      const IDs = Array.isArray(userID) ? userID : [userID];
      switch (callerType) {
        case TableType.user:
          dispatch(setDepartment(userID, newDepartmentID));
          break;
        case TableType.coach:
          dispatch(setCoachDepartment(IDs, newDepartmentID));
          break;
        case TableType.devices:
          dispatch(setDevicesDepartment(IDs, newDepartmentID));
          break;
        default:
          throw new Error('wrong caller type');
      }
    },
  );
};

export const createAndAssignSite = (
  userID,
  companyID,
  siteName,
  callerType,
) => async (dispatch, getState) => {
  const { selectedCompany } = getState().companies;
  createSiteRequest.setCompanyId(companyID);
  createSiteRequest.setSiteName(siteName);
  await companiesService.createSite(
    createSiteRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      } else {
        const { sitesList } = response.toObject();
        dispatch({
          type: types.SET_SELECTED_COMPANY,
          selectedCompany: { ...selectedCompany, sitesList },
        });
        const newSiteID = sitesList.find((site) => site.name === siteName).id;
        const IDs = Array.isArray(userID) ? userID : [userID];
        switch (callerType) {
          // case TableTypes.user:
          //   dispatch(setDepartment(userID, newDepartmentID));
          //   break;
          case TableType.coach:
            dispatch(setUsersSite(IDs, newSiteID));
            break;
          default:
            throw new Error('wrong caller type');
        }
      }
    },
  );
};

const requestUserDataByType = (itemType, userId, companyId, dispatch, id, title) => {
  switch (itemType) {
    case TableType.user:
      dispatch(getUser(userId));
      break;
    case TableType.coach:
      dispatch(getUsers(companyId));
      break;
    case TableType.devices:
      dispatch(getDevices(companyId));
      break;
    case TableType.entries:
      dispatch(removeEntry(id));
      break;
    case 'updateDepartment':
      dispatch(changeEntryName({ id, title }));
      break;
    case 'updateSite':
      dispatch(changeEntryName({ id, title }));
      break;
    default:
      throw new Error('wrong caller type');
  }
};

export const editDepartment = (
  departmentId,
  departmentName,
  companyId,
  itemType,
  userId,
  siteIDs,
  departmentIDs,
) => async (dispatch) => {
  editDepartmentRequest.setDepartmentId(departmentId);
  editDepartmentRequest.setDepartmentName(departmentName);
  editDepartmentRequest.setCompanyId(companyId);
  await companiesService.editDepartment(
    editDepartmentRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.EDIT_DEPARTMENT_IN_CURRENT_COMPANY,
          id: departmentId,
          name: departmentName,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.EDIT_DEPARTMENT_SUCCESS_MSG,
        }));
        requestUserDataByType(itemType, userId, companyId, dispatch, departmentId, departmentName, siteIDs, departmentIDs);
      }
    },
  );
};

export const editSite = (
  siteId,
  siteName,
  companyId,
  itemType,
  userId,
  siteIDs,
  departmentIDs,
) => async (dispatch) => {
  editSiteRequest.setSiteId(siteId);
  editSiteRequest.setSiteName(siteName);
  editSiteRequest.setCompanyId(companyId);
  await companiesService.editSite(
    editSiteRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.EDIT_SITE_IN_CURRENT_COMPANY,
          id: siteId,
          name: siteName,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.EDIT_SITE_SUCCESS_MSG,
        }));
        requestUserDataByType(itemType, userId, companyId, dispatch, siteId, siteName, siteIDs, departmentIDs);
      }
    },
  );
};

export const removeDepartment = (
  id,
  companyId,
  itemType,
  userId,
  reqLength,
  index,
  siteIDs,
  departmentIDs,
) => async (dispatch) => {
  removeDepartmentRequest.setDepartmentId(id);
  await companiesService.removeDepartment(
    removeDepartmentRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.REMOVE_DEPARTMENT_FROM_CURRENT_COMPANY,
          id,
        });
        (reqLength && index) ? (reqLength === index + 1 && (dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_DEPARTMENT_SUCCESS_MSG,
        })))) : dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_DEPARTMENT_SUCCESS_MSG,
        }));
        requestUserDataByType(itemType, userId, companyId, dispatch, id, siteIDs, departmentIDs);
      }
    },
  );
};

export const removeSite = (
  id,
  companyId,
  itemType,
  userId,
  reqLength,
  index,
  siteIDs,
  departmentIDs,
) => async (dispatch) => {
  removeSiteRequest.setSiteId(id);
  await companiesService.removeSite(
    removeSiteRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
        dispatch({
          type: types.SET_COMPANIES_ERROR,
          error,
        });
      } else {
        dispatch({
          type: types.REMOVE_SITE_FROM_CURRENT_COMPANY,
          id,
        });
        requestUserDataByType(itemType, userId, companyId, dispatch, id, siteIDs, departmentIDs);
        (reqLength && index) ? (reqLength === index + 1 && dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_SITE_SUCCESS_MSG,
        }))) : dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_SITE_SUCCESS_MSG,
        }));
      }
    },
  );
};

export const resetCompanies = () => async (dispatch) => {
  dispatch({
    type: types.RESET_COMPANIES,
  });
};

export const resetSelectedCompany = () => async (dispatch) => {
  dispatch({
    type: types.RESET_SELECTED_COMPANY,
  });
};
