// eslint-disable-next-line import/prefer-default-export
export const getAvgHCByRisk = (data, dataKey = 'spineHazardsChangeByRisk') => {
  // let departmentsCount = 0;
  const getWorkersCountKey = (key) => key.replace('End', 'WorkersCount').replace('Start', 'WorkersCount');
  const reducedData = data.reduce((acc, report) => {
    // if (report.metric && report.metric.spineHazardsChangeByRisk) departmentsCount += 1;
    const keys = Object.keys((report.metric && report.metric[dataKey]) || {});

    keys.forEach((key) => {
      if ((/WorkersCount/).test(key)) {
        if (acc[key]) {
          acc[key] += report.metric[dataKey][key];
        } else {
          acc[key] = report.metric[dataKey][key];
        }
      } else {
        const workersCountKey = getWorkersCountKey(key);
        if (acc[key]) {
          acc[key] += report.metric[dataKey][key] * report.metric[dataKey][workersCountKey];
        } else {
          acc[key] = report.metric[dataKey][key] * report.metric[dataKey][workersCountKey];
        }
      }
    });
    return acc;
  }, {});
  return Object.keys(reducedData).reduce((acc, metric) => {
    const workersCountKey = getWorkersCountKey(metric);
    if (!(/WorkersCount/).test(metric)) {
      acc[metric] = +(reducedData[metric] / reducedData[workersCountKey]).toFixed(1) || 0;
    } else {
      acc[metric] = reducedData[metric];
    }
    return acc;
  }, {});
};
