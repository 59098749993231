import * as types from './actionTypes';
import {
  getDashboardFeedbackRequest,
  getMetaData,
  adminServiceV2,
} from '../../../init/proto_client';

export const getFeedbacks = (companyId, limit, offset, sort) => async (dispatch) => {
  getDashboardFeedbackRequest.setCompanyId(companyId);
  getDashboardFeedbackRequest.setLimit(limit);
  getDashboardFeedbackRequest.setOffset(offset);
  getDashboardFeedbackRequest.setSortByRate(sort);
  dispatch({
    type: types.GET_FEEDBACKS_IS_LOADING,
    isLoading: true,
  });
  await adminServiceV2.getDashboardUsersFeedback(
    getDashboardFeedbackRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.GET_FEEDBACKS_ERROR,
          error,
        });
      } else {
        const { feedbacksList } = response.toObject();
        dispatch({
          type: types.GET_FEEDBACKS,
          feedbacksList,
        });
        dispatch({
          type: types.GET_FEEDBACKS_IS_LOADING,
          isLoading: false,
        });
      }
    },
  );
};
