import React from 'react';
import { Redirect } from '@reach/router';
import { useSelector } from 'react-redux';
import { container, flexColumn, mt4, } from '../../assets/soter-flex-styles';
import { selectedCompanySelector } from '../companiesStore/selectors';
import { authedUserSelector } from '../../auth/store/selectors';
import DashboardUsersTable from '../components/DashboardUsersTable';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';

const CompanyUsersContainer = () => {
  const currentCompany = useSelector(selectedCompanySelector);
  const authedUser = useSelector(authedUserSelector);

  const getIsRedirect = () => {
    if (!authedUser || authedUser.role === 'admin' || authedUser.canInviteUsers) {
      return false;
    }
    return true;
  };

  return getIsRedirect()
    ? <Redirect noThrow to="/users" />
    : (
      <>
        <div className={`${container} ${mt4}`}>
          <div className={flexColumn}>
            <h3>{currentCompany.name || '\u00A0'}</h3>
          </div>
        </div>
        <DashboardUsersTable type="myCompany" />
      </>
    );
};

export default PrivatePageHOC(CompanyUsersContainer);
