import getHMPresentationName from '../../../constants/getHMPresentationName';

export const getTooltipText = (t) => (hazardsData, overall, { roundingOverall } = {}) => {
  const roundedOverall = roundingOverall ? overall.toFixed(roundingOverall) : overall;
  const res = [`${roundedOverall} - ${t('WEARABLES.OVERVIEW.OVERALL_HOUR')}`];
  if (hazardsData.key !== 'empty') {
    res.unshift(`${(hazardsData[1] - hazardsData[0]).toFixed(1)} - ${
      t(getHMPresentationName(hazardsData.key))
    }`);
  }
  return res;
};

export const getTooltipTextConstructor = (opt) => (hazardsData, overall) => getTooltipText(hazardsData, overall, opt);
