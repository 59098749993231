/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import {
  column,
  flexColumn,
  flexRow,
  formControlInput,
  inputLabel,
  invisibleButton,
  mt3,
  mt5,
  pl3,
  pr3,
  transparentButton,
} from '../../../assets/soter-flex-styles';
import { fuzzySearch } from '../../../lib/utils/fuzzySearch';
import plus from '../../../assets/img/icons/plus.svg';
// eslint-disable-next-line import/named
import { USER_ENTRY_CHANGED } from '../../../constants/analyticsEvents';
import Button from '../../../lib/ui/Button';
import useFocus from '../../../lib/utils/useFocus';
import useSelectionList from './useSelectionList';
import { createAndAssign, editDepartment } from '../../../company/companiesStore/actions';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

const DepartmentsChangeDialog = ({
  user,
  handlerFn,
  userIds,
  company,
  closeModal = () => {},
  itemType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { departmentsList: departments } = useSelector(
    (state) => state.companies.selectedCompany,
  );
  const [inputRef, setInputFocus] = useFocus();
  const [departmentSearchString, setDepartmentSearchString] = useState('');
  const [currentDepartment, setCurrentDepartment] = useState(
    (user && user.department?.id) || null,
  );
  const sortedDepartments = _orderBy(
    departments,
    [(item) => item?.name?.toLowerCase()],
    ['asc'],
  );

  const filteredDepartments = sortedDepartments.filter((department) => fuzzySearch(department?.name, departmentSearchString));

  // eslint-disable-next-line no-unused-vars
  const [departmentID, _, renderDepartmentSelectionList, editedId]
    = useSelectionList({
      defaultState: currentDepartment,
      options: filteredDepartments || [],
      onEdit: (departmentId, departmentName) => dispatch(
        editDepartment(
          departmentId,
          departmentName,
          company.id,
          itemType,
          user?.id,
        ),
      ),
      onRemove: (id) => {
        if (window.confirm(t('WEARABLES.ACTIVITY.DIALOGS.DEPARTMENTS_CONFIRM'))) { dispatch(removeDepartment(id, company.id, itemType, user?.id)); }
      },
    });

  const searchForDepartment = (searchString) => {
    setDepartmentSearchString(searchString);
  };

  const handleCreateAndAssign = () => {
    const itemIDs = user?.id || userIds;
    dispatch(
      createAndAssign(itemIDs, company.id, departmentSearchString, itemType),
    );
    closeModal();
  };

  useEffect(() => {
    setTimeout(() => setInputFocus(), 0);
  }, []);

  useEffect(() => {
    setCurrentDepartment(departmentID);
  }, [departmentID]);

  return (
    <form
      className={column}
      autoComplete="off"
      name="change"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label
        className={`${inputLabel}`}
        style={{ marginBottom: '1.625rem' }}
        htmlFor="department"
      >
        {t('WEARABLES.ACTIVITY.DIALOGS.DEPARTMENT_SEARCH')}
        <input
          className={`${formControlInput} ${mt3}`}
          type="text"
          id="department"
          placeholder={t('WEARABLES.ACTIVITY.DIALOGS.DEPARTMENT_PLACEHOLDER')}
          ref={inputRef}
          disabled={editedId}
          onChange={(e) => searchForDepartment(e.target.value)}
        />
      </label>
      <div
        style={{
          height: '20rem',
          overflow: 'auto',
          borderTop: '.5px solid #dee2e6',
          borderBottom: '.5px solid #dee2e6',
          marginLeft: '-1.5rem',
          marginRight: '-1.5rem',
        }}
      >
        {renderDepartmentSelectionList()}
        {departmentSearchString.replace(/\s/g, '').length > 1 && (
          <div className={flexColumn}>
            <Button
              className={invisibleButton('small', COLORS.LIGHT_BLUE[600])}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
              handler={() => handleCreateAndAssign()}
            >
              <img src={plus} alt="add" />
              <span style={{ lineHeight: '1.5rem', paddingLeft: '.5rem' }}>
                {t('GENERAL.CREATE')}
                {' '}
                &quot;
                {departmentSearchString}
                &quot;
                {`${t('WEARABLES.ACTIVITY.DIALOGS.DEPARTMENTS_AND_ASSIGN')} ${
                  itemType === 'devices'
                    ? t('GENERAL.DEVICE')
                    : t('GENERAL.USER')
                }`}
              </span>
            </Button>
          </div>
        )}
      </div>
      <div
        className={`${flexRow} ${mt5} ${pl3} ${pr3}`}
        style={{ justifyContent: 'flex-end' }}
      >
        <Button
          className={transparentButton('small')}
          name="remove"
          style={{ width: '30%', minWidth: '133px' }}
          type="button"
          handler={(e) => {
            handlerFn(e, userIds || user.id, 0);
            closeModal();
          }}
          disabled={(user ? !user.department : false) || editedId}
          text={t('WEARABLES.ACTIVITY.DIALOGS.CLEAR_DEPARTMENT')}
        />
        <Button
          name="change"
          style={{ width: '30%', minWidth: '133px', marginLeft: '.5rem' }}
          type="button"
          handler={(e) => {
            const params = {
              entry_type: user.jobTitle,
            };
            window.logEvent(USER_ENTRY_CHANGED, params);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(`${USER_ENTRY_CHANGED}: ${JSON.stringify(params)}`));
            }
            handlerFn(e, userIds || user.id, departmentID);
            closeModal();
          }}
          disabled={!departmentID || editedId}
          text={t('WEARABLES.ACTIVITY.DIALOGS.ASSIGN_DEPARTMENT')}
        />
      </div>
    </form>
  );
};

export default DepartmentsChangeDialog;
