import React, { useEffect, useRef, useState } from 'react';
import * as Emotion from 'emotion';
import moment from 'moment';
import { useNavigate, useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import {
  cardMediumWithHeight,
  columnSmall,
  container,
  flexColumn,
  flexRow,
  mb5,
  mt3,
  mt5,
  pl3,
} from '../../assets/soter-flex-styles';
import VideoPlayer from '../components/VideoPlayer';
import EditingTaskProfileHeader from '../components/EditableTaskProfileHeader';
import { weightUnitList } from '../constants';
import { COLORS } from '../../constants/colors';
import ActivityBreakdownTable from '../components/ActivityBreakdownTable';
import DemandsActivityByteConvertor from '../utils/DemandsActivityByteConvertor';
import DemandsActivityUiConvertor from '../utils/DemandsActivityUiConvertor';
import PhysicalDemand from '../components/PhysicalDemand';
import { TASK_PROFILE_SOTER_VIDEO_ADD, TASK_PROFILE_VIDEO_ADD } from '../../constants/analyticsEvents';
import { emptyTaskProfile } from '../../init/proto_client';
import useOutsideClickHandler from '../../lib/utils/useOutsideClickHandler';
import {
  attachSoterTaskVideo,
  createVideo,
  detachSoterTaskVideo,
  editTask,
  getSoterTaskVideos,
  getTask,
  removeVideo,
  resetProfileState,
} from './taskProfileStore/actions';
import { routes } from '../../containers/PrivatPageHOC/constants/routes';
import Loader from '../../lib/ui/Loader';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
import EditableTextCard from '../../lib/ui/EditableTextCard/EditableTextCard';
import InfoCard from '../../lib/ui/InfoCard';
import Select from '../../wearables/user/ProgramContainer/components/Select';
import CardEditingControl from '../../lib/ui/CardEditingControl';
import hexToRgba from '../../lib/utils/hexToRgba';
import Button from '../../lib/ui/Button';
import SearchableSelectableDialog from '../../task/components/SearchableSelectableDialog';

const TaskProfileContainer = () => {
  const videoDialogRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uiConvertor = new DemandsActivityUiConvertor();
  const byteConvertor = new DemandsActivityByteConvertor();
  const { profile, soterTaskVideos, error } = useSelector(
    (state) => state.taskProfile,
  );
  const { id: companyID } = useSelector(
    (state) => state.companies.selectedCompany,
  );
  const [isEmptyProfile, setIsEmptyProfile] = useState(!params.taskId);
  const zeroBase64String = byteConvertor.getZeroBase64String();
  _each(emptyTaskProfile, (_, key) => {
    if (key.slice(0, 3) === 'cpd') {
      // eslint-disable-next-line no-param-reassign
      emptyTaskProfile[key] = zeroBase64String;
    }
  });
  const [currentTask, setCurrentTask] = useState({
    ...emptyTaskProfile,
    profileId: parseInt(params.id),
  });
  const [headerLocalCurrentTask, setHeaderLocalCurrentTask]
    = useState(currentTask);
  const [criticalPhysicalDemandsData, setCriticalPhysicalDemandsData]
    = useState({});
  const [headerEditMode, setHeaderEditMode] = useState(!params.taskId);
  const [demandEditMode, setDemandEditMode] = useState(false);
  const [weightUnits, setWeightUnits] = useState(weightUnitList.kg.title);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [isTaskVideoDialogOpen, setIsTaskVideoDialogOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const convertCpdData = () => {
    const newCpdData = {};
    _each(currentTask, (v, k) => {
      if (k.slice(0, 3) === 'cpd') newCpdData[k] = v;
    });
    _each(newCpdData, (base64string, key) => {
      const values = [];
      const uintArray = byteConvertor.convertBase64ToUintArray(base64string);
      uintArray.forEach((value) => values.push(byteConvertor.convertIntToActivityValues(value)));
      newCpdData[key] = values;
    });
    setCriticalPhysicalDemandsData(
      uiConvertor.convertDataToUiValues(newCpdData, weightUnits),
    );
  };
  useOutsideClickHandler(videoDialogRef, () => setIsVideoDialogOpen(false));
  useEffect(() => {
    if (params.taskId && profile?.title) {
      setCurrentTask(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (params.taskId) {
      dispatch(getTask(params.taskId));
    }
    return () => dispatch(resetProfileState());
  }, []);
  useEffect(() => {
    if (companyID) dispatch(getSoterTaskVideos(companyID));
  }, [companyID]);

  useEffect(() => {
    if (profile.id && isEmptyProfile) {
      navigate(`/${companyID}/${routes.users}/${routes.jobs}/${routes.profile}/${params.id}/${routes.taskPage}/${profile.id}`);
      setIsEmptyProfile(false);
    }
  }, [profile?.id]);

  useEffect(() => {
    setHeaderLocalCurrentTask(currentTask);
    convertCpdData();
  }, [currentTask, weightUnits]);

  const saveLocalValue = (key) => (value) => dispatch(editTask({ ...currentTask, [key]: value }));
  const saveCriticalPhysicalDemands = () => {
    const newTask = { ...currentTask };
    _each(
      uiConvertor.convertUiValuesToData(
        criticalPhysicalDemandsData,
        weightUnits,
      ),
      (v, k) => {
        const array = v.map(({ color, value }) => byteConvertor.convertActivityValuesToInt(value, color));
        const base64String = byteConvertor.convertUintArrayToBase64(
          new Uint16Array(array),
        );
        newTask[k] = base64String;
      },
    );
    dispatch(editTask(newTask));
    setDemandEditMode(false);
  };
  const cancelHandler = () => {
    setCurrentTask(profile);
    setDemandEditMode(false);
    convertCpdData();
  };
  const detachVideoHandler = (videoId, isLastVideo) => dispatch(detachSoterTaskVideo(params.taskId, videoId, isLastVideo));
  const removeVideoHandler = (type) => (id) => {
    if (type === 'youtube') {
      dispatch(removeVideo(id, params.taskId));
    } else {
      detachVideoHandler(id);
    }
  };
  const attachVideoHandler = (selectedVideos, videosToRemove) => {
    if (selectedVideos.length > 0) dispatch(attachSoterTaskVideo(params.taskId, selectedVideos));
    if (videosToRemove.length > 0) {
      videosToRemove.map((videoId, index, array) => (array.length - 1 === index
        ? detachVideoHandler(videoId)
        : detachVideoHandler(videoId, false)));
    }
  };
  const [unitedVideosList, setUnitedVideoList] = useState([]);
  useEffect(() => {
    if (currentTask.videosListList || currentTask.videosTaskListList) {
      const mappedVideos = currentTask.videosListList
        ? currentTask.videosListList.map((v) => ({
          ...v,
          videoType: 'youtube',
        }))
        : [];
      const mappedTaskVideos = currentTask.videosTaskListList
        ? currentTask.videosTaskListList.map((v) => ({
          ...v,
          videoType: 'sotertask',
        }))
        : [];
      setUnitedVideoList(
        _sortBy([...mappedVideos, ...mappedTaskVideos], ['title']),
      );
    } else {
      setUnitedVideoList([]);
    }
  }, [currentTask.videosListList, currentTask.videosTaskListList]);
  return (
    <div className={container}>
      {params.taskId && !profile.id ? (
        <Loader />
      ) : (
        <>
          <div className={`${flexRow} ${mt3}`}>
            <div
              className={columnSmall}
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <NavigateBackButton
                name={t('JOB_DICTIONARY.GO_BACK')}
                navigateTo={`/${companyID}/${routes.users}/${routes.jobs}/${routes.profile}/${params.id}`}
              />
            </div>
          </div>
          <div className={flexColumn}>
            <div className={flexRow}>
              <div className={flexColumn}>
                <EditingTaskProfileHeader
                  setCurrentTask={setHeaderLocalCurrentTask}
                  currentTask={headerLocalCurrentTask}
                  editMode={headerEditMode}
                />
              </div>
            </div>
          </div>
          {params.taskId && (
            <>
              <div className={flexColumn}>
                <div className={flexRow}>
                  <div className={`${flexColumn} ${pl3}`}>
                    <EditableTextCard
                      className={mb5}
                      id="task_overview"
                      title={t('JOB_DICTIONARY.TASK_PROFILE.TASK_OVERVIEW')}
                      text={currentTask.taskOverview}
                      saveValue={saveLocalValue('taskOverview')}
                      height={275}
                    />
                    <InfoCard
                      title={t('JOB_DICTIONARY.JOB_PROFILE.CRITICAL_DEMAND')}
                      cardClass={`${cardMediumWithHeight(395)} ${Emotion.css`
                        & div:last-child {
                          padding-left: 18px;
                        }
                      `}`}
                      titleInlineControl={(
                        <Select
                          noMargin
                          id="pie_chart_unit_select"
                          data={_map(weightUnitList, (unit) => ({
                            id: unit.title,
                            date: unit.title.toUpperCase(),
                          }))}
                          setValue={setWeightUnits}
                        />
                      )}
                      rightCornerControl={(
                        <CardEditingControl
                          editMode={demandEditMode}
                          editButtonHandler={() => setDemandEditMode(!demandEditMode)}
                          saveButtonHandler={saveCriticalPhysicalDemands}
                          cancelButtonHandler={cancelHandler}
                        />
                      )}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          color: COLORS.GRAY[400],
                          fontSize: '12px',
                          top: '-14px',
                        }}
                      >
                        {t('JOB_DICTIONARY.JOB_PROFILE.MATERIAL').toUpperCase()}
                      </span>
                      <ActivityBreakdownTable
                        haveValue
                        data={criticalPhysicalDemandsData}
                        units={weightUnits}
                        isEditing={demandEditMode}
                        setData={setCriticalPhysicalDemandsData}
                      />
                    </InfoCard>
                  </div>
                  <div className={`${flexColumn} ${pl3}`}>
                    <PhysicalDemand
                      isForTask
                      currentRole={currentTask}
                      cancelHandler={cancelHandler}
                      saveDemandsData={() => dispatch(editTask(currentTask))}
                      setDemandData={(type) => (value) => setCurrentTask({ ...currentTask, [type]: value })}
                    />
                  </div>
                </div>
              </div>
              <div className={`${flexColumn} ${mt5} ${mb5} ${pl3}`}>
                <div className={flexRow}>
                  <VideoPlayer
                    videoList={unitedVideosList}
                    taskAddHandler={() => {
                      window.logEvent(TASK_PROFILE_VIDEO_ADD);
                      setIsTaskVideoDialogOpen(true);
                    }}
                    addButtonHandler={() => {
                      window.logEvent(TASK_PROFILE_SOTER_VIDEO_ADD);
                      setIsVideoDialogOpen(true);
                    }}
                    removeVideoHandler={removeVideoHandler}
                    isTaskProfile
                  />
                </div>
              </div>
              {isVideoDialogOpen && (
                <div
                  ref={videoDialogRef}
                  style={{
                    position: 'absolute',
                    top: '45%',
                    left: '45%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '30px 20px',
                    backgroundColor: hexToRgba(COLORS.GRAY[400], 0.6),
                    border: `1px solid ${COLORS.GRAY[100]}`,
                    borderRadius: '7px,',
                  }}
                >
                  <label htmlFor="video_title">Title</label>
                  <input
                    name="video_title"
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                  />
                  <label htmlFor="video_url">Youtube url</label>
                  <input
                    name="video_url"
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                  <Button
                    style={{ marginTop: '15px' }}
                    handler={() => {
                      dispatch(
                        createVideo(currentTask.id, videoUrl, videoTitle),
                      );
                      setIsVideoDialogOpen(false);
                      setVideoUrl('');
                      setVideoTitle('');
                    }}
                    text={t('JOB_DICTIONARY.JOB_PROFILE.ADD').toUpperCase()}
                  />
                  <Button
                    style={{ marginTop: '15px' }}
                    onClick={() => {
                      setIsVideoDialogOpen(false);
                      setVideoUrl('');
                      setVideoTitle('');
                    }}
                    text={t('GENERAL.CLOSE').toUpperCase()}
                  />
                </div>
              )}
              {isTaskVideoDialogOpen && (
                <SearchableSelectableDialog
                  placeholder={t(
                    'JOB_DICTIONARY.TASK_PROFILE.ADD_TASK_VIDEO_DIALOG.INPUT_PLACEHOLDER',
                  )}
                  buttonText={t(
                    'JOB_DICTIONARY.TASK_PROFILE.ADD_TASK_VIDEO_DIALOG.BUTTON_TEXT',
                  )}
                  items={soterTaskVideos.map(({ id, title, createdAt }) => ({
                    value: id,
                    title,
                    subTitle: moment(createdAt).format('DD MMM YYYY'),
                  }))}
                  buttonHandler={attachVideoHandler}
                  closeHandler={() => setIsTaskVideoDialogOpen(false)}
                  videoList={unitedVideosList}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TaskProfileContainer;
