import React from 'react';
import Navigation from './Navigation';

const SecondLevelNavigation = ({ activePath, links }) => (
  <Navigation activePath={activePath}>
    {links}
  </Navigation>
);

export default SecondLevelNavigation;
