import React from 'react';
import { flexRow, mediumIcon, ratingValue } from '../../../../assets/soter-flex-styles';
import { useTranslation } from 'react-i18next';
import NoDataInfo from '../../NoDataInfo';

const RatingInfo = ({ ratingProps }) => {
  const { t } = useTranslation();
  const noDataMessage = t('WEARABLES.USER_PAGE.NO_IMPROVEMENT');
  if (!ratingProps.rating) {
    return (
      <div style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      }}
      >
        <NoDataInfo noDataMessage={noDataMessage} />
      </div>
    );
  }

  return (
    <div className={flexRow} style={{ justifyContent: 'flex-start' }}>
      { ratingProps.icon
        ? (
          <img
            className={mediumIcon}
            src={ratingProps.icon}
            alt={ratingProps.iconName}
          />
        )
        : null}
      <p
        className={ratingValue}
        style={{
          color: ratingProps.color,
          marginLeft: ratingProps.icon ? '0' : '.75rem',
        }}
      >
        {`${Math.abs(ratingProps.rating)}${ratingProps.withPercent ? '%' : ''}`}
      </p>
    </div>
  );
};

export default RatingInfo;
