var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import writeXlsxFile from 'write-excel-file';
import downloadCSV from './csvLoader';
import { ReportType } from '../constants/tablePageReportList';
export const editedCompany = (company, t) => {
    const { name, values } = company;
    const companyData = {
        name: `customCategory_${name}`,
        label: name,
        values,
    };
    if (name === 'task_title')
        companyData.label = t('WEARABLES.OVERVIEW.TASK');
    if (name === 'user_job_role') {
        companyData.name = 'jobRoles';
        companyData.label = t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE');
        companyData.values = companyData.values.map((item) => ({
            label: item.label,
            value: item.label,
        }));
    }
    return companyData;
};
export const printReport = (reportEntity, reportBuilder, t) => __awaiter(void 0, void 0, void 0, function* () {
    if (reportEntity.type === ReportType.Xls) {
        const xlsOptions = {
            fileName: `${t(reportEntity.title)}.xls`,
        };
        let xlsData = reportBuilder.getXlsData();
        if (reportBuilder.hasLegend()) {
            xlsOptions.sheets = [t('WEARABLES.ACTIVITY.REPORT.REPORT_DATA'), t('GENERAL.LEGEND')];
            xlsData = [xlsData, reportBuilder.getXlsLegend()];
        }
        yield writeXlsxFile(xlsData, xlsOptions);
    }
    else {
        downloadCSV(reportBuilder.getScvData(), t(reportEntity.title));
    }
});
