import { COLORS } from 'constants/colors';
import { Risks } from 'init/proto_client';
import { GeniusReportFormat, GeniusReportType } from "./types";
export const thresholds = {
    back: [35, 60],
    neck: [45, 75],
    hand: [30, 60],
    leg: [50, 85],
};
export const bodyPartNames = {
    back_angle: 'SOTER_TASK.BODY_PARTS.BACK',
    neck_angle: 'SOTER_TASK.BODY_PARTS.NECK',
    hand_angle_l: 'SOTER_TASK.BODY_PARTS.LEFT_ARM',
    hand_angle_r: 'SOTER_TASK.BODY_PARTS.RIGHT_ARM',
    leg_angle_l: 'SOTER_TASK.BODY_PARTS.LEFT_LEG',
    leg_angle_r: 'SOTER_TASK.BODY_PARTS.RIGHT_LEG',
    reba: 'reba',
};
export const bodyPartNamesHackMap = {
    back_angle: 'back',
    neck_angle: 'neck',
    hand_angle_l: 'armLeft',
    hand_angle_r: 'armRight',
    leg_angle_l: 'legLeft',
    leg_angle_r: 'legRight',
};
export const bodyEdgesMap = {
    rightHandUpper: {
        pointsIndex: [10, 9],
        threshold: 'hand',
        color: 'hand_angle_r',
    },
    rightHandLower: {
        pointsIndex: [11, 10],
        threshold: 'hand',
        color: 'hand_angle_r',
    },
    leftHandUpper: {
        pointsIndex: [3, 4],
        threshold: 'hand',
        color: 'hand_angle_l',
    },
    leftHandLower: {
        pointsIndex: [4, 5],
        threshold: 'hand',
        color: 'hand_angle_l',
    },
    rightLegUpper: {
        pointsIndex: [12, 13],
        threshold: 'leg',
        color: 'leg_angle_r',
    },
    rightLegLower: {
        pointsIndex: [13, 14],
        threshold: 'leg',
        color: 'leg_angle_r',
    },
    leftLegUpper: {
        pointsIndex: [6, 7],
        threshold: 'leg',
        color: 'leg_angle_l',
    },
    leftLegLower: {
        pointsIndex: [7, 8],
        threshold: 'leg',
        color: 'leg_angle_l',
    },
    backLower: {
        pointsIndex: [6, 12],
        threshold: 'back',
        color: 'back_angle',
    },
    // backUpperLeft: {
    //   pointsIndex: [0, 3],
    //   threshold: 'back',
    //   color: 'back_angle',
    // },
    // backUpperRight: {
    //   pointsIndex: [0, 9],
    //   threshold: 'back',
    //   color: 'back_angle',
    // },
    backUpper: {
        pointsIndex: [3, 9],
        threshold: 'back',
        color: 'back_angle',
    },
    backRight: {
        pointsIndex: [9, 12],
        threshold: 'back',
        color: 'back_angle',
    },
    backLeft: {
        pointsIndex: [3, 6],
        threshold: 'back',
        color: 'back_angle',
    },
    // neckNose: {
    //   pointsIndex: [0, 1],
    //   threshold: 'neck',
    //   color: 'neck_angle',
    // },
    neckNoseRight: {
        pointsIndex: [1, 9],
        threshold: 'neck',
        color: 'neck_angle',
    },
    neckNoseLeft: {
        pointsIndex: [1, 3],
        threshold: 'neck',
        color: 'neck_angle',
    },
};
export const assessmentSteps = {
    list: 'list',
    settings: 'settings',
    name: 'name',
    time: 'time',
    edit: 'edit',
    view: 'view',
};
export const assessmentStepsNames = {
    settings: 'SOTER_TASK.ASSESSMENT.STEP_1',
    name: 'SOTER_TASK.ASSESSMENT.STEP_2',
    time: 'SOTER_TASK.ASSESSMENT.STEP_3',
    edit: 'SOTER_TASK.ASSESSMENT.STEP_4',
};
export const assessmentTypesList = [
    // { title: 'SOTER_TASK.ASSESSMENT.TITLES.CUSTOM_REPORT', value: 'custom' },
    { title: 'SOTER_TASK.ASSESSMENT.TITLES.RULA_LEFT', value: 'rula_left' },
    { title: 'SOTER_TASK.ASSESSMENT.TITLES.RULA_RIGHT', value: 'rula_right' },
    { title: 'SOTER_TASK.ASSESSMENT.TITLES.REBA', value: 'reba' },
    { title: 'SOTER_TASK.ASSESSMENT.TITLES.NIOSH', value: 'niosh' },
];
export const weightUnitsList = [
    { title: 'SOTER_TASK.ASSESSMENT.TITLES.LB', value: 'lb' },
    { title: 'SOTER_TASK.ASSESSMENT.TITLES.KG', value: 'kg' },
];
export const riskGroupColors = {
    [Risks.NONE]: COLORS.GRAY[400],
    [Risks.LOW]: COLORS.GREEN[600],
    [Risks.MEDIUM]: COLORS.ORANGE[600],
    [Risks.HIGH]: COLORS.RED[600],
};
export const riskGroupNames = {
    [Risks.NONE]: 'GENERAL.NA',
    [Risks.LOW]: 'SOTER_TASK.RISKS.LOWER',
    [Risks.MEDIUM]: 'SOTER_TASK.RISKS.MEDIUM',
    [Risks.HIGH]: 'SOTER_TASK.RISKS.HIGHER',
};
export const bodyPartNamesList = {
    back: 'SOTER_TASK.BODY_PARTS.BACK',
    neck: 'SOTER_TASK.BODY_PARTS.NECK',
    armLeft: 'SOTER_TASK.BODY_PARTS.LEFT_ARM',
    armRight: 'SOTER_TASK.BODY_PARTS.RIGHT_ARM',
    legLeft: 'SOTER_TASK.BODY_PARTS.LEFT_LEG',
    legRight: 'SOTER_TASK.BODY_PARTS.RIGHT_LEG',
};
export const videoPlaybackRateList = [2, 1.5, 1, 0.5];
export const VIDEO_WIDTH = 826;
export const VIDEO_HEIGHT = 463;
export const CONTROLS_HEIGHT = 45;
export const CHART_HEIGHT = 216;
export const ASSESSMENT_WIDTH = 333;
export const ASSESSMENT_SHORT_HEIGHT = 463;
export const ASSESSMENT_LONG_HEIGHT = 787;
export const TIME_DISTRIBUTION_BAR_CHART_WIDTH = 526;
export const TIME_DISTRIBUTION_BAR_CHART_HEIGHT = 24;
// The first string is for AI usage and business logic, the second is for translation if needed
export const reportTypeNames = {
    [GeniusReportType.ASSESSMENT]: ['Risk assessment', 'SOTER_GENIUS.REPORT_TYPES.ASSESSMENT'],
    [GeniusReportType.RECOMMENDATIONS]: ['Ergonomic Recommendations', 'SOTER_GENIUS.REPORT_TYPES.RECOMMENDATIONS'],
    [GeniusReportType.SCREENING]: ['MSD Screening tool (France-only)', 'SOTER_GENIUS.REPORT_TYPES.SCREENING'],
    [GeniusReportType.PRODUCTIVITY]: ['Productivity Assessment', 'SOTER_GENIUS.REPORT_TYPES.PRODUCTIVITY'],
    [GeniusReportType.REVIEW]: ['Safety Review', 'SOTER_GENIUS.REPORT_TYPES.REVIEW'],
};
export const reportFormatNames = {
    [GeniusReportFormat.INCHAT]: ['In-chat message', 'SOTER_GENIUS.IN_CHAT'],
    [GeniusReportFormat.PDF]: ['pdf'],
    [GeniusReportFormat.XLS]: ['xls'],
    [GeniusReportFormat.XLSX]: ['xlsx'],
    [GeniusReportFormat.CSV]: ['csv'],
};
export const defaultFormatSet = [GeniusReportFormat.INCHAT, GeniusReportFormat.PDF];
export const msdScreeningFormatSet = [GeniusReportFormat.XLS, GeniusReportFormat.XLSX, GeniusReportFormat.CSV];
