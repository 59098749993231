// eslint-disable-next-line import/prefer-default-export
import { deviceTypes } from '../constants/deviceTypes';
import { mapConfigToProgramType } from './mapConfigToProgramType';

export const getConfigByDeviceType = (deviceType, companyConfig) => {
  if (!companyConfig) return [];
  switch (deviceType) {
    case deviceTypes.bend:
      return mapConfigToProgramType(Object.keys(companyConfig), deviceTypes.bend);
    case deviceTypes.armband:
      return mapConfigToProgramType(Object.keys(companyConfig), deviceTypes.armband);
    default: return [];
  }
};
