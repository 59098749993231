import * as Emotion from 'emotion';
import { flexColumn } from './flex-base-elements';

export const programIcon = Emotion.css`
  ${flexColumn};
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 33%;
`;

export const smallIcon = Emotion.css`
  padding: 0;
  height: 12px;
  width: 12px;
`;

export const mediumIcon = Emotion.css`
  padding: 0;
  height: 24px;
  width: 24px;
  flex: 0 1 16.66666667%;
  @media (min-width: 768px) and (max-width: 992px) {
    flex: 0 1 33%;
  }
`;

export const medalIconLarge = Emotion.css`
  width: 3.4rem;
  height: 3.4rem;
  @media (min-width: 992px) and (max-width: 1280px) {
    width: 2.8rem;
    height: 2.8rem;
  }
`;
