// source: api/dashboard/v2/tier_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.tier_message.MessageTier', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tier_message.MessageTier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tier_message.MessageTier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tier_message.MessageTier.displayName = 'proto.tier_message.MessageTier';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tier_message.MessageTier.prototype.toObject = function(opt_includeInstance) {
  return proto.tier_message.MessageTier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tier_message.MessageTier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tier_message.MessageTier.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maskRisk: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maskSound: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maskVibration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    showHazardLifting: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    showHazardTwisting: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    showHazardStatic: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    showHazardIntensity: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    showHazardRepetition: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    showHazardArmElevation: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    showHazardPushingPulling: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    showHazardStaticArmElevation: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    showHazardOverexertion: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    showHazardRepetitiveArmMovements: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    firstDayNotificationsDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    notificationTimeoutMinutes: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tier_message.MessageTier}
 */
proto.tier_message.MessageTier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tier_message.MessageTier;
  return proto.tier_message.MessageTier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tier_message.MessageTier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tier_message.MessageTier}
 */
proto.tier_message.MessageTier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaskRisk(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaskSound(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaskVibration(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardLifting(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardTwisting(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardStatic(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardIntensity(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardRepetition(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardArmElevation(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardPushingPulling(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardStaticArmElevation(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardOverexertion(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHazardRepetitiveArmMovements(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFirstDayNotificationsDisabled(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotificationTimeoutMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tier_message.MessageTier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tier_message.MessageTier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tier_message.MessageTier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tier_message.MessageTier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaskRisk();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaskSound();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaskVibration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getShowHazardLifting();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getShowHazardTwisting();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getShowHazardStatic();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getShowHazardIntensity();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getShowHazardRepetition();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getShowHazardArmElevation();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getShowHazardPushingPulling();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getShowHazardStaticArmElevation();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getShowHazardOverexertion();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getShowHazardRepetitiveArmMovements();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getFirstDayNotificationsDisabled();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getNotificationTimeoutMinutes();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.tier_message.MessageTier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 mask_risk = 2;
 * @return {number}
 */
proto.tier_message.MessageTier.prototype.getMaskRisk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setMaskRisk = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 mask_sound = 3;
 * @return {number}
 */
proto.tier_message.MessageTier.prototype.getMaskSound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setMaskSound = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 mask_vibration = 4;
 * @return {number}
 */
proto.tier_message.MessageTier.prototype.getMaskVibration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setMaskVibration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool show_hazard_lifting = 5;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardLifting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardLifting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool show_hazard_twisting = 6;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardTwisting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardTwisting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool show_hazard_static = 7;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardStatic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardStatic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool show_hazard_intensity = 8;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardIntensity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardIntensity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool show_hazard_repetition = 9;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardRepetition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardRepetition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool show_hazard_arm_elevation = 10;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardArmElevation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardArmElevation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool show_hazard_pushing_pulling = 11;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardPushingPulling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardPushingPulling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool show_hazard_static_arm_elevation = 12;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardStaticArmElevation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardStaticArmElevation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool show_hazard_overexertion = 13;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardOverexertion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardOverexertion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool show_hazard_repetitive_arm_movements = 14;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getShowHazardRepetitiveArmMovements = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setShowHazardRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool first_day_notifications_disabled = 15;
 * @return {boolean}
 */
proto.tier_message.MessageTier.prototype.getFirstDayNotificationsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setFirstDayNotificationsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int64 notification_timeout_minutes = 16;
 * @return {number}
 */
proto.tier_message.MessageTier.prototype.getNotificationTimeoutMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.tier_message.MessageTier} returns this
 */
proto.tier_message.MessageTier.prototype.setNotificationTimeoutMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


goog.object.extend(exports, proto.tier_message);
