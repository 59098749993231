import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  users: [],
  newUser: null,
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.USERS_SET:
      const { users } = action;
      return state.merge({
        users,
        error: null,
      });
    case types.USERS_USER_CREATE:
      const { newUser } = action;
      return state.merge({
        newUser,
        error: null,
      });
    case types.USERS_USER_RESET:
      return state.merge({
        newUser: null,
        error: null,
      });
    case types.USERS_TOGGLE_FILTERS:
      const { filters } = action;
      return state.merge({
        filters: { ...state.filters, ...filters },
      });
    case types.USERS_TOGGLE_LOADING:
      const { isLoading } = action;
      return state.merge({ isLoading });
    case types.USERS_ERROR:
      const { error } = action;
      return state.merge({ error });
    case types.USERS_RESET:
      return state.merge({ ...initialState, pagination: state.pagination });
    default: return state;
  }
}
