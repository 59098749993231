import React from 'react';
import { useTranslation } from 'react-i18next';
import { TABLE_CSV_REPORT, TABLE_XLS_REPORT } from '../../../constants/analyticsEvents';
import { clickableArea, link } from '../../../assets/jss/containers/itemTableStyle';
import { useAppDispatch } from '../../../lib/utils/useStore';
import { setReportId } from '../reportsStore/reportSlice';
import { ReportType } from '../constants/tablePageReportList';
import { createEventNotification } from '../../NotificationContainer/notificationsStore/actions';

const TableReport = ({ id, type, title }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initReportGeneration = () => {
    dispatch(setReportId(id));
    if (type === ReportType.Csv) {
      window.logEvent(TABLE_CSV_REPORT);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(TABLE_CSV_REPORT));
      }
    } else {
      window.logEvent(TABLE_XLS_REPORT);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(TABLE_XLS_REPORT));
      }
    }
  };

  return (
    <div
      className={`${link} ${clickableArea}`}
      onClick={initReportGeneration}
    >
      {t(title)}
    </div>
  );
};

export function createExportActions(reportList) {
  return reportList.map(({ id, type, title }) => (
    {
      name: <TableReport type={type} title={title} id={id} />,
      handler: () => {},
    }
  ));
}

export default TableReport;
