import { setCurrentPage, setDisplayLength, setSortingColumn, setSortingType, } from '../tableStateStore/actions';
import { addFilterValue, setFilterValues, setSearchString } from '../filtersStore/filtersSlice';
import { passedPrograms } from '../../../constants/URLParams';
import { objectToMap } from './objectToMap';
import { getUsers } from '../../../wearables/activity/usersStore/actions';
import { getIDsFromArray } from '../../../lib/utils/getIDsFromArray';
import { safelyConvertToNumber } from './safelyConvertToNumber';
export const setFilterFromParameters = (r, tableType, dispatch, selectedCompany, dateRangePickerValue) => {
    var _a, _b;
    if (r.sorting) {
        const { sortingType } = r.sorting;
        const { field } = r.sorting;
        dispatch(setSortingColumn(field, tableType));
        dispatch(setSortingType(sortingType, tableType));
    }
    if (r.page) {
        const { pageNumber } = r.page;
        const { pageSize } = r.page;
        dispatch(setDisplayLength(+pageSize, tableType));
        dispatch(setCurrentPage(+pageNumber, tableType));
    }
    if (r.search) {
        const { search } = r;
        dispatch(setSearchString({ searchString: search }));
    }
    if (r.filter || r[passedPrograms]) {
        const filters = objectToMap(Object.assign(Object.assign({}, r === null || r === void 0 ? void 0 : r.filter), { passedPrograms: r[passedPrograms] }));
        filters.forEach((value, key) => {
            !Array.isArray(value)
                ? setTimeout(() => dispatch(setFilterValues({ type: 'showPassedPrograms', values: [value], pageType: tableType })), 300)
                : value.forEach((v) => {
                    // TODO dispatch addFilterValue only after initFilters to prevent usage of setTimeout
                    setTimeout(() => dispatch(addFilterValue({ type: key, value: safelyConvertToNumber(v), pageType: tableType })), 300);
                });
        });
    }
    if (((_a = r.calendar) === null || _a === void 0 ? void 0 : _a.to) && ((_b = r.calendar) === null || _b === void 0 ? void 0 : _b.from)) {
        setTimeout(() => dispatch(getUsers(selectedCompany.id, {
            // @ts-ignore
            startedAt: +r.calendar.from,
            // @ts-ignore
            finishedAt: +r.calendar.to,
        }, 
        // @ts-ignore
        selectedCompany.sitesList.filter((id) => id > 0).length > 0
            // @ts-ignore
            ? selectedCompany.sitesList.filter((id) => id > 0)
            : getIDsFromArray(selectedCompany.sitesList), 
        // @ts-ignore
        selectedCompany.departmentsList.filter((id) => id > 0).length > 0
            // @ts-ignore
            ? selectedCompany.departmentsList.filter((id) => id > 0)
            : getIDsFromArray(selectedCompany.departmentsList))), 300);
    }
};
