import React, { useEffect, useState } from 'react';
import { text } from 'assets/jss/style';
import { ReactComponent as ArrowUp } from 'assets/img/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/img/icons/arrow-down.svg';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import hexToRgba from '../../../lib/utils/hexToRgba';

const header = Emotion.css`
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
`;

const bubble = (disabled) => Emotion.css`
  border-radius: 10px;
  background-color: ${disabled ? COLORS.GRAY[100] : hexToRgba(COLORS.LIGHT_BLUE[600], 0.2)};
  color: ${disabled ? COLORS.GRAY[400] : COLORS.LIGHT_BLUE[600]};
  font-size: ${disabled ? 10 : 14}px;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExpandedPanel = ({ expanded, title, score, children, scoreDisabled }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(expanded);
  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);
  return (
    <div>
      <div onClick={() => setIsExpanded(!isExpanded)} className={header}>
        <div style={{ flexGrow: 1 }} className={text(COLORS.GRAY[950], 15)}>
          {t(title)}
        </div>
        <div className={text(hexToRgba(COLORS.GRAY[950], 0.5), 14)}>
          {t('SOTER_TASK.ASSESSMENT.SCORE')}
        </div>
        <div className={bubble(scoreDisabled)}>{score}</div>
        {isExpanded ? <ArrowUp style={{ marginTop: '-4px' }} /> : <ArrowDown />}
      </div>
      {isExpanded && children}
    </div>
  );
};

export default ExpandedPanel;
