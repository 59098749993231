import React from 'react';
import { productStatus } from 'init/proto_client';
import { useAppSelector } from '../../lib/utils/useStore';
import { selectedCompanySelector } from '../../company/companiesStore/selectors';
import SoterTaskInfoBanner from '../components/infoBanner/SoterTaskInfoBanner';

const useNumberOfTrialDaysLeftBanner = (productName) => {
  const currentCompany = useAppSelector(selectedCompanySelector);
  const currentCompanyProduct = currentCompany?.products?.[productName];
  const fourteenDaysInSeconds = 1209600;
  const oneMonthInSeconds = 2592000;
  const timePeriod = productName === 'geniusProduct' ? fourteenDaysInSeconds : oneMonthInSeconds;

  if (
    currentCompanyProduct?.status === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE &&
    currentCompanyProduct?.currentTermEnd > new Date() / 1000 && // don't show if dates in the past
    currentCompanyProduct?.currentTermEnd < new Date() / 1000 + timePeriod
  ) {
    return (
      <SoterTaskInfoBanner
        productInfo={currentCompanyProduct}
        name={productName}
      />
    );
  }
  
  return null;
};

export default useNumberOfTrialDaysLeftBanner;
