import { COLORS } from '../../../constants/colors';

const getRiskGroupsColor = (risk) => {
  switch (risk) {
    case 'lower': return COLORS.GREEN[600];
    case 'medium': return COLORS.ORANGE[600];
    case 'high': return COLORS.RED[600];
    default: return COLORS.GRAY[400];
  }
};

export default getRiskGroupsColor;
