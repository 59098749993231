import React from 'react';
import { useTranslation } from 'react-i18next';
import legendSquare from '../../../assets/jss/components/legendSquare';
import { text } from '../../../assets/jss/style';
import { COLORS } from '../../../constants/colors';
import calculateRebaRiskLevel, { rebaRiskTypes } from '../../utils/calculateRebaRiskLevel';
const Legend = ({ chartAnglesData, currentThreshold, selectedBodyPart }) => {
    const { t } = useTranslation();
    const rebaData = Object.values(rebaRiskTypes).map((v) => calculateRebaRiskLevel(v, t));
    const rebaHeaders = rebaData.map((arr) => arr[0]);
    const rebaColors = rebaData.map((arr) => arr[1]);
    const riskHeaders = selectedBodyPart === 'reba' ? rebaHeaders : [`${t('SOTER_TASK.ASSESSMENT.HIGH_RISK')}: `,
        `${t('SOTER_TASK.ASSESSMENT.MEDIUM_RISK')}: `,
        `${t('SOTER_TASK.ASSESSMENT.LOW_RISK')}: `];
    return (React.createElement("div", { style: {
            display: 'flex',
            padding: '0 21px',
            justifyContent: 'flex-start',
            gap: '25px',
            // width: '560px',
            margin: '7px 0 17px 0',
            fontSize: '14px',
        } }, riskHeaders.map((header, index) => {
        var _a;
        return (React.createElement("div", { key: `${header}_${index}`, style: {
                display: 'flex',
                alignItems: 'center',
            } }, selectedBodyPart === 'reba' ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: legendSquare(rebaColors[index]) }),
            React.createElement("div", null,
                React.createElement("span", { className: text(COLORS.GRAY[400], 14) }, header),
                React.createElement("span", null,
                    index === 4 && '1',
                    index === 3 && '2-3',
                    index === 2 && '4-7',
                    index === 1 && '8-10',
                    index === 0 && '11+')),
            React.createElement("div", null))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: legendSquare((_a = chartAnglesData[selectedBodyPart]) === null || _a === void 0 ? void 0 : _a.arcs[index].fill) }),
            React.createElement("div", null,
                React.createElement("span", { className: text(COLORS.GRAY[400], 14) }, header),
                React.createElement("span", null,
                    React.createElement("span", null,
                        index !== 2 && `${t('GENERAL.ABOVE').toLowerCase()} `,
                        (index !== 0) && currentThreshold[0],
                        index === 1 && `˚ ${t('GENERAL.TO').toLowerCase()} `,
                        index !== 2 && currentThreshold[1],
                        "\u02DA"))),
            React.createElement("div", null)))));
    })));
};
export default Legend;
