import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import { getMultiSelectionChecked, selectionHandler } from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import SimpleCompaniesTextCell from '../../containers/ItemTableContainer/components/CustomCells/SimpleCompaniesTextCell';
import RatingCell from '../../containers/ItemTableContainer/components/CustomCells/RatingCell';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { getFeedbacks } from './feedbacksStore/actions';
import { getMyCompanyUsers } from '../../company/users/dashboardUsersStore/actions';
import CreatedAtCellStringSpecified from './createdAtCellStringSpecific';

const feedbacksTable = Emotion.css`
    font-size: 14px;
`;

const FeedbacksContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const feedbacksState = useAppSelector((state) => state.feedbacks);
  const users = useAppSelector((state) => state.dashboardUsers.users);
  const selectedCompany = useAppSelector((state) => state.companies.selectedCompany);
  const dateRangePickerValue = useAppSelector((state) => state.coachUsers.dateRangePickerValue);
  const isLoadingFeedbacks = useAppSelector((state) => state.feedbacks.isLoading);
  const isLoadingUsers = useAppSelector((state) => state.dashboardUsers.isLoading);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);

  useEffect(() => {
    dispatch(getFeedbacks(0));
    dispatch(getMyCompanyUsers(selectedCompany.id));
  }, [dispatch, selectedCompany.id]);

  useEffect(() => {
    if (isLoadingFeedbacks || isLoadingUsers || !feedbacksState.feedbacksList.length || !users.length) {
      return;
    }

    const from = Number(dateRangePickerValue[0]);
    const to = Number(dateRangePickerValue[1]);
    function parseISOString(s) {
      const b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }

    const feedbacksWithUsers = (feedbacks, usersList) => {
      const usersById = usersList.reduce((acc, user) => ({ ...acc, [user.id]: user }), {});
      return feedbacks.map((feedback) => ({
        ...feedback,
        firstName: usersById[feedback.dashboardUserId]?.firstName,
        lastName: usersById[feedback.dashboardUserId]?.lastName,
      }));
    };

    const feedbacksInRange = feedbacksState.feedbacksList.filter((feedback) => {
      const createdAt = parseISOString(feedback.createdAt).getTime();
      const isInRange = createdAt >= from && createdAt <= to;
      return isInRange;
    });

    setFilteredFeedbacks(feedbacksWithUsers(feedbacksInRange, users));
  }, [feedbacksState.feedbacksList, users, dateRangePickerValue, isLoadingFeedbacks, isLoadingUsers]);

  const columns = (getActionForCompanies) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.feedbacks),
    // eslint-disable-next-line no-undef
    _,
    getActionForCompanies,
    TableType.feedbacks,
    t,
  );

  const cells = {
    dashboardUserId: SimpleCompaniesTextCell,
    reason: SimpleCompaniesTextCell,
    description: SimpleCompaniesTextCell,
    rate: RatingCell,
    createdAt: ({ rowData }) => <CreatedAtCellStringSpecified rowData={rowData} />,
  };

  return (
    <ItemTableContainer
      tableClass={feedbacksTable}
      items={filteredFeedbacks}
      columns={columns}
      cells={cells}
      type={TableType.feedbacks}
      isLoading={isLoadingFeedbacks || isLoadingUsers}
    />
  );
};

export default PrivatePageHOC(FeedbacksContainer);
