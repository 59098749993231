/* eslint-disable no-case-declarations */
import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { usersParametersSetter } from './usersParametersSetter';

export const usersState = Immutable(
  {
    users: [],
    error: null,
    deletedUsers: [],
    failToDeleteUsers: [],
    isLoading: false,
    dateRangePickerValue: [],
    createdStatuses: [],
    errorsOnEdit: [],
  },
);

export default function reduce(state = usersState, action = {}) {
  switch (action.type) {
    case types.USERS_SET_CREATED_STATUSES:
      // eslint-disable-next-line no-case-declarations
      const { createdStatuses } = action;
      return state.merge({
        createdStatuses,
        error: null,
      });
    case types.USERS_SET:
      // eslint-disable-next-line no-case-declarations
      const { users } = action;
      return state.merge({
        users,
        error: null,
      });
    case types.USERS_TOGGLE_FILTERS:
      // eslint-disable-next-line no-case-declarations
      const { filters } = action;
      return state.merge({
        filters: { ...state.filters, ...filters },
      });
    case types.USERS_TOGGLE_LOADING:
      // eslint-disable-next-line no-case-declarations
      const { isLoading } = action;
      return state.merge({ isLoading });
    case types.USERS_ERROR:
      // eslint-disable-next-line no-case-declarations
      const { error } = action;
      return state.merge({ error });
    case types.USERS_RESET:
      return state.merge({ ...usersState, pagination: state.pagination });
    case types.USERS_REMOVE_TAG:
      const { userId, entryId } = action.removeAction;
      const userIndex = state.users.indexOf(state.users.find((user) => user.id === userId));
      const renewedUser = {
        ...state.users[userIndex],
        tagsList: state.users[userIndex]?.tagsList.filter((tag) => tag.id !== entryId),
      };
      const mutableUsers = [...state.users];
      mutableUsers.splice(userIndex, 1, renewedUser);
      return state.merge({ ...usersState, users: mutableUsers });
    case types.USERS_ADD_TAG:
      const { categories } = action.addAction;
      const entryForAdding = categories.find((c) => c?.tagsList.find(
        (entries) => entries.id === +action.addAction.entryId,
      ))?.tagsList.find(
        (entries) => entries.id === +action.addAction.entryId,
      );
      const usersWithEntry = state.users.map(user => {
        if (action.addAction.userIds.includes(user.id)) {
          return {
            ...user,
            tagsList: [...user.tagsList, entryForAdding],
          };
        }
        return user;
      });
      return state.merge({ ...usersState, users: usersWithEntry });
    case types.USERS_SET_DEPARTMENT:
      const departmentChangeList = action.statusesList;
      const { departments, departmentId } = action;
      const usersForSetDeps = [...state.users];
      // eslint-disable-next-line no-shadow
      departmentChangeList.map(({ userId, status }) => {
        if (status === 'ok') {
          const { userForChangeDepartment, renewedUserForSetDepartment } = usersParametersSetter([...state.users],
            'department',
            departments,
            departmentId,
            userId);
          usersForSetDeps.splice(userForChangeDepartment, 1, renewedUserForSetDepartment);
        }
      });
      return state.merge({ ...usersState, users: usersForSetDeps });
    case types.USERS_SET_JOB_ROLE:
      const { jobRolesChangeList, jobRoleId, jobRoles } = action;
      const usersForSetJobRoles = [...state.users];
      jobRolesChangeList.map(({ userId, status }) => {
        if (status === 'ok') {
          const { userForChangeJobRole, renewedUserForSetJobRole } = usersParametersSetter([...state.users],
            'jobRole',
            jobRoles,
            jobRoleId,
            userId);
          usersForSetJobRoles.splice(userForChangeJobRole, 1, renewedUserForSetJobRole);
        }
      });
      return state.merge({ ...usersState, users: usersForSetJobRoles });
    case types.USERS_SET_SITE:
      const { siteId, sites, siteChangeList } = action;
      const usersForSetSites = [...state.users];
      siteChangeList.map(({ userId, status }) => {
        if (status === 'ok') {
          const { userForChangeSite, renewedUserForSetSite } = usersParametersSetter(
            [...state.users],
            'site',
            sites,
            siteId,
            userId,
          );
          usersForSetSites.splice(userForChangeSite, 1, renewedUserForSetSite);
        }
      });
      return state.merge({ ...usersState, users: usersForSetSites });
    case types.USERS_SET_PICKER_VALUE:
      const { dateRangePickerValue } = action;
      return state.merge({ ...usersState, dateRangePickerValue });
    case types.USERS_DELETE: {
      const { deletedUsers, failToDeleteUsers } = action;

      // Create maps for quick ID lookup
      const deletedUsersMap = new Map(deletedUsers.map(user => [user.id, user]));
      const failToDeleteUsersMap = new Map(failToDeleteUsers.map(user => [user.id, user]));

      const newUsers = [];
      const deleted = [];
      const failed = [];

      // Single iteration over the users
      state.users.forEach(user => {
        if (deletedUsersMap.has(user.id)) {
          // Add additional information for deleted users
          deleted.push({
            ...deletedUsersMap.get(user.id),
            firstName: user.firstName,
            lastName: user.lastName,
            nickname: user.nickname,
          });
        } else if (failToDeleteUsersMap.has(user.id)) {
          // Add additional information for failed-to-delete users
          failed.push({
            ...failToDeleteUsersMap.get(user.id),
            firstName: user.firstName,
            lastName: user.lastName,
            nickname: user.nickname,
          });
        } else {
          // Keep users that are not deleted
          newUsers.push(user);
        }
      });

      // Correctly update the state by returning a new object
      return state.merge({
        ...state,
        deletedUsers: deleted,
        failToDeleteUsers: failed,
        users: newUsers,
      });
    }

    // case types.USERS_SET_CREATED_STATUSES:
    //   const { usersStatus } = action;
    //   return state.merge({ createdStatuses: usersStatus });
    case types.USERS_RESET_DELETED:
      return state.merge({ deletedUsers: [], failToDeleteUsers: [] });
    case types.USERS_EDIT_STATUSES:
      return state.merge({ ...state, errorsOnEdit: action.errorsOnEdit });
    case types.USERS_CLEAR_EDIT_STATUSES:
      return state.merge({ ...state, errorsOnEdit: [] });
    case types.USERS_EDIT_SET:

      const usersMap = new Map(action.users.map((user) => [user.id, user]));

      const updatedUsers = state.users.map((user) => {
        const matchedUser = usersMap.get(user.id);
        if (matchedUser) {
          return {
            ...user,
            firstName: matchedUser.firstName,
            lastName: matchedUser.lastName,
            nickname: matchedUser.nickname,
          };
        }
        return user;
      });

      return state.merge({ ...state, users: updatedUsers });

    default: return state;
  }
}
