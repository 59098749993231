import React from 'react';
import { useTranslation } from 'react-i18next';

const DaysPassedCell = ({ rowData }) => {
  const days = rowData.program
    ? rowData.program?.daysPassed
    : rowData.daysPassed;
  const { t } = useTranslation();
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        {days === 1
          ? // eslint-disable-next-line react/destructuring-assignment
            `${days} ${t('GENERAL.DAY')}`
          : `${days || 0} ${t('GENERAL.DAYS')}`}
      </div>
    </>
  );
};

export default DaysPassedCell;
