/* eslint no-param-reassign: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-shadow: 0 */
/* eslint no-underscore-dangle:0 */
import * as d3 from 'd3';
import Chart, { LegendPosition } from '../Chart';
import getHMPresentationName from '../../../../../constants/getHMPresentationName';
import { emptyDonutChartData } from '../../../constants/emptyChartData/emptyDonutChartData';
import { COLORS } from 'constants/colors'

export default class DonutChart extends Chart {
  constructor(id) {
    super(id);
    this._overallNumber = 0;
    this._overallText = '';
  }

  setOverallNumber(value) {
    this._overallNumber = value;
  }

  get overallNumber() {
    return this._overallNumber;
  }

  setOverallText(value = '') {
    this._overallText = value;
  }

  get overallText() {
    return this._overallText;
  }

  calculateXTextPosition() {
    switch (this.legendPosition) {
      case LegendPosition.RIGHT:
        return 0.37 * this.width + 35;
      case LegendPosition.BOTTOM:
        return this.width - 10;
      default:
        return 200;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  drawLegend(data, isEmpty = false) {
    const xTextPosition = this.calculateXTextPosition();
    return (g) => g
      .append('g')
      .selectAll('g')
      .data(data.filter((d) => !d.columns))
      .join('g')
      .attr(
        'transform',
        (d, i) => `translate(0,${
          (i - (data.length - 1))
              * (data.length === 4 && this.legendPosition === LegendPosition.RIGHT
                ? 70
                : 45)
        })`,
      )
      .call((g) => g
        .append('rect')
        .attr('width', 12)
        .attr('height', 12)
        .attr(
          'y',
          data.length === 4 && this.legendPosition === LegendPosition.RIGHT
            ? 90
            : 0,
        )
        .style(
          'fill',
          (d) => `url("#${d.name ? d.name : 'noHazardousMovements'}")`,
        ))
      .call((g) => g
        .append('text')
        .attr('x', 20)
        .attr(
          'y',
          data.length === 4 && this.legendPosition === LegendPosition.RIGHT
            ? 97
            : 7,
        )
        .attr('dy', '0.25em')
        .attr('fill', COLORS.GRAY[950])
        .attr('font', 'Mulish')
        .attr('font-size', '0.625rem')
        .text((d) => this.t(getHMPresentationName(d.name))))
      .call((g) => g
        .append('text')
        .attr('text-anchor', 'end')
        .attr('x', xTextPosition)
        .attr(
          'y',
          data.length === 4 && this.legendPosition === LegendPosition.RIGHT
            ? 97
            : 7,
        )
        .attr('dy', '0.25em')
        .attr('fill', COLORS.GRAY[950])
        .attr('font', 'Mulish')
        .attr('font-size', '0.75rem')
        .text((d) => (isEmpty ? 0 : `${d.value}${d.unit || ''}`)));
  }

  draw(gradients) {
    const currentTooltip = d3.select(`#${this.id}_tooltip`);
    if (currentTooltip) currentTooltip.remove();
    const pie = d3
      .pie()
      .padAngle(0.005)
      .sort(null)
      .value((d) => d.value);
    const arcs = pie(this.data);

    const arc = () => d3
      .arc()
      .innerRadius(this.radius * 0.83)
      .outerRadius(this.radius - 1);

    this.drawSvg([
      -this.width / 2,
      -this.height / 2.5,
      this.width,
      this.height,
    ]);

    const svg = d3.select(`svg.${this.id}-chart`);

    this.createGradients(svg, gradients);

    const chartTranslate = this.calculateChartTranslation();

    const tooltip = d3
      .select('#page_layer')
      .append('div')
      .attr('id', `${this.id}_tooltip`)
      .attr('class', 'tooltip')
      .style('opacity', 0);

    svg
      .append('g')
      .attr('class', `donut-${this.id}`)
      .attr('transform', `translate(${chartTranslate})`)
      .selectAll('path')
      .data(arcs)
      .join('path')
      .attr('class', (d) => d.data.name)
      .style('fill', (d) => `url("#${d.data.name ? d.data.name : 'default'}")`)
      .attr('d', arc())
      .on('mouseover', (event, d) => {
        tooltip
          .transition()
          .duration(200)
          // Here is tooltip hiding
          .style('opacity', 0);
        tooltip
          .html(`${getHMPresentationName(d.data.name)}: ${d.data.value}`)
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', (d) => {
        tooltip.transition().duration(500).style('opacity', 0);
      })
      .append('title');

    if (this.withLegend) {
      svg
        .append('g')
        .attr('class', `legend-${this.id}`)
        .attr('transform', `translate(${this.calculateLegendTranslation()})`)
        .call(this.drawLegend(this.data));
    }

    d3.select(`g.donut-${this.id}`)
      .append('g')
      .attr(
        'transform',
        `${this.overallText ? 'translate(0, 0)' : 'translate(0, 20)'}`,
      )
      .attr('class', `number-overall-${this.id}`)
      .call((g) => g
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', COLORS.GRAY[950])
        .attr('style', 'font-size: 3.6em')
        .text(this.overallNumber));

    d3.select(`g.number-overall-${this.id}`)
      .append('g')
      .attr('transform', 'translate(0, 40)')
      .call((g) => g
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', COLORS.GRAY[400])
        .attr('style', 'font-size: 1em')
        .text(this.overallText.toUpperCase()));
  }

  drawEmpty(gradients, programTYpe = '', legendItems) {
    const pie = d3
      .pie()
      .padAngle(0.005)
      .sort(null)
      .value((d) => d.value);
    const arcs = pie(emptyDonutChartData(programTYpe));

    const arc = () => d3
      .arc()
      .innerRadius(this.radius * 0.83)
      .outerRadius(this.radius - 1);

    d3.select(`#${this.id}`)
      .append('svg')
      .attr('class', `${this.id}-chart`)
      .attr('viewBox', [
        -this.width / 2,
        -this.height / 2.5,
        this.width,
        this.height,
      ]);

    const svg = d3.select(`svg.${this.id}-chart`);

    svg
      .append('defs')
      .selectAll('linearGradient')
      .data(gradients)
      .join('linearGradient')
      .attr('id', (d) => d.hazard)
      .call((g) => g
        .append('stop')
        .attr('class', 'stop-left')
        .attr('offset', '0%')
        .attr('stop-color', (d) => d.leftStopColor))
      .call((g) => g
        .append('stop')
        .attr('class', 'stop-right')
        .attr('offset', '100%')
        .attr('stop-color', (d) => d.rightStopColor))
      .attr('gradientTransform', 'rotate(45)');

    const chartTranslate = this.calculateChartTranslation();

    svg
      .append('g')
      .attr('class', `donut-${this.id}`)
      .attr('transform', `translate(${chartTranslate})`)
      .selectAll('path')
      .data(arcs)
      .join('path')
      .attr('class', (d) => d.data.name)
      .style('fill', '#8a8da6')
      .attr('d', arc());

    if (this.withLegend) {
      const emptyDonutLegend = emptyDonutChartData(programTYpe);
      const filteredLegend = legendItems
        ? emptyDonutLegend.filter((item) => legendItems.includes(item.name))
        : emptyDonutLegend;
      svg
        .append('g')
        .attr('class', `legend-${this.id}`)
        .attr(
          'transform',
          `translate(${this.calculateEmptyLegendTranslation(
            filteredLegend.length,
          )})`,
        )
        .call(this.drawLegend(filteredLegend, true));
    }

    d3.select(`g.donut-${this.id}`)
      .append('g')
      .attr('transform', 'translate(0, 10)')
      .attr('class', 'number-overall')
      .call((g) => g
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', COLORS.GRAY[400])
        .attr('style', 'font-size: 1em')
        .text(this.t('GENERAL.NO_DATA').toUpperCase()));
  }
}
