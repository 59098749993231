import React from 'react';
import { Rect } from 'react-konva';
import { riskGroupColors } from '../../video/constants';

const RebaChartRisks = ({ points, width, rebaWeights }) => {
  const initialYAxis = 52;
  const yAxisForOneRow = 128;
  const distance = 40;
  const rebaWeightsLength = rebaWeights.length;
  const yAxis = !rebaWeightsLength
    ? initialYAxis
    : rebaWeightsLength === 1
      ? yAxisForOneRow
      : initialYAxis + ((rebaWeightsLength + 1) * distance);

  const getHazardsColor = (score) => {
    let color;
    if (score >= 8) {
      color = riskGroupColors[3];
    } else if (score >= 4 && score <= 7) {
      color = riskGroupColors[2];
    } else if (score >= 2 && score <= 3) {
      color = riskGroupColors[1];
    } else {
      color = '';
    }
    return color;
  };

  return points?.map((pointsChunk, index) => pointsChunk?.map((point) => (
    <Rect
      key={index}
      width={3}
      height={16}
      x={index / (points.length / width)}
      y={yAxis}
      fill={getHazardsColor(point)}
    />
  )));
};

export default RebaChartRisks;
