import BarChart from './drawers/BarChart';
import DonutChart from './drawers/DonutChart';
import StackedChart from './drawers/StackedChart';
import GroupedBarChart from './drawers/GroupedBarChart';

export const ChartTypeMap = {
  bar: BarChart,
  stacked: StackedChart,
  donut: DonutChart,
  groupedBar: GroupedBarChart,
};

class ChartFactory {
  constructor() {
    this.chartTypeMap = ChartTypeMap;
  }

  getChartDrawer(type, id) {
    return new this.chartTypeMap[type](id);
  }
}

export default ChartFactory;
