import _compact from 'lodash/compact';
import _get from 'lodash/get';
import moment from 'moment';
import { TableType } from '../constants/tableType';
import calculateRebaRiskLevel from '../../../task/utils/calculateRebaRiskLevel';

const basicSearchProps = new Set([
  'firstName',
  'lastName',
  'jobTitle',
  'macAddress',
  'name',
  'id',
  'region',
  'reseller',
  'title',
  'workgroupTitle',
  'createdBy',
  'number',
  'companyCode',
  'login',
  'nickname',
  'departmentName',
  'imei',
  'taskName',
  'alias',
]);

// eslint-disable-next-line array-callback-return
const search = (items, value, type) => _compact(
  items.map(
    (user) => Object.keys(user).filter((prop) => {
      const userProp = type === 'help' ? user.user[prop] : user[prop];
      if (
        userProp?.toString().toLowerCase().indexOf(value.toLowerCase())
              > -1 &&
            basicSearchProps.has(prop)
      ) {
        return true;
      }

      if (
        prop === 'createdBy' &&
            (type === TableType.soterTaskVideos
              || type === TableType.soterTaskComparison) &&
            userProp &&
            userProp?.name
              ?.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
      ) return true;
      if (
        prop === 'taskName' &&
            (type === TableType.soterTaskVideos
              || type === TableType.soterTaskComparison) &&
            userProp &&
            userProp?.title
              ?.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
      ) return true;
      if (
        prop === 'tagsList' &&
            type === TableType.coach &&
            userProp &&
            userProp?.some(
              ({ title }) => title?.toString().toLowerCase().indexOf(value.toLowerCase())
                > -1,
            )
      ) return true;
      if (
        prop === 'email' &&
            userProp &&
            userProp?.toString().toLowerCase().indexOf(value.toLowerCase()) > -1
      ) return true;
      if (
        (prop === 'department' || prop === 'program') &&
            userProp &&
            userProp?.name
              ?.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
      ) return true;
      if (
        prop === 'jobRole' &&
            userProp &&
            userProp?.title
              ?.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
      ) return true;
      if (
        prop === 'device' &&
            userProp &&
            userProp?.macAddress
              ?.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
      ) return true;
      if (
        prop === 'user' &&
            user[prop] &&
            (user[prop]?.firstName
              ?.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
              || user[prop]?.lastName
                ?.toString()
                .toLowerCase()
                .indexOf(value.toLowerCase()) > -1
              || user[prop]?.department?.name
                ?.toString()
                .toLowerCase()
                .indexOf(value.toLowerCase()) > -1)
      ) return true;
      if (
        prop === 'comment' &&
            user[prop] &&
            user[prop]?.toString().toLowerCase().indexOf(value.toLowerCase())
              > -1
      ) return true;
    }).length > 0 && user,
  ),
);

const searchStringFilter = (items, searchValue, type) => {
  let filteredItems = items;
  const searchValues = searchValue.split(' ');
  // eslint-disable-next-line no-shadow
  searchValues.forEach((value) => {
    filteredItems = search(filteredItems, value, type);
  });
  return filteredItems;
};

const departmentFilter = (users, departments, type) => users.filter((user) => {
  const dep = type === 'help' ? user.user.department : user.department;
  const userWithProperDepartment
      = departments.reduce((acc, department) => {
        if ((dep && dep.id === department) || (department === -1 && !dep)) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return (
    user.id === (userWithProperDepartment && userWithProperDepartment.id)
  );
});

const siteFilter = (users, sites) => users.filter((user) => {
  const st = user.site;
  const userWithProperSite
      = sites.reduce((acc, site) => {
        if ((st && st.id === site) || (site <= 0 && !st)) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperSite && userWithProperSite.id);
});

const typeFilter = (users, types) => users.filter((user) => {
  const userWithProperTypes
      = types.reduce((acc, type) => {
        if ((type && user.device) || (!type && !user.device)) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperTypes && userWithProperTypes.id);
});

const riskFilter = (users, risks) => users.filter((user) => {
  const userWithProperRiskGroup
      = risks.reduce((acc, risk) => {
        if (user.program?.currentRiskGroup === risk) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperRiskGroup && userWithProperRiskGroup.id);
});

const initialRiskFilter = (users, risks) => users.filter((user) => {
  const userWithProperRiskGroup
      = risks.reduce((acc, risk) => {
        if (user.program?.initialRiskGroup === risk) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperRiskGroup && userWithProperRiskGroup.id);
});

const resultFilter = (users, results) => users.filter((user) => {
  const userWithProperResult
      = results.reduce((acc, result) => {
        if (
          (result === 'improved' &&
            user.program?.improvements?.hazardousMovementRatioChange < 0)
          || (result === 'flat' &&
            (!user.program?.improvements
              || user.improvements?.hazardousMovementRatioChange === 0))
          || (result === 'worse' &&
            user.program?.improvements?.hazardousMovementRatioChange > 0)
        ) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperResult && userWithProperResult.id);
});

const userTypeFilter = (users, type) => users.filter((user) => {
  const userWithProperResult
      = type.reduce((acc, result) => {
        if (
          (result === 'coach' &&
            (user.currentUser?.userType === 'coach'
              || user.lastUser?.userType === 'coach'))
          || (result === 'clipgo' &&
            (user.currentUser?.userType === 'clipgo'
              || user.lastUser?.userType === 'clipgo'))
        ) {
          return [...acc, user];
        }
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperResult && userWithProperResult.id);
});

const wearingStatusFilter = (users, status) => users.filter((user) => {
  const userWithProperResult
      = status.reduce((acc, result) => {
        if (
          (result === 'wearing_now' && user.currentUser)
          || (result === 'not_wearing' && !user.currentUser)
        ) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperResult && userWithProperResult.id);
});

const hubStatusFilter = (hubs, status) => hubs.filter((hub) => {
  const hubWithProperResult
      = status.reduce((acc, result) => {
        if (
          (result === 'active' &&
            hub.isActive &&
            moment().format('x') - hub.lastSeenMilliseconds <= 604800000)
          || (result === 'deactivated' && !hub.isActive)
          || (result === 'notSetUp' &&
            hub.isActive &&
            !hub.lastSeenMilliseconds)
          || (result === 'inactive' &&
            hub.isActive &&
            hub.lastSeenMilliseconds &&
            moment().format('x') - hub.lastSeenMilliseconds > 604800000)
        ) return [...acc, hub];
        return acc;
      }, [])[0] || null;
  return hub.id === (hubWithProperResult && hubWithProperResult.id);
});

const programFilter = (users, programs, type) => users.filter((user) => {
  const userWithProperProgram
      = programs.reduce((acc, program) => {
        if (user.program && user.program.id === program) return [...acc, user];
        if (program === 0 && !user.program) return [...acc, user];
        if (
          type === 'companies' &&
          user.availableCoachingProgramsList.some((e) => e.id === program)
        ) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperProgram && userWithProperProgram.id);
});

const clipProgramsFilter = (users, programs) => users.filter((user) => {
  const userWithProperProgram
      = programs.reduce((acc, program) => {
        if (user.deviceType === program) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperProgram && userWithProperProgram.id);
});

const jobDepartmentFilter = (users, departments) => users.filter((user) => {
  const userWithProperDepartment
      = departments.reduce((acc, department) => {
        if (user.data.department && user.data.department === department) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return (
    user.id === (userWithProperDepartment && userWithProperDepartment.id)
  );
});

const groupFilter = (users, groups) => users.filter((user) => {
  const userWithProperDepartment
      = groups.reduce((acc, group) => {
        if (user.workgroupTitle === group) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return (
    user.id === (userWithProperDepartment && userWithProperDepartment.id)
  );
});

const helpStatusFilter = (users, statuses) => users.filter((user) => {
  const userWithProperStatus
      = statuses.reduce((acc, status) => {
        if (user.status === status) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperStatus && userWithProperStatus.id);
});

const companiesFilter = (items, companiesIds, type) => items.filter((item) => {
  if (type === 'feedbacks' && companiesIds.length) {
    return companiesIds.includes(item.companyId);
  }

  if (item?.companiesList.length) {
    return item.companiesList.some((company) => companiesIds.includes(company.id));
  }

  return null;
});

const videoStatusFilter = (users, statuses) => users.filter((user) => {
  const userWithProperStatus
      = statuses.reduce((acc, status) => {
        if (user.status === status) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperStatus && userWithProperStatus.id);
});

const maxRebaScoreFilter = (videos, statuses) => videos.filter((video) => {
  const videoRiskType = calculateRebaRiskLevel(video.maxRebaScore, () => null)[2];
  const videoWithProperStatus
    = statuses.reduce((acc, status) => {
      if ((videoRiskType) === status) return [...acc, video];
      return acc;
    }, [])[0] || null;
  return video.id === (videoWithProperStatus && videoWithProperStatus.id);
});

const videoCreatedByFilter = (users, creators) => users.filter((user) => {
  const userWithProperStatus
      = creators.reduce((acc, creator) => {
        if (user.createdBy.name === creator) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperStatus && userWithProperStatus.id);
});

const partnerFilter = (users, isPartnerValues) => {
  if (isPartnerValues.length === 0 || isPartnerValues.length === 2) {
    return users;
  }

  const isPartnerTrue = isPartnerValues[0] === true;

  return users.filter((user) => {
    if (isPartnerTrue) {
      return user.resellerId > 0;
    }
    return user.resellerId === 0;
  });
};

const newUserStatusFilter = (users, statuses) => users.filter((user) => {
  const userWithProperStatus
      = statuses.reduce((acc, status) => {
        if (user.program?.filterStatus === +status) return [...acc, user];
        return acc;
      }, [])[0] || null;
  return user.id === (userWithProperStatus && userWithProperStatus.id);
});

const customCategoryFilter = () => (items, filterValues) => {
  const filtered = [];
  items.map((item) => item?.tagsList.map((tag) => {
    if (filterValues.includes(tag.id)) filtered.push(item);
  }));
  return filtered;
};

const simpleFilter = (items, filterValues, { fieldLabel }) => {
  const hash = new Set(
    filterValues.map((item) => (item === null ? undefined : item)),
  );
  return items.filter((item) => hash.has(_get(item, fieldLabel)));
};

const generateSimpleFilter = (options) => (items, filterValues) => simpleFilter(items, filterValues, options);

const filters = {
  searchString: searchStringFilter,
  programs: programFilter,
  wearingStatus: wearingStatusFilter,
  type: userTypeFilter,
  results: resultFilter,
  risks: riskFilter,
  initialRisks: initialRiskFilter,
  types: typeFilter,
  departments: departmentFilter,
  sites: siteFilter,
  jobRoles: generateSimpleFilter({ fieldLabel: 'jobRole.title' }),
  jobRolesHelp: generateSimpleFilter({ fieldLabel: 'user.jobRole.title' }),
  statuses: newUserStatusFilter,
  clipPrograms: clipProgramsFilter,
  group: groupFilter,
  jobDepartments: jobDepartmentFilter,
  status: helpStatusFilter,
  regions: generateSimpleFilter({ fieldLabel: 'region' }),
  resellers: generateSimpleFilter({ fieldLabel: 'partner.name' }),
  createdBy: generateSimpleFilter({ fieldLabel: 'createdBy' }),
  companies: companiesFilter,
  canInviteUsers: generateSimpleFilter({ fieldLabel: 'canInviteUsers' }),
  canReceiveNeedHelpRequests: generateSimpleFilter({
    fieldLabel: 'receiveNeedHelpRequests',
  }),
  isAdmin: generateSimpleFilter({ fieldLabel: 'admin' }),
  isPartner: partnerFilter,
  isSubscriptionManager: generateSimpleFilter({
    fieldLabel: 'isSubscriptionManager',
  }),
  dateOfUpload: () => {},
  videoCreatedBy: generateSimpleFilter({ fieldLabel: 'createdBy.name' }),
  videoTaskName: generateSimpleFilter({ fieldLabel: 'taskName.title' }),
  videoJobRole: generateSimpleFilter({ fieldLabel: 'jobRole.title' }),
  videoRisk: generateSimpleFilter({ fieldLabel: 'risks.overallRisk' }),
  videoStatus: videoStatusFilter,
  comparisonCreatedBy: generateSimpleFilter({ fieldLabel: 'createdBy' }),
  hubTypes: generateSimpleFilter({ fieldLabel: 'hubType' }),
  hubCompanies: generateSimpleFilter({ fieldLabel: 'companyId' }),
  hubStatus: hubStatusFilter,
  videoType: generateSimpleFilter({ fieldLabel: 'videoType' }),
  videoCategory: generateSimpleFilter({ fieldLabel: 'category' }),
  showPassedPrograms: (items) => items,
  typeOfProblem: generateSimpleFilter({ fieldLabel: 'comment' }),
  departmentsTask: generateSimpleFilter({ fieldLabel: 'departmentId' }),
  sitesTask: generateSimpleFilter({ fieldLabel: 'siteId' }),
  departmentsComparison: generateSimpleFilter({
    fieldLabel: 'firstTaskVideo.departmentId',
  }),
  sitesComparison: generateSimpleFilter({
    fieldLabel: 'firstTaskVideo.siteId',
  }),
  customCategory: customCategoryFilter(),
  maxRebaScore: maxRebaScoreFilter,
};

const getStateCondition = (filtersStateForItemType) => {
  const stateKeys = Object.keys(filtersStateForItemType);
  return stateKeys.reduce((acc, key) => {
    if (filtersStateForItemType[key].length) {
      return [...acc, key];
    }
    return acc;
  }, []);
};

// eslint-disable-next-line import/prefer-default-export
export const filterItemsAccordingToFilterState = (
  items,
  filtersState,
  type,
) => {
  const conditions = getStateCondition(filtersState);
  // !TODO remove condition below after backend fix case sensitivity in jobRoles
  if (filtersState.jobRoles) {
    filtersState = { ...filtersState, jobRoles: filtersState.jobRoles.map((role) => role.toLowerCase()) };
  }
  if (conditions.length === 0) return items;
  let filteredItems = [...items];
  conditions.forEach((condition) => {
    filteredItems = filters[
      condition.includes('customCategory') ? 'customCategory' : condition
    ](filteredItems, filtersState[condition], type);
  });
  return filteredItems;
};
