import * as Emotion from 'emotion';
import { flexColumn } from './flex-base-elements';

// eslint-disable-next-line import/prefer-default-export
export const loginContainer = Emotion.css`
  ${flexColumn};

  display: block;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transform: translate(0, 1vh);
  
  flex: 1 1 100%;
  @media (min-width: 768px) {
    margin-bottom: 0;
    flex: 0 1 60%;
  }
  @media (min-width: 1240px) {
    margin-bottom: 0;
    flex: 0 1 35%;
  }
`;
