import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  coachUsers: [],
  clipGoUsers: [],
  devices: [],
  companies: [],
  dashboardUsers: [],
  soterTaskVideos: [],
  entries: [],
});

export default function reducer(state = initialState, action) {
  let uniqueSelectedUsers = [];
  let reducedUsers = [];
  const userMap = new Map();

  switch (action.type) {
    case types.SET_SELECTED_ENTRIES:
      uniqueSelectedUsers = [
        ...state.entries,
        ...action.entries,
      ].reduce((acc, entry) => {
        if (!userMap.has(entry.id)) {
          userMap.set(entry.id, true);
          return [...acc, entry];
        }
        return acc;
      }, []);
      return { ...state, entries: uniqueSelectedUsers };
    case types.SET_SELECTED_COACH:
      uniqueSelectedUsers = [
        ...state.coachUsers,
        ...action.users,
      ].reduce((acc, user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, true);
          return [...acc, user];
        }
        return acc;
      }, []);
      return { ...state, coachUsers: uniqueSelectedUsers };
    case types.SET_SELECTED_CLIPGO:
      uniqueSelectedUsers = [
        ...state.clipGoUsers,
        ...action.users,
      ].reduce((acc, user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, true);
          return [...acc, user];
        }
        return acc;
      }, []);
      return { ...state, clipGoUsers: uniqueSelectedUsers };
    case types.SET_SELECTED_DEVICES:
      uniqueSelectedUsers = [
        ...state.devices,
        ...action.devices,
      ].reduce((acc, user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, true);
          return [...acc, user];
        }
        return acc;
      }, []);
      return { ...state, devices: uniqueSelectedUsers };
    case types.SET_SELECTED_COMPANIES:
      uniqueSelectedUsers = [
        ...state.companies,
        ...action.companies,
      ].reduce((acc, user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, true);
          return [...acc, user];
        }
        return acc;
      }, []);
      return { ...state, companies: uniqueSelectedUsers };
    case types.SET_SELECTED_DASHBOARD_USERS:
      uniqueSelectedUsers = [
        ...state.dashboardUsers,
        ...action.dashboardUsers,
      ].reduce((acc, user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, true);
          return [...acc, user];
        }
        return acc;
      }, []);
      return { ...state, dashboardUsers: uniqueSelectedUsers };
    case types.SET_SELECTED_SOTERTASK_VIDEOS:
      uniqueSelectedUsers = [
        ...state.soterTaskVideos,
        ...action.soterTaskVideos,
      ].reduce((acc, user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, true);
          return [...acc, user];
        }
        return acc;
      }, []);
      return { ...state, soterTaskVideos: uniqueSelectedUsers };
    case types.REMOVE_SELECTED_COACH:
      reducedUsers = state.coachUsers
        .reduce((acc, user) => (action.users
          .map((actionUser) => actionUser?.id).includes(user.id) ? acc : [...acc, user]), []);
      return { ...state, coachUsers: [...reducedUsers] };
    case types.REMOVE_SELECTED_ENTRIES:
      reducedUsers = state.entries
        .reduce((acc, entry) => (action.entries
          .map((actionUser) => actionUser.id).includes(entry.id) ? acc : [...acc, entry]), []);
      return { ...state, entries: [...reducedUsers] };
    case types.REMOVE_SELECTED_CLIPGO:
      reducedUsers = state.clipGoUsers
        .reduce((acc, user) => (action.users
          .map((actionUser) => actionUser.id).includes(user.id) ? acc : [...acc, user]), []);
      return { ...state, clipGoUsers: [...reducedUsers] };
    case types.REMOVE_SELECTED_DEVICES:
      reducedUsers = state.devices
        .reduce((acc, user) => (action.devices
          .map((actionUser) => actionUser.id).includes(user.id) ? acc : [...acc, user]), []);
      return { ...state, devices: [...reducedUsers] };
    case types.REMOVE_SELECTED_COMPANIES:
      reducedUsers = state.companies
        .reduce((acc, user) => (action.companies
          .map((actionUser) => actionUser.id).includes(user.id) ? acc : [...acc, user]), []);
      return { ...state, companies: [...reducedUsers] };
    case types.REMOVE_SELECTED_DASHBOARD_USERS:
      reducedUsers = state.dashboardUsers
        .reduce((acc, user) => (action.dashboardUsers
          .map((actionUser) => actionUser.id).includes(user.id) ? acc : [...acc, user]), []);
      return { ...state, dashboardUsers: [...reducedUsers] };
    case types.REMOVE_SELECTED_SOTERTASK_VIDEOS:
      reducedUsers = state.soterTaskVideos
        .reduce((acc, user) => (action.soterTaskVideos
          .map((actionUser) => actionUser.id).includes(user.id) ? acc : [...acc, user]), []);
      return { ...state, soterTaskVideos: [...reducedUsers] };
    case types.RESET_ALL_SELECTED:
      return initialState;
    default: return state;
  }
}
