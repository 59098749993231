import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';
export const wrapper = Emotion.css `
  color: ${COLORS.GRAY[950]};
  background: rgb(245, 247, 251);
  align-content: center;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
`;
export const content = Emotion.css `
  max-width: 1100px;
  margin: 0 auto;
`;
export const header = Emotion.css `
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  margin-left: 160px;

  h1 {
    font-size: 32px;
    font-weight: 900;
    padding-right: 28px;
    position: relative;

    &::after {
      content: '';
      width: 2px;
      height: 32px;
      background: ${COLORS.GRAY[950]};
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      position: absolute;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 800;
    margin-top: 6px;
    margin-left: 2px;
  }
`;
export const desc = Emotion.css `
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px;
  max-width: 740px;
  margin-left: 160px;
`;
export const adv = Emotion.css `
  margin: 0 auto;
  padding-top: 44px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  padding-bottom: 32px;

  ul {
    margin-left: 185px;
    padding: 0;
  }

  li {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    max-width: 340px;
  }

  .withoutVideo li {
    max-width: 440px;
  }
`;
export const cta = Emotion.css `
  margin-left: 160px;
  padding-top: 30px;
  padding-bottom: 40px;
  position: relative;

  &::after {
    content: '';
    width: 784px;
    height: 1px;
    background: rgba(0, 0, 0, 0.16);
    bottom: 0;
    position: absolute;
  }

  button {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500 !important;
    width: 300px;
    width: auto;
    height: 40px;
    background: #0D77D6;
  }
`;
