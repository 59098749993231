import React, { useEffect } from 'react';
import * as Emotion from 'emotion';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import arrowRight from '../../assets/img/icons/arrow-right.svg';
import SinglePage from 'containers/PdfContainer/SinglePage';
import { SINGLE_PAGE_TYPE } from '../../containers/PdfContainer/constants';
import { text } from '../../assets/jss/style';
import { COLORS } from '../../constants/colors';
import AssessmentsReportList from '../components/AssessmentsReportList';
import { border, header, titleWithBadge } from '../../containers/PdfContainer/styles';
import SecondaryHeader from '../../containers/PdfContainer/components/SecondaryHeader';
import { Risks } from '../../init/proto_client';
import RiskGroupBadge from '../../lib/ui/RiskGroupBadge/RiskGroupBadge';
import { getAssessmentForVideo } from './comparisonsStore/actions';
import { dataSplitting } from '../utils/dataSplittingChunk';
import { riskGroupColors, riskGroupNames } from '../video/constants';
import { RiskLabelsContainer } from '../components/BodyPartsRiskComparison';
import BodyPartRiskDistribution from '../components/BodyPartsRiskDistribution';
import sliceLongString from '../../lib/utils/sliceLongString';
import stripHtmlFromText from '../../lib/utils/stripHtmlFromText';

const TitleWithBadge = ({ title, risk }) => (
  <div className={titleWithBadge}>
    <span style={{ marginRight: '7px' }}>{title}</span>
    <RiskGroupBadge riskGroup={Object?.keys(Risks)[risk]?.toLowerCase()} />
  </div>
);

const ComparisonPdfContainer = ({
  currentComparison,
  firstRisksForBarChart,
  secondRisksForBarChart,
  improvements,
}) => {
  const { t } = useTranslation();
  const ComparisonAssessments = useSelector(
    (state) => state.soterTaskComparisons.assessmentsList,
  );
  const { firstTaskVideo, secondTaskVideo } = currentComparison;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAssessmentForVideo(firstTaskVideo?.id, secondTaskVideo?.id));
  }, [currentComparison]);
  const flattenAssessmentsData = [
    {
      type: 'videoTitle',
      title: firstTaskVideo?.title,
      header: `${t('SOTER_TASK.VIDEOS_LIST.VIDEO')} 1`,
    },
    ...ComparisonAssessments.firstVideoAssessments,
    {
      type: 'videoTitle',
      title: secondTaskVideo?.title,
      header: `${t('SOTER_TASK.VIDEOS_LIST.VIDEO')} 2`,
    },
    ...ComparisonAssessments.secondVideoAssessments,
  ];

  const assessmentsData = dataSplitting(flattenAssessmentsData, 6);
  const firstRisks = currentComparison?.firstTaskVideo?.risks;
  const firstRisk = firstRisks?.overallRisk;
  const secondRisks = currentComparison?.secondTaskVideo?.risks;
  const secondRisk = secondRisks?.overallRisk;
  const firstTitle = currentComparison?.firstTaskVideo?.title;
  const secondTitle = currentComparison?.secondTaskVideo?.title;
  return (
    <div
      id="soter_task_comparison_report"
      style={{
        display: 'none',
      }}
    >
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <div
          className={`${text(COLORS.GRAY[950], 36, 800)} ${Emotion.css`
            border-bottom: 1px solid ${COLORS.GRAY[100]};
            font-weight: bold;
          `}`}
        >
          SoterTask
        </div>
        <div className={header}>
          <div className={text(COLORS.GRAY[950], 18, 500)}>
            {currentComparison?.title}
          </div>
          <div className={text(COLORS.GRAY[400])}>
            {`${t('MY_COMPANY.USERS.CREATED_AT')}: `}
            <span className={text(COLORS.GRAY[950])}>
              {moment(currentComparison?.createdAt, 'X').format('MMM Do YYYY')}
            </span>
          </div>
          <div className={text(COLORS.GRAY[400])}>
            {`${t('MY_COMPANY.USERS.CREATED_BY')}: `}
            <span className={text(COLORS.GRAY[950])}>
              {currentComparison?.createdBy}
            </span>
          </div>
          <div className={text(COLORS.GRAY[400])}>
            {`${t('SOTER_TASK.COMPARISON.CHANGES')}: `}
            <span className={text(riskGroupColors[firstRisk], 15)}>
              {`${t(riskGroupNames[firstRisk])}`}
            </span>
            <img width="15px" src={arrowRight} alt=">" />
            <span className={text(riskGroupColors[secondRisk], 15)}>
              {`${t(riskGroupNames[secondRisk])}`}
            </span>
          </div>
        </div>
        <div>
          <div
            style={{ width: '465px', marginLeft: '95px', marginBottom: '30px' }}
          >
            <RiskLabelsContainer
              risksImprovements={improvements}
              firstRisks={firstRisks}
              secondRisks={secondRisks}
              hideNumbers
            />
          </div>
        </div>
      </SinglePage>
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={t('NAVBAR.OVERVIEW')} type="SoterTask" />
        <div
          className={border}
          style={{
            paddingTop: '15px',
            paddingBottom: '50px',
            marginBottom: '35px',
          }}
        >
          <TitleWithBadge title={firstTitle} risk={firstRisk} />
          <BodyPartRiskDistribution
            risks={firstRisksForBarChart}
            width={666}
            barWidth={500}
            barHeight={21}
            bigGap
          />
        </div>
        <TitleWithBadge title={secondTitle} risk={secondRisk} />
        <BodyPartRiskDistribution
          risks={secondRisksForBarChart}
          width={666}
          barWidth={500}
          barHeight={21}
          bigGap
        />
      </SinglePage>
      <AssessmentsReportList
        assessmentsData={[...assessmentsData]}
        title={t('SOTER_TASK.ASSESSMENTS')}
      />
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <SecondaryHeader title={t('GENERAL.DESCRIPTION')} />
        <div className={text(COLORS.GRAY[950], 12, 400)}>
          {sliceLongString(
            stripHtmlFromText(currentComparison?.description),
            6800,
          )}
        </div>
      </SinglePage>
    </div>
  );
};

export default ComparisonPdfContainer;
