import { createSelector } from 'reselect';

const rootSelector = (state) => state.dashboardUsers;

export const newUserDataSelector = createSelector(
  rootSelector,
  ({ newUserData }) => newUserData,
);

export const currentDashboardUserIdSelector = createSelector(
  rootSelector,
  ({ currentDashboardUserId }) => currentDashboardUserId,
);

export const currentDashboardUserIsOnlyItemSelector = createSelector(
  rootSelector,
  ({ currentDashboardUserIsOnlyItem }) => currentDashboardUserIsOnlyItem,
);

export const inviteLinkSelector = createSelector(
  rootSelector,
  ({ inviteLink }) => inviteLink,
);

export const isLoadingSelector = createSelector(
  rootSelector,
  ({ isLoading }) => isLoading,
);

export const dashboardUsersSelector = createSelector(
  rootSelector,
  ({ users }) => users.map((user) => {
    const creator = users.find(({ id }) => id === user.createdBy);

    return {
      ...user,
      createdByFirstName: creator?.firstName,
      createdByLastName: creator?.lastName,
    };
  }),
);
