import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  hubsList: [],
  error: null,
  isLoading: false,
});

export default function (state = initialState, action = {}) {
  const {
    error, hubsList, isLoading, type, hub, status, hubId,
  } = action;
  let newHubs;
  let newHub;
  switch (type) {
    case types.SET_IS_LOADING:
      return ({ ...state, isLoading });
    case types.SET_HUBS_ERROR:
      return ({ ...state, error });
    case types.SET_HUBS:
      return ({ ...state, hubsList });
    case types.SET_HUB:
      newHubs = state.hubsList.filter(({ id }) => id !== hub.id);
      return ({ ...state, hubsList: [...newHubs, hub] });
    case types.RESET_HUBS:
      return (initialState);
    case types.REMOVE_HUB:
      return ({ ...state, hubsList: state.hubsList.filter(({ id }) => id !== hubId) });
    case types.CHANGE_HUB_ACTIVITY_STATUS:
      newHubs = state.hubsList.filter(({ id }) => id !== hubId);
      newHub = state.hubsList.find(({ id }) => id === hubId);
      newHub.isActive = status;
      return ({ ...state, hubsList: [...newHubs, newHub] });
    default: return state;
  }
}
