const TYPE_SUCCESS = 'TYPE_SUCCESS';
const TYPE_WARNING = 'TYPE_WARNING';
const TYPE_FAILED = 'TYPE_FAILED';

export const notificationTypes = {
  TYPE_WARNING,
  TYPE_SUCCESS,
  TYPE_FAILED,
};

const successMessageMaker = (subject, predicate, prular) => [
  subject,
  prular ? 'NOTIFICATIONS.HAVE_BEEN' : 'NOTIFICATIONS.HAS_BEEN',
  predicate,
];
const failMessageMaker = (subject, predicate) => [
  'NOTIFICATIONS.FAILED_TO',
  predicate,
  subject,
  '.',
  'NOTIFICATIONS.YOU_CAN',
];

const DEPARTMENT_SUCCESS_MSG = successMessageMaker(
  'WEARABLES.OVERVIEW.DEPARTMENT',
  'NOTIFICATIONS.CHANGED',
);
const SITE_SUCCESS_MSG = successMessageMaker(
  'WEARABLES.OVERVIEW.SITE',
  'NOTIFICATIONS.CHANGED',
);
const JOB_TITLE_SUCCESS_MSG = successMessageMaker(
  'SOTER_TASK.VIDEOS_LIST.JOB_ROLE',
  'NOTIFICATIONS.CHANGED',
);
const PROGRAM_START_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.NEW_PROGRAM',
  'NOTIFICATIONS.STARTED',
);
const CONTINUOUS_WEARING_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.CONTINUOUS',
  'NOTIFICATIONS.STARTED',
);
const DEACTIVATE_USER_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.USER',
  'NOTIFICATIONS.DEACTIVATED',
);
const CHANGE_NAME_USER_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.NAMES',
  'NOTIFICATIONS.CHANGED',
);
const CHANGE_HELP_REQUEST_STATUS_SUCCESS_MSG = successMessageMaker(
  'WEARABLES.HELP.STATUS',
  'NOTIFICATIONS.CHANGED',
);
const CREATE_ADMIN_COMPANY_SUCCESS_MSG = successMessageMaker(
  'ADMIN.NEW_COMPANY',
  'NOTIFICATIONS.CREATED',
);
const UPDATE_ADMIN_COMPANY_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.COMPANY_DATA',
  'NOTIFICATIONS.UPDATED',
);
const DELETE_ADMIN_COMPANY_SUCCESS_MSG = successMessageMaker(
  'SIGN_UP.COMPANY.LABEL',
  'NOTIFICATIONS.DELETED',
);
const CREATE_JOB_PROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.JOB_PROFILE',
  'NOTIFICATIONS.CREATED',
);
const EDIT_JOB_PROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.JOB_PROFILE',
  'NOTIFICATIONS.EDITED',
);
const CREATE_JOB_PROFILE_TAG_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.JOB_PROFILE_TAG',
  'NOTIFICATIONS.CREATED',
);
const CREATE_TASK_PROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.TASK_PROFILE',
  'NOTIFICATIONS.CREATED',
);
const EDIT_TASK_PROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.TASK_PROFILE',
  'NOTIFICATIONS.EDITED',
);
const DELETE_JOB_PROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.JOB_PROFILE',
  'NOTIFICATIONS.DELETED',
);
const CREATE_DASHBOARD_USER_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.NEW_USER',
  'NOTIFICATIONS.CREATED',
);
const INVITE_DASHBOARD_USER_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.NEW_USER',
  'NOTIFICATIONS.INVITED',
);
const DELETE_DASHBOARD_USER_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.USER',
  'NOTIFICATIONS.DELETED',
);
const LINK_COPIED_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.LINK',
  'NOTIFICATIONS.COPIED_TO',
);
const SEND_INVITE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.INVITATION',
  'NOTIFICATIONS.SENT',
);
const RESET_PASSWORD_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.PASSWORD_REST',
  'NOTIFICATIONS.SENT',
);
const CHANGE_TASK_VIDEO_TITLE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.VIDEO_TITLE',
  'NOTIFICATIONS.CHANGED',
);
const APPROVE_TASK_VIDEO_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.VIDEO',
  'NOTIFICATIONS.APPROVED',
);
const DISAPPROVE_TASK_VIDEO_SUCCESS_MSG = ['NOTIFICATIONS.VIDEO_DISAPPROVED'];
const ARCHIVE_TASK_VIDEO_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.VIDEO',
  'NOTIFICATIONS.ARCHIVED',
);
const REMOVE_TASK_VIDEO_SUCCESS_MSG = ['NOTIFICATIONS.VIDEO_REMOVED'];
const UPLOAD_TASK_VIDEO_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.VIDEO',
  'NOTIFICATIONS.UPLOADED',
);
const REMOVE_DEPARTMENT_SUCCESS_MSG = successMessageMaker(
  'WEARABLES.OVERVIEW.DEPARTMENT',
  'NOTIFICATIONS.REMOVED',
);
const REMOVE_SITE_SUCCESS_MSG = successMessageMaker(
  'WEARABLES.OVERVIEW.SITE',
  'NOTIFICATIONS.REMOVED',
);
const EDIT_DEPARTMENT_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.DEPARTMENT_NAME',
  'NOTIFICATIONS.CHANGED',
);
const EDIT_SITE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.SITE_NAME',
  'NOTIFICATIONS.CHANGED',
);
const TASK_PROFILE_VIDEO_ATTACHED = successMessageMaker(
  'NOTIFICATIONS.VIDEO',
  'NOTIFICATIONS.ATTACHED',
);
const TASK_PROFILE_VIDEO_DETACHED_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.VIDEO',
  'NOTIFICATIONS.DETACHED',
);
const SOTER_TASK_TRIAL_STARTED_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.TRIAL',
  'NOTIFICATIONS.STARTED',
);
const SAVE_ASSESSMENT_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.ASSESSMENT',
  'NOTIFICATIONS.SAVED',
);
const REMOVE_ASSESSMENT_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.ASSESSMENT',
  'NOTIFICATIONS.REMOVED',
);
const CREATE_COMPARISON_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.COMPARISON',
  'NOTIFICATIONS.CREATED',
);
const UPDATE_COMPARISON_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.COMPARISON',
  'NOTIFICATIONS.UPDATED',
);
const REMOVE_COMPARISON_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.COMPARISON',
  'NOTIFICATIONS.REMOVED',
);
const DESCRIPTION_SUCCESS_MSG = successMessageMaker(
  'GENERAL.DESCRIPTION',
  'NOTIFICATIONS.CHANGED',
);
const EMAIL_CONFIRM_SUCCESS_MSG = successMessageMaker(
  'DASHBOARD_USER.EMAIL',
  'NOTIFICATIONS.CONFIRMED',
);
const EMAIL_CONFIRM_REQUEST_MSG = successMessageMaker(
  'DASHBOARD_USER.EMAIL',
  'NOTIFICATIONS.SENT',
);
const EDIT_TASK_VIDEO_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.VIDEO',
  'NOTIFICATIONS.EDITED',
);
const CREATE_HUB_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.HUB',
  'NOTIFICATIONS.CREATED',
);
const EDIT_HUB_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.HUB',
  'NOTIFICATIONS.EDITED',
);
const REMOVE_HUB_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.HUB',
  'NOTIFICATIONS.REMOVED',
);
const ACTIVATE_HUB_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.HUB',
  'NOTIFICATIONS.ACTIVATED',
);
const DEACTIVATE_HUB_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.HUB',
  'NOTIFICATIONS.DEACTIVATED',
);
const PRODUCT_TRIAL_REQUESTED_SUCCESS = successMessageMaker(
  'NOTIFICATIONS.YOUR_REQUEST',
  'NOTIFICATIONS.SENT',
);
const REMOVE_TASK_PROFILE_SUCCESS = successMessageMaker(
  'NOTIFICATIONS.TASK_PROFILE',
  'NOTIFICATIONS.REMOVED',
);
const CATEGORY_CREATED = successMessageMaker(
  'NOTIFICATIONS.CATEGORY',
  'NOTIFICATIONS.CREATED',
);
const ENTRY_CREATED = successMessageMaker(
  'NOTIFICATIONS.ENTRY',
  'NOTIFICATIONS.CREATED',
);
const SITE_CREATED = successMessageMaker(
  'WEARABLES.OVERVIEW.SITE',
  'NOTIFICATIONS.CREATED',
);
const ENTRY_ADDED = successMessageMaker(
  'NOTIFICATIONS.ENTRY',
  'NOTIFICATIONS.ADDED',
);
const CATEGORY_DELETED = successMessageMaker(
  'NOTIFICATIONS.CATEGORY',
  'NOTIFICATIONS.DELETED',
);
const ENTRY_DELETED = successMessageMaker(
  'NOTIFICATIONS.ENTRY',
  'NOTIFICATIONS.DELETED',
);
const ENTRY_UPDATE = successMessageMaker(
  'NOTIFICATIONS.ENTRY',
  'NOTIFICATIONS.UPDATED',
);
const SET_USER_NOTE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.NOTE',
  'NOTIFICATIONS.SET',
);
const SET_FEEDBACK_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.FEEDBACK',
  'NOTIFICATIONS.SENT',
);
const REMOVE_ENTRY_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.ENTRY',
  'NOTIFICATIONS.REMOVED',
);
const SAVE_REBAWEIGHT_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.WEIGHT',
  'NOTIFICATIONS.SAVED',
);
const CREATE_USERPROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.PROFILE_INO',
  'NOTIFICATIONS.CREATED',
);
const UPDATE_USERPROFILE_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.PROFILE_INO',
  'NOTIFICATIONS.UPDATED',
);
const SAVE_REBAWEIGHTS_SUCCESS_MSG = successMessageMaker(
  'NOTIFICATIONS.WEIGHTS',
  'NOTIFICATIONS.SAVED',
  'prular',
);

const CHANGE_NAME_USER_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.NAME_LOW',
  'NOTIFICATIONS.CHANGE',
);
const DEPARTMENT_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.DEPARTMENT_LOW',
  'NOTIFICATIONS.CHANGE',
);
const SITE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.SITE_LOW',
  'NOTIFICATIONS.CHANGE',
);
const JOB_TITLE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.JOB_ROLE',
  'NOTIFICATIONS.CHANGE',
);
const PROGRAM_START_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.NEW_PROGRAM_LOW',
  'NOTIFICATIONS.START',
);
const CONTINUOUS_WEARING_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.CONTINUOUS_LOW',
  'NOTIFICATIONS.START',
);
const DEACTIVATE_USER_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.USER_LOW',
  'NOTIFICATIONS.DEACTIVATE',
);
const CHANGE_HELP_REQUEST_STATUS_FAILED_MSG = successMessageMaker(
  'NOTIFICATIONS.STATUS',
  'NOTIFICATIONS.CHANGE',
);
const CREATE_ADMIN_COMPANY_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.NEW_COMPANY',
  'NOTIFICATIONS.CREATE',
);
const UPDATE_ADMIN_COMPANY_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.COMPANY_DATA_LOW',
  'NOTIFICATIONS.UPDATE',
);
const DELETE_ADMIN_COMPANY_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.COMPANY',
  'NOTIFICATIONS.DELETE',
);
const CREATE_JOB_PROFILE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.JOB_ROLE',
  'NOTIFICATIONS.CREATE',
);
const EDIT_JOB_PROFILE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.JOB_ROLE',
  'NOTIFICATIONS.EDIT',
);
const CREATE_JOB_PROFILE_TAG_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.JOB_ROLE_TAG',
  'NOTIFICATIONS.CREATE',
);
const CREATE_TASK_PROFILE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.TASK_ROLE',
  'NOTIFICATIONS.CREATE',
);
const EDIT_TASK_PROFILE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.TASK_ROLE',
  'NOTIFICATIONS.EDIT',
);
const DELETE_JOB_PROFILE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.JOB_ROLE',
  'NOTIFICATIONS.DELETE',
);
const DELETE_DASHBOARD_USER_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.USER_LOW',
  'NOTIFICATIONS.DELETE',
);
const CREATE_DASHBOARD_USER_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.USER_LOW',
  'NOTIFICATIONS.CREATE',
);
const CREATE_DASHBOARD_USER_FAILED_ALREADY_EXIST_MSG = [
  'NOTIFICATIONS.ALREADY_EXIST',
];
const GET_DEVICE_HISTORY_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.DEVICE_HISTORY',
  'NOTIFICATIONS.GET',
);
const EDIT_TASK_VIDEO_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.VIDEO_LOW',
  'NOTIFICATIONS.EDIT',
);
const CHANGE_TASK_VIDEO_STATUS_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.VIDEO_STATUS',
  'NOTIFICATIONS.CHANGE',
);
const REMOVE_TASK_VIDEO_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.VIDEO_LOW',
  'NOTIFICATIONS.REMOVE',
);
const UPLOAD_TASK_VIDEO_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.VIDEO_LOW',
  'NOTIFICATIONS.UPLOAD',
);
const GENERATE_REPORT_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.REPORT',
  'NOTIFICATIONS.GENERATE',
);
const SAVE_ASSESSMENT_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.ASSESSMENT_LOW',
  'NOTIFICATIONS.SAVE',
);
const SERVER_ERROR_MSG = ['NOTIFICATIONS.GOING_WRONG'];
const GENERIC_ERROR = ['NOTIFICATIONS.WENT_WRONG'];
const CATEGORY_DUPLICATE = ['MY_COMPANY.CATEGORIES.CATEGORY_DUPLICATE'];
const REMOVE_ASSESSMENT_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.ASSESSMENT_LOW',
  'NOTIFICATIONS.REMOVE',
);
const CREATE_COMPARISON_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.COMPARISON_LOW',
  'NOTIFICATIONS.CREATE',
);
const UPDATE_COMPARISON_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.COMPARISON_LOW',
  'NOTIFICATIONS.UPDATE',
);
const REMOVE_COMPARISON_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.COMPARISON_LOW',
  'NOTIFICATIONS.REMOVE',
);
const PRODUCT_TRIAL_REQUESTED_ERROR = ['NOTIFICATIONS.SOMETHING'];
const UPLOAD_EMPTY_CSV_ERROR = ['NOTIFICATIONS.CANT'];
const EMAIL_CONFIRM_REQUEST_ERROR_MSG = failMessageMaker(
  'NOTIFICATIONS.EMAIL_LOW',
  'NOTIFICATIONS.SEND',
);
const SET_USER_NOTE_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.NOTE_LOW',
  'NOTIFICATIONS.SET',
);
const SET_FEEDBACK_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.FEEDBACK_LOW',
  'NOTIFICATIONS.SET',
);
const REMOVE_ENTRY_FAILED_MSG = failMessageMaker(
  'NOTIFICATIONS.ENTRY_LOW',
  'NOTIFICATIONS.REMOVE',
);

const USER_DATA_UPDATED = ['NOTIFICATIONS.USER_DATA_UPDATED'];
const USER_LOGIN_UPDATED = ['NOTIFICATIONS.USER_LOGIN_UPDATED'];

export const notificationMessages = {
  USER_DATA_UPDATED,
  USER_LOGIN_UPDATED,
  CATEGORY_CREATED,
  DEACTIVATE_USER_FAILED_MSG,
  DEACTIVATE_USER_SUCCESS_MSG,
  CATEGORY_DELETED,
  REMOVE_ENTRY_FAILED_MSG,
  REMOVE_ENTRY_SUCCESS_MSG,
  DEPARTMENT_SUCCESS_MSG,
  DEPARTMENT_FAILED_MSG,
  JOB_TITLE_SUCCESS_MSG,
  JOB_TITLE_FAILED_MSG,
  PROGRAM_START_SUCCESS_MSG,
  PROGRAM_START_FAILED_MSG,
  CONTINUOUS_WEARING_FAILED_MSG,
  CONTINUOUS_WEARING_SUCCESS_MSG,
  CHANGE_NAME_USER_FAILED_MSG,
  CHANGE_NAME_USER_SUCCESS_MSG,
  CHANGE_HELP_REQUEST_STATUS_SUCCESS_MSG,
  CHANGE_HELP_REQUEST_STATUS_FAILED_MSG,
  CREATE_ADMIN_COMPANY_SUCCESS_MSG,
  CREATE_ADMIN_COMPANY_FAILED_MSG,
  UPDATE_ADMIN_COMPANY_SUCCESS_MSG,
  UPDATE_ADMIN_COMPANY_FAILED_MSG,
  DELETE_ADMIN_COMPANY_SUCCESS_MSG,
  DELETE_ADMIN_COMPANY_FAILED_MSG,
  CREATE_JOB_PROFILE_SUCCESS_MSG,
  CREATE_JOB_PROFILE_FAILED_MSG,
  EDIT_JOB_PROFILE_SUCCESS_MSG,
  EDIT_JOB_PROFILE_FAILED_MSG,
  CREATE_JOB_PROFILE_TAG_FAILED_MSG,
  CREATE_JOB_PROFILE_TAG_SUCCESS_MSG,
  CREATE_TASK_PROFILE_FAILED_MSG,
  CREATE_TASK_PROFILE_SUCCESS_MSG,
  EDIT_TASK_PROFILE_FAILED_MSG,
  EDIT_TASK_PROFILE_SUCCESS_MSG,
  DELETE_JOB_PROFILE_FAILED_MSG,
  DELETE_JOB_PROFILE_SUCCESS_MSG,
  CREATE_DASHBOARD_USER_SUCCESS_MSG,
  INVITE_DASHBOARD_USER_SUCCESS_MSG,
  DELETE_DASHBOARD_USER_FAILED_MSG,
  DELETE_DASHBOARD_USER_SUCCESS_MSG,
  CREATE_DASHBOARD_USER_FAILED_MSG,
  LINK_COPIED_SUCCESS_MSG,
  SEND_INVITE_SUCCESS_MSG,
  RESET_PASSWORD_SUCCESS_MSG,
  EDIT_TASK_VIDEO_FAILED_MSG,
  CHANGE_TASK_VIDEO_TITLE_SUCCESS_MSG,
  APPROVE_TASK_VIDEO_SUCCESS_MSG,
  DISAPPROVE_TASK_VIDEO_SUCCESS_MSG,
  ARCHIVE_TASK_VIDEO_SUCCESS_MSG,
  REMOVE_TASK_VIDEO_FAILED_MSG,
  REMOVE_TASK_VIDEO_SUCCESS_MSG,
  UPLOAD_TASK_VIDEO_FAILED_MSG,
  UPLOAD_TASK_VIDEO_SUCCESS_MSG,
  REMOVE_DEPARTMENT_SUCCESS_MSG,
  EDIT_DEPARTMENT_SUCCESS_MSG,
  GET_DEVICE_HISTORY_FAILED_MSG,
  GENERATE_REPORT_FAILED_MSG,
  SERVER_ERROR_MSG,
  GENERIC_ERROR,
  TASK_PROFILE_VIDEO_ATTACHED,
  SOTER_TASK_TRIAL_STARTED_SUCCESS_MSG,
  TASK_PROFILE_VIDEO_DETACHED_SUCCESS_MSG,
  CHANGE_TASK_VIDEO_STATUS_FAILED_MSG,
  SAVE_ASSESSMENT_FAILED_MSG,
  SAVE_ASSESSMENT_SUCCESS_MSG,
  REMOVE_ASSESSMENT_FAILED_MSG,
  REMOVE_ASSESSMENT_SUCCESS_MSG,
  CREATE_COMPARISON_FAILED_MSG,
  CREATE_COMPARISON_SUCCESS_MSG,
  UPDATE_COMPARISON_FAILED_MSG,
  UPDATE_COMPARISON_SUCCESS_MSG,
  REMOVE_COMPARISON_FAILED_MSG,
  REMOVE_COMPARISON_SUCCESS_MSG,
  PRODUCT_TRIAL_REQUESTED_SUCCESS,
  PRODUCT_TRIAL_REQUESTED_ERROR,
  DESCRIPTION_SUCCESS_MSG,
  EDIT_TASK_VIDEO_SUCCESS_MSG,
  EMAIL_CONFIRM_SUCCESS_MSG,
  EMAIL_CONFIRM_REQUEST_MSG,
  EMAIL_CONFIRM_REQUEST_ERROR_MSG,
  SITE_FAILED_MSG,
  SITE_SUCCESS_MSG,
  EDIT_SITE_SUCCESS_MSG,
  REMOVE_SITE_SUCCESS_MSG,
  UPLOAD_EMPTY_CSV_ERROR,
  CREATE_HUB_SUCCESS_MSG,
  EDIT_HUB_SUCCESS_MSG,
  REMOVE_HUB_SUCCESS_MSG,
  ACTIVATE_HUB_SUCCESS_MSG,
  DEACTIVATE_HUB_SUCCESS_MSG,
  REMOVE_TASK_PROFILE_SUCCESS,
  SET_USER_NOTE_FAILED_MSG,
  SET_USER_NOTE_SUCCESS_MSG,
  SET_FEEDBACK_FAILED_MSG,
  SET_FEEDBACK_SUCCESS_MSG,
  ENTRY_CREATED,
  ENTRY_DELETED,
  ENTRY_ADDED,
  ENTRY_UPDATE,
  SITE_CREATED,
  CREATE_DASHBOARD_USER_FAILED_ALREADY_EXIST_MSG,
  SAVE_REBAWEIGHT_SUCCESS_MSG,
  SAVE_REBAWEIGHTS_SUCCESS_MSG,
  CREATE_USERPROFILE_SUCCESS_MSG,
  UPDATE_USERPROFILE_SUCCESS_MSG,
  CATEGORY_DUPLICATE,
};
