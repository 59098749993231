// source: api/dashboard/v2/company_product_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.company_product_message.CompanyProduct', null, global);
goog.exportSymbol('proto.company_product_message.ContactSalesInfo', null, global);
goog.exportSymbol('proto.company_product_message.DataSandpitProduct', null, global);
goog.exportSymbol('proto.company_product_message.EHSCopilotProduct', null, global);
goog.exportSymbol('proto.company_product_message.GeniusLimitProduct', null, global);
goog.exportSymbol('proto.company_product_message.GeniusProduct', null, global);
goog.exportSymbol('proto.company_product_message.JobDictionaryProduct', null, global);
goog.exportSymbol('proto.company_product_message.ProductStatus', null, global);
goog.exportSymbol('proto.company_product_message.TaskLimitProduct', null, global);
goog.exportSymbol('proto.company_product_message.TaskProduct', null, global);
goog.exportSymbol('proto.company_product_message.WearableClipgoProductStatus', null, global);
goog.exportSymbol('proto.company_product_message.WearableCoachProduct', null, global);
goog.exportSymbol('proto.company_product_message.WearableProduct', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.CompanyProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.CompanyProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.CompanyProduct.displayName = 'proto.company_product_message.CompanyProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.TaskProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.TaskProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.TaskProduct.displayName = 'proto.company_product_message.TaskProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.TaskLimitProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.TaskLimitProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.TaskLimitProduct.displayName = 'proto.company_product_message.TaskLimitProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.ContactSalesInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.ContactSalesInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.ContactSalesInfo.displayName = 'proto.company_product_message.ContactSalesInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.DataSandpitProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.DataSandpitProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.DataSandpitProduct.displayName = 'proto.company_product_message.DataSandpitProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.WearableProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.WearableProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.WearableProduct.displayName = 'proto.company_product_message.WearableProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.JobDictionaryProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.JobDictionaryProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.JobDictionaryProduct.displayName = 'proto.company_product_message.JobDictionaryProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.EHSCopilotProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.EHSCopilotProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.EHSCopilotProduct.displayName = 'proto.company_product_message.EHSCopilotProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.GeniusProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.GeniusProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.GeniusProduct.displayName = 'proto.company_product_message.GeniusProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.GeniusLimitProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.GeniusLimitProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.GeniusLimitProduct.displayName = 'proto.company_product_message.GeniusLimitProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.WearableCoachProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.WearableCoachProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.WearableCoachProduct.displayName = 'proto.company_product_message.WearableCoachProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company_product_message.WearableClipgoProductStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company_product_message.WearableClipgoProductStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company_product_message.WearableClipgoProductStatus.displayName = 'proto.company_product_message.WearableClipgoProductStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.CompanyProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.CompanyProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.CompanyProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.CompanyProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskProduct: (f = msg.getTaskProduct()) && proto.company_product_message.TaskProduct.toObject(includeInstance, f),
    jobDictionaryProduct: (f = msg.getJobDictionaryProduct()) && proto.company_product_message.JobDictionaryProduct.toObject(includeInstance, f),
    wearableProduct: (f = msg.getWearableProduct()) && proto.company_product_message.WearableProduct.toObject(includeInstance, f),
    dataSandpitProduct: (f = msg.getDataSandpitProduct()) && proto.company_product_message.DataSandpitProduct.toObject(includeInstance, f),
    ehsCopilotProduct: (f = msg.getEhsCopilotProduct()) && proto.company_product_message.EHSCopilotProduct.toObject(includeInstance, f),
    geniusProduct: (f = msg.getGeniusProduct()) && proto.company_product_message.GeniusProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.CompanyProduct}
 */
proto.company_product_message.CompanyProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.CompanyProduct;
  return proto.company_product_message.CompanyProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.CompanyProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.CompanyProduct}
 */
proto.company_product_message.CompanyProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.company_product_message.TaskProduct;
      reader.readMessage(value,proto.company_product_message.TaskProduct.deserializeBinaryFromReader);
      msg.setTaskProduct(value);
      break;
    case 2:
      var value = new proto.company_product_message.JobDictionaryProduct;
      reader.readMessage(value,proto.company_product_message.JobDictionaryProduct.deserializeBinaryFromReader);
      msg.setJobDictionaryProduct(value);
      break;
    case 3:
      var value = new proto.company_product_message.WearableProduct;
      reader.readMessage(value,proto.company_product_message.WearableProduct.deserializeBinaryFromReader);
      msg.setWearableProduct(value);
      break;
    case 4:
      var value = new proto.company_product_message.DataSandpitProduct;
      reader.readMessage(value,proto.company_product_message.DataSandpitProduct.deserializeBinaryFromReader);
      msg.setDataSandpitProduct(value);
      break;
    case 5:
      var value = new proto.company_product_message.EHSCopilotProduct;
      reader.readMessage(value,proto.company_product_message.EHSCopilotProduct.deserializeBinaryFromReader);
      msg.setEhsCopilotProduct(value);
      break;
    case 6:
      var value = new proto.company_product_message.GeniusProduct;
      reader.readMessage(value,proto.company_product_message.GeniusProduct.deserializeBinaryFromReader);
      msg.setGeniusProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.CompanyProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.CompanyProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.CompanyProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.CompanyProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.company_product_message.TaskProduct.serializeBinaryToWriter
    );
  }
  f = message.getJobDictionaryProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.company_product_message.JobDictionaryProduct.serializeBinaryToWriter
    );
  }
  f = message.getWearableProduct();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.company_product_message.WearableProduct.serializeBinaryToWriter
    );
  }
  f = message.getDataSandpitProduct();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.company_product_message.DataSandpitProduct.serializeBinaryToWriter
    );
  }
  f = message.getEhsCopilotProduct();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.company_product_message.EHSCopilotProduct.serializeBinaryToWriter
    );
  }
  f = message.getGeniusProduct();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.company_product_message.GeniusProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskProduct task_product = 1;
 * @return {?proto.company_product_message.TaskProduct}
 */
proto.company_product_message.CompanyProduct.prototype.getTaskProduct = function() {
  return /** @type{?proto.company_product_message.TaskProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.TaskProduct, 1));
};


/**
 * @param {?proto.company_product_message.TaskProduct|undefined} value
 * @return {!proto.company_product_message.CompanyProduct} returns this
*/
proto.company_product_message.CompanyProduct.prototype.setTaskProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.CompanyProduct} returns this
 */
proto.company_product_message.CompanyProduct.prototype.clearTaskProduct = function() {
  return this.setTaskProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.CompanyProduct.prototype.hasTaskProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JobDictionaryProduct job_dictionary_product = 2;
 * @return {?proto.company_product_message.JobDictionaryProduct}
 */
proto.company_product_message.CompanyProduct.prototype.getJobDictionaryProduct = function() {
  return /** @type{?proto.company_product_message.JobDictionaryProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.JobDictionaryProduct, 2));
};


/**
 * @param {?proto.company_product_message.JobDictionaryProduct|undefined} value
 * @return {!proto.company_product_message.CompanyProduct} returns this
*/
proto.company_product_message.CompanyProduct.prototype.setJobDictionaryProduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.CompanyProduct} returns this
 */
proto.company_product_message.CompanyProduct.prototype.clearJobDictionaryProduct = function() {
  return this.setJobDictionaryProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.CompanyProduct.prototype.hasJobDictionaryProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WearableProduct wearable_product = 3;
 * @return {?proto.company_product_message.WearableProduct}
 */
proto.company_product_message.CompanyProduct.prototype.getWearableProduct = function() {
  return /** @type{?proto.company_product_message.WearableProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.WearableProduct, 3));
};


/**
 * @param {?proto.company_product_message.WearableProduct|undefined} value
 * @return {!proto.company_product_message.CompanyProduct} returns this
*/
proto.company_product_message.CompanyProduct.prototype.setWearableProduct = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.CompanyProduct} returns this
 */
proto.company_product_message.CompanyProduct.prototype.clearWearableProduct = function() {
  return this.setWearableProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.CompanyProduct.prototype.hasWearableProduct = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DataSandpitProduct data_sandpit_product = 4;
 * @return {?proto.company_product_message.DataSandpitProduct}
 */
proto.company_product_message.CompanyProduct.prototype.getDataSandpitProduct = function() {
  return /** @type{?proto.company_product_message.DataSandpitProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.DataSandpitProduct, 4));
};


/**
 * @param {?proto.company_product_message.DataSandpitProduct|undefined} value
 * @return {!proto.company_product_message.CompanyProduct} returns this
*/
proto.company_product_message.CompanyProduct.prototype.setDataSandpitProduct = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.CompanyProduct} returns this
 */
proto.company_product_message.CompanyProduct.prototype.clearDataSandpitProduct = function() {
  return this.setDataSandpitProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.CompanyProduct.prototype.hasDataSandpitProduct = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EHSCopilotProduct ehs_copilot_product = 5;
 * @return {?proto.company_product_message.EHSCopilotProduct}
 */
proto.company_product_message.CompanyProduct.prototype.getEhsCopilotProduct = function() {
  return /** @type{?proto.company_product_message.EHSCopilotProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.EHSCopilotProduct, 5));
};


/**
 * @param {?proto.company_product_message.EHSCopilotProduct|undefined} value
 * @return {!proto.company_product_message.CompanyProduct} returns this
*/
proto.company_product_message.CompanyProduct.prototype.setEhsCopilotProduct = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.CompanyProduct} returns this
 */
proto.company_product_message.CompanyProduct.prototype.clearEhsCopilotProduct = function() {
  return this.setEhsCopilotProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.CompanyProduct.prototype.hasEhsCopilotProduct = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GeniusProduct genius_product = 6;
 * @return {?proto.company_product_message.GeniusProduct}
 */
proto.company_product_message.CompanyProduct.prototype.getGeniusProduct = function() {
  return /** @type{?proto.company_product_message.GeniusProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.GeniusProduct, 6));
};


/**
 * @param {?proto.company_product_message.GeniusProduct|undefined} value
 * @return {!proto.company_product_message.CompanyProduct} returns this
*/
proto.company_product_message.CompanyProduct.prototype.setGeniusProduct = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.CompanyProduct} returns this
 */
proto.company_product_message.CompanyProduct.prototype.clearGeniusProduct = function() {
  return this.setGeniusProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.CompanyProduct.prototype.hasGeniusProduct = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.TaskProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.TaskProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.TaskProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.TaskProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentTermStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentTermEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limits: (f = msg.getLimits()) && proto.company_product_message.TaskLimitProduct.toObject(includeInstance, f),
    disableVideoDownload: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isSubscriptionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.TaskProduct}
 */
proto.company_product_message.TaskProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.TaskProduct;
  return proto.company_product_message.TaskProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.TaskProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.TaskProduct}
 */
proto.company_product_message.TaskProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermEnd(value);
      break;
    case 4:
      var value = new proto.company_product_message.TaskLimitProduct;
      reader.readMessage(value,proto.company_product_message.TaskLimitProduct.deserializeBinaryFromReader);
      msg.setLimits(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableVideoDownload(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionRequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.TaskProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.TaskProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.TaskProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.TaskProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentTermStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLimits();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.company_product_message.TaskLimitProduct.serializeBinaryToWriter
    );
  }
  f = message.getDisableVideoDownload();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsSubscriptionRequested();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.TaskProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.TaskProduct} returns this
 */
proto.company_product_message.TaskProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 current_term_start = 2;
 * @return {number}
 */
proto.company_product_message.TaskProduct.prototype.getCurrentTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.TaskProduct} returns this
 */
proto.company_product_message.TaskProduct.prototype.setCurrentTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 current_term_end = 3;
 * @return {number}
 */
proto.company_product_message.TaskProduct.prototype.getCurrentTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.TaskProduct} returns this
 */
proto.company_product_message.TaskProduct.prototype.setCurrentTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TaskLimitProduct limits = 4;
 * @return {?proto.company_product_message.TaskLimitProduct}
 */
proto.company_product_message.TaskProduct.prototype.getLimits = function() {
  return /** @type{?proto.company_product_message.TaskLimitProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.TaskLimitProduct, 4));
};


/**
 * @param {?proto.company_product_message.TaskLimitProduct|undefined} value
 * @return {!proto.company_product_message.TaskProduct} returns this
*/
proto.company_product_message.TaskProduct.prototype.setLimits = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.TaskProduct} returns this
 */
proto.company_product_message.TaskProduct.prototype.clearLimits = function() {
  return this.setLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.TaskProduct.prototype.hasLimits = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool disable_video_download = 5;
 * @return {boolean}
 */
proto.company_product_message.TaskProduct.prototype.getDisableVideoDownload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.TaskProduct} returns this
 */
proto.company_product_message.TaskProduct.prototype.setDisableVideoDownload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_subscription_requested = 6;
 * @return {boolean}
 */
proto.company_product_message.TaskProduct.prototype.getIsSubscriptionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.TaskProduct} returns this
 */
proto.company_product_message.TaskProduct.prototype.setIsSubscriptionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.TaskLimitProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.TaskLimitProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.TaskLimitProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.TaskLimitProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoFileSizeMbLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    videoRecordingTimeSecondsLimit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    videoPerBillingCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    videoPerBillingLeft: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.TaskLimitProduct}
 */
proto.company_product_message.TaskLimitProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.TaskLimitProduct;
  return proto.company_product_message.TaskLimitProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.TaskLimitProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.TaskLimitProduct}
 */
proto.company_product_message.TaskLimitProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoFileSizeMbLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoRecordingTimeSecondsLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoPerBillingCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoPerBillingLeft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.TaskLimitProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.TaskLimitProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.TaskLimitProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.TaskLimitProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoFileSizeMbLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVideoRecordingTimeSecondsLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getVideoPerBillingCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getVideoPerBillingLeft();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 video_file_size_mb_limit = 1;
 * @return {number}
 */
proto.company_product_message.TaskLimitProduct.prototype.getVideoFileSizeMbLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.TaskLimitProduct} returns this
 */
proto.company_product_message.TaskLimitProduct.prototype.setVideoFileSizeMbLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 video_recording_time_seconds_limit = 2;
 * @return {number}
 */
proto.company_product_message.TaskLimitProduct.prototype.getVideoRecordingTimeSecondsLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.TaskLimitProduct} returns this
 */
proto.company_product_message.TaskLimitProduct.prototype.setVideoRecordingTimeSecondsLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 video_per_billing_count = 3;
 * @return {number}
 */
proto.company_product_message.TaskLimitProduct.prototype.getVideoPerBillingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.TaskLimitProduct} returns this
 */
proto.company_product_message.TaskLimitProduct.prototype.setVideoPerBillingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 video_per_billing_left = 4;
 * @return {number}
 */
proto.company_product_message.TaskLimitProduct.prototype.getVideoPerBillingLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.TaskLimitProduct} returns this
 */
proto.company_product_message.TaskLimitProduct.prototype.setVideoPerBillingLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.ContactSalesInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.ContactSalesInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.ContactSalesInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.ContactSalesInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSubscriptionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.ContactSalesInfo}
 */
proto.company_product_message.ContactSalesInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.ContactSalesInfo;
  return proto.company_product_message.ContactSalesInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.ContactSalesInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.ContactSalesInfo}
 */
proto.company_product_message.ContactSalesInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionRequested(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDashboardUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.ContactSalesInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.ContactSalesInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.ContactSalesInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.ContactSalesInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSubscriptionRequested();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool is_subscription_requested = 1;
 * @return {boolean}
 */
proto.company_product_message.ContactSalesInfo.prototype.getIsSubscriptionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.ContactSalesInfo} returns this
 */
proto.company_product_message.ContactSalesInfo.prototype.setIsSubscriptionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 dashboard_user_id = 2;
 * @return {number}
 */
proto.company_product_message.ContactSalesInfo.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.ContactSalesInfo} returns this
 */
proto.company_product_message.ContactSalesInfo.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 create_at = 3;
 * @return {number}
 */
proto.company_product_message.ContactSalesInfo.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.ContactSalesInfo} returns this
 */
proto.company_product_message.ContactSalesInfo.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.DataSandpitProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.DataSandpitProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.DataSandpitProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.DataSandpitProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.DataSandpitProduct}
 */
proto.company_product_message.DataSandpitProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.DataSandpitProduct;
  return proto.company_product_message.DataSandpitProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.DataSandpitProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.DataSandpitProduct}
 */
proto.company_product_message.DataSandpitProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.DataSandpitProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.DataSandpitProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.DataSandpitProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.DataSandpitProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.DataSandpitProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.DataSandpitProduct} returns this
 */
proto.company_product_message.DataSandpitProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.WearableProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.WearableProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.WearableProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.WearableProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentTermStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentTermEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSubscriptionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.WearableProduct}
 */
proto.company_product_message.WearableProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.WearableProduct;
  return proto.company_product_message.WearableProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.WearableProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.WearableProduct}
 */
proto.company_product_message.WearableProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermEnd(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionRequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.WearableProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.WearableProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.WearableProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.WearableProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentTermStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsSubscriptionRequested();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.WearableProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.WearableProduct} returns this
 */
proto.company_product_message.WearableProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 current_term_start = 2;
 * @return {number}
 */
proto.company_product_message.WearableProduct.prototype.getCurrentTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.WearableProduct} returns this
 */
proto.company_product_message.WearableProduct.prototype.setCurrentTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 current_term_end = 3;
 * @return {number}
 */
proto.company_product_message.WearableProduct.prototype.getCurrentTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.WearableProduct} returns this
 */
proto.company_product_message.WearableProduct.prototype.setCurrentTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_subscription_requested = 4;
 * @return {boolean}
 */
proto.company_product_message.WearableProduct.prototype.getIsSubscriptionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.WearableProduct} returns this
 */
proto.company_product_message.WearableProduct.prototype.setIsSubscriptionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.JobDictionaryProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.JobDictionaryProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.JobDictionaryProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.JobDictionaryProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentTermStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentTermEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSubscriptionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.JobDictionaryProduct}
 */
proto.company_product_message.JobDictionaryProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.JobDictionaryProduct;
  return proto.company_product_message.JobDictionaryProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.JobDictionaryProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.JobDictionaryProduct}
 */
proto.company_product_message.JobDictionaryProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermEnd(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionRequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.JobDictionaryProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.JobDictionaryProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.JobDictionaryProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.JobDictionaryProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentTermStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsSubscriptionRequested();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.JobDictionaryProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.JobDictionaryProduct} returns this
 */
proto.company_product_message.JobDictionaryProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 current_term_start = 2;
 * @return {number}
 */
proto.company_product_message.JobDictionaryProduct.prototype.getCurrentTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.JobDictionaryProduct} returns this
 */
proto.company_product_message.JobDictionaryProduct.prototype.setCurrentTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 current_term_end = 3;
 * @return {number}
 */
proto.company_product_message.JobDictionaryProduct.prototype.getCurrentTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.JobDictionaryProduct} returns this
 */
proto.company_product_message.JobDictionaryProduct.prototype.setCurrentTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_subscription_requested = 4;
 * @return {boolean}
 */
proto.company_product_message.JobDictionaryProduct.prototype.getIsSubscriptionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.JobDictionaryProduct} returns this
 */
proto.company_product_message.JobDictionaryProduct.prototype.setIsSubscriptionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.EHSCopilotProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.EHSCopilotProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.EHSCopilotProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.EHSCopilotProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentTermStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentTermEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSubscriptionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.EHSCopilotProduct}
 */
proto.company_product_message.EHSCopilotProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.EHSCopilotProduct;
  return proto.company_product_message.EHSCopilotProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.EHSCopilotProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.EHSCopilotProduct}
 */
proto.company_product_message.EHSCopilotProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermEnd(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionRequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.EHSCopilotProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.EHSCopilotProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.EHSCopilotProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.EHSCopilotProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentTermStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsSubscriptionRequested();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.EHSCopilotProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.EHSCopilotProduct} returns this
 */
proto.company_product_message.EHSCopilotProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 current_term_start = 2;
 * @return {number}
 */
proto.company_product_message.EHSCopilotProduct.prototype.getCurrentTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.EHSCopilotProduct} returns this
 */
proto.company_product_message.EHSCopilotProduct.prototype.setCurrentTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 current_term_end = 3;
 * @return {number}
 */
proto.company_product_message.EHSCopilotProduct.prototype.getCurrentTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.EHSCopilotProduct} returns this
 */
proto.company_product_message.EHSCopilotProduct.prototype.setCurrentTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_subscription_requested = 4;
 * @return {boolean}
 */
proto.company_product_message.EHSCopilotProduct.prototype.getIsSubscriptionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.EHSCopilotProduct} returns this
 */
proto.company_product_message.EHSCopilotProduct.prototype.setIsSubscriptionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.GeniusProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.GeniusProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.GeniusProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.GeniusProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentTermStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentTermEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limits: (f = msg.getLimits()) && proto.company_product_message.GeniusLimitProduct.toObject(includeInstance, f),
    disableVideoDownload: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isSubscriptionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.GeniusProduct}
 */
proto.company_product_message.GeniusProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.GeniusProduct;
  return proto.company_product_message.GeniusProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.GeniusProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.GeniusProduct}
 */
proto.company_product_message.GeniusProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermEnd(value);
      break;
    case 4:
      var value = new proto.company_product_message.GeniusLimitProduct;
      reader.readMessage(value,proto.company_product_message.GeniusLimitProduct.deserializeBinaryFromReader);
      msg.setLimits(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableVideoDownload(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscriptionRequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.GeniusProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.GeniusProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.GeniusProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.GeniusProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentTermStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLimits();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.company_product_message.GeniusLimitProduct.serializeBinaryToWriter
    );
  }
  f = message.getDisableVideoDownload();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsSubscriptionRequested();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.GeniusProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.GeniusProduct} returns this
 */
proto.company_product_message.GeniusProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 current_term_start = 2;
 * @return {number}
 */
proto.company_product_message.GeniusProduct.prototype.getCurrentTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.GeniusProduct} returns this
 */
proto.company_product_message.GeniusProduct.prototype.setCurrentTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 current_term_end = 3;
 * @return {number}
 */
proto.company_product_message.GeniusProduct.prototype.getCurrentTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.GeniusProduct} returns this
 */
proto.company_product_message.GeniusProduct.prototype.setCurrentTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional GeniusLimitProduct limits = 4;
 * @return {?proto.company_product_message.GeniusLimitProduct}
 */
proto.company_product_message.GeniusProduct.prototype.getLimits = function() {
  return /** @type{?proto.company_product_message.GeniusLimitProduct} */ (
    jspb.Message.getWrapperField(this, proto.company_product_message.GeniusLimitProduct, 4));
};


/**
 * @param {?proto.company_product_message.GeniusLimitProduct|undefined} value
 * @return {!proto.company_product_message.GeniusProduct} returns this
*/
proto.company_product_message.GeniusProduct.prototype.setLimits = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company_product_message.GeniusProduct} returns this
 */
proto.company_product_message.GeniusProduct.prototype.clearLimits = function() {
  return this.setLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company_product_message.GeniusProduct.prototype.hasLimits = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool disable_video_download = 5;
 * @return {boolean}
 */
proto.company_product_message.GeniusProduct.prototype.getDisableVideoDownload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.GeniusProduct} returns this
 */
proto.company_product_message.GeniusProduct.prototype.setDisableVideoDownload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_subscription_requested = 6;
 * @return {boolean}
 */
proto.company_product_message.GeniusProduct.prototype.getIsSubscriptionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company_product_message.GeniusProduct} returns this
 */
proto.company_product_message.GeniusProduct.prototype.setIsSubscriptionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.GeniusLimitProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.GeniusLimitProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.GeniusLimitProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.GeniusLimitProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoFileSizeMbLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    videoRecordingTimeSecondsLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.GeniusLimitProduct}
 */
proto.company_product_message.GeniusLimitProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.GeniusLimitProduct;
  return proto.company_product_message.GeniusLimitProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.GeniusLimitProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.GeniusLimitProduct}
 */
proto.company_product_message.GeniusLimitProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoFileSizeMbLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoRecordingTimeSecondsLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.GeniusLimitProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.GeniusLimitProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.GeniusLimitProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.GeniusLimitProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoFileSizeMbLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVideoRecordingTimeSecondsLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 video_file_size_mb_limit = 1;
 * @return {number}
 */
proto.company_product_message.GeniusLimitProduct.prototype.getVideoFileSizeMbLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.GeniusLimitProduct} returns this
 */
proto.company_product_message.GeniusLimitProduct.prototype.setVideoFileSizeMbLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 video_recording_time_seconds_limit = 2;
 * @return {number}
 */
proto.company_product_message.GeniusLimitProduct.prototype.getVideoRecordingTimeSecondsLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company_product_message.GeniusLimitProduct} returns this
 */
proto.company_product_message.GeniusLimitProduct.prototype.setVideoRecordingTimeSecondsLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.WearableCoachProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.WearableCoachProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.WearableCoachProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.WearableCoachProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.WearableCoachProduct}
 */
proto.company_product_message.WearableCoachProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.WearableCoachProduct;
  return proto.company_product_message.WearableCoachProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.WearableCoachProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.WearableCoachProduct}
 */
proto.company_product_message.WearableCoachProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.WearableCoachProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.WearableCoachProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.WearableCoachProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.WearableCoachProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.WearableCoachProduct.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.WearableCoachProduct} returns this
 */
proto.company_product_message.WearableCoachProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company_product_message.WearableClipgoProductStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.company_product_message.WearableClipgoProductStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company_product_message.WearableClipgoProductStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.WearableClipgoProductStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gamificationStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company_product_message.WearableClipgoProductStatus}
 */
proto.company_product_message.WearableClipgoProductStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company_product_message.WearableClipgoProductStatus;
  return proto.company_product_message.WearableClipgoProductStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company_product_message.WearableClipgoProductStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company_product_message.WearableClipgoProductStatus}
 */
proto.company_product_message.WearableClipgoProductStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {!proto.company_product_message.ProductStatus} */ (reader.readEnum());
      msg.setGamificationStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company_product_message.WearableClipgoProductStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company_product_message.WearableClipgoProductStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company_product_message.WearableClipgoProductStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company_product_message.WearableClipgoProductStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGamificationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.WearableClipgoProductStatus.prototype.getStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.WearableClipgoProductStatus} returns this
 */
proto.company_product_message.WearableClipgoProductStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ProductStatus gamification_status = 2;
 * @return {!proto.company_product_message.ProductStatus}
 */
proto.company_product_message.WearableClipgoProductStatus.prototype.getGamificationStatus = function() {
  return /** @type {!proto.company_product_message.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.company_product_message.ProductStatus} value
 * @return {!proto.company_product_message.WearableClipgoProductStatus} returns this
 */
proto.company_product_message.WearableClipgoProductStatus.prototype.setGamificationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.company_product_message.ProductStatus = {
  PRODUCTSTATUSNONE: 0,
  PRODUCTSTATUSINTERNAL: 1,
  PRODUCTSTATUSNOTAVAILABLE: 2,
  PRODUCTSTATUSAVAILABLE: 3,
  PRODUCTSTATUSTRIALACTIVE: 4,
  PRODUCTSTATUSTRIALFINISHED: 5,
  PRODUCTSTATUSSUBSCRIPTIONACTIVE: 6,
  PRODUCTSTATUSSUBSCRIPTIONDUE: 7,
  PRODUCTSTATUSLIMITEXCEEDED: 8,
  PRODUCTSTATUSSUBSCRIPTIONFINISHED: 9
};

goog.object.extend(exports, proto.company_product_message);
