import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import ChartFactory from '../overview/utils/d3/СhartFactory';
import { getDatesOfSessions } from './ProgramContainer/utils/getDatesOfSessions';
import { getHazardsConfig } from './ProgramContainer/utils/getHazardsConfig';
import { getUser, resetUser } from './userStore/actions';
import NoUserFoundPage from './NoUserFoundPage';

const withUserDataSources = (UserPageComponent, id) => {
  const { t } = useTranslation();
  const navigateBackText = t('WEARABLES.USER_PAGE.BACK_TO_TABLE');
  const chartFactory = new ChartFactory();
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.user);
  const company = useSelector((state) => state.companies?.selectedCompany);
  const userTimezone = user?.timezone || moment.tz.guess();

  const program = user?.currentProgram ?? {};
  const hazardsByDay = user?.currentProgram?.hazardsByDayList ?? [];
  const dates = hazardsByDay
    ? getDatesOfSessions(hazardsByDay, userTimezone)
    : [];
  const hazardsConfig = user?.tier ? getHazardsConfig(user.tier) : null;

  useEffect(() => {
    dispatch(getUser(id));

    return () => dispatch(resetUser());
  }, []);

  if (!user && error) {
    return <NoUserFoundPage id={id} />;
  }

  return (
    <UserPageComponent
      user={user}
      company={company}
      program={program}
      chartFactory={chartFactory}
      navigateBackText={navigateBackText}
      dates={dates}
      hazardsByDay={hazardsByDay}
      hazardsConfig={hazardsConfig}
      timezone={userTimezone}
    />
  );
};

export default withUserDataSources;
