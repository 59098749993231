export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SET_RISKS = 'SET_RISK';
export const SET_INITIAL_RISKS = 'SET_INITIAL_RISK';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const SET_SITES = 'SET_SITES';
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_STATUSES = 'SET_STATUSES';
export const SET_DATES = 'SET_DATES';
export const SET_RESULTS = 'SET_RESULTS';
export const SET_GROUP = 'SET_GROUP';
export const SET_JOBS_DEPARTMENTS = 'SET_JOBS_DEPARTMENTS';
export const SET_JOB_ROLES = 'SET_JOB_ROLES';
export const SET_TYPES = 'SET_TYPES';
export const SET_PAGE = 'SET_PAGE';
export const SET_TYPE = 'SET_TYPE';
export const SET_WEARING_STATUS = 'SET_WEARING_STATUS';
export const SET_CLIP_PROGRAMS = 'SET_CLIP_PROGRAMS';
export const SET_HELP_STATUS = 'SET_HELP_STATUS';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_RESELLERS = 'SET_RESELLERS';
export const SET_COMPANIES_FILTER = 'SET_COMPANIES_FILTER';
export const SET_CREATED_BY = 'SET_CREATED_BY';
export const SET_CAN_INVITE_USERS_FILTER = 'SET_CAN_INVITE_USERS_FILTER';
export const SET_IS_ADMIN_FILTER = 'SET_IS_ADMIN_FILTER';
export const SET_IS_PARTNER_FILTER = 'SET_IS_PARTNER_FILTER';
export const SET_IS_SUBSCRIPTION_MANAGER_FILTER = 'SET_IS_SUBSCRIPTION_MANAGER_FILTER';
export const SET_CAN_RECEIVE_NEEDHELP_REQUESTS_FILTER = 'SET_CAN_RECEIVE_NEEDHELP_REQUESTS_FILTER';
export const SET_VIDEO_STATUS_FILTER = 'SET_VIDEO_STATUS_FILTER';
export const SET_VIDEO_CREATED_BY_FILTER = 'SET_VIDEO_CREATED_BY_FILTER';
export const SET_VIDEO_TASK_NAME_FILTER = 'SET_VIDEO_TASK_NAME_FILTER';
export const SET_VIDEO_JOB_ROLE_FILTER = 'SET_VIDEO_JOB_ROLE_FILTER';
export const SET_VIDEO_RISK_FILTER = 'SET_VIDEO_RISK_FILTER';
export const SET_COMPARISON_CREATED_BY_FILTER = 'SET_COMPARISON_CREATED_BY_FILTER';
export const RESET_FILTERS_STATE = 'RESET_FILTERS_STATE';
export const SET_HUB_TYPE_FILTER = 'SET_HUB_TYPE_FILTER';
export const SET_HUB_STATUS_FILTER = 'SET_HUB_STATUS_FILTER';
export const SET_HUB_COMPANIES_FILTER = 'SET_HUB_COMPANIES_FILTER';
export const VIDEO_TYPE_FILTER = 'VIDEO_TYPE_FILTER';
export const VIDEO_CATEGORY_FILTER = 'VIDEO_CATEGORY_FILTER';
