import React from 'react';
import { Text } from 'tabler-react';
import { Link } from '@reach/router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { nameLink } from '../../../../assets/soter-flex-styles';
import sliceLongString from '../../../../lib/utils/sliceLongString';

const DeviceUserCell = ({ rowData }) => {
  const { lastUser, currentUser } = rowData;
  const user = currentUser || lastUser;
  const { t } = useTranslation();
  return (
    <>
      <p>
        {user
          ? (
            <Link className={nameLink} to={`../user/${user?.id}`} state={{ userType: user?.userType }}>
              {sliceLongString(`${user.firstName} ${user.lastName === 'Clip&Go' ? '' : user.lastName}`, 14)}
            </Link>
          ) : '-'}
      </p>
      <Text.Small
        muted
      >
        {user?.lastActivity ? moment(user?.lastActivity, 'X').fromNow()?.slice(0, moment(user?.lastActivity, 'X').fromNow().length - 3) : t('WEARABLES.DEVICES.NEVER_SYNCED')}
      </Text.Small>
    </>
  );
};

export default DeviceUserCell;
