import * as types from './actionTypes';
import {
  createHubRequest,
  getHubsRequest,
  getMetaData,
  hubRequest,
  hubService,
  typesWearingDevice,
  updateHubRequest,
} from '../../../init/proto_client';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { setOpenedModal } from '../../../containers/ModalContainer/modalsStore/actions';

export function toggleIsLoading(isLoading) {
  return ({ type: types.SET_IS_LOADING, isLoading });
}

export const getHubs = () => async (dispatch) => {
  dispatch(toggleIsLoading(true));
  getHubsRequest.setDummy();
  hubService.getHubs(
    getHubsRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_HUBS_ERROR,
          error,
        });
      } else {
        const { hubsList } = response.toObject();
        dispatch({
          type: types.SET_HUBS,
          hubsList,
        });
      }
      dispatch(toggleIsLoading(false));
    },
  );
};

const setHubDataToRequest = (request,
  {
    alias,
    companyId,
    hubType,
    employeeLoginTypeEnable,
    employeeLoginType,
    inputLoginMethodEnable,
    inputLoginMethod,
    downloadDataNotOnlyFromDevicesOnChargeEnable,
    downloadDataNotOnlyFromDevicesOnCharge,
    configureDeviceMethod,
    configureDeviceMethodEnable,
    hubsCoachingProgramsEnable,
    hubsCoachingProgramsList,
    typeWearingEnable,
    typeWearing,
  }) => {
  const {
    spine, shoulder, headset, helmet,
  } = typeWearing;
  request.setAlias(alias);
  request.setHubType(hubType);
  request.setCompanyId(companyId);
  request.setEmployeeLoginTypeEnable(employeeLoginTypeEnable);
  request.setEmployeeLoginType(employeeLoginType);
  request.setInputLoginMethod(inputLoginMethod);
  request.setInputLoginMethodEnable(inputLoginMethodEnable);
  request.setDownloadDataNotOnlyFromDevicesOnChargeEnable(downloadDataNotOnlyFromDevicesOnChargeEnable);
  request.setDownloadDataNotOnlyFromDevicesOnCharge(downloadDataNotOnlyFromDevicesOnCharge);
  request.setConfigureDeviceMethodEnable(configureDeviceMethodEnable);
  request.setConfigureDeviceMethod(configureDeviceMethod);
  request.setHubsCoachingProgramsEnable(hubsCoachingProgramsEnable);
  request.setHubsCoachingProgramsList(hubsCoachingProgramsList.map(({ value }) => value));
  request.setTypeWearingEnable(typeWearingEnable);
  typesWearingDevice.setSpine(spine);
  typesWearingDevice.setShoulder(shoulder);
  typesWearingDevice.setHeadset(headset);
  typesWearingDevice.setHelmet(helmet);
  request.setTypeWearing(typesWearingDevice);
};

export const createHub = (data) => async (dispatch) => {
  setHubDataToRequest(createHubRequest, data);
  hubService.createHub(
    createHubRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_HUBS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
      } else {
        dispatch({
          type: types.SET_HUB,
          hub: response.toObject(),
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.CREATE_HUB_SUCCESS_MSG,
        }));
        dispatch(setOpenedModal(null));
      }
    },
  );
};

export const editHub = (id, data) => async (dispatch) => {
  updateHubRequest.setId(id);
  setHubDataToRequest(updateHubRequest, data);
  hubService.updateHub(
    updateHubRequest,
    getMetaData(),
    (error, response) => {
      if (error) {
        dispatch({
          type: types.SET_HUBS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
      } else {
        dispatch({
          type: types.SET_HUB,
          hub: response.toObject(),
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.EDIT_HUB_SUCCESS_MSG,
        }));
        dispatch(setOpenedModal(null));
      }
    },
  );
};

export const removeHub = (hubId) => async (dispatch) => {
  hubRequest.setId(hubId);
  hubService.deleteHub(
    hubRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch({
          type: types.SET_HUBS_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.GENERIC_ERROR,
        }));
      } else {
        dispatch({
          type: types.REMOVE_HUB,
          hubId,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.REMOVE_HUB_SUCCESS_MSG,
        }));
        dispatch(setOpenedModal(null));
      }
    },
  );
};

export const changeHubStatus = (hubId, status) => async (dispatch) => {
  hubRequest.setId(hubId);
  if (status) {
    hubService.activateHub(
      hubRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch({
            type: types.SET_HUBS_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
        } else {
          dispatch({
            type: types.CHANGE_HUB_ACTIVITY_STATUS,
            status,
            hubId,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.ACTIVATE_HUB_SUCCESS_MSG,
          }));
          dispatch(setOpenedModal(null));
        }
      },
    );
  } else {
    hubService.deactivateHub(
      hubRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch({
            type: types.SET_HUBS_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
        } else {
          dispatch({
            type: types.CHANGE_HUB_ACTIVITY_STATUS,
            status,
            hubId,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.DEACTIVATE_HUB_SUCCESS_MSG,
          }));
          dispatch(setOpenedModal(null));
        }
      },
    );
  }
};

export const resetHubs = () => (dispatch) => {
  dispatch({ type: types.RESET_HUBS });
};
