import _sortBy from 'lodash/sortBy';
import { editedCompany } from './helpers';
import { TableType } from '../constants/tableType';
import { filterStatusMap } from '../constants/filterStatusMap';
import { categoryNames, typeNames } from '../../../task/gallery/constants/videoTypeAndStatusNames';
import { TaskVideoCategory, TaskVideoType } from '../../../init/proto_client';
import calculateRebaRiskLevel, { rebaRiskTypes } from '../../../task/utils/calculateRebaRiskLevel';

const getFilteringParameters = (departments, type, programs, groups, regions, resellers, companies, creators, videoStatuses, videosCreatedBy, videosTaskNames, videosJobRoles, comparisonsCreatedBy, jobRoles, sites, problems, t, companySegmentList) => {
  switch (type) {
    case TableType.coach: return ([
      {
        name: 'dates',
        label: t('WEARABLES.OVERVIEW.DATE_RANGE'),
        values: [
          {

          },
        ],
      },
      {
        name: 'showPassedPrograms',
        label: '',
        values: [{}],
      },
      {
        name: 'initialRisks',
        label: t('WEARABLES.ACTIVITY.FILTERS.INITIAL'),
        width: 141,
        values: [
          {
            value: 'lower',
            label: t('WEARABLES.ACTIVITY.FILTERS.LOWER'),
          },
          {
            value: 'medium',
            label: t('WEARABLES.ACTIVITY.FILTERS.MEDIUM'),
          },
          {
            value: 'high',
            label: t('WEARABLES.ACTIVITY.FILTERS.HIGHER'),
          },
        ],
      },
      {
        name: 'risks',
        label: t('WEARABLES.ACTIVITY.FILTERS.CURRENT'),
        width: 151,
        values: [
          {
            value: 'lower',
            label: t('WEARABLES.ACTIVITY.FILTERS.LOWER'),
          },
          {
            value: 'medium',
            label: t('WEARABLES.ACTIVITY.FILTERS.MEDIUM'),
          },
          {
            value: 'high',
            label: t('WEARABLES.ACTIVITY.FILTERS.HIGHER'),
          },
        ],
      },
      // {
      //   name: 'jobRoles',
      //   label: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
      //   width: 93,
      //   values: _sortBy(jobRoles, ['label']),
      // },
      {
        name: 'departments',
        label: t('WEARABLES.OVERVIEW.DEPARTMENT'),
        width: 116,
        values: _sortBy(departments, ['label']),
      },
      {
        name: 'sites',
        label: t('WEARABLES.OVERVIEW.SITE'),
        width: 86,
        values: _sortBy(sites, ['label']),
      },
      {
        name: 'programs',
        label: t('WEARABLES.ACTIVITY.PROGRAM'),
        width: 102,
        values: _sortBy(programs, ['label']),
      },
      {
        name: 'statuses',
        label: t('WEARABLES.HELP.STATUS'),
        width: 134,
        values: Object.keys(filterStatusMap).map((value) => ({ value, label: filterStatusMap[value] })),
      },
      ...companySegmentList.map((item) => editedCompany(item, t)),
    ]);
    case TableType.devices:
      return ([
        {
          name: 'wearingStatus',
          label: t('WEARABLES.HELP.STATUS'),
          values: [
            {
              value: 'wearing_now',
              label: t('WEARABLES.DEVICES.FILTERS.WEARING'),
            },
            {
              value: 'not_wearing',
              label: t('WEARABLES.DEVICES.FILTERS.NO_ASSIGNED'),
            },
          ],
        },
        {
          name: 'departments',
          label: t('WEARABLES.OVERVIEW.DEPARTMENT'),
          values: departments,
        },
        {
          name: 'sites',
          label: t('WEARABLES.DEVICES.FILTERS.SITE'),
          values: sites,
        },
        {
          name: 'type',
          label: t('WEARABLES.DEVICES.FILTERS.TYPE'),
          values: [
            {
              value: 'coach',
              label: 'Coach',
            },
            {
              value: 'clipgo',
              label: 'Clip&Go',
            },
          ],
        },
      ]);
    case TableType.jobs:
      return ([
        {
          name: 'group',
          label: t('JOB_DICTIONARY.WORK_GROUP'),
          values: groups,
        },
      ]);
    case TableType.help:
      return ([
        {
          name: 'departments',
          label: t('WEARABLES.OVERVIEW.DEPARTMENT'),
          values: departments,
        },
        {
          name: 'status',
          label: t('WEARABLES.HELP.STATUS'),
          values: [
            {
              value: 'new',
              label: t('GENERAL.NEW'),
            },
            {
              value: 'reopen',
              label: t('WEARABLES.HELP.FILTERS.REOPENED'),
            },
            {
              value: 'in_progress',
              label: t('WEARABLES.HELP.FILTERS.IN_PROGRESS'),
            },
            {
              value: 'closed',
              label: t('WEARABLES.HELP.FILTERS.RESOLVED'),
            },
          ],
        },
        {
          name: 'jobRolesHelp',
          label: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
          values: _sortBy(jobRoles, ['label']),
        },
        {
          name: 'typeOfProblem',
          label: t('WEARABLES.HELP.ACTION'),
          values: _sortBy(problems, ['label']),
        },
      ]);
    case TableType.companies:
      return ([
        {
          name: 'resellers',
          label: 'Partner',
          values: resellers,
        },
        {
          name: 'regions',
          label: 'Region',
          values: regions,
        },
        {
          name: 'programs',
          label: 'Program',
          values: programs,
        },
      ]);
    case TableType.hubs:
      return ([
        {
          name: 'hubStatus',
          label: 'Status',
          values: [
            { value: 'deactivated', label: 'Deactivated' },
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
            { value: 'notSetUp', label: 'Not set up' },
          ],
        },
        {
          name: 'hubCompanies',
          label: 'Company',
          values: _sortBy(companies, 'label'),
        },
        {
          name: 'hubTypes',
          label: 'Hub type',
          values: [
            { value: 1, label: 'SoterCoach Hub' },
            { value: 2, label: 'DCH' },
          ],
        },
      ]);
    case TableType.feedbacks:
      return ([
        {
          name: 'companies',
          label: 'Company',
          values: companies,
        },
      ]);
    case TableType.dashboardUsers:
      return ([
        {
          name: 'companies',
          label: 'Company',
          values: companies,
        },
        {
          name: 'createdBy',
          label: 'Created by',
          values: creators,
        },
        {
          name: 'isAdmin',
          label: 'Admin',
          values: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
        },
        {
          name: 'isPartner',
          label: 'Partner',
          values: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
        },
        {
          name: 'isSubscriptionManager',
          label: 'Subscription manager',
          values: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
        },
      ]);
    case TableType.myCompanyUsers:
      return ([
        {
          name: 'createdBy',
          label: t('MY_COMPANY.USERS.CREATED_BY'),
          values: creators,
        },
        {
          name: 'canInviteUsers',
          label: t('MY_COMPANY.USERS.CAN_INVITE'),
          values: [
            {
              value: true,
              label: t('GENERAL.YES'),
            },
            {
              value: false,
              label: t('GENERAL.NO'),
            },
          ],
        },
        {
          name: 'canReceiveNeedHelpRequests',
          label: t('MY_COMPANY.USERS.NEED_ACTION'),
          values: [
            {
              value: true,
              label: t('GENERAL.YES'),
            },
            {
              value: false,
              label: t('GENERAL.NO'),
            },
          ],
        },
      ]);
    case TableType.soterTaskVideos:
      return ([
        {
          name: 'videoStatus',
          label: t('WEARABLES.HELP.STATUS'),
          values: videoStatuses,
        },
        {
          name: 'videoType',
          label: t('GENERAL.TYPE'),
          values: [{
            label: t(typeNames[TaskVideoType.TASK_ASSESSMENT]), value: TaskVideoType.TASK_ASSESSMENT,
          },
          {
            label: t(typeNames[TaskVideoType.COACHING]), value: TaskVideoType.COACHING,
          },
          {
            label: t(typeNames[TaskVideoType.OTHER]), value: TaskVideoType.OTHER,
          },
          ],
        },
        {
          name: 'videoCategory',
          label: t('SOTER_TASK.VIDEOS_LIST.FILTERS.CATEGORY'),
          values: [{
            label: t(categoryNames[TaskVideoCategory.INITIAL]), value: TaskVideoCategory.INITIAL,
          },
          {
            label: t(categoryNames[TaskVideoCategory.PROPOSED_CHANGE]), value: TaskVideoCategory.PROPOSED_CHANGE,
          },
          {
            label: t(categoryNames[TaskVideoCategory.POST_CHANGE]), value: TaskVideoCategory.POST_CHANGE,
          },
          ],
        },
        {
          name: 'videoCreatedBy',
          label: t('MY_COMPANY.USERS.CREATED_BY'),
          values: videosCreatedBy,
        },
        {
          name: 'videoTaskName',
          label: t('SOTER_TASK.VIDEOS_LIST.TASK_NAME'),
          values: videosTaskNames,
        },
        {
          name: 'videoJobRole',
          label: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
          values: videosJobRoles,
        },
        {
          name: 'videoRisk',
          label: t('SOTER_TASK.VIDEOS_LIST.RISK'),
          values: [
            {
              value: 0,
              label: t('GENERAL.NONE'),
            },
            {
              value: 1,
              label: t('GENERAL.LOW'),
            },
            {
              value: 2,
              label: t('GENERAL.MEDIUM'),
            },
            {
              value: 3,
              label: t('GENERAL.HIGH'),
            },
          ],
        },
        {
          name: 'departmentsTask',
          label: t('WEARABLES.OVERVIEW.DEPARTMENT'),
          values: departments,
        },
        {
          name: 'sitesTask',
          label: t('WEARABLES.OVERVIEW.SITE'),
          values: sites,
        },
        {
          name: 'maxRebaScore',
          label: t('SOTER_TASK.MAX_REBA_SCORE'),
          values: Object.values(rebaRiskTypes).map((value) => ({ label: calculateRebaRiskLevel(value, t)[0], value })),
        },
      ]);
    case TableType.soterTaskComparison:
      return (
        [
          {
            name: 'comparisonCreatedBy',
            label: t('MY_COMPANY.USERS.CREATED_BY'),
            values: comparisonsCreatedBy,
          },
          {
            name: 'departmentsComparison',
            label: t('WEARABLES.OVERVIEW.DEPARTMENT'),
            values: departments,
          },
          {
            name: 'sitesComparison',
            label: t('WEARABLES.OVERVIEW.SITE'),
            values: sites,
          },
        ]
      );
    case TableType.adminDevices:
      return ([]);
    default: return [];
  }
};

export default getFilteringParameters;
