import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import _each from 'lodash/each';
import { useTranslation } from 'react-i18next';
import { cardMediumWithHeight } from '../../assets/soter-flex-styles';
import { COLORS } from '../../constants/colors';
import ActivityBreakdownTable from './ActivityBreakdownTable';
import { weightUnitList } from '../constants';
import DemandsActivityUiConvertor from '../utils/DemandsActivityUiConvertor';
import DemandsActivityByteConvertor from '../utils/DemandsActivityByteConvertor';
import splitDemandActivitiesForTables from '../utils/splitDemandActivitiesForTables';
import { CPD_WEIGHT_UNIT_CHANGE } from '../../constants/analyticsEvents';
import InfoCard from '../../lib/ui/InfoCard';
import CardEditingControl from '../../lib/ui/CardEditingControl';
import Select from '../../wearables/user/ProgramContainer/components/Select';

const CriticalPhysicalDemands = ({
  physicalDemandData,
  saveHandler,
  cancelHandler,
}) => {
  const byteConvertor = new DemandsActivityByteConvertor();
  const uiConvertor = new DemandsActivityUiConvertor();
  const [weightUnits, setWeightUnits] = useState(weightUnitList.kg.title);
  const [physicalDemandDataWithWeight, setPhysicalDemandDataWithWeight]
    = useState({});
  const [physicalDemandDataWithoutWeight, setPhysicalDemandDataWithoutWeight]
    = useState({});
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const convertDataForTable = () => {
    const convertedData = { ...physicalDemandData };
    _each(convertedData, (base64string, key) => {
      const values = [];
      const uintArray = byteConvertor.convertBase64ToUintArray(base64string);
      uintArray.forEach((value) => values.push(byteConvertor.convertIntToActivityValues(value)));
      convertedData[key] = values;
    });
    const { activitiesWithWeight, activitiesWithoutWeight }
      = splitDemandActivitiesForTables(convertedData);
    setPhysicalDemandDataWithWeight(
      uiConvertor.convertDataToUiValues(activitiesWithWeight, weightUnits),
    );
    setPhysicalDemandDataWithoutWeight(
      uiConvertor.convertDataToUiValues(activitiesWithoutWeight, weightUnits),
    );
  };
  useEffect(convertDataForTable, [physicalDemandData, weightUnits]);
  const saveDataHandler = () => {
    const newData = {
      ...physicalDemandDataWithWeight,
      ...physicalDemandDataWithoutWeight,
    };
    const convertedData = uiConvertor.convertUiValuesToData(
      newData,
      weightUnits,
    );
    _each(convertedData, (data, key) => {
      const uintArray = new Uint16Array(
        data.map(({ value, color }) => byteConvertor.convertActivityValuesToInt(value, color)),
      );
      convertedData[key] = byteConvertor.convertUintArrayToBase64(uintArray);
    });
    saveHandler(convertedData);
    setEditMode(false);
  };
  const cancelHandle = () => {
    cancelHandler();
    setEditMode(false);
    convertDataForTable();
  };
  const editHandler = () => {
    setEditMode(true);
    setWeightUnits('kg');
  };
  return (
    <InfoCard
      title={t('JOB_DICTIONARY.JOB_PROFILE.CRITICAL_DEMAND')}
      cardClass={cardMediumWithHeight(1249)}
      titleInlineControl={(
        <Select
          noMargin
          id="pie_chart_unit_select"
          data={
            editMode
              ? [
                {
                  id: weightUnitList.kg.title,
                  date: weightUnitList.kg.title.toUpperCase(),
                },
              ]
              : _map(weightUnitList, (unit) => ({
                id: unit.title,
                date: unit.title.toUpperCase(),
              }))
          }
          setValue={(v) => {
            window.logEvent(CPD_WEIGHT_UNIT_CHANGE, {
              selected_unit: weightUnits,
            });
            setWeightUnits(v);
          }}
        />
      )}
      rightCornerControl={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CardEditingControl
            editMode={editMode}
            editButtonHandler={editHandler}
            cancelButtonHandler={cancelHandle}
            saveButtonHandler={saveDataHandler}
          />
        </div>
      )}
    >
      <span
        style={{
          position: 'absolute',
          color: COLORS.GRAY[400],
          fontSize: '13px',
          top: '-14px',
          fontWeight: 400,
          marginBottom: '5px',
        }}
      >
        {t('JOB_DICTIONARY.JOB_PROFILE.MATERIAL').toUpperCase()}
      </span>
      <ActivityBreakdownTable
        haveValue
        data={physicalDemandDataWithWeight}
        units={weightUnits}
        isEditing={editMode}
        setData={setPhysicalDemandDataWithWeight}
      />
      <span
        style={{
          color: COLORS.GRAY[400],
          fontSize: '13px',
          marginBottom: '10px',
          fontWeight: 400,
        }}
      >
        {t('JOB_DICTIONARY.JOB_PROFILE.NON_MATERIAL_HANDLING').toUpperCase()}
      </span>
      <ActivityBreakdownTable
        data={physicalDemandDataWithoutWeight}
        isEditing={editMode}
        setData={setPhysicalDemandDataWithoutWeight}
      />
    </InfoCard>
  );
};

export default CriticalPhysicalDemands;
