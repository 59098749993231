import * as Emotion from 'emotion';
import { COLORS } from '../../constants/colors';

export const bubble = Emotion.css`
   {
    position: absolute;
    left: 88%;
    top: 8px;
    background-color: ${COLORS.RED[600]};
    border-radius: 20px;
    width: 18px;
    height: 18px;
    font-size: 11px;
    font-weight: 600;
    color: ${COLORS.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const bubbleForButton = Emotion.css`
   {
    position: absolute;
    margin-right: 3px;
    top: -6px;
    background-color: ${COLORS.LIGHT_BLUE[600]};
    border-radius: 20px;
    width: 20px;
    height: 20px;
    font-size: 11px;
    font-weight: 600;
    color: ${COLORS.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
`;
