import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { pl1, pl2 } from './paddings';

export const text = Emotion.css`
  font-family: 'Mulish', sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${COLORS.GRAY[950]};
  text-align: left;
`;

export const subText = Emotion.css`
  margin: 0;
  color: ${COLORS.GRAY[400]} !important;
  font-size: 16px;
  line-height: 20px;
`;

export const infoMainText = Emotion.css`
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  color: ${COLORS.GRAY[950]};
  
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 20px;
  }
`;

export const metricMainText = Emotion.css`
  color: ${COLORS.GRAY[950]};
  font-weight: 600 !important;
  font-size: 32px;
  line-height: 40px;
`;

export const metricSubText = Emotion.css`
  ${subText};

  font-size: 15px;
  line-height: 18px;
  text-align: center;
`;

export const programInfoMainText = Emotion.css`
  ${infoMainText};

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (min-width: 992px) and (max-width: 1280px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const mutedText = Emotion.css`
  margin: 0;
  color: ${COLORS.GRAY[400]};
  font-size: 24px;
  line-height: 20px;
`;

export const smallMutedText = Emotion.css`
  ${mutedText};

  font-size: 14px;
`;

export const darkTitleText = Emotion.css`
  color: ${COLORS.GRAY[950]};
  font-size: 23px;
  font-weight: normal;
  line-height: 28px;
`;

export const subTextResizing = Emotion.css`
  margin: 0;
  color: ${COLORS.GRAY[400]};
  font-size: 0.75rem;
  line-height: 1rem;

  @media (min-width: 992px) and (max-width: 1280px) {
    font-size: 0.65rem;
    line-height: 0.75rem;
  }
`;

export const programInfoSubText = Emotion.css`
  ${subText};

  display: block;
  font-size: 12px !important;
  line-height: 14px;
`;

export const ratingValue = Emotion.css`
  padding: 0;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 30px;
`;

export const infoSpan = Emotion.css`
  ${pl2};

  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.GRAY[950]};
  @media (min-width: 992px) and (max-width: 1280px) {
    ${pl1};

    font-size: 12px;
    line-height: 14px;
  }
`;

export const noDataText = Emotion.css`
  font-weight: 600;
  font-size: 24px !important;
  line-height: 30px;
  color: ${COLORS.GRAY[400]} !important;

  @media (min-width: 992px) and (max-width: 1280px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const medalQuantity = Emotion.css`
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3rem;
  color: ${COLORS.GRAY[400]};
  white-space: nowrap;
  @media (min-width: 768px) and (max-width: 1280px) {
    font-weight: 600;
    font-size: 1.8rem !important;
    line-height: 2.8rem;
  }
`;
