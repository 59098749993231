import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CheckPicker } from 'rsuite';
import { COLORS } from 'constants/colors';
import { multiSelectFooter } from 'assets/jss/components/inlineMultiSelect';
import useOutsideClickHandler from '../../../lib/utils/useOutsideClickHandler';
import Tooltip from '../../../lib/ui/Tooltip';
import sliceLongString from '../../../lib/utils/sliceLongString';
import { formControlInput, formWrapper } from '../../../assets/soter-flex-styles';
import { searchInput } from '../../../assets/jss/components/searchInput';
import Button from '../../../lib/ui/Button';

const InlineMultiSelect = ({
  data = [],
  option = [],
  changeOption,
  appearance = 'subtle',
  size,
  unit,
  values,
  onApply,
}) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredData(
      data.filter(({ label }) => (searchString
        ? label.toLowerCase().includes(searchString.toLowerCase())
        : data)),
    );
  }, [searchString]);

  useOutsideClickHandler(ref, () => setIsOpen(false));

  const getRenderValue = (val) => {
    let element;
    const value = val.map((v) => values.find(({ id }) => id === v)?.name);
    if (!data || !option || data.length === option.length) {
      element = (
        <span style={{color: COLORS.LIGHT_BLUE[600] }}>
          {unit === 'site'
            ? t('WEARABLES.OVERVIEW.ALL_SITES')
            : t('WEARABLES.OVERVIEW.ALL_DEPARTMENTS')}
        </span>
      );
    } else {
      element = (
        <Tooltip text={value.join(' , ')}>
          <span>{sliceLongString(value.join(' , '), 20)}</span>
        </Tooltip>
      );
    }
    return <div>{element}</div>;
  };

  const handleClear = useCallback(() => {
    changeOption([]);
  }, [changeOption]);

  const handleSelectAll = useCallback(() => {
    changeOption(data.map(({ value }) => value));
  }, [changeOption, data]);

  const handleInputChange = (e) => setSearchString(e.target.value);
  const clearInput = () => setSearchString('');

  const language = localStorage.getItem('language');
  const styleForNoEnglish = language !== 'en' ? { width: 'auto' } : null;

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <CheckPicker
        cleanable={false}
        menuStyle={{ color: COLORS.LIGHT_BLUE[600] }}
        placeholder={`${t('GENERAL.SELECT')} ${unit}`}
        onClick={() => setIsOpen(!isOpen)}
        open={isOpen}
        appearance={appearance}
        data={filteredData}
        value={data.length > 0 ? option : []}
        onChange={changeOption}
        size={size}
        searchable={false}
        renderValue={getRenderValue}
        renderMenu={(menu) => (
          <div
            style={{
              width:
                language === 'fr'
                  ? '380px'
                  : language === 'es'
                    ? '370px'
                    : '290px',
            }}
            ref={ref}
          >
            <div className={formWrapper}>
              <input
                className={`${formControlInput} ${searchInput}`}
                style={{
                  margin: '0 10px 10px 10px',
                  width:
                  language === 'fr' || language === 'es' ? '300px' : '270px',
                }}
                type="text"
                id="user-search"
                placeholder={t('GENERAL.SEARCH')}
                value={searchString}
                onChange={(e) => handleInputChange(e)}
              />
              <button type="button" className="clearInput" onClick={clearInput}>
                ✕
              </button>
            </div>

            {menu}
            <div className={multiSelectFooter} style={styleForNoEnglish}>
              <Button
                handler={handleClear}
                disabled={option.length === 0}
                text={t('GENERAL.CLEAR_ALL')}
              />
              <Button
                handler={handleSelectAll}
                disabled={data.length === option.length}
                text={t('GENERAL.SELECT_ALL')}
              />
              <Button
                // style={{ width: 'auto', padding: '0 13px' }}
                handler={() => {
                  onApply(option);
                  setIsOpen(false);
                }}
                text={t('GENERAL.APPLY')}
              />
            </div>
          </div>
        )}
        container={() => containerRef.current}
      />
    </div>
  );
};

export default InlineMultiSelect;
