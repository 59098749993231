import React, { useEffect, useRef } from 'react';
import { Redirect, useLocation } from '@reach/router';
import { page, pageMain } from '../../assets/soter-flex-styles';
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer';
import { storeLocallyLastVisitedPage } from '../utils/storeLocallyLastVisitedPage';
import NotificationContainer from '../../containers/NotificationContainer/NotificationContainer';
import Loader from '../../lib/ui/Loader';

const SiteWrapper = ({
  auth,
  children,
  navigate,
  isAdminArea,
  setInitLoader,
}) => {
  const location = useLocation();
  const initialMount = useRef(true);
  useEffect(() => {
    storeLocallyLastVisitedPage(location.pathname);
    if (initialMount.current === true) {
      sessionStorage.setItem('initPage', true);
      initialMount.current = false;
    } else sessionStorage.removeItem('initPage');
  }, [location]);

  const whenNotAuthorized = (auth.error)
    ? <Redirect noThrow to="/auth" />
    : <Loader />;

  return auth?.profile ? (
    <div id="page_layer" className={page}>
      <div className={pageMain}>
        <HeaderContainer navigate={navigate} isAdminArea={isAdminArea} setInitLoader={setInitLoader} />
        {children}
        <NotificationContainer />
      </div>
    </div>
  )
    : whenNotAuthorized;
};

export default SiteWrapper;
