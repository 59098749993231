import { COLORS } from 'constants/colors';
import neckOne from '../../assets/img/rebaPictures/NeckScore1.svg';
import neckTwo from '../../assets/img/rebaPictures/NeckScore2.svg';
import neckThree from '../../assets/img/rebaPictures/NeckScore3.svg';
import trunkOne from '../../assets/img/rebaPictures/TrunkPosition1.svg';
import trunkTwo from '../../assets/img/rebaPictures/TrunkPosition2.svg';
import trunkThree from '../../assets/img/rebaPictures/TrunkPosition3.svg';
import trunkFour from '../../assets/img/rebaPictures/TrunkPosition4.svg';
import trunkFive from '../../assets/img/rebaPictures/TrunkPosition5.svg';
import legsOne from '../../assets/img/rebaPictures/LegsScore1.svg';
import legsTwo from '../../assets/img/rebaPictures/LegsScore2.svg';
import legsThree from '../../assets/img/rebaPictures/LegsScore3.svg';
import legsFour from '../../assets/img/rebaPictures/LegsScore4.svg';
import upperArmOne from '../../assets/img/rebaPictures/UpperArmScore1.svg';
import upperArmTwo from '../../assets/img/rebaPictures/UpperArmScore2.svg';
import upperArmThree from '../../assets/img/rebaPictures/UpperArmScore3.svg';
import upperArmFour from '../../assets/img/rebaPictures/UpperArmScore4.svg';
import upperArmFive from '../../assets/img/rebaPictures/UpperArmScore5.svg';
import lowerArmOne from '../../assets/img/rebaPictures/LowerArmPosition1.svg';
import lowerArmTwo from '../../assets/img/rebaPictures/LowerArmPosition2.svg';
import wristOne from '../../assets/img/rebaPictures/WristScore1.svg';
import wristTwo from '../../assets/img/rebaPictures/WristScore2.svg';

const makeValue = (type, score, id, image = null, text = null) => ({
  type,
  score,
  id,
  image,
  text,
});
const makeRadio = (score, id, image = null, text = null) => makeValue('radio', score, id, image, text);
const makeCheck = (score, id, image = null, text = null) => makeValue('checkbox', score, id, image, text);

export const rebaStructure = [
  {
    metaTitle: 'SOTER_TASK.ASSESSMENT.REBA.NECK_TRUNK',
    steps: [
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.NECK_SCORE',
        id: 'neck',
        number: 1,
        color: COLORS.WHITE,
        values: [
          makeRadio(1, 'neck_first_img', neckOne),
          makeRadio(2, 'neck_second_img', neckTwo),
          makeRadio(2, 'neck_third_img', neckThree),
          makeCheck(
            1,
            'neck_twisted',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.NECK_TWIST',
          ),
          makeCheck(
            1,
            'neck_bending',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.NECK_BEND',
          ),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.TRUNK_SCORE',
        id: 'trunk',
        number: 2,
        color: '#C7D9F1',
        values: [
          makeRadio(1, 'trunk_first_img', trunkOne),
          makeRadio(2, 'trunk_second_img', trunkTwo),
          makeRadio(2, 'trunk_third_img', trunkThree),
          makeRadio(3, 'trunk_fourth_img', trunkFour),
          makeRadio(4, 'trunk_fifth_img', trunkFive),
          makeCheck(
            1,
            'trunk_twisted',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.TRUNK_TWIST',
          ),
          makeCheck(
            1,
            'trunk_bending',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.TRUNK_BEND',
          ),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.LEGS_SCORE',
        id: 'legs',
        number: 3,
        color: '#FFEAA7',
        values: [
          makeRadio(1, 'leg_first_img', legsOne),
          makeRadio(2, 'leg_second_img', legsTwo),
          makeCheck(1, 'leg_third_img', legsThree),
          makeCheck(2, 'leg_fourth_img', legsFour),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.POSTURE_SCORE',
        id: 'posture_score_a',
        color: COLORS.WHITE,
        symbol: true,
        values: [
          {
            id: 'first_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.REBA.A_LABEL',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.REBA.A_HEADER',
              subHeader: 'SOTER_TASK.ASSESSMENT.REBA.A_SUB',
            },
          },
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.LOAD_SCORE',
        id: 'load',
        number: 5,
        color: COLORS.WHITE,
        weight: true,
        values: [
          makeRadio(0, 'force_first', null, {
            lb: 'SOTER_TASK.ASSESSMENT.REBA.LB_LOAD',
            kg: 'SOTER_TASK.ASSESSMENT.REBA.KG_LOAD',
          }),
          makeRadio(1, 'force_second', null, {
            lb: 'SOTER_TASK.ASSESSMENT.REBA.LB_INTER',
            kg: 'SOTER_TASK.ASSESSMENT.REBA.KG_INTER',
          }),
          makeRadio(2, 'force_third', null, {
            lb: 'SOTER_TASK.ASSESSMENT.REBA.LB_MORE',
            kg: 'SOTER_TASK.ASSESSMENT.REBA.KG_MORE',
          }),
          makeCheck(1, 'force_rapid', null, 'SOTER_TASK.ASSESSMENT.REBA.SHOKE'),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.SCORE_C',
        id: 'row_c',
        color: '#9EBEE6',
        symbol: true,
        values: [
          {
            id: 'second_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.REBA.C_LABEL',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.REBA.C_HEADER',
              subHeader: 'SOTER_TASK.ASSESSMENT.REBA.C_SUB',
            },
          },
        ],
      },
    ],
  },
  {
    metaTitle: 'SOTER_TASK.ASSESSMENT.REBA.ARM_WRIST',
    steps: [
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.UPPER_SCORE',
        id: 'upper_arm',
        number: 7,
        color: '#FF9393',
        values: [
          makeRadio(1, 'arm_first_img', upperArmOne),
          makeRadio(2, 'arm_second_img', upperArmTwo),
          makeRadio(2, 'arm_third_img', upperArmThree),
          makeRadio(3, 'arm_fourth_img', upperArmFour),
          makeRadio(4, 'arm_fifth_img', upperArmFive),
          makeCheck(1, 'arm_raised', null, 'SOTER_TASK.ASSESSMENT.REBA.RAISED'),
          makeCheck(
            1,
            'arm_bending',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.ABDUCTED',
          ),
          makeCheck(
            -1,
            'arm_supporting',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.SUPPORTED',
          ),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.ARM_POSITION',
        id: 'lower_arm',
        number: 8,
        color: '#C1EFFF',
        values: [
          makeRadio(1, 'lower_first_img', lowerArmOne),
          makeRadio(2, 'lower_second_img', lowerArmTwo),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.WRIST_SCORE',
        id: 'wrist',
        number: 9,
        color: '#FDD4B4',
        values: [
          makeRadio(1, 'wrist_first_img', wristOne),
          makeRadio(2, 'wrist_second_img', wristTwo),
          makeCheck(
            1,
            'wrist_bent',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.WRIST_BEND',
          ),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.TABLE_B',
        id: 'posture_score_b',
        color: COLORS.WHITE,
        symbol: true,
        values: [
          {
            id: 'third_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.REBA.B_LABEL',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.REBA.B_HEADER',
              subHeader: 'SOTER_TASK.ASSESSMENT.REBA.B_SUB',
            },
          },
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.COUPLING_SCORE',
        id: 'coupling',
        number: 11,
        color: COLORS.WHITE,
        values: [
          makeRadio(
            0,
            'coupling_first',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.WELL',
          ),
          makeRadio(
            1,
            'coupling_second',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.ACCEPTABLE',
          ),
          makeRadio(
            2,
            'coupling_third',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.HAND',
          ),
          makeRadio(
            3,
            'coupling_fourth',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.NO_HANDLES',
          ),
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.SCORE_B',
        id: 'score_b',
        color: '#CBC1DA',
        symbol: true,
        values: [
          {
            id: 'fourth_posture_score',
            type: 'label',
            label: 'SOTER_TASK.ASSESSMENT.REBA.LABEL_B',
            tableText: {
              header: 'SOTER_TASK.ASSESSMENT.REBA.HEADER_B',
              subHeader: 'SOTER_TASK.ASSESSMENT.REBA.SUB_B',
            },
          },
        ],
      },
      {
        title: 'SOTER_TASK.ASSESSMENT.REBA.ACTIVITY_SCORE',
        id: 'activity',
        number: 13,
        color: COLORS.WHITE,
        values: [
          makeCheck(
            1,
            'activity_held',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.MORE',
          ),
          makeCheck(
            1,
            'activity_repeated',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.REPEATED',
          ),
          makeCheck(
            1,
            'activity_rapid',
            null,
            'SOTER_TASK.ASSESSMENT.REBA.ACTION',
          ),
        ],
      },
    ],
  },
];

export const rebaAMatrix = [
  [1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4],
  [1, 2, 3, 4, 1, 2, 3, 4, 3, 3, 5, 6],
  [2, 3, 4, 5, 3, 4, 5, 6, 4, 5, 6, 7],
  [2, 4, 5, 6, 4, 5, 6, 7, 5, 6, 7, 8],
  [4, 6, 7, 8, 6, 7, 8, 9, 7, 8, 9, 9],
];
export const rebaBMatrix = [
  [1, 2, 2, 1, 2, 3],
  [1, 2, 3, 2, 3, 4],
  [3, 4, 5, 4, 5, 5],
  [4, 5, 5, 5, 6, 7],
  [6, 7, 8, 7, 8, 8],
  [7, 8, 8, 8, 9, 9],
];
export const rebaCMatrix = [
  [1, 1, 1, 2, 3, 3, 4, 5, 6, 7, 7, 7],
  [1, 2, 2, 3, 4, 4, 5, 6, 6, 7, 7, 8],
  [2, 3, 3, 3, 4, 5, 6, 7, 7, 8, 8, 8],
  [3, 4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9],
  [4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9, 9],
  [6, 6, 6, 7, 8, 8, 9, 9, 10, 10, 10, 10],
  [7, 7, 7, 8, 9, 9, 9, 10, 10, 11, 11, 11],
  [8, 8, 8, 9, 10, 10, 10, 10, 10, 11, 11, 11],
  [9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 12],
  [10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12],
  [11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12],
  [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12],
];
