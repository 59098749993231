import { productStatus } from '../proto_client';
import { navSettingsRegular, copilotNavSetting } from '../../containers/PrivatPageHOC/constants/navSettings';

export const getFirstAvailableProductRoute = (selectedCompany) => {
  if (!selectedCompany.products) return;
  const productList = selectedCompany.products;
  const navList = navSettingsRegular.filter(({ name }) => name === 'NAVBAR.WEARABLES'
    || name === 'SoterTask' || name === 'NAVBAR.JOB_DICTIONARY' || name === 'NAVBAR.SOTER_GENIUS');
  const coachList = navList.find((elem) => elem.name === 'NAVBAR.WEARABLES');
  const jobDictionaryList = navList.find((elem) => elem.name === 'NAVBAR.JOB_DICTIONARY');
  const copilotNavSetting = navList.find((elem) => elem.name === 'NAVBAR.SOTER_GENIUS');

  if (productList?.wearableProduct?.status !== productStatus.PRODUCTSTATUSNOTAVAILABLE) {
    return coachList.to;
  } if (productList?.geniusProduct?.status !== productStatus.PRODUCTSTATUSNOTAVAILABLE) {
    return copilotNavSetting.to;
  } if (productList?.jobDictionaryProduct?.status !== productStatus.PRODUCTSTATUSNOTAVAILABLE) {
    return jobDictionaryList.to;
  }
  return coachList.to;
};
