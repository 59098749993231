/* eslint import/prefer-default-export: 0 */

// eslint-disable-next-line import/named
import { AngleZones, MovementTypes } from '../constants';
import { deviceTypes } from '../deviceTypes';

export const emptyDonutChartData = (type) => {
  switch (type) {
    case 'angle':
      return [
        {
          name: AngleZones.LOW,
          value: 0,
        },
        {
          name: AngleZones.MIDDLE,
          value: 0,
        },
        {
          name: AngleZones.HIGH,
          value: 0,
        },
        {
          name: AngleZones.HIGHEST,
          value: 100,
        },
      ];
    case 'percent':
      return [
        {
          name: MovementTypes.HAZARDOUS_MOVEMENT,
          value: 0,
        },
        {
          name: MovementTypes.NORMAL_MOVEMENT,
          value: 100,
        },
      ];
    case deviceTypes.bend:
      return [
        {
          name: 'repetition',
          value: 100,
        },
        {
          name: 'intensity',
          value: 0,
        },
        {
          name: 'pbStatic',
          value: 0,
        },
        {
          name: 'twisting',
          value: 0,
        },
        {
          name: 'lifting',
          value: 0,
        },
      ];
    case deviceTypes.armband:
      return [
        {
          name: 'overexertion',
          value: 100,
        },
        {
          name: 'pushingPulling',
          value: 0,
        },
        {
          name: 'staticArmElevation',
          value: 0,
        },
        {
          name: 'repetitiveArmMovements',
          value: 0,
        },
        {
          name: 'armElevation',
          value: 0,
        },
      ];
    case 'feedback':
      return [
        {
          name: 'negativeFeedbackCount',
          value: 100,
        },
        {
          name: 'neutralFeedbackCount',
          value: 0,
        },
        {
          name: 'positiveFeedbackCount',
          value: 0,
        },
      ];
    default:
      return [
        {
          name: 'noHazardousMovements',
          value: 100,
        },
      ];
  }
};
