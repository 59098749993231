/* eslint no-unused-expressions:0 */
import _intersection from 'lodash/intersection';
import { removeItemsFromSelection, setItemSelection } from '../selectedItemsStore/actions';

export const getMultiSelectionChecked = (selectedUsers, filteredUsersForCurrentPage, id) => {
  if (id === 'all') {
    return _intersection(
      selectedUsers.map((user) => user.id),
      filteredUsersForCurrentPage.map((user) => user.id),
    )
      .length === filteredUsersForCurrentPage.length;
  }
  return !!selectedUsers.filter((user) => user.id === id).length;
};

export const selectionHandler = (dispatch, userType) => (user, checked) => {
  let usersArray;
  user.length ? usersArray = user : usersArray = [user];
  checked
    ? dispatch(setItemSelection(usersArray, userType))
    : dispatch(removeItemsFromSelection(usersArray, userType));
};
