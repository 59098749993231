import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  userProfile: {},
  error: null,
  signature: '',
  videoMetadata: null,
  latestChatId: '',
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  const {
    userProfile, isLoading, error, signature, videoMetadata, latestChatId,
  } = action;
  switch (action.type) {
    case types.USER_PROFILE_CREATE:
      return state.merge({
        userProfile,
        error: null,
        isLoading: false,
      });
    case types.USER_PROFILE_SET:
      return state.merge({
        userProfile,
        error: null,
        isLoading: false,
      });
    case types.USER_PROFILE_SIGNATURE_SET:
      return state.merge({
        signature,
      });
    case types.USER_PROFILE_CHAT_ID_SET:
      return state.merge({
        latestChatId,
      });
    case types.USER_PROFILE_VIDEO_METADATA_SET:
      return state.merge({ videoMetadata });
    case types.USER_PROFILE_VIDEO_METADATA_RESET:
      return state.merge({ videoMetadata: null });
    case types.USER_PROFILE_LOADING:
      return state.merge({ isLoading });
    case types.USER_PROFILE_ERROR:
      return state.merge({ error });
    case types.USER_PROFILE_RESET:
      return state.merge({ ...initialState });
    default: return state;
  }
}
