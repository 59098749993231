// source: api/job_dictionary/v1/job_profile_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.job_dictionary.ActivityDemandBreakdown', null, global);
goog.exportSymbol('proto.job_dictionary.CardsViewConfig', null, global);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryProfile', null, global);
goog.exportSymbol('proto.job_dictionary.CriticalPhysicalDemands', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryBodyProfile', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryProfile', null, global);
goog.exportSymbol('proto.job_dictionary.Task', null, global);
goog.exportSymbol('proto.job_dictionary.TaskItem', null, global);
goog.exportSymbol('proto.job_dictionary.TaskRisk', null, global);
goog.exportSymbol('proto.job_dictionary.VideoItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.Task.displayName = 'proto.job_dictionary.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.TaskRisk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.TaskRisk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.TaskRisk.displayName = 'proto.job_dictionary.TaskRisk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.TaskItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.TaskItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.TaskItem.displayName = 'proto.job_dictionary.TaskItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.VideoItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.VideoItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.VideoItem.displayName = 'proto.job_dictionary.VideoItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CardsViewConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CardsViewConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CardsViewConfig.displayName = 'proto.job_dictionary.CardsViewConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.ActivityDemandBreakdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.ActivityDemandBreakdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.ActivityDemandBreakdown.displayName = 'proto.job_dictionary.ActivityDemandBreakdown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CriticalPhysicalDemands = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CriticalPhysicalDemands, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CriticalPhysicalDemands.displayName = 'proto.job_dictionary.CriticalPhysicalDemands';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryBodyProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.JobDictionaryBodyProfile.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryBodyProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryBodyProfile.displayName = 'proto.job_dictionary.JobDictionaryBodyProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.JobDictionaryProfile.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryProfile.displayName = 'proto.job_dictionary.JobDictionaryProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryProfile.displayName = 'proto.job_dictionary.CreateJobDictionaryProfile';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    demandScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.Task}
 */
proto.job_dictionary.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.Task;
  return proto.job_dictionary.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.Task}
 */
proto.job_dictionary.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDemandScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDemandScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.Task.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.Task} returns this
 */
proto.job_dictionary.Task.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.Task.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.Task} returns this
 */
proto.job_dictionary.Task.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double demand_score = 3;
 * @return {number}
 */
proto.job_dictionary.Task.prototype.getDemandScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.Task} returns this
 */
proto.job_dictionary.Task.prototype.setDemandScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.TaskRisk.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.TaskRisk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.TaskRisk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.TaskRisk.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    riskGroup: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.TaskRisk}
 */
proto.job_dictionary.TaskRisk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.TaskRisk;
  return proto.job_dictionary.TaskRisk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.TaskRisk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.TaskRisk}
 */
proto.job_dictionary.TaskRisk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.TaskRisk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.TaskRisk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.TaskRisk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.TaskRisk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRiskGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string risk_name = 1;
 * @return {string}
 */
proto.job_dictionary.TaskRisk.prototype.getRiskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.TaskRisk} returns this
 */
proto.job_dictionary.TaskRisk.prototype.setRiskName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string risk_group = 2;
 * @return {string}
 */
proto.job_dictionary.TaskRisk.prototype.getRiskGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.TaskRisk} returns this
 */
proto.job_dictionary.TaskRisk.prototype.setRiskGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.TaskItem.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.TaskItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.TaskItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.TaskItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isVideoAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    riskGroup: jspb.Message.getFieldWithDefault(msg, 4, 0),
    spineRiskGroup: jspb.Message.getFieldWithDefault(msg, 5, 0),
    shouldersRiskGroup: jspb.Message.getFieldWithDefault(msg, 6, 0),
    kneeRiskGroup: jspb.Message.getFieldWithDefault(msg, 7, 0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    taskNameId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    titleIsDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    neckRiskGroup: jspb.Message.getFieldWithDefault(msg, 11, 0),
    repetition: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.TaskItem}
 */
proto.job_dictionary.TaskItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.TaskItem;
  return proto.job_dictionary.TaskItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.TaskItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.TaskItem}
 */
proto.job_dictionary.TaskItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVideoAvailable(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRiskGroup(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpineRiskGroup(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShouldersRiskGroup(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKneeRiskGroup(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskNameId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitleIsDeleted(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckRiskGroup(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.TaskItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.TaskItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.TaskItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.TaskItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsVideoAvailable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSpineRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getShouldersRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getKneeRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTaskNameId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTitleIsDeleted();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNeckRiskGroup();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.TaskItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_video_available = 3;
 * @return {boolean}
 */
proto.job_dictionary.TaskItem.prototype.getIsVideoAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setIsVideoAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 risk_group = 4;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 spine_risk_group = 5;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getSpineRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setSpineRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 shoulders_risk_group = 6;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getShouldersRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setShouldersRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 knee_risk_group = 7;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getKneeRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setKneeRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double duration = 8;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 task_name_id = 9;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getTaskNameId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setTaskNameId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool title_is_deleted = 10;
 * @return {boolean}
 */
proto.job_dictionary.TaskItem.prototype.getTitleIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setTitleIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 neck_risk_group = 11;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getNeckRiskGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setNeckRiskGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 repetition = 12;
 * @return {number}
 */
proto.job_dictionary.TaskItem.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.TaskItem} returns this
 */
proto.job_dictionary.TaskItem.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.VideoItem.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.VideoItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.VideoItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.VideoItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    durationSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.VideoItem}
 */
proto.job_dictionary.VideoItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.VideoItem;
  return proto.job_dictionary.VideoItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.VideoItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.VideoItem}
 */
proto.job_dictionary.VideoItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.VideoItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.VideoItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.VideoItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.VideoItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurationSeconds();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.VideoItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.VideoItem} returns this
 */
proto.job_dictionary.VideoItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.job_dictionary.VideoItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.VideoItem} returns this
 */
proto.job_dictionary.VideoItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.job_dictionary.VideoItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.VideoItem} returns this
 */
proto.job_dictionary.VideoItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 duration_seconds = 4;
 * @return {number}
 */
proto.job_dictionary.VideoItem.prototype.getDurationSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.VideoItem} returns this
 */
proto.job_dictionary.VideoItem.prototype.setDurationSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CardsViewConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CardsViewConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CardsViewConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CardsViewConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    showKeyRisks: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    showMedicalSurveillance: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    showEnvironmentalDemand: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    showPsychosocialDemand: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    showCognitiveDemand: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    showTools: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    showPreRequirements: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CardsViewConfig}
 */
proto.job_dictionary.CardsViewConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CardsViewConfig;
  return proto.job_dictionary.CardsViewConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CardsViewConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CardsViewConfig}
 */
proto.job_dictionary.CardsViewConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowKeyRisks(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowMedicalSurveillance(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowEnvironmentalDemand(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowPsychosocialDemand(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowCognitiveDemand(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowTools(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowPreRequirements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CardsViewConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CardsViewConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CardsViewConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CardsViewConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShowKeyRisks();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getShowMedicalSurveillance();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getShowEnvironmentalDemand();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getShowPsychosocialDemand();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getShowCognitiveDemand();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getShowTools();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getShowPreRequirements();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional bool show_key_risks = 1;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowKeyRisks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowKeyRisks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool show_medical_surveillance = 2;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowMedicalSurveillance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowMedicalSurveillance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool show_environmental_demand = 3;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowEnvironmentalDemand = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowEnvironmentalDemand = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool show_psychosocial_demand = 4;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowPsychosocialDemand = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowPsychosocialDemand = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool show_cognitive_demand = 5;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowCognitiveDemand = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowCognitiveDemand = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool show_tools = 6;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowTools = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowTools = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool show_pre_requirements = 7;
 * @return {boolean}
 */
proto.job_dictionary.CardsViewConfig.prototype.getShowPreRequirements = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.CardsViewConfig} returns this
 */
proto.job_dictionary.CardsViewConfig.prototype.setShowPreRequirements = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.ActivityDemandBreakdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.ActivityDemandBreakdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.ActivityDemandBreakdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitting: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    standingWalking: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    activeWork: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    liftingLowering: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    armElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    pushingPulling: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    stoopingBending: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.ActivityDemandBreakdown}
 */
proto.job_dictionary.ActivityDemandBreakdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.ActivityDemandBreakdown;
  return proto.job_dictionary.ActivityDemandBreakdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.ActivityDemandBreakdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.ActivityDemandBreakdown}
 */
proto.job_dictionary.ActivityDemandBreakdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSitting(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStandingWalking(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActiveWork(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLiftingLowering(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmElevation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPushingPulling(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStoopingBending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.ActivityDemandBreakdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.ActivityDemandBreakdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.ActivityDemandBreakdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitting();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getStandingWalking();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getActiveWork();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLiftingLowering();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getStoopingBending();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional double sitting = 1;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getSitting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setSitting = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double standing_walking = 2;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getStandingWalking = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setStandingWalking = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double active_work = 3;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getActiveWork = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setActiveWork = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double lifting_lowering = 4;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getLiftingLowering = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setLiftingLowering = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double arm_elevation = 5;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double pushing_pulling = 6;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double stooping_bending = 7;
 * @return {number}
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.getStoopingBending = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.ActivityDemandBreakdown} returns this
 */
proto.job_dictionary.ActivityDemandBreakdown.prototype.setStoopingBending = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CriticalPhysicalDemands.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CriticalPhysicalDemands} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CriticalPhysicalDemands.toObject = function(includeInstance, msg) {
  var f, obj = {
    floorToWaistLift: msg.getFloorToWaistLift_asB64(),
    waistToShoulderLift: msg.getWaistToShoulderLift_asB64(),
    overheadLift: msg.getOverheadLift_asB64(),
    carry: msg.getCarry_asB64(),
    push: msg.getPush_asB64(),
    pull: msg.getPull_asB64(),
    sitting: msg.getSitting_asB64(),
    standing: msg.getStanding_asB64(),
    walking: msg.getWalking_asB64(),
    squatting: msg.getSquatting_asB64(),
    kneeling: msg.getKneeling_asB64(),
    stairClimbing: msg.getStairClimbing_asB64(),
    ladderClimbing: msg.getLadderClimbing_asB64(),
    bending: msg.getBending_asB64(),
    sustainedFlexion: msg.getSustainedFlexion_asB64(),
    forwardReach: msg.getForwardReach_asB64(),
    overheadReach: msg.getOverheadReach_asB64(),
    neckMovement: msg.getNeckMovement_asB64(),
    trunkMovement: msg.getTrunkMovement_asB64(),
    wristMovement: msg.getWristMovement_asB64(),
    grip: msg.getGrip_asB64(),
    armControl: msg.getArmControl_asB64(),
    legControl: msg.getLegControl_asB64(),
    criticalBalance: msg.getCriticalBalance_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CriticalPhysicalDemands}
 */
proto.job_dictionary.CriticalPhysicalDemands.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CriticalPhysicalDemands;
  return proto.job_dictionary.CriticalPhysicalDemands.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CriticalPhysicalDemands} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CriticalPhysicalDemands}
 */
proto.job_dictionary.CriticalPhysicalDemands.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFloorToWaistLift(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWaistToShoulderLift(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOverheadLift(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCarry(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPush(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPull(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSitting(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStanding(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWalking(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSquatting(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setKneeling(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStairClimbing(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLadderClimbing(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBending(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSustainedFlexion(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setForwardReach(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOverheadReach(value);
      break;
    case 18:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNeckMovement(value);
      break;
    case 19:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTrunkMovement(value);
      break;
    case 20:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWristMovement(value);
      break;
    case 22:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGrip(value);
      break;
    case 23:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setArmControl(value);
      break;
    case 24:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLegControl(value);
      break;
    case 25:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCriticalBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CriticalPhysicalDemands.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CriticalPhysicalDemands} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CriticalPhysicalDemands.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloorToWaistLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getWaistToShoulderLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getOverheadLift_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getCarry_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getPush_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getPull_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getSitting_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getStanding_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getWalking_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getSquatting_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = message.getKneeling_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getStairClimbing_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getLadderClimbing_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getBending_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getSustainedFlexion_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getForwardReach_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      16,
      f
    );
  }
  f = message.getOverheadReach_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getNeckMovement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      18,
      f
    );
  }
  f = message.getTrunkMovement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      19,
      f
    );
  }
  f = message.getWristMovement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      20,
      f
    );
  }
  f = message.getGrip_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      22,
      f
    );
  }
  f = message.getArmControl_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      23,
      f
    );
  }
  f = message.getLegControl_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      24,
      f
    );
  }
  f = message.getCriticalBalance_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      25,
      f
    );
  }
};


/**
 * optional bytes floor_to_waist_lift = 1;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getFloorToWaistLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes floor_to_waist_lift = 1;
 * This is a type-conversion wrapper around `getFloorToWaistLift()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getFloorToWaistLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFloorToWaistLift()));
};


/**
 * optional bytes floor_to_waist_lift = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFloorToWaistLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getFloorToWaistLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFloorToWaistLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setFloorToWaistLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes waist_to_shoulder_lift = 2;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWaistToShoulderLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes waist_to_shoulder_lift = 2;
 * This is a type-conversion wrapper around `getWaistToShoulderLift()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWaistToShoulderLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWaistToShoulderLift()));
};


/**
 * optional bytes waist_to_shoulder_lift = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWaistToShoulderLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWaistToShoulderLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWaistToShoulderLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setWaistToShoulderLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes overhead_lift = 3;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getOverheadLift = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes overhead_lift = 3;
 * This is a type-conversion wrapper around `getOverheadLift()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getOverheadLift_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOverheadLift()));
};


/**
 * optional bytes overhead_lift = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOverheadLift()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getOverheadLift_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOverheadLift()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setOverheadLift = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes carry = 4;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getCarry = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes carry = 4;
 * This is a type-conversion wrapper around `getCarry()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getCarry_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCarry()));
};


/**
 * optional bytes carry = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCarry()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getCarry_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCarry()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setCarry = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes push = 5;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getPush = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes push = 5;
 * This is a type-conversion wrapper around `getPush()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getPush_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPush()));
};


/**
 * optional bytes push = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPush()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getPush_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPush()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setPush = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional bytes pull = 6;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getPull = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes pull = 6;
 * This is a type-conversion wrapper around `getPull()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getPull_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPull()));
};


/**
 * optional bytes pull = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPull()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getPull_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPull()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setPull = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional bytes sitting = 7;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSitting = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes sitting = 7;
 * This is a type-conversion wrapper around `getSitting()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSitting_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSitting()));
};


/**
 * optional bytes sitting = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSitting()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSitting_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSitting()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setSitting = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional bytes standing = 8;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getStanding = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes standing = 8;
 * This is a type-conversion wrapper around `getStanding()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getStanding_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStanding()));
};


/**
 * optional bytes standing = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStanding()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getStanding_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStanding()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setStanding = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional bytes walking = 9;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWalking = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes walking = 9;
 * This is a type-conversion wrapper around `getWalking()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWalking_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWalking()));
};


/**
 * optional bytes walking = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWalking()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWalking_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWalking()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setWalking = function(value) {
  return jspb.Message.setProto3BytesField(this, 9, value);
};


/**
 * optional bytes squatting = 10;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSquatting = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes squatting = 10;
 * This is a type-conversion wrapper around `getSquatting()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSquatting_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSquatting()));
};


/**
 * optional bytes squatting = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSquatting()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSquatting_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSquatting()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setSquatting = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};


/**
 * optional bytes kneeling = 11;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getKneeling = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes kneeling = 11;
 * This is a type-conversion wrapper around `getKneeling()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getKneeling_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getKneeling()));
};


/**
 * optional bytes kneeling = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKneeling()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getKneeling_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getKneeling()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setKneeling = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional bytes stair_climbing = 12;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getStairClimbing = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes stair_climbing = 12;
 * This is a type-conversion wrapper around `getStairClimbing()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getStairClimbing_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStairClimbing()));
};


/**
 * optional bytes stair_climbing = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStairClimbing()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getStairClimbing_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStairClimbing()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setStairClimbing = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};


/**
 * optional bytes ladder_climbing = 13;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getLadderClimbing = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes ladder_climbing = 13;
 * This is a type-conversion wrapper around `getLadderClimbing()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getLadderClimbing_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLadderClimbing()));
};


/**
 * optional bytes ladder_climbing = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLadderClimbing()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getLadderClimbing_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLadderClimbing()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setLadderClimbing = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bytes bending = 14;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getBending = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes bending = 14;
 * This is a type-conversion wrapper around `getBending()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getBending_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBending()));
};


/**
 * optional bytes bending = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBending()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getBending_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBending()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setBending = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional bytes sustained_flexion = 15;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSustainedFlexion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes sustained_flexion = 15;
 * This is a type-conversion wrapper around `getSustainedFlexion()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSustainedFlexion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSustainedFlexion()));
};


/**
 * optional bytes sustained_flexion = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSustainedFlexion()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getSustainedFlexion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSustainedFlexion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setSustainedFlexion = function(value) {
  return jspb.Message.setProto3BytesField(this, 15, value);
};


/**
 * optional bytes forward_reach = 16;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getForwardReach = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes forward_reach = 16;
 * This is a type-conversion wrapper around `getForwardReach()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getForwardReach_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getForwardReach()));
};


/**
 * optional bytes forward_reach = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getForwardReach()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getForwardReach_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getForwardReach()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setForwardReach = function(value) {
  return jspb.Message.setProto3BytesField(this, 16, value);
};


/**
 * optional bytes overhead_reach = 17;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getOverheadReach = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes overhead_reach = 17;
 * This is a type-conversion wrapper around `getOverheadReach()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getOverheadReach_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOverheadReach()));
};


/**
 * optional bytes overhead_reach = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOverheadReach()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getOverheadReach_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOverheadReach()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setOverheadReach = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};


/**
 * optional bytes neck_movement = 18;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getNeckMovement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * optional bytes neck_movement = 18;
 * This is a type-conversion wrapper around `getNeckMovement()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getNeckMovement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNeckMovement()));
};


/**
 * optional bytes neck_movement = 18;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNeckMovement()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getNeckMovement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNeckMovement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setNeckMovement = function(value) {
  return jspb.Message.setProto3BytesField(this, 18, value);
};


/**
 * optional bytes trunk_movement = 19;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getTrunkMovement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * optional bytes trunk_movement = 19;
 * This is a type-conversion wrapper around `getTrunkMovement()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getTrunkMovement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTrunkMovement()));
};


/**
 * optional bytes trunk_movement = 19;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTrunkMovement()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getTrunkMovement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTrunkMovement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setTrunkMovement = function(value) {
  return jspb.Message.setProto3BytesField(this, 19, value);
};


/**
 * optional bytes wrist_movement = 20;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWristMovement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * optional bytes wrist_movement = 20;
 * This is a type-conversion wrapper around `getWristMovement()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWristMovement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWristMovement()));
};


/**
 * optional bytes wrist_movement = 20;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWristMovement()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getWristMovement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWristMovement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setWristMovement = function(value) {
  return jspb.Message.setProto3BytesField(this, 20, value);
};


/**
 * optional bytes grip = 22;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getGrip = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * optional bytes grip = 22;
 * This is a type-conversion wrapper around `getGrip()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getGrip_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGrip()));
};


/**
 * optional bytes grip = 22;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGrip()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getGrip_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGrip()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setGrip = function(value) {
  return jspb.Message.setProto3BytesField(this, 22, value);
};


/**
 * optional bytes arm_control = 23;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getArmControl = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * optional bytes arm_control = 23;
 * This is a type-conversion wrapper around `getArmControl()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getArmControl_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getArmControl()));
};


/**
 * optional bytes arm_control = 23;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getArmControl()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getArmControl_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getArmControl()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setArmControl = function(value) {
  return jspb.Message.setProto3BytesField(this, 23, value);
};


/**
 * optional bytes leg_control = 24;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getLegControl = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * optional bytes leg_control = 24;
 * This is a type-conversion wrapper around `getLegControl()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getLegControl_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLegControl()));
};


/**
 * optional bytes leg_control = 24;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLegControl()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getLegControl_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLegControl()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setLegControl = function(value) {
  return jspb.Message.setProto3BytesField(this, 24, value);
};


/**
 * optional bytes critical_balance = 25;
 * @return {!(string|Uint8Array)}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getCriticalBalance = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * optional bytes critical_balance = 25;
 * This is a type-conversion wrapper around `getCriticalBalance()`
 * @return {string}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getCriticalBalance_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCriticalBalance()));
};


/**
 * optional bytes critical_balance = 25;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCriticalBalance()`
 * @return {!Uint8Array}
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.getCriticalBalance_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCriticalBalance()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.job_dictionary.CriticalPhysicalDemands} returns this
 */
proto.job_dictionary.CriticalPhysicalDemands.prototype.setCriticalBalance = function(value) {
  return jspb.Message.setProto3BytesField(this, 25, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.JobDictionaryBodyProfile.repeatedFields_ = [16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryBodyProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryBodyProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryBodyProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dotClassification: jspb.Message.getFieldWithDefault(msg, 2, 0),
    occupationCategory: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessUnit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rosterHoursShift: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rosterDays: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assessedBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastReviewInMilliseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nextReviewInMilliseconds: jspb.Message.getFieldWithDefault(msg, 9, 0),
    keyRisks: jspb.Message.getFieldWithDefault(msg, 10, ""),
    medicalSurveillance: jspb.Message.getFieldWithDefault(msg, 11, ""),
    environmentalDemand: jspb.Message.getFieldWithDefault(msg, 12, ""),
    psychosocialDemand: jspb.Message.getFieldWithDefault(msg, 13, ""),
    cognitiveDemand: jspb.Message.getFieldWithDefault(msg, 14, ""),
    summaryDescription: jspb.Message.getFieldWithDefault(msg, 15, ""),
    toolsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    preRequirementsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    departmentId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    workGroupId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    cardsViewConfig: (f = msg.getCardsViewConfig()) && proto.job_dictionary.CardsViewConfig.toObject(includeInstance, f),
    activityDemandBreakdown: (f = msg.getActivityDemandBreakdown()) && proto.job_dictionary.ActivityDemandBreakdown.toObject(includeInstance, f),
    criticalPhysicalDemands: (f = msg.getCriticalPhysicalDemands()) && proto.job_dictionary.CriticalPhysicalDemands.toObject(includeInstance, f),
    physicalDemandShoulders: jspb.Message.getFieldWithDefault(msg, 23, 0),
    physicalDemandSpine: jspb.Message.getFieldWithDefault(msg, 24, 0),
    physicalDemandKnees: jspb.Message.getFieldWithDefault(msg, 25, 0),
    shoulderChartData: jspb.Message.getFieldWithDefault(msg, 26, ""),
    spineChartData: jspb.Message.getFieldWithDefault(msg, 27, ""),
    jobRoleId: jspb.Message.getFieldWithDefault(msg, 28, 0),
    titleIsDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 29, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile}
 */
proto.job_dictionary.JobDictionaryBodyProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryBodyProfile;
  return proto.job_dictionary.JobDictionaryBodyProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryBodyProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile}
 */
proto.job_dictionary.JobDictionaryBodyProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDotClassification(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupationCategory(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRosterHoursShift(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRosterDays(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessedBy(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastReviewInMilliseconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextReviewInMilliseconds(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyRisks(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalSurveillance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnvironmentalDemand(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPsychosocialDemand(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCognitiveDemand(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryDescription(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTools(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPreRequirements(values[i]);
      }
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkGroupId(value);
      break;
    case 20:
      var value = new proto.job_dictionary.CardsViewConfig;
      reader.readMessage(value,proto.job_dictionary.CardsViewConfig.deserializeBinaryFromReader);
      msg.setCardsViewConfig(value);
      break;
    case 21:
      var value = new proto.job_dictionary.ActivityDemandBreakdown;
      reader.readMessage(value,proto.job_dictionary.ActivityDemandBreakdown.deserializeBinaryFromReader);
      msg.setActivityDemandBreakdown(value);
      break;
    case 22:
      var value = new proto.job_dictionary.CriticalPhysicalDemands;
      reader.readMessage(value,proto.job_dictionary.CriticalPhysicalDemands.deserializeBinaryFromReader);
      msg.setCriticalPhysicalDemands(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhysicalDemandShoulders(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhysicalDemandSpine(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhysicalDemandKnees(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setShoulderChartData(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpineChartData(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobRoleId(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitleIsDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryBodyProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryBodyProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryBodyProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDotClassification();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOccupationCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRosterHoursShift();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRosterDays();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssessedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastReviewInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNextReviewInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getKeyRisks();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMedicalSurveillance();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEnvironmentalDemand();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPsychosocialDemand();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCognitiveDemand();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSummaryDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getToolsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      16,
      f
    );
  }
  f = message.getPreRequirementsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      17,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getWorkGroupId();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getCardsViewConfig();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.job_dictionary.CardsViewConfig.serializeBinaryToWriter
    );
  }
  f = message.getActivityDemandBreakdown();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.job_dictionary.ActivityDemandBreakdown.serializeBinaryToWriter
    );
  }
  f = message.getCriticalPhysicalDemands();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.job_dictionary.CriticalPhysicalDemands.serializeBinaryToWriter
    );
  }
  f = message.getPhysicalDemandShoulders();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getPhysicalDemandSpine();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getPhysicalDemandKnees();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getShoulderChartData();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getSpineChartData();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getJobRoleId();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getTitleIsDeleted();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 dot_classification = 2;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getDotClassification = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setDotClassification = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string occupation_category = 3;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getOccupationCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setOccupationCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_unit = 4;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getBusinessUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setBusinessUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string roster_hours_shift = 5;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getRosterHoursShift = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setRosterHoursShift = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string roster_days = 6;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getRosterDays = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setRosterDays = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string assessed_by = 7;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getAssessedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setAssessedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 last_review_in_milliseconds = 8;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getLastReviewInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setLastReviewInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 next_review_in_milliseconds = 9;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getNextReviewInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setNextReviewInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string key_risks = 10;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getKeyRisks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setKeyRisks = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string medical_surveillance = 11;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getMedicalSurveillance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setMedicalSurveillance = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string environmental_demand = 12;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getEnvironmentalDemand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setEnvironmentalDemand = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string psychosocial_demand = 13;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getPsychosocialDemand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setPsychosocialDemand = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string cognitive_demand = 14;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getCognitiveDemand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setCognitiveDemand = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string summary_description = 15;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getSummaryDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setSummaryDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated int32 tools = 16;
 * @return {!Array<number>}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getToolsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setToolsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.addTools = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.clearToolsList = function() {
  return this.setToolsList([]);
};


/**
 * repeated int32 pre_requirements = 17;
 * @return {!Array<number>}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getPreRequirementsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setPreRequirementsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.addPreRequirements = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.clearPreRequirementsList = function() {
  return this.setPreRequirementsList([]);
};


/**
 * optional int32 department_id = 18;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 work_group_id = 19;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getWorkGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setWorkGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional CardsViewConfig cards_view_config = 20;
 * @return {?proto.job_dictionary.CardsViewConfig}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getCardsViewConfig = function() {
  return /** @type{?proto.job_dictionary.CardsViewConfig} */ (
    jspb.Message.getWrapperField(this, proto.job_dictionary.CardsViewConfig, 20));
};


/**
 * @param {?proto.job_dictionary.CardsViewConfig|undefined} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
*/
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setCardsViewConfig = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.clearCardsViewConfig = function() {
  return this.setCardsViewConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.hasCardsViewConfig = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional ActivityDemandBreakdown activity_demand_breakdown = 21;
 * @return {?proto.job_dictionary.ActivityDemandBreakdown}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getActivityDemandBreakdown = function() {
  return /** @type{?proto.job_dictionary.ActivityDemandBreakdown} */ (
    jspb.Message.getWrapperField(this, proto.job_dictionary.ActivityDemandBreakdown, 21));
};


/**
 * @param {?proto.job_dictionary.ActivityDemandBreakdown|undefined} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
*/
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setActivityDemandBreakdown = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.clearActivityDemandBreakdown = function() {
  return this.setActivityDemandBreakdown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.hasActivityDemandBreakdown = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional CriticalPhysicalDemands critical_physical_demands = 22;
 * @return {?proto.job_dictionary.CriticalPhysicalDemands}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getCriticalPhysicalDemands = function() {
  return /** @type{?proto.job_dictionary.CriticalPhysicalDemands} */ (
    jspb.Message.getWrapperField(this, proto.job_dictionary.CriticalPhysicalDemands, 22));
};


/**
 * @param {?proto.job_dictionary.CriticalPhysicalDemands|undefined} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
*/
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setCriticalPhysicalDemands = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.clearCriticalPhysicalDemands = function() {
  return this.setCriticalPhysicalDemands(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.hasCriticalPhysicalDemands = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional int32 physical_demand_shoulders = 23;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getPhysicalDemandShoulders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setPhysicalDemandShoulders = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 physical_demand_spine = 24;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getPhysicalDemandSpine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setPhysicalDemandSpine = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 physical_demand_knees = 25;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getPhysicalDemandKnees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setPhysicalDemandKnees = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string shoulder_chart_data = 26;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getShoulderChartData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setShoulderChartData = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string spine_chart_data = 27;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getSpineChartData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setSpineChartData = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional int32 job_role_id = 28;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getJobRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setJobRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional bool title_is_deleted = 29;
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.getTitleIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.JobDictionaryBodyProfile} returns this
 */
proto.job_dictionary.JobDictionaryBodyProfile.prototype.setTitleIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.JobDictionaryProfile.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profile: (f = msg.getProfile()) && proto.job_dictionary.JobDictionaryBodyProfile.toObject(includeInstance, f),
    taskProfilesListList: jspb.Message.toObjectList(msg.getTaskProfilesListList(),
    proto.job_dictionary.TaskItem.toObject, includeInstance),
    videosListList: jspb.Message.toObjectList(msg.getVideosListList(),
    proto.job_dictionary.VideoItem.toObject, includeInstance),
    videosTaskListList: jspb.Message.toObjectList(msg.getVideosTaskListList(),
    proto.job_dictionary.VideoItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryProfile}
 */
proto.job_dictionary.JobDictionaryProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryProfile;
  return proto.job_dictionary.JobDictionaryProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryProfile}
 */
proto.job_dictionary.JobDictionaryProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.job_dictionary.JobDictionaryBodyProfile;
      reader.readMessage(value,proto.job_dictionary.JobDictionaryBodyProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 3:
      var value = new proto.job_dictionary.TaskItem;
      reader.readMessage(value,proto.job_dictionary.TaskItem.deserializeBinaryFromReader);
      msg.addTaskProfilesList(value);
      break;
    case 4:
      var value = new proto.job_dictionary.VideoItem;
      reader.readMessage(value,proto.job_dictionary.VideoItem.deserializeBinaryFromReader);
      msg.addVideosList(value);
      break;
    case 5:
      var value = new proto.job_dictionary.VideoItem;
      reader.readMessage(value,proto.job_dictionary.VideoItem.deserializeBinaryFromReader);
      msg.addVideosTaskList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.job_dictionary.JobDictionaryBodyProfile.serializeBinaryToWriter
    );
  }
  f = message.getTaskProfilesListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.job_dictionary.TaskItem.serializeBinaryToWriter
    );
  }
  f = message.getVideosListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.job_dictionary.VideoItem.serializeBinaryToWriter
    );
  }
  f = message.getVideosTaskListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.job_dictionary.VideoItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
 */
proto.job_dictionary.JobDictionaryProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional JobDictionaryBodyProfile profile = 2;
 * @return {?proto.job_dictionary.JobDictionaryBodyProfile}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.getProfile = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryBodyProfile} */ (
    jspb.Message.getWrapperField(this, proto.job_dictionary.JobDictionaryBodyProfile, 2));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryBodyProfile|undefined} value
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
*/
proto.job_dictionary.JobDictionaryProfile.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
 */
proto.job_dictionary.JobDictionaryProfile.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TaskItem task_profiles_list = 3;
 * @return {!Array<!proto.job_dictionary.TaskItem>}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.getTaskProfilesListList = function() {
  return /** @type{!Array<!proto.job_dictionary.TaskItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.TaskItem, 3));
};


/**
 * @param {!Array<!proto.job_dictionary.TaskItem>} value
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
*/
proto.job_dictionary.JobDictionaryProfile.prototype.setTaskProfilesListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.job_dictionary.TaskItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.TaskItem}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.addTaskProfilesList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.job_dictionary.TaskItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
 */
proto.job_dictionary.JobDictionaryProfile.prototype.clearTaskProfilesListList = function() {
  return this.setTaskProfilesListList([]);
};


/**
 * repeated VideoItem videos_list = 4;
 * @return {!Array<!proto.job_dictionary.VideoItem>}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.getVideosListList = function() {
  return /** @type{!Array<!proto.job_dictionary.VideoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.VideoItem, 4));
};


/**
 * @param {!Array<!proto.job_dictionary.VideoItem>} value
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
*/
proto.job_dictionary.JobDictionaryProfile.prototype.setVideosListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.job_dictionary.VideoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.VideoItem}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.addVideosList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.job_dictionary.VideoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
 */
proto.job_dictionary.JobDictionaryProfile.prototype.clearVideosListList = function() {
  return this.setVideosListList([]);
};


/**
 * repeated VideoItem videos_task_list = 5;
 * @return {!Array<!proto.job_dictionary.VideoItem>}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.getVideosTaskListList = function() {
  return /** @type{!Array<!proto.job_dictionary.VideoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.VideoItem, 5));
};


/**
 * @param {!Array<!proto.job_dictionary.VideoItem>} value
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
*/
proto.job_dictionary.JobDictionaryProfile.prototype.setVideosTaskListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.job_dictionary.VideoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.VideoItem}
 */
proto.job_dictionary.JobDictionaryProfile.prototype.addVideosTaskList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.job_dictionary.VideoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryProfile} returns this
 */
proto.job_dictionary.JobDictionaryProfile.prototype.clearVideosTaskListList = function() {
  return this.setVideosTaskListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profile: (f = msg.getProfile()) && proto.job_dictionary.JobDictionaryBodyProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryProfile}
 */
proto.job_dictionary.CreateJobDictionaryProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryProfile;
  return proto.job_dictionary.CreateJobDictionaryProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryProfile}
 */
proto.job_dictionary.CreateJobDictionaryProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.job_dictionary.JobDictionaryBodyProfile;
      reader.readMessage(value,proto.job_dictionary.JobDictionaryBodyProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.job_dictionary.JobDictionaryBodyProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryProfile} returns this
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional JobDictionaryBodyProfile profile = 2;
 * @return {?proto.job_dictionary.JobDictionaryBodyProfile}
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.getProfile = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryBodyProfile} */ (
    jspb.Message.getWrapperField(this, proto.job_dictionary.JobDictionaryBodyProfile, 2));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryBodyProfile|undefined} value
 * @return {!proto.job_dictionary.CreateJobDictionaryProfile} returns this
*/
proto.job_dictionary.CreateJobDictionaryProfile.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.CreateJobDictionaryProfile} returns this
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.CreateJobDictionaryProfile.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.job_dictionary);
