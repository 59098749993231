// source: api/soter-task/common/v1/overview_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.overviewTask.SoterTaskOverviewRequest', null, global);
goog.exportSymbol('proto.overviewTask.SoterTaskOverviewResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overviewTask.SoterTaskOverviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overviewTask.SoterTaskOverviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overviewTask.SoterTaskOverviewRequest.displayName = 'proto.overviewTask.SoterTaskOverviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overviewTask.SoterTaskOverviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overviewTask.SoterTaskOverviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overviewTask.SoterTaskOverviewResponse.displayName = 'proto.overviewTask.SoterTaskOverviewResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overviewTask.SoterTaskOverviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.overviewTask.SoterTaskOverviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overviewTask.SoterTaskOverviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overviewTask.SoterTaskOverviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overviewTask.SoterTaskOverviewRequest}
 */
proto.overviewTask.SoterTaskOverviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overviewTask.SoterTaskOverviewRequest;
  return proto.overviewTask.SoterTaskOverviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overviewTask.SoterTaskOverviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overviewTask.SoterTaskOverviewRequest}
 */
proto.overviewTask.SoterTaskOverviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overviewTask.SoterTaskOverviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overviewTask.SoterTaskOverviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overviewTask.SoterTaskOverviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overviewTask.SoterTaskOverviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewRequest} returns this
 */
proto.overviewTask.SoterTaskOverviewRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.overviewTask.SoterTaskOverviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overviewTask.SoterTaskOverviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overviewTask.SoterTaskOverviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    videoCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assessmentCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    comparisonCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    averageComparisonImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lowRiskVideoCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mediumRiskVideoCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    highRiskVideoCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    comparisonLowRiskBeforeCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    comparisonMediumRiskBeforeCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    comparisonHighRiskBeforeCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    comparisonLowRiskAfterCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    comparisonMediumRiskAfterCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    comparisonHighRiskAfterCount: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overviewTask.SoterTaskOverviewResponse}
 */
proto.overviewTask.SoterTaskOverviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overviewTask.SoterTaskOverviewResponse;
  return proto.overviewTask.SoterTaskOverviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overviewTask.SoterTaskOverviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overviewTask.SoterTaskOverviewResponse}
 */
proto.overviewTask.SoterTaskOverviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssessmentCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageComparisonImprovement(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowRiskVideoCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMediumRiskVideoCount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHighRiskVideoCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonLowRiskBeforeCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonMediumRiskBeforeCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonHighRiskBeforeCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonLowRiskAfterCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonMediumRiskAfterCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComparisonHighRiskAfterCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overviewTask.SoterTaskOverviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overviewTask.SoterTaskOverviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overviewTask.SoterTaskOverviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVideoCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAssessmentCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getComparisonCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAverageComparisonImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLowRiskVideoCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMediumRiskVideoCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHighRiskVideoCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getComparisonLowRiskBeforeCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getComparisonMediumRiskBeforeCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getComparisonHighRiskBeforeCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getComparisonLowRiskAfterCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getComparisonMediumRiskAfterCount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getComparisonHighRiskAfterCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 video_count = 2;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getVideoCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setVideoCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 assessment_count = 3;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getAssessmentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setAssessmentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 comparison_count = 4;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double average_comparison_improvement = 5;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getAverageComparisonImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setAverageComparisonImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 low_risk_video_count = 6;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getLowRiskVideoCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setLowRiskVideoCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 medium_risk_video_count = 7;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getMediumRiskVideoCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setMediumRiskVideoCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 high_risk_video_count = 8;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getHighRiskVideoCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setHighRiskVideoCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 comparison_low_risk_before_count = 9;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonLowRiskBeforeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonLowRiskBeforeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 comparison_medium_risk_before_count = 10;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonMediumRiskBeforeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonMediumRiskBeforeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 comparison_high_risk_before_count = 11;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonHighRiskBeforeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonHighRiskBeforeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 comparison_low_risk_after_count = 12;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonLowRiskAfterCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonLowRiskAfterCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 comparison_medium_risk_after_count = 13;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonMediumRiskAfterCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonMediumRiskAfterCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 comparison_high_risk_after_count = 14;
 * @return {number}
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.getComparisonHighRiskAfterCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.overviewTask.SoterTaskOverviewResponse} returns this
 */
proto.overviewTask.SoterTaskOverviewResponse.prototype.setComparisonHighRiskAfterCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


goog.object.extend(exports, proto.overviewTask);
