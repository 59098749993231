import React from 'react';
import { COLORS } from 'constants/colors';

const ImprovementCell = ({ avg, rowData }) => {
  const value = rowData.program
    ? rowData.program?.improvements?.hazardsPerHourImprovement
    : rowData.improvements?.currentHazardsPerHour;
  const change = rowData.program
    ? rowData.program?.improvements?.hazardsPerHourChange
    : rowData.improvements?.hazardsPerHourChange;
  const zeroChange = change === 0 || !change;
  const positiveChange = change < 0;
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      <div style={{ fontSize: '14px' }}>
        {value || value === 0 ? (avg ? value : `${value}%`) : '–'}
      </div>
      <div
        style={{
          fontSize: '12px',
          // eslint-disable-next-line no-nested-ternary
          color: zeroChange ? COLORS.GRAY[400] : positiveChange ? COLORS.GREEN[600] : COLORS.RED[600],
        }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {change
          ? `${positiveChange ? '-' : zeroChange ? '' : '+'}${Math.abs(
            change,
          )}%`
          : '–'}
      </div>
    </>
  );
};

export default ImprovementCell;
