import React from 'react';
import { useTranslation } from 'react-i18next';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';
import presentSeconds from '../../../lib/utils/presentSeconds';

const timeWrapper = Emotion.css`
  width: inherit;
  height: 35px;
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 3px;
  padding: 6px;
  cursor: default;
`;

const AssessmentTime = ({ time }) => {
  const { t } = useTranslation();
  return (
    <div style={{ height: 'inherit', padding: '10px' }}>
      <div className={text(COLORS.GRAY[400], 16)}>
        {t('SOTER_TASK.ASSESSMENT.TITLES.SELECT_TIME').toUpperCase()}
      </div>
      <div className={text(COLORS.GRAY[950], 14)}>
        {t('SOTER_TASK.ASSESSMENT.TITLES.TIME')}
      </div>
      {/*<CustomInput value={presentSeconds(time / 1000)} />*/}
      <div className={timeWrapper}>{presentSeconds(time / 1000)}</div>
    </div>
  );
};

export default AssessmentTime;
