import { createSelector } from 'reselect';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';

const rootSelector = (state) => state.userTasks;

export const isLoadingSelector = createSelector(
  rootSelector,
  ({ isLoading }) => isLoading,
);

export const tasksSelector = (taskss) => createSelector(rootSelector, ({ tasks }) => _sortBy([...taskss, ...tasks], 'timeStart'));

export const taskTitlesSelector = createSelector(
  rootSelector,
  ({ taskTitles }) => _uniq(taskTitles.map((item) => item.title)),
);

export const editedTaskSelector = createSelector(
  rootSelector,
  ({ editedTask }) => editedTask,
);
