import React from 'react';

const getStyleForLabel = (position) => {
  let left;
  let top;
  switch (position) {
    case 'middle':
      left = '276px';
      top = '264px';
      break;
    case 'top':
      left = '17px';
      top = '2px';
      break;
    case 'bottom':
      left = '17px';
      top = '526px';
      break;
    case 'middleTop':
      top = '36px';
      left = '146px';
      break;
    case 'highMiddle':
      top = '134px';
      left = '243px';
      break;
    case 'lowMiddle':
      top = '398px';
      left = '238px';
      break;
    case 'middleBottom':
      top = '491px';
      left = '140px';
      break;
    default: return;
  }
  return {
    fontSize: '12px',
    position: 'absolute',
    zIndex: 10,
    left,
    top,
  };
};

const AngleLabel = ({ angle, position }) => (
  <span style={getStyleForLabel(position)}>
    {`${angle}°`}
  </span>
);

export default AngleLabel;
