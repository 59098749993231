var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Checkbox } from 'rsuite';
import { customCheckboxStyles, switcherStyles } from './styles';
const CustomCheckbox = (_a) => {
    var { checked, name, label, disabled, formik, onChange, custom } = _a, rest = __rest(_a, ["checked", "name", "label", "disabled", "formik", "onChange", "custom"]);
    const handleChange = (_, isChecked) => {
        if (formik) {
            if (onChange)
                onChange(name, isChecked, label);
            formik.setFieldValue(name, isChecked);
        }
        else {
            onChange(name, isChecked, label);
        }
    };
    const renderCheckbox = (checkbox) => {
        switch (checkbox) {
            case 'customSwitcher':
                return (React.createElement("label", { className: switcherStyles },
                    React.createElement("span", { className: "title" }, label),
                    React.createElement("input", { type: "checkbox", checked: formik ? formik.values[name] : checked, disabled: disabled, onChange: (e) => (formik ? handleChange(e, !formik.values[name]) : handleChange(e, !checked)) })));
            case 'customCheckbox':
                return (React.createElement("label", { className: customCheckboxStyles },
                    React.createElement("input", Object.assign({ type: "checkbox", checked: formik ? formik.values[name] : checked, disabled: disabled, onChange: (e) => (formik ? handleChange(e, !formik.values[name]) : handleChange(e, !checked)) }, rest)),
                    React.createElement("span", { className: "title" }, label)));
            default:
                return (React.createElement(Checkbox, Object.assign({ checked: formik ? formik.values[name] : checked, disabled: disabled, onChange: handleChange }, rest), label));
        }
    };
    return (React.createElement(React.Fragment, null, renderCheckbox(custom)));
};
export default CustomCheckbox;
