import React from 'react';
import { ReactComponent as False } from '../../../../assets/img/icons/notifications/failClose.svg';
import { ReactComponent as True } from '../../../../assets/img/icons/notifications/success-notification-icon.svg';

const BooleanCell = ({ rowData, dataKey }) => {
  let value;
  switch (dataKey) {
    case 'isAdmin':
      value = rowData.admin;
      break;
    case 'isPartner':
      value = rowData.resellerId !== 0;
      break;
    case 'isSubscriptionManager':
      value = rowData.isSubscriptionManager;
      break;
    case 'canInviteUsers':
      value = rowData.canInviteUsers;
      break;
    case 'receiveNeedHelpRequests':
      value = rowData.receiveNeedHelpRequests;
      break;
    default: value = rowData.admin;
  }
  return (
    <>
      {value ? <True /> : <False />}
    </>
  );
};

export default BooleanCell;
