import React, { useState, useEffect } from 'react';
import { Text } from 'tabler-react';
import { useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import moment from 'moment';
import * as Emotion from 'emotion';
import { COLORS } from '../../../../constants/colors';
import Tooltip from '../../../../lib/ui/Tooltip';
import sliceLongString from '../../../../lib/utils/sliceLongString';
import { hubTypeNameMap } from '../../constants/hubTypeNameMap';

const companyNameBtn = Emotion.css`
    font-size: 14px;
    background: transparent;
    transition: 0.3s;
`;

const orangeText = { color: COLORS.ORANGE[500] };
const NAME_LENGTH = 80;
const RESELLER_LENGTH = 10;
const DESCRIPTION_LENGTH = 50;

const SimpleCompaniesTextCell = (props) => {
  const { rowData, type } = props;
  const navigate = useNavigate();
  const { companiesList } = useSelector((state) => state.companies);

  // State to store the mouse position
  const [mousePosition, setMousePosition] = useState('bottomEnd');

  // Function to update the mouse position
  const updateMousePosition = (event) => {
    const mouseY = event.clientY;
    const windowHeight = window.innerHeight;
    if (mouseY < windowHeight / 2) {
      setMousePosition('bottomEnd');
    } else {
      setMousePosition('topStart');
    }
  };

  // Effect hook to add the mousemove event listener
  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  const getUserName = () => (
    <span>
      {`${rowData?.firstName || ''} `}
      <b>{rowData?.lastName || ''}</b>
    </span>
  );

  const onEditCompany = (company) => {
    navigate(`companies/${company.id}`);
  };

  const getSlicedTextWithTooltip = (text, maxLength, selectedType, company) => (
    text?.length >= maxLength ? (
        <Tooltip placement={mousePosition} text={text}>
          {selectedType ? <button className={companyNameBtn} type="button" onClick={() => onEditCompany(company)}>{sliceLongString(text, maxLength)}</button>
            : <span>{sliceLongString(text, maxLength)}</span>}
        </Tooltip>
      )
      : <div>{selectedType ? <button className={companyNameBtn} type="button" onClick={() => onEditCompany(company)}>{text}</button> : text}</div>
  );

  const getTextByType = (rowData, type) => {
    switch (type) {
      case 'companyName':
        return getSlicedTextWithTooltip(rowData.name, NAME_LENGTH, 'name', rowData);
      case 'description':
        return getSlicedTextWithTooltip(rowData.description, DESCRIPTION_LENGTH);
      case 'companyDeviceCount':
        return rowData.deviceCount;
      case 'namesInReport':
        return rowData.wearableSettings?.reportPeopleName ? (
          'Shown'
        ) : (
          <span style={orangeText}>Hidden</span>
        );
      case 'reseller':
        return rowData.partner?.name
          ? getSlicedTextWithTooltip(rowData.partner.name, RESELLER_LENGTH)
          : '–';
      case 'company':
        return rowData.companyId
          ? getSlicedTextWithTooltip(
            companiesList.find(({ id }) => id === rowData.companyId)?.name,
            15,
          )
          : '-';
      case 'hubType':
        return hubTypeNameMap[rowData.hubType];
      case 'dashboardUserId':
        return getUserName();
      case 'createdAtMilliseconds':
        return rowData.createdAtMilliseconds
          ? moment(rowData.createdAtMilliseconds).format('DD MMM YYYY')
          : '-';
      case 'lastSeenMilliseconds':
        return rowData.lastSeenMilliseconds
          ? moment(rowData.lastSeenMilliseconds)
            .fromNow()
            .slice(
              0,
              moment(rowData.lastSeenMilliseconds).fromNow().indexOf('ago') + 3,
            )
          : '-';
      case 'status':
        return rowData.isActive
          ? !rowData.lastSeenMilliseconds
            ? 'Not set up'
            : moment().format('x') - rowData.lastSeenMilliseconds > 604800000
              ? 'Inactive'
              : 'Active'
          : 'Deactivated';
      default:
        return rowData[type]?.toString() || '–';
    }
  };

  return <Text>{getTextByType(rowData, type)}</Text>;
};

export default SimpleCompaniesTextCell;
