import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../constants/colors';
import Select from '../../wearables/user/ProgramContainer/components/Select';

const SelectionString = ({
  setUnitState,
  unitList,
  setTypeState,
  typeList,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '10px 5px 0px 25px', height: '38px' }}>
      <Select
        noMargin
        id='pie_chart_unit_select'
        data={unitList.map((unit) => ({
          id: unit.id,
          date: t(unit.text).toUpperCase(),
        }))}
        setValue={setUnitState}
      />
      <span
        style={{
          color: COLORS.GRAY[400],
          fontSize: 16,
          fontWeight: 400,
          paddingRight: '3px',
          paddingLeft: '4px',
        }}
      >
        {t('GENERAL.IN')}
      </span>
      <Select
        noMargin
        id='pie_chart_unit_select'
        data={typeList.map((unit) => ({
          id: unit,
          date: t(unit).toUpperCase(),
        }))}
        setValue={setTypeState}
      />
    </div>
  );
};

export default SelectionString;
