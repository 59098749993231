/**
 * @fileoverview gRPC-Web generated client stub for overviewTask
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/soter-task/dashboard/v1/overview_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_soter$task_common_v1_overview_message_pb = require('../../../../api/soter-task/common/v1/overview_message_pb.js')
const proto = {};
proto.overviewTask = require('./overview_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.overviewTask.TaskOverviewServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.overviewTask.TaskOverviewServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.overviewTask.SoterTaskOverviewRequest,
 *   !proto.overviewTask.SoterTaskOverviewResponse>}
 */
const methodDescriptor_TaskOverviewService_GetTaskOverviewData = new grpc.web.MethodDescriptor(
  '/overviewTask.TaskOverviewService/GetTaskOverviewData',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_overview_message_pb.SoterTaskOverviewRequest,
  api_soter$task_common_v1_overview_message_pb.SoterTaskOverviewResponse,
  /**
   * @param {!proto.overviewTask.SoterTaskOverviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_overview_message_pb.SoterTaskOverviewResponse.deserializeBinary
);


/**
 * @param {!proto.overviewTask.SoterTaskOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.overviewTask.SoterTaskOverviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.overviewTask.SoterTaskOverviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.overviewTask.TaskOverviewServiceClient.prototype.getTaskOverviewData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/overviewTask.TaskOverviewService/GetTaskOverviewData',
      request,
      metadata || {},
      methodDescriptor_TaskOverviewService_GetTaskOverviewData,
      callback);
};


/**
 * @param {!proto.overviewTask.SoterTaskOverviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.overviewTask.SoterTaskOverviewResponse>}
 *     Promise that resolves to the response
 */
proto.overviewTask.TaskOverviewServicePromiseClient.prototype.getTaskOverviewData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/overviewTask.TaskOverviewService/GetTaskOverviewData',
      request,
      metadata || {},
      methodDescriptor_TaskOverviewService_GetTaskOverviewData);
};


module.exports = proto.overviewTask;

