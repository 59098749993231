import React from 'react';
import { useTranslation } from 'react-i18next';
import { buttonIcon, flexRow, iconButton, } from '../../../assets/soter-flex-styles';
import arrowLeftEnabled from '../../../assets/img/icons/chevron-left-enabled.svg';
import arrowRightEnabled from '../../../assets/img/icons/chevron-right-enabled.svg';
import arrowLeftDisabled from '../../../assets/img/icons/chevron-left-disabled.svg';
import arrowRightDisabled from '../../../assets/img/icons/chevron-right-disabled.svg';
import CustomTooltip from '../../../lib/ui/Tooltip';

const ArrowBackForwardSwitch = ({
  couldStepBack,
  couldStepForward,
  onStepBack,
  onStepForward,
  hazardsChart = false,
}) => {
  const { t } = useTranslation();
  const rightBtn = (
    <button
      type='button'
      className={iconButton}
      style={{
        marginLeft: "6px",
      }}
      disabled={!couldStepForward && !hazardsChart}
      onClick={couldStepForward ? onStepForward : null}
    >
      <span className={buttonIcon}>
        <img
          src={couldStepForward ? arrowRightEnabled : arrowRightDisabled}
          alt='step forward'
        />
      </span>
    </button>
  );

  const button =
    couldStepForward || !hazardsChart ? (
      rightBtn
    ) : (
      <CustomTooltip
        placement='topEnd'
        text={t("WEARABLES.OVERVIEW.TOOLTIPS.NO_DATA")}
      >
        {rightBtn}
      </CustomTooltip>
    );

  return (
    <div
      className={flexRow}
      style={{
        flexWrap: "nowrap",
        width: "60px",
        marginLeft: "auto",
      }}
    >
      <button
        type='button'
        className={iconButton}
        style={{
          marginRight: "6px",
        }}
        disabled={!couldStepBack}
        onClick={onStepBack}
      >
        <span className={buttonIcon}>
          <img
            src={couldStepBack ? arrowLeftEnabled : arrowLeftDisabled}
            alt='step back'
          />
        </span>
      </button>
      {button}
    </div>
  );
};

export default ArrowBackForwardSwitch;
