import React from 'react';
import { cardLarge, mb2 } from 'assets/soter-flex-styles';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { text } from 'assets/jss/style';
import _map from 'lodash/map';
import legendSquare from 'assets/jss/components/legendSquare';
import { useTranslation } from 'react-i18next';
import Text from 'lib/ui/Text';
import { getRatingProps } from '../utils/getRatingProps';
import InfoTooltip from '../../../../lib/ui/InfoTooltip';
import { ChartTooltips } from '../../../overview/constants/constants';
import RatingInfo from './RatingInfo';
import RiskGroupBadge from '../../../../lib/ui/RiskGroupBadge/RiskGroupBadge';
import DonutChart from '../../../../task/video/components/DonutChart';

const header = Emotion.css`
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const body = Emotion.css`
  display: flex;
`;

export const legendSlot = Emotion.css`
  display: flex;
  justify-content: space-between;
`;

const legend = Emotion.css`
  width: 30%;
  margin: 60px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const hazardValue = Emotion.css`
  ${text(COLORS.GRAY[950], 16, 600)};

  width: 27px;
`;

const OverallHazardsCharts = ({
  improvements,
  currentChartData,
  startChartData,
  currentRiskGroup,
  startRiskGroup,
}) => {
  const { t } = useTranslation();
  const overallImprovement = getRatingProps(
    improvements?.averageHazardousMovementFrequencyChange,
  );
  return (
    <div className={cardLarge}>
      <div className={header}>
        <Text
          text={t('WEARABLES.OVERVIEW.OVERALL_IMPROVEMENT')}
          style={{ marginRight: '10px' }}
          className={text(COLORS.GRAY[400], 20)}
        />
        <div style={{ marginRight: '20px' }}>
          <InfoTooltip text={ChartTooltips.AVG_HM_H} />
        </div>
        <RatingInfo ratingProps={overallImprovement} />
      </div>
      <div className={body}>
        <div
          style={{
            marginLeft: '40px',
            marginBottom: '25px',
            marginTop: '10px',
          }}
        >
          <Text
            text={`${t('WEARABLES.USER_PAGE.OVERALL_HAZARDS')} - ${t(
              'GENERAL.START',
            )}`.toUpperCase()}
            className={`${text(COLORS.GRAY[400], 20)} ${mb2}`}
          />
          <RiskGroupBadge
            mediumSize
            riskGroup={startRiskGroup}
            showTooltip={false}
          />
          <div style={{ paddingLeft: '30px' }}>
            <DonutChart
              text={t('WEARABLES.OVERVIEW.OVERALL')}
              height={244}
              width={244}
              data={startChartData.chartData}
              totalTime={startChartData.overall.toFixed(1)}
              innerRadius={104}
              outerRadius={122}
            />
          </div>
        </div>
        <div className={legend}>
          {_map(startChartData.overallHazardsForCurrentType, (val, key) => {
            const currentHazardPresentation = startChartData.hazardsColors.find(
              ({ alias }) => alias === key,
            );
            const currentValue
              = currentChartData.overallHazardsForCurrentType[key];
            return (
              <div key={key} className={legendSlot}>
                <Text
                  text={
                    val % Math.round(val) === 0 || val === 0
                      ? val
                      : val.toFixed(1)
                  }
                  className={hazardValue}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '180px',
                  }}
                >
                  <div
                    className={legendSquare(currentHazardPresentation.color)}
                  />
                  <Text text={t(currentHazardPresentation.name)} />
                </div>
                <Text
                  text={
                    currentValue % Math.round(currentValue) === 0
                    || currentValue === 0
                      ? currentValue
                      : currentValue.toFixed(1)
                  }
                  className={hazardValue}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            marginRight: '40px',
            marginBottom: '25px',
            marginTop: '10px',
          }}
        >
          <Text
            text={`${t('WEARABLES.USER_PAGE.OVERALL_HAZARDS')} - ${t(
              'GENERAL.CURRENT',
            )}`.toUpperCase()}
            className={`${text(COLORS.GRAY[400], 20)} ${mb2}`}
          />
          <RiskGroupBadge
            riskGroup={currentRiskGroup}
            mediumSize
            showTooltip={false}
          />
          <div style={{ paddingLeft: '50px' }}>
            <DonutChart
              text={t('WEARABLES.OVERVIEW.OVERALL')}
              height={244}
              width={244}
              data={currentChartData.chartData}
              totalTime={currentChartData.overall.toFixed(1)}
              innerRadius={104}
              outerRadius={122}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallHazardsCharts;
