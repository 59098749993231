import capitalizeFirstLetter from '../../../lib/utils/capitalizeFirstLetter';
export const usersParametersSetter = (users, paramToAdd, elemsToSet, idToSet, userId) => {
    const userToChange = users.find((user) => Number(user.id) === Number(userId));
    const userToChangIndex = users.indexOf(userToChange);
    const elemToAdd = elemsToSet.find(({ id }) => Number(id) === Number(idToSet));
    const renewedUserForSet = Object.assign(Object.assign({}, userToChange), { [paramToAdd]: {
            id: elemToAdd === null || elemToAdd === void 0 ? void 0 : elemToAdd.id,
            name: elemToAdd === null || elemToAdd === void 0 ? void 0 : elemToAdd.title,
        } });
    return {
        [`userForChange${capitalizeFirstLetter(paramToAdd)}`]: userToChangIndex,
        [`renewedUserForSet${capitalizeFirstLetter(paramToAdd)}`]: renewedUserForSet,
    };
};
