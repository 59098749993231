import * as Emotion from 'emotion';

const HEIGHT = 56;

export const staticwrapper = (height = HEIGHT) => Emotion.css`
  height: ${height}px;
`;

export const innerWrapper = (height = HEIGHT) => Emotion.css`
  width: 100%;
  height: ${height}px;
  border-bottom: 1px solid transparent;
`;

export const sticky = Emotion.css`
  position: fixed;
  top: 65px;
  z-index: 7;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom-color: rgb(224, 229, 236);
`;
