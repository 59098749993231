import { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const ValidateAsyncOnChange = ({ validate, formik }) => {
  useEffect(() => {
    let isCurrent = true;
    validate(formik.values).then((errors) => {
      if (isCurrent && formik.dirty) formik.setErrors(errors);
    });
    return () => {
      isCurrent = false;
    };
  }, [formik.values, formik.error]);
  return null;
};
