import React from 'react';
import _chunk from 'lodash/chunk';
import moment from 'moment';
import { text } from 'assets/jss/style';
import { useTranslation } from 'react-i18next';
import { COLORS, CHART_GREY, CHART_COLORS_SPINE } from 'constants/colors';
import getBarHeightCoefficient
  from 'containers/PdfContainer/utils/getBarHeightCoefficient';
import splitNumberByQuoter from 'containers/PdfContainer/utils/splitNumberByQuoter';
import SinglePage from 'containers/PdfContainer/SinglePage';
import BarChartWrapper from 'containers/PdfContainer/components/BarChartWrapper';
import hexToRgba from 'lib/utils/hexToRgba';
import sliceStringWithoutWordsCutting from 'lib/utils/sliceStringWithoutWordsCutting';
import {
  CHART_CANVAS_HEIGHT,
  CHART_HEIGHT_GAP,
  CHART_SIDE_GAP,
  DATE_LONG_FORMAT,
  DATE_SHORT_FORMAT,
  PIXEL_COEFFICIENT,
  SINGLE_PAGE_TYPE,
  SPLIT_CHART_BAR_WIDTH,
  SPLIT_CHART_SLOT_WIDTH,
  USER_CHARTS_STAGE_HEIGHT_MM,
  USER_CHARTS_STAGE_WIDTH_MM,
  USER_CHARTS_WORK_WIDTH_MM,
} from '../../../containers/PdfContainer/constants';
import getCurrentHazardsList from '../../overview/utils/getCurrentHazardsList';
import getHazardRatioChartBlockWidths from './getHazardsRatioChartBlockWidths';
import getUserChartMaximums from './getUserChartMaximums';
import UserReportHeader from './components/UserReportHeader';
import UserReportSubheader from './components/UserReportSubheader';
import UserReportProgram from './components/UserReportProgram';
import OverallData from './components/OverallData';

const UserPdfContainer = ({
  user,
  isGrayScale,
  currentProgram,
  timezone,
  date,
}) => {
  const { t } = useTranslation();
  const colorMap = isGrayScale ? CHART_GREY : CHART_COLORS_SPINE;
  const {
    firstName, lastName, jobTitle, department,
  } = user;
  const { hazardsByDayList, deviceType } = currentProgram;
  const hazardsList = getCurrentHazardsList(deviceType);
  const programLength = hazardsByDayList?.length;
  const [ratioSlotWidth, ratioBarWidth]
    = getHazardRatioChartBlockWidths(programLength);
  const hazardsSlotWidth = SPLIT_CHART_SLOT_WIDTH;
  const hazardsBarWidth = SPLIT_CHART_BAR_WIDTH;
  const [ratioChartMax, hazardsChartMax] = getUserChartMaximums(
    hazardsByDayList,
    hazardsList,
  );
  const chartHeight = CHART_CANVAS_HEIGHT * PIXEL_COEFFICIENT;
  const hazardBarHeightCoefficient = getBarHeightCoefficient(
    hazardsChartMax,
    true,
  );
  const hazardsChartDomain = splitNumberByQuoter(
    hazardsChartMax + CHART_HEIGHT_GAP,
  ).reverse();
  const xAxisPoints = splitNumberByQuoter(chartHeight);
  const stageWidth = USER_CHARTS_STAGE_WIDTH_MM * PIXEL_COEFFICIENT;
  const chartWidth = USER_CHARTS_WORK_WIDTH_MM * PIXEL_COEFFICIENT;
  const stageHeight = USER_CHARTS_STAGE_HEIGHT_MM * PIXEL_COEFFICIENT;
  const chunkedHazardsByDayList = _chunk(hazardsByDayList, 10);
  const axisData = (domainValues) => ({
    bottom: {
      x: 0,
      y: chartHeight,
      width: chartWidth + 100,
    },
    doted: {
      x: CHART_SIDE_GAP + 2,
      width: stageWidth + 10,
      points: xAxisPoints,
      domainValues,
    },
  });
  const passedPrograms = user?.pastProgramsList;
  const hasPassedPrograms = !!passedPrograms?.length;
  const hazardsChartData = (hazardsListSlice) => hazardsListSlice.map((hazard, index) => {
    const position = hazardsSlotWidth * index + ratioBarWidth;
    const slotParameters = {
      background: index % 2 === 0 && hexToRgba(COLORS.GRAY[400], 0.2),
      x: position + 2 * PIXEL_COEFFICIENT - (programLength > 10 ? 0 : 9),
      y: 0,
      height: chartHeight,
      width: hazardsSlotWidth,
    };
    const textParameters = {
      x: position + 8 * PIXEL_COEFFICIENT - (programLength > 10 ? 0 : 9),
      y: chartHeight + PIXEL_COEFFICIENT,
      size: 10,
      text: moment(hazard.timeStart).format(
        programLength > 10 ? DATE_SHORT_FORMAT : DATE_LONG_FORMAT,
      ),
    };
    const bars = hazardsList.map((h, i) => {
      const barHeight = hazard[h.key] * hazardBarHeightCoefficient;
      const subPosition
          = index * hazardsSlotWidth
          + i * (hazardsBarWidth * 1.7)
          + 11 * PIXEL_COEFFICIENT;
        // eslint-disable-next-line no-shadow
      const textParameters = {
        x: subPosition,
        y: chartHeight - barHeight - 9,
        size: 8,
        text: hazard[h.key].toFixed(0),
      };
      const rectParameters = [
        {
          x: subPosition,
          y: chartHeight - barHeight,
          width: hazardsBarWidth,
          height: barHeight,
          color: colorMap[h.colorIndex],
          radius: 3,
        },
      ];
      return { rectParameters, textParameters };
    });
    return { slotParameters, textParameters, bars };
  });
  return (
    <div
      id={`user_report_${currentProgram.id}`}
      style={{
        display: 'none',
      }}
    >
      <SinglePage type={SINGLE_PAGE_TYPE.HORIZONTAL} isGrayScale={isGrayScale}>
        <div style={{ marginTop: hasPassedPrograms ? '15mm' : null }}>
          <UserReportHeader
            isGrayScale={isGrayScale}
            user={user}
            currentProgram={currentProgram}
          />
          <UserReportProgram
            timezone={timezone}
            isGrayScale={isGrayScale}
            user={user}
            currentProgram={currentProgram}
          />
          <OverallData currentProgram={currentProgram} />
        </div>
        {!hasPassedPrograms && (
        <BarChartWrapper
          width={stageWidth}
          height={stageHeight}
          header={t('WEARABLES.OVERVIEW.HAZARDS_PER_DAY')}
          axisData={axisData(hazardsChartDomain)}
          data={hazardsChartData(chunkedHazardsByDayList[0])}
        />
        )}
      </SinglePage>
      {(hasPassedPrograms || chunkedHazardsByDayList.length > 1) &&
      chunkedHazardsByDayList.slice(hasPassedPrograms ? 0 : 1).map(
        (hazardsChunk, ind, arr) => ind % 2 === 0 && (
        <SinglePage
          type={SINGLE_PAGE_TYPE.HORIZONTAL}
          isGrayScale={isGrayScale}
          key={t('WEARABLES.OVERVIEW.HAZARDS_PER_DAY') + Math.random()}
        >
          <UserReportSubheader
            firstName={firstName}
            lastName={lastName}
            department={department}
            jobTitle={jobTitle}
          />
          <BarChartWrapper
            width={stageWidth}
            height={stageHeight}
            header={t('WEARABLES.OVERVIEW.HAZARDS_PER_DAY')}
            axisData={axisData(hazardsChartDomain)}
            data={hazardsChartData(hazardsChunk)}
            withGap
          />
          {arr[ind + 1] && (
          <BarChartWrapper
            width={stageWidth}
            height={stageHeight}
            axisData={axisData(hazardsChartDomain)}
            data={hazardsChartData(arr[ind + 1])}
          />
          )}
        </SinglePage>
        ),
      )}
      {user.note?.text.trim() ? sliceStringWithoutWordsCutting(user.note?.text, 2820)
        .map((slicedTex) => (
          <SinglePage
            type={SINGLE_PAGE_TYPE.HORIZONTAL}
            isGrayScale={isGrayScale}
            key={slicedTex.slice(0, 3)}
          >
            <UserReportHeader
              isGrayScale={isGrayScale}
              user={user}
              currentProgram={currentProgram}
            />
            <UserReportProgram
              timezone={timezone}
              isGrayScale={isGrayScale}
              user={user}
              currentProgram={currentProgram}
            />
            <h2 className={text(COLORS.GRAY[400], 14)}>
              {t('GENERAL.NOTE').toUpperCase()}
            </h2>
            <div>{slicedTex}</div>
          </SinglePage>
        )) : null}
    </div>
  );
};

export default UserPdfContainer;
