/**
 * @fileoverview gRPC-Web generated client stub for videoDashboardTask
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/soter-task/dashboard/v1/assessmet_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_soter$task_common_v1_assessment_message_pb = require('../../../../api/soter-task/common/v1/assessment_message_pb.js')
const proto = {};
proto.videoDashboardTask = require('./assessmet_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.videoDashboardTask.TaskAssessmentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.videoDashboardTask.TaskAssessmentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.assessmentTask.GetAssessmentsForVideoRequest,
 *   !proto.assessmentTask.AssessmentsResponse>}
 */
const methodDescriptor_TaskAssessmentService_GetAssessmentsForVideo = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskAssessmentService/GetAssessmentsForVideo',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_assessment_message_pb.GetAssessmentsForVideoRequest,
  api_soter$task_common_v1_assessment_message_pb.AssessmentsResponse,
  /**
   * @param {!proto.assessmentTask.GetAssessmentsForVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_assessment_message_pb.AssessmentsResponse.deserializeBinary
);


/**
 * @param {!proto.assessmentTask.GetAssessmentsForVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.assessmentTask.AssessmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.assessmentTask.AssessmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskAssessmentServiceClient.prototype.getAssessmentsForVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/GetAssessmentsForVideo',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_GetAssessmentsForVideo,
      callback);
};


/**
 * @param {!proto.assessmentTask.GetAssessmentsForVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.assessmentTask.AssessmentsResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskAssessmentServicePromiseClient.prototype.getAssessmentsForVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/GetAssessmentsForVideo',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_GetAssessmentsForVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.assessmentTask.CreateAssessmentRequest,
 *   !proto.assessmentTask.AssessmentResponse>}
 */
const methodDescriptor_TaskAssessmentService_CreateAssessment = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskAssessmentService/CreateAssessment',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_assessment_message_pb.CreateAssessmentRequest,
  api_soter$task_common_v1_assessment_message_pb.AssessmentResponse,
  /**
   * @param {!proto.assessmentTask.CreateAssessmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_assessment_message_pb.AssessmentResponse.deserializeBinary
);


/**
 * @param {!proto.assessmentTask.CreateAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.assessmentTask.AssessmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.assessmentTask.AssessmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskAssessmentServiceClient.prototype.createAssessment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/CreateAssessment',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_CreateAssessment,
      callback);
};


/**
 * @param {!proto.assessmentTask.CreateAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.assessmentTask.AssessmentResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskAssessmentServicePromiseClient.prototype.createAssessment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/CreateAssessment',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_CreateAssessment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.assessmentTask.UpdateAssessmentRequest,
 *   !proto.assessmentTask.AssessmentResponse>}
 */
const methodDescriptor_TaskAssessmentService_UpdateAssessment = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskAssessmentService/UpdateAssessment',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_assessment_message_pb.UpdateAssessmentRequest,
  api_soter$task_common_v1_assessment_message_pb.AssessmentResponse,
  /**
   * @param {!proto.assessmentTask.UpdateAssessmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_assessment_message_pb.AssessmentResponse.deserializeBinary
);


/**
 * @param {!proto.assessmentTask.UpdateAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.assessmentTask.AssessmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.assessmentTask.AssessmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskAssessmentServiceClient.prototype.updateAssessment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/UpdateAssessment',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_UpdateAssessment,
      callback);
};


/**
 * @param {!proto.assessmentTask.UpdateAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.assessmentTask.AssessmentResponse>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskAssessmentServicePromiseClient.prototype.updateAssessment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/UpdateAssessment',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_UpdateAssessment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.assessmentTask.DeleteAssessmentRequest,
 *   !proto.assessmentTask.AssessmentStatus>}
 */
const methodDescriptor_TaskAssessmentService_DeleteAssessment = new grpc.web.MethodDescriptor(
  '/videoDashboardTask.TaskAssessmentService/DeleteAssessment',
  grpc.web.MethodType.UNARY,
  api_soter$task_common_v1_assessment_message_pb.DeleteAssessmentRequest,
  api_soter$task_common_v1_assessment_message_pb.AssessmentStatus,
  /**
   * @param {!proto.assessmentTask.DeleteAssessmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_soter$task_common_v1_assessment_message_pb.AssessmentStatus.deserializeBinary
);


/**
 * @param {!proto.assessmentTask.DeleteAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.assessmentTask.AssessmentStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.assessmentTask.AssessmentStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.videoDashboardTask.TaskAssessmentServiceClient.prototype.deleteAssessment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/DeleteAssessment',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_DeleteAssessment,
      callback);
};


/**
 * @param {!proto.assessmentTask.DeleteAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.assessmentTask.AssessmentStatus>}
 *     Promise that resolves to the response
 */
proto.videoDashboardTask.TaskAssessmentServicePromiseClient.prototype.deleteAssessment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/videoDashboardTask.TaskAssessmentService/DeleteAssessment',
      request,
      metadata || {},
      methodDescriptor_TaskAssessmentService_DeleteAssessment);
};


module.exports = proto.videoDashboardTask;

