// source: api/soter-task/common/v1/auth_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.authTask.CheckEmailDashboardUserRequest', null, global);
goog.exportSymbol('proto.authTask.CheckEmailResponse', null, global);
goog.exportSymbol('proto.authTask.Company', null, global);
goog.exportSymbol('proto.authTask.CompanyProduct', null, global);
goog.exportSymbol('proto.authTask.ConfirmEmailRequest', null, global);
goog.exportSymbol('proto.authTask.CreateTaskDashboardUserRequest', null, global);
goog.exportSymbol('proto.authTask.Department', null, global);
goog.exportSymbol('proto.authTask.LoginTaskRequest', null, global);
goog.exportSymbol('proto.authTask.LoginTaskResponse', null, global);
goog.exportSymbol('proto.authTask.ProductStatus', null, global);
goog.exportSymbol('proto.authTask.ResendConfirmationRequest', null, global);
goog.exportSymbol('proto.authTask.ResendConfirmationResponse', null, global);
goog.exportSymbol('proto.authTask.ResetPasswordRequest', null, global);
goog.exportSymbol('proto.authTask.ResetPasswordResponse', null, global);
goog.exportSymbol('proto.authTask.Site', null, global);
goog.exportSymbol('proto.authTask.TaskCheckEmailStatus', null, global);
goog.exportSymbol('proto.authTask.TaskLimitProduct', null, global);
goog.exportSymbol('proto.authTask.TaskProduct', null, global);
goog.exportSymbol('proto.authTask.TaskProfile', null, global);
goog.exportSymbol('proto.authTask.TaskVideoLimit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.LoginTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.LoginTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.LoginTaskRequest.displayName = 'proto.authTask.LoginTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.TaskProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authTask.TaskProfile.repeatedFields_, null);
};
goog.inherits(proto.authTask.TaskProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.TaskProfile.displayName = 'proto.authTask.TaskProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authTask.Company.repeatedFields_, null);
};
goog.inherits(proto.authTask.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.Company.displayName = 'proto.authTask.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.TaskVideoLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.TaskVideoLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.TaskVideoLimit.displayName = 'proto.authTask.TaskVideoLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.LoginTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.LoginTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.LoginTaskResponse.displayName = 'proto.authTask.LoginTaskResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.CheckEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.CheckEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.CheckEmailResponse.displayName = 'proto.authTask.CheckEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.CheckEmailDashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.CheckEmailDashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.CheckEmailDashboardUserRequest.displayName = 'proto.authTask.CheckEmailDashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.CreateTaskDashboardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.CreateTaskDashboardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.CreateTaskDashboardUserRequest.displayName = 'proto.authTask.CreateTaskDashboardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.ResetPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.ResetPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.ResetPasswordRequest.displayName = 'proto.authTask.ResetPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.ResetPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.ResetPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.ResetPasswordResponse.displayName = 'proto.authTask.ResetPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.CompanyProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.CompanyProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.CompanyProduct.displayName = 'proto.authTask.CompanyProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.TaskProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.TaskProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.TaskProduct.displayName = 'proto.authTask.TaskProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.TaskLimitProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.TaskLimitProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.TaskLimitProduct.displayName = 'proto.authTask.TaskLimitProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.ConfirmEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.ConfirmEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.ConfirmEmailRequest.displayName = 'proto.authTask.ConfirmEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.ResendConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.ResendConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.ResendConfirmationRequest.displayName = 'proto.authTask.ResendConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.ResendConfirmationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.ResendConfirmationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.ResendConfirmationResponse.displayName = 'proto.authTask.ResendConfirmationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.Department = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.Department, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.Department.displayName = 'proto.authTask.Department';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authTask.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authTask.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authTask.Site.displayName = 'proto.authTask.Site';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.LoginTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.LoginTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.LoginTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.LoginTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.LoginTaskRequest}
 */
proto.authTask.LoginTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.LoginTaskRequest;
  return proto.authTask.LoginTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.LoginTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.LoginTaskRequest}
 */
proto.authTask.LoginTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.LoginTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.LoginTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.LoginTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.LoginTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.authTask.LoginTaskRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.LoginTaskRequest} returns this
 */
proto.authTask.LoginTaskRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.authTask.LoginTaskRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.LoginTaskRequest} returns this
 */
proto.authTask.LoginTaskRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authTask.TaskProfile.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.TaskProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.TaskProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.TaskProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resellerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    canDeactivateUsers: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    canDeactivateDevices: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    subscriptionManager: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    role: jspb.Message.getFieldWithDefault(msg, 15, ""),
    company: jspb.Message.getFieldWithDefault(msg, 16, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 17, ""),
    taskVideoLimit: (f = msg.getTaskVideoLimit()) && proto.authTask.TaskVideoLimit.toObject(includeInstance, f),
    products: (f = msg.getProducts()) && proto.authTask.CompanyProduct.toObject(includeInstance, f),
    companiesList: jspb.Message.toObjectList(msg.getCompaniesList(),
    proto.authTask.Company.toObject, includeInstance),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 21, ""),
    emailConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 22, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.TaskProfile}
 */
proto.authTask.TaskProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.TaskProfile;
  return proto.authTask.TaskProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.TaskProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.TaskProfile}
 */
proto.authTask.TaskProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResellerId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeactivateUsers(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeactivateDevices(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriptionManager(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 18:
      var value = new proto.authTask.TaskVideoLimit;
      reader.readMessage(value,proto.authTask.TaskVideoLimit.deserializeBinaryFromReader);
      msg.setTaskVideoLimit(value);
      break;
    case 19:
      var value = new proto.authTask.CompanyProduct;
      reader.readMessage(value,proto.authTask.CompanyProduct.deserializeBinaryFromReader);
      msg.setProducts(value);
      break;
    case 20:
      var value = new proto.authTask.Company;
      reader.readMessage(value,proto.authTask.Company.deserializeBinaryFromReader);
      msg.addCompanies(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailConfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.TaskProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.TaskProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.TaskProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResellerId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCanDeactivateUsers();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCanDeactivateDevices();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSubscriptionManager();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTaskVideoLimit();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.authTask.TaskVideoLimit.serializeBinaryToWriter
    );
  }
  f = message.getProducts();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.authTask.CompanyProduct.serializeBinaryToWriter
    );
  }
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.authTask.Company.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getEmailConfirmed();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.authTask.TaskProfile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 reseller_id = 5;
 * @return {number}
 */
proto.authTask.TaskProfile.prototype.getResellerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setResellerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.authTask.TaskProfile.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_active = 11;
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool can_deactivate_users = 12;
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.getCanDeactivateUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setCanDeactivateUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool can_deactivate_devices = 13;
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.getCanDeactivateDevices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setCanDeactivateDevices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool subscription_manager = 14;
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.getSubscriptionManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setSubscriptionManager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string role = 15;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string company = 16;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string job_title = 17;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional TaskVideoLimit task_video_limit = 18;
 * @return {?proto.authTask.TaskVideoLimit}
 */
proto.authTask.TaskProfile.prototype.getTaskVideoLimit = function() {
  return /** @type{?proto.authTask.TaskVideoLimit} */ (
    jspb.Message.getWrapperField(this, proto.authTask.TaskVideoLimit, 18));
};


/**
 * @param {?proto.authTask.TaskVideoLimit|undefined} value
 * @return {!proto.authTask.TaskProfile} returns this
*/
proto.authTask.TaskProfile.prototype.setTaskVideoLimit = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.clearTaskVideoLimit = function() {
  return this.setTaskVideoLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.hasTaskVideoLimit = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional CompanyProduct products = 19;
 * @return {?proto.authTask.CompanyProduct}
 */
proto.authTask.TaskProfile.prototype.getProducts = function() {
  return /** @type{?proto.authTask.CompanyProduct} */ (
    jspb.Message.getWrapperField(this, proto.authTask.CompanyProduct, 19));
};


/**
 * @param {?proto.authTask.CompanyProduct|undefined} value
 * @return {!proto.authTask.TaskProfile} returns this
*/
proto.authTask.TaskProfile.prototype.setProducts = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.clearProducts = function() {
  return this.setProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.hasProducts = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated Company companies = 20;
 * @return {!Array<!proto.authTask.Company>}
 */
proto.authTask.TaskProfile.prototype.getCompaniesList = function() {
  return /** @type{!Array<!proto.authTask.Company>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authTask.Company, 20));
};


/**
 * @param {!Array<!proto.authTask.Company>} value
 * @return {!proto.authTask.TaskProfile} returns this
*/
proto.authTask.TaskProfile.prototype.setCompaniesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.authTask.Company=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authTask.Company}
 */
proto.authTask.TaskProfile.prototype.addCompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.authTask.Company, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};


/**
 * optional string phone_number = 21;
 * @return {string}
 */
proto.authTask.TaskProfile.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool email_confirmed = 22;
 * @return {boolean}
 */
proto.authTask.TaskProfile.prototype.getEmailConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.TaskProfile} returns this
 */
proto.authTask.TaskProfile.prototype.setEmailConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authTask.Company.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    proto.authTask.Department.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.authTask.Site.toObject, includeInstance),
    products: (f = msg.getProducts()) && proto.authTask.CompanyProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.Company}
 */
proto.authTask.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.Company;
  return proto.authTask.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.Company}
 */
proto.authTask.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.authTask.Department;
      reader.readMessage(value,proto.authTask.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 4:
      var value = new proto.authTask.Site;
      reader.readMessage(value,proto.authTask.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 19:
      var value = new proto.authTask.CompanyProduct;
      reader.readMessage(value,proto.authTask.CompanyProduct.deserializeBinaryFromReader);
      msg.setProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.authTask.Department.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.authTask.Site.serializeBinaryToWriter
    );
  }
  f = message.getProducts();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.authTask.CompanyProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.authTask.Company.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.Company} returns this
 */
proto.authTask.Company.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.authTask.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.Company} returns this
 */
proto.authTask.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Department departments = 3;
 * @return {!Array<!proto.authTask.Department>}
 */
proto.authTask.Company.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.authTask.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authTask.Department, 3));
};


/**
 * @param {!Array<!proto.authTask.Department>} value
 * @return {!proto.authTask.Company} returns this
*/
proto.authTask.Company.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.authTask.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authTask.Department}
 */
proto.authTask.Company.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.authTask.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authTask.Company} returns this
 */
proto.authTask.Company.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * repeated Site sites = 4;
 * @return {!Array<!proto.authTask.Site>}
 */
proto.authTask.Company.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.authTask.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authTask.Site, 4));
};


/**
 * @param {!Array<!proto.authTask.Site>} value
 * @return {!proto.authTask.Company} returns this
*/
proto.authTask.Company.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.authTask.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authTask.Site}
 */
proto.authTask.Company.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.authTask.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authTask.Company} returns this
 */
proto.authTask.Company.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional CompanyProduct products = 19;
 * @return {?proto.authTask.CompanyProduct}
 */
proto.authTask.Company.prototype.getProducts = function() {
  return /** @type{?proto.authTask.CompanyProduct} */ (
    jspb.Message.getWrapperField(this, proto.authTask.CompanyProduct, 19));
};


/**
 * @param {?proto.authTask.CompanyProduct|undefined} value
 * @return {!proto.authTask.Company} returns this
*/
proto.authTask.Company.prototype.setProducts = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authTask.Company} returns this
 */
proto.authTask.Company.prototype.clearProducts = function() {
  return this.setProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authTask.Company.prototype.hasProducts = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.TaskVideoLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.TaskVideoLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.TaskVideoLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskVideoLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoFileSizeMbLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    videoRecordingTimeSecondsLimit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    videoPerBillingCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    videoPerBillingLeft: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.TaskVideoLimit}
 */
proto.authTask.TaskVideoLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.TaskVideoLimit;
  return proto.authTask.TaskVideoLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.TaskVideoLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.TaskVideoLimit}
 */
proto.authTask.TaskVideoLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoFileSizeMbLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoRecordingTimeSecondsLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoPerBillingCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoPerBillingLeft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.TaskVideoLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.TaskVideoLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.TaskVideoLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskVideoLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoFileSizeMbLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVideoRecordingTimeSecondsLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getVideoPerBillingCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getVideoPerBillingLeft();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 video_file_size_mb_limit = 1;
 * @return {number}
 */
proto.authTask.TaskVideoLimit.prototype.getVideoFileSizeMbLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskVideoLimit} returns this
 */
proto.authTask.TaskVideoLimit.prototype.setVideoFileSizeMbLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 video_recording_time_seconds_limit = 2;
 * @return {number}
 */
proto.authTask.TaskVideoLimit.prototype.getVideoRecordingTimeSecondsLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskVideoLimit} returns this
 */
proto.authTask.TaskVideoLimit.prototype.setVideoRecordingTimeSecondsLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 video_per_billing_count = 3;
 * @return {number}
 */
proto.authTask.TaskVideoLimit.prototype.getVideoPerBillingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskVideoLimit} returns this
 */
proto.authTask.TaskVideoLimit.prototype.setVideoPerBillingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 video_per_billing_left = 4;
 * @return {number}
 */
proto.authTask.TaskVideoLimit.prototype.getVideoPerBillingLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskVideoLimit} returns this
 */
proto.authTask.TaskVideoLimit.prototype.setVideoPerBillingLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.LoginTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.LoginTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.LoginTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.LoginTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profile: (f = msg.getProfile()) && proto.authTask.TaskProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.LoginTaskResponse}
 */
proto.authTask.LoginTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.LoginTaskResponse;
  return proto.authTask.LoginTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.LoginTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.LoginTaskResponse}
 */
proto.authTask.LoginTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = new proto.authTask.TaskProfile;
      reader.readMessage(value,proto.authTask.TaskProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.LoginTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.LoginTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.LoginTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.LoginTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authTask.TaskProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.authTask.LoginTaskResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.LoginTaskResponse} returns this
 */
proto.authTask.LoginTaskResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TaskProfile profile = 2;
 * @return {?proto.authTask.TaskProfile}
 */
proto.authTask.LoginTaskResponse.prototype.getProfile = function() {
  return /** @type{?proto.authTask.TaskProfile} */ (
    jspb.Message.getWrapperField(this, proto.authTask.TaskProfile, 2));
};


/**
 * @param {?proto.authTask.TaskProfile|undefined} value
 * @return {!proto.authTask.LoginTaskResponse} returns this
*/
proto.authTask.LoginTaskResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authTask.LoginTaskResponse} returns this
 */
proto.authTask.LoginTaskResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authTask.LoginTaskResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.CheckEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.CheckEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.CheckEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CheckEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.CheckEmailResponse}
 */
proto.authTask.CheckEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.CheckEmailResponse;
  return proto.authTask.CheckEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.CheckEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.CheckEmailResponse}
 */
proto.authTask.CheckEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.authTask.TaskCheckEmailStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.CheckEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.CheckEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.CheckEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CheckEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional TaskCheckEmailStatus status = 1;
 * @return {!proto.authTask.TaskCheckEmailStatus}
 */
proto.authTask.CheckEmailResponse.prototype.getStatus = function() {
  return /** @type {!proto.authTask.TaskCheckEmailStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.authTask.TaskCheckEmailStatus} value
 * @return {!proto.authTask.CheckEmailResponse} returns this
 */
proto.authTask.CheckEmailResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.CheckEmailDashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.CheckEmailDashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.CheckEmailDashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CheckEmailDashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.CheckEmailDashboardUserRequest}
 */
proto.authTask.CheckEmailDashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.CheckEmailDashboardUserRequest;
  return proto.authTask.CheckEmailDashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.CheckEmailDashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.CheckEmailDashboardUserRequest}
 */
proto.authTask.CheckEmailDashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.CheckEmailDashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.CheckEmailDashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.CheckEmailDashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CheckEmailDashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.authTask.CheckEmailDashboardUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CheckEmailDashboardUserRequest} returns this
 */
proto.authTask.CheckEmailDashboardUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.CreateTaskDashboardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.CreateTaskDashboardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CreateTaskDashboardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    company: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.CreateTaskDashboardUserRequest}
 */
proto.authTask.CreateTaskDashboardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.CreateTaskDashboardUserRequest;
  return proto.authTask.CreateTaskDashboardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.CreateTaskDashboardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.CreateTaskDashboardUserRequest}
 */
proto.authTask.CreateTaskDashboardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.CreateTaskDashboardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.CreateTaskDashboardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CreateTaskDashboardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string company = 5;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string job_title = 6;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone_number = 7;
 * @return {string}
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.CreateTaskDashboardUserRequest} returns this
 */
proto.authTask.CreateTaskDashboardUserRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.ResetPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.ResetPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.ResetPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResetPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.ResetPasswordRequest}
 */
proto.authTask.ResetPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.ResetPasswordRequest;
  return proto.authTask.ResetPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.ResetPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.ResetPasswordRequest}
 */
proto.authTask.ResetPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.ResetPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.ResetPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.ResetPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResetPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.authTask.ResetPasswordRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.ResetPasswordRequest} returns this
 */
proto.authTask.ResetPasswordRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.ResetPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.ResetPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.ResetPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResetPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.ResetPasswordResponse}
 */
proto.authTask.ResetPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.ResetPasswordResponse;
  return proto.authTask.ResetPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.ResetPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.ResetPasswordResponse}
 */
proto.authTask.ResetPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.ResetPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.ResetPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.ResetPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResetPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.authTask.ResetPasswordResponse.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.ResetPasswordResponse} returns this
 */
proto.authTask.ResetPasswordResponse.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.CompanyProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.CompanyProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.CompanyProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CompanyProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskProduct: (f = msg.getTaskProduct()) && proto.authTask.TaskProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.CompanyProduct}
 */
proto.authTask.CompanyProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.CompanyProduct;
  return proto.authTask.CompanyProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.CompanyProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.CompanyProduct}
 */
proto.authTask.CompanyProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authTask.TaskProduct;
      reader.readMessage(value,proto.authTask.TaskProduct.deserializeBinaryFromReader);
      msg.setTaskProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.CompanyProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.CompanyProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.CompanyProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.CompanyProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authTask.TaskProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskProduct task_product = 1;
 * @return {?proto.authTask.TaskProduct}
 */
proto.authTask.CompanyProduct.prototype.getTaskProduct = function() {
  return /** @type{?proto.authTask.TaskProduct} */ (
    jspb.Message.getWrapperField(this, proto.authTask.TaskProduct, 1));
};


/**
 * @param {?proto.authTask.TaskProduct|undefined} value
 * @return {!proto.authTask.CompanyProduct} returns this
*/
proto.authTask.CompanyProduct.prototype.setTaskProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authTask.CompanyProduct} returns this
 */
proto.authTask.CompanyProduct.prototype.clearTaskProduct = function() {
  return this.setTaskProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authTask.CompanyProduct.prototype.hasTaskProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.TaskProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.TaskProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.TaskProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentTermStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentTermEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limits: (f = msg.getLimits()) && proto.authTask.TaskLimitProduct.toObject(includeInstance, f),
    disableVideoDownload: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.TaskProduct}
 */
proto.authTask.TaskProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.TaskProduct;
  return proto.authTask.TaskProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.TaskProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.TaskProduct}
 */
proto.authTask.TaskProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.authTask.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentTermEnd(value);
      break;
    case 4:
      var value = new proto.authTask.TaskLimitProduct;
      reader.readMessage(value,proto.authTask.TaskLimitProduct.deserializeBinaryFromReader);
      msg.setLimits(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableVideoDownload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.TaskProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.TaskProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.TaskProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentTermStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLimits();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.authTask.TaskLimitProduct.serializeBinaryToWriter
    );
  }
  f = message.getDisableVideoDownload();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional ProductStatus status = 1;
 * @return {!proto.authTask.ProductStatus}
 */
proto.authTask.TaskProduct.prototype.getStatus = function() {
  return /** @type {!proto.authTask.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.authTask.ProductStatus} value
 * @return {!proto.authTask.TaskProduct} returns this
 */
proto.authTask.TaskProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 current_term_start = 2;
 * @return {number}
 */
proto.authTask.TaskProduct.prototype.getCurrentTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskProduct} returns this
 */
proto.authTask.TaskProduct.prototype.setCurrentTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 current_term_end = 3;
 * @return {number}
 */
proto.authTask.TaskProduct.prototype.getCurrentTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskProduct} returns this
 */
proto.authTask.TaskProduct.prototype.setCurrentTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TaskLimitProduct limits = 4;
 * @return {?proto.authTask.TaskLimitProduct}
 */
proto.authTask.TaskProduct.prototype.getLimits = function() {
  return /** @type{?proto.authTask.TaskLimitProduct} */ (
    jspb.Message.getWrapperField(this, proto.authTask.TaskLimitProduct, 4));
};


/**
 * @param {?proto.authTask.TaskLimitProduct|undefined} value
 * @return {!proto.authTask.TaskProduct} returns this
*/
proto.authTask.TaskProduct.prototype.setLimits = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authTask.TaskProduct} returns this
 */
proto.authTask.TaskProduct.prototype.clearLimits = function() {
  return this.setLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authTask.TaskProduct.prototype.hasLimits = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool disable_video_download = 5;
 * @return {boolean}
 */
proto.authTask.TaskProduct.prototype.getDisableVideoDownload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.TaskProduct} returns this
 */
proto.authTask.TaskProduct.prototype.setDisableVideoDownload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.TaskLimitProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.TaskLimitProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.TaskLimitProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskLimitProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoFileSizeMbLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    videoRecordingTimeSecondsLimit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    videoPerBillingCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    videoPerBillingLeft: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.TaskLimitProduct}
 */
proto.authTask.TaskLimitProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.TaskLimitProduct;
  return proto.authTask.TaskLimitProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.TaskLimitProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.TaskLimitProduct}
 */
proto.authTask.TaskLimitProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoFileSizeMbLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoRecordingTimeSecondsLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoPerBillingCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoPerBillingLeft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.TaskLimitProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.TaskLimitProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.TaskLimitProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.TaskLimitProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoFileSizeMbLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVideoRecordingTimeSecondsLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getVideoPerBillingCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getVideoPerBillingLeft();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 video_file_size_mb_limit = 1;
 * @return {number}
 */
proto.authTask.TaskLimitProduct.prototype.getVideoFileSizeMbLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskLimitProduct} returns this
 */
proto.authTask.TaskLimitProduct.prototype.setVideoFileSizeMbLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 video_recording_time_seconds_limit = 2;
 * @return {number}
 */
proto.authTask.TaskLimitProduct.prototype.getVideoRecordingTimeSecondsLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskLimitProduct} returns this
 */
proto.authTask.TaskLimitProduct.prototype.setVideoRecordingTimeSecondsLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 video_per_billing_count = 3;
 * @return {number}
 */
proto.authTask.TaskLimitProduct.prototype.getVideoPerBillingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskLimitProduct} returns this
 */
proto.authTask.TaskLimitProduct.prototype.setVideoPerBillingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 video_per_billing_left = 4;
 * @return {number}
 */
proto.authTask.TaskLimitProduct.prototype.getVideoPerBillingLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.TaskLimitProduct} returns this
 */
proto.authTask.TaskLimitProduct.prototype.setVideoPerBillingLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.ConfirmEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.ConfirmEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.ConfirmEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ConfirmEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.ConfirmEmailRequest}
 */
proto.authTask.ConfirmEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.ConfirmEmailRequest;
  return proto.authTask.ConfirmEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.ConfirmEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.ConfirmEmailRequest}
 */
proto.authTask.ConfirmEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.ConfirmEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.ConfirmEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.ConfirmEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ConfirmEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.authTask.ConfirmEmailRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.ConfirmEmailRequest} returns this
 */
proto.authTask.ConfirmEmailRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.ResendConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.ResendConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.ResendConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResendConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.ResendConfirmationRequest}
 */
proto.authTask.ResendConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.ResendConfirmationRequest;
  return proto.authTask.ResendConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.ResendConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.ResendConfirmationRequest}
 */
proto.authTask.ResendConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.ResendConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.ResendConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.ResendConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResendConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.authTask.ResendConfirmationRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.ResendConfirmationRequest} returns this
 */
proto.authTask.ResendConfirmationRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.ResendConfirmationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.ResendConfirmationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.ResendConfirmationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResendConfirmationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.ResendConfirmationResponse}
 */
proto.authTask.ResendConfirmationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.ResendConfirmationResponse;
  return proto.authTask.ResendConfirmationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.ResendConfirmationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.ResendConfirmationResponse}
 */
proto.authTask.ResendConfirmationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.ResendConfirmationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.ResendConfirmationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.ResendConfirmationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.ResendConfirmationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.authTask.ResendConfirmationResponse.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authTask.ResendConfirmationResponse} returns this
 */
proto.authTask.ResendConfirmationResponse.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.Department.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.Department.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.Department} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.Department.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.Department}
 */
proto.authTask.Department.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.Department;
  return proto.authTask.Department.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.Department} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.Department}
 */
proto.authTask.Department.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.Department.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.Department.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.Department} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.Department.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.authTask.Department.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.Department} returns this
 */
proto.authTask.Department.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.authTask.Department.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.Department} returns this
 */
proto.authTask.Department.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authTask.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.authTask.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authTask.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authTask.Site}
 */
proto.authTask.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authTask.Site;
  return proto.authTask.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authTask.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authTask.Site}
 */
proto.authTask.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authTask.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authTask.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authTask.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authTask.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.authTask.Site.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authTask.Site} returns this
 */
proto.authTask.Site.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.authTask.Site.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authTask.Site} returns this
 */
proto.authTask.Site.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.authTask.TaskCheckEmailStatus = {
  NONE: 0,
  FREE: 1,
  NOTFREE: 2
};

/**
 * @enum {number}
 */
proto.authTask.ProductStatus = {
  PRODUCTSTATUSNONE: 0,
  PRODUCTSTATUSINTERNAL: 1,
  PRODUCTSTATUSNOTAVAILABLE: 2,
  PRODUCTSTATUSAVAILABLE: 3,
  PRODUCTSTATUSTRIALACTIVE: 4,
  PRODUCTSTATUSTRIALFINISHED: 5,
  PRODUCTSTATUSSUBSCRIPTIONACTIVE: 6,
  PRODUCTSTATUSSUBSCRIPTIONDUE: 7,
  PRODUCTSTATUSLIMITEXCEEDED: 8,
  PRODUCTSTATUSSUBSCRIPTIONFINISHED: 9
};

goog.object.extend(exports, proto.authTask);
