import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import * as rootReducer from './reducers';
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
const Root = ({ children }) => (
// @ts-ignore
React.createElement(Provider, { store: store }, children));
export default Root;
