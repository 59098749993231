import { COLORS } from 'constants/colors'
import * as Emotion from 'emotion';

export const pickerForTable = (fullWidth) => Emotion.css`
  margin-left: 10px;
  max-width: ${!fullWidth && '94px'};
  a {
    max-width: 140px;
    height: 38px !important;
  }
  span {
    color: ${COLORS.GRAY[400]} !important;
  }
`;
