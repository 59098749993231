import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'constants/colors';
import { Toggle } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { outlineColoredButton } from '../../../assets/soter-flex-styles';
import { setFilterValues } from '../filtersStore/filtersSlice';
import { TableType } from '../constants/tableType';
import { ACTIVITY_SHOW_PASSED_PROGRAMS_SWITCHER_CHANGED } from '../../../constants/analyticsEvents';
import { createEventNotification } from '../../NotificationContainer/notificationsStore/actions';

const PassedProgramSwitcher = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showPassedPrograms = useSelector(
    (state) => state.filtersNew.filters.coach?.showPassedPrograms,
  );
  return (
    <div
      style={{
        width: '200px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: COLORS.GRAY[400],
        fontSize: '12px',
        marginRight: '12px',
        fontWeight: 400,
        borderRadius: '4px',
      }}
      className={outlineColoredButton(COLORS.GRAY[100], 'small')}
    >
      <Toggle
        checked={showPassedPrograms}
        style={{ margin: 0 }}
        onChange={(v) => {
          // v ? addURLQueryParams(URLParams.passedPrograms, 'true') : removeParam(URLParams.passedPrograms);
          dispatch(
            setFilterValues({
              type: 'showPassedPrograms',
              values: v,
              pageType: TableType.coach,
            }),
          );
          const params = {
            is_passed_programs_shown: v,
          };
          window.logEvent(
            ACTIVITY_SHOW_PASSED_PROGRAMS_SWITCHER_CHANGED, params,
          );
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(`${ACTIVITY_SHOW_PASSED_PROGRAMS_SWITCHER_CHANGED}: ${JSON.stringify(params)}`));
          }
        }}
      />
      <span>{t('WEARABLES.ACTIVITY.SHOW_PASSED')}</span>
    </div>
  );
};

export default PassedProgramSwitcher;
