import * as Emotion from 'emotion';

const legendSquare = (color, isMinified = false) => Emotion.css`
  background-color: ${color};
  background-opacity: 0.75;
  width: ${isMinified ? '9px' : '12px'};
  height: ${isMinified ? '9px' : '12px'};
  border-radius: ${isMinified ? '2px' : '3px'};
  display: inline-block;
  margin-right: ${isMinified ? '3px' : '7px'};
`;

export default legendSquare;
