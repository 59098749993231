const legendText = {
  NoData: 'GENERAL.NO_DATA',
  SafeMovements: 'GENERAL.ALL_SAFE',
  NoText: '',
};

export default function getTextForLegend(overall) {
  if (overall === -1) {
    return legendText.NoData.toUpperCase();
  }
  return overall
    ? legendText.NoText.toUpperCase()
    : legendText.SafeMovements.toUpperCase();
}
