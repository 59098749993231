import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  feedbacksList: [],
  error: null,
  isLoading: false,
});

export default function (state = initialState, action = {}) {
  const {
    error, feedbacksList, isLoading, type,
  } = action;
  switch (type) {
    case types.GET_FEEDBACKS_IS_LOADING:
      return ({ ...state, isLoading });
    case types.GET_FEEDBACKS_ERROR:
      return ({ ...state, error });
    case types.GET_FEEDBACKS:
      return ({ ...state, feedbacksList });
    default: return state;
  }
}
