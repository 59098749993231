import React from 'react';
import * as Emotion from 'emotion';
import moment from 'moment';
import { SINGLE_PAGE_TYPE } from 'containers/PdfContainer/constants';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import AssessmentTotalScore from './components/AssessmentTotalScore';
import { getColorForScore, getDescriptionForScore } from './components/AssessmentNiosh';
import { getColorForTotalScore, getTotalScoreDescription } from './components/Assessment';
import { rulaStructure } from './rulaStructure';
import { rebaStructure } from './rebaStructure';
import SinglePage from '../../containers/PdfContainer/SinglePage';

const reportSlot = Emotion.css`
  width: 100%;
  border-bottom: 1px solid ${COLORS.GRAY[50]};
  padding: 14px 0;
`;

const getAssessmentTypeString = (type) => {
  switch (type) {
    case 2:
    case 3:
      return 'rula';
    case 4:
      return 'reba';
    case 5:
      return 'niosh';
    default:
      return 'custom';
  }
};

const rulaRebaGrid = Emotion.css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const HeaderSlot = ({ title, value, color }) => (
  <div className={text(COLORS.GRAY[400], 14)}>
    <span>{`${title}: `}</span>
    <span style={{ color }}>{value}</span>
  </div>
);

const metaTitle = Emotion.css`
  ${text(COLORS.GRAY[400], 21)};
  margin-bottom: 10px;
`;

const nioshSlotChildren = Emotion.css`
  ${text(COLORS.GRAY[950], 18)};
  div {
    margin-bottom: 5px;
  }
`;

const NioshSlot = ({ children, title }) => (
  <div>
    <div className={metaTitle}>{title.toUpperCase()}</div>
    <div className={nioshSlotChildren}>{children}</div>
  </div>
);

const NioshBody = ({ fields, totalScore, additionalTotalScore }) => {
  const { t } = useTranslation();
  const getFieldValue = (n) => fields.find(({ name }) => n === name)?.value;
  const isControlRequired = !!getFieldValue('control_required');
  return (
    <div>
      <div className={reportSlot}>
        <NioshSlot title={t('SOTER_TASK.ASSESSMENT.NIOSH.HOR_LOCATION')}>
          <div>
            {`${t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}: ${getFieldValue(
              'horizontal',
            )}`}
          </div>
          {isControlRequired ? (
            <div>
              {`${t(
                'SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION',
              )}: ${getFieldValue('horizontal')}: ${getFieldValue(
                'horizontal_destination',
              )}`}
            </div>
          ) : null}
        </NioshSlot>
        <NioshSlot title={t('SOTER_TASK.ASSESSMENT.NIOSH.VERT_LOCATION')}>
          <div>
            {`${t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}: ${getFieldValue(
              'vertical_origin',
            )}`}
          </div>
          <div>
            {`${t(
              'SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION',
            )}: ${getFieldValue('vertical_destination')}`}
          </div>
        </NioshSlot>
        <NioshSlot title={t('SOTER_TASK.ASSESSMENT.NIOSH.ASYMMETRY')}>
          <div>
            {`${t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN')}: ${getFieldValue(
              'asymmetry',
            )}`}
          </div>
          {isControlRequired ? (
            <div>
              {`${t(
                'SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION',
              )}: ${getFieldValue('asymmetry_destination')}`}
            </div>
          ) : null}
        </NioshSlot>
        <NioshSlot title={t('SOTER_TASK.ASSESSMENT.NIOSH.COUPLING')}>
          <div>
            {getFieldValue('coupling') === 0
              ? t('SOTER_TASK.ASSESSMENT.NIOSH.GOOD')
              : getFieldValue('coupling') === 1
                ? t('SOTER_TASK.ASSESSMENT.NIOSH.FAIR')
                : t('SOTER_TASK.ASSESSMENT.NIOSH.POOR')}
          </div>
        </NioshSlot>
        <NioshSlot title={t('SOTER_TASK.ASSESSMENT.NIOSH.LIFTING')}>
          <div>
            {`${t('SOTER_TASK.ASSESSMENT.NIOSH.FREQUENCY')}: ${getFieldValue(
              'frequency',
            )}`}
          </div>
          <div>
            {`${t(
              'SOTER_TASK.ASSESSMENT.NIOSH.AVERAGE_LOAD',
            )}: ${getFieldValue('avg_load')}`}
          </div>
          <div>
            {`${t(
              'SOTER_TASK.ASSESSMENT.NIOSH.AVERAGE_LOAD',
            )}: ${getFieldValue('avg_load')}`}
          </div>
        </NioshSlot>
        <NioshSlot title={t('SOTER_TASK.VIDEOS_LIST.DURATION')}>
          <div>
            {getFieldValue('duration') === 0
              ? `1 ${t('GENERAL.HOUR')}`
              : getFieldValue('duration') === 1
                ? `1-2 ${t('GENERAL.HOURS')}`
                : `2-8 ${t('GENERAL.HOURS')}`}
          </div>
        </NioshSlot>
      </div>
      <AssessmentTotalScore
        score={totalScore}
        description={getDescriptionForScore(totalScore, t)}
        color={getColorForScore(totalScore)}
        scoreSubject="Origin"
      />
      {isControlRequired ? (
        <AssessmentTotalScore
          score={additionalTotalScore}
          description={getDescriptionForScore(additionalTotalScore, t)}
          color={getColorForScore(additionalTotalScore)}
        />
      ) : null}
    </div>
  );
};

const findValues = (values, selectedFields) => values.filter(({ id }) => selectedFields.includes(id));

const RulaRebaSlot = ({ title, selectedValues }) => {
  const { t } = useTranslation();
  const stepScores = selectedValues?.reduce((acc, { score }) => acc + score, 0);
  const selectedValue
    = selectedValues.find(({ image }) => image) || selectedValues[0];
  return (
    <div style={{ marginBottom: '12px' }} key={title}>
      <div style={{ marginBottom: '10px', fontSize: '18px' }}>{t(title)}</div>
      <div
        style={{
          height: selectedValues?.image ? '70px' : 'inherit',
          display: selectedValues?.image ? 'flex' : 'block',
          alignItems: 'flex-end',
          marginTop: '10px',
        }}
      >
        {selectedValue?.image ? (
          <img src={selectedValue?.image} />
        ) : (
          <span>{t(selectedValue?.text.lb) || t(selectedValue?.text)}</span>
        )}
        {stepScores ? (
          <div
            style={{ marginLeft: selectedValue?.image ? '10px' : '0' }}
            className={text(COLORS.GRAY[400], 14)}
          >
            {`${t('SOTER_TASK.ASSESSMENT.SCORE')}: ${stepScores}`}
          </div>
        ) : null}
        {/* {selectedValues?.score ? <div style={{ marginLeft: selectedValues?.image ? '10px' : '0' }} className={text(COLORS.GRAY[400], 14)}>{`Score: ${selectedValues?.score}`}</div> : null} */}
      </div>
    </div>
  );
};

const RulaRebaBody = ({ structure, selectedFields }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={reportSlot}>
        <div className={metaTitle}>
          {t(structure[0].metaTitle).toUpperCase()}
        </div>
        <div className={rulaRebaGrid}>
          {structure[0].steps.map(({ title, values }) => {
            const selectedValues = findValues(values, selectedFields);
            return (
              <RulaRebaSlot title={title} selectedValues={selectedValues} />
            );
          })}
        </div>
      </div>
      <div className={reportSlot}>
        <div className={metaTitle}>
          {t(structure[1].metaTitle.toUpperCase())}
        </div>
        <div className={rulaRebaGrid}>
          {structure[1].steps.map(({ title, values }) => {
            const selectedValues = findValues(values, selectedFields);
            return (
              <RulaRebaSlot title={title} selectedValues={selectedValues} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AssessmentPdfContainer = ({ assessment, weightUnits }) => {
  const { t } = useTranslation();
  const assessmentTypeString = getAssessmentTypeString(assessment.type);
  const colorForTotalScore
    = assessmentTypeString === 'niosh'
      ? getColorForScore(assessment.totalScore)
      : getColorForTotalScore(assessment.totalScore, assessmentTypeString);
  const reportStructure
    = assessmentTypeString === 'rula' ? rulaStructure : rebaStructure;
  return (
    <div
      id={`assessment_report_${assessment.id}`}
      style={{
        display: 'none',
      }}
    >
      <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL}>
        <div className={`${text(COLORS.GRAY[950], 42, 1000)} ${reportSlot}`}>
          SoterTask
        </div>
        <div className={reportSlot}>
          <div
            style={{ marginBottom: '10px' }}
            className={text(COLORS.GRAY[950], 16, 500)}
          >
            {t('SOTER_TASK.ASSESSMENTS')}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: '1fr 1fr',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '10px',
            }}
          >
            <HeaderSlot
              color={COLORS.GRAY[950]}
              title={t('GENERAL.TITLE')}
              value={assessment.title}
            />
            <HeaderSlot
              color={COLORS.GRAY[950]}
              title={t('MY_COMPANY.USERS.CREATED_AT')}
              value={moment(assessment.createdAt, 'X').format('DD MMM YYYY')}
            />
            <HeaderSlot
              color={colorForTotalScore}
              title={
                assessmentTypeString === 'niosh'
                  ? t('SOTER_TASK.ASSESSMENT.NIOSH.ORIGIN_SCORE')
                  : t('SOTER_TASK.ASSESSMENT.SCORE')
              }
              value={
                assessmentTypeString === 'niosh'
                  ? assessment.totalScore.toFixed(2)
                  : assessment.totalScore
              }
            />
            <HeaderSlot
              color={COLORS.GRAY[950]}
              title={t('GENERAL.TYPE')}
              value={assessmentTypeString.toUpperCase()}
            />
            <HeaderSlot
              color={COLORS.GRAY[950]}
              title={t('MY_COMPANY.USERS.CREATED_BY')}
              value={assessment.createdBy}
            />
            {assessmentTypeString === 'niosh' ? (
              <HeaderSlot
                color={getColorForScore(assessment.additionalTotalScore)}
                title={t('SOTER_TASK.ASSESSMENT.NIOSH.DESTINATION_SCORE')}
                value={assessment.additionalTotalScore.toFixed(2)}
              />
            ) : (
              <div className={text(colorForTotalScore)}>
                {getTotalScoreDescription(
                  assessment.totalScore,
                  assessmentTypeString,
                  t,
                )}
              </div>
            )}
          </div>
        </div>
        {assessmentTypeString === 'rula' || assessmentTypeString === 'reba' ? (
          <RulaRebaBody
            selectedFields={assessment.fieldsList.map(({ name }) => name)}
            structure={reportStructure}
          />
        ) : null}
        {assessmentTypeString === 'niosh' ? (
          <NioshBody
            totalScore={assessment.totalScore}
            fields={assessment.fieldsList}
            additionalTotalScore={assessment.additionalTotalScore}
          />
        ) : null}
      </SinglePage>
    </div>
  );
};

export default AssessmentPdfContainer;
