// source: api/soter-task/common/v1/video_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_soter$task_common_v1_video_platform_enum_pb = require('../../../../api/soter-task/common/v1/video_platform_enum_pb.js');
goog.object.extend(proto, api_soter$task_common_v1_video_platform_enum_pb);
var api_soter$task_common_v1_video_status_enum_pb = require('../../../../api/soter-task/common/v1/video_status_enum_pb.js');
goog.object.extend(proto, api_soter$task_common_v1_video_status_enum_pb);
var api_soter$task_common_v1_company_tag_message_pb = require('../../../../api/soter-task/common/v1/company_tag_message_pb.js');
goog.object.extend(proto, api_soter$task_common_v1_company_tag_message_pb);
var api_soter$task_common_v1_video_risks_enum_pb = require('../../../../api/soter-task/common/v1/video_risks_enum_pb.js');
goog.object.extend(proto, api_soter$task_common_v1_video_risks_enum_pb);
var api_soter$task_common_v1_video_type_enum_pb = require('../../../../api/soter-task/common/v1/video_type_enum_pb.js');
goog.object.extend(proto, api_soter$task_common_v1_video_type_enum_pb);
var api_soter$task_common_v1_video_category_enum_pb = require('../../../../api/soter-task/common/v1/video_category_enum_pb.js');
goog.object.extend(proto, api_soter$task_common_v1_video_category_enum_pb);
goog.exportSymbol('proto.videoTask.CreateTaskVideoComparisonRequest', null, global);
goog.exportSymbol('proto.videoTask.CreateTaskVideoRequest', null, global);
goog.exportSymbol('proto.videoTask.CreateTaskVideosRebaWeightsRequest', null, global);
goog.exportSymbol('proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight', null, global);
goog.exportSymbol('proto.videoTask.CreateTaskVideosRebaWeightsResponse', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoComparisonRequest', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoComparisonResponse', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoComparisonsRequest', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoComparisonsResponse', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoRebaWeightsRequest', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoRebaWeightsResponse', null, global);
goog.exportSymbol('proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status', null, global);
goog.exportSymbol('proto.videoTask.DownloadProcessedVideoResponse', null, global);
goog.exportSymbol('proto.videoTask.EventsForRebaWeightRequest', null, global);
goog.exportSymbol('proto.videoTask.EventsForVideoRequest', null, global);
goog.exportSymbol('proto.videoTask.GetTaskVideoRebaWeightsRequest', null, global);
goog.exportSymbol('proto.videoTask.GetTaskVideoRebaWeightsResponse', null, global);
goog.exportSymbol('proto.videoTask.RebaWeight', null, global);
goog.exportSymbol('proto.videoTask.SetDepartmentForVideosRequest', null, global);
goog.exportSymbol('proto.videoTask.SetDepartmentForVideosResponse', null, global);
goog.exportSymbol('proto.videoTask.SetJobRoleForVideosRequest', null, global);
goog.exportSymbol('proto.videoTask.SetJobRoleForVideosResponse', null, global);
goog.exportSymbol('proto.videoTask.SetSiteForVideosRequest', null, global);
goog.exportSymbol('proto.videoTask.SetSiteForVideosResponse', null, global);
goog.exportSymbol('proto.videoTask.SetTaskNameForVideosRequest', null, global);
goog.exportSymbol('proto.videoTask.SetTaskNameForVideosResponse', null, global);
goog.exportSymbol('proto.videoTask.StatusForEventsRebaWeightResponse', null, global);
goog.exportSymbol('proto.videoTask.StatusForEventsRebaWeightResponse.StatusForEventsRebaWeight', null, global);
goog.exportSymbol('proto.videoTask.StatusForEventsTaskVideo', null, global);
goog.exportSymbol('proto.videoTask.StatusForEventsTaskVideoResponse', null, global);
goog.exportSymbol('proto.videoTask.TaskDeleteVideosRequest', null, global);
goog.exportSymbol('proto.videoTask.TaskDeleteVideosResponse', null, global);
goog.exportSymbol('proto.videoTask.TaskMovementData', null, global);
goog.exportSymbol('proto.videoTask.TaskRebaData', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoAngleConfig', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoComparisonListRequest', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoComparisonListResponse', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoComparisonResponse', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoCreatedBy', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoListRequest', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoRequest', null, global);
goog.exportSymbol('proto.videoTask.TaskVideoResponse', null, global);
goog.exportSymbol('proto.videoTask.TaskVideosRequest', null, global);
goog.exportSymbol('proto.videoTask.TaskVideosResponse', null, global);
goog.exportSymbol('proto.videoTask.UpdateTaskVideoAngleConfig', null, global);
goog.exportSymbol('proto.videoTask.UpdateTaskVideoComparisonRequest', null, global);
goog.exportSymbol('proto.videoTask.UpdateTaskVideoRequest', null, global);
goog.exportSymbol('proto.videoTask.VideoRisks', null, global);
goog.exportSymbol('proto.videoTask.VideoUploadChunkRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.CreateTaskVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.CreateTaskVideoRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.CreateTaskVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.CreateTaskVideoRequest.displayName = 'proto.videoTask.CreateTaskVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.TaskVideoResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.TaskVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoResponse.displayName = 'proto.videoTask.TaskVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoRequest.displayName = 'proto.videoTask.TaskVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskDeleteVideosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.TaskDeleteVideosRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.TaskDeleteVideosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskDeleteVideosRequest.displayName = 'proto.videoTask.TaskDeleteVideosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskDeleteVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.TaskDeleteVideosResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.TaskDeleteVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskDeleteVideosResponse.displayName = 'proto.videoTask.TaskDeleteVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.UpdateTaskVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.UpdateTaskVideoRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.UpdateTaskVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.UpdateTaskVideoRequest.displayName = 'proto.videoTask.UpdateTaskVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideoListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoListRequest.displayName = 'proto.videoTask.TaskVideoListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideosRequest.displayName = 'proto.videoTask.TaskVideosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.TaskVideosResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.TaskVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideosResponse.displayName = 'proto.videoTask.TaskVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskMovementData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskMovementData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskMovementData.displayName = 'proto.videoTask.TaskMovementData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskRebaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskRebaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskRebaData.displayName = 'proto.videoTask.TaskRebaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.VideoUploadChunkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.VideoUploadChunkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.VideoUploadChunkRequest.displayName = 'proto.videoTask.VideoUploadChunkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoAngleConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideoAngleConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoAngleConfig.displayName = 'proto.videoTask.TaskVideoAngleConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.UpdateTaskVideoAngleConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.UpdateTaskVideoAngleConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.UpdateTaskVideoAngleConfig.displayName = 'proto.videoTask.UpdateTaskVideoAngleConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.StatusForEventsTaskVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.StatusForEventsTaskVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.StatusForEventsTaskVideoResponse.displayName = 'proto.videoTask.StatusForEventsTaskVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.EventsForVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.EventsForVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.EventsForVideoRequest.displayName = 'proto.videoTask.EventsForVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DownloadProcessedVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.DownloadProcessedVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DownloadProcessedVideoResponse.displayName = 'proto.videoTask.DownloadProcessedVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.CreateTaskVideoComparisonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.CreateTaskVideoComparisonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.CreateTaskVideoComparisonRequest.displayName = 'proto.videoTask.CreateTaskVideoComparisonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.UpdateTaskVideoComparisonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.UpdateTaskVideoComparisonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.UpdateTaskVideoComparisonRequest.displayName = 'proto.videoTask.UpdateTaskVideoComparisonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoComparisonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideoComparisonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoComparisonResponse.displayName = 'proto.videoTask.TaskVideoComparisonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoComparisonListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideoComparisonListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoComparisonListRequest.displayName = 'proto.videoTask.TaskVideoComparisonListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoComparisonListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.TaskVideoComparisonListResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.TaskVideoComparisonListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoComparisonListResponse.displayName = 'proto.videoTask.TaskVideoComparisonListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.DeleteTaskVideoComparisonsRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoComparisonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoComparisonsRequest.displayName = 'proto.videoTask.DeleteTaskVideoComparisonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoComparisonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoComparisonsResponse.displayName = 'proto.videoTask.DeleteTaskVideoComparisonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.VideoRisks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.VideoRisks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.VideoRisks.displayName = 'proto.videoTask.VideoRisks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.TaskVideoCreatedBy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.TaskVideoCreatedBy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.TaskVideoCreatedBy.displayName = 'proto.videoTask.TaskVideoCreatedBy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetDepartmentForVideosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.SetDepartmentForVideosRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.SetDepartmentForVideosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetDepartmentForVideosRequest.displayName = 'proto.videoTask.SetDepartmentForVideosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetDepartmentForVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.SetDepartmentForVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetDepartmentForVideosResponse.displayName = 'proto.videoTask.SetDepartmentForVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetSiteForVideosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.SetSiteForVideosRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.SetSiteForVideosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetSiteForVideosRequest.displayName = 'proto.videoTask.SetSiteForVideosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetSiteForVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.SetSiteForVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetSiteForVideosResponse.displayName = 'proto.videoTask.SetSiteForVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetJobRoleForVideosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.SetJobRoleForVideosRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.SetJobRoleForVideosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetJobRoleForVideosRequest.displayName = 'proto.videoTask.SetJobRoleForVideosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetJobRoleForVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.SetJobRoleForVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetJobRoleForVideosResponse.displayName = 'proto.videoTask.SetJobRoleForVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetTaskNameForVideosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.SetTaskNameForVideosRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.SetTaskNameForVideosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetTaskNameForVideosRequest.displayName = 'proto.videoTask.SetTaskNameForVideosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.SetTaskNameForVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.SetTaskNameForVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.SetTaskNameForVideosResponse.displayName = 'proto.videoTask.SetTaskNameForVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.CreateTaskVideosRebaWeightsRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.CreateTaskVideosRebaWeightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.CreateTaskVideosRebaWeightsRequest.displayName = 'proto.videoTask.CreateTaskVideosRebaWeightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.displayName = 'proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.CreateTaskVideosRebaWeightsResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.CreateTaskVideosRebaWeightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.CreateTaskVideosRebaWeightsResponse.displayName = 'proto.videoTask.CreateTaskVideosRebaWeightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.GetTaskVideoRebaWeightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.GetTaskVideoRebaWeightsRequest.displayName = 'proto.videoTask.GetTaskVideoRebaWeightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.GetTaskVideoRebaWeightsResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.GetTaskVideoRebaWeightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.GetTaskVideoRebaWeightsResponse.displayName = 'proto.videoTask.GetTaskVideoRebaWeightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.RebaWeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.RebaWeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.RebaWeight.displayName = 'proto.videoTask.RebaWeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoComparisonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoComparisonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoComparisonRequest.displayName = 'proto.videoTask.DeleteTaskVideoComparisonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoComparisonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoComparisonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoComparisonResponse.displayName = 'proto.videoTask.DeleteTaskVideoComparisonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.DeleteTaskVideoRebaWeightsRequest.repeatedFields_, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoRebaWeightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoRebaWeightsRequest.displayName = 'proto.videoTask.DeleteTaskVideoRebaWeightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.videoTask.DeleteTaskVideoRebaWeightsResponse.repeatedFields_, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoRebaWeightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoRebaWeightsResponse.displayName = 'proto.videoTask.DeleteTaskVideoRebaWeightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.displayName = 'proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.EventsForRebaWeightRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.EventsForRebaWeightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.EventsForRebaWeightRequest.displayName = 'proto.videoTask.EventsForRebaWeightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.videoTask.StatusForEventsRebaWeightResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.videoTask.StatusForEventsRebaWeightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.videoTask.StatusForEventsRebaWeightResponse.displayName = 'proto.videoTask.StatusForEventsRebaWeightResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.CreateTaskVideoRequest.repeatedFields_ = [16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.CreateTaskVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.CreateTaskVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 4, 0),
    videoCreateAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    videoType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    taskName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    jobRole: jspb.Message.getFieldWithDefault(msg, 10, ""),
    repetition: jspb.Message.getFieldWithDefault(msg, 11, 0),
    taskDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    category: jspb.Message.getFieldWithDefault(msg, 13, 0),
    departmentId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    jobRolesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    jobRoleIdsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.CreateTaskVideoRequest}
 */
proto.videoTask.CreateTaskVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.CreateTaskVideoRequest;
  return proto.videoTask.CreateTaskVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.CreateTaskVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.CreateTaskVideoRequest}
 */
proto.videoTask.CreateTaskVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {!proto.videoTaskPlatform.TaskVideoPlatform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVideoCreateAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {!proto.taskVideoType.TaskVideoType} */ (reader.readEnum());
      msg.setVideoType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobRole(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetition(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskDuration(value);
      break;
    case 13:
      var value = /** @type {!proto.taskVideoCategory.TaskVideoCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addJobRoles(value);
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addJobRoleIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.CreateTaskVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.CreateTaskVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVideoCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVideoType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getJobRole();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTaskDuration();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getJobRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getJobRoleIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      17,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 file_size = 2;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setFileSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional videoTaskPlatform.TaskVideoPlatform platform = 4;
 * @return {!proto.videoTaskPlatform.TaskVideoPlatform}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getPlatform = function() {
  return /** @type {!proto.videoTaskPlatform.TaskVideoPlatform} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.videoTaskPlatform.TaskVideoPlatform} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 video_create_at = 5;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getVideoCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setVideoCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 company_id = 6;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional taskVideoType.TaskVideoType video_type = 8;
 * @return {!proto.taskVideoType.TaskVideoType}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getVideoType = function() {
  return /** @type {!proto.taskVideoType.TaskVideoType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.taskVideoType.TaskVideoType} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string task_name = 9;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string job_role = 10;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getJobRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setJobRole = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 repetition = 11;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 task_duration = 12;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getTaskDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setTaskDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional taskVideoCategory.TaskVideoCategory category = 13;
 * @return {!proto.taskVideoCategory.TaskVideoCategory}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getCategory = function() {
  return /** @type {!proto.taskVideoCategory.TaskVideoCategory} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.taskVideoCategory.TaskVideoCategory} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional int32 department_id = 14;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 site_id = 15;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated string job_roles = 16;
 * @return {!Array<string>}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getJobRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setJobRolesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.addJobRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.clearJobRolesList = function() {
  return this.setJobRolesList([]);
};


/**
 * repeated int32 job_role_ids = 17;
 * @return {!Array<number>}
 */
proto.videoTask.CreateTaskVideoRequest.prototype.getJobRoleIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.setJobRoleIdsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.addJobRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.CreateTaskVideoRequest} returns this
 */
proto.videoTask.CreateTaskVideoRequest.prototype.clearJobRoleIdsList = function() {
  return this.setJobRoleIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.TaskVideoResponse.repeatedFields_ = [34];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amazonProcessedUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amazonStreamUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    amazonThumbnailUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    currentOffset: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 13, 0),
    startUploadingTime: jspb.Message.getFieldWithDefault(msg, 14, 0),
    finishingUploadingTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
    videoWidthPx: jspb.Message.getFieldWithDefault(msg, 16, 0),
    videoHeightPx: jspb.Message.getFieldWithDefault(msg, 17, 0),
    anglesConfig: (f = msg.getAnglesConfig()) && proto.videoTask.TaskVideoAngleConfig.toObject(includeInstance, f),
    originalFileName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    videoCreateAt: jspb.Message.getFieldWithDefault(msg, 20, 0),
    amazonDownloadUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    risks: (f = msg.getRisks()) && proto.videoTask.VideoRisks.toObject(includeInstance, f),
    taskName: (f = msg.getTaskName()) && api_soter$task_common_v1_company_tag_message_pb.CompanyTag.toObject(includeInstance, f),
    jobRole: (f = msg.getJobRole()) && api_soter$task_common_v1_company_tag_message_pb.CompanyTag.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && proto.videoTask.TaskVideoCreatedBy.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 26, ""),
    videoType: jspb.Message.getFieldWithDefault(msg, 27, 0),
    repetition: jspb.Message.getFieldWithDefault(msg, 28, 0),
    taskDuration: jspb.Message.getFieldWithDefault(msg, 29, 0),
    category: jspb.Message.getFieldWithDefault(msg, 30, 0),
    amazonMovementDataUrl: jspb.Message.getFieldWithDefault(msg, 31, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 32, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 33, 0),
    jobRolesList: jspb.Message.toObjectList(msg.getJobRolesList(),
    api_soter$task_common_v1_company_tag_message_pb.CompanyTag.toObject, includeInstance),
    amazonMovementDataMd5sum: jspb.Message.getFieldWithDefault(msg, 35, ""),
    maxRebaScore: jspb.Message.getFieldWithDefault(msg, 36, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoResponse}
 */
proto.videoTask.TaskVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoResponse;
  return proto.videoTask.TaskVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoResponse}
 */
proto.videoTask.TaskVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonProcessedUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonStreamUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonThumbnailUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {!proto.videoTaskStatus.TaskVideoStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentOffset(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {!proto.videoTaskPlatform.TaskVideoPlatform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUploadingTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishingUploadingTime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoWidthPx(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoHeightPx(value);
      break;
    case 18:
      var value = new proto.videoTask.TaskVideoAngleConfig;
      reader.readMessage(value,proto.videoTask.TaskVideoAngleConfig.deserializeBinaryFromReader);
      msg.setAnglesConfig(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFileName(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVideoCreateAt(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonDownloadUrl(value);
      break;
    case 22:
      var value = new proto.videoTask.VideoRisks;
      reader.readMessage(value,proto.videoTask.VideoRisks.deserializeBinaryFromReader);
      msg.setRisks(value);
      break;
    case 23:
      var value = new api_soter$task_common_v1_company_tag_message_pb.CompanyTag;
      reader.readMessage(value,api_soter$task_common_v1_company_tag_message_pb.CompanyTag.deserializeBinaryFromReader);
      msg.setTaskName(value);
      break;
    case 24:
      var value = new api_soter$task_common_v1_company_tag_message_pb.CompanyTag;
      reader.readMessage(value,api_soter$task_common_v1_company_tag_message_pb.CompanyTag.deserializeBinaryFromReader);
      msg.setJobRole(value);
      break;
    case 25:
      var value = new proto.videoTask.TaskVideoCreatedBy;
      reader.readMessage(value,proto.videoTask.TaskVideoCreatedBy.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 27:
      var value = /** @type {!proto.taskVideoType.TaskVideoType} */ (reader.readEnum());
      msg.setVideoType(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetition(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskDuration(value);
      break;
    case 30:
      var value = /** @type {!proto.taskVideoCategory.TaskVideoCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonMovementDataUrl(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 34:
      var value = new api_soter$task_common_v1_company_tag_message_pb.CompanyTag;
      reader.readMessage(value,api_soter$task_common_v1_company_tag_message_pb.CompanyTag.deserializeBinaryFromReader);
      msg.addJobRoles(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmazonMovementDataMd5sum(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxRebaScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmazonProcessedUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmazonStreamUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmazonThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCurrentOffset();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getStartUploadingTime();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFinishingUploadingTime();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getVideoWidthPx();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getVideoHeightPx();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getAnglesConfig();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.videoTask.TaskVideoAngleConfig.serializeBinaryToWriter
    );
  }
  f = message.getOriginalFileName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getVideoCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getAmazonDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRisks();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.videoTask.VideoRisks.serializeBinaryToWriter
    );
  }
  f = message.getTaskName();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      api_soter$task_common_v1_company_tag_message_pb.CompanyTag.serializeBinaryToWriter
    );
  }
  f = message.getJobRole();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      api_soter$task_common_v1_company_tag_message_pb.CompanyTag.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.videoTask.TaskVideoCreatedBy.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getVideoType();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getTaskDuration();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getAmazonMovementDataUrl();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
  f = message.getJobRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      34,
      f,
      api_soter$task_common_v1_company_tag_message_pb.CompanyTag.serializeBinaryToWriter
    );
  }
  f = message.getAmazonMovementDataMd5sum();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getMaxRebaScore();
  if (f !== 0) {
    writer.writeInt32(
      36,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 file_size = 3;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setFileSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string amazon_processed_url = 5;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getAmazonProcessedUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setAmazonProcessedUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amazon_stream_url = 6;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getAmazonStreamUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setAmazonStreamUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string amazon_thumbnail_url = 7;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getAmazonThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setAmazonThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 duration = 8;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional videoTaskStatus.TaskVideoStatus status = 9;
 * @return {!proto.videoTaskStatus.TaskVideoStatus}
 */
proto.videoTask.TaskVideoResponse.prototype.getStatus = function() {
  return /** @type {!proto.videoTaskStatus.TaskVideoStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.videoTaskStatus.TaskVideoStatus} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 current_offset = 11;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getCurrentOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setCurrentOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 created_at = 12;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional videoTaskPlatform.TaskVideoPlatform platform = 13;
 * @return {!proto.videoTaskPlatform.TaskVideoPlatform}
 */
proto.videoTask.TaskVideoResponse.prototype.getPlatform = function() {
  return /** @type {!proto.videoTaskPlatform.TaskVideoPlatform} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.videoTaskPlatform.TaskVideoPlatform} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional int64 start_uploading_time = 14;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getStartUploadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setStartUploadingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 finishing_uploading_time = 15;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getFinishingUploadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setFinishingUploadingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 video_width_px = 16;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getVideoWidthPx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setVideoWidthPx = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 video_height_px = 17;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getVideoHeightPx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setVideoHeightPx = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional TaskVideoAngleConfig angles_config = 18;
 * @return {?proto.videoTask.TaskVideoAngleConfig}
 */
proto.videoTask.TaskVideoResponse.prototype.getAnglesConfig = function() {
  return /** @type{?proto.videoTask.TaskVideoAngleConfig} */ (
    jspb.Message.getWrapperField(this, proto.videoTask.TaskVideoAngleConfig, 18));
};


/**
 * @param {?proto.videoTask.TaskVideoAngleConfig|undefined} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
*/
proto.videoTask.TaskVideoResponse.prototype.setAnglesConfig = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.clearAnglesConfig = function() {
  return this.setAnglesConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoResponse.prototype.hasAnglesConfig = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string original_file_name = 19;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getOriginalFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setOriginalFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 video_create_at = 20;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getVideoCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setVideoCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string amazon_download_url = 21;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getAmazonDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setAmazonDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional VideoRisks risks = 22;
 * @return {?proto.videoTask.VideoRisks}
 */
proto.videoTask.TaskVideoResponse.prototype.getRisks = function() {
  return /** @type{?proto.videoTask.VideoRisks} */ (
    jspb.Message.getWrapperField(this, proto.videoTask.VideoRisks, 22));
};


/**
 * @param {?proto.videoTask.VideoRisks|undefined} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
*/
proto.videoTask.TaskVideoResponse.prototype.setRisks = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.clearRisks = function() {
  return this.setRisks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoResponse.prototype.hasRisks = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional companyTag.CompanyTag task_name = 23;
 * @return {?proto.companyTag.CompanyTag}
 */
proto.videoTask.TaskVideoResponse.prototype.getTaskName = function() {
  return /** @type{?proto.companyTag.CompanyTag} */ (
    jspb.Message.getWrapperField(this, api_soter$task_common_v1_company_tag_message_pb.CompanyTag, 23));
};


/**
 * @param {?proto.companyTag.CompanyTag|undefined} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
*/
proto.videoTask.TaskVideoResponse.prototype.setTaskName = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.clearTaskName = function() {
  return this.setTaskName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoResponse.prototype.hasTaskName = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional companyTag.CompanyTag job_role = 24;
 * @return {?proto.companyTag.CompanyTag}
 */
proto.videoTask.TaskVideoResponse.prototype.getJobRole = function() {
  return /** @type{?proto.companyTag.CompanyTag} */ (
    jspb.Message.getWrapperField(this, api_soter$task_common_v1_company_tag_message_pb.CompanyTag, 24));
};


/**
 * @param {?proto.companyTag.CompanyTag|undefined} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
*/
proto.videoTask.TaskVideoResponse.prototype.setJobRole = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.clearJobRole = function() {
  return this.setJobRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoResponse.prototype.hasJobRole = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional TaskVideoCreatedBy created_by = 25;
 * @return {?proto.videoTask.TaskVideoCreatedBy}
 */
proto.videoTask.TaskVideoResponse.prototype.getCreatedBy = function() {
  return /** @type{?proto.videoTask.TaskVideoCreatedBy} */ (
    jspb.Message.getWrapperField(this, proto.videoTask.TaskVideoCreatedBy, 25));
};


/**
 * @param {?proto.videoTask.TaskVideoCreatedBy|undefined} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
*/
proto.videoTask.TaskVideoResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoResponse.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string description = 26;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional taskVideoType.TaskVideoType video_type = 27;
 * @return {!proto.taskVideoType.TaskVideoType}
 */
proto.videoTask.TaskVideoResponse.prototype.getVideoType = function() {
  return /** @type {!proto.taskVideoType.TaskVideoType} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.taskVideoType.TaskVideoType} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional int32 repetition = 28;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 task_duration = 29;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getTaskDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setTaskDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional taskVideoCategory.TaskVideoCategory category = 30;
 * @return {!proto.taskVideoCategory.TaskVideoCategory}
 */
proto.videoTask.TaskVideoResponse.prototype.getCategory = function() {
  return /** @type {!proto.taskVideoCategory.TaskVideoCategory} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.taskVideoCategory.TaskVideoCategory} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional string amazon_movement_data_url = 31;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getAmazonMovementDataUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setAmazonMovementDataUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional int32 department_id = 32;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int32 site_id = 33;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * repeated companyTag.CompanyTag job_roles = 34;
 * @return {!Array<!proto.companyTag.CompanyTag>}
 */
proto.videoTask.TaskVideoResponse.prototype.getJobRolesList = function() {
  return /** @type{!Array<!proto.companyTag.CompanyTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_soter$task_common_v1_company_tag_message_pb.CompanyTag, 34));
};


/**
 * @param {!Array<!proto.companyTag.CompanyTag>} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
*/
proto.videoTask.TaskVideoResponse.prototype.setJobRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 34, value);
};


/**
 * @param {!proto.companyTag.CompanyTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.companyTag.CompanyTag}
 */
proto.videoTask.TaskVideoResponse.prototype.addJobRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 34, opt_value, proto.companyTag.CompanyTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.clearJobRolesList = function() {
  return this.setJobRolesList([]);
};


/**
 * optional string amazon_movement_data_md5sum = 35;
 * @return {string}
 */
proto.videoTask.TaskVideoResponse.prototype.getAmazonMovementDataMd5sum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setAmazonMovementDataMd5sum = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional int32 max_reba_score = 36;
 * @return {number}
 */
proto.videoTask.TaskVideoResponse.prototype.getMaxRebaScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoResponse} returns this
 */
proto.videoTask.TaskVideoResponse.prototype.setMaxRebaScore = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoRequest}
 */
proto.videoTask.TaskVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoRequest;
  return proto.videoTask.TaskVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoRequest}
 */
proto.videoTask.TaskVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 video_id = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoRequest.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoRequest} returns this
 */
proto.videoTask.TaskVideoRequest.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.TaskDeleteVideosRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskDeleteVideosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskDeleteVideosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskDeleteVideosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskDeleteVideosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskDeleteVideosRequest}
 */
proto.videoTask.TaskDeleteVideosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskDeleteVideosRequest;
  return proto.videoTask.TaskDeleteVideosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskDeleteVideosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskDeleteVideosRequest}
 */
proto.videoTask.TaskDeleteVideosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVideoIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskDeleteVideosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskDeleteVideosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskDeleteVideosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskDeleteVideosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 video_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.TaskDeleteVideosRequest.prototype.getVideoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.TaskDeleteVideosRequest} returns this
 */
proto.videoTask.TaskDeleteVideosRequest.prototype.setVideoIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.TaskDeleteVideosRequest} returns this
 */
proto.videoTask.TaskDeleteVideosRequest.prototype.addVideoIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.TaskDeleteVideosRequest} returns this
 */
proto.videoTask.TaskDeleteVideosRequest.prototype.clearVideoIdsList = function() {
  return this.setVideoIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.TaskDeleteVideosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskDeleteVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskDeleteVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskDeleteVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskDeleteVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletedVideoIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskDeleteVideosResponse}
 */
proto.videoTask.TaskDeleteVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskDeleteVideosResponse;
  return proto.videoTask.TaskDeleteVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskDeleteVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskDeleteVideosResponse}
 */
proto.videoTask.TaskDeleteVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeletedVideoIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskDeleteVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskDeleteVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskDeleteVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskDeleteVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeletedVideoIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 deleted_video_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.TaskDeleteVideosResponse.prototype.getDeletedVideoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.TaskDeleteVideosResponse} returns this
 */
proto.videoTask.TaskDeleteVideosResponse.prototype.setDeletedVideoIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.TaskDeleteVideosResponse} returns this
 */
proto.videoTask.TaskDeleteVideosResponse.prototype.addDeletedVideoIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.TaskDeleteVideosResponse} returns this
 */
proto.videoTask.TaskDeleteVideosResponse.prototype.clearDeletedVideoIdsList = function() {
  return this.setDeletedVideoIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.UpdateTaskVideoRequest.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.UpdateTaskVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.UpdateTaskVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.UpdateTaskVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    videoType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    taskName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    jobRole: jspb.Message.getFieldWithDefault(msg, 7, ""),
    repetition: jspb.Message.getFieldWithDefault(msg, 8, 0),
    taskDuration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    overallRisk: jspb.Message.getFieldWithDefault(msg, 10, 0),
    category: jspb.Message.getFieldWithDefault(msg, 11, 0),
    departmentId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    jobRolesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    jobRoleIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.UpdateTaskVideoRequest}
 */
proto.videoTask.UpdateTaskVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.UpdateTaskVideoRequest;
  return proto.videoTask.UpdateTaskVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.UpdateTaskVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.UpdateTaskVideoRequest}
 */
proto.videoTask.UpdateTaskVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.videoTaskStatus.TaskVideoStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.taskVideoType.TaskVideoType} */ (reader.readEnum());
      msg.setVideoType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobRole(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetition(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskDuration(value);
      break;
    case 10:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setOverallRisk(value);
      break;
    case 11:
      var value = /** @type {!proto.taskVideoCategory.TaskVideoCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addJobRoles(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addJobRoleIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.UpdateTaskVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.UpdateTaskVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.UpdateTaskVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVideoType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJobRole();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTaskDuration();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOverallRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getJobRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getJobRoleIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      15,
      f
    );
  }
};


/**
 * optional int32 video_id = 1;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional videoTaskStatus.TaskVideoStatus status = 3;
 * @return {!proto.videoTaskStatus.TaskVideoStatus}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getStatus = function() {
  return /** @type {!proto.videoTaskStatus.TaskVideoStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.videoTaskStatus.TaskVideoStatus} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional taskVideoType.TaskVideoType video_type = 5;
 * @return {!proto.taskVideoType.TaskVideoType}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getVideoType = function() {
  return /** @type {!proto.taskVideoType.TaskVideoType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.taskVideoType.TaskVideoType} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string task_name = 6;
 * @return {string}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string job_role = 7;
 * @return {string}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getJobRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setJobRole = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 repetition = 8;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 task_duration = 9;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getTaskDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setTaskDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional taskVideoRisks.Risks overall_risk = 10;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getOverallRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setOverallRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional taskVideoCategory.TaskVideoCategory category = 11;
 * @return {!proto.taskVideoCategory.TaskVideoCategory}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getCategory = function() {
  return /** @type {!proto.taskVideoCategory.TaskVideoCategory} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.taskVideoCategory.TaskVideoCategory} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int32 department_id = 12;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 site_id = 13;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string job_roles = 14;
 * @return {!Array<string>}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getJobRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setJobRolesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.addJobRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.clearJobRolesList = function() {
  return this.setJobRolesList([]);
};


/**
 * repeated int32 job_role_ids = 15;
 * @return {!Array<number>}
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.getJobRoleIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.setJobRoleIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.addJobRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.UpdateTaskVideoRequest} returns this
 */
proto.videoTask.UpdateTaskVideoRequest.prototype.clearJobRoleIdsList = function() {
  return this.setJobRoleIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoListRequest}
 */
proto.videoTask.TaskVideoListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoListRequest;
  return proto.videoTask.TaskVideoListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoListRequest}
 */
proto.videoTask.TaskVideoListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoListRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoListRequest} returns this
 */
proto.videoTask.TaskVideoListRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideosRequest}
 */
proto.videoTask.TaskVideosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideosRequest;
  return proto.videoTask.TaskVideosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideosRequest}
 */
proto.videoTask.TaskVideosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.videoTask.TaskVideosRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.videoTask.TaskVideosRequest} returns this
 */
proto.videoTask.TaskVideosRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.TaskVideosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    proto.videoTask.TaskVideoResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideosResponse}
 */
proto.videoTask.TaskVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideosResponse;
  return proto.videoTask.TaskVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideosResponse}
 */
proto.videoTask.TaskVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.videoTask.TaskVideoResponse;
      reader.readMessage(value,proto.videoTask.TaskVideoResponse.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.videoTask.TaskVideoResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaskVideoResponse videos = 1;
 * @return {!Array<!proto.videoTask.TaskVideoResponse>}
 */
proto.videoTask.TaskVideosResponse.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.videoTask.TaskVideoResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.videoTask.TaskVideoResponse, 1));
};


/**
 * @param {!Array<!proto.videoTask.TaskVideoResponse>} value
 * @return {!proto.videoTask.TaskVideosResponse} returns this
*/
proto.videoTask.TaskVideosResponse.prototype.setVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.videoTask.TaskVideoResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.videoTask.TaskVideoResponse}
 */
proto.videoTask.TaskVideosResponse.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.videoTask.TaskVideoResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.TaskVideosResponse} returns this
 */
proto.videoTask.TaskVideosResponse.prototype.clearVideosList = function() {
  return this.setVideosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskMovementData.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskMovementData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskMovementData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskMovementData.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, ""),
    anglesConfig: (f = msg.getAnglesConfig()) && proto.videoTask.TaskVideoAngleConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskMovementData}
 */
proto.videoTask.TaskMovementData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskMovementData;
  return proto.videoTask.TaskMovementData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskMovementData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskMovementData}
 */
proto.videoTask.TaskMovementData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 2:
      var value = new proto.videoTask.TaskVideoAngleConfig;
      reader.readMessage(value,proto.videoTask.TaskVideoAngleConfig.deserializeBinaryFromReader);
      msg.setAnglesConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskMovementData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskMovementData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskMovementData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskMovementData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnglesConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.videoTask.TaskVideoAngleConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.videoTask.TaskMovementData.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskMovementData} returns this
 */
proto.videoTask.TaskMovementData.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TaskVideoAngleConfig angles_config = 2;
 * @return {?proto.videoTask.TaskVideoAngleConfig}
 */
proto.videoTask.TaskMovementData.prototype.getAnglesConfig = function() {
  return /** @type{?proto.videoTask.TaskVideoAngleConfig} */ (
    jspb.Message.getWrapperField(this, proto.videoTask.TaskVideoAngleConfig, 2));
};


/**
 * @param {?proto.videoTask.TaskVideoAngleConfig|undefined} value
 * @return {!proto.videoTask.TaskMovementData} returns this
*/
proto.videoTask.TaskMovementData.prototype.setAnglesConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskMovementData} returns this
 */
proto.videoTask.TaskMovementData.prototype.clearAnglesConfig = function() {
  return this.setAnglesConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskMovementData.prototype.hasAnglesConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskRebaData.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskRebaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskRebaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskRebaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskRebaData}
 */
proto.videoTask.TaskRebaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskRebaData;
  return proto.videoTask.TaskRebaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskRebaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskRebaData}
 */
proto.videoTask.TaskRebaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskRebaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskRebaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskRebaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskRebaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.videoTask.TaskRebaData.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskRebaData} returns this
 */
proto.videoTask.TaskRebaData.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.VideoUploadChunkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.VideoUploadChunkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.VideoUploadChunkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.VideoUploadChunkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.VideoUploadChunkRequest}
 */
proto.videoTask.VideoUploadChunkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.VideoUploadChunkRequest;
  return proto.videoTask.VideoUploadChunkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.VideoUploadChunkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.VideoUploadChunkRequest}
 */
proto.videoTask.VideoUploadChunkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.VideoUploadChunkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.VideoUploadChunkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.VideoUploadChunkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.VideoUploadChunkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChunkData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 video_id = 1;
 * @return {number}
 */
proto.videoTask.VideoUploadChunkRequest.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoUploadChunkRequest} returns this
 */
proto.videoTask.VideoUploadChunkRequest.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.videoTask.VideoUploadChunkRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.videoTask.VideoUploadChunkRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.videoTask.VideoUploadChunkRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.videoTask.VideoUploadChunkRequest} returns this
 */
proto.videoTask.VideoUploadChunkRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoAngleConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoAngleConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoAngleConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    neckGreen: jspb.Message.getFieldWithDefault(msg, 1, 0),
    neckYellow: jspb.Message.getFieldWithDefault(msg, 2, 0),
    backGreen: jspb.Message.getFieldWithDefault(msg, 3, 0),
    backYellow: jspb.Message.getFieldWithDefault(msg, 4, 0),
    legGreen: jspb.Message.getFieldWithDefault(msg, 5, 0),
    legYellow: jspb.Message.getFieldWithDefault(msg, 6, 0),
    handGreen: jspb.Message.getFieldWithDefault(msg, 7, 0),
    handYellow: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoAngleConfig}
 */
proto.videoTask.TaskVideoAngleConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoAngleConfig;
  return proto.videoTask.TaskVideoAngleConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoAngleConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoAngleConfig}
 */
proto.videoTask.TaskVideoAngleConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckGreen(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckYellow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackGreen(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackYellow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegGreen(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegYellow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandGreen(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandYellow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoAngleConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoAngleConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoAngleConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNeckGreen();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNeckYellow();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBackGreen();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBackYellow();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLegGreen();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLegYellow();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHandGreen();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHandYellow();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 neck_green = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getNeckGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setNeckGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 neck_yellow = 2;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getNeckYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setNeckYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 back_green = 3;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getBackGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setBackGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 back_yellow = 4;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getBackYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setBackYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 leg_green = 5;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getLegGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setLegGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 leg_yellow = 6;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getLegYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setLegYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 hand_green = 7;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getHandGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setHandGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 hand_yellow = 8;
 * @return {number}
 */
proto.videoTask.TaskVideoAngleConfig.prototype.getHandYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoAngleConfig} returns this
 */
proto.videoTask.TaskVideoAngleConfig.prototype.setHandYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.UpdateTaskVideoAngleConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.UpdateTaskVideoAngleConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.UpdateTaskVideoAngleConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    neckGreen: jspb.Message.getFieldWithDefault(msg, 2, 0),
    neckYellow: jspb.Message.getFieldWithDefault(msg, 3, 0),
    backGreen: jspb.Message.getFieldWithDefault(msg, 4, 0),
    backYellow: jspb.Message.getFieldWithDefault(msg, 5, 0),
    legGreen: jspb.Message.getFieldWithDefault(msg, 6, 0),
    legYellow: jspb.Message.getFieldWithDefault(msg, 7, 0),
    handGreen: jspb.Message.getFieldWithDefault(msg, 8, 0),
    handYellow: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.UpdateTaskVideoAngleConfig;
  return proto.videoTask.UpdateTaskVideoAngleConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.UpdateTaskVideoAngleConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckGreen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckYellow(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackGreen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackYellow(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegGreen(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegYellow(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandGreen(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandYellow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.UpdateTaskVideoAngleConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.UpdateTaskVideoAngleConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.UpdateTaskVideoAngleConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNeckGreen();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNeckYellow();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBackGreen();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBackYellow();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLegGreen();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLegYellow();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHandGreen();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHandYellow();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 video_id = 1;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 neck_green = 2;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getNeckGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setNeckGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 neck_yellow = 3;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getNeckYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setNeckYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 back_green = 4;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getBackGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setBackGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 back_yellow = 5;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getBackYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setBackYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 leg_green = 6;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getLegGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setLegGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 leg_yellow = 7;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getLegYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setLegYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 hand_green = 8;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getHandGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setHandGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 hand_yellow = 9;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.getHandYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoAngleConfig} returns this
 */
proto.videoTask.UpdateTaskVideoAngleConfig.prototype.setHandYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.StatusForEventsTaskVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.StatusForEventsTaskVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.StatusForEventsTaskVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.StatusForEventsTaskVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.StatusForEventsTaskVideoResponse}
 */
proto.videoTask.StatusForEventsTaskVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.StatusForEventsTaskVideoResponse;
  return proto.videoTask.StatusForEventsTaskVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.StatusForEventsTaskVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.StatusForEventsTaskVideoResponse}
 */
proto.videoTask.StatusForEventsTaskVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.videoTask.StatusForEventsTaskVideo} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.StatusForEventsTaskVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.StatusForEventsTaskVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.StatusForEventsTaskVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.StatusForEventsTaskVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional StatusForEventsTaskVideo status = 1;
 * @return {!proto.videoTask.StatusForEventsTaskVideo}
 */
proto.videoTask.StatusForEventsTaskVideoResponse.prototype.getStatus = function() {
  return /** @type {!proto.videoTask.StatusForEventsTaskVideo} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.videoTask.StatusForEventsTaskVideo} value
 * @return {!proto.videoTask.StatusForEventsTaskVideoResponse} returns this
 */
proto.videoTask.StatusForEventsTaskVideoResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.EventsForVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.EventsForVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.EventsForVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.EventsForVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.EventsForVideoRequest}
 */
proto.videoTask.EventsForVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.EventsForVideoRequest;
  return proto.videoTask.EventsForVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.EventsForVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.EventsForVideoRequest}
 */
proto.videoTask.EventsForVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.EventsForVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.EventsForVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.EventsForVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.EventsForVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.videoTask.EventsForVideoRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.EventsForVideoRequest} returns this
 */
proto.videoTask.EventsForVideoRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DownloadProcessedVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DownloadProcessedVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DownloadProcessedVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DownloadProcessedVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DownloadProcessedVideoResponse}
 */
proto.videoTask.DownloadProcessedVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DownloadProcessedVideoResponse;
  return proto.videoTask.DownloadProcessedVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DownloadProcessedVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DownloadProcessedVideoResponse}
 */
proto.videoTask.DownloadProcessedVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DownloadProcessedVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DownloadProcessedVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DownloadProcessedVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DownloadProcessedVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.videoTask.DownloadProcessedVideoResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.DownloadProcessedVideoResponse} returns this
 */
proto.videoTask.DownloadProcessedVideoResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.CreateTaskVideoComparisonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.CreateTaskVideoComparisonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideoComparisonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstTaskVideoId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    secondTaskVideoId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.CreateTaskVideoComparisonRequest;
  return proto.videoTask.CreateTaskVideoComparisonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.CreateTaskVideoComparisonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirstTaskVideoId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecondTaskVideoId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.CreateTaskVideoComparisonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.CreateTaskVideoComparisonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideoComparisonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSecondTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 first_task_video_id = 2;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.getFirstTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.setFirstTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 second_task_video_id = 3;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.getSecondTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.setSecondTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 company_id = 5;
 * @return {number}
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.CreateTaskVideoComparisonRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.UpdateTaskVideoComparisonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.UpdateTaskVideoComparisonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstTaskVideoId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    secondTaskVideoId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.UpdateTaskVideoComparisonRequest;
  return proto.videoTask.UpdateTaskVideoComparisonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.UpdateTaskVideoComparisonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirstTaskVideoId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecondTaskVideoId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.UpdateTaskVideoComparisonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.UpdateTaskVideoComparisonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSecondTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 first_task_video_id = 3;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.getFirstTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.setFirstTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 second_task_video_id = 4;
 * @return {number}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.getSecondTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.setSecondTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.UpdateTaskVideoComparisonRequest} returns this
 */
proto.videoTask.UpdateTaskVideoComparisonRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoComparisonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoComparisonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoComparisonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstTaskVideo: (f = msg.getFirstTaskVideo()) && proto.videoTask.TaskVideoResponse.toObject(includeInstance, f),
    secondTaskVideo: (f = msg.getSecondTaskVideo()) && proto.videoTask.TaskVideoResponse.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    overallImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    backImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    neckImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    armRightImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    armLeftImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    legRightImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    legLeftImprovement: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoComparisonResponse}
 */
proto.videoTask.TaskVideoComparisonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoComparisonResponse;
  return proto.videoTask.TaskVideoComparisonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoComparisonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoComparisonResponse}
 */
proto.videoTask.TaskVideoComparisonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.videoTask.TaskVideoResponse;
      reader.readMessage(value,proto.videoTask.TaskVideoResponse.deserializeBinaryFromReader);
      msg.setFirstTaskVideo(value);
      break;
    case 4:
      var value = new proto.videoTask.TaskVideoResponse;
      reader.readMessage(value,proto.videoTask.TaskVideoResponse.deserializeBinaryFromReader);
      msg.setSecondTaskVideo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverallImprovement(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackImprovement(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNeckImprovement(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmRightImprovement(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmLeftImprovement(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLegRightImprovement(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLegLeftImprovement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoComparisonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoComparisonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoComparisonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstTaskVideo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.videoTask.TaskVideoResponse.serializeBinaryToWriter
    );
  }
  f = message.getSecondTaskVideo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.videoTask.TaskVideoResponse.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOverallImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBackImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getNeckImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getArmRightImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getArmLeftImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getLegRightImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getLegLeftImprovement();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TaskVideoResponse first_task_video = 3;
 * @return {?proto.videoTask.TaskVideoResponse}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getFirstTaskVideo = function() {
  return /** @type{?proto.videoTask.TaskVideoResponse} */ (
    jspb.Message.getWrapperField(this, proto.videoTask.TaskVideoResponse, 3));
};


/**
 * @param {?proto.videoTask.TaskVideoResponse|undefined} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
*/
proto.videoTask.TaskVideoComparisonResponse.prototype.setFirstTaskVideo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.clearFirstTaskVideo = function() {
  return this.setFirstTaskVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.hasFirstTaskVideo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TaskVideoResponse second_task_video = 4;
 * @return {?proto.videoTask.TaskVideoResponse}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getSecondTaskVideo = function() {
  return /** @type{?proto.videoTask.TaskVideoResponse} */ (
    jspb.Message.getWrapperField(this, proto.videoTask.TaskVideoResponse, 4));
};


/**
 * @param {?proto.videoTask.TaskVideoResponse|undefined} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
*/
proto.videoTask.TaskVideoComparisonResponse.prototype.setSecondTaskVideo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.clearSecondTaskVideo = function() {
  return this.setSecondTaskVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.hasSecondTaskVideo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string created_by = 7;
 * @return {string}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 company_id = 8;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double overall_improvement = 9;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getOverallImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setOverallImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double back_improvement = 10;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getBackImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setBackImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double neck_improvement = 11;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getNeckImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setNeckImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double arm_right_improvement = 12;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getArmRightImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setArmRightImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double arm_left_improvement = 13;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getArmLeftImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setArmLeftImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double leg_right_improvement = 14;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getLegRightImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setLegRightImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double leg_left_improvement = 15;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.getLegLeftImprovement = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonResponse} returns this
 */
proto.videoTask.TaskVideoComparisonResponse.prototype.setLegLeftImprovement = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoComparisonListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoComparisonListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoComparisonListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoComparisonListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoComparisonListRequest}
 */
proto.videoTask.TaskVideoComparisonListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoComparisonListRequest;
  return proto.videoTask.TaskVideoComparisonListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoComparisonListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoComparisonListRequest}
 */
proto.videoTask.TaskVideoComparisonListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoComparisonListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoComparisonListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoComparisonListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoComparisonListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoComparisonListRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoComparisonListRequest} returns this
 */
proto.videoTask.TaskVideoComparisonListRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.TaskVideoComparisonListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoComparisonListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoComparisonListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoComparisonListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoComparisonListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.videoTask.TaskVideoComparisonResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoComparisonListResponse}
 */
proto.videoTask.TaskVideoComparisonListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoComparisonListResponse;
  return proto.videoTask.TaskVideoComparisonListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoComparisonListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoComparisonListResponse}
 */
proto.videoTask.TaskVideoComparisonListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.videoTask.TaskVideoComparisonResponse;
      reader.readMessage(value,proto.videoTask.TaskVideoComparisonResponse.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoComparisonListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoComparisonListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoComparisonListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoComparisonListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.videoTask.TaskVideoComparisonResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaskVideoComparisonResponse list = 1;
 * @return {!Array<!proto.videoTask.TaskVideoComparisonResponse>}
 */
proto.videoTask.TaskVideoComparisonListResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.videoTask.TaskVideoComparisonResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.videoTask.TaskVideoComparisonResponse, 1));
};


/**
 * @param {!Array<!proto.videoTask.TaskVideoComparisonResponse>} value
 * @return {!proto.videoTask.TaskVideoComparisonListResponse} returns this
*/
proto.videoTask.TaskVideoComparisonListResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.videoTask.TaskVideoComparisonResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.videoTask.TaskVideoComparisonResponse}
 */
proto.videoTask.TaskVideoComparisonListResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.videoTask.TaskVideoComparisonResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.TaskVideoComparisonListResponse} returns this
 */
proto.videoTask.TaskVideoComparisonListResponse.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoComparisonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskVideoComparisonIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsRequest}
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoComparisonsRequest;
  return proto.videoTask.DeleteTaskVideoComparisonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsRequest}
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTaskVideoComparisonIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoComparisonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskVideoComparisonIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 task_video_comparison_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.prototype.getTaskVideoComparisonIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsRequest} returns this
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.prototype.setTaskVideoComparisonIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsRequest} returns this
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.prototype.addTaskVideoComparisonIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsRequest} returns this
 */
proto.videoTask.DeleteTaskVideoComparisonsRequest.prototype.clearTaskVideoComparisonIdsList = function() {
  return this.setTaskVideoComparisonIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoComparisonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsResponse}
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoComparisonsResponse;
  return proto.videoTask.DeleteTaskVideoComparisonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsResponse}
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoComparisonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.DeleteTaskVideoComparisonsResponse} returns this
 */
proto.videoTask.DeleteTaskVideoComparisonsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.VideoRisks.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.VideoRisks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.VideoRisks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.VideoRisks.toObject = function(includeInstance, msg) {
  var f, obj = {
    overallRisk: jspb.Message.getFieldWithDefault(msg, 1, 0),
    backRisk: jspb.Message.getFieldWithDefault(msg, 2, 0),
    neckRisk: jspb.Message.getFieldWithDefault(msg, 3, 0),
    armLeftRisk: jspb.Message.getFieldWithDefault(msg, 4, 0),
    armRightRisk: jspb.Message.getFieldWithDefault(msg, 5, 0),
    legLeftRisk: jspb.Message.getFieldWithDefault(msg, 6, 0),
    legRightRisk: jspb.Message.getFieldWithDefault(msg, 7, 0),
    backGreenTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    backYellowTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    backRedTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    neckGreenTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    neckYellowTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    neckRedTime: jspb.Message.getFieldWithDefault(msg, 13, 0),
    armLeftGreenTime: jspb.Message.getFieldWithDefault(msg, 14, 0),
    armLeftYellowTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
    armLeftRedTime: jspb.Message.getFieldWithDefault(msg, 16, 0),
    armRightGreenTime: jspb.Message.getFieldWithDefault(msg, 17, 0),
    armRightYellowTime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    armRightRedTime: jspb.Message.getFieldWithDefault(msg, 19, 0),
    legLeftGreenTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    legLeftYellowTime: jspb.Message.getFieldWithDefault(msg, 21, 0),
    legLeftRedTime: jspb.Message.getFieldWithDefault(msg, 22, 0),
    legRightGreenTime: jspb.Message.getFieldWithDefault(msg, 23, 0),
    legRightYellowTime: jspb.Message.getFieldWithDefault(msg, 24, 0),
    legRightRedTime: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.VideoRisks}
 */
proto.videoTask.VideoRisks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.VideoRisks;
  return proto.videoTask.VideoRisks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.VideoRisks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.VideoRisks}
 */
proto.videoTask.VideoRisks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setOverallRisk(value);
      break;
    case 2:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setBackRisk(value);
      break;
    case 3:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setNeckRisk(value);
      break;
    case 4:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setArmLeftRisk(value);
      break;
    case 5:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setArmRightRisk(value);
      break;
    case 6:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setLegLeftRisk(value);
      break;
    case 7:
      var value = /** @type {!proto.taskVideoRisks.Risks} */ (reader.readEnum());
      msg.setLegRightRisk(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackGreenTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackYellowTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackRedTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckGreenTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckYellowTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeckRedTime(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmLeftGreenTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmLeftYellowTime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmLeftRedTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmRightGreenTime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmRightYellowTime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmRightRedTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegLeftGreenTime(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegLeftYellowTime(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegLeftRedTime(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegRightGreenTime(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegRightYellowTime(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegRightRedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.VideoRisks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.VideoRisks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.VideoRisks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.VideoRisks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverallRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBackRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNeckRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getArmLeftRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getArmRightRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLegLeftRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLegRightRisk();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getBackGreenTime();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getBackYellowTime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBackRedTime();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getNeckGreenTime();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getNeckYellowTime();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getNeckRedTime();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getArmLeftGreenTime();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getArmLeftYellowTime();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getArmLeftRedTime();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getArmRightGreenTime();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getArmRightYellowTime();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getArmRightRedTime();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getLegLeftGreenTime();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getLegLeftYellowTime();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getLegLeftRedTime();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getLegRightGreenTime();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getLegRightYellowTime();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getLegRightRedTime();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
};


/**
 * optional taskVideoRisks.Risks overall_risk = 1;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getOverallRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setOverallRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional taskVideoRisks.Risks back_risk = 2;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getBackRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setBackRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional taskVideoRisks.Risks neck_risk = 3;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getNeckRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setNeckRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional taskVideoRisks.Risks arm_left_risk = 4;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getArmLeftRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmLeftRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional taskVideoRisks.Risks arm_right_risk = 5;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getArmRightRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmRightRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional taskVideoRisks.Risks leg_left_risk = 6;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getLegLeftRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegLeftRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional taskVideoRisks.Risks leg_right_risk = 7;
 * @return {!proto.taskVideoRisks.Risks}
 */
proto.videoTask.VideoRisks.prototype.getLegRightRisk = function() {
  return /** @type {!proto.taskVideoRisks.Risks} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.taskVideoRisks.Risks} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegRightRisk = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 back_green_time = 8;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getBackGreenTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setBackGreenTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 back_yellow_time = 9;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getBackYellowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setBackYellowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 back_red_time = 10;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getBackRedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setBackRedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 neck_green_time = 11;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getNeckGreenTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setNeckGreenTime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 neck_yellow_time = 12;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getNeckYellowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setNeckYellowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 neck_red_time = 13;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getNeckRedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setNeckRedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 arm_left_green_time = 14;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getArmLeftGreenTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmLeftGreenTime = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 arm_left_yellow_time = 15;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getArmLeftYellowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmLeftYellowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 arm_left_red_time = 16;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getArmLeftRedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmLeftRedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 arm_right_green_time = 17;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getArmRightGreenTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmRightGreenTime = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 arm_right_yellow_time = 18;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getArmRightYellowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmRightYellowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 arm_right_red_time = 19;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getArmRightRedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setArmRightRedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 leg_left_green_time = 20;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getLegLeftGreenTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegLeftGreenTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 leg_left_yellow_time = 21;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getLegLeftYellowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegLeftYellowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 leg_left_red_time = 22;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getLegLeftRedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegLeftRedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 leg_right_green_time = 23;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getLegRightGreenTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegRightGreenTime = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 leg_right_yellow_time = 24;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getLegRightYellowTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegRightYellowTime = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 leg_right_red_time = 25;
 * @return {number}
 */
proto.videoTask.VideoRisks.prototype.getLegRightRedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.VideoRisks} returns this
 */
proto.videoTask.VideoRisks.prototype.setLegRightRedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.TaskVideoCreatedBy.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.TaskVideoCreatedBy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.TaskVideoCreatedBy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoCreatedBy.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.TaskVideoCreatedBy}
 */
proto.videoTask.TaskVideoCreatedBy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.TaskVideoCreatedBy;
  return proto.videoTask.TaskVideoCreatedBy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.TaskVideoCreatedBy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.TaskVideoCreatedBy}
 */
proto.videoTask.TaskVideoCreatedBy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.TaskVideoCreatedBy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.TaskVideoCreatedBy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.TaskVideoCreatedBy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.TaskVideoCreatedBy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.videoTask.TaskVideoCreatedBy.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.TaskVideoCreatedBy} returns this
 */
proto.videoTask.TaskVideoCreatedBy.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.videoTask.TaskVideoCreatedBy.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.TaskVideoCreatedBy} returns this
 */
proto.videoTask.TaskVideoCreatedBy.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.SetDepartmentForVideosRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetDepartmentForVideosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetDepartmentForVideosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetDepartmentForVideosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    departmentId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetDepartmentForVideosRequest}
 */
proto.videoTask.SetDepartmentForVideosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetDepartmentForVideosRequest;
  return proto.videoTask.SetDepartmentForVideosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetDepartmentForVideosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetDepartmentForVideosRequest}
 */
proto.videoTask.SetDepartmentForVideosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVideoIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetDepartmentForVideosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetDepartmentForVideosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetDepartmentForVideosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 video_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.getVideoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.SetDepartmentForVideosRequest} returns this
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.setVideoIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.SetDepartmentForVideosRequest} returns this
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.addVideoIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.SetDepartmentForVideosRequest} returns this
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.clearVideoIdsList = function() {
  return this.setVideoIdsList([]);
};


/**
 * optional int32 department_id = 2;
 * @return {number}
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.SetDepartmentForVideosRequest} returns this
 */
proto.videoTask.SetDepartmentForVideosRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetDepartmentForVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetDepartmentForVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetDepartmentForVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetDepartmentForVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetDepartmentForVideosResponse}
 */
proto.videoTask.SetDepartmentForVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetDepartmentForVideosResponse;
  return proto.videoTask.SetDepartmentForVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetDepartmentForVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetDepartmentForVideosResponse}
 */
proto.videoTask.SetDepartmentForVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetDepartmentForVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetDepartmentForVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetDepartmentForVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetDepartmentForVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.videoTask.SetDepartmentForVideosResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.SetDepartmentForVideosResponse} returns this
 */
proto.videoTask.SetDepartmentForVideosResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.SetSiteForVideosRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetSiteForVideosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetSiteForVideosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetSiteForVideosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetSiteForVideosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    siteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetSiteForVideosRequest}
 */
proto.videoTask.SetSiteForVideosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetSiteForVideosRequest;
  return proto.videoTask.SetSiteForVideosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetSiteForVideosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetSiteForVideosRequest}
 */
proto.videoTask.SetSiteForVideosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVideoIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetSiteForVideosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetSiteForVideosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetSiteForVideosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetSiteForVideosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 video_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.SetSiteForVideosRequest.prototype.getVideoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.SetSiteForVideosRequest} returns this
 */
proto.videoTask.SetSiteForVideosRequest.prototype.setVideoIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.SetSiteForVideosRequest} returns this
 */
proto.videoTask.SetSiteForVideosRequest.prototype.addVideoIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.SetSiteForVideosRequest} returns this
 */
proto.videoTask.SetSiteForVideosRequest.prototype.clearVideoIdsList = function() {
  return this.setVideoIdsList([]);
};


/**
 * optional int32 site_id = 2;
 * @return {number}
 */
proto.videoTask.SetSiteForVideosRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.SetSiteForVideosRequest} returns this
 */
proto.videoTask.SetSiteForVideosRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetSiteForVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetSiteForVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetSiteForVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetSiteForVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetSiteForVideosResponse}
 */
proto.videoTask.SetSiteForVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetSiteForVideosResponse;
  return proto.videoTask.SetSiteForVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetSiteForVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetSiteForVideosResponse}
 */
proto.videoTask.SetSiteForVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetSiteForVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetSiteForVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetSiteForVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetSiteForVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.videoTask.SetSiteForVideosResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.SetSiteForVideosResponse} returns this
 */
proto.videoTask.SetSiteForVideosResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.SetJobRoleForVideosRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetJobRoleForVideosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetJobRoleForVideosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetJobRoleForVideosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    jobRoleId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetJobRoleForVideosRequest}
 */
proto.videoTask.SetJobRoleForVideosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetJobRoleForVideosRequest;
  return proto.videoTask.SetJobRoleForVideosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetJobRoleForVideosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetJobRoleForVideosRequest}
 */
proto.videoTask.SetJobRoleForVideosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVideoIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetJobRoleForVideosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetJobRoleForVideosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetJobRoleForVideosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getJobRoleId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 video_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.getVideoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.SetJobRoleForVideosRequest} returns this
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.setVideoIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.SetJobRoleForVideosRequest} returns this
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.addVideoIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.SetJobRoleForVideosRequest} returns this
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.clearVideoIdsList = function() {
  return this.setVideoIdsList([]);
};


/**
 * optional int32 job_role_id = 2;
 * @return {number}
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.getJobRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.SetJobRoleForVideosRequest} returns this
 */
proto.videoTask.SetJobRoleForVideosRequest.prototype.setJobRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetJobRoleForVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetJobRoleForVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetJobRoleForVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetJobRoleForVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetJobRoleForVideosResponse}
 */
proto.videoTask.SetJobRoleForVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetJobRoleForVideosResponse;
  return proto.videoTask.SetJobRoleForVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetJobRoleForVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetJobRoleForVideosResponse}
 */
proto.videoTask.SetJobRoleForVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetJobRoleForVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetJobRoleForVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetJobRoleForVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetJobRoleForVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.videoTask.SetJobRoleForVideosResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.SetJobRoleForVideosResponse} returns this
 */
proto.videoTask.SetJobRoleForVideosResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.SetTaskNameForVideosRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetTaskNameForVideosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetTaskNameForVideosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetTaskNameForVideosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    taskNameId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetTaskNameForVideosRequest}
 */
proto.videoTask.SetTaskNameForVideosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetTaskNameForVideosRequest;
  return proto.videoTask.SetTaskNameForVideosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetTaskNameForVideosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetTaskNameForVideosRequest}
 */
proto.videoTask.SetTaskNameForVideosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVideoIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskNameId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetTaskNameForVideosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetTaskNameForVideosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetTaskNameForVideosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getTaskNameId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 video_ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.getVideoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.SetTaskNameForVideosRequest} returns this
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.setVideoIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.SetTaskNameForVideosRequest} returns this
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.addVideoIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.SetTaskNameForVideosRequest} returns this
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.clearVideoIdsList = function() {
  return this.setVideoIdsList([]);
};


/**
 * optional int32 task_name_id = 2;
 * @return {number}
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.getTaskNameId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.SetTaskNameForVideosRequest} returns this
 */
proto.videoTask.SetTaskNameForVideosRequest.prototype.setTaskNameId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.SetTaskNameForVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.SetTaskNameForVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.SetTaskNameForVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetTaskNameForVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.SetTaskNameForVideosResponse}
 */
proto.videoTask.SetTaskNameForVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.SetTaskNameForVideosResponse;
  return proto.videoTask.SetTaskNameForVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.SetTaskNameForVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.SetTaskNameForVideosResponse}
 */
proto.videoTask.SetTaskNameForVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.SetTaskNameForVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.SetTaskNameForVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.SetTaskNameForVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.SetTaskNameForVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.videoTask.SetTaskNameForVideosResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.SetTaskNameForVideosResponse} returns this
 */
proto.videoTask.SetTaskNameForVideosResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.CreateTaskVideosRebaWeightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskVideosRebaWeightsList: jspb.Message.toObjectList(msg.getTaskVideosRebaWeightsList(),
    proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.CreateTaskVideosRebaWeightsRequest;
  return proto.videoTask.CreateTaskVideosRebaWeightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight;
      reader.readMessage(value,proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.deserializeBinaryFromReader);
      msg.addTaskVideosRebaWeights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.CreateTaskVideosRebaWeightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskVideosRebaWeightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    weightType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timeStart: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timeEnd: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight;
  return proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeight(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeightType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getWeightType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTimeEnd();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 task_video_id = 1;
 * @return {number}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float weight = 2;
 * @return {number}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string weight_type = 3;
 * @return {string}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.getWeightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.setWeightType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 time_start = 4;
 * @return {number}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 time_end = 5;
 * @return {number}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.getTimeEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight.prototype.setTimeEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated TaskVideoRebaWeight task_videos_reba_weights = 1;
 * @return {!Array<!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight>}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.prototype.getTaskVideosRebaWeightsList = function() {
  return /** @type{!Array<!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight, 1));
};


/**
 * @param {!Array<!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight>} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} returns this
*/
proto.videoTask.CreateTaskVideosRebaWeightsRequest.prototype.setTaskVideosRebaWeightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight}
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.prototype.addTaskVideosRebaWeights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.videoTask.CreateTaskVideosRebaWeightsRequest.TaskVideoRebaWeight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsRequest} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsRequest.prototype.clearTaskVideosRebaWeightsList = function() {
  return this.setTaskVideosRebaWeightsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.CreateTaskVideosRebaWeightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rebaWeightsList: jspb.Message.toObjectList(msg.getRebaWeightsList(),
    proto.videoTask.RebaWeight.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsResponse}
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.CreateTaskVideosRebaWeightsResponse;
  return proto.videoTask.CreateTaskVideosRebaWeightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsResponse}
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.videoTask.RebaWeight;
      reader.readMessage(value,proto.videoTask.RebaWeight.deserializeBinaryFromReader);
      msg.addRebaWeights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.CreateTaskVideosRebaWeightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.CreateTaskVideosRebaWeightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRebaWeightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.videoTask.RebaWeight.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RebaWeight reba_weights = 1;
 * @return {!Array<!proto.videoTask.RebaWeight>}
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.prototype.getRebaWeightsList = function() {
  return /** @type{!Array<!proto.videoTask.RebaWeight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.videoTask.RebaWeight, 1));
};


/**
 * @param {!Array<!proto.videoTask.RebaWeight>} value
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsResponse} returns this
*/
proto.videoTask.CreateTaskVideosRebaWeightsResponse.prototype.setRebaWeightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.videoTask.RebaWeight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.videoTask.RebaWeight}
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.prototype.addRebaWeights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.videoTask.RebaWeight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.CreateTaskVideosRebaWeightsResponse} returns this
 */
proto.videoTask.CreateTaskVideosRebaWeightsResponse.prototype.clearRebaWeightsList = function() {
  return this.setRebaWeightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.GetTaskVideoRebaWeightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsRequest}
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.GetTaskVideoRebaWeightsRequest;
  return proto.videoTask.GetTaskVideoRebaWeightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsRequest}
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.GetTaskVideoRebaWeightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 task_video_id = 1;
 * @return {number}
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsRequest} returns this
 */
proto.videoTask.GetTaskVideoRebaWeightsRequest.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.GetTaskVideoRebaWeightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rebaWeightsList: jspb.Message.toObjectList(msg.getRebaWeightsList(),
    proto.videoTask.RebaWeight.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsResponse}
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.GetTaskVideoRebaWeightsResponse;
  return proto.videoTask.GetTaskVideoRebaWeightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsResponse}
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.videoTask.RebaWeight;
      reader.readMessage(value,proto.videoTask.RebaWeight.deserializeBinaryFromReader);
      msg.addRebaWeights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.GetTaskVideoRebaWeightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.GetTaskVideoRebaWeightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRebaWeightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.videoTask.RebaWeight.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RebaWeight reba_weights = 1;
 * @return {!Array<!proto.videoTask.RebaWeight>}
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.prototype.getRebaWeightsList = function() {
  return /** @type{!Array<!proto.videoTask.RebaWeight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.videoTask.RebaWeight, 1));
};


/**
 * @param {!Array<!proto.videoTask.RebaWeight>} value
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsResponse} returns this
*/
proto.videoTask.GetTaskVideoRebaWeightsResponse.prototype.setRebaWeightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.videoTask.RebaWeight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.videoTask.RebaWeight}
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.prototype.addRebaWeights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.videoTask.RebaWeight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.GetTaskVideoRebaWeightsResponse} returns this
 */
proto.videoTask.GetTaskVideoRebaWeightsResponse.prototype.clearRebaWeightsList = function() {
  return this.setRebaWeightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.RebaWeight.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.RebaWeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.RebaWeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.RebaWeight.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    weightType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeStart: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timeEnd: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.RebaWeight}
 */
proto.videoTask.RebaWeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.RebaWeight;
  return proto.videoTask.RebaWeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.RebaWeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.RebaWeight}
 */
proto.videoTask.RebaWeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeight(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeightType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.RebaWeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.RebaWeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.RebaWeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.RebaWeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getWeightType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTimeEnd();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.videoTask.RebaWeight.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.RebaWeight} returns this
 */
proto.videoTask.RebaWeight.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 task_video_id = 2;
 * @return {number}
 */
proto.videoTask.RebaWeight.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.RebaWeight} returns this
 */
proto.videoTask.RebaWeight.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float weight = 3;
 * @return {number}
 */
proto.videoTask.RebaWeight.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.RebaWeight} returns this
 */
proto.videoTask.RebaWeight.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string weight_type = 4;
 * @return {string}
 */
proto.videoTask.RebaWeight.prototype.getWeightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.RebaWeight} returns this
 */
proto.videoTask.RebaWeight.prototype.setWeightType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 time_start = 5;
 * @return {number}
 */
proto.videoTask.RebaWeight.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.RebaWeight} returns this
 */
proto.videoTask.RebaWeight.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 time_end = 6;
 * @return {number}
 */
proto.videoTask.RebaWeight.prototype.getTimeEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.RebaWeight} returns this
 */
proto.videoTask.RebaWeight.prototype.setTimeEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoComparisonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoComparisonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskVideoComparisonId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonRequest}
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoComparisonRequest;
  return proto.videoTask.DeleteTaskVideoComparisonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonRequest}
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoComparisonId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoComparisonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskVideoComparisonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 task_video_comparison_id = 1;
 * @return {number}
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.prototype.getTaskVideoComparisonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.DeleteTaskVideoComparisonRequest} returns this
 */
proto.videoTask.DeleteTaskVideoComparisonRequest.prototype.setTaskVideoComparisonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoComparisonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoComparisonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonResponse}
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoComparisonResponse;
  return proto.videoTask.DeleteTaskVideoComparisonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoComparisonResponse}
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoComparisonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoComparisonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.DeleteTaskVideoComparisonResponse} returns this
 */
proto.videoTask.DeleteTaskVideoComparisonResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoRebaWeightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoRebaWeightsRequest;
  return proto.videoTask.DeleteTaskVideoRebaWeightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoRebaWeightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 ids = 1;
 * @return {!Array<number>}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} returns this
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} returns this
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsRequest} returns this
 */
proto.videoTask.DeleteTaskVideoRebaWeightsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoRebaWeightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: jspb.Message.toObjectList(msg.getStatusesList(),
    proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoRebaWeightsResponse;
  return proto.videoTask.DeleteTaskVideoRebaWeightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status;
      reader.readMessage(value,proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.deserializeBinaryFromReader);
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoRebaWeightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status;
  return proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status} returns this
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status} returns this
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Status statuses = 1;
 * @return {!Array<!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status>}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.prototype.getStatusesList = function() {
  return /** @type{!Array<!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status, 1));
};


/**
 * @param {!Array<!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status>} value
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse} returns this
*/
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.prototype.setStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status}
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.prototype.addStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.videoTask.DeleteTaskVideoRebaWeightsResponse.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.videoTask.DeleteTaskVideoRebaWeightsResponse} returns this
 */
proto.videoTask.DeleteTaskVideoRebaWeightsResponse.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.EventsForRebaWeightRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.EventsForRebaWeightRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.EventsForRebaWeightRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.EventsForRebaWeightRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    videoId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.EventsForRebaWeightRequest}
 */
proto.videoTask.EventsForRebaWeightRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.EventsForRebaWeightRequest;
  return proto.videoTask.EventsForRebaWeightRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.EventsForRebaWeightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.EventsForRebaWeightRequest}
 */
proto.videoTask.EventsForRebaWeightRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.EventsForRebaWeightRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.EventsForRebaWeightRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.EventsForRebaWeightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.EventsForRebaWeightRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 video_id = 1;
 * @return {number}
 */
proto.videoTask.EventsForRebaWeightRequest.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.videoTask.EventsForRebaWeightRequest} returns this
 */
proto.videoTask.EventsForRebaWeightRequest.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.videoTask.StatusForEventsRebaWeightResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.videoTask.StatusForEventsRebaWeightResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.videoTask.StatusForEventsRebaWeightResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.StatusForEventsRebaWeightResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.videoTask.StatusForEventsRebaWeightResponse}
 */
proto.videoTask.StatusForEventsRebaWeightResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.videoTask.StatusForEventsRebaWeightResponse;
  return proto.videoTask.StatusForEventsRebaWeightResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.videoTask.StatusForEventsRebaWeightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.videoTask.StatusForEventsRebaWeightResponse}
 */
proto.videoTask.StatusForEventsRebaWeightResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.videoTask.StatusForEventsRebaWeightResponse.StatusForEventsRebaWeight} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.videoTask.StatusForEventsRebaWeightResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.videoTask.StatusForEventsRebaWeightResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.videoTask.StatusForEventsRebaWeightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.videoTask.StatusForEventsRebaWeightResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.videoTask.StatusForEventsRebaWeightResponse.StatusForEventsRebaWeight = {
  NONE: 0,
  IN_PROGRESS: 1,
  PROCESSED: 2
};

/**
 * optional StatusForEventsRebaWeight status = 1;
 * @return {!proto.videoTask.StatusForEventsRebaWeightResponse.StatusForEventsRebaWeight}
 */
proto.videoTask.StatusForEventsRebaWeightResponse.prototype.getStatus = function() {
  return /** @type {!proto.videoTask.StatusForEventsRebaWeightResponse.StatusForEventsRebaWeight} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.videoTask.StatusForEventsRebaWeightResponse.StatusForEventsRebaWeight} value
 * @return {!proto.videoTask.StatusForEventsRebaWeightResponse} returns this
 */
proto.videoTask.StatusForEventsRebaWeightResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.videoTask.StatusForEventsTaskVideo = {
  NONE: 0,
  UPDATED: 1
};

goog.object.extend(exports, proto.videoTask);
