import * as types from './actionTypes';

export function setSelectedDepartmentNames(departmentNames) {
  return ({
    type: types.SET_SELECTED_DEPARTMENT_NAMES,
    names: departmentNames,
  });
}

export function setAllSelectedDepartments(departmentNames) {
  return ({
    type: types.SET_ALL_SELECTED_DEPARTMENTS,
    names: departmentNames,
  });
}

export function setSelectedPeriod(period) {
  return ({
    type: types.SET_SELECTED_PERIOD,
    period,
  });
}

export function setSelectedGrouping(groupBy, chartType) {
  return ({
    type: types.SET_SELECTED_GROUPING,
    groupBy,
    chartType,
  });
}

export function resetOverviewState() {
  return {
    type: types.RESET_OVERVIEW_STATE,
  };
}
