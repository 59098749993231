import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import { ReactComponent as StayTunedImg } from 'assets/img/stayTuned.svg';
import { mb2, mb4, mt2 } from 'assets/soter-flex-styles';
// @ts-ignore
import wearablesExpiredImage from 'assets/img/wearables_expired.jpg';
// @ts-ignore
import jobDictionaryExpiredImage from 'assets/img/jobDictionary_expired.jpg';
// @ts-ignore
import soterGeniusExpiredImage from 'assets/img/soterGenius_expired.jpg';
import SoterTaskInfoBanner from '../../../task/components/infoBanner/SoterTaskInfoBanner';
const wrapper = Emotion.css `
  position: relative;

  .requestSubmitted {
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
`;
const subscriptionEndedImg = Emotion.css `
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0.75rem;
  min-height: 80vh;
`;
const SubscriptionEnded = ({ name, productInfo }) => {
    const { t } = useTranslation();
    const expiredSubscriptionImages = {
        'Wearables': wearablesExpiredImage,
        'Job Dictionary': jobDictionaryExpiredImage,
        'geniusProduct': soterGeniusExpiredImage,
    };
    const expiredSubscriptionImage = expiredSubscriptionImages[name] || wearablesExpiredImage;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: wrapper },
            (productInfo === null || productInfo === void 0 ? void 0 : productInfo.isSubscriptionRequested) ? (React.createElement(React.Fragment, null,
                React.createElement(SoterTaskInfoBanner, { productInfo: productInfo, name: name }),
                React.createElement("div", { className: "requestSubmitted", style: { textAlign: 'center' } },
                    React.createElement(StayTunedImg, null),
                    React.createElement("h4", { className: `${mt2} ${mb2}` }, t('SOTER_TASK.SUBSCRIPTION_EXPIRED.HEADER_SENT')),
                    React.createElement("p", { className: mb4, style: { fontSize: '18px', maxWidth: '350px', margin: '0 auto 16px' } }, `${t('SOTER_TASK.SUBSCRIPTION_EXPIRED.MESSAGE_SENT')}`)))) : React.createElement(SoterTaskInfoBanner, { productInfo: productInfo, name: name }),
            React.createElement("img", { className: subscriptionEndedImg, src: expiredSubscriptionImage, alt: "" }))));
};
export default SubscriptionEnded;
