import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'lib/ui/Text';
import Button from 'lib/ui/Button';
import { ReactComponent as UploadVideo } from '../../../assets/img/upload-video.svg';
import { ReactComponent as GetProcessedVideo } from '../../../assets/img/get-processed-video.svg';
import { ReactComponent as DetailedAnalysis } from '../../../assets/img/detailed-analysis.svg';
import { coloredButton, container, flexColumn, flexRow, mt6, } from '../../../assets/soter-flex-styles';
import { actionBox, appStore, description, divider, googlePlay, links, step, title, wrapper, } from './styles';
import { COLORS } from '../../../constants/colors';
import useNumberOfTrialDaysLeftBanner from '../../overview/useNumberOfTrialDaysLeftBanner';
import StickyOnScrollContainer from '../../../containers/ItemTableContainer/components/StickyOnScrollContainer';
import { useAppSelector } from 'lib/utils/useStore';
import Loader from 'lib/ui/Loader';

const SoterTaskOnboarding = ({
  addNewVideo,
  openQRCode,
  isStatusAvailable,
  startTrialHandler,
  isUploadingAvailable,
  setQrCode,
}) => {
  const { t } = useTranslation();
  const { error } = useAppSelector((store) => store.videos);
  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('geniusProduct');
  if (error && error.code !== 5) return <Loader />;
  return (
    <div style={{ minHeight: '85vh' }}>
      {numberOfTrialDaysLeftBanner && (
        <StickyOnScrollContainer
          topMargin={0}
          topBlock={numberOfTrialDaysLeftBanner}
          height={50}
        />
      )}
      <div className={`${container} ${mt6}`}>
        <div className={`${flexRow} ${wrapper}`}>
          <div className={`${flexColumn} ${step}`}>
            <UploadVideo />
            <Text
              text={t('SOTER_TASK.ONBOARDING.UPLOAD_VIDEO', 'Upload video')}
              appearance="headlineTwo"
              className={title}
              style={{ color: 'inherit' }}
            />
            <Text
              text={t('SOTER_TASK.ONBOARDING.UPLOAD_VIDEO_DESCRIPTION')}
              className={description}
            />
          </div>
          <div className={`${flexColumn} ${step}`}>
            <GetProcessedVideo />
            <Text
              text={t(
                'SOTER_TASK.ONBOARDING.GET_PROCESSED_VIDEO',
                'Get processed video',
              )}
              appearance="headlineTwo"
              className={title}
              style={{ color: 'inherit' }}
            />
            <Text
              text={t('SOTER_TASK.ONBOARDING.GET_PROCESSED_VIDEO_DESCRIPTION')}
              className={description}
            />
          </div>
          <div className={`${flexColumn} ${step}`}>
            <DetailedAnalysis />
            <Text
              text={t(
                'SOTER_TASK.ONBOARDING.DETAILED_ANALYSIS',
                'Detailed analysis',
              )}
              appearance="headlineTwo"
              className={title}
              style={{ color: 'inherit' }}
            />
            <Text
              text={t('SOTER_TASK.ONBOARDING.DETAILED_ANALYSIS_DESCRIPTION')}
              className={description}
            />
          </div>
        </div>
        <div className={actionBox}>
          {isStatusAvailable ? (
            <Button
              type="button"
              style={{ display: 'block', width: '100%' }}
              className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
              handler={startTrialHandler}
              text={t('SOTER_TASK.ONBOARDING.START_TRIAL', 'Start trial')}
            />
          ) : (
            isUploadingAvailable && (
              <>
                <Button
                  type="button"
                  style={{ display: 'block', width: '100%' }}
                  className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
                  handler={addNewVideo}
                  text={t(
                    'SOTER_TASK.ONBOARDING.UPLOAD_FIRST_VIDEO',
                    'Upload your first video',
                  )}
                />
              </>
            )
          )}
          <div className={divider}>
            <div />
            <Text
              text={t('SOTER_TASK.ONBOARDING.OR', 'Or')}
              appearance="footnote"
            />
            <div />
          </div>
          <Text
            text={t(
              'SOTER_TASK.ONBOARDING.DOWNLOAD_SOTERTASK',
              'Download SoterTask app for video recording',
            )}
            appearance="footnote"
          />
          <div className={links}>
            <Button
              type="button"
              aria-label="Open app QR-Code"
              className={appStore}
              handler={() => {
                openQRCode();
                setQrCode('ios');
              }}
            />
            <Button
              type="button"
              aria-label="Open app QR-Code"
              className={googlePlay}
              handler={() => {
                openQRCode();
                setQrCode('android');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoterTaskOnboarding;
