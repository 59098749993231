import React from 'react';
import { Group, Layer, Line, Stage, } from 'react-konva';

const DudeKonvaLayer = ({
  linesData, scale, width, height, videoWidth, videoHeight, selectedBodyPart,
}) => (
  <div style={{
    position: 'absolute',
    zIndex: 5,
    top: '0px',
    left: width > height ? '0px' : `${((videoWidth - (width * scale)) / 2)}px`,
  }}
  >
    <Stage id="dude_layer" width={width * scale || 0} height={videoHeight} scale={{ x: scale, y: scale }}>
      <Layer>
        <Group>
          {linesData?.map(({ points, color, bodyPart }, key) => (points[0] > 0 && points[2] > 0) && (
          <Line
            key={Math.random() + points[0]}
            id={key}
            stroke={color}
            points={points}
            strokeWidth={selectedBodyPart.includes(bodyPart) ? 10 : 5}
            lineJoin="round"
          />
          ))}
        </Group>
      </Layer>
    </Stage>
  </div>
);

export default DudeKonvaLayer;
