/* eslint no-underscore-dangle:0 */
/* eslint no-irregular-whitespace:0 */
import * as d3 from 'd3';

/* TickFormatter : the available type values are:
 * e - exponent notation.
 * f - fixed point notation.
 * g - either decimal or exponent notation, rounded to significant digits.
 * r - decimal notation, rounded to significant digits.
 * % - multiply by 100, and then decimal notation with a percent sign.
 * p - multiply by 100, round to significant digits
 *     , and then decimal notation with a percent sign.
 * b - binary notation, rounded to integer.
 * o - octal notation, rounded to integer.
 * d - decimal notation, rounded to integer.
 * x - hexadecimal notation, using lower-case letters, rounded to integer.
 * X - hexadecimal notation, using upper-case letters, rounded to integer.
 * c - converts the integer to the corresponding unicode character
 *     before printing.
 * The type ​ (none) is also supported as shorthand for ~g
 *     (with a default precision of 12 instead of 6),
 *     and the type n is shorthand for ,g.
 *     For the g, n and ​ (none) types, decimal notation
 *     is used if the resulting string would have precision or fewer digits;
 *     otherwise, exponent notation is used. */

export const LegendPosition = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
};

export const LegendMode = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export default class Chart {
  constructor(id) {
    this.id = id;
    this._width = 0;
    this._height = 0;
    this._config = [];
    this._data = [];
    this._xAxisTickFormatter = () => d3.format('');
    this._yAxisTickFormatter = () => d3.format('');
    this._fontName = 'Mulish';
    this._fontSize = '.6rem';
    this._fontWeight = 400;
    this._withLegend = false;
    this._legendMode = LegendMode.VERTICAL;
    this._legendPosition = LegendPosition.RIGHT;
    this._withRotatedXAxisTickText = true;
    this._max = 0;
  }

  get radius() {
    return Math.min(this.width, this.height) / 2.6;
  }

  setWidth(value) {
    this._width = value;
  }

  get width() {
    return this._width;
  }

  setHeight(value) {
    this._height = value;
  }

  get height() {
    return this._height;
  }

  setConfig(value) {
    this._config = value;
  }

  get config() {
    return this._config;
  }

  setData(value) {
    this._data = value;
  }

  get data() {
    return this._data;
  }

  setMax(value) {
    this._max = value;
  }

  get max() {
    return this._max;
  }

  setTranslation(t) {
    this.t = t;
  }

  setXAxisTickFormatter(value) {
    this._xAxisTickFormatter = value;
  }

  get xAxisTickFormatter() {
    return this._xAxisTickFormatter;
  }

  setYAxisTickFormatter(value) {
    this._yAxisTickFormatter = value;
  }

  get yAxisTickFormatter() {
    return this._yAxisTickFormatter;
  }

  setFontSize(fontSize) {
    this._fontSize = fontSize;
  }

  get fontSize() {
    return this._fontSize;
  }

  setFontWeight(fontWeight) {
    this._fontWeight = fontWeight;
  }

  get fontWeight() {
    return this._fontWeight;
  }

  setFontName(font) {
    this._fontName = font;
  }

  get fontName() {
    return this._fontName;
  }

  setWithLegend(bool) {
    this._withLegend = bool;
  }

  get withLegend() {
    return this._withLegend;
  }

  setLegendMode(position) {
    this._legendPosition = position;
  }

  get legendMode() {
    return this._legendPosition;
  }

  setLegendPosition(position) {
    this._legendPosition = position;
  }

  get legendPosition() {
    return this._legendPosition;
  }

  setWithRotatedXAxisTickText(bool) {
    this._withRotatedXAxisTickText = bool;
  }

  get withRotatedXAxisTickText() {
    return this._withRotatedXAxisTickText;
  }

  calculateLegendTranslation() {
    switch (this.legendPosition) {
      case LegendPosition.BOTTOM:
        return `${-this.width / 2.1}, ${this.height / 1.9}`;
      case LegendPosition.RIGHT:
        return `${this.width / 18}, ${-(this.height / 3) + (this.data.length - 1) * 45}`;
      case LegendPosition.TOP:
        return `0, ${this.width / 3.6}`;
      case LegendPosition.LEFT:
        return `${this.width / 3.6}, 0`;
      default:
        return '0, 0';
    }
  }

  calculateEmptyLegendTranslation(legendLength) {
    switch (this.legendPosition) {
      case LegendPosition.BOTTOM:
        return `${-this.width / 2.1}, ${this.height / 2.3}`;
      case LegendPosition.RIGHT:
        return `${this.width / 18}, ${-(this.height / 2) + legendLength * 45}`;
      case LegendPosition.TOP:
        return `0, ${this.width / 3.6}`;
      case LegendPosition.LEFT:
        return `${this.width / 3.6}, 0`;
      default:
        return '0, 0';
    }
  }

  calculateChartTranslation() {
    if (this.withLegend) {
      switch (this.legendPosition) {
        case LegendPosition.BOTTOM:
          return `0, ${-this.width / 2.8}`;
        case LegendPosition.RIGHT:
          return `${-this.width / 4.1}, 0`;
        case LegendPosition.TOP:
          return `0, ${this.width / 3.6}`;
        case LegendPosition.LEFT:
          return `${this.width / 3.6}, 0`;
        default:
          return '0, 0';
      }
    }
    return '0, 0';
  }

  drawSvg(params) {
    d3.select(`#${this.id}`)
      .append('svg')
      .attr('class', `${this.id}-chart`)
      .attr('viewBox', params);
  }

  // eslint-disable-next-line class-methods-use-this
  createGradients(svg, gradients) {
    svg.append('defs')
      .selectAll('linearGradient')
      .data(gradients)
      .join('linearGradient')
      .attr('id', (d) => d.hazard)
      .call((g) => g.append('stop')
        .attr('class', 'stop-left')
        .attr('offset', '0%')
        .attr('stop-color', (d) => d.leftStopColor))
      .call((g) => g.append('stop')
        .attr('class', 'stop-right')
        .attr('offset', '100%')
        .attr('stop-color', (d) => d.rightStopColor))
      .attr('gradientTransform', 'rotate(90)');
  }

  setTickTextAttr() {
    d3.selectAll('.tick>text')
      .attr('font', this.fontName)
      .attr('font-size', this.fontSize)
      .attr('font-weight', this.fontWeight);
  }

  // eslint-disable-next-line class-methods-use-this
  decimateYAxisTicks() {
    const YAxisTickText = d3.selectAll('.y-axis > .tick:nth-child(2n)');

    YAxisTickText
      .call((g) => g.selectAll('text')
        .attr('opacity', 0))
      .call((g) => g.selectAll('line')
        .attr('stroke-opacity', 0));
  }

  // eslint-disable-next-line class-methods-use-this
  rotateXAxisTickText(g) {
    return g.selectAll('.tick text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)');
  }

  clearChart() {
    const currentChart = d3.select(`#${this.id}`);
    if (currentChart) {
      currentChart.selectAll(`svg.${this.id}-chart`).remove();
    }
  }
}
