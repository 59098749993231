import { useRef } from 'react';

const useVideoTimeUpdateHandler = (setCurrentTimeMs) => {
  const videoUpdateInterval = useRef(0);
  return (e) => {
    clearInterval(videoUpdateInterval.current);

    let time = e.target.currentTime * 1000;
    setCurrentTimeMs(time);
    videoUpdateInterval.current = setInterval(() => {
      if (!e.target.paused && e.target.currentTime * 1000 > time + 100 / 3) {
        time += 100 / 3;
        setCurrentTimeMs(time);
      }
    });
  };
};

export default useVideoTimeUpdateHandler;
