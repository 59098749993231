// source: api/dashboard/v2/users_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_improvement_message_pb = require('../../../api/dashboard/v2/improvement_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_improvement_message_pb);
var api_dashboard_v2_report_data_message_pb = require('../../../api/dashboard/v2/report_data_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_report_data_message_pb);
var api_dashboard_v2_job_role_message_pb = require('../../../api/dashboard/v2/job_role_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_job_role_message_pb);
var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_site_message_pb);
var api_dashboard_v2_user_note_message_pb = require('../../../api/dashboard/v2/user_note_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_user_note_message_pb);
var api_dashboard_v2_segment_message_pb = require('../../../api/dashboard/v2/segment_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_segment_message_pb);
goog.exportSymbol('proto.users_message.FilterStatus', null, global);
goog.exportSymbol('proto.users_message.User', null, global);
goog.exportSymbol('proto.users_message.UsersAchievements', null, global);
goog.exportSymbol('proto.users_message.UsersCompany', null, global);
goog.exportSymbol('proto.users_message.UsersProgram', null, global);
goog.exportSymbol('proto.users_message.UsersProgress', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users_message.UsersCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users_message.UsersCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users_message.UsersCompany.displayName = 'proto.users_message.UsersCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users_message.UsersAchievements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users_message.UsersAchievements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users_message.UsersAchievements.displayName = 'proto.users_message.UsersAchievements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users_message.UsersProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users_message.UsersProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users_message.UsersProgram.displayName = 'proto.users_message.UsersProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users_message.UsersProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users_message.UsersProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users_message.UsersProgress.displayName = 'proto.users_message.UsersProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users_message.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users_message.User.repeatedFields_, null);
};
goog.inherits(proto.users_message.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users_message.User.displayName = 'proto.users_message.User';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users_message.UsersCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.users_message.UsersCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users_message.UsersCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportPeopleNames: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    showGroupedValues: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users_message.UsersCompany}
 */
proto.users_message.UsersCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users_message.UsersCompany;
  return proto.users_message.UsersCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users_message.UsersCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users_message.UsersCompany}
 */
proto.users_message.UsersCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReportPeopleNames(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowGroupedValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users_message.UsersCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users_message.UsersCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users_message.UsersCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportPeopleNames();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getShowGroupedValues();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.users_message.UsersCompany.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersCompany} returns this
 */
proto.users_message.UsersCompany.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.users_message.UsersCompany.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.UsersCompany} returns this
 */
proto.users_message.UsersCompany.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool report_people_names = 3;
 * @return {boolean}
 */
proto.users_message.UsersCompany.prototype.getReportPeopleNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users_message.UsersCompany} returns this
 */
proto.users_message.UsersCompany.prototype.setReportPeopleNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool show_grouped_values = 4;
 * @return {boolean}
 */
proto.users_message.UsersCompany.prototype.getShowGroupedValues = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users_message.UsersCompany} returns this
 */
proto.users_message.UsersCompany.prototype.setShowGroupedValues = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users_message.UsersAchievements.prototype.toObject = function(opt_includeInstance) {
  return proto.users_message.UsersAchievements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users_message.UsersAchievements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersAchievements.toObject = function(includeInstance, msg) {
  var f, obj = {
    goldGoalsQuantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    silverGoalsQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bronzeGoalsQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users_message.UsersAchievements}
 */
proto.users_message.UsersAchievements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users_message.UsersAchievements;
  return proto.users_message.UsersAchievements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users_message.UsersAchievements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users_message.UsersAchievements}
 */
proto.users_message.UsersAchievements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoldGoalsQuantity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSilverGoalsQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBronzeGoalsQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users_message.UsersAchievements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users_message.UsersAchievements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users_message.UsersAchievements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersAchievements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoldGoalsQuantity();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSilverGoalsQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBronzeGoalsQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 gold_goals_quantity = 1;
 * @return {number}
 */
proto.users_message.UsersAchievements.prototype.getGoldGoalsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersAchievements} returns this
 */
proto.users_message.UsersAchievements.prototype.setGoldGoalsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 silver_goals_quantity = 2;
 * @return {number}
 */
proto.users_message.UsersAchievements.prototype.getSilverGoalsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersAchievements} returns this
 */
proto.users_message.UsersAchievements.prototype.setSilverGoalsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 bronze_goals_quantity = 3;
 * @return {number}
 */
proto.users_message.UsersAchievements.prototype.getBronzeGoalsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersAchievements} returns this
 */
proto.users_message.UsersAchievements.prototype.setBronzeGoalsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users_message.UsersProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.users_message.UsersProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users_message.UsersProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    initialRiskGroup: jspb.Message.getFieldWithDefault(msg, 6, ""),
    initialRisk: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    currentRiskGroup: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentRisk: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    improvements: (f = msg.getImprovements()) && api_dashboard_v2_improvement_message_pb.Improvements.toObject(includeInstance, f),
    reportData: (f = msg.getReportData()) && api_dashboard_v2_report_data_message_pb.ReportData.toObject(includeInstance, f),
    progress: (f = msg.getProgress()) && proto.users_message.UsersProgress.toObject(includeInstance, f),
    achievements: (f = msg.getAchievements()) && proto.users_message.UsersAchievements.toObject(includeInstance, f),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    filterStatus: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users_message.UsersProgram}
 */
proto.users_message.UsersProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users_message.UsersProgram;
  return proto.users_message.UsersProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users_message.UsersProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users_message.UsersProgram}
 */
proto.users_message.UsersProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialRiskGroup(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInitialRisk(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentRiskGroup(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentRisk(value);
      break;
    case 8:
      var value = new api_dashboard_v2_improvement_message_pb.Improvements;
      reader.readMessage(value,api_dashboard_v2_improvement_message_pb.Improvements.deserializeBinaryFromReader);
      msg.setImprovements(value);
      break;
    case 9:
      var value = new api_dashboard_v2_report_data_message_pb.ReportData;
      reader.readMessage(value,api_dashboard_v2_report_data_message_pb.ReportData.deserializeBinaryFromReader);
      msg.setReportData(value);
      break;
    case 12:
      var value = new proto.users_message.UsersProgress;
      reader.readMessage(value,proto.users_message.UsersProgress.deserializeBinaryFromReader);
      msg.setProgress(value);
      break;
    case 13:
      var value = new proto.users_message.UsersAchievements;
      reader.readMessage(value,proto.users_message.UsersAchievements.deserializeBinaryFromReader);
      msg.setAchievements(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishedAt(value);
      break;
    case 15:
      var value = /** @type {!proto.users_message.FilterStatus} */ (reader.readEnum());
      msg.setFilterStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users_message.UsersProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users_message.UsersProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users_message.UsersProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInitialRiskGroup();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInitialRisk();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCurrentRiskGroup();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentRisk();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getImprovements();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      api_dashboard_v2_improvement_message_pb.Improvements.serializeBinaryToWriter
    );
  }
  f = message.getReportData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      api_dashboard_v2_report_data_message_pb.ReportData.serializeBinaryToWriter
    );
  }
  f = message.getProgress();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.users_message.UsersProgress.serializeBinaryToWriter
    );
  }
  f = message.getAchievements();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.users_message.UsersAchievements.serializeBinaryToWriter
    );
  }
  f = message.getFinishedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFilterStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.users_message.UsersProgram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.users_message.UsersProgram.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_type = 3;
 * @return {string}
 */
proto.users_message.UsersProgram.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.users_message.UsersProgram.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 started_at = 5;
 * @return {number}
 */
proto.users_message.UsersProgram.prototype.getStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string initial_risk_group = 6;
 * @return {string}
 */
proto.users_message.UsersProgram.prototype.getInitialRiskGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setInitialRiskGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double initial_risk = 10;
 * @return {number}
 */
proto.users_message.UsersProgram.prototype.getInitialRisk = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setInitialRisk = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string current_risk_group = 7;
 * @return {string}
 */
proto.users_message.UsersProgram.prototype.getCurrentRiskGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setCurrentRiskGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double current_risk = 11;
 * @return {number}
 */
proto.users_message.UsersProgram.prototype.getCurrentRisk = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setCurrentRisk = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional improvement_message.Improvements improvements = 8;
 * @return {?proto.improvement_message.Improvements}
 */
proto.users_message.UsersProgram.prototype.getImprovements = function() {
  return /** @type{?proto.improvement_message.Improvements} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_improvement_message_pb.Improvements, 8));
};


/**
 * @param {?proto.improvement_message.Improvements|undefined} value
 * @return {!proto.users_message.UsersProgram} returns this
*/
proto.users_message.UsersProgram.prototype.setImprovements = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.clearImprovements = function() {
  return this.setImprovements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.UsersProgram.prototype.hasImprovements = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional report_data.ReportData report_data = 9;
 * @return {?proto.report_data.ReportData}
 */
proto.users_message.UsersProgram.prototype.getReportData = function() {
  return /** @type{?proto.report_data.ReportData} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_report_data_message_pb.ReportData, 9));
};


/**
 * @param {?proto.report_data.ReportData|undefined} value
 * @return {!proto.users_message.UsersProgram} returns this
*/
proto.users_message.UsersProgram.prototype.setReportData = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.clearReportData = function() {
  return this.setReportData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.UsersProgram.prototype.hasReportData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UsersProgress progress = 12;
 * @return {?proto.users_message.UsersProgress}
 */
proto.users_message.UsersProgram.prototype.getProgress = function() {
  return /** @type{?proto.users_message.UsersProgress} */ (
    jspb.Message.getWrapperField(this, proto.users_message.UsersProgress, 12));
};


/**
 * @param {?proto.users_message.UsersProgress|undefined} value
 * @return {!proto.users_message.UsersProgram} returns this
*/
proto.users_message.UsersProgram.prototype.setProgress = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.clearProgress = function() {
  return this.setProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.UsersProgram.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional UsersAchievements achievements = 13;
 * @return {?proto.users_message.UsersAchievements}
 */
proto.users_message.UsersProgram.prototype.getAchievements = function() {
  return /** @type{?proto.users_message.UsersAchievements} */ (
    jspb.Message.getWrapperField(this, proto.users_message.UsersAchievements, 13));
};


/**
 * @param {?proto.users_message.UsersAchievements|undefined} value
 * @return {!proto.users_message.UsersProgram} returns this
*/
proto.users_message.UsersProgram.prototype.setAchievements = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.clearAchievements = function() {
  return this.setAchievements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.UsersProgram.prototype.hasAchievements = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 finished_at = 14;
 * @return {number}
 */
proto.users_message.UsersProgram.prototype.getFinishedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional FilterStatus filter_status = 15;
 * @return {!proto.users_message.FilterStatus}
 */
proto.users_message.UsersProgram.prototype.getFilterStatus = function() {
  return /** @type {!proto.users_message.FilterStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.users_message.FilterStatus} value
 * @return {!proto.users_message.UsersProgram} returns this
 */
proto.users_message.UsersProgram.prototype.setFilterStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users_message.UsersProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.users_message.UsersProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users_message.UsersProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    acceptedWorkSessionsCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetWorkSessionsCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastSyncInMilliseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastSession: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users_message.UsersProgress}
 */
proto.users_message.UsersProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users_message.UsersProgress;
  return proto.users_message.UsersProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users_message.UsersProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users_message.UsersProgress}
 */
proto.users_message.UsersProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAcceptedWorkSessionsCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetWorkSessionsCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSyncInMilliseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users_message.UsersProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users_message.UsersProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users_message.UsersProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.UsersProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcceptedWorkSessionsCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTargetWorkSessionsCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastSyncInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLastSession();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int32 accepted_work_sessions_count = 1;
 * @return {number}
 */
proto.users_message.UsersProgress.prototype.getAcceptedWorkSessionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgress} returns this
 */
proto.users_message.UsersProgress.prototype.setAcceptedWorkSessionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 target_work_sessions_count = 2;
 * @return {number}
 */
proto.users_message.UsersProgress.prototype.getTargetWorkSessionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgress} returns this
 */
proto.users_message.UsersProgress.prototype.setTargetWorkSessionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_sync_in_milliseconds = 3;
 * @return {number}
 */
proto.users_message.UsersProgress.prototype.getLastSyncInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgress} returns this
 */
proto.users_message.UsersProgress.prototype.setLastSyncInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 last_session = 4;
 * @return {number}
 */
proto.users_message.UsersProgress.prototype.getLastSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.UsersProgress} returns this
 */
proto.users_message.UsersProgress.prototype.setLastSession = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users_message.User.repeatedFields_ = [11,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users_message.User.prototype.toObject = function(opt_includeInstance) {
  return proto.users_message.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users_message.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userStatus: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jobRole: (f = msg.getJobRole()) && api_dashboard_v2_job_role_message_pb.JobRole.toObject(includeInstance, f),
    site: (f = msg.getSite()) && api_dashboard_v2_site_message_pb.Site.toObject(includeInstance, f),
    department: (f = msg.getDepartment()) && api_dashboard_v2_department_message_pb.Department.toObject(includeInstance, f),
    program: (f = msg.getProgram()) && proto.users_message.UsersProgram.toObject(includeInstance, f),
    passedProgramsList: jspb.Message.toObjectList(msg.getPassedProgramsList(),
    proto.users_message.UsersProgram.toObject, includeInstance),
    taskNumber: jspb.Message.getFieldWithDefault(msg, 12, 0),
    nickname: jspb.Message.getFieldWithDefault(msg, 13, ""),
    wearingNow: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    note: (f = msg.getNote()) && api_dashboard_v2_user_note_message_pb.UserNote.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    api_dashboard_v2_segment_message_pb.CompanyTag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users_message.User}
 */
proto.users_message.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users_message.User;
  return proto.users_message.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users_message.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users_message.User}
 */
proto.users_message.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserStatus(value);
      break;
    case 6:
      var value = new api_dashboard_v2_job_role_message_pb.JobRole;
      reader.readMessage(value,api_dashboard_v2_job_role_message_pb.JobRole.deserializeBinaryFromReader);
      msg.setJobRole(value);
      break;
    case 7:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 8:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    case 9:
      var value = new proto.users_message.UsersProgram;
      reader.readMessage(value,proto.users_message.UsersProgram.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    case 11:
      var value = new proto.users_message.UsersProgram;
      reader.readMessage(value,proto.users_message.UsersProgram.deserializeBinaryFromReader);
      msg.addPassedPrograms(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskNumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWearingNow(value);
      break;
    case 15:
      var value = new api_dashboard_v2_user_note_message_pb.UserNote;
      reader.readMessage(value,api_dashboard_v2_user_note_message_pb.UserNote.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 16:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTag;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users_message.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users_message.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users_message.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users_message.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJobRole();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard_v2_job_role_message_pb.JobRole.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.users_message.UsersProgram.serializeBinaryToWriter
    );
  }
  f = message.getPassedProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.users_message.UsersProgram.serializeBinaryToWriter
    );
  }
  f = message.getTaskNumber();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWearingNow();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      api_dashboard_v2_user_note_message_pb.UserNote.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTag.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.users_message.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.users_message.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.users_message.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.users_message.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_status = 5;
 * @return {string}
 */
proto.users_message.User.prototype.getUserStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setUserStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional job_role.JobRole job_role = 6;
 * @return {?proto.job_role.JobRole}
 */
proto.users_message.User.prototype.getJobRole = function() {
  return /** @type{?proto.job_role.JobRole} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_job_role_message_pb.JobRole, 6));
};


/**
 * @param {?proto.job_role.JobRole|undefined} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setJobRole = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearJobRole = function() {
  return this.setJobRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.User.prototype.hasJobRole = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional site_message.Site site = 7;
 * @return {?proto.site_message.Site}
 */
proto.users_message.User.prototype.getSite = function() {
  return /** @type{?proto.site_message.Site} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_site_message_pb.Site, 7));
};


/**
 * @param {?proto.site_message.Site|undefined} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.User.prototype.hasSite = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional department_message.Department department = 8;
 * @return {?proto.department_message.Department}
 */
proto.users_message.User.prototype.getDepartment = function() {
  return /** @type{?proto.department_message.Department} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_department_message_pb.Department, 8));
};


/**
 * @param {?proto.department_message.Department|undefined} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.User.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UsersProgram program = 9;
 * @return {?proto.users_message.UsersProgram}
 */
proto.users_message.User.prototype.getProgram = function() {
  return /** @type{?proto.users_message.UsersProgram} */ (
    jspb.Message.getWrapperField(this, proto.users_message.UsersProgram, 9));
};


/**
 * @param {?proto.users_message.UsersProgram|undefined} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.User.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated UsersProgram passed_programs = 11;
 * @return {!Array<!proto.users_message.UsersProgram>}
 */
proto.users_message.User.prototype.getPassedProgramsList = function() {
  return /** @type{!Array<!proto.users_message.UsersProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users_message.UsersProgram, 11));
};


/**
 * @param {!Array<!proto.users_message.UsersProgram>} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setPassedProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.users_message.UsersProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users_message.UsersProgram}
 */
proto.users_message.User.prototype.addPassedPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.users_message.UsersProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearPassedProgramsList = function() {
  return this.setPassedProgramsList([]);
};


/**
 * optional int32 task_number = 12;
 * @return {number}
 */
proto.users_message.User.prototype.getTaskNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setTaskNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string nickname = 13;
 * @return {string}
 */
proto.users_message.User.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool wearing_now = 14;
 * @return {boolean}
 */
proto.users_message.User.prototype.getWearingNow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.setWearingNow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional user_note_message.UserNote note = 15;
 * @return {?proto.user_note_message.UserNote}
 */
proto.users_message.User.prototype.getNote = function() {
  return /** @type{?proto.user_note_message.UserNote} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_user_note_message_pb.UserNote, 15));
};


/**
 * @param {?proto.user_note_message.UserNote|undefined} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users_message.User.prototype.hasNote = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated segment_message.CompanyTag tags = 16;
 * @return {!Array<!proto.segment_message.CompanyTag>}
 */
proto.users_message.User.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTag, 16));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTag>} value
 * @return {!proto.users_message.User} returns this
*/
proto.users_message.User.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.segment_message.CompanyTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTag}
 */
proto.users_message.User.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.segment_message.CompanyTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users_message.User} returns this
 */
proto.users_message.User.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * @enum {number}
 */
proto.users_message.FilterStatus = {
  USER_STATUS_NONE: 0,
  DROPPED: 1,
  FINISH: 2,
  ACTIVE: 3,
  NOT_SETUP: 4,
  PENDING: 5
};

goog.object.extend(exports, proto.users_message);
