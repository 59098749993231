import React, { useEffect } from 'react';
import { Table } from 'rsuite';
import Pagination from 'rsuite/Pagination';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Emotion from 'emotion';
import { pagination } from '../../../assets/jss/style';
import { cardLarge, noDataText } from '../../../assets/soter-flex-styles';
import Loader from '../../../lib/ui/Loader';
import { TABLE_PAGINATION_CHANGE } from '../../../constants/analyticsEvents';
import Text from '../../../lib/ui/Text';
import { useAppDispatch } from '../../../lib/utils/useStore';
import { clearPagination } from '../tableStateStore/actions';
import { TableType } from '../constants/tableType';
import { createEventNotification } from '../../NotificationContainer/notificationsStore/actions';

const cardLargeWrapper = Emotion.css`
  @media (max-width: 1400px) {
    margin-bottom: 50px;
  }
  @media (max-width: 1240px) {
    margin-bottom: 0;
  }
`;

const CustomizableTable = React.memo(
  ({
    tableType,
    tableClass,
    items,
    totalItems,
    currentPage,
    setCurrentPage,
    displayLength,
    setDisplayLength,
    sortColumn,
    sortType,
    handleChangeSorting,
    isLoadingItems,
    allItemsLength,
    children,
  }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const hasData = !!items.length;
    const noDataView = isLoadingItems ? (
      <Loader />
    ) : (
      <div style={{ height: '700px' }}>
        <Text
          text={
            allItemsLength
              ? t('WEARABLES.ACTIVITY.NO_DATA_TABLE')
              : t('WEARABLES.ACTIVITY.EMPTY_DATA_TABLE')
          }
          appearance="headlineTwo"
          className={noDataText}
        />
      </div>
    );

    const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;

    useEffect(() => {
      function getQueryParameters() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const pageSize = parseInt(params.get('page-size'), 10);
        const page = parseInt(params.get('page'), 10);
        return { pageSize, page };
      }
      const { pageSize, page } = getQueryParameters();
      const emptyPagePagination = (page && !pageSize && (page - 1) * displayLength >= totalItems)
      || (page && pageSize && (page - 1) * pageSize >= totalItems);

      if (emptyPagePagination) {
        dispatch(clearPagination());
      }
    }, [totalItems, window.location.href]);

    return hasData && !isLoadingItems ? (
      <div className={cardLargeWrapper} style={{ minHeight: '700px' }}>
        <div className={cardLarge}>
          <Table
            // key={uuidv4()} // remounts table on page change (to fix calculation of table height)
            className={tableClass || ''}
            data={items}
            wordWrap
            autoHeight={!isFirefox}
            height={500}
            headerHeight={tableType === TableType.dashboardUsers ? 60 : 40}
            onSortColumn={handleChangeSorting}
            sortColumn={sortColumn}
            sortType={sortType}
          >
            {children}
          </Table>
          <Pagination
            className={pagination}
            limitOptions={[10, 20, 50, 100]}
            activePage={currentPage}
            limit={displayLength}
            total={totalItems}
            layout={[t('WEARABLES.ACTIVITY.SHOW'), 'limit', t('WEARABLES.ACTIVITY.ENTRIES'), ' | ', t('SOTER_TASK.ASSESSMENT.TOTAL'), ': ', totalItems, '-', 'pager']}
            locale={{ limit: '{0}' }}
            maxButtons={5}
            prev
            last
            next
            first
            onChangePage={(val) => {
              const params = {
                table_type: tableType,
                pagination_length: displayLength,
                current_page: currentPage,
              };
              window.logEvent(TABLE_PAGINATION_CHANGE, params);
              if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(`${TABLE_PAGINATION_CHANGE}: ${JSON.stringify(params)}`));
              }
              setCurrentPage(val);
              // document.getElementById('overflowContainer').scrollIntoView();
            }}
            onChangeLimit={(val) => {
              setDisplayLength(val);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
    ) : (
      noDataView
    );
  },
);

export default CustomizableTable;

CustomizableTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  displayLength: PropTypes.number.isRequired,
  setDisplayLength: PropTypes.func.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  handleChangeSorting: PropTypes.func.isRequired,
  isLoadingItems: PropTypes.bool.isRequired,
  allItemsLength: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
