// source: api/dashboard/v2/admin_hub_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_admin_message_pb = require('../../../api/dashboard/v2/admin_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_admin_message_pb);
goog.exportSymbol('proto.admin.CreateHubRequest', null, global);
goog.exportSymbol('proto.admin.GetHubsRequest', null, global);
goog.exportSymbol('proto.admin.GetHubsResponse', null, global);
goog.exportSymbol('proto.admin.Hub', null, global);
goog.exportSymbol('proto.admin.HubRequest', null, global);
goog.exportSymbol('proto.admin.HubResponse', null, global);
goog.exportSymbol('proto.admin.UpdateHubRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.HubRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.HubRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.HubRequest.displayName = 'proto.admin.HubRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.HubResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.HubResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.HubResponse.displayName = 'proto.admin.HubResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.GetHubsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin.GetHubsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.GetHubsRequest.displayName = 'proto.admin.GetHubsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.GetHubsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.GetHubsResponse.repeatedFields_, null);
};
goog.inherits(proto.admin.GetHubsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.GetHubsResponse.displayName = 'proto.admin.GetHubsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.CreateHubRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.CreateHubRequest.repeatedFields_, null);
};
goog.inherits(proto.admin.CreateHubRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.CreateHubRequest.displayName = 'proto.admin.CreateHubRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.UpdateHubRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.UpdateHubRequest.repeatedFields_, null);
};
goog.inherits(proto.admin.UpdateHubRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.UpdateHubRequest.displayName = 'proto.admin.UpdateHubRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin.Hub = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.Hub.repeatedFields_, null);
};
goog.inherits(proto.admin.Hub, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin.Hub.displayName = 'proto.admin.Hub';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.HubRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.HubRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.HubRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.HubRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.HubRequest}
 */
proto.admin.HubRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.HubRequest;
  return proto.admin.HubRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.HubRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.HubRequest}
 */
proto.admin.HubRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.HubRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.HubRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.HubRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.HubRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.HubRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.HubRequest} returns this
 */
proto.admin.HubRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.HubResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.HubResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.HubResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.HubResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.HubResponse}
 */
proto.admin.HubResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.HubResponse;
  return proto.admin.HubResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.HubResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.HubResponse}
 */
proto.admin.HubResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.HubResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.HubResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.HubResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.HubResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.admin.HubResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.HubResponse} returns this
 */
proto.admin.HubResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.GetHubsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.GetHubsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.GetHubsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.GetHubsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.GetHubsRequest}
 */
proto.admin.GetHubsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.GetHubsRequest;
  return proto.admin.GetHubsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.GetHubsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.GetHubsRequest}
 */
proto.admin.GetHubsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.GetHubsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.GetHubsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.GetHubsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.GetHubsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.admin.GetHubsRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.GetHubsRequest} returns this
 */
proto.admin.GetHubsRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.GetHubsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.GetHubsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.GetHubsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.GetHubsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.GetHubsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hubsList: jspb.Message.toObjectList(msg.getHubsList(),
    proto.admin.Hub.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.GetHubsResponse}
 */
proto.admin.GetHubsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.GetHubsResponse;
  return proto.admin.GetHubsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.GetHubsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.GetHubsResponse}
 */
proto.admin.GetHubsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin.Hub;
      reader.readMessage(value,proto.admin.Hub.deserializeBinaryFromReader);
      msg.addHubs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.GetHubsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.GetHubsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.GetHubsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.GetHubsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHubsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admin.Hub.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Hub hubs = 1;
 * @return {!Array<!proto.admin.Hub>}
 */
proto.admin.GetHubsResponse.prototype.getHubsList = function() {
  return /** @type{!Array<!proto.admin.Hub>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin.Hub, 1));
};


/**
 * @param {!Array<!proto.admin.Hub>} value
 * @return {!proto.admin.GetHubsResponse} returns this
*/
proto.admin.GetHubsResponse.prototype.setHubsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admin.Hub=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin.Hub}
 */
proto.admin.GetHubsResponse.prototype.addHubs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admin.Hub, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.GetHubsResponse} returns this
 */
proto.admin.GetHubsResponse.prototype.clearHubsList = function() {
  return this.setHubsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.CreateHubRequest.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.CreateHubRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.CreateHubRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.CreateHubRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CreateHubRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hubType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    downloadDataNotOnlyFromDevicesOnChargeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    downloadDataNotOnlyFromDevicesOnCharge: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    configureDeviceMethodEnable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    configureDeviceMethod: jspb.Message.getFieldWithDefault(msg, 7, 0),
    inputLoginMethodEnable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    inputLoginMethod: jspb.Message.getFieldWithDefault(msg, 9, 0),
    typeWearingEnable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    typeWearing: (f = msg.getTypeWearing()) && api_dashboard_v2_admin_message_pb.TypesWearingDevice.toObject(includeInstance, f),
    employeeLoginTypeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    employeeLoginType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hubsCoachingProgramsEnable: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hubsCoachingProgramsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.CreateHubRequest}
 */
proto.admin.CreateHubRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.CreateHubRequest;
  return proto.admin.CreateHubRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.CreateHubRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.CreateHubRequest}
 */
proto.admin.CreateHubRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {!proto.admin.HubType} */ (reader.readEnum());
      msg.setHubType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnChargeEnable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnCharge(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigureDeviceMethodEnable(value);
      break;
    case 7:
      var value = /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (reader.readEnum());
      msg.setConfigureDeviceMethod(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInputLoginMethodEnable(value);
      break;
    case 9:
      var value = /** @type {!proto.admin.ClipgoInputLoginMethod} */ (reader.readEnum());
      msg.setInputLoginMethod(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTypeWearingEnable(value);
      break;
    case 11:
      var value = new api_dashboard_v2_admin_message_pb.TypesWearingDevice;
      reader.readMessage(value,api_dashboard_v2_admin_message_pb.TypesWearingDevice.deserializeBinaryFromReader);
      msg.setTypeWearing(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmployeeLoginTypeEnable(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeLoginType(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHubsCoachingProgramsEnable(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHubsCoachingPrograms(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.CreateHubRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.CreateHubRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.CreateHubRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.CreateHubRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHubType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnChargeEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnCharge();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getConfigureDeviceMethodEnable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getConfigureDeviceMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getInputLoginMethodEnable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInputLoginMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getTypeWearingEnable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getTypeWearing();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      api_dashboard_v2_admin_message_pb.TypesWearingDevice.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeLoginTypeEnable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getEmployeeLoginType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHubsCoachingProgramsEnable();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHubsCoachingProgramsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      15,
      f
    );
  }
};


/**
 * optional string alias = 1;
 * @return {string}
 */
proto.admin.CreateHubRequest.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.admin.CreateHubRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional HubType hub_type = 3;
 * @return {!proto.admin.HubType}
 */
proto.admin.CreateHubRequest.prototype.getHubType = function() {
  return /** @type {!proto.admin.HubType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.admin.HubType} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setHubType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge_enable = 4;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getDownloadDataNotOnlyFromDevicesOnChargeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setDownloadDataNotOnlyFromDevicesOnChargeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge = 5;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getDownloadDataNotOnlyFromDevicesOnCharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setDownloadDataNotOnlyFromDevicesOnCharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool configure_device_method_enable = 6;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getConfigureDeviceMethodEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setConfigureDeviceMethodEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional ClipgoConfigureDeviceMethod configure_device_method = 7;
 * @return {!proto.admin.ClipgoConfigureDeviceMethod}
 */
proto.admin.CreateHubRequest.prototype.getConfigureDeviceMethod = function() {
  return /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.admin.ClipgoConfigureDeviceMethod} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setConfigureDeviceMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool input_login_method_enable = 8;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getInputLoginMethodEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setInputLoginMethodEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional ClipgoInputLoginMethod input_login_method = 9;
 * @return {!proto.admin.ClipgoInputLoginMethod}
 */
proto.admin.CreateHubRequest.prototype.getInputLoginMethod = function() {
  return /** @type {!proto.admin.ClipgoInputLoginMethod} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.admin.ClipgoInputLoginMethod} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setInputLoginMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool type_wearing_enable = 10;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getTypeWearingEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setTypeWearingEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional TypesWearingDevice type_wearing = 11;
 * @return {?proto.admin.TypesWearingDevice}
 */
proto.admin.CreateHubRequest.prototype.getTypeWearing = function() {
  return /** @type{?proto.admin.TypesWearingDevice} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_admin_message_pb.TypesWearingDevice, 11));
};


/**
 * @param {?proto.admin.TypesWearingDevice|undefined} value
 * @return {!proto.admin.CreateHubRequest} returns this
*/
proto.admin.CreateHubRequest.prototype.setTypeWearing = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.clearTypeWearing = function() {
  return this.setTypeWearing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.hasTypeWearing = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool employee_login_type_enable = 12;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getEmployeeLoginTypeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setEmployeeLoginTypeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string employee_login_type = 13;
 * @return {string}
 */
proto.admin.CreateHubRequest.prototype.getEmployeeLoginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setEmployeeLoginType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool hubs_coaching_programs_enable = 14;
 * @return {boolean}
 */
proto.admin.CreateHubRequest.prototype.getHubsCoachingProgramsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setHubsCoachingProgramsEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated int32 hubs_coaching_programs = 15;
 * @return {!Array<number>}
 */
proto.admin.CreateHubRequest.prototype.getHubsCoachingProgramsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.setHubsCoachingProgramsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.addHubsCoachingPrograms = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.CreateHubRequest} returns this
 */
proto.admin.CreateHubRequest.prototype.clearHubsCoachingProgramsList = function() {
  return this.setHubsCoachingProgramsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.UpdateHubRequest.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.UpdateHubRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.UpdateHubRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.UpdateHubRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.UpdateHubRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hubType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    downloadDataNotOnlyFromDevicesOnChargeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    downloadDataNotOnlyFromDevicesOnCharge: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    configureDeviceMethodEnable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    configureDeviceMethod: jspb.Message.getFieldWithDefault(msg, 8, 0),
    inputLoginMethodEnable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    inputLoginMethod: jspb.Message.getFieldWithDefault(msg, 10, 0),
    typeWearingEnable: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    typeWearing: (f = msg.getTypeWearing()) && api_dashboard_v2_admin_message_pb.TypesWearingDevice.toObject(includeInstance, f),
    employeeLoginTypeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    employeeLoginType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hubsCoachingProgramsEnable: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    hubsCoachingProgramsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.UpdateHubRequest}
 */
proto.admin.UpdateHubRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.UpdateHubRequest;
  return proto.admin.UpdateHubRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.UpdateHubRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.UpdateHubRequest}
 */
proto.admin.UpdateHubRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {!proto.admin.HubType} */ (reader.readEnum());
      msg.setHubType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnChargeEnable(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnCharge(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigureDeviceMethodEnable(value);
      break;
    case 8:
      var value = /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (reader.readEnum());
      msg.setConfigureDeviceMethod(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInputLoginMethodEnable(value);
      break;
    case 10:
      var value = /** @type {!proto.admin.ClipgoInputLoginMethod} */ (reader.readEnum());
      msg.setInputLoginMethod(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTypeWearingEnable(value);
      break;
    case 12:
      var value = new api_dashboard_v2_admin_message_pb.TypesWearingDevice;
      reader.readMessage(value,api_dashboard_v2_admin_message_pb.TypesWearingDevice.deserializeBinaryFromReader);
      msg.setTypeWearing(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmployeeLoginTypeEnable(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeLoginType(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHubsCoachingProgramsEnable(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHubsCoachingPrograms(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.UpdateHubRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.UpdateHubRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.UpdateHubRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.UpdateHubRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHubType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnChargeEnable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnCharge();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getConfigureDeviceMethodEnable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getConfigureDeviceMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getInputLoginMethodEnable();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getInputLoginMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTypeWearingEnable();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getTypeWearing();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      api_dashboard_v2_admin_message_pb.TypesWearingDevice.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeLoginTypeEnable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getEmployeeLoginType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHubsCoachingProgramsEnable();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHubsCoachingProgramsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      16,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.UpdateHubRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.admin.UpdateHubRequest.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.admin.UpdateHubRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional HubType hub_type = 4;
 * @return {!proto.admin.HubType}
 */
proto.admin.UpdateHubRequest.prototype.getHubType = function() {
  return /** @type {!proto.admin.HubType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.admin.HubType} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setHubType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge_enable = 5;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getDownloadDataNotOnlyFromDevicesOnChargeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setDownloadDataNotOnlyFromDevicesOnChargeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge = 6;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getDownloadDataNotOnlyFromDevicesOnCharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setDownloadDataNotOnlyFromDevicesOnCharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool configure_device_method_enable = 7;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getConfigureDeviceMethodEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setConfigureDeviceMethodEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ClipgoConfigureDeviceMethod configure_device_method = 8;
 * @return {!proto.admin.ClipgoConfigureDeviceMethod}
 */
proto.admin.UpdateHubRequest.prototype.getConfigureDeviceMethod = function() {
  return /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.admin.ClipgoConfigureDeviceMethod} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setConfigureDeviceMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool input_login_method_enable = 9;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getInputLoginMethodEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setInputLoginMethodEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional ClipgoInputLoginMethod input_login_method = 10;
 * @return {!proto.admin.ClipgoInputLoginMethod}
 */
proto.admin.UpdateHubRequest.prototype.getInputLoginMethod = function() {
  return /** @type {!proto.admin.ClipgoInputLoginMethod} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.admin.ClipgoInputLoginMethod} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setInputLoginMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool type_wearing_enable = 11;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getTypeWearingEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setTypeWearingEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional TypesWearingDevice type_wearing = 12;
 * @return {?proto.admin.TypesWearingDevice}
 */
proto.admin.UpdateHubRequest.prototype.getTypeWearing = function() {
  return /** @type{?proto.admin.TypesWearingDevice} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_admin_message_pb.TypesWearingDevice, 12));
};


/**
 * @param {?proto.admin.TypesWearingDevice|undefined} value
 * @return {!proto.admin.UpdateHubRequest} returns this
*/
proto.admin.UpdateHubRequest.prototype.setTypeWearing = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.clearTypeWearing = function() {
  return this.setTypeWearing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.hasTypeWearing = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool employee_login_type_enable = 13;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getEmployeeLoginTypeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setEmployeeLoginTypeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string employee_login_type = 14;
 * @return {string}
 */
proto.admin.UpdateHubRequest.prototype.getEmployeeLoginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setEmployeeLoginType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool hubs_coaching_programs_enable = 15;
 * @return {boolean}
 */
proto.admin.UpdateHubRequest.prototype.getHubsCoachingProgramsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setHubsCoachingProgramsEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated int32 hubs_coaching_programs = 16;
 * @return {!Array<number>}
 */
proto.admin.UpdateHubRequest.prototype.getHubsCoachingProgramsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.setHubsCoachingProgramsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.addHubsCoachingPrograms = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.UpdateHubRequest} returns this
 */
proto.admin.UpdateHubRequest.prototype.clearHubsCoachingProgramsList = function() {
  return this.setHubsCoachingProgramsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin.Hub.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin.Hub.prototype.toObject = function(opt_includeInstance) {
  return proto.admin.Hub.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin.Hub} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.Hub.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hubCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imei: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hubType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastSeenMilliseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAtMilliseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    downloadDataNotOnlyFromDevicesOnChargeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    downloadDataNotOnlyFromDevicesOnCharge: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    configureDeviceMethodEnable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    configureDeviceMethod: jspb.Message.getFieldWithDefault(msg, 13, 0),
    inputLoginMethodEnable: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    inputLoginMethod: jspb.Message.getFieldWithDefault(msg, 15, 0),
    typeWearingEnable: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    typeWearing: (f = msg.getTypeWearing()) && api_dashboard_v2_admin_message_pb.TypesWearingDevice.toObject(includeInstance, f),
    employeeLoginTypeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    employeeLoginType: jspb.Message.getFieldWithDefault(msg, 19, ""),
    hubsCoachingProgramsEnable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hubsCoachingProgramsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin.Hub}
 */
proto.admin.Hub.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin.Hub;
  return proto.admin.Hub.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin.Hub} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin.Hub}
 */
proto.admin.Hub.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImei(value);
      break;
    case 6:
      var value = /** @type {!proto.admin.HubType} */ (reader.readEnum());
      msg.setHubType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeenMilliseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAtMilliseconds(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnChargeEnable(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDownloadDataNotOnlyFromDevicesOnCharge(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigureDeviceMethodEnable(value);
      break;
    case 13:
      var value = /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (reader.readEnum());
      msg.setConfigureDeviceMethod(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInputLoginMethodEnable(value);
      break;
    case 15:
      var value = /** @type {!proto.admin.ClipgoInputLoginMethod} */ (reader.readEnum());
      msg.setInputLoginMethod(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTypeWearingEnable(value);
      break;
    case 17:
      var value = new api_dashboard_v2_admin_message_pb.TypesWearingDevice;
      reader.readMessage(value,api_dashboard_v2_admin_message_pb.TypesWearingDevice.deserializeBinaryFromReader);
      msg.setTypeWearing(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmployeeLoginTypeEnable(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeLoginType(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHubsCoachingProgramsEnable(value);
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHubsCoachingPrograms(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin.Hub.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin.Hub.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin.Hub} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin.Hub.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHubCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImei();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHubType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLastSeenMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedAtMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnChargeEnable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDownloadDataNotOnlyFromDevicesOnCharge();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getConfigureDeviceMethodEnable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getConfigureDeviceMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getInputLoginMethodEnable();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getInputLoginMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getTypeWearingEnable();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getTypeWearing();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      api_dashboard_v2_admin_message_pb.TypesWearingDevice.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeLoginTypeEnable();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getEmployeeLoginType();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getHubsCoachingProgramsEnable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHubsCoachingProgramsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      21,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.admin.Hub.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.admin.Hub.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.admin.Hub.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string hub_code = 4;
 * @return {string}
 */
proto.admin.Hub.prototype.getHubCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setHubCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string imei = 5;
 * @return {string}
 */
proto.admin.Hub.prototype.getImei = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setImei = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional HubType hub_type = 6;
 * @return {!proto.admin.HubType}
 */
proto.admin.Hub.prototype.getHubType = function() {
  return /** @type {!proto.admin.HubType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.admin.HubType} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setHubType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 last_seen_milliseconds = 7;
 * @return {number}
 */
proto.admin.Hub.prototype.getLastSeenMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setLastSeenMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 created_at_milliseconds = 8;
 * @return {number}
 */
proto.admin.Hub.prototype.getCreatedAtMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setCreatedAtMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_active = 9;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge_enable = 10;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getDownloadDataNotOnlyFromDevicesOnChargeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setDownloadDataNotOnlyFromDevicesOnChargeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool download_data_not_only_from_devices_on_charge = 11;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getDownloadDataNotOnlyFromDevicesOnCharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setDownloadDataNotOnlyFromDevicesOnCharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool configure_device_method_enable = 12;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getConfigureDeviceMethodEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setConfigureDeviceMethodEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional ClipgoConfigureDeviceMethod configure_device_method = 13;
 * @return {!proto.admin.ClipgoConfigureDeviceMethod}
 */
proto.admin.Hub.prototype.getConfigureDeviceMethod = function() {
  return /** @type {!proto.admin.ClipgoConfigureDeviceMethod} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.admin.ClipgoConfigureDeviceMethod} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setConfigureDeviceMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional bool input_login_method_enable = 14;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getInputLoginMethodEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setInputLoginMethodEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional ClipgoInputLoginMethod input_login_method = 15;
 * @return {!proto.admin.ClipgoInputLoginMethod}
 */
proto.admin.Hub.prototype.getInputLoginMethod = function() {
  return /** @type {!proto.admin.ClipgoInputLoginMethod} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.admin.ClipgoInputLoginMethod} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setInputLoginMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool type_wearing_enable = 16;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getTypeWearingEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setTypeWearingEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional TypesWearingDevice type_wearing = 17;
 * @return {?proto.admin.TypesWearingDevice}
 */
proto.admin.Hub.prototype.getTypeWearing = function() {
  return /** @type{?proto.admin.TypesWearingDevice} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_admin_message_pb.TypesWearingDevice, 17));
};


/**
 * @param {?proto.admin.TypesWearingDevice|undefined} value
 * @return {!proto.admin.Hub} returns this
*/
proto.admin.Hub.prototype.setTypeWearing = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.clearTypeWearing = function() {
  return this.setTypeWearing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin.Hub.prototype.hasTypeWearing = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool employee_login_type_enable = 18;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getEmployeeLoginTypeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setEmployeeLoginTypeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string employee_login_type = 19;
 * @return {string}
 */
proto.admin.Hub.prototype.getEmployeeLoginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setEmployeeLoginType = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool hubs_coaching_programs_enable = 20;
 * @return {boolean}
 */
proto.admin.Hub.prototype.getHubsCoachingProgramsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setHubsCoachingProgramsEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated int32 hubs_coaching_programs = 21;
 * @return {!Array<number>}
 */
proto.admin.Hub.prototype.getHubsCoachingProgramsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.setHubsCoachingProgramsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.addHubsCoachingPrograms = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin.Hub} returns this
 */
proto.admin.Hub.prototype.clearHubsCoachingProgramsList = function() {
  return this.setHubsCoachingProgramsList([]);
};


goog.object.extend(exports, proto.admin);
