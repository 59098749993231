import React from 'react';
import * as Emotion from 'emotion';
import _compact from 'lodash/compact';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import Tooltip from '../../../../lib/ui/Tooltip';
import sliceLongString from '../../../../lib/utils/sliceLongString';

const programSpan = Emotion.css`
  background-color: #e0e5ec;
  color: ${COLORS.GRAY[950]};
  font-size: 12px;
  border-radius: 3px;
  padding: 2px;
  word-break: keep-all;
  line-break: strict;
  margin-left: 2px;
  margin-right: 2px;
  max-width: 140px;
`;

const gridContainer = Emotion.css`
  //display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(72px, 82px));
  //grid-gap: 4px;
  display: flex;
`;

const DeviceProgramCell = ({ rowData }) => {
  const { t } = useTranslation();
  let programs;
  if ('availableCoachingProgramsList' in rowData) {
    programs = rowData.availableCoachingProgramsList;
  } else {
    programs = rowData.availableProgramsList;
  }
  const programString
    = programs?.length > 0
      ? programs.reduce((accum, program) => `${accum}${program.name}, `, '')
      : t('WEARABLES.DEVICES.NO_PROGRAMS');
  return (
    <div style={{ display: 'block' }}>
      <div className={gridContainer}>
        {_compact(programString.split(', ')).map((program) => (
          <Tooltip text={program} key={program}>
            <div className={programSpan} style={{ wordBreak: 'keep-all' }}>{sliceLongString(program, 11)}</div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default DeviceProgramCell;
