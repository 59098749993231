import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import _uniqBy from 'lodash/uniqBy';
import {
  emptyTasks,
  taskColumnHeading,
  tasksHeader,
  tasksList,
} from '../../../../assets/jss/components/addDayTasksDialog';
import Task from './Task';
import { getDisplayedDate } from '../utils';
import Loader from '../../../../lib/ui/Loader';

const TaskList = ({
  isLoading,
  tasks,
  editedTaskId,
  timeZone = moment.tz.guess(),
  worksessionStartTime,
  onEditTask,
  onRemoveTask,
}) => {
  const { t } = useTranslation();
  const taskList = _uniqBy(tasks, 'id').map(({
    id, taskName, title, timeStart, timeEnd,
  }) => (
    <Task
      key={id}
      id={id}
      taskName={taskName || title}
      timeStart={getDisplayedDate(worksessionStartTime, timeStart, timeZone)}
      timeEnd={getDisplayedDate(worksessionStartTime, timeEnd, timeZone)}
      isEdited={editedTaskId === id}
      onEdit={onEditTask}
      onRemove={onRemoveTask}
    />
  ));

  const tasksView = (tasks.length === 0)
    ? <div className={emptyTasks}>{t('WEARABLES.USER_PAGE.TASK_EMPTY')}</div>
    : <>{taskList}</>;

  return (
    <div className={tasksList}>
      <div className={tasksHeader}>
        <div className={taskColumnHeading}>{t('SOTER_TASK.VIDEOS_LIST.TASK_NAME')}</div>
        <div className={taskColumnHeading}>{t('GENERAL.TIME')}</div>
      </div>
      {
        isLoading
          ? <Loader />
          : tasksView
      }
    </div>
  );
};

export default TaskList;
