// source: api/dashboard/v2/overview_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.overview.ActiveUsersByDate', null, global);
goog.exportSymbol('proto.overview.ActiveUsersByDateRange', null, global);
goog.exportSymbol('proto.overview.HazardsChangeByRisk', null, global);
goog.exportSymbol('proto.overview.HazardsOfShoulder', null, global);
goog.exportSymbol('proto.overview.HazardsOfSpine', null, global);
goog.exportSymbol('proto.overview.NewData', null, global);
goog.exportSymbol('proto.overview.NewOverviewDataResponse', null, global);
goog.exportSymbol('proto.overview.NewOverviewRequest', null, global);
goog.exportSymbol('proto.overview.Parameter', null, global);
goog.exportSymbol('proto.overview.Period', null, global);
goog.exportSymbol('proto.overview.RegisteredUsersByDate', null, global);
goog.exportSymbol('proto.overview.RegisteredUsersByDateRange', null, global);
goog.exportSymbol('proto.overview.ShoulderHazardsByGroup', null, global);
goog.exportSymbol('proto.overview.SpineHazardsByGroup', null, global);
goog.exportSymbol('proto.overview.UserFeedback', null, global);
goog.exportSymbol('proto.overview.UsersFeedback', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.NewOverviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.overview.NewOverviewRequest.repeatedFields_, null);
};
goog.inherits(proto.overview.NewOverviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.NewOverviewRequest.displayName = 'proto.overview.NewOverviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.NewOverviewDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.overview.NewOverviewDataResponse.repeatedFields_, null);
};
goog.inherits(proto.overview.NewOverviewDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.NewOverviewDataResponse.displayName = 'proto.overview.NewOverviewDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.Period = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.Period, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.Period.displayName = 'proto.overview.Period';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.NewData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.overview.NewData.repeatedFields_, null);
};
goog.inherits(proto.overview.NewData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.NewData.displayName = 'proto.overview.NewData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.Parameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.Parameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.Parameter.displayName = 'proto.overview.Parameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.HazardsOfSpine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.HazardsOfSpine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.HazardsOfSpine.displayName = 'proto.overview.HazardsOfSpine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.HazardsOfShoulder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.HazardsOfShoulder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.HazardsOfShoulder.displayName = 'proto.overview.HazardsOfShoulder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.HazardsChangeByRisk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.HazardsChangeByRisk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.HazardsChangeByRisk.displayName = 'proto.overview.HazardsChangeByRisk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.UsersFeedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.UsersFeedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.UsersFeedback.displayName = 'proto.overview.UsersFeedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.UserFeedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.UserFeedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.UserFeedback.displayName = 'proto.overview.UserFeedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.SpineHazardsByGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.SpineHazardsByGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.SpineHazardsByGroup.displayName = 'proto.overview.SpineHazardsByGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.ShoulderHazardsByGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.ShoulderHazardsByGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.ShoulderHazardsByGroup.displayName = 'proto.overview.ShoulderHazardsByGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.ActiveUsersByDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.overview.ActiveUsersByDate.repeatedFields_, null);
};
goog.inherits(proto.overview.ActiveUsersByDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.ActiveUsersByDate.displayName = 'proto.overview.ActiveUsersByDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.ActiveUsersByDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.ActiveUsersByDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.ActiveUsersByDateRange.displayName = 'proto.overview.ActiveUsersByDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.RegisteredUsersByDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.overview.RegisteredUsersByDate.repeatedFields_, null);
};
goog.inherits(proto.overview.RegisteredUsersByDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.RegisteredUsersByDate.displayName = 'proto.overview.RegisteredUsersByDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.overview.RegisteredUsersByDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.overview.RegisteredUsersByDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.overview.RegisteredUsersByDateRange.displayName = 'proto.overview.RegisteredUsersByDateRange';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.overview.NewOverviewRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.NewOverviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.NewOverviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.NewOverviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.NewOverviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    period: (f = msg.getPeriod()) && proto.overview.Period.toObject(includeInstance, f),
    departmentIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.NewOverviewRequest}
 */
proto.overview.NewOverviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.NewOverviewRequest;
  return proto.overview.NewOverviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.NewOverviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.NewOverviewRequest}
 */
proto.overview.NewOverviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.overview.Period;
      reader.readMessage(value,proto.overview.Period.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDepartmentIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSiteIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.NewOverviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.NewOverviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.NewOverviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.NewOverviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.overview.Period.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.overview.NewOverviewRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Period period = 2;
 * @return {?proto.overview.Period}
 */
proto.overview.NewOverviewRequest.prototype.getPeriod = function() {
  return /** @type{?proto.overview.Period} */ (
    jspb.Message.getWrapperField(this, proto.overview.Period, 2));
};


/**
 * @param {?proto.overview.Period|undefined} value
 * @return {!proto.overview.NewOverviewRequest} returns this
*/
proto.overview.NewOverviewRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewOverviewRequest.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated int32 department_ids = 3;
 * @return {!Array<number>}
 */
proto.overview.NewOverviewRequest.prototype.getDepartmentIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.setDepartmentIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.addDepartmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.clearDepartmentIdsList = function() {
  return this.setDepartmentIdsList([]);
};


/**
 * repeated int32 site_ids = 4;
 * @return {!Array<number>}
 */
proto.overview.NewOverviewRequest.prototype.getSiteIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewOverviewRequest} returns this
 */
proto.overview.NewOverviewRequest.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.overview.NewOverviewDataResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.NewOverviewDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.NewOverviewDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.NewOverviewDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.NewOverviewDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    period: (f = msg.getPeriod()) && proto.overview.Period.toObject(includeInstance, f),
    departmentIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    fullCompanyData: (f = msg.getFullCompanyData()) && proto.overview.NewData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.NewOverviewDataResponse}
 */
proto.overview.NewOverviewDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.NewOverviewDataResponse;
  return proto.overview.NewOverviewDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.NewOverviewDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.NewOverviewDataResponse}
 */
proto.overview.NewOverviewDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.overview.Period;
      reader.readMessage(value,proto.overview.Period.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDepartmentIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSiteIds(values[i]);
      }
      break;
    case 5:
      var value = new proto.overview.NewData;
      reader.readMessage(value,proto.overview.NewData.deserializeBinaryFromReader);
      msg.setFullCompanyData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.NewOverviewDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.NewOverviewDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.NewOverviewDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.NewOverviewDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.overview.Period.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getFullCompanyData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.overview.NewData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.overview.NewOverviewDataResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Period period = 2;
 * @return {?proto.overview.Period}
 */
proto.overview.NewOverviewDataResponse.prototype.getPeriod = function() {
  return /** @type{?proto.overview.Period} */ (
    jspb.Message.getWrapperField(this, proto.overview.Period, 2));
};


/**
 * @param {?proto.overview.Period|undefined} value
 * @return {!proto.overview.NewOverviewDataResponse} returns this
*/
proto.overview.NewOverviewDataResponse.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewOverviewDataResponse.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated int32 department_ids = 3;
 * @return {!Array<number>}
 */
proto.overview.NewOverviewDataResponse.prototype.getDepartmentIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.setDepartmentIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.addDepartmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.clearDepartmentIdsList = function() {
  return this.setDepartmentIdsList([]);
};


/**
 * repeated int32 site_ids = 4;
 * @return {!Array<number>}
 */
proto.overview.NewOverviewDataResponse.prototype.getSiteIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};


/**
 * optional NewData full_company_data = 5;
 * @return {?proto.overview.NewData}
 */
proto.overview.NewOverviewDataResponse.prototype.getFullCompanyData = function() {
  return /** @type{?proto.overview.NewData} */ (
    jspb.Message.getWrapperField(this, proto.overview.NewData, 5));
};


/**
 * @param {?proto.overview.NewData|undefined} value
 * @return {!proto.overview.NewOverviewDataResponse} returns this
*/
proto.overview.NewOverviewDataResponse.prototype.setFullCompanyData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewOverviewDataResponse} returns this
 */
proto.overview.NewOverviewDataResponse.prototype.clearFullCompanyData = function() {
  return this.setFullCompanyData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewOverviewDataResponse.prototype.hasFullCompanyData = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.Period.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.Period.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.Period} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.Period.toObject = function(includeInstance, msg) {
  var f, obj = {
    startedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.Period}
 */
proto.overview.Period.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.Period;
  return proto.overview.Period.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.Period} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.Period}
 */
proto.overview.Period.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.Period.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.Period.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.Period} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.Period.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFinishedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 started_at = 1;
 * @return {number}
 */
proto.overview.Period.prototype.getStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.Period} returns this
 */
proto.overview.Period.prototype.setStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 finished_at = 2;
 * @return {number}
 */
proto.overview.Period.prototype.getFinishedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.Period} returns this
 */
proto.overview.Period.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.overview.NewData.repeatedFields_ = [13,14,15,16,19,20,31,32];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.NewData.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.NewData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.NewData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.NewData.toObject = function(includeInstance, msg) {
  var f, obj = {
    completedSession: (f = msg.getCompletedSession()) && proto.overview.Parameter.toObject(includeInstance, f),
    improvement: (f = msg.getImprovement()) && proto.overview.Parameter.toObject(includeInstance, f),
    activeUsers: (f = msg.getActiveUsers()) && proto.overview.Parameter.toObject(includeInstance, f),
    newUsers: (f = msg.getNewUsers()) && proto.overview.Parameter.toObject(includeInstance, f),
    finishedUsers: (f = msg.getFinishedUsers()) && proto.overview.Parameter.toObject(includeInstance, f),
    hazardsOfSpine: (f = msg.getHazardsOfSpine()) && proto.overview.HazardsOfSpine.toObject(includeInstance, f),
    hazardsOfShoulder: (f = msg.getHazardsOfShoulder()) && proto.overview.HazardsOfShoulder.toObject(includeInstance, f),
    spineHazardsChangeByRisk: (f = msg.getSpineHazardsChangeByRisk()) && proto.overview.HazardsChangeByRisk.toObject(includeInstance, f),
    shoulderHazardsChangeByRisk: (f = msg.getShoulderHazardsChangeByRisk()) && proto.overview.HazardsChangeByRisk.toObject(includeInstance, f),
    usersFeedback: (f = msg.getUsersFeedback()) && proto.overview.UsersFeedback.toObject(includeInstance, f),
    shoulderHazardsByDepartmentList: jspb.Message.toObjectList(msg.getShoulderHazardsByDepartmentList(),
    proto.overview.ShoulderHazardsByGroup.toObject, includeInstance),
    shoulderHazardsByJobRoleList: jspb.Message.toObjectList(msg.getShoulderHazardsByJobRoleList(),
    proto.overview.ShoulderHazardsByGroup.toObject, includeInstance),
    spineHazardsByDepartmentList: jspb.Message.toObjectList(msg.getSpineHazardsByDepartmentList(),
    proto.overview.SpineHazardsByGroup.toObject, includeInstance),
    spineHazardsByJobRoleList: jspb.Message.toObjectList(msg.getSpineHazardsByJobRoleList(),
    proto.overview.SpineHazardsByGroup.toObject, includeInstance),
    spineHazardsByTaskList: jspb.Message.toObjectList(msg.getSpineHazardsByTaskList(),
    proto.overview.SpineHazardsByGroup.toObject, includeInstance),
    shoulderHazardsByTaskList: jspb.Message.toObjectList(msg.getShoulderHazardsByTaskList(),
    proto.overview.ShoulderHazardsByGroup.toObject, includeInstance),
    spineHazardsBySiteList: jspb.Message.toObjectList(msg.getSpineHazardsBySiteList(),
    proto.overview.SpineHazardsByGroup.toObject, includeInstance),
    shoulderHazardsBySiteList: jspb.Message.toObjectList(msg.getShoulderHazardsBySiteList(),
    proto.overview.ShoulderHazardsByGroup.toObject, includeInstance),
    activeUsersDate: (f = msg.getActiveUsersDate()) && proto.overview.ActiveUsersByDate.toObject(includeInstance, f),
    registeredUsers: (f = msg.getRegisteredUsers()) && proto.overview.RegisteredUsersByDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.NewData}
 */
proto.overview.NewData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.NewData;
  return proto.overview.NewData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.NewData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.NewData}
 */
proto.overview.NewData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.overview.Parameter;
      reader.readMessage(value,proto.overview.Parameter.deserializeBinaryFromReader);
      msg.setCompletedSession(value);
      break;
    case 2:
      var value = new proto.overview.Parameter;
      reader.readMessage(value,proto.overview.Parameter.deserializeBinaryFromReader);
      msg.setImprovement(value);
      break;
    case 3:
      var value = new proto.overview.Parameter;
      reader.readMessage(value,proto.overview.Parameter.deserializeBinaryFromReader);
      msg.setActiveUsers(value);
      break;
    case 4:
      var value = new proto.overview.Parameter;
      reader.readMessage(value,proto.overview.Parameter.deserializeBinaryFromReader);
      msg.setNewUsers(value);
      break;
    case 5:
      var value = new proto.overview.Parameter;
      reader.readMessage(value,proto.overview.Parameter.deserializeBinaryFromReader);
      msg.setFinishedUsers(value);
      break;
    case 8:
      var value = new proto.overview.HazardsOfSpine;
      reader.readMessage(value,proto.overview.HazardsOfSpine.deserializeBinaryFromReader);
      msg.setHazardsOfSpine(value);
      break;
    case 9:
      var value = new proto.overview.HazardsOfShoulder;
      reader.readMessage(value,proto.overview.HazardsOfShoulder.deserializeBinaryFromReader);
      msg.setHazardsOfShoulder(value);
      break;
    case 10:
      var value = new proto.overview.HazardsChangeByRisk;
      reader.readMessage(value,proto.overview.HazardsChangeByRisk.deserializeBinaryFromReader);
      msg.setSpineHazardsChangeByRisk(value);
      break;
    case 18:
      var value = new proto.overview.HazardsChangeByRisk;
      reader.readMessage(value,proto.overview.HazardsChangeByRisk.deserializeBinaryFromReader);
      msg.setShoulderHazardsChangeByRisk(value);
      break;
    case 11:
      var value = new proto.overview.UsersFeedback;
      reader.readMessage(value,proto.overview.UsersFeedback.deserializeBinaryFromReader);
      msg.setUsersFeedback(value);
      break;
    case 13:
      var value = new proto.overview.ShoulderHazardsByGroup;
      reader.readMessage(value,proto.overview.ShoulderHazardsByGroup.deserializeBinaryFromReader);
      msg.addShoulderHazardsByDepartment(value);
      break;
    case 14:
      var value = new proto.overview.ShoulderHazardsByGroup;
      reader.readMessage(value,proto.overview.ShoulderHazardsByGroup.deserializeBinaryFromReader);
      msg.addShoulderHazardsByJobRole(value);
      break;
    case 15:
      var value = new proto.overview.SpineHazardsByGroup;
      reader.readMessage(value,proto.overview.SpineHazardsByGroup.deserializeBinaryFromReader);
      msg.addSpineHazardsByDepartment(value);
      break;
    case 16:
      var value = new proto.overview.SpineHazardsByGroup;
      reader.readMessage(value,proto.overview.SpineHazardsByGroup.deserializeBinaryFromReader);
      msg.addSpineHazardsByJobRole(value);
      break;
    case 19:
      var value = new proto.overview.SpineHazardsByGroup;
      reader.readMessage(value,proto.overview.SpineHazardsByGroup.deserializeBinaryFromReader);
      msg.addSpineHazardsByTask(value);
      break;
    case 20:
      var value = new proto.overview.ShoulderHazardsByGroup;
      reader.readMessage(value,proto.overview.ShoulderHazardsByGroup.deserializeBinaryFromReader);
      msg.addShoulderHazardsByTask(value);
      break;
    case 31:
      var value = new proto.overview.SpineHazardsByGroup;
      reader.readMessage(value,proto.overview.SpineHazardsByGroup.deserializeBinaryFromReader);
      msg.addSpineHazardsBySite(value);
      break;
    case 32:
      var value = new proto.overview.ShoulderHazardsByGroup;
      reader.readMessage(value,proto.overview.ShoulderHazardsByGroup.deserializeBinaryFromReader);
      msg.addShoulderHazardsBySite(value);
      break;
    case 33:
      var value = new proto.overview.ActiveUsersByDate;
      reader.readMessage(value,proto.overview.ActiveUsersByDate.deserializeBinaryFromReader);
      msg.setActiveUsersDate(value);
      break;
    case 34:
      var value = new proto.overview.RegisteredUsersByDate;
      reader.readMessage(value,proto.overview.RegisteredUsersByDate.deserializeBinaryFromReader);
      msg.setRegisteredUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.NewData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.NewData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.NewData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.NewData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompletedSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.overview.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getImprovement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.overview.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getActiveUsers();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.overview.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getNewUsers();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.overview.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getFinishedUsers();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.overview.Parameter.serializeBinaryToWriter
    );
  }
  f = message.getHazardsOfSpine();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.overview.HazardsOfSpine.serializeBinaryToWriter
    );
  }
  f = message.getHazardsOfShoulder();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.overview.HazardsOfShoulder.serializeBinaryToWriter
    );
  }
  f = message.getSpineHazardsChangeByRisk();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.overview.HazardsChangeByRisk.serializeBinaryToWriter
    );
  }
  f = message.getShoulderHazardsChangeByRisk();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.overview.HazardsChangeByRisk.serializeBinaryToWriter
    );
  }
  f = message.getUsersFeedback();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.overview.UsersFeedback.serializeBinaryToWriter
    );
  }
  f = message.getShoulderHazardsByDepartmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.overview.ShoulderHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getShoulderHazardsByJobRoleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.overview.ShoulderHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getSpineHazardsByDepartmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.overview.SpineHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getSpineHazardsByJobRoleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.overview.SpineHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getSpineHazardsByTaskList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.overview.SpineHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getShoulderHazardsByTaskList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.overview.ShoulderHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getSpineHazardsBySiteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.overview.SpineHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getShoulderHazardsBySiteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.overview.ShoulderHazardsByGroup.serializeBinaryToWriter
    );
  }
  f = message.getActiveUsersDate();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.overview.ActiveUsersByDate.serializeBinaryToWriter
    );
  }
  f = message.getRegisteredUsers();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.overview.RegisteredUsersByDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional Parameter completed_session = 1;
 * @return {?proto.overview.Parameter}
 */
proto.overview.NewData.prototype.getCompletedSession = function() {
  return /** @type{?proto.overview.Parameter} */ (
    jspb.Message.getWrapperField(this, proto.overview.Parameter, 1));
};


/**
 * @param {?proto.overview.Parameter|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setCompletedSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearCompletedSession = function() {
  return this.setCompletedSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasCompletedSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Parameter improvement = 2;
 * @return {?proto.overview.Parameter}
 */
proto.overview.NewData.prototype.getImprovement = function() {
  return /** @type{?proto.overview.Parameter} */ (
    jspb.Message.getWrapperField(this, proto.overview.Parameter, 2));
};


/**
 * @param {?proto.overview.Parameter|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setImprovement = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearImprovement = function() {
  return this.setImprovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasImprovement = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Parameter active_users = 3;
 * @return {?proto.overview.Parameter}
 */
proto.overview.NewData.prototype.getActiveUsers = function() {
  return /** @type{?proto.overview.Parameter} */ (
    jspb.Message.getWrapperField(this, proto.overview.Parameter, 3));
};


/**
 * @param {?proto.overview.Parameter|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setActiveUsers = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearActiveUsers = function() {
  return this.setActiveUsers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasActiveUsers = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Parameter new_users = 4;
 * @return {?proto.overview.Parameter}
 */
proto.overview.NewData.prototype.getNewUsers = function() {
  return /** @type{?proto.overview.Parameter} */ (
    jspb.Message.getWrapperField(this, proto.overview.Parameter, 4));
};


/**
 * @param {?proto.overview.Parameter|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setNewUsers = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearNewUsers = function() {
  return this.setNewUsers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasNewUsers = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Parameter finished_users = 5;
 * @return {?proto.overview.Parameter}
 */
proto.overview.NewData.prototype.getFinishedUsers = function() {
  return /** @type{?proto.overview.Parameter} */ (
    jspb.Message.getWrapperField(this, proto.overview.Parameter, 5));
};


/**
 * @param {?proto.overview.Parameter|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setFinishedUsers = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearFinishedUsers = function() {
  return this.setFinishedUsers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasFinishedUsers = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional HazardsOfSpine hazards_of_spine = 8;
 * @return {?proto.overview.HazardsOfSpine}
 */
proto.overview.NewData.prototype.getHazardsOfSpine = function() {
  return /** @type{?proto.overview.HazardsOfSpine} */ (
    jspb.Message.getWrapperField(this, proto.overview.HazardsOfSpine, 8));
};


/**
 * @param {?proto.overview.HazardsOfSpine|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setHazardsOfSpine = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearHazardsOfSpine = function() {
  return this.setHazardsOfSpine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasHazardsOfSpine = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional HazardsOfShoulder hazards_of_shoulder = 9;
 * @return {?proto.overview.HazardsOfShoulder}
 */
proto.overview.NewData.prototype.getHazardsOfShoulder = function() {
  return /** @type{?proto.overview.HazardsOfShoulder} */ (
    jspb.Message.getWrapperField(this, proto.overview.HazardsOfShoulder, 9));
};


/**
 * @param {?proto.overview.HazardsOfShoulder|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setHazardsOfShoulder = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearHazardsOfShoulder = function() {
  return this.setHazardsOfShoulder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasHazardsOfShoulder = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional HazardsChangeByRisk spine_hazards_change_by_risk = 10;
 * @return {?proto.overview.HazardsChangeByRisk}
 */
proto.overview.NewData.prototype.getSpineHazardsChangeByRisk = function() {
  return /** @type{?proto.overview.HazardsChangeByRisk} */ (
    jspb.Message.getWrapperField(this, proto.overview.HazardsChangeByRisk, 10));
};


/**
 * @param {?proto.overview.HazardsChangeByRisk|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setSpineHazardsChangeByRisk = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearSpineHazardsChangeByRisk = function() {
  return this.setSpineHazardsChangeByRisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasSpineHazardsChangeByRisk = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional HazardsChangeByRisk shoulder_hazards_change_by_risk = 18;
 * @return {?proto.overview.HazardsChangeByRisk}
 */
proto.overview.NewData.prototype.getShoulderHazardsChangeByRisk = function() {
  return /** @type{?proto.overview.HazardsChangeByRisk} */ (
    jspb.Message.getWrapperField(this, proto.overview.HazardsChangeByRisk, 18));
};


/**
 * @param {?proto.overview.HazardsChangeByRisk|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setShoulderHazardsChangeByRisk = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearShoulderHazardsChangeByRisk = function() {
  return this.setShoulderHazardsChangeByRisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasShoulderHazardsChangeByRisk = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional UsersFeedback users_feedback = 11;
 * @return {?proto.overview.UsersFeedback}
 */
proto.overview.NewData.prototype.getUsersFeedback = function() {
  return /** @type{?proto.overview.UsersFeedback} */ (
    jspb.Message.getWrapperField(this, proto.overview.UsersFeedback, 11));
};


/**
 * @param {?proto.overview.UsersFeedback|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setUsersFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearUsersFeedback = function() {
  return this.setUsersFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasUsersFeedback = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated ShoulderHazardsByGroup shoulder_hazards_by_department = 13;
 * @return {!Array<!proto.overview.ShoulderHazardsByGroup>}
 */
proto.overview.NewData.prototype.getShoulderHazardsByDepartmentList = function() {
  return /** @type{!Array<!proto.overview.ShoulderHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ShoulderHazardsByGroup, 13));
};


/**
 * @param {!Array<!proto.overview.ShoulderHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setShoulderHazardsByDepartmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.overview.ShoulderHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ShoulderHazardsByGroup}
 */
proto.overview.NewData.prototype.addShoulderHazardsByDepartment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.overview.ShoulderHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearShoulderHazardsByDepartmentList = function() {
  return this.setShoulderHazardsByDepartmentList([]);
};


/**
 * repeated ShoulderHazardsByGroup shoulder_hazards_by_job_role = 14;
 * @return {!Array<!proto.overview.ShoulderHazardsByGroup>}
 */
proto.overview.NewData.prototype.getShoulderHazardsByJobRoleList = function() {
  return /** @type{!Array<!proto.overview.ShoulderHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ShoulderHazardsByGroup, 14));
};


/**
 * @param {!Array<!proto.overview.ShoulderHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setShoulderHazardsByJobRoleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.overview.ShoulderHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ShoulderHazardsByGroup}
 */
proto.overview.NewData.prototype.addShoulderHazardsByJobRole = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.overview.ShoulderHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearShoulderHazardsByJobRoleList = function() {
  return this.setShoulderHazardsByJobRoleList([]);
};


/**
 * repeated SpineHazardsByGroup spine_hazards_by_department = 15;
 * @return {!Array<!proto.overview.SpineHazardsByGroup>}
 */
proto.overview.NewData.prototype.getSpineHazardsByDepartmentList = function() {
  return /** @type{!Array<!proto.overview.SpineHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.SpineHazardsByGroup, 15));
};


/**
 * @param {!Array<!proto.overview.SpineHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setSpineHazardsByDepartmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.overview.SpineHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.SpineHazardsByGroup}
 */
proto.overview.NewData.prototype.addSpineHazardsByDepartment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.overview.SpineHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearSpineHazardsByDepartmentList = function() {
  return this.setSpineHazardsByDepartmentList([]);
};


/**
 * repeated SpineHazardsByGroup spine_hazards_by_job_role = 16;
 * @return {!Array<!proto.overview.SpineHazardsByGroup>}
 */
proto.overview.NewData.prototype.getSpineHazardsByJobRoleList = function() {
  return /** @type{!Array<!proto.overview.SpineHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.SpineHazardsByGroup, 16));
};


/**
 * @param {!Array<!proto.overview.SpineHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setSpineHazardsByJobRoleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.overview.SpineHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.SpineHazardsByGroup}
 */
proto.overview.NewData.prototype.addSpineHazardsByJobRole = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.overview.SpineHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearSpineHazardsByJobRoleList = function() {
  return this.setSpineHazardsByJobRoleList([]);
};


/**
 * repeated SpineHazardsByGroup spine_hazards_by_task = 19;
 * @return {!Array<!proto.overview.SpineHazardsByGroup>}
 */
proto.overview.NewData.prototype.getSpineHazardsByTaskList = function() {
  return /** @type{!Array<!proto.overview.SpineHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.SpineHazardsByGroup, 19));
};


/**
 * @param {!Array<!proto.overview.SpineHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setSpineHazardsByTaskList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.overview.SpineHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.SpineHazardsByGroup}
 */
proto.overview.NewData.prototype.addSpineHazardsByTask = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.overview.SpineHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearSpineHazardsByTaskList = function() {
  return this.setSpineHazardsByTaskList([]);
};


/**
 * repeated ShoulderHazardsByGroup shoulder_hazards_by_task = 20;
 * @return {!Array<!proto.overview.ShoulderHazardsByGroup>}
 */
proto.overview.NewData.prototype.getShoulderHazardsByTaskList = function() {
  return /** @type{!Array<!proto.overview.ShoulderHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ShoulderHazardsByGroup, 20));
};


/**
 * @param {!Array<!proto.overview.ShoulderHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setShoulderHazardsByTaskList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.overview.ShoulderHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ShoulderHazardsByGroup}
 */
proto.overview.NewData.prototype.addShoulderHazardsByTask = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.overview.ShoulderHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearShoulderHazardsByTaskList = function() {
  return this.setShoulderHazardsByTaskList([]);
};


/**
 * repeated SpineHazardsByGroup spine_hazards_by_site = 31;
 * @return {!Array<!proto.overview.SpineHazardsByGroup>}
 */
proto.overview.NewData.prototype.getSpineHazardsBySiteList = function() {
  return /** @type{!Array<!proto.overview.SpineHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.SpineHazardsByGroup, 31));
};


/**
 * @param {!Array<!proto.overview.SpineHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setSpineHazardsBySiteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.overview.SpineHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.SpineHazardsByGroup}
 */
proto.overview.NewData.prototype.addSpineHazardsBySite = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.overview.SpineHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearSpineHazardsBySiteList = function() {
  return this.setSpineHazardsBySiteList([]);
};


/**
 * repeated ShoulderHazardsByGroup shoulder_hazards_by_site = 32;
 * @return {!Array<!proto.overview.ShoulderHazardsByGroup>}
 */
proto.overview.NewData.prototype.getShoulderHazardsBySiteList = function() {
  return /** @type{!Array<!proto.overview.ShoulderHazardsByGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ShoulderHazardsByGroup, 32));
};


/**
 * @param {!Array<!proto.overview.ShoulderHazardsByGroup>} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setShoulderHazardsBySiteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.overview.ShoulderHazardsByGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ShoulderHazardsByGroup}
 */
proto.overview.NewData.prototype.addShoulderHazardsBySite = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.overview.ShoulderHazardsByGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearShoulderHazardsBySiteList = function() {
  return this.setShoulderHazardsBySiteList([]);
};


/**
 * optional ActiveUsersByDate active_users_date = 33;
 * @return {?proto.overview.ActiveUsersByDate}
 */
proto.overview.NewData.prototype.getActiveUsersDate = function() {
  return /** @type{?proto.overview.ActiveUsersByDate} */ (
    jspb.Message.getWrapperField(this, proto.overview.ActiveUsersByDate, 33));
};


/**
 * @param {?proto.overview.ActiveUsersByDate|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setActiveUsersDate = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearActiveUsersDate = function() {
  return this.setActiveUsersDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasActiveUsersDate = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional RegisteredUsersByDate registered_users = 34;
 * @return {?proto.overview.RegisteredUsersByDate}
 */
proto.overview.NewData.prototype.getRegisteredUsers = function() {
  return /** @type{?proto.overview.RegisteredUsersByDate} */ (
    jspb.Message.getWrapperField(this, proto.overview.RegisteredUsersByDate, 34));
};


/**
 * @param {?proto.overview.RegisteredUsersByDate|undefined} value
 * @return {!proto.overview.NewData} returns this
*/
proto.overview.NewData.prototype.setRegisteredUsers = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.NewData} returns this
 */
proto.overview.NewData.prototype.clearRegisteredUsers = function() {
  return this.setRegisteredUsers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.NewData.prototype.hasRegisteredUsers = function() {
  return jspb.Message.getField(this, 34) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.Parameter.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.Parameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.Parameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.Parameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    change: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.Parameter}
 */
proto.overview.Parameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.Parameter;
  return proto.overview.Parameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.Parameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.Parameter}
 */
proto.overview.Parameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.Parameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.Parameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.Parameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.Parameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChange();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.overview.Parameter.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.Parameter} returns this
 */
proto.overview.Parameter.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 change = 2;
 * @return {number}
 */
proto.overview.Parameter.prototype.getChange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.Parameter} returns this
 */
proto.overview.Parameter.prototype.setChange = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.HazardsOfSpine.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.HazardsOfSpine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.HazardsOfSpine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.HazardsOfSpine.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pb_static: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    repetition: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lifting: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    twisting: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.HazardsOfSpine}
 */
proto.overview.HazardsOfSpine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.HazardsOfSpine;
  return proto.overview.HazardsOfSpine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.HazardsOfSpine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.HazardsOfSpine}
 */
proto.overview.HazardsOfSpine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStatic(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLifting(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTwisting(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntensity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.HazardsOfSpine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.HazardsOfSpine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.HazardsOfSpine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.HazardsOfSpine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getStatic();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLifting();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTwisting();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional double total = 1;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double static = 2;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setStatic = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double repetition = 3;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double lifting = 4;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getLifting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setLifting = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double twisting = 5;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setTwisting = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double intensity = 6;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double duration = 7;
 * @return {number}
 */
proto.overview.HazardsOfSpine.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfSpine} returns this
 */
proto.overview.HazardsOfSpine.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.HazardsOfShoulder.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.HazardsOfShoulder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.HazardsOfShoulder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.HazardsOfShoulder.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    armElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    pushingPulling: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    staticArmElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    repetitiveArmMovements: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    overexertion: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.HazardsOfShoulder}
 */
proto.overview.HazardsOfShoulder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.HazardsOfShoulder;
  return proto.overview.HazardsOfShoulder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.HazardsOfShoulder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.HazardsOfShoulder}
 */
proto.overview.HazardsOfShoulder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmElevation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPushingPulling(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaticArmElevation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetitiveArmMovements(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverexertion(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.HazardsOfShoulder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.HazardsOfShoulder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.HazardsOfShoulder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.HazardsOfShoulder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional double total = 1;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double arm_elevation = 2;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double pushing_pulling = 3;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double static_arm_elevation = 4;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double repetitive_arm_movements = 5;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double overexertion = 6;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double duration = 7;
 * @return {number}
 */
proto.overview.HazardsOfShoulder.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsOfShoulder} returns this
 */
proto.overview.HazardsOfShoulder.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.HazardsChangeByRisk.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.HazardsChangeByRisk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.HazardsChangeByRisk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.HazardsChangeByRisk.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerStart: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lowerEnd: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lowerWorkersCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    middleStart: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    middleEnd: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    middleWorkersCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    higherStart: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    higherEnd: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    higherWorkersCount: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.HazardsChangeByRisk}
 */
proto.overview.HazardsChangeByRisk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.HazardsChangeByRisk;
  return proto.overview.HazardsChangeByRisk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.HazardsChangeByRisk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.HazardsChangeByRisk}
 */
proto.overview.HazardsChangeByRisk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowerStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowerEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowerWorkersCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMiddleStart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMiddleEnd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMiddleWorkersCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigherStart(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigherEnd(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHigherWorkersCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.HazardsChangeByRisk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.HazardsChangeByRisk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.HazardsChangeByRisk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.HazardsChangeByRisk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerStart();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLowerEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLowerWorkersCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMiddleStart();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMiddleEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMiddleWorkersCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHigherStart();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHigherEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getHigherWorkersCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional double lower_start = 1;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getLowerStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setLowerStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lower_end = 2;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getLowerEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setLowerEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 lower_workers_count = 3;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getLowerWorkersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setLowerWorkersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double middle_start = 4;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getMiddleStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setMiddleStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double middle_end = 5;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getMiddleEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setMiddleEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 middle_workers_count = 6;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getMiddleWorkersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setMiddleWorkersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double higher_start = 7;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getHigherStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setHigherStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double higher_end = 8;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getHigherEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setHigherEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 higher_workers_count = 9;
 * @return {number}
 */
proto.overview.HazardsChangeByRisk.prototype.getHigherWorkersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.HazardsChangeByRisk} returns this
 */
proto.overview.HazardsChangeByRisk.prototype.setHigherWorkersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.UsersFeedback.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.UsersFeedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.UsersFeedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.UsersFeedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    spine: (f = msg.getSpine()) && proto.overview.UserFeedback.toObject(includeInstance, f),
    shoulder: (f = msg.getShoulder()) && proto.overview.UserFeedback.toObject(includeInstance, f),
    headset: (f = msg.getHeadset()) && proto.overview.UserFeedback.toObject(includeInstance, f),
    helmet: (f = msg.getHelmet()) && proto.overview.UserFeedback.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.UsersFeedback}
 */
proto.overview.UsersFeedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.UsersFeedback;
  return proto.overview.UsersFeedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.UsersFeedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.UsersFeedback}
 */
proto.overview.UsersFeedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.overview.UserFeedback;
      reader.readMessage(value,proto.overview.UserFeedback.deserializeBinaryFromReader);
      msg.setSpine(value);
      break;
    case 2:
      var value = new proto.overview.UserFeedback;
      reader.readMessage(value,proto.overview.UserFeedback.deserializeBinaryFromReader);
      msg.setShoulder(value);
      break;
    case 3:
      var value = new proto.overview.UserFeedback;
      reader.readMessage(value,proto.overview.UserFeedback.deserializeBinaryFromReader);
      msg.setHeadset(value);
      break;
    case 4:
      var value = new proto.overview.UserFeedback;
      reader.readMessage(value,proto.overview.UserFeedback.deserializeBinaryFromReader);
      msg.setHelmet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.UsersFeedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.UsersFeedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.UsersFeedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.UsersFeedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpine();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.overview.UserFeedback.serializeBinaryToWriter
    );
  }
  f = message.getShoulder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.overview.UserFeedback.serializeBinaryToWriter
    );
  }
  f = message.getHeadset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.overview.UserFeedback.serializeBinaryToWriter
    );
  }
  f = message.getHelmet();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.overview.UserFeedback.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserFeedback spine = 1;
 * @return {?proto.overview.UserFeedback}
 */
proto.overview.UsersFeedback.prototype.getSpine = function() {
  return /** @type{?proto.overview.UserFeedback} */ (
    jspb.Message.getWrapperField(this, proto.overview.UserFeedback, 1));
};


/**
 * @param {?proto.overview.UserFeedback|undefined} value
 * @return {!proto.overview.UsersFeedback} returns this
*/
proto.overview.UsersFeedback.prototype.setSpine = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.UsersFeedback} returns this
 */
proto.overview.UsersFeedback.prototype.clearSpine = function() {
  return this.setSpine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.UsersFeedback.prototype.hasSpine = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFeedback shoulder = 2;
 * @return {?proto.overview.UserFeedback}
 */
proto.overview.UsersFeedback.prototype.getShoulder = function() {
  return /** @type{?proto.overview.UserFeedback} */ (
    jspb.Message.getWrapperField(this, proto.overview.UserFeedback, 2));
};


/**
 * @param {?proto.overview.UserFeedback|undefined} value
 * @return {!proto.overview.UsersFeedback} returns this
*/
proto.overview.UsersFeedback.prototype.setShoulder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.UsersFeedback} returns this
 */
proto.overview.UsersFeedback.prototype.clearShoulder = function() {
  return this.setShoulder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.UsersFeedback.prototype.hasShoulder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserFeedback headset = 3;
 * @return {?proto.overview.UserFeedback}
 */
proto.overview.UsersFeedback.prototype.getHeadset = function() {
  return /** @type{?proto.overview.UserFeedback} */ (
    jspb.Message.getWrapperField(this, proto.overview.UserFeedback, 3));
};


/**
 * @param {?proto.overview.UserFeedback|undefined} value
 * @return {!proto.overview.UsersFeedback} returns this
*/
proto.overview.UsersFeedback.prototype.setHeadset = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.UsersFeedback} returns this
 */
proto.overview.UsersFeedback.prototype.clearHeadset = function() {
  return this.setHeadset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.UsersFeedback.prototype.hasHeadset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserFeedback helmet = 4;
 * @return {?proto.overview.UserFeedback}
 */
proto.overview.UsersFeedback.prototype.getHelmet = function() {
  return /** @type{?proto.overview.UserFeedback} */ (
    jspb.Message.getWrapperField(this, proto.overview.UserFeedback, 4));
};


/**
 * @param {?proto.overview.UserFeedback|undefined} value
 * @return {!proto.overview.UsersFeedback} returns this
*/
proto.overview.UsersFeedback.prototype.setHelmet = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.UsersFeedback} returns this
 */
proto.overview.UsersFeedback.prototype.clearHelmet = function() {
  return this.setHelmet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.UsersFeedback.prototype.hasHelmet = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.UserFeedback.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.UserFeedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.UserFeedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.UserFeedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    negativeFeedbackCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    neutralFeedbackCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positiveFeedbackCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.UserFeedback}
 */
proto.overview.UserFeedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.UserFeedback;
  return proto.overview.UserFeedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.UserFeedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.UserFeedback}
 */
proto.overview.UserFeedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNegativeFeedbackCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNeutralFeedbackCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositiveFeedbackCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.UserFeedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.UserFeedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.UserFeedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.UserFeedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNegativeFeedbackCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNeutralFeedbackCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPositiveFeedbackCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 negative_feedback_count = 1;
 * @return {number}
 */
proto.overview.UserFeedback.prototype.getNegativeFeedbackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.UserFeedback} returns this
 */
proto.overview.UserFeedback.prototype.setNegativeFeedbackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 neutral_feedback_count = 2;
 * @return {number}
 */
proto.overview.UserFeedback.prototype.getNeutralFeedbackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.UserFeedback} returns this
 */
proto.overview.UserFeedback.prototype.setNeutralFeedbackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 positive_feedback_count = 3;
 * @return {number}
 */
proto.overview.UserFeedback.prototype.getPositiveFeedbackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.UserFeedback} returns this
 */
proto.overview.UserFeedback.prototype.setPositiveFeedbackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.SpineHazardsByGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.SpineHazardsByGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.SpineHazardsByGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.SpineHazardsByGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_static: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    repetition: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lifting: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    twisting: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 8, 0),
    debugInfo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    amountOfWorkers: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.SpineHazardsByGroup}
 */
proto.overview.SpineHazardsByGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.SpineHazardsByGroup;
  return proto.overview.SpineHazardsByGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.SpineHazardsByGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.SpineHazardsByGroup}
 */
proto.overview.SpineHazardsByGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStatic(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLifting(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTwisting(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntensity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebugInfo(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountOfWorkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.SpineHazardsByGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.SpineHazardsByGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.SpineHazardsByGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.SpineHazardsByGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatic();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLifting();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTwisting();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDebugInfo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAmountOfWorkers();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.overview.SpineHazardsByGroup.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double static = 2;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setStatic = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double repetition = 3;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double lifting = 4;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getLifting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setLifting = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double twisting = 5;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setTwisting = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double intensity = 6;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double duration = 7;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 count = 8;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string debug_info = 9;
 * @return {string}
 */
proto.overview.SpineHazardsByGroup.prototype.getDebugInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setDebugInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 amount_of_workers = 10;
 * @return {number}
 */
proto.overview.SpineHazardsByGroup.prototype.getAmountOfWorkers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.SpineHazardsByGroup} returns this
 */
proto.overview.SpineHazardsByGroup.prototype.setAmountOfWorkers = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.ShoulderHazardsByGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.ShoulderHazardsByGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.ShoulderHazardsByGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.ShoulderHazardsByGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    armElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    pushingPulling: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    staticArmElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    repetitiveArmMovements: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    overexertion: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 8, 0),
    debugInfo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    amountOfWorkers: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.ShoulderHazardsByGroup}
 */
proto.overview.ShoulderHazardsByGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.ShoulderHazardsByGroup;
  return proto.overview.ShoulderHazardsByGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.ShoulderHazardsByGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.ShoulderHazardsByGroup}
 */
proto.overview.ShoulderHazardsByGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmElevation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPushingPulling(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaticArmElevation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetitiveArmMovements(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverexertion(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebugInfo(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountOfWorkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.ShoulderHazardsByGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.ShoulderHazardsByGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.ShoulderHazardsByGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.ShoulderHazardsByGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDebugInfo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAmountOfWorkers();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double arm_elevation = 2;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double pushing_pulling = 3;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double static_arm_elevation = 4;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double repetitive_arm_movements = 5;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double overexertion = 6;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double duration = 7;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 count = 8;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string debug_info = 9;
 * @return {string}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getDebugInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setDebugInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 amount_of_workers = 10;
 * @return {number}
 */
proto.overview.ShoulderHazardsByGroup.prototype.getAmountOfWorkers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ShoulderHazardsByGroup} returns this
 */
proto.overview.ShoulderHazardsByGroup.prototype.setAmountOfWorkers = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.overview.ActiveUsersByDate.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.ActiveUsersByDate.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.ActiveUsersByDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.ActiveUsersByDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.ActiveUsersByDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.overview.ActiveUsersByDateRange.toObject, includeInstance),
    weeksList: jspb.Message.toObjectList(msg.getWeeksList(),
    proto.overview.ActiveUsersByDateRange.toObject, includeInstance),
    monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
    proto.overview.ActiveUsersByDateRange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.ActiveUsersByDate}
 */
proto.overview.ActiveUsersByDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.ActiveUsersByDate;
  return proto.overview.ActiveUsersByDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.ActiveUsersByDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.ActiveUsersByDate}
 */
proto.overview.ActiveUsersByDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.overview.ActiveUsersByDateRange;
      reader.readMessage(value,proto.overview.ActiveUsersByDateRange.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    case 2:
      var value = new proto.overview.ActiveUsersByDateRange;
      reader.readMessage(value,proto.overview.ActiveUsersByDateRange.deserializeBinaryFromReader);
      msg.addWeeks(value);
      break;
    case 3:
      var value = new proto.overview.ActiveUsersByDateRange;
      reader.readMessage(value,proto.overview.ActiveUsersByDateRange.deserializeBinaryFromReader);
      msg.addMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.ActiveUsersByDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.ActiveUsersByDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.ActiveUsersByDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.ActiveUsersByDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.overview.ActiveUsersByDateRange.serializeBinaryToWriter
    );
  }
  f = message.getWeeksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.overview.ActiveUsersByDateRange.serializeBinaryToWriter
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.overview.ActiveUsersByDateRange.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActiveUsersByDateRange days = 1;
 * @return {!Array<!proto.overview.ActiveUsersByDateRange>}
 */
proto.overview.ActiveUsersByDate.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.overview.ActiveUsersByDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ActiveUsersByDateRange, 1));
};


/**
 * @param {!Array<!proto.overview.ActiveUsersByDateRange>} value
 * @return {!proto.overview.ActiveUsersByDate} returns this
*/
proto.overview.ActiveUsersByDate.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.overview.ActiveUsersByDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ActiveUsersByDateRange}
 */
proto.overview.ActiveUsersByDate.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.overview.ActiveUsersByDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.ActiveUsersByDate} returns this
 */
proto.overview.ActiveUsersByDate.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};


/**
 * repeated ActiveUsersByDateRange weeks = 2;
 * @return {!Array<!proto.overview.ActiveUsersByDateRange>}
 */
proto.overview.ActiveUsersByDate.prototype.getWeeksList = function() {
  return /** @type{!Array<!proto.overview.ActiveUsersByDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ActiveUsersByDateRange, 2));
};


/**
 * @param {!Array<!proto.overview.ActiveUsersByDateRange>} value
 * @return {!proto.overview.ActiveUsersByDate} returns this
*/
proto.overview.ActiveUsersByDate.prototype.setWeeksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.overview.ActiveUsersByDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ActiveUsersByDateRange}
 */
proto.overview.ActiveUsersByDate.prototype.addWeeks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.overview.ActiveUsersByDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.ActiveUsersByDate} returns this
 */
proto.overview.ActiveUsersByDate.prototype.clearWeeksList = function() {
  return this.setWeeksList([]);
};


/**
 * repeated ActiveUsersByDateRange months = 3;
 * @return {!Array<!proto.overview.ActiveUsersByDateRange>}
 */
proto.overview.ActiveUsersByDate.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.overview.ActiveUsersByDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.ActiveUsersByDateRange, 3));
};


/**
 * @param {!Array<!proto.overview.ActiveUsersByDateRange>} value
 * @return {!proto.overview.ActiveUsersByDate} returns this
*/
proto.overview.ActiveUsersByDate.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.overview.ActiveUsersByDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.ActiveUsersByDateRange}
 */
proto.overview.ActiveUsersByDate.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.overview.ActiveUsersByDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.ActiveUsersByDate} returns this
 */
proto.overview.ActiveUsersByDate.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.ActiveUsersByDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.ActiveUsersByDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.ActiveUsersByDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.ActiveUsersByDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto.overview.Period.toObject(includeInstance, f),
    spineUsers: jspb.Message.getFieldWithDefault(msg, 2, 0),
    shoulderUsers: jspb.Message.getFieldWithDefault(msg, 3, 0),
    headsetUsers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    helmetUsers: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.ActiveUsersByDateRange}
 */
proto.overview.ActiveUsersByDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.ActiveUsersByDateRange;
  return proto.overview.ActiveUsersByDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.ActiveUsersByDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.ActiveUsersByDateRange}
 */
proto.overview.ActiveUsersByDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.overview.Period;
      reader.readMessage(value,proto.overview.Period.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpineUsers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShoulderUsers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeadsetUsers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHelmetUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.ActiveUsersByDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.ActiveUsersByDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.ActiveUsersByDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.ActiveUsersByDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.overview.Period.serializeBinaryToWriter
    );
  }
  f = message.getSpineUsers();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getShoulderUsers();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHeadsetUsers();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHelmetUsers();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional Period date_range = 1;
 * @return {?proto.overview.Period}
 */
proto.overview.ActiveUsersByDateRange.prototype.getDateRange = function() {
  return /** @type{?proto.overview.Period} */ (
    jspb.Message.getWrapperField(this, proto.overview.Period, 1));
};


/**
 * @param {?proto.overview.Period|undefined} value
 * @return {!proto.overview.ActiveUsersByDateRange} returns this
*/
proto.overview.ActiveUsersByDateRange.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.ActiveUsersByDateRange} returns this
 */
proto.overview.ActiveUsersByDateRange.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.ActiveUsersByDateRange.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 spine_users = 2;
 * @return {number}
 */
proto.overview.ActiveUsersByDateRange.prototype.getSpineUsers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ActiveUsersByDateRange} returns this
 */
proto.overview.ActiveUsersByDateRange.prototype.setSpineUsers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 shoulder_users = 3;
 * @return {number}
 */
proto.overview.ActiveUsersByDateRange.prototype.getShoulderUsers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ActiveUsersByDateRange} returns this
 */
proto.overview.ActiveUsersByDateRange.prototype.setShoulderUsers = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 headset_users = 4;
 * @return {number}
 */
proto.overview.ActiveUsersByDateRange.prototype.getHeadsetUsers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ActiveUsersByDateRange} returns this
 */
proto.overview.ActiveUsersByDateRange.prototype.setHeadsetUsers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 helmet_users = 5;
 * @return {number}
 */
proto.overview.ActiveUsersByDateRange.prototype.getHelmetUsers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.ActiveUsersByDateRange} returns this
 */
proto.overview.ActiveUsersByDateRange.prototype.setHelmetUsers = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.overview.RegisteredUsersByDate.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.RegisteredUsersByDate.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.RegisteredUsersByDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.RegisteredUsersByDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.RegisteredUsersByDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.overview.RegisteredUsersByDateRange.toObject, includeInstance),
    weeksList: jspb.Message.toObjectList(msg.getWeeksList(),
    proto.overview.RegisteredUsersByDateRange.toObject, includeInstance),
    monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
    proto.overview.RegisteredUsersByDateRange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.RegisteredUsersByDate}
 */
proto.overview.RegisteredUsersByDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.RegisteredUsersByDate;
  return proto.overview.RegisteredUsersByDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.RegisteredUsersByDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.RegisteredUsersByDate}
 */
proto.overview.RegisteredUsersByDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.overview.RegisteredUsersByDateRange;
      reader.readMessage(value,proto.overview.RegisteredUsersByDateRange.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    case 2:
      var value = new proto.overview.RegisteredUsersByDateRange;
      reader.readMessage(value,proto.overview.RegisteredUsersByDateRange.deserializeBinaryFromReader);
      msg.addWeeks(value);
      break;
    case 3:
      var value = new proto.overview.RegisteredUsersByDateRange;
      reader.readMessage(value,proto.overview.RegisteredUsersByDateRange.deserializeBinaryFromReader);
      msg.addMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.RegisteredUsersByDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.RegisteredUsersByDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.RegisteredUsersByDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.RegisteredUsersByDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.overview.RegisteredUsersByDateRange.serializeBinaryToWriter
    );
  }
  f = message.getWeeksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.overview.RegisteredUsersByDateRange.serializeBinaryToWriter
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.overview.RegisteredUsersByDateRange.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RegisteredUsersByDateRange days = 1;
 * @return {!Array<!proto.overview.RegisteredUsersByDateRange>}
 */
proto.overview.RegisteredUsersByDate.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.overview.RegisteredUsersByDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.RegisteredUsersByDateRange, 1));
};


/**
 * @param {!Array<!proto.overview.RegisteredUsersByDateRange>} value
 * @return {!proto.overview.RegisteredUsersByDate} returns this
*/
proto.overview.RegisteredUsersByDate.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.overview.RegisteredUsersByDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.RegisteredUsersByDateRange}
 */
proto.overview.RegisteredUsersByDate.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.overview.RegisteredUsersByDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.RegisteredUsersByDate} returns this
 */
proto.overview.RegisteredUsersByDate.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};


/**
 * repeated RegisteredUsersByDateRange weeks = 2;
 * @return {!Array<!proto.overview.RegisteredUsersByDateRange>}
 */
proto.overview.RegisteredUsersByDate.prototype.getWeeksList = function() {
  return /** @type{!Array<!proto.overview.RegisteredUsersByDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.RegisteredUsersByDateRange, 2));
};


/**
 * @param {!Array<!proto.overview.RegisteredUsersByDateRange>} value
 * @return {!proto.overview.RegisteredUsersByDate} returns this
*/
proto.overview.RegisteredUsersByDate.prototype.setWeeksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.overview.RegisteredUsersByDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.RegisteredUsersByDateRange}
 */
proto.overview.RegisteredUsersByDate.prototype.addWeeks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.overview.RegisteredUsersByDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.RegisteredUsersByDate} returns this
 */
proto.overview.RegisteredUsersByDate.prototype.clearWeeksList = function() {
  return this.setWeeksList([]);
};


/**
 * repeated RegisteredUsersByDateRange months = 3;
 * @return {!Array<!proto.overview.RegisteredUsersByDateRange>}
 */
proto.overview.RegisteredUsersByDate.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.overview.RegisteredUsersByDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.overview.RegisteredUsersByDateRange, 3));
};


/**
 * @param {!Array<!proto.overview.RegisteredUsersByDateRange>} value
 * @return {!proto.overview.RegisteredUsersByDate} returns this
*/
proto.overview.RegisteredUsersByDate.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.overview.RegisteredUsersByDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.overview.RegisteredUsersByDateRange}
 */
proto.overview.RegisteredUsersByDate.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.overview.RegisteredUsersByDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.overview.RegisteredUsersByDate} returns this
 */
proto.overview.RegisteredUsersByDate.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.overview.RegisteredUsersByDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.overview.RegisteredUsersByDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.overview.RegisteredUsersByDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.RegisteredUsersByDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateRange: (f = msg.getDateRange()) && proto.overview.Period.toObject(includeInstance, f),
    usersCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.overview.RegisteredUsersByDateRange}
 */
proto.overview.RegisteredUsersByDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.overview.RegisteredUsersByDateRange;
  return proto.overview.RegisteredUsersByDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.overview.RegisteredUsersByDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.overview.RegisteredUsersByDateRange}
 */
proto.overview.RegisteredUsersByDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.overview.Period;
      reader.readMessage(value,proto.overview.Period.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsersCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.overview.RegisteredUsersByDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.overview.RegisteredUsersByDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.overview.RegisteredUsersByDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.overview.RegisteredUsersByDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.overview.Period.serializeBinaryToWriter
    );
  }
  f = message.getUsersCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Period date_range = 1;
 * @return {?proto.overview.Period}
 */
proto.overview.RegisteredUsersByDateRange.prototype.getDateRange = function() {
  return /** @type{?proto.overview.Period} */ (
    jspb.Message.getWrapperField(this, proto.overview.Period, 1));
};


/**
 * @param {?proto.overview.Period|undefined} value
 * @return {!proto.overview.RegisteredUsersByDateRange} returns this
*/
proto.overview.RegisteredUsersByDateRange.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.overview.RegisteredUsersByDateRange} returns this
 */
proto.overview.RegisteredUsersByDateRange.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.overview.RegisteredUsersByDateRange.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 users_count = 2;
 * @return {number}
 */
proto.overview.RegisteredUsersByDateRange.prototype.getUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.overview.RegisteredUsersByDateRange} returns this
 */
proto.overview.RegisteredUsersByDateRange.prototype.setUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.overview);
