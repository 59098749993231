export const USER_PROFILE_CREATE = 'USER_PROFILE_CREATE';
export const USER_PROFILE_SET = 'USER_PROFILE_SET';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_LOADING = 'USER_PROFILE_LOADING';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET';
export const USER_PROFILE_SIGNATURE_SET = 'USER_PROFILE_SIGNATURE_SET';
export const USER_PROFILE_CHAT_ID_SET = 'USER_PROFILE_CHAT_ID_SET';
export const USER_PROFILE_VIDEO_METADATA_SET = 'USER_PROFILE_VIDEO_METADATA_SET';
export const USER_PROFILE_VIDEO_METADATA_RESET = 'USER_PROFILE_VIDEO_METADATA_RESET';
