export const getOldCompany = () => {
  if (window.localStorage && window.localStorage.getItem('oldCompanyId')) {
    return window.localStorage.getItem('oldCompanyId');
  }
  return null;
};

export const setOldCompanyId = (id) => {
  if (window.localStorage) {
    return window.localStorage.setItem('oldCompanyId', id);
  }
  return null;
};
