import React, { useRef } from 'react';
import * as Emotion from 'emotion';
import { SelectPicker } from 'rsuite';
import { COLORS } from 'constants/colors'

const selectMenu = Emotion.css`
  padding: 11px 13px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 1px;

  .rs-picker-select-menu-item {
    color: ${COLORS.LIGHT_BLUE[600]};
  }
`;

const selectStyles = Emotion.css`
  .rs-picker-toggle-value {
    color: ${COLORS.LIGHT_BLUE[600]} !important;
  }
`;

const InlineSelect = ({
  data,
  option,
  changeOption,
  searchable = true,
  cleanable = false,
  appearance = 'subtle',
  size,
  disabled,
}) => {
  const containerRef = useRef(null)
  const hasData = data && data.length;

  return !!hasData
    &&
    <div ref={containerRef} style={{ position: 'relative', fontSize: "initial", whiteSpace: "nowrap", zIndex: "1" }}>
      <SelectPicker
        menuClassName={selectMenu}
        className={selectStyles}
        placement="bottomEnd"
        appearance={appearance}
        cleanable={cleanable}
        data={data}
        value={option}
        onChange={changeOption}
        searchable={searchable}
        size={size}
        disabled={disabled}
        container={()=> containerRef.current}
      />
      </div>
};

export default InlineSelect;
