const getConfig = (tier, flag) => {
  const hazards = Object.keys(tier);
  return hazards.reduce((acc, hazard) => {
    if (hazard.substring(0, 10) === 'showHazard' && tier[hazard] === flag) {
      const hazardName = hazard[10].toLowerCase() + hazard.substring(11);
      // eslint-disable-next-line no-unused-expressions
      hazardName === 'static' ? acc[`pb${hazardName.charAt(0).toUpperCase()}${hazardName.slice(1)}`] = true : acc[hazardName] = true;
    }
    return acc;
  }, {});
};

export const getHazardsConfig = (tier) => getConfig(tier, true);

export const getHiddenFields = (tier) => getConfig(tier, false);
