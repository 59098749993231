import * as Emotion from 'emotion';

// eslint-disable-next-line import/prefer-default-export
export const legendRowWrapper = Emotion.css`
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
`;

export const itemColorMark = (color) => Emotion.css`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: .75rem;
  background-color: ${color};
  border: 1px solid ${color};
  border-radius: 2px;
`;

export const itemName = Emotion.css`
  white-space: nowrap;
  margin-right: 1.3rem;
`;

export const itemBox = Emotion.css`
  margin-bottom: .25rem;
`;
