import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { logEvent } from 'firebase/analytics';
import { PAGE_VIEW, USER_INFO } from 'constants/analyticsEvents';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import SiteWrapper from './SiteWrapper';
import ConfirmEmail from '../../containers/PrivatPageHOC/components/ConfirmEmail';
import SoterTaskIsMovedModal from '../../containers/PrivatPageHOC/components/SoterTaskIsMovedModal';
import { productStatus } from 'init/proto_client'

const PrivateRoute = ({
  navigate, children, analytics, isAdminArea, setInitLoader,
}) => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { products } = useAppSelector(state => state.companies.selectedCompany);
  const productIsActive = products?.geniusProduct?.status === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE;

  useEffect(() => {
    logEvent(analytics, PAGE_VIEW, { page_path: location.pathname });
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${PAGE_VIEW}, path: ${location.pathname}`));
    }

    if (!sessionStorage.getItem('getUserInfo')) {
      const { innerWidth: width, innerHeight: height } = window;
      sessionStorage.setItem('getUserInfo', true);
      logEvent(analytics, USER_INFO, {
        system_language: navigator.language, screen_width: width, screen_height: height,
      });
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(`${USER_INFO}, language: ${navigator.language}, 
          width: ${width}, height: ${height}`));
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('soter-genius') && !productIsActive) {
      navigate(`soter-genius/chat`);
    }
  }, []);

  if (isAdminArea && auth.profile && auth.profile.role !== 'admin') {
    navigate('/not-found');
  }

  return (
    <SiteWrapper
      navigate={navigate}
      auth={auth}
      isAdminArea={isAdminArea}
      setInitLoader={setInitLoader}
    >
      {auth.profile?.emailConfirmed ? children : <ConfirmEmail />}
      <SoterTaskIsMovedModal/>
    </SiteWrapper>
  );
};

export default PrivateRoute;
