import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from 'lib/ui/Button';
import { ReactComponent as UpgradeIcon } from '../../assets/img/upgrade.svg';
import { COLORS } from '../../constants/colors';
import { coloredButton, mb2 } from '../../assets/soter-flex-styles';

const UpgradeDialog = ({ handleClick, days }) => {
  const { t } = useTranslation();
  const trialEndsDay = () => moment(days, 'X').fromNow();
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <UpgradeIcon />
      <h4 style={{ fontSize: '24px', textAlign: 'center' }} className={`${mb2}`}>
        {`${t('SOTER_TASK.OVERVIEW.TRIAL_EXPIRES')} ${trialEndsDay(days)}`}
      </h4>
      <div className={mb2} style={{ fontSize: '16px' }}>
        {t('SOTER_TASK.OVERVIEW.SUBMIT_TO_UPGRADE')}
      </div>
      <Button
        handler={handleClick}
        style={{ width: '60%', margin: '18px 0' }}
        className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
        text={t('SOTER_TASK.OVERVIEW.CONTACT_SALES')}
      />
    </div>
  );
};

export default UpgradeDialog;
