import { Layer, Rect, Stage } from 'react-konva';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DotedLine from './konvaShapes/DotedLine';
import Slot from './konvaShapes/Slot';
import { chartHeader, legend, legendRect } from '../../../assets/jss/containers/pdfLayersStyle';
import { COLORS } from '../../../constants/colors';

const BarChartWrapper = ({
  header,
  width,
  height,
  withGap,
  legendData,
  axisData,
  data,
}) => {
  const { bottom, doted } = axisData;
  const { t } = useTranslation();
  return (
    <>
      {header && <h1 className={chartHeader}>{header}</h1>}
      <Stage width={width} height={height + 20}>
        <Layer y={20}>
          <Rect
            x={bottom.x}
            y={bottom.y}
            height={0.5}
            width={bottom.width}
            fill={COLORS.GRAY[400]}
          />
          {doted.points.map((point, index, arr) => (
            <DotedLine
              key={`dot${Math.random()}`}
              width={doted.width}
              y={point - bottom.y / arr.length + bottom.y / arr.length}
              x={doted.x}
              domainValue={doted.domainValues[index]?.toFixed()}
            />
          ))}
          {data?.map(({ slotParameters, bars, textParameters }) => (
            <Slot
              key={`slo${Math.random()}`}
              textParameters={textParameters}
              slotParameters={slotParameters}
              bars={bars}
            />
          ))}
        </Layer>
      </Stage>
      {legendData && (
        <div className={legend}>
          {legendData.map(({ title, color }) => (
            <div
              key={`legend${Math.random()}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className={legendRect(color)} />
              {t(title)}
            </div>
          ))}
        </div>
      )}
      {withGap && <div style={{ height: '10mm' }} />}
    </>
  );
};

export default BarChartWrapper;
