import React from 'react';
import moment from 'moment';
import { mutedText } from '../../../../assets/soter-flex-styles';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors'

const HelpRequestCreatedCell = ({ rowData }) => {
  const fromNow = moment(rowData.createdAt, 'X').fromNow();
  const { t } = useTranslation();
  const isDouble =
    fromNow.indexOf('hours') === -1 ||
    !fromNow.includes('min') ||
    fromNow.indexOf('Day ago') === -1;
  let day;
  if (!isDouble) {
    if (fromNow.indexOf('hours') !== -1 || fromNow.indexOf('minutes') !== -1)
      day = t('GENERAL.TODAY');
    if (fromNow.indexOf('Day ago')) day = t('GENERAL.YESTERDAY');
  }
  return (
    <>
      {isDouble ? (
        <div>
          <span style={{ fontSize: '14px' }}>
            {moment(rowData.createdAt, 'X').format('DD MMM, HH:mm')}
          </span>
          <div style={{ fontSize: '12px', color: COLORS.GRAY[400] }} className={mutedText}>
            {fromNow.slice(0, fromNow.length - 3)}
          </div>
        </div>
      ) : (
        <div>
          {`${day}, ${moment(rowData.createdAt, 'X')
            .format('HH:mm')
            ?.slice(
              0,
              moment(rowData.createdAt, 'X').format('HH:mm').length - 3
            )}`}
        </div>
      )}
    </>
  );
};

export default HelpRequestCreatedCell;
