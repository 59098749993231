import React from 'react';
import { SelectPicker } from 'rsuite';
import { navigate } from '@reach/router';
import _sortBy from 'lodash/sortBy';
import { TableType } from 'containers/ItemTableContainer/constants/tableType';
import wearable from '../../../assets/img/icons/wearable_small.svg';
import briefcase from '../../../assets/img/icons/briefcase.svg';
import { companySelect, companySelectMenu } from '../../../assets/jss/style';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import { CHANGE_COMPANY } from '../../../constants/analyticsEvents';
import { createEventNotification } from '../../NotificationContainer/notificationsStore/actions';
import { selectCompany } from '../../../company/companiesStore/actions';
import { clearPagination, setCurrentPage } from '../../ItemTableContainer/tableStateStore/actions';
import { setOpenedModal } from '../../ModalContainer/modalsStore/actions';
import { resetFiltersState } from '../../ItemTableContainer/filtersStoreOld/actions';
import { resetOverview } from '../../../wearables/overview/overviewStore/actions';
import { resetOverviewState } from '../../../wearables/overview/overviewState/actions';
import { clearFilters } from '../../ItemTableContainer/filtersStore/filtersSlice';
import { resetSelectedUsers } from '../../ItemTableContainer/selectedItemsStore/actions';
import { setDateRange } from '../../../wearables/activity/usersStore/actions';
import { overview, routes, wearables } from '../../PrivatPageHOC/constants/routes';
import sliceLongString from '../../../lib/utils/sliceLongString';

const CompanySelect = ({ setInitLoader }) => {
  const {
    companiesList,
    selectedCompany,
  } = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch();

  const companyChangeHandler = (id) => {
    if (selectedCompany.id === id) return;
    window.logEvent(CHANGE_COMPANY, { company_id: id });
    window?.localStorage.setItem('lastVisitedPage', '');
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${CHANGE_COMPANY}, company id: ${id}`));
    }
    setInitLoader(true);
    dispatch(selectCompany(id))
      .then(() => {
        dispatch(setCurrentPage(1, TableType.coach));
        dispatch(setCurrentPage(1, TableType.devices));
        dispatch(resetFiltersState());
        dispatch(setOpenedModal(null));
        dispatch(resetOverview());
        dispatch(resetOverviewState());
        dispatch(clearFilters({ pageType: null }));
        dispatch(resetSelectedUsers());
        dispatch(setDateRange([]));
        dispatch(clearPagination());
      })
      .then(navigate(`/${id}/${routes.users}/${wearables}/${overview}`));
    ['clipgoTrial', 'coachTrial', 'taskTrial', 'jobdictionaryTrial'].map((key) => localStorage.removeItem(key));
  };
  const input = document.getElementsByClassName('rs-picker-search-bar-input');
  return (
    <SelectPicker
      data={_sortBy(companiesList, 'name') || [{ value: 1, name: 1 }]}
      valueKey="id"
      labelKey="name"
      value={selectedCompany?.id}
      className={companySelect}
      menuClassName={companySelectMenu}
      onChange={(companyChangeHandler)}
      cleanable={false}
      searchable
      onOpen={() => input[0]?.focus()}
      renderMenuItem={(label, item) => (
        <div style={{ display: 'flex' }}>
          <span>{sliceLongString(item?.name, 24)}</span>
          {item.hasWearable ? <img style={{ marginLeft: '2px' }} alt="∆" src={wearable} /> : null }
        </div>
      )}
      renderValue={(value, item) => (
        <>
          <img style={{ marginTop: -2 }} src={briefcase} alt="briefcase" />
          <span>{item?.name}</span>
        </>
      )}
    />
  );
};

export default CompanySelect;
