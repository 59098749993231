import React from 'react';
import * as Emotion from 'emotion';
import { card } from 'assets/soter-flex-styles';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import Tooltip from 'lib/ui/Tooltip';
import RiskGroupBadge from 'lib/ui/RiskGroupBadge/RiskGroupBadge';
import moment from 'moment';
import arrowRight from 'assets/img/icons/arrow-right.svg';
import SpineActive from 'assets/img/program/LogoSpineColored.png';
import SpineInactive from 'assets/img/program/LogoSpine.png';
import ShoulderActive from 'assets/img/program/LogoShoulderColored.png';
import ShoulderInactive from 'assets/img/program/LogoShoulder.png';
import { useTranslation } from 'react-i18next';
import { CARD_TITLES } from '../ProgramContainer/constants';

const programFlexContainer = Emotion.css`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 5px;
`;

const line = Emotion.css`
  position: absolute;
  top: 50%;
  width: 95%;
  height: 4px;
  border-radius: 4px;
  background-color: ${COLORS.GRAY[400]};
  margin: 0 5% 0 0;
  z-index: 9;
`;

const Image = ({ name }) => (
  <img
    src={name}
    style={{
      width: '64px',
      height: '64px',
      position: 'absolute',
    }}
    alt="program"
  />
);

const images = [
  [<Image name={SpineActive} />, <Image name={ShoulderActive} />],
  [<Image name={SpineInactive} />, <Image name={ShoulderInactive} />],
];

const ProgramSlot = ({
  isSelected,
  clickHandler,
  startedAt,
  finishedAt,
  initialRiskGroup,
  currentRiskGroup,
  deviceType,
  title,
  forPDF,
}) => (
  <Tooltip text={title} placement="top">
    <div
      style={
        !forPDF
          ? {
            backgroundColor: COLORS.WHITE,
            zIndex: 10,
            borderRadius: '100px',
            cursor: 'pointer',
          }
          : null
      }
      onClick={clickHandler}
    >
      <div
        style={{ height: '36px', margin: '8px 0' }}
        className={text(COLORS.GRAY[400], 12)}
      >
        <div>
          {moment(startedAt, 'x').format('DD MMM YYYY')}
          {' '}
          -
          {' '}
        </div>
        {finishedAt ? (
          <div>{moment(finishedAt, 'x').format('DD MMM YYYY')}</div>
        ) : null}
      </div>
      <div style={{ width: '64px', height: '64px', position: 'relative' }}>
        <div
          style={{
            width: '64px',
            height: '64px',
            backgroundColor: COLORS.WHITE,
            zIndex: '21',
            position: 'absolute',
          }}
        >
          {images[isSelected ? 0 : 1][deviceType !== 'armband' ? 0 : 1]}
        </div>
      </div>
      <div style={{ marginTop: '12px' }}>
        <RiskGroupBadge
          mediumSize
          riskGroup={initialRiskGroup}
          showTooltip={false}
        />
        <img style={{ marginBottom: '10px' }} src={arrowRight} alt=">" />
        <RiskGroupBadge
          mediumSize
          riskGroup={currentRiskGroup}
          showTooltip={false}
        />
      </div>
    </div>
  </Tooltip>
);

const ProgramSelector = ({
  programList,
  currentProgram,
  setCurrentProgram,
  noTitle = false,
  forPDF = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={!forPDF ? card : null}
      style={
        !forPDF
          ? { padding: '10px', minWidth: '300px', height: '222px' }
          : { margin: '5px 12px -10px 12px', minWidth: '300px' }
      }
    >
      {noTitle ? null : (
        <div className={text(COLORS.GRAY[400], 20)}>
          {t(CARD_TITLES.programHistory).toUpperCase()}
        </div>
      )}
      <div className={programFlexContainer}>
        {[...programList]
          .sort((a, b) => b.startedAtInMilliseconds - a.startedAtInMilliseconds)
          .slice(0, 8)
          .reverse()
          .map(
            ({
              coachingProgramPassId: id,
              title,
              deviceType,
              startedAtInMilliseconds,
              finishedAtInMilliseconds,
              riskGroup,
              currentRiskGroup,
            }) => (
              <ProgramSlot
                key={id}
                isSelected={id === currentProgram}
                clickHandler={() => setCurrentProgram(id)}
                title={title}
                deviceType={deviceType}
                startedAt={startedAtInMilliseconds}
                finishedAt={finishedAtInMilliseconds}
                initialRiskGroup={riskGroup}
                forPDF={forPDF}
                currentRiskGroup={currentRiskGroup}
              />
            ),
          )}
        <hr className={line} />
      </div>
    </div>
  );
};

export default ProgramSelector;
