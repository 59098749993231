import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { button } from '../../assets/jss/style';
import { COLORS } from '../../constants/colors';
import { routes } from '../../containers/PrivatPageHOC/constants/routes';

const NotFound = () => {
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.companies.selectedCompany);
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: 'url(../assets/img/404.png)',
        backgroundColor: COLORS.WHITE,
        backgroundRepeat: 'no-repeat',
        paddingTop: '17%',
        backgroundPosition: 'top 95px left 210px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            color: COLORS.BLUE[600],
            fontSize: '164px',
            paddingRight: '25px',
            borderRight: `3px solid ${COLORS.GRAY[400]}`,
          }}
        >
          404
        </div>
        <div style={{ marginLeft: '21px' }}>
          <p
            style={{ fontSize: '72px', color: COLORS.GRAY[400], fontWeight: '600' }}
          >
            {t('NOT_FOUND.TITLE')}
          </p>
          <p
            style={{ fontSize: '24px', fontWeight: '300', color: COLORS.GRAY[300] }}
          >
            {t('NOT_FOUND.DESCRIPTION')}
          </p>
          <Link
            className={button(COLORS.LIGHT_BLUE[600], true)}
            style={{
              textDecoration: 'none',
              maxWidth: '290px',
              marginTop: '24px',
            }}
            to={`/${id}/${routes.users}`}
          >
            {t('NOT_FOUND.GO_BACK')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
