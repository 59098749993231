import React from 'react';
import {
  Group, Layer, Line, Rect, Stage, Text,
} from 'react-konva';
import { COLORS } from 'constants/colors';
import hexToRgba from '../../../lib/utils/hexToRgba';

const AlphaLayer = ({
  width, height, yStart, color,
}) => (
  <Rect
    width={width}
    height={height}
    x={0}
    y={yStart}
    fill={color}
    globalCompositeOperation="source-atop"
    opacity={1}
  />
);

const KonvaChart = ({
  anglesData,
  axisData,
  progressPosition,
  currentTime,
  scale = 1,
  videoWidth,
  chartHeight,
}) => {
  const { thresholds, points, angles } = anglesData;
  const { yAxis, xAxis } = axisData;
  const lineLayerWidth = videoWidth;
  const angle = Math.round(angles[Math.round((currentTime / 1000) * 30)]);
  return (
    <Stage
      height={chartHeight * scale}
      width={videoWidth * scale}
      scale={{ x: scale, y: scale }}
    >
      <Layer>
        {points.map(
          (pointsChunk) => pointsChunk && (
          <Line
            key={pointsChunk.length + Math.random()}
            points={pointsChunk}
            stroke={COLORS.GRAY[950]}
            strokeWidth={1}
          />
          ),
        )}
        <Group>
          {thresholds.negligible && (
            <AlphaLayer
              width={lineLayerWidth}
              height={thresholds.negligible.height}
              yStart={thresholds.negligible.start}
              color={!thresholds.negligible ? COLORS.GREEN[600] : COLORS.BLUE[600]}
            />
          )}
          <AlphaLayer
            width={lineLayerWidth}
            height={thresholds.low.height}
            yStart={thresholds.low.start}
            color={COLORS.GREEN[600]}
          />
          <AlphaLayer
            width={lineLayerWidth}
            height={thresholds.mid.height}
            yStart={thresholds.mid.start}
            color={COLORS.ORANGE[600]}
          />
          <AlphaLayer
            width={lineLayerWidth}
            height={thresholds.high.height}
            yStart={thresholds.high.start}
            color={COLORS.RED[600]}
          />
          {thresholds.veryHigh && (
            <AlphaLayer
              width={lineLayerWidth}
              height={thresholds.veryHigh.height}
              yStart={thresholds.veryHigh.start}
              color={COLORS.RED[900]}
            />
          )}
        </Group>
      </Layer>
      <Layer>
        {/* <Line points={[0, 0, 0, CHART_HEIGHT]} stroke={COLORS.GRAY[400]} strokeWidth={1} /> */}
        {yAxis.map(({ point, value }) => (
          <Group key={point}>
            <Line
              points={[0, point + 1, 15, point + 1]}
              stroke={COLORS.GRAY[100]}
              strokeWidth={1}
            />
            <Text
              fontSize={10}
              fontFamily="Mulish"
              text={`${value}${!thresholds.negligible ? '°' : ''}`}
              x={6}
              y={point + 4}
              fill={COLORS.GRAY[400]}
              strokeWidth={0.3}
            />
          </Group>
        ))}
        <Line
          points={[0, chartHeight, videoWidth - 1, chartHeight]}
          stroke={COLORS.GRAY[400]}
          strokeWidth={1}
        />
        {xAxis.map(({ point, value }, index) => (
          <Group key={point}>
            <Line
              points={[point, chartHeight, point, chartHeight - 14]}
              stroke={COLORS.GRAY[100]}
              strokeWidth={1}
            />
            <Text
              fontSize={10}
              fontFamily="Mulish"
              text={value}
              x={point + 3}
              y={chartHeight - 11}
              fill={COLORS.GRAY[400]}
              strokeWidth={0.3}
            />
          </Group>
        ))}
      </Layer>
      <Layer>
        {progressPosition > 0 && (
          <>
            <Rect
              width={33}
              height={18}
              x={progressPosition - 16}
              y={0}
              fill={hexToRgba(
                COLORS.LIGHT_BLUE[600],
                progressPosition > 3 ? 0.6 : progressPosition / 5,
              )}
              cornerRadius={10}
              globalCompositeOperation="multiply"
            />
            <Text
              text={angle || angle >= 0 ? `${angle}˚` : ''}
              x={
                progressPosition
                - (`${angle}`.length === 3 ? 10 : `${angle}`.length === 2 ? 7 : 4)
              }
              y={4}
              fontSize={12}
              fontFamily="Mulish"
              fill={COLORS.GRAY[950]}
              fontStyle="bold"
            />
          </>
        )}
        <Line
          points={[
            Math.round(progressPosition || 0),
            19,
            Math.round(progressPosition || 0),
            chartHeight,
          ]}
          stroke={COLORS.LIGHT_BLUE[600]}
          strokeWidth={0.8}
          dash={[4, 2]}
        />
        {/* <Circle fill='red' radius={5} x={Math.round(progressPosition)} y={points[Math.round((progressPosition * videoLengthPixelRatio) / 2) - 1]}/> */}
      </Layer>
    </Stage>
  );
};

export default KonvaChart;
