import { COLORS } from 'constants/colors';
import * as Emotion from 'emotion';
export const formWrapper = Emotion.css `
  width: calc(100% + 1rem);
  padding-right: 1rem;
  max-height: 475px;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const newCompanyTitle = Emotion.css `
  font-size: 26px;
  font-weight: 800;
  color: ${COLORS.GRAY[950]};
  margin: -10px 0 6px;
  padding-left: 10px;
`;
export const createCompanyBtn = Emotion.css `
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background: #0D77D6;
  border-radius: 6px;
  width: 96%;
  height: 40px;
  margin: 20px auto 0;
  display: block;
`;
export const inputStyles = Emotion.css `
  color: ${COLORS.GRAY[950]};

  &::placeholder {
    color: rgba(0, 0, 0, 0.24);
  }
`;
export const inputWrapper = Emotion.css `
  position: relative;

  svg {
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    pointer-events: none;
  }
`;
