import { Risks } from '../init/proto_client';

// TODO: Transform this functions to constants
export const getRiskGroupText = (risk) => {
  switch (risk) {
    case 'high': return 'WEARABLES.OVERVIEW.HIGH_RISK';
    case 'medium': return 'WEARABLES.OVERVIEW.MEDIUM_RISK';
    case 'lower': return 'WEARABLES.OVERVIEW.LOW_RISK';
    default: return '-';
  }
};

export const getRiskText = (risk) => {
  if (risk === Risks.LOW) return 'SOTER_TASK.RISKS.LOWER';
  if (risk === Risks.MEDIUM) return 'SOTER_TASK.RISKS.MEDIUM';
  if (risk === Risks.HIGH) return 'SOTER_TASK.RISKS.HIGHER';
};
