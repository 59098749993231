import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { resetPassword } from './store/actions';
import { clearError } from './store/authSlice';
import ForgotPasswordPageContainer from './PasswordContainer/ForgotPasswordPageContainer';
import LoginFormCard from './LoginContainer/components/LoginFormCard';
import RecoverActionSuccess from './PasswordContainer/components/RecoverActionSuccess';
import RecoverActionFailed from './PasswordContainer/components/RecoverActionFailed';
import LoginTextInput from './LoginContainer/components/LoginTextInput';
import { ValidateAsyncOnChange } from './ValidateAsyncOnChange';
import { useAppDispatch, useAppSelector } from '../lib/utils/useStore';
import emailValidator from './emailValidator';

const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useAppSelector((state) => state.auth);
  const [isReset, setIsReset] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { t } = useTranslation();
  const pageElementsNames = {
    title: t('RECOVER_PASSWORD'),
    buttonText: t('LOGIN.RECOVERY_LINK'),
    emailLabel: t('LOGIN.EMAIL'),
    emailPlaceholder: t('LOGIN.EMAIL_PLACEHOLDER'),
  };

  const validate = emailValidator(t);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      const { email } = values;
      dispatch(resetPassword({ email }));
      setIsReset(true);
    },
  });

  const debouncedValidation = () => sleep(500).then(() => validate(formik.values));
  const isButtonDisabled = !!validate(formik.values).email;

  const successMessage = t('LOGIN.FORGOT_PASSWORD_SUCCESS');
  const failedMessage = t('LOGIN.FORGOT_PASSWORD_FAILED');

  useEffect(() => {
    dispatch(clearError());
  }, []);

  return (
    <>
      <ForgotPasswordPageContainer>
        <div style={{
          marginLeft: '-.75em',
        }}
        />
        <LoginFormCard
          showBackButton
          handleBackButton={() => {
            setIsReset(false);
            navigate(-1);
          }}
          buttonText={pageElementsNames.buttonText}
          title={pageElementsNames.title}
          onSubmit={formik.handleSubmit}
          buttonDisabled={isButtonDisabled}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isReset && !(error?.code === 3)
            ? <RecoverActionSuccess message={successMessage} />
            : error?.code === 3
              ? <RecoverActionFailed message={failedMessage} />
              : (
                <LoginTextInput
                  name="email"
                  label={pageElementsNames.emailLabel}
                  placeholder={pageElementsNames.emailPlaceholder}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values && formik.values.email}
                  error={formik.dirty && formik.errors && formik.errors.email}
                  touched={formik.dirty}
                  errorMessage={formik.errors?.email}
                />
              )}
        </LoginFormCard>
      </ForgotPasswordPageContainer>
      <ValidateAsyncOnChange validate={debouncedValidation} formik={formik} />
    </>
  );
};

export default ForgotPasswordPage;
