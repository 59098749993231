var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toCamel } from 'snake-camel';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { navigate } from '@reach/router';
import { authMeRequest, authService, confirmEmailRequest, getMetaData, loginRequest, productTrialRequest, resendConfirmationRequest, resetPasswordByTokenRequest, resetPasswordRequest, taskAuthService, taskContactRequest, taskContactService, userResourceRequest, SSOService, SSOURLRequest, } from '../../init/proto_client';
import setCookie from './setCookie';
import { LOGIN_ERROR } from '../../constants/analyticsEvents';
import { createEventNotification, setNotification, } from '../../containers/NotificationContainer/notificationsStore/actions';
import { LOGIN, SSO_LOGIN } from './auth';
import { resetProfile } from './authSlice';
import { customError } from './CustomError';
import { notificationMessages, notificationTypes } from '../../containers/NotificationContainer/notifications';
import { openedModalSelector } from '../../containers/ModalContainer/modalsStore/selectors';
import { setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import { selectCompany } from '../../company/companiesStore/actions';
const Cookies = require('js-cookie');
export const pending = (dispatch) => dispatch({
    type: 'auth/pending',
    payload: true,
});
export const rejected = (dispatch, message) => dispatch({
    type: 'auth/rejected',
    payload: message,
});
export const login = createAsyncThunk('auth/login', ({ email, password }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    loginRequest.setPassword(password);
    loginRequest.setUsername(email);
    yield authService.login(loginRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const auth = toCamel(response.toObject());
            localStorage.setItem('token', auth.accessToken);
            setCookie('sid', auth.accessToken, 365);
            thunkAPI.dispatch({
                type: 'auth/login/fulfilled',
                payload: { auth, anonymous: false },
            });
        }
        if (error) {
            // @ts-ignore
            window.logEvent(LOGIN_ERROR, { error_code: error.code });
            if (localStorage.getItem('showEvents')) {
                thunkAPI.dispatch(createEventNotification(LOGIN_ERROR));
            }
            thunkAPI.dispatch({
                type: 'auth/login/rejected',
                payload: { error, errorAction: LOGIN },
            });
        }
    }));
}));
export const authMe = createAsyncThunk('auth/authMe', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    authMeRequest.setDummy(true);
    yield authService.authMe(authMeRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const auth = toCamel(response.toObject());
            thunkAPI.dispatch({
                type: 'auth/authMe/fulfilled',
                payload: { auth: toCamel(response.toObject()) },
            });
            localStorage.setItem('token', auth.accessToken);
        }
        if (error) {
            thunkAPI.dispatch({
                type: 'auth/authMe/rejected',
                payload: { anonymous: true, error },
            });
        }
    }));
}));
export const requestSSOLink = createAsyncThunk('auth/requestSSOLink', (email, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    SSOURLRequest.setEmail(email);
    yield SSOService.getSSOUrl(SSOURLRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (error) {
            thunkAPI.dispatch({
                type: 'auth/requestSSOLink/rejected',
                payload: { error, errorAction: SSO_LOGIN },
            });
        }
        else {
            const link = response.toObject().ssoUrl;
            navigate(link);
        }
    });
}));
export const getUserResource = createAsyncThunk('auth/getUserResource', ({ companyId }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    userResourceRequest.setCompanyId(companyId);
    yield authService.getUserResource(userResourceRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch({
                type: 'auth/getUserResource/fulfilled',
                payload: { resources: response.toObject() },
            });
        }
        if (error) {
            rejected(thunkAPI.dispatch, error);
        }
    }));
}));
export const logout = createAsyncThunk('auth/logout', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        thunkAPI.dispatch(resetProfile());
        localStorage.removeItem('token');
        Cookies.remove('sid');
    }
    catch (e) {
        const error = customError(e, 'info', 'AUTH_ERROR');
        rejected(thunkAPI.dispatch, error);
    }
}));
export const confirmEmail = createAsyncThunk('auth/confirmEmail', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    confirmEmailRequest.setDummy(true);
    yield taskAuthService.confirmEmail(userResourceRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch(authMe());
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.EMAIL_CONFIRM_SUCCESS_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error);
        }
    }));
}));
export const resetPassword = createAsyncThunk('auth/resetPassword', ({ email }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    resetPasswordRequest.setEmail(email);
    yield authService.resetPasswordSendLink(resetPasswordRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            const state = thunkAPI.getState();
            const openedModal = openedModalSelector(state);
            thunkAPI.dispatch({
                type: 'auth/resetPassword/fulfilled',
                payload: { isPasswordReset: true },
            });
            if (openedModal === 'resetPassword') {
                thunkAPI.dispatch(setNotification({
                    type: notificationTypes.TYPE_SUCCESS,
                    message: notificationMessages.RESET_PASSWORD_SUCCESS_MSG,
                }));
                thunkAPI.dispatch(setOpenedModal(null));
            }
        }
        if (error) {
            thunkAPI.dispatch({
                type: 'auth/resetPassword/rejected',
                payload: { anonymous: true, error },
            });
        }
    }));
}));
export const resetPasswordByToken = createAsyncThunk('auth/resetPasswordByToken', ({ token, password, repeatedPassword }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    resetPasswordByTokenRequest.setToken(token);
    resetPasswordByTokenRequest.setPassword(password);
    resetPasswordByTokenRequest.setPassword2(repeatedPassword);
    yield authService.resetPasswordByToken(resetPasswordByTokenRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch({
                type: 'auth/resetPasswordByToken/fulfilled',
                payload: { isPasswordSet: true },
            });
        }
        if (error) {
            thunkAPI.dispatch({
                type: 'auth/resetPasswordByToken/rejected',
                payload: { anonymous: true, error },
            });
        }
    }));
}));
// export const requestTrial = createAsyncThunk(
//   'auth/requestTrial',
//   async ({ productType }: any, thunkAPI) => {
//     productTrialRequest.setProductType(productType);
//     await productTrialService.productTrial(
//       productTrialRequest,
//       // @ts-ignore
//       getMetaData(),
//       async (error: any, response: any) => {
//         if (response) {
//           thunkAPI.dispatch(setNotification({
//             type: notificationTypes.TYPE_SUCCESS,
//             message: notificationMessages.PRODUCT_TRIAL_REQUESTED_SUCCESS,
//           }));
//         }
//         if (error) {
//           rejected(thunkAPI.dispatch, error);
//           thunkAPI.dispatch(setNotification({
//             type: notificationTypes.TYPE_FAILED,
//             message: notificationMessages.PRODUCT_TRIAL_REQUESTED_ERROR,
//           }));
//         }
//       },
//     );
//   },
// );
export const contactSales = createAsyncThunk('auth/contactSales', ({ companyId, productType }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    taskContactRequest.setCompanyId(companyId);
    taskContactRequest.setProductType(productType);
    yield taskContactService.contactSales(taskContactRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => {
        if (response) {
            thunkAPI.dispatch({
                type: 'auth/contactSales/fulfilled',
                payload: { isContactSales: true },
            });
            thunkAPI.dispatch(selectCompany(companyId));
        }
        if (error) {
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.SERVER_ERROR_MSG,
            }));
        }
    });
}));
export const requestEmailConfirm = createAsyncThunk('auth/requestEmailConfirm', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    resendConfirmationRequest.setDummy();
    yield taskAuthService.resendConfirmationEmail(productTrialRequest, 
    // @ts-ignore
    getMetaData(), (error, response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response) {
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_SUCCESS,
                message: notificationMessages.EMAIL_CONFIRM_REQUEST_MSG,
            }));
        }
        if (error) {
            rejected(thunkAPI.dispatch, error);
            thunkAPI.dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.EMAIL_CONFIRM_REQUEST_ERROR_MSG,
            }));
        }
    }));
}));
