import { COLORS } from 'constants/colors';
export const riskColorMap = [
    {
        name: 'WEARABLES.OVERVIEW.PROGRAM_START',
        color: COLORS.INDIGO[800],
    },
    {
        name: 'WEARABLES.OVERVIEW.PROGRAM_END',
        color: COLORS.INDIGO[500],
    },
];
