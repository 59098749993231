import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { overflow } from '../../assets/soter-flex-styles';
import Navigation from './components/Navigation';
import SecondLevelNavigation from './components/SecondLevelNavigation';
import {
  adminNavSettings, myCompanyNav,
  navSettingsRegular,
  soterGeniusNavSettings,
  wearableNavSettings,
} from './constants/navSettings';
import { navigationLevels, routes } from './constants/routes';
import { useAppSelector } from '../../lib/utils/useStore';
import { productStatus } from '../../init/proto_client';
import capitalizeFirstLetter from '../../lib/utils/capitalizeFirstLetter';
import ConfirmEmail from './components/ConfirmEmail';
import { getOverflowDivHeightToSubtract } from './utils/getOverflowDivHeightToSubtract';

const getCustomizedNavLinks = (activePath, navSettings, profile, selectedCompanyId) => (
  navSettings.map((itemSettings) => {
    // Uncomment code below to enable Need Action bubble
    const requests = profile && profile[itemSettings?.activeQuantity];
    const requestsForSelectedCompany = requests && requests.find((i) => i.companyId === selectedCompanyId);
    return (
      <div key={itemSettings.name} style={{ position: 'relative' }}>
        {/* Uncomment code below to enable Need Action bubble */}
        {/*
         {activePath === 'wearables' && requestsForSelectedCompany?.count ? (
          <div className={bubble}>
            {requestsForSelectedCompany.count}
          </div>
        ) : null}
        */}
        <Navigation.Link settings={itemSettings} />
      </div>
    );
  })
);

const renderNavigation = (activePath, location, navSettings, nestedActivePath) => (
  <Navigation activePath={location.pathname.includes(routes.company) ? nestedActivePath : activePath}>
    {getCustomizedNavLinks(activePath,
      // eslint-disable-next-line no-nested-ternary
      location.pathname.includes(routes.admin)
        ? adminNavSettings : location.pathname.includes(routes.company) ? myCompanyNav : navSettings)}
  </Navigation>
);

const PrivatePageHOC = (Page) => (props) => {
  const auth = useAppSelector((state) => state.auth);
  const isEmailConfirmed = useAppSelector((state) => state.auth.profile.emailConfirmed);
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const { products } = selectedCompany;
  const [navSettings, setNavSettings] = useState(navSettingsRegular);
  const location = useLocation();
  const activePath = location.pathname.split('/')[navigationLevels.product];
  const nestedActivePath = location.pathname.split('/')[navigationLevels.page];
  useEffect(() => {
    setNavSettings(navSettingsRegular);
    if (products?.wearableProduct?.status === productStatus.PRODUCTSTATUSNOTAVAILABLE) {
      setNavSettings(navSettings.filter(({ name }) => name !== capitalizeFirstLetter(routes.overview) && name !== capitalizeFirstLetter(routes.devices)));
    }
  }, [selectedCompany]);
  return (
    (!isEmailConfirmed ? <ConfirmEmail />
      : (
        <div
          className={overflow(getOverflowDivHeightToSubtract(
            activePath,
            nestedActivePath,
            location.pathname,
            products?.wearableProduct?.status !== productStatus.PRODUCTSTATUSNOTAVAILABLE,
          ))}
          id="scrollContainer"
        >
          {/* <div style={{ overflow: 'hidden' }}> */}
          <div>
            <div style={{ width: '100vw' }}>
              {renderNavigation(activePath, location, navSettings, nestedActivePath)}
              {
                ((activePath === routes.wearables && products?.wearableProduct?.status
            === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE)
          || (activePath === routes.genius && products?.geniusProduct?.status === productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE))  &&
        (
          <SecondLevelNavigation
            activePath={nestedActivePath}
            links={getCustomizedNavLinks(activePath, activePath === routes.wearables
              ? wearableNavSettings
              : soterGeniusNavSettings, auth, selectedCompany.id)}
          />
        )}
              <div id="scrollWaypoint" />
              <Page {...props} />
            </div>
          </div>
        </div>
      )
    ));
};

export default PrivatePageHOC;
