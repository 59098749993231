import * as Emotion from 'emotion';
import { flexColumn } from './flex-base-elements';

// eslint-disable-next-line import/prefer-default-export
export const headerControlsContainer = Emotion.css`
  ${flexColumn};
  
  display: flex;
  margin-top: .75rem;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;
