import React from 'react';
import { useTranslation } from 'react-i18next';
import DonutChart from './DonutChart';
import { LegendPosition } from '../../utils/d3/Chart';
import { deviceTypes } from '../../constants/deviceTypes';

const HMofShoulderDonutChart = ({
  HMofShoulderByDepartmentByPeriod,
  containerHeight,
  hasDataForChart,
}) => {
  const { t } = useTranslation();
  const chartId = 'hm-of-shoulder-donut-chart';
  const hazards = Object.keys(HMofShoulderByDepartmentByPeriod);
  const getData = () => hazards.reduce((acc, hazard) => {
    if (hazard !== 'total') {
      return [...acc,
        {
          name: hazard,
          value: HMofShoulderByDepartmentByPeriod[hazard],
        },
      ];
    }
    return acc;
  }, []).concat([{
    columns: ['name', 'value'],
  }]);

  const overallNumber = HMofShoulderByDepartmentByPeriod.total &&
    !Number.isNaN(HMofShoulderByDepartmentByPeriod.total) &&
    (+HMofShoulderByDepartmentByPeriod.total).toFixed(1);

  return (
    <DonutChart
      chartId={chartId}
      hasDataForChart={hasDataForChart}
      getData={getData}
      sessionHazards={hazards}
      containerHeight={containerHeight}
      overallNumber={overallNumber}
      withLegend
      legendPosition={LegendPosition.RIGHT}
      overallText={t('WEARABLES.OVERVIEW.HAZARDS_PER_HOUR')}
      deviceType={deviceTypes.armband}
    />
  );
};

export default HMofShoulderDonutChart;
