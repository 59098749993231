import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  comparisonList: [],
  currentComparison: {},
  firstVideoMovementData: [],
  secondVideoMovementData: [],
  newComparisonIds: [null, null],
  error: null,
  isLoading: true,
  assessmentsList: {
    firstVideoAssessments: [],
    secondVideoAssessments: [],
  },
});

export default function reduce(state = initialState, action = {}) {
  const {
    currentComparison, comparisonList, error, newComparisonIds,
    firstVideoMovementData, secondVideoMovementData, assessmentsList,
  } = action;
  switch (action.type) {
    case types.COMPARISON_LIST_SET: return state.merge({ comparisonList, error: null, isLoading: false });
    case types.CURRENT_COMPARISON_SET: return state.merge({ currentComparison, error: null });
    case types.NEW_COMPARISON_IDS_SET: return state.merge({ newComparisonIds });
    case types.COMPARISON_FIRST_VIDEO_MOVEMENT_DATA: return state.merge({ firstVideoMovementData });
    case types.COMPARISON_SECOND_VIDEO_MOVEMENT_DATA: return state.merge({ secondVideoMovementData });
    case types.COMPARISON_ERROR: return state.merge({ error });
    case types.COMPARISON_RESET: return state.merge(initialState);
    case types.COMPARISON_ASSESSMENTS: return state.merge({ assessmentsList });
    default: return state;
  }
}
