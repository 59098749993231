// source: api/dashboard/v2/company_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_tier_message_pb = require('../../../api/dashboard/v2/tier_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_tier_message_pb);
var api_dashboard_v2_company_product_message_pb = require('../../../api/dashboard/v2/company_product_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_company_product_message_pb);
var api_job_dictionary_v1_job_dictionary_service_pb = require('../../../api/job_dictionary/v1/job_dictionary_service_pb.js');
goog.object.extend(proto, api_job_dictionary_v1_job_dictionary_service_pb);
var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_site_message_pb);
var api_dashboard_v2_segment_message_pb = require('../../../api/dashboard/v2/segment_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_segment_message_pb);
goog.exportSymbol('proto.company.CompaniesCompany', null, global);
goog.exportSymbol('proto.company.CompaniesWithDepartments', null, global);
goog.exportSymbol('proto.company.CompaniesWithSitesAndDepartments', null, global);
goog.exportSymbol('proto.company.Company', null, global);
goog.exportSymbol('proto.company.CompanyCoachingProgram', null, global);
goog.exportSymbol('proto.company.CompanyReseller', null, global);
goog.exportSymbol('proto.company.HelpRequestByCompanies', null, global);
goog.exportSymbol('proto.company.Program', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.CompanyReseller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company.CompanyReseller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.CompanyReseller.displayName = 'proto.company.CompanyReseller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.CompanyCoachingProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company.CompanyCoachingProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.CompanyCoachingProgram.displayName = 'proto.company.CompanyCoachingProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.Program = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company.Program, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.Program.displayName = 'proto.company.Program';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.CompaniesCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.company.CompaniesCompany.repeatedFields_, null);
};
goog.inherits(proto.company.CompaniesCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.CompaniesCompany.displayName = 'proto.company.CompaniesCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.HelpRequestByCompanies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.company.HelpRequestByCompanies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.HelpRequestByCompanies.displayName = 'proto.company.HelpRequestByCompanies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.company.Company.repeatedFields_, null);
};
goog.inherits(proto.company.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.Company.displayName = 'proto.company.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.CompaniesWithDepartments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.company.CompaniesWithDepartments.repeatedFields_, null);
};
goog.inherits(proto.company.CompaniesWithDepartments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.CompaniesWithDepartments.displayName = 'proto.company.CompaniesWithDepartments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.company.CompaniesWithSitesAndDepartments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.company.CompaniesWithSitesAndDepartments.repeatedFields_, null);
};
goog.inherits(proto.company.CompaniesWithSitesAndDepartments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.company.CompaniesWithSitesAndDepartments.displayName = 'proto.company.CompaniesWithSitesAndDepartments';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.CompanyReseller.prototype.toObject = function(opt_includeInstance) {
  return proto.company.CompanyReseller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.CompanyReseller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompanyReseller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasWearable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.CompanyReseller}
 */
proto.company.CompanyReseller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.CompanyReseller;
  return proto.company.CompanyReseller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.CompanyReseller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.CompanyReseller}
 */
proto.company.CompanyReseller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWearable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.CompanyReseller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.CompanyReseller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.CompanyReseller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompanyReseller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasWearable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.CompanyReseller.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompanyReseller} returns this
 */
proto.company.CompanyReseller.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.company.CompanyReseller.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.CompanyReseller} returns this
 */
proto.company.CompanyReseller.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_wearable = 3;
 * @return {boolean}
 */
proto.company.CompanyReseller.prototype.getHasWearable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company.CompanyReseller} returns this
 */
proto.company.CompanyReseller.prototype.setHasWearable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.CompanyCoachingProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.company.CompanyCoachingProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.CompanyCoachingProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompanyCoachingProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.CompanyCoachingProgram}
 */
proto.company.CompanyCoachingProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.CompanyCoachingProgram;
  return proto.company.CompanyCoachingProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.CompanyCoachingProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.CompanyCoachingProgram}
 */
proto.company.CompanyCoachingProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.CompanyCoachingProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.CompanyCoachingProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.CompanyCoachingProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompanyCoachingProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.CompanyCoachingProgram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompanyCoachingProgram} returns this
 */
proto.company.CompanyCoachingProgram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.company.CompanyCoachingProgram.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.CompanyCoachingProgram} returns this
 */
proto.company.CompanyCoachingProgram.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.Program.prototype.toObject = function(opt_includeInstance) {
  return proto.company.Program.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.Program} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.Program.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.Program}
 */
proto.company.Program.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.Program;
  return proto.company.Program.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.Program} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.Program}
 */
proto.company.Program.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.Program.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.Program.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.Program} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.Program.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.Program.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.Program} returns this
 */
proto.company.Program.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.company.Program.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.Program} returns this
 */
proto.company.Program.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.company.Program.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.Program} returns this
 */
proto.company.Program.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.company.CompaniesCompany.repeatedFields_ = [14,15,17,20,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.CompaniesCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.company.CompaniesCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.CompaniesCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompaniesCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAtInMilliseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    region: jspb.Message.getFieldWithDefault(msg, 4, ""),
    workSessionTimeMinutes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    workSessionIntervalMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    showGroupedValues: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    reportPeopleNames: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    reseller: (f = msg.getReseller()) && proto.company.CompanyReseller.toObject(includeInstance, f),
    defaultCoachingProgram: (f = msg.getDefaultCoachingProgram()) && proto.company.CompanyCoachingProgram.toObject(includeInstance, f),
    availableCoachingProgramsList: jspb.Message.toObjectList(msg.getAvailableCoachingProgramsList(),
    proto.company.CompanyCoachingProgram.toObject, includeInstance),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard_v2_department_message_pb.Department.toObject, includeInstance),
    tier: (f = msg.getTier()) && api_dashboard_v2_tier_message_pb.MessageTier.toObject(includeInstance, f),
    workGroupsList: jspb.Message.toObjectList(msg.getWorkGroupsList(),
    api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryWorkGroup.toObject, includeInstance),
    jdTags: (f = msg.getJdTags()) && api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryTags.toObject(includeInstance, f),
    products: (f = msg.getProducts()) && api_dashboard_v2_company_product_message_pb.CompanyProduct.toObject(includeInstance, f),
    companyTagsList: jspb.Message.toObjectList(msg.getCompanyTagsList(),
    api_dashboard_v2_segment_message_pb.CompanyTag.toObject, includeInstance),
    companySegmentList: jspb.Message.toObjectList(msg.getCompanySegmentList(),
    api_dashboard_v2_segment_message_pb.Segment.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    api_dashboard_v2_site_message_pb.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.CompaniesCompany}
 */
proto.company.CompaniesCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.CompaniesCompany;
  return proto.company.CompaniesCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.CompaniesCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.CompaniesCompany}
 */
proto.company.CompaniesCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAtInMilliseconds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkSessionTimeMinutes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkSessionIntervalMinutes(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowGroupedValues(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReportPeopleNames(value);
      break;
    case 12:
      var value = new proto.company.CompanyReseller;
      reader.readMessage(value,proto.company.CompanyReseller.deserializeBinaryFromReader);
      msg.setReseller(value);
      break;
    case 13:
      var value = new proto.company.CompanyCoachingProgram;
      reader.readMessage(value,proto.company.CompanyCoachingProgram.deserializeBinaryFromReader);
      msg.setDefaultCoachingProgram(value);
      break;
    case 14:
      var value = new proto.company.CompanyCoachingProgram;
      reader.readMessage(value,proto.company.CompanyCoachingProgram.deserializeBinaryFromReader);
      msg.addAvailableCoachingPrograms(value);
      break;
    case 15:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 16:
      var value = new api_dashboard_v2_tier_message_pb.MessageTier;
      reader.readMessage(value,api_dashboard_v2_tier_message_pb.MessageTier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 17:
      var value = new api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryWorkGroup;
      reader.readMessage(value,api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryWorkGroup.deserializeBinaryFromReader);
      msg.addWorkGroups(value);
      break;
    case 18:
      var value = new api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryTags;
      reader.readMessage(value,api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryTags.deserializeBinaryFromReader);
      msg.setJdTags(value);
      break;
    case 19:
      var value = new api_dashboard_v2_company_product_message_pb.CompanyProduct;
      reader.readMessage(value,api_dashboard_v2_company_product_message_pb.CompanyProduct.deserializeBinaryFromReader);
      msg.setProducts(value);
      break;
    case 20:
      var value = new api_dashboard_v2_segment_message_pb.CompanyTag;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.CompanyTag.deserializeBinaryFromReader);
      msg.addCompanyTags(value);
      break;
    case 21:
      var value = new api_dashboard_v2_segment_message_pb.Segment;
      reader.readMessage(value,api_dashboard_v2_segment_message_pb.Segment.deserializeBinaryFromReader);
      msg.addCompanySegment(value);
      break;
    case 22:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.CompaniesCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.CompaniesCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.CompaniesCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompaniesCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAtInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWorkSessionTimeMinutes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWorkSessionIntervalMinutes();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getShowGroupedValues();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getReportPeopleNames();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getReseller();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.company.CompanyReseller.serializeBinaryToWriter
    );
  }
  f = message.getDefaultCoachingProgram();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.company.CompanyCoachingProgram.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCoachingProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.company.CompanyCoachingProgram.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      api_dashboard_v2_tier_message_pb.MessageTier.serializeBinaryToWriter
    );
  }
  f = message.getWorkGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryWorkGroup.serializeBinaryToWriter
    );
  }
  f = message.getJdTags();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryTags.serializeBinaryToWriter
    );
  }
  f = message.getProducts();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      api_dashboard_v2_company_product_message_pb.CompanyProduct.serializeBinaryToWriter
    );
  }
  f = message.getCompanyTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      api_dashboard_v2_segment_message_pb.CompanyTag.serializeBinaryToWriter
    );
  }
  f = message.getCompanySegmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      api_dashboard_v2_segment_message_pb.Segment.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.CompaniesCompany.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.company.CompaniesCompany.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created_at_in_milliseconds = 3;
 * @return {number}
 */
proto.company.CompaniesCompany.prototype.getCreatedAtInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setCreatedAtInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string region = 4;
 * @return {string}
 */
proto.company.CompaniesCompany.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 work_session_time_minutes = 5;
 * @return {number}
 */
proto.company.CompaniesCompany.prototype.getWorkSessionTimeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setWorkSessionTimeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 work_session_interval_minutes = 6;
 * @return {number}
 */
proto.company.CompaniesCompany.prototype.getWorkSessionIntervalMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setWorkSessionIntervalMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool show_grouped_values = 10;
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.getShowGroupedValues = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setShowGroupedValues = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool report_people_names = 11;
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.getReportPeopleNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.setReportPeopleNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional CompanyReseller reseller = 12;
 * @return {?proto.company.CompanyReseller}
 */
proto.company.CompaniesCompany.prototype.getReseller = function() {
  return /** @type{?proto.company.CompanyReseller} */ (
    jspb.Message.getWrapperField(this, proto.company.CompanyReseller, 12));
};


/**
 * @param {?proto.company.CompanyReseller|undefined} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setReseller = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearReseller = function() {
  return this.setReseller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.hasReseller = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CompanyCoachingProgram default_coaching_program = 13;
 * @return {?proto.company.CompanyCoachingProgram}
 */
proto.company.CompaniesCompany.prototype.getDefaultCoachingProgram = function() {
  return /** @type{?proto.company.CompanyCoachingProgram} */ (
    jspb.Message.getWrapperField(this, proto.company.CompanyCoachingProgram, 13));
};


/**
 * @param {?proto.company.CompanyCoachingProgram|undefined} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setDefaultCoachingProgram = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearDefaultCoachingProgram = function() {
  return this.setDefaultCoachingProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.hasDefaultCoachingProgram = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated CompanyCoachingProgram available_coaching_programs = 14;
 * @return {!Array<!proto.company.CompanyCoachingProgram>}
 */
proto.company.CompaniesCompany.prototype.getAvailableCoachingProgramsList = function() {
  return /** @type{!Array<!proto.company.CompanyCoachingProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.company.CompanyCoachingProgram, 14));
};


/**
 * @param {!Array<!proto.company.CompanyCoachingProgram>} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setAvailableCoachingProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.company.CompanyCoachingProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.company.CompanyCoachingProgram}
 */
proto.company.CompaniesCompany.prototype.addAvailableCoachingPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.company.CompanyCoachingProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearAvailableCoachingProgramsList = function() {
  return this.setAvailableCoachingProgramsList([]);
};


/**
 * repeated department_message.Department departments = 15;
 * @return {!Array<!proto.department_message.Department>}
 */
proto.company.CompaniesCompany.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.department_message.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_department_message_pb.Department, 15));
};


/**
 * @param {!Array<!proto.department_message.Department>} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.department_message.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.department_message.Department}
 */
proto.company.CompaniesCompany.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.department_message.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional tier_message.MessageTier tier = 16;
 * @return {?proto.tier_message.MessageTier}
 */
proto.company.CompaniesCompany.prototype.getTier = function() {
  return /** @type{?proto.tier_message.MessageTier} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_tier_message_pb.MessageTier, 16));
};


/**
 * @param {?proto.tier_message.MessageTier|undefined} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.hasTier = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated job_dictionary.JobDictionaryWorkGroup work_groups = 17;
 * @return {!Array<!proto.job_dictionary.JobDictionaryWorkGroup>}
 */
proto.company.CompaniesCompany.prototype.getWorkGroupsList = function() {
  return /** @type{!Array<!proto.job_dictionary.JobDictionaryWorkGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryWorkGroup, 17));
};


/**
 * @param {!Array<!proto.job_dictionary.JobDictionaryWorkGroup>} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setWorkGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryWorkGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.JobDictionaryWorkGroup}
 */
proto.company.CompaniesCompany.prototype.addWorkGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.job_dictionary.JobDictionaryWorkGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearWorkGroupsList = function() {
  return this.setWorkGroupsList([]);
};


/**
 * optional job_dictionary.JobDictionaryTags jd_tags = 18;
 * @return {?proto.job_dictionary.JobDictionaryTags}
 */
proto.company.CompaniesCompany.prototype.getJdTags = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryTags} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_dictionary_service_pb.JobDictionaryTags, 18));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryTags|undefined} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setJdTags = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearJdTags = function() {
  return this.setJdTags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.hasJdTags = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional company_product_message.CompanyProduct products = 19;
 * @return {?proto.company_product_message.CompanyProduct}
 */
proto.company.CompaniesCompany.prototype.getProducts = function() {
  return /** @type{?proto.company_product_message.CompanyProduct} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_company_product_message_pb.CompanyProduct, 19));
};


/**
 * @param {?proto.company_product_message.CompanyProduct|undefined} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setProducts = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearProducts = function() {
  return this.setProducts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.CompaniesCompany.prototype.hasProducts = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated segment_message.CompanyTag company_tags = 20;
 * @return {!Array<!proto.segment_message.CompanyTag>}
 */
proto.company.CompaniesCompany.prototype.getCompanyTagsList = function() {
  return /** @type{!Array<!proto.segment_message.CompanyTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.CompanyTag, 20));
};


/**
 * @param {!Array<!proto.segment_message.CompanyTag>} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setCompanyTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.segment_message.CompanyTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.CompanyTag}
 */
proto.company.CompaniesCompany.prototype.addCompanyTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.segment_message.CompanyTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearCompanyTagsList = function() {
  return this.setCompanyTagsList([]);
};


/**
 * repeated segment_message.Segment company_segment = 21;
 * @return {!Array<!proto.segment_message.Segment>}
 */
proto.company.CompaniesCompany.prototype.getCompanySegmentList = function() {
  return /** @type{!Array<!proto.segment_message.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_segment_message_pb.Segment, 21));
};


/**
 * @param {!Array<!proto.segment_message.Segment>} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setCompanySegmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.segment_message.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.segment_message.Segment}
 */
proto.company.CompaniesCompany.prototype.addCompanySegment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.segment_message.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearCompanySegmentList = function() {
  return this.setCompanySegmentList([]);
};


/**
 * repeated site_message.Site sites = 22;
 * @return {!Array<!proto.site_message.Site>}
 */
proto.company.CompaniesCompany.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.site_message.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_site_message_pb.Site, 22));
};


/**
 * @param {!Array<!proto.site_message.Site>} value
 * @return {!proto.company.CompaniesCompany} returns this
*/
proto.company.CompaniesCompany.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.site_message.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.site_message.Site}
 */
proto.company.CompaniesCompany.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.site_message.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesCompany} returns this
 */
proto.company.CompaniesCompany.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.HelpRequestByCompanies.prototype.toObject = function(opt_includeInstance) {
  return proto.company.HelpRequestByCompanies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.HelpRequestByCompanies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.HelpRequestByCompanies.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.HelpRequestByCompanies}
 */
proto.company.HelpRequestByCompanies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.HelpRequestByCompanies;
  return proto.company.HelpRequestByCompanies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.HelpRequestByCompanies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.HelpRequestByCompanies}
 */
proto.company.HelpRequestByCompanies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.HelpRequestByCompanies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.HelpRequestByCompanies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.HelpRequestByCompanies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.HelpRequestByCompanies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.company.HelpRequestByCompanies.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.HelpRequestByCompanies} returns this
 */
proto.company.HelpRequestByCompanies.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.company.HelpRequestByCompanies.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.HelpRequestByCompanies} returns this
 */
proto.company.HelpRequestByCompanies.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.company.Company.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.company.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard_v2_department_message_pb.Department.toObject, includeInstance),
    defaultProgram: (f = msg.getDefaultProgram()) && proto.company.Program.toObject(includeInstance, f),
    availableProgramsList: jspb.Message.toObjectList(msg.getAvailableProgramsList(),
    proto.company.Program.toObject, includeInstance),
    tier: (f = msg.getTier()) && api_dashboard_v2_tier_message_pb.MessageTier.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.Company}
 */
proto.company.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.Company;
  return proto.company.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.Company}
 */
proto.company.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 4:
      var value = new proto.company.Program;
      reader.readMessage(value,proto.company.Program.deserializeBinaryFromReader);
      msg.setDefaultProgram(value);
      break;
    case 5:
      var value = new proto.company.Program;
      reader.readMessage(value,proto.company.Program.deserializeBinaryFromReader);
      msg.addAvailablePrograms(value);
      break;
    case 6:
      var value = new api_dashboard_v2_tier_message_pb.MessageTier;
      reader.readMessage(value,api_dashboard_v2_tier_message_pb.MessageTier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getDefaultProgram();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.company.Program.serializeBinaryToWriter
    );
  }
  f = message.getAvailableProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.company.Program.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard_v2_tier_message_pb.MessageTier.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.Company.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.company.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated department_message.Department departments = 3;
 * @return {!Array<!proto.department_message.Department>}
 */
proto.company.Company.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.department_message.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_department_message_pb.Department, 3));
};


/**
 * @param {!Array<!proto.department_message.Department>} value
 * @return {!proto.company.Company} returns this
*/
proto.company.Company.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.department_message.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.department_message.Department}
 */
proto.company.Company.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.department_message.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional Program default_program = 4;
 * @return {?proto.company.Program}
 */
proto.company.Company.prototype.getDefaultProgram = function() {
  return /** @type{?proto.company.Program} */ (
    jspb.Message.getWrapperField(this, proto.company.Program, 4));
};


/**
 * @param {?proto.company.Program|undefined} value
 * @return {!proto.company.Company} returns this
*/
proto.company.Company.prototype.setDefaultProgram = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.clearDefaultProgram = function() {
  return this.setDefaultProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.Company.prototype.hasDefaultProgram = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Program available_programs = 5;
 * @return {!Array<!proto.company.Program>}
 */
proto.company.Company.prototype.getAvailableProgramsList = function() {
  return /** @type{!Array<!proto.company.Program>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.company.Program, 5));
};


/**
 * @param {!Array<!proto.company.Program>} value
 * @return {!proto.company.Company} returns this
*/
proto.company.Company.prototype.setAvailableProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.company.Program=} opt_value
 * @param {number=} opt_index
 * @return {!proto.company.Program}
 */
proto.company.Company.prototype.addAvailablePrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.company.Program, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.clearAvailableProgramsList = function() {
  return this.setAvailableProgramsList([]);
};


/**
 * optional tier_message.MessageTier tier = 6;
 * @return {?proto.tier_message.MessageTier}
 */
proto.company.Company.prototype.getTier = function() {
  return /** @type{?proto.tier_message.MessageTier} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_tier_message_pb.MessageTier, 6));
};


/**
 * @param {?proto.tier_message.MessageTier|undefined} value
 * @return {!proto.company.Company} returns this
*/
proto.company.Company.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.company.Company.prototype.hasTier = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.company.Company.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.Company} returns this
 */
proto.company.Company.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.company.CompaniesWithDepartments.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.CompaniesWithDepartments.prototype.toObject = function(opt_includeInstance) {
  return proto.company.CompaniesWithDepartments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.CompaniesWithDepartments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompaniesWithDepartments.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard_v2_department_message_pb.Department.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    api_dashboard_v2_site_message_pb.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.CompaniesWithDepartments}
 */
proto.company.CompaniesWithDepartments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.CompaniesWithDepartments;
  return proto.company.CompaniesWithDepartments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.CompaniesWithDepartments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.CompaniesWithDepartments}
 */
proto.company.CompaniesWithDepartments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 4:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.CompaniesWithDepartments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.CompaniesWithDepartments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.CompaniesWithDepartments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompaniesWithDepartments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.CompaniesWithDepartments.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompaniesWithDepartments} returns this
 */
proto.company.CompaniesWithDepartments.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.company.CompaniesWithDepartments.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.CompaniesWithDepartments} returns this
 */
proto.company.CompaniesWithDepartments.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated department_message.Department departments = 3;
 * @return {!Array<!proto.department_message.Department>}
 */
proto.company.CompaniesWithDepartments.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.department_message.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_department_message_pb.Department, 3));
};


/**
 * @param {!Array<!proto.department_message.Department>} value
 * @return {!proto.company.CompaniesWithDepartments} returns this
*/
proto.company.CompaniesWithDepartments.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.department_message.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.department_message.Department}
 */
proto.company.CompaniesWithDepartments.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.department_message.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesWithDepartments} returns this
 */
proto.company.CompaniesWithDepartments.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * repeated site_message.Site sites = 4;
 * @return {!Array<!proto.site_message.Site>}
 */
proto.company.CompaniesWithDepartments.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.site_message.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_site_message_pb.Site, 4));
};


/**
 * @param {!Array<!proto.site_message.Site>} value
 * @return {!proto.company.CompaniesWithDepartments} returns this
*/
proto.company.CompaniesWithDepartments.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.site_message.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.site_message.Site}
 */
proto.company.CompaniesWithDepartments.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.site_message.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesWithDepartments} returns this
 */
proto.company.CompaniesWithDepartments.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.company.CompaniesWithSitesAndDepartments.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.toObject = function(opt_includeInstance) {
  return proto.company.CompaniesWithSitesAndDepartments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.company.CompaniesWithSitesAndDepartments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompaniesWithSitesAndDepartments.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    api_dashboard_v2_department_message_pb.Department.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    api_dashboard_v2_site_message_pb.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.company.CompaniesWithSitesAndDepartments}
 */
proto.company.CompaniesWithSitesAndDepartments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.company.CompaniesWithSitesAndDepartments;
  return proto.company.CompaniesWithSitesAndDepartments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.company.CompaniesWithSitesAndDepartments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.company.CompaniesWithSitesAndDepartments}
 */
proto.company.CompaniesWithSitesAndDepartments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 4:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.company.CompaniesWithSitesAndDepartments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.company.CompaniesWithSitesAndDepartments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.company.CompaniesWithSitesAndDepartments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.company.CompaniesWithSitesAndDepartments} returns this
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.company.CompaniesWithSitesAndDepartments} returns this
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated department_message.Department departments = 3;
 * @return {!Array<!proto.department_message.Department>}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.department_message.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_department_message_pb.Department, 3));
};


/**
 * @param {!Array<!proto.department_message.Department>} value
 * @return {!proto.company.CompaniesWithSitesAndDepartments} returns this
*/
proto.company.CompaniesWithSitesAndDepartments.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.department_message.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.department_message.Department}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.department_message.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesWithSitesAndDepartments} returns this
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * repeated site_message.Site sites = 4;
 * @return {!Array<!proto.site_message.Site>}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.site_message.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_dashboard_v2_site_message_pb.Site, 4));
};


/**
 * @param {!Array<!proto.site_message.Site>} value
 * @return {!proto.company.CompaniesWithSitesAndDepartments} returns this
*/
proto.company.CompaniesWithSitesAndDepartments.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.site_message.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.site_message.Site}
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.site_message.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.company.CompaniesWithSitesAndDepartments} returns this
 */
proto.company.CompaniesWithSitesAndDepartments.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


goog.object.extend(exports, proto.company);
