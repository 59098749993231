/* eslint no-param-reassign: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-shadow: 0 */
/* eslint no-underscore-dangle:0 */
import * as d3 from 'd3';
import BarChart from './BarChart';

export default class GroupedBarChart extends BarChart {
  constructor(id) {
    super(id);

    // eslint-disable-next-line prefer-destructuring
    this._groupKey = 'Group';
    this._keys = [];
  }

  setGroupKey(key) {
    this._groupKey = key;
  }

  get groupKey() {
    return this._groupKey;
  }

  setKeys(keys) {
    this._keys = keys;
  }

  get keys() {
    return this._keys;
  }

  getX(barPadding) {
    return d3.scaleBand()
      .domain(this.data.map((d) => d[this.groupKey]))
      .rangeRound([this.margin.left, this.width - this.margin.right])
      .paddingInner(barPadding)
      .paddingOuter(0.2);
  }

  getX1(barPadding) {
    return d3.scaleBand()
      .domain(this.keys)
      .rangeRound([0, this.getX(barPadding).bandwidth()])
      .padding(0.05);
  }

  getY() {
    return d3.scaleLinear()
      .domain([0, d3.max(this.data, (d) => d3.max(this.keys, (key) => d[key]))]).nice()
      .rangeRound([this.height - this.margin.bottom, this.margin.top]);
  }

  displayValuesOnTopOfBar(svg, x, y) {
    svg.selectAll('g.bar')
      .data(this.data)
      .join('g')
      .call((g) => g.append('g')
        .attr('class', 'bar-text')
        .attr('text-anchor', 'middle')
        .attr('opacity', 0.5)
        .attr('font-size', '12px')
        .selectAll('text')
        .data((d) => this.keys.map((key) => ({ key, value: d[key] })))
        .join('text')
        .attr('x', (d) => x(d.key) + 16)
        .attr('y', (d) => y(d.value) - 5)
        .attr('width', 32)
        .attr('height', (d) => y(0) - y(d.value))
        .text((d) => d.value));
  }

  draw(colors, barPadding) {
    const x = this.getX(barPadding);
    const x1 = this.getX1(barPadding);
    const y = this.getY();
    const color = d3.scaleOrdinal()
      .range(colors);

    const xAxis = this.drawXAxis(x, this.data);
    const yAxis = this.drawYAxis(y);

    this.drawSvg([0, 0, this.width, this.height]);

    const svg = d3.select(`svg.${this.id}-chart`);

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis)
      .call((g) => g.select('.domain')
        .remove());

    svg.append('g')
      .selectAll('g')
      .data(this.data)
      .join('g')
      .attr('transform', (d) => `translate(${x(d[this.groupKey])},0)`)
      .attr('class', 'bar')
      .selectAll('rect')
      .data((d) => this.keys.map((key) => ({ key, value: d[key] })))
      .join('rect')
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => y(d.value))
      .attr('width', 32)
      .attr('height', (d) => y(0) - y(d.value))
      .attr('fill', (d) => color(d.key));

    this.displayValuesOnTopOfBar(svg, x1, y);
    this.setTickTextAttr('Mulish', '0.6rem');
  }

  drawEmpty(data) {
    super.drawEmpty(data);
  }
}
