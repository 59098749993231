var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetEventNotification, resetNotification, setEventNotification, showNotification, } from './notificationsSlice';
import { createNotification } from './createNotification';
export const createEventNotification = createAsyncThunk('notifications/createEventNotification', (message, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const notification = createNotification('', message);
    thunkAPI.dispatch(setEventNotification(notification));
    setTimeout(() => {
        thunkAPI.dispatch(resetEventNotification(notification.id));
    }, 10000);
}));
export const setNotification = createAsyncThunk('notifications/setNotification', ({ type, message }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const notification = createNotification(type, message);
    thunkAPI.dispatch(showNotification(notification));
    setTimeout(() => {
        thunkAPI.dispatch(resetNotification(notification.id));
    }, 3000);
}));
