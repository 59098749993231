import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import _orderBy from 'lodash/orderBy';
import {
  contactSalesProductType, productStatus, Risks, TaskVideoStatus,
} from 'init/proto_client';
import { useTranslation } from 'react-i18next';
import { modalOverflow } from '../../assets/jss/style';
import { outlineColoredButton } from '../../assets/soter-flex-styles';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { resetTaskVideos, setVideosUpdated, resetTaskVideosError } from './videosStore/videoSlice';
import {
  getVideos, getVideoStatusStream, removeTaskVideo, updateVideoStatus,
} from './videosStore/actions';
import { resetOpenModal, setOpenedModal } from '../../containers/ModalContainer/modalsStore/actions';
import { openedModalSelector } from '../../containers/ModalContainer/modalsStore/selectors';
import RiskGroupBadge from '../../lib/ui/RiskGroupBadge/RiskGroupBadge';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import VideoPreviewAndNameCell from '../../containers/ItemTableContainer/components/CustomCells/VideoPreviewAndNameCell';
import DepartmentCell from '../../containers/ItemTableContainer/components/CustomCells/DepartmentCell';
import CreatedAtCell from '../../containers/ItemTableContainer/components/CustomCells/CreatedAtCell';
import VideoStatusCell from '../../containers/ItemTableContainer/components/CustomCells/VideoStatusCell';
import DoubleLineCell from '../../containers/ItemTableContainer/components/CustomCells/DoubleLineCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import { COLORS } from '../../constants/colors';
import ActionToggleButton from '../../lib/ui/ActionToggleButton';
import { createExportActions } from '../../containers/ItemTableContainer/components/TableReport';
import { getCurrentPageReportList } from '../../containers/ItemTableContainer/constants/tablePageReportList';
import AddNewVideoDialog from '../video/AddNewVideoDialog/AddNewVideoDialog';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import { TASK_VIDEO_DELETED } from '../../constants/analyticsEvents';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import VideoLimitDialog from '../components/VideoLimitDialog';
import { contactSales } from '../../auth/store/actions';
import SubscriptionExpiredDialog from '../components/SubscriptionExpiredDialog';
import TrialFinishedDialog from '../components/TrialFinishedDialog';
import ContactUsDialog from '../../lib/ui/ContactUsDialog';
import ShowAppQrcodeDialog from '../components/ShowAppQrcodeDialog';
import UpgradeDialog from '../components/UpgradeDialog';
import useNumberOfTrialDaysLeftBanner from '../overview/useNumberOfTrialDaysLeftBanner';
import Loader from '../../lib/ui/Loader';
import SoterTaskOnboarding from '../components/onBoarding/SoterTaskOnboarding';
import ModalWrapper from '../../containers/ModalContainer/ModalWrapper';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';

import calculateRebaRiskLevel from '../utils/calculateRebaRiskLevel';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';

const getNewVideoStatus = (currentStatus) => (currentStatus === TaskVideoStatus.APPROVED ? TaskVideoStatus.WAITING_FOR_APPROVAL : TaskVideoStatus.APPROVED);

const SoterTaskContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [trialSent, setTrialSent] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [editedVideo, setEditedVideo] = useState(null);
  const videos = useAppSelector((state) => state.videos.videosList);
  const videosAreLoading = useAppSelector((state) => state.videos.isLoading);
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const status = selectedCompany.products?.geniusProduct?.status;
  const { uploadedVideo, updated } = useAppSelector((state) => state.videos);
  const { closeButtonAlternativeBehavior } = useAppSelector((state) => state.modals);
  const { videoStatus } = useAppSelector((state) => state.filtersNew.filters);
  const openedModal = useAppSelector(openedModalSelector);

  useEffect(
    () => () => {
      dispatch(resetTaskVideos());
      dispatch(resetOpenModal());
    },
    [],
  );
  useEffect(() => {
    if (selectedCompany.id) {
      dispatch(getVideos(selectedCompany.id));
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    if (selectedCompany.id && videos.length > 0) {
      dispatch(getVideoStatusStream(selectedCompany.id));
    }
  }, [videos.length]);

  useEffect(() => {
    if (updated) {
      if (!openedModal) dispatch(getVideos(selectedCompany.id));
      dispatch(setVideosUpdated(false));
    }
  }, [updated]);

  useEffect(() => {
    dispatch(setOpenedModal(null));
  }, [status]);

  const videoOnClickGoTo = (id) => `../gallery/${id}`;

  const getRiskForBadge = (risk) => {
    switch (risk) {
      case Risks.LOW:
        return 'lower';
      case Risks.MEDIUM:
        return 'medium';
      case Risks.HIGH:
        return 'high';
      default:
        return 'lower';
    }
  };

  const getScoreStyle = (score, t) => Emotion.css`
  .score {
    font-size: 14px;
    font-weight: 700;
    color: ${calculateRebaRiskLevel(score, t)[1]};
  }

  .description, .no-score {
    color: ${COLORS.GRAY[400]};
    font-size: 12px;
    font-weight: 400;
  }
`;

  const TextCell
    = (type) => ({ rowData }) => <div>{rowData[type]?.title || rowData[type]?.name || '–'}</div>;

  const RebaCell = ({ rowData }) => (
    <div className={getScoreStyle(rowData?.maxRebaScore, t)}>
      {rowData?.maxRebaScore
        ? (
          <>
            <p className="score">
              {rowData?.maxRebaScore}
              {' '}
              Score
            </p>
            <p className="description">{calculateRebaRiskLevel(rowData?.maxRebaScore, t)[0]}</p>
          </>
        )
        : <p className="no-score">{t('SOTER_TASK.ASSESSMENT.REBA.NOT_CALCULATED')}</p>}
    </div>
  );

  const RiskCell = ({ rowData }) => (
    <div>
      {rowData.risks?.overallRisk ? (
        <RiskGroupBadge noRiskGroup riskGroup={getRiskForBadge(rowData.risks?.overallRisk)} />
      ) : (
        ''
      )}
    </div>
  );

  const columns = (getActionsForUser) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.soterTaskVideos),
    videoOnClickGoTo,
    getActionsForUser,
    TableType.soterTaskVideos,
    t,
  );

  const cells = {
    previewAndName: (props) => <VideoPreviewAndNameCell {...props} />,
    departmentAndSite: (props) => (
      <DepartmentCell {...props} sites={selectedCompany?.sitesList} departments={selectedCompany?.departmentsList} />
    ),
    dateOfUpload: CreatedAtCell,
    status: VideoStatusCell,
    videoType: DoubleLineCell,
    videoCreatedBy: TextCell('createdBy'),
    videoTaskName: TextCell('taskName'),
    videoJobRole: TextCell('jobRole'),
    videoRisk: RiskCell,
    reba: RebaCell,
    actions: ActionCell,
  };
  const actions = [
    {
      type: 'CHANGE_STATUS',
      title: t('SOTER_TASK.VIDEOS_LIST.CHANGE_STATUS'),
      handler: ({ id, status: currentStatus }) => {
        const currentVideo = videos.find((video) => video.id === id);
        dispatch(updateVideoStatus({ currentVideo, getStatus: getNewVideoStatus(currentStatus) }));
      },
    },
    {
      title: t('ADMIN.DELETE'),
      handler: ({ id }) => {
        setEditedVideo(id);
        dispatch(setOpenedModal('deleteVideo'));
      },
      attention: true,
    },
  ];

  const userActions = [
    {
      id: 'setCompany',
      name: t('SOTER_TASK.VIDEOS_LIST.CHANGE_USER'),
      color: COLORS.GRAY[400],
      size: 'small',
      child: <div />,
      disabled: true,
    },
  ];

  const togglerButton = (
    <ActionToggleButton
      name="multi"
      dataName="multi-user-dropdown"
      className={outlineColoredButton(COLORS.LIGHT_BLUE[600], 'small')}
    />
  );

  const openAddNewVideoModal = () => {
    dispatch(setOpenedModal('addNewVideo'));
  };

  const toglerButtonExport = <ActionToggleButton name="exportReport" dataName="exportReport" />;
  const exportActions = createExportActions(getCurrentPageReportList(TableType.soterTaskVideos));

  const filterButtonProps = {
    text: t('SOTER_TASK.VIDEOS_LIST.ADD_NEW_VIDEO'),
    action:
      status === productStatus.PRODUCTSTATUSLIMITEXCEEDED
      || status === productStatus.PRODUCTSTATUSTRIALFINISHED
      || status === productStatus.PRODUCTSTATUSSUBSCRIPTIONDUE
        ? () => dispatch(
          setOpenedModal(
            status === productStatus.PRODUCTSTATUSSUBSCRIPTIONDUE
              ? 'subscriptionExpired'
              : 'videoUploadingLimitExceeded',
          ),
        )
        : openAddNewVideoModal,
  };

  const secondaryButton = [
    {
      text: t('WEARABLES.ACTIVITY.ACTIONS.EXPORT'),
      handler: () => {},
      dropdown: {
        type: 'exportReport',
        selectedItems: videos,
        selectedCompany,
        exportActions,
        content: t('WEARABLES.ACTIVITY.ACTIONS.EXPORT'),
        togglerButton: toglerButtonExport,
      },
    },
  ];

  const switchModal = (modalType) => {
    switch (modalType) {
      case 'addNewVideo':
        return <AddNewVideoDialog />;
      case 'deleteVideo':
        return (
          <DeleteEntityDialog
            confirmText={t('SOTER_TASK.VIDEOS_LIST.SURE_DELETE')}
            buttonText={t('SOTER_TASK.VIDEOS_LIST.DELETE_VIDEO')}
            onSubmit={() => {
              window.logEvent(TASK_VIDEO_DELETED);
              if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(TASK_VIDEO_DELETED));
              }
              dispatch(removeTaskVideo(editedVideo));
            }}
          />
        );
      case 'videoUploadingLimitExceeded':
        return (
          <VideoLimitDialog
            contactHandler={() => {
              contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK });
              dispatch(setOpenedModal('contactUs'));
            }}
          />
        );
      case 'subscriptionExpired':
        return (
          <SubscriptionExpiredDialog
            contactHandler={() => {
              contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK });
              dispatch(setOpenedModal('contactUs'));
            }}
          />
        );
      case 'trialFinished':
        return (
          <TrialFinishedDialog
            onClickHandler={() => {
              dispatch(
                contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK }),
              );
              setTrialSent(true);
              dispatch(setOpenedModal('contactUs'));
            }}
            trialSent={trialSent}
          />
        );
      case 'contactUs':
        return <ContactUsDialog handleClose={() => dispatch(setOpenedModal(null))} />;
      case 'showAppQrcode':
        return <ShowAppQrcodeDialog qrCode={qrCode} />;
      case 'upgrade':
        return (
          <UpgradeDialog
            handleClick={() => {
              dispatch(
                contactSales({ companyId: selectedCompany.id, productType: contactSalesProductType.PRODUCTTYPETASK }),
              );
              dispatch(setOpenedModal('contactSales'));
            }}
            days={selectedCompany.products?.geniusProduct?.currentTermEnd}
          />
        );
      case 'contactSales':
        return <ContactUsDialog handleClose={() => dispatch(setOpenedModal(null))} />;
      default:
        return null;
    }
  };

  const handleCloseModal = () => {
    if (closeButtonAlternativeBehavior) {
      if (window.confirm(t('SOTER_TASK.VIDEOS_LIST.ACTION_STOP_UPLOADING'))) {
        dispatch(setOpenedModal(null));
        dispatch(removeTaskVideo(uploadedVideo?.id));
        dispatch(resetTaskVideosError());
      }
    } else {
      dispatch(setOpenedModal(null));
    }
  };

  const openQRCodeModal = () => {
    dispatch(setOpenedModal('showAppQrcode'));
  };

  const numberOfTrialDaysLeftBanner = useNumberOfTrialDaysLeftBanner('geniusProduct');
  if (videosAreLoading) return <Loader />;
  return (
    <>
      {videos.length === 0 ? (
        <>
          <SoterTaskOnboarding
            addNewVideo={openAddNewVideoModal}
            openQRCode={openQRCodeModal}
            isStatusAvailable={false}
            setQrCode={setQrCode}
            isUploadingAvailable
          />
          {openedModal && (
            <ModalWrapper
              modalClass={modalOverflow}
              closeModal={handleCloseModal}
              withCloseButton
              isCloseModal
              closeWithoutConfirmation={
                openedModal === 'videoUploadingLimitExceeded'
                || openedModal === 'contactSales'
                || openedModal === 'showAppQrcode'
                || openedModal === 'contactUs'
                || openedModal === 'upgrade'
                || openedModal === 'createUser'
                || openedModal === 'fillingError'
                || openedModal === 'userActionDropdownModal'
                || openedModal === 'qrCode'
                || openedModal === 'subscriptionExpired'
              }
            >
              {switchModal(openedModal)}
            </ModalWrapper>
          )}
        </>
      ) : (
        <ItemTableContainer
          items={_orderBy(
            !videoStatus || videoStatus.length === 0
              ? videos.filter((v) => v.status !== TaskVideoStatus.ARCHIVED)
              : videos,
            ['createdAt'],
            'desc',
          )}
          selectedItems={[]}
          columns={columns}
          cells={cells}
          type={TableType.soterTaskVideos}
          isLoading={false}
          actions={actions}
          editedItem={editedVideo}
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
          itemActions={userActions}
          togglerButton={togglerButton}
          filterButtonProps={filterButtonProps}
          switchModal={switchModal}
          modalClass={modalOverflow}
          keepModalOnClickOutside
          stickyOnScrollContainerTopBlock={numberOfTrialDaysLeftBanner}
          stickyOnScrollContainerHeight={numberOfTrialDaysLeftBanner && 106}
          onCloseModal={handleCloseModal}
          withCloseButton
          secondaryButton={secondaryButton}
        />
      )}
    </>
  );
};

export default PrivatePageHOC(SoterTaskContainer);
