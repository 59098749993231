import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import LoginFormCard from './LoginContainer/components/LoginFormCard';
import LoginTextInput from './LoginContainer/components/LoginTextInput';
import { ValidateAsyncOnChange } from './ValidateAsyncOnChange';
import LoginPageContainer from './LoginContainer/components/LoginPageContainer';
import { useAppDispatch } from '../lib/utils/useStore';
import { requestSSOLink } from './store/actions';
import emailValidator from "./emailValidator";
const SingleSignOn = () => {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const validate = emailValidator(t);
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values) => {
            const { email } = values;
            dispatch(requestSSOLink(email));
        },
    });
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const debouncedValidation = () => sleep(500).then(() => validate(formik.values));
    const isButtonDisabled = !!validate(formik.values).email;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoginPageContainer, { isSignUp: false },
            React.createElement(LoginFormCard, { buttonText: t('LOGIN.LOGIN', 'Login'), title: t('LOGIN.HEADER_SSO', 'Login using Single Sign-On'), onSubmit: formik.handleSubmit, buttonDisabled: isButtonDisabled },
                React.createElement(LoginTextInput, { type: "email", name: "email", label: t('LOGIN.EMAIL', 'Email Address'), placeholder: t('LOGIN.EMAIL_PLACEHOLDER', 'Enter email'), onChange: formik.handleChange, value: formik.values.email, error: (_a = formik.errors) === null || _a === void 0 ? void 0 : _a.email, touched: formik.touched.email, errorMessage: (_b = formik.errors) === null || _b === void 0 ? void 0 : _b.email, checkTouched: false }))),
        React.createElement(ValidateAsyncOnChange, { validate: debouncedValidation, formik: formik })));
};
export default SingleSignOn;
