import * as types from './actionTypes';
import { TableType } from '../constants/tableType';

export const setItemSelection = (items, type) => {
  switch (type) {
    case TableType.coach:
      return ({
        type: types.SET_SELECTED_COACH,
        users: items,
      });
    case TableType.clipgo:
      return ({
        type: types.SET_SELECTED_CLIPGO,
        users: items,
      });
    case TableType.devices:
      return ({
        type: types.SET_SELECTED_DEVICES,
        devices: items,
      });
    case TableType.companies:
      return ({
        type: types.SET_SELECTED_COMPANIES,
        companies: items,
      });
    case TableType.dashboardUsers:
      return ({
        type: types.SET_SELECTED_DASHBOARD_USERS,
        dashboardUsers: items,
      });
    case TableType.entries:
      return ({
        type: types.SET_SELECTED_ENTRIES,
        entries: items,
      });
    default: return null;
  }
};

export const removeItemsFromSelection = (items, userType) => {
  switch (userType) {
    case TableType.coach:
      return ({
        type: types.REMOVE_SELECTED_COACH,
        users: items,
      });
    case TableType.clipgo:
      return ({
        type: types.REMOVE_SELECTED_CLIPGO,
        users: items,
      });
    case TableType.devices:
      return ({
        type: types.REMOVE_SELECTED_DEVICES,
        devices: items,
      });
    case TableType.companies:
      return ({
        type: types.REMOVE_SELECTED_COMPANIES,
        companies: items,
      });
    case TableType.dashboardUsers:
      return ({
        type: types.REMOVE_SELECTED_DASHBOARD_USERS,
        dashboardUsers: items,
      });
    case TableType.entries:
      return ({
        type: types.REMOVE_SELECTED_ENTRIES,
        entries: items,
      });

    default: return null;
  }
};

export const resetSelectedUsers = () => ({
  type: types.RESET_ALL_SELECTED,
});
