export const getAssessmentType = (typeNum) => {
  switch (typeNum) {
    case 1:
      return 'CUSTOM';
    case 2 || 3:
      return 'RULA';
    case 4:
      return 'REBA';
    case 5:
      return 'NIOSH';
    default:
      return 'NONE';
  }
};
