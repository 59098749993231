export const deviceTypes = {
  bend: 'bend',
  armband: 'armband',
};

export const bendConfig = ['lifting', 'twisting', 'pbStatic', 'intensity', 'repetition'];
export const armbandConfig = ['armElevation', 'pushingPulling', 'staticArmElevation', 'overexertion', 'repetitiveArmMovements'];
// eslint-disable-next-line import/prefer-default-export,consistent-return
export const mapConfigToProgramType = (config, type) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case type !== deviceTypes.armband:
      return config.filter((hazard) => bendConfig.includes(hazard));
    case deviceTypes.armband:
      return config.filter((hazard) => armbandConfig.includes(hazard));
    default: return config.filter((hazard) => bendConfig.includes(hazard));
  }
};
