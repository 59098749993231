import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { closeModalButton, modalContent, modalOverlay, transparentButton } from '../../assets/soter-flex-styles';
import { modalOverflow } from '../../assets/jss/style';
import useOutsideClickHandler from '../../lib/utils/useOutsideClickHandler';
const ModalWrapper = ({ closeModal, keepModalOnClickOutside, children, withCloseButton = true, withCancelButton = false, modalClass, overlayClass, disableClosing, isCloseModal = true, }) => {
    const modalRef = useRef(null);
    const contentRef = useRef(null);
    if (!modalRef.current) {
        modalRef.current = document.createElement('div');
    }
    const closeHandlerWithConfirm = () => {
        if (disableClosing)
            return;
        closeModal();
    };
    // add undefined check to prevent hook call on unmount
    if (isCloseModal && !keepModalOnClickOutside && keepModalOnClickOutside !== undefined) {
        useOutsideClickHandler(contentRef, closeHandlerWithConfirm);
    }
    useEffect(() => {
        const modalRoot = document.getElementById('modal');
        if (modalRoot)
            modalRoot.appendChild(modalRef.current);
        return () => {
            if (modalRoot)
                modalRoot.removeChild(modalRef.current);
        };
    }, []);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const modal = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${modalContent} ${modalClass || ''} ${modalOverflow}`, ref: contentRef },
            withCloseButton && (React.createElement("div", { className: closeModalButton },
                React.createElement("button", { type: "button", onClick: () => {
                        closeHandlerWithConfirm();
                    }, "aria-label": "close-modal-button" }))),
            React.createElement("div", { style: { padding: '1.5rem' } },
                children,
                withCancelButton && (React.createElement("button", { className: transparentButton('small'), type: "button", onClick: () => {
                        closeModal();
                    } }, "Cancel")))),
        React.createElement("div", { className: `${modalOverlay} ${overlayClass || ''}` })));
    return createPortal(modal, modalRef.current);
};
export default ModalWrapper;
