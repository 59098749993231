import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'rsuite';
import { calendarLocale } from './utils';

const AdminDateRangePicker = ({ changeData, setDateStart, ...rest }) => {
  const [firstDatePick, setFirstDatePick] = useState(false);
  const containerRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <DateRangePicker
        locale={calendarLocale(t)}
        format="MMM dd, yyyy"
        editable={false}
        onChange={(dates) => changeData(dates)}
        onSelect={(date) => {
          if (setDateStart && !firstDatePick) {
            setFirstDatePick(false);
            setDateStart(date);
          }
        }}
        {...rest}
      />
    </div>
  );
};

export default AdminDateRangePicker;
