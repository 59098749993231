import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import * as Emotion from 'emotion';
import { createExportActions } from 'containers/ItemTableContainer/components/TableReport';
import { COLORS } from 'constants/colors';
import { useSelector } from 'react-redux';
import { setOpenedModal as closeStoredModals } from 'containers/ModalContainer/modalsStore/actions';
import QRPrintDialog from './components/QRPrintDialog';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import {
  getMultiSelectionChecked,
  selectionHandler,
} from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import { getCurrentPageReportList } from '../../containers/ItemTableContainer/constants/tablePageReportList';
import capitalizeFirstLetter from '../../lib/utils/capitalizeFirstLetter';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import { deleteUsers, getUsers, resetDeleted } from './usersStore/actions';
import StaticNotification from '../../containers/NotificationContainer/components/StaticNotification';
import { notificationTypes } from '../../containers/NotificationContainer/notifications';
import CreateCoachUserDialog from './components/CreateCoachUserDialog';
import {
  ACTIVITY_USER_CREATED,
  ACTIVITY_USER_QR_GENERATED,
} from '../../constants/analyticsEvents';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import { getActionHandlerForModal } from '../../lib/utils/getActionHandlerForModal';
import { resetWearableUser } from './createUserStore/actions';
import LinkToEntry from '../../lib/ui/LinkToEntry/LinkToEntry';
import { removeEntryFromUser } from '../../company/categories/store/actions';
import DeletedEntriesList from './components/DeletedEntriesList';
import ActionToggleButton from '../../lib/ui/ActionToggleButton';
import SelectionCell from '../../containers/ItemTableContainer/components/CustomCells/SelectionCell';
import NameCell from '../../containers/ItemTableContainer/components/CustomCells/NameCell';
import DepartmentCell from '../../containers/ItemTableContainer/components/CustomCells/DepartmentCell';
import ProgramAndProgressCell from '../../containers/ItemTableContainer/components/CustomCells/ProgramAndProgressCell';
import ImprovementCell from '../../containers/ItemTableContainer/components/CustomCells/ImprovementCell';
import LastSyncCell from '../../containers/ItemTableContainer/components/CustomCells/LastSyncCell';
import AchievementsCell from '../../containers/ItemTableContainer/components/CustomCells/AchievementsCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import QRsPrintDialog from './components/QRsPrintDialog';
import { getIDsFromArray } from '../../lib/utils/getIDsFromArray';
import { EntryTooltip } from './Entries/EntryTooltip';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import EditUserDialog from './components/EditUserDialog';

const ActivityContainer = () => {
  const dispatch = useAppDispatch();
  const {
    dateRangePickerValue,
    deletedUsers,
    failToDeleteUsers,
    users,
    isLoading,
  } = useSelector((state) => state.coachUsers);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem('language');
  const [isClicked, setIsClicked] = useState(1);
  const [userForTagShown, setUserForTagShown] = useState(false);
  const [openedModal, setOpenedModal] = useState(null);
  const [editedUser, setEditedUser] = useState(null);
  const [coords, setCoords] = useState(null);
  const { reportPeopleNames } = useAppSelector(
    (state) => state.companies.selectedCompany,
  );
  const [isScrolled, setIsScrolled] = useState(false);
  const { categories } = useAppSelector((state) => state.category);
  const { newUser } = useAppSelector((state) => state.clipgoUsers);
  const { coachUsers: selectedCoachUsers } = useAppSelector(
    (state) => state.selectedItems,
  );
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const companyId = selectedCompany?.id;
  const userOnClickGoTo = (id) => `../user/${id}`;
  const modalClass = Emotion.css`
    min-width: 680px;
  `;

  useEffect(() => {
    if (document.getElementById('EntryTooltip') === null) {
      setIsScrolled(false);
    }
  }, [userForTagShown]);

  document.getElementById('scrollContainer') &&
    document
      .getElementById('scrollContainer')
      .addEventListener('scroll', () => {
        setIsScrolled(true);
      });

  const columns = (getActionsForUser) => getColumns(
    getMultiSelectionChecked,
    selectionHandler(dispatch, TableType.coach),
    userOnClickGoTo,
    getActionsForUser,
    TableType.coach,
    t,
  );
  const exportActions = createExportActions(
    getCurrentPageReportList(TableType.coach),
  );
  const closeModals = () => {
    setOpenedModal(null);
  };
  const usrText
    = selectedCoachUsers.length > 1 ? t('GENERAL.USERS') : t('GENERAL.USER');
  const deleteUsersMessage = editedUser ? (
    <h5>
      {`${t('GENERAL.DELETING')} ${editedUser.firstName} ${
        editedUser.lastName
      } ${t('WEARABLES.ACTIVITY.WILL_REMOVE')}`}
    </h5>
  ) : (
    <div>
      <h5>
        {`${t('WEARABLES.ACTIVITY.DELETING_SELECTED')} ${
          selectedCoachUsers.length
        } 
        ${usrText} ${t('WEARABLES.ACTIVITY.WILL_REMOVE')}`}
      </h5>
      <h6>{`${capitalizeFirstLetter(usrText)}:`}</h6>
      {selectedCoachUsers.map(({ firstName, lastName }) => (
        <p key={firstName + lastName}>{`${firstName} ${lastName}`}</p>
      ))}
    </div>
  );
  const deleteText = `${t('GENERAL.DELETE')} ${
    !editedUser && selectedCoachUsers.length > 1
      ? t('GENERAL.USERS')
      : t('GENERAL.USER')
  }`;
  const deleteUserDialog = (
    <DeleteEntityDialog
      confirmText={deleteText}
      buttonText={deleteText}
      onSubmit={() => {
        dispatch(
          deleteUsers(
            editedUser
              ? [editedUser.id]
              : selectedCoachUsers.map(({ id }) => id),
          ),
        );
        setEditedUser(null);
        onCloseModal();
        setOpenedModal('deletedUsersList');
      }}
    >
      <StaticNotification
        notification={{
          status: notificationTypes.TYPE_WARNING,
          message: deleteUsersMessage,
        }}
      />
    </DeleteEntityDialog>
  );

  const switchModal = (modalType) => {
    switch (modalType) {
      case 'createUser':
        return (
          <CreateCoachUserDialog
            user={newUser}
            reportPeopleNames={reportPeopleNames}
            handlerFn={(e, userData) => {
              userData.firstName.trim();
              userData.lastName.trim();
              window.logEvent(ACTIVITY_USER_CREATED);
              if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(ACTIVITY_USER_CREATED));
              }
              getActionHandlerForModal('createUser', TableType.coach)(
                closeModals,
                dispatch,
              )(e, userData, companyId);
              if (!reportPeopleNames) {
                closeModals();
                dispatch(resetWearableUser());
              }
            }}
            closeModals={() => {
              closeModals();
            }}
          />
        );
      case 'qrCode':
        return <QRPrintDialog user={editedUser} />;
      case 'linkToEntry':
        return (
          <LinkToEntry
            title={t('WEARABLES.ACTIVITY.DIALOGS.LINK_ENTRY')}
            categories={categories}
            companyId={companyId}
            setOpenedModal={setOpenedModal}
            userIds={[editedUser.id]}
            setUserForTagShown={setUserForTagShown}
          />
        );
      case 'removeEntry':
        return (
          <DeleteEntityDialog
            confirmText={`${t(
              'WEARABLES.ACTIVITY.DIALOGS.REMOVE_ENTRY_CONFIRM',
            )} "${userForTagShown?.entryData?.title}" 
              ${t('GENERAL.FROM')} ${userForTagShown?.firstName} ${
              userForTagShown?.lastName
            }`}
            buttonText={t('WEARABLES.ACTIVITY.DIALOGS.REMOVE_ENTRY')}
            onSubmit={() => dispatch(
              removeEntryFromUser({
                entryId: userForTagShown.entryData.id,
                userId: userForTagShown.id,
                closeModal: setOpenedModal,
              }),
            )}
            additionalText={false}
          >
            {false}
          </DeleteEntityDialog>
        );
      case 'editUser':
        return (
          <EditUserDialog
            users={[editedUser]}
            setUser={setEditedUser}
            title={t('MY_COMPANY.USERS.EDIT')}
            setOpenedModal={setOpenedModal}
          />
        );
      case 'deleteUser':
        return deleteUserDialog;
      case 'deletedUsersList':
        return (
          <DeletedEntriesList
            closeModal={() => {
              closeModals();
              dispatch(closeStoredModals());
              dispatch(resetDeleted());
            }}
            deleted={deletedUsers}
            failed={failToDeleteUsers}
          />
        );
      default:
        return null;
    }
  };
  const buttonStyles = {
    fontSize: lang === 'es' ? '10px' : lang === 'fr' ? '8px' : '0.875rem',
    height: '38px'
  }
  const togglerButton = (
    <ActionToggleButton
      name="coach"
      dataName="coach"
      style={buttonStyles}
    />
  );

  const togglerButtonExport = (
    <ActionToggleButton
      name="exportReport"
      dataName="exportReport"
      style={buttonStyles}
    />
  );
  const cells = {
    selection: SelectionCell,
    name: (rowData) => (
      <NameCell
        {...rowData}
        setUserForTagShown={setUserForTagShown}
        coords={coords}
        setCoords={setCoords}
        setOpenedModal={setOpenedModal}
        isScrolled={isScrolled}
        setIsScrolled={setIsScrolled}
      />
    ),
    department: DepartmentCell,
    progress: ProgramAndProgressCell,
    average: ImprovementCell,
    synced: (props) => <LastSyncCell {...props} />,
    started: (props) => <LastSyncCell {...props} started />,
    achievement: AchievementsCell,
    actions: ActionCell,
  };

  document.getElementById('scrollContainer') &&
    document
      .getElementById('scrollContainer')
      .addEventListener('scroll', () => {
        setIsScrolled(true);
      });

  const actions = [
    {
      title: t('WEARABLES.ACTIVITY.ACTIONS.QR'),
      handler: (user) => {
        window.logEvent(ACTIVITY_USER_QR_GENERATED);
        if (localStorage.getItem('showEvents')) {
          dispatch(createEventNotification(ACTIVITY_USER_QR_GENERATED));
        }
        setEditedUser(user);
        setOpenedModal('qrCode');
      },
      disabled: !reportPeopleNames,
    },
    {
      title: t('WEARABLES.ACTIVITY.ACTIONS.LINK'),
      handler: (user) => {
        setEditedUser(user);
        setOpenedModal('linkToEntry');
      },
    },
    {
      title: t('GENERAL.DELETE'),
      handler: (user) => {
        setOpenedModal('deleteUser');
        setEditedUser(user);
      },
      attention: true,
    },
  ];
  reportPeopleNames && actions.splice(actions.length - 1, 0, {
    title: t('DASHBOARD_USER.EDIT'),
    handler: (user) => {
      setEditedUser(user);
      setOpenedModal('editUser');
    },
  });

  const userActions = [
    {
      id: 'generateQRCodes',
      name:
        selectedCoachUsers?.length > 1
          ? t('WEARABLES.ACTIVITY.ACTIONS.QRS')
          : t('WEARABLES.ACTIVITY.ACTIONS.QR'),
      color: COLORS.GRAY[950],
      size: 'small',
      child: (
        <QRsPrintDialog users={selectedCoachUsers} closeModals={closeModals} />
      ),
      disabled: selectedCoachUsers?.length === 0 || !reportPeopleNames,
    },
    {
      id: 'linkToEntry',
      name: t('WEARABLES.ACTIVITY.ACTIONS.LINK'),
      color: COLORS.GRAY[950],
      size: 'small',
      child: (
        <LinkToEntry
          title={t('WEARABLES.ACTIVITY.ACTIONS.LINK')}
          categories={categories}
          companyId={companyId}
          setOpenedModal={setOpenedModal}
          userIds={selectedCoachUsers.map((user) => user.id)}
          setUserForTagShown={setUserForTagShown}
        />
      ),
      disabled: selectedCoachUsers?.length === 0,
    },
    {
      id: 'deleteUser',
      name: t('GENERAL.DELETE'),
      handler: () => alert('boom'),
      color: COLORS.RED[600],
      size: 'small',
      child: deleteUserDialog,
    },
  ];

  const buttonProps = [
    {
      bubble: selectedCoachUsers.length,
      dropdown: {
        type: 'coach',
        selectedItems: selectedCoachUsers,
        selectedCompany,
        userActions,
        handler: () => setIsClicked(isClicked + 1),
        content: t('WEARABLES.ACTIVITY.ACTIONS.USER'),
        togglerButton,
      },
      isClicked,
      disabled: selectedCoachUsers.length < 1,
    },
    {
      text: t('WEARABLES.ACTIVITY.ACTIONS.EXPORT'),
      handler: () => {},
      dropdown: {
        type: 'exportReport',
        selectedItems: selectedCoachUsers,
        selectedCompany,
        exportActions,
        handler: () => setIsClicked(isClicked + 1),
        content: t('WEARABLES.ACTIVITY.ACTIONS.EXPORT'),
        togglerButton: togglerButtonExport,
      },
    },
    {
      text: t('WEARABLES.ACTIVITY.ACTIONS.UPLOAD_LIST'),
      handler: () => {
        navigate('activity/upload-user-file');
      },
    },
  ];

  const filtersButton = {
    text: t('WEARABLES.ACTIVITY.DIALOGS.CREATE_USER'),
    action: () => setOpenedModal('createUser'),
  };
  useEffect(() => {
    if (selectedCompany.id && dateRangePickerValue.length > 0) {
      dispatch(
        getUsers(
          selectedCompany.id,
          {
            startedAt: dateRangePickerValue[0],
            finishedAt: dateRangePickerValue[1],
          },
          selectedCompany.sitesList.filter((id) => id > 0).length > 0
            ? selectedCompany.sitesList.filter((id) => id > 0)
            : getIDsFromArray(selectedCompany.sitesList),
          selectedCompany.departmentsList.filter((id) => id > 0).length > 0
            ? selectedCompany.departmentsList.filter((id) => id > 0)
            : getIDsFromArray(selectedCompany.departmentsList),
        ),
      );
    }
  }, [dateRangePickerValue]);
  const onCloseModal = () => {
    setOpenedModal(null);
    setEditedUser(null);
    closeStoredModals(null);
  };
  return (
    <>
      {userForTagShown && coords && !isScrolled && (
        <div
          style={{
            top: coords.y + 30,
            left: coords.x - 20,
            zIndex: 5,
            position: 'absolute',
          }}
        >
          <>
            <EntryTooltip
              tags={uniqBy(
                [...userForTagShown?.tagsList].filter(
                  (tag) => typeof (tag?.title || tag?.name) === 'string',
                ),
                'id',
              )}
              coords={coords}
              setCoords={setCoords}
              setUserForTagShown={setUserForTagShown}
              setOpenedModal={setOpenedModal}
              rowData={userForTagShown}
            />
          </>
        </div>
      )}
      <ItemTableContainer
        items={users}
        selectedItems={selectedCoachUsers}
        columns={columns}
        cells={cells}
        type={TableType.coach}
        isLoading={isLoading}
        actions={actions}
        editedItem={editedUser}
        modalClass={modalClass}
        openedModal={openedModal}
        setOpenedModal={setOpenedModal}
        togglerButton={togglerButton}
        switchModal={switchModal}
        filterButtonProps={filtersButton}
        secondaryButton={buttonProps}
        keepModalOnClickOutside={openedModal === 'editUser'}
        onCloseModal={onCloseModal}
      />
    </>
  );
};

export default ActivityContainer;
