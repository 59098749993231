import React, { useState } from 'react';
import { delEntry, delMarker, tagCard } from '../../../assets/jss/components/entries';
import sliceLongString from '../../../lib/utils/sliceLongString';
import Tooltip from '../../../lib/ui/Tooltip';
export const CategoryEntry = ({ title, withoutTooltip, id, rowData, setUserForTagShown, setOpenedModal, }) => {
    const [showDeleting, setShowDeleting] = useState(false);
    const [shownTooltip, setShownTooltip] = useState(false);
    return (React.createElement("div", { onMouseMove: () => setShowDeleting(true), onMouseOut: () => setShowDeleting(false), onFocus: () => setShowDeleting(true), onBlur: () => setShowDeleting(false), style: { position: 'relative' } }, !withoutTooltip ? (React.createElement(Tooltip, { text: title, openOnClick: false, noTranslation: true, childrenStyle: {} },
        React.createElement("div", null,
            React.createElement("div", { style: { maxHeight: '34px' }, className: tagCard },
                React.createElement("div", null, sliceLongString(title, 8))),
            React.createElement("button", { type: "button", className: delEntry, style: {
                    display: !showDeleting ? 'none' : 'flex',
                }, onClick: () => {
                    setShowDeleting(false);
                    setUserForTagShown(Object.assign(Object.assign({}, rowData), { entryData: { id, title } }));
                    setOpenedModal('removeEntry');
                } },
                React.createElement("span", { className: delMarker }, "\u00D7"))))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { maxWidth: '150px' }, className: tagCard }, sliceLongString(title, 20)),
        React.createElement("button", { type: "button", className: delEntry, style: { display: !showDeleting ? 'none' : 'flex' }, onClick: () => {
                rowData && setUserForTagShown(Object.assign(Object.assign({}, rowData), { entryData: { id, title } }));
                setShownTooltip(!shownTooltip);
                setOpenedModal('removeEntry');
                setShowDeleting(false);
            } },
            React.createElement("span", { className: delMarker }, "\u00D7"))))));
};
