import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  devices: [],
  deviceHistory: [],
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  const {
    devices, filters, isLoading, error, deviceHistory,
  } = action;
  switch (action.type) {
    case types.DEVICES_SET:
      return state.merge({
        devices,
        error: null,
      });
    case types.DEVICES_HISTORY_SET:
      return state.merge({
        deviceHistory,
        error: null,
      });
    case types.DEVICES_TOGGLE_FILTERS:
      return state.merge({
        filters: { ...state.filters, ...filters },
      });
    case types.DEVICES_TOGGLE_LOADING:
      return state.merge({ isLoading });
    case types.DEVICES_ERROR:
      return state.merge({ error });
    case types.DEVICES_RESET:
      return state.merge({ ...initialState, pagination: state.pagination });
    default: return state;
  }
}
