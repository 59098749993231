import { toCamel } from 'snake-camel';
import {
  getMetaData,
  userDepartmentRequest,
  userJobTitleRequest,
  userNoteRequest,
  userRequest,
  userService,
  userUpdateUserPrivateDataRequest,
  createDashboardFeedbackRequest
} from 'init/proto_client';

import { setNotification } from 'containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from 'containers/NotificationContainer/notifications';
import * as types from './actionTypes';

export function toggleIsLoading(isLoading) {
  return ({ type: types.USER_TOGGLE_LOADING, isLoading });
}

export function getUser(userId) {
  return async (dispatch) => {
    dispatch(toggleIsLoading(true));
    userRequest.setUserId(userId);
    await userService.getUser(
      userRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.GENERIC_ERROR,
          }));
          dispatch({
            type: types.USER_ERROR,
            isLoading: false,
            error,
          });
        } else {
          const user = toCamel(response.toObject());
          dispatch({
            type: types.USER_SET,
            isLoading: false,
            user,
          });
        }
      },
    );
  };
}

export function setJobTitle(userId, jobTitle) {
  return async (dispatch, getState) => {
    userJobTitleRequest.setUserId(userId);
    userJobTitleRequest.setJobTitle(jobTitle);
    // userJobTitleRequest.setCompanyId(getState().companies.selectedCompany.id);
    await userService.setJobTitle(userJobTitleRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch({
          type: types.USER_ERROR,
          isLoading: false,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.JOB_TITLE_FAILED_MSG,
        }));
      } else {
        const user = toCamel(response.toObject());
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.JOB_TITLE_SUCCESS_MSG,
        }));
        dispatch({
          type: types.USER_SET,
          isLoading: false,
          user,
        });
      }
    });
  };
}

export function setDepartment(userId, departmentId) {
  return async (dispatch) => {
    userDepartmentRequest.setUserId(userId);
    userDepartmentRequest.setDepartmentId(departmentId);
    await userService.setDepartment(userDepartmentRequest, getMetaData(), (error, response) => {
      if (error) {
        dispatch({
          type: types.USER_ERROR,
          isLoading: false,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.DEPARTMENT_FAILED_MSG,
        }));
      } else {
        const user = toCamel(response.toObject());
        dispatch(setNotification({
          type: notificationTypes.TYPE_SUCCESS,
          message: notificationMessages.DEPARTMENT_SUCCESS_MSG,
        }));
        dispatch({
          type: types.USER_SET,
          isLoading: false,
          user,
        });
      }
    });
  };
}

export function setUserNamesCG(userID, firstName, lastName) {
  return async (dispatch) => {
    userUpdateUserPrivateDataRequest.setUserId(userID);
    userUpdateUserPrivateDataRequest.setFirstName(firstName);
    userUpdateUserPrivateDataRequest.setLastName(lastName);
    await userService.updateUserPrivateData(
      userUpdateUserPrivateDataRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.CHANGE_NAME_USER_FAILED_MSG,
          }));
          dispatch({
            type: types.USER_ERROR,
            isLoading: false,
            error,
          });
        } else {
          const user = toCamel(response.toObject());
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.CHANGE_NAME_USER_SUCCESS_MSG,
          }));
          dispatch({
            type: types.USER_SET,
            isLoading: false,
            user,
          });
        }
      },
    );
  };
}

export function setNote(id, text) {
  return async (dispatch) => {
    userNoteRequest.setUserId(id);
    userNoteRequest.setNote(text);
    await userService.setNote(
      userNoteRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.SET_USER_NOTE_FAILED_MSG,
          }));
          dispatch({
            type: types.USER_ERROR,
            isLoading: false,
            error,
          });
        } else {
          const user = toCamel(response.toObject());
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.SET_USER_NOTE_SUCCESS_MSG,
          }));
          dispatch({
            type: types.USER_SET,
            isLoading: false,
            user,
          });
        }
      },
    );
  };
}

export function setFeedback(id, reason, description, rating) {
  return async (dispatch) => {
    createDashboardFeedbackRequest.setCompanyId(id);
    createDashboardFeedbackRequest.setReason(reason);
    createDashboardFeedbackRequest.setDescription(description);
    createDashboardFeedbackRequest.setRate(rating);
    await userService.createDashboardFeedback(
      createDashboardFeedbackRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.SET_FEEDBACK_FAILED_MSG,
          }));
          dispatch({
            type: types.USER_ERROR,
            isLoading: false,
            error,
          });
        } else {
          const user = toCamel(response.toObject());
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.SET_FEEDBACK_SUCCESS_MSG,
          }));
          dispatch({
            type: types.FEEDBACK_SET,
            isLoading: false,
            user,
          });
        }
      },
    );
  };
}

export function resetUser() {
  return { type: types.USER_RESET };
}
