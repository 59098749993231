import { COLORS } from '../../constants/colors';

export const rebaRiskTypes = {
  very: 'VERY',
  high: 'HIGH',
  medium: 'MEDIUM',
  low: 'LOW',
  negligible: 'NEGLIGIBLE',
  none: 'NONE',
};

// TODO: I kept hardcoded colors here, because we have not all of them in our color scheme, it's good to be solved in the future

const calculateRebaRiskLevel = (score, t) => {
  let riskLevel;
  let color;
  let title;
  if (score >= 11 || score === rebaRiskTypes.very) {
    riskLevel = t('SOTER_TASK.ASSESSMENT.REBA.RISKS.VERY_HIGH_RISK');
    color = '#811A28';
    title = rebaRiskTypes.very;
  } else if ((score >= 8 && score <= 10) || score === rebaRiskTypes.high) {
    riskLevel = t('SOTER_TASK.ASSESSMENT.REBA.RISKS.HIGH_RISK');
    color = '#D93B42';
    title = rebaRiskTypes.high;
  } else if ((score >= 4 && score <= 7) || score === rebaRiskTypes.medium) {
    riskLevel = t('SOTER_TASK.ASSESSMENT.REBA.RISKS.MEDIUM_RISK');
    color = '#DE7A09';
    title = rebaRiskTypes.medium;
  } else if (score === 2 || score === 3 || score === rebaRiskTypes.low) {
    riskLevel = t('SOTER_TASK.ASSESSMENT.REBA.RISKS.LOW_RISK');
    color = '#16A34A';
    title = rebaRiskTypes.low;
  } else if (score === 1 || score === rebaRiskTypes.negligible) {
    riskLevel = t('SOTER_TASK.ASSESSMENT.REBA.RISKS.NEGLIGIBLE');
    color = '#046CC5';
    title = rebaRiskTypes.negligible;
  } else {
    riskLevel = t('SOTER_TASK.ASSESSMENT.REBA.NOT_CALCULATED');
    color = COLORS.GRAY[400];
    title = rebaRiskTypes.none;
  }
  return [riskLevel, color, title];
};

export default calculateRebaRiskLevel;
