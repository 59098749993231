import React from 'react';
import { cardBody, cardHeader, column, columnSmall, ml3, } from '../../assets/soter-flex-styles';
import Text from './Text';
import Badge from './Badge';

const InfoCard = ({
  title,
  cardClass,
  badgeMap = {},
  badge,
  titleInlineControl = null,
  rightCornerControl = null,
  children,
  additionalStyles = {},
  bodyStyles,
  style = {},
  rightCornerControlClass = columnSmall,
  titleIsNotAColumn,
  isTaskProfile,
}) => (
  <div style={style} className={cardClass}>
    {title && (
      <div className={cardHeader} style={additionalStyles}>
        <div
          className={titleIsNotAColumn ? '' : column}
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            whiteSpace: isTaskProfile ? 'nowrap' : 'normal',
          }}
        >
          <Text text={title.toUpperCase()} style={{ color: 'inherit' }} />
          {titleInlineControl && (
            <span className={ml3}>{titleInlineControl}</span>
          )}
        </div>
        {rightCornerControl && (
          <div
            className={rightCornerControlClass}
            style={{ textAlign: 'end', paddingRight: 0 }}
          >
            {rightCornerControl}
          </div>
        )}
        {badge && (
          <div className={column} style={{ textAlign: 'end' }}>
            <Badge badgeId={badge} badgeMap={badgeMap} />
          </div>
        )}
      </div>
    )}
    <div className={cardBody} style={bodyStyles}>
      {children}
    </div>
  </div>
);

export default InfoCard;
