import { setCoachDepartment, setCoachJobTitle, setUsersSite } from '../../../wearables/activity/usersStore/actions';
import { addEntry } from '../../../company/categories/store/actions';
export function getActionToDispatch({ userCategoryTitle, userIds, idsArr, companyId, categories, }) {
    // departments and sites have id -1 or lower, at the same time we can identify job_role by title
    if (userCategoryTitle === 'user_job_role' || userCategoryTitle === 'users_job_role') {
        return setCoachJobTitle(userIds, Number(idsArr[0]));
    }
    if (Number(idsArr[1]) > 0) {
        return addEntry({
            entryId: Number(idsArr[0]),
            userIds,
            companyId,
            categories,
        });
    }
    if (Number(idsArr[1]) === -1) {
        return setCoachDepartment(userIds, idsArr[0]);
    }
    return setUsersSite(userIds, idsArr[0]);
}
