import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cardMediumWithHeight } from '../../assets/soter-flex-styles';
import human from '../../assets/img/human-body.png';
import SpineChart from './SpineChart';
import BodyPartControl from './BodyPartControl';
import { chartTypes, taskUnitList, unitList } from '../constants';
import mapDataForAngleChart from '../utils/mapDataForAngleChart';
import ShoulderChart from './ShoulderChart';
import InfoCard from '../../lib/ui/InfoCard';

const views = {
  human: 'human',
  spine: 'spine',
  shoulder: 'shoulder',
};

const PhysicalDemand = ({
  currentRole, saveDemandsData, setDemandData, cancelHandler, isForTask,
}) => {
  const { spineChartData, shoulderChartData } = currentRole;
  const [shouldersData, setShouldersData] = useState([]);
  const [spineData, setSpineData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (spineChartData) setSpineData(JSON.parse(spineChartData.replaceAll('None', 'null')).data);
    if (shoulderChartData) setShouldersData(JSON.parse(shoulderChartData.replaceAll('None', 'null')).data);
  }, [currentRole]);
  const [view, setView] = useState(views.human);
  const [editMode, setEditMode] = useState(false);
  const [spineChartState, setSpineChartState] = useState({ type: chartTypes[0], unit: unitList[0].id });
  const [shouldersChartState, setShouldersChartState] = useState({ type: chartTypes[0], unit: unitList[0].id });
  const timeInAnglesSpine = mapDataForAngleChart(
    spineData,
    isForTask ? 'time_in_minutes' : 'time_in_seconds_percentage',
    'spine',
    isForTask ? 3 : 7.6,
    isForTask ? 'twisting_time_in_seconds' : 'relative_overall_twisting_time_percentage',
    false,
    !isForTask,
  );
  const timeInAnglesSpineWithDummies = [...timeInAnglesSpine];
  timeInAnglesSpineWithDummies.push({ count: 1 }, { count: 1 });
  const timeInAnglesSpineStatic = mapDataForAngleChart(
    spineData,
    isForTask ? 'static_time_in_minutes' : 'static_time_in_seconds_percentage',
    'spine',
    isForTask ? 3 : 7.6,
    isForTask ? 'static_twisting_time_in_seconds' : 'relative_static_twisting_time_percentage',
    false,
    !isForTask,
  );
  const timeInAnglesSpineStaticWithDummies = [...timeInAnglesSpineStatic];
  timeInAnglesSpineStaticWithDummies.push({ count: 1 }, { count: 1 });
  const timeInAnglesSpineRepetition = mapDataForAngleChart(
    spineData,
    isForTask ? 'non_static_time_in_minutes' : 'non_static_time_in_seconds_percentage',
    'spine',
    isForTask ? 3 : 7.6,
    isForTask ? 'non_static_twisting_time_in_seconds' : 'relative_non_static_twisting_time_percentage',
    false,
    !isForTask,
  );
  const timeInAnglesSpineRepetitionWithDummies = [...timeInAnglesSpineRepetition];
  timeInAnglesSpineRepetitionWithDummies.push({ count: 1 }, { count: 1 });
  const switchSpineRepetition = mapDataForAngleChart(spineData, 'non_static_number', 'spine', isForTask ? 3 : 91, 'non_static_twisting_number', isForTask);
  const switchSpineRepetitionWithDummies = [...switchSpineRepetition];
  switchSpineRepetitionWithDummies.push({ count: 1 }, { count: 1 });
  const switchSpineStatic = mapDataForAngleChart(spineData, 'static_number', 'spine', isForTask ? 3 : 91, 'static_twisting_number', isForTask);
  const switchSpineStaticWithDummies = [...switchSpineStatic];
  switchSpineStaticWithDummies.push({ count: 1 }, { count: 1 });
  const switchSpine = mapDataForAngleChart(spineData, 'overall_number', 'spine', isForTask ? 50 : 91, 'overall_twisting_number', isForTask);
  const switchSpineWithDummies = [...switchSpine];
  switchSpineWithDummies.push({ count: 1 }, { count: 1 });
  const timeInAnglesShoulders = mapDataForAngleChart(shouldersData, isForTask ? 'time_in_minutes' : 'time_in_seconds_percentage', 'shoulders', isForTask ? 3 : 3.5);
  const switchShoulders = mapDataForAngleChart(shouldersData, 'overall_number', 'shoulders', 50, null, isForTask);
  const switchShouldersStatic = mapDataForAngleChart(shouldersData, 'static_number', 'shoulders', 50, null, isForTask);
  const switchShouldersRepetition = mapDataForAngleChart(shouldersData, 'non_static_number', 'shoulders', 50, null, isForTask);
  const timeInAnglesStaticShoulders = mapDataForAngleChart(shouldersData, isForTask ? 'static_time_in_minutes' : 'static_time_in_seconds_percentage', 'shoulders', isForTask ? 3 : 3.5);
  const timeInAnglesRepetitionShoulders = mapDataForAngleChart(shouldersData, isForTask ? 'non_static_time_in_minutes' : 'non_static_time_in_seconds_percentage', 'shoulders', isForTask ? 3 : 3.5);
  const getSpineData = () => {
    if (spineChartState.unit === unitList[0].id) {
      if (spineChartState.type === chartTypes[0]) return timeInAnglesSpineWithDummies;
      if (spineChartState.type === chartTypes[1]) return timeInAnglesSpineStaticWithDummies;
      if (spineChartState.type === chartTypes[2]) return timeInAnglesSpineRepetitionWithDummies;
    } else {
      if (spineChartState.type === chartTypes[0]) return switchSpineWithDummies;
      if (spineChartState.type === chartTypes[1]) return switchSpineStaticWithDummies;
      if (spineChartState.type === chartTypes[2]) return switchSpineRepetitionWithDummies;
    }
  };
  const getShouldersData = () => {
    if (shouldersChartState.unit === unitList[0].id) {
      if (shouldersChartState.type === chartTypes[0]) return timeInAnglesShoulders;
      if (shouldersChartState.type === chartTypes[1]) return timeInAnglesStaticShoulders;
      if (shouldersChartState.type === chartTypes[2]) return timeInAnglesRepetitionShoulders;
    } else {
      if (shouldersChartState.type === chartTypes[0]) return switchShoulders;
      if (shouldersChartState.type === chartTypes[1]) return switchShouldersStatic;
      if (shouldersChartState.type === chartTypes[2]) return switchShouldersRepetition;
    }
  };
  const saveButtonHandler = () => {
    saveDemandsData();
    setEditMode(false);
  };
  const cancelButtonHandler = () => {
    cancelHandler();
    setEditMode(false);
  };
  return (
    <>
      {view === views.spine && (
      <SpineChart
        setSpineChartState={setSpineChartState}
        spineChartState={spineChartState}
        unitList={isForTask ? taskUnitList : unitList}
        chartTypes={chartTypes}
        getSpineData={getSpineData}
        goBack={() => setView(views.human)}
      />
      )}
      {view === views.shoulder && (
        <ShoulderChart
          setShouldersChartState={setShouldersChartState}
          shouldersChartState={shouldersChartState}
          unitList={isForTask ? taskUnitList : unitList}
          chartTypes={chartTypes}
          getShouldersData={getShouldersData}
          goBack={() => setView(views.human)}
        />
      )}
      {view === views.human && (
        <InfoCard
          title={t('JOB_DICTIONARY.PHYSICAL_DEMAND')}
          cardClass={cardMediumWithHeight(690)}
          // rightCornerControl={(
          //   <CardEditingControl
          //     editButtonHandler={() => setEditMode(true)}
          //     editMode={editMode}
          //     cancelButtonHandler={cancelButtonHandler}
          //     saveButtonHandler={saveButtonHandler}
          //   />
          // )}
        >
          <div style={{ position: 'relative' }}>
            <BodyPartControl
              isEditing={editMode}
              changeView={spineChartData ? () => setView(views.shoulder) : null}
              top={108}
              left={175 + 40}
              riskLevel={currentRole[isForTask ? 'shouldersRiskGroup' : 'physicalDemandShoulders']}
              changeRiskLevel={(value) => setDemandData(isForTask ? 'shouldersRiskGroup' : 'physicalDemandShoulders')(value)}
            />
            <BodyPartControl
              isEditing={editMode}
              changeView={shoulderChartData ? () => setView(views.spine) : null}
              top={235}
              left={214 + 40}
              goRight
              riskLevel={currentRole[isForTask ? 'spineRiskGroup' : 'physicalDemandSpine']}
              changeRiskLevel={(value) => setDemandData(isForTask ? 'spineRiskGroup' : 'physicalDemandSpine')(value)}
            />
            <BodyPartControl
              isEditing={editMode}
              top={415}
              left={177 + 40}
              riskLevel={currentRole[isForTask ? 'kneeRiskGroup' : 'physicalDemandKnees']}
              changeRiskLevel={(value) => setDemandData(isForTask ? 'kneeRiskGroup' : 'physicalDemandKnees')(value)}
            />
            <img style={{ margin: '30px 0 0 140px' }} src={human} alt="" />
          </div>
        </InfoCard>
      )}
    </>
  );
};

export default PhysicalDemand;
