import getCurrentHazardsList from './getCurrentHazardsList';
import { DONUT_CHART_OUTER_RADIUS } from '../../../containers/PdfContainer/constants';
import { COLORS } from '../../../constants/colors';
import getHMPresentationName from '../../../constants/getHMPresentationName';

const getArc = (realTotal, itemsList, items, colorMap) => {
  const radiusCoefficient = 360 / realTotal;
  const arcs = itemsList
    .map((item) => ({
      fill: colorMap[item.colorIndex],
      angle:
        items[item.key] === '0.0' ? 0 : items[item.key] * radiusCoefficient,
      title: item.title,
      value: items[item.key],
    }))
    .sort((a, b) => (a.angle > b.angle ? -1 : 1));
  return arcs;
};

export const mapDataForDonutChart = (data, type, colorMap) => {
  const hazardsList = getCurrentHazardsList(type);
  const { total } = data;
  const x = DONUT_CHART_OUTER_RADIUS;
  const y = DONUT_CHART_OUTER_RADIUS + 13;
  const hazards = { ...data };
  delete hazards.total;
  const keys = Object.keys(hazards);
  const realTotal = keys.reduce((acc, val) => (acc += +hazards[val]), 0);
  const arcs = getArc(realTotal, hazardsList, hazards, colorMap);
  return {
    x,
    y,
    total,
    arcs,
  };
};

export const mapDataForFeedbackChart = (data, t) => {
  const x = DONUT_CHART_OUTER_RADIUS;
  const y = DONUT_CHART_OUTER_RADIUS + 13;
  const total = Object.keys(data).reduce(
    (acc, value) => (acc += data[value]),
    0,
  );
  const itemsList = Object.keys(data).map((value, index) => ({
    key: value,
    title: t(getHMPresentationName(value)),
    colorIndex: index,
  }));
  const colorMap = [COLORS.RED[600], COLORS.BLUE[900], COLORS.GREEN[600]];
  const arcs = getArc(total, itemsList, { ...data }, colorMap);
  return {
    x,
    y,
    total,
    arcs,
  };
};
