import React, { useEffect, useRef, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { useSelector } from 'react-redux';
import { Dropdown } from 'rsuite';
import * as Emotion from 'emotion';
import Text from '../../../lib/ui/Text';
import {
  container, flexRow, navLink, navLinkActive, navLinkDropdown, navLinkActiveDropdown, pl2, pl3, pr3,
} from '../../../assets/soter-flex-styles';

const dropdownStyles = Emotion.css`
.rs-btn {
    margin: 0.5rem 0;
    background: none !important;
    color: #16161C !important;
    &:hover,
    &:focus {
    color: ${COLORS.LIGHT_BLUE[600]} !important;
  }
  }
`;

const dropdownActiveStyles = Emotion.css`
.rs-btn {
    margin: 0.5rem 0;
    background: none !important;
    color: ${COLORS.LIGHT_BLUE[600]} !important;
  }
`;

const dropdownItemStyle = Emotion.css`
    background: none !important;
`;

const NavigationContext = React.createContext(null);

const useEffectAfterMount = (cb, dependencies) => {
  const justMounted = useRef(true);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
  }, dependencies);
};

const useNavigationContext = () => {
  const context = React.useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'Navigation compound components cannot be rendered outside the Navigation component',
    );
  }
  return context;
};

const getIcon = ({ name, icon }) => {
  const Icon = icon;
  return Icon ? (
    <Icon className={name === 'NAVBAR.DEVICES' ? 'devices' : undefined} />
  ) : null;
};

const LinkTab = ({ settings }) => {
  const { navigationState, setNavigationState } = useNavigationContext();
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.companies.selectedCompany);
  const withIcon = !!settings.icon;
  const isActive = settings.links?.some(link => 
    window.location.href.includes(t(link.name).toLowerCase())
  );
  
  return (
    settings.type === 'dropdown' ?
    <Dropdown className={isActive ? dropdownActiveStyles : dropdownStyles} title={t(settings.name)}>
    {settings.links.map(item => (
      <Dropdown.Item key={item.name} className={dropdownItemStyle} onClick={() => navigate(`/${id + item.to}`)}>
        <Text
          text={t(item.name)}
          appearance="footnote"
          className={
            window.location.href?.includes(t(item.name).toLowerCase())
               ? navLinkActiveDropdown
               : navLinkDropdown
           }
          inline
        />
      </Dropdown.Item>
    ))}
    </Dropdown>
    : <Link
      className={
       settings.to?.slice(5).indexOf(navigationState) !== -1
          ? navLinkActive
          : navLink
      }
      to={`/${id + settings.to}`}
      onClick={() => setNavigationState(settings.to)}
    >
      {withIcon && getIcon(settings)}
      <Text
        text={t(settings.name)}
        style={{ color: 'inherit' }}
        appearance="footnote"
        className={pl2}
        inline
      />
    </Link>
  );
};

// eslint-disable-next-line react/prefer-stateless-function
const Navigation = ({ children, color, activePath }) => {
  const [navigationState, setNavigationState] = useState(activePath);

  useEffectAfterMount(() => setNavigationState(activePath), [activePath]);
  return (
    <div
      style={{
        backgroundColor: COLORS.WHITE,
        borderBottom: '1px solid #E0E5EC',
      }}
    >
      <div className={container}>
        <div
          className={`${flexRow} ${pl3} ${pr3}`}
          style={{
            justifyContent: 'flex-start',
            color,
          }}
        >
          <NavigationContext.Provider
            value={{
              navigationState,
              setNavigationState,
            }}
          >
            {children}
          </NavigationContext.Provider>
        </div>
      </div>
    </div>
  );
};

Navigation.Link = LinkTab;
export default Navigation;
