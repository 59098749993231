import React from 'react';
import { Radio, RadioGroup } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { assessmentTypesList, weightUnitsList } from '../constants';
import { text } from '../../../assets/jss/style';
import { COLORS } from '../../../constants/colors';
const AssessmentSettings = ({ assessmentType, weightUnit, setAssessmentType, setWeightUnit, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { style: { height: 'inherit', padding: '10px' } },
        React.createElement(RadioGroup, { value: assessmentType, onChange: (value) => setAssessmentType(value) },
            React.createElement("div", { style: { marginLeft: '8px' }, className: text(COLORS.GRAY[400], 16) }, t('SOTER_TASK.ASSESSMENT.TITLES.SELECT_TYPE').toUpperCase()),
            assessmentTypesList.map(({ value, title }) => React.createElement(Radio, { key: value, value: value }, t(title)))),
        React.createElement(RadioGroup, { value: weightUnit, onChange: (value) => setWeightUnit(value) },
            React.createElement("div", { style: { marginLeft: '8px' }, className: text(COLORS.GRAY[400], 16) }, t('SOTER_TASK.ASSESSMENT.TITLES.SELECT_UNIT').toUpperCase()),
            weightUnitsList.map(({ value, title }) => React.createElement(Radio, { key: value, value: value }, t(title))))));
};
export default AssessmentSettings;
