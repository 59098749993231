import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import {
  columnSmall, container, flexColumn, flexRow, mb5, mt3, mt5, pl3,
} from '../../assets/soter-flex-styles';
import ActivityBreakdown from '../components/ActivityBreakdown';
import EditableJobProfileHeader from '../components/EditableJobProfileHeader';
import CriticalPhysicalDemands from '../components/CriticalPhysicalDemands';
import VideoPlayer from '../components/VideoPlayer';
import EditableDictionary from '../components/EditableDictionary';
import TasksTable from '../components/TasksTable';
import getMappedActivitiesList from '../utils/getMappedActivitiesList';
import DemandsActivityByteConvertor from '../utils/DemandsActivityByteConvertor';
import PhysicalDemand from '../components/PhysicalDemand';
import { TASK_PROFILE_ADD } from '../../constants/analyticsEvents';
import { emptyJobProfile } from '../../init/proto_client';
import {
  createJobProfile,
  createTag,
  editJobProfile,
  editTaskProfile,
  getJobProfile,
  resetProfileState,
} from './jobProfileStore/actions';
import { getCompanies } from '../../company/companiesStore/actions';
import { removeTask, removeVideo } from '../taskProfile/taskProfileStore/actions';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
import Loader from '../../lib/ui/Loader';
import EditableTextCard from '../../lib/ui/EditableTextCard/EditableTextCard';
import { routes } from '../../containers/PrivatPageHOC/constants/routes';

const JobProfileContainer = () => {
  const byteConvertor = new DemandsActivityByteConvertor();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const zeroBase64String = byteConvertor.getZeroBase64String();
  _each(
    emptyJobProfile.criticalPhysicalDemands,
    (value, key) => (emptyJobProfile.criticalPhysicalDemands[key] = zeroBase64String),
  );
  const {
    id: companyId,
    workGroupsList,
    jdTags: tags,
  } = useSelector((state) => state.companies.selectedCompany);
  const {
    error: jobProfileError,
    isLoading,
    videosList,
  } = useSelector((state) => state.jobProfile);
  const {
    profile, id, tag, taskProfilesListList,
  } = useSelector(
    (state) => state.jobProfile,
  );
  const [isEmptyProfile, setIsEmptyProfile] = useState(!params.id);
  const [currentRole, setCurrentRole] = useState(emptyJobProfile);
  const [activitiesList, setActivitiesList] = useState([]);
  const [headerLocalCurrentRole, setHeaderLocalCurrentRole]
    = useState(currentRole);
  const [headerEditMode, setHeaderEditMode] = useState(!params.id);
  useEffect(() => {
    if (params.id && profile.title) {
      setCurrentRole(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (params.id) {
      dispatch(getJobProfile(params.id));
    }
    return () => dispatch(resetProfileState());
  }, []);

  useEffect(() => {
    if (id && isEmptyProfile) {
      navigate(`/users/jobs/profile/${id}`);
      setIsEmptyProfile(false);
    }
  }, [id]);

  useEffect(() => {
    setActivitiesList(
      getMappedActivitiesList(currentRole.activityDemandBreakdown),
    );
    setHeaderLocalCurrentRole(currentRole);
  }, [currentRole]);

  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    if (!tag) return;
    dispatch(getCompanies());
    if (
      !currentRole.toolsList.includes(tag?.id)
      || !currentRole.preRequirementsList.includes(tag?.id)
    ) {
      if (tag.entity === 0) {
        setCurrentRole({
          ...currentRole,
          toolsList: [...currentRole.toolsList, tag.id],
        });
      } else {
        setCurrentRole({
          ...currentRole,
          preRequirementsList: [...currentRole.preRequirementsList, tag.id],
        });
      }
    }
  }, [tag]);

  const setActivityDemandBreakdown = (mappedActivities) => {
    const activityDemandBreakdown = {};
    _each(mappedActivities, (activity) => {
      activityDemandBreakdown[activity.activityKey] = parseFloat(
        activity.value,
      );
    });
    dispatch(
      editJobProfile(params.id, { ...currentRole, activityDemandBreakdown }),
    );
  };
  const saveProfileButtonHandler = () => {
    if (!headerLocalCurrentRole.title) {
      alert(t('JOB_DICTIONARY.JOB_PROFILE.TITLE_REQUIRED'));
      return;
    }
    if (!params.id) {
      dispatch(createJobProfile(companyId, headerLocalCurrentRole));
    } else {
      dispatch(editJobProfile(params.id, headerLocalCurrentRole));
    }
    if (jobProfileError) return;
    setHeaderEditMode(false);
  };
  const saveLocalValue = (key) => (value) => dispatch(editJobProfile(params.id, { ...currentRole, [key]: value }));
  const addTagHandler = (type) => (value) => {
    dispatch(createTag(companyId, type, value));
  };
  const cancelHandler = () => {
    setCurrentRole(profile);
    setActivitiesList(
      getMappedActivitiesList(currentRole.activityDemandBreakdown),
    );
  };
  const setTagsHandler = (type) => (items) => setCurrentRole({ ...currentRole, [type]: items });
  const editTaskHandler = (taskProfile) => {
    dispatch(editTaskProfile(taskProfile));
  };
  const removeTaskHandler = (taskId) => {
    dispatch(removeTask(taskId, params.id));
  };
  return (
    <div className={container}>
      {params.id && !id ? (
        <Loader />
      ) : (
        <>
          <div className={`${flexRow} ${mt3}`}>
            <div
              className={columnSmall}
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <NavigateBackButton
                name={t('WEARABLES.USER_PAGE.BACK_TO_TABLE')}
                navigateTo={`/${companyId}/${routes.users}/${routes.jobs}`}
              />
            </div>
            {/* UN comment this to edit job jobProfile */}
            {/* <HeaderButtons */}
            {/*  currentRole={currentRole} */}
            {/*  headerEditMode={headerEditMode} */}
            {/*  saveProfileButtonHandler={saveProfileButtonHandler} */}
            {/*  setHeaderEditMode={setHeaderEditMode} */}
            {/*  setHeaderLocalCurrentRole={setHeaderLocalCurrentRole} */}
            {/*  deleteProfile={() => { */}
            {/*    if (window.confirm(t('SOTER_TASK.VIDEO.SURE'))) { */}
            {/*      dispatch(deleteJobProfile(params.id)); */}
            {/*    } */}
            {/*  }} */}
            {/*  showOnlySaveButton={!params.id} */}
            {/* /> */}
          </div>
          <div className={flexColumn}>
            <div className={flexRow}>
              <div className={flexColumn}>
                <EditableJobProfileHeader
                  setCurrentRole={setHeaderLocalCurrentRole}
                  currentRole={headerLocalCurrentRole}
                  editMode={headerEditMode}
                />
              </div>
            </div>
          </div>
          {params.id && (
            <>
              <div className={flexColumn}>
                <div className={flexRow}>
                  <div className={`${flexColumn} ${pl3}`}>
                    <EditableTextCard
                      id="summary_description"
                      className={mb5}
                      height={266}
                      title={t('JOB_DICTIONARY.JOB_PROFILE.SUM_DESCRIPTION')}
                      text={currentRole.summaryDescription}
                      saveValue={saveLocalValue('summaryDescription')}
                    />
                    <ActivityBreakdown
                      hazardsList={activitiesList}
                      setActivityBreakdown={setActivityDemandBreakdown}
                      cancelHandler={cancelHandler}
                    />
                  </div>
                  <div className={`${flexColumn} ${pl3}`}>
                    <PhysicalDemand
                      currentRole={currentRole}
                      cancelHandler={cancelHandler}
                      saveDemandsData={() => dispatch(editJobProfile(params.id, currentRole))}
                      setDemandData={(type) => (value) => setCurrentRole({ ...currentRole, [type]: value })}
                    />
                  </div>
                </div>
              </div>
              <div className={`${flexColumn} ${mt5} ${mb5}`}>
                <div className={flexRow}>
                  <div className={flexColumn}>
                    <TasksTable
                      data={_sortBy(
                        taskProfilesListList,
                        ({ duration }) => -duration,
                      )}
                      addTaskHandler={() => {
                        window.logEvent(TASK_PROFILE_ADD);
                        navigate(`/users/jobs/profile/${params.id}/task`);
                      }}
                      editTaskHandler={editTaskHandler}
                      removeTaskHandler={removeTaskHandler}
                    />
                  </div>
                </div>
              </div>
              {videosList?.length > 0 && (
                <div className={`${flexColumn} ${mt5} ${mb5} ${pl3}`}>
                  <div className={flexRow}>
                    <VideoPlayer
                      noActions
                      videoList={videosList}
                      removeVideoHandler={(videoId) => dispatch(removeVideo(videoId, null, params.id))}
                    />
                  </div>
                </div>
              )}
              <div className={`${flexColumn} ${mb5}`}>
                <div className={flexRow}>
                  <div className={flexColumn}>
                    <CriticalPhysicalDemands
                      physicalDemandData={currentRole.criticalPhysicalDemands}
                      saveHandler={saveLocalValue('criticalPhysicalDemands')}
                      cancelHandler={cancelHandler}
                    />
                  </div>
                  <div className={flexColumn}>
                    <EditableDictionary
                      title={t('JOB_DICTIONARY.JOB_PROFILE.TOOLS')}
                      items={currentRole.toolsList}
                      tags={tags?.toolsList}
                      addTagHandler={addTagHandler(0)}
                      saveHandler={saveLocalValue('tools')}
                      setItemsHandler={setTagsHandler('toolsList')}
                      cancelHandler={cancelHandler}
                    />
                    <EditableDictionary
                      title={t('JOB_DICTIONARY.JOB_PROFILE.PPE')}
                      items={currentRole.preRequirementsList}
                      tags={tags?.preRequirementsList}
                      addTagHandler={addTagHandler(1)}
                      saveHandler={saveLocalValue('preRequirements')}
                      setItemsHandler={setTagsHandler('preRequirementsList')}
                      cancelHandler={cancelHandler}
                    />
                    <EditableTextCard
                      id="edit_risks"
                      height={402}
                      title={t('JOB_DICTIONARY.JOB_PROFILE.RISKS')}
                      text={currentRole.keyRisks}
                      saveValue={saveLocalValue('keyRisks')}
                    />
                  </div>
                </div>
              </div>
              <div className={`${flexColumn} ${mt5}`}>
                <div className={flexRow}>
                  <div className={flexColumn}>
                    <EditableTextCard
                      id="edit_cog_demands"
                      className={mb5}
                      height={408}
                      title={t('JOB_DICTIONARY.JOB_PROFILE.COGNITIVE')}
                      text={currentRole.cognitiveDemand}
                      saveValue={saveLocalValue('cognitiveDemand')}
                    />
                  </div>
                  <div className={flexColumn}>
                    <EditableTextCard
                      className={mb5}
                      height={408}
                      id="edit_psy_demands"
                      title={t('JOB_DICTIONARY.JOB_PROFILE.PSYCHOLOGICAL')}
                      text={currentRole.psychosocialDemand}
                      saveValue={saveLocalValue('psychosocialDemand')}
                    />
                  </div>
                </div>
              </div>
              <div className={`${flexColumn} ${mb5}`}>
                <div className={flexRow}>
                  <div className={flexColumn}>
                    <EditableTextCard
                      id="edit_env_demands"
                      height={408}
                      title={t('JOB_DICTIONARY.JOB_PROFILE.ENVIRONMENTAL')}
                      text={currentRole.environmentalDemand}
                      saveValue={saveLocalValue('environmentalDemand')}
                    />
                  </div>
                  <div className={flexColumn}>
                    <EditableTextCard
                      id="edit_surveillance"
                      height={408}
                      title={t('JOB_DICTIONARY.JOB_PROFILE.MEDICAL')}
                      text={currentRole.medicalSurveillance}
                      saveValue={saveLocalValue('medicalSurveillance')}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default JobProfileContainer;
