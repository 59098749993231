import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  requests: [],
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.HELP_SET:
      // eslint-disable-next-line no-case-declarations
      const { requests } = action;
      return state.merge({
        requests,
        error: null,
      });
    case types.HELP_TOGGLE_FILTERS:
      // eslint-disable-next-line no-case-declarations
      const { filters } = action;
      return state.merge({
        filters: { ...state.filters, ...filters },
      });
    case types.HELP_TOGGLE_LOADING:
      // eslint-disable-next-line no-case-declarations
      const { isLoading } = action;
      return state.merge({ isLoading });
    case types.HELP_ERROR:
      // eslint-disable-next-line no-case-declarations
      const { error } = action;
      return state.merge({ error });
    case types.HELP_RESET:
      return state.merge({ ...initialState, pagination: state.pagination });
    default: return state;
  }
}
