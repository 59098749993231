import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';

export const banner = Emotion.css`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  height: 50px;
  color: ${COLORS.GRAY[900]};
`;

export const success = Emotion.css`
  background-color: ${COLORS.GREEN[200]};
`;

export const warning = Emotion.css`
  background-color: ${COLORS.ORANGE[200]};
`;

export const ended = Emotion.css`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.RED[600]};
`;

export const inner = Emotion.css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.75rem;
`;

export const icon = Emotion.css`
  stroke: ${COLORS.YELLOW[300]};
  margin-right: 26px;
`;
