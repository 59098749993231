import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';

export const wrapper = Emotion.css`
   {
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

export const step = Emotion.css`
   {
    max-width: 335px;
    text-align: center;

    @media (max-width: 1240px) {
      max-width: 285px;
    }

    &:last-child {
      @media (max-width: 768px) {
        margin-top: 40px;
      }
    }
  }
`;

export const title = Emotion.css`
   {
    margin-bottom: 1.5rem;
    font-family: 'Mulish', sans-serif;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const description = Emotion.css`
   {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: ${COLORS.GRAY[400]};
  }
`;

export const actionBox = Emotion.css`
   {
    width: 295px;
    margin: 40px auto 0;
    color: ${COLORS.GRAY[950]};
    text-align: center;
    font-size: 14px;
  }
`;

export const divider = Emotion.css`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    & > div {
      width: 116px;
      height: 1px;
      background-color: ${COLORS.LIGHT_BLUE[700]};
    }
  }
`;

export const links = Emotion.css`
   {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    button {
      width: 128px;
      height: 37px;
    }
  }
`;

export const appStore = Emotion.css`
   {
    background-image: url('/assets/img/appStore.svg');
  }
`;

export const googlePlay = Emotion.css`
   {
    background-image: url('/assets/img/googlePlay.svg');
  }
`;
