import React, { useRef } from 'react';
import { DateRangePicker } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { customDateRangePicker } from '../../../assets/jss/style';
import { calendarLocale } from '../../../admin/components/utils';

const { afterToday, before, combine } = DateRangePicker;

const CustomDateRangePicker = (props) => {
  const { disableBefore, ...restProps } = props;
  const containerRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <DateRangePicker
        shouldDisableDate={combine(before(disableBefore), afterToday())}
        className={customDateRangePicker}
        container={() => containerRef.current}
        format='MMM dd, yyyy'
        locale={calendarLocale(t)}
        {...restProps}
      />
    </div>
  );
};

export default CustomDateRangePicker;
