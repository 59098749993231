import {
  Group, Layer, Rect, Stage,
} from 'react-konva';
import React from 'react';
import DotedLine from 'containers/PdfContainer/components/konvaShapes/DotedLine';
import { COLORS } from 'constants/colors';
import Text from 'containers/PdfContainer/components/konvaShapes/Text';
import { chartHeader } from '../../../../assets/jss/containers/pdfLayersStyle';

const HorizontalBarChartWrapper = ({
  header,
  width,
  height,
  axisData,
  data,
}) => {
  const { bottom, doted } = axisData;
  const values = data.map((item) => item.value);
  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }
  const maxValue = getMaxOfArray(values);
  const maxDomainValue = getMaxOfArray(doted.domainValues);
  return (
    <>
      {header && <h1 className={chartHeader}>{header}</h1>}
      <Stage width={width} height={height}>
        <Layer>
          <Rect
            x={bottom.x}
            y={bottom.y}
            height={0.5}
            width={bottom.width}
            fill={COLORS.GRAY[400]}
          />
          {doted.points.map((point, index, arr) => (
            <DotedLine
              key={`dot${Math.random()}`}
              width={doted.width}
              y={point - bottom.y / arr.length}
              x={doted.x}
              domainValue={
                maxDomainValue > 2.4
                  ? doted.domainValues[index]?.toFixed(1)
                  : doted.domainValues[index]?.toFixed(2)
              }
              vertical
            />
          ))}
          {data?.map(
            ({
              // eslint-disable-next-line no-shadow
              x,
              y,
              width,
              fill,
              height,
              value,
            }) => (
              <Group key={y} y={y} x={x}>
                <Rect width={width} fill={fill} height={height} />
                <Text
                  text={maxValue > 2.4 ? value.toFixed(1) : value.toFixed(2)}
                  x={width + 2}
                  fontSize={9}
                  fill="black"
                />
              </Group>
            ),
          )}
        </Layer>
      </Stage>
    </>
  );
};

export default HorizontalBarChartWrapper;
