import * as Emotion from 'emotion';

export const entryItem = Emotion.css`
  &::after {
    content: '';
    width: 90%;
    height: 1px;
    left: 36px;
    background: #dee2e6;
    position: absolute;
  }

  .rs-radio-wrapper {
    top: 16px;
  }
`;

export const entryBtn = Emotion.css`
  bottom: 10px;
  background: #fff;
  position: sticky;

  button {
    width: 100%;
  }
`;

