var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Input, InputGroup } from 'rsuite';
import Close from '@rsuite/icons/Close';
import { useTranslation } from 'react-i18next';
import { customInput, errorInput, errorMessage } from '../../assets/soter-flex-styles';
const CustomInput = (_a) => {
    var { className, name, onChange = () => { }, onBlur = () => { }, value, onClear, addon, formik, disabled, type, max = 50000, addonStyle = {}, onInputChange } = _a, rest = __rest(_a, ["className", "name", "onChange", "onBlur", "value", "onClear", "addon", "formik", "disabled", "type", "max", "addonStyle", "onInputChange"]);
    const { t } = useTranslation();
    const handleChange = (val) => {
        let v = val;
        if (type === 'int' &&
            ((val.length && !+val) || +val > max || String(val).includes(',') || String(val).includes('.')))
            v = value || (name && formik.values[name]);
        if (formik) {
            if (onInputChange && name) {
                onInputChange(name, val);
            }
            else {
                formik.setFieldValue(name, v);
            }
        }
        else {
            onChange(name, v);
        }
    };
    const handleBlur = (e) => {
        if (formik) {
            formik.setFieldTouched(name);
        }
        else {
            onBlur(name, e.target.value);
        }
    };
    const formikError = formik && name && formik.touched[name] && formik.errors[name];
    const getSelectView = () => {
        if (onClear) {
            return (React.createElement(InputGroup, { style: { height: '38px' } },
                React.createElement(Input, Object.assign({ disabled: disabled, className: `${className} ${customInput}`, onChange: handleChange, onBlur: handleBlur, value: (formik && name) ? formik.values[name] : value, type: type === 'int' ? '' : type, max: max }, rest)),
                value && !disabled && (React.createElement(InputGroup.Addon, { onClick: onClear },
                    React.createElement(Close, null)))));
        }
        if (addon) {
            return (React.createElement(InputGroup, { style: addonStyle, className: `${customInput}` },
                React.createElement(Input, Object.assign({ value: (formik && name) ? formik.values[name] : value, onChange: handleChange, onBlur: handleBlur, type: type === 'int' ? '' : type, max: max }, rest)),
                React.createElement(InputGroup.Addon, null, addon)));
        }
        const renderInput = () => (React.createElement(Input, Object.assign({ className: `${className} ${customInput} ${formikError && errorInput}`, onChange: handleChange, onBlur: handleBlur, value: (formik && name) ? formik.values[name] : value, type: type === 'int' ? '' : type, max: max }, rest)));
        if (formik) {
            return (React.createElement("div", { style: { position: 'relative' } },
                renderInput(),
                formikError && (React.createElement("div", { className: errorMessage }, t(formikError)))));
        }
        return renderInput();
    };
    const selectView = getSelectView();
    return selectView;
};
export default CustomInput;
