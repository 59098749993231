import React from 'react';
import { useTranslation } from 'react-i18next';
import DonutChart from './DonutChart';
import { LegendPosition } from '../../utils/d3/Chart';

const UserFeedbackChart = ({
  data = {},
  containerHeight,
  hasDataForChart,
  chartId,
}) => {
  const { t } = useTranslation();
  const feedbackTypes = Object.keys(data);
  const getData = () => feedbackTypes.reduce((acc, item) => {
    if (item !== 'total') {
      return [...acc,
        {
          name: item,
          value: data[item],
        },
      ];
    }
    return acc;
  }, []).concat([{
    columns: ['name', 'value'],
  }]);
  const overallNumber = data?.negativeFeedbackCount + data?.neutralFeedbackCount + data?.positiveFeedbackCount || 0;

  return (
    <DonutChart
      chartId={chartId}
      hasDataForChart={overallNumber}
      getData={getData}
      sessionHazards={feedbackTypes}
      containerHeight={containerHeight}
      overallNumber={overallNumber}
      withLegend
      legendPosition={LegendPosition.RIGHT}
      overallText={t('SOTER_TASK.ASSESSMENT.TOTAL')}
      deviceType="feedback"
    />
  );
};

export default UserFeedbackChart;
