import { TableType } from '../constants/tableType';
import * as types from './actionTypes';

export function setCurrentPage(currentPage, type) {
  switch (type) {
    case TableType.coach:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.COACH_USER_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.help:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.HELP_USER_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.devices:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.DEVICES_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.companies:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.COMPANIES_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.dashboardUsers:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.DASHBOARD_USERS_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.myCompanyUsers:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.MY_COMPANY_USERS_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.soterTaskVideos:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.SOTER_TASK_VIDEOS_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.soterTaskComparison:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.SOTER_TASK_COMPARISONS_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.adminDevices:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.ADMIN_DEVICES_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.hubs:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.HUBS_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.feedbacks:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.FEEDBACKS_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.usersUpload:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.USERS_UPLOAD_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.categories:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.MY_COMPANY_CATEGORIES_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.entries:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.MY_COMPANY_ENTRIES_SET_CURRENT_PAGE,
        currentPage,
      };
    case TableType.jobs:
      // addURLQueryParams(page, currentPage);
      return {
        type: types.JOBS_SET_CURRENT_PAGE,
        currentPage,
      };
    default: return null;
  }
}

export function setDisplayLength(displayLength, type) {
  switch (type) {
    case TableType.coach:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.COACH_USER_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.help:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.HELP_USER_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case 'devices':
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.DEVICES_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.companies:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.COMPANIES_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.dashboardUsers:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.DASHBOARD_USERS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.myCompanyUsers:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.MY_COMPANY_USERS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.soterTaskVideos:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.SOTER_TASK_VIDEOS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.soterTaskComparison:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.SOTER_TASK_COMPARISONS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.adminDevices:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.ADMIN_DEVICES_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.hubs:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.HUBS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.feedbacks:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.FEEDBACKS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.usersUpload:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.USERS_UPLOAD_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.categories:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.MY_COMPANY_CATEGORIES_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.entries:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.MY_COMPANY_ENTRIES_SET_DISPLAY_LENGTH,
        displayLength,
      };
    case TableType.jobs:
      // addURLQueryParams(pageSize, displayLength);
      return {
        type: types.JOBS_SET_DISPLAY_LENGTH,
        displayLength,
      };
    default: return null;
  }
}

export function setSortingColumn(sortColumn, type) {
  switch (type) {
    case TableType.coach:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.USERS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.help:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.HELP_USERS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.devices:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.DEVICES_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.companies:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.COMPANIES_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.dashboardUsers:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.DASHBOARD_USERS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.myCompanyUsers:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.MY_COMPANY_USERS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.soterTaskVideos:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.SOTER_TASK_VIDEOS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.soterTaskComparison:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.SOTER_TASK_COMPARISONS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.adminDevices:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.ADMIN_DEVICES_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.hubs:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.HUBS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.feedbacks:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.FEEDBACKS_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.usersUpload:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.USERS_UPLOAD_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.categories:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.MY_COMPANY_CATEGORIES_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.entries:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.MY_COMPANY_ENTRIES_SET_SORTING_COLUMN,
        sortColumn,
      };
    case TableType.jobs:
      // addURLQueryParams(sorting, sortColumn);
      return {
        type: types.JOBS_SET_SORTING_COLUMN,
        sortColumn,
      };
    default: return null;
  }
}

export function setSortingType(sortType, type) {
  switch (type) {
    case TableType.coach:
      // addURLQueryParams(sortingType, sortType);
      return ({
        type: types.USERS_SET_SORTING_TYPE,
        sortType,
      });
    case TableType.help:
      // addURLQueryParams(sortingType, sortType);
      return ({
        type: types.HELP_USERS_SET_SORTING_TYPE,
        sortType,
      });
    case TableType.devices:
      // addURLQueryParams(sortingType, sortType);
      return ({
        type: types.DEVICES_SET_SORTING_TYPE,
        sortType,
      });
    case TableType.companies:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.COMPANIES_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.dashboardUsers:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.DASHBOARD_USERS_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.myCompanyUsers:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.MY_COMPANY_USERS_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.soterTaskVideos:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.SOTER_TASK_VIDEOS_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.soterTaskComparison:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.SOTER_TASK_SET_COMPARISONS_SORTING_TYPE,
        sortType,
      };
    case TableType.adminDevices:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.ADMIN_DEVICES_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.hubs:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.HUBS_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.feedbacks:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.FEEDBACKS_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.usersUpload:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.USERS_UPLOAD_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.categories:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.MY_COMPANY_CATEGORIES_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.entries:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.MY_COMPANY_ENTRIES_SET_SORTING_TYPE,
        sortType,
      };
    case TableType.jobs:
      // addURLQueryParams(sortingType, sortType);
      return {
        type: types.JOBS_SET_SORTING_TYPE,
        sortType,
      };
    default: return null;
  }
}

export function clearPagination() {
  // removeParam(urlParams.page);
  // removeParam(urlParams.pageSize);

  return ({
    type: types.CLEAR_PAGINATION,
  });
}
