import React from 'react';
import { mb1, mb5, mb6, mt6, smallMutedText, } from '../../../assets/soter-flex-styles';
import { companyTitle, heading } from './styles';
import { useTranslation } from 'react-i18next';

const CompaniesWithAccessDialog = ({ companies, forSite }) => {
  const { t } = useTranslation();
  const getDepartmentsString = (departments) => (departments.length === 0 ? (
    <span>{forSite ? t('MY_COMPANY.USERS.ALL_SITES') : t('MY_COMPANY.USERS.ALL_DEPARTMENTS')}</span>
  ) : (
    departments.map((dep, i) => (
      <span key={dep.id}>{`${i !== 0 ? ',' : ''} ${dep.name}`}</span>
    ))
  ));

  return (
    <>
      <div className={`${heading} ${mb5}`}>{t('MY_COMPANY.USERS.GRANT')}</div>
      {companies.map((company) => (
        <div key={company.id} className={`${mt6} ${mb6}`}>
          <div className={`${companyTitle} ${mb1}`}>{company.name}</div>
          <div className={smallMutedText}>{getDepartmentsString(forSite ? company.sitesList : company.departmentsList)}</div>
        </div>
      ))}
    </>
  );
};

export default CompaniesWithAccessDialog;
