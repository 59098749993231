import React from 'react';
import { useTranslation } from 'react-i18next';
import { contactSalesProductType, productStatus } from 'init/proto_client';
import { useAppSelector } from 'lib/utils/useStore';
import ChatContainer from './ChatContainer';
import SubscriptionEnded from '../wearables/overview/components/SubscriptionEnded';
import ProductPreviewContainer from 'containers/ProductPreviewContainer/ProductPreviewContainer';
import { productPreview } from 'containers/ProductPreviewContainer/productPreview';
import PrivatePageHOC from 'containers/PrivatPageHOC/PrivatePageHOC';
const Chat = () => {
    var _a;
    const { products } = useAppSelector((state) => state.companies.selectedCompany);
    const { t } = useTranslation();
    const showCurrentSubscription = (status) => {
        switch (status) {
            case productStatus.PRODUCTSTATUSSUBSCRIPTIONACTIVE:
                return React.createElement(ChatContainer, null);
            case productStatus.PRODUCTSTATUSSUBSCRIPTIONFINISHED:
                return React.createElement(SubscriptionEnded, { name: "geniusProduct", productInfo: products === null || products === void 0 ? void 0 : products.geniusProduct });
            default:
                return (React.createElement(ProductPreviewContainer, { productInfo: products.geniusProduct, productType: contactSalesProductType.PRODUCTTYPEGENIUS, layout: productPreview.GeniusPreviewProduct(t) }));
        }
    };
    return showCurrentSubscription((_a = products === null || products === void 0 ? void 0 : products.geniusProduct) === null || _a === void 0 ? void 0 : _a.status);
};
export default PrivatePageHOC(Chat);
