import React from 'react';
import { useTranslation } from 'react-i18next';
import { Arc, Layer, Stage } from 'react-konva';
import Text from './konvaShapes/Text';
import { COLORS } from '../../../constants/colors';
import {
  DONUT_CHART_ARC_GAP_DEGREE, DONUT_CHART_INNER_RADIUS,
  DONUT_CHART_OUTER_RADIUS,
} from '../constants';
import {
  chartHeader,
  legendRect,
} from '../../../assets/jss/containers/pdfLayersStyle';

const DonutChartWrapper = ({
  header,
  width,
  height,
  data,
  legendData,
  innerText,
}) => {
  const { t } = useTranslation();
  let rotation = 270;
  const {
    arcs, x, y, total,
  } = data;
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'relative' }}>
        {header && (
          <h1
            style={{ position: 'absolute', width: width * 2 }}
            className={chartHeader}
          >
            {header}
          </h1>
        )}
        <div style={{ marginTop: '65px' }}>
          <Stage width={width} height={height}>
            <Layer>
              {arcs.map(({ fill, angle }) => {
                rotation += angle;
                return (
                  <Arc
                    innerRadius={DONUT_CHART_INNER_RADIUS}
                    outerRadius={DONUT_CHART_OUTER_RADIUS}
                    key={Math.random()}
                    x={x}
                    y={y}
                    /* eslint-disable-next-line no-nested-ternary */
                    angle={
                      angle
                        ? angle > DONUT_CHART_ARC_GAP_DEGREE
                          ? angle - DONUT_CHART_ARC_GAP_DEGREE
                          : angle
                        : 0
                    }
                    /* eslint-disable-next-line no-nested-ternary */
                    rotation={
                      angle
                        ? angle > DONUT_CHART_ARC_GAP_DEGREE
                          ? rotation - angle
                          : rotation - angle * 1.5
                        : 0
                    }
                    fill={fill}
                  />
                );
              })}
              <Text
                text={total}
                fontSize={42}
                fontStyle="bold"
                fill={COLORS.GRAY[950]}
                x={DONUT_CHART_OUTER_RADIUS - 27 - (String(total).length * 3)}
                y={DONUT_CHART_OUTER_RADIUS - 19}
              />
              <Text
                text={innerText}
                alignItems="center"
                fontSize={10}
                x={DONUT_CHART_OUTER_RADIUS - innerText?.length - 12}
                y={DONUT_CHART_OUTER_RADIUS + 24}
                align="center"
                verticalAlign="middle"
              />
            </Layer>
          </Stage>
        </div>
      </div>
      <div
        style={{
          marginTop: '21mm',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        {legendData.map(({ title, color }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '4mm',
              fontSize: '7pt',
            }}
            key={`legend${Math.random()}`}
          >
            <div className={legendRect(color)} />
            {`${t(title)} ${arcs.find((arc) => arc.title === title)?.value}`}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DonutChartWrapper;
