// source: api/job_dictionary/v1/job_dictionary_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_job_dictionary_v1_job_profile_message_pb = require('../../../api/job_dictionary/v1/job_profile_message_pb.js');
goog.object.extend(proto, api_job_dictionary_v1_job_profile_message_pb);
var api_job_dictionary_v1_job_task_profile_message_pb = require('../../../api/job_dictionary/v1/job_task_profile_message_pb.js');
goog.object.extend(proto, api_job_dictionary_v1_job_task_profile_message_pb);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryProfileRequest', null, global);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryTagRequest', null, global);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryTaskRequest', null, global);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryVideoResponse', null, global);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryWorkGroupRequest', null, global);
goog.exportSymbol('proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest', null, global);
goog.exportSymbol('proto.job_dictionary.DetachJobDictionaryTaskVideoRequest', null, global);
goog.exportSymbol('proto.job_dictionary.DetachJobDictionaryTaskVideoResponse', null, global);
goog.exportSymbol('proto.job_dictionary.EditJobDictionaryProfileRequest', null, global);
goog.exportSymbol('proto.job_dictionary.EditJobDictionaryTaskRequest', null, global);
goog.exportSymbol('proto.job_dictionary.EditLimitedJobDictionaryTaskRequest', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryItem', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryProfileRequest', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryProfileResponse', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryRequest', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryResponse', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryTag', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryTags', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryTagsEntity', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryTaskRequest', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryTaskResponse', null, global);
goog.exportSymbol('proto.job_dictionary.JobDictionaryWorkGroup', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryProfileRequest', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryTagsRequest', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryTagsResponse', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryTaskRequest', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryVideoRequest', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryVideoResponse', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest', null, global);
goog.exportSymbol('proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse', null, global);
goog.exportSymbol('proto.job_dictionary.SetTaskVideoRequest', null, global);
goog.exportSymbol('proto.job_dictionary.SetTaskVideoResponse', null, global);
goog.exportSymbol('proto.job_dictionary.SoterTaskVideoRequest', null, global);
goog.exportSymbol('proto.job_dictionary.SoterTaskVideoResponse', null, global);
goog.exportSymbol('proto.job_dictionary.SoterTaskVideosResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryItem.displayName = 'proto.job_dictionary.JobDictionaryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryTagRequest.displayName = 'proto.job_dictionary.CreateJobDictionaryTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryTag.displayName = 'proto.job_dictionary.JobDictionaryTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryTags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.JobDictionaryTags.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryTags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryTags.displayName = 'proto.job_dictionary.JobDictionaryTags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryWorkGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.displayName = 'proto.job_dictionary.CreateJobDictionaryWorkGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryWorkGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryWorkGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryWorkGroup.displayName = 'proto.job_dictionary.JobDictionaryWorkGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.JobDictionaryResponse.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryResponse.displayName = 'proto.job_dictionary.JobDictionaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryProfileResponse.displayName = 'proto.job_dictionary.JobDictionaryProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryTaskResponse.displayName = 'proto.job_dictionary.JobDictionaryTaskResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryRequest.displayName = 'proto.job_dictionary.JobDictionaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryProfileRequest.displayName = 'proto.job_dictionary.JobDictionaryProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.JobDictionaryTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.JobDictionaryTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.JobDictionaryTaskRequest.displayName = 'proto.job_dictionary.JobDictionaryTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryProfileRequest.displayName = 'proto.job_dictionary.CreateJobDictionaryProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.EditJobDictionaryProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.EditJobDictionaryProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.EditJobDictionaryProfileRequest.displayName = 'proto.job_dictionary.EditJobDictionaryProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryTaskRequest.displayName = 'proto.job_dictionary.CreateJobDictionaryTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.EditJobDictionaryTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.EditJobDictionaryTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.EditJobDictionaryTaskRequest.displayName = 'proto.job_dictionary.EditJobDictionaryTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.EditLimitedJobDictionaryTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.displayName = 'proto.job_dictionary.EditLimitedJobDictionaryTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryProfileRequest.displayName = 'proto.job_dictionary.RemoveJobDictionaryProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryTaskRequest.displayName = 'proto.job_dictionary.RemoveJobDictionaryTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.displayName = 'proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.CreateJobDictionaryVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.CreateJobDictionaryVideoResponse.displayName = 'proto.job_dictionary.CreateJobDictionaryVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.displayName = 'proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.displayName = 'proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryTagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryTagsRequest.displayName = 'proto.job_dictionary.RemoveJobDictionaryTagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryTagsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryTagsResponse.displayName = 'proto.job_dictionary.RemoveJobDictionaryTagsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryVideoRequest.displayName = 'proto.job_dictionary.RemoveJobDictionaryVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.RemoveJobDictionaryVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.RemoveJobDictionaryVideoResponse.displayName = 'proto.job_dictionary.RemoveJobDictionaryVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.DetachJobDictionaryTaskVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.displayName = 'proto.job_dictionary.DetachJobDictionaryTaskVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.DetachJobDictionaryTaskVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.displayName = 'proto.job_dictionary.DetachJobDictionaryTaskVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.SoterTaskVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.SoterTaskVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.SoterTaskVideoRequest.displayName = 'proto.job_dictionary.SoterTaskVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.SoterTaskVideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.SoterTaskVideosResponse.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.SoterTaskVideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.SoterTaskVideosResponse.displayName = 'proto.job_dictionary.SoterTaskVideosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.SoterTaskVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.SoterTaskVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.SoterTaskVideoResponse.displayName = 'proto.job_dictionary.SoterTaskVideoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.SetTaskVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.job_dictionary.SetTaskVideoRequest.repeatedFields_, null);
};
goog.inherits(proto.job_dictionary.SetTaskVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.SetTaskVideoRequest.displayName = 'proto.job_dictionary.SetTaskVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.job_dictionary.SetTaskVideoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.job_dictionary.SetTaskVideoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.job_dictionary.SetTaskVideoResponse.displayName = 'proto.job_dictionary.SetTaskVideoResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departmentTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    workgroupTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dotClassification: jspb.Message.getFieldWithDefault(msg, 5, 0),
    jobRoleId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    titleIsDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryItem}
 */
proto.job_dictionary.JobDictionaryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryItem;
  return proto.job_dictionary.JobDictionaryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryItem}
 */
proto.job_dictionary.JobDictionaryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkgroupTitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDotClassification(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobRoleId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitleIsDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartmentTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWorkgroupTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDotClassification();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getJobRoleId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTitleIsDeleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string department_title = 3;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getDepartmentTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setDepartmentTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string workgroup_title = 4;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getWorkgroupTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setWorkgroupTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 dot_classification = 5;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getDotClassification = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setDotClassification = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 job_role_id = 6;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getJobRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setJobRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool title_is_deleted = 7;
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryItem.prototype.getTitleIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.JobDictionaryItem} returns this
 */
proto.job_dictionary.JobDictionaryItem.prototype.setTitleIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryTagRequest}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryTagRequest;
  return proto.job_dictionary.CreateJobDictionaryTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryTagRequest}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {!proto.job_dictionary.JobDictionaryTagsEntity} */ (reader.readEnum());
      msg.setEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEntity();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTagRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTagRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional JobDictionaryTagsEntity entity = 3;
 * @return {!proto.job_dictionary.JobDictionaryTagsEntity}
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.getEntity = function() {
  return /** @type {!proto.job_dictionary.JobDictionaryTagsEntity} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.job_dictionary.JobDictionaryTagsEntity} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTagRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryTagRequest.prototype.setEntity = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryTag.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryTag}
 */
proto.job_dictionary.JobDictionaryTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryTag;
  return proto.job_dictionary.JobDictionaryTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryTag}
 */
proto.job_dictionary.JobDictionaryTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.job_dictionary.JobDictionaryTagsEntity} */ (reader.readEnum());
      msg.setEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntity();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTag} returns this
 */
proto.job_dictionary.JobDictionaryTag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryTag.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryTag} returns this
 */
proto.job_dictionary.JobDictionaryTag.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional JobDictionaryTagsEntity entity = 3;
 * @return {!proto.job_dictionary.JobDictionaryTagsEntity}
 */
proto.job_dictionary.JobDictionaryTag.prototype.getEntity = function() {
  return /** @type {!proto.job_dictionary.JobDictionaryTagsEntity} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.job_dictionary.JobDictionaryTagsEntity} value
 * @return {!proto.job_dictionary.JobDictionaryTag} returns this
 */
proto.job_dictionary.JobDictionaryTag.prototype.setEntity = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.JobDictionaryTags.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryTags.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryTags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryTags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTags.toObject = function(includeInstance, msg) {
  var f, obj = {
    toolsList: jspb.Message.toObjectList(msg.getToolsList(),
    proto.job_dictionary.JobDictionaryTag.toObject, includeInstance),
    preRequirementsList: jspb.Message.toObjectList(msg.getPreRequirementsList(),
    proto.job_dictionary.JobDictionaryTag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryTags}
 */
proto.job_dictionary.JobDictionaryTags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryTags;
  return proto.job_dictionary.JobDictionaryTags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryTags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryTags}
 */
proto.job_dictionary.JobDictionaryTags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.job_dictionary.JobDictionaryTag;
      reader.readMessage(value,proto.job_dictionary.JobDictionaryTag.deserializeBinaryFromReader);
      msg.addTools(value);
      break;
    case 2:
      var value = new proto.job_dictionary.JobDictionaryTag;
      reader.readMessage(value,proto.job_dictionary.JobDictionaryTag.deserializeBinaryFromReader);
      msg.addPreRequirements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryTags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryTags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToolsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.job_dictionary.JobDictionaryTag.serializeBinaryToWriter
    );
  }
  f = message.getPreRequirementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.job_dictionary.JobDictionaryTag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated JobDictionaryTag tools = 1;
 * @return {!Array<!proto.job_dictionary.JobDictionaryTag>}
 */
proto.job_dictionary.JobDictionaryTags.prototype.getToolsList = function() {
  return /** @type{!Array<!proto.job_dictionary.JobDictionaryTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.JobDictionaryTag, 1));
};


/**
 * @param {!Array<!proto.job_dictionary.JobDictionaryTag>} value
 * @return {!proto.job_dictionary.JobDictionaryTags} returns this
*/
proto.job_dictionary.JobDictionaryTags.prototype.setToolsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.JobDictionaryTag}
 */
proto.job_dictionary.JobDictionaryTags.prototype.addTools = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.job_dictionary.JobDictionaryTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryTags} returns this
 */
proto.job_dictionary.JobDictionaryTags.prototype.clearToolsList = function() {
  return this.setToolsList([]);
};


/**
 * repeated JobDictionaryTag pre_requirements = 2;
 * @return {!Array<!proto.job_dictionary.JobDictionaryTag>}
 */
proto.job_dictionary.JobDictionaryTags.prototype.getPreRequirementsList = function() {
  return /** @type{!Array<!proto.job_dictionary.JobDictionaryTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.JobDictionaryTag, 2));
};


/**
 * @param {!Array<!proto.job_dictionary.JobDictionaryTag>} value
 * @return {!proto.job_dictionary.JobDictionaryTags} returns this
*/
proto.job_dictionary.JobDictionaryTags.prototype.setPreRequirementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.JobDictionaryTag}
 */
proto.job_dictionary.JobDictionaryTags.prototype.addPreRequirements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.job_dictionary.JobDictionaryTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryTags} returns this
 */
proto.job_dictionary.JobDictionaryTags.prototype.clearPreRequirementsList = function() {
  return this.setPreRequirementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest}
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryWorkGroupRequest;
  return proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest}
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryWorkGroupRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryWorkGroupRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryWorkGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryWorkGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryWorkGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryWorkGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryWorkGroup}
 */
proto.job_dictionary.JobDictionaryWorkGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryWorkGroup;
  return proto.job_dictionary.JobDictionaryWorkGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryWorkGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryWorkGroup}
 */
proto.job_dictionary.JobDictionaryWorkGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryWorkGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryWorkGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryWorkGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryWorkGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryWorkGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryWorkGroup} returns this
 */
proto.job_dictionary.JobDictionaryWorkGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.JobDictionaryWorkGroup.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.JobDictionaryWorkGroup} returns this
 */
proto.job_dictionary.JobDictionaryWorkGroup.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.JobDictionaryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobRolesListList: jspb.Message.toObjectList(msg.getJobRolesListList(),
    proto.job_dictionary.JobDictionaryItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryResponse}
 */
proto.job_dictionary.JobDictionaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryResponse;
  return proto.job_dictionary.JobDictionaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryResponse}
 */
proto.job_dictionary.JobDictionaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.job_dictionary.JobDictionaryItem;
      reader.readMessage(value,proto.job_dictionary.JobDictionaryItem.deserializeBinaryFromReader);
      msg.addJobRolesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobRolesListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.job_dictionary.JobDictionaryItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryResponse} returns this
 */
proto.job_dictionary.JobDictionaryResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated JobDictionaryItem job_roles_list = 2;
 * @return {!Array<!proto.job_dictionary.JobDictionaryItem>}
 */
proto.job_dictionary.JobDictionaryResponse.prototype.getJobRolesListList = function() {
  return /** @type{!Array<!proto.job_dictionary.JobDictionaryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.JobDictionaryItem, 2));
};


/**
 * @param {!Array<!proto.job_dictionary.JobDictionaryItem>} value
 * @return {!proto.job_dictionary.JobDictionaryResponse} returns this
*/
proto.job_dictionary.JobDictionaryResponse.prototype.setJobRolesListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.job_dictionary.JobDictionaryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.JobDictionaryItem}
 */
proto.job_dictionary.JobDictionaryResponse.prototype.addJobRolesList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.job_dictionary.JobDictionaryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.JobDictionaryResponse} returns this
 */
proto.job_dictionary.JobDictionaryResponse.prototype.clearJobRolesListList = function() {
  return this.setJobRolesListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobProfile: (f = msg.getJobProfile()) && api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryProfileResponse}
 */
proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryProfileResponse;
  return proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryProfileResponse}
 */
proto.job_dictionary.JobDictionaryProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile;
      reader.readMessage(value,api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile.deserializeBinaryFromReader);
      msg.setJobProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobDictionaryProfile job_profile = 1;
 * @return {?proto.job_dictionary.JobDictionaryProfile}
 */
proto.job_dictionary.JobDictionaryProfileResponse.prototype.getJobProfile = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryProfile} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile, 1));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryProfile|undefined} value
 * @return {!proto.job_dictionary.JobDictionaryProfileResponse} returns this
*/
proto.job_dictionary.JobDictionaryProfileResponse.prototype.setJobProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.JobDictionaryProfileResponse} returns this
 */
proto.job_dictionary.JobDictionaryProfileResponse.prototype.clearJobProfile = function() {
  return this.setJobProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryProfileResponse.prototype.hasJobProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskProfile: (f = msg.getTaskProfile()) && api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryTaskResponse}
 */
proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryTaskResponse;
  return proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryTaskResponse}
 */
proto.job_dictionary.JobDictionaryTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask;
      reader.readMessage(value,api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask.deserializeBinaryFromReader);
      msg.setTaskProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobDictionaryTask task_profile = 1;
 * @return {?proto.job_dictionary.JobDictionaryTask}
 */
proto.job_dictionary.JobDictionaryTaskResponse.prototype.getTaskProfile = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryTask} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask, 1));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryTask|undefined} value
 * @return {!proto.job_dictionary.JobDictionaryTaskResponse} returns this
*/
proto.job_dictionary.JobDictionaryTaskResponse.prototype.setTaskProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.JobDictionaryTaskResponse} returns this
 */
proto.job_dictionary.JobDictionaryTaskResponse.prototype.clearTaskProfile = function() {
  return this.setTaskProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.JobDictionaryTaskResponse.prototype.hasTaskProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryRequest}
 */
proto.job_dictionary.JobDictionaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryRequest;
  return proto.job_dictionary.JobDictionaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryRequest}
 */
proto.job_dictionary.JobDictionaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryRequest} returns this
 */
proto.job_dictionary.JobDictionaryRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobProfileId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryProfileRequest}
 */
proto.job_dictionary.JobDictionaryProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryProfileRequest;
  return proto.job_dictionary.JobDictionaryProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryProfileRequest}
 */
proto.job_dictionary.JobDictionaryProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobProfileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobProfileId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 job_profile_id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryProfileRequest.prototype.getJobProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryProfileRequest} returns this
 */
proto.job_dictionary.JobDictionaryProfileRequest.prototype.setJobProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.JobDictionaryTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.JobDictionaryTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.JobDictionaryTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.JobDictionaryTaskRequest}
 */
proto.job_dictionary.JobDictionaryTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.JobDictionaryTaskRequest;
  return proto.job_dictionary.JobDictionaryTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.JobDictionaryTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.JobDictionaryTaskRequest}
 */
proto.job_dictionary.JobDictionaryTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.JobDictionaryTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.JobDictionaryTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.JobDictionaryTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.JobDictionaryTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 task_id = 1;
 * @return {number}
 */
proto.job_dictionary.JobDictionaryTaskRequest.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.JobDictionaryTaskRequest} returns this
 */
proto.job_dictionary.JobDictionaryTaskRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobProfile: (f = msg.getJobProfile()) && api_job_dictionary_v1_job_profile_message_pb.CreateJobDictionaryProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryProfileRequest}
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryProfileRequest;
  return proto.job_dictionary.CreateJobDictionaryProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryProfileRequest}
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_profile_message_pb.CreateJobDictionaryProfile;
      reader.readMessage(value,api_job_dictionary_v1_job_profile_message_pb.CreateJobDictionaryProfile.deserializeBinaryFromReader);
      msg.setJobProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_profile_message_pb.CreateJobDictionaryProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateJobDictionaryProfile job_profile = 1;
 * @return {?proto.job_dictionary.CreateJobDictionaryProfile}
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.prototype.getJobProfile = function() {
  return /** @type{?proto.job_dictionary.CreateJobDictionaryProfile} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_profile_message_pb.CreateJobDictionaryProfile, 1));
};


/**
 * @param {?proto.job_dictionary.CreateJobDictionaryProfile|undefined} value
 * @return {!proto.job_dictionary.CreateJobDictionaryProfileRequest} returns this
*/
proto.job_dictionary.CreateJobDictionaryProfileRequest.prototype.setJobProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.CreateJobDictionaryProfileRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.prototype.clearJobProfile = function() {
  return this.setJobProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.CreateJobDictionaryProfileRequest.prototype.hasJobProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.EditJobDictionaryProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.EditJobDictionaryProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobProfile: (f = msg.getJobProfile()) && api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.EditJobDictionaryProfileRequest}
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.EditJobDictionaryProfileRequest;
  return proto.job_dictionary.EditJobDictionaryProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.EditJobDictionaryProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.EditJobDictionaryProfileRequest}
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile;
      reader.readMessage(value,api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile.deserializeBinaryFromReader);
      msg.setJobProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.EditJobDictionaryProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.EditJobDictionaryProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobDictionaryProfile job_profile = 1;
 * @return {?proto.job_dictionary.JobDictionaryProfile}
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.prototype.getJobProfile = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryProfile} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_profile_message_pb.JobDictionaryProfile, 1));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryProfile|undefined} value
 * @return {!proto.job_dictionary.EditJobDictionaryProfileRequest} returns this
*/
proto.job_dictionary.EditJobDictionaryProfileRequest.prototype.setJobProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.EditJobDictionaryProfileRequest} returns this
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.prototype.clearJobProfile = function() {
  return this.setJobProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.EditJobDictionaryProfileRequest.prototype.hasJobProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskProfile: (f = msg.getTaskProfile()) && api_job_dictionary_v1_job_task_profile_message_pb.CreateJobDictionaryTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryTaskRequest}
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryTaskRequest;
  return proto.job_dictionary.CreateJobDictionaryTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryTaskRequest}
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_task_profile_message_pb.CreateJobDictionaryTask;
      reader.readMessage(value,api_job_dictionary_v1_job_task_profile_message_pb.CreateJobDictionaryTask.deserializeBinaryFromReader);
      msg.setTaskProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_task_profile_message_pb.CreateJobDictionaryTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateJobDictionaryTask task_profile = 1;
 * @return {?proto.job_dictionary.CreateJobDictionaryTask}
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.prototype.getTaskProfile = function() {
  return /** @type{?proto.job_dictionary.CreateJobDictionaryTask} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_task_profile_message_pb.CreateJobDictionaryTask, 1));
};


/**
 * @param {?proto.job_dictionary.CreateJobDictionaryTask|undefined} value
 * @return {!proto.job_dictionary.CreateJobDictionaryTaskRequest} returns this
*/
proto.job_dictionary.CreateJobDictionaryTaskRequest.prototype.setTaskProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.CreateJobDictionaryTaskRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.prototype.clearTaskProfile = function() {
  return this.setTaskProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.CreateJobDictionaryTaskRequest.prototype.hasTaskProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.EditJobDictionaryTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.EditJobDictionaryTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskProfile: (f = msg.getTaskProfile()) && api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.EditJobDictionaryTaskRequest}
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.EditJobDictionaryTaskRequest;
  return proto.job_dictionary.EditJobDictionaryTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.EditJobDictionaryTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.EditJobDictionaryTaskRequest}
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask;
      reader.readMessage(value,api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask.deserializeBinaryFromReader);
      msg.setTaskProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.EditJobDictionaryTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.EditJobDictionaryTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobDictionaryTask task_profile = 1;
 * @return {?proto.job_dictionary.JobDictionaryTask}
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.prototype.getTaskProfile = function() {
  return /** @type{?proto.job_dictionary.JobDictionaryTask} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_task_profile_message_pb.JobDictionaryTask, 1));
};


/**
 * @param {?proto.job_dictionary.JobDictionaryTask|undefined} value
 * @return {!proto.job_dictionary.EditJobDictionaryTaskRequest} returns this
*/
proto.job_dictionary.EditJobDictionaryTaskRequest.prototype.setTaskProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.EditJobDictionaryTaskRequest} returns this
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.prototype.clearTaskProfile = function() {
  return this.setTaskProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.EditJobDictionaryTaskRequest.prototype.hasTaskProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskProfile: (f = msg.getTaskProfile()) && api_job_dictionary_v1_job_task_profile_message_pb.EditLimitedJobDictionaryTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest}
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.EditLimitedJobDictionaryTaskRequest;
  return proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest}
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_job_dictionary_v1_job_task_profile_message_pb.EditLimitedJobDictionaryTask;
      reader.readMessage(value,api_job_dictionary_v1_job_task_profile_message_pb.EditLimitedJobDictionaryTask.deserializeBinaryFromReader);
      msg.setTaskProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_job_dictionary_v1_job_task_profile_message_pb.EditLimitedJobDictionaryTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditLimitedJobDictionaryTask task_profile = 1;
 * @return {?proto.job_dictionary.EditLimitedJobDictionaryTask}
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.prototype.getTaskProfile = function() {
  return /** @type{?proto.job_dictionary.EditLimitedJobDictionaryTask} */ (
    jspb.Message.getWrapperField(this, api_job_dictionary_v1_job_task_profile_message_pb.EditLimitedJobDictionaryTask, 1));
};


/**
 * @param {?proto.job_dictionary.EditLimitedJobDictionaryTask|undefined} value
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} returns this
*/
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.prototype.setTaskProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.job_dictionary.EditLimitedJobDictionaryTaskRequest} returns this
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.prototype.clearTaskProfile = function() {
  return this.setTaskProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.job_dictionary.EditLimitedJobDictionaryTaskRequest.prototype.hasTaskProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryProfileRequest}
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryProfileRequest;
  return proto.job_dictionary.RemoveJobDictionaryProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryProfileRequest}
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryProfileRequest} returns this
 */
proto.job_dictionary.RemoveJobDictionaryProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryTaskRequest}
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryTaskRequest;
  return proto.job_dictionary.RemoveJobDictionaryTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryTaskRequest}
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryTaskRequest} returns this
 */
proto.job_dictionary.RemoveJobDictionaryTaskRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    youtubeUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest;
  return proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubeUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYoutubeUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 task_id = 1;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string youtube_url = 2;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.getYoutubeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.setYoutubeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest} returns this
 */
proto.job_dictionary.CreateJobDictionaryYoutubeVideoRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.CreateJobDictionaryVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.CreateJobDictionaryVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    youtubeUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.CreateJobDictionaryVideoResponse;
  return proto.job_dictionary.CreateJobDictionaryVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.CreateJobDictionaryVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubeUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.CreateJobDictionaryVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.CreateJobDictionaryVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getYoutubeUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse} returns this
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 task_id = 2;
 * @return {number}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse} returns this
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string youtube_url = 3;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.getYoutubeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse} returns this
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.setYoutubeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse} returns this
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.CreateJobDictionaryVideoResponse} returns this
 */
proto.job_dictionary.CreateJobDictionaryVideoResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest;
  return proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest} returns this
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse;
  return proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse} returns this
 */
proto.job_dictionary.RemoveJobDictionaryWorkGroupResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryTagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryTagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryTagsRequest}
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryTagsRequest;
  return proto.job_dictionary.RemoveJobDictionaryTagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryTagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryTagsRequest}
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryTagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryTagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryTagsRequest} returns this
 */
proto.job_dictionary.RemoveJobDictionaryTagsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryTagsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryTagsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryTagsResponse}
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryTagsResponse;
  return proto.job_dictionary.RemoveJobDictionaryTagsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryTagsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryTagsResponse}
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryTagsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryTagsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryTagsResponse} returns this
 */
proto.job_dictionary.RemoveJobDictionaryTagsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryVideoRequest}
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryVideoRequest;
  return proto.job_dictionary.RemoveJobDictionaryVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryVideoRequest}
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryVideoRequest} returns this
 */
proto.job_dictionary.RemoveJobDictionaryVideoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.RemoveJobDictionaryVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.RemoveJobDictionaryVideoResponse}
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.RemoveJobDictionaryVideoResponse;
  return proto.job_dictionary.RemoveJobDictionaryVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.RemoveJobDictionaryVideoResponse}
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.RemoveJobDictionaryVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.RemoveJobDictionaryVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.RemoveJobDictionaryVideoResponse} returns this
 */
proto.job_dictionary.RemoveJobDictionaryVideoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobDictionaryTaskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.DetachJobDictionaryTaskVideoRequest;
  return proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobDictionaryTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobDictionaryTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 job_dictionary_task_id = 1;
 * @return {number}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.prototype.getJobDictionaryTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} returns this
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.prototype.setJobDictionaryTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 task_video_id = 2;
 * @return {number}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoRequest} returns this
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoRequest.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobDictionaryTaskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskVideoId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.DetachJobDictionaryTaskVideoResponse;
  return proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobDictionaryTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskVideoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobDictionaryTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTaskVideoId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 job_dictionary_task_id = 1;
 * @return {number}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.prototype.getJobDictionaryTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse} returns this
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.prototype.setJobDictionaryTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 task_video_id = 2;
 * @return {number}
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.prototype.getTaskVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.DetachJobDictionaryTaskVideoResponse} returns this
 */
proto.job_dictionary.DetachJobDictionaryTaskVideoResponse.prototype.setTaskVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.SoterTaskVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.SoterTaskVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.SoterTaskVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SoterTaskVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.SoterTaskVideoRequest}
 */
proto.job_dictionary.SoterTaskVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.SoterTaskVideoRequest;
  return proto.job_dictionary.SoterTaskVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.SoterTaskVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.SoterTaskVideoRequest}
 */
proto.job_dictionary.SoterTaskVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.SoterTaskVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.SoterTaskVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.SoterTaskVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SoterTaskVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.job_dictionary.SoterTaskVideoRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.SoterTaskVideoRequest} returns this
 */
proto.job_dictionary.SoterTaskVideoRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.SoterTaskVideosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.SoterTaskVideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.SoterTaskVideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.SoterTaskVideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SoterTaskVideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    proto.job_dictionary.SoterTaskVideoResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.SoterTaskVideosResponse}
 */
proto.job_dictionary.SoterTaskVideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.SoterTaskVideosResponse;
  return proto.job_dictionary.SoterTaskVideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.SoterTaskVideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.SoterTaskVideosResponse}
 */
proto.job_dictionary.SoterTaskVideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.job_dictionary.SoterTaskVideoResponse;
      reader.readMessage(value,proto.job_dictionary.SoterTaskVideoResponse.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.SoterTaskVideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.SoterTaskVideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.SoterTaskVideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SoterTaskVideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.job_dictionary.SoterTaskVideoResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SoterTaskVideoResponse videos = 1;
 * @return {!Array<!proto.job_dictionary.SoterTaskVideoResponse>}
 */
proto.job_dictionary.SoterTaskVideosResponse.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.job_dictionary.SoterTaskVideoResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.job_dictionary.SoterTaskVideoResponse, 1));
};


/**
 * @param {!Array<!proto.job_dictionary.SoterTaskVideoResponse>} value
 * @return {!proto.job_dictionary.SoterTaskVideosResponse} returns this
*/
proto.job_dictionary.SoterTaskVideosResponse.prototype.setVideosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.job_dictionary.SoterTaskVideoResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.SoterTaskVideoResponse}
 */
proto.job_dictionary.SoterTaskVideosResponse.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.job_dictionary.SoterTaskVideoResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.SoterTaskVideosResponse} returns this
 */
proto.job_dictionary.SoterTaskVideosResponse.prototype.clearVideosList = function() {
  return this.setVideosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.SoterTaskVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.SoterTaskVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SoterTaskVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.SoterTaskVideoResponse}
 */
proto.job_dictionary.SoterTaskVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.SoterTaskVideoResponse;
  return proto.job_dictionary.SoterTaskVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.SoterTaskVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.SoterTaskVideoResponse}
 */
proto.job_dictionary.SoterTaskVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.SoterTaskVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.SoterTaskVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SoterTaskVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.SoterTaskVideoResponse} returns this
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.job_dictionary.SoterTaskVideoResponse} returns this
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.SoterTaskVideoResponse} returns this
 */
proto.job_dictionary.SoterTaskVideoResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.job_dictionary.SetTaskVideoRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.SetTaskVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.SetTaskVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SetTaskVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    soterTaskVideoIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.SetTaskVideoRequest}
 */
proto.job_dictionary.SetTaskVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.SetTaskVideoRequest;
  return proto.job_dictionary.SetTaskVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.SetTaskVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.SetTaskVideoRequest}
 */
proto.job_dictionary.SetTaskVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSoterTaskVideoId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.SetTaskVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.SetTaskVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SetTaskVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSoterTaskVideoIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 task_id = 1;
 * @return {number}
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.job_dictionary.SetTaskVideoRequest} returns this
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 soter_task_video_id = 2;
 * @return {!Array<number>}
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.getSoterTaskVideoIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.job_dictionary.SetTaskVideoRequest} returns this
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.setSoterTaskVideoIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.job_dictionary.SetTaskVideoRequest} returns this
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.addSoterTaskVideoId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.job_dictionary.SetTaskVideoRequest} returns this
 */
proto.job_dictionary.SetTaskVideoRequest.prototype.clearSoterTaskVideoIdList = function() {
  return this.setSoterTaskVideoIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.job_dictionary.SetTaskVideoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.job_dictionary.SetTaskVideoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.job_dictionary.SetTaskVideoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SetTaskVideoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.job_dictionary.SetTaskVideoResponse}
 */
proto.job_dictionary.SetTaskVideoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.job_dictionary.SetTaskVideoResponse;
  return proto.job_dictionary.SetTaskVideoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.job_dictionary.SetTaskVideoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.job_dictionary.SetTaskVideoResponse}
 */
proto.job_dictionary.SetTaskVideoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.job_dictionary.SetTaskVideoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.job_dictionary.SetTaskVideoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.job_dictionary.SetTaskVideoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.job_dictionary.SetTaskVideoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.job_dictionary.SetTaskVideoResponse.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.job_dictionary.SetTaskVideoResponse} returns this
 */
proto.job_dictionary.SetTaskVideoResponse.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.job_dictionary.JobDictionaryTagsEntity = {
  TOOLS: 0,
  PREREQUIREMENTS: 1
};

goog.object.extend(exports, proto.job_dictionary);
