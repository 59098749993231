import React, { useState, useEffect } from 'react';
import * as Emotion from 'emotion';
import { ReactComponent as UploadIcon } from 'assets/img/icons/file-upload.svg';
import { ReactComponent as CrossIcon } from 'assets/img/icons/cross.svg';
import { ReactComponent as NewWindowIcon } from 'assets/img/icons/new-window.svg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../constants/colors';
import { uploadUserProfileFile, deleteUserProfileFile } from './profileStore/actions';
import sliceLongString from '../lib/utils/sliceLongString';
const uploaderTile = (withFile) => Emotion.css `
  width: 200px;
  height: 150px;
  border: 1px solid ${COLORS.GRAY[200]};
  border-radius: 5px;
  box-shadow: 4px 4px ${COLORS.GRAY[50]};
  cursor: pointer;
  color: ${withFile ? COLORS.BLUE[500] : COLORS.GRAY[500]};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 4px;
  background-color: ${COLORS.WHITE};
  svg {
    fill: ${withFile ? COLORS.BLUE[400] : COLORS.GRAY[400]};
  }
  p {
    text-align: center;
  }
  &:hover {
    box-shadow: 5px 5px ${COLORS.GRAY[100]};
  }
`;
const crossButton = Emotion.css `
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  border-radius: 50px;
  &:hover {
    background-color: ${COLORS.RED[100]};
  }
`;
export default function UserFileUploader({ file, fileConfig, fileName, fileId, }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { title, required, type } = fileConfig;
    const emptyFile = { path: '' };
    const [newFile, setNewFile] = useState(emptyFile);
    const inputId = `file_upload_${type}`;
    useEffect(() => {
        if (!newFile.path)
            return;
        dispatch(uploadUserProfileFile(newFile, fileConfig.type, t));
    }, [newFile]);
    const removeFileHandler = () => {
        if (fileId) {
            dispatch(deleteUserProfileFile(fileId, t));
        }
        setNewFile(emptyFile);
    };
    return (React.createElement("div", { style: { position: 'relative', width: '200px' } },
        (newFile.path || file) && (React.createElement(CrossIcon, { className: crossButton, onClick: removeFileHandler })),
        React.createElement("label", { htmlFor: inputId, className: uploaderTile(file || newFile.path) },
            React.createElement(UploadIcon, null),
            React.createElement("p", null, `${t('SOTER_GENIUS.UPLOAD')} ${t(title)}${required ? '' : ` (${t('SOTER_GENIUS.OPTIONAL').toLowerCase()})`}`),
            React.createElement("input", { value: newFile.path, onChange: ({ target }) => setNewFile({ path: target.value, file: target.files && target.files[0] }), id: inputId, type: "file", style: { display: 'none' }, accept: ".pdf, .doc, .docx" })),
        file && (React.createElement("a", { href: file, target: "_blank", rel: "noreferrer" },
            React.createElement("div", { style: {
                    display: 'flex', justifyContent: 'center', marginTop: '7px', color: COLORS.BLUE[400],
                } },
                React.createElement(NewWindowIcon, { style: { display: 'inline', marginRight: '4px' } }),
                React.createElement("p", null, sliceLongString(fileName, 23)))))));
}
