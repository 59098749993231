import React from 'react';
import * as Emotion from 'emotion';
import { COLORS } from '../../constants/colors';
const { css } = Emotion;
const radioButton = css `
  background-color: ${COLORS.BLUE[500]};
  margin-right: 6px;
  margin-bottom: 18px;
`;
const labelStyle = css `
  margin-right: 20px;
  line-break: strict;
  font-size: 14px;
  
  display: inline;
`;
export default function RadioButton({ label, name, onChange, value, selectedValue, id, }) {
    return (React.createElement("label", { className: labelStyle, htmlFor: value },
        React.createElement("input", { id: id, value: value, type: "radio", checked: selectedValue === value, onChange: onChange, className: radioButton, name: name }),
        label));
}
