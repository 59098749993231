import React from 'react';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import human from 'assets/img/human-body.svg';
import { useTranslation } from 'react-i18next';
import { absoluteColoredLabel, absoluteHorizontalLine } from '../../assets/jss/style';
import {
  TASK_BODY_COMPARISON_PARTS_SELECTOR_CHANGED,
  TASK_BODY_PARTS_SELECTOR_CHANGED,
} from '../../constants/analyticsEvents';
import { useAppDispatch } from '../../lib/utils/useStore';
import { riskGroupColors, riskGroupNames } from '../video/constants';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';

const labelCoordinates = [
  // first in array y, second x
  [125, 217, true], // back
  [36, 217], // neck
  [64, 177], // left arm
  [64, 257, true], // right arm
  [230, 187], // left leg
  [230, 248, true], // right leg
];

const circle = (top, left, color, isSelected, forReport) => Emotion.css`
  position: absolute;
  top: ${top}px;
  left: ${left}px;
  background-color: ${isSelected || forReport ? color : 'transparent'};
  color: ${isSelected || forReport ? COLORS.WHITE : color};
  border: 1px solid ${color};
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RiskScoreSelectableCircle = ({
  bodyPart,
  number,
  isSelected,
  setSelected,
  riskGroup,
  coordinates,
  forReport,
  isComparison,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <div>
      {isSelected && (
        <>
          <hr
            style={{
              width: '75px',
              left: coordinates[2] ? coordinates[1] + 35 : coordinates[1] - 80,
            }}
            className={absoluteHorizontalLine(
              coordinates[0] - 5,
              coordinates[1] - 700,
              coordinates[2],
            )}
          />
          <div
            style={{
              minWidth: '60px',
              left: coordinates[2] ? coordinates[1] + 75 : coordinates[1] - 95,
            }}
            className={absoluteColoredLabel(
              coordinates[0] - 25,
              coordinates[1] - 170,
              riskGroupColors[riskGroup],
              coordinates[2],
              false,
              true,
            )}
          >
            {`${t(riskGroupNames[riskGroup])} ${t(
              'SOTER_TASK.ASSESSMENT.RISK',
            ).toLowerCase()}`}
          </div>
        </>
      )}
      {forReport && (
        <div
          style={{
            paddingTop: bodyPart === 'neck_angle' && '-45px !important',
          }}
        >
          <hr
            style={{
              width: '75px',
              left: coordinates[2] ? coordinates[1] + 35 : coordinates[1] - 80,
            }}
            className={absoluteHorizontalLine(
              bodyPart === 'neck_angle'
                ? coordinates[0] - 20
                : coordinates[0] - 5,
              coordinates[1] - 700,
              coordinates[2],
            )}
          />
          <div
            style={{
              minWidth: '60px',
              left: coordinates[2] ? coordinates[1] + 45 : coordinates[1] - 95,
            }}
            className={absoluteColoredLabel(
              bodyPart === 'neck_angle'
                ? coordinates[0] - 40
                : coordinates[0] - 25,
              coordinates[1] - 170,
              riskGroupColors[riskGroup],
              coordinates[2],
              false,
              true,
            )}
          >
            {`${t(riskGroupNames[riskGroup])} ${t(
              'SOTER_TASK.ASSESSMENT.RISK',
            ).toLowerCase()}`}
          </div>
        </div>
      )}
      <div
        className={circle(
          coordinates[0],
          coordinates[1],
          riskGroupColors[riskGroup],
          isSelected,
          forReport,
        )}
        onClick={() => {
          setSelected(bodyPart);
          if (isComparison) {
            const params = {
              body_part: bodyPart,
            };
            window.logEvent(TASK_BODY_PARTS_SELECTOR_CHANGED, params);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(`${TASK_BODY_PARTS_SELECTOR_CHANGED}: ${JSON.stringify(params)}`));
            }
          } else {
            const params = {
              body_part: bodyPart,
            };
            window.logEvent(TASK_BODY_COMPARISON_PARTS_SELECTOR_CHANGED, params);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(`${TASK_BODY_COMPARISON_PARTS_SELECTOR_CHANGED}: ${JSON.stringify(analyticsParams)}`));
            }
          }
        }}
      >
        {number}
      </div>
    </div>
  );
};

const BodyPartsRiskScore = ({
  risks = {},
  selectedBodyPart,
  selectBodyPart,
  forReport = false,
  isComparison,
}) => (
  <div
    style={{
      height: '333px',
      gridArea: 'body',
      width: '420px',
      position: 'relative',
      marginLeft: '-80px',
      marginTop: '35px',
    }}
  >
    {risks &&
      Object.keys(risks)
        .filter((bodyPart) => risks[bodyPart])
        .map((bodyPart, index) => (
          <div key={bodyPart}>
            <RiskScoreSelectableCircle
              bodyPart={bodyPart}
              number={`0${index + 1}`}
              isSelected={selectedBodyPart === bodyPart}
              setSelected={selectBodyPart}
              riskGroup={risks[bodyPart]}
              coordinates={labelCoordinates[index]}
              forReport={forReport}
              isComparison={isComparison}
            />
          </div>
        ))}
    <img
      style={{ marginLeft: '160px', paddingTop: '12px' }}
      src={human}
      alt="."
    />
  </div>
);

export default BodyPartsRiskScore;
