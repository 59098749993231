export const prepareSeparatedTitle = (title, t) => {
    if (!title)
        return '';
    const titleMappings = {
        site: t('WEARABLES.OVERVIEW.SITE'),
        department: t('WEARABLES.OVERVIEW.DEPARTMENT'),
        task_title: t('GENERAL.TASK_TITLE'),
        user_job_role: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
    };
    const translatedTitle = titleMappings[title];
    return translatedTitle || title.replace(/(?:^|_)(.)/g, (match, char) => ' ' + char.toUpperCase()).trim();
};
