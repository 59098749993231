import React, { useEffect, useRef, useState } from 'react';
import * as Emotion from 'emotion';
import _pull from 'lodash/pull';
import _difference from 'lodash/difference';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { cardMediumWithHeight, mb5 } from '../../assets/soter-flex-styles';
import { COLORS } from '../../constants/colors';
import cross from '../../assets/img/icons/closeModal.svg';
import hexToRgba from '../../lib/utils/hexToRgba';
import Tooltip from '../../lib/ui/Tooltip';
import sliceLongString from '../../lib/utils/sliceLongString';
import useOutsideClickHandler from '../../lib/utils/useOutsideClickHandler';
import { fuzzySearch } from '../../lib/utils/fuzzySearch';
import InfoCard from '../../lib/ui/InfoCard';
import CardEditingControl from '../../lib/ui/CardEditingControl';
import CustomInput from '../../lib/ui/CustomInput';
import Button from '../../lib/ui/Button';

export const tagsMenu = Emotion.css`
  position: absolute;
  top: 38px;
  padding: 0;
  border: 1px solid ${COLORS.GRAY[100]};
  width: 87%;
  background-color: ${COLORS.WHITE};
`;

const tagsMenuItem = Emotion.css`
  padding: 3px 10px;
  &:hover {
    cursor: pointer;
    background-color: ${hexToRgba(COLORS.LIGHT_BLUE[600], 0.4)};
  }
`;

const ToolItem = ({ name, isEditing, handleRemove }) => (
  <Tooltip text={name}>
    <div
      key={name}
      style={{
        display: 'inline-block',
        padding: '3px 5px',
        marginRight: '7px',
        marginBottom: '10px',
        backgroundColor: COLORS.GRAY[100],
        borderRadius: '3px',
      }}
    >
      {sliceLongString(name, 40)}
      {isEditing && (
        <img
          style={{ marginLeft: '8px', width: '8px', height: '8px' }}
          alt="x"
          src={cross}
          onClick={handleRemove}
        />
      )}
    </div>
  </Tooltip>
);

const EditableDictionary = ({
  title,
  items,
  saveHandler,
  tags = [],
  addTagHandler,
  setItemsHandler,
  cancelHandler,
}) => {
  const ref = useRef();
  const MENU_LENGTH = 5;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [tagsForMenu, setTagsForMenu] = useState([]);
  useOutsideClickHandler(ref, () => setIsMenuOpen(false));
  useEffect(() => {
    const newTags = _sortBy(
      tags.filter((t) => !items?.includes(t.id)),
      ['title'],
    );
    if (inputValue) {
      setTagsForMenu(
        newTags
          .filter((t) => fuzzySearch(t.title, inputValue))
          .slice(0, MENU_LENGTH),
      );
    } else {
      setTagsForMenu(newTags.slice(0, MENU_LENGTH));
    }
  }, [tags, inputValue]);
  useEffect(() => {
    setCurrentItems(
      _sortBy(
        tags.filter((t) => items?.includes(t.id)),
        ['title'],
      ),
    );
  }, [tags, items]);
  useEffect(() => {
    if (
      _difference(
        currentItems.map((i) => i.id),
        items,
      ).length > 0
    ) setItemsHandler(currentItems.map((i) => i.id));
  }, [currentItems]);
  const addItemHandler = () => {
    if (!selectedId) {
      addTagHandler(inputValue);
    } else {
      setCurrentItems(
        _sortBy(
          [...currentItems, tags.find((t) => t.id === selectedId)],
          ['title'],
        ),
      );
    }
    setInputValue('');
    setSelectedId(null);
    setIsMenuOpen(false);
  };
  const clearInputHandler = () => {
    setInputValue('');
    setSelectedId(null);
  };
  const { t } = useTranslation();
  return (
    <InfoCard
      title={title}
      cardClass={`${cardMediumWithHeight(399)} ${mb5}`}
      rightCornerControl={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CardEditingControl
            editButtonHandler={() => setIsEditing(!isEditing)}
            saveButtonHandler={() => {
              saveHandler(currentItems.map((i) => i.id));
              setIsEditing(false);
            }}
            editMode={isEditing}
            cancelButtonHandler={() => {
              setIsEditing(false);
              cancelHandler();
            }}
          />
        </div>
      )}
    >
      {isEditing && (
        <div
          style={{
            display: 'flex',
            height: '38px',
            marginBottom: '10px',
            position: 'relative',
          }}
        >
          <CustomInput
            style={{ borderRadius: '3px 0 0 3px' }}
            onFocus={() => setIsMenuOpen(true)}
            value={inputValue}
            onChange={(_, v) => setInputValue(v)}
            placeholder={`${t(
              'JOB_DICTIONARY.JOB_PROFILE.ENTER_NAME',
            )} ${title}`}
            onClear={clearInputHandler}
          />
          <Button
            style={{
              backgroundColor: COLORS.LIGHT_BLUE[600],
              color: COLORS.WHITE,
              borderRadius: '0 3px 3px 0',
              border: `1px solid ${COLORS.GRAY[400]}`,
              borderLeft: 0,
              fontSize: '13px',
              fontWeight: 600,
            }}
            handler={addItemHandler}
            text={`+ ${t('JOB_DICTIONARY.JOB_PROFILE.ADD')}`}
          />
          {isMenuOpen && tagsForMenu.length > 0 && (
            <div ref={ref} className={tagsMenu}>
              {tagsForMenu.map((tag) => (
                <div
                  className={tagsMenuItem}
                  key={tag.id}
                  onClick={() => {
                    setInputValue(tag.title);
                    setSelectedId(tag.id);
                    setIsMenuOpen(false);
                  }}
                >
                  {tag.title}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div style={{ overflow: 'scroll', height: isEditing ? 290 : 337 }}>
        {currentItems?.map((item) => (
          <ToolItem
            key={item.id}
            name={item.title}
            handleRemove={() => setItemsHandler(_pull([...currentItems], item).map((i) => i.id))}
            isEditing={isEditing}
          />
        ))}
      </div>
    </InfoCard>
  );
};

export default EditableDictionary;
