import React, { useState } from 'react';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import InfoCard from '../InfoCard';
import { cardMediumWithHeight, column } from '../../../assets/soter-flex-styles';
import CardEditingControl from '../CardEditingControl';
import { TASK_COMPARISON_DESCRIPTION_CHANGED, TASK_DESCRIPTION_CHANGED } from '../../../constants/analyticsEvents';
import { useAppDispatch } from '../../utils/useStore';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import InfoTooltip from '../InfoTooltip';
import { escapeHTML } from './escapeHTML';

const editingField = Emotion.css`
  padding: 7px;
  border-radius: 3px;
  &:focus {
    outline: 1px solid ${COLORS.GRAY[100]};
  }
`;

const EditableTextCard = ({
  title,
  text,
  height,
  className,
  id,
  saveValue,
  tooltipText,
  isComparisonPage,
}) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const elementId = id;
  const editButtonHandler = () => {
    document.getElementById(elementId)?.focus();
    setEditMode(true);
  };
  const cancelButtonHandler = () => {
    document.getElementById(elementId).innerHTML = escapeHTML(text);
    setEditMode(false);
  };
  const saveButtonHandler = () => {
    if (isComparisonPage) {
      window.logEvent(TASK_COMPARISON_DESCRIPTION_CHANGED);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(TASK_COMPARISON_DESCRIPTION_CHANGED));
      }
    } else {
      window.logEvent(TASK_DESCRIPTION_CHANGED);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(TASK_DESCRIPTION_CHANGED));
      }
    }
    saveValue(escapeHTML(document.getElementById(elementId).innerText));
    setEditMode(false);
  };
  return (
    <InfoCard
      title={title}
      cardClass={`${cardMediumWithHeight(height)} ${className}`}
      titleIsNotAColumn={!!tooltipText}
      rightCornerControl={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {tooltipText ? <InfoTooltip text={tooltipText} /> : null}
          <CardEditingControl
            editButtonHandler={editButtonHandler}
            editMode={editMode}
            cancelButtonHandler={cancelButtonHandler}
            saveButtonHandler={saveButtonHandler}
          />
        </div>
      )}
      rightCornerControlClass={column}
      isTaskProfile
    >
      <div
        className={editingField}
        id={elementId}
        contentEditable
        suppressContentEditableWarning
        dangerouslySetInnerHTML={{ __html: escapeHTML(text) }}
        onFocus={() => setEditMode(true)}
        style={{ overflow: 'scroll', height: height - 69 }}
      />
    </InfoCard>
  );
};

export default EditableTextCard;
