export const getTextForMoment = (t) => ({
  future: 'in %s',
  past: '%s ago',
  s: 'a few seconds',
  ss: '%d seconds',
  m: 'a minute',
  mm: '%d minutes',
  h: `${t('GENERAL.HOUR')}`,
  hh: `%d ${t('GENERAL.HOURS')}`,
  d: `${t('TIME.SINCE.DAY')}`,
  dd: `%d ${t('TIME.SINCE.DAYS')}`,
  w: 'a week',
  ww: '%d weeks',
  M: `${t('TIME.SINCE.MONTH')}`,
  MM: `%d ${t('TIME.SINCE.MONTHS')}`,
  y: `${t('TIME.SINCE.YEAR')}`,
  yy: `%d ${t('TIME.SINCE.YEARS')}`,
});
