import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';
export const link = Emotion.css `
   {
    margin-left: 4px;
    color: ${COLORS.LIGHT_BLUE[600]};
    text-decoration: underline;
    :hover {
      cursor: pointer;
    }
  }
`;
export const tagCard = Emotion.css `
    background-color: rgba(33, 150, 243, 0.1);
    color: rgba(33, 150, 243, 1);
    padding: 2px 5px 2px;
    margin: 2px 2px 2px 5px;
    border-radius: 6px;
    font-size: 12px;
  `;
export const delEntry = Emotion.css `
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: -2px;
  font-size: 16px;
  color: ${COLORS.BLUE[600]};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${COLORS.LIGHT_BLUE[600]};

  &:focus {
    outline: none;
  }
`;
export const delMarker = Emotion.css `
  color: ${COLORS.WHITE};
  margin-top: -3px;
`;
