import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  jobProfiles: [],
  error: null,
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.JOB_PROFILES_SET:
      const {
        jobRolesListList,
      } = action.profileData;
      return state.merge({
        jobProfiles: jobRolesListList,
        error: null,
        isLoading: false,
      });

    case types.JOB_PROFILES_IS_LOADING:
      return state.merge({
        error: null,
        isLoading: true,
      });
    default: return state;
  }
}
