import React, { useEffect, useState } from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import Tooltip from 'lib/ui/Tooltip';
import { COLORS } from '../../../../constants/colors';
import Button from '../../../../lib/ui/Button';
import { NEED_HELP_STATUS_CHANGE } from '../../../../constants/analyticsEvents';
import { createEventNotification } from '../../../NotificationContainer/notificationsStore/actions';
import { useAppDispatch } from '../../../../lib/utils/useStore';

const STATUS = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  CLOSED: 'closed',
  REOPEN: 'reopen',
};

const getColorForStatus = (status) => {
  switch (status) {
    case STATUS.REOPEN:
    case STATUS.NEW:
      return [COLORS.RED[600], COLORS.LIGHT_BLUE[600]];
    case STATUS.IN_PROGRESS:
      return [COLORS.LIGHT_BLUE[600], COLORS.GREEN[600]];
    case STATUS.CLOSED:
      return [COLORS.GRAY[400], COLORS.RED[600]];
    default:
      return [];
  }
};

const switchStatus = (status) => {
  switch (status) {
    case STATUS.NEW:
      return STATUS.IN_PROGRESS;
    case STATUS.IN_PROGRESS:
      return STATUS.CLOSED;
    case STATUS.CLOSED:
      return STATUS.REOPEN;
    case STATUS.REOPEN:
      return STATUS.IN_PROGRESS;
    default:
      return '';
  }
};

const buttonStyle = (color, isPressed) => Emotion.css`
   {
    width: 100px;
    height: 31px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${isPressed ? color[1] : color[0]} !important;
    border: ${isPressed ? '2px' : '1px'} solid
      ${isPressed ? color[1] : color[0]} !important;
    background-color: ${isPressed
    ? 'rgba(0, 0, 0, 0.12) !important'
    : 'transparent'} !important;
    border-radius: 3px;
    &:hover {
      cursor: pointer;
      border: ${isPressed ? '2px' : '1px'} solid ${color[1]} !important;
      background-color: ${isPressed
    ? 'rgba(0, 0, 0, 0.12)'
    : 'rgba(0, 0, 0, 0.04)'} !important;
      color: ${color[1]} !important;
    }
    overflow: hidden;
  }
`;

const ChangeStatusCell = ({ rowData, setStatus, error }) => {
  const { status, id } = rowData;
  const { t } = useTranslation();
  const [isPressed, setIsPressed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setIsPressed(false);
  }, [status, id, error]);
  const getButtonText = (status, isPressed, isHovered) => {
    switch (status) {
      case STATUS.REOPEN:
        return isPressed || isHovered
          ? t('WEARABLES.HELP.START_PROGRESS')
          : t('WEARABLES.HELP.REOPENED');
      case STATUS.NEW:
        return isPressed || isHovered
          ? t('WEARABLES.HELP.START_PROGRESS')
          : t('WEARABLES.HELP.NEW_ACTION');
      case STATUS.IN_PROGRESS:
        return isPressed || isHovered
          ? t('WEARABLES.HELP.RESOLVE')
          : t('WEARABLES.HELP.IN_PROGRESS');
      case STATUS.CLOSED:
        return isPressed || isHovered
          ? t('WEARABLES.HELP.REOPEN')
          : t('WEARABLES.HELP.RESOLVED');
      default:
        return '';
    }
  };
  return (
    <>
      {getButtonText(status, isPressed, isHovered).length > 18 ? (
        <Tooltip
          noTranslation
          text={getButtonText(status, isPressed, isHovered)}
        >
          <div>
            <Button
              text={getButtonText(status, isPressed, isHovered)}
              className={buttonStyle(getColorForStatus(status), isPressed)}
              handler={() => {
                window.logEvent(NEED_HELP_STATUS_CHANGE);
                if (localStorage.getItem('showEvents')) {
                  dispatch(createEventNotification(NEED_HELP_STATUS_CHANGE));
                }
                setIsPressed(true);
                setStatus(id, switchStatus(status));
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
          </div>
        </Tooltip>
      ) : (
        <Button
          text={getButtonText(status, isPressed, isHovered)}
          className={buttonStyle(getColorForStatus(status), isPressed)}
          handler={() => {
            window.logEvent(NEED_HELP_STATUS_CHANGE);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(NEED_HELP_STATUS_CHANGE));
            }
            setIsPressed(true);
            setStatus(id, switchStatus(status));
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      )}
    </>
  );
};

export default ChangeStatusCell;
