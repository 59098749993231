import React from 'react';
import * as Emotion from 'emotion';
import qrCodeIOS from '../../assets/img/qrCodeIOS.png';
import qrCodeAndroid from '../../assets/img/qrCodeAndroid.png';
import { useTranslation } from 'react-i18next';

export const qrCodeStyles = Emotion.css`{
  display: block;
  width: 192px;
  height: 192px;
  margin: 20px auto;
}`;

const ShowAppQrcodeDialog = ({ qrCode }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t('SOTER_TASK.VIDEOS_LIST.OPEN_LINK')}</h4>
      <img src={qrCode === 'ios' ? qrCodeIOS : qrCodeAndroid} className={qrCodeStyles} alt="" />
    </>
  );
};

export default ShowAppQrcodeDialog;
