import React from 'react';
import { Button } from 'rsuite';
import { COLORS } from 'constants/colors';

const DropdownActionButton = ({
  className,
  id,
  clickHandler,
  disabled,
  children,
  style = null,
}) => (
  <Button
    className={className}
    type="button"
    id={id}
    onClick={(e) => clickHandler(e)}
    disabled={disabled}
    style={{
      ...style,
      backgroundColor: disabled ? COLORS.WHITE : null,
    }}
  >
    {children}
  </Button>
);

export default DropdownActionButton;
