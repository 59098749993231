import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import {
  infoMainText, mb3, mt3, subText,
} from '../../../assets/soter-flex-styles';

const RecoverActionSuccess = ({ message }) => {
  const [redirectTimeMs, setRedirectTimeMs] = useState(10000);
  const { t } = useTranslation();
  useEffect(() => {
    const timer = setInterval(
      () => setRedirectTimeMs(redirectTimeMs - 1000),
      1000,
    );
    if (redirectTimeMs === 0) navigate('/auth');

    return () => clearInterval(timer);
  }, [redirectTimeMs]);

  return (
    <>
      <p className={`${infoMainText} ${mt3} ${mb3}`}>{message}</p>
      <p
        className={`${subText} ${mb3}`}
        style={{ color: COLORS.BLUE[600] }}
      >
        {`${t('LOGIN.WILL_BE_REDIRECT')} ${
          redirectTimeMs / 1000
        } ${t('GENERAL.SECONDS')}`}
      </p>
    </>
  );
};

export default RecoverActionSuccess;
