import { HAZARDS_COLORS } from 'constants/colors';
import { hazardsEnum } from './hazardsEnum';

const { spine, shoulder } = hazardsEnum;

export const hazardNameColorMap = {
  spine: [
    {
      alias: 'lifting',
      name: spine.lifting,
      color: HAZARDS_COLORS.SPINE.POOR_BENDING[600],
    },
    {
      alias: 'twisting',
      name: spine.twisting,
      color: HAZARDS_COLORS.SPINE.BACK_TWISTING[600],
    },
    {
      alias: 'pbStatic',
      name: spine.pbStatic,
      color: HAZARDS_COLORS.SPINE.AWKWARD_STATIC_POSTURE[600],
    },
    {
      alias: 'intensity',
      name: spine.intensity,
      color: HAZARDS_COLORS.SPINE.INTENSE_BENDING[600],
    },
    {
      alias: 'repetition',
      name: spine.repetition,
      color: HAZARDS_COLORS.SPINE.REPETITION[600],
    },
  ],
  shoulder: [
    {
      alias: 'armElevation',
      name: shoulder.armElevation,
      color: HAZARDS_COLORS.SHOULDER.ARM_ELEVATION[600],
    },

    {
      alias: 'pushingPulling',
      name: shoulder.pushingPulling,
      color: HAZARDS_COLORS.SHOULDER.PUSHING_AND_PULLING[600],
    },
    {
      alias: 'staticArmElevation',
      name: shoulder.staticArmElevation,
      color: HAZARDS_COLORS.SHOULDER.STATIC_ARM_ELEVATION[600],
    },
    {
      alias: 'overexertion',
      name: shoulder.overexertion,
      color: HAZARDS_COLORS.SHOULDER.OVEREXERTION[600],
    },
    {
      alias: 'repetitiveArmMovements',
      name: shoulder.repetitiveArmMovements,
      color: HAZARDS_COLORS.SHOULDER.REPETITIVE_ARM_MOVEMENTS[600],
    },
  ],
};
