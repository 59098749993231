import React from 'react';
import { Group, Rect } from 'react-konva';
import DraggableBar from './DraggableBar';

const Bar = React.memo(
  ({
    width,
    rebaWeights,
    setRebaWeights,
    userActions,
    setUserActions,
    selectedId,
    selectShape,
    checkDeselect,
    setIsButtonDisabled,
  }) => rebaWeights?.map((rect, i) => (
    <Group key={rect.order}>
      <Rect width={width} height={16} x={0} fill="#EBF8FF" y={rect.y} />
      <DraggableBar
        shapeProps={rect}
        isSelected={rect.y === selectedId}
        onMouseEnter={() => {
          selectShape(rect.y);
        }}
        onChange={(newAttrs) => {
          const newWeights = [...rebaWeights];
          newWeights[i] = newAttrs;
          setRebaWeights(newWeights);
        }}
        width={width}
        rebaWeights={rebaWeights}
        selectShape={selectShape}
        checkDeselect={checkDeselect}
        userActions={userActions}
        setUserActions={setUserActions}
        setIsButtonDisabled={setIsButtonDisabled}
      />
    </Group>
  )),
);

export default Bar;
