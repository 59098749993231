import {
  createTaskProfile,
  createTaskProfileRequest,
  createVideoRequest,
  detachVideoRequest,
  editTaskProfileRequest,
  getMetaData,
  getTaskRequest,
  jobDictionaryRequest,
  jobDictionaryService,
  removeTaskProfileRequest,
  removeVideoRequest,
  setTaskVideoToTaskProfileRequest,
  taskProfile,
} from 'init/proto_client';
import { TASK_PROFILE_EDIT, TASK_PROFILE_REMOVE } from 'constants/analyticsEvents';
import {
  createEventNotification,
  setNotification,
} from 'containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from 'containers/NotificationContainer/notifications';
import * as types from './actionTypes';
import { getJobProfile } from '../../jobProfile/jobProfileStore/actions';
import DemandsActivityByteConvertor from '../../utils/DemandsActivityByteConvertor';

const setTaskProfile = (profile, {
  title,
  dotClassification,
  frequency,
  safetyCritical,
  taskOverview,
  cpdFloorToWaistLift,
  cpdWaistToShoulderLift,
  cpdOverheadLift,
  cpdCarry,
  cpdPush,
  cpdPull,
  shouldersRiskGroup,
  spineRiskGroup,
  kneeRiskGroup,
  shoulderChartData,
  spineChartData,
  duration,
  riskGroup,
}) => {
  profile.setTitle(title);
  profile.setDotClassification(dotClassification);
  profile.setFrequency(frequency);
  profile.setSafetyCritical(safetyCritical);
  profile.setTaskOverview(taskOverview);
  profile.setCpdFloorToWaistLift(cpdFloorToWaistLift);
  profile.setCpdWaistToShoulderLift(cpdWaistToShoulderLift);
  profile.setCpdOverheadLift(cpdOverheadLift);
  profile.setCpdCarry(cpdCarry);
  profile.setCpdPush(cpdPush);
  profile.setCpdPull(cpdPull);
  profile.setShouldersRiskGroup(shouldersRiskGroup);
  profile.setSpineRiskGroup(spineRiskGroup);
  profile.setKneeRiskGroup(kneeRiskGroup);
  profile.setShoulderChartData(shoulderChartData);
  profile.setSpineChartData(spineChartData);
  profile.setRiskGroup(riskGroup);
  profile.setDuration(duration);
  return profile;
};

export function toggleIsLoading(isLoading) {
  return ({ type: types.TASK_PROFILE_IS_LOADING, isLoading });
}

export function resetProfileState() {
  return ({ type: types.TASK_PROFILE_RESET });
}

export function createTask(profile) {
  return async (dispatch) => {
    const pr = setTaskProfile(createTaskProfile, profile);
    pr.setJobDictionaryProfileId(profile.profileId);
    createTaskProfileRequest.setTaskProfile(pr);
    await jobDictionaryService.createJobDictionaryTask(
      createTaskProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.CREATE_TASK_PROFILE_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.TASK_PROFILE_SET,
            profile: response.toObject().taskProfile,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.CREATE_TASK_PROFILE_SUCCESS_MSG,
          }));
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export function editTask(profile) {
  return async (dispatch) => {
    const params = {
      edited_fields: profile,
    };
    window.logEvent(TASK_PROFILE_EDIT, params);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(`${TASK_PROFILE_EDIT}: ${JSON.stringify(params)}`));
    }
    const pr = setTaskProfile(taskProfile, profile);
    pr.setId(profile.id);
    editTaskProfileRequest.setTaskProfile(pr);
    await jobDictionaryService.editJobDictionaryTask(
      editTaskProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.EDIT_TASK_PROFILE_FAILED_MSG,
          }));
        } else {
          dispatch({
            type: types.TASK_PROFILE_SET,
            profile: response.toObject().taskProfile,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.EDIT_TASK_PROFILE_SUCCESS_MSG,
          }));
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export function removeTask(id, profileId) {
  return async (dispatch) => {
    window.logEvent(TASK_PROFILE_REMOVE);
    removeTaskProfileRequest.setId(id);
    await jobDictionaryService.removeJobDictionaryTask(
      removeTaskProfileRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: error.message,
          }));
        } else {
          dispatch({
            type: types.TASK_PROFILE_REMOVED,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.REMOVE_TASK_PROFILE_SUCCESS,
          }));
          dispatch(getJobProfile(profileId));
        }
      },
    );
  };
}

export function getSoterTaskVideos(companyId) {
  return async (dispatch) => {
    jobDictionaryRequest.setCompanyId(companyId);
    await jobDictionaryService.getVideosForSoterTask(
      jobDictionaryRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
        } else {
          dispatch({
            type: types.TASK_PROFILE_VIDEOS_SET,
            soterTaskVideos: response.toObject().videosList,
          });
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export function getTask(id) {
  return async (dispatch) => {
    getTaskRequest.setTaskId(id);
    await jobDictionaryService.getJobDictionaryTask(
      getTaskRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch(toggleIsLoading(false));
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
        } else {
          const TaskProfile = response.toObject().taskProfile;
          const demands = {
            cpdFloorToWaistLift: TaskProfile.cpdFloorToWaistLift,
            cpdWaistToShoulderLift: TaskProfile.cpdWaistToShoulderLift,
            cpdOverheadLift: TaskProfile.cpdOverheadLift,
            cpdCarry: TaskProfile.cpdCarry,
            cpdPush: TaskProfile.cpdPush,
            cpdPull: TaskProfile.cpdPull,
          };
          const byteConvertor = new DemandsActivityByteConvertor();
          const zeroBase64String = byteConvertor.getZeroBase64String();
          // eslint-disable-next-line no-restricted-syntax
          for (const demand in demands) {
            if (demands[demand] === '') {
              demands[demand] = zeroBase64String;
            }
          }
          dispatch({
            type: types.TASK_PROFILE_SET,
            profile: {
              ...TaskProfile,
              cpdFloorToWaistLift: demands.cpdFloorToWaistLift,
              cpdWaistToShoulderLift: demands.cpdWaistToShoulderLift,
              cpdOverheadLift: demands.cpdOverheadLift,
              cpdCarry: demands.cpdCarry,
              cpdPush: demands.cpdPush,
              cpdPull: demands.cpdPull,
            },
          });
          dispatch(toggleIsLoading(false));
        }
      },
    );
  };
}

export function attachSoterTaskVideo(taskId, videoIds) {
  return async (dispatch) => {
    setTaskVideoToTaskProfileRequest.setTaskId(taskId);
    setTaskVideoToTaskProfileRequest.setSoterTaskVideoIdList(videoIds);
    await jobDictionaryService.setTaskVideoForJobDictionaryTask(
      setTaskVideoToTaskProfileRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: notificationMessages.SERVER_ERROR_MSG,
          }));
        } else {
          dispatch({
            type: types.TASK_PROFILE_SET,
            profile: response.toObject().taskProfile,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.TASK_PROFILE_VIDEO_ATTACHED,
          }));
        }
      },
    );
  };
}

export function createVideo(taskId, url, title) {
  return async (dispatch) => {
    createVideoRequest.setTaskId(taskId);
    createVideoRequest.setTitle(title);
    createVideoRequest.setYoutubeUrl(url);
    await jobDictionaryService.createJobDictionaryYoutubeVideo(
      createVideoRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: error.message,
          }));
        } else {
          dispatch({
            type: types.TASK_PROFILE_VIDEO_CREATED,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: 'VIDEO OK',
          }));
          dispatch(getTask(taskId));
        }
      },
    );
  };
}

export function removeVideo(id, taskId, profileId) {
  return async (dispatch) => {
    removeVideoRequest.setId(id);
    await jobDictionaryService.removeJobDictionaryYoutubeVideo(
      removeVideoRequest,
      getMetaData(),
      (error) => {
        if (error) {
          dispatch({
            type: types.TASK_PROFILE_ERROR,
            error,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_FAILED,
            message: error.message,
          }));
        } else {
          dispatch({
            type: types.TASK_PROFILE_VIDEO_REMOVED,
          });
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: 'VIDEO REMOVED',
          }));
          if (taskId) dispatch(getTask(taskId));
          if (profileId) dispatch(getJobProfile(profileId));
        }
      },
    );
  };
}

export const detachSoterTaskVideo = (taskId, videoId, isLastItem = true) => async (dispatch) => {
  detachVideoRequest.setJobDictionaryTaskId(taskId);
  detachVideoRequest.setTaskVideoId(videoId);

  await jobDictionaryService.detachJobDictionaryTaskVideo(
    detachVideoRequest,
    getMetaData(),
    (error) => {
      if (error) {
        dispatch({
          type: types.TASK_PROFILE_ERROR,
          error,
        });
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: error.message,
        }));
      } else {
        dispatch({
          type: types.TASK_PROFILE_VIDEO_REMOVED,
        });
        if (isLastItem) {
          dispatch(setNotification({
            type: notificationTypes.TYPE_SUCCESS,
            message: notificationMessages.TASK_PROFILE_VIDEO_DETACHED_SUCCESS_MSG,
          }));
        }
        if (taskId) dispatch(getTask(taskId));
      }
    },
  );
};
