// source: api/dashboard/v2/user_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var api_dashboard_v2_tier_message_pb = require('../../../api/dashboard/v2/tier_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_tier_message_pb);
var api_dashboard_v2_improvement_message_pb = require('../../../api/dashboard/v2/improvement_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_improvement_message_pb);
var api_dashboard_v2_job_role_message_pb = require('../../../api/dashboard/v2/job_role_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_job_role_message_pb);
var api_dashboard_v2_department_message_pb = require('../../../api/dashboard/v2/department_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_department_message_pb);
var api_dashboard_v2_site_message_pb = require('../../../api/dashboard/v2/site_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_site_message_pb);
var api_dashboard_v2_user_note_message_pb = require('../../../api/dashboard/v2/user_note_message_pb.js');
goog.object.extend(proto, api_dashboard_v2_user_note_message_pb);
goog.exportSymbol('proto.users.CreateDashboardFeedbackRequest', null, global);
goog.exportSymbol('proto.users.CreateDashboardFeedbackResponse', null, global);
goog.exportSymbol('proto.users.DeviceLinkHistory', null, global);
goog.exportSymbol('proto.users.DeviceLinkHistoryRequest', null, global);
goog.exportSymbol('proto.users.DeviceLinkHistoryResponse', null, global);
goog.exportSymbol('proto.users.Hazards', null, global);
goog.exportSymbol('proto.users.UserAchievements', null, global);
goog.exportSymbol('proto.users.UserActivationRequest', null, global);
goog.exportSymbol('proto.users.UserCreateClipGoUserRequest', null, global);
goog.exportSymbol('proto.users.UserDepartmentRequest', null, global);
goog.exportSymbol('proto.users.UserDevice', null, global);
goog.exportSymbol('proto.users.UserHazardsByTask', null, global);
goog.exportSymbol('proto.users.UserHazardsSplit', null, global);
goog.exportSymbol('proto.users.UserJobTitleRequest', null, global);
goog.exportSymbol('proto.users.UserOverallHazards', null, global);
goog.exportSymbol('proto.users.UserProgram', null, global);
goog.exportSymbol('proto.users.UserProgress', null, global);
goog.exportSymbol('proto.users.UserRequest', null, global);
goog.exportSymbol('proto.users.UserResponse', null, global);
goog.exportSymbol('proto.users.UserServiceCreateUserRequest', null, global);
goog.exportSymbol('proto.users.UserSetProgramRequest', null, global);
goog.exportSymbol('proto.users.UserSiteRequest', null, global);
goog.exportSymbol('proto.users.UserTutorials', null, global);
goog.exportSymbol('proto.users.UserUpdateUserPrivateDataRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserProgress.displayName = 'proto.users.UserProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserTutorials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserTutorials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserTutorials.displayName = 'proto.users.UserTutorials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserDevice.displayName = 'proto.users.UserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserHazardsSplit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserHazardsSplit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserHazardsSplit.displayName = 'proto.users.UserHazardsSplit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserHazardsByTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserHazardsByTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserHazardsByTask.displayName = 'proto.users.UserHazardsByTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserOverallHazards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserOverallHazards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserOverallHazards.displayName = 'proto.users.UserOverallHazards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.Hazards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.Hazards.repeatedFields_, null);
};
goog.inherits(proto.users.Hazards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.Hazards.displayName = 'proto.users.Hazards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.UserProgram.repeatedFields_, null);
};
goog.inherits(proto.users.UserProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserProgram.displayName = 'proto.users.UserProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserAchievements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserAchievements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserAchievements.displayName = 'proto.users.UserAchievements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.UserResponse.repeatedFields_, null);
};
goog.inherits(proto.users.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserResponse.displayName = 'proto.users.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserRequest.displayName = 'proto.users.UserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserDepartmentRequest.displayName = 'proto.users.UserDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserSiteRequest.displayName = 'proto.users.UserSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserJobTitleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserJobTitleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserJobTitleRequest.displayName = 'proto.users.UserJobTitleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserSetProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserSetProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserSetProgramRequest.displayName = 'proto.users.UserSetProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserActivationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserActivationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserActivationRequest.displayName = 'proto.users.UserActivationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserUpdateUserPrivateDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserUpdateUserPrivateDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserUpdateUserPrivateDataRequest.displayName = 'proto.users.UserUpdateUserPrivateDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.DeviceLinkHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.DeviceLinkHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.DeviceLinkHistory.displayName = 'proto.users.DeviceLinkHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.DeviceLinkHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.users.DeviceLinkHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.users.DeviceLinkHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.DeviceLinkHistoryResponse.displayName = 'proto.users.DeviceLinkHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.DeviceLinkHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.DeviceLinkHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.DeviceLinkHistoryRequest.displayName = 'proto.users.DeviceLinkHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserCreateClipGoUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserCreateClipGoUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserCreateClipGoUserRequest.displayName = 'proto.users.UserCreateClipGoUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.UserServiceCreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.UserServiceCreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.UserServiceCreateUserRequest.displayName = 'proto.users.UserServiceCreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.CreateDashboardFeedbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.CreateDashboardFeedbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.CreateDashboardFeedbackRequest.displayName = 'proto.users.CreateDashboardFeedbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.users.CreateDashboardFeedbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.users.CreateDashboardFeedbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.users.CreateDashboardFeedbackResponse.displayName = 'proto.users.CreateDashboardFeedbackResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    acceptedWorkSessionsCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetWorkSessionsCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastSyncInMilliseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastSession: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserProgress}
 */
proto.users.UserProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserProgress;
  return proto.users.UserProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserProgress}
 */
proto.users.UserProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAcceptedWorkSessionsCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetWorkSessionsCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSyncInMilliseconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcceptedWorkSessionsCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTargetWorkSessionsCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastSyncInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLastSession();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int32 accepted_work_sessions_count = 1;
 * @return {number}
 */
proto.users.UserProgress.prototype.getAcceptedWorkSessionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgress} returns this
 */
proto.users.UserProgress.prototype.setAcceptedWorkSessionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 target_work_sessions_count = 2;
 * @return {number}
 */
proto.users.UserProgress.prototype.getTargetWorkSessionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgress} returns this
 */
proto.users.UserProgress.prototype.setTargetWorkSessionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_sync_in_milliseconds = 3;
 * @return {number}
 */
proto.users.UserProgress.prototype.getLastSyncInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgress} returns this
 */
proto.users.UserProgress.prototype.setLastSyncInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 last_session = 4;
 * @return {number}
 */
proto.users.UserProgress.prototype.getLastSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgress} returns this
 */
proto.users.UserProgress.prototype.setLastSession = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserTutorials.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserTutorials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserTutorials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserTutorials.toObject = function(includeInstance, msg) {
  var f, obj = {
    tutorialsCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requiredTutorialsCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    finishedTutorialsCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    finishedRequiredTutorialsCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserTutorials}
 */
proto.users.UserTutorials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserTutorials;
  return proto.users.UserTutorials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserTutorials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserTutorials}
 */
proto.users.UserTutorials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTutorialsCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequiredTutorialsCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinishedTutorialsCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinishedRequiredTutorialsCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserTutorials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserTutorials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserTutorials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserTutorials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTutorialsCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRequiredTutorialsCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFinishedTutorialsCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFinishedRequiredTutorialsCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 tutorials_count = 1;
 * @return {number}
 */
proto.users.UserTutorials.prototype.getTutorialsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserTutorials} returns this
 */
proto.users.UserTutorials.prototype.setTutorialsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 required_tutorials_count = 2;
 * @return {number}
 */
proto.users.UserTutorials.prototype.getRequiredTutorialsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserTutorials} returns this
 */
proto.users.UserTutorials.prototype.setRequiredTutorialsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 finished_tutorials_count = 3;
 * @return {number}
 */
proto.users.UserTutorials.prototype.getFinishedTutorialsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserTutorials} returns this
 */
proto.users.UserTutorials.prototype.setFinishedTutorialsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 finished_required_tutorials_count = 4;
 * @return {number}
 */
proto.users.UserTutorials.prototype.getFinishedRequiredTutorialsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserTutorials} returns this
 */
proto.users.UserTutorials.prototype.setFinishedRequiredTutorialsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    macAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastBatteryCharge: jspb.Message.getFieldWithDefault(msg, 2, 0),
    soundOn: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    vibroOn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserDevice}
 */
proto.users.UserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserDevice;
  return proto.users.UserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserDevice}
 */
proto.users.UserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastBatteryCharge(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSoundOn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVibroOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastBatteryCharge();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSoundOn();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getVibroOn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string mac_address = 1;
 * @return {string}
 */
proto.users.UserDevice.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserDevice} returns this
 */
proto.users.UserDevice.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 last_battery_charge = 2;
 * @return {number}
 */
proto.users.UserDevice.prototype.getLastBatteryCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserDevice} returns this
 */
proto.users.UserDevice.prototype.setLastBatteryCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool sound_on = 3;
 * @return {boolean}
 */
proto.users.UserDevice.prototype.getSoundOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.UserDevice} returns this
 */
proto.users.UserDevice.prototype.setSoundOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool vibro_on = 4;
 * @return {boolean}
 */
proto.users.UserDevice.prototype.getVibroOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.UserDevice} returns this
 */
proto.users.UserDevice.prototype.setVibroOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserHazardsSplit.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserHazardsSplit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserHazardsSplit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserHazardsSplit.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_static: jspb.Message.getFieldWithDefault(msg, 1, 0),
    repetition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lifting: jspb.Message.getFieldWithDefault(msg, 3, 0),
    twisting: jspb.Message.getFieldWithDefault(msg, 4, 0),
    intensity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pushingPulling: jspb.Message.getFieldWithDefault(msg, 6, 0),
    staticArmElevation: jspb.Message.getFieldWithDefault(msg, 7, 0),
    repetitiveArmMovements: jspb.Message.getFieldWithDefault(msg, 8, 0),
    overexertion: jspb.Message.getFieldWithDefault(msg, 9, 0),
    armElevation: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timeInMilliseconds: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserHazardsSplit}
 */
proto.users.UserHazardsSplit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserHazardsSplit;
  return proto.users.UserHazardsSplit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserHazardsSplit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserHazardsSplit}
 */
proto.users.UserHazardsSplit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatic(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLifting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTwisting(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntensity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPushingPulling(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStaticArmElevation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetitiveArmMovements(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverexertion(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArmElevation(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeInMilliseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserHazardsSplit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserHazardsSplit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserHazardsSplit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserHazardsSplit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatic();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLifting();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTwisting();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTimeInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int32 static = 1;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setStatic = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 repetition = 2;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 lifting = 3;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getLifting = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setLifting = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 twisting = 4;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setTwisting = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 intensity = 5;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 pushing_pulling = 6;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 static_arm_elevation = 7;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 repetitive_arm_movements = 8;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 overexertion = 9;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 arm_elevation = 10;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 time_in_milliseconds = 11;
 * @return {number}
 */
proto.users.UserHazardsSplit.prototype.getTimeInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsSplit} returns this
 */
proto.users.UserHazardsSplit.prototype.setTimeInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserHazardsByTask.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserHazardsByTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserHazardsByTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserHazardsByTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timeStart: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeEnd: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pb_static: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    repetition: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lifting: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    twisting: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    pushingPulling: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    staticArmElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    repetitiveArmMovements: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    overexertion: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    armElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    taskOverall: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserHazardsByTask}
 */
proto.users.UserHazardsByTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserHazardsByTask;
  return proto.users.UserHazardsByTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserHazardsByTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserHazardsByTask}
 */
proto.users.UserHazardsByTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeEnd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStatic(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetition(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLifting(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTwisting(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntensity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPushingPulling(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaticArmElevation(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetitiveArmMovements(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverexertion(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmElevation(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaskOverall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserHazardsByTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserHazardsByTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserHazardsByTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserHazardsByTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTimeEnd();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStatic();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLifting();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTwisting();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getTaskOverall();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string task_name = 2;
 * @return {string}
 */
proto.users.UserHazardsByTask.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 time_start = 3;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 time_end = 4;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getTimeEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setTimeEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double static = 5;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setStatic = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double repetition = 6;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double lifting = 7;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getLifting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setLifting = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double twisting = 8;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setTwisting = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double intensity = 9;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double pushing_pulling = 10;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double static_arm_elevation = 11;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double repetitive_arm_movements = 12;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double overexertion = 13;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double arm_elevation = 14;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double task_overall = 15;
 * @return {number}
 */
proto.users.UserHazardsByTask.prototype.getTaskOverall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserHazardsByTask} returns this
 */
proto.users.UserHazardsByTask.prototype.setTaskOverall = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserOverallHazards.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserOverallHazards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserOverallHazards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserOverallHazards.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_static: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    repetition: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lifting: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    twisting: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    pushingPulling: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    staticArmElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    repetitiveArmMovements: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    overexertion: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    armElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    overallHazard: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserOverallHazards}
 */
proto.users.UserOverallHazards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserOverallHazards;
  return proto.users.UserOverallHazards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserOverallHazards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserOverallHazards}
 */
proto.users.UserOverallHazards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStatic(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLifting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTwisting(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntensity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPushingPulling(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaticArmElevation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetitiveArmMovements(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverexertion(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmElevation(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverallHazard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserOverallHazards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserOverallHazards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserOverallHazards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserOverallHazards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatic();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLifting();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTwisting();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getOverallHazard();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
};


/**
 * optional double static = 1;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setStatic = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double repetition = 2;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lifting = 3;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getLifting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setLifting = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double twisting = 4;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setTwisting = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double intensity = 5;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double pushing_pulling = 6;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double static_arm_elevation = 7;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double repetitive_arm_movements = 8;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double overexertion = 9;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double arm_elevation = 10;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double overall_hazard = 11;
 * @return {number}
 */
proto.users.UserOverallHazards.prototype.getOverallHazard = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserOverallHazards} returns this
 */
proto.users.UserOverallHazards.prototype.setOverallHazard = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.Hazards.repeatedFields_ = [6,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.Hazards.prototype.toObject = function(opt_includeInstance) {
  return proto.users.Hazards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.Hazards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.Hazards.toObject = function(includeInstance, msg) {
  var f, obj = {
    workSessionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    durationInMilliseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    overallHazards: (f = msg.getOverallHazards()) && proto.users.UserOverallHazards.toObject(includeInstance, f),
    hazardsByHourList: jspb.Message.toObjectList(msg.getHazardsByHourList(),
    proto.users.UserHazardsSplit.toObject, includeInstance),
    pb_static: jspb.Message.getFieldWithDefault(msg, 7, 0),
    repetition: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lifting: jspb.Message.getFieldWithDefault(msg, 9, 0),
    twisting: jspb.Message.getFieldWithDefault(msg, 10, 0),
    intensity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pushingPulling: jspb.Message.getFieldWithDefault(msg, 12, 0),
    staticArmElevation: jspb.Message.getFieldWithDefault(msg, 13, 0),
    repetitiveArmMovements: jspb.Message.getFieldWithDefault(msg, 14, 0),
    overexertion: jspb.Message.getFieldWithDefault(msg, 15, 0),
    armElevation: jspb.Message.getFieldWithDefault(msg, 16, 0),
    hazardousMovementRatioChange: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    hazardousMovementRatioValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    hazardsByTaskList: jspb.Message.toObjectList(msg.getHazardsByTaskList(),
    proto.users.UserHazardsByTask.toObject, includeInstance),
    fillDay: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.Hazards}
 */
proto.users.Hazards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.Hazards;
  return proto.users.Hazards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.Hazards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.Hazards}
 */
proto.users.Hazards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkSessionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationInMilliseconds(value);
      break;
    case 5:
      var value = new proto.users.UserOverallHazards;
      reader.readMessage(value,proto.users.UserOverallHazards.deserializeBinaryFromReader);
      msg.setOverallHazards(value);
      break;
    case 6:
      var value = new proto.users.UserHazardsSplit;
      reader.readMessage(value,proto.users.UserHazardsSplit.deserializeBinaryFromReader);
      msg.addHazardsByHour(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatic(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepetition(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLifting(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTwisting(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntensity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPushingPulling(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStaticArmElevation(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepetitiveArmMovements(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOverexertion(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArmElevation(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHazardousMovementRatioChange(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHazardousMovementRatioValue(value);
      break;
    case 19:
      var value = new proto.users.UserHazardsByTask;
      reader.readMessage(value,proto.users.UserHazardsByTask.deserializeBinaryFromReader);
      msg.addHazardsByTask(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFillDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.Hazards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.Hazards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.Hazards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.Hazards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkSessionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTimeEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDurationInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOverallHazards();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.users.UserOverallHazards.serializeBinaryToWriter
    );
  }
  f = message.getHazardsByHourList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.users.UserHazardsSplit.serializeBinaryToWriter
    );
  }
  f = message.getStatic();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getLifting();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTwisting();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getHazardousMovementRatioChange();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getHazardousMovementRatioValue();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getHazardsByTaskList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.users.UserHazardsByTask.serializeBinaryToWriter
    );
  }
  f = message.getFillDay();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
};


/**
 * optional int64 work_session_id = 1;
 * @return {number}
 */
proto.users.Hazards.prototype.getWorkSessionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setWorkSessionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 time_start = 2;
 * @return {number}
 */
proto.users.Hazards.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 time_end = 3;
 * @return {number}
 */
proto.users.Hazards.prototype.getTimeEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setTimeEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 duration_in_milliseconds = 4;
 * @return {number}
 */
proto.users.Hazards.prototype.getDurationInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setDurationInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserOverallHazards overall_hazards = 5;
 * @return {?proto.users.UserOverallHazards}
 */
proto.users.Hazards.prototype.getOverallHazards = function() {
  return /** @type{?proto.users.UserOverallHazards} */ (
    jspb.Message.getWrapperField(this, proto.users.UserOverallHazards, 5));
};


/**
 * @param {?proto.users.UserOverallHazards|undefined} value
 * @return {!proto.users.Hazards} returns this
*/
proto.users.Hazards.prototype.setOverallHazards = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.clearOverallHazards = function() {
  return this.setOverallHazards(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.Hazards.prototype.hasOverallHazards = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated UserHazardsSplit hazards_by_hour = 6;
 * @return {!Array<!proto.users.UserHazardsSplit>}
 */
proto.users.Hazards.prototype.getHazardsByHourList = function() {
  return /** @type{!Array<!proto.users.UserHazardsSplit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.UserHazardsSplit, 6));
};


/**
 * @param {!Array<!proto.users.UserHazardsSplit>} value
 * @return {!proto.users.Hazards} returns this
*/
proto.users.Hazards.prototype.setHazardsByHourList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.users.UserHazardsSplit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.UserHazardsSplit}
 */
proto.users.Hazards.prototype.addHazardsByHour = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.users.UserHazardsSplit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.clearHazardsByHourList = function() {
  return this.setHazardsByHourList([]);
};


/**
 * optional int64 static = 7;
 * @return {number}
 */
proto.users.Hazards.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setStatic = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 repetition = 8;
 * @return {number}
 */
proto.users.Hazards.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 lifting = 9;
 * @return {number}
 */
proto.users.Hazards.prototype.getLifting = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setLifting = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 twisting = 10;
 * @return {number}
 */
proto.users.Hazards.prototype.getTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setTwisting = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 intensity = 11;
 * @return {number}
 */
proto.users.Hazards.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 pushing_pulling = 12;
 * @return {number}
 */
proto.users.Hazards.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 static_arm_elevation = 13;
 * @return {number}
 */
proto.users.Hazards.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 repetitive_arm_movements = 14;
 * @return {number}
 */
proto.users.Hazards.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 overexertion = 15;
 * @return {number}
 */
proto.users.Hazards.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 arm_elevation = 16;
 * @return {number}
 */
proto.users.Hazards.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double hazardous_movement_ratio_change = 17;
 * @return {number}
 */
proto.users.Hazards.prototype.getHazardousMovementRatioChange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setHazardousMovementRatioChange = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double hazardous_movement_ratio_value = 18;
 * @return {number}
 */
proto.users.Hazards.prototype.getHazardousMovementRatioValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setHazardousMovementRatioValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * repeated UserHazardsByTask hazards_by_task = 19;
 * @return {!Array<!proto.users.UserHazardsByTask>}
 */
proto.users.Hazards.prototype.getHazardsByTaskList = function() {
  return /** @type{!Array<!proto.users.UserHazardsByTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.UserHazardsByTask, 19));
};


/**
 * @param {!Array<!proto.users.UserHazardsByTask>} value
 * @return {!proto.users.Hazards} returns this
*/
proto.users.Hazards.prototype.setHazardsByTaskList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.users.UserHazardsByTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.UserHazardsByTask}
 */
proto.users.Hazards.prototype.addHazardsByTask = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.users.UserHazardsByTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.clearHazardsByTaskList = function() {
  return this.setHazardsByTaskList([]);
};


/**
 * optional double fill_day = 20;
 * @return {number}
 */
proto.users.Hazards.prototype.getFillDay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.users.Hazards} returns this
 */
proto.users.Hazards.prototype.setFillDay = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.UserProgram.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    coachingProgramPassId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    improvements: (f = msg.getImprovements()) && api_dashboard_v2_improvement_message_pb.Improvements.toObject(includeInstance, f),
    lastSyncInMilliseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    startedAtInMilliseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    finishedAtInMilliseconds: jspb.Message.getFieldWithDefault(msg, 9, 0),
    progress: (f = msg.getProgress()) && proto.users.UserProgress.toObject(includeInstance, f),
    tutorials: (f = msg.getTutorials()) && proto.users.UserTutorials.toObject(includeInstance, f),
    currentDevice: (f = msg.getCurrentDevice()) && proto.users.UserDevice.toObject(includeInstance, f),
    oldDevicesList: jspb.Message.toObjectList(msg.getOldDevicesList(),
    proto.users.UserDevice.toObject, includeInstance),
    hazardsByDayList: jspb.Message.toObjectList(msg.getHazardsByDayList(),
    proto.users.Hazards.toObject, includeInstance),
    overallHazardsStart: (f = msg.getOverallHazardsStart()) && proto.users.UserOverallHazards.toObject(includeInstance, f),
    overallHazardsCurrent: (f = msg.getOverallHazardsCurrent()) && proto.users.UserOverallHazards.toObject(includeInstance, f),
    riskGroup: jspb.Message.getFieldWithDefault(msg, 17, ""),
    currentRiskGroup: jspb.Message.getFieldWithDefault(msg, 18, ""),
    mostRiskyDayMilliseconds: jspb.Message.getFieldWithDefault(msg, 19, 0),
    mostRiskyHourMilliseconds: jspb.Message.getFieldWithDefault(msg, 20, 0),
    mostRiskyMovement: jspb.Message.getFieldWithDefault(msg, 21, ""),
    mostRiskyTask: jspb.Message.getFieldWithDefault(msg, 22, ""),
    achievements: (f = msg.getAchievements()) && proto.users.UserAchievements.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserProgram}
 */
proto.users.UserProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserProgram;
  return proto.users.UserProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserProgram}
 */
proto.users.UserProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoachingProgramPassId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = new api_dashboard_v2_improvement_message_pb.Improvements;
      reader.readMessage(value,api_dashboard_v2_improvement_message_pb.Improvements.deserializeBinaryFromReader);
      msg.setImprovements(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSyncInMilliseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedAtInMilliseconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishedAtInMilliseconds(value);
      break;
    case 10:
      var value = new proto.users.UserProgress;
      reader.readMessage(value,proto.users.UserProgress.deserializeBinaryFromReader);
      msg.setProgress(value);
      break;
    case 11:
      var value = new proto.users.UserTutorials;
      reader.readMessage(value,proto.users.UserTutorials.deserializeBinaryFromReader);
      msg.setTutorials(value);
      break;
    case 12:
      var value = new proto.users.UserDevice;
      reader.readMessage(value,proto.users.UserDevice.deserializeBinaryFromReader);
      msg.setCurrentDevice(value);
      break;
    case 13:
      var value = new proto.users.UserDevice;
      reader.readMessage(value,proto.users.UserDevice.deserializeBinaryFromReader);
      msg.addOldDevices(value);
      break;
    case 14:
      var value = new proto.users.Hazards;
      reader.readMessage(value,proto.users.Hazards.deserializeBinaryFromReader);
      msg.addHazardsByDay(value);
      break;
    case 15:
      var value = new proto.users.UserOverallHazards;
      reader.readMessage(value,proto.users.UserOverallHazards.deserializeBinaryFromReader);
      msg.setOverallHazardsStart(value);
      break;
    case 16:
      var value = new proto.users.UserOverallHazards;
      reader.readMessage(value,proto.users.UserOverallHazards.deserializeBinaryFromReader);
      msg.setOverallHazardsCurrent(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskGroup(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentRiskGroup(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMostRiskyDayMilliseconds(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMostRiskyHourMilliseconds(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setMostRiskyMovement(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setMostRiskyTask(value);
      break;
    case 23:
      var value = new proto.users.UserAchievements;
      reader.readMessage(value,proto.users.UserAchievements.deserializeBinaryFromReader);
      msg.setAchievements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCoachingProgramPassId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImprovements();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_dashboard_v2_improvement_message_pb.Improvements.serializeBinaryToWriter
    );
  }
  f = message.getLastSyncInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStartedAtInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getFinishedAtInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getProgress();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.users.UserProgress.serializeBinaryToWriter
    );
  }
  f = message.getTutorials();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.users.UserTutorials.serializeBinaryToWriter
    );
  }
  f = message.getCurrentDevice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.users.UserDevice.serializeBinaryToWriter
    );
  }
  f = message.getOldDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.users.UserDevice.serializeBinaryToWriter
    );
  }
  f = message.getHazardsByDayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.users.Hazards.serializeBinaryToWriter
    );
  }
  f = message.getOverallHazardsStart();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.users.UserOverallHazards.serializeBinaryToWriter
    );
  }
  f = message.getOverallHazardsCurrent();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.users.UserOverallHazards.serializeBinaryToWriter
    );
  }
  f = message.getRiskGroup();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCurrentRiskGroup();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getMostRiskyDayMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getMostRiskyHourMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getMostRiskyMovement();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMostRiskyTask();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAchievements();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.users.UserAchievements.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.users.UserProgram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 coaching_program_pass_id = 2;
 * @return {number}
 */
proto.users.UserProgram.prototype.getCoachingProgramPassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setCoachingProgramPassId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.users.UserProgram.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_type = 4;
 * @return {string}
 */
proto.users.UserProgram.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.users.UserProgram.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional improvement_message.Improvements improvements = 6;
 * @return {?proto.improvement_message.Improvements}
 */
proto.users.UserProgram.prototype.getImprovements = function() {
  return /** @type{?proto.improvement_message.Improvements} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_improvement_message_pb.Improvements, 6));
};


/**
 * @param {?proto.improvement_message.Improvements|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setImprovements = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearImprovements = function() {
  return this.setImprovements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasImprovements = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 last_sync_in_milliseconds = 7;
 * @return {number}
 */
proto.users.UserProgram.prototype.getLastSyncInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setLastSyncInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 started_at_in_milliseconds = 8;
 * @return {number}
 */
proto.users.UserProgram.prototype.getStartedAtInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setStartedAtInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 finished_at_in_milliseconds = 9;
 * @return {number}
 */
proto.users.UserProgram.prototype.getFinishedAtInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setFinishedAtInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional UserProgress progress = 10;
 * @return {?proto.users.UserProgress}
 */
proto.users.UserProgram.prototype.getProgress = function() {
  return /** @type{?proto.users.UserProgress} */ (
    jspb.Message.getWrapperField(this, proto.users.UserProgress, 10));
};


/**
 * @param {?proto.users.UserProgress|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setProgress = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearProgress = function() {
  return this.setProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional UserTutorials tutorials = 11;
 * @return {?proto.users.UserTutorials}
 */
proto.users.UserProgram.prototype.getTutorials = function() {
  return /** @type{?proto.users.UserTutorials} */ (
    jspb.Message.getWrapperField(this, proto.users.UserTutorials, 11));
};


/**
 * @param {?proto.users.UserTutorials|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setTutorials = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearTutorials = function() {
  return this.setTutorials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasTutorials = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UserDevice current_device = 12;
 * @return {?proto.users.UserDevice}
 */
proto.users.UserProgram.prototype.getCurrentDevice = function() {
  return /** @type{?proto.users.UserDevice} */ (
    jspb.Message.getWrapperField(this, proto.users.UserDevice, 12));
};


/**
 * @param {?proto.users.UserDevice|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setCurrentDevice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearCurrentDevice = function() {
  return this.setCurrentDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasCurrentDevice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated UserDevice old_devices = 13;
 * @return {!Array<!proto.users.UserDevice>}
 */
proto.users.UserProgram.prototype.getOldDevicesList = function() {
  return /** @type{!Array<!proto.users.UserDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.UserDevice, 13));
};


/**
 * @param {!Array<!proto.users.UserDevice>} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setOldDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.users.UserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.UserDevice}
 */
proto.users.UserProgram.prototype.addOldDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.users.UserDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearOldDevicesList = function() {
  return this.setOldDevicesList([]);
};


/**
 * repeated Hazards hazards_by_day = 14;
 * @return {!Array<!proto.users.Hazards>}
 */
proto.users.UserProgram.prototype.getHazardsByDayList = function() {
  return /** @type{!Array<!proto.users.Hazards>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.Hazards, 14));
};


/**
 * @param {!Array<!proto.users.Hazards>} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setHazardsByDayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.users.Hazards=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.Hazards}
 */
proto.users.UserProgram.prototype.addHazardsByDay = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.users.Hazards, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearHazardsByDayList = function() {
  return this.setHazardsByDayList([]);
};


/**
 * optional UserOverallHazards overall_hazards_start = 15;
 * @return {?proto.users.UserOverallHazards}
 */
proto.users.UserProgram.prototype.getOverallHazardsStart = function() {
  return /** @type{?proto.users.UserOverallHazards} */ (
    jspb.Message.getWrapperField(this, proto.users.UserOverallHazards, 15));
};


/**
 * @param {?proto.users.UserOverallHazards|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setOverallHazardsStart = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearOverallHazardsStart = function() {
  return this.setOverallHazardsStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasOverallHazardsStart = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional UserOverallHazards overall_hazards_current = 16;
 * @return {?proto.users.UserOverallHazards}
 */
proto.users.UserProgram.prototype.getOverallHazardsCurrent = function() {
  return /** @type{?proto.users.UserOverallHazards} */ (
    jspb.Message.getWrapperField(this, proto.users.UserOverallHazards, 16));
};


/**
 * @param {?proto.users.UserOverallHazards|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setOverallHazardsCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearOverallHazardsCurrent = function() {
  return this.setOverallHazardsCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasOverallHazardsCurrent = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string risk_group = 17;
 * @return {string}
 */
proto.users.UserProgram.prototype.getRiskGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setRiskGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string current_risk_group = 18;
 * @return {string}
 */
proto.users.UserProgram.prototype.getCurrentRiskGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setCurrentRiskGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 most_risky_day_milliseconds = 19;
 * @return {number}
 */
proto.users.UserProgram.prototype.getMostRiskyDayMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setMostRiskyDayMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 most_risky_hour_milliseconds = 20;
 * @return {number}
 */
proto.users.UserProgram.prototype.getMostRiskyHourMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setMostRiskyHourMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string most_risky_movement = 21;
 * @return {string}
 */
proto.users.UserProgram.prototype.getMostRiskyMovement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setMostRiskyMovement = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string most_risky_task = 22;
 * @return {string}
 */
proto.users.UserProgram.prototype.getMostRiskyTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.setMostRiskyTask = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional UserAchievements achievements = 23;
 * @return {?proto.users.UserAchievements}
 */
proto.users.UserProgram.prototype.getAchievements = function() {
  return /** @type{?proto.users.UserAchievements} */ (
    jspb.Message.getWrapperField(this, proto.users.UserAchievements, 23));
};


/**
 * @param {?proto.users.UserAchievements|undefined} value
 * @return {!proto.users.UserProgram} returns this
*/
proto.users.UserProgram.prototype.setAchievements = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserProgram} returns this
 */
proto.users.UserProgram.prototype.clearAchievements = function() {
  return this.setAchievements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserProgram.prototype.hasAchievements = function() {
  return jspb.Message.getField(this, 23) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserAchievements.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserAchievements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserAchievements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserAchievements.toObject = function(includeInstance, msg) {
  var f, obj = {
    goldGoalsQuantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    silverGoalsQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bronzeGoalsQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserAchievements}
 */
proto.users.UserAchievements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserAchievements;
  return proto.users.UserAchievements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserAchievements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserAchievements}
 */
proto.users.UserAchievements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoldGoalsQuantity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSilverGoalsQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBronzeGoalsQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserAchievements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserAchievements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserAchievements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserAchievements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoldGoalsQuantity();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSilverGoalsQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBronzeGoalsQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 gold_goals_quantity = 1;
 * @return {number}
 */
proto.users.UserAchievements.prototype.getGoldGoalsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserAchievements} returns this
 */
proto.users.UserAchievements.prototype.setGoldGoalsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 silver_goals_quantity = 2;
 * @return {number}
 */
proto.users.UserAchievements.prototype.getSilverGoalsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserAchievements} returns this
 */
proto.users.UserAchievements.prototype.setSilverGoalsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 bronze_goals_quantity = 3;
 * @return {number}
 */
proto.users.UserAchievements.prototype.getBronzeGoalsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserAchievements} returns this
 */
proto.users.UserAchievements.prototype.setBronzeGoalsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.UserResponse.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    registeredAtInMilliseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastActivityInMilliseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    userStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    department: (f = msg.getDepartment()) && api_dashboard_v2_department_message_pb.Department.toObject(includeInstance, f),
    site: (f = msg.getSite()) && api_dashboard_v2_site_message_pb.Site.toObject(includeInstance, f),
    currentProgram: (f = msg.getCurrentProgram()) && proto.users.UserProgram.toObject(includeInstance, f),
    pastProgramsList: jspb.Message.toObjectList(msg.getPastProgramsList(),
    proto.users.UserProgram.toObject, includeInstance),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    nickname: jspb.Message.getFieldWithDefault(msg, 14, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 15, ""),
    tier: (f = msg.getTier()) && api_dashboard_v2_tier_message_pb.MessageTier.toObject(includeInstance, f),
    jobRole: (f = msg.getJobRole()) && api_dashboard_v2_job_role_message_pb.JobRole.toObject(includeInstance, f),
    note: (f = msg.getNote()) && api_dashboard_v2_user_note_message_pb.UserNote.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserResponse}
 */
proto.users.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserResponse;
  return proto.users.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserResponse}
 */
proto.users.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRegisteredAtInMilliseconds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivityInMilliseconds(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserStatus(value);
      break;
    case 9:
      var value = new api_dashboard_v2_department_message_pb.Department;
      reader.readMessage(value,api_dashboard_v2_department_message_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    case 10:
      var value = new api_dashboard_v2_site_message_pb.Site;
      reader.readMessage(value,api_dashboard_v2_site_message_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 11:
      var value = new proto.users.UserProgram;
      reader.readMessage(value,proto.users.UserProgram.deserializeBinaryFromReader);
      msg.setCurrentProgram(value);
      break;
    case 12:
      var value = new proto.users.UserProgram;
      reader.readMessage(value,proto.users.UserProgram.deserializeBinaryFromReader);
      msg.addPastPrograms(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 16:
      var value = new api_dashboard_v2_tier_message_pb.MessageTier;
      reader.readMessage(value,api_dashboard_v2_tier_message_pb.MessageTier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 17:
      var value = new api_dashboard_v2_job_role_message_pb.JobRole;
      reader.readMessage(value,api_dashboard_v2_job_role_message_pb.JobRole.deserializeBinaryFromReader);
      msg.setJobRole(value);
      break;
    case 18:
      var value = new api_dashboard_v2_user_note_message_pb.UserNote;
      reader.readMessage(value,api_dashboard_v2_user_note_message_pb.UserNote.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRegisteredAtInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLastActivityInMilliseconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUserStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      api_dashboard_v2_department_message_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      api_dashboard_v2_site_message_pb.Site.serializeBinaryToWriter
    );
  }
  f = message.getCurrentProgram();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.users.UserProgram.serializeBinaryToWriter
    );
  }
  f = message.getPastProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.users.UserProgram.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      api_dashboard_v2_tier_message_pb.MessageTier.serializeBinaryToWriter
    );
  }
  f = message.getJobRole();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      api_dashboard_v2_job_role_message_pb.JobRole.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      api_dashboard_v2_user_note_message_pb.UserNote.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.users.UserResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.users.UserResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.users.UserResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string job_title = 4;
 * @return {string}
 */
proto.users.UserResponse.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_type = 5;
 * @return {string}
 */
proto.users.UserResponse.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 registered_at_in_milliseconds = 6;
 * @return {number}
 */
proto.users.UserResponse.prototype.getRegisteredAtInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setRegisteredAtInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 last_activity_in_milliseconds = 7;
 * @return {number}
 */
proto.users.UserResponse.prototype.getLastActivityInMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setLastActivityInMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string user_status = 8;
 * @return {string}
 */
proto.users.UserResponse.prototype.getUserStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setUserStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional department_message.Department department = 9;
 * @return {?proto.department_message.Department}
 */
proto.users.UserResponse.prototype.getDepartment = function() {
  return /** @type{?proto.department_message.Department} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_department_message_pb.Department, 9));
};


/**
 * @param {?proto.department_message.Department|undefined} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserResponse.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional site_message.Site site = 10;
 * @return {?proto.site_message.Site}
 */
proto.users.UserResponse.prototype.getSite = function() {
  return /** @type{?proto.site_message.Site} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_site_message_pb.Site, 10));
};


/**
 * @param {?proto.site_message.Site|undefined} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional UserProgram current_program = 11;
 * @return {?proto.users.UserProgram}
 */
proto.users.UserResponse.prototype.getCurrentProgram = function() {
  return /** @type{?proto.users.UserProgram} */ (
    jspb.Message.getWrapperField(this, proto.users.UserProgram, 11));
};


/**
 * @param {?proto.users.UserProgram|undefined} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setCurrentProgram = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearCurrentProgram = function() {
  return this.setCurrentProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserResponse.prototype.hasCurrentProgram = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated UserProgram past_programs = 12;
 * @return {!Array<!proto.users.UserProgram>}
 */
proto.users.UserResponse.prototype.getPastProgramsList = function() {
  return /** @type{!Array<!proto.users.UserProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.UserProgram, 12));
};


/**
 * @param {!Array<!proto.users.UserProgram>} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setPastProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.users.UserProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.UserProgram}
 */
proto.users.UserResponse.prototype.addPastPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.users.UserProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearPastProgramsList = function() {
  return this.setPastProgramsList([]);
};


/**
 * optional bool active = 13;
 * @return {boolean}
 */
proto.users.UserResponse.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string nickname = 14;
 * @return {string}
 */
proto.users.UserResponse.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string timezone = 15;
 * @return {string}
 */
proto.users.UserResponse.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional tier_message.MessageTier tier = 16;
 * @return {?proto.tier_message.MessageTier}
 */
proto.users.UserResponse.prototype.getTier = function() {
  return /** @type{?proto.tier_message.MessageTier} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_tier_message_pb.MessageTier, 16));
};


/**
 * @param {?proto.tier_message.MessageTier|undefined} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserResponse.prototype.hasTier = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional job_role.JobRole job_role = 17;
 * @return {?proto.job_role.JobRole}
 */
proto.users.UserResponse.prototype.getJobRole = function() {
  return /** @type{?proto.job_role.JobRole} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_job_role_message_pb.JobRole, 17));
};


/**
 * @param {?proto.job_role.JobRole|undefined} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setJobRole = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearJobRole = function() {
  return this.setJobRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserResponse.prototype.hasJobRole = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional user_note_message.UserNote note = 18;
 * @return {?proto.user_note_message.UserNote}
 */
proto.users.UserResponse.prototype.getNote = function() {
  return /** @type{?proto.user_note_message.UserNote} */ (
    jspb.Message.getWrapperField(this, api_dashboard_v2_user_note_message_pb.UserNote, 18));
};


/**
 * @param {?proto.user_note_message.UserNote|undefined} value
 * @return {!proto.users.UserResponse} returns this
*/
proto.users.UserResponse.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.users.UserResponse} returns this
 */
proto.users.UserResponse.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.users.UserResponse.prototype.hasNote = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserRequest}
 */
proto.users.UserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserRequest;
  return proto.users.UserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserRequest}
 */
proto.users.UserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserRequest} returns this
 */
proto.users.UserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    departmentId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserDepartmentRequest}
 */
proto.users.UserDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserDepartmentRequest;
  return proto.users.UserDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserDepartmentRequest}
 */
proto.users.UserDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartmentId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserDepartmentRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserDepartmentRequest} returns this
 */
proto.users.UserDepartmentRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 department_id = 2;
 * @return {number}
 */
proto.users.UserDepartmentRequest.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserDepartmentRequest} returns this
 */
proto.users.UserDepartmentRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserSiteRequest}
 */
proto.users.UserSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserSiteRequest;
  return proto.users.UserSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserSiteRequest}
 */
proto.users.UserSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserSiteRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserSiteRequest} returns this
 */
proto.users.UserSiteRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 site_id = 2;
 * @return {number}
 */
proto.users.UserSiteRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserSiteRequest} returns this
 */
proto.users.UserSiteRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserJobTitleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserJobTitleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserJobTitleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserJobTitleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserJobTitleRequest}
 */
proto.users.UserJobTitleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserJobTitleRequest;
  return proto.users.UserJobTitleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserJobTitleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserJobTitleRequest}
 */
proto.users.UserJobTitleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserJobTitleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserJobTitleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserJobTitleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserJobTitleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserJobTitleRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserJobTitleRequest} returns this
 */
proto.users.UserJobTitleRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string job_title = 2;
 * @return {string}
 */
proto.users.UserJobTitleRequest.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserJobTitleRequest} returns this
 */
proto.users.UserJobTitleRequest.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserSetProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserSetProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserSetProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserSetProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserSetProgramRequest}
 */
proto.users.UserSetProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserSetProgramRequest;
  return proto.users.UserSetProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserSetProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserSetProgramRequest}
 */
proto.users.UserSetProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserSetProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserSetProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserSetProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserSetProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserSetProgramRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserSetProgramRequest} returns this
 */
proto.users.UserSetProgramRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 program_id = 2;
 * @return {number}
 */
proto.users.UserSetProgramRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserSetProgramRequest} returns this
 */
proto.users.UserSetProgramRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserActivationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserActivationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserActivationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserActivationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserActivationRequest}
 */
proto.users.UserActivationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserActivationRequest;
  return proto.users.UserActivationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserActivationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserActivationRequest}
 */
proto.users.UserActivationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserActivationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserActivationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserActivationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserActivationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserActivationRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserActivationRequest} returns this
 */
proto.users.UserActivationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.users.UserActivationRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.UserActivationRequest} returns this
 */
proto.users.UserActivationRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserUpdateUserPrivateDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserUpdateUserPrivateDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserUpdateUserPrivateDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserUpdateUserPrivateDataRequest}
 */
proto.users.UserUpdateUserPrivateDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserUpdateUserPrivateDataRequest;
  return proto.users.UserUpdateUserPrivateDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserUpdateUserPrivateDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserUpdateUserPrivateDataRequest}
 */
proto.users.UserUpdateUserPrivateDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserUpdateUserPrivateDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserUpdateUserPrivateDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserUpdateUserPrivateDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserUpdateUserPrivateDataRequest} returns this
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserUpdateUserPrivateDataRequest} returns this
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserUpdateUserPrivateDataRequest} returns this
 */
proto.users.UserUpdateUserPrivateDataRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.DeviceLinkHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.users.DeviceLinkHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.DeviceLinkHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DeviceLinkHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.DeviceLinkHistory}
 */
proto.users.DeviceLinkHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.DeviceLinkHistory;
  return proto.users.DeviceLinkHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.DeviceLinkHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.DeviceLinkHistory}
 */
proto.users.DeviceLinkHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.DeviceLinkHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.DeviceLinkHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.DeviceLinkHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DeviceLinkHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.users.DeviceLinkHistory.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.DeviceLinkHistory} returns this
 */
proto.users.DeviceLinkHistory.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.users.DeviceLinkHistory.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.DeviceLinkHistory} returns this
 */
proto.users.DeviceLinkHistory.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.users.DeviceLinkHistory.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.DeviceLinkHistory} returns this
 */
proto.users.DeviceLinkHistory.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string job_title = 5;
 * @return {string}
 */
proto.users.DeviceLinkHistory.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.DeviceLinkHistory} returns this
 */
proto.users.DeviceLinkHistory.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.users.DeviceLinkHistory.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.DeviceLinkHistory} returns this
 */
proto.users.DeviceLinkHistory.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.users.DeviceLinkHistoryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.DeviceLinkHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.DeviceLinkHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.DeviceLinkHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DeviceLinkHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deviceLinkHistoryList: jspb.Message.toObjectList(msg.getDeviceLinkHistoryList(),
    proto.users.DeviceLinkHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.DeviceLinkHistoryResponse}
 */
proto.users.DeviceLinkHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.DeviceLinkHistoryResponse;
  return proto.users.DeviceLinkHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.DeviceLinkHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.DeviceLinkHistoryResponse}
 */
proto.users.DeviceLinkHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new proto.users.DeviceLinkHistory;
      reader.readMessage(value,proto.users.DeviceLinkHistory.deserializeBinaryFromReader);
      msg.addDeviceLinkHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.DeviceLinkHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.DeviceLinkHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.DeviceLinkHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DeviceLinkHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeviceLinkHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.users.DeviceLinkHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 device_id = 1;
 * @return {number}
 */
proto.users.DeviceLinkHistoryResponse.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.DeviceLinkHistoryResponse} returns this
 */
proto.users.DeviceLinkHistoryResponse.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated DeviceLinkHistory device_link_history = 2;
 * @return {!Array<!proto.users.DeviceLinkHistory>}
 */
proto.users.DeviceLinkHistoryResponse.prototype.getDeviceLinkHistoryList = function() {
  return /** @type{!Array<!proto.users.DeviceLinkHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.users.DeviceLinkHistory, 2));
};


/**
 * @param {!Array<!proto.users.DeviceLinkHistory>} value
 * @return {!proto.users.DeviceLinkHistoryResponse} returns this
*/
proto.users.DeviceLinkHistoryResponse.prototype.setDeviceLinkHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.users.DeviceLinkHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.users.DeviceLinkHistory}
 */
proto.users.DeviceLinkHistoryResponse.prototype.addDeviceLinkHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.users.DeviceLinkHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.users.DeviceLinkHistoryResponse} returns this
 */
proto.users.DeviceLinkHistoryResponse.prototype.clearDeviceLinkHistoryList = function() {
  return this.setDeviceLinkHistoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.DeviceLinkHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.DeviceLinkHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.DeviceLinkHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DeviceLinkHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.DeviceLinkHistoryRequest}
 */
proto.users.DeviceLinkHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.DeviceLinkHistoryRequest;
  return proto.users.DeviceLinkHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.DeviceLinkHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.DeviceLinkHistoryRequest}
 */
proto.users.DeviceLinkHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.DeviceLinkHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.DeviceLinkHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.DeviceLinkHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.DeviceLinkHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 device_id = 1;
 * @return {number}
 */
proto.users.DeviceLinkHistoryRequest.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.DeviceLinkHistoryRequest} returns this
 */
proto.users.DeviceLinkHistoryRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserCreateClipGoUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserCreateClipGoUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserCreateClipGoUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserCreateClipGoUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    login: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    department: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    password: jspb.Message.getFieldWithDefault(msg, 8, ""),
    site: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserCreateClipGoUserRequest}
 */
proto.users.UserCreateClipGoUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserCreateClipGoUserRequest;
  return proto.users.UserCreateClipGoUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserCreateClipGoUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserCreateClipGoUserRequest}
 */
proto.users.UserCreateClipGoUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserCreateClipGoUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserCreateClipGoUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserCreateClipGoUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserCreateClipGoUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSite();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string login = 3;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 company_id = 4;
 * @return {number}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string department = 5;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string job_title = 6;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string site = 9;
 * @return {string}
 */
proto.users.UserCreateClipGoUserRequest.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserCreateClipGoUserRequest} returns this
 */
proto.users.UserCreateClipGoUserRequest.prototype.setSite = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.UserServiceCreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.UserServiceCreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.UserServiceCreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserServiceCreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    login: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    department: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    password: jspb.Message.getFieldWithDefault(msg, 8, ""),
    site: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.UserServiceCreateUserRequest}
 */
proto.users.UserServiceCreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.UserServiceCreateUserRequest;
  return proto.users.UserServiceCreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.UserServiceCreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.UserServiceCreateUserRequest}
 */
proto.users.UserServiceCreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.UserServiceCreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.UserServiceCreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.UserServiceCreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.UserServiceCreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSite();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string login = 3;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 company_id = 4;
 * @return {number}
 */
proto.users.UserServiceCreateUserRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string department = 5;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string job_title = 6;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string site = 9;
 * @return {string}
 */
proto.users.UserServiceCreateUserRequest.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.UserServiceCreateUserRequest} returns this
 */
proto.users.UserServiceCreateUserRequest.prototype.setSite = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.CreateDashboardFeedbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.users.CreateDashboardFeedbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.CreateDashboardFeedbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.CreateDashboardFeedbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.CreateDashboardFeedbackRequest}
 */
proto.users.CreateDashboardFeedbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.CreateDashboardFeedbackRequest;
  return proto.users.CreateDashboardFeedbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.CreateDashboardFeedbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.CreateDashboardFeedbackRequest}
 */
proto.users.CreateDashboardFeedbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.CreateDashboardFeedbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.CreateDashboardFeedbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.CreateDashboardFeedbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.CreateDashboardFeedbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.users.CreateDashboardFeedbackRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.CreateDashboardFeedbackRequest} returns this
 */
proto.users.CreateDashboardFeedbackRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.users.CreateDashboardFeedbackRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.CreateDashboardFeedbackRequest} returns this
 */
proto.users.CreateDashboardFeedbackRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.users.CreateDashboardFeedbackRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.users.CreateDashboardFeedbackRequest} returns this
 */
proto.users.CreateDashboardFeedbackRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 rate = 4;
 * @return {number}
 */
proto.users.CreateDashboardFeedbackRequest.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.users.CreateDashboardFeedbackRequest} returns this
 */
proto.users.CreateDashboardFeedbackRequest.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.users.CreateDashboardFeedbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.users.CreateDashboardFeedbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.users.CreateDashboardFeedbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.CreateDashboardFeedbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.users.CreateDashboardFeedbackResponse}
 */
proto.users.CreateDashboardFeedbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.users.CreateDashboardFeedbackResponse;
  return proto.users.CreateDashboardFeedbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.users.CreateDashboardFeedbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.users.CreateDashboardFeedbackResponse}
 */
proto.users.CreateDashboardFeedbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.users.CreateDashboardFeedbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.users.CreateDashboardFeedbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.users.CreateDashboardFeedbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.users.CreateDashboardFeedbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.users.CreateDashboardFeedbackResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.users.CreateDashboardFeedbackResponse} returns this
 */
proto.users.CreateDashboardFeedbackResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.users);
