/**
 * @fileoverview gRPC-Web generated client stub for users
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: api/dashboard/v2/users_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_dashboard_v2_users_message_pb = require('../../../api/dashboard/v2/users_message_pb.js')
const proto = {};
proto.users = require('./users_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.users.UsersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.users.UsersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.ActivityPageRequest,
 *   !proto.users.UsersResponse>}
 */
const methodDescriptor_Users_GetActivityPageDateRange = new grpc.web.MethodDescriptor(
  '/users.Users/GetActivityPageDateRange',
  grpc.web.MethodType.UNARY,
  proto.users.ActivityPageRequest,
  proto.users.UsersResponse,
  /**
   * @param {!proto.users.ActivityPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UsersResponse.deserializeBinary
);


/**
 * @param {!proto.users.ActivityPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.getActivityPageDateRange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/GetActivityPageDateRange',
      request,
      metadata || {},
      methodDescriptor_Users_GetActivityPageDateRange,
      callback);
};


/**
 * @param {!proto.users.ActivityPageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UsersResponse>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.getActivityPageDateRange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/GetActivityPageDateRange',
      request,
      metadata || {},
      methodDescriptor_Users_GetActivityPageDateRange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UsersDepartmentRequest,
 *   !proto.users.UsersResponseStatus>}
 */
const methodDescriptor_Users_SetDepartment = new grpc.web.MethodDescriptor(
  '/users.Users/SetDepartment',
  grpc.web.MethodType.UNARY,
  proto.users.UsersDepartmentRequest,
  proto.users.UsersResponseStatus,
  /**
   * @param {!proto.users.UsersDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UsersResponseStatus.deserializeBinary
);


/**
 * @param {!proto.users.UsersDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UsersResponseStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UsersResponseStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.setDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/SetDepartment',
      request,
      metadata || {},
      methodDescriptor_Users_SetDepartment,
      callback);
};


/**
 * @param {!proto.users.UsersDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UsersResponseStatus>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.setDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/SetDepartment',
      request,
      metadata || {},
      methodDescriptor_Users_SetDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UsersSiteRequest,
 *   !proto.users.UsersResponseStatus>}
 */
const methodDescriptor_Users_SetSite = new grpc.web.MethodDescriptor(
  '/users.Users/SetSite',
  grpc.web.MethodType.UNARY,
  proto.users.UsersSiteRequest,
  proto.users.UsersResponseStatus,
  /**
   * @param {!proto.users.UsersSiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UsersResponseStatus.deserializeBinary
);


/**
 * @param {!proto.users.UsersSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UsersResponseStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UsersResponseStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.setSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/SetSite',
      request,
      metadata || {},
      methodDescriptor_Users_SetSite,
      callback);
};


/**
 * @param {!proto.users.UsersSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UsersResponseStatus>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.setSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/SetSite',
      request,
      metadata || {},
      methodDescriptor_Users_SetSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.UsersJobRoleRequest,
 *   !proto.users.UsersResponseStatus>}
 */
const methodDescriptor_Users_SetJobRole = new grpc.web.MethodDescriptor(
  '/users.Users/SetJobRole',
  grpc.web.MethodType.UNARY,
  proto.users.UsersJobRoleRequest,
  proto.users.UsersResponseStatus,
  /**
   * @param {!proto.users.UsersJobRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.UsersResponseStatus.deserializeBinary
);


/**
 * @param {!proto.users.UsersJobRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.UsersResponseStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.UsersResponseStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.setJobRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/SetJobRole',
      request,
      metadata || {},
      methodDescriptor_Users_SetJobRole,
      callback);
};


/**
 * @param {!proto.users.UsersJobRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.UsersResponseStatus>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.setJobRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/SetJobRole',
      request,
      metadata || {},
      methodDescriptor_Users_SetJobRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.CreateUsersRequest,
 *   !proto.users.CreateUsersResponse>}
 */
const methodDescriptor_Users_CreateUsers = new grpc.web.MethodDescriptor(
  '/users.Users/CreateUsers',
  grpc.web.MethodType.UNARY,
  proto.users.CreateUsersRequest,
  proto.users.CreateUsersResponse,
  /**
   * @param {!proto.users.CreateUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.CreateUsersResponse.deserializeBinary
);


/**
 * @param {!proto.users.CreateUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.CreateUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.CreateUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.createUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/CreateUsers',
      request,
      metadata || {},
      methodDescriptor_Users_CreateUsers,
      callback);
};


/**
 * @param {!proto.users.CreateUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.CreateUsersResponse>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.createUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/CreateUsers',
      request,
      metadata || {},
      methodDescriptor_Users_CreateUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.DeleteUsersRequest,
 *   !proto.users.DeleteUsersResponse>}
 */
const methodDescriptor_Users_DeleteUsers = new grpc.web.MethodDescriptor(
  '/users.Users/DeleteUsers',
  grpc.web.MethodType.UNARY,
  proto.users.DeleteUsersRequest,
  proto.users.DeleteUsersResponse,
  /**
   * @param {!proto.users.DeleteUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.DeleteUsersResponse.deserializeBinary
);


/**
 * @param {!proto.users.DeleteUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.DeleteUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.DeleteUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.deleteUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/DeleteUsers',
      request,
      metadata || {},
      methodDescriptor_Users_DeleteUsers,
      callback);
};


/**
 * @param {!proto.users.DeleteUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.DeleteUsersResponse>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.deleteUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/DeleteUsers',
      request,
      metadata || {},
      methodDescriptor_Users_DeleteUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.ActivityPageNewRequest,
 *   !proto.users.ActivityPageNewResponse>}
 */
const methodDescriptor_Users_NewGetActivityPageDateRange = new grpc.web.MethodDescriptor(
  '/users.Users/NewGetActivityPageDateRange',
  grpc.web.MethodType.UNARY,
  proto.users.ActivityPageNewRequest,
  proto.users.ActivityPageNewResponse,
  /**
   * @param {!proto.users.ActivityPageNewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.ActivityPageNewResponse.deserializeBinary
);


/**
 * @param {!proto.users.ActivityPageNewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.ActivityPageNewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.ActivityPageNewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.newGetActivityPageDateRange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/NewGetActivityPageDateRange',
      request,
      metadata || {},
      methodDescriptor_Users_NewGetActivityPageDateRange,
      callback);
};


/**
 * @param {!proto.users.ActivityPageNewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.ActivityPageNewResponse>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.newGetActivityPageDateRange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/NewGetActivityPageDateRange',
      request,
      metadata || {},
      methodDescriptor_Users_NewGetActivityPageDateRange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.users.EditUsersInfoRequest,
 *   !proto.users.EditUsersInfoResponse>}
 */
const methodDescriptor_Users_EditUsersInfo = new grpc.web.MethodDescriptor(
  '/users.Users/EditUsersInfo',
  grpc.web.MethodType.UNARY,
  proto.users.EditUsersInfoRequest,
  proto.users.EditUsersInfoResponse,
  /**
   * @param {!proto.users.EditUsersInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.users.EditUsersInfoResponse.deserializeBinary
);


/**
 * @param {!proto.users.EditUsersInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.users.EditUsersInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.users.EditUsersInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.users.UsersClient.prototype.editUsersInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/users.Users/EditUsersInfo',
      request,
      metadata || {},
      methodDescriptor_Users_EditUsersInfo,
      callback);
};


/**
 * @param {!proto.users.EditUsersInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.users.EditUsersInfoResponse>}
 *     Promise that resolves to the response
 */
proto.users.UsersPromiseClient.prototype.editUsersInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/users.Users/EditUsersInfo',
      request,
      metadata || {},
      methodDescriptor_Users_EditUsersInfo);
};


module.exports = proto.users;

