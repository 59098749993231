import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';

export const addButton = Emotion.css`
   {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      stroke: ${COLORS.LIGHT_BLUE[600]};
      transition: all 0.2s ease;
    }

    &:hover svg {
      stroke: ${COLORS.WHITE};
    }
  }
`;
