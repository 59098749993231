import React from 'react';
import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';
import { text } from 'assets/jss/style';
import { card } from 'assets/soter-flex-styles';
import { useTranslation } from 'react-i18next';
import { bodyPartNames } from '../video/constants';
import Legend from '../video/components/Legend';
import KonvaChart from '../video/components/KonvaChart';

const chartHeaderSlot = Emotion.css`
  flex-grow: 1;
  text-align: center;
  margin: 0 7px;
  padding: 8px 0;
`;

const chartBodyPartSlot = (isSelected) => Emotion.css`
  ${chartHeaderSlot};

  color: ${isSelected ? COLORS.LIGHT_BLUE[600] : COLORS.GRAY[400]};
  cursor: pointer;
`;

const totalTimeSlot = Emotion.css`
  ${chartHeaderSlot};
  ${text(COLORS.GRAY[950], 16)};
  flex-grow: 4;
  margin-right: 21px;
  text-align: right;
`;

const MovementDataDynamicLineChart = ({
  gridArea,
  videoWidth,
  chartHeight,
  bodyParts,
  selectedBodyPart,
  setSelectedBodyPart,
  totalTime,
  totalFrames,
  currentThreshold,
  currentTimeMs,
  videoLengthPixelRatio,
  chartAnglesData,
  chartAxisData,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={card}
      style={{
        gridArea,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {currentThreshold ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: videoWidth,
            }}
          >
            {bodyParts.map((part) => (
              <div
                key={part}
                className={chartBodyPartSlot(part === selectedBodyPart)}
                onClick={() => setSelectedBodyPart(part)}
              >
                {t(bodyPartNames[part])}
              </div>
            ))}
            <div className={totalTimeSlot}>
              {`${t('SOTER_TASK.ASSESSMENT.TOTAL_TIME')}: ${selectedBodyPart === bodyPartNames.reba.toLowerCase() ? totalFrames : totalTime}`}
            </div>
          </div>
          <Legend
            chartAnglesData={chartAnglesData}
            currentThreshold={currentThreshold}
            selectedBodyPart={selectedBodyPart}
          />
            <KonvaChart
              progressPosition={Math.round(
                currentTimeMs / videoLengthPixelRatio
              )}
              anglesData={chartAnglesData[selectedBodyPart]}
              currentTime={currentTimeMs}
              axisData={chartAxisData}
              videoWidth={videoWidth}
              chartHeight={chartHeight}
            />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default MovementDataDynamicLineChart;
