import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  itemBox, itemColorMark, itemName, legendRowWrapper,
} from 'assets/soter-flex-styles';
import Text from 'lib/ui/Text';

const StaticHazardLegend = ({ itemMap, hiddenFields = {} }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={legendRowWrapper}>
        {itemMap
          .filter(({ alias }) => !hiddenFields[alias])
          .sort(({ alias }, { alias: nextAlias }) => alias > nextAlias ? -1 : 1)
          .map(({ name, color }) => (
            <div className={itemBox} key={name}>
              <span className={itemColorMark(color)} />
              <Text
                text={t(name)}
                appearance="footnote"
                className={itemName}
                inline
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default StaticHazardLegend;
