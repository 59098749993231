import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { overviewEmptyData } from 'init/proto_client';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import {
  cardExtraSmall119,
  cardMediumWithHeight,
  columnExtraSmall,
  columnMedium,
  container,
  flexColumn,
  flexColumnFlowColumnAligned,
  flexRow,
  infoMainText,
  mb2,
  metricMainText,
  metricSubText,
  mt4,
} from 'assets/soter-flex-styles';
import capitalizeFirstLetter from 'lib/utils/capitalizeFirstLetter';
import { COLORS } from 'constants/colors';
import InlineSelect from './components/InlineSelect';
import { getConfigByDeviceType } from './utils/getConfigByDeviceType';
import { ChartType } from './useOverviewDataSource';
import { getAverageImprovement } from './utils/metricCalculationUtils';
import {
  OVERVIEW_ACTIVE_USERS_GROUPING_CHANGED,
  OVERVIEW_HAZARDS_PER_HOUR_GROUPING_BY_CHANGED,
  OVERVIEW_REGISTERED_USERS_GROUPING_CHANGED,
} from '../../constants/analyticsEvents';
import legendSquare from '../../assets/jss/components/legendSquare';
import { deviceTypes } from './constants/deviceTypes';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { deviceTypesUsersColorMap, deviceTypesUsersMap } from './constants/deviceTypesUsersMap';
import { hazardsEnum } from './constants/hazardsEnum';
import {
  ChartTooltips,
  GroupingCriteria,
  GroupingCriteriaByPeriod,
  infoCardMetricKeys,
  MetricNames,
  MetricTooltips,
} from './constants/constants';
import { createEventNotification } from '../../containers/NotificationContainer/notificationsStore/actions';
import { useBackForward } from './utils/useBackForward';
import InfoTooltip from '../../lib/ui/InfoTooltip';
import HMofSpineDonutChart from './components/charts/HMofSpineDonutChart';
import HMofShoulderDonutChart from './components/charts/HMofSHoulderDonutChart';
import HazardMovementByRiskGroupChart from './components/charts/HazardMovementByRiskGroupChart';
import StaticHazardLegend from './components/StaticHazardLegend';
import { riskColorMap } from './constants/riskColorMap';
import LayoutColumn from './components/LayoutColumn';
import InfoCard from '../../lib/ui/InfoCard';
import ArrowBackForwardSwitch from './components/ArrowBackForwardSwitch';
import ActiveUsersByPeriod from './components/charts/ActiveUsersByPeriod';
import LayoutRow from './components/LayoutRow';
import HazardByDepartmentByJobRoleChart from './components/charts/HazardByDepartmentByJobRoleChart';
import { hazardNameColorMap } from './constants/hazardNameColorMap';
import UserFeedbackChart from './components/charts/UserFeedbackChart';

const SectionLayoutDivider = ({ dividerText }) => (
  <div style={{ marginTop: '8px' }} className={flexColumn}>
    <p className={infoMainText}>{dividerText}</p>
  </div>
);
export const prepareGroupedDataForChart = (data = [], hazards) => {
  const dataWithNulls = [...data];
  dataWithNulls.forEach((dept) => {
    dept.overall = hazards.reduce((acc, hazard) => acc + dept[hazard], 0);
    dept.id = dept.title;
  });
  return dataWithNulls.filter(({ overall }) => overall);
};

export const prepareActiveUsers = (usersList, periodType) => usersList?.map((item) => {
  const currentYear = moment().format('YYYY');
  const itemYear = moment(item.dateRange.finishedAt).format('YYYY');
  const finishedDateFormat = currentYear === itemYear ? 'MMM DD' : 'MMM DD, YYYY';
  const i = { ...item };
  i.overall = Object.keys(item).reduce((acc, key) => key !== 'dateRange' && acc + item[key], 0);
  if (periodType === 'day') {
    i.title = `${moment.utc(item.dateRange.startedAt).format(finishedDateFormat)}`;
  } else {
    i.title = `${moment.utc(item.dateRange.startedAt).format('MMM DD')} - ${moment.utc(item.dateRange.finishedAt).format(finishedDateFormat)}`;
  }
  i.id = i.title;
  // delete i.dateRange;
  return i;
});

const OverviewContainer = ({
  data = overviewEmptyData, isClipgo = undefined, hiddenFields = undefined, setGrouping, companyConfig,
}) => {
  const {
    grouping,
  } = useAppSelector((state) => state.overviewState);
  const dispatch = useAppDispatch();
  const { period } = useAppSelector((state) => state.overview.overview);
  const { t } = useTranslation();
  const language = localStorage.getItem('language');
  const mapGroupingCriteriaForSelect = (criteria) => {
    const criteriaNames = Object.values(criteria);
    return criteriaNames.map((name) => ({
      value: name,
      label: t(name),
    }));
  };
  const spineConfig = getConfigByDeviceType(deviceTypes.bend, companyConfig);
  const shoulderConfig = getConfigByDeviceType(deviceTypes.armband, companyConfig);
  const activeUsersConfig = Object.keys(deviceTypesUsersMap).map((e) => t(e));
  const registeredUsersConfig = ['usersCount'];

  const spineHazards = Object.keys(hazardsEnum.spine);
  const spineHazardsByDepartment = prepareGroupedDataForChart(data?.spineHazardsByDepartmentList, spineHazards);
  const spineHazardsByJobRoles = prepareGroupedDataForChart(data?.spineHazardsByJobRoleList, spineHazards);
  const spineHazardsByTasks = prepareGroupedDataForChart(data?.spineHazardsByTaskList, spineHazards);
  const spineHazardsBySites = prepareGroupedDataForChart(data?.spineHazardsBySiteList, spineHazards);
  const shoulderHazards = Object.keys(hazardsEnum.shoulder);
  const shoulderHazardsByDepartment = prepareGroupedDataForChart(data?.shoulderHazardsByDepartmentList, shoulderHazards);
  const shoulderHazardsByJobRoles = prepareGroupedDataForChart(data?.shoulderHazardsByJobRoleList, shoulderHazards);
  const shoulderHazardsByTasks = prepareGroupedDataForChart(data?.shoulderHazardsByTaskList, shoulderHazards);
  const shoulderHazardsBySites = prepareGroupedDataForChart(data?.shoulderHazardsBySiteList, shoulderHazards);

  const activeUsersByMonth = prepareActiveUsers(data?.activeUsersDate?.monthsList);
  const activeUsersByWeek = prepareActiveUsers(data?.activeUsersDate?.weeksList);
  const activeUsersByDay = prepareActiveUsers(data?.activeUsersDate?.daysList, 'day');
  const registeredUsersByMonth = prepareActiveUsers(data?.registeredUsers?.monthsList);
  const registeredUsersByWeek = prepareActiveUsers(data?.registeredUsers?.weeksList);
  const registeredUsersByDay = prepareActiveUsers(data?.registeredUsers?.daysList, 'day');

  const maxBarsQuantity = 8;
  const selectGroupingCriteria = mapGroupingCriteriaForSelect(GroupingCriteria);
  const periodSelectGroupingCriteria = mapGroupingCriteriaForSelect(GroupingCriteriaByPeriod);
  const activeUsersGrouping = grouping[ChartType.ACTIVE_USERS_CHART];
  const registeredUsersGrouping = grouping[ChartType.REGISTERED_USERS_CHART];
  const [selectedSpineOption, setSelectedSpineOption] = useState('WEARABLES.OVERVIEW.BY_DEPARTMENT');
  const [selectedShoulderOption, setSelectedShoulderOption] = useState('WEARABLES.OVERVIEW.BY_DEPARTMENT');
  const regroupRegisteredUsers = (value) => {
    window.logEvent(OVERVIEW_REGISTERED_USERS_GROUPING_CHANGED);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(OVERVIEW_REGISTERED_USERS_GROUPING_CHANGED));
    }
    setGrouping(value, ChartType.REGISTERED_USERS_CHART);
  };
  const regroupActiveUsers = (value) => {
    window.logEvent(OVERVIEW_ACTIVE_USERS_GROUPING_CHANGED);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(OVERVIEW_ACTIVE_USERS_GROUPING_CHANGED));
    }
    setGrouping(value, ChartType.ACTIVE_USERS_CHART);
  };
  const regroupSpineHMPH = (value) => {
    window.logEvent(OVERVIEW_HAZARDS_PER_HOUR_GROUPING_BY_CHANGED);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(OVERVIEW_HAZARDS_PER_HOUR_GROUPING_BY_CHANGED));
    }
    setGrouping(value, ChartType.SPINE_CHART);
    setSelectedSpineOption(value);
  };
  const regroupShoulderHMPH = (value) => {
    window.logEvent(OVERVIEW_HAZARDS_PER_HOUR_GROUPING_BY_CHANGED);
    if (localStorage.getItem('showEvents')) {
      dispatch(createEventNotification(OVERVIEW_HAZARDS_PER_HOUR_GROUPING_BY_CHANGED));
    }
    setGrouping(value, ChartType.SHOULDER_CHART);
    setSelectedShoulderOption(value);
  };
  const spineHMPHGrouping = grouping[ChartType.SPINE_CHART];
  const spineGroupingLengths = {
    [GroupingCriteria.BY_DEPARTMENT]: spineHazardsByDepartment?.length,
    [GroupingCriteria.BY_JOB_ROLE]: spineHazardsByJobRoles?.length,
    [GroupingCriteria.BY_TASK]: spineHazardsByTasks?.length,
    [GroupingCriteria.BY_SITE]: spineHazardsBySites?.length,
  };
  const getDataForShowModeSpine = (spineGrouping) => {
    switch (spineGrouping) {
      case GroupingCriteria.BY_DEPARTMENT:
        return spineHazardsByDepartment;
      case GroupingCriteria.BY_JOB_ROLE:
        return spineHazardsByJobRoles;
      case GroupingCriteria.BY_TASK:
        return spineHazardsByTasks;
      case GroupingCriteria.BY_SITE:
        return spineHazardsBySites;
      default:
        return null;
    }
  };
  const dataForShowModeSpine = getDataForShowModeSpine(spineHMPHGrouping);
  const shoulderHMPHGrouping = grouping[ChartType.SHOULDER_CHART];
  const shoulderGroupingLengths = {
    [GroupingCriteria.BY_DEPARTMENT]: shoulderHazardsByDepartment?.length,
    [GroupingCriteria.BY_JOB_ROLE]: shoulderHazardsByJobRoles?.length,
    [GroupingCriteria.BY_TASK]: shoulderHazardsByTasks?.length,
    [GroupingCriteria.BY_SITE]: shoulderHazardsBySites?.length,
  };
  const getDataForShowModeShoulder = (shoulderGrouping) => {
    switch (shoulderGrouping) {
      case GroupingCriteria.BY_DEPARTMENT:
        return shoulderHazardsByDepartment;
      case GroupingCriteria.BY_JOB_ROLE:
        return shoulderHazardsByJobRoles;
      case GroupingCriteria.BY_TASK:
        return shoulderHazardsByTasks;
      case GroupingCriteria.BY_SITE:
        return shoulderHazardsBySites;
      default:
        return null;
    }
  };
  const dataForShowModeShoulder = getDataForShowModeShoulder(shoulderHMPHGrouping);
  const [
    couldShoulderHMPHStepBack,
    onShoulderHMPHStepBack,
    couldShoulderHMPHStepForward,
    onShoulderHMPHStepForward,
    shoulderPeriod,
    setShoulderInitialRange] = useBackForward({
    from: 0,
    to: 8,
  }, shoulderGroupingLengths[selectedShoulderOption]);
  const [
    couldSpineHMPHStepBack,
    onSpineHMPHStepBack,
    couldSpineHMPHStepForward,
    onSpineHMPHStepForward,
    spinePeriod,
    setSpineInitialRange] = useBackForward({
    from: 0,
    to: 8,
  }, spineGroupingLengths[selectedSpineOption]);
  const activeUsers = activeUsersGrouping === GroupingCriteriaByPeriod.BY_MONTH ? activeUsersByMonth
    : activeUsersGrouping === GroupingCriteriaByPeriod.BY_WEEK ? activeUsersByWeek : activeUsersByDay;
  const registeredUsers = registeredUsersGrouping === GroupingCriteriaByPeriod.BY_MONTH ? registeredUsersByMonth
    : registeredUsersGrouping === GroupingCriteriaByPeriod.BY_WEEK ? registeredUsersByWeek : registeredUsersByDay;

  const [
    canActiveUsersStepBack,
    onActiveUsersStepBack,
    canActiveUsersStepForward,
    onActiveUsersStepForward,
    activeUsersPeriod,
    setActiveUsersInitialRange,
  ] = useBackForward({
    from: Math.max(activeUsers?.length - 8, 0),
    to: activeUsers?.length,
  },
  activeUsersGrouping === GroupingCriteriaByPeriod.BY_MONTH ? activeUsersByMonth?.length
    : activeUsersGrouping === GroupingCriteriaByPeriod.BY_WEEK ? activeUsersByWeek?.length : activeUsersByDay?.length);
  const [
    canRegisteredUsersStepBack,
    onRegisteredUsersStepBack,
    canRegisteredUsersStepForward,
    onRegisteredUsersStepForward,
    registeredUsersPeriod,
    setRegisteredUsersInitialRange,
  ] = useBackForward({
    from: Math.max(registeredUsers?.length - 8, 0),
    to: registeredUsers?.length,
  },
  registeredUsersGrouping === GroupingCriteriaByPeriod.BY_MONTH ? registeredUsersByMonth?.length
    : registeredUsersGrouping === GroupingCriteriaByPeriod.BY_WEEK ? registeredUsersByWeek?.length : registeredUsersByDay?.length);

  useEffect(() => { regroupSpineHMPH(spineHMPHGrouping); }, []); // the page loads endlessly
  useEffect(() => {
    setActiveUsersInitialRange();
  }, [grouping.activeUsers, period]);
  useEffect(() => {
    setRegisteredUsersInitialRange();
  }, [grouping.registeredUsers, period]);
  useEffect(() => {
    setSpineInitialRange();
  }, [grouping.spineChart, period]);
  useEffect(() => {
    setShoulderInitialRange();
  }, [grouping.shoulderChart, period]);
  const metricValue = {
    [MetricNames.SESSION_COMPLETED]: data.completedSession?.value || 0,
    [MetricNames.IMPROVEMENT]: getAverageImprovement(data.spineHazardsChangeByRisk),
    [MetricNames.IMPROVEMENT_SHOULDER]: getAverageImprovement(data.shoulderHazardsChangeByRisk),
    [MetricNames.ACTIVE_USERS]: data.activeUsers?.value || 0,
    [MetricNames.NEW_USERS]: data.newUsers?.value || 0,
    [MetricNames.USERS_WHO_FINISHED_PROGRAM]: data.finishedUsers?.value || 0,
  };
  const getImprovementStyle = (value) => {
    // if (value > 0) {
    //   return { color: '#F6650A' };
    // }
    if (value < 0) {
      return { color: '#01a793' };
    }
    return { color: COLORS.GRAY[950] };
  };
  const getMetricPresentation = (metricName) => (
    metricName === MetricNames.IMPROVEMENT || metricName === MetricNames.IMPROVEMENT_SHOULDER
      ? metricValue[metricName] === 1 ? t('GENERAL.NA') : `${Math.abs(metricValue[metricName])}%`
      : metricValue[metricName]
  );
  const getAdditionalMetricStyle = (metricName) => (
    metricName === MetricNames.IMPROVEMENT || metricName === MetricNames.IMPROVEMENT_SHOULDER
      ? getImprovementStyle(metricValue[metricName])
      : null
  );
  const infoCards = infoCardMetricKeys(isClipgo).map((key) => {
    const metricName = MetricNames[key];
    return ({
      id: metricName,
      content: (
        <div
          className={`${flexColumnFlowColumnAligned} ${mt4}`}
          style={{ justifyContent: 'start', position: 'relative' }}
        >
          <InfoTooltip text={MetricTooltips[key]} isAbs />
          <p
            className={metricMainText}
            style={getAdditionalMetricStyle(metricName)}
          >
            {getMetricPresentation(metricName)}
          </p>
          <p className={metricSubText}>{t(metricName)}</p>
        </div>
      ),
    });
  });
  const avgHMofSpine = { ...data.hazardsOfSpine };
  delete avgHMofSpine.duration;
  const avgHMofShoulder = { ...data.hazardsOfShoulder };
  delete avgHMofShoulder.duration;

  const avgHazardChangeByRisk = data.spineHazardsChangeByRisk;
  const avgHazardChangeByRiskShoulder = data.shoulderHazardsChangeByRisk;

  const hmCharts = [
    {
      id: 'WEARABLES.OVERVIEW.HM_CHART_NAMES.HM_OF_SPINE',
      title: t('WEARABLES.OVERVIEW.HM_CHART_NAMES.HM_OF_SPINE'),
      tooltip: 'SPINE',
      content: <HMofSpineDonutChart
        // Temporary remove tier filtration to make it work
        // HMofSpineByDepartmentByPeriod={getFilteredByTierData(avgHMofSpine, hiddenFields)}
        HMofSpineByDepartmentByPeriod={avgHMofSpine}
        containerHeight={357}
        hasDataForChart={!!avgHMofSpine.total}
      />,
    },
    {
      id: 'WEARABLES.OVERVIEW.HM_CHART_NAMES.HM_OF_SHOULDER',
      title: t('WEARABLES.OVERVIEW.HM_CHART_NAMES.HM_OF_SHOULDER'),
      tooltip: 'SHOULDER',
      content: <HMofShoulderDonutChart
        // Temporary remove tier filtration to make it work
        // HMofShoulderByDepartmentByPeriod={getFilteredByTierData(avgHMofShoulder, hiddenFields)}
        HMofShoulderByDepartmentByPeriod={avgHMofShoulder}
        containerHeight={357}
        hasDataForChart={!!avgHMofShoulder.total}
      />,
    },
  ];

  const changeAndZoneCharts = [
    {
      id: t('WEARABLES.OVERVIEW.HM_CHART_NAMES.PER_HOUR_CHANGE_BY_RISK_GROUP'),
      title: t('WEARABLES.OVERVIEW.HM_CHART_NAMES.PER_HOUR_CHANGE_BY_RISK_GROUP').toUpperCase(),
      tooltip: 'CHANGE_SPINE',
      content: (
        <>
          <HazardMovementByRiskGroupChart
            chartId="hm-by-risk-group"
            avgHazardChangeByRisk={avgHazardChangeByRisk}
            containerHeight={240}
          />
          <div>
            <StaticHazardLegend itemMap={riskColorMap} centered />
          </div>
        </>),
    },
    {
      id: t('WEARABLES.OVERVIEW.HM_CHART_NAMES.PER_HOUR_CHANGE_BY_RISK_GROUP_SHOULDER'),
      title: t('WEARABLES.OVERVIEW.HM_CHART_NAMES.PER_HOUR_CHANGE_BY_RISK_GROUP_SHOULDER').toUpperCase(),
      tooltip: 'CHANGE_SHOULDER',
      content: (
        <>
          <HazardMovementByRiskGroupChart
            chartId="hm-by-risk-group-shoulder"
            avgHazardChangeByRisk={avgHazardChangeByRiskShoulder}
            containerHeight={240}
          />
          <div>
            <StaticHazardLegend itemMap={riskColorMap} centered />
          </div>
        </>),
    },
  ];

  const setTooltip = (itemsMap, itemName, headerName) => (data, overall) => {
    const amountOfWorkers = itemName === 'users' ? overall : undefined;
    const header = `<div style="text-align: left"><div style="font-size: 16px">${headerName}</div><div style="font-weight: 700; font-size: 20px">${data.data.title}</div></div>`;
    const overallItem = itemName === 'users' ? '' : `<div style="text-align: left"><div style="font-size: 16px">${t('WEARABLES.OVERVIEW.OVERALL')}</div><div style="font-weight: 700; font-size: 20px">${overall.toFixed(1)} ${itemName === 'hazards' ? t('WEARABLES.USER_PAGE.HAZARDS').toLowerCase() : itemName === 'users' ? t('NAVBAR.USERS').toLowerCase() : itemName}</div></div>`;
    const amount = `<div style="margin-bottom: 7px; text-align: left"><div style="font-size: 16px; ${amountOfWorkers ? '' : 'display: none'}">${t('WEARABLES.OVERVIEW.AMOUNT_WORKERS')}</div><div style="font-weight: 700; font-size: 20px; ${amountOfWorkers ? '' : 'display: none'}">${amountOfWorkers}</div></div>`;
    const items = itemsMap.map((item) => `<div><div style="display: flex; justify-content: flex-start; align-items: center"><div style="display: inline"><span class="${legendSquare(item.color)}"/></div><div style="display: inline; font-size: 16px">${t(item.name)}</div></div><div style="font-weight: 700; font-size: 20px; text-align: left">${data.data[item.alias]} ${itemName === 'hazards' ? t('WEARABLES.USER_PAGE.HAZARDS').toLowerCase() : itemName === 'users' ? t('NAVBAR.USERS').toLowerCase() : itemName}</div></div>`);
    return [header, amount, ...items, overallItem];
  };
  const extraHeight = Emotion.css`{
    height: 149px;
  }`;
  return (
    <LayoutColumn className={flexColumn}>
      <div className={container}>
        <div className={flexRow}>
          {infoCards.map(
            (card) => (
              <LayoutColumn columnClass={columnExtraSmall} key={card.id}>
                <InfoCard
                  cardClass={`${cardExtraSmall119} ${language === 'fr' && extraHeight}`}
                >
                  {card.content}
                </InfoCard>
              </LayoutColumn>
            ),
          )}
        </div>
        <div className={`${flexRow} ${mb2}`}>
          <SectionLayoutDivider dividerText={t('WEARABLES.OVERVIEW.USER_ACTIVITY')} />
        </div>
        <div className={flexRow}>
          <LayoutColumn columnClass={columnMedium}>
            <InfoCard
              cardClass={cardMediumWithHeight(365)}
              title={t('WEARABLES.OVERVIEW.NEW_USERS')}
              titleInlineControl={(
                <InlineSelect
                  data={periodSelectGroupingCriteria}
                  option={registeredUsersGrouping}
                  changeOption={(v) => {
                    regroupRegisteredUsers(v);
                    setRegisteredUsersInitialRange();
                  }}
                  searchable={false}
                  appearance="default"
                />
              )}
              rightCornerControl={(
                <ArrowBackForwardSwitch
                  couldStepBack={canRegisteredUsersStepBack}
                  couldStepForward={canRegisteredUsersStepForward}
                  onStepBack={onRegisteredUsersStepBack}
                  onStepForward={onRegisteredUsersStepForward}
                />
              )}
              additionalStyles={{}}
            >
              <InfoTooltip text={t(`${ChartTooltips.REGISTERED_USERS}`)} forGrouped isAbs />
              <ActiveUsersByPeriod
                chartId="registered-users-chart"
                config={registeredUsersConfig}
                data={registeredUsers && _sortBy([...registeredUsers], ['dateRange.finishedAt'])}
                setTooltip={setTooltip([], 'users', capitalizeFirstLetter(t(registeredUsersGrouping).slice(3)))}
                period={registeredUsersPeriod}
              />
            </InfoCard>
          </LayoutColumn>
          <LayoutColumn columnClass={columnMedium}>
            <InfoCard
              cardClass={cardMediumWithHeight(365)}
              title={t('WEARABLES.OVERVIEW.ACTIVE_USERS')}
              titleInlineControl={(
                <InlineSelect
                  data={periodSelectGroupingCriteria}
                  option={activeUsersGrouping}
                  changeOption={(v) => {
                    regroupActiveUsers(v);
                    setActiveUsersInitialRange();
                  }}
                  searchable={false}
                  appearance="default"
                />
              )}
              rightCornerControl={(
                <ArrowBackForwardSwitch
                  couldStepBack={canActiveUsersStepBack}
                  couldStepForward={canActiveUsersStepForward}
                  onStepBack={onActiveUsersStepBack}
                  onStepForward={onActiveUsersStepForward}
                />
              )}
              additionalStyles={{}}
            >
              <InfoTooltip text={t(`${ChartTooltips.ACTIVE_USERS}`)} forGrouped isAbs />
              <ActiveUsersByPeriod
                chartId="active-users-chart"
                config={activeUsersConfig}
                data={activeUsers && _sortBy([...activeUsers], ['dateRange.finishedAt'])}
                setTooltip={setTooltip(deviceTypesUsersColorMap, 'users', capitalizeFirstLetter(t(activeUsersGrouping).slice(3)))}
                period={activeUsersPeriod}
              />
              <div style={{ marginTop: '-.75rem' }}>
                <StaticHazardLegend itemMap={deviceTypesUsersColorMap} />
              </div>
            </InfoCard>
          </LayoutColumn>
        </div>
        <div className={`${flexRow} ${mb2}`}>
          <SectionLayoutDivider dividerText={t('WEARABLES.OVERVIEW.RISK_OVERVIEW')} />
        </div>
        <div className={flexRow}>
          { changeAndZoneCharts.map((chart) => (
            <LayoutColumn columnClass={columnMedium} key={chart.id}>
              <InfoCard
                cardClass={cardMediumWithHeight('auto')}
                title={chart.title}
                rightCornerControl={<InfoTooltip text={ChartTooltips[chart.tooltip]} />}
              >
                {chart.content}
              </InfoCard>
            </LayoutColumn>
          ))}
        </div>
        <div className={flexRow}>
          {hmCharts.map((chart) => (
            <LayoutColumn columnClass={columnMedium} key={chart.id}>
              <InfoCard
                cardClass={cardMediumWithHeight(357)}
                title={chart.title}
                additionalStyles={{}}
                rightCornerControl={<InfoTooltip text={ChartTooltips[chart.tooltip]} />}
              >
                {chart.content}
              </InfoCard>
            </LayoutColumn>
          ))}
        </div>
        <div className={flexRow}>
          <SectionLayoutDivider dividerText={t('WEARABLES.OVERVIEW.DIVIDER')} />
        </div>
        <LayoutRow>
          <LayoutColumn columnClass={columnMedium}>
            <InfoCard
              cardClass={cardMediumWithHeight('auto')}
              title={t('WEARABLES.OVERVIEW.HM_CHART_NAMES.SPINE_H_PER_HOUR')}
              titleInlineControl={(
                <InlineSelect
                  data={selectGroupingCriteria}
                  option={spineHMPHGrouping}
                  changeOption={(v) => {
                    setSpineInitialRange();
                    regroupSpineHMPH(v);
                  }}
                  searchable={false}
                  appearance="default"
                />
              )}
              rightCornerControl={(
                <ArrowBackForwardSwitch
                  couldStepBack={couldSpineHMPHStepBack}
                  couldStepForward={couldSpineHMPHStepForward}
                  onStepBack={onSpineHMPHStepBack}
                  onStepForward={onSpineHMPHStepForward}
                  hazardsChart={selectedSpineOption === 'WEARABLES.OVERVIEW.BY_DEPARTMENT'}
                />
              )}
              additionalStyles={{}}
            >
              <InfoTooltip text={ChartTooltips.GROUPED_SPINE} forGrouped isAbs />
              <HazardByDepartmentByJobRoleChart
                chartId="spine-grouped-chart"
                config={spineConfig}
                data={dataForShowModeSpine}
                setTooltip={setTooltip(hazardNameColorMap.spine, 'hazards', capitalizeFirstLetter(t(spineHMPHGrouping).slice(3)))}
                period={spinePeriod}
              />
              <div style={{ marginTop: '-.75rem' }}>
                <StaticHazardLegend itemMap={hazardNameColorMap.spine} hiddenFields={hiddenFields} />
              </div>
            </InfoCard>
          </LayoutColumn>
          <LayoutColumn columnClass={columnMedium}>
            <InfoCard
              cardClass={cardMediumWithHeight('auto')}
              title={t('WEARABLES.OVERVIEW.HM_CHART_NAMES.SHOULDER_H_PER_HOUR')}
              titleInlineControl={(
                <InlineSelect
                  data={selectGroupingCriteria}
                  option={shoulderHMPHGrouping}
                  changeOption={(v) => {
                    setShoulderInitialRange();
                    regroupShoulderHMPH(v);
                  }}
                  searchable={false}
                  appearance="default"
                />
              )}
              rightCornerControl={(
                <ArrowBackForwardSwitch
                  couldStepBack={couldShoulderHMPHStepBack}
                  couldStepForward={couldShoulderHMPHStepForward}
                  onStepBack={onShoulderHMPHStepBack}
                  onStepForward={onShoulderHMPHStepForward}
                  hazardsChart={selectedShoulderOption === 'WEARABLES.OVERVIEW.BY_DEPARTMENT'}
                />
              )}
            >
              <InfoTooltip text={ChartTooltips.GROUPED_SHOULDER} forGrouped isAbs />
              <HazardByDepartmentByJobRoleChart
                chartId="shoulder-grouped-chart"
                config={shoulderConfig}
                data={dataForShowModeShoulder}
                setTooltip={setTooltip(hazardNameColorMap.shoulder, 'hazards', capitalizeFirstLetter(t(shoulderHMPHGrouping).slice(3)))}
                period={shoulderPeriod}
              />
              <div style={{ marginTop: '-.75rem' }}>
                <StaticHazardLegend itemMap={hazardNameColorMap.shoulder} hiddenFields={hiddenFields} />
              </div>
            </InfoCard>
          </LayoutColumn>
        </LayoutRow>
        <div className={`${flexRow} ${mb2}`}>
          <SectionLayoutDivider dividerText={t('WEARABLES.OVERVIEW.USER_FEEDBACK')} />
        </div>
        <div className={flexRow}>
          <LayoutColumn columnClass={columnMedium}>
            <InfoCard
              cardClass={cardMediumWithHeight(357)}
              title={t('WEARABLES.SPINE')}
              additionalStyles={{}}
              rightCornerControl={<InfoTooltip text={t(`${ChartTooltips.SPINE_FEEDBACK}`)} />}
            >
              <UserFeedbackChart
                data={data?.usersFeedback?.spine}
                containerHeight={357}
                chartId="spine_user_feedback_chart"
              />
            </InfoCard>
          </LayoutColumn>
          <LayoutColumn columnClass={columnMedium}>
            <InfoCard
              cardClass={cardMediumWithHeight(357)}
              title={t('WEARABLES.SHOULDER')}
              additionalStyles={{}}
              rightCornerControl={<InfoTooltip text={t(`${ChartTooltips.SHOULDER_FEEDBACK}`)} />}
            >
              <UserFeedbackChart
                data={data?.usersFeedback?.shoulder}
                containerHeight={357}
                chartId="shoulder_user_feedback_chart"
              />
            </InfoCard>
          </LayoutColumn>
        </div>
      </div>
    </LayoutColumn>
  );
};

export default OverviewContainer;
