import React from 'react';
import moment from 'moment';
import sliceLongString from 'lib/utils/sliceLongString';
import { useTranslation } from 'react-i18next';
import { text } from 'assets/jss/style';
import { COLORS } from 'constants/colors';
import ProgramSelector from '../../UserContainer/ProgramSelector';

const UserReportHeader = ({ user, currentProgram }) => {
  const currentProgramId = currentProgram.coachingProgramPassId;
  const {
    firstName,
    lastName,
    jobTitle,
    department,
    registeredAtInMilliseconds,
    site,
  } = user;
  const { t } = useTranslation();
  const fullProgramList = [user?.currentProgram].concat(
    user?.pastProgramsList || [],
  );
  const passedPrograms = user?.pastProgramsList;
  const hasPassedPrograms = !!passedPrograms?.length;

  return (
    <div
      style={{
        borderBottom: `1px solid ${COLORS.GRAY[100]}`,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '-20px',
        paddingBottom: '5px',
      }}
    >
      <div
        style={
          hasPassedPrograms
            ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }
            : null
        }
      >
        <h3 style={hasPassedPrograms ? { paddingTop: '16px' } : null}>
          {sliceLongString(`${firstName} ${lastName}`, 40)}
        </h3>
        <div>
          <p className={text(COLORS.GRAY[400], 16)}>
            {
              `${jobTitle || t('WEARABLES.USER_PAGE.NO_JOB_TITLE')}, 
              ${department?.name || t('WEARABLES.USER_PAGE.NO_DEPARTMENT')}, 
              ${site?.name || t('WEARABLES.USER_PAGE.NO_SITE')}`
            }
          </p>
          <span
            style={!hasPassedPrograms ? { marginBottom: '8px' } : null}
            className={text(COLORS.GRAY[400], 14)}
          >
            (
            {`${t('WEARABLES.USER_PAGE.REGISTERED_AT')} ${moment(
              registeredAtInMilliseconds,
            ).format('MM.DD.YYYY')}`}
            )
          </span>
        </div>
      </div>
      {hasPassedPrograms ? (
        <div style={passedPrograms.length > 3 ? { minWidth: '700px' } : null}>
          <ProgramSelector
            noTitle
            currentProgram={currentProgramId}
            programList={fullProgramList}
            forPDF
          />
        </div>
      ) : null}
    </div>
  );
};

export default UserReportHeader;
