import * as Emotion from 'emotion';
import { COLORS } from 'constants/colors';

export const modalContent = Emotion.css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  max-width: 578px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${COLORS.GRAY[100]};
  border-radius: 8px;
  outline: 0;
  z-index: 100;
`;

export const modalOverlay = Emotion.css`
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 33;
`;
