import * as Emotion from 'emotion';
import { COLORS } from '../../constants/colors';

/* eslint import/prefer-default-export:0 */

export const link = Emotion.css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const nameLink = Emotion.css`
  ${link};

  color: ${COLORS.GRAY[950]} !important;
`;

export const navLink = Emotion.css`
  ${link};

  padding: 1rem 0.75rem;
  transition: 0.3s color;
  margin-bottom: -1px;
  border: 0;
  color: inherit;
  border-bottom: 1px solid transparent;
  transition: 0.3s border-color;
  text-align: center;

  &:hover,
  &:focus {
    // border-color: ${COLORS.LIGHT_BLUE[600]};
    color: ${COLORS.LIGHT_BLUE[600]};
    background: transparent;

    & [aria-class="chat"] path:first-child {
      fill: ${COLORS.LIGHT_BLUE[600]};
    }
    svg {
      stroke: ${COLORS.LIGHT_BLUE[600]};

      &.devices path:first-child {
        fill: ${COLORS.LIGHT_BLUE[600]};
      }
    }
  }

  svg {
    stroke: ${COLORS.GRAY[400]};

    &.devices path:first-child {
      stroke: transparent;
    }
  }
`;

export const navLinkActive = Emotion.css`
  ${navLink};

  text-decoration: none;
  // border-color: ${COLORS.LIGHT_BLUE[600]};
  font-weight: bold;
  color: ${COLORS.LIGHT_BLUE[600]};
  background: transparent;

  & [aria-class="chat"] path:first-child {
    fill: ${COLORS.LIGHT_BLUE[600]};
  }
  
  svg {
    stroke: ${COLORS.LIGHT_BLUE[600]};

    &.devices path:first-child {
      fill: ${COLORS.LIGHT_BLUE[600]};
    }
  }
`;

export const navLinkDropdown = Emotion.css`
  text-decoration: none;
  font-weight: bold;
  color: #8A8DA6 !important;
  background: transparent;
  &:hover,
  &:focus {
    color: ${COLORS.LIGHT_BLUE[600]} !important;
  }
`;

export const navLinkActiveDropdown = Emotion.css`
  text-decoration: none;
  font-weight: bold;
  color: ${COLORS.LIGHT_BLUE[600]} !important;
  background: transparent;
  &:hover,
  &:focus {
    color: ${COLORS.LIGHT_BLUE[600]} !important;
  }
`;
