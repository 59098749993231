import { COLORS } from 'constants/colors';
import _each from 'lodash/each';
import { findMaxY } from '../video/utils/findMaxY';
import { bodyPartNames, CHART_HEIGHT } from '../video/constants';

const bodyParts = Object.keys(bodyPartNames);

const prepareChartAnglesData = (
  movementData,
  thresholds,
  videoWidth,
  chartHeight,
  selectedBodyPart,
) => {
  const chartAngleMax = findMaxY(movementData, selectedBodyPart);
  const anglePixelRatio = chartAngleMax / CHART_HEIGHT;
  const result = {};
  bodyParts.forEach((part) => {
    const thresholdKeys = Object.keys(thresholds);
    let currentThreshold = [0, 0];
    thresholdKeys.forEach((key) => {
      if (part.includes(key)) currentThreshold = thresholds[key];
    });
    if (part !== bodyPartNames.reba.toLowerCase()) {
      result[part] = {
        angles: [],
        points: [],
        thresholds: {
          high: {
            start: 0,
            height:
              (chartAngleMax - currentThreshold[1]) / anglePixelRatio || 0,
          },
          mid: {
            start: (chartAngleMax - currentThreshold[1]) / anglePixelRatio || 0,
            height:
              (currentThreshold[1] - currentThreshold[0]) / anglePixelRatio
              || 0,
          },
          low: {
            start: (chartAngleMax - currentThreshold[0]) / anglePixelRatio || 0,
            height: currentThreshold[0] / anglePixelRatio || 0,
          },
        },
        arcs: [
          { angle: 0, fill: COLORS.RED[600] },
          { angle: 0, fill: COLORS.ORANGE[600] },
          { angle: 0, fill: COLORS.GREEN[600] },
        ],
        totalFramesWithAngles: 0,
      };
    } else {
      result.reba = {
        angles: [],
        points: [],
        thresholds: {
          veryHigh: {
            start: 0,
            height:
              (chartAngleMax - currentThreshold[3]) / anglePixelRatio || 0,
          },
          high: {
            start: (chartAngleMax - currentThreshold[3]) / anglePixelRatio || 0,
            height:
              (currentThreshold[3] - currentThreshold[2]) / anglePixelRatio
              || 0,
          },
          mid: {
            start: (chartAngleMax - currentThreshold[2]) / anglePixelRatio || 0,
            height:
              (currentThreshold[2] - currentThreshold[1]) / anglePixelRatio
              || 0,
          },
          low: {
            start: (chartAngleMax - currentThreshold[1]) / anglePixelRatio || 0,
            height:
              (currentThreshold[1] - currentThreshold[0]) / anglePixelRatio
              || 0,
          },
          negligible: {
            start: (chartAngleMax - currentThreshold[0]) / anglePixelRatio || 0,
            height: currentThreshold[0] / anglePixelRatio || 0,
          },
        },

        arcs: [
          { angle: 0, fill: COLORS.RED[600] },
          { angle: 0, fill: COLORS.ORANGE[600] },
          { angle: 0, fill: COLORS.GREEN[600] },
        ],
        totalFramesWithAngles: 0,
      };
    }
  });
  const pointsChunk = {};
  // Scaling movement data for long videos
  const lineScale
    = movementData.length < 1000
      ? 1
      : movementData.length < 12000
        ? Math.round(movementData.length / 1000)
        : Math.round(movementData.length / 500);
  movementData.forEach((dot, indexData) => {
    // Counting angle and angle proportion for presentation
    bodyParts.forEach((part) => {
      let currentThreshold = [0, 0];
      Object.keys(thresholds).forEach((key) => {
        if (part.includes(key)) currentThreshold = key;
      });
      result[part].angles.push(dot[19][part]);
      if (dot[19][part] && thresholds[currentThreshold]) {
        const arcIndex
          = dot[19][part] > thresholds[currentThreshold][1]
            ? 0
            : dot[19][part] > thresholds[currentThreshold][0]
              ? 1
              : 2;
        result[part].arcs[arcIndex].angle += 1;
        result[part].totalFramesWithAngles += 1;
      }
    });
    // Counting data for line chart with long video scaling
    bodyParts.forEach((part) => {
      if (indexData % lineScale !== 0) return;
      if (!pointsChunk[part]) pointsChunk[part] = [];
      if (!dot[19][part] && dot[19][part] !== 0) {
        result[part].points.push(pointsChunk[part]);
        pointsChunk[part] = [];
      } else {
        pointsChunk[part].push(
          indexData * (videoWidth / movementData.length) || 0,
        );
        pointsChunk[part].push(
          Math.max(0, chartAngleMax - dot[19][part]) / anglePixelRatio
            || chartHeight,
        );
        if (
          indexData === movementData.length - 1
          || indexData === movementData.length - lineScale
        ) result[part].points.push(pointsChunk[part]);
      }
    });
  });
  bodyParts.forEach((part) => {
    if (pointsChunk[part]?.length > 0) result[part].points.push(pointsChunk[part]);
  });
  _each(result, ({ arcs, totalFramesWithAngles }) => {
    arcs.forEach((arc) => {
      arc.angle *= 360 / totalFramesWithAngles;
    });
  });
  return result;
};

export default prepareChartAnglesData;
