import React, { useEffect, useRef } from 'react';
import { Rect, Transformer } from 'react-konva';

const DraggableBar = ({
  shapeProps,
  isSelected,
  onMouseEnter,
  onChange,
  width,
  rebaWeights,
  checkDeselect,
  setIsButtonDisabled,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const node = shapeRef.current;
  const barPositions = [];
  rebaWeights?.map((weight) => barPositions.push([
    weight.x,
    weight.x + weight.width,
    weight.x + weight.width / 2,
  ]));

  const onDragStart = () => {
    setIsButtonDisabled(false);
  };

  const onDragMove = () => {
    node.x() < 0 && node.x(0);
    node.x() + node.width() > width && node.x(width - node.width());
    node.y(shapeProps.y);
  };

  const onDragEnd = () => {
    if (node.x() < 0) {
      node.x(0);
    }
    node.x() + node.width() > width && node.width(width - node.x());
    onChange({
      ...shapeProps,
      x: node.x(),
    });
  };

  const onTransformStart = () => {
    setIsButtonDisabled(false);
  };

  const onTransformMove = () => {
    if (node.x() < 0) {
      node.width(node.scaleX() * (node.width() + node.x() / node.scaleX()));
      node.scaleX(1);
      node.x(0);
    }
    if (node.x() + node.width() * node.scaleX() > width) {
      node.scaleX(1);
      node.width(width - node.x());
    }

    node.width(node.width() * node.scaleX());
    node.scaleX(1);
    node.y(shapeProps.y);
  };

  const onTransformEnd = () => {
    onChange({
      ...shapeProps,
      x: node.x(),
      width: Math.max(5, node.width()),
    });
  };

  return (
    <>
      <Rect
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransformStart={onTransformStart}
        onTransformEnd={onTransformEnd}
        onTransform={onTransformMove}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'move';
          onMouseEnter();
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
          checkDeselect(e);
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={['middle-right', 'middle-left']}
          rotateEnabled={false}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => (newBox.width < 5 || newBox.height < 5 ? oldBox : newBox)}
        />
      )}
    </>
  );
};

export default DraggableBar;
