import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { flexColumn, inputLabel } from '../../../assets/soter-flex-styles';
import { ReactComponent as ArrowDown } from '../../../assets/img/icons/arrows/admin-arrow-down.svg';
import { createCompanyBtn, formWrapper, inputStyles, inputWrapper, newCompanyTitle, } from './styles';
import CustomSelect from '../../../lib/ui/CustomSelect';
import CustomInput from '../../../lib/ui/CustomInput';
import { getRegionOptions } from '../../companies/company/utils/utils';
import { sortByValue } from '../../companies/company/utils/createCompanyFilters';
import validationSchema from '../../companies/company/utils/validationSchema';
const CreateCompany = ({ companyData, onSubmit, }) => {
    const regions = getRegionOptions();
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: companyData,
        validationSchema,
        onSubmit: (data) => onSubmit(data),
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: newCompanyTitle }, t('ADMIN.CREATE_NEW_COMPANY')),
        React.createElement("form", { className: formWrapper, onSubmit: formik.handleSubmit },
            React.createElement("div", { className: flexColumn, style: { flex: '1 0 100%', marginBottom: 18 } },
                React.createElement("label", { className: inputLabel }, t('ADMIN.REGION', 'Region')),
                React.createElement("div", { className: inputWrapper },
                    React.createElement(CustomSelect, { name: "region", options: sortByValue(regions), placeholder: t('GENERAL.NAME', 'Name'), isSearchable: true, isClearable: true, formik: formik }),
                    React.createElement(ArrowDown, null))),
            React.createElement("div", { className: flexColumn, style: { flex: '1 0 100%', marginBottom: 24 } },
                React.createElement("label", { className: inputLabel }, t('ADMIN.COMPANY_NAME', 'Company Name')),
                React.createElement(CustomInput, { name: "name", placeholder: t('GENERAL.NAME', 'Name'), className: inputStyles, style: { outline: 'none' }, maxLength: 40, formik: formik })),
            React.createElement("button", { className: createCompanyBtn, onClick: () => formik.handleSubmit(), type: "button" }, t('ADMIN.CREATE_NEW_COMPANY')))));
};
export default CreateCompany;
