import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { mapConfigToProgramType } from '../../../overview/utils/mapConfigToProgramType';
import SplitChart from '../../../overview/components/charts/SplitChart';
import { getTooltipText } from '../../../overview/utils/getTooltipText';

const HazardsOverallPerDaySplitChart = ({
  hazardsByDay,
  programId,
  hazardsConfig,
  period,
  timezone,
  deviceType,
  containerHeight,
}) => {
  const hasDataForChart = !!(hazardsByDay && hazardsByDay.length);
  const config = hazardsConfig
    ? mapConfigToProgramType(Object.keys(hazardsConfig), deviceType)
    : [];
  const chartId = `hazards-per-session-bar-chart-${programId}`;
  const { t } = useTranslation();
  const fullData = hasDataForChart ? hazardsByDay.map((dailyReport) => {
    const hazards = Object.keys(dailyReport.overallHazards);
    const filteredHazards = hazards.filter((hazard) => hazard !== 'overallHazard');

    return filteredHazards.reduce((acc, hazard) => {
      if (config?.includes(hazard)) {
        acc[hazard] = dailyReport.overallHazards[hazard];
        return acc;
      }
      return acc;
    }, {
      id: dailyReport.workSessionId,
      time: moment(dailyReport.timeStart, 'x').format('MMM DD'),
      overall: dailyReport.overallHazards.overallHazard,
    });
  })
    .slice(-hazardsByDay.length) : null;

  const tempArr = fullData ? [...fullData].reverse() : null;
  const dataForRequestedPeriod = tempArr?.slice(period.from, period.to);
  const data = dataForRequestedPeriod?.reverse();

  const maxY = fullData?.length > 20 ? Math.max(...fullData.map(day => day.overall)) : undefined;

  return (
    <SplitChart
      chartId={chartId}
      hasDataForChart={hasDataForChart}
      config={config}
      data={data}
      containerHeight={containerHeight}
      setTooltip={getTooltipText(t)}
      totalMax={maxY}
    />
  );
};

export default HazardsOverallPerDaySplitChart;
