import React, { useEffect, useRef, useState } from 'react';
import { innerWrapper, staticwrapper, sticky, } from '../../../assets/jss/containers/stickyOnScrollContainer';
import { container } from '../../../assets/soter-flex-styles';
import { useEventListener } from '../../../lib/utils/useEventListener';

const StickyOnScrollContainer = (props) => {
  const {
    children, topMargin, topBlock, height, innerHeight,
  } = props;
  const [isSticky, setIsSticky] = useState(false);
  const scrollContainerRef = useRef();
  const scrollWaypointRef = useRef();

  useEffect(() => {
    scrollContainerRef.current = document.getElementById('scrollContainer');
    scrollWaypointRef.current = document.getElementById('scrollWaypoint');
  }, []);

  const getWaypointOffsetTop = () => scrollWaypointRef.current.offsetTop - scrollContainerRef.current.offsetTop;

  useEventListener({
    type: 'scroll',
    listener: () => { setIsSticky(scrollContainerRef.current.scrollTop > getWaypointOffsetTop() + topMargin); },
    element: scrollContainerRef,
    options: { passive: true },
  });

  return (
    <div
      className={staticwrapper(innerHeight)}
      style={{ marginTop: `${topMargin}px`, height }}
    >
      <div
        className={`${innerWrapper(innerHeight)} ${isSticky ? sticky : ''}`}
        style={{ height }}
      >
        {topBlock}
        <div className={container} style={{ paddingTop: 8 }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default StickyOnScrollContainer;
