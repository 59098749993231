import { COLORS, HAZARDS_COLORS } from '../constants/colors';

export const viewOptions = [
  {
    value: 'movements',
    label: 'Movements',
  },
  {
    value: 'task',
    label: 'Task',
  },
];

export const unitList = [
  { id: 'time', text: 'JOB_DICTIONARY.PERCENTAGE' },
  { id: 'amount', text: 'JOB_DICTIONARY.MOVEMENTS' },
];

export const taskUnitList = [
  { id: 'time', text: 'JOB_DICTIONARY.TOTAL_TIME' },
  { id: 'amount', text: 'JOB_DICTIONARY.TOTAL_MOVEMENTS' },
];

export const weightUnitList = {
  kg: {
    title: 'kg',
    coefficient: 1,
  },
  lbs: {
    title: 'lbs',
    coefficient: 0.453,
  },
  st: {
    title: 'st',
    coefficient: 6.35,
  },
};

export const dot = [
  'JOB_DICTIONARY.DOT.SEATED',
  'JOB_DICTIONARY.DOT.LIGHT',
  'JOB_DICTIONARY.DOT.MEDIUM',
  'JOB_DICTIONARY.DOT.HEAVY',
  'JOB_DICTIONARY.DOT.VERY_HEAVY',
  'JOB_DICTIONARY.DOT.VARIABLE',
];

export const risks = ['GENERAL.NA', 'GENERAL.LOW', 'GENERAL.MEDIUM', 'GENERAL.HIGH'];

export const DOTClassificationsToColors = {
  'JOB_DICTIONARY.DOT.SEATED': COLORS.LIME[600],
  'JOB_DICTIONARY.DOT.LIGHT': COLORS.GREEN[600],
  'JOB_DICTIONARY.DOT.MEDIUM': COLORS.ORANGE[600],
  'JOB_DICTIONARY.DOT.HEAVY': COLORS.RED[600],
  'JOB_DICTIONARY.DOT.VERY_HEAVY': COLORS.RED[900],
  'JOB_DICTIONARY.DOT.VARIABLE': HAZARDS_COLORS.SPINE.POOR_BENDING[600],
};

export const physicalDemandsColorList = [
  COLORS.WHITE,
  COLORS.GREEN[600],
  COLORS.YELLOW[600],
  COLORS.ORANGE[600],
  COLORS.RED[600],
];

export const physicalDemandActivitiesWithWeight = [
  'floorToWaistLift',
  'waistToShoulderLift',
  'overheadLift',
  'carry',
  'push',
  'pull',
];

export const tagsTypes = ['tools', 'preRequirements'];

export const CPD_MAX_KG_VALUE = 125;

export const chartTypes = [
  'all non - neutral positions',
  'static positions',
  'repetitive positions',
];
