import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { chartContainerWithHeight } from 'assets/soter-flex-styles/chart-container';
import { useTranslation } from 'react-i18next';
import { ChartFactoryContext } from '../../utils/d3/chartFactoryContext';
import useWindowSize from '../../utils/useWindowSize';
import { emptyGroupedChartData } from '../../constants/emptyChartData/emptyGroupedChartData';

const GroupedBarChart = ({
  hasDataForChart,
  chartId,
  data,
  containerHeight,
  xAxisTickFormatter,
  isTickTextRotated,
  groupKey,
  keys,
  colors,
  withExtraText,
  barPadding,
}) => {
  useWindowSize();
  const { t } = useTranslation();
  const chartFactory = useContext(ChartFactoryContext);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const chartContainerRef = useRef(null);

  const setChartDimensions = (updatedWidth, updatedHeight) => {
    if (updatedWidth !== chartWidth) setChartWidth(updatedWidth);
    if (updatedHeight !== chartHeight) setChartHeight(updatedHeight);
  };

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartDimensions(
        chartContainerRef.current.offsetWidth,
        chartContainerRef.current.offsetHeight,
      );
      const drawer = chartFactory.getChartDrawer('groupedBar', chartId);
      drawer.clearChart();
      if (chartWidth && chartHeight && hasDataForChart) {
        drawer.setWidth(chartWidth);
        drawer.setHeight(chartHeight);
        drawer.setData(data);
        drawer.setGroupKey(groupKey);
        drawer.setKeys(keys);
        drawer.setTranslation(t);
        drawer.setXAxisTickFormatter(xAxisTickFormatter);
        drawer.setWithRotatedXAxisTickText(isTickTextRotated);
        drawer.setWithExtraText(withExtraText);
        drawer.draw(colors, barPadding);
      } else if (chartWidth && chartHeight) {
        drawer.setWidth(chartWidth);
        drawer.setHeight(chartHeight);
        drawer.setTranslation(t);
        drawer.setXAxisTickFormatter(xAxisTickFormatter);
        drawer.setWithRotatedXAxisTickText(isTickTextRotated);
        drawer.setWithExtraText(withExtraText);
        drawer.drawEmpty(emptyGroupedChartData);
      }
    }
  });

  return (
    <div
      className={chartContainerWithHeight(containerHeight)}
      ref={chartContainerRef}
      id={chartId}
    />
  );
};

export default GroupedBarChart;
