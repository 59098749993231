export const emptyBarChartData = [
  {
    date: '1 day',
    sum: 10,
  },
  {
    date: '2 day',
    sum: 10,
  },
  {
    date: '3 day',
    sum: 10,
  },
  {
    date: '4 day',
    sum: 10,
  },
  {
    date: '5 day',
    sum: 10,
  },
  {
    date: '6 day',
    sum: 10,
  },
  {
    date: '7 day',
    sum: 10,
  },
  {
    date: '8 day',
    sum: 10,
  },
  {
    date: '9 day',
    sum: 10,
  },
  {
    date: '10 day',
    sum: 10,
  },
];
