import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Radio, RadioGroup } from 'rsuite';
import { ReactComponent as PaperClip } from 'assets/img/icons/paperClip.svg';
import { TaskVideoCategory, TaskVideoType } from 'init/proto_client';
import { resetTaskVideosError } from '../../gallery/videosStore/videoSlice';
import Loader from 'react-js-loader';
import {
  coloredButton,
  flexColumn,
  flexRow,
  inputLabel,
  mb2,
  mb4,
  mb6,
  mt2,
  outlineColoredButton,
  programInfoSubText, smallLoader,
  subText, text,
} from 'assets/soter-flex-styles';
import { COLORS } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { addButton } from './styles';
import { TASK_VIDEO_UPLOAD } from '../../../constants/analyticsEvents';
import Button from '../../../lib/ui/Button';
import { useAppDispatch, useAppSelector } from '../../../lib/utils/useStore';
import {
  createEventNotification,
  setNotification,
} from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { createTaskVideo, getVideos, uploadTaskVideo } from '../../gallery/videosStore/actions';
import {
  setCloseButtonAlternativeBehavior,
  setOpenedModal,
} from '../../../containers/ModalContainer/modalsStore/actions';
import { UPLOAD_CHUNK_SIZE } from './videoSettings';
import CustomInput from '../../../lib/ui/CustomInput';
import CustomSelect from '../../../lib/ui/CustomSelect';
import { validateToPositiveIntegrals } from '../utils/filterNumberInput';
import sliceLongString from '../../../lib/utils/sliceLongString';
import ProgressBar from './ProgressBar';
import hexToRgba from '../../../lib/utils/hexToRgba';

const AddNewVideoDialog = () => {
  let errorCounter = 0;
  const dispatch = useAppDispatch();
  const { selectedCompany } = useAppSelector((state) => state.companies);
  const { geniusProduct } = selectedCompany.products;
  const { uploadedVideo, error } = useAppSelector(
    (state) => state.videos,
  );
  const { id, currentOffset } = uploadedVideo;
  const jobRoles = selectedCompany.companySegmentList
    .find(({ title }) => title === 'user_job_role')
    ?.tagsList.map(({ title, id }) => ({ label: title, value: id }));
  const taskNames = selectedCompany.companySegmentList
    .find(({ title }) => title === 'task_title')
    ?.tagsList.map(({ title, id }) => ({ label: title, value: id }));
  const { resources } = useAppSelector((state) => state.auth);
  const { departmentsList, sitesList } = resources;
  const [videoName, setVideoName] = useState(
    moment().format('YYYY-MM-DD HH:mm:ss'),
  );
  const [taskName, setTaskName] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [repetition, setRepetition] = useState('');
  const [duration, setDuration] = useState('');
  const [department, setDepartment] = useState('');
  const [site, setSite] = useState('');
  const [videoType, setVideoType] = useState(TaskVideoType.TASK_ASSESSMENT);
  const [videoCategory, setVideoCategory] = useState(TaskVideoCategory.INITIAL);
  const [videoFile, setVideoFile] = useState('');
  const [chunkedVideo, setChunkedVideo] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const { t } = useTranslation();
  const videoInputRef = useRef(null);
  useEffect(() => {
    if (
      videoType === TaskVideoType.COACHING
      || videoType === TaskVideoType.OTHER
    ) { setRepetition(null); }
    if (videoType === TaskVideoType.OTHER) {
      setTaskName(null);
      setJobRole(null);
    }
  }, [videoType]);

  const handleNameChange = (_, value) => {
    setVideoName(value);
  };

  const handleFileChange = (event) => {
    if (
      parseInt(event.target.files[0]?.size / (1000 ** 2))
      > geniusProduct?.limits?.videoFileSizeMbLimit
    ) {
      dispatch(
        setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: t('SOTER_TASK.VIDEOS_LIST.FILE_BIG'),
        }),
      );
      videoInputRef.current.value = '';
      return;
    }
    setVideoFile(event.target.files[0]);
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const { duration } = video;
      if (duration * 1000 > geniusProduct?.limits?.videoRecordingTimeSecondsLimit) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: duration.toString() === 'Infinity'
            ? t('SOTER_TASK.VIDEOS_LIST.VIDEO_UPLOAD_ERR')
            : t('SOTER_TASK.VIDEOS_LIST.VIDEO_LONG'),
        }));
        setVideoFile(null);
        videoInputRef.current.value = '';
      }
    };
    video.src = URL.createObjectURL(event.target.files[0]);
  };
  const handleSubmitButtonClick = () => {
    if (videoFile && videoName && !uploadProgress) {
      setUploadProgress(1);
      window.logEvent(TASK_VIDEO_UPLOAD);
      if (localStorage.getItem('showEvents')) {
        dispatch(createEventNotification(TASK_VIDEO_UPLOAD));
      }

      const newVideo = {
        title: videoName,
        fileSize: videoFile.size,
        fileName: videoFile.name,
        repetition,
        taskName: taskName?.label,
        jobRole: jobRole?.label,
        videoType,
        duration,
        videoCategory,
        department: department?.value,
        site: site?.value,
      };
      dispatch(createTaskVideo({ companyId: selectedCompany.id, newVideo }));
      dispatch(setCloseButtonAlternativeBehavior(true));
    }
  };
  const removeFileHandler = () => {
    setVideoFile(null);
    videoInputRef.current.value = '';
  };
  // Make chunks of raw video
  useEffect(() => {
    if (id && videoFile) {
      const reader = new FileReader();
      let offset = 0;
      const rawVideo = new Blob([videoFile]);
      const slicedRawVideo = [];
      for (let i = 0; i < videoFile.size; i += UPLOAD_CHUNK_SIZE) {
        slicedRawVideo.push(
          rawVideo.slice(i, i + UPLOAD_CHUNK_SIZE, videoFile.type),
        );
      }
      const chunkedBase64Strings = [];
      const pushAndReadNext = (result) => {
        chunkedBase64Strings.push(result);
        if (offset >= slicedRawVideo.length - 1) {
          setChunkedVideo(chunkedBase64Strings);
          return;
        }
        offset += 1;
        reader.readAsDataURL(slicedRawVideo[offset]);
      };
      reader.onloadend = (e) => {
        pushAndReadNext(
          e.target.result.substr(e.target.result.indexOf(',') + 1),
        );
      };
      reader.readAsDataURL(slicedRawVideo[offset]);
    }
  }, [id]);
  // Handle video upload by chunks
  useEffect(() => {
    if (!isNetworkConnected) {
      dispatch(resetTaskVideosError())
      return;
    };
    if (error?.type) {
      setUploadProgress(0);
      setChunkedVideo('');
      isremoveFileHandler();
    }
    if (chunkedVideo && currentOffset < videoFile.size) {
      if (error) errorCounter += 1;
      if (errorCounter > 10) {
        dispatch(setNotification({
          type: notificationTypes.TYPE_FAILED,
          message: notificationMessages.UPLOAD_TASK_VIDEO_FAILED_MSG,
        }));
        return;
      }
      dispatch(
        uploadTaskVideo({ id, chunkData: chunkedVideo[currentOffset / UPLOAD_CHUNK_SIZE] }),
      );
      if (currentOffset && !error) {
        errorCounter = 0;
        setUploadProgress(Math.ceil(currentOffset / (videoFile.size / 100)));
      }
    } else if (currentOffset >= videoFile?.size) {
      dispatch(setNotification({
        type: notificationTypes.TYPE_SUCCESS,
        message: notificationMessages.UPLOAD_TASK_VIDEO_SUCCESS_MSG,
      }));
      dispatch(setOpenedModal(null));
      dispatch(setCloseButtonAlternativeBehavior(false));
      dispatch(getVideos(selectedCompany.id));
    }
  }, [chunkedVideo, currentOffset, error, isNetworkConnected]);
  // document.addEventListener('keydown', (e) => {
  //   if (e.which === 13 && !uploadProgress) handleSubmitButtonClick();
  // });
  const [isNetworkConnected, setIsNetworkConnected] = useState(navigator.onLine);
  useEffect(() => {
    window.addEventListener('online', () => setIsNetworkConnected(true));
    window.addEventListener('offline', () => setIsNetworkConnected(false));
    return () => {
      window.removeEventListener('online');
      window.removeEventListener('offline');
    };
  });
  useEffect(() => {
    if (isNetworkConnected && currentOffset && videoFile) {
      dispatch(resetTaskVideosError());
      setUploadProgress(Math.ceil(currentOffset / (videoFile.size / 100)));
      dispatch(
        uploadTaskVideo({ id, chunkData: chunkedVideo[currentOffset / UPLOAD_CHUNK_SIZE] }),
      );
    }
  }, [isNetworkConnected]);
  return (
    <>
      <h4>{t('SOTER_TASK.VIDEOS_LIST.ADD_NEW_VIDEO')}</h4>
      <span className={`${programInfoSubText} ${mb4}`}>
        {t('SOTER_TASK.VIDEOS_LIST.ADD_FOR_PROCESSING')}
      </span>
      <label
        style={{ marginBottom: 0 }}
        className={inputLabel}
        htmlFor="videoType"
      >
        {t('SOTER_TASK.VIDEOS_LIST.VIDEO_TYPE')}
      </label>
      <RadioGroup
        name="videoType"
        inline
        value={videoType}
        onChange={(v) => setVideoType(v)}
      >
        <Radio value={TaskVideoType.TASK_ASSESSMENT}>
          {t('SOTER_TASK.VIDEOS_LIST.ASSESSMENT')}
        </Radio>
        <Radio value={TaskVideoType.COACHING}>
          {t('SOTER_TASK.VIDEOS_LIST.COACHING')}
        </Radio>
        <Radio value={TaskVideoType.OTHER}>
          {t('SOTER_TASK.VIDEOS_LIST.OTHER')}
        </Radio>
      </RadioGroup>
      {videoType !== TaskVideoType.OTHER ? (
        <>
          <label
            style={{ marginBottom: 0 }}
            className={inputLabel}
            htmlFor="videoCategory"
          >
            {t('SOTER_TASK.VIDEOS_LIST.CATEGORY')}
          </label>
          <RadioGroup
            name="videoType"
            inline
            value={videoCategory}
            onChange={(v) => setVideoCategory(v)}
          >
            <Radio value={TaskVideoCategory.INITIAL}>
              {t('SOTER_TASK.VIDEOS_LIST.INITIAL')}
            </Radio>
            <Radio value={TaskVideoCategory.POST_CHANGE}>
              {t('SOTER_TASK.VIDEOS_LIST.POST')}
            </Radio>
            <Radio value={TaskVideoCategory.PROPOSED_CHANGE}>
              {t('SOTER_TASK.VIDEOS_LIST.PROPOSED')}
            </Radio>
          </RadioGroup>
        </>
      ) : null}
      <label
        style={{ marginBottom: '1.2rem' }}
        className={inputLabel}
        htmlFor="name"
      >
        {t('SOTER_TASK.VIDEOS_LIST.VIDEO_NAME')}
        <CustomInput
          className={`${mt2} ${mb2}`}
          type="text"
          id="name"
          name="name"
          onChange={handleNameChange}
          value={videoName}
          onClear={() => setVideoName('')}
        />
        <input
          ref={videoInputRef}
          style={{ display: 'none' }}
          id="video_input"
          type="file"
          accept="video/*"
          onChange={handleFileChange}
        />
      </label>
      <label
        style={{ marginBottom: '1.2rem' }}
        className={inputLabel}
        htmlFor="department"
      >
        {t('SOTER_TASK.VIDEOS_LIST.DEPARTMENT')}
        <CustomSelect
          id="department"
          value={department}
          options={[...departmentsList].map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          isSearchable
          onChange={(_, v) => setDepartment(v)}
        />
      </label>
      <label
        style={{ marginBottom: '1.2rem' }}
        className={inputLabel}
        htmlFor="site"
      >
        {t('SOTER_TASK.VIDEOS_LIST.SITE')}
        <CustomSelect
          id="site"
          value={site}
          options={[...sitesList].map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          isSearchable
          onChange={(_, v) => setSite(v)}
        />
      </label>
      {videoType !== TaskVideoType.OTHER ? (
        <>
          <label
            style={{ marginBottom: '1.2rem' }}
            className={inputLabel}
            htmlFor="taskName"
          >
            {t('SOTER_TASK.VIDEOS_LIST.TASK_NAME')}
            <CustomSelect
              id="taskName"
              value={taskName}
              options={taskNames}
              isSearchable
              onChange={(_, v) => setTaskName(v)}
            />
          </label>
          <label
            style={{ marginBottom: '1.2rem' }}
            className={inputLabel}
            htmlFor="jobRole"
          >
            {t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE')}
            <CustomSelect
              id="jobRole"
              value={jobRole}
              options={jobRoles}
              isSearchable
              onChange={(_, v) => setJobRole(v)}
            />
          </label>
        </>
      ) : null}
      {videoType === TaskVideoType.TASK_ASSESSMENT ? (
        <>
          <label
            style={{ marginBottom: '1.2rem' }}
            className={inputLabel}
            htmlFor="duration"
          >
            {t('SOTER_TASK.VIDEOS_LIST.DURATION')}
            <CustomInput
              className={`${mt2} ${mb2}`}
              type="text"
              id="duration"
              name="duration"
              onChange={(_, v) => {
                +v <= 1440 ? setDuration(validateToPositiveIntegrals(v)) : '';
              }}
              value={duration}
              onClear={() => setDuration('')}
              placeholder={t('SOTER_TASK.VIDEOS_LIST.AVG_TIME_TO_COMPLETE')}
            />
          </label>
          <label
            style={{ marginBottom: '1.2rem' }}
            className={inputLabel}
            htmlFor="repetition"
          >
            {t('SOTER_TASK.VIDEOS_LIST.TASK_REPETITION')}
            <CustomInput
              className={`${mt2} ${mb2}`}
              type="text"
              id="repetition"
              name="repetition"
              onChange={(_, v) => {
                +v <= 10000
                  ? setRepetition(validateToPositiveIntegrals(v))
                  : '';
              }}
              value={repetition}
              onClear={() => setRepetition('')}
              placeholder={t('SOTER_TASK.VIDEOS_LIST.HOW_MANY_TIMES')}
            />
          </label>
        </>
      ) : null}
      <div className={`${flexRow} ${mb6}`}>
        {uploadProgress ? (<div className={flexColumn}>
            {isNetworkConnected ?
              (<>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div className={programInfoSubText} style={{ color: COLORS.GRAY[950] }}>
                    {videoFile.name}
                  </div>
                  <div className={programInfoSubText}>{`${uploadProgress}%`}</div>
                </div>
                <ProgressBar width={526} currentProgress={uploadProgress} />
              </> ) : (<>
                  <div style={{ display: 'flex', padding: '9px', alignItems: 'center', backgroundColor: hexToRgba(COLORS.ORANGE[600], 0.4), borderRadius: '7px', borderColor: COLORS.GRAY[400] }}>
                    <div style={{ width: '90%', fontSize: '16px', color: COLORS.GRAY[950] }}>
                      <div>
                        {t('SOTER_TASK.VIDEOS_LIST.INTERNET_LOST_1')}
                      </div>
                      <div>
                        {t('SOTER_TASK.VIDEOS_LIST.INTERNET_LOST_2')}
                      </div>
                    </div>
                    <Loader size={21} bgColor={COLORS.BLUE[600]} type="bubble-top"/>
                  </div>
              </>
              )}

          </div>
        ) : videoFile ? (
          <div
            className={flexColumn}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <div className={subText} style={{ color: COLORS.GRAY[950] }}>
                {sliceLongString(videoFile.name, 44)}
              </div>
              <span className={`${programInfoSubText} ${mt2}`}>
                {`${Math.round(videoFile.size / 1000000)} Mb`}
              </span>
            </div>
            <div
              className={`${outlineColoredButton(COLORS.RED[600], 'small')}`}
              style={{
                width: '64px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              onClick={removeFileHandler}
            >
              {t('GENERAL.DELETE')}
            </div>
          </div>
        ) : (
          <>
            <div className={flexColumn} style={{ flex: '1 0 70%' }}>
              <div className={subText} style={{ color: COLORS.GRAY[950] }}>
                {t('SOTER_TASK.VIDEOS_LIST.CRITERIA')}
                :
              </div>
              {geniusProduct?.limits?.videoFileSizeMbLimit && (
                <span className={`${programInfoSubText} ${mt2}`}>
                  {`${t('SOTER_TASK.VIDEOS_LIST.SMALLER_THAN')} 
                    ${
                      geniusProduct?.limits?.videoFileSizeMbLimit > 1000
                        ? `${(
                          geniusProduct?.limits?.videoFileSizeMbLimit / 1024
                        ).toFixed(1)}GB`
                        : `${geniusProduct?.limits?.videoFileSizeMbLimit}MB`
                    }`}
                </span>
              )}
              {geniusProduct?.limits?.videoRecordingTimeSecondsLimit && (
                <span className={`${programInfoSubText}`}>
                  {`${t('SOTER_TASK.VIDEOS_LIST.NO_LONGER')} 
                  ${(geniusProduct?.limits?.videoRecordingTimeSecondsLimit / 60000).toFixed(0)}
                  ${t('GENERAL.MINUTES')}`}
                </span>
              )}
            </div>
            <label
              htmlFor="video_input"
              className={flexColumn}
              style={{ flex: '1 0 30%' }}
            >
              <div
                className={`${outlineColoredButton(
                  COLORS.LIGHT_BLUE[600],
                  'small',
                )} ${addButton}`}
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <PaperClip />
                {t('SOTER_TASK.VIDEOS_LIST.ADD_FILE')}
              </div>
            </label>
          </>
        )}
      </div>
      <Button
        style={{ display: 'block', width: '100%' }}
        className={coloredButton(COLORS.LIGHT_BLUE[600], 'small')}
        disabled={!videoFile || !videoName || uploadProgress}
        type="button"
        handler={handleSubmitButtonClick}
        text={t('SOTER_TASK.VIDEOS_LIST.SEND_PROCESSING')}
      />
    </>
  );
};

export default AddNewVideoDialog;
