export const onlyPositiveInteger = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};
export const validateToPositiveIntegrals = (string) => {
  const result = string.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  if (result.length === 1 && +result === 0) { return ''; }
  if (result !== '') { return +result; }
  return '';
};
