import React from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import { Risks } from 'init/proto_client';
import { text } from 'assets/jss/style';
import { card } from 'assets/soter-flex-styles';
import { bodyPartNamesList, riskGroupColors } from '../video/constants';

const bar = (value, riskGroup, height, width) => Emotion.css`
  height: ${height}px;
  width: ${Math.floor((width / 100) * value)
  + (riskGroup === Risks.LOW ? 0 : 5)}px;
  border-radius: 3px;
  margin-right: ${riskGroup === Risks.LOW ? 0 : -5}px;
  text-align: right;
  color: ${COLORS.WHITE};
  background-color: ${riskGroupColors[riskGroup]};
  padding-right: 6px;
`;

const disabledBar = (height, width) => Emotion.css`
  height: ${height}px;
  width: ${width}px;
  border-radius: 3px;
  text-align: right;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.GRAY[400]};
  padding-right: 5px;
  margin-right: -1px;
`;

const tripleBar = Emotion.css`
  display: flex;
  flex-direction: row-reverse;
`;

const tripleBarWrapper = (height, bigGap) => Emotion.css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin: ${bigGap ? height * 1.2 : height / 2}px 25px;

  span {
    ${text(COLORS.GRAY[400], 16)};
    margin-right: 20px;
  }
`;

const TripleBar = ({ bodyPartRisksByTime, height, width }) => (
  <div className={tripleBar}>
    {bodyPartRisksByTime.map(({ value, riskGroup }) => (value > 0.1 ? (
      <div key={riskGroup} className={bar(value, riskGroup, height, width)}>
        {value > 5.2 ? `${value}%` : ''}
      </div>
    ) : (
      ''
    )))}
  </div>
);

const BodyPartRiskDistribution = ({
  risks = {},
  gridArea,
  width,
  barWidth,
  barHeight,
  isCard,
  bigGap,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={isCard ? card : ''}
      style={{ gridArea, width, paddingTop: isCard ? '22px' : 0 }}
    >
      {risks &&
        Object.keys(risks).map((bodyPart) => (
          <div key={bodyPart} className={tripleBarWrapper(barHeight, bigGap)}>
            <span>{t(bodyPartNamesList[bodyPart])}</span>
            {(risks[bodyPart][1].value
              || risks[bodyPart][2].value
              || risks[bodyPart][3].value) &&
            risks[bodyPart].riskGroup ? (
              <TripleBar
                bodyPartRisksByTime={risks[bodyPart]}
                height={barHeight}
                width={barWidth}
              />
              ) : (
                <div className={disabledBar(barHeight, barWidth)}>
                  {t('GENERAL.NA')}
                </div>
              )}
          </div>
        ))}
    </div>
  );
};

export default BodyPartRiskDistribution;
