import React from 'react';
import { useSelector } from 'react-redux';
import ActivityContainer from './ActivityContainer';
import ProductPreviewContainer from '../../containers/ProductPreviewContainer/ProductPreviewContainer';
import { productStatus, productTypes } from '../../init/proto_client';
import { productPreview } from '../../containers/ProductPreviewContainer/productPreview';
import PrivatePageHOC from '../../containers/PrivatPageHOC/PrivatePageHOC';

const ActivityPage = () => {
  const { products } = useSelector((state) => state.companies.selectedCompany);
  return products?.wearableProduct?.status ===
    productStatus.PRODUCTSTATUSNOTAVAILABLE ? (
    <ProductPreviewContainer
      productType={productTypes.PRODUCTTYPESOTERCOACH}
      layout={productPreview.CoachPreviewProduct}
    />
  ) : (
    <ActivityContainer />
  );
};

export default PrivatePageHOC(ActivityPage);
