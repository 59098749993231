import React from 'react';
import { infoMainText, mb3, mt3, } from '../../../assets/soter-flex-styles';

const RecoverActionFailed = ({ message }) => (
  <>
    <p className={`${infoMainText} ${mt3} ${mb3}`}>
        {message}
    </p>
  </>
);

export default RecoverActionFailed;
