import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import _compact from 'lodash/compact';
import _sortBy from 'lodash/sortBy';
import _chunk from 'lodash/chunk';
import _maxBy from 'lodash/maxBy';
import capitalizeFirstLetter from 'lib/utils/capitalizeFirstLetter';
import {
  CHART_HEIGHT_MM, DONUT_CHART_HEIGHT_MM, DONUT_CHART_OUTER_RADIUS,
  PIXEL_COEFFICIENT, RISKS_CHART_BAR_WIDTH_MM,
  RISKS_CHART_LEGEND_TITLES,
  RISKS_CHART_SIDE_GAP_MM, RISKS_CHART_SLOT_WIDTH_MM, RISKS_CHART_WIDTH_MM,
  RISKS_GROUP_ENUM, SINGLE_PAGE_TYPE,
  STACKED_CHART_BAR_WIDTH_MM,
  STACKED_CHART_INNER_GAP_MM,
  STACKED_CHART_SLOT_WIDTH_MM, STACKED_CHART_WIDTH_MM,
} from 'containers/PdfContainer/constants';
import hexToRgba from 'lib/utils/hexToRgba';
import { hazardsEnum } from '../constants/hazardsEnum';
import { ChartType } from '../useOverviewDataSource';
import { prepareActiveUsers, prepareGroupedDataForChart } from '../OverviewContainer';
import { GroupingCriteriaByPeriod } from '../constants/constants';
import {
  COLORS, CHART_GREY, CHART_COLORS_SPINE, CHART_COLORS_SHOULDER,
} from '../../../constants/colors';
import splitNumberByQuoter from '../../../containers/PdfContainer/utils/splitNumberByQuoter';
import { getAverageImprovement } from '../utils/metricCalculationUtils';
import filterHazardsByTier from '../utils/filterHazardsByTier';
import getCurrentHazardsList from '../utils/getCurrentHazardsList';
import { deviceTypesUsersColorMap } from '../constants/deviceTypesUsersMap';
import {
  mapDataForDonutChart,
  mapDataForFeedbackChart,
} from '../utils/mapDataForDonutChart';
import getBarHeightCoefficient from '../../../containers/PdfContainer/utils/getBarHeightCoefficient';
import getRisksChartMaximum from '../utils/getRisksChartMaximum';
import OverviewReportHeader from './OverviewReportHeader';
import BarChartWrapper from '../../../containers/PdfContainer/components/BarChartWrapper';
import SinglePage from '../../../containers/PdfContainer/SinglePage';
import DonutChartWrapper from '../../../containers/PdfContainer/components/DonutChartWrapper';

const OverviewPdfContainer = ({
  globalData,
  isGrayScale,
  timePeriod,
  selectedDepartmentNames,
  selectedSiteNames,
}) => {
  const spineHazards = Object.keys(hazardsEnum.spine);
  const shoulderHazards = Object.keys(hazardsEnum.shoulder);
  const { grouping } = useSelector((state) => state.overviewState);
  const activeUsersGrouping = grouping[ChartType.ACTIVE_USERS_CHART];
  const registeredUsersGrouping = grouping[ChartType.REGISTERED_USERS_CHART];
  function getUsersData(data, grouping) {
    let usersList, periodType;
    switch (grouping) {
      case GroupingCriteriaByPeriod.BY_MONTH:
        usersList = data.monthsList;
        periodType = 'month';
        break;
      case GroupingCriteriaByPeriod.BY_WEEK:
        usersList = data.weeksList;
        periodType = 'week';
        break;
      default:
        usersList = data.daysList;
        periodType = 'day';
    }
  
    return { usersList, periodType };
  }
  const { usersList: activeUsersList, periodType: activeUsersPeriodType } = getUsersData(
    globalData.activeUsersDate,
    activeUsersGrouping
  );
  const activeUsers = prepareActiveUsers(activeUsersList, activeUsersPeriodType);
  const { usersList: registeredUsersList, periodType: registeredUsersPeriodType } = getUsersData(
    globalData.registeredUsers,
    registeredUsersGrouping
  );
  const registeredUsers = prepareActiveUsers(registeredUsersList, registeredUsersPeriodType);
  const { tier } = useSelector((state) => state.companies.selectedCompany);
  const location = useLocation();
  const { t } = useTranslation();
  const colorMapSpine = isGrayScale ? CHART_GREY : CHART_COLORS_SPINE;
  const colorMapShoulder = isGrayScale ? CHART_GREY : CHART_COLORS_SHOULDER;
  const spineKeys = ['pbStatic', 'intensity', 'repetition', 'twisting', 'lifting'];
  const determineColor = (item) => {
    if (item.color) {
      return item.color;
    }
    if (spineKeys.includes(item.key)) {
      return colorMapSpine[item.colorIndex];
    }

    return colorMapShoulder[item.colorIndex];
  };
  const sortByOverallChunkThree = (array) => _chunk(_sortBy(array, (i) => i.overall).reverse(), 3);
  const sortByDateChunkThree = (array) => _chunk(
    _sortBy(array, (i) => i.dateRange.startedAt),
    3,
  );
  const xAxisPoints = splitNumberByQuoter(CHART_HEIGHT_MM * PIXEL_COEFFICIENT);
  const getAxisData = (domainValues, width) => ({
    bottom: {
      x: 0,
      y: CHART_HEIGHT_MM * PIXEL_COEFFICIENT,
      width: (width - RISKS_CHART_SIDE_GAP_MM) * PIXEL_COEFFICIENT + 6,
    },
    doted: {
      x: 0,
      width: width * PIXEL_COEFFICIENT + 15,
      points: xAxisPoints,
      domainValues,
    },
  });
  const getCurrentProduct = () => {
    const { pathname } = location;
    if (pathname.includes('coach')) return 'SoterCoach';
    if (pathname.includes('clipgo')) return 'Clip&Go';
    if (pathname.includes('all')) return 'SoterCoach';
    return 'SoterCoach';
  };
  const users = {
    Spine: {
      active: globalData.activeUsers.value,
      new: globalData.newUsers.value,
      finished_program: globalData.finishedUsers.value,
    },
    Shoulder: {
      active: globalData.activeUsers.value,
      new: globalData.newUsers.value,
      finished_program: globalData.finishedUsers.value,
    },
  };
  const improvements = {
    shoulder: getAverageImprovement(globalData.shoulderHazardsChangeByRisk),
    spine: getAverageImprovement(globalData.spineHazardsChangeByRisk),
  };
  const daysRecorded = {
    Spine: globalData.completedSession.value,
    Shoulder: globalData.completedSession.value,
  };

  const spineLegendData = filterHazardsByTier(
    getCurrentHazardsList('bend'),
    tier,
  ).map((hazard) => ({
    color: colorMapSpine[hazard.colorIndex],
    title: hazard.title,
  }));
  const shoulderLegendData = filterHazardsByTier(
    getCurrentHazardsList('armband'),
    tier,
  ).map((hazard) => ({
    color: colorMapShoulder[hazard.colorIndex],
    title: hazard.title,
  }));
  const activeUserLegendData = deviceTypesUsersColorMap
    .reverse()
    .map(({ color, name }) => ({ color, title: name }));
  const feedbackLegend = [
    { color: COLORS.RED[600], title: t('WEARABLES.OVERVIEW.NEGATIVE') },
    { color: COLORS.BLUE[900], title: t('WEARABLES.OVERVIEW.NEUTRAL') },
    { color: COLORS.GREEN[600], title: t('WEARABLES.OVERVIEW.POSITIVE') },
  ];

  const hazardsOfSpine = { ...globalData.hazardsOfSpine };
  delete hazardsOfSpine.duration;
  const hazardsOfShoulder = { ...globalData.hazardsOfShoulder };
  delete hazardsOfShoulder.duration;
  const dataForSpineDonut = mapDataForDonutChart(
    hazardsOfSpine,
    'bend',
    colorMapSpine,
  );
  const dataForShoulderDonut = mapDataForDonutChart(
    hazardsOfShoulder,
    'armband',
    colorMapShoulder,
  );
  const dataForFeedbackSpine = mapDataForFeedbackChart(
    globalData?.usersFeedback?.spine,
    t,
  );
  const dataForFeedbackShoulder = mapDataForFeedbackChart(
    globalData?.usersFeedback?.shoulder,
    t,
  );
  const risksOfSpine = globalData.spineHazardsChangeByRisk;
  const risksOfShoulder = globalData.shoulderHazardsChangeByRisk;
  const spineByDepartment = sortByOverallChunkThree(
    prepareGroupedDataForChart(
      globalData.spineHazardsByDepartmentList,
      spineHazards,
    ),
  );
  const shoulderByDepartment = sortByOverallChunkThree(
    prepareGroupedDataForChart(
      globalData.shoulderHazardsByDepartmentList,
      shoulderHazards,
    ),
  );
  const spineByJob = sortByOverallChunkThree(
    prepareGroupedDataForChart(
      globalData.spineHazardsByJobRoleList,
      spineHazards,
    ),
  );
  const shoulderByJob = sortByOverallChunkThree(
    prepareGroupedDataForChart(
      globalData.shoulderHazardsByJobRoleList,
      shoulderHazards,
    ),
  );
  const spineByTask = sortByOverallChunkThree(
    prepareGroupedDataForChart(globalData.spineHazardsByTaskList, spineHazards),
  );
  const shoulderByTask = sortByOverallChunkThree(
    prepareGroupedDataForChart(
      globalData.shoulderHazardsByTaskList,
      shoulderHazards,
    ),
  );
  const spineBySite = sortByOverallChunkThree(
    prepareGroupedDataForChart(globalData.spineHazardsBySiteList, spineHazards),
  );
  const shoulderBySite = sortByOverallChunkThree(
    prepareGroupedDataForChart(globalData.shoulderHazardsBySiteList, shoulderHazards),
  );
  const activeUsersByPeriod = sortByDateChunkThree(activeUsers);
  const registeredUsersByPeriod = sortByDateChunkThree(registeredUsers);
  const spineByDepartmentMax
    = spineByDepartment[0] && spineByDepartment[0][0]?.overall;
  const shoulderByDepartmentMax
    = shoulderByDepartment[0] && shoulderByDepartment[0][0]?.overall;
  const spineByJobMax = spineByJob[0] && spineByJob[0][0]?.overall;
  const shoulderByJobMax = shoulderByJob[0] && shoulderByJob[0][0]?.overall;
  const spineByTaskMax = spineByTask[0] && spineByTask[0][0]?.overall;
  const shoulderByTaskMax = shoulderByTask[0] && shoulderByTask[0][0]?.overall;
  const spineBySiteMax = spineBySite[0] && spineBySite[0][0]?.overall;
  const shoulderBySiteMax = shoulderBySite[0] && shoulderBySite[0][0]?.overall;
  const activeUsersMax = _maxBy(activeUsers, 'overall')?.overall;
  const registeredUsersMax = _maxBy(registeredUsers, 'overall')?.overall;
  const shoulderByDepartmentHeightCoefficient = getBarHeightCoefficient(
    shoulderByDepartmentMax,
    false,
  );
  const spineByDepartmentHeightCoefficient = getBarHeightCoefficient(
    spineByDepartmentMax,
    false,
  );
  const shoulderByJobHeightCoefficient = getBarHeightCoefficient(
    shoulderByJobMax,
    false,
  );
  const spineByJobHeightCoefficient = getBarHeightCoefficient(
    spineByJobMax,
    false,
  );
  const shoulderByTaskHeightCoefficient = getBarHeightCoefficient(
    shoulderByTaskMax,
    false,
  );
  const spineByTaskHeightCoefficient = getBarHeightCoefficient(
    spineByTaskMax,
    false,
  );
  const shoulderBySiteHeightCoefficient = getBarHeightCoefficient(
    shoulderBySiteMax,
    false,
  );
  const spineBySiteHeightCoefficient = getBarHeightCoefficient(
    spineBySiteMax,
    false,
  );
  const activeUsersHeightCoefficient = getBarHeightCoefficient(
    activeUsersMax,
    false,
  );
  const registeredUsersHeightCoefficient = getBarHeightCoefficient(
    registeredUsersMax,
    false,
  );
  const spineByDepartmentDomain
    = splitNumberByQuoter(spineByDepartmentMax).reverse();
  const shoulderByDepartmentDomain = splitNumberByQuoter(
    shoulderByDepartmentMax,
  ).reverse();
  const spineByJobDomain = splitNumberByQuoter(spineByJobMax).reverse();
  const shoulderByJobDomain = splitNumberByQuoter(shoulderByJobMax).reverse();
  const spineByTaskDomain = splitNumberByQuoter(spineByTaskMax).reverse();
  const shoulderByTaskDomain = splitNumberByQuoter(shoulderByTaskMax).reverse();
  const spineBySiteDomain = splitNumberByQuoter(spineBySiteMax).reverse();
  const shoulderBySiteDomain = splitNumberByQuoter(shoulderBySiteMax).reverse();
  const activeUsersDomain = splitNumberByQuoter(activeUsersMax).reverse();
  const registeredUsersDomain
    = splitNumberByQuoter(registeredUsersMax).reverse();
  const stackedSlotWidth = STACKED_CHART_SLOT_WIDTH_MM * PIXEL_COEFFICIENT;
  const stackedInnerGap = STACKED_CHART_INNER_GAP_MM * PIXEL_COEFFICIENT;
  const stackedBarWidth = STACKED_CHART_BAR_WIDTH_MM * PIXEL_COEFFICIENT;
  const hazardsListMap = {
    active: deviceTypesUsersColorMap,
    registered: [{ key: 'usersCount', color: '#a60e48' }],
  };
  const dataForStackedChart = (preparedData, coefficient, type) => preparedData.map((group, index) => {
    const position = stackedSlotWidth * index + stackedInnerGap;
    const chartHeight = CHART_HEIGHT_MM * PIXEL_COEFFICIENT;
    const hazardsList = hazardsListMap[type] || getCurrentHazardsList(type).reverse();
    const slotParameters = {
      background: index % 2 === 0 && hexToRgba(COLORS.GRAY[400], 0.2),
      x: position,
      y: -20,
      height: chartHeight + 20,
      width: stackedSlotWidth,
    };
    const textParameters = {
      x: 0,
      y: 0,
      size: 0,
      text: '',
    };
    const bars = group.map((hazards, i) => {
      let currentBarY = hazards.overall * coefficient;
      const subPosition
          = position + i * (stackedBarWidth * 2.1) + stackedBarWidth + 3;
        // eslint-disable-next-line no-shadow
      const textParameters = {
        x: subPosition - stackedBarWidth * 0.6,
        y: chartHeight - 5,
        size: 8,
        text: hazards.id,
        rotation: 270,
        fill: COLORS.GRAY[950],
      };
      const rectParameters = _compact(
        hazardsList.map((item) => {
          const hazardValue = hazards[item.key || item.alias];
          if (hazardValue) {
            const rectHeight = hazardValue * coefficient;
            const rect = {
              x: subPosition,
              y: chartHeight - currentBarY,
              width: stackedBarWidth,
              height: rectHeight,
              color: determineColor(item),
            };
            currentBarY -= rectHeight;
            return rect;
          }
        }),
      );
      return { rectParameters, textParameters };
    });
    return { slotParameters, textParameters, bars };
  });
  const risksSpineMax = getRisksChartMaximum(risksOfSpine);
  const risksShoulderMax = getRisksChartMaximum(risksOfShoulder);
  const risksSpineHeightCoefficient = getBarHeightCoefficient(
    risksSpineMax,
    false,
  );
  const risksShoulderHeightCoefficient = getBarHeightCoefficient(
    risksShoulderMax,
    false,
  );
  const risksSpineDomain = splitNumberByQuoter(risksSpineMax).reverse();
  const risksShoulderDomain = splitNumberByQuoter(risksShoulderMax).reverse();

  const risksChartLegend = RISKS_CHART_LEGEND_TITLES.map((item) => ({
    title: t(item.text),
    color: item.color,
  }));
  const dataForRiskChart = (risks, coefficient) => Object.keys(risks)
    .filter((key) => key.indexOf('Worker') === -1)
    .map((key, index, arr) => {
      if (index % 2 === 0) {
        const riskKey = Object.keys(RISKS_GROUP_ENUM).filter(
          (k) => key.indexOf(k) !== -1,
        );
        const barWidth = RISKS_CHART_BAR_WIDTH_MM * PIXEL_COEFFICIENT;
        const chartHeight = CHART_HEIGHT_MM * PIXEL_COEFFICIENT;
        const slotPosition
            = RISKS_CHART_SLOT_WIDTH_MM * PIXEL_COEFFICIENT * (index / 2)
            + RISKS_CHART_SIDE_GAP_MM * PIXEL_COEFFICIENT;
        const firstBarHeight = risks[key] ? risks[key] * coefficient : 0;
        const secondBarHeight = risks[arr[index + 1]]
          ? risks[arr[index + 1]] * coefficient
          : 0;
        const firstBarPosition = slotPosition + 13;
        const secondBarPosition = firstBarPosition + barWidth + 10;
        const slotParameters = { width: slotPosition };
        const workers = risks[RISKS_GROUP_ENUM[riskKey][1]];
        const textParameters = {
          x: slotPosition + 10,
          y: CHART_HEIGHT_MM * PIXEL_COEFFICIENT + 3,
          size: 10,
          text: `${t(RISKS_GROUP_ENUM[riskKey][0])}\n      ${workers} ${
            workers > 1
              ? t('WEARABLES.OVERVIEW.WORKERS')
              : t('WEARABLES.OVERVIEW.WORKER')
          }`,
        };
        const bars = [
          {
            textParameters: {
              x: firstBarPosition + barWidth / 5,
              y: chartHeight - firstBarHeight - 9,
              text: risks[key] || 0,
              size: 10,
            },
            rectParameters: [
              {
                x: firstBarPosition,
                y: chartHeight - firstBarHeight,
                width: barWidth,
                height: firstBarHeight,
                color: COLORS.INDIGO[800],
                radius: 0,
              },
            ],
          },
          {
            textParameters: {
              x: secondBarPosition + barWidth / 5,
              y: chartHeight - secondBarHeight - 9,
              text: risks[arr[index + 1]] || 0,
              size: 10,
            },
            rectParameters: [
              {
                x: secondBarPosition,
                y: chartHeight - secondBarHeight,
                width: barWidth,
                height: secondBarHeight,
                color: COLORS.INDIGO[500],
                radius: 0,
              },
            ],
          },
        ];
        return { slotParameters, textParameters, bars };
      }
    });
  const Header = ({ deviceType }) => (
    <OverviewReportHeader
      deviceType={deviceType}
      period={timePeriod}
      selectedDepartmentNames={selectedDepartmentNames}
      selectedSiteNames={selectedSiteNames}
      product={getCurrentProduct()}
      users={users}
      daysRecorded={daysRecorded}
      improvements={improvements}
    />
  );
  const StackedChart = ({
    data, legendData, axisData, header,
  }) => (
    <BarChartWrapper
      header={header}
      height={(CHART_HEIGHT_MM + 1) * PIXEL_COEFFICIENT}
      width={STACKED_CHART_WIDTH_MM * PIXEL_COEFFICIENT}
      axisData={axisData}
      data={data}
      legendData={legendData}
    />
  );
  const ActiveUsersChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={`${t('WEARABLES.OVERVIEW.ACTIVE_USERS').toUpperCase()} ${t(
        activeUsersGrouping,
      ).toUpperCase()}`}
      axisData={getAxisData(activeUsersDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const RegisteredUsersChart = ({ data }) => (
    <StackedChart
      data={data}
      header={`${t('WEARABLES.OVERVIEW.NEW_USERS').toUpperCase()} ${t(
        registeredUsersGrouping,
      ).toUpperCase()}`}
      axisData={getAxisData(registeredUsersDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const SpineDepartmentsChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t('WEARABLES.OVERVIEW.SPINE_HAZARDS_BY_DEPARTMENT').toUpperCase()}
      axisData={getAxisData(spineByDepartmentDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const ShoulderDepartmentsChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t(
        'WEARABLES.OVERVIEW.SHOULDER_HAZARDS_BY_DEPARTMENT',
      ).toUpperCase()}
      axisData={getAxisData(shoulderByDepartmentDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const SpineJobRoleChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t('WEARABLES.OVERVIEW.SPINE_HAZARDS_BY_JOB_ROLE').toUpperCase()}
      axisData={getAxisData(spineByJobDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const ShoulderJobRoleChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t(
        'WEARABLES.OVERVIEW.SHOULDER_HAZARDS_BY_JOB_ROLE',
      ).toUpperCase()}
      axisData={getAxisData(shoulderByJobDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const SpineTaskChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t('WEARABLES.OVERVIEW.SPINE_HAZARDS_BY_TASK').toUpperCase()}
      axisData={getAxisData(spineByTaskDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const ShoulderTaskChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t('WEARABLES.OVERVIEW.SHOULDER_HAZARDS_BY_TASK').toUpperCase()}
      axisData={getAxisData(shoulderByTaskDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const SpineSiteChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t('WEARABLES.OVERVIEW.SPINE_HAZARDS_BY_SITE').toUpperCase()}
      axisData={getAxisData(spineBySiteDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const ShoulderSiteChart = ({ data, legendData }) => (
    <StackedChart
      data={data}
      legendData={legendData}
      header={t('WEARABLES.OVERVIEW.SHOULDER_HAZARDS_BY_SITE').toUpperCase()}
      axisData={getAxisData(shoulderBySiteDomain, STACKED_CHART_WIDTH_MM)}
    />
  );
  const dataForStackedSpineDepartment = _chunk(spineByDepartment, 6).map((v) => dataForStackedChart(v, spineByDepartmentHeightCoefficient, 'bend'));
  const dataForStackedSpineJobRole = _chunk(spineByJob, 6).map((v) => dataForStackedChart(v, spineByJobHeightCoefficient, 'bend'));
  const dataForStackedSpineTask = _chunk(spineByTask, 6).map((v) => dataForStackedChart(v, spineByTaskHeightCoefficient, 'bend'));
  const dataForStackedSpineSite = _chunk(spineBySite, 6).map((v) => dataForStackedChart(v, spineBySiteHeightCoefficient, 'bend'));
  const dataForStackedShoulderDepartment = _chunk(shoulderByDepartment, 6).map(
    (v) => dataForStackedChart(v, shoulderByDepartmentHeightCoefficient, 'armband'),
  );
  const dataForStackedShoulderJobRole = _chunk(shoulderByJob, 6).map((v) => dataForStackedChart(v, shoulderByJobHeightCoefficient, 'armband'));
  const dataForStackedShoulderTask = _chunk(shoulderByTask, 6).map((v) => dataForStackedChart(v, shoulderByTaskHeightCoefficient, 'armband'));
  const dataForStackedShoulderSite = _chunk(shoulderBySite, 6).map((v) => dataForStackedChart(v, shoulderBySiteHeightCoefficient, 'armband'));
  const dataForStackedActive = _chunk(activeUsersByPeriod, 6).map((v) => dataForStackedChart(v, activeUsersHeightCoefficient, 'active'));
  const dataForStackedRegistered = _chunk(registeredUsersByPeriod, 6).map((v) => dataForStackedChart(v, registeredUsersHeightCoefficient, 'registered'));
  const activeExtraPageQuantity = new Array(
    Math.ceil(
      (dataForStackedActive.length + dataForStackedRegistered.length - 2) / 4,
    ),
  ).fill(new Array(4).fill(true));
  const spineExtraPageQuantity = new Array(
    Math.ceil(
      (dataForStackedSpineDepartment.length
        + dataForStackedSpineJobRole.length
        + dataForStackedSpineTask.length
        + dataForStackedSpineSite.length
        - 2)
        / 4,
    ),
  ).fill(new Array(4).fill(true));
  const shoulderExtraPageQuantity = new Array(
    Math.ceil(
      (dataForStackedShoulderDepartment.length
        + dataForStackedShoulderJobRole.length
        + dataForStackedShoulderTask.length
        + dataForStackedShoulderSite.length
        - 2)
        / 4,
    ),
  ).fill(new Array(4).fill(true));

  return (
    <div
      id="overview_report"
      style={{ display: 'none' }}
    >
      {activeUsers.length > 0 && (
        <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL} isGrayScale={isGrayScale}>
          <Header deviceType={capitalizeFirstLetter(t('GENERAL.USERS'))} />
          <ActiveUsersChart data={dataForStackedActive.shift()} />
          {dataForStackedActive.length > 0 ? (
            <ActiveUsersChart
              data={dataForStackedActive.shift()}
              legendData={activeUserLegendData}
            />
          ) : (
            <RegisteredUsersChart data={dataForStackedRegistered.shift()} />
          )}
        </SinglePage>
      )}
      {activeUsers.length > 0 &&
        activeExtraPageQuantity.length > 0 &&
        activeExtraPageQuantity.map((val) => (
          <SinglePage
            type={SINGLE_PAGE_TYPE.VERTICAL}
            isGrayScale={isGrayScale}
            key={`active_page_${Math.random()}`}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {val.map((v, index) => (dataForStackedActive.length > 0 ? (
              <ActiveUsersChart
                key={`active_${Math.random()}`}
                data={dataForStackedActive.shift()}
                legendData={index === 3 ? activeUserLegendData : null}
              />
            ) : dataForStackedRegistered.length > 0 ? (
              <RegisteredUsersChart
                key={`registered_${Math.random()}`}
                data={dataForStackedRegistered.shift()}
              />
            ) : null))}
          </SinglePage>
        ))}
      {hazardsOfSpine.total > 0 && (
        <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL} isGrayScale={isGrayScale}>
          <Header deviceType={t('WEARABLES.SPINE')} />
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: `${RISKS_CHART_WIDTH_MM + 5}mm`,
                marginRight: '10mm',
              }}
            >
              <DonutChartWrapper
                height={DONUT_CHART_HEIGHT_MM * PIXEL_COEFFICIENT}
                width={DONUT_CHART_OUTER_RADIUS * 2 + 22}
                data={dataForSpineDonut}
                legendData={spineLegendData}
                header={t('WEARABLES.OVERVIEW.SPINE_HAZARDS').toUpperCase()}
                innerText={t('WEARABLES.OVERVIEW.HAZARDS_PER').toUpperCase()}
              />
            </div>
            <div style={{ width: `${RISKS_CHART_WIDTH_MM + 5}mm` }}>
              <DonutChartWrapper
                height={DONUT_CHART_HEIGHT_MM * PIXEL_COEFFICIENT}
                width={DONUT_CHART_OUTER_RADIUS * 2 + 22}
                data={dataForFeedbackSpine}
                legendData={feedbackLegend}
                header={`${t('WEARABLES.SPINE')} ${t(
                  'WEARABLES.OVERVIEW.USER_FEEDBACK',
                )}`.toUpperCase()}
                innerText={t('SOTER_TASK.ASSESSMENT.TOTAL').toUpperCase()}
              />
            </div>
          </div>
          <div style={{ width: `${RISKS_CHART_WIDTH_MM + 5}mm` }}>
            <BarChartWrapper
              header={t('WEARABLES.OVERVIEW.SPINE_HM_BY_RISK').toUpperCase()}
              height={(CHART_HEIGHT_MM + 6) * PIXEL_COEFFICIENT}
              width={RISKS_CHART_WIDTH_MM * PIXEL_COEFFICIENT}
              axisData={getAxisData(risksSpineDomain, RISKS_CHART_WIDTH_MM)}
              legendData={risksChartLegend}
              data={_compact(
                dataForRiskChart(risksOfSpine, risksSpineHeightCoefficient),
              )}
            />
          </div>
        </SinglePage>
      )}
      {hazardsOfSpine.total > 0 && (
        <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL} isGrayScale={isGrayScale}>
          <Header deviceType={t('WEARABLES.SPINE')} />
          <SpineDepartmentsChart data={dataForStackedSpineDepartment.shift()} />
          {dataForStackedSpineDepartment.length > 0 ? (
            <SpineDepartmentsChart
              data={dataForStackedSpineDepartment.shift()}
              legendData={spineLegendData}
            />
          ) : dataForStackedSpineJobRole.length ? (
            <SpineJobRoleChart
              data={dataForStackedSpineJobRole.shift()}
              legendData={spineLegendData}
            />
          ) : dataForStackedSpineTask.length > 0
            ? (
              <SpineTaskChart
                data={dataForStackedSpineTask.shift()}
                legendData={spineLegendData}
              />
            )
            : (
              dataForStackedSpineSite.length > 0 && (
              <SpineSiteChart
                data={dataForStackedSpineSite.shift()}
                legendData={spineLegendData}
              />
              )
            )}
        </SinglePage>
      )}
      {hazardsOfSpine.total > 0 &&
        spineExtraPageQuantity.length > 0 &&
        spineExtraPageQuantity.map((val) => (
          <SinglePage
            type={SINGLE_PAGE_TYPE.VERTICAL}
            isGrayScale={isGrayScale}
            key={`spine_page_${Math.random()}`}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {val.map((v, index) => (dataForStackedSpineDepartment.length > 0 ? (
              <SpineDepartmentsChart
                key={`spine_dep_${Math.random()}`}
                legendData={index === 3 ? spineLegendData : null}
                data={dataForStackedSpineDepartment.shift()}
              />
            ) : dataForStackedSpineJobRole.length > 0 ? (
              <SpineJobRoleChart
                key={`spine_job_${Math.random()}`}
                legendData={
                    index === 3 || dataForStackedSpineJobRole.length === 1
                      ? spineLegendData
                      : null
                  }
                data={dataForStackedSpineJobRole.shift()}
              />
            ) : dataForStackedSpineTask.length > 0 ? (
              <SpineTaskChart
                key={`spine_task_${Math.random()}`}
                legendData={
                      index === 3 || dataForStackedSpineTask.length === 1
                        ? spineLegendData
                        : null
                    }
                data={dataForStackedSpineTask.shift()}
              />
            )
              : (
                dataForStackedSpineSite.length > 0 && (
                <SpineSiteChart
                  key={`spine_site_${Math.random()}`}
                  legendData={
                        index === 3 || dataForStackedSpineSite.length === 1
                          ? spineLegendData
                          : null
                      }
                  data={dataForStackedSpineSite.shift()}
                />
                )
              )
            ))}
          </SinglePage>
        ))}
      {hazardsOfShoulder.total > 0 && (
        <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL} isGrayScale={isGrayScale}>
          <Header deviceType={t('WEARABLES.SHOULDER')} />
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: `${RISKS_CHART_WIDTH_MM + 5}mm`,
                marginRight: '1.5mm',
              }}
            >
              <DonutChartWrapper
                height={DONUT_CHART_HEIGHT_MM * PIXEL_COEFFICIENT}
                width={DONUT_CHART_OUTER_RADIUS * 2 + 5}
                data={dataForShoulderDonut}
                header={t('WEARABLES.OVERVIEW.SHOULDER_HAZARDS').toUpperCase()}
                legendData={shoulderLegendData}
                innerText={t('WEARABLES.OVERVIEW.HAZARDS_PER').toUpperCase()}
              />
            </div>
            <div style={{ width: `${RISKS_CHART_WIDTH_MM + 5}mm` }}>
              <DonutChartWrapper
                height={DONUT_CHART_HEIGHT_MM * PIXEL_COEFFICIENT}
                width={DONUT_CHART_OUTER_RADIUS * 2 + 22}
                data={dataForFeedbackShoulder}
                legendData={feedbackLegend}
                header={`${t('WEARABLES.SHOULDER')} ${t(
                  'WEARABLES.OVERVIEW.USER_FEEDBACK',
                )}`.toUpperCase()}
                innerText={t('SOTER_TASK.ASSESSMENT.TOTAL').toUpperCase()}
              />
            </div>
          </div>
          <div style={{ width: `${RISKS_CHART_WIDTH_MM + 5}mm` }}>
            <BarChartWrapper
              header={t('WEARABLES.OVERVIEW.SHOULDER_HM_BY_RISK').toUpperCase()}
              height={(CHART_HEIGHT_MM + 6) * PIXEL_COEFFICIENT}
              width={RISKS_CHART_WIDTH_MM * PIXEL_COEFFICIENT}
              axisData={getAxisData(risksShoulderDomain, RISKS_CHART_WIDTH_MM)}
              legendData={risksChartLegend}
              data={_compact(
                dataForRiskChart(
                  risksOfShoulder,
                  risksShoulderHeightCoefficient,
                ),
              )}
            />
          </div>
        </SinglePage>
      )}
      {hazardsOfShoulder.total > 0 && (
        <SinglePage type={SINGLE_PAGE_TYPE.VERTICAL} isGrayScale={isGrayScale}>
          <Header deviceType={t('WEARABLES.SHOULDER')} />
          <ShoulderDepartmentsChart
            data={dataForStackedShoulderDepartment.shift()}
            legendData={shoulderLegendData}
          />
          {dataForStackedShoulderDepartment.length > 0 ? (
            <ShoulderDepartmentsChart
              data={dataForStackedShoulderDepartment.shift()}
              legendData={shoulderLegendData}
            />
          ) : dataForStackedShoulderJobRole.length > 0 ? (
            <ShoulderJobRoleChart
              data={dataForStackedShoulderJobRole.shift()}
              legendData={shoulderLegendData}
            />
          )
            : dataForStackedShoulderTask.length > 0 ? (
              <ShoulderTaskChart
                data={dataForStackedShoulderTask.shift()}
                legendData={shoulderLegendData}
              />
            )
              : (
                dataForStackedShoulderSite.length > 0 && (
                  <ShoulderSiteChart
                    data={dataForStackedShoulderSite.shift()}
                    legendData={shoulderLegendData}
                  />
                )
              )}
        </SinglePage>
      )}
      {hazardsOfShoulder.total > 0 &&
        shoulderExtraPageQuantity.length > 0 &&
        shoulderExtraPageQuantity.map((val) => (
          <SinglePage
            type={SINGLE_PAGE_TYPE.VERTICAL}
            isGrayScale={isGrayScale}
            key={`shoulder_page_${Math.random()}`}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {val.map((v, index) => (dataForStackedShoulderDepartment.length > 0 ? (
              <ShoulderDepartmentsChart
                key={`shoulder_dep_${Math.random()}`}
                legendData={index === 3 ? shoulderLegendData : null}
                data={dataForStackedShoulderDepartment.shift()}
              />
            ) : dataForStackedShoulderJobRole.length > 0 ? (
              <ShoulderJobRoleChart
                key={`shoulder_job_${Math.random()}`}
                legendData={
                    index === 3 || dataForStackedShoulderJobRole.length === 1
                      ? shoulderLegendData
                      : null
                  }
                data={dataForStackedShoulderJobRole.shift()}
              />
            )
              : dataForStackedShoulderTask.length > 0 ? (
                <ShoulderTaskChart
                  key={`shoulder_task_${Math.random()}`}
                  legendData={
                      index === 3 || dataForStackedShoulderTask.length === 1
                        ? shoulderLegendData
                        : null
                    }
                  data={dataForStackedShoulderTask.shift()}
                />
              )
                : (
                  !!dataForStackedShoulderSite.length > 0 && (
                    <ShoulderSiteChart
                      key={`shoulder_task_${Math.random()}`}
                      legendData={
                        index === 3 || dataForStackedShoulderSite.length === 1
                          ? shoulderLegendData
                          : null
                      }
                      data={dataForStackedShoulderSite.shift()}
                    />
                  )
                )
            ))}
          </SinglePage>
        ))}
    </div>
  );
};

export default OverviewPdfContainer;
