import { Table } from 'rsuite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TaskCell from './TaskCell';
import { cardLarge } from '../../assets/soter-flex-styles';
import CardFooterControl from './CardFooterControl';
import useChunkedList from '../utils/useChunkedList';

const { HeaderCell, Column, Cell } = Table;

const LIST_LENGTH = 10;

const TaskTable = ({
  data,
}) => {
  const [editingTask, setEditingTask] = useState(null);
  const { t } = useTranslation();
  const [selectedList, chunkedLists, showSwitcher, switchHandler]
    = useChunkedList(data, LIST_LENGTH);
  return (
    <div className={cardLarge}>
      <Table
        data={chunkedLists[selectedList]}
        height={
          chunkedLists[0]?.length < LIST_LENGTH - 1
            ? chunkedLists[0]?.length * 45 + 75
            : 510
        }
        // onRowClick={(data) => navigate(`${location.pathname}/task/${data.id}`)}
      >
        <Column flexGrow={5}>
          <HeaderCell>
            {t('JOB_DICTIONARY.JOB_PROFILE.TASKS').toUpperCase()}
          </HeaderCell>
          <TaskCell
            setEditingTask={setEditingTask}
            editingTask={editingTask}
            type="name"
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>
            {t('JOB_DICTIONARY.JOB_PROFILE.RISK_GROUP').toUpperCase()}
          </HeaderCell>
          <TaskCell
            setEditingTask={setEditingTask}
            editingTask={editingTask}
            type="group"
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>{t('WEARABLES.SPINE').toUpperCase()}</HeaderCell>
          <TaskCell
            setEditingTask={setEditingTask}
            editingTask={editingTask}
            type="spine"
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>{t('WEARABLES.SHOULDER').toUpperCase()}</HeaderCell>
          <TaskCell
            setEditingTask={setEditingTask}
            editingTask={editingTask}
            type="shoulders"
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>
            {t('JOB_DICTIONARY.JOB_PROFILE.KNEE').toUpperCase()}
          </HeaderCell>
          <TaskCell
            setEditingTask={setEditingTask}
            editingTask={editingTask}
            type="knee"
          />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>
            {t('JOB_DICTIONARY.JOB_PROFILE.HOURS_PER').toUpperCase()}
          </HeaderCell>
          <TaskCell
            setEditingTask={setEditingTask}
            editingTask={editingTask}
            type="time"
          />
        </Column>
      </Table>
      <CardFooterControl
        moreItemsCount={data.length - (selectedList + 1) * LIST_LENGTH}
        isFirstPage={selectedList === 0}
        // addHandler={addTaskHandler}
        switchHandler={switchHandler}
        showSwitcher={showSwitcher}
      />
    </div>
  );
};

export default TaskTable;
