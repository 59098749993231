import { useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const useBackForward = (rangeScope, total = 0) => {
  const [range, setRange] = useState(rangeScope);
  const couldStepForward = (total - range.to) > 0;
  const couldStepBack = range.from > 0;
  const onStepBack = () => {
    setRange({
      from: range.from - 1,
      to: range.to - 1,
    });
  };
  const onStepForward = () => {
    setRange({
      from: range.from + 1,
      to: range.to + 1,
    });
  };
  const setInitialRange = () => setRange(rangeScope);
  return [couldStepBack, onStepBack, couldStepForward, onStepForward, range, setInitialRange];
};

useBackForward.propTypes = {
  rangeScope: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
  }),
  total: PropTypes.number.isRequired,
};
