import React from 'react';
import { Table } from 'rsuite';
import { Link } from '@reach/router';
import _isNaN from 'lodash/isNaN';
import * as Emotion from 'emotion';
import { ReactComponent as Lower } from '../../assets/img/icons/Lower.svg';
import { ReactComponent as Higher } from '../../assets/img/icons/Higher.svg';
import { ReactComponent as Medium } from '../../assets/img/icons/Medium.svg';
import { ReactComponent as LowerGray } from '../../assets/img/icons/LowerGray.svg';
import { ReactComponent as HigherGray } from '../../assets/img/icons/HigherGray.svg';
import { ReactComponent as MediumGray } from '../../assets/img/icons/MediumGray.svg';
import { nameLink } from '../../assets/soter-flex-styles';
import filmOff from '../../assets/img/icons/FilmOff.svg';
import filmOn from '../../assets/img/icons/FilmOn.svg';
import RiskGroupBadge from '../../lib/ui/RiskGroupBadge/RiskGroupBadge';
import CustomInput from '../../lib/ui/CustomInput';

const { Cell } = Table;

const riskGroups = ['lower', 'medium', 'high'];
const iconStyle = { width: '12px', height: '12px', marginRight: '3px' };
const inputStyle = Emotion.css`
  height: 24px;
  width: 55px;
`;

const TaskCell = (props) => {
  const RiskImage = ({ riskCategory }) => (
    <Cell {...props}>
      {riskCategory === '-'
        ? <div style={{ marginLeft: '8px' }}>-</div>
        : <RiskGroupBadge riskGroup={riskCategory?.toLowerCase() || 'lower'} />}
    </Cell>
  );
  const RiskImageSwitcher = (p) => (
    <Cell {...p}>
      {p.riskCategory === 0 ? <Lower style={iconStyle} /> : <LowerGray style={iconStyle} onClick={() => p.setRiskCategory(0)} />}
      {p.riskCategory === 1 ? <Medium style={iconStyle} /> : <MediumGray style={iconStyle} onClick={() => p.setRiskCategory(1)} />}
      {p.riskCategory === 2 ? <Higher style={iconStyle} /> : <HigherGray style={iconStyle} onClick={() => p.setRiskCategory(2)} />}
    </Cell>
  );
  const {
    type, rowData, editingTask, setEditingTask,
  } = props;
  const hours = (rowData?.duration / (1000 * 60 * 60) % 24) || 0;
  const isEditing = editingTask?.id === rowData.id;
  const setTaskField = (field) => (value) => setEditingTask({ ...editingTask, [field]: value });
  const onInputChange = (_, value) => {
    if (_isNaN(+value)) return;
    let v = value;
    if (value > 24) v = 24;
    if (value < 0) v = 0;
    setTaskField('duration')(v);
  };
  switch (type) {
    case ('name'):
      return (
        <Cell {...props}>
          <Link className={nameLink} to={`task/${rowData.id}`}>
            <img src={rowData.isVideoAvailable ? filmOn : filmOff} alt="F" style={{ marginRight: '12px' }} />
            <span>{rowData.title}</span>
          </Link>
        </Cell>
      );
    case ('time'): return isEditing ? <Cell {...props}><CustomInput className={inputStyle} type="numbers" max={24} min={0} value={editingTask.duration} onChange={onInputChange} /></Cell> : <Cell {...props}>{hours.toFixed(2)}</Cell>;
    case ('group'): return isEditing ? <RiskImageSwitcher riskCategory={editingTask.riskGroup} setRiskCategory={setTaskField('riskGroup')} {...props} /> : <RiskImage riskCategory={riskGroups[rowData.riskGroup - 1]} />;
    case ('spine'): return isEditing ? <RiskImageSwitcher riskCategory={editingTask.spineRiskGroup} setRiskCategory={setTaskField('spineRiskGroup')} {...props} /> : <RiskImage riskCategory={riskGroups[rowData.spineRiskGroup - 1]} />;
    case ('shoulders'): return isEditing ? <RiskImageSwitcher riskCategory={editingTask.shouldersRiskGroup} setRiskCategory={setTaskField('shouldersRiskGroup')} {...props} /> : <RiskImage riskCategory={riskGroups[rowData.shouldersRiskGroup - 1]} />;
    case ('knee'): return isEditing ? <RiskImageSwitcher riskCategory={editingTask.kneeRiskGroup} setRiskCategory={setTaskField('kneeRiskGroup')} {...props} /> : <RiskImage riskCategory={riskGroups[rowData.kneeRiskGroup - 1]} />;
    default: return <Cell {...props}>no</Cell>;
  }
};

export default TaskCell;
