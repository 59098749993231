import { TaskVideoStatus } from 'init/proto_client';

export const videoStatus = {
  [TaskVideoStatus.NONE]: 'SOTER_TASK.VIDEO_STATUS.NONE',
  [TaskVideoStatus.NEW]: 'SOTER_TASK.VIDEO_STATUS.NEW',
  [TaskVideoStatus.UPLOADING]: 'SOTER_TASK.VIDEO_STATUS.UPLOADING',
  [TaskVideoStatus.UPLOAD_FINISHED]: 'SOTER_TASK.VIDEO_STATUS.UPLOAD_FINISHED',
  [TaskVideoStatus.WAITING_FOR_PROCESSING]: 'SOTER_TASK.VIDEO_STATUS.WAITING_PROCESSING',
  [TaskVideoStatus.PROCESSING]: 'SOTER_TASK.VIDEO_STATUS.PROCESSING',
  [TaskVideoStatus.WAITING_FOR_APPROVAL]: 'SOTER_TASK.VIDEO_STATUS.VERIFICATION',
  [TaskVideoStatus.APPROVED]: 'SOTER_TASK.VIDEO_STATUS.APPROVED',
  [TaskVideoStatus.ARCHIVED]: 'SOTER_TASK.VIDEO_STATUS.ARCHIVED',
  [TaskVideoStatus.PROCESSING_ERROR]: 'SOTER_TASK.VIDEO_STATUS.ERROR',
};
