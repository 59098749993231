import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import GroupedBarChart from './GroupedBarChart';
import useWindowSize from '../../utils/useWindowSize';

const HazardMovementByRiskGroupChart = ({
  chartId,
  avgHazardChangeByRisk = {},
  containerHeight,
}) => {
  const { t } = useTranslation();
  const colors = [COLORS.INDIGO[800], COLORS.INDIGO[500]];
  const windowSize = useWindowSize();
  const [barPadding, setBarPadding] = useState(windowSize.width < 1280 ? 0.52 : 0.65);
  useEffect(() => {
    setBarPadding(windowSize.width < 1280 ? 0.52 : 0.65);
  }, [windowSize]);
  const prepareData = () => _.reduce(
    avgHazardChangeByRisk,
    (acc, value, key) => {
      if ((/lower/).test(key)) {
        acc[0] = { ...acc[0], [key.substring(5)]: value };
        return acc;
      }
      if ((/middle/).test(key)) {
        acc[1] = { ...acc[1], [key.substring(6)]: value };
        return acc;
      }
      if ((/higher/).test(key)) {
        acc[2] = { ...acc[2], [key.substring(6)]: value };
        return acc;
      }
      return acc;
    },
    [
      { Risk: t('WEARABLES.OVERVIEW.LOW_RISK') },
      { Risk: t('WEARABLES.OVERVIEW.MEDIUM_RISK') },
      { Risk: t('WEARABLES.OVERVIEW.HIGH_RISK') },
    ],
  );

  const data = prepareData();
  const getHasDataForChart = () => {
    const valuableFields = Object.keys(avgHazardChangeByRisk).filter((key) => ((/End/).test(key)) || (/Start/).test(key));
    return !!(valuableFields.reduce((acc, field) => acc + avgHazardChangeByRisk[field], 0));
  };

  const keys = ['Start', 'End'];
  const groupKey = 'Risk';
  const xAxisTickFormatter = (i, d) => d[i]?.Risk || i;

  return (
    <GroupedBarChart
      hasDataForChart={getHasDataForChart()}
      chartId={chartId}
      data={data}
      containerHeight={containerHeight}
      groupKey={groupKey}
      keys={keys}
      xAxisTickFormatter={xAxisTickFormatter}
      colors={colors}
      isTickTextRotated={false}
      withExtraText
      barPadding={barPadding}
    />
  );
};

export default HazardMovementByRiskGroupChart;
