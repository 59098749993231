import * as Emotion from 'emotion';
import { COLORS } from '../../../constants/colors';

const text = Emotion.css`
  color: ${COLORS.GRAY[950]};
`;

// Text Appearances Start
const headlineOne = Emotion.css`
  font-size: 32px;
  font-weight: 900;
`;

const headlineTwo = Emotion.css`
  font-size: 24px;
  font-weight: 800;
`;

const title = Emotion.css`
  font-size: 20px;
`;

const body = Emotion.css`
  font-size: 16px;
`;

const footnote = Emotion.css`
  font-size: 14px;
`;

const caption = Emotion.css`
  font-size: 12px;
`;
// Text Appearances End

// Text Weights Start
const medium = Emotion.css`
  font-weight: 500;
`;

const semiBold = Emotion.css`
  font-weight: 600;
`;

const bold = Emotion.css`
  font-weight: 700;
`;
// Text Weights End

const textAppearance = (appearance) => {
  switch (appearance) {
    case 'headlineOne':
      return headlineOne;
    case 'headlineTwo':
      return headlineTwo;
    case 'title':
      return title;
    case 'body':
      return body;
    case 'footnote':
      return footnote;
    case 'caption':
      return caption;
    default:
      return body;
  }
};

const textWeight = (weight) => {
  switch (weight) {
    case 'medium':
      return medium;
    case 'semi-bold':
      return semiBold;
    case 'bold':
      return bold;
    default:
      return medium;
  }
};

export const textAppearanceAndWeight = (
  appearance = 'default',
  weight = 'default',
) => Emotion.css`
  ${text};
  ${textAppearance(appearance)};
  ${textWeight(weight)};
  ${appearance.includes('headline') && textWeight(weight)};
`;
