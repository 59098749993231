// source: api/dashboard/v2/dashboard_user_chat_profile_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DashboardUserChatProfile', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DashboardUserChatProfileFile', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest', null, global);
goog.exportSymbol('proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chat_dashboard_user_profile.DashboardUserChatProfile.repeatedFields_, null);
};
goog.inherits(proto.chat_dashboard_user_profile.DashboardUserChatProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.DashboardUserChatProfile.displayName = 'proto.chat_dashboard_user_profile.DashboardUserChatProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.DashboardUserChatProfileFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.displayName = 'proto.chat_dashboard_user_profile.DashboardUserChatProfileFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.displayName = 'proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.displayName = 'proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.displayName = 'proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.displayName = 'proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.displayName = 'proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.displayName = 'proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.displayName = 'proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.displayName = 'proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.displayName = 'proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.displayName = 'proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.displayName = 'proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.displayName = 'proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.displayName = 'proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.displayName = 'proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.displayName = 'proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.displayName = 'proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.displayName = 'proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.displayName = 'proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyIndustry: jspb.Message.getFieldWithDefault(msg, 3, ""),
    naicsCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    location: jspb.Message.getFieldWithDefault(msg, 5, ""),
    additionalInformation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
  return proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyIndustry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNaicsCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalInformation(value);
      break;
    case 7:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfileFile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyIndustry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNaicsCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdditionalInformation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_industry = 3;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getCompanyIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setCompanyIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string naics_code = 4;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getNaicsCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setNaicsCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location = 5;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string additional_information = 6;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getAdditionalInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setAdditionalInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated DashboardUserChatProfileFile files = 7;
 * @return {!Array<!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile>}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfileFile, 7));
};


/**
 * @param {!Array<!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile>} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
*/
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.chat_dashboard_user_profile.DashboardUserChatProfileFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfile.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fileType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originalFileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.DashboardUserChatProfileFile;
  return proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType} */ (reader.readEnum());
      msg.setFileType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFileName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFileType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginalFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DashboardUserChatProfileFileType file_type = 2;
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.getFileType = function() {
  return /** @type {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.setFileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string file_url = 3;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string original_file_name = 4;
 * @return {string}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.getOriginalFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.setOriginalFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFile} returns this
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFile.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatDashboardUserProfile: (f = msg.getChatDashboardUserProfile()) && proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest;
  return proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader);
      msg.setChatDashboardUserProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatDashboardUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardUserChatProfile chat_dashboard_user_profile = 1;
 * @return {?proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.prototype.getChatDashboardUserProfile = function() {
  return /** @type{?proto.chat_dashboard_user_profile.DashboardUserChatProfile} */ (
    jspb.Message.getWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfile, 1));
};


/**
 * @param {?proto.chat_dashboard_user_profile.DashboardUserChatProfile|undefined} value
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} returns this
*/
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.prototype.setChatDashboardUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest} returns this
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.prototype.clearChatDashboardUserProfile = function() {
  return this.setChatDashboardUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileRequest.prototype.hasChatDashboardUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatDashboardUserProfile: (f = msg.getChatDashboardUserProfile()) && proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse;
  return proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader);
      msg.setChatDashboardUserProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatDashboardUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardUserChatProfile chat_dashboard_user_profile = 1;
 * @return {?proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.prototype.getChatDashboardUserProfile = function() {
  return /** @type{?proto.chat_dashboard_user_profile.DashboardUserChatProfile} */ (
    jspb.Message.getWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfile, 1));
};


/**
 * @param {?proto.chat_dashboard_user_profile.DashboardUserChatProfile|undefined} value
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse} returns this
*/
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.prototype.setChatDashboardUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse} returns this
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.prototype.clearChatDashboardUserProfile = function() {
  return this.setChatDashboardUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.CreateDashboardUserChatProfileResponse.prototype.hasChatDashboardUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardUserId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest;
  return proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDashboardUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 dashboard_user_id = 1;
 * @return {number}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.prototype.getDashboardUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest} returns this
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileRequest.prototype.setDashboardUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatDashboardUserProfile: (f = msg.getChatDashboardUserProfile()) && proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse;
  return proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader);
      msg.setChatDashboardUserProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatDashboardUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardUserChatProfile chat_dashboard_user_profile = 1;
 * @return {?proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.prototype.getChatDashboardUserProfile = function() {
  return /** @type{?proto.chat_dashboard_user_profile.DashboardUserChatProfile} */ (
    jspb.Message.getWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfile, 1));
};


/**
 * @param {?proto.chat_dashboard_user_profile.DashboardUserChatProfile|undefined} value
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse} returns this
*/
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.prototype.setChatDashboardUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse} returns this
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.prototype.clearChatDashboardUserProfile = function() {
  return this.setChatDashboardUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.GetDashboardUserChatProfileResponse.prototype.hasChatDashboardUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatDashboardUserProfile: (f = msg.getChatDashboardUserProfile()) && proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest;
  return proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader);
      msg.setChatDashboardUserProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatDashboardUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardUserChatProfile chat_dashboard_user_profile = 1;
 * @return {?proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.prototype.getChatDashboardUserProfile = function() {
  return /** @type{?proto.chat_dashboard_user_profile.DashboardUserChatProfile} */ (
    jspb.Message.getWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfile, 1));
};


/**
 * @param {?proto.chat_dashboard_user_profile.DashboardUserChatProfile|undefined} value
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} returns this
*/
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.prototype.setChatDashboardUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest} returns this
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.prototype.clearChatDashboardUserProfile = function() {
  return this.setChatDashboardUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileRequest.prototype.hasChatDashboardUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatDashboardUserProfile: (f = msg.getChatDashboardUserProfile()) && proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse;
  return proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader);
      msg.setChatDashboardUserProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatDashboardUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardUserChatProfile chat_dashboard_user_profile = 1;
 * @return {?proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.prototype.getChatDashboardUserProfile = function() {
  return /** @type{?proto.chat_dashboard_user_profile.DashboardUserChatProfile} */ (
    jspb.Message.getWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfile, 1));
};


/**
 * @param {?proto.chat_dashboard_user_profile.DashboardUserChatProfile|undefined} value
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse} returns this
*/
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.prototype.setChatDashboardUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse} returns this
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.prototype.clearChatDashboardUserProfile = function() {
  return this.setChatDashboardUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.UpdateDashboardUserChatProfileResponse.prototype.hasChatDashboardUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest;
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest} returns this
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse;
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse} returns this
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalFileName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest;
  return proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string original_file_name = 1;
 * @return {string}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.prototype.getOriginalFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest} returns this
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileRequest.prototype.setOriginalFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preSignetUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse;
  return proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreSignetUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreSignetUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pre_signet_url = 1;
 * @return {string}
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.prototype.getPreSignetUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse} returns this
 */
proto.chat_dashboard_user_profile.GetPreSignedUrlDashboardUserChatProfileResponse.prototype.setPreSignetUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    originalFileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest;
  return proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType} */ (reader.readEnum());
      msg.setFileType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOriginalFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional DashboardUserChatProfileFileType file_type = 1;
 * @return {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.getFileType = function() {
  return /** @type {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType} value
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} returns this
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.setFileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string original_file_name = 2;
 * @return {string}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.getOriginalFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} returns this
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.setOriginalFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_url = 3;
 * @return {string}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest} returns this
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileRequest.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatDashboardUserProfile: (f = msg.getChatDashboardUserProfile()) && proto.chat_dashboard_user_profile.DashboardUserChatProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse;
  return proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat_dashboard_user_profile.DashboardUserChatProfile;
      reader.readMessage(value,proto.chat_dashboard_user_profile.DashboardUserChatProfile.deserializeBinaryFromReader);
      msg.setChatDashboardUserProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatDashboardUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat_dashboard_user_profile.DashboardUserChatProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardUserChatProfile chat_dashboard_user_profile = 1;
 * @return {?proto.chat_dashboard_user_profile.DashboardUserChatProfile}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.prototype.getChatDashboardUserProfile = function() {
  return /** @type{?proto.chat_dashboard_user_profile.DashboardUserChatProfile} */ (
    jspb.Message.getWrapperField(this, proto.chat_dashboard_user_profile.DashboardUserChatProfile, 1));
};


/**
 * @param {?proto.chat_dashboard_user_profile.DashboardUserChatProfile|undefined} value
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse} returns this
*/
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.prototype.setChatDashboardUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse} returns this
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.prototype.clearChatDashboardUserProfile = function() {
  return this.setChatDashboardUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.SetDashboardUserChatProfileFileResponse.prototype.hasChatDashboardUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest;
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest} returns this
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse;
  return proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse} returns this
 */
proto.chat_dashboard_user_profile.DeleteDashboardUserChatProfileFileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest;
  return proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest} returns this
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    signature: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse;
  return proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string signature = 1;
 * @return {string}
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse} returns this
 */
proto.chat_dashboard_user_profile.GetDashboardUserSignatureResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest;
  return proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool dummy = 1;
 * @return {boolean}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.prototype.getDummy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest} returns this
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdRequest.prototype.setDummy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestChatId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse;
  return proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestChatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestChatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string latest_chat_id = 1;
 * @return {string}
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.prototype.getLatestChatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse} returns this
 */
proto.chat_dashboard_user_profile.GetDashboardUserLatestChatIdResponse.prototype.setLatestChatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.chat_dashboard_user_profile.DashboardUserChatProfileFileType = {
  NONE: 0,
  FILE_HS_POLICY: 1,
  FILE_HS_PROCEDURES: 2,
  FILE_CHEM_LIST: 3,
  FILE_EQUIPMENT_LIST: 4,
  FILE_INJURY: 5,
  OTHER: 6
};

goog.object.extend(exports, proto.chat_dashboard_user_profile);
