import React from 'react';
import { Group, Rect } from 'react-konva';
import Text from './Text';
import Bar from './Bar';

const Slot = ({ slotParameters, bars, textParameters }) => {
  const {
    background, x, y, width, height,
  } = slotParameters;
  return (
    <Group>
      {background && (
      <Rect
        x={x}
        y={y}
        height={height}
        width={width}
        fill={background}
      />
      )}
      {bars.map((bar) => (
        <Bar
          key={`bar${Math.random()}`}
          rectParameters={bar.rectParameters}
          textParameters={bar.textParameters}
        />
      ))}
      <Text
        x={textParameters.x}
        y={textParameters.y}
        text={textParameters.text}
        fontSize={textParameters.size}
      />
    </Group>
  );
};

export default Slot;
