import React from 'react';
import { Rect } from 'react-konva';

const GradientRect = ({ width, rebaWeights }) => {
  const angleInDeg = 90;
  const angleInRad = ((180 - angleInDeg) / 180) * Math.PI;
  const length =
    Math.abs(width * Math.sin(angleInRad)) +
    Math.abs(16 * Math.cos(angleInRad));
  const halfX = (Math.sin(angleInRad) * length) / 2.0;
  const halfY = (Math.cos(angleInRad) * length) / 2.0;
  const centerX = width / 2.0;
  const centerY = 16 / 2.0;
  const startPointX = centerX - halfX;
  const startPointY = centerY - halfY;
  const endPointX = centerX + halfX;
  const endPointY = centerY + halfY;
  const initialYAxis = 12;
  const distance = 40;

  const yAxis =
    !rebaWeights?.length ? initialYAxis: 48 + rebaWeights?.length * distance;

  const linearGradientColorStops = [
    0,
    'rgba(32, 150, 243, 0)',
    0.5,
    'rgba(32, 150, 243, 0.32)',
    1,
    'rgba(32, 150, 243, 0)',
  ];

  return (
    <Rect
      width={width}
      height={16}
      x={0}
      y={yAxis}
      fillPriority='linear-gradient'
      fillLinearGradientStartPoint={{ x: startPointX, y: startPointY }}
      fillLinearGradientEndPoint={{ x: endPointX, y: endPointY }}
      fillLinearGradientColorStops={linearGradientColorStops}
    />
  );
};

export default GradientRect;
