export const Periods = {
  YESTERDAY: 'WEARABLES.OVERVIEW.PERIODS.YESTERDAY',
  LAST_7_DAYS: 'WEARABLES.OVERVIEW.PERIODS.LAST_7_DAYS',
  LAST_30_DAYS: 'WEARABLES.OVERVIEW.PERIODS.LAST_30_DAYS',
  ALL_TIME: 'WEARABLES.OVERVIEW.PERIODS.ALL_TIME',
};

export const AdminTerms = {
  TWO_WEEKS: 'WEARABLES.OVERVIEW.ADMIN_TERMS.TWO_WEEKS',
  ONE_MONTH: 'WEARABLES.OVERVIEW.ADMIN_TERMS.ONE_MONTH',
  THREE_MONTHS: 'WEARABLES.OVERVIEW.ADMIN_TERMS.THREE_MONTHS',
  SIX_MONTHS: 'WEARABLES.OVERVIEW.ADMIN_TERMS.SIX_MONTHS',
  ONE_YEAR: 'WEARABLES.OVERVIEW.ADMIN_TERMS.ONE_YEAR',
};

export const GroupingCriteria = {
  BY_DEPARTMENT: 'WEARABLES.OVERVIEW.BY_DEPARTMENT',
  BY_JOB_ROLE: 'WEARABLES.OVERVIEW.BY_JOB_ROLE',
  BY_TASK: 'WEARABLES.OVERVIEW.BY_TASK',
  BY_SITE: 'WEARABLES.OVERVIEW.BY_SITE',
};

export const GroupingCriteriaByPeriod = {
  BY_DAY: 'WEARABLES.OVERVIEW.BY_DAY',
  BY_WEEK: 'WEARABLES.OVERVIEW.BY_WEEK',
  BY_MONTH: 'WEARABLES.OVERVIEW.BY_MONTH',
};

export const ExtraDepartmentOptions = {
  ALL_DEPARTMENTS: 'WEARABLES.OVERVIEW.ALL_DEPARTMENTS',
  WITH_NO_DEPARTMENT: 'WEARABLES.OVERVIEW.NO_DEPARTMENT',
};

export const ExtraSitesOptions = {
  ALL_SITES: 'all sites',
  WITH_NO_SITE: 'Users with no site',
};

export const MetricNames = {
  SESSION_COMPLETED: 'WEARABLES.OVERVIEW.DAYS_RECORDED',
  IMPROVEMENT: 'WEARABLES.OVERVIEW.SPINE_IMPROVEMENT',
  IMPROVEMENT_SHOULDER: 'WEARABLES.OVERVIEW.SHOULDER_IMPROVEMENT',
  ACTIVE_USERS: 'WEARABLES.OVERVIEW.ACTIVE_USERS',
  NEW_USERS: 'WEARABLES.OVERVIEW.NEW_USERS',
  USERS_WHO_FINISHED_PROGRAM: 'WEARABLES.OVERVIEW.USERS_FINISHED',
};

export const MetricTooltips = {
  SESSION_COMPLETED: 'WEARABLES.OVERVIEW.TOOLTIPS.SESSION_COMPLETED',
  IMPROVEMENT: 'WEARABLES.OVERVIEW.TOOLTIPS.IMPROVEMENT',
  IMPROVEMENT_SHOULDER: 'WEARABLES.OVERVIEW.TOOLTIPS.IMPROVEMENT_SHOULDER',
  ACTIVE_USERS: 'WEARABLES.OVERVIEW.TOOLTIPS.ACTIVE_USERS',
  NEW_USERS: 'WEARABLES.OVERVIEW.TOOLTIPS.NEW_USERS',
  USERS_WHO_FINISHED_PROGRAM: 'WEARABLES.OVERVIEW.TOOLTIPS.USERS_WHO_FINISHED_PROGRAM',
  ACTIVE_DEVICES: 'WEARABLES.OVERVIEW.TOOLTIPS.ACTIVE_DEVICES',
};

export const ChartTooltips = {
  RATIO: 'WEARABLES.OVERVIEW.TOOLTIPS.RATIO',
  SPINE: ['WEARABLES.OVERVIEW.TOOLTIPS.SPINE_1',
    'WEARABLES.OVERVIEW.TOOLTIPS.SPINE_2',
    'WEARABLES.OVERVIEW.TOOLTIPS.SPINE_3',
    'WEARABLES.OVERVIEW.TOOLTIPS.SPINE_4',
    'WEARABLES.OVERVIEW.TOOLTIPS.SPINE_5'],
  SHOULDER: ['WEARABLES.OVERVIEW.TOOLTIPS.SHOULDER_1',
    'WEARABLES.OVERVIEW.TOOLTIPS.SHOULDER_2',
    'WEARABLES.OVERVIEW.TOOLTIPS.SHOULDER_3',
    'WEARABLES.OVERVIEW.TOOLTIPS.SHOULDER_4',
    'WEARABLES.OVERVIEW.TOOLTIPS.SHOULDER_5'],
  CHANGE_SPINE: ['WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_SPINE',
    'WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_1',
    'WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_2',
    'WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_3',
  ],
  CHANGE_SHOULDER: ['WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_SHOULDER',
    'WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_1',
    'WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_2',
    'WEARABLES.OVERVIEW.TOOLTIPS.CHANGE_3',
  ],
  GROUPED_SPINE: 'WEARABLES.OVERVIEW.TOOLTIPS.GROUPED_SPINE',
  GROUPED_SHOULDER: 'WEARABLES.OVERVIEW.TOOLTIPS.GROUPED_SHOULDER',
  AVG_HM_H: 'WEARABLES.OVERVIEW.TOOLTIPS.AVG_HM_H',
  HM_RATIO: 'WEARABLES.OVERVIEW.TOOLTIPS.HM_RATIO',
  ACTIVE_USERS: 'WEARABLES.OVERVIEW.TOOLTIPS.ACTIVE_USERS_CHART_TOOLTIP',
  REGISTERED_USERS: 'WEARABLES.OVERVIEW.TOOLTIPS.REGISTERED_USERS_CHART_TOOLTIP',
  SPINE_FEEDBACK: 'WEARABLES.OVERVIEW.TOOLTIPS.SPINE_USERS_FEEDBACK',
  SHOULDER_FEEDBACK: 'WEARABLES.OVERVIEW.TOOLTIPS.SHOULDER_USERS_FEEDBACK',
};

// eslint-disable-next-line max-len
export const infoCardMetricNames = () => Object.values(MetricNames);
export const infoCardMetricKeys = () => Object.keys(MetricNames);

export const MovementTypes = {
  NORMAL_MOVEMENT: 'Normal movement',
  HAZARDOUS_MOVEMENT: 'Hazardous movement',
};

export const ProgramType = {
  SHOULDER: 'hazardsOfShoulder',
  SPINE: 'hazardsOfSpine',
};

export const AngleZones = {
  LOW: '0-30',
  MIDDLE: '30-60',
  HIGH: '60-90',
  HIGHEST: '>90',
};
