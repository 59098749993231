import React from 'react';
import DropWrapper from './DropWrapper';

/*
 For proper work of toggler button it must get data-name attribute
 with the same value as id prop that is provided to DropToggler
 */
const DropToggler = ({
  id,
  togglerButton,
  direction,
  state,
  toggleDrop,
  disabled,
  children,
  content,
}) => {
  const clickHandler = () => {
    toggleDrop(id);
  };

  const button = React.cloneElement(togglerButton, {
    content,
    clickHandler,
    disabled,
  });

  return (
    <>
      {togglerButton && button}
      <div id={id} />
      {state && (
        <DropWrapper
          id={id}
          name={id}
          data-name={id}
          direction={direction}
          toggleDrop={toggleDrop}
        >
          {children}
        </DropWrapper>
      )}
    </>
  );
};

export default DropToggler;
