const profileInformationFields = [
    {
        id: 'companyName',
        name: 'companyName',
        type: 'text',
        maxLength: 255,
        placeholder: 'SOTER_GENIUS.COMPANY_NAME',
    },
    {
        id: 'companyIndustry',
        name: 'companyIndustry',
        type: 'text',
        maxLength: 255,
        placeholder: 'SOTER_GENIUS.INDUSTRY',
    },
    {
        id: 'naicsCode',
        name: 'naicsCode',
        type: 'text',
        maxLength: 6,
        placeholder: 'NAICS Code',
    },
    {
        id: 'location',
        name: 'location',
        type: 'text',
        maxLength: 255,
        placeholder: 'SOTER_GENIUS.SITE_LOCATION',
    },
];
export default profileInformationFields;
