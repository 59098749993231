import React from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { COLORS } from 'constants/colors';
import {
  TASK_VIDEO_COMPARISON_PAUSED,
  TASK_VIDEO_COMPARISON_PLAYED,
  TASK_VIDEO_COMPARISON_SCROLLED,
  TASK_VIDEO_COMPARISON_SPEED_CHANGED,
  TASK_VIDEO_PAUSED,
  TASK_VIDEO_PLAYED,
  TASK_VIDEO_SCROLLED,
  TASK_VIDEO_SPEED_CHANGED,
} from '../../../constants/analyticsEvents';
import { useAppDispatch } from '../../../lib/utils/useStore';
import { createEventNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';

const TaskPlayer = ({
  videoRef,
  videoLink = '',
  setIsPlaying,
  videoHeight,
  videoWidth,
  toggleMuteBtn,
  isRewoundVideo,
  setIsRewoundVideo,
  currentVideo,
}) => {
  const dispatch = useAppDispatch();

  return (
    <ReactHlsPlayer
      style={{ backgroundColor: COLORS.NEUTRAL[800] }}
      onPause={() => {
        setIsPlaying(false);
        if (currentVideo === 'video1' || currentVideo === 'video2') {
          window.logEvent(TASK_VIDEO_COMPARISON_PAUSED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_VIDEO_COMPARISON_PAUSED));
          }
        } else {
          window.logEvent(TASK_VIDEO_PAUSED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_VIDEO_PAUSED));
          }
        }
      }}
      onPlay={() => {
        if (currentVideo === 'video1' || currentVideo === 'video2') {
          window.logEvent(TASK_VIDEO_COMPARISON_PLAYED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_VIDEO_COMPARISON_PLAYED));
          }
        } else {
          window.logEvent(TASK_VIDEO_PLAYED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_VIDEO_PLAYED));
          }
        }
      }}
      onRateChange={() => {
        if (currentVideo === 'video1' || currentVideo === 'video2') {
          window.logEvent(TASK_VIDEO_COMPARISON_SPEED_CHANGED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_VIDEO_COMPARISON_SPEED_CHANGED));
          }
        } else {
          window.logEvent(TASK_VIDEO_SPEED_CHANGED);
          if (localStorage.getItem('showEvents')) {
            dispatch(createEventNotification(TASK_VIDEO_SPEED_CHANGED));
          }
        }
      }}
      onSeeked={() => {
        if (!isRewoundVideo) {
          setIsRewoundVideo(true);
          if (currentVideo === 'video1' || currentVideo === 'video2') {
            window.logEvent(TASK_VIDEO_COMPARISON_SCROLLED);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(TASK_VIDEO_COMPARISON_SCROLLED));
            }
          } else {
            window.logEvent(TASK_VIDEO_SCROLLED);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(TASK_VIDEO_SCROLLED));
            }
          }
        }
      }}
      onVolumeChange={() => toggleMuteBtn(currentVideo)}
      height={videoHeight}
      width={videoWidth}
      playerRef={videoRef}
      preload="auto"
      src={videoLink}
    />
  );
};

export default TaskPlayer;
