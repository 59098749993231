import React, { useRef } from 'react';
import moment from 'moment';
import { ReactComponent as Calendar } from 'assets/img/icons/calendarNew.svg';
import { ReactComponent as Check } from 'assets/img/icons/check.svg';
import { ReactComponent as Cross } from 'assets/img/icons/cross.svg';
import { productTitle, subscriptionDates } from './styles';
import AdminDateRangePicker from '../AdminDateRangePicker';
import { getAdminDatePickerRanges } from '../../../wearables/overview/utils/getPeriods';
const SubscriptionItem = ({ title, info, editMode, itemInfo, setItemInfo, calendarPosition, setIsCalendarTouched, t, }) => {
    const containerRef = useRef(null);
    const datePickerRanges = getAdminDatePickerRanges(t, itemInfo === null || itemInfo === void 0 ? void 0 : itemInfo.timeStart);
    const calendarDefaultValue = (info === null || info === void 0 ? void 0 : info.timeStart) && (info === null || info === void 0 ? void 0 : info.timeEnd) ? [new Date(info.timeStart), new Date(info.timeEnd)] : null;
    const changeDates = (dates) => {
        if (setIsCalendarTouched)
            setIsCalendarTouched(true);
        if (dates) {
            setItemInfo(Object.assign(Object.assign({}, itemInfo), { isAvailable: true, timeStart: Date.parse(dates[0]), timeEnd: Date.parse(dates[1]) }));
        }
        else {
            setItemInfo(Object.assign(Object.assign({}, itemInfo), { isAvailable: false, timeStart: null, timeEnd: null }));
        }
    };
    const setDateStart = (date) => {
        setItemInfo(Object.assign(Object.assign({}, itemInfo), { isAvailable: true, timeStart: Date.parse(date) }));
    };
    const showSubscriptionStatus = (changeColor) => {
        const getTitle = (text, colorClass) => (React.createElement("div", { className: `title ${changeColor && colorClass}` }, t(text)));
        let statusText = 'ADMIN.SUBSCRIPTION_NOT_ACTIVATED';
        let statusColorClass = 'titleRed';
        if (info) {
            if (info.isAvailable && info.timeStart) {
                statusText = 'ADMIN.ACTIVE_SUBSCRIPTION';
                statusColorClass = 'titleGreen';
            }
            else if (!info.isAvailable && info.timeStart) {
                statusText = 'ADMIN.ACTIVE_ENDED';
            }
        }
        return getTitle(statusText, statusColorClass);
    };
    const showCalendar = () => {
        if (info && info.timeStart && info.timeEnd) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "custom-border" }),
                React.createElement("div", { className: "item__wrapper" },
                    React.createElement(Calendar, null),
                    React.createElement("div", { className: "info" },
                        React.createElement("div", { className: "subtitle" }, t('ADMIN.TERM_DATES')),
                        React.createElement("div", { className: "title" }, `${moment(info === null || info === void 0 ? void 0 : info.timeStart).format('MMM DD, YYYY')} - ${moment(info === null || info === void 0 ? void 0 : info.timeEnd).format('MMM DD, YYYY')}`)))));
        }
    };
    return (React.createElement("div", { className: "item" },
        React.createElement("h4", { className: productTitle }, title),
        editMode ? (React.createElement("div", { style: { marginLeft: 12 } },
            React.createElement("div", { className: "info" },
                React.createElement("div", { className: "subtitle" }, t('WEARABLES.HELP.STATUS', 'Status')),
                showSubscriptionStatus(true)),
            React.createElement("div", { className: "custom-border custom-border__edit" }),
            React.createElement("div", { className: "item__wrapper" },
                React.createElement("div", { className: "info", style: { position: 'relative' }, ref: containerRef },
                    React.createElement("div", { className: "subtitle subtitle__edit" }, t('ADMIN.TERM_DATES')),
                    React.createElement(AdminDateRangePicker, { className: subscriptionDates, container: () => containerRef.current, changeData: changeDates, setDateStart: setDateStart, placeholder: "Select dates", defaultValue: calendarDefaultValue, ranges: datePickerRanges, placement: calendarPosition }))))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "item__wrapper" },
                (info === null || info === void 0 ? void 0 : info.isAvailable) ? React.createElement(Check, null) : React.createElement(Cross, null),
                React.createElement("div", { className: "info" },
                    React.createElement("div", { className: "subtitle" }, t('WEARABLES.HELP.STATUS', 'Status')),
                    showSubscriptionStatus())),
            showCalendar()))));
};
export default SubscriptionItem;
