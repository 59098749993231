import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import {
  coloredButton,
  flexColumn,
  flexRow,
  inputLabel,
  mb3,
  mb5,
  mt1,
  mt2,
  mt4,
  smallMutedText,
} from '../../../assets/soter-flex-styles';
import validationSchema from './validationSchema';

import {
  getCompaniesAccordionOptions,
  getCompaniesSelectOptions,
  getInitialDepartments,
  getInitialFields,
  getInitialSites,
  transformDataForServer,
} from './utils';
import { texts } from './constants';
import Button from '../../../lib/ui/Button';
import * as companiesSelectors from '../../../company/companiesStore/selectors';
import CustomInput from '../../../lib/ui/CustomInput';
import CustomCheckbox from '../../../lib/ui/CustomCheckbox/CustomCheckbox';
import CustomSelect from '../../../lib/ui/CustomSelect';
import CompaniesAccordion from './CompaniesAccordion';
import { COLORS } from 'constants/colors'

const modalTitle = Emotion.css`
  font-size: 24px;
  font-weight: 800;
  color: ${COLORS.GRAY[950]};
  margin-bottom: 14px;
`;

const customLabel = Emotion.css`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  
`;

export const checkboxWrapper = Emotion.css`
  margin-left: -10px;

  label {
    line-height: 18px;
  }
`;

const AddNewDashboardUserDialog = ({
  type,
  companies,
  editedUser,
  userEmail,
  showEvents,
  setShowEvents,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const isAddUser = type === 'addNewUser';
  const isInviteUser = type === 'inviteNewUser';
  const isAdminEditUser = type === 'editUser';
  const isMyCompanyEditUser = type === 'myCompanyEditUser';

  const accessibleDepartments = useSelector(
    companiesSelectors.accessibleDepartmentsSelector,
  );

  const fields = getInitialFields(editedUser);

  const {
    isAdmin, receiveNeedHelpRequests, canInviteUsers,
  }
    = fields;

  const handleSubmit = (values) => {
    const transformedFields = transformDataForServer(values);
    onSubmit(transformedFields, showEvents);
  };

  const formik = useFormik({
    initialValues: {
      ...fields,
      departments: getInitialDepartments(editedUser),
      sites: getInitialSites(editedUser),
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const companiesSelectOptions = getCompaniesSelectOptions(
    companies,
    formik.values.companies,
  );
  const companiesAccordionOptions = getCompaniesAccordionOptions(
    companies,
    formik.values.companies,
  );

  const handleCompaniesChange = (name, value, onChangeObj) => {
    const { removedValue } = onChangeObj;

    if (removedValue) {
      formik.setFieldValue('departments', {
        ...formik.values.departments,
        [removedValue.value]: [],
      });
    }

    formik.setFieldValue(name, value || []);
    formik.setFieldTouched(name, true, false);
  };

  const handleDepartmentsChange = (companyId, values) => {
    formik.setFieldValue('departments', {
      ...formik.values.departments,
      [companyId]: values,
    });
  };

  const handleSitesChange = (companyId, values) => {
    formik.setFieldValue('sites', {
      ...formik.values.sites,
      [companyId]: values,
    });
  };
  return (
    <>
      <h4 className={`${modalTitle}`}>{t(texts[type].title)}</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className={flexRow}>
          <div className={flexColumn}>
            <label className={`${inputLabel} ${customLabel}`}>{t('GENERAL.FIRST_NAME')}</label>
            <CustomInput
              className={`${mt1} ${mb3}`}
              type="text"
              id="firstName"
              name="firstName"
              formik={formik}
              maxLength={30}
            />
          </div>
          <div className={flexColumn}>
            <label className={`${inputLabel} ${customLabel}`}>{t('GENERAL.LAST_NAME')}</label>
            <CustomInput
              className={`${mt1} ${mb3}`}
              type="text"
              id="lastName"
              name="lastName"
              formik={formik}
              maxLength={30}
            />
          </div>
        </div>
        <div className={`${flexRow}`}>
          <div
            className={flexColumn}
            style={{ flexBasis: '50%', flexGrow: '0' }}
          >
            <label className={`${inputLabel} ${customLabel}`}>
              {t('DASHBOARD_USER.EMAIL', 'Email')}
            </label>
            <CustomInput
              className={`${mt1} ${mb3}`}
              type="text"
              id="email"
              name="email"
              formik={formik}
              maxLength={80}
            />
          </div>
          <div
            className={flexColumn}
            style={{ flexBasis: '50%', flexGrow: '0' }}
          >
            <label className={`${inputLabel} ${customLabel}`}>{t('GENERAL.JOB_TITLE')}</label>
            <CustomInput
              className={`${mt1} ${mb3}`}
              type="text"
              id="jobTitle"
              name="jobTitle"
              formik={formik}
              maxLength={80}
            />
          </div>
        </div>
        {(isAddUser || isAdminEditUser) && (
          <>
            <div
              className={flexRow}
              style={{
                alignItems: 'flex-start',
                fontSize: '14px',
              }}
            >
              <div className={`${flexColumn} ${checkboxWrapper}`}>
                <CustomCheckbox
                  checked={isAdmin}
                  label={t('DASHBOARD_USER.SUPER_ADMIN', 'Super admin')}
                  name="isAdmin"
                  formik={formik}
                />
              </div>
              <div className={`${flexColumn} ${checkboxWrapper}`}>
                <CustomCheckbox
                  checked={canInviteUsers}
                  label={t(
                    'DASHBOARD_USER.CAN_INVITE_NEW_USERS',
                    'Can invite new users',
                  )}
                  name="canInviteUsers"
                  formik={formik}
                />
              </div>
            </div>
            <div
              className={flexRow}
              style={{
                alignItems: 'flex-start',
                fontSize: '14px',
              }}
            >
              <div className={`${flexColumn} ${checkboxWrapper}`}>
                <CustomCheckbox
                  label={t(
                    'DASHBOARD_USER.SUBSCRIPTION_MANAGER',
                    'Subscription manager',
                  )}
                  name="isSubscriptionManager"
                  formik={formik}
                />
              </div>
            </div>
            {userEmail === formik.values.email && (
            <div
              className={flexRow}
              style={{
                alignItems: 'flex-start',
                fontSize: '14px',
              }}
            >
              <div className={`${flexColumn} ${checkboxWrapper}`}>
                <CustomCheckbox
                  label="Show events"
                  name="isShowEvents"
                  checked={showEvents}
                  onChange={() => setShowEvents(!showEvents)}
                />
              </div>
            </div>
            )}
          </>
        )}
        {(isInviteUser || isMyCompanyEditUser) && (
          <div
            className={`${flexRow} ${mt1}`}
            style={{
              alignItems: 'center',
              fontSize: '14px',
            }}
          >
            <div className={`${flexColumn} ${checkboxWrapper}`}>
              <CustomCheckbox
                checked={receiveNeedHelpRequests}
                label={t(
                  'DASHBOARD_USER.RECEIVE_NEED_HELP_REQUESTS',
                  'Receive Need Help requests notifications',
                )}
                name="receiveNeedHelpRequests"
                formik={formik}
                custom="customCheckbox"
              />
            </div>
            <div className={`${flexColumn} ${checkboxWrapper}`}>
              <CustomCheckbox
                checked={canInviteUsers}
                label={t(
                  'DASHBOARD_USER.CAN_INVITE_NEW_USERS',
                  'Can invite new users',
                )}
                name="canInviteUsers"
                formik={formik}
                custom="customCheckbox"
              />
            </div>
          </div>
        )}
        <h4 className={`${mt4} ${mb3}`}>
          {t('DASHBOARD_USER.ACCESS', 'Access')}
        </h4>
        <div className={`${flexRow} ${mb5}`}>
          <div className={flexColumn}>
            {(isAddUser || isAdminEditUser) && formik.values.isAdmin ? (
              <span className={smallMutedText}>
                {t(
                  'DASHBOARD_USER.ADMINS_HAVE_ACCESS_TO_ALL_COMPANIES',
                  'Admins have access to all companies and departments',
                )}
              </span>
            ) : (
              <>
                <label className={inputLabel}>
                  {t('DASHBOARD_USER.COMPANIES', 'Companies')}
                </label>
                <div className={mb5}>
                  <CustomSelect
                    name="companies"
                    menuPlacement="top"
                    placeholder={(
                      <div>
                        {t('DASHBOARD_USER.SELECT_COMPANY', 'Select company')}
                      </div>
                    )}
                    value={formik.values.companies}
                    options={companiesSelectOptions}
                    isSearchable
                    isClearable
                    isMulti
                    dropdownIndicator
                    clearIndicator={false}
                    // menuPortalTarget={document.body}
                    formik={formik}
                    customFormikCallback
                    onChange={handleCompaniesChange}
                  />
                </div>
                {formik.values.companies?.length > 0 && (
                  <CompaniesAccordion
                    companies={companiesAccordionOptions}
                    departments={formik.values.departments}
                    accessibleDepartments={accessibleDepartments}
                    setDepartment={handleDepartmentsChange}
                    setSite={handleSitesChange}
                    sites={formik.values.sites}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <Button
          style={{
            fontSize: '18px', lineHeight: '18px', display: 'block', width: '100%',
          }}
          className={`${coloredButton('#248BF2', 'small')}`}
          type="submit"
          text={t(texts[type].buttonText)}
        />
      </form>
    </>
  );
};

export default AddNewDashboardUserDialog;
