import SelectionCell from '../components/CustomCells/SelectionCell';
import { TableType } from '../constants/tableType';

export default function getColumns(
  multiSelectionCheckedGetter,
  selectionHandler,
  userOnCLickHandler,
  actions,
  type,
  t,
) {
  switch (type) {
    case TableType.coach:
      return [
        {
          title: SelectionCell,
          dataKey: 'id',
          width: 25,
          fixed: true,
          align: 'center',
          type: 'selection',
          checked: multiSelectionCheckedGetter,
          handler: selectionHandler,
        },
        {
          title: t('WEARABLES.ACTIVITY.WORKER_JOB'),
          dataKey: 'name',
          fixed: true,
          sortable: true,
          align: 'left',
          width: 270,
          type: 'name',
          handler: userOnCLickHandler,
        },
        {
          title: t('WEARABLES.ACTIVITY.DEPARTMENT_SITE'),
          sortable: true,
          dataKey: 'department',
          align: 'left',
          width: 185,
          type: 'department',
          tooltip: t('WEARABLES.ACTIVITY.SORT_DEPARTMENT'),
        },
        {
          title: t('WEARABLES.ACTIVITY.PROGRAM_AND_PROGRESS'),
          sortable: true,
          dataKey: 'progress',
          align: 'left',
          width: 145,
          type: 'progress',
          tooltip: t('WEARABLES.ACTIVITY.SORT_PROGRESS'),
        },
        {
          title: t('WEARABLES.ACTIVITY.HAZARDS'),
          dataKey: 'average',
          align: 'left',
          flexGrow: 3,
          minWidth: 140,
          type: 'average',
          sortable: true,
          avg: true,
          tooltip: t('WEARABLES.ACTIVITY.HAZARDS_TOOLTIP'),
        },
        {
          title: t('WEARABLES.ACTIVITY.STARTED'),
          dataKey: 'startedAt',
          sortable: true,
          align: 'left',
          width: 120,
          type: 'started',
        },
        {
          title: t('WEARABLES.ACTIVITY.SESSION'),
          dataKey: 'coachSync',
          sortable: true,
          align: 'left',
          width: 120,
          type: 'synced',
        },
        {
          title: t('WEARABLES.ACTIVITY.MEDALS'),
          dataKey: 'achievement',
          align: 'left',
          sortable: true,
          width: 100,
          type: 'achievement',
        },
        {
          title: ' ',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.devices:
      return [
        {
          title: SelectionCell,
          dataKey: 'id',
          width: 25,
          fixed: true,
          type: 'selection',
          align: 'center',
          checked: multiSelectionCheckedGetter,
          handler: selectionHandler,
        },
        {
          title: t('WEARABLES.DEVICES.NAME'),
          dataKey: 'id',
          fixed: true,
          width: 210,
          type: 'device',
          tooltip: t('WEARABLES.DEVICES.SORT_NAME'),
        },
        {
          title: t('WEARABLES.DEVICES.LAST_USER'),
          sortable: true,
          dataKey: 'lastUser',
          width: 120,
          type: 'user',
        },
        {
          title: t('WEARABLES.ACTIVITY.DEPARTMENT_SITE'),
          sortable: true,
          dataKey: 'department',
          width: 160,
          type: 'department',
        },
        {
          title: t('WEARABLES.DEVICES.PROGRAMS'),
          dataKey: 'id',
          width: 380,
          type: 'programs',
        },
        {
          title: t('WEARABLES.DEVICES.BATTERY'),
          sortable: true,
          dataKey: 'lastBatteryCharge',
          width: 90,
          type: 'battery',
        },
        {
          title: t('WEARABLES.DEVICES.SYNCED'),
          dataKey: 'lastSynced',
          sortable: true,
          width: 120,
          type: 'synced',
        },
        {
          title: '',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.help:
      return [
        {
          title: t('WEARABLES.ACTIVITY.WORKER_JOB'),
          dataKey: 'name',
          fixed: true,
          sortable: true,
          align: 'left',
          width: 180,
          type: 'name',
          handler: userOnCLickHandler,
        },
        {
          title: t('WEARABLES.ACTIVITY.DEPARTMENT_SITE'),
          dataKey: 'department',
          align: 'left',
          sortable: true,
          width: 140,
          type: 'department',
          tooltip: t('WEARABLES.ACTIVITY.SORT_DEPARTMENT'),
        },
        {
          title: t('WEARABLES.HELP.CURRENT_H'),
          dataKey: 'average',
          align: 'left',
          width: 130,
          type: 'average',
          avg: true,
          sortable: true,
          tooltip: t('WEARABLES.HELP.CURRENT_H_TOOLTIP'),
        },
        {
          title: t('WEARABLES.HELP.DAYS'),
          sortable: true,
          dataKey: 'daysPassed',
          align: 'left',
          width: 90,
          type: 'daysPassed',
        },
        {
          title: t('WEARABLES.ACTIVITY.SESSION'),
          sortable: true,
          dataKey: 'lastSync',
          align: 'left',
          width: 119,
          type: 'lastSync',
        },
        {
          title: t('GENERAL.DEVICE'),
          dataKey: 'device',
          sortable: true,
          align: 'left',
          width: 105,
          type: 'device',
          isClip: true,
          tooltip: t('WEARABLES.HELP.SORT_BATTERY'),
        },
        {
          title: t('WEARABLES.HELP.ACTION'),
          dataKey: 'comment',
          sortable: false,
          align: 'left',
          width: 140,
          type: 'comment',
        },
        {
          title: t('WEARABLES.HELP.CREATED'),
          dataKey: 'createdAt',
          sortable: true,
          align: 'left',
          width: 120,
          type: 'createdAt',
        },
        {
          title: t('WEARABLES.HELP.STATUS'),
          dataKey: 'status',
          sortable: true,
          align: 'left',
          width: 110,
          type: 'status',
        },
      ];
    case TableType.companies:
      return [
        {
          title: 'Id',
          dataKey: 'companyId',
          width: 60,
          fixed: true,
          sortable: true,
          align: 'right',
          type: 'id',
        },
        {
          title: 'Name',
          dataKey: 'companyName',
          fixed: true,
          width: 1050,
          sortable: true,
          type: 'companyName',
        },
        // {
        //   title: 'Region',
        //   dataKey: 'region',
        //   width: 83,
        //   sortable: true,
        //   type: 'region',
        // },
        // {
        //   title: 'Partner',
        //   dataKey: 'reseller',
        //   width: 110,
        //   sortable: true,
        //   type: 'reseller',
        // },
        // {
        //   title: 'Devices',
        //   dataKey: 'companyDeviceCount',
        //   width: 88,
        //   sortable: true,
        //   type: 'companyDeviceCount',
        // },
        // {
        //   title: 'Available programs',
        //   dataKey: 'programs',
        //   width: 335,
        //   type: 'programs',
        // },
        // {
        //   title: 'Created',
        //   dataKey: 'companyCreatedAt',
        //   sortable: true,
        //   align: 'left',
        //   width: 135,
        //   type: 'companyCreatedAt',
        // },
        // {
        //   title: 'Names',
        //   dataKey: 'namesInReport',
        //   width: 120,
        //   sortable: true,
        //   type: 'namesInReport',
        // },
        {
          title: '',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.dashboardUsers:
      return [
        {
          title: 'Id',
          dataKey: 'userId',
          width: 55,
          fixed: true,
          sortable: true,
          align: 'right',
          type: 'id',
        },
        {
          title: 'Name & Email',
          dataKey: 'name',
          fixed: true,
          width: 200,
          sortable: true,
          type: 'name',
        },
        {
          title: 'Granted access',
          dataKey: 'access',
          width: 130,
          type: 'access',
        },
        {
          title: 'Granted access',
          dataKey: 'siteAccess',
          width: 130,
          type: 'siteAccess',
        },
        {
          title: 'Admin',
          dataKey: 'isAdmin',
          width: 75,
          sortable: true,
          type: 'isAdmin',
        },
        {
          title: 'Partner',
          dataKey: 'isPartner',
          width: 90,
          sortable: true,
          type: 'isPartner',
        },
        {
          title: 'Subscription manager',
          dataKey: 'isSubscriptionManager',
          width: 125,
          sortable: true,
          type: 'isSubscriptionManager',
          tooltip: 'Subscription manager',
        },
        {
          title: 'Created by',
          dataKey: 'createdBy',
          sortable: true,
          align: 'left',
          width: 145,
          type: 'createdBy',
        },
        {
          title: 'Activity & Last seen',
          dataKey: 'lastSeen',
          align: 'left',
          width: 140,
          sortable: true,
          type: 'lastSeen',
          tooltip: 'Activity & Last seen',
        },
        {
          title: '',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.myCompanyUsers:
      return [
        {
          title: t('GENERAL.ID'),
          dataKey: 'userId',
          width: 100,
          fixed: true,
          sortable: true,
          // align: 'right',
          type: 'id',
        },
        {
          title: t('MY_COMPANY.USERS.NAME_MAIL'),
          dataKey: 'name',
          fixed: true,
          width: 210,
          sortable: true,
          type: 'name',
        },
        {
          title: t('MY_COMPANY.USERS.ACCESS'),
          dataKey: 'access',
          width: 160,
          type: 'access',
        },
        {
          title: t('MY_COMPANY.USERS.ACCESS'),
          dataKey: 'siteAccess',
          width: 150,
          type: 'siteAccess',
        },
        {
          title: t('MY_COMPANY.USERS.CAN_INVITE'),
          dataKey: 'canInviteUsers',
          width: 180,
          sortable: true,
          type: 'canInviteUsers',
        },
        {
          title: t('MY_COMPANY.USERS.NEED_ACTION'),
          dataKey: 'receiveNeedHelpRequests',
          width: 200,
          sortable: true,
          type: 'receiveNeedHelpRequests',
        },
        {
          title: t('MY_COMPANY.USERS.CREATED_BY'),
          dataKey: 'createdBy',
          sortable: true,
          align: 'left',
          width: 105,
          type: 'createdBy',
        },
        {
          title: '',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.soterTaskVideos:
      return [
        {
          title: t('SOTER_TASK.VIDEOS_LIST.VIDEO'),
          dataKey: 'previewAndName',
          fixed: true,
          width: 195,
          type: 'previewAndName',
          sortable: true,
          handler: userOnCLickHandler,
        },
        {
          title: t('WEARABLES.ACTIVITY.DEPARTMENT_SITE'),
          dataKey: 'departmentAndSite',
          width: 125,
          type: 'departmentAndSite',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.DATE'),
          dataKey: 'dateOfUpload',
          width: 115,
          sortable: true,
          type: 'dateOfUpload',
        },
        {
          title: t('WEARABLES.HELP.STATUS'),
          dataKey: 'status',
          width: 90,
          sortable: true,
          type: 'status',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.TYPE'),
          dataKey: 'videoType',
          width: 125,
          sortable: true,
          type: 'videoType',
        },
        {
          title: t('MY_COMPANY.USERS.CREATED_BY'),
          dataKey: 'videoCreatedBy',
          width: 105,
          sortable: true,
          type: 'videoCreatedBy',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.TASK_NAME'),
          dataKey: 'videoTaskName',
          width: 100,
          sortable: true,
          type: 'videoTaskName',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
          dataKey: 'videoJobRole',
          width: 90,
          sortable: true,
          type: 'videoJobRole',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.RISK'),
          dataKey: 'videoRisk',
          width: 65,
          sortable: true,
          type: 'videoRisk',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.REBA'),
          dataKey: 'reba',
          width: 100,
          sortable: true,
          type: 'reba',
        },
        {
          title: '',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.soterTaskComparison:
      return [
        {
          title: t('SOTER_TASK.COMPARISONS_LIST.COMPARISONS'),
          dataKey: 'title',
          type: 'title',
          sortable: true,
          fixed: true,
          width: 240,
        },
        {
          title: t('WEARABLES.ACTIVITY.DEPARTMENT_SITE'),
          dataKey: 'departmentAndSite',
          width: 150,
          type: 'departmentAndSite',
        },
        {
          title: t('SOTER_TASK.COMPARISONS_LIST.DATE'),
          dataKey: 'createdAt',
          type: 'createdAt',
          sortable: true,
          width: 200,
        },
        {
          title: t('MY_COMPANY.USERS.CREATED_BY'),
          dataKey: 'createdBy',
          type: 'createdBy',
          sortable: true,
          width: 200,
        },
        {
          title: t('SOTER_TASK.COMPARISONS_LIST.CHANGES'),
          dataKey: 'changesInRisk',
          type: 'changesInRisk',
          flexGrow: 1,
          minWidth: 160,
        },
        {
          title: t('SOTER_TASK.COMPARISONS_LIST.IMPROVEMENTS'),
          dataKey: 'improvements',
          type: 'improvements',
          flexGrow: 1,
          minWidth: 70,
          sortable: true,
        },
        {
          title: ' ',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.adminDevices:
      return [
        {
          title: 'Device name',
          dataKey: 'id',
          flexGrow: 2,
          fixed: true,
          minWidth: 186,
          type: 'device',
        },
        {
          title: 'Company',
          dataKey: 'company',
          flexGrow: 2,
          minWidth: 120,
          type: 'company',
        },
        {
          title: 'Linked at',
          dataKey: 'linkedAt',
          width: 130,
          flexGrow: 1,
          type: 'synced',
        },
        {
          title: 'Batch',
          dataKey: 'batch',
          flexGrow: 1,
          type: 'batch',
        },
        // {
        //   title: '',
        //   dataKey: 'id',
        //   width: 40,
        //   type: 'actions',
        //   actions,
        // },
      ];
    case TableType.hubs:
      return [
        {
          title: 'ID',
          dataKey: 'id',
          fixed: true,
          width: 70,
          type: 'id',
          sortable: true,
        },
        {
          title: 'IMEI',
          dataKey: 'imei',
          fixed: true,
          flexGrow: 2,
          minWidth: 120,
          type: 'imei',
        },
        {
          title: 'ALIAS',
          dataKey: 'alias',
          flexGrow: 2,
          minWidth: 130,
          type: 'alias',
        },
        {
          title: 'Company',
          dataKey: 'companyId',
          minWidth: 110,
          flexGrow: 1,
          type: 'company',
        },
        {
          title: 'Status',
          dataKey: 'isActive',
          minWidth: 110,
          flexGrow: 1,
          type: 'status',
        },
        {
          title: 'Hub type',
          dataKey: 'hubType',
          minWidth: 110,
          flexGrow: 1,
          type: 'hubType',
        },
        {
          title: 'Lasts seen',
          dataKey: 'lastSeenMilliseconds',
          minWidth: 110,
          flexGrow: 1,
          type: 'lastSeenMilliseconds',
          sortable: true,
        },
        {
          title: 'Created date',
          dataKey: 'createdAtMilliseconds',
          minWidth: 100,
          flexGrow: 1,
          type: 'createdAtMilliseconds',
          sortable: true,
        },
        {
          title: '',
          dataKey: 'id',
          width: 40,
          type: 'actions',
          actions,
        },
      ];
    case TableType.feedbacks:
      return [
        {
          title: t('FEEDBACK.ADMIN.USERNAME'),
          dataKey: 'dashboardUserId',
          fixed: true,
          width: 160,
          type: 'dashboardUserId',
          sortable: true,
        },
        {
          title: t('FEEDBACK.ADMIN.REASON'),
          dataKey: 'reason',
          fixed: true,
          minWidth: 120,
          type: 'reason',
          sortable: true,
        },
        {
          title: t('FEEDBACK.ADMIN.DESCRIPTION'),
          dataKey: 'description',
          flexGrow: 2,
          minWidth: 140,
          type: 'description',
        },
        {
          title: t('FEEDBACK.ADMIN.RATE'),
          dataKey: 'rate',
          minWidth: 110,
          flexGrow: 1,
          type: 'rate',
          sortable: true,
        },
        {
          title: t('FEEDBACK.ADMIN.DATE'),
          dataKey: 'createdAt',
          minWidth: 110,
          flexGrow: 1,
          type: 'createdAt',
          sortable: true,
        },
      ];
    case TableType.usersUpload:
      return [
        {
          title: `${userOnCLickHandler}`,
          // sortable: true,
          dataKey: 'status',
          width: 120,
          align: 'left',
          type: 'number',
        },
        {
          title: t('LOGIN.LOGIN'),
          dataKey: 'login',
          // flexGrow: 2,
          minWidth: 120,
          align: 'left',
          type: 'login',
        },
        {
          title: t('GENERAL.FIRST_NAME'),
          dataKey: 'firstName',
          flexGrow: 2,
          minWidth: 106,
          align: 'left',
          type: 'firstName',
        },
        {
          title: t('GENERAL.LAST_NAME'),
          dataKey: 'lastName',
          flexGrow: 2,
          minWidth: 106,
          align: 'left',
          type: 'lastName',
        },
        {
          title: t('WEARABLES.OVERVIEW.DEPARTMENT'),
          // sortable: true,
          dataKey: 'department',
          minWidth: 126,
          align: 'left',
          type: 'department',
        },
        {
          title: t('WEARABLES.OVERVIEW.SITE'),
          dataKey: 'site',
          // flexGrow: 2,
          minWidth: 120,
          align: 'left',
          type: 'site',
        },
        {
          title: t('SOTER_TASK.VIDEOS_LIST.JOB_ROLE'),
          // sortable: true,
          dataKey: 'jobRole',
          width: 250,
          align: 'left',
          type: 'jobRole',
        },
      ];
    case TableType.categories:
      return [
        {
          title: '№',
          dataKey: 'number',
          width: 110,
          align: 'center',
          type: 'number',
        },
        {
          title: t('MY_COMPANY.CATEGORIES.CATEGORIES'),
          dataKey: 'title',
          width: 400,
          sortable: false,
          align: 'left',
          type: 'title',
        },
        {
          title: t('MY_COMPANY.CATEGORIES.ENTRIES'),
          dataKey: 'entries',
          align: 'left',
          width: 150,
          type: 'entries',
        },
        {
          title: '',
          dataKey: 'edit',
          width: 400,
          align: 'left',
          type: 'edit',
        },
        {
          title: '',
          dataKey: 'id',
          width: 70,
          align: 'center',
          type: 'actions',
          actions,
        },
      ];
    case TableType.entries:
      return [
        {
          title: SelectionCell,
          dataKey: 'id',
          width: 50,
          fixed: true,
          type: 'selection',
          align: 'center',
          checked: multiSelectionCheckedGetter,
          handler: selectionHandler,
        },
        {
          title: `${userOnCLickHandler} ${t(
            'MY_COMPANY.CATEGORIES.ENTRIES',
          ).toLowerCase()}`,
          dataKey: 'title',
          sortable: true,
          width: 450,
          align: 'left',
          type: 'title',
        },
        {
          title: '',
          dataKey: 'actions',
          width: 650,
          align: 'right',
          type: 'actions',
          actions,
        },
      ];
    case TableType.jobs:
      return [
        {
          title: 'Job role',
          dataKey: 'title',
          type: 'title',
          flexGrow: 1,
        },
      ];
    default:
      return [];
  }
}
