import React from 'react';
import moment from 'moment';
import { mutedText } from '../../assets/soter-flex-styles';

const CreatedAtCellStringSpecified = ({ rowData }) => {
  const parseDate = (input) => {
    // Check if input is a number (timestamp)
    if (typeof input === 'number') {
      return moment.unix(input); // assuming the timestamp is in seconds
    }
    // Otherwise, try to reformat the string to an ISO 8601 compliant format
    const isoFormattedString = input.replace(' ', 'T').replace(' +0000 UTC', 'Z');
    return moment(isoFormattedString, moment.ISO_8601);
  };

  const time = parseDate(rowData.createdAt);

  // Check if the time is valid before attempting to format it
  const formattedTime = time.isValid() ? time.format('DD MMM YYYY') : 'Invalid date';
  const fromNowText = time.isValid() ? time.fromNow() : 'Invalid date';

  return (
    <div>
      <span>{formattedTime}</span>
      <div style={{ fontSize: '12px' }} className={mutedText}>
        {fromNowText.includes('ago') ? fromNowText.slice(0, fromNowText.indexOf('ago') + 3) : fromNowText}
      </div>
    </div>
  );
};

export default CreatedAtCellStringSpecified;
