import React from 'react';
import { Checkbox } from 'rsuite';
import { headerCheckbox, selectionCheckbox } from '../../../../assets/jss/style';

const SelectionCell = ({ rowData, checked, handler, isHeaderCheckbox }) => (
  <Checkbox
    value={rowData || 'all'}
    onChange={handler}
    className={`${selectionCheckbox} ${isHeaderCheckbox && headerCheckbox}`}
    checked={checked}
  />
);

export default SelectionCell;
