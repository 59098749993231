import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import sliceLongString from 'lib/utils/sliceLongString';
import Text from 'lib/ui/Text';
import {
  columnExtraSmall,
  columnSmall,
  flexColumn,
  flexRow,
  pl3,
  pr3,
  pt3,
  subText,
} from '../../../assets/soter-flex-styles';

const UserContainer = ({ user, timezone, children }) => {
  const {
    firstName,
    lastName,
    nickname,
    jobRole,
    department,
    site,
    registeredAtInMilliseconds,
  } = user;
  const { t } = useTranslation();
  const isNoNameClip = lastName === 'Clip&Go';
  const name = isNoNameClip ? nickname : `${firstName} ${lastName}`;
  return (
    <div className={flexColumn}>
      <div className={`${flexRow} ${pt3}`}>
        <div className={flexColumn}>
          <div className={flexRow}>
            <div className={`${flexColumn} ${pl3}`}>
              <h3>{sliceLongString(name, 30)}</h3>
              {!isNoNameClip && <p className={subText}>{nickname || ''}</p>}
            </div>
            <div className={columnExtraSmall}>
              <span />
            </div>
          </div>
          <div className={`${flexRow} ${pt3}`}>
            <div className={`${flexColumn} ${pl3}`}>
              <p className={subText} style={{ textAlign: 'left' }}>
                {`${t('GENERAL.JOB_TITLE')}: ${jobRole?.title || t('WEARABLES.USER_PAGE.NO_JOB_TITLE')}, ${t('WEARABLES.OVERVIEW.DEPARTMENT')}: 
                  ${department?.name ? department.name : t('WEARABLES.USER_PAGE.DEPARTMENT_NOT_ASSIGN')}, ${t('WEARABLES.OVERVIEW.SITE')}:
                  ${site?.name ? site.name : t('WEARABLES.USER_PAGE.SITE_NOT_ASSIGN')}`}
              </p>
            </div>
          </div>
        </div>
        <div className={columnSmall}>
          {children}
          <div className={`${flexRow} ${pt3}`}>
            <div className={`${flexColumn} ${pr3}`}>
              <Text
                text={
                  (user.registeredAtInMilliseconds &&
                    `${t('WEARABLES.USER_PAGE.REGISTERED_AT')}: ${moment(
                      registeredAtInMilliseconds,
                      'x',
                    )
                      .tz(timezone)
                      .format('MMM DD, YYYY')}`)
                  || null
                }
                className={subText}
                style={{ textAlign: 'right' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserContainer;
