export const USER_SET = 'USER_SET';
export const USER_ERROR = 'USER_ERROR';
export const USER_TOGGLE_LOADING = 'USER_TOGGLE_LOADING';
export const USER_RESET = 'USER_RESET';
export const FEEDBACK_SET = 'FEEDBACK_SET';

export const USER_EDIT_STATUS = 'USER_EDIT_STATUS';
export const USER_CLEAR_EDIT_STATUS = 'USER_CLEAR_EDIT_STATUS';

export const USER_EDIT_SET = 'USER_EDIT_SET';
