import React from 'react';
import { CHART_HEIGHT, VIDEO_WIDTH } from '../constants';
import { COLORS } from '../../../constants/colors';
import DonutChart from './DonutChart';
import legendSquare from '../../../assets/jss/components/legendSquare';
import presentSeconds from '../../../lib/utils/presentSeconds';

const getMargin = (scale) => (scale ? '20px' : '35px');

const DonutChartWithLegendForReba = ({
  chartAnglesData,
  selectedBodyPart,
  scale,
}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: '18px 0',
      color: COLORS.GRAY[400],
      fontSize: '12px',
      width: '333px',
    }}
  >
    <div
      style={{
        marginLeft: scale ? 0 : '10px',
        color: COLORS.GRAY[950],
        marginRight: '15px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
          marginTop: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            COLORS.RED[700],
            !!scale,
          )}
        />
        <div>
          <span>Very high:</span>
          <span>11+</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
          marginTop: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            COLORS.RED[500],
            !!scale,
          )}
        />
        <div>
          <span>High: 8-10</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
          marginTop: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            COLORS.YELLOW[600],
            !!scale,
          )}
        />
        <div>
          <span>Medium: 4-7</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
          marginTop: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            COLORS.YELLOW[300],
            !!scale,
          )}
        />
        <div>
          <span>Low: 2-3</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: getMargin(scale),
          marginTop: getMargin(scale),
        }}
      >
        <div
          className={legendSquare(
            COLORS.GREEN[600],
            !!scale,
          )}
        />
        <div>
          <span>Negligible: 1</span>
        </div>
      </div>
    </div>
    <div style={{ marginTop: scale > 0.5 ? '0px' : '4px' }}>
      <DonutChart
        height={CHART_HEIGHT - 20}
        width={VIDEO_WIDTH / 4 - 30}
        data={chartAnglesData[selectedBodyPart]?.arcs}
        scale={scale}
        totalTime={presentSeconds(
          chartAnglesData[selectedBodyPart]?.totalFramesWithAngles / 30,
        )}
      />
    </div>
  </div>
);

export default DonutChartWithLegendForReba;
