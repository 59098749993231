import { getTimeInUserTimeZone } from 'lib/utils/getTimeInUserTimeZone';

const removeItem = (arr, item) => {
  const index = arr.indexOf(item);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

const getEmptyHazards = (data) => {
  const keys = Object.keys(data);
  const keysToRemove = ['timeInMilliseconds', 'time'];
  keysToRemove.forEach((item) => removeItem(keys, item));
  const emptyHazards = {};
  keys.forEach((item) => {
    emptyHazards[item] = 0;
  });
  return emptyHazards;
};

export default (data, timezone, oneHour) => {
  if (!data || data.length === 0) {
    return [];
  }

  const res = [data[0]];
  const emptyHazards = getEmptyHazards(data[0]);

  for (let i = 1; i < data.length; i += 1) {
    const current = data[i]?.timeInMilliseconds;
    const prev = data[i - 1]?.timeInMilliseconds;

    for (let time = prev + oneHour; time < current; time += oneHour) {
      res.push({
        time: getTimeInUserTimeZone(time, timezone, 'HH:mm'),
        ...emptyHazards,
      });
    }

    res.push(data[i]);
  }

  return res;
};
