import React from 'react';
import { Link, } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { outlineColoredButton } from '../../../../assets/soter-flex-styles/button-elements';
import { useSelector } from 'react-redux';
// 'src/assets/soter-flex-styles/button-elements';

const SoterTaskComparisonEmptyList = () => {
  const { t } = useTranslation();
  const { id: companyID } = useSelector((state) => state.companies.selectedCompany)
  return (
    <div
      style={{
        position: 'absolute',
        textAlign: 'center',
        width: '400px',
        left: '50%',
        marginLeft: '-200px',
        top: '240px',
      }}
    >
      <h3>{t('SOTER_TASK.COMPARISONS_LIST.NO_COMPARISON')}</h3>
      <h5>{t('SOTER_TASK.COMPARISONS_LIST.CREATE_FIRST')}</h5>

      <Link
        className={outlineColoredButton('#248BF2', 'small')}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '210px',
          height: '40px',
          textDecoration: 'none',
          margin: '0 auto',
          marginTop: '24px',
          fontSize: '1rem',
        }}
        to={`/${companyID}/users/soter-genius/comparison/new`}
        type="button"
      >
        {t('SOTER_TASK.COMPARISONS_LIST.CREATE_NEW')}
      </Link>
    </div>
  );
};

export default SoterTaskComparisonEmptyList;
