import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getActionTypeById, getCreateEntryActionType, getDeleteEntriesAction, getFilteredByTitleArray, } from '../utils/helpers';
import { setOpenedModal as closeModals } from '../../containers/ModalContainer/modalsStore/actions';
import ActionToggleButton from '../../lib/ui/ActionToggleButton';
import DeleteEntityDialog from '../../lib/ui/DeleteEntityDialog';
import StaticNotification from '../../containers/NotificationContainer/components/StaticNotification';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
import ItemTableContainer from '../../containers/ItemTableContainer/ItemTableContainer';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import getColumns from '../../containers/ItemTableContainer/utils/getColumns';
import { getMultiSelectionChecked, selectionHandler, } from '../../containers/ItemTableContainer/utils/checkboxSelctionHandlers';
import { prepareSeparatedTitle } from '../utils/preparedTitle';
import SelectionCell from '../../containers/ItemTableContainer/components/CustomCells/SelectionCell';
import ActionCell from '../../containers/ItemTableContainer/components/CustomCells/ActionCell';
import { getElementByTitle } from '../utils/getElementByTitle';
import { setCurrentCategory } from '../categories/store/categorySlice';
import { getActionHandlerForModal } from '../../lib/utils/getActionHandlerForModal';
import { resetSelectedUsers } from '../../containers/ItemTableContainer/selectedItemsStore/actions';
import { container } from '../../assets/soter-flex-styles';
import { button } from '../../assets/jss/style';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import { COLORS } from '../../constants/colors';
import TitleChangeDialog from './TitleChangeDialog';
const EntriesTable = ({ currentCategory, }) => {
    var _a;
    const { selectedCompany } = useAppSelector((state) => state.companies);
    const [openedModal, setOpenedModal] = useState('');
    const { t } = useTranslation();
    const handleCloseModal = () => {
        setOpenedModal('');
    };
    const { entries: selectedEntries } = useAppSelector((state) => state.selectedItems);
    const [editedEntry, setEditedEntry] = useState(0);
    const currentRoute = useLocation();
    const { categories } = useSelector((state) => state.category);
    const dispatch = useAppDispatch();
    const items = currentCategory.tagsList;
    const sortedItems = [...items];
    const isLoading = useSelector((state) => state.category.isLoading);
    const columns = (getActions) => getColumns(getMultiSelectionChecked, selectionHandler(dispatch, TableType.entries), prepareSeparatedTitle(currentCategory.title, t), getActions, TableType.entries, t);
    const cells = {
        selection: SelectionCell,
        title: (rowData) => (React.createElement("div", null,
            rowData.rowData.title,
            ' ')),
        actions: ActionCell,
    };
    const warningTextGetter = (t) => {
        switch (currentCategory.title) {
            case 'user_job_role':
                return `${selectedEntries.length > 1
                    ? t('MY_COMPANY.CATEGORIES.JOB_ROLES')
                    : t('MY_COMPANY.CATEGORIES.JOB_ROLE')} 
        ${t('MY_COMPANY.CATEGORIES.WILL_BE')}`;
            case 'site':
                return `${selectedEntries.length > 1
                    ? t('MY_COMPANY.USERS.SITES')
                    : t('MY_COMPANY.USERS.SITE')} ${t('MY_COMPANY.CATEGORIES.WILL_AND')}`;
            case 'department':
                return `${selectedEntries.length > 1
                    ? t('MY_COMPANY.USERS.DEPARTMENTS')
                    : t('MY_COMPANY.USERS.DEPARTMENT')} 
        ${t('MY_COMPANY.CATEGORIES.WILL_AND')}`;
            case 'task_title':
                return `${selectedEntries.length > 1
                    ? t('MY_COMPANY.CATEGORIES.TASKS')
                    : t('MY_COMPANY.CATEGORIES.TASK')} ${t('MY_COMPANY.CATEGORIES.WILL_UN')}`;
            default:
                return `${prepareSeparatedTitle(currentCategory.title, t)} ${t('MY_COMPANY.CATEGORIES.WILL_FROM')}`;
        }
    };
    useEffect(() => {
        if (!currentCategory) {
            const category = getElementByTitle(categories, currentRoute);
            dispatch(setCurrentCategory({ id: category.id, title: category.title }));
        }
    }, [categories]);
    useEffect(() => {
        const taskName = localStorage.getItem('taskName');
        taskName && setOpenedModal('createEntries');
    }, []);
    const actions = [
        {
            title: t('MY_COMPANY.CATEGORIES.RENAME'),
            handler: ({ id }) => {
                setEditedEntry(id);
                setOpenedModal('renameEntry');
            },
        },
        {
            title: t('GENERAL.DELETE'),
            handler: ({ id }) => {
                setEditedEntry(id);
                setOpenedModal('deleteEntry');
            },
            attention: true,
        },
    ];
    const filterButton = {
        text: t('MY_COMPANY.CATEGORIES.CREATE'),
        action: () => {
            setOpenedModal('createEntries');
        },
    };
    const togglerButton = (
    // @ts-ignore
    React.createElement(ActionToggleButton, { name: "entries", dataName: "entries" }));
    const currentTitle = ((_a = sortedItems.find(({ id }) => editedEntry === id)) === null || _a === void 0 ? void 0 : _a.title) || '';
    const usersAction = [
        {
            id: 'deleteEntries',
            name: t('MY_COMPANY.CATEGORIES.DELETE_ENTRY'),
            color: COLORS.RED[600],
            size: 'small',
            child: (React.createElement(DeleteEntityDialog, { confirmText: t('MY_COMPANY.CATEGORIES.SURE_TO'), buttonText: t('MY_COMPANY.CATEGORIES.DELETE_ENTRY'), onSubmit: () => {
                    selectedEntries.map((entry, index) => getDeleteEntriesAction(currentCategory.id, dispatch, entry.id, selectedEntries.length, index, selectedCompany.id));
                    dispatch(closeModals(null));
                    dispatch(resetSelectedUsers());
                }, additionalText: false },
                React.createElement(StaticNotification, { notification: {
                        status: 'TYPE_WARNING',
                        message: warningTextGetter(t),
                    } }))),
            disabled: (selectedEntries === null || selectedEntries === void 0 ? void 0 : selectedEntries.length) === 0,
        },
    ];
    const usersActions = [
        {
            bubble: selectedEntries.length,
            dropdown: {
                type: 'entries',
                selectedItems: selectedEntries,
                selectedCompany,
                userActions: usersAction,
                handler: () => setOpenedModal('deleteEntry'),
                content: t('MY_COMPANY.CATEGORIES.ACTIONS'),
                togglerButton,
            },
        },
    ];
    const switchModal = (modalType) => {
        switch (modalType) {
            case 'deleteEntry':
                return (React.createElement(DeleteEntityDialog, { confirmText: t('MY_COMPANY.CATEGORIES.SURE_TO'), buttonText: t('MY_COMPANY.CATEGORIES.DELETE_ENTRY'), onSubmit: () => {
                        getDeleteEntriesAction(currentCategory.id, dispatch, editedEntry, undefined, undefined, selectedCompany.id);
                        handleCloseModal();
                    }, additionalText: false },
                    React.createElement(StaticNotification, { notification: {
                            status: 'TYPE_WARNING',
                            message: warningTextGetter(t),
                        } })));
            case 'createEntries':
                return (React.createElement(TitleChangeDialog, { companyId: selectedCompany.id, entryId: editedEntry, categoryId: currentCategory.id, handlerFn: (e, companyId, categoryId, title) => {
                        getActionHandlerForModal(getCreateEntryActionType(currentCategory.id), TableType.categories)(handleCloseModal, dispatch)(e, companyId, categoryId, title);
                    }, type: t('MY_COMPANY.CATEGORIES.ENTRIES').toLowerCase(), userIds: false, user: false, subtitle: false, currentTitle: "" }));
            case 'renameEntry':
                return (React.createElement(TitleChangeDialog, { companyId: selectedCompany.id, categoryId: currentCategory.id, entryId: editedEntry, handlerFn: (e, companyId, title, color) => {
                        getActionHandlerForModal(getActionTypeById(currentCategory.id), TableType.categories)(handleCloseModal, dispatch)(e, companyId, editedEntry, title, color);
                    }, type: t('MY_COMPANY.CATEGORIES.ENTRY'), subtitle: t('MY_COMPANY.CATEGORIES.UPDATE'), userIds: false, user: false, currentTitle: currentTitle }));
            default:
                return null;
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: container },
            React.createElement(NavigateBackButton, { name: t('MY_COMPANY.USERS.BACK_TO'), navigateTo: false, goBackStepHistory: true, style: { paddingTop: '10px' }, additionalHandler: false })),
        React.createElement("div", { style: { display: items.length === 0 ? 'none' : 'block' } },
            ' ',
            React.createElement(ItemTableContainer, { items: getFilteredByTitleArray(sortedItems), columns: columns, actions: actions, isLoading: isLoading, type: TableType.entries, cells: cells, filterButtonProps: filterButton, openedModal: openedModal, setOpenedModal: setOpenedModal, switchModal: switchModal, onCloseModal: handleCloseModal, tableClass: false, selectedItems: selectedEntries, overlayClass: false, modalClass: false, keepModalOnClickOutside: true, 
                // @ts-ignore
                secondaryButton: usersActions, stickyOnScrollContainerTopBlock: false, stickyOnScrollContainerHeight: false })),
        items.length === 0 && (React.createElement("div", { style: { marginTop: '10%' } },
            React.createElement("div", { style: {
                    textAlign: 'center',
                    margin: 'auto',
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '30px',
                    marginBottom: '6px',
                } }, t('MY_COMPANY.CATEGORIES.YOU_DONT')),
            React.createElement("div", { style: {
                    textAlign: 'center',
                    margin: 'auto',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                } }, t('MY_COMPANY.CATEGORIES.CREATE_LIST')),
            React.createElement("div", { style: {
                    width: '100px',
                    textAlign: 'center',
                    margin: 'auto',
                    marginTop: '20px',
                }, onClick: () => setOpenedModal('createEntries'), className: button(COLORS.LIGHT_BLUE[600]) }, t('MY_COMPANY.CATEGORIES.CREATE'))))));
};
export default EntriesTable;
