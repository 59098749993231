export const trialRequested = (productType) => {
  if (productType === 1) {
    localStorage.coachTrial = 1;
    localStorage.coachRequestTime = +new Date();
  }
  if (productType === 2) {
    localStorage.clipgoTrial = 2;
    localStorage.clipgoRequestTime = +new Date();
  }
  if (productType === 3) {
    localStorage.taskTrial = 3;
    localStorage.taskRequestTime = +new Date();
  }
  if (productType === 4) {
    localStorage.jobdictionaryTrial = 4;
    localStorage.jobdictionaryRequestTime = +new Date();
  }
};

export const deleteTrialRequest = (now) => {
  if ((now - +localStorage.coachRequestTime) >= 86400000) {
    localStorage.removeItem('coachRequestTime');
    localStorage.removeItem('coachTrial');
  }
  if ((now - +localStorage.clipgoRequestTime) >= 86400000) {
    localStorage.removeItem('clipgoRequestTime');
    localStorage.removeItem('clipgoTrial');
  }
  if ((now - +localStorage.taskRequestTime) >= 86400000) {
    localStorage.removeItem('taskRequestTime');
    localStorage.removeItem('taskTrial');
  }
  if ((now - +localStorage.jobdictionaryRequestTime) >= 86400000) {
    localStorage.removeItem('jobdictionaryRequestTime');
    localStorage.removeItem('jobdictionaryTrial');
  }
};
