import React from 'react';
import * as Emotion from 'emotion';
import { useTranslation } from 'react-i18next';
import { absoluteColoredLabel, absoluteHorizontalLine } from '../../assets/jss/style';
import { COLORS } from '../../constants/colors';
import { risks } from '../constants';
import hexToRgba from '../../lib/utils/hexToRgba';

const colors = [COLORS.GRAY[400], COLORS.GREEN[600], COLORS.ORANGE[600], COLORS.RED[600]];

const circle = (disabled) => Emotion.css`
  border-radius: 100px;
  background-color: ${hexToRgba(COLORS.LIGHT_BLUE[600], 0.5)};
  width: 38px;
  height: 38px;
  &:hover {
    cursor: ${disabled ? 'default' : 'pointer'};
    background-color: ${hexToRgba(COLORS.LIGHT_BLUE[600], disabled ? 0.5 : 0.4)};
  }
`;

const bodyPartControl = (top, left) => Emotion.css`
  position: absolute;
  top: ${top}px;
  left: ${left}px;
`;

const BodyPartControl = ({
  isEditing,
  changeView,
  changeRiskLevel,
  top,
  left,
  goRight,
  riskLevel,
}) => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem('language');
  const labelOffsets = [
    0,
    currentLanguage === 'es' ? 52 : currentLanguage === 'fr' ? 62 : 50,
    currentLanguage === 'es' ? 148 : currentLanguage === 'fr' ? 140 : 125,
  ];

  return (
    <div className={bodyPartControl(top, left)}>
      <div className={circle(!changeView)} onClick={changeView} />
      <hr className={absoluteHorizontalLine(0, -150, goRight)} />
      {isEditing ? (
        risks.map((r, i) => (
          <span
            key={r}
            onClick={() => changeRiskLevel(i)}
            className={absoluteColoredLabel(
              -25,
              -225 + labelOffsets[i],
              colors[i],
              goRight,
              isEditing,
              r === risks[riskLevel],
            )}
          >
            {t(r)}
          </span>
        ))
      ) : (
        <span
          className={absoluteColoredLabel(
            -25,
            -200,
            colors[riskLevel],
            goRight,
            isEditing,
            true,
          )}
        >
          {`${t(risks[riskLevel])} ${t(
            'JOB_DICTIONARY.DEMANDS',
          ).toLowerCase()}`}
        </span>
      )}
    </div>
  );
};

export default BodyPartControl;
