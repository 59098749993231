import React, { useState } from 'react';
import {
  companyBar,
  companyWrapper,
  disabledButton,
  makeCompanyWrapperWithBorder,
  plusButton,
  subText,
} from '../../../assets/jss/components/companiesAccordion';
import { inputLabel, mt2, mt4, } from '../../../assets/soter-flex-styles';
import { ReactComponent as AccordionOpen } from '../../../assets/img/icons/plus-square.svg';
import { ReactComponent as AccordionClose } from '../../../assets/img/icons/minus-square.svg';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../lib/ui/CustomSelect';
import capitalizeFirstLetter from '../../../lib/utils/capitalizeFirstLetter';
import { getDepartmentSelectOptions, getSelectedDepartmentString } from './utils';
import { NO_DEPARTMENTS, NO_SITES } from './constants';

const CompaniesAccordion = ({
  companies,
  departments,
  sites,
  accessibleDepartments,
  setDepartment,
  setSite,
}) => {
  const [openedCompany, setOpenedCompany] = useState(null);
  const { t } = useTranslation();
  const isCompanyOpen = (id) => openedCompany === id;
  return (
    <div className={mt2}>
      {companies.map(({ id, name, departmentsList, sitesList }) => (
        <div key={id} className={companyWrapper}>
          <div className={companyBar}>
            {openedCompany === id
              ? (
                <AccordionClose
                  className={plusButton}
                  onClick={() => setOpenedCompany(null)}
                />
              )
              : (
                <AccordionOpen
                  className={`${plusButton} ${(departmentsList.length === 0 && sitesList.length === 0) ? disabledButton : ''}`}
                  onClick={() => (departmentsList.length > 0 || sitesList.length > 0) && setOpenedCompany(id)}
                />
              )}
            <div className={makeCompanyWrapperWithBorder(!isCompanyOpen(id))}>
              <>
                <span>{name}</span>
                {
                  isCompanyOpen(id) ? (
                    <div className={mt4}>
                      {departmentsList?.length > 0 ? (
                        <>
                          <label className={inputLabel}>{capitalizeFirstLetter(t('WEARABLES.OVERVIEW.DEPARTMENTS'))}</label>
                          <CustomSelect
                            name="departments"
                            menuPlacement="top"
                            placeholder={<div>{t('WEARABLES.OVERVIEW.ALL_DEPARTMENTS')}</div>}
                            value={departments[id] || null}
                            options={(getDepartmentSelectOptions(departmentsList)).filter((el) => accessibleDepartments.includes(el.label))}
                            isSearchable
                            isClearable
                            isMulti
                            dropdownIndicator
                            clearIndicator={false}
                            // menuPortalTarget={document.body}
                            onChange={(_, values) => setDepartment(id, values)}
                          />
                        </>
                      ) : (
                        <span className={subText}>
                          {t(NO_DEPARTMENTS)}
                        </span>
                      )}
                      {sitesList?.length > 0 ? (
                        <>
                          <label className={`${inputLabel} ${mt2}`}>{capitalizeFirstLetter(t('MY_COMPANY.USERS.SITES'))}</label>
                          <CustomSelect
                            name="sites"
                            menuPlacement="top"
                            placeholder={<div>{t('WEARABLES.OVERVIEW.ALL_SITES')}</div>}
                            value={sites[id] || null}
                            options={(getDepartmentSelectOptions(sitesList))}
                            isSearchable
                            isClearable
                            isMulti
                            dropdownIndicator
                            clearIndicator={false}
                            // menuPortalTarget={document.body}
                            onChange={(_, values) => setSite(id, values)}
                          />
                        </>
                      ) : (
                        <span className={`${subText} ${mt2}`}>
                          {t(NO_SITES)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className={subText}>
                      {t(getSelectedDepartmentString(departments[id] || [], departmentsList))}
                      {' '}
                      {t(getSelectedDepartmentString(sites[id] || [], sitesList, true))}
                    </div>
                  )
                }
              </>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompaniesAccordion;
