// source: api/dashboard/v2/report_data_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.report_data.ReportData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.report_data.ReportData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.report_data.ReportData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.report_data.ReportData.displayName = 'proto.report_data.ReportData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.report_data.ReportData.prototype.toObject = function(opt_includeInstance) {
  return proto.report_data.ReportData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.report_data.ReportData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_data.ReportData.toObject = function(includeInstance, msg) {
  var f, obj = {
    hazardsCountSpine: jspb.Message.getFieldWithDefault(msg, 1, 0),
    movementsCountSpine: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hazardsCountShoulder: jspb.Message.getFieldWithDefault(msg, 3, 0),
    movementsCountShoulder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hoursRecorded: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    poorLifting: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    intenseLifting: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    backTwisting: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    staticPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    repetition: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    pushingPulling: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    staticArmElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    repetitiveArmMovements: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    overexertion: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    armElevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    workSessionCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    hazardsPerHour: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.report_data.ReportData}
 */
proto.report_data.ReportData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.report_data.ReportData;
  return proto.report_data.ReportData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.report_data.ReportData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.report_data.ReportData}
 */
proto.report_data.ReportData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazardsCountSpine(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMovementsCountSpine(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazardsCountShoulder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMovementsCountShoulder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHoursRecorded(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPoorLifting(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntenseLifting(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackTwisting(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaticPosition(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetition(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPushingPulling(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaticArmElevation(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRepetitiveArmMovements(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverexertion(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArmElevation(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkSessionCount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHazardsPerHour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.report_data.ReportData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.report_data.ReportData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.report_data.ReportData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.report_data.ReportData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHazardsCountSpine();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMovementsCountSpine();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHazardsCountShoulder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMovementsCountShoulder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHoursRecorded();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPoorLifting();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getIntenseLifting();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBackTwisting();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getStaticPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getRepetition();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPushingPulling();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getStaticArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getRepetitiveArmMovements();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getOverexertion();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getArmElevation();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getWorkSessionCount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getHazardsPerHour();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
};


/**
 * optional int32 hazards_count_spine = 1;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getHazardsCountSpine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setHazardsCountSpine = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 movements_count_spine = 2;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getMovementsCountSpine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setMovementsCountSpine = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 hazards_count_shoulder = 3;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getHazardsCountShoulder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setHazardsCountShoulder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 movements_count_shoulder = 4;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getMovementsCountShoulder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setMovementsCountShoulder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double hours_recorded = 5;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getHoursRecorded = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setHoursRecorded = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double poor_lifting = 6;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getPoorLifting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setPoorLifting = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double intense_lifting = 7;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getIntenseLifting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setIntenseLifting = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double back_twisting = 8;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getBackTwisting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setBackTwisting = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double static_position = 9;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getStaticPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setStaticPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double repetition = 10;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getRepetition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setRepetition = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double pushing_pulling = 11;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getPushingPulling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setPushingPulling = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double static_arm_elevation = 12;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getStaticArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setStaticArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double repetitive_arm_movements = 13;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getRepetitiveArmMovements = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setRepetitiveArmMovements = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double overexertion = 14;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getOverexertion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setOverexertion = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double arm_elevation = 15;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getArmElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setArmElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional int32 work_session_count = 16;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getWorkSessionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setWorkSessionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double hazards_per_hour = 17;
 * @return {number}
 */
proto.report_data.ReportData.prototype.getHazardsPerHour = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.report_data.ReportData} returns this
 */
proto.report_data.ReportData.prototype.setHazardsPerHour = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


goog.object.extend(exports, proto.report_data);
