import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { container } from 'assets/soter-flex-styles';
import Text from '../../lib/ui/Text';
import Button from '../../lib/ui/Button';
import { useAppDispatch, useAppSelector } from '../../lib/utils/useStore';
import { contactSales } from '../../auth/store/actions';
import { deleteTrialRequest } from './utils/requestTrialLocalStorage';
import { adv, content, cta, desc, header, wrapper, } from './styles';
const ProductPreviewContainer = ({ productInfo, productType, layout }) => {
    const { selectedCompany } = useAppSelector((state) => state.companies);
    const dispatch = useAppDispatch();
    const isError = useAppSelector((state) => state.auth.error);
    const requestContactSalesHandler = () => {
        dispatch(contactSales({ companyId: selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id, productType }));
    };
    useEffect(() => {
        const now = +new Date();
        deleteTrialRequest(now);
    }, []);
    return (React.createElement("div", { style: { width: '100vw' } },
        React.createElement("div", { className: wrapper },
            React.createElement("div", { className: container },
                React.createElement("div", { className: content },
                    React.createElement("div", { className: header },
                        React.createElement("h1", null, layout === null || layout === void 0 ? void 0 : layout.header),
                        React.createElement("h3", null, layout === null || layout === void 0 ? void 0 : layout.subHeader)),
                    React.createElement(Text, { className: desc, text: layout === null || layout === void 0 ? void 0 : layout.description, appearance: "headlineTwo" }),
                    React.createElement("div", { className: cta }, isError || (productInfo === null || productInfo === void 0 ? void 0 : productInfo.isSubscriptionRequested) ? React.createElement(Button, { disabled: true, type: "button", text: layout === null || layout === void 0 ? void 0 : layout.requested }) : (React.createElement(Button, { type: "button", handler: requestContactSalesHandler, text: layout === null || layout === void 0 ? void 0 : layout.c2a, disabled: false }))),
                    React.createElement("div", { className: adv }, (layout === null || layout === void 0 ? void 0 : layout.video) ? (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement("ul", null, layout === null || layout === void 0 ? void 0 : layout.advantages),
                        React.createElement("div", { style: { marginTop: -12, marginRight: 155 } }, // @ts-ignore
                        React.createElement(ReactPlayer, { url: layout === null || layout === void 0 ? void 0 : layout.video, width: "384px", height: "197px" })))) : React.createElement("ul", { className: "withoutVideo" }, layout === null || layout === void 0 ? void 0 : layout.advantages)))))));
};
export default ProductPreviewContainer;
