import { physicalDemandActivitiesWithWeight } from '../constants';

export default function (activities) {
  const keys = Object.keys(activities);
  const activitiesWithWeight = {};
  const activitiesWithoutWeight = {};
  keys.forEach((key) => {
    if (physicalDemandActivitiesWithWeight.includes(key)) {
      activitiesWithWeight[key] = activities[key];
    } else {
      activitiesWithoutWeight[key] = activities[key];
    }
  });
  return { activitiesWithWeight, activitiesWithoutWeight };
}
