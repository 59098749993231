import React from 'react';
import TaskPlayer from '../video/components/TaskPlayer';
import DudeKonvaLayer from './DudeKonvaLayer';
import Controls from '../video/components/Controls';

const VideoPlayerWithStickman = ({
  gridArea,
  currentVideo,
  canvasRef,
  videoRef,
  videoHeight,
  videoWidth,
  isPlaying,
  setIsPlaying,
  canvasScale,
  parsedData,
  currentTimeMs,
  totalFrames,
  isDataParsed,
  selectedBodyPart,
  toggleMuteBtn,
  isRewoundVideo,
  setIsRewoundVideo,
}) => (
  <div style={{ position: 'relative', gridArea }}>
    <div ref={canvasRef}>
      <TaskPlayer
        videoLink={currentVideo?.amazonStreamUrl}
        videoRef={videoRef}
        setIsPlaying={setIsPlaying}
        videoHeight={videoHeight}
        videoWidth={videoWidth}
        toggleMuteBtn={toggleMuteBtn}
        isRewoundVideo={isRewoundVideo}
        setIsRewoundVideo={setIsRewoundVideo}
        currentVideo={gridArea}
      />
      <DudeKonvaLayer
        scale={canvasScale}
        width={currentVideo?.videoWidthPx}
        height={currentVideo?.videoHeightPx}
        videoWidth={videoWidth}
        videoHeight={videoHeight}
        linesData={parsedData[Math.round(currentTimeMs * 0.03)]}
        selectedBodyPart={selectedBodyPart}
      />
    </div>
    <Controls
      video={videoRef.current}
      canvas={canvasRef.current}
      currentTimeMs={currentTimeMs}
      totalFrames={totalFrames}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      isDataParsed={isDataParsed}
      videoHeight={videoHeight}
      videoWidth={videoWidth}
    />
  </div>
);

export default VideoPlayerWithStickman;
