import React from 'react';
import { navigate } from '@reach/router';
import { COLORS } from 'constants/colors';
import { backButton } from '../../assets/jss/components/button';
import { invisibleButton } from '../../assets/soter-flex-styles';
import { ReactComponent as ArrowLeft } from '../../assets/img/icons/chevron-left.svg';
import Text from './Text';
import Button from './Button';

const NavigateBackButton = ({
  name,
  navigateTo,
  goBackStepHistory = false,
  style = {},
  color = false,
  additionalHandler,
}) => (
  <div style={style}>
    <Button
      appearance="text"
      type="button"
      className={
        `${invisibleButton('small', color || COLORS.LIGHT_BLUE[600])} ${backButton}`
      }
      style={{
        width: '18rem',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
      }}
      handler={() => {
        navigate(goBackStepHistory ? -1 : navigateTo).then(() => {
          if (additionalHandler) additionalHandler();
        });
      }}
      name="navigate back"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: '600',
        }}
      >
        <span>
          <ArrowLeft
            style={{ stroke: `${color || COLORS.LIGHT_BLUE[600]}`, marginTop: '5px' }}
          />
        </span>
        <Text
          text={name}
          style={{ color: `${color || COLORS.LIGHT_BLUE[600]}` }}
          inline
        />
      </div>
    </Button>
  </div>
);

export default NavigateBackButton;
