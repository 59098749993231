import { createUserRequest, getMetaData, userService } from 'init/proto_client';
import { getIDsFromArray } from 'lib/utils/getIDsFromArray';
import * as types from './actionTypes';
import { setNotification } from '../../../containers/NotificationContainer/notificationsStore/actions';
import { notificationMessages, notificationTypes } from '../../../containers/NotificationContainer/notifications';
import { getUsers } from '../usersStore/actions';

export function createUser(userData, companyId, showNotification = true) {
  return async (dispatch, getState) => {
    const { selectedCompany } = getState().companies;
    const {
      firstName, lastName, login, site, department, jobTitle, id,
    } = userData;
    createUserRequest.setCompanyId(companyId);
    createUserRequest.setFirstName(firstName);
    createUserRequest.setLastName(lastName);
    createUserRequest.setLogin(login);
    createUserRequest.setDepartment(department);
    createUserRequest.setJobTitle(jobTitle);
    createUserRequest.setSite(site);
    await userService.createUser(
      createUserRequest,
      getMetaData(),
      (error, response) => {
        if (error) {
          if (showNotification) {
            if (error.code === 6) {
              dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.CREATE_DASHBOARD_USER_FAILED_ALREADY_EXIST_MSG,
              }));
            } else {
              dispatch(setNotification({
                type: notificationTypes.TYPE_FAILED,
                message: notificationMessages.CREATE_DASHBOARD_USER_FAILED_MSG,
              }));
            }
          }
          dispatch({
            type: types.USERS_ERROR,
            error: { ...error, id: login },
          });
        } else {
          const newUser = response.toObject();
          dispatch(getUsers(
            selectedCompany.id,
            { startedAt: 0, finishedAt: Date.now() + 1000 }, // Added 1 second to account for backend latency in returning the user list
            getIDsFromArray(selectedCompany.sitesList),
            getIDsFromArray(selectedCompany.departmentsList),
          ));
          if (showNotification) {
            dispatch(setNotification({
              type: notificationTypes.TYPE_SUCCESS,
              message: notificationMessages.CREATE_DASHBOARD_USER_SUCCESS_MSG,
            }));
          }
          dispatch({
            type: types.USERS_USER_CREATE,
            newUser,
          });
        }
      },
    );
  };
}

export function resetWearableUser() {
  return { type: types.USERS_USER_RESET };
}

export function resetClipgoUsers() {
  return { type: types.USERS_RESET };
}
