import * as Emotion from 'emotion';
import { absoluteCentered } from '../style';
import { coloredButton, invisibleButton, outlineColoredButton, } from '../../soter-flex-styles';
import { COLORS } from 'constants/colors';

const PADDING = '8px';

export const dateSelect = Emotion.css`
  margin-bottom: 0;

  select {
    margin-left: 1rem;
    border-radius: 6px;
  }
`;

export const autocomplete = Emotion.css`
  .rs-input {
    font-weight: 400;
  }
`;

export const timeSelect = Emotion.css`
  & > div {
    min-height: 36px;
    border-radius: 6px;

    &:hover {
      border-color: ${COLORS.LIGHT_BLUE[600]};
    }
  }
`;

export const button = Emotion.css`
  height: 36px;
  border-radius: 6px;
`;

export const addButton = Emotion.css`
  ${coloredButton(COLORS.LIGHT_BLUE[600], 'small')};
  ${button};

  margin-bottom: 0.5rem;
`;

export const cancelButton = Emotion.css`
  ${outlineColoredButton(COLORS.LIGHT_BLUE[600], 'small')};
  ${button};
`;

export const taskLoader = Emotion.css`
  margin-top: 0;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const emptyTasks = Emotion.css`
  ${absoluteCentered};

  color: ${COLORS.NEUTRAL[950]};
`;

export const tasksList = Emotion.css`
  position: relative;
  width: 100%;
  height: 360px;
  margin-top: 30px;
  margin-bottom: 35px;
  margin-left: -${PADDING};
  margin-right: -${PADDING};
  overflow: auto;

  @media (max-height: 980px) {
    height: 210px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media (max-height: 768px) {
    height: 190px;
  }
`;

export const tasksHeader = Emotion.css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 ${PADDING};
`;

export const taskColumnHeading = Emotion.css`
  font-size: 12px;
  text-transform: uppercase;
  color: ${COLORS.GRAY[400]};
`;

export const task = Emotion.css`
  padding: 0 ${PADDING};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.GRAY[50]};
  }
`;

export const edited = Emotion.css`
  background: rgba(173, 216, 230, 0.25);

  &:hover {
    background-color: rgba(173, 216, 230, 0.45);
  }
`;

export const taskTitle = Emotion.css`
  flex-shrink: 0;
  font-size: 14px;
`;

export const flexSpaceBetween = Emotion.css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const deleteButton = Emotion.css`
  ${invisibleButton('small')};

  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 0;
  margin-right: 0;
  margin-left: 5px;
  padding-right: 0;

  svg {
    fill: ${COLORS.GRAY[950]};
    stroke: ${COLORS.GRAY[950]};
    transition-property: fill, stroke;
    transition-duration: 0.1s;
    transition-timing-function: ease;
  }

  &:hover {
    svg {
      fill: ${COLORS.RED[700]};
      stroke: ${COLORS.RED[700]};
    }
  }
`;

export const errors = Emotion.css`
  margin: 20px 0;
  font-size: 13px;
  color: ${COLORS.RED[600]};
`;

export const buttonWrapper = Emotion.css`
  display: flex;
  justify-content: space-between;

  & > button {
    flex-basis: 47%;
  }
`;
