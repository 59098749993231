import React, { useState, useEffect } from 'react';
import * as Emotion from 'emotion';
import { container, mt5, mb3, mb5, customInput, flexColumn, mb1, } from 'assets/soter-flex-styles';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import ModalWrapper from 'containers/ModalContainer/ModalWrapper';
import { createDashboardUserChatProfile, getDashboardUserChatProfile, updateDashboardUserChatProfile, resetUserProfile, setIsLoading, } from './profileStore/actions';
import { createValidationSchema } from './utils/validationSchema';
import Text from '../lib/ui/Text';
import CustomInput from '../lib/ui/CustomInput';
import CustomCheckbox from '../lib/ui/CustomCheckbox/CustomCheckbox';
import Button from '../lib/ui/Button';
import { COLORS } from '../constants/colors';
import UserFileUploader from './UserFileUploader';
import userProfileFilesConfig from './constants/userProfileFilesConfig';
import profileInformationFields from './constants/profileInformationFields';
import { useAppSelector } from '../lib/utils/useStore';
import Loader from '../lib/ui/Loader';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';
import NavigateBackButton from 'lib/ui/NavigateBackButton';
import { routes } from 'containers/PrivatPageHOC/constants/routes';
const UserProfileContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [additionalInformation, setAdditionalInformation] = useState('');
    const [openedModal, setOpenedModal] = useState(false);
    const [showTextAreaErrorMessage, setShowTextAreaErrorMessage] = useState(false);
    const { selectedCompany } = useAppSelector((store) => store.companies);
    const { userProfile, error } = useAppSelector((store) => store.userProfile);
    const { isLoading } = useAppSelector((state) => state.userProfile);
    useEffect(() => {
        dispatch(getDashboardUserChatProfile());
        dispatch(setIsLoading(true));
        return () => {
            dispatch(resetUserProfile());
        };
    }, []);
    useEffect(() => {
        if (userProfile.id) {
            setTermsAccepted(true);
        }
    }, [userProfile.id]);
    const modalClass = Emotion.css `
  min-width: 680px;
`;
    const textAreamaxLength = 1000;
    const sendData = ({ companyName, companyIndustry, naicsCode, location, }) => {
        const userProfileInfo = {
            companyName,
            companyIndustry,
            naicsCode,
            location,
            additionalInformation,
        };
        userProfile.id ? dispatch(updateDashboardUserChatProfile(userProfileInfo)) : dispatch(createDashboardUserChatProfile(userProfileInfo));
    };
    const validationSchema = createValidationSchema(t);
    const formik = useFormik({
        initialValues: userProfile,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (data) => sendData(data),
    });
    if (isLoading)
        return React.createElement(Loader, null);
    return (React.createElement("div", { className: container },
        ((error === null || error === void 0 ? void 0 : error.code) !== 5 && (error === null || error === void 0 ? void 0 : error.code) !== 13) && React.createElement("div", { style: { marginTop: '14px' } },
            React.createElement(NavigateBackButton, { additionalHandler: () => { }, name: t("SOTER_GENIUS.BACK_TO_CHAT"), navigateTo: `/${selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.id}/users/${routes.genius}/${routes.chat}` })),
        React.createElement("form", { onSubmit: formik.handleSubmit },
            React.createElement("div", { className: flexColumn },
                React.createElement(Text, { className: `${mb3} ${mt5}`, text: t("SOTER_GENIUS.PROFILE_INFORMATION") }),
                React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '22px' } }, profileInformationFields.map((field) => (React.createElement(CustomInput, { key: field.id, type: field.type, id: field.id, name: field.name, max: field.maxLength, placeholder: t(field.placeholder), formik: formik, defaultValue: userProfile[field.name], value: userProfile[field.name] })))),
                userProfile.id && (React.createElement(React.Fragment, null,
                    React.createElement(Text, { className: mt5, text: t("SOTER_GENIUS.SUPPORTING_INFORMATION") }),
                    React.createElement(Text, { className: mb3, text: t("SOTER_GENIUS.SUPPORTED_FORMATS"), appearance: 'caption' }),
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } }, userProfileFilesConfig.map((config) => {
                        var _a;
                        const file = userProfile.filesList ? [...(_a = userProfile.filesList) === null || _a === void 0 ? void 0 : _a.filter((file) => file.fileType === config.type)].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0] : null;
                        return (React.createElement(UserFileUploader, { key: config.type, fileConfig: config, file: file === null || file === void 0 ? void 0 : file.fileUrl, fileName: file === null || file === void 0 ? void 0 : file.originalFileName, fileId: file === null || file === void 0 ? void 0 : file.id }));
                    })))),
                React.createElement(Text, { className: `${mt5} ${mb3}`, text: t("SOTER_GENIUS.ADDITIONAL_INFORMATION") }),
                React.createElement("textarea", { id: "summary_description", className: `${mb5} ${customInput}`, style: {
                        height: '180px', width: '100%', borderColor: additionalInformation.length === textAreamaxLength ? '#d93b42' : COLORS.GRAY[100], borderWidth: '1px', marginBottom: '0px', outline: 'none'
                    }, onChange: (event) => setAdditionalInformation(event.target.value), onKeyDown: () => additionalInformation.length === textAreamaxLength ? setShowTextAreaErrorMessage(true) : setShowTextAreaErrorMessage(false), defaultValue: userProfile.additionalInformation, maxLength: textAreamaxLength }),
                showTextAreaErrorMessage && React.createElement("p", { style: { fontSize: '12px', fontWeight: 'normal', color: '#d93b42' } }, t("SOTER_GENIUS.MAX_LENGTH_TEXTAREA")),
                !userProfile.id && (React.createElement(React.Fragment, null,
                    React.createElement(Text, { className: `${mt5} ${mb1}`, text: t("SOTER_GENIUS.TERMS_CONDITIONS") }),
                    React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                        React.createElement(CustomCheckbox, { name: "terms", onChange: () => setTermsAccepted(!termsAccepted) }),
                        React.createElement("p", null,
                            React.createElement(Trans, { i18nKey: "SOTER_GENIUS.AGREEMENT", components: {
                                    1: React.createElement("button", { type: "button", onClick: () => setOpenedModal(true), style: { background: 'none', padding: '0', color: '#2096F3' } }, "Terms & Conditions")
                                } }))))),
                React.createElement(Button, { type: "submit", className: mt5, text: t("SOTER_GENIUS.SAVE_INFORMATION"), disabled: !userProfile.id && !termsAccepted })),
            openedModal && (React.createElement(ModalWrapper, { modalClass: modalClass, keepModalOnClickOutside: false, closeModal: () => setOpenedModal(false) },
                React.createElement(TermsAndConditionsDialog, null))))));
};
export default UserProfileContainer;
